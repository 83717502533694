import { FC, MouseEventHandler } from 'react';
import { Box, Button, Modal, ModalProps, styled } from '@mui/material';
import Image from 'next/image';
import close from '@/assets/icons/pay/close.svg';

const StyledModal = styled(Box)<{ backgroundColor?: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.backgroundColor || '#171b35'};
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 16px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #939bc2;
  border-radius: 120px;
  padding: 6px;
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
  z-index: 1;
  cursor: pointer;

  &:hover {
    border: none;
    background: #bdc2dc;
  }
`;

interface CustomModalProps extends ModalProps {
  backgroundColor?: string;
}

const CustomModal: FC<CustomModalProps> = ({
  open,
  onClose,
  children,
  backgroundColor = '#171b35',
  ...otherProps
}) => {
  const handleCloseClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    onClose?.(event, 'backdropClick');
  };
  return (
    <Modal open={open} onClose={onClose} {...otherProps}>
      <StyledModal backgroundColor={backgroundColor}>
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <CloseButton onClick={handleCloseClick}>
            <Image src={close} alt="Закрыть" width={24} height={24} />
          </CloseButton>
        </div>
        {children}
      </StyledModal>
    </Modal>
  );
};

export default CustomModal;
