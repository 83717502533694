import { isEmpty } from '@/utils/is-empty';
import { ParsedUrlQuery } from 'querystring';

type StringMap = { [key: string]: string | string[] | undefined };

const utmKeys = new Set([
  'utm_content',
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_term',
]);
const tdsKeys = new Set(['click_id', 'partner_id', 'tds']);
const referalCodeKeys = new Set(['code']);

const validPattern = /^[a-zA-Z0-9-_.*:=]{1,128}$/;

export const parseUtm = (query: ParsedUrlQuery) => {
  const utmData: StringMap = {};
  const tdsData: StringMap = {};
  let referralData: string = '';

  for (const [key, value] of Object.entries(query)) {
    if (!validPattern.test(String(value))) {
      continue;
    }
    if (utmKeys.has(key)) {
      utmData[key] = value;
    } else if (tdsKeys.has(key)) {
      tdsData[key] = value;
    } else if (referalCodeKeys.has(key)) {
      referralData = String(value);
    }
  }

  const marksData = {
    utm: utmData,
    tds: tdsData,
  };

  return { marksData, referralData };
};

export const saveUtmToCookies = (
  utm: Object,
  tds: Object,
  referralData: string
) => {
  if (!isEmpty(tds) || !isEmpty(utm)) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);
    const expires = `expires=${expirationDate.toUTCString()}`;

    document.cookie = `marks_data=${JSON.stringify(
      Object.assign(utm, tds)
    )}; ${expires}; path=/`;
  }
  if (!isEmpty(referralData)) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);
    const expires = `expires=${expirationDate.toUTCString()}`;

    document.cookie = `referral_param=${referralData}; ${expires}; path=/`;
  }
};
