import { useAuthContext } from '@/hooks/use-auth-context';
import { track } from '@amplitude/analytics-browser';
import { Box } from '@mui/material';
import Link from 'next/link';
import styles from './MediaLink.module.scss';
import OptimizedImage from '../Image';

/**
 * Иконка соц сети
 * @param href Ссылка на ресурс
 * @param icon Иконка медиа линка
 * @param bgcolor Цвет фона
 * @param size Размер
 */
export const MediaLink = ({
  href,
  icon,
  header,
  name,
}: {
  href: string;
  icon: string;
  header?: boolean;
  name: string;
}) => {
  const { isAuthorized } = useAuthContext();
  const handleClick = () => {
    track('goto', {
      subevent: `social_${name}`,
      auser: isAuthorized,
    });
  };
  return (
    <Link href={href} passHref>
      <Box
        onClick={() => handleClick()}
        component={'a'}
        target="_blank"
        className={header ? styles.header_container : styles.container}
      >
        <OptimizedImage src={icon} layout="fixed" alt="иконка соцсети" />
      </Box>
    </Link>
  );
};
