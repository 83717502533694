import React from 'react';
import Image from 'next/image';
import { Button, Typography } from '@mui/material';
import styles from './SoundsToggle.module.scss';
import iconSoundsOn from '@/assets/soundOn.svg';
import iconSoundsOff from '@/assets/soundOff.svg';

interface ISoundsToggle {
  toggleSound: () => void;
  isSoundEnabled: boolean | undefined;
}

const SoundsToggle = (props: ISoundsToggle) => {
  return (
    <Button
      color="mainGreen"
      variant="outlined"
      onClick={props.toggleSound}
      className={styles.container}
    >
      <div className={styles.main_icon}>
        <Image
          src={props.isSoundEnabled ? iconSoundsOn : iconSoundsOff}
          alt=""
        />
      </div>
      <Typography className={styles.main_text}>Звук</Typography>
    </Button>
  );
};

export default SoundsToggle;
