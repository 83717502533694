import wallet from '@/assets/icons/money.svg';
import plus from '@/assets/icons/plusCash.svg';
import IconButton from '@/components/styled/CustomButtons/IconButton/IconButton';
import { useBalance } from '@/hooks/use-balance';
import { calcPrice } from '@/utils/calc-price';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import styles from './UserMoneyBlock.module.scss';
import { useMediaQuery } from '@mui/material';
import { theme } from '@/styles/theme';
import { useSoundContext } from 'sounds';
import OptimizedImage from '@/components/Image';

const DynamicPayModal = dynamic(
  () => import('@/components/Pay/PayModal/PayModal'),
  {
    ssr: false,
  }
);

/**
 * Блок с балансом юзера и кнопкой
 * открытия модалки пополнения счета
 */
export const UserMoneyBlock = () => {
  const { balance } = useBalance();

  const { playSound } = useSoundContext();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    playSound('click_general');
  };

  const handleClose = () => {
    setOpen(false);
    playSound('click_general');
  };

  const showMobileMenuSmall = useMediaQuery(theme.breakpoints.down(700));

  const convertedBalance = Math.floor(calcPrice(balance))
    .toLocaleString('ru-RU')
    .replaceAll(',', '.');

  return (
    <>
      <IconButton
        onClick={handleOpen}
        icon={
          <OptimizedImage
            src={wallet}
            alt="wallet"
            title="Пополнить баланс"
            width="24px"
          />
        }
        text={`${convertedBalance}`}
        wallet
        iconSecondary={
          !showMobileMenuSmall ? (
            <OptimizedImage
              src={plus}
              layout="fixed"
              alt="пополнение баланса"
              className={styles.plus}
            />
          ) : undefined
        }
      />

      <DynamicPayModal open={open} close={handleClose} />
    </>
  );
};
