import {
  UserBalanceDocument,
  UserBalanceQuery,
  UserBalanceQueryVariables,
} from '@/generated/projectlb-hasura';
import { useQuery } from '@apollo/client';
import { useAuthContext } from './use-auth-context';

export const useBalance = () => {
  const { isAuthorized, userId, accessToken } = useAuthContext();

  const { data: balance }: { data: UserBalanceQuery | undefined } = useQuery(
    UserBalanceDocument,
    {
      fetchPolicy: 'cache-and-network',
      context: {
        token: accessToken,
      },
      skip: !isAuthorized,
      variables: {
        user_id: userId,
      } as UserBalanceQueryVariables,
    }
  );
  return {
    balance: balance?.user_account_by_pk?.user_wallet?.balance || 0,
    bonusBalance: balance?.user_account_by_pk?.user_bonus_wallet?.balance || 0,
    isOutputBlocked: balance?.user_account_by_pk?.is_output_blocked,
  };
};
