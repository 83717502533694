import styles from './ModalWritePromo.module.scss';
import ModalPromo from './ModalPromo/ModalPromo';
import CustomModal from '@/components/styled/CustomModal';
import { useEffect } from 'react';
import { track } from '@amplitude/analytics-browser';
import { useAuthContext } from '@/hooks/use-auth-context';

export interface IPayModal {
  open: boolean;
  close: () => void;
  openPay: () => void;
}

const ModalWritePromo = (props: IPayModal) => {
  const { isAuthorized } = useAuthContext();

  useEffect(() => {
    if (props.open) {
      track('open promocode modal', { auser: isAuthorized });
    }
  }, [props.open]);

  return (
    <CustomModal
      open={props.open}
      onClose={props.close}
      className={styles.containerModal}
    >
      <div className={styles.container}>
        <ModalPromo openPay={props.openPay} />
      </div>
    </CustomModal>
  );
};

export default ModalWritePromo;
