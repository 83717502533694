import { useGetUserProfileByIdLazyQuery } from '@/generated/projectlb-hasura';
import { useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';

export type TUserType = {
  avatar: string;
  persona_name: string;
  profile_url?: string | null;
  haveBonus: boolean;
};

/** Получение основных данных профиля */
export const useUserData = (userId: string | undefined) => {
  const [userData, setUserData] = useState({} as TUserType);

  const [getUserProfile] = useGetUserProfileByIdLazyQuery({
    variables: { user_id: { _eq: userId } },

    onCompleted: (data) => {
      const getUserData = data.user_profile[0];

      const userHaveBonus = data.user_got_bonus?.length === 0;

      setUserData({
        avatar: getUserData.avatar_full!,
        persona_name: getUserData.persona_name,
        profile_url: getUserData.profile_url,
        haveBonus: userHaveBonus,
      });
    },
    onError: (error) => {
      captureException(error);
    },
  });

  useEffect(() => {
    if (userId) getUserProfile();
  }, [userId]);

  return userData;
};
