import { track } from '@amplitude/analytics-browser';
import { useRouter } from 'next/router';
import { useAuthContext } from './use-auth-context';

interface IErrorProperties {
  message: string;
  from?: string;
}

export const useAmpliError = () => {
  const { asPath } = useRouter();
  const { isAuthorized } = useAuthContext();

  const errorTrack = ({ message, from = asPath }: IErrorProperties) =>
    track('error', { msg: message, from: from, auser: isAuthorized });

  return { errorTrack };
};
