const itemColors = {
  GRAY: '#6F6F6F',
  LIGHT_BLUE: '#5C98EF',
  BLUE: '#3C60FC',
  PURPLE: '#6E30FA',
  PINK: '#BE48DA',
  RED: '#EC433C',
  YELLOW: '#DFBB27',
};

const borderColors = {
  GRAY: '#ACACAC',
  LIGHT_BLUE: '#5C98EF',
  BLUE: '#3C60FC',
  PURPLE: '#6E30FA',
  PINK: '#DA47FD',
  RED: '#EC433C',
  YELLOW: '#DFBB27',
};

export const itemRarities: { [rarityName: string]: string } = {
  'consumer grade': itemColors.GRAY,
  'mil-spec grade': itemColors.BLUE,
  'industrial grade': itemColors.LIGHT_BLUE,
  restricted: itemColors.PURPLE,
  classified: itemColors.PINK,
  covert: itemColors.RED,
  'base grade': itemColors.GRAY,
  master: itemColors.RED,
  extraordinary: itemColors.RED,
  distinguished: itemColors.BLUE,
  exceptional: itemColors.PINK,
  superior: itemColors.PURPLE,
  'high grade': itemColors.BLUE,
  remarkable: itemColors.PINK,
  exotic: itemColors.PURPLE,
  contraband: itemColors.YELLOW,
};

export const itemBorders: { [rarityName: string]: string } = {
  'consumer grade': borderColors.GRAY,
  'mil-spec grade': borderColors.BLUE,
  'industrial grade': borderColors.LIGHT_BLUE,
  restricted: borderColors.PURPLE,
  classified: borderColors.PINK,
  covert: borderColors.RED,
  'base grade': borderColors.GRAY,
  master: borderColors.RED,
  extraordinary: borderColors.RED,
  distinguished: borderColors.BLUE,
  exceptional: borderColors.PINK,
  superior: borderColors.PURPLE,
  'high grade': borderColors.BLUE,
  remarkable: borderColors.PINK,
  exotic: borderColors.PURPLE,
  contraband: borderColors.YELLOW,
};
