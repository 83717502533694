import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetWheelRewardsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetWheelRewardsQuery = {
  __typename?: 'query_root';
  GetWheelRewardType: {
    __typename?: 'WheelRewardsType';
    success: boolean;
    error?: string | null;
    wheel_rewards_type?: Array<{
      __typename?: 'WheelRewardType';
      reward_image: string;
      reward_name: string;
      reward_type: string;
    } | null> | null;
  };
};

export type UserWheelTicketsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type UserWheelTicketsQuery = {
  __typename?: 'query_root';
  UserWheelInfo: {
    __typename?: 'UserWheelInfo';
    success: boolean;
    error?: string | null;
    ticket_info?: Array<{
      __typename?: 'TicketInfo';
      ticket_count?: number | null;
      ticket_limit?: number | null;
      ticket_name?: string | null;
    } | null> | null;
  };
};

export type WheelUserTicketInfoQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type WheelUserTicketInfoQuery = {
  __typename?: 'query_root';
  WheelUserTicketInfo?: {
    __typename?: 'TicketsInfoResponse';
    tickets?: Array<{
      __typename?: 'TicketsInfo';
      ticket_count?: number | null;
      ticket_type_name?: string | null;
    } | null> | null;
  } | null;
};

export type RollingWheelMutationVariables = Types.Exact<{
  ticketType: Types.Scalars['String'];
}>;

export type RollingWheelMutation = {
  __typename?: 'mutation_root';
  RollingWheel: {
    __typename?: 'RollingWheelRewardAnswer';
    success: boolean;
    error?: string | null;
    reward_data?: {
      __typename?: 'RollingWheelRewardData';
      reward_id: string;
      reward_description: string;
      reward_image: string;
      reward_name: string;
      reward_type: string;
      reward_value: string;
      ticket_type_id: string;
      skin?: {
        __typename?: 'WheelItem';
        item_image_link: string;
        item_name: string;
        item_id: string;
        item_rarity: string;
        item_quality: string;
        item_hash_name: string;
        item_min_price: number;
        localization: Array<{
          __typename?: 'Localization';
          item_name_localized: string;
          locale: string;
        } | null>;
      } | null;
    } | null;
  };
};

export type CheckBonusWheelNicknameMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CheckBonusWheelNicknameMutation = {
  __typename?: 'mutation_root';
  CheckBonusWheelNickname: {
    __typename?: 'UserWheelNickname';
    error?: string | null;
    claimed: boolean;
  };
};

export const GetWheelRewardsDocument = gql`
  query GetWheelRewards {
    GetWheelRewardType {
      success
      error
      wheel_rewards_type {
        reward_image
        reward_name
        reward_type
      }
    }
  }
`;

/**
 * __useGetWheelRewardsQuery__
 *
 * To run a query within a React component, call `useGetWheelRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWheelRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWheelRewardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWheelRewardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWheelRewardsQuery,
    GetWheelRewardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWheelRewardsQuery, GetWheelRewardsQueryVariables>(
    GetWheelRewardsDocument,
    options
  );
}
export function useGetWheelRewardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWheelRewardsQuery,
    GetWheelRewardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWheelRewardsQuery,
    GetWheelRewardsQueryVariables
  >(GetWheelRewardsDocument, options);
}
export type GetWheelRewardsQueryHookResult = ReturnType<
  typeof useGetWheelRewardsQuery
>;
export type GetWheelRewardsLazyQueryHookResult = ReturnType<
  typeof useGetWheelRewardsLazyQuery
>;
export type GetWheelRewardsQueryResult = Apollo.QueryResult<
  GetWheelRewardsQuery,
  GetWheelRewardsQueryVariables
>;
export const UserWheelTicketsDocument = gql`
  query UserWheelTickets {
    UserWheelInfo {
      success
      error
      ticket_info {
        ticket_count
        ticket_limit
        ticket_name
      }
    }
  }
`;

/**
 * __useUserWheelTicketsQuery__
 *
 * To run a query within a React component, call `useUserWheelTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWheelTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWheelTicketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWheelTicketsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserWheelTicketsQuery,
    UserWheelTicketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserWheelTicketsQuery, UserWheelTicketsQueryVariables>(
    UserWheelTicketsDocument,
    options
  );
}
export function useUserWheelTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserWheelTicketsQuery,
    UserWheelTicketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserWheelTicketsQuery,
    UserWheelTicketsQueryVariables
  >(UserWheelTicketsDocument, options);
}
export type UserWheelTicketsQueryHookResult = ReturnType<
  typeof useUserWheelTicketsQuery
>;
export type UserWheelTicketsLazyQueryHookResult = ReturnType<
  typeof useUserWheelTicketsLazyQuery
>;
export type UserWheelTicketsQueryResult = Apollo.QueryResult<
  UserWheelTicketsQuery,
  UserWheelTicketsQueryVariables
>;
export const WheelUserTicketInfoDocument = gql`
  query WheelUserTicketInfo {
    WheelUserTicketInfo {
      tickets {
        ticket_count
        ticket_type_name
      }
    }
  }
`;

/**
 * __useWheelUserTicketInfoQuery__
 *
 * To run a query within a React component, call `useWheelUserTicketInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useWheelUserTicketInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWheelUserTicketInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useWheelUserTicketInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WheelUserTicketInfoQuery,
    WheelUserTicketInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WheelUserTicketInfoQuery,
    WheelUserTicketInfoQueryVariables
  >(WheelUserTicketInfoDocument, options);
}
export function useWheelUserTicketInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WheelUserTicketInfoQuery,
    WheelUserTicketInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WheelUserTicketInfoQuery,
    WheelUserTicketInfoQueryVariables
  >(WheelUserTicketInfoDocument, options);
}
export type WheelUserTicketInfoQueryHookResult = ReturnType<
  typeof useWheelUserTicketInfoQuery
>;
export type WheelUserTicketInfoLazyQueryHookResult = ReturnType<
  typeof useWheelUserTicketInfoLazyQuery
>;
export type WheelUserTicketInfoQueryResult = Apollo.QueryResult<
  WheelUserTicketInfoQuery,
  WheelUserTicketInfoQueryVariables
>;
export const RollingWheelDocument = gql`
  mutation RollingWheel($ticketType: String!) {
    RollingWheel(ticket_type: $ticketType) {
      success
      error
      reward_data {
        reward_id
        reward_description
        reward_image
        reward_name
        reward_type
        reward_value
        ticket_type_id
        skin {
          item_image_link
          item_name
          item_id
          item_rarity
          item_quality
          item_hash_name
          item_min_price
          localization {
            item_name_localized
            locale
          }
        }
      }
    }
  }
`;
export type RollingWheelMutationFn = Apollo.MutationFunction<
  RollingWheelMutation,
  RollingWheelMutationVariables
>;

/**
 * __useRollingWheelMutation__
 *
 * To run a mutation, you first call `useRollingWheelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRollingWheelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rollingWheelMutation, { data, loading, error }] = useRollingWheelMutation({
 *   variables: {
 *      ticketType: // value for 'ticketType'
 *   },
 * });
 */
export function useRollingWheelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RollingWheelMutation,
    RollingWheelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RollingWheelMutation,
    RollingWheelMutationVariables
  >(RollingWheelDocument, options);
}
export type RollingWheelMutationHookResult = ReturnType<
  typeof useRollingWheelMutation
>;
export type RollingWheelMutationResult =
  Apollo.MutationResult<RollingWheelMutation>;
export type RollingWheelMutationOptions = Apollo.BaseMutationOptions<
  RollingWheelMutation,
  RollingWheelMutationVariables
>;
export const CheckBonusWheelNicknameDocument = gql`
  mutation CheckBonusWheelNickname {
    CheckBonusWheelNickname {
      error
      claimed
    }
  }
`;
export type CheckBonusWheelNicknameMutationFn = Apollo.MutationFunction<
  CheckBonusWheelNicknameMutation,
  CheckBonusWheelNicknameMutationVariables
>;

/**
 * __useCheckBonusWheelNicknameMutation__
 *
 * To run a mutation, you first call `useCheckBonusWheelNicknameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckBonusWheelNicknameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkBonusWheelNicknameMutation, { data, loading, error }] = useCheckBonusWheelNicknameMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckBonusWheelNicknameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckBonusWheelNicknameMutation,
    CheckBonusWheelNicknameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckBonusWheelNicknameMutation,
    CheckBonusWheelNicknameMutationVariables
  >(CheckBonusWheelNicknameDocument, options);
}
export type CheckBonusWheelNicknameMutationHookResult = ReturnType<
  typeof useCheckBonusWheelNicknameMutation
>;
export type CheckBonusWheelNicknameMutationResult =
  Apollo.MutationResult<CheckBonusWheelNicknameMutation>;
export type CheckBonusWheelNicknameMutationOptions = Apollo.BaseMutationOptions<
  CheckBonusWheelNicknameMutation,
  CheckBonusWheelNicknameMutationVariables
>;
