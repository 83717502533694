import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActivePaymentMethodsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ActivePaymentMethodsQuery = {
  __typename?: 'query_root';
  payment_methods_view: Array<{
    __typename?: 'payment_methods_view';
    commission?: number | null;
    currency: string;
    display_name: string;
    is_active: boolean;
    is_popular: boolean;
    is_p2p: boolean;
    max_amount: number;
    method: string;
    method_id: string;
    min_amount: number;
    pic?: string | null;
    pic_asset_name?: string | null;
    pic_mobile?: string | null;
    pic_mobile_asset_name?: string | null;
    ps_type: number;
    sort: number;
    sub_method: string;
    tab_id: number;
  }>;
};

export type CreatePaymentLinkMutationVariables = Types.Exact<{
  transaction_data: Types.CreateUserPaymentTransactionInput;
}>;

export type CreatePaymentLinkMutation = {
  __typename?: 'mutation_root';
  CreateUserPaymentTransaction: {
    __typename?: 'CreateUserPaymentTransactionOutput';
    success: boolean;
    error?: string | null;
    link?: string | null;
  };
};

export const ActivePaymentMethodsDocument = gql`
  query ActivePaymentMethods {
    payment_methods_view(where: { is_active: { _eq: true } }) {
      commission
      currency
      display_name
      is_active
      is_popular
      is_p2p
      max_amount
      method
      method_id
      min_amount
      pic
      pic_asset_name
      pic_mobile
      pic_mobile_asset_name
      ps_type
      sort
      sub_method
      tab_id
    }
  }
`;

/**
 * __useActivePaymentMethodsQuery__
 *
 * To run a query within a React component, call `useActivePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivePaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivePaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActivePaymentMethodsQuery,
    ActivePaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActivePaymentMethodsQuery,
    ActivePaymentMethodsQueryVariables
  >(ActivePaymentMethodsDocument, options);
}
export function useActivePaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivePaymentMethodsQuery,
    ActivePaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActivePaymentMethodsQuery,
    ActivePaymentMethodsQueryVariables
  >(ActivePaymentMethodsDocument, options);
}
export type ActivePaymentMethodsQueryHookResult = ReturnType<
  typeof useActivePaymentMethodsQuery
>;
export type ActivePaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useActivePaymentMethodsLazyQuery
>;
export type ActivePaymentMethodsQueryResult = Apollo.QueryResult<
  ActivePaymentMethodsQuery,
  ActivePaymentMethodsQueryVariables
>;
export const CreatePaymentLinkDocument = gql`
  mutation CreatePaymentLink(
    $transaction_data: CreateUserPaymentTransactionInput!
  ) {
    CreateUserPaymentTransaction(transaction_data: $transaction_data) {
      success
      error
      link
    }
  }
`;
export type CreatePaymentLinkMutationFn = Apollo.MutationFunction<
  CreatePaymentLinkMutation,
  CreatePaymentLinkMutationVariables
>;

/**
 * __useCreatePaymentLinkMutation__
 *
 * To run a mutation, you first call `useCreatePaymentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentLinkMutation, { data, loading, error }] = useCreatePaymentLinkMutation({
 *   variables: {
 *      transaction_data: // value for 'transaction_data'
 *   },
 * });
 */
export function useCreatePaymentLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentLinkMutation,
    CreatePaymentLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaymentLinkMutation,
    CreatePaymentLinkMutationVariables
  >(CreatePaymentLinkDocument, options);
}
export type CreatePaymentLinkMutationHookResult = ReturnType<
  typeof useCreatePaymentLinkMutation
>;
export type CreatePaymentLinkMutationResult =
  Apollo.MutationResult<CreatePaymentLinkMutation>;
export type CreatePaymentLinkMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentLinkMutation,
  CreatePaymentLinkMutationVariables
>;
