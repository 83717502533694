import visa from '@/assets/icons/pay/visa.svg';
import mastercard from '@/assets/icons/pay/mastercard.svg';
import mir from '@/assets/icons/pay/mir.svg';
import uCard from '@/assets/icons/pay/uCard.svg';
import qiwi from '@/assets/icons/pay/qiwi.svg';
import psiDss from '@/assets/icons/pay/psiDss.svg';

export const pays = [
  {
    img: visa,
  },
  {
    img: mastercard,
  },
  {
    img: mir,
  },
  {
    img: uCard,
  },
  {
    img: qiwi,
  },
  {
    img: psiDss,
  },
];
