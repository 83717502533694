import ArrowRightIcon from '@/assets/icons/pay/ArrowRightIcon';
import CentreButton from '@/components/styled/CustomButtons/CentreButton/CentreButton';
import OvalButton from '@/components/styled/CustomButtons/OvalButton/OvalButton';
import { formatNumber } from '@/utils/format-number';
import Link from 'next/link';
import styles from '../MainPageCards/MainPageCards.module.scss';
import golden from '@/assets/icons/baraban/golden.svg';
import silver from '@/assets/icons/baraban/silver.svg';
import iron from '@/assets/icons/baraban/bronze.svg';
import Image from 'next/image';
import coin from '@/assets/icons/HDCoinSmall.svg';
import TimerBlock from '@/components/Layout/ChallengeDrop/CIrcle/TimerBlock';
import { useRouter } from 'next/router';
import { ClosestGiveaway, TicketsInfo } from '@/generated/projectlb-hasura';
import { MissionCount } from '../../../atoms/mission-count-atom';
import dayjs from 'dayjs';
import { BannerCardsInfo } from '@/components/MainPage/MainPageCards/MainPageCards';

interface BannerCardProps {
  card: BannerCardsInfo;
  index: number;
  handleBannerClick: () => void;
  missionsCount: MissionCount | undefined;
  bonusBalance: number;
  ticketsInfo: (TicketsInfo | null)[] | null | undefined;
  closestGiveaway: ClosestGiveaway | null | undefined;
}

export function getTicketImage(type: string) {
  switch (type) {
    case 'iron':
      return iron;
    case 'silver':
      return silver;
    case 'gold':
      return golden;
    default:
      return '';
  }
}

const BannerCard = ({
  card,
  index,
  handleBannerClick,
  missionsCount,
  bonusBalance,
  ticketsInfo,
  closestGiveaway,
}: BannerCardProps) => {
  const router = useRouter();
  const isMainPage =
    router.asPath === '/' || router.asPath === '/?modal=payment';

  const cardLink =
    card.type !== 'mission'
      ? card?.link!
      : missionsCount && missionsCount.completed < missionsCount.all
        ? card?.link!
        : card?.link2!;

  function getTicketCount(type: string) {
    const ticket = ticketsInfo?.find((t) => t?.ticket_type_name === type);
    return ticket ? ticket.ticket_count : 0;
  }

  if (card.type === 'banner' && isMainPage) {
    return (
      <div key={card.title} className={styles[`div${index + 1}`]}>
        <div className={styles.cardContainer}>
          <div className={styles.cardInfo}>
            <div className={styles.cardText}>
              <div className={styles.cardHeaderArrow}>
                <h2>{card.title}</h2>
              </div>
              <p className={styles.firstBannerTxt}>{card.description}</p>
            </div>
            <div className={styles.cardButton}>
              <CentreButton
                className={styles.cardButtonText}
                text={card.buttonText!}
                onClick={handleBannerClick}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (card.type === 'banner' && !isMainPage) {
    return null;
  }

  if (isMainPage) {
    return (
      <div key={card.title} className={styles[`div${index + 1}`]}>
        <Link href={cardLink}>
          <div className={styles.cardContainer}>
            <div className={styles.cardInfo}>
              <div className={styles.cardText}>
                <div className={styles.cardHeaderArrow}>
                  <h2>
                    {card.type !== 'mission' ||
                    (missionsCount &&
                      missionsCount.completed < missionsCount.all)
                      ? card.title
                      : 'Миссии'}
                  </h2>
                  <OvalButton
                    type="submit"
                    small
                    id="arrow"
                    icon={<ArrowRightIcon />}
                  />
                </div>
                <p>{card.description}</p>
              </div>

              {card.type === 'mission' &&
                missionsCount &&
                missionsCount?.completed < missionsCount?.all && (
                  <div className={styles.bonusConBlock}>
                    <div className={styles.bonusCon}>
                      <p>Выполнено:</p>
                      <div className={styles.bonuses}>
                        <p className={styles.text_bonus}>
                          {`${missionsCount?.completed} / ${missionsCount?.all}`}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              {card.type === 'store' && (
                <div className={styles.bonusCon}>
                  <p>Накоплено:</p>
                  <div className={styles.bonuses}>
                    <p className={styles.text_bonus}>
                      <Image src={coin} alt="Coin" width={16} height={16} />
                      {bonusBalance ? String(formatNumber(bonusBalance)) : 0}
                    </p>
                  </div>
                </div>
              )}
              {card.type === 'giveaway' &&
                closestGiveaway?.giveaway_id &&
                dayjs(closestGiveaway?.end_date).valueOf() >=
                  dayjs().valueOf() && (
                  <div className={styles.bonusCon}>
                    <p>До розыгрыша</p>

                    <TimerBlock
                      endDate={new Date(closestGiveaway?.end_date!).getTime()}
                      startDate={new Date(
                        closestGiveaway?.start_date!
                      ).getTime()}
                    />
                  </div>
                )}
              {card.type === 'wheel' && (
                <div className={styles.barabanCon}>
                  {['iron', 'silver', 'gold'].map((type) => (
                    <div className={styles.bonusCon} key={type}>
                      <Image
                        src={getTicketImage(type)}
                        alt={`${type} ticket`}
                      />
                      <div className={styles.barabanText}>
                        <p>{getTicketCount(type)}/5</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Link>
      </div>
    );
  }

  // Not main page
  return (
    <div className={styles.secondCon} key={card.title}>
      <Link href={cardLink}>
        <a style={{ width: '100%' }}>
          <div className={styles.cardCon2}>
            <div className={styles[`secondDiv${index + 1}`]}>
              <div className={styles.cardInfo2}>
                <h2>
                  {card.type !== 'mission' ||
                  (missionsCount && missionsCount.completed < missionsCount.all)
                    ? card.title
                    : 'Миссии'}
                </h2>
                {/* Cards without extra indo (Upgrade and Giveaway) */}
                {card.type !== 'mission' &&
                  card.type !== 'wheel' &&
                  card.type !== 'store' && <p>{card.description}</p>}

                {card.type === 'mission' &&
                  (missionsCount &&
                  missionsCount.completed < missionsCount.all ? (
                    <div className={styles.bonusCon2}>
                      <p>Выполнено:</p>
                      <div className={styles.bonuses}>
                        <p
                          className={styles.text_bonus}
                        >{`${missionsCount?.completed} / ${missionsCount?.all}`}</p>
                      </div>
                    </div>
                  ) : (
                    <p>Зарабатывайте hd coin</p>
                  ))}
                {card.type === 'store' && (
                  <div className={styles.bonusCon2}>
                    <p>Накоплено:</p>
                    <div className={styles.bonuses}>
                      <p className={styles.text_bonus}>
                        <Image src={coin} alt="Coin" width={16} height={16} />
                        {bonusBalance ? String(formatNumber(bonusBalance)) : 0}
                      </p>
                    </div>
                  </div>
                )}
                {card.type === 'wheel' && (
                  <div className={styles.bonusCon2}>
                    {['iron', 'silver', 'gold'].map((type) => (
                      <div className={styles.cardInfo2Item} key={type}>
                        <Image
                          src={getTicketImage(type)}
                          alt={`${type} ticket`}
                        />
                        <div className={styles.barabanText}>
                          <p>{getTicketCount(type)}/5</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default BannerCard;
