import React from 'react';

interface HyperRubProps {
  color?: string;
}

const HyperRub: React.FC<HyperRubProps> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M9.16667 1.11111C9.16667 0.497461 9.66413 0 10.2778 0H17.2222C17.8359 0 18.3333 0.497461 18.3333 1.11111V54.8889C18.3333 55.5025 17.8359 56 17.2222 56H10.2778C9.66413 56 9.16667 55.5025 9.16667 54.8889V1.11111Z"
      fill={color || '#D3F85A'}
    />
    <path
      d="M43.6111 1.11111C43.6111 0.497461 44.1086 0 44.7222 0H48.3333C50.7879 0 52.7778 1.98985 52.7778 4.44444V54.8889C52.7778 55.5025 52.2803 56 51.6667 56H48.0556C45.601 56 43.6111 54.0102 43.6111 51.5556V1.11111Z"
      fill={color || '#D3F85A'}
    />
    <path
      d="M43.6111 1.11111C43.6111 0.497461 44.1086 0 44.7222 0H48.3333C50.7879 0 52.7778 1.98985 52.7778 4.44444V54.8889C52.7778 55.5025 52.2803 56 51.6667 56H48.0556C45.601 56 43.6111 54.0102 43.6111 51.5556V1.11111Z"
      fill={color || '#D3F85A'}
    />
    <path
      d="M54.8889 20.1111C55.5025 20.1111 56 20.6086 56 21.2222V24.8333C56 27.2879 54.0102 29.2778 51.5556 29.2778L4.44444 29.2778C1.98985 29.2778 1.31125e-07 27.2879 2.38419e-07 24.8333L3.96265e-07 21.2222C4.23089e-07 20.6086 0.497462 20.1111 1.11111 20.1111L54.8889 20.1111Z"
      fill={color || '#D3F85A'}
    />
    <path
      d="M26.3889 33.8333C27.0025 33.8333 27.5 34.3308 27.5 34.9444V38.5556C27.5 41.0102 25.5102 43 23.0556 43H4.44444C1.98985 43 -1.07294e-07 41.0102 0 38.5556L1.57847e-07 34.9444C1.8467e-07 34.3308 0.497462 33.8333 1.11111 33.8333H26.3889Z"
      fill={color || '#D3F85A'}
    />
  </svg>
);

export default HyperRub;
