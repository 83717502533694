import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export const CustomTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      display: 'none',

      width: '100%',
    },
    '&[type="number"]': {
      MozAppearance: 'textfield',
    },
  },
  '& .MuiOutlinedInput-root': {
    // [theme.breakpoints.down(599)]: {
    //   fontSize: '16px',
    // },
    height: '40px',
    '& fieldset': {
      border: '1px solid #D3F85A',
      borderRadius: '16px',
    },
    '&:hover fieldset': {
      borderColor: '#D3F85A',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D3F85A',
    },
  },
});

export const CustomTextFieldPromo = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    [theme.breakpoints.down(599)]: {
      height: '40px',
    },

    '& fieldset': {
      border: '1px solid #D3F85A',
      borderRadius: '16px',
    },
    '&:hover fieldset': {
      borderColor: '#D3F85A',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D3F85A',
    },
  },
}));
