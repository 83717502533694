const AllIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.1667 5.67968V2.65301C15.1667 1.71301 14.74 1.33301 13.68 1.33301H10.9867C9.92667 1.33301 9.5 1.71301 9.5 2.65301V5.67301C9.5 6.61967 9.92667 6.99301 10.9867 6.99301H13.68C14.74 6.99967 15.1667 6.61967 15.1667 5.67968Z"
        fill={color || '#9297B2'}
      />
      <path
        d="M15.1667 13.18V10.4867C15.1667 9.42667 14.74 9 13.68 9H10.9867C9.92667 9 9.5 9.42667 9.5 10.4867V13.18C9.5 14.24 9.92667 14.6667 10.9867 14.6667H13.68C14.74 14.6667 15.1667 14.24 15.1667 13.18Z"
        fill={color || '#9297B2'}
      />
      <path
        d="M7.50016 5.67968V2.65301C7.50016 1.71301 7.0735 1.33301 6.0135 1.33301H3.32016C2.26016 1.33301 1.8335 1.71301 1.8335 2.65301V5.67301C1.8335 6.61967 2.26016 6.99301 3.32016 6.99301H6.0135C7.0735 6.99967 7.50016 6.61967 7.50016 5.67968Z"
        fill={color || '#9297B2'}
      />
      <path
        d="M7.50016 13.18V10.4867C7.50016 9.42667 7.0735 9 6.0135 9H3.32016C2.26016 9 1.8335 9.42667 1.8335 10.4867V13.18C1.8335 14.24 2.26016 14.6667 3.32016 14.6667H6.0135C7.0735 14.6667 7.50016 14.24 7.50016 13.18Z"
        fill={color || '#9297B2'}
      />
      <path
        d="M7.50016 13.18V10.4867C7.50016 9.42667 7.0735 9 6.0135 9H3.32016C2.26016 9 1.8335 9.42667 1.8335 10.4867V13.18C1.8335 14.24 2.26016 14.6667 3.32016 14.6667H6.0135C7.0735 14.6667 7.50016 14.24 7.50016 13.18Z"
        fill={color || '#9297B2'}
      />
    </svg>
  );
};

export default AllIcon;
