import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import styles from './TabItems.module.scss';

type ITabItems = ButtonProps & {
  loading?: boolean;
  className?: string;
  active?: boolean;
  icon: JSX.Element;
  text?: string;
};

export default function TabItems({
  loading,
  className,
  active,
  icon,
  text,
  ...props
}: ITabItems) {
  return (
    <>
      <Button
        className={`${styles.default} ${
          active ? styles.active : undefined
        } ${className}`}
        disabled={props.disabled || loading == true}
        {...props}
      >
        {icon}
        <span className={styles.span}>{text}</span>
      </Button>
    </>
  );
}
