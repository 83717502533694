import { useEffect } from 'react';
import {
  detectMobile,
  getBrowserName,
  getUserOS,
  getUserTimeZoneOffset,
} from '@/utils/user-device';

/**
 * This hook will then send the user's data to JivoChat, including their ID, browser name, device type, OS, timezone and screen size.
 * If the user is not authorized or the ID is not provided, the hook will do nothing.
 * @param {boolean} isAuthorized - A boolean flag indicating whether the user is authorized.
 * @param {string | undefined} userId - The user's ID.
 */
const useJivoSetUserData = (
  isAuthorized: boolean,
  userId: string | undefined
) => {
  useEffect(() => {
    const MAX_RETRIES = 10;
    const RETRY_INTERVAL = 3000;
    let retryCount = 0;

    const setJivoChatData = () => {
      if (!isAuthorized || !userId) {
        return;
      }

      const browserName = getBrowserName();
      const device = detectMobile() ? 'Mobile' : 'Desktop';
      const userOS = getUserOS();
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const timezoneOffset = getUserTimeZoneOffset();

      const userData = [
        { title: 'User ID', content: userId },
        { title: 'Browser', content: `${browserName} ${device}` },
        { title: 'User OS', content: userOS },
        { title: 'User Timezone', content: timezoneOffset },
        {
          title: 'Screen size',
          content: `Ширина экрана: ${screenWidth}, Высота экрана: ${screenHeight}`,
        },
      ];

      if (typeof window?.jivo_api?.setCustomData === 'function') {
        window.jivo_api.setCustomData(userData);
      } else if (retryCount < MAX_RETRIES) {
        retryCount++;
        setTimeout(setJivoChatData, RETRY_INTERVAL);
      }
    };

    setJivoChatData();
  }, [isAuthorized, userId]);
};

export default useJivoSetUserData;
