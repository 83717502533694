import { Logo } from '@/components/Logo/Logo';
import { pays } from '@/utils/pay-line';
import { Typography } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { footerLinks, footerLinksBottom } from '@/configs/urls';
import styles from './Footer.module.scss';
import footer from '@/assets/images/mainBackground/footer.webp';
import hyperDrop from '@/assets/images/footerText.png';

/**
 * Подвал сайта
 */
const Footer = () => {
  return (
    <>
      <footer className={styles.container}>
        <div className={styles.pay_container}>
          <Typography className={styles.textPay}>
            Любые способы оплаты
          </Typography>
          <div className={styles.payBlock}>
            {pays.map((pay, index) => {
              return (
                <div key={index} className={styles.pay}>
                  <Image src={pay.img} alt="" />
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.footerDown}>
          <div className={styles.topBox}>
            <div className={styles.leftBox}>
              <div className={styles.urlHyper}>
                <Logo />
                <Typography className={styles.coopyrightText}>
                  © hyper-drop.com, {new Date().getFullYear()}
                </Typography>
              </div>
            </div>

            <RightBox />
          </div>
          <div className={styles.footerImageWrapper}>
            <div className={styles.imgBack}>
              <Image
                src={footer}
                layout="fixed"
                quality={100}
                alt="Картинка на фоне"
                style={{
                  mixBlendMode: 'luminosity',
                }}
              />
            </div>
            <div className={styles.gradient}></div>
          </div>
          <div className={styles.bottomBox}>
            <div className={styles.bottom_container}>
              {footerLinksBottom.map((footerLinkBottom, index) => {
                return (
                  <Link key={index} href={footerLinkBottom.href} legacyBehavior>
                    <a className={styles.footerLinkBottom}>
                      {footerLinkBottom.title}
                    </a>
                  </Link>
                );
              })}
              <div className={styles.bottomRight}>
                <Image src={hyperDrop} width={413} height={26} alt="" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const RightBox = () => {
  return (
    <div className={styles.rightBox}>
      <div className={styles.footerLinksContainer}>
        {footerLinks.map((link) => {
          return (
            <Link key={link.title} href={link.href} legacyBehavior>
              <a className={styles.pointLink}>
                <Image src={link.icon} alt="" />
                <Typography className={styles.linkText}>
                  {link.title}
                </Typography>
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
