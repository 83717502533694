import Head from 'next/head';

/**
 * Обертка тега head для страниц, чтобы иметь возможность настраивать SEO и мету отдельно под каждую
 */
export const PageHead = ({
  title = 'HyperDrop - открывай кейсы КС ГО, бесплатные и новые кейсы',
  description = 'Честное открытие кейсов с самыми популярными скинами в CS:GO! Крутые бонусы и моментальный вывод в Steam.',
}: {
  title?: string;
  description?: string;
}) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="format-detection" content="telephone=no"></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
    </>
  );
};
