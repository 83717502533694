import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMissionsCompletionQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetMissionsCompletionQuery = {
  __typename?: 'query_root';
  GetBasicMissionsData?: {
    __typename?: 'BasicMissionsResponse';
    one_time_missions: {
      __typename?: 'BasicOneTimeMissionsList';
      missions_full_count: number;
      missions_complete_count: number;
      bonus_case_available: number;
    };
  } | null;
};

export type GetMissionsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetMissionsQuery = {
  __typename?: 'query_root';
  GetMissionsData?: {
    __typename?: 'MissionsResponse';
    one_time_missions: {
      __typename?: 'OneTimeMissionsList';
      bonus_case_available: number;
      missions_complete_count: number;
      missions_full_count: number;
      missions: Array<{
        __typename?: 'OneTimeMission';
        mission_availability: number;
        mission_current_progress: number;
        mission_description: string;
        mission_full_progress: number;
        mission_id: string;
        mission_reward: number;
        mission_sort: number;
        mission_title: string;
        mission_image?: string | null;
        mission_tags: Array<{
          __typename?: 'MissionTag';
          tag_name: string;
          tag_title: string;
        }>;
        mission_click_type: {
          __typename?: 'MissionClickType';
          button_type: number;
          redirect_link: string;
          redirect_type: number;
        };
      }>;
      bonus_case_data: {
        __typename?: 'BonusCaseData';
        items: Array<{
          __typename?: 'BonusLootboxItem';
          item_hash_name: string;
          item_id: string;
          item_image_link: string;
          item_image_link_case?: string | null;
          item_image_link_strip?: string | null;
          item_quality: string;
          item_rarity: string;
          localized_name?: string | null;
        }>;
        lootbox: {
          __typename?: 'BonusLootbox';
          available_for_user: number;
          lootbox_available_statuses: any;
          lootbox_id: string;
          lootbox_image_link: string;
          lootbox_name: string;
          lootbox_slug: string;
        };
      };
    };
    permanent_missions: {
      __typename?: 'PermanentMissionsList';
      missions_full_count: number;
      missions: Array<{
        __typename?: 'PermanentMission';
        mission_availability: number;
        mission_current_progress: number;
        mission_description: string;
        mission_full_progress: number;
        mission_id: string;
        mission_reward: number;
        mission_sort: number;
        mission_title: string;
        mission_image?: string | null;
        mission_click_type: {
          __typename?: 'MissionClickType';
          button_type: number;
          redirect_link: string;
          redirect_type: number;
        };
        mission_tags: Array<{
          __typename?: 'MissionTag';
          tag_name: string;
          tag_title: string;
        }>;
        mission_timer?: {
          __typename?: 'PermanentMissionTimer';
          launch: boolean;
          timer_value: number;
          max_value: number;
        } | null;
      }>;
    };
  } | null;
};

export type UserMissionProgressMutationVariables = Types.Exact<{
  mission_id?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;

export type UserMissionProgressMutation = {
  __typename?: 'mutation_root';
  ProcessUserPermMissionProgress?: {
    __typename?: 'ProcMissionProgressResponse';
    success: boolean;
    error?: string | null;
    message?: string | null;
    redirect_type: number;
    redirect_url?: string | null;
    completed: boolean;
    mark_as_done: boolean;
    current_progress: number;
  } | null;
};

export const GetMissionsCompletionDocument = gql`
  query GetMissionsCompletion {
    GetBasicMissionsData {
      one_time_missions {
        missions_full_count
        missions_complete_count
        bonus_case_available
      }
    }
  }
`;

/**
 * __useGetMissionsCompletionQuery__
 *
 * To run a query within a React component, call `useGetMissionsCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionsCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionsCompletionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMissionsCompletionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >(GetMissionsCompletionDocument, options);
}
export function useGetMissionsCompletionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >(GetMissionsCompletionDocument, options);
}
export type GetMissionsCompletionQueryHookResult = ReturnType<
  typeof useGetMissionsCompletionQuery
>;
export type GetMissionsCompletionLazyQueryHookResult = ReturnType<
  typeof useGetMissionsCompletionLazyQuery
>;
export type GetMissionsCompletionQueryResult = Apollo.QueryResult<
  GetMissionsCompletionQuery,
  GetMissionsCompletionQueryVariables
>;
export const GetMissionsDocument = gql`
  query GetMissions {
    GetMissionsData {
      one_time_missions {
        bonus_case_available
        missions_complete_count
        missions_full_count
        missions {
          mission_availability
          mission_current_progress
          mission_description
          mission_full_progress
          mission_id
          mission_reward
          mission_sort
          mission_title
          mission_image
          mission_tags {
            tag_name
            tag_title
          }
          mission_click_type {
            button_type
            redirect_link
            redirect_type
          }
        }
        bonus_case_data {
          items {
            item_hash_name
            item_id
            item_image_link
            item_image_link_case
            item_image_link_strip
            item_quality
            item_rarity
            localized_name
          }
          lootbox {
            available_for_user
            lootbox_available_statuses
            lootbox_id
            lootbox_image_link
            lootbox_name
            lootbox_slug
          }
        }
      }
      permanent_missions {
        missions_full_count
        missions {
          mission_availability
          mission_current_progress
          mission_description
          mission_full_progress
          mission_id
          mission_reward
          mission_sort
          mission_title
          mission_image
          mission_click_type {
            button_type
            redirect_link
            redirect_type
          }
          mission_tags {
            tag_name
            tag_title
          }
          mission_timer {
            launch
            timer_value
            max_value
          }
        }
      }
    }
  }
`;

/**
 * __useGetMissionsQuery__
 *
 * To run a query within a React component, call `useGetMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMissionsQuery,
    GetMissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMissionsQuery, GetMissionsQueryVariables>(
    GetMissionsDocument,
    options
  );
}
export function useGetMissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMissionsQuery,
    GetMissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMissionsQuery, GetMissionsQueryVariables>(
    GetMissionsDocument,
    options
  );
}
export type GetMissionsQueryHookResult = ReturnType<typeof useGetMissionsQuery>;
export type GetMissionsLazyQueryHookResult = ReturnType<
  typeof useGetMissionsLazyQuery
>;
export type GetMissionsQueryResult = Apollo.QueryResult<
  GetMissionsQuery,
  GetMissionsQueryVariables
>;
export const UserMissionProgressDocument = gql`
  mutation UserMissionProgress($mission_id: uuid = "") {
    ProcessUserPermMissionProgress(mission_id: $mission_id) {
      success
      error
      message
      redirect_type
      redirect_url
      completed
      mark_as_done
      current_progress
    }
  }
`;
export type UserMissionProgressMutationFn = Apollo.MutationFunction<
  UserMissionProgressMutation,
  UserMissionProgressMutationVariables
>;

/**
 * __useUserMissionProgressMutation__
 *
 * To run a mutation, you first call `useUserMissionProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserMissionProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userMissionProgressMutation, { data, loading, error }] = useUserMissionProgressMutation({
 *   variables: {
 *      mission_id: // value for 'mission_id'
 *   },
 * });
 */
export function useUserMissionProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserMissionProgressMutation,
    UserMissionProgressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserMissionProgressMutation,
    UserMissionProgressMutationVariables
  >(UserMissionProgressDocument, options);
}
export type UserMissionProgressMutationHookResult = ReturnType<
  typeof useUserMissionProgressMutation
>;
export type UserMissionProgressMutationResult =
  Apollo.MutationResult<UserMissionProgressMutation>;
export type UserMissionProgressMutationOptions = Apollo.BaseMutationOptions<
  UserMissionProgressMutation,
  UserMissionProgressMutationVariables
>;
