import React from 'react';
import styles from './PageLoader.module.scss';
import { Loader } from '@/components/Loader/Loader';

export const PageLoader = () => {
  return (
    <div className={styles.loading}>
      <Loader />
    </div>
  );
};
