export const setReturnPageCookie = (
  relativePath: string,
  openPayModal?: boolean
) => {
  const date = new Date();
  date.setDate(date.getDate() + 30);
  const expires = `expires=${date.toUTCString()}`;

  // Удаление всех query-параметров из URL
  let cleanUrl = relativePath.split('?')[0];

  if (openPayModal) {
    cleanUrl += '?modal=payment';
  }

  document.cookie = `return_page=${cleanUrl}; ${expires}; path=/`;
};
