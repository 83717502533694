import { payment_systems_methods, TPmMethod } from '@/configs/payCards';
import { Payment_Methods_View } from '@/types';
import onlineBank from '@/assets/images/pay/onlineBank.webp';
import qiwi from '@/assets/images/pay/qiwi.svg';
import qiwiMobile from '@/assets/images/pay/qiwiMobile.svg';
import sbp from '@/assets/images/pay/sbp.svg';
import sbpMobile from '@/assets/images/pay/sbpMobile.svg';
import visaMir from '@/assets/images/pay/visaMir.svg';
import visaMirMobile from '@/assets/images/pay/visaMirMobile.svg';
import skinsback from '@/assets/images/pay/skinsback.svg';
import yoomoney from '@/assets/images/pay/iomoney.svg';
import yoomoneyMobile from '@/assets/images/pay/iomoneyMobile.svg';
import sberpay from '@/assets/images/pay/sberpay.svg';
import tether from '@/assets/images/pay/tether.svg';
import tetherMobile from '@/assets/images/pay/tetherMobile.svg';

const pmImgMapper = new Map([
  ['onlineBank', onlineBank],
  ['qiwi', qiwi],
  ['qiwiMobile', qiwiMobile],
  ['sbp', sbp],
  ['sbpMobile', sbpMobile],
  ['visaMir', visaMir],
  ['visaMirMobile', visaMirMobile],
  ['skinsback', skinsback],
  ['yoomoney', yoomoney],
  ['yoomoneyMobile', yoomoneyMobile],
  ['sberpay', sberpay],
  ['tether', tether],
  ['tetherMobile', tetherMobile],
]);

export const mapPmMethodsToCards = (
  methods: Payment_Methods_View[] | undefined
): TPmMethod[] => {
  if (methods && methods.length) {
    return methods?.map((method) => {
      const pmCardMethod: TPmMethod = {
        pm_name: method.display_name,
        pm_ps_type: method.ps_type,
        pm_method: method.method,
        pm_sub: method.sub_method,
        pm_currency: method.currency,
        pm_min_value: method.min_amount,
        pm_max_value: method.max_amount,
        pm_pic: method.pic_asset_name
          ? pmImgMapper.get(method.pic_asset_name)
          : method.pic,
        pm_pic_mobile: method.pic_mobile_asset_name
          ? pmImgMapper.get(method.pic_mobile_asset_name)
          : method.pic_mobile,
        commission_percentage: method.commission ?? undefined,
        pm_tab: method.tab_id,
        new_req: true,
        sort: method.sort,
        is_popular: method.is_popular,
        is_p2p: method.is_p2p,
      };
      return pmCardMethod;
    });
  }
  return payment_systems_methods;
};
