import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { captureException } from '@sentry/nextjs';

const vapidKey =
  'BBpKYtTDMvlJgPFV9u_-1zrh4vbzOIXFvVmTojMs35xkptOyNQ1oZrgPJMrbvmYeq8fx-ac89AdX9GBMCERcshw';

const firebaseConfig = {
  apiKey: 'AIzaSyDyynkJnpVbrVoJ5MsOOWhO61wD_KAX4k8',
  authDomain: 'hyper-drop-375808.firebaseapp.com',
  projectId: 'hyper-drop-375808',
  storageBucket: 'hyper-drop-375808.appspot.com',
  messagingSenderId: '636136007831',
  appId: '1:636136007831:web:6cddd5f21e1bdc3eb921d2',
  measurementId: 'G-CJC17JWGGG',
};

let messagingInstance;
const initFirebase = () => {
  const app = initializeApp(firebaseConfig);
  messagingInstance = getMessaging(app);
};

export const getMessagingInstance = () => {
  if (!messagingInstance) {
    initFirebase();
  }
  return messagingInstance;
};

const getFCMToken = async () => {
  const messaging = getMessagingInstance();
  try {
    const tokenInLocalStorage = localStorage.getItem('fcm_token');
    if (tokenInLocalStorage !== null) {
      return { success: true, token: tokenInLocalStorage };
    }

    const isSupportedBrowser = await isSupported();
    if (!isSupportedBrowser) {
      return {
        isSupportedBrowserSuccess: false,
        error: 'Уведомления не поддерживаются в этом браузере.',
      };
    }

    const status = await Notification.requestPermission();
    if (status === 'granted') {
      const fcm_token = await getToken(messaging, { vapidKey });
      if (fcm_token) {
        localStorage.setItem('fcm_token', fcm_token);
        return { success: true, token: fcm_token };
      }
    }
    return { success: false, error: 'Не удалось получить токен FCM.' };
  } catch (error) {
    captureException(error);

    return {
      success: false,
      error: 'Во время инициализации FCM произошла ошибка.',
    };
  }
};

const firebaseCloudMessaging = {
  init: async () => {
    const messaging = getMessagingInstance();
    return await getFCMToken(messaging, vapidKey);
  },
};

const FirebaseMessagingContext = createContext();

export const useFirebaseMessagingContext = () =>
  useContext(FirebaseMessagingContext);

export const FirebaseMessagingProvider = ({ children }) => {
  const [messagingInstance, setMessagingInstance] = useState(null);

  useEffect(() => {
    initFirebase();
    setMessagingInstance(getMessagingInstance());
  }, []);

  return (
    <FirebaseMessagingContext.Provider
      value={{ messagingInstance, setMessagingInstance }}
    >
      {children}
    </FirebaseMessagingContext.Provider>
  );
};

export { firebaseCloudMessaging };
