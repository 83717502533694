import Image from 'next/image';
import styles from './PromoBlock.module.scss';
import ArrowRightIcon from '@/assets/icons/pay/ArrowRightIcon';
import OvalButton from '@/components/styled/CustomButtons/OvalButton/OvalButton';
import promo from '@/assets/icons/pay/promo.svg';
import { theme } from '@/styles/theme';
import { useMediaQuery } from '@mui/material';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import ModalWritePromo from './ModalWritePromo/ModalWritePromo';

const DynamicPayModal = dynamic(
  () => import('@/components/Pay/PayModal/PayModal'),
  {
    ssr: false,
  }
);

export const PromoBlock = () => {
  const [openModalPromo, setOpenModalPromo] = useState(false);
  const [openPay, setOpenPay] = useState(false);

  const mobile = useMediaQuery(theme.breakpoints.down(856));

  const handleOpenPay = () => {
    setOpenModalPromo(false);
    setOpenPay(true);
  };

  return (
    <>
      <div className={styles.promoCon} onClick={() => setOpenModalPromo(true)}>
        <div className={styles.promoImg}>
          <Image src={promo} alt="Promocode" />
        </div>
        <p className={styles.promoTxt}>
          У меня есть <span style={{ color: '#D3F85A' }}>ПРОМОКОД</span>
        </p>
        {!mobile && (
          <OvalButton
            type="submit"
            small
            id="arrow"
            icon={<ArrowRightIcon />}
          />
        )}
      </div>
      <ModalWritePromo
        open={openModalPromo}
        close={() => setOpenModalPromo(false)}
        openPay={handleOpenPay}
      />
      <DynamicPayModal open={openPay} close={() => setOpenPay(false)} />
    </>
  );
};
