import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  bpchar: any;
  date: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  seed_float: any;
  smallint: any;
  timestamp: string;
  timestamptz: any;
  uuid: string;
};

export type AccOpenOutput = {
  __typename?: 'AccOpenOutput';
  available_status: Scalars['Int'];
  available_status_for_sale?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<OpenOutput>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AddOfferDto = {
  offer_name?: InputMaybe<Scalars['String']>;
  offer_type: Scalars['String'];
  promocode: Scalars['String'];
  promocode_type?: InputMaybe<Scalars['String']>;
};

export type AddOfferResponse = {
  __typename?: 'AddOfferResponse';
  error?: Maybe<Scalars['String']>;
  offer_info?: Maybe<OfferInfo>;
  success: Scalars['Boolean'];
};

export type AdminUpdateUserMailAlertsActivityInput = {
  is_active: Scalars['Boolean'];
  user_id: Scalars['String'];
};

export type AlternativeBaseItem = {
  __typename?: 'AlternativeBaseItem';
  item_hash_name: Scalars['String'];
  item_image_link: Scalars['String'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Int'];
  localized_name?: Maybe<Scalars['String']>;
  output_id?: Maybe<Scalars['uuid']>;
  user_item_id: Scalars['uuid'];
};

export type AlternativeItem = {
  __typename?: 'AlternativeItem';
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Int'];
  localized_name?: Maybe<Scalars['String']>;
  output_id?: Maybe<Scalars['uuid']>;
};

export type AlternativeItemInput = {
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Int'];
  localized_name?: InputMaybe<Scalars['String']>;
  output_id?: InputMaybe<Scalars['uuid']>;
};

export type Answer = {
  __typename?: 'Answer';
  message: Scalars['String'];
};

export type AnswerBaseModel = {
  __typename?: 'AnswerBaseModel';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type BackgroundServiceAnswerBaseModel = {
  __typename?: 'BackgroundServiceAnswerBaseModel';
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type BaseItemDetail = {
  __typename?: 'BaseItemDetail';
  price: Scalars['Int'];
  quality: Scalars['String'];
  rarity: Scalars['String'];
};

export type BaseLootboxDetail = {
  __typename?: 'BaseLootboxDetail';
  items_view?: Maybe<Array<Maybe<LootboxItemsView>>>;
  slug: Scalars['String'];
};

export type BasicMissionsResponse = {
  __typename?: 'BasicMissionsResponse';
  one_time_missions: BasicOneTimeMissionsList;
};

export type BasicOneTimeMissionsList = {
  __typename?: 'BasicOneTimeMissionsList';
  bonus_case_available: Scalars['Int'];
  missions_complete_count: Scalars['Int'];
  missions_full_count: Scalars['Int'];
};

export type BonusCaseData = {
  __typename?: 'BonusCaseData';
  items: Array<BonusLootboxItem>;
  lootbox: BonusLootbox;
};

export type BonusLootbox = {
  __typename?: 'BonusLootbox';
  available_for_user: Scalars['Int'];
  lootbox_available_statuses: Scalars['jsonb'];
  lootbox_id: Scalars['uuid'];
  lootbox_image_link: Scalars['String'];
  lootbox_name: Scalars['String'];
  lootbox_slug: Scalars['String'];
};

export type BonusLootboxItem = {
  __typename?: 'BonusLootboxItem';
  item_drop_chance: Scalars['Float'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_image_link_case?: Maybe<Scalars['String']>;
  item_image_link_strip?: Maybe<Scalars['String']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Float'];
  localized_name?: Maybe<Scalars['String']>;
};

export type BonusWheelGetBonusSkinAnswer = {
  __typename?: 'BonusWheelGetBonusSkinAnswer';
  button_action?: Maybe<Scalars['String']>;
  button_link?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  skin_data?: Maybe<BonusWheelGetBonusSkinAnswerSkinData>;
  success: Scalars['Boolean'];
};

export type BonusWheelGetBonusSkinAnswerSkinData = {
  __typename?: 'BonusWheelGetBonusSkinAnswerSkinData';
  item_hash_name: Scalars['String'];
  item_image_link_formated: Scalars['String'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['String'];
  localized_name: Scalars['String'];
};

export type BonusWheelPageDataAnswer = {
  __typename?: 'BonusWheelPageDataAnswer';
  status: Scalars['Int'];
  success: Scalars['Boolean'];
  top_bar?: Maybe<BonusWheelPageDataTopBar>;
  wheel_data?: Maybe<BonusWheelPageDataWheelData>;
};

export type BonusWheelPageDataAnswerTemp = {
  __typename?: 'BonusWheelPageDataAnswerTemp';
  status: Scalars['Int'];
  success: Scalars['Boolean'];
  timer?: Maybe<Scalars['Int']>;
  wheel_data?: Maybe<BonusWheelPageDataWheelData>;
};

export type BonusWheelPageDataTopBar = {
  __typename?: 'BonusWheelPageDataTopBar';
  button_action: Scalars['String'];
  button_link?: Maybe<Scalars['String']>;
  button_text: Scalars['String'];
  description: Scalars['String'];
  offer_image_link?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['Int']>;
};

export type BonusWheelPageDataWheelBonusData = {
  __typename?: 'BonusWheelPageDataWheelBonusData';
  bonus_description: Scalars['String'];
  bonus_id: Scalars['uuid'];
  bonus_image: Scalars['String'];
  bonus_name: Scalars['String'];
};

export type BonusWheelPageDataWheelData = {
  __typename?: 'BonusWheelPageDataWheelData';
  available_for_user: Scalars['Boolean'];
  bonuses: Array<BonusWheelPageDataWheelBonusData>;
  wheel_title: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type Booster = {
  __typename?: 'Booster';
  booster_id: Scalars['uuid'];
  booster_image_link: Scalars['String'];
  cost: Scalars['Int'];
  count: Scalars['Int'];
  score: Scalars['Int'];
};

export type Boosters = {
  __typename?: 'Boosters';
  balance?: Maybe<Scalars['Float']>;
  boosters?: Maybe<Array<Maybe<Booster>>>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type BuyInput = {
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id: Scalars['bigint'];
  item_price: Scalars['Int'];
  item_start_price?: InputMaybe<Scalars['Float']>;
  user_id: Scalars['String'];
  user_partner: Scalars['bigint'];
  user_token: Scalars['String'];
};

export type BuyOutput = {
  __typename?: 'BuyOutput';
  alt_price?: Maybe<Scalars['Int']>;
  alternative_items: Array<AlternativeItem>;
  base_item?: Maybe<AlternativeBaseItem>;
  cust_id?: Maybe<Scalars['uuid']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  internal_error?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['uuid']>;
  output_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
  use_alts?: Maybe<Scalars['Boolean']>;
};

export type BuyingBoostersAnswer = {
  __typename?: 'BuyingBoostersAnswer';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CalculateReferralBalanceResponse = {
  __typename?: 'CalculateReferralBalanceResponse';
  message?: Maybe<Scalars['String']>;
};

export type CheckPromocodeOutput = {
  __typename?: 'CheckPromocodeOutput';
  error?: Maybe<Scalars['String']>;
  max_amount?: Maybe<Scalars['Int']>;
  min_deposit?: Maybe<Scalars['Int']>;
  reward_type?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  type?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type ChoiceValidationResponse = {
  __typename?: 'ChoiceValidationResponse';
  current_reward_count?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  is_correct_choice: Scalars['Boolean'];
  losing_item?: Maybe<ComparisonItem>;
  success: Scalars['Boolean'];
  total_reward_count?: Maybe<Scalars['Int']>;
  winning_item?: Maybe<ComparisonItem>;
};

export type ClosestGiveaway = {
  __typename?: 'ClosestGiveaway';
  end_date?: Maybe<Scalars['timestamp']>;
  giveaway_id: Scalars['uuid'];
  item?: Maybe<Item>;
  start_date?: Maybe<Scalars['timestamp']>;
  total_seconds?: Maybe<Scalars['Int']>;
};

export type ClosestGiveawayResponse = {
  __typename?: 'ClosestGiveawayResponse';
  giveaway?: Maybe<ClosestGiveaway>;
};

export type CoinBoosterTicketOfferView = {
  __typename?: 'CoinBoosterTicketOfferView';
  available_quantity?: Maybe<Scalars['Int']>;
  badge?: Maybe<Scalars['String']>;
  description?: Maybe<Array<Maybe<Scalars['String']>>>;
  discount: Scalars['Int'];
  end_datetime?: Maybe<Scalars['timestamp']>;
  image?: Maybe<Scalars['String']>;
  is_combo: Scalars['Boolean'];
  offer_id: Scalars['String'];
  offer_type: Scalars['String'];
  old_price?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  start_datetime?: Maybe<Scalars['timestamp']>;
  title?: Maybe<Scalars['String']>;
};

export type ComparisonItem = {
  __typename?: 'ComparisonItem';
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_min_price: Scalars['Int'];
  item_name: Scalars['String'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
};

export type CompetitionStatusModel = {
  __typename?: 'CompetitionStatusModel';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  team_one?: Maybe<StatusTeam>;
  team_two?: Maybe<StatusTeam>;
  total_prize?: Maybe<Scalars['Int']>;
  user_team_id?: Maybe<Scalars['Int']>;
};

export type CreateUserPaymentTransactionInput = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
  pm: Scalars['String'];
  pm_sub: Scalars['String'];
  promocode?: InputMaybe<Scalars['String']>;
  ps: Scalars['Int'];
};

export type CreateUserPaymentTransactionOutput = {
  __typename?: 'CreateUserPaymentTransactionOutput';
  error?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CurrentOnlineOutput = {
  __typename?: 'CurrentOnlineOutput';
  bots?: Maybe<Scalars['Int']>;
  real?: Maybe<Scalars['Int']>;
};

export type DailyCoinsClaimAnswer = {
  __typename?: 'DailyCoinsClaimAnswer';
  end_date?: Maybe<Scalars['timestamp']>;
  error?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamp']>;
  success: Scalars['Boolean'];
  total_seconds?: Maybe<Scalars['Int']>;
};

export type DataInfo = {
  __typename?: 'DataInfo';
  causer?: Maybe<Scalars['String']>;
  classid?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  for?: Maybe<Scalars['String']>;
  instance?: Maybe<Scalars['Float']>;
  item_id?: Maybe<Scalars['Float']>;
  market_hash_name?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Float']>;
  send_until?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
  trade_id?: Maybe<Scalars['String']>;
};

export type DemoItemOutput = {
  __typename?: 'DemoItemOutput';
  item_hash_name: Scalars['String'];
  item_image_link: Scalars['String'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Float'];
};

export type DemoItemsInLootbox = {
  __typename?: 'DemoItemsInLootbox';
  item_hash_name?: Maybe<Scalars['String']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
};

export type DemoLootboxDataOutput = {
  __typename?: 'DemoLootboxDataOutput';
  lootboxes_by_pk?: Maybe<DemoLootboxDataOutputData>;
};

export type DemoLootboxDataOutputData = {
  __typename?: 'DemoLootboxDataOutputData';
  items_in_lootbox_views?: Maybe<Array<Maybe<DemoItemsInLootbox>>>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
};

export type EditUserReferalLinkOutput = {
  __typename?: 'EditUserReferalLinkOutput';
  error?: Maybe<Scalars['String']>;
  new_code?: Maybe<Scalars['String']>;
  new_link?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type FakeOutput = {
  __typename?: 'FakeOutput';
  data?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

export type Force1PayCallbackInput = {
  account: Scalars['String'];
  currency: Scalars['String'];
  init_time: Scalars['String'];
  merchant_price: Scalars['String'];
  order_id: Scalars['String'];
  pan: Scalars['String'];
  payment_type: Scalars['String'];
  project_id: Scalars['String'];
  sign: Scalars['String'];
  status: Scalars['String'];
  status_description: Scalars['String'];
  status_time: Scalars['String'];
  user_data: Scalars['String'];
  user_price: Scalars['String'];
};

export type FreeLootbox = {
  __typename?: 'FreeLootbox';
  available_for_user: Scalars['Int'];
  coin_price: Scalars['Int'];
  lootbox_available_amount: Scalars['Int'];
  lootbox_available_statuses?: Maybe<Scalars['jsonb']>;
  lootbox_id: Scalars['uuid'];
  lootbox_image_link: Scalars['String'];
  lootbox_name: Scalars['String'];
  lootbox_slug: Scalars['String'];
  lootbox_status?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type FreeLootboxInfo = {
  __typename?: 'FreeLootboxInfo';
  available_for_user: Scalars['Int'];
};

export type GameData = {
  chosen_item_id: Scalars['uuid'];
  game_id: Scalars['uuid'];
};

export type GameItemDetail = {
  __typename?: 'GameItemDetail';
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_name: Scalars['String'];
};

export type GameItems = {
  __typename?: 'GameItems';
  compare_pair: Array<Maybe<GameItemDetail>>;
  current_reward_count?: Maybe<Scalars['Int']>;
  extra_items: Array<Maybe<GameItemDetail>>;
  game_id?: Maybe<Scalars['uuid']>;
  total_reward_count?: Maybe<Scalars['Int']>;
};

export type GameItemsResponse = {
  __typename?: 'GameItemsResponse';
  error?: Maybe<Scalars['String']>;
  game_items?: Maybe<GameItems>;
  success: Scalars['Boolean'];
};

export type GetItemsForUpgradeAnswer = {
  __typename?: 'GetItemsForUpgradeAnswer';
  current_page: Scalars['Int'];
  items: Array<ItemForUpgrade>;
  max_item_price: Scalars['Int'];
  min_item_price: Scalars['Int'];
  pages_count: Scalars['Int'];
  per_page: Scalars['Int'];
};

export type GetItemsInBonusLootboxOutput = {
  __typename?: 'GetItemsInBonusLootboxOutput';
  drop_strip: Array<ItemInDropStripOfLootbox>;
  items: Array<BonusLootboxItem>;
  lootbox: BonusLootbox;
};

export type GetItemsInFreeLootboxOutput = {
  __typename?: 'GetItemsInFreeLootboxOutput';
  drop_strip: Array<ItemInDropStripOfLootbox>;
  items: Array<ItemsInFreeLootboxOutput>;
  lootbox: FreeLootbox;
};

export type GetItemsInLootboxOutput = {
  __typename?: 'GetItemsInLootboxOutput';
  drop_strip: Array<ItemInDropStripOfLootbox>;
  items: Array<ItemsInLootboxOutput>;
  lootbox: LootboxView;
};

export type GetItemsListAllInput = {
  names: Array<Scalars['String']>;
};

export type GetTokensQueueOutput = {
  __typename?: 'GetTokensQueueOutput';
  token_id?: Maybe<Scalars['uuid']>;
  token_queue?: Maybe<Scalars['Int']>;
  token_value?: Maybe<Scalars['String']>;
};

export type GetUserReferalPageDataOutput = {
  __typename?: 'GetUserReferalPageDataOutput';
  current_bonus_percent: Scalars['Int'];
  current_code: Scalars['String'];
  current_deposits: Scalars['Int'];
  current_level: Scalars['Int'];
  current_link: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  levels: Array<UserReferalsLevel>;
  min_balance_to_withdraw: Scalars['Int'];
  next_level?: Maybe<Scalars['Int']>;
  next_level_deposits?: Maybe<Scalars['Int']>;
  referal_balance: Scalars['Int'];
  referal_bonus_amount: Scalars['Int'];
  referal_bonus_min_deposit: Scalars['Int'];
  referal_bonus_type: Scalars['String'];
  referals_count: Scalars['Int'];
  referals_month_count: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type GiveawayGetItem = {
  __typename?: 'GiveawayGetItem';
  error?: Maybe<Scalars['String']>;
  giveaway_id?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type GiveawayItem = {
  __typename?: 'GiveawayItem';
  active: Scalars['Boolean'];
  end_date: Scalars['String'];
  fixed_price?: Maybe<Scalars['Float']>;
  giveaway_id: Scalars['String'];
  item_claimed?: Maybe<Scalars['Boolean']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id: Scalars['String'];
  item_image_link?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  start_date: Scalars['String'];
  status: GiveawayStatus;
  type?: Maybe<Scalars['String']>;
  winner_id?: Maybe<Scalars['String']>;
};

export type GiveawayParticipate = {
  __typename?: 'GiveawayParticipate';
  error?: Maybe<Scalars['String']>;
  giveaway_id?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type GiveawayPrize = {
  __typename?: 'GiveawayPrize';
  item_id: Scalars['String'];
  item_image_link?: Maybe<Scalars['String']>;
  item_name?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Float']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
};

export enum GiveawayStatus {
  ItemClaimed = 'ITEM_CLAIMED',
  Lose = 'LOSE',
  NotClaimed = 'NOT_CLAIMED'
}

export type GivewayMembers = {
  __typename?: 'GivewayMembers';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  giveaway_id: Scalars['String'];
  persona_name: Scalars['String'];
  user_id: Scalars['String'];
};

export type GivewayRibbon = {
  __typename?: 'GivewayRibbon';
  current_deposit?: Maybe<Scalars['Float']>;
  end_date?: Maybe<Scalars['timestamp']>;
  giveaway_id: Scalars['String'];
  item_claimed?: Maybe<Scalars['Boolean']>;
  item_image_link: Scalars['String'];
  item_name: Scalars['String'];
  item_price: Scalars['Float'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  minimal_deposit?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['timestamp']>;
  total_seconds: Scalars['Int'];
  user_count?: Maybe<Scalars['Int']>;
  user_participates?: Maybe<Scalars['Boolean']>;
  winner_avatar?: Maybe<Scalars['String']>;
  winner_id?: Maybe<Scalars['String']>;
  winner_name?: Maybe<Scalars['String']>;
};

export type GivewaysInfo = {
  __typename?: 'GivewaysInfo';
  current_deposit?: Maybe<Scalars['Float']>;
  current_users?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['timestamp']>;
  error?: Maybe<Scalars['String']>;
  giveaway_id?: Maybe<Scalars['String']>;
  item_claimed?: Maybe<Scalars['Boolean']>;
  item_id?: Maybe<Scalars['String']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_name?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Float']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  limit_users?: Maybe<Scalars['Int']>;
  minimal_deposit?: Maybe<Scalars['Float']>;
  minimal_users?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  total_seconds?: Maybe<Scalars['Int']>;
  user_participates?: Maybe<Scalars['Boolean']>;
  winner_avatar?: Maybe<Scalars['String']>;
  winner_id?: Maybe<Scalars['String']>;
  winner_name?: Maybe<Scalars['String']>;
};

export type HelloBonusAnswer = {
  __typename?: 'HelloBonusAnswer';
  bonus_available: Scalars['Boolean'];
  bonus_data?: Maybe<HelloBonusSubData>;
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_time: Scalars['Int'];
  bonus_type: Scalars['Int'];
};

export type HelloBonusSubData = {
  __typename?: 'HelloBonusSubData';
  coin_amount?: Maybe<Scalars['Int']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type Item = {
  __typename?: 'Item';
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_name?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Float']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
};

export type ItemForUpgrade = {
  __typename?: 'ItemForUpgrade';
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_image_link_formated: Scalars['String'];
  item_price: Scalars['Int'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  ru_name: Scalars['String'];
};

export type ItemInDropStripOfLootbox = {
  __typename?: 'ItemInDropStripOFLootbox';
  drop_hour_text: Scalars['String'];
  item_hash_name: Scalars['String'];
  item_image_link_formated: Scalars['String'];
  item_quality: Scalars['String'];
  item_rariry: Scalars['String'];
  item_start_price: Scalars['Int'];
  localized_name?: Maybe<Scalars['String']>;
  lootbox_id: Scalars['uuid'];
  lootbox_image_link: Scalars['String'];
  lootbox_name: Scalars['String'];
  lootbox_slug: Scalars['String'];
  user_avatar: Scalars['String'];
  user_id: Scalars['uuid'];
  user_name: Scalars['String'];
};

export type ItemInInventory = {
  __typename?: 'ItemInInventory';
  item_custom_image_link?: Maybe<Scalars['String']>;
  item_frozen?: Maybe<Scalars['Boolean']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_status?: Maybe<Scalars['Int']>;
  item_visible_name?: Maybe<Scalars['String']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

export type ItemOnSale = {
  __typename?: 'ItemOnSale';
  class: Scalars['bigint'];
  id: Scalars['bigint'];
  instance: Scalars['bigint'];
  market_hash_name: Scalars['String'];
  price: Scalars['Int'];
};

export type ItemSellData = {
  sell_all?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  user_item_ids?: InputMaybe<Array<Scalars['uuid']>>;
};

export type ItemSendData = {
  alternative_item?: InputMaybe<AlternativeItemInput>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

export type ItemsForUpgradeFiltersDto = {
  item_price: Scalars['Int'];
  max_price?: InputMaybe<Scalars['Int']>;
  min_price?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Scalars['String']>;
  order_direction?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  quality?: InputMaybe<Array<Scalars['String']>>;
  rarity?: InputMaybe<Array<Scalars['String']>>;
  search_string?: InputMaybe<Scalars['String']>;
  weapons?: InputMaybe<Array<Scalars['String']>>;
};

export type ItemsInFreeLootboxOutput = {
  __typename?: 'ItemsInFreeLootboxOutput';
  item_drop_chance: Scalars['Float'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_image_link_case?: Maybe<Scalars['String']>;
  item_image_link_strip?: Maybe<Scalars['String']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Float'];
  localized_name?: Maybe<Scalars['String']>;
};

export type ItemsInLootboxOutput = {
  __typename?: 'ItemsInLootboxOutput';
  item_drop_chance: Scalars['Float'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['String'];
  item_image_link: Scalars['String'];
  item_image_link_case?: Maybe<Scalars['String']>;
  item_image_link_strip?: Maybe<Scalars['String']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Float'];
  localized_name?: Maybe<Scalars['String']>;
  sort: Scalars['Int'];
};

export type ItemsOutputMarketData = {
  __typename?: 'ItemsOutputMarketData';
  causer?: Maybe<Scalars['String']>;
  classid?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  instance?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['String']>;
  market_hash_name?: Maybe<Scalars['String']>;
  paid: Scalars['Float'];
  send_until?: Maybe<Scalars['String']>;
  stage: Scalars['Int'];
  time?: Maybe<Scalars['String']>;
  trade_id?: Maybe<Scalars['String']>;
  user_for?: Maybe<Scalars['String']>;
};

export type ItemsOutputStatusInput = {
  custom_ids: Array<Scalars['String']>;
};

export type ItemsOutputStatusOutput = {
  __typename?: 'ItemsOutputStatusOutput';
  market_data: ItemsOutputMarketData;
  order_custom_id: Scalars['String'];
  stage: Scalars['Int'];
  token_id?: Maybe<Scalars['String']>;
};

export type Localization = {
  __typename?: 'Localization';
  item_name_localized: Scalars['String'];
  locale: Scalars['String'];
};

export type LootboxInfoBySlug = {
  __typename?: 'LootboxInfoBySlug';
  available_for_user: Scalars['Int'];
  drop_strip: Array<ItemInDropStripOfLootbox>;
  lootbox_open_count: Scalars['Int'];
  lootbox_open_max_count?: Maybe<Scalars['Int']>;
};

export type LootboxInput = {
  count?: InputMaybe<Scalars['Int']>;
  fast_roll?: InputMaybe<Scalars['Boolean']>;
  lootbox_id: Scalars['uuid'];
};

export type LootboxItemsView = {
  __typename?: 'LootboxItemsView';
  image: Scalars['String'];
};

export type LootboxOfferView = {
  __typename?: 'LootboxOfferView';
  available_quantity?: Maybe<Scalars['Int']>;
  badge?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discount: Scalars['Int'];
  end_datetime?: Maybe<Scalars['timestamp']>;
  image?: Maybe<Scalars['String']>;
  is_combo: Scalars['Boolean'];
  is_mistery: Scalars['Boolean'];
  lootbox_detail?: Maybe<BaseLootboxDetail>;
  offer_id: Scalars['String'];
  offer_type: Scalars['String'];
  old_price?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  start_datetime?: Maybe<Scalars['timestamp']>;
  title?: Maybe<Scalars['String']>;
};

/** columns and relationships of "LootboxOpensCounts" */
export type LootboxOpensCounts = {
  __typename?: 'LootboxOpensCounts';
  lootbox_name?: Maybe<Scalars['String']>;
  opens?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "LootboxOpensCounts" */
export type LootboxOpensCounts_Aggregate = {
  __typename?: 'LootboxOpensCounts_aggregate';
  aggregate?: Maybe<LootboxOpensCounts_Aggregate_Fields>;
  nodes: Array<LootboxOpensCounts>;
};

/** aggregate fields of "LootboxOpensCounts" */
export type LootboxOpensCounts_Aggregate_Fields = {
  __typename?: 'LootboxOpensCounts_aggregate_fields';
  avg?: Maybe<LootboxOpensCounts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<LootboxOpensCounts_Max_Fields>;
  min?: Maybe<LootboxOpensCounts_Min_Fields>;
  stddev?: Maybe<LootboxOpensCounts_Stddev_Fields>;
  stddev_pop?: Maybe<LootboxOpensCounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<LootboxOpensCounts_Stddev_Samp_Fields>;
  sum?: Maybe<LootboxOpensCounts_Sum_Fields>;
  var_pop?: Maybe<LootboxOpensCounts_Var_Pop_Fields>;
  var_samp?: Maybe<LootboxOpensCounts_Var_Samp_Fields>;
  variance?: Maybe<LootboxOpensCounts_Variance_Fields>;
};


/** aggregate fields of "LootboxOpensCounts" */
export type LootboxOpensCounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LootboxOpensCounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type LootboxOpensCounts_Avg_Fields = {
  __typename?: 'LootboxOpensCounts_avg_fields';
  opens?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "LootboxOpensCounts". All fields are combined with a logical 'AND'. */
export type LootboxOpensCounts_Bool_Exp = {
  _and?: InputMaybe<Array<LootboxOpensCounts_Bool_Exp>>;
  _not?: InputMaybe<LootboxOpensCounts_Bool_Exp>;
  _or?: InputMaybe<Array<LootboxOpensCounts_Bool_Exp>>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  opens?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type LootboxOpensCounts_Max_Fields = {
  __typename?: 'LootboxOpensCounts_max_fields';
  lootbox_name?: Maybe<Scalars['String']>;
  opens?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type LootboxOpensCounts_Min_Fields = {
  __typename?: 'LootboxOpensCounts_min_fields';
  lootbox_name?: Maybe<Scalars['String']>;
  opens?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "LootboxOpensCounts". */
export type LootboxOpensCounts_Order_By = {
  lootbox_name?: InputMaybe<Order_By>;
  opens?: InputMaybe<Order_By>;
};

/** select columns of table "LootboxOpensCounts" */
export enum LootboxOpensCounts_Select_Column {
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  Opens = 'opens'
}

/** aggregate stddev on columns */
export type LootboxOpensCounts_Stddev_Fields = {
  __typename?: 'LootboxOpensCounts_stddev_fields';
  opens?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type LootboxOpensCounts_Stddev_Pop_Fields = {
  __typename?: 'LootboxOpensCounts_stddev_pop_fields';
  opens?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type LootboxOpensCounts_Stddev_Samp_Fields = {
  __typename?: 'LootboxOpensCounts_stddev_samp_fields';
  opens?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "LootboxOpensCounts" */
export type LootboxOpensCounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: LootboxOpensCounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LootboxOpensCounts_Stream_Cursor_Value_Input = {
  lootbox_name?: InputMaybe<Scalars['String']>;
  opens?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type LootboxOpensCounts_Sum_Fields = {
  __typename?: 'LootboxOpensCounts_sum_fields';
  opens?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type LootboxOpensCounts_Var_Pop_Fields = {
  __typename?: 'LootboxOpensCounts_var_pop_fields';
  opens?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type LootboxOpensCounts_Var_Samp_Fields = {
  __typename?: 'LootboxOpensCounts_var_samp_fields';
  opens?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type LootboxOpensCounts_Variance_Fields = {
  __typename?: 'LootboxOpensCounts_variance_fields';
  opens?: Maybe<Scalars['Float']>;
};

export type LootboxView = {
  __typename?: 'LootboxView';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  available_for_user: Scalars['Int'];
  badge?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  lootbox_available_statuses?: Maybe<Scalars['jsonb']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_current_id?: Maybe<Scalars['uuid']>;
  lootbox_discount?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_open_count?: Maybe<Scalars['Int']>;
  lootbox_open_max_count?: Maybe<Scalars['Int']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_status?: Maybe<Scalars['Boolean']>;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};

export type MainPageLootboxFilterDto = {
  filter_skin_name?: InputMaybe<Scalars['String']>;
  filter_weapon_types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  max_price?: InputMaybe<Scalars['Float']>;
  min_price?: InputMaybe<Scalars['Float']>;
};

export type MainPageLootboxesAnswerModel = {
  __typename?: 'MainPageLootboxesAnswerModel';
  filter_categories: Array<MpLootboxesFilterCategoryModel>;
  lootbox_categories: Array<MpLootboxesCategoryModel>;
};

export type MainReportInput = {
  date_end?: InputMaybe<Scalars['timestamp']>;
  date_start?: InputMaybe<Scalars['timestamp']>;
  for_day?: InputMaybe<Scalars['Boolean']>;
};

export type ManageUserFirebaseTokenActivityAnswer = {
  __typename?: 'ManageUserFirebaseTokenActivityAnswer';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  wp_model?: Maybe<UserFirebaseWebpushSettingsModel>;
};

export type ManageUserFirebaseTokenActivityDto = {
  is_active: Scalars['Boolean'];
  push_token: Scalars['String'];
  user_agent?: InputMaybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type ManageUserMailAlertsActivityAnswer = {
  __typename?: 'ManageUserMailAlertsActivityAnswer';
  current_status: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  mg_response_status?: Maybe<Scalars['String']>;
  mg_response_text?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ManageUserMailAlertsActivityDto = {
  is_active: Scalars['Boolean'];
  user_id: Scalars['String'];
};

export type MassGeneratorOutput = {
  __typename?: 'MassGeneratorOutput';
  generator_answers: Scalars['String'];
};

export type MetasInput = {
  global_key?: InputMaybe<Scalars['String']>;
  sub_key?: InputMaybe<Scalars['String']>;
};

export type MetasOutput = {
  __typename?: 'MetasOutput';
  metas?: Maybe<Scalars['json']>;
};

export type MissionClickType = {
  __typename?: 'MissionClickType';
  button_type: Scalars['Int'];
  redirect_link: Scalars['String'];
  redirect_type: Scalars['Int'];
};

export type MissionTag = {
  __typename?: 'MissionTag';
  tag_name: Scalars['String'];
  tag_title: Scalars['String'];
};

export type MissionsResponse = {
  __typename?: 'MissionsResponse';
  one_time_missions: OneTimeMissionsList;
  permanent_missions: PermanentMissionsList;
};

export type MonthlyWinners = {
  __typename?: 'MonthlyWinners';
  avatar?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamp']>;
  giveaway_id?: Maybe<Scalars['uuid']>;
  item?: Maybe<Item>;
  participation_count?: Maybe<Scalars['Int']>;
  persona_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['String']>;
  won_count?: Maybe<Scalars['Int']>;
};

export type MonthlyWinnersResponse = {
  __typename?: 'MonthlyWinnersResponse';
  winners?: Maybe<Array<Maybe<MonthlyWinners>>>;
};

export type MpLootboxesCategoryModel = {
  __typename?: 'MpLootboxesCategoryModel';
  anchor: Scalars['String'];
  info_text?: Maybe<Scalars['String']>;
  lootboxes: Array<MpLootboxesLootboxModel>;
  title: Scalars['String'];
};

export type MpLootboxesFilterCategoryModel = {
  __typename?: 'MpLootboxesFilterCategoryModel';
  anchor: Scalars['String'];
  enabled: Scalars['Boolean'];
  title: Scalars['String'];
};

export type MpLootboxesFilterModel = {
  __typename?: 'MpLootboxesFilterModel';
  filter_skin_name: Scalars['String'];
  filter_weapon_types: Array<MpLootboxesFilterWeaponTypeModel>;
  max_price: Scalars['Float'];
  min_price: Scalars['Float'];
};

export type MpLootboxesFilterWeaponTypeModel = {
  __typename?: 'MpLootboxesFilterWeaponTypeModel';
  name: Scalars['String'];
  param: Scalars['String'];
};

export type MpLootboxesLootboxModel = {
  __typename?: 'MpLootboxesLootboxModel';
  badges?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_link: Scalars['String'];
  name: Scalars['String'];
  open_count: Scalars['Int'];
  open_max_count?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  price_type: Scalars['String'];
  slug: Scalars['String'];
  url_prefix: Scalars['String'];
  weapon_types: Scalars['String'];
};

export type OfferAccountInfo = {
  __typename?: 'OfferAccountInfo';
  balance?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  today_total_amount?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Int']>;
};

export type OfferInfo = {
  __typename?: 'OfferInfo';
  bonus_type?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  offer_id?: Maybe<Scalars['String']>;
  offer_name?: Maybe<Scalars['String']>;
  offer_type?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
};

export type OfferResponse = {
  __typename?: 'OfferResponse';
  error?: Maybe<Scalars['String']>;
  links?: Maybe<Array<Maybe<OfferInfo>>>;
  promocodes?: Maybe<Array<Maybe<OfferInfo>>>;
  success: Scalars['Boolean'];
};

export type OneTimeMission = {
  __typename?: 'OneTimeMission';
  mission_availability: Scalars['Int'];
  mission_click_type: MissionClickType;
  mission_current_progress: Scalars['Int'];
  mission_description: Scalars['String'];
  mission_full_progress: Scalars['Int'];
  mission_id: Scalars['uuid'];
  mission_image?: Maybe<Scalars['String']>;
  mission_reward: Scalars['Int'];
  mission_sort: Scalars['Int'];
  mission_tags: Array<MissionTag>;
  mission_title: Scalars['String'];
};

export type OneTimeMissionsList = {
  __typename?: 'OneTimeMissionsList';
  bonus_case_available: Scalars['Int'];
  bonus_case_data: BonusCaseData;
  missions: Array<OneTimeMission>;
  missions_complete_count: Scalars['Int'];
  missions_full_count: Scalars['Int'];
};

export type OpenOutput = {
  __typename?: 'OpenOutput';
  error?: Maybe<Scalars['String']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_type?: Maybe<Scalars['String']>;
  localized_name?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

export type OutUserItemAnswerModel = {
  __typename?: 'OutUserItemAnswerModel';
  alts_flag?: Maybe<Scalars['Boolean']>;
  amplitude_error?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  leave_frozen?: Maybe<Scalars['Boolean']>;
  out_request_id?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  token_id?: Maybe<Scalars['String']>;
  user_error?: Maybe<Scalars['String']>;
};

export type OutUserItemDto = {
  item_buy_price: Scalars['Int'];
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_price?: InputMaybe<Scalars['Float']>;
  market_item_id: Scalars['String'];
  transaction_id: Scalars['String'];
  user_id: Scalars['String'];
  user_partner: Scalars['String'];
  user_token: Scalars['String'];
};

export type OutputItemsInfoList = {
  __typename?: 'OutputItemsInfoList';
  currency?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['json']>;
  success: Scalars['Boolean'];
};

export type OutputMarketInfo = {
  __typename?: 'OutputMarketInfo';
  currency?: Maybe<Scalars['String']>;
  data?: Maybe<Array<ItemOnSale>>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type OutputStatus = {
  __typename?: 'OutputStatus';
  data?: Maybe<DataInfo>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  current_page: Scalars['Int'];
  page_size: Scalars['Int'];
  total_items: Scalars['Int'];
  total_pages: Scalars['Int'];
};

export type Participant = {
  __typename?: 'Participant';
  row_number: Scalars['Int'];
  total: Scalars['Int'];
  user_id: Scalars['uuid'];
  user_profile?: Maybe<ParticipantProfile>;
};

export type ParticipantProfile = {
  __typename?: 'ParticipantProfile';
  avatar?: Maybe<Scalars['String']>;
  persona_name: Scalars['String'];
};

export type ParticipantRewardsOutput = {
  __typename?: 'ParticipantRewardsOutput';
  error?: Maybe<Scalars['String']>;
  second_place_team?: Maybe<Array<Maybe<PrizeParticipant>>>;
  success: Scalars['Boolean'];
  winner_team?: Maybe<Array<Maybe<PrizeParticipant>>>;
  winner_team_id?: Maybe<Scalars['Int']>;
};

export type ParticipantsRatingModel = {
  __typename?: 'ParticipantsRatingModel';
  additional_list?: Maybe<Array<Maybe<Participant>>>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  team_one?: Maybe<Array<Maybe<Participant>>>;
  team_one_count?: Maybe<Scalars['Int']>;
  team_two?: Maybe<Array<Maybe<Participant>>>;
  team_two_count?: Maybe<Scalars['Int']>;
};

export type PermanentMission = {
  __typename?: 'PermanentMission';
  mission_availability: Scalars['Int'];
  mission_click_type: MissionClickType;
  mission_current_progress: Scalars['Int'];
  mission_description: Scalars['String'];
  mission_full_progress: Scalars['Int'];
  mission_id: Scalars['uuid'];
  mission_image?: Maybe<Scalars['String']>;
  mission_reward: Scalars['Int'];
  mission_sort: Scalars['Int'];
  mission_tags: Array<MissionTag>;
  mission_timer?: Maybe<PermanentMissionTimer>;
  mission_title: Scalars['String'];
};

export type PermanentMissionTimer = {
  __typename?: 'PermanentMissionTimer';
  launch: Scalars['Boolean'];
  max_value: Scalars['Int'];
  timer_value: Scalars['Int'];
};

export type PermanentMissionsList = {
  __typename?: 'PermanentMissionsList';
  missions: Array<PermanentMission>;
  missions_full_count: Scalars['Int'];
};

export type PrizeItemModel = {
  __typename?: 'PrizeItemModel';
  item_hash_name: Scalars['String'];
  item_image_link: Scalars['String'];
  item_min_price: Scalars['Int'];
  item_name: Scalars['String'];
  item_name_localized?: Maybe<Scalars['String']>;
};

export type PrizeParticipant = {
  __typename?: 'PrizeParticipant';
  cash_prize?: Maybe<Scalars['Float']>;
  coin_prize?: Maybe<Scalars['Int']>;
  row_number: Scalars['Int'];
  skin_prize?: Maybe<PrizeItemModel>;
  total: Scalars['Int'];
  user_id: Scalars['uuid'];
  user_profile?: Maybe<ParticipantProfile>;
};

export type ProcMissionProgressResponse = {
  __typename?: 'ProcMissionProgressResponse';
  completed: Scalars['Boolean'];
  current_progress: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  mark_as_done: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  redirect_type: Scalars['Int'];
  redirect_url?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RandomItems = {
  __typename?: 'RandomItems';
  item_hash_name: Scalars['String'];
  item_id: Scalars['String'];
  item_image_link: Scalars['String'];
  item_min_price: Scalars['Int'];
  item_name: Scalars['String'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_type: Scalars['String'];
};

export type ReferralStatistic = {
  __typename?: 'ReferralStatistic';
  day: Scalars['String'];
  lead: Scalars['Int'];
  payed: Scalars['Int'];
  total_amount: Scalars['Float'];
};

export type ReferralStatisticsResponse = {
  __typename?: 'ReferralStatisticsResponse';
  referral_statistics: Array<Maybe<ReferralStatistic>>;
};

export type RegBonusInput = {
  ip?: InputMaybe<Scalars['String']>;
};

export type RegBonusOutput = {
  __typename?: 'RegBonusOutput';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Result = {
  __typename?: 'Result';
  result?: Maybe<Scalars['String']>;
};

export type RollUserBonusWheelAnswer = {
  __typename?: 'RollUserBonusWheelAnswer';
  bonus_data?: Maybe<RollUserBonusWheelBonusData>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  top_bar?: Maybe<BonusWheelPageDataTopBar>;
};

export type RollUserBonusWheelBonusData = {
  __typename?: 'RollUserBonusWheelBonusData';
  bonus_description: Scalars['String'];
  bonus_id: Scalars['String'];
  bonus_image: Scalars['String'];
  bonus_name: Scalars['String'];
  bonus_title: Scalars['String'];
  bonus_value?: Maybe<Scalars['String']>;
  button_action: Scalars['String'];
  button_link?: Maybe<Scalars['String']>;
  button_text: Scalars['String'];
};

export type RollingWheelRewardAnswer = {
  __typename?: 'RollingWheelRewardAnswer';
  error?: Maybe<Scalars['String']>;
  reward_data?: Maybe<RollingWheelRewardData>;
  success: Scalars['Boolean'];
};

export type RollingWheelRewardData = {
  __typename?: 'RollingWheelRewardData';
  reward_description: Scalars['String'];
  reward_id: Scalars['String'];
  reward_image: Scalars['String'];
  reward_name: Scalars['String'];
  reward_type: Scalars['String'];
  reward_value: Scalars['String'];
  skin?: Maybe<WheelItem>;
  ticket_type_id: Scalars['String'];
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  message: Scalars['String'];
};

export type ScheduleUserMailByEventDto = {
  append_minutes: Scalars['Int'];
  event: Scalars['String'];
  should_validate: Scalars['Boolean'];
  user_id: Scalars['String'];
};

export type ScheduleUserMailByUniqueTriggerAnswer = {
  __typename?: 'ScheduleUserMailByUniqueTriggerAnswer';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ScheduleUserPushByEventDto = {
  append_minutes: Scalars['Int'];
  event: Scalars['String'];
  should_validate: Scalars['Boolean'];
  user_id: Scalars['String'];
};

export type ScheduleUserPushByUniqueTriggerAnswer = {
  __typename?: 'ScheduleUserPushByUniqueTriggerAnswer';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ScoreProcessModel = {
  __typename?: 'ScoreProcessModel';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  total_scope?: Maybe<Scalars['Int']>;
};

export type SellOutput = {
  __typename?: 'SellOutput';
  count: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  full_price: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type SendEmailToUserAnswer = {
  __typename?: 'SendEmailToUserAnswer';
  error?: Maybe<Scalars['String']>;
  mg_response_status?: Maybe<Scalars['Int']>;
  mg_response_text?: Maybe<Scalars['String']>;
  mg_send_success: Scalars['Boolean'];
  success: Scalars['Boolean'];
};

export type SendEmailToUserInput = {
  force_sending?: InputMaybe<Scalars['Boolean']>;
  subject?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  template_name: Scalars['String'];
  template_params?: InputMaybe<Scalars['jsonb']>;
  tracking?: InputMaybe<Scalars['Boolean']>;
  user_id: Scalars['String'];
  user_params?: InputMaybe<Scalars['jsonb']>;
};

export type SendItemToSteamInput = {
  alter_id?: InputMaybe<Scalars['uuid']>;
  hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  user_partner: Scalars['String'];
  user_token: Scalars['String'];
};

export type SendItemToSteamInputNew = {
  alternative_item?: InputMaybe<AlternativeItemInput>;
  hash_name: Scalars['String'];
  item_id?: InputMaybe<Scalars['String']>;
  item_price: Scalars['Float'];
  user_id: Scalars['String'];
  user_item_id: Scalars['String'];
  user_name?: InputMaybe<Scalars['String']>;
  user_partner: Scalars['String'];
  user_token: Scalars['String'];
};

export type SendItemToUserInSteamAnswerModel = {
  __typename?: 'SendItemToUserInSteamAnswerModel';
  alternative_item_price?: Maybe<Scalars['Float']>;
  alternative_items?: Maybe<Array<AlternativeItem>>;
  alternatives_output_id?: Maybe<Scalars['String']>;
  alts_flag?: Maybe<Scalars['Boolean']>;
  amplitude_error?: Maybe<Scalars['String']>;
  base_item?: Maybe<AlternativeBaseItem>;
  error?: Maybe<Scalars['String']>;
  leave_frozen?: Maybe<Scalars['Boolean']>;
  out_request_id?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  token_id?: Maybe<Scalars['String']>;
  user_error?: Maybe<Scalars['String']>;
};

export type SendItemToUserInSteamDto = {
  alternative_item?: InputMaybe<AlternativeItemInput>;
  item_hash_name: Scalars['String'];
  item_id: Scalars['String'];
  item_price: Scalars['Float'];
  transaction_id: Scalars['String'];
  user_id: Scalars['String'];
  user_item_id: Scalars['String'];
  user_name?: InputMaybe<Scalars['String']>;
  user_partner: Scalars['String'];
  user_token: Scalars['String'];
};

export type SendWebPushToTopicInput = {
  body: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  topic: Scalars['String'];
};

export type SendWebPushToTopicOutput = {
  __typename?: 'SendWebPushToTopicOutput';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SendWebPushToUserInput = {
  body: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  user_id: Scalars['String'];
};

export type SendWebPushToUserOutput = {
  __typename?: 'SendWebPushToUserOutput';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SkinDetailDto = {
  __typename?: 'SkinDetailDto';
  available_quantity?: Maybe<Scalars['Int']>;
  image: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  quality: Scalars['String'];
  rarity: Scalars['String'];
};

export type SkinOfferView = {
  __typename?: 'SkinOfferView';
  available_quantity?: Maybe<Scalars['Int']>;
  badge?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discount: Scalars['Int'];
  end_datetime?: Maybe<Scalars['timestamp']>;
  image?: Maybe<Scalars['String']>;
  is_combo: Scalars['Boolean'];
  is_mistery: Scalars['Boolean'];
  item_detail?: Maybe<Array<Maybe<BaseItemDetail>>>;
  offer_id: Scalars['String'];
  offer_type: Scalars['String'];
  old_price?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  start_datetime?: Maybe<Scalars['timestamp']>;
  title?: Maybe<Scalars['String']>;
};

export type SkinPurchaseResponse = {
  __typename?: 'SkinPurchaseResponse';
  error?: Maybe<Scalars['String']>;
  skin_detail?: Maybe<SkinDetailDto>;
  success: Scalars['Boolean'];
};

export type StatusTeam = {
  __typename?: 'StatusTeam';
  total: Scalars['Int'];
};

export type StoreOffersView = {
  __typename?: 'StoreOffersView';
  boosters?: Maybe<Array<Maybe<CoinBoosterTicketOfferView>>>;
  coins?: Maybe<Array<Maybe<CoinBoosterTicketOfferView>>>;
  error?: Maybe<Scalars['String']>;
  lootboxes?: Maybe<Array<Maybe<LootboxOfferView>>>;
  skins?: Maybe<Array<Maybe<SkinOfferView>>>;
  success: Scalars['Boolean'];
  tickets?: Maybe<Array<Maybe<CoinBoosterTicketOfferView>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type TestMainPageLootboxFilterDto = {
  filter_skin_name?: InputMaybe<Scalars['String']>;
  filter_weapon_types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  max_price?: InputMaybe<Scalars['Float']>;
  min_price?: InputMaybe<Scalars['Float']>;
};

export type TestMainPageLootboxesAnswerModel = {
  __typename?: 'TestMainPageLootboxesAnswerModel';
  filter_categories: Array<TestMpLootboxesFilterCategoryModel>;
  lootbox_categories: Array<TestMpLootboxesCategoryModel>;
};

export type TestMpLootboxesCategoryModel = {
  __typename?: 'TestMpLootboxesCategoryModel';
  anchor: Scalars['String'];
  info_text?: Maybe<Scalars['String']>;
  lootboxes: Array<TestMpLootboxesLootboxModel>;
  title: Scalars['String'];
};

export type TestMpLootboxesFilterCategoryModel = {
  __typename?: 'TestMpLootboxesFilterCategoryModel';
  anchor: Scalars['String'];
  enabled: Scalars['Boolean'];
  title: Scalars['String'];
};

export type TestMpLootboxesLootboxModel = {
  __typename?: 'TestMpLootboxesLootboxModel';
  badges?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_link: Scalars['String'];
  name: Scalars['String'];
  open_count: Scalars['Int'];
  open_max_count?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  price_type: Scalars['String'];
  slug: Scalars['String'];
  url_prefix: Scalars['String'];
  weapon_types: Scalars['String'];
};

export type TicketInfo = {
  __typename?: 'TicketInfo';
  ticket_count?: Maybe<Scalars['Int']>;
  ticket_limit?: Maybe<Scalars['Int']>;
  ticket_name?: Maybe<Scalars['String']>;
};

export type TicketsInfo = {
  __typename?: 'TicketsInfo';
  ticket_count?: Maybe<Scalars['Int']>;
  ticket_type_name?: Maybe<Scalars['String']>;
};

export type TicketsInfoResponse = {
  __typename?: 'TicketsInfoResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  tickets?: Maybe<Array<Maybe<TicketsInfo>>>;
};

/** columns and relationships of "TopDropStripView" */
export type TopDropStripView = {
  __typename?: 'TopDropStripView';
  full_flag?: Maybe<Scalars['Int']>;
  /** An array relationship */
  full_items: Array<Top_Drop_Strip>;
  /** An aggregate relationship */
  full_items_aggregate: Top_Drop_Strip_Aggregate;
  low_flag?: Maybe<Scalars['Int']>;
  top_flag?: Maybe<Scalars['Int']>;
  /** An array relationship */
  top_items: Array<Top_Drop_Strip>;
  /** An aggregate relationship */
  top_items_aggregate: Top_Drop_Strip_Aggregate;
};


/** columns and relationships of "TopDropStripView" */
export type TopDropStripViewFull_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Top_Drop_Strip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Top_Drop_Strip_Order_By>>;
  where?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
};


/** columns and relationships of "TopDropStripView" */
export type TopDropStripViewFull_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Top_Drop_Strip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Top_Drop_Strip_Order_By>>;
  where?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
};


/** columns and relationships of "TopDropStripView" */
export type TopDropStripViewTop_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Top_Drop_Strip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Top_Drop_Strip_Order_By>>;
  where?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
};


/** columns and relationships of "TopDropStripView" */
export type TopDropStripViewTop_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Top_Drop_Strip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Top_Drop_Strip_Order_By>>;
  where?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
};

/** aggregated selection of "TopDropStripView" */
export type TopDropStripView_Aggregate = {
  __typename?: 'TopDropStripView_aggregate';
  aggregate?: Maybe<TopDropStripView_Aggregate_Fields>;
  nodes: Array<TopDropStripView>;
};

/** aggregate fields of "TopDropStripView" */
export type TopDropStripView_Aggregate_Fields = {
  __typename?: 'TopDropStripView_aggregate_fields';
  avg?: Maybe<TopDropStripView_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TopDropStripView_Max_Fields>;
  min?: Maybe<TopDropStripView_Min_Fields>;
  stddev?: Maybe<TopDropStripView_Stddev_Fields>;
  stddev_pop?: Maybe<TopDropStripView_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TopDropStripView_Stddev_Samp_Fields>;
  sum?: Maybe<TopDropStripView_Sum_Fields>;
  var_pop?: Maybe<TopDropStripView_Var_Pop_Fields>;
  var_samp?: Maybe<TopDropStripView_Var_Samp_Fields>;
  variance?: Maybe<TopDropStripView_Variance_Fields>;
};


/** aggregate fields of "TopDropStripView" */
export type TopDropStripView_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TopDropStripView_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TopDropStripView_Avg_Fields = {
  __typename?: 'TopDropStripView_avg_fields';
  full_flag?: Maybe<Scalars['Float']>;
  low_flag?: Maybe<Scalars['Float']>;
  top_flag?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "TopDropStripView". All fields are combined with a logical 'AND'. */
export type TopDropStripView_Bool_Exp = {
  _and?: InputMaybe<Array<TopDropStripView_Bool_Exp>>;
  _not?: InputMaybe<TopDropStripView_Bool_Exp>;
  _or?: InputMaybe<Array<TopDropStripView_Bool_Exp>>;
  full_flag?: InputMaybe<Int_Comparison_Exp>;
  full_items?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
  full_items_aggregate?: InputMaybe<Top_Drop_Strip_Aggregate_Bool_Exp>;
  low_flag?: InputMaybe<Int_Comparison_Exp>;
  top_flag?: InputMaybe<Int_Comparison_Exp>;
  top_items?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
  top_items_aggregate?: InputMaybe<Top_Drop_Strip_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type TopDropStripView_Max_Fields = {
  __typename?: 'TopDropStripView_max_fields';
  full_flag?: Maybe<Scalars['Int']>;
  low_flag?: Maybe<Scalars['Int']>;
  top_flag?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TopDropStripView_Min_Fields = {
  __typename?: 'TopDropStripView_min_fields';
  full_flag?: Maybe<Scalars['Int']>;
  low_flag?: Maybe<Scalars['Int']>;
  top_flag?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "TopDropStripView". */
export type TopDropStripView_Order_By = {
  full_flag?: InputMaybe<Order_By>;
  full_items_aggregate?: InputMaybe<Top_Drop_Strip_Aggregate_Order_By>;
  low_flag?: InputMaybe<Order_By>;
  top_flag?: InputMaybe<Order_By>;
  top_items_aggregate?: InputMaybe<Top_Drop_Strip_Aggregate_Order_By>;
};

/** select columns of table "TopDropStripView" */
export enum TopDropStripView_Select_Column {
  /** column name */
  FullFlag = 'full_flag',
  /** column name */
  LowFlag = 'low_flag',
  /** column name */
  TopFlag = 'top_flag'
}

/** aggregate stddev on columns */
export type TopDropStripView_Stddev_Fields = {
  __typename?: 'TopDropStripView_stddev_fields';
  full_flag?: Maybe<Scalars['Float']>;
  low_flag?: Maybe<Scalars['Float']>;
  top_flag?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TopDropStripView_Stddev_Pop_Fields = {
  __typename?: 'TopDropStripView_stddev_pop_fields';
  full_flag?: Maybe<Scalars['Float']>;
  low_flag?: Maybe<Scalars['Float']>;
  top_flag?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TopDropStripView_Stddev_Samp_Fields = {
  __typename?: 'TopDropStripView_stddev_samp_fields';
  full_flag?: Maybe<Scalars['Float']>;
  low_flag?: Maybe<Scalars['Float']>;
  top_flag?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "TopDropStripView" */
export type TopDropStripView_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TopDropStripView_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TopDropStripView_Stream_Cursor_Value_Input = {
  full_flag?: InputMaybe<Scalars['Int']>;
  low_flag?: InputMaybe<Scalars['Int']>;
  top_flag?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TopDropStripView_Sum_Fields = {
  __typename?: 'TopDropStripView_sum_fields';
  full_flag?: Maybe<Scalars['Int']>;
  low_flag?: Maybe<Scalars['Int']>;
  top_flag?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type TopDropStripView_Var_Pop_Fields = {
  __typename?: 'TopDropStripView_var_pop_fields';
  full_flag?: Maybe<Scalars['Float']>;
  low_flag?: Maybe<Scalars['Float']>;
  top_flag?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TopDropStripView_Var_Samp_Fields = {
  __typename?: 'TopDropStripView_var_samp_fields';
  full_flag?: Maybe<Scalars['Float']>;
  low_flag?: Maybe<Scalars['Float']>;
  top_flag?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TopDropStripView_Variance_Fields = {
  __typename?: 'TopDropStripView_variance_fields';
  full_flag?: Maybe<Scalars['Float']>;
  low_flag?: Maybe<Scalars['Float']>;
  top_flag?: Maybe<Scalars['Float']>;
};

export type UpdateTokenBalanceOutput = {
  __typename?: 'UpdateTokenBalanceOutput';
  token_balance?: Maybe<Scalars['Float']>;
  token_id?: Maybe<Scalars['String']>;
  token_value?: Maybe<Scalars['String']>;
};

export type UpdateTokensOutput = {
  __typename?: 'UpdateTokensOutput';
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['String']>;
};

export type UpdateUserWebPushActivityDto = {
  is_active: Scalars['Boolean'];
  push_token: Scalars['String'];
};

export type UpgradeBoosters = {
  booster_type: Scalars['Int'];
  booster_value: Scalars['Int'];
};

export type UserEventData = {
  event_data: Scalars['jsonb'];
  event_sub_type: Scalars['String'];
  event_type: Scalars['String'];
  user_id: Scalars['uuid'];
};

export type UserFirebaseWebpushSettingsModel = {
  __typename?: 'UserFirebaseWebpushSettingsModel';
  is_active: Scalars['Boolean'];
  push_token: Scalars['String'];
  token_id?: Maybe<Scalars['String']>;
  user_agent?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type UserGiveawayHistoryResponse = {
  __typename?: 'UserGiveawayHistoryResponse';
  history: Array<GiveawayItem>;
  pagination: PaginationInfo;
};

export type UserHelloBonusClaimInput = {
  bonus_id: Scalars['uuid'];
  claimed: Scalars['Boolean'];
};

export type UserHelloBonusClaimOutput = {
  __typename?: 'UserHelloBonusClaimOutput';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UserInventoryOutput = {
  __typename?: 'UserInventoryOutput';
  user_items_in_inventory?: Maybe<Array<Maybe<ItemInInventory>>>;
};

export type UserMailAlertsUpdateAnswer = {
  __typename?: 'UserMailAlertsUpdateAnswer';
  error?: Maybe<Scalars['String']>;
  mails_status?: Maybe<Scalars['Boolean']>;
  success: Scalars['Boolean'];
};

export type UserProfileOutput = {
  __typename?: 'UserProfileOutput';
  error?: Maybe<Scalars['String']>;
  user_items_in_inventory?: Maybe<Array<Maybe<ItemInInventory>>>;
  user_wallet?: Maybe<UserWallet>;
};

export type UserReferalsLevel = {
  __typename?: 'UserReferalsLevel';
  level: Scalars['Int'];
  owner_bonus: Scalars['Int'];
  referal_bonus?: Maybe<Scalars['Int']>;
  target_deposits: Scalars['Int'];
};

export type UserSettingsPageDataModel = {
  __typename?: 'UserSettingsPageDataModel';
  email?: Maybe<Scalars['String']>;
  email_alerts: Scalars['Boolean'];
  email_verification_timer?: Maybe<Scalars['Int']>;
  email_verified: Scalars['Boolean'];
  pushes_active: Scalars['Boolean'];
};

export type UserUpgradeAnswer = {
  __typename?: 'UserUpgradeAnswer';
  error?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
  success: Scalars['Boolean'];
};

export type UserUpgradeCalcAnswer = {
  __typename?: 'UserUpgradeCalcAnswer';
  chance: Scalars['Float'];
  koef: Scalars['Float'];
};

export type UserUpgradeCalcInput = {
  boosters?: InputMaybe<Array<InputMaybe<UpgradeBoosters>>>;
  input_item_price: Scalars['Int'];
  target_item_price: Scalars['Int'];
};

export type UserUpgradeInput = {
  boosters?: InputMaybe<Array<UserUpgradeInputBooster>>;
  item_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};

export type UserUpgradeInputBooster = {
  booster_type: Scalars['Int'];
  booster_value: Scalars['String'];
};

export type UserWallet = {
  __typename?: 'UserWallet';
  balance?: Maybe<Scalars['Float']>;
  user_wallet_id?: Maybe<Scalars['uuid']>;
  wallet_currency?: Maybe<Scalars['String']>;
};

export type UserWebPushUpdateAnswer = {
  __typename?: 'UserWebPushUpdateAnswer';
  error?: Maybe<Scalars['String']>;
  pushes_status?: Maybe<Scalars['Boolean']>;
  success: Scalars['Boolean'];
};

export type UserWheelInfo = {
  __typename?: 'UserWheelInfo';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  ticket_info?: Maybe<Array<Maybe<TicketInfo>>>;
};

export type UserWheelNickname = {
  __typename?: 'UserWheelNickname';
  claimed: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UsersGiveawaysRating = {
  __typename?: 'UsersGiveawaysRating';
  avatar?: Maybe<Scalars['String']>;
  last_prize?: Maybe<Array<Maybe<GiveawayPrize>>>;
  participation_count: Scalars['Int'];
  persona_name: Scalars['String'];
  user_id: Scalars['String'];
  won_count: Scalars['Int'];
};

export type UsersGiveawaysRatingResponse = {
  __typename?: 'UsersGiveawaysRatingResponse';
  pagination: PaginationInfo;
  rating: Array<UsersGiveawaysRating>;
};

export type ValidateItemsOutStatusMassInput = {
  transactions_ids: Array<Scalars['String']>;
};

export type ValidateUserBonusWheelAvailabilityAnswerModel = {
  __typename?: 'ValidateUserBonusWheelAvailabilityAnswerModel';
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type VerifyUserEmailAnswer = {
  __typename?: 'VerifyUserEmailAnswer';
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type WheelItem = {
  __typename?: 'WheelItem';
  item_hash_name: Scalars['String'];
  item_id: Scalars['String'];
  item_image_link: Scalars['String'];
  item_min_price: Scalars['Float'];
  item_name: Scalars['String'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  localization: Array<Maybe<Localization>>;
};

export type WheelRewardType = {
  __typename?: 'WheelRewardType';
  reward_image: Scalars['String'];
  reward_name: Scalars['String'];
  reward_type: Scalars['String'];
};

export type WheelRewardsType = {
  __typename?: 'WheelRewardsType';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  wheel_rewards_type?: Maybe<Array<Maybe<WheelRewardType>>>;
};

export type WithdrawUserReferalBalanceOutput = {
  __typename?: 'WithdrawUserReferalBalanceOutput';
  error?: Maybe<Scalars['String']>;
  new_balance?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
};

/** columns and relationships of "account_items_names" */
export type Account_Items_Names = {
  __typename?: 'account_items_names';
  name: Scalars['String'];
  ru_name: Scalars['String'];
};

/** aggregated selection of "account_items_names" */
export type Account_Items_Names_Aggregate = {
  __typename?: 'account_items_names_aggregate';
  aggregate?: Maybe<Account_Items_Names_Aggregate_Fields>;
  nodes: Array<Account_Items_Names>;
};

/** aggregate fields of "account_items_names" */
export type Account_Items_Names_Aggregate_Fields = {
  __typename?: 'account_items_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Items_Names_Max_Fields>;
  min?: Maybe<Account_Items_Names_Min_Fields>;
};


/** aggregate fields of "account_items_names" */
export type Account_Items_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Items_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account_items_names". All fields are combined with a logical 'AND'. */
export type Account_Items_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Items_Names_Bool_Exp>>;
  _not?: InputMaybe<Account_Items_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Items_Names_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_items_names" */
export enum Account_Items_Names_Constraint {
  /** unique or primary key constraint on columns "name" */
  AccountItemsNamesPkey = 'account_items_names_pkey'
}

/** input type for inserting data into table "account_items_names" */
export type Account_Items_Names_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Account_Items_Names_Max_Fields = {
  __typename?: 'account_items_names_max_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Account_Items_Names_Min_Fields = {
  __typename?: 'account_items_names_min_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "account_items_names" */
export type Account_Items_Names_Mutation_Response = {
  __typename?: 'account_items_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Items_Names>;
};

/** input type for inserting object relation for remote table "account_items_names" */
export type Account_Items_Names_Obj_Rel_Insert_Input = {
  data: Account_Items_Names_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_Items_Names_On_Conflict>;
};

/** on_conflict condition type for table "account_items_names" */
export type Account_Items_Names_On_Conflict = {
  constraint: Account_Items_Names_Constraint;
  update_columns?: Array<Account_Items_Names_Update_Column>;
  where?: InputMaybe<Account_Items_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "account_items_names". */
export type Account_Items_Names_Order_By = {
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account_items_names */
export type Account_Items_Names_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "account_items_names" */
export enum Account_Items_Names_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

/** input type for updating data in table "account_items_names" */
export type Account_Items_Names_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "account_items_names" */
export type Account_Items_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Items_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Items_Names_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "account_items_names" */
export enum Account_Items_Names_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

export type Account_Items_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Items_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Items_Names_Bool_Exp;
};

/** columns and relationships of "admin_items_names" */
export type Admin_Items_Names = {
  __typename?: 'admin_items_names';
  name: Scalars['String'];
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "admin_items_names" */
export type Admin_Items_Names_Aggregate = {
  __typename?: 'admin_items_names_aggregate';
  aggregate?: Maybe<Admin_Items_Names_Aggregate_Fields>;
  nodes: Array<Admin_Items_Names>;
};

/** aggregate fields of "admin_items_names" */
export type Admin_Items_Names_Aggregate_Fields = {
  __typename?: 'admin_items_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Admin_Items_Names_Max_Fields>;
  min?: Maybe<Admin_Items_Names_Min_Fields>;
};


/** aggregate fields of "admin_items_names" */
export type Admin_Items_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Admin_Items_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "admin_items_names". All fields are combined with a logical 'AND'. */
export type Admin_Items_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Admin_Items_Names_Bool_Exp>>;
  _not?: InputMaybe<Admin_Items_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Admin_Items_Names_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "admin_items_names" */
export enum Admin_Items_Names_Constraint {
  /** unique or primary key constraint on columns "name" */
  ItemsNamesPkey = 'items_names_pkey'
}

/** input type for inserting data into table "admin_items_names" */
export type Admin_Items_Names_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Admin_Items_Names_Max_Fields = {
  __typename?: 'admin_items_names_max_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Admin_Items_Names_Min_Fields = {
  __typename?: 'admin_items_names_min_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "admin_items_names" */
export type Admin_Items_Names_Mutation_Response = {
  __typename?: 'admin_items_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Admin_Items_Names>;
};

/** input type for inserting object relation for remote table "admin_items_names" */
export type Admin_Items_Names_Obj_Rel_Insert_Input = {
  data: Admin_Items_Names_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Admin_Items_Names_On_Conflict>;
};

/** on_conflict condition type for table "admin_items_names" */
export type Admin_Items_Names_On_Conflict = {
  constraint: Admin_Items_Names_Constraint;
  update_columns?: Array<Admin_Items_Names_Update_Column>;
  where?: InputMaybe<Admin_Items_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "admin_items_names". */
export type Admin_Items_Names_Order_By = {
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: admin_items_names */
export type Admin_Items_Names_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "admin_items_names" */
export enum Admin_Items_Names_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

/** input type for updating data in table "admin_items_names" */
export type Admin_Items_Names_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "admin_items_names" */
export type Admin_Items_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Admin_Items_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Admin_Items_Names_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "admin_items_names" */
export enum Admin_Items_Names_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

export type Admin_Items_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Admin_Items_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Admin_Items_Names_Bool_Exp;
};

/** columns and relationships of "admin_user_params" */
export type Admin_User_Params = {
  __typename?: 'admin_user_params';
  user_account?: Maybe<User_Account>;
  user_id: Scalars['uuid'];
  user_items_set: Scalars['Int'];
};

/** aggregated selection of "admin_user_params" */
export type Admin_User_Params_Aggregate = {
  __typename?: 'admin_user_params_aggregate';
  aggregate?: Maybe<Admin_User_Params_Aggregate_Fields>;
  nodes: Array<Admin_User_Params>;
};

/** aggregate fields of "admin_user_params" */
export type Admin_User_Params_Aggregate_Fields = {
  __typename?: 'admin_user_params_aggregate_fields';
  avg?: Maybe<Admin_User_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Admin_User_Params_Max_Fields>;
  min?: Maybe<Admin_User_Params_Min_Fields>;
  stddev?: Maybe<Admin_User_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Admin_User_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Admin_User_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Admin_User_Params_Sum_Fields>;
  var_pop?: Maybe<Admin_User_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Admin_User_Params_Var_Samp_Fields>;
  variance?: Maybe<Admin_User_Params_Variance_Fields>;
};


/** aggregate fields of "admin_user_params" */
export type Admin_User_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Admin_User_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Admin_User_Params_Avg_Fields = {
  __typename?: 'admin_user_params_avg_fields';
  user_items_set?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "admin_user_params". All fields are combined with a logical 'AND'. */
export type Admin_User_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Admin_User_Params_Bool_Exp>>;
  _not?: InputMaybe<Admin_User_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Admin_User_Params_Bool_Exp>>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_items_set?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "admin_user_params" */
export enum Admin_User_Params_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  AdminUserParamsPkey = 'admin_user_params_pkey'
}

/** input type for incrementing numeric columns in table "admin_user_params" */
export type Admin_User_Params_Inc_Input = {
  user_items_set?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "admin_user_params" */
export type Admin_User_Params_Insert_Input = {
  user_id?: InputMaybe<Scalars['uuid']>;
  user_items_set?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Admin_User_Params_Max_Fields = {
  __typename?: 'admin_user_params_max_fields';
  user_id?: Maybe<Scalars['uuid']>;
  user_items_set?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Admin_User_Params_Min_Fields = {
  __typename?: 'admin_user_params_min_fields';
  user_id?: Maybe<Scalars['uuid']>;
  user_items_set?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "admin_user_params" */
export type Admin_User_Params_Mutation_Response = {
  __typename?: 'admin_user_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Admin_User_Params>;
};

/** on_conflict condition type for table "admin_user_params" */
export type Admin_User_Params_On_Conflict = {
  constraint: Admin_User_Params_Constraint;
  update_columns?: Array<Admin_User_Params_Update_Column>;
  where?: InputMaybe<Admin_User_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "admin_user_params". */
export type Admin_User_Params_Order_By = {
  user_id?: InputMaybe<Order_By>;
  user_items_set?: InputMaybe<Order_By>;
};

/** primary key columns input for table: admin_user_params */
export type Admin_User_Params_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "admin_user_params" */
export enum Admin_User_Params_Select_Column {
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserItemsSet = 'user_items_set'
}

/** input type for updating data in table "admin_user_params" */
export type Admin_User_Params_Set_Input = {
  user_id?: InputMaybe<Scalars['uuid']>;
  user_items_set?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Admin_User_Params_Stddev_Fields = {
  __typename?: 'admin_user_params_stddev_fields';
  user_items_set?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Admin_User_Params_Stddev_Pop_Fields = {
  __typename?: 'admin_user_params_stddev_pop_fields';
  user_items_set?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Admin_User_Params_Stddev_Samp_Fields = {
  __typename?: 'admin_user_params_stddev_samp_fields';
  user_items_set?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "admin_user_params" */
export type Admin_User_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Admin_User_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Admin_User_Params_Stream_Cursor_Value_Input = {
  user_id?: InputMaybe<Scalars['uuid']>;
  user_items_set?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Admin_User_Params_Sum_Fields = {
  __typename?: 'admin_user_params_sum_fields';
  user_items_set?: Maybe<Scalars['Int']>;
};

/** update columns of table "admin_user_params" */
export enum Admin_User_Params_Update_Column {
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserItemsSet = 'user_items_set'
}

export type Admin_User_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Admin_User_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Admin_User_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Admin_User_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Admin_User_Params_Var_Pop_Fields = {
  __typename?: 'admin_user_params_var_pop_fields';
  user_items_set?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Admin_User_Params_Var_Samp_Fields = {
  __typename?: 'admin_user_params_var_samp_fields';
  user_items_set?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Admin_User_Params_Variance_Fields = {
  __typename?: 'admin_user_params_variance_fields';
  user_items_set?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "amp_sessions_data" */
export type Amp_Sessions_Data = {
  __typename?: 'amp_sessions_data';
  created_at: Scalars['timestamp'];
  key: Scalars['String'];
  session_id: Scalars['bigint'];
};

/** aggregated selection of "amp_sessions_data" */
export type Amp_Sessions_Data_Aggregate = {
  __typename?: 'amp_sessions_data_aggregate';
  aggregate?: Maybe<Amp_Sessions_Data_Aggregate_Fields>;
  nodes: Array<Amp_Sessions_Data>;
};

/** aggregate fields of "amp_sessions_data" */
export type Amp_Sessions_Data_Aggregate_Fields = {
  __typename?: 'amp_sessions_data_aggregate_fields';
  avg?: Maybe<Amp_Sessions_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Amp_Sessions_Data_Max_Fields>;
  min?: Maybe<Amp_Sessions_Data_Min_Fields>;
  stddev?: Maybe<Amp_Sessions_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Amp_Sessions_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Amp_Sessions_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Amp_Sessions_Data_Sum_Fields>;
  var_pop?: Maybe<Amp_Sessions_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Amp_Sessions_Data_Var_Samp_Fields>;
  variance?: Maybe<Amp_Sessions_Data_Variance_Fields>;
};


/** aggregate fields of "amp_sessions_data" */
export type Amp_Sessions_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Amp_Sessions_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Amp_Sessions_Data_Avg_Fields = {
  __typename?: 'amp_sessions_data_avg_fields';
  session_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "amp_sessions_data". All fields are combined with a logical 'AND'. */
export type Amp_Sessions_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Amp_Sessions_Data_Bool_Exp>>;
  _not?: InputMaybe<Amp_Sessions_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Amp_Sessions_Data_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  session_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "amp_sessions_data" */
export enum Amp_Sessions_Data_Constraint {
  /** unique or primary key constraint on columns "key" */
  AmpSessionsDataKeyKey = 'amp_sessions_data_key_key',
  /** unique or primary key constraint on columns "key" */
  AmpSessionsDataPkey = 'amp_sessions_data_pkey'
}

/** input type for incrementing numeric columns in table "amp_sessions_data" */
export type Amp_Sessions_Data_Inc_Input = {
  session_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "amp_sessions_data" */
export type Amp_Sessions_Data_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  key?: InputMaybe<Scalars['String']>;
  session_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Amp_Sessions_Data_Max_Fields = {
  __typename?: 'amp_sessions_data_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  key?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Amp_Sessions_Data_Min_Fields = {
  __typename?: 'amp_sessions_data_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  key?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "amp_sessions_data" */
export type Amp_Sessions_Data_Mutation_Response = {
  __typename?: 'amp_sessions_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Amp_Sessions_Data>;
};

/** on_conflict condition type for table "amp_sessions_data" */
export type Amp_Sessions_Data_On_Conflict = {
  constraint: Amp_Sessions_Data_Constraint;
  update_columns?: Array<Amp_Sessions_Data_Update_Column>;
  where?: InputMaybe<Amp_Sessions_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "amp_sessions_data". */
export type Amp_Sessions_Data_Order_By = {
  created_at?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: amp_sessions_data */
export type Amp_Sessions_Data_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "amp_sessions_data" */
export enum Amp_Sessions_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  SessionId = 'session_id'
}

/** input type for updating data in table "amp_sessions_data" */
export type Amp_Sessions_Data_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  key?: InputMaybe<Scalars['String']>;
  session_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Amp_Sessions_Data_Stddev_Fields = {
  __typename?: 'amp_sessions_data_stddev_fields';
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Amp_Sessions_Data_Stddev_Pop_Fields = {
  __typename?: 'amp_sessions_data_stddev_pop_fields';
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Amp_Sessions_Data_Stddev_Samp_Fields = {
  __typename?: 'amp_sessions_data_stddev_samp_fields';
  session_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "amp_sessions_data" */
export type Amp_Sessions_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Amp_Sessions_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Amp_Sessions_Data_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  key?: InputMaybe<Scalars['String']>;
  session_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Amp_Sessions_Data_Sum_Fields = {
  __typename?: 'amp_sessions_data_sum_fields';
  session_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "amp_sessions_data" */
export enum Amp_Sessions_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  SessionId = 'session_id'
}

export type Amp_Sessions_Data_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Amp_Sessions_Data_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Amp_Sessions_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Amp_Sessions_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Amp_Sessions_Data_Var_Pop_Fields = {
  __typename?: 'amp_sessions_data_var_pop_fields';
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Amp_Sessions_Data_Var_Samp_Fields = {
  __typename?: 'amp_sessions_data_var_samp_fields';
  session_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Amp_Sessions_Data_Variance_Fields = {
  __typename?: 'amp_sessions_data_variance_fields';
  session_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "banned_ips" */
export type Banned_Ips = {
  __typename?: 'banned_ips';
  ban_active: Scalars['Boolean'];
  ip: Scalars['String'];
};

/** aggregated selection of "banned_ips" */
export type Banned_Ips_Aggregate = {
  __typename?: 'banned_ips_aggregate';
  aggregate?: Maybe<Banned_Ips_Aggregate_Fields>;
  nodes: Array<Banned_Ips>;
};

/** aggregate fields of "banned_ips" */
export type Banned_Ips_Aggregate_Fields = {
  __typename?: 'banned_ips_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Banned_Ips_Max_Fields>;
  min?: Maybe<Banned_Ips_Min_Fields>;
};


/** aggregate fields of "banned_ips" */
export type Banned_Ips_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Banned_Ips_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "banned_ips". All fields are combined with a logical 'AND'. */
export type Banned_Ips_Bool_Exp = {
  _and?: InputMaybe<Array<Banned_Ips_Bool_Exp>>;
  _not?: InputMaybe<Banned_Ips_Bool_Exp>;
  _or?: InputMaybe<Array<Banned_Ips_Bool_Exp>>;
  ban_active?: InputMaybe<Boolean_Comparison_Exp>;
  ip?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "banned_ips" */
export enum Banned_Ips_Constraint {
  /** unique or primary key constraint on columns "ip" */
  BannedIpsPkey = 'banned_ips_pkey'
}

/** input type for inserting data into table "banned_ips" */
export type Banned_Ips_Insert_Input = {
  ban_active?: InputMaybe<Scalars['Boolean']>;
  ip?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Banned_Ips_Max_Fields = {
  __typename?: 'banned_ips_max_fields';
  ip?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Banned_Ips_Min_Fields = {
  __typename?: 'banned_ips_min_fields';
  ip?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "banned_ips" */
export type Banned_Ips_Mutation_Response = {
  __typename?: 'banned_ips_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Banned_Ips>;
};

/** on_conflict condition type for table "banned_ips" */
export type Banned_Ips_On_Conflict = {
  constraint: Banned_Ips_Constraint;
  update_columns?: Array<Banned_Ips_Update_Column>;
  where?: InputMaybe<Banned_Ips_Bool_Exp>;
};

/** Ordering options when selecting data from "banned_ips". */
export type Banned_Ips_Order_By = {
  ban_active?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
};

/** primary key columns input for table: banned_ips */
export type Banned_Ips_Pk_Columns_Input = {
  ip: Scalars['String'];
};

/** select columns of table "banned_ips" */
export enum Banned_Ips_Select_Column {
  /** column name */
  BanActive = 'ban_active',
  /** column name */
  Ip = 'ip'
}

/** input type for updating data in table "banned_ips" */
export type Banned_Ips_Set_Input = {
  ban_active?: InputMaybe<Scalars['Boolean']>;
  ip?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "banned_ips" */
export type Banned_Ips_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Banned_Ips_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Banned_Ips_Stream_Cursor_Value_Input = {
  ban_active?: InputMaybe<Scalars['Boolean']>;
  ip?: InputMaybe<Scalars['String']>;
};

/** update columns of table "banned_ips" */
export enum Banned_Ips_Update_Column {
  /** column name */
  BanActive = 'ban_active',
  /** column name */
  Ip = 'ip'
}

export type Banned_Ips_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Banned_Ips_Set_Input>;
  /** filter the rows which have to be updated */
  where: Banned_Ips_Bool_Exp;
};

/** Пользователи которые были заблокированные из-за подозрения в мультуаккаунт инге */
export type Banned_Users_For_Multiaccount = {
  __typename?: 'banned_users_for_multiaccount';
  cause: Scalars['String'];
  /** An array relationship */
  connected_banned_accs: Array<Banned_Users_For_Multiaccount>;
  /** An aggregate relationship */
  connected_banned_accs_aggregate: Banned_Users_For_Multiaccount_Aggregate;
  connection_value?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamp'];
  extra_data?: Maybe<Scalars['jsonb']>;
  is_banned: Scalars['Boolean'];
  updated_at: Scalars['timestamp'];
  user_id: Scalars['uuid'];
};


/** Пользователи которые были заблокированные из-за подозрения в мультуаккаунт инге */
export type Banned_Users_For_MultiaccountConnected_Banned_AccsArgs = {
  distinct_on?: InputMaybe<Array<Banned_Users_For_Multiaccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banned_Users_For_Multiaccount_Order_By>>;
  where?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
};


/** Пользователи которые были заблокированные из-за подозрения в мультуаккаунт инге */
export type Banned_Users_For_MultiaccountConnected_Banned_Accs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Banned_Users_For_Multiaccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banned_Users_For_Multiaccount_Order_By>>;
  where?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
};


/** Пользователи которые были заблокированные из-за подозрения в мультуаккаунт инге */
export type Banned_Users_For_MultiaccountExtra_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Aggregate = {
  __typename?: 'banned_users_for_multiaccount_aggregate';
  aggregate?: Maybe<Banned_Users_For_Multiaccount_Aggregate_Fields>;
  nodes: Array<Banned_Users_For_Multiaccount>;
};

export type Banned_Users_For_Multiaccount_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Banned_Users_For_Multiaccount_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Banned_Users_For_Multiaccount_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Banned_Users_For_Multiaccount_Aggregate_Bool_Exp_Count>;
};

export type Banned_Users_For_Multiaccount_Aggregate_Bool_Exp_Bool_And = {
  arguments: Banned_Users_For_Multiaccount_Select_Column_Banned_Users_For_Multiaccount_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Banned_Users_For_Multiaccount_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Banned_Users_For_Multiaccount_Select_Column_Banned_Users_For_Multiaccount_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Banned_Users_For_Multiaccount_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Banned_Users_For_Multiaccount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Aggregate_Fields = {
  __typename?: 'banned_users_for_multiaccount_aggregate_fields';
  avg?: Maybe<Banned_Users_For_Multiaccount_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Banned_Users_For_Multiaccount_Max_Fields>;
  min?: Maybe<Banned_Users_For_Multiaccount_Min_Fields>;
  stddev?: Maybe<Banned_Users_For_Multiaccount_Stddev_Fields>;
  stddev_pop?: Maybe<Banned_Users_For_Multiaccount_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Banned_Users_For_Multiaccount_Stddev_Samp_Fields>;
  sum?: Maybe<Banned_Users_For_Multiaccount_Sum_Fields>;
  var_pop?: Maybe<Banned_Users_For_Multiaccount_Var_Pop_Fields>;
  var_samp?: Maybe<Banned_Users_For_Multiaccount_Var_Samp_Fields>;
  variance?: Maybe<Banned_Users_For_Multiaccount_Variance_Fields>;
};


/** aggregate fields of "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Banned_Users_For_Multiaccount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Aggregate_Order_By = {
  avg?: InputMaybe<Banned_Users_For_Multiaccount_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Banned_Users_For_Multiaccount_Max_Order_By>;
  min?: InputMaybe<Banned_Users_For_Multiaccount_Min_Order_By>;
  stddev?: InputMaybe<Banned_Users_For_Multiaccount_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Banned_Users_For_Multiaccount_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Banned_Users_For_Multiaccount_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Banned_Users_For_Multiaccount_Sum_Order_By>;
  var_pop?: InputMaybe<Banned_Users_For_Multiaccount_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Banned_Users_For_Multiaccount_Var_Samp_Order_By>;
  variance?: InputMaybe<Banned_Users_For_Multiaccount_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Banned_Users_For_Multiaccount_Append_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Arr_Rel_Insert_Input = {
  data: Array<Banned_Users_For_Multiaccount_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Banned_Users_For_Multiaccount_On_Conflict>;
};

/** aggregate avg on columns */
export type Banned_Users_For_Multiaccount_Avg_Fields = {
  __typename?: 'banned_users_for_multiaccount_avg_fields';
  connection_value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Avg_Order_By = {
  connection_value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "banned_users_for_multiaccount". All fields are combined with a logical 'AND'. */
export type Banned_Users_For_Multiaccount_Bool_Exp = {
  _and?: InputMaybe<Array<Banned_Users_For_Multiaccount_Bool_Exp>>;
  _not?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
  _or?: InputMaybe<Array<Banned_Users_For_Multiaccount_Bool_Exp>>;
  cause?: InputMaybe<String_Comparison_Exp>;
  connected_banned_accs?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
  connected_banned_accs_aggregate?: InputMaybe<Banned_Users_For_Multiaccount_Aggregate_Bool_Exp>;
  connection_value?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  extra_data?: InputMaybe<Jsonb_Comparison_Exp>;
  is_banned?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "banned_users_for_multiaccount" */
export enum Banned_Users_For_Multiaccount_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  BannedUsersForMultiaccountPkey = 'banned_users_for_multiaccount_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Banned_Users_For_Multiaccount_Delete_At_Path_Input = {
  extra_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Banned_Users_For_Multiaccount_Delete_Elem_Input = {
  extra_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Banned_Users_For_Multiaccount_Delete_Key_Input = {
  extra_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Inc_Input = {
  connection_value?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Insert_Input = {
  cause?: InputMaybe<Scalars['String']>;
  connected_banned_accs?: InputMaybe<Banned_Users_For_Multiaccount_Arr_Rel_Insert_Input>;
  connection_value?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  is_banned?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Banned_Users_For_Multiaccount_Max_Fields = {
  __typename?: 'banned_users_for_multiaccount_max_fields';
  cause?: Maybe<Scalars['String']>;
  connection_value?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Max_Order_By = {
  cause?: InputMaybe<Order_By>;
  connection_value?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Banned_Users_For_Multiaccount_Min_Fields = {
  __typename?: 'banned_users_for_multiaccount_min_fields';
  cause?: Maybe<Scalars['String']>;
  connection_value?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Min_Order_By = {
  cause?: InputMaybe<Order_By>;
  connection_value?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Mutation_Response = {
  __typename?: 'banned_users_for_multiaccount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Banned_Users_For_Multiaccount>;
};

/** input type for inserting object relation for remote table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Obj_Rel_Insert_Input = {
  data: Banned_Users_For_Multiaccount_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Banned_Users_For_Multiaccount_On_Conflict>;
};

/** on_conflict condition type for table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_On_Conflict = {
  constraint: Banned_Users_For_Multiaccount_Constraint;
  update_columns?: Array<Banned_Users_For_Multiaccount_Update_Column>;
  where?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
};

/** Ordering options when selecting data from "banned_users_for_multiaccount". */
export type Banned_Users_For_Multiaccount_Order_By = {
  cause?: InputMaybe<Order_By>;
  connected_banned_accs_aggregate?: InputMaybe<Banned_Users_For_Multiaccount_Aggregate_Order_By>;
  connection_value?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  extra_data?: InputMaybe<Order_By>;
  is_banned?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: banned_users_for_multiaccount */
export type Banned_Users_For_Multiaccount_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Banned_Users_For_Multiaccount_Prepend_Input = {
  extra_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "banned_users_for_multiaccount" */
export enum Banned_Users_For_Multiaccount_Select_Column {
  /** column name */
  Cause = 'cause',
  /** column name */
  ConnectionValue = 'connection_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExtraData = 'extra_data',
  /** column name */
  IsBanned = 'is_banned',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "banned_users_for_multiaccount_aggregate_bool_exp_bool_and_arguments_columns" columns of table "banned_users_for_multiaccount" */
export enum Banned_Users_For_Multiaccount_Select_Column_Banned_Users_For_Multiaccount_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsBanned = 'is_banned'
}

/** select "banned_users_for_multiaccount_aggregate_bool_exp_bool_or_arguments_columns" columns of table "banned_users_for_multiaccount" */
export enum Banned_Users_For_Multiaccount_Select_Column_Banned_Users_For_Multiaccount_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsBanned = 'is_banned'
}

/** input type for updating data in table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Set_Input = {
  cause?: InputMaybe<Scalars['String']>;
  connection_value?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  is_banned?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Banned_Users_For_Multiaccount_Stddev_Fields = {
  __typename?: 'banned_users_for_multiaccount_stddev_fields';
  connection_value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Stddev_Order_By = {
  connection_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Banned_Users_For_Multiaccount_Stddev_Pop_Fields = {
  __typename?: 'banned_users_for_multiaccount_stddev_pop_fields';
  connection_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Stddev_Pop_Order_By = {
  connection_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Banned_Users_For_Multiaccount_Stddev_Samp_Fields = {
  __typename?: 'banned_users_for_multiaccount_stddev_samp_fields';
  connection_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Stddev_Samp_Order_By = {
  connection_value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Banned_Users_For_Multiaccount_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Banned_Users_For_Multiaccount_Stream_Cursor_Value_Input = {
  cause?: InputMaybe<Scalars['String']>;
  connection_value?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  is_banned?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Banned_Users_For_Multiaccount_Sum_Fields = {
  __typename?: 'banned_users_for_multiaccount_sum_fields';
  connection_value?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Sum_Order_By = {
  connection_value?: InputMaybe<Order_By>;
};

/** update columns of table "banned_users_for_multiaccount" */
export enum Banned_Users_For_Multiaccount_Update_Column {
  /** column name */
  Cause = 'cause',
  /** column name */
  ConnectionValue = 'connection_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExtraData = 'extra_data',
  /** column name */
  IsBanned = 'is_banned',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Banned_Users_For_Multiaccount_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Banned_Users_For_Multiaccount_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Banned_Users_For_Multiaccount_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Banned_Users_For_Multiaccount_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Banned_Users_For_Multiaccount_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Banned_Users_For_Multiaccount_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Banned_Users_For_Multiaccount_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Banned_Users_For_Multiaccount_Set_Input>;
  /** filter the rows which have to be updated */
  where: Banned_Users_For_Multiaccount_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Banned_Users_For_Multiaccount_Var_Pop_Fields = {
  __typename?: 'banned_users_for_multiaccount_var_pop_fields';
  connection_value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Var_Pop_Order_By = {
  connection_value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Banned_Users_For_Multiaccount_Var_Samp_Fields = {
  __typename?: 'banned_users_for_multiaccount_var_samp_fields';
  connection_value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Var_Samp_Order_By = {
  connection_value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Banned_Users_For_Multiaccount_Variance_Fields = {
  __typename?: 'banned_users_for_multiaccount_variance_fields';
  connection_value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "banned_users_for_multiaccount" */
export type Banned_Users_For_Multiaccount_Variance_Order_By = {
  connection_value?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "bill" */
export type Bill = {
  __typename?: 'bill';
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_internal: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  transactions: Array<Transaction>;
  /** An array relationship */
  transactionsByDebit: Array<Transaction>;
  /** An aggregate relationship */
  transactionsByDebit_aggregate: Transaction_Aggregate;
  /** An aggregate relationship */
  transactions_aggregate: Transaction_Aggregate;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "bill" */
export type BillTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


/** columns and relationships of "bill" */
export type BillTransactionsByDebitArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


/** columns and relationships of "bill" */
export type BillTransactionsByDebit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


/** columns and relationships of "bill" */
export type BillTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};

/** aggregated selection of "bill" */
export type Bill_Aggregate = {
  __typename?: 'bill_aggregate';
  aggregate?: Maybe<Bill_Aggregate_Fields>;
  nodes: Array<Bill>;
};

/** aggregate fields of "bill" */
export type Bill_Aggregate_Fields = {
  __typename?: 'bill_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bill_Max_Fields>;
  min?: Maybe<Bill_Min_Fields>;
};


/** aggregate fields of "bill" */
export type Bill_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bill_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bill". All fields are combined with a logical 'AND'. */
export type Bill_Bool_Exp = {
  _and?: InputMaybe<Array<Bill_Bool_Exp>>;
  _not?: InputMaybe<Bill_Bool_Exp>;
  _or?: InputMaybe<Array<Bill_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_internal?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  transactions?: InputMaybe<Transaction_Bool_Exp>;
  transactionsByDebit?: InputMaybe<Transaction_Bool_Exp>;
  transactionsByDebit_aggregate?: InputMaybe<Transaction_Aggregate_Bool_Exp>;
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "bill" */
export enum Bill_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillPkey = 'bill_pkey'
}

/** input type for inserting data into table "bill" */
export type Bill_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_internal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<Transaction_Arr_Rel_Insert_Input>;
  transactionsByDebit?: InputMaybe<Transaction_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Bill_Max_Fields = {
  __typename?: 'bill_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Bill_Min_Fields = {
  __typename?: 'bill_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "bill" */
export type Bill_Mutation_Response = {
  __typename?: 'bill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bill>;
};

/** input type for inserting object relation for remote table "bill" */
export type Bill_Obj_Rel_Insert_Input = {
  data: Bill_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bill_On_Conflict>;
};

/** on_conflict condition type for table "bill" */
export type Bill_On_Conflict = {
  constraint: Bill_Constraint;
  update_columns?: Array<Bill_Update_Column>;
  where?: InputMaybe<Bill_Bool_Exp>;
};

/** Ordering options when selecting data from "bill". */
export type Bill_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_internal?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  transactionsByDebit_aggregate?: InputMaybe<Transaction_Aggregate_Order_By>;
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bill */
export type Bill_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "bill" */
export enum Bill_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsInternal = 'is_internal',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "bill" */
export type Bill_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_internal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "bill" */
export type Bill_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bill_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bill_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_internal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "bill" */
export enum Bill_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsInternal = 'is_internal',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

export type Bill_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bill_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bill_Bool_Exp;
};

/** columns and relationships of "bonus_params" */
export type Bonus_Params = {
  __typename?: 'bonus_params';
  bonus_id: Scalars['uuid'];
  param_name: Scalars['String'];
  param_value: Scalars['String'];
};

/** aggregated selection of "bonus_params" */
export type Bonus_Params_Aggregate = {
  __typename?: 'bonus_params_aggregate';
  aggregate?: Maybe<Bonus_Params_Aggregate_Fields>;
  nodes: Array<Bonus_Params>;
};

/** aggregate fields of "bonus_params" */
export type Bonus_Params_Aggregate_Fields = {
  __typename?: 'bonus_params_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Params_Max_Fields>;
  min?: Maybe<Bonus_Params_Min_Fields>;
};


/** aggregate fields of "bonus_params" */
export type Bonus_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bonus_params". All fields are combined with a logical 'AND'. */
export type Bonus_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Params_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Params_Bool_Exp>>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  param_name?: InputMaybe<String_Comparison_Exp>;
  param_value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_params" */
export enum Bonus_Params_Constraint {
  /** unique or primary key constraint on columns "param_name", "bonus_id" */
  BonusParamsPkey = 'bonus_params_pkey'
}

/** input type for inserting data into table "bonus_params" */
export type Bonus_Params_Insert_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  param_name?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bonus_Params_Max_Fields = {
  __typename?: 'bonus_params_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  param_name?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bonus_Params_Min_Fields = {
  __typename?: 'bonus_params_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  param_name?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bonus_params" */
export type Bonus_Params_Mutation_Response = {
  __typename?: 'bonus_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Params>;
};

/** on_conflict condition type for table "bonus_params" */
export type Bonus_Params_On_Conflict = {
  constraint: Bonus_Params_Constraint;
  update_columns?: Array<Bonus_Params_Update_Column>;
  where?: InputMaybe<Bonus_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_params". */
export type Bonus_Params_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  param_name?: InputMaybe<Order_By>;
  param_value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_params */
export type Bonus_Params_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  param_name: Scalars['String'];
};

/** select columns of table "bonus_params" */
export enum Bonus_Params_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ParamName = 'param_name',
  /** column name */
  ParamValue = 'param_value'
}

/** input type for updating data in table "bonus_params" */
export type Bonus_Params_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  param_name?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "bonus_params" */
export type Bonus_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Params_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  param_name?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "bonus_params" */
export enum Bonus_Params_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ParamName = 'param_name',
  /** column name */
  ParamValue = 'param_value'
}

export type Bonus_Params_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Params_Bool_Exp;
};

/** columns and relationships of "bonus_wheel_rewards" */
export type Bonus_Wheel_Rewards = {
  __typename?: 'bonus_wheel_rewards';
  reward_description: Scalars['String'];
  reward_id: Scalars['uuid'];
  reward_image: Scalars['String'];
  reward_name: Scalars['String'];
  reward_type: Scalars['String'];
  reward_value: Scalars['String'];
  ticket_type_id: Scalars['uuid'];
};

/** aggregated selection of "bonus_wheel_rewards" */
export type Bonus_Wheel_Rewards_Aggregate = {
  __typename?: 'bonus_wheel_rewards_aggregate';
  aggregate?: Maybe<Bonus_Wheel_Rewards_Aggregate_Fields>;
  nodes: Array<Bonus_Wheel_Rewards>;
};

/** aggregate fields of "bonus_wheel_rewards" */
export type Bonus_Wheel_Rewards_Aggregate_Fields = {
  __typename?: 'bonus_wheel_rewards_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheel_Rewards_Max_Fields>;
  min?: Maybe<Bonus_Wheel_Rewards_Min_Fields>;
};


/** aggregate fields of "bonus_wheel_rewards" */
export type Bonus_Wheel_Rewards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheel_Rewards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bonus_wheel_rewards". All fields are combined with a logical 'AND'. */
export type Bonus_Wheel_Rewards_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheel_Rewards_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheel_Rewards_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheel_Rewards_Bool_Exp>>;
  reward_description?: InputMaybe<String_Comparison_Exp>;
  reward_id?: InputMaybe<Uuid_Comparison_Exp>;
  reward_image?: InputMaybe<String_Comparison_Exp>;
  reward_name?: InputMaybe<String_Comparison_Exp>;
  reward_type?: InputMaybe<String_Comparison_Exp>;
  reward_value?: InputMaybe<String_Comparison_Exp>;
  ticket_type_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheel_rewards" */
export enum Bonus_Wheel_Rewards_Constraint {
  /** unique or primary key constraint on columns "reward_id" */
  BonusWheelRewardsPkey = 'bonus_wheel_rewards_pkey'
}

/** input type for inserting data into table "bonus_wheel_rewards" */
export type Bonus_Wheel_Rewards_Insert_Input = {
  reward_description?: InputMaybe<Scalars['String']>;
  reward_id?: InputMaybe<Scalars['uuid']>;
  reward_image?: InputMaybe<Scalars['String']>;
  reward_name?: InputMaybe<Scalars['String']>;
  reward_type?: InputMaybe<Scalars['String']>;
  reward_value?: InputMaybe<Scalars['String']>;
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Bonus_Wheel_Rewards_Max_Fields = {
  __typename?: 'bonus_wheel_rewards_max_fields';
  reward_description?: Maybe<Scalars['String']>;
  reward_id?: Maybe<Scalars['uuid']>;
  reward_image?: Maybe<Scalars['String']>;
  reward_name?: Maybe<Scalars['String']>;
  reward_type?: Maybe<Scalars['String']>;
  reward_value?: Maybe<Scalars['String']>;
  ticket_type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Bonus_Wheel_Rewards_Min_Fields = {
  __typename?: 'bonus_wheel_rewards_min_fields';
  reward_description?: Maybe<Scalars['String']>;
  reward_id?: Maybe<Scalars['uuid']>;
  reward_image?: Maybe<Scalars['String']>;
  reward_name?: Maybe<Scalars['String']>;
  reward_type?: Maybe<Scalars['String']>;
  reward_value?: Maybe<Scalars['String']>;
  ticket_type_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "bonus_wheel_rewards" */
export type Bonus_Wheel_Rewards_Mutation_Response = {
  __typename?: 'bonus_wheel_rewards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheel_Rewards>;
};

/** input type for inserting object relation for remote table "bonus_wheel_rewards" */
export type Bonus_Wheel_Rewards_Obj_Rel_Insert_Input = {
  data: Bonus_Wheel_Rewards_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheel_Rewards_On_Conflict>;
};

/** on_conflict condition type for table "bonus_wheel_rewards" */
export type Bonus_Wheel_Rewards_On_Conflict = {
  constraint: Bonus_Wheel_Rewards_Constraint;
  update_columns?: Array<Bonus_Wheel_Rewards_Update_Column>;
  where?: InputMaybe<Bonus_Wheel_Rewards_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheel_rewards". */
export type Bonus_Wheel_Rewards_Order_By = {
  reward_description?: InputMaybe<Order_By>;
  reward_id?: InputMaybe<Order_By>;
  reward_image?: InputMaybe<Order_By>;
  reward_name?: InputMaybe<Order_By>;
  reward_type?: InputMaybe<Order_By>;
  reward_value?: InputMaybe<Order_By>;
  ticket_type_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheel_rewards */
export type Bonus_Wheel_Rewards_Pk_Columns_Input = {
  reward_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheel_rewards" */
export enum Bonus_Wheel_Rewards_Select_Column {
  /** column name */
  RewardDescription = 'reward_description',
  /** column name */
  RewardId = 'reward_id',
  /** column name */
  RewardImage = 'reward_image',
  /** column name */
  RewardName = 'reward_name',
  /** column name */
  RewardType = 'reward_type',
  /** column name */
  RewardValue = 'reward_value',
  /** column name */
  TicketTypeId = 'ticket_type_id'
}

/** input type for updating data in table "bonus_wheel_rewards" */
export type Bonus_Wheel_Rewards_Set_Input = {
  reward_description?: InputMaybe<Scalars['String']>;
  reward_id?: InputMaybe<Scalars['uuid']>;
  reward_image?: InputMaybe<Scalars['String']>;
  reward_name?: InputMaybe<Scalars['String']>;
  reward_type?: InputMaybe<Scalars['String']>;
  reward_value?: InputMaybe<Scalars['String']>;
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "bonus_wheel_rewards" */
export type Bonus_Wheel_Rewards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheel_Rewards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheel_Rewards_Stream_Cursor_Value_Input = {
  reward_description?: InputMaybe<Scalars['String']>;
  reward_id?: InputMaybe<Scalars['uuid']>;
  reward_image?: InputMaybe<Scalars['String']>;
  reward_name?: InputMaybe<Scalars['String']>;
  reward_type?: InputMaybe<Scalars['String']>;
  reward_value?: InputMaybe<Scalars['String']>;
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "bonus_wheel_rewards" */
export enum Bonus_Wheel_Rewards_Update_Column {
  /** column name */
  RewardDescription = 'reward_description',
  /** column name */
  RewardId = 'reward_id',
  /** column name */
  RewardImage = 'reward_image',
  /** column name */
  RewardName = 'reward_name',
  /** column name */
  RewardType = 'reward_type',
  /** column name */
  RewardValue = 'reward_value',
  /** column name */
  TicketTypeId = 'ticket_type_id'
}

export type Bonus_Wheel_Rewards_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheel_Rewards_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheel_Rewards_Bool_Exp;
};

/** columns and relationships of "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_Type = {
  __typename?: 'bonus_wheel_ticket_type';
  ticket_type_id: Scalars['uuid'];
  ticket_type_limit: Scalars['Int'];
  ticket_type_name: Scalars['String'];
  /** An array relationship */
  ticket_type_name_localizations: Array<Bonus_Wheel_Ticket_Type_Name_Localization>;
  /** An aggregate relationship */
  ticket_type_name_localizations_aggregate: Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate;
};


/** columns and relationships of "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_TypeTicket_Type_Name_LocalizationsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>;
};


/** columns and relationships of "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_TypeTicket_Type_Name_Localizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>;
};

/** aggregated selection of "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_Type_Aggregate = {
  __typename?: 'bonus_wheel_ticket_type_aggregate';
  aggregate?: Maybe<Bonus_Wheel_Ticket_Type_Aggregate_Fields>;
  nodes: Array<Bonus_Wheel_Ticket_Type>;
};

/** aggregate fields of "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_Type_Aggregate_Fields = {
  __typename?: 'bonus_wheel_ticket_type_aggregate_fields';
  avg?: Maybe<Bonus_Wheel_Ticket_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheel_Ticket_Type_Max_Fields>;
  min?: Maybe<Bonus_Wheel_Ticket_Type_Min_Fields>;
  stddev?: Maybe<Bonus_Wheel_Ticket_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Bonus_Wheel_Ticket_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonus_Wheel_Ticket_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Bonus_Wheel_Ticket_Type_Sum_Fields>;
  var_pop?: Maybe<Bonus_Wheel_Ticket_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Bonus_Wheel_Ticket_Type_Var_Samp_Fields>;
  variance?: Maybe<Bonus_Wheel_Ticket_Type_Variance_Fields>;
};


/** aggregate fields of "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bonus_Wheel_Ticket_Type_Avg_Fields = {
  __typename?: 'bonus_wheel_ticket_type_avg_fields';
  ticket_type_limit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bonus_wheel_ticket_type". All fields are combined with a logical 'AND'. */
export type Bonus_Wheel_Ticket_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheel_Ticket_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Bool_Exp>>;
  ticket_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  ticket_type_limit?: InputMaybe<Int_Comparison_Exp>;
  ticket_type_name?: InputMaybe<String_Comparison_Exp>;
  ticket_type_name_localizations?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>;
  ticket_type_name_localizations_aggregate?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "bonus_wheel_ticket_type" */
export enum Bonus_Wheel_Ticket_Type_Constraint {
  /** unique or primary key constraint on columns "ticket_type_id" */
  BonusWheelTicketTypePkey = 'bonus_wheel_ticket_type_pkey'
}

/** input type for incrementing numeric columns in table "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_Type_Inc_Input = {
  ticket_type_limit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_Type_Insert_Input = {
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
  ticket_type_limit?: InputMaybe<Scalars['Int']>;
  ticket_type_name?: InputMaybe<Scalars['String']>;
  ticket_type_name_localizations?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Bonus_Wheel_Ticket_Type_Max_Fields = {
  __typename?: 'bonus_wheel_ticket_type_max_fields';
  ticket_type_id?: Maybe<Scalars['uuid']>;
  ticket_type_limit?: Maybe<Scalars['Int']>;
  ticket_type_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bonus_Wheel_Ticket_Type_Min_Fields = {
  __typename?: 'bonus_wheel_ticket_type_min_fields';
  ticket_type_id?: Maybe<Scalars['uuid']>;
  ticket_type_limit?: Maybe<Scalars['Int']>;
  ticket_type_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_Type_Mutation_Response = {
  __typename?: 'bonus_wheel_ticket_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheel_Ticket_Type>;
};

/** Таблица локализации имен билетов бонусного барабана. ticket_type_name - оригинальное имя билета;  locale - локализация;  ticket_type_name_localized - локализованное имя билета */
export type Bonus_Wheel_Ticket_Type_Name_Localization = {
  __typename?: 'bonus_wheel_ticket_type_name_localization';
  /** An object relationship */
  bonus_wheel_ticket_type: Bonus_Wheel_Ticket_Type;
  locale: Scalars['String'];
  ticket_type_id: Scalars['uuid'];
  ticket_type_name_localized: Scalars['String'];
};

/** aggregated selection of "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate = {
  __typename?: 'bonus_wheel_ticket_type_name_localization_aggregate';
  aggregate?: Maybe<Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate_Fields>;
  nodes: Array<Bonus_Wheel_Ticket_Type_Name_Localization>;
};

export type Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate_Bool_Exp_Count>;
};

export type Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate_Fields = {
  __typename?: 'bonus_wheel_ticket_type_name_localization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheel_Ticket_Type_Name_Localization_Max_Fields>;
  min?: Maybe<Bonus_Wheel_Ticket_Type_Name_Localization_Min_Fields>;
};


/** aggregate fields of "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Max_Order_By>;
  min?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Min_Order_By>;
};

/** input type for inserting array relation for remote table "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Arr_Rel_Insert_Input = {
  data: Array<Bonus_Wheel_Ticket_Type_Name_Localization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_On_Conflict>;
};

/** Boolean expression to filter rows from the table "bonus_wheel_ticket_type_name_localization". All fields are combined with a logical 'AND'. */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>>;
  bonus_wheel_ticket_type?: InputMaybe<Bonus_Wheel_Ticket_Type_Bool_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  ticket_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  ticket_type_name_localized?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheel_ticket_type_name_localization" */
export enum Bonus_Wheel_Ticket_Type_Name_Localization_Constraint {
  /** unique or primary key constraint on columns "locale", "ticket_type_id" */
  TicketTypeNameLocalizationPkey = 'ticket_type_name_localization_pkey'
}

/** input type for inserting data into table "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Insert_Input = {
  bonus_wheel_ticket_type?: InputMaybe<Bonus_Wheel_Ticket_Type_Obj_Rel_Insert_Input>;
  locale?: InputMaybe<Scalars['String']>;
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
  ticket_type_name_localized?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Max_Fields = {
  __typename?: 'bonus_wheel_ticket_type_name_localization_max_fields';
  locale?: Maybe<Scalars['String']>;
  ticket_type_id?: Maybe<Scalars['uuid']>;
  ticket_type_name_localized?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Max_Order_By = {
  locale?: InputMaybe<Order_By>;
  ticket_type_id?: InputMaybe<Order_By>;
  ticket_type_name_localized?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Min_Fields = {
  __typename?: 'bonus_wheel_ticket_type_name_localization_min_fields';
  locale?: Maybe<Scalars['String']>;
  ticket_type_id?: Maybe<Scalars['uuid']>;
  ticket_type_name_localized?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Min_Order_By = {
  locale?: InputMaybe<Order_By>;
  ticket_type_id?: InputMaybe<Order_By>;
  ticket_type_name_localized?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Mutation_Response = {
  __typename?: 'bonus_wheel_ticket_type_name_localization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheel_Ticket_Type_Name_Localization>;
};

/** on_conflict condition type for table "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_On_Conflict = {
  constraint: Bonus_Wheel_Ticket_Type_Name_Localization_Constraint;
  update_columns?: Array<Bonus_Wheel_Ticket_Type_Name_Localization_Update_Column>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheel_ticket_type_name_localization". */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Order_By = {
  bonus_wheel_ticket_type?: InputMaybe<Bonus_Wheel_Ticket_Type_Order_By>;
  locale?: InputMaybe<Order_By>;
  ticket_type_id?: InputMaybe<Order_By>;
  ticket_type_name_localized?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheel_ticket_type_name_localization */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Pk_Columns_Input = {
  locale: Scalars['String'];
  ticket_type_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheel_ticket_type_name_localization" */
export enum Bonus_Wheel_Ticket_Type_Name_Localization_Select_Column {
  /** column name */
  Locale = 'locale',
  /** column name */
  TicketTypeId = 'ticket_type_id',
  /** column name */
  TicketTypeNameLocalized = 'ticket_type_name_localized'
}

/** input type for updating data in table "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Set_Input = {
  locale?: InputMaybe<Scalars['String']>;
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
  ticket_type_name_localized?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "bonus_wheel_ticket_type_name_localization" */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheel_Ticket_Type_Name_Localization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheel_Ticket_Type_Name_Localization_Stream_Cursor_Value_Input = {
  locale?: InputMaybe<Scalars['String']>;
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
  ticket_type_name_localized?: InputMaybe<Scalars['String']>;
};

/** update columns of table "bonus_wheel_ticket_type_name_localization" */
export enum Bonus_Wheel_Ticket_Type_Name_Localization_Update_Column {
  /** column name */
  Locale = 'locale',
  /** column name */
  TicketTypeId = 'ticket_type_id',
  /** column name */
  TicketTypeNameLocalized = 'ticket_type_name_localized'
}

export type Bonus_Wheel_Ticket_Type_Name_Localization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp;
};

/** input type for inserting object relation for remote table "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_Type_Obj_Rel_Insert_Input = {
  data: Bonus_Wheel_Ticket_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheel_Ticket_Type_On_Conflict>;
};

/** on_conflict condition type for table "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_Type_On_Conflict = {
  constraint: Bonus_Wheel_Ticket_Type_Constraint;
  update_columns?: Array<Bonus_Wheel_Ticket_Type_Update_Column>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheel_ticket_type". */
export type Bonus_Wheel_Ticket_Type_Order_By = {
  ticket_type_id?: InputMaybe<Order_By>;
  ticket_type_limit?: InputMaybe<Order_By>;
  ticket_type_name?: InputMaybe<Order_By>;
  ticket_type_name_localizations_aggregate?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate_Order_By>;
};

/** primary key columns input for table: bonus_wheel_ticket_type */
export type Bonus_Wheel_Ticket_Type_Pk_Columns_Input = {
  ticket_type_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheel_ticket_type" */
export enum Bonus_Wheel_Ticket_Type_Select_Column {
  /** column name */
  TicketTypeId = 'ticket_type_id',
  /** column name */
  TicketTypeLimit = 'ticket_type_limit',
  /** column name */
  TicketTypeName = 'ticket_type_name'
}

/** input type for updating data in table "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_Type_Set_Input = {
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
  ticket_type_limit?: InputMaybe<Scalars['Int']>;
  ticket_type_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Bonus_Wheel_Ticket_Type_Stddev_Fields = {
  __typename?: 'bonus_wheel_ticket_type_stddev_fields';
  ticket_type_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bonus_Wheel_Ticket_Type_Stddev_Pop_Fields = {
  __typename?: 'bonus_wheel_ticket_type_stddev_pop_fields';
  ticket_type_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bonus_Wheel_Ticket_Type_Stddev_Samp_Fields = {
  __typename?: 'bonus_wheel_ticket_type_stddev_samp_fields';
  ticket_type_limit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bonus_wheel_ticket_type" */
export type Bonus_Wheel_Ticket_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheel_Ticket_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheel_Ticket_Type_Stream_Cursor_Value_Input = {
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
  ticket_type_limit?: InputMaybe<Scalars['Int']>;
  ticket_type_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Bonus_Wheel_Ticket_Type_Sum_Fields = {
  __typename?: 'bonus_wheel_ticket_type_sum_fields';
  ticket_type_limit?: Maybe<Scalars['Int']>;
};

/** update columns of table "bonus_wheel_ticket_type" */
export enum Bonus_Wheel_Ticket_Type_Update_Column {
  /** column name */
  TicketTypeId = 'ticket_type_id',
  /** column name */
  TicketTypeLimit = 'ticket_type_limit',
  /** column name */
  TicketTypeName = 'ticket_type_name'
}

export type Bonus_Wheel_Ticket_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonus_Wheel_Ticket_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheel_Ticket_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheel_Ticket_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonus_Wheel_Ticket_Type_Var_Pop_Fields = {
  __typename?: 'bonus_wheel_ticket_type_var_pop_fields';
  ticket_type_limit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bonus_Wheel_Ticket_Type_Var_Samp_Fields = {
  __typename?: 'bonus_wheel_ticket_type_var_samp_fields';
  ticket_type_limit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bonus_Wheel_Ticket_Type_Variance_Fields = {
  __typename?: 'bonus_wheel_ticket_type_variance_fields';
  ticket_type_limit?: Maybe<Scalars['Float']>;
};

/** claimed - была ли забрана награда */
export type Bonus_Wheel_User_Rewards = {
  __typename?: 'bonus_wheel_user_rewards';
  /** An object relationship */
  bonus_wheel_reward: Bonus_Wheel_Rewards;
  claimed: Scalars['Boolean'];
  created_at?: Maybe<Scalars['timestamptz']>;
  reward_id: Scalars['uuid'];
  ticket_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['uuid'];
  user_reward_id: Scalars['uuid'];
};

/** aggregated selection of "bonus_wheel_user_rewards" */
export type Bonus_Wheel_User_Rewards_Aggregate = {
  __typename?: 'bonus_wheel_user_rewards_aggregate';
  aggregate?: Maybe<Bonus_Wheel_User_Rewards_Aggregate_Fields>;
  nodes: Array<Bonus_Wheel_User_Rewards>;
};

/** aggregate fields of "bonus_wheel_user_rewards" */
export type Bonus_Wheel_User_Rewards_Aggregate_Fields = {
  __typename?: 'bonus_wheel_user_rewards_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheel_User_Rewards_Max_Fields>;
  min?: Maybe<Bonus_Wheel_User_Rewards_Min_Fields>;
};


/** aggregate fields of "bonus_wheel_user_rewards" */
export type Bonus_Wheel_User_Rewards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheel_User_Rewards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bonus_wheel_user_rewards". All fields are combined with a logical 'AND'. */
export type Bonus_Wheel_User_Rewards_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheel_User_Rewards_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheel_User_Rewards_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheel_User_Rewards_Bool_Exp>>;
  bonus_wheel_reward?: InputMaybe<Bonus_Wheel_Rewards_Bool_Exp>;
  claimed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  reward_id?: InputMaybe<Uuid_Comparison_Exp>;
  ticket_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_reward_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheel_user_rewards" */
export enum Bonus_Wheel_User_Rewards_Constraint {
  /** unique or primary key constraint on columns "user_reward_id" */
  BonusWheelUserRewardsPkey = 'bonus_wheel_user_rewards_pkey'
}

/** input type for inserting data into table "bonus_wheel_user_rewards" */
export type Bonus_Wheel_User_Rewards_Insert_Input = {
  bonus_wheel_reward?: InputMaybe<Bonus_Wheel_Rewards_Obj_Rel_Insert_Input>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  reward_id?: InputMaybe<Scalars['uuid']>;
  ticket_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_reward_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Bonus_Wheel_User_Rewards_Max_Fields = {
  __typename?: 'bonus_wheel_user_rewards_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  reward_id?: Maybe<Scalars['uuid']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_reward_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Bonus_Wheel_User_Rewards_Min_Fields = {
  __typename?: 'bonus_wheel_user_rewards_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  reward_id?: Maybe<Scalars['uuid']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_reward_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "bonus_wheel_user_rewards" */
export type Bonus_Wheel_User_Rewards_Mutation_Response = {
  __typename?: 'bonus_wheel_user_rewards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheel_User_Rewards>;
};

/** on_conflict condition type for table "bonus_wheel_user_rewards" */
export type Bonus_Wheel_User_Rewards_On_Conflict = {
  constraint: Bonus_Wheel_User_Rewards_Constraint;
  update_columns?: Array<Bonus_Wheel_User_Rewards_Update_Column>;
  where?: InputMaybe<Bonus_Wheel_User_Rewards_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheel_user_rewards". */
export type Bonus_Wheel_User_Rewards_Order_By = {
  bonus_wheel_reward?: InputMaybe<Bonus_Wheel_Rewards_Order_By>;
  claimed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  reward_id?: InputMaybe<Order_By>;
  ticket_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_reward_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheel_user_rewards */
export type Bonus_Wheel_User_Rewards_Pk_Columns_Input = {
  user_reward_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheel_user_rewards" */
export enum Bonus_Wheel_User_Rewards_Select_Column {
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  RewardId = 'reward_id',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserRewardId = 'user_reward_id'
}

/** input type for updating data in table "bonus_wheel_user_rewards" */
export type Bonus_Wheel_User_Rewards_Set_Input = {
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  reward_id?: InputMaybe<Scalars['uuid']>;
  ticket_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_reward_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "bonus_wheel_user_rewards" */
export type Bonus_Wheel_User_Rewards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheel_User_Rewards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheel_User_Rewards_Stream_Cursor_Value_Input = {
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  reward_id?: InputMaybe<Scalars['uuid']>;
  ticket_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_reward_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "bonus_wheel_user_rewards" */
export enum Bonus_Wheel_User_Rewards_Update_Column {
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  RewardId = 'reward_id',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserRewardId = 'user_reward_id'
}

export type Bonus_Wheel_User_Rewards_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheel_User_Rewards_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheel_User_Rewards_Bool_Exp;
};

/** used - был ли использован билет */
export type Bonus_Wheel_User_Tickets = {
  __typename?: 'bonus_wheel_user_tickets';
  /** An object relationship */
  bonus_wheel_ticket_type: Bonus_Wheel_Ticket_Type;
  created_at: Scalars['timestamptz'];
  /** Флаг, указывающий, что билет был получет в качестве награды */
  is_reward: Scalars['Boolean'];
  ticket_id: Scalars['uuid'];
  ticket_type_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  used: Scalars['Boolean'];
  user_account?: Maybe<User_Account>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "bonus_wheel_user_tickets" */
export type Bonus_Wheel_User_Tickets_Aggregate = {
  __typename?: 'bonus_wheel_user_tickets_aggregate';
  aggregate?: Maybe<Bonus_Wheel_User_Tickets_Aggregate_Fields>;
  nodes: Array<Bonus_Wheel_User_Tickets>;
};

/** aggregate fields of "bonus_wheel_user_tickets" */
export type Bonus_Wheel_User_Tickets_Aggregate_Fields = {
  __typename?: 'bonus_wheel_user_tickets_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheel_User_Tickets_Max_Fields>;
  min?: Maybe<Bonus_Wheel_User_Tickets_Min_Fields>;
};


/** aggregate fields of "bonus_wheel_user_tickets" */
export type Bonus_Wheel_User_Tickets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bonus_wheel_user_tickets". All fields are combined with a logical 'AND'. */
export type Bonus_Wheel_User_Tickets_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheel_User_Tickets_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Bool_Exp>>;
  bonus_wheel_ticket_type?: InputMaybe<Bonus_Wheel_Ticket_Type_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  is_reward?: InputMaybe<Boolean_Comparison_Exp>;
  ticket_id?: InputMaybe<Uuid_Comparison_Exp>;
  ticket_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  used?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheel_user_tickets" */
export enum Bonus_Wheel_User_Tickets_Constraint {
  /** unique or primary key constraint on columns "ticket_id" */
  BonusWheelUserTicketsPkey = 'bonus_wheel_user_tickets_pkey'
}

/** input type for inserting data into table "bonus_wheel_user_tickets" */
export type Bonus_Wheel_User_Tickets_Insert_Input = {
  bonus_wheel_ticket_type?: InputMaybe<Bonus_Wheel_Ticket_Type_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Флаг, указывающий, что билет был получет в качестве награды */
  is_reward?: InputMaybe<Scalars['Boolean']>;
  ticket_id?: InputMaybe<Scalars['uuid']>;
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Bonus_Wheel_User_Tickets_Max_Fields = {
  __typename?: 'bonus_wheel_user_tickets_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  ticket_type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Bonus_Wheel_User_Tickets_Min_Fields = {
  __typename?: 'bonus_wheel_user_tickets_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ticket_id?: Maybe<Scalars['uuid']>;
  ticket_type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "bonus_wheel_user_tickets" */
export type Bonus_Wheel_User_Tickets_Mutation_Response = {
  __typename?: 'bonus_wheel_user_tickets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheel_User_Tickets>;
};

/** on_conflict condition type for table "bonus_wheel_user_tickets" */
export type Bonus_Wheel_User_Tickets_On_Conflict = {
  constraint: Bonus_Wheel_User_Tickets_Constraint;
  update_columns?: Array<Bonus_Wheel_User_Tickets_Update_Column>;
  where?: InputMaybe<Bonus_Wheel_User_Tickets_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheel_user_tickets". */
export type Bonus_Wheel_User_Tickets_Order_By = {
  bonus_wheel_ticket_type?: InputMaybe<Bonus_Wheel_Ticket_Type_Order_By>;
  created_at?: InputMaybe<Order_By>;
  is_reward?: InputMaybe<Order_By>;
  ticket_id?: InputMaybe<Order_By>;
  ticket_type_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  used?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheel_user_tickets */
export type Bonus_Wheel_User_Tickets_Pk_Columns_Input = {
  ticket_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheel_user_tickets" */
export enum Bonus_Wheel_User_Tickets_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsReward = 'is_reward',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  TicketTypeId = 'ticket_type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "bonus_wheel_user_tickets" */
export type Bonus_Wheel_User_Tickets_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Флаг, указывающий, что билет был получет в качестве награды */
  is_reward?: InputMaybe<Scalars['Boolean']>;
  ticket_id?: InputMaybe<Scalars['uuid']>;
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "bonus_wheel_user_tickets" */
export type Bonus_Wheel_User_Tickets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheel_User_Tickets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheel_User_Tickets_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** Флаг, указывающий, что билет был получет в качестве награды */
  is_reward?: InputMaybe<Scalars['Boolean']>;
  ticket_id?: InputMaybe<Scalars['uuid']>;
  ticket_type_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "bonus_wheel_user_tickets" */
export enum Bonus_Wheel_User_Tickets_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsReward = 'is_reward',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  TicketTypeId = 'ticket_type_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used',
  /** column name */
  UserId = 'user_id'
}

export type Bonus_Wheel_User_Tickets_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheel_User_Tickets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheel_User_Tickets_Bool_Exp;
};

/** columns and relationships of "bonus_wheels" */
export type Bonus_Wheels = {
  __typename?: 'bonus_wheels';
  /** An array relationship */
  bonuses_in_wheel: Array<Bonus_Wheels_Items_In_Wheel>;
  /** An aggregate relationship */
  bonuses_in_wheel_aggregate: Bonus_Wheels_Items_In_Wheel_Aggregate;
  wheel_description?: Maybe<Scalars['String']>;
  wheel_id: Scalars['uuid'];
  wheel_name: Scalars['String'];
  wheel_type: Scalars['String'];
};


/** columns and relationships of "bonus_wheels" */
export type Bonus_WheelsBonuses_In_WheelArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Bool_Exp>;
};


/** columns and relationships of "bonus_wheels" */
export type Bonus_WheelsBonuses_In_Wheel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Bool_Exp>;
};

/** aggregated selection of "bonus_wheels" */
export type Bonus_Wheels_Aggregate = {
  __typename?: 'bonus_wheels_aggregate';
  aggregate?: Maybe<Bonus_Wheels_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels>;
};

/** aggregate fields of "bonus_wheels" */
export type Bonus_Wheels_Aggregate_Fields = {
  __typename?: 'bonus_wheels_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_Max_Fields>;
  min?: Maybe<Bonus_Wheels_Min_Fields>;
};


/** aggregate fields of "bonus_wheels" */
export type Bonus_Wheels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins = {
  __typename?: 'bonus_wheels_bonus_skins';
  bonus_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Float'];
  /** An object relationship */
  locales?: Maybe<Admin_Items_Names>;
};

/** aggregated selection of "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Aggregate = {
  __typename?: 'bonus_wheels_bonus_skins_aggregate';
  aggregate?: Maybe<Bonus_Wheels_Bonus_Skins_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_Bonus_Skins>;
};

export type Bonus_Wheels_Bonus_Skins_Aggregate_Bool_Exp = {
  count?: InputMaybe<Bonus_Wheels_Bonus_Skins_Aggregate_Bool_Exp_Count>;
};

export type Bonus_Wheels_Bonus_Skins_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bonus_Wheels_Bonus_Skins_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Aggregate_Fields = {
  __typename?: 'bonus_wheels_bonus_skins_aggregate_fields';
  avg?: Maybe<Bonus_Wheels_Bonus_Skins_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_Bonus_Skins_Max_Fields>;
  min?: Maybe<Bonus_Wheels_Bonus_Skins_Min_Fields>;
  stddev?: Maybe<Bonus_Wheels_Bonus_Skins_Stddev_Fields>;
  stddev_pop?: Maybe<Bonus_Wheels_Bonus_Skins_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonus_Wheels_Bonus_Skins_Stddev_Samp_Fields>;
  sum?: Maybe<Bonus_Wheels_Bonus_Skins_Sum_Fields>;
  var_pop?: Maybe<Bonus_Wheels_Bonus_Skins_Var_Pop_Fields>;
  var_samp?: Maybe<Bonus_Wheels_Bonus_Skins_Var_Samp_Fields>;
  variance?: Maybe<Bonus_Wheels_Bonus_Skins_Variance_Fields>;
};


/** aggregate fields of "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Aggregate_Order_By = {
  avg?: InputMaybe<Bonus_Wheels_Bonus_Skins_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bonus_Wheels_Bonus_Skins_Max_Order_By>;
  min?: InputMaybe<Bonus_Wheels_Bonus_Skins_Min_Order_By>;
  stddev?: InputMaybe<Bonus_Wheels_Bonus_Skins_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bonus_Wheels_Bonus_Skins_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bonus_Wheels_Bonus_Skins_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bonus_Wheels_Bonus_Skins_Sum_Order_By>;
  var_pop?: InputMaybe<Bonus_Wheels_Bonus_Skins_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bonus_Wheels_Bonus_Skins_Var_Samp_Order_By>;
  variance?: InputMaybe<Bonus_Wheels_Bonus_Skins_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Arr_Rel_Insert_Input = {
  data: Array<Bonus_Wheels_Bonus_Skins_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheels_Bonus_Skins_On_Conflict>;
};

/** aggregate avg on columns */
export type Bonus_Wheels_Bonus_Skins_Avg_Fields = {
  __typename?: 'bonus_wheels_bonus_skins_avg_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Avg_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_bonus_skins". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_Bonus_Skins_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_Bonus_Skins_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Bool_Exp>>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_image_link?: InputMaybe<String_Comparison_Exp>;
  item_quality?: InputMaybe<String_Comparison_Exp>;
  item_rarity?: InputMaybe<String_Comparison_Exp>;
  item_start_price?: InputMaybe<Float_Comparison_Exp>;
  locales?: InputMaybe<Admin_Items_Names_Bool_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_bonus_skins" */
export enum Bonus_Wheels_Bonus_Skins_Constraint {
  /** unique or primary key constraint on columns "item_id", "bonus_id" */
  BonusWheelsBonusSkinsPkey = 'bonus_wheels_bonus_skins_pkey'
}

/** input type for incrementing numeric columns in table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Inc_Input = {
  item_start_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Insert_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  locales?: InputMaybe<Admin_Items_Names_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Bonus_Wheels_Bonus_Skins_Max_Fields = {
  __typename?: 'bonus_wheels_bonus_skins_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Max_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bonus_Wheels_Bonus_Skins_Min_Fields = {
  __typename?: 'bonus_wheels_bonus_skins_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Min_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Mutation_Response = {
  __typename?: 'bonus_wheels_bonus_skins_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_Bonus_Skins>;
};

/** on_conflict condition type for table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_On_Conflict = {
  constraint: Bonus_Wheels_Bonus_Skins_Constraint;
  update_columns?: Array<Bonus_Wheels_Bonus_Skins_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_Bonus_Skins_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_bonus_skins". */
export type Bonus_Wheels_Bonus_Skins_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  locales?: InputMaybe<Admin_Items_Names_Order_By>;
};

/** primary key columns input for table: bonus_wheels_bonus_skins */
export type Bonus_Wheels_Bonus_Skins_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  item_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheels_bonus_skins" */
export enum Bonus_Wheels_Bonus_Skins_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price'
}

/** input type for updating data in table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Bonus_Wheels_Bonus_Skins_Stddev_Fields = {
  __typename?: 'bonus_wheels_bonus_skins_stddev_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Stddev_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bonus_Wheels_Bonus_Skins_Stddev_Pop_Fields = {
  __typename?: 'bonus_wheels_bonus_skins_stddev_pop_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Stddev_Pop_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bonus_Wheels_Bonus_Skins_Stddev_Samp_Fields = {
  __typename?: 'bonus_wheels_bonus_skins_stddev_samp_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Stddev_Samp_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_Bonus_Skins_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_Bonus_Skins_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Bonus_Wheels_Bonus_Skins_Sum_Fields = {
  __typename?: 'bonus_wheels_bonus_skins_sum_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Sum_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** update columns of table "bonus_wheels_bonus_skins" */
export enum Bonus_Wheels_Bonus_Skins_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price'
}

export type Bonus_Wheels_Bonus_Skins_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonus_Wheels_Bonus_Skins_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_Bonus_Skins_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_Bonus_Skins_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonus_Wheels_Bonus_Skins_Var_Pop_Fields = {
  __typename?: 'bonus_wheels_bonus_skins_var_pop_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Var_Pop_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bonus_Wheels_Bonus_Skins_Var_Samp_Fields = {
  __typename?: 'bonus_wheels_bonus_skins_var_samp_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Var_Samp_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bonus_Wheels_Bonus_Skins_Variance_Fields = {
  __typename?: 'bonus_wheels_bonus_skins_variance_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bonus_wheels_bonus_skins" */
export type Bonus_Wheels_Bonus_Skins_Variance_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "bonus_wheels_bonuses" */
export type Bonus_Wheels_Bonuses = {
  __typename?: 'bonus_wheels_bonuses';
  bonus_description: Scalars['String'];
  bonus_id: Scalars['uuid'];
  bonus_image: Scalars['String'];
  bonus_name: Scalars['String'];
  bonus_param: Scalars['String'];
  /** An object relationship */
  bonus_params?: Maybe<Bonus_Wheels_Bonuses_Params>;
  /** An array relationship */
  bonus_skins: Array<Bonus_Wheels_Bonus_Skins>;
  /** An aggregate relationship */
  bonus_skins_aggregate: Bonus_Wheels_Bonus_Skins_Aggregate;
  bonus_type: Scalars['String'];
  bonus_value: Scalars['Int'];
  /** An array relationship */
  localization: Array<Bonus_Wheels_Bonuses_Localization>;
  /** An aggregate relationship */
  localization_aggregate: Bonus_Wheels_Bonuses_Localization_Aggregate;
};


/** columns and relationships of "bonus_wheels_bonuses" */
export type Bonus_Wheels_BonusesBonus_SkinsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonus_Skins_Bool_Exp>;
};


/** columns and relationships of "bonus_wheels_bonuses" */
export type Bonus_Wheels_BonusesBonus_Skins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonus_Skins_Bool_Exp>;
};


/** columns and relationships of "bonus_wheels_bonuses" */
export type Bonus_Wheels_BonusesLocalizationArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Bool_Exp>;
};


/** columns and relationships of "bonus_wheels_bonuses" */
export type Bonus_Wheels_BonusesLocalization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Bool_Exp>;
};

/** aggregated selection of "bonus_wheels_bonuses" */
export type Bonus_Wheels_Bonuses_Aggregate = {
  __typename?: 'bonus_wheels_bonuses_aggregate';
  aggregate?: Maybe<Bonus_Wheels_Bonuses_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_Bonuses>;
};

/** aggregate fields of "bonus_wheels_bonuses" */
export type Bonus_Wheels_Bonuses_Aggregate_Fields = {
  __typename?: 'bonus_wheels_bonuses_aggregate_fields';
  avg?: Maybe<Bonus_Wheels_Bonuses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_Bonuses_Max_Fields>;
  min?: Maybe<Bonus_Wheels_Bonuses_Min_Fields>;
  stddev?: Maybe<Bonus_Wheels_Bonuses_Stddev_Fields>;
  stddev_pop?: Maybe<Bonus_Wheels_Bonuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonus_Wheels_Bonuses_Stddev_Samp_Fields>;
  sum?: Maybe<Bonus_Wheels_Bonuses_Sum_Fields>;
  var_pop?: Maybe<Bonus_Wheels_Bonuses_Var_Pop_Fields>;
  var_samp?: Maybe<Bonus_Wheels_Bonuses_Var_Samp_Fields>;
  variance?: Maybe<Bonus_Wheels_Bonuses_Variance_Fields>;
};


/** aggregate fields of "bonus_wheels_bonuses" */
export type Bonus_Wheels_Bonuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_Bonuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bonus_Wheels_Bonuses_Avg_Fields = {
  __typename?: 'bonus_wheels_bonuses_avg_fields';
  bonus_value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_bonuses". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_Bonuses_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_Bonuses_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_Bonuses_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_Bonuses_Bool_Exp>>;
  bonus_description?: InputMaybe<String_Comparison_Exp>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  bonus_image?: InputMaybe<String_Comparison_Exp>;
  bonus_name?: InputMaybe<String_Comparison_Exp>;
  bonus_param?: InputMaybe<String_Comparison_Exp>;
  bonus_params?: InputMaybe<Bonus_Wheels_Bonuses_Params_Bool_Exp>;
  bonus_skins?: InputMaybe<Bonus_Wheels_Bonus_Skins_Bool_Exp>;
  bonus_skins_aggregate?: InputMaybe<Bonus_Wheels_Bonus_Skins_Aggregate_Bool_Exp>;
  bonus_type?: InputMaybe<String_Comparison_Exp>;
  bonus_value?: InputMaybe<Int_Comparison_Exp>;
  localization?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Bool_Exp>;
  localization_aggregate?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_bonuses" */
export enum Bonus_Wheels_Bonuses_Constraint {
  /** unique or primary key constraint on columns "bonus_id" */
  BonusWheelsBonusesPkey = 'bonus_wheels_bonuses_pkey'
}

/** input type for incrementing numeric columns in table "bonus_wheels_bonuses" */
export type Bonus_Wheels_Bonuses_Inc_Input = {
  bonus_value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bonus_wheels_bonuses" */
export type Bonus_Wheels_Bonuses_Insert_Input = {
  bonus_description?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_image?: InputMaybe<Scalars['String']>;
  bonus_name?: InputMaybe<Scalars['String']>;
  bonus_param?: InputMaybe<Scalars['String']>;
  bonus_params?: InputMaybe<Bonus_Wheels_Bonuses_Params_Obj_Rel_Insert_Input>;
  bonus_skins?: InputMaybe<Bonus_Wheels_Bonus_Skins_Arr_Rel_Insert_Input>;
  bonus_type?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['Int']>;
  localization?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization = {
  __typename?: 'bonus_wheels_bonuses_localization';
  bonus_description_localized: Scalars['String'];
  bonus_id: Scalars['uuid'];
  bonus_name_localized: Scalars['String'];
  locale: Scalars['String'];
};

/** aggregated selection of "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_Aggregate = {
  __typename?: 'bonus_wheels_bonuses_localization_aggregate';
  aggregate?: Maybe<Bonus_Wheels_Bonuses_Localization_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_Bonuses_Localization>;
};

export type Bonus_Wheels_Bonuses_Localization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Aggregate_Bool_Exp_Count>;
};

export type Bonus_Wheels_Bonuses_Localization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_Aggregate_Fields = {
  __typename?: 'bonus_wheels_bonuses_localization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_Bonuses_Localization_Max_Fields>;
  min?: Maybe<Bonus_Wheels_Bonuses_Localization_Min_Fields>;
};


/** aggregate fields of "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Max_Order_By>;
  min?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Min_Order_By>;
};

/** input type for inserting array relation for remote table "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_Arr_Rel_Insert_Input = {
  data: Array<Bonus_Wheels_Bonuses_Localization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_Localization_On_Conflict>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_bonuses_localization". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_Bonuses_Localization_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Bool_Exp>>;
  bonus_description_localized?: InputMaybe<String_Comparison_Exp>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  bonus_name_localized?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_bonuses_localization" */
export enum Bonus_Wheels_Bonuses_Localization_Constraint {
  /** unique or primary key constraint on columns "bonus_id", "locale" */
  BonusWheelsBonusesNamesPkey = 'bonus_wheels_bonuses_names_pkey'
}

/** input type for inserting data into table "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_Insert_Input = {
  bonus_description_localized?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_name_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bonus_Wheels_Bonuses_Localization_Max_Fields = {
  __typename?: 'bonus_wheels_bonuses_localization_max_fields';
  bonus_description_localized?: Maybe<Scalars['String']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_name_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_Max_Order_By = {
  bonus_description_localized?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  bonus_name_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bonus_Wheels_Bonuses_Localization_Min_Fields = {
  __typename?: 'bonus_wheels_bonuses_localization_min_fields';
  bonus_description_localized?: Maybe<Scalars['String']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_name_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_Min_Order_By = {
  bonus_description_localized?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  bonus_name_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_Mutation_Response = {
  __typename?: 'bonus_wheels_bonuses_localization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_Bonuses_Localization>;
};

/** on_conflict condition type for table "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_On_Conflict = {
  constraint: Bonus_Wheels_Bonuses_Localization_Constraint;
  update_columns?: Array<Bonus_Wheels_Bonuses_Localization_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_bonuses_localization". */
export type Bonus_Wheels_Bonuses_Localization_Order_By = {
  bonus_description_localized?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  bonus_name_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheels_bonuses_localization */
export type Bonus_Wheels_Bonuses_Localization_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
};

/** select columns of table "bonus_wheels_bonuses_localization" */
export enum Bonus_Wheels_Bonuses_Localization_Select_Column {
  /** column name */
  BonusDescriptionLocalized = 'bonus_description_localized',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusNameLocalized = 'bonus_name_localized',
  /** column name */
  Locale = 'locale'
}

/** input type for updating data in table "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_Set_Input = {
  bonus_description_localized?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_name_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "bonus_wheels_bonuses_localization" */
export type Bonus_Wheels_Bonuses_Localization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_Bonuses_Localization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_Bonuses_Localization_Stream_Cursor_Value_Input = {
  bonus_description_localized?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_name_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** update columns of table "bonus_wheels_bonuses_localization" */
export enum Bonus_Wheels_Bonuses_Localization_Update_Column {
  /** column name */
  BonusDescriptionLocalized = 'bonus_description_localized',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusNameLocalized = 'bonus_name_localized',
  /** column name */
  Locale = 'locale'
}

export type Bonus_Wheels_Bonuses_Localization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_Bonuses_Localization_Bool_Exp;
};

/** aggregate max on columns */
export type Bonus_Wheels_Bonuses_Max_Fields = {
  __typename?: 'bonus_wheels_bonuses_max_fields';
  bonus_description?: Maybe<Scalars['String']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_image?: Maybe<Scalars['String']>;
  bonus_name?: Maybe<Scalars['String']>;
  bonus_param?: Maybe<Scalars['String']>;
  bonus_type?: Maybe<Scalars['String']>;
  bonus_value?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Bonus_Wheels_Bonuses_Min_Fields = {
  __typename?: 'bonus_wheels_bonuses_min_fields';
  bonus_description?: Maybe<Scalars['String']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_image?: Maybe<Scalars['String']>;
  bonus_name?: Maybe<Scalars['String']>;
  bonus_param?: Maybe<Scalars['String']>;
  bonus_type?: Maybe<Scalars['String']>;
  bonus_value?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "bonus_wheels_bonuses" */
export type Bonus_Wheels_Bonuses_Mutation_Response = {
  __typename?: 'bonus_wheels_bonuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_Bonuses>;
};

/** input type for inserting object relation for remote table "bonus_wheels_bonuses" */
export type Bonus_Wheels_Bonuses_Obj_Rel_Insert_Input = {
  data: Bonus_Wheels_Bonuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_On_Conflict>;
};

/** on_conflict condition type for table "bonus_wheels_bonuses" */
export type Bonus_Wheels_Bonuses_On_Conflict = {
  constraint: Bonus_Wheels_Bonuses_Constraint;
  update_columns?: Array<Bonus_Wheels_Bonuses_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_bonuses". */
export type Bonus_Wheels_Bonuses_Order_By = {
  bonus_description?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  bonus_image?: InputMaybe<Order_By>;
  bonus_name?: InputMaybe<Order_By>;
  bonus_param?: InputMaybe<Order_By>;
  bonus_params?: InputMaybe<Bonus_Wheels_Bonuses_Params_Order_By>;
  bonus_skins_aggregate?: InputMaybe<Bonus_Wheels_Bonus_Skins_Aggregate_Order_By>;
  bonus_type?: InputMaybe<Order_By>;
  bonus_value?: InputMaybe<Order_By>;
  localization_aggregate?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Aggregate_Order_By>;
};

/** columns and relationships of "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_Params = {
  __typename?: 'bonus_wheels_bonuses_params';
  bonus_description: Scalars['String'];
  bonus_id: Scalars['uuid'];
  bonus_image: Scalars['String'];
  bonus_name: Scalars['String'];
  bonus_title: Scalars['String'];
  bonus_value?: Maybe<Scalars['String']>;
  button_action: Scalars['String'];
  button_link?: Maybe<Scalars['String']>;
  button_text: Scalars['String'];
  /** An array relationship */
  localization: Array<Bonus_Wheels_Bonuses_Params_Localization>;
  /** An aggregate relationship */
  localization_aggregate: Bonus_Wheels_Bonuses_Params_Localization_Aggregate;
};


/** columns and relationships of "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_ParamsLocalizationArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>;
};


/** columns and relationships of "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_ParamsLocalization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>;
};

/** aggregated selection of "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_Params_Aggregate = {
  __typename?: 'bonus_wheels_bonuses_params_aggregate';
  aggregate?: Maybe<Bonus_Wheels_Bonuses_Params_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_Bonuses_Params>;
};

/** aggregate fields of "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_Params_Aggregate_Fields = {
  __typename?: 'bonus_wheels_bonuses_params_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_Bonuses_Params_Max_Fields>;
  min?: Maybe<Bonus_Wheels_Bonuses_Params_Min_Fields>;
};


/** aggregate fields of "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_bonuses_params". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_Bonuses_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_Bonuses_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Bool_Exp>>;
  bonus_description?: InputMaybe<String_Comparison_Exp>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  bonus_image?: InputMaybe<String_Comparison_Exp>;
  bonus_name?: InputMaybe<String_Comparison_Exp>;
  bonus_title?: InputMaybe<String_Comparison_Exp>;
  bonus_value?: InputMaybe<String_Comparison_Exp>;
  button_action?: InputMaybe<String_Comparison_Exp>;
  button_link?: InputMaybe<String_Comparison_Exp>;
  button_text?: InputMaybe<String_Comparison_Exp>;
  localization?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>;
  localization_aggregate?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_bonuses_params" */
export enum Bonus_Wheels_Bonuses_Params_Constraint {
  /** unique or primary key constraint on columns "bonus_id" */
  BonusWheelsBonusesParamsPkey = 'bonus_wheels_bonuses_params_pkey'
}

/** input type for inserting data into table "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_Params_Insert_Input = {
  bonus_description?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_image?: InputMaybe<Scalars['String']>;
  bonus_name?: InputMaybe<Scalars['String']>;
  bonus_title?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['String']>;
  button_action?: InputMaybe<Scalars['String']>;
  button_link?: InputMaybe<Scalars['String']>;
  button_text?: InputMaybe<Scalars['String']>;
  localization?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization = {
  __typename?: 'bonus_wheels_bonuses_params_localization';
  bonus_description_localized: Scalars['String'];
  bonus_id: Scalars['uuid'];
  bonus_name_localized: Scalars['String'];
  bonus_title_localized: Scalars['String'];
  button_text_localized: Scalars['String'];
  locale: Scalars['String'];
};

/** aggregated selection of "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_Aggregate = {
  __typename?: 'bonus_wheels_bonuses_params_localization_aggregate';
  aggregate?: Maybe<Bonus_Wheels_Bonuses_Params_Localization_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_Bonuses_Params_Localization>;
};

export type Bonus_Wheels_Bonuses_Params_Localization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Aggregate_Bool_Exp_Count>;
};

export type Bonus_Wheels_Bonuses_Params_Localization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_Aggregate_Fields = {
  __typename?: 'bonus_wheels_bonuses_params_localization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_Bonuses_Params_Localization_Max_Fields>;
  min?: Maybe<Bonus_Wheels_Bonuses_Params_Localization_Min_Fields>;
};


/** aggregate fields of "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Max_Order_By>;
  min?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Min_Order_By>;
};

/** input type for inserting array relation for remote table "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_Arr_Rel_Insert_Input = {
  data: Array<Bonus_Wheels_Bonuses_Params_Localization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_On_Conflict>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_bonuses_params_localization". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>>;
  bonus_description_localized?: InputMaybe<String_Comparison_Exp>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  bonus_name_localized?: InputMaybe<String_Comparison_Exp>;
  bonus_title_localized?: InputMaybe<String_Comparison_Exp>;
  button_text_localized?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_bonuses_params_localization" */
export enum Bonus_Wheels_Bonuses_Params_Localization_Constraint {
  /** unique or primary key constraint on columns "bonus_id", "locale" */
  BonusWheelsBonusesParamsLocalesPkey = 'bonus_wheels_bonuses_params_locales_pkey'
}

/** input type for inserting data into table "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_Insert_Input = {
  bonus_description_localized?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_name_localized?: InputMaybe<Scalars['String']>;
  bonus_title_localized?: InputMaybe<Scalars['String']>;
  button_text_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bonus_Wheels_Bonuses_Params_Localization_Max_Fields = {
  __typename?: 'bonus_wheels_bonuses_params_localization_max_fields';
  bonus_description_localized?: Maybe<Scalars['String']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_name_localized?: Maybe<Scalars['String']>;
  bonus_title_localized?: Maybe<Scalars['String']>;
  button_text_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_Max_Order_By = {
  bonus_description_localized?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  bonus_name_localized?: InputMaybe<Order_By>;
  bonus_title_localized?: InputMaybe<Order_By>;
  button_text_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bonus_Wheels_Bonuses_Params_Localization_Min_Fields = {
  __typename?: 'bonus_wheels_bonuses_params_localization_min_fields';
  bonus_description_localized?: Maybe<Scalars['String']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_name_localized?: Maybe<Scalars['String']>;
  bonus_title_localized?: Maybe<Scalars['String']>;
  button_text_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_Min_Order_By = {
  bonus_description_localized?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  bonus_name_localized?: InputMaybe<Order_By>;
  bonus_title_localized?: InputMaybe<Order_By>;
  button_text_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_Mutation_Response = {
  __typename?: 'bonus_wheels_bonuses_params_localization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_Bonuses_Params_Localization>;
};

/** on_conflict condition type for table "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_On_Conflict = {
  constraint: Bonus_Wheels_Bonuses_Params_Localization_Constraint;
  update_columns?: Array<Bonus_Wheels_Bonuses_Params_Localization_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_bonuses_params_localization". */
export type Bonus_Wheels_Bonuses_Params_Localization_Order_By = {
  bonus_description_localized?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  bonus_name_localized?: InputMaybe<Order_By>;
  bonus_title_localized?: InputMaybe<Order_By>;
  button_text_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheels_bonuses_params_localization */
export type Bonus_Wheels_Bonuses_Params_Localization_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
};

/** select columns of table "bonus_wheels_bonuses_params_localization" */
export enum Bonus_Wheels_Bonuses_Params_Localization_Select_Column {
  /** column name */
  BonusDescriptionLocalized = 'bonus_description_localized',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusNameLocalized = 'bonus_name_localized',
  /** column name */
  BonusTitleLocalized = 'bonus_title_localized',
  /** column name */
  ButtonTextLocalized = 'button_text_localized',
  /** column name */
  Locale = 'locale'
}

/** input type for updating data in table "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_Set_Input = {
  bonus_description_localized?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_name_localized?: InputMaybe<Scalars['String']>;
  bonus_title_localized?: InputMaybe<Scalars['String']>;
  button_text_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "bonus_wheels_bonuses_params_localization" */
export type Bonus_Wheels_Bonuses_Params_Localization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_Bonuses_Params_Localization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_Bonuses_Params_Localization_Stream_Cursor_Value_Input = {
  bonus_description_localized?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_name_localized?: InputMaybe<Scalars['String']>;
  bonus_title_localized?: InputMaybe<Scalars['String']>;
  button_text_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** update columns of table "bonus_wheels_bonuses_params_localization" */
export enum Bonus_Wheels_Bonuses_Params_Localization_Update_Column {
  /** column name */
  BonusDescriptionLocalized = 'bonus_description_localized',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusNameLocalized = 'bonus_name_localized',
  /** column name */
  BonusTitleLocalized = 'bonus_title_localized',
  /** column name */
  ButtonTextLocalized = 'button_text_localized',
  /** column name */
  Locale = 'locale'
}

export type Bonus_Wheels_Bonuses_Params_Localization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp;
};

/** aggregate max on columns */
export type Bonus_Wheels_Bonuses_Params_Max_Fields = {
  __typename?: 'bonus_wheels_bonuses_params_max_fields';
  bonus_description?: Maybe<Scalars['String']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_image?: Maybe<Scalars['String']>;
  bonus_name?: Maybe<Scalars['String']>;
  bonus_title?: Maybe<Scalars['String']>;
  bonus_value?: Maybe<Scalars['String']>;
  button_action?: Maybe<Scalars['String']>;
  button_link?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bonus_Wheels_Bonuses_Params_Min_Fields = {
  __typename?: 'bonus_wheels_bonuses_params_min_fields';
  bonus_description?: Maybe<Scalars['String']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_image?: Maybe<Scalars['String']>;
  bonus_name?: Maybe<Scalars['String']>;
  bonus_title?: Maybe<Scalars['String']>;
  bonus_value?: Maybe<Scalars['String']>;
  button_action?: Maybe<Scalars['String']>;
  button_link?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_Params_Mutation_Response = {
  __typename?: 'bonus_wheels_bonuses_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_Bonuses_Params>;
};

/** input type for inserting object relation for remote table "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_Params_Obj_Rel_Insert_Input = {
  data: Bonus_Wheels_Bonuses_Params_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_Params_On_Conflict>;
};

/** on_conflict condition type for table "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_Params_On_Conflict = {
  constraint: Bonus_Wheels_Bonuses_Params_Constraint;
  update_columns?: Array<Bonus_Wheels_Bonuses_Params_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_bonuses_params". */
export type Bonus_Wheels_Bonuses_Params_Order_By = {
  bonus_description?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  bonus_image?: InputMaybe<Order_By>;
  bonus_name?: InputMaybe<Order_By>;
  bonus_title?: InputMaybe<Order_By>;
  bonus_value?: InputMaybe<Order_By>;
  button_action?: InputMaybe<Order_By>;
  button_link?: InputMaybe<Order_By>;
  button_text?: InputMaybe<Order_By>;
  localization_aggregate?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Aggregate_Order_By>;
};

/** primary key columns input for table: bonus_wheels_bonuses_params */
export type Bonus_Wheels_Bonuses_Params_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheels_bonuses_params" */
export enum Bonus_Wheels_Bonuses_Params_Select_Column {
  /** column name */
  BonusDescription = 'bonus_description',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusImage = 'bonus_image',
  /** column name */
  BonusName = 'bonus_name',
  /** column name */
  BonusTitle = 'bonus_title',
  /** column name */
  BonusValue = 'bonus_value',
  /** column name */
  ButtonAction = 'button_action',
  /** column name */
  ButtonLink = 'button_link',
  /** column name */
  ButtonText = 'button_text'
}

/** input type for updating data in table "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_Params_Set_Input = {
  bonus_description?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_image?: InputMaybe<Scalars['String']>;
  bonus_name?: InputMaybe<Scalars['String']>;
  bonus_title?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['String']>;
  button_action?: InputMaybe<Scalars['String']>;
  button_link?: InputMaybe<Scalars['String']>;
  button_text?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "bonus_wheels_bonuses_params" */
export type Bonus_Wheels_Bonuses_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_Bonuses_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_Bonuses_Params_Stream_Cursor_Value_Input = {
  bonus_description?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_image?: InputMaybe<Scalars['String']>;
  bonus_name?: InputMaybe<Scalars['String']>;
  bonus_title?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['String']>;
  button_action?: InputMaybe<Scalars['String']>;
  button_link?: InputMaybe<Scalars['String']>;
  button_text?: InputMaybe<Scalars['String']>;
};

/** update columns of table "bonus_wheels_bonuses_params" */
export enum Bonus_Wheels_Bonuses_Params_Update_Column {
  /** column name */
  BonusDescription = 'bonus_description',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusImage = 'bonus_image',
  /** column name */
  BonusName = 'bonus_name',
  /** column name */
  BonusTitle = 'bonus_title',
  /** column name */
  BonusValue = 'bonus_value',
  /** column name */
  ButtonAction = 'button_action',
  /** column name */
  ButtonLink = 'button_link',
  /** column name */
  ButtonText = 'button_text'
}

export type Bonus_Wheels_Bonuses_Params_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_Bonuses_Params_Bool_Exp;
};

/** primary key columns input for table: bonus_wheels_bonuses */
export type Bonus_Wheels_Bonuses_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheels_bonuses" */
export enum Bonus_Wheels_Bonuses_Select_Column {
  /** column name */
  BonusDescription = 'bonus_description',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusImage = 'bonus_image',
  /** column name */
  BonusName = 'bonus_name',
  /** column name */
  BonusParam = 'bonus_param',
  /** column name */
  BonusType = 'bonus_type',
  /** column name */
  BonusValue = 'bonus_value'
}

/** input type for updating data in table "bonus_wheels_bonuses" */
export type Bonus_Wheels_Bonuses_Set_Input = {
  bonus_description?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_image?: InputMaybe<Scalars['String']>;
  bonus_name?: InputMaybe<Scalars['String']>;
  bonus_param?: InputMaybe<Scalars['String']>;
  bonus_type?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bonus_Wheels_Bonuses_Stddev_Fields = {
  __typename?: 'bonus_wheels_bonuses_stddev_fields';
  bonus_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bonus_Wheels_Bonuses_Stddev_Pop_Fields = {
  __typename?: 'bonus_wheels_bonuses_stddev_pop_fields';
  bonus_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bonus_Wheels_Bonuses_Stddev_Samp_Fields = {
  __typename?: 'bonus_wheels_bonuses_stddev_samp_fields';
  bonus_value?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bonus_wheels_bonuses" */
export type Bonus_Wheels_Bonuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_Bonuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_Bonuses_Stream_Cursor_Value_Input = {
  bonus_description?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_image?: InputMaybe<Scalars['String']>;
  bonus_name?: InputMaybe<Scalars['String']>;
  bonus_param?: InputMaybe<Scalars['String']>;
  bonus_type?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Bonus_Wheels_Bonuses_Sum_Fields = {
  __typename?: 'bonus_wheels_bonuses_sum_fields';
  bonus_value?: Maybe<Scalars['Int']>;
};

/** update columns of table "bonus_wheels_bonuses" */
export enum Bonus_Wheels_Bonuses_Update_Column {
  /** column name */
  BonusDescription = 'bonus_description',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusImage = 'bonus_image',
  /** column name */
  BonusName = 'bonus_name',
  /** column name */
  BonusParam = 'bonus_param',
  /** column name */
  BonusType = 'bonus_type',
  /** column name */
  BonusValue = 'bonus_value'
}

export type Bonus_Wheels_Bonuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonus_Wheels_Bonuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_Bonuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonus_Wheels_Bonuses_Var_Pop_Fields = {
  __typename?: 'bonus_wheels_bonuses_var_pop_fields';
  bonus_value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bonus_Wheels_Bonuses_Var_Samp_Fields = {
  __typename?: 'bonus_wheels_bonuses_var_samp_fields';
  bonus_value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bonus_Wheels_Bonuses_Variance_Fields = {
  __typename?: 'bonus_wheels_bonuses_variance_fields';
  bonus_value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bonus_wheels". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_Bool_Exp>>;
  bonuses_in_wheel?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Bool_Exp>;
  bonuses_in_wheel_aggregate?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Aggregate_Bool_Exp>;
  wheel_description?: InputMaybe<String_Comparison_Exp>;
  wheel_id?: InputMaybe<Uuid_Comparison_Exp>;
  wheel_name?: InputMaybe<String_Comparison_Exp>;
  wheel_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels" */
export enum Bonus_Wheels_Constraint {
  /** unique or primary key constraint on columns "wheel_id" */
  BonusWheelsPkey = 'bonus_wheels_pkey'
}

/** columns and relationships of "bonus_wheels_generator" */
export type Bonus_Wheels_Generator = {
  __typename?: 'bonus_wheels_generator';
  /** An object relationship */
  bonus_wheel?: Maybe<Bonus_Wheels>;
  /** An array relationship */
  bonuses_for_generator: Array<Bonus_Wheels_Generator_Bonuses>;
  /** An aggregate relationship */
  bonuses_for_generator_aggregate: Bonus_Wheels_Generator_Bonuses_Aggregate;
  is_active: Scalars['Boolean'];
  wheel_id: Scalars['uuid'];
  wheel_price: Scalars['Int'];
};


/** columns and relationships of "bonus_wheels_generator" */
export type Bonus_Wheels_GeneratorBonuses_For_GeneratorArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Bool_Exp>;
};


/** columns and relationships of "bonus_wheels_generator" */
export type Bonus_Wheels_GeneratorBonuses_For_Generator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Bool_Exp>;
};

/** aggregated selection of "bonus_wheels_generator" */
export type Bonus_Wheels_Generator_Aggregate = {
  __typename?: 'bonus_wheels_generator_aggregate';
  aggregate?: Maybe<Bonus_Wheels_Generator_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_Generator>;
};

/** aggregate fields of "bonus_wheels_generator" */
export type Bonus_Wheels_Generator_Aggregate_Fields = {
  __typename?: 'bonus_wheels_generator_aggregate_fields';
  avg?: Maybe<Bonus_Wheels_Generator_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_Generator_Max_Fields>;
  min?: Maybe<Bonus_Wheels_Generator_Min_Fields>;
  stddev?: Maybe<Bonus_Wheels_Generator_Stddev_Fields>;
  stddev_pop?: Maybe<Bonus_Wheels_Generator_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonus_Wheels_Generator_Stddev_Samp_Fields>;
  sum?: Maybe<Bonus_Wheels_Generator_Sum_Fields>;
  var_pop?: Maybe<Bonus_Wheels_Generator_Var_Pop_Fields>;
  var_samp?: Maybe<Bonus_Wheels_Generator_Var_Samp_Fields>;
  variance?: Maybe<Bonus_Wheels_Generator_Variance_Fields>;
};


/** aggregate fields of "bonus_wheels_generator" */
export type Bonus_Wheels_Generator_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_Generator_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bonus_Wheels_Generator_Avg_Fields = {
  __typename?: 'bonus_wheels_generator_avg_fields';
  wheel_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses = {
  __typename?: 'bonus_wheels_generator_bonuses';
  /** An object relationship */
  bonus?: Maybe<Bonus_Wheels_Bonuses>;
  bonus_id: Scalars['uuid'];
  wheel_id: Scalars['uuid'];
};

/** aggregated selection of "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_Aggregate = {
  __typename?: 'bonus_wheels_generator_bonuses_aggregate';
  aggregate?: Maybe<Bonus_Wheels_Generator_Bonuses_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_Generator_Bonuses>;
};

export type Bonus_Wheels_Generator_Bonuses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Aggregate_Bool_Exp_Count>;
};

export type Bonus_Wheels_Generator_Bonuses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_Aggregate_Fields = {
  __typename?: 'bonus_wheels_generator_bonuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_Generator_Bonuses_Max_Fields>;
  min?: Maybe<Bonus_Wheels_Generator_Bonuses_Min_Fields>;
};


/** aggregate fields of "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Max_Order_By>;
  min?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_Arr_Rel_Insert_Input = {
  data: Array<Bonus_Wheels_Generator_Bonuses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheels_Generator_Bonuses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_generator_bonuses". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_Generator_Bonuses_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Bool_Exp>>;
  bonus?: InputMaybe<Bonus_Wheels_Bonuses_Bool_Exp>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  wheel_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_generator_bonuses" */
export enum Bonus_Wheels_Generator_Bonuses_Constraint {
  /** unique or primary key constraint on columns "wheel_id", "bonus_id" */
  BonusWheelsGeneratorBonusesPkey = 'bonus_wheels_generator_bonuses_pkey'
}

/** input type for inserting data into table "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_Insert_Input = {
  bonus?: InputMaybe<Bonus_Wheels_Bonuses_Obj_Rel_Insert_Input>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Bonus_Wheels_Generator_Bonuses_Max_Fields = {
  __typename?: 'bonus_wheels_generator_bonuses_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  wheel_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_Max_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  wheel_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bonus_Wheels_Generator_Bonuses_Min_Fields = {
  __typename?: 'bonus_wheels_generator_bonuses_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  wheel_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_Min_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  wheel_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_Mutation_Response = {
  __typename?: 'bonus_wheels_generator_bonuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_Generator_Bonuses>;
};

/** on_conflict condition type for table "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_On_Conflict = {
  constraint: Bonus_Wheels_Generator_Bonuses_Constraint;
  update_columns?: Array<Bonus_Wheels_Generator_Bonuses_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_generator_bonuses". */
export type Bonus_Wheels_Generator_Bonuses_Order_By = {
  bonus?: InputMaybe<Bonus_Wheels_Bonuses_Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  wheel_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheels_generator_bonuses */
export type Bonus_Wheels_Generator_Bonuses_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  wheel_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheels_generator_bonuses" */
export enum Bonus_Wheels_Generator_Bonuses_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  WheelId = 'wheel_id'
}

/** input type for updating data in table "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "bonus_wheels_generator_bonuses" */
export type Bonus_Wheels_Generator_Bonuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_Generator_Bonuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_Generator_Bonuses_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "bonus_wheels_generator_bonuses" */
export enum Bonus_Wheels_Generator_Bonuses_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  WheelId = 'wheel_id'
}

export type Bonus_Wheels_Generator_Bonuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_Generator_Bonuses_Bool_Exp;
};

/** Boolean expression to filter rows from the table "bonus_wheels_generator". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_Generator_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_Generator_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_Generator_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_Generator_Bool_Exp>>;
  bonus_wheel?: InputMaybe<Bonus_Wheels_Bool_Exp>;
  bonuses_for_generator?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Bool_Exp>;
  bonuses_for_generator_aggregate?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Aggregate_Bool_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  wheel_id?: InputMaybe<Uuid_Comparison_Exp>;
  wheel_price?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_generator" */
export enum Bonus_Wheels_Generator_Constraint {
  /** unique or primary key constraint on columns "wheel_id" */
  BonusWheelsGeneratorPkey = 'bonus_wheels_generator_pkey'
}

/** input type for incrementing numeric columns in table "bonus_wheels_generator" */
export type Bonus_Wheels_Generator_Inc_Input = {
  wheel_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bonus_wheels_generator" */
export type Bonus_Wheels_Generator_Insert_Input = {
  bonus_wheel?: InputMaybe<Bonus_Wheels_Obj_Rel_Insert_Input>;
  bonuses_for_generator?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Arr_Rel_Insert_Input>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
  wheel_price?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Bonus_Wheels_Generator_Max_Fields = {
  __typename?: 'bonus_wheels_generator_max_fields';
  wheel_id?: Maybe<Scalars['uuid']>;
  wheel_price?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Bonus_Wheels_Generator_Min_Fields = {
  __typename?: 'bonus_wheels_generator_min_fields';
  wheel_id?: Maybe<Scalars['uuid']>;
  wheel_price?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "bonus_wheels_generator" */
export type Bonus_Wheels_Generator_Mutation_Response = {
  __typename?: 'bonus_wheels_generator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_Generator>;
};

/** on_conflict condition type for table "bonus_wheels_generator" */
export type Bonus_Wheels_Generator_On_Conflict = {
  constraint: Bonus_Wheels_Generator_Constraint;
  update_columns?: Array<Bonus_Wheels_Generator_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_generator". */
export type Bonus_Wheels_Generator_Order_By = {
  bonus_wheel?: InputMaybe<Bonus_Wheels_Order_By>;
  bonuses_for_generator_aggregate?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Aggregate_Order_By>;
  is_active?: InputMaybe<Order_By>;
  wheel_id?: InputMaybe<Order_By>;
  wheel_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheels_generator */
export type Bonus_Wheels_Generator_Pk_Columns_Input = {
  wheel_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheels_generator" */
export enum Bonus_Wheels_Generator_Select_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  WheelId = 'wheel_id',
  /** column name */
  WheelPrice = 'wheel_price'
}

/** input type for updating data in table "bonus_wheels_generator" */
export type Bonus_Wheels_Generator_Set_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
  wheel_price?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bonus_Wheels_Generator_Stddev_Fields = {
  __typename?: 'bonus_wheels_generator_stddev_fields';
  wheel_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bonus_Wheels_Generator_Stddev_Pop_Fields = {
  __typename?: 'bonus_wheels_generator_stddev_pop_fields';
  wheel_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bonus_Wheels_Generator_Stddev_Samp_Fields = {
  __typename?: 'bonus_wheels_generator_stddev_samp_fields';
  wheel_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bonus_wheels_generator" */
export type Bonus_Wheels_Generator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_Generator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_Generator_Stream_Cursor_Value_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
  wheel_price?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Bonus_Wheels_Generator_Sum_Fields = {
  __typename?: 'bonus_wheels_generator_sum_fields';
  wheel_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "bonus_wheels_generator" */
export enum Bonus_Wheels_Generator_Update_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  WheelId = 'wheel_id',
  /** column name */
  WheelPrice = 'wheel_price'
}

export type Bonus_Wheels_Generator_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonus_Wheels_Generator_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_Generator_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_Generator_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonus_Wheels_Generator_Var_Pop_Fields = {
  __typename?: 'bonus_wheels_generator_var_pop_fields';
  wheel_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bonus_Wheels_Generator_Var_Samp_Fields = {
  __typename?: 'bonus_wheels_generator_var_samp_fields';
  wheel_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bonus_Wheels_Generator_Variance_Fields = {
  __typename?: 'bonus_wheels_generator_variance_fields';
  wheel_price?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "bonus_wheels" */
export type Bonus_Wheels_Insert_Input = {
  bonuses_in_wheel?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Arr_Rel_Insert_Input>;
  wheel_description?: InputMaybe<Scalars['String']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
  wheel_name?: InputMaybe<Scalars['String']>;
  wheel_type?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel = {
  __typename?: 'bonus_wheels_items_in_wheel';
  /** An object relationship */
  bonus?: Maybe<Bonus_Wheels_Bonuses>;
  bonus_drop_chance: Scalars['Float'];
  bonus_id: Scalars['uuid'];
  wheel_id: Scalars['uuid'];
};

/** aggregated selection of "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Aggregate = {
  __typename?: 'bonus_wheels_items_in_wheel_aggregate';
  aggregate?: Maybe<Bonus_Wheels_Items_In_Wheel_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_Items_In_Wheel>;
};

export type Bonus_Wheels_Items_In_Wheel_Aggregate_Bool_Exp = {
  count?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Aggregate_Bool_Exp_Count>;
};

export type Bonus_Wheels_Items_In_Wheel_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Aggregate_Fields = {
  __typename?: 'bonus_wheels_items_in_wheel_aggregate_fields';
  avg?: Maybe<Bonus_Wheels_Items_In_Wheel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_Items_In_Wheel_Max_Fields>;
  min?: Maybe<Bonus_Wheels_Items_In_Wheel_Min_Fields>;
  stddev?: Maybe<Bonus_Wheels_Items_In_Wheel_Stddev_Fields>;
  stddev_pop?: Maybe<Bonus_Wheels_Items_In_Wheel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonus_Wheels_Items_In_Wheel_Stddev_Samp_Fields>;
  sum?: Maybe<Bonus_Wheels_Items_In_Wheel_Sum_Fields>;
  var_pop?: Maybe<Bonus_Wheels_Items_In_Wheel_Var_Pop_Fields>;
  var_samp?: Maybe<Bonus_Wheels_Items_In_Wheel_Var_Samp_Fields>;
  variance?: Maybe<Bonus_Wheels_Items_In_Wheel_Variance_Fields>;
};


/** aggregate fields of "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Aggregate_Order_By = {
  avg?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Max_Order_By>;
  min?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Min_Order_By>;
  stddev?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Sum_Order_By>;
  var_pop?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Var_Samp_Order_By>;
  variance?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Arr_Rel_Insert_Input = {
  data: Array<Bonus_Wheels_Items_In_Wheel_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheels_Items_In_Wheel_On_Conflict>;
};

/** aggregate avg on columns */
export type Bonus_Wheels_Items_In_Wheel_Avg_Fields = {
  __typename?: 'bonus_wheels_items_in_wheel_avg_fields';
  bonus_drop_chance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Avg_Order_By = {
  bonus_drop_chance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_items_in_wheel". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_Items_In_Wheel_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Bool_Exp>>;
  bonus?: InputMaybe<Bonus_Wheels_Bonuses_Bool_Exp>;
  bonus_drop_chance?: InputMaybe<Float_Comparison_Exp>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  wheel_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_items_in_wheel" */
export enum Bonus_Wheels_Items_In_Wheel_Constraint {
  /** unique or primary key constraint on columns "wheel_id", "bonus_id" */
  BonusWheelsItemsInWheelPkey = 'bonus_wheels_items_in_wheel_pkey'
}

/** input type for incrementing numeric columns in table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Inc_Input = {
  bonus_drop_chance?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Insert_Input = {
  bonus?: InputMaybe<Bonus_Wheels_Bonuses_Obj_Rel_Insert_Input>;
  bonus_drop_chance?: InputMaybe<Scalars['Float']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Bonus_Wheels_Items_In_Wheel_Max_Fields = {
  __typename?: 'bonus_wheels_items_in_wheel_max_fields';
  bonus_drop_chance?: Maybe<Scalars['Float']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  wheel_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Max_Order_By = {
  bonus_drop_chance?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  wheel_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bonus_Wheels_Items_In_Wheel_Min_Fields = {
  __typename?: 'bonus_wheels_items_in_wheel_min_fields';
  bonus_drop_chance?: Maybe<Scalars['Float']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  wheel_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Min_Order_By = {
  bonus_drop_chance?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  wheel_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Mutation_Response = {
  __typename?: 'bonus_wheels_items_in_wheel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_Items_In_Wheel>;
};

/** on_conflict condition type for table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_On_Conflict = {
  constraint: Bonus_Wheels_Items_In_Wheel_Constraint;
  update_columns?: Array<Bonus_Wheels_Items_In_Wheel_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_items_in_wheel". */
export type Bonus_Wheels_Items_In_Wheel_Order_By = {
  bonus?: InputMaybe<Bonus_Wheels_Bonuses_Order_By>;
  bonus_drop_chance?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  wheel_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheels_items_in_wheel */
export type Bonus_Wheels_Items_In_Wheel_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  wheel_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheels_items_in_wheel" */
export enum Bonus_Wheels_Items_In_Wheel_Select_Column {
  /** column name */
  BonusDropChance = 'bonus_drop_chance',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  WheelId = 'wheel_id'
}

/** input type for updating data in table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Set_Input = {
  bonus_drop_chance?: InputMaybe<Scalars['Float']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Bonus_Wheels_Items_In_Wheel_Stddev_Fields = {
  __typename?: 'bonus_wheels_items_in_wheel_stddev_fields';
  bonus_drop_chance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Stddev_Order_By = {
  bonus_drop_chance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bonus_Wheels_Items_In_Wheel_Stddev_Pop_Fields = {
  __typename?: 'bonus_wheels_items_in_wheel_stddev_pop_fields';
  bonus_drop_chance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Stddev_Pop_Order_By = {
  bonus_drop_chance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bonus_Wheels_Items_In_Wheel_Stddev_Samp_Fields = {
  __typename?: 'bonus_wheels_items_in_wheel_stddev_samp_fields';
  bonus_drop_chance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Stddev_Samp_Order_By = {
  bonus_drop_chance?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_Items_In_Wheel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_Items_In_Wheel_Stream_Cursor_Value_Input = {
  bonus_drop_chance?: InputMaybe<Scalars['Float']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Bonus_Wheels_Items_In_Wheel_Sum_Fields = {
  __typename?: 'bonus_wheels_items_in_wheel_sum_fields';
  bonus_drop_chance?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Sum_Order_By = {
  bonus_drop_chance?: InputMaybe<Order_By>;
};

/** update columns of table "bonus_wheels_items_in_wheel" */
export enum Bonus_Wheels_Items_In_Wheel_Update_Column {
  /** column name */
  BonusDropChance = 'bonus_drop_chance',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  WheelId = 'wheel_id'
}

export type Bonus_Wheels_Items_In_Wheel_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_Items_In_Wheel_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonus_Wheels_Items_In_Wheel_Var_Pop_Fields = {
  __typename?: 'bonus_wheels_items_in_wheel_var_pop_fields';
  bonus_drop_chance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Var_Pop_Order_By = {
  bonus_drop_chance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bonus_Wheels_Items_In_Wheel_Var_Samp_Fields = {
  __typename?: 'bonus_wheels_items_in_wheel_var_samp_fields';
  bonus_drop_chance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Var_Samp_Order_By = {
  bonus_drop_chance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bonus_Wheels_Items_In_Wheel_Variance_Fields = {
  __typename?: 'bonus_wheels_items_in_wheel_variance_fields';
  bonus_drop_chance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bonus_wheels_items_in_wheel" */
export type Bonus_Wheels_Items_In_Wheel_Variance_Order_By = {
  bonus_drop_chance?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Bonus_Wheels_Max_Fields = {
  __typename?: 'bonus_wheels_max_fields';
  wheel_description?: Maybe<Scalars['String']>;
  wheel_id?: Maybe<Scalars['uuid']>;
  wheel_name?: Maybe<Scalars['String']>;
  wheel_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bonus_Wheels_Min_Fields = {
  __typename?: 'bonus_wheels_min_fields';
  wheel_description?: Maybe<Scalars['String']>;
  wheel_id?: Maybe<Scalars['uuid']>;
  wheel_name?: Maybe<Scalars['String']>;
  wheel_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bonus_wheels" */
export type Bonus_Wheels_Mutation_Response = {
  __typename?: 'bonus_wheels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels>;
};

/** input type for inserting object relation for remote table "bonus_wheels" */
export type Bonus_Wheels_Obj_Rel_Insert_Input = {
  data: Bonus_Wheels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheels_On_Conflict>;
};

/** on_conflict condition type for table "bonus_wheels" */
export type Bonus_Wheels_On_Conflict = {
  constraint: Bonus_Wheels_Constraint;
  update_columns?: Array<Bonus_Wheels_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels". */
export type Bonus_Wheels_Order_By = {
  bonuses_in_wheel_aggregate?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Aggregate_Order_By>;
  wheel_description?: InputMaybe<Order_By>;
  wheel_id?: InputMaybe<Order_By>;
  wheel_name?: InputMaybe<Order_By>;
  wheel_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheels */
export type Bonus_Wheels_Pk_Columns_Input = {
  wheel_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheels" */
export enum Bonus_Wheels_Select_Column {
  /** column name */
  WheelDescription = 'wheel_description',
  /** column name */
  WheelId = 'wheel_id',
  /** column name */
  WheelName = 'wheel_name',
  /** column name */
  WheelType = 'wheel_type'
}

/** input type for updating data in table "bonus_wheels" */
export type Bonus_Wheels_Set_Input = {
  wheel_description?: InputMaybe<Scalars['String']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
  wheel_name?: InputMaybe<Scalars['String']>;
  wheel_type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "bonus_wheels" */
export type Bonus_Wheels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_Stream_Cursor_Value_Input = {
  wheel_description?: InputMaybe<Scalars['String']>;
  wheel_id?: InputMaybe<Scalars['uuid']>;
  wheel_name?: InputMaybe<Scalars['String']>;
  wheel_type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "bonus_wheels" */
export enum Bonus_Wheels_Update_Column {
  /** column name */
  WheelDescription = 'wheel_description',
  /** column name */
  WheelId = 'wheel_id',
  /** column name */
  WheelName = 'wheel_name',
  /** column name */
  WheelType = 'wheel_type'
}

export type Bonus_Wheels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_Bool_Exp;
};

/** columns and relationships of "bonus_wheels_user_check" */
export type Bonus_Wheels_User_Check = {
  __typename?: 'bonus_wheels_user_check';
  created_at: Scalars['timestamp'];
  deposits_amount_check: Scalars['Boolean'];
  name_changed_check: Scalars['Boolean'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "bonus_wheels_user_check" */
export type Bonus_Wheels_User_Check_Aggregate = {
  __typename?: 'bonus_wheels_user_check_aggregate';
  aggregate?: Maybe<Bonus_Wheels_User_Check_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_User_Check>;
};

/** aggregate fields of "bonus_wheels_user_check" */
export type Bonus_Wheels_User_Check_Aggregate_Fields = {
  __typename?: 'bonus_wheels_user_check_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_User_Check_Max_Fields>;
  min?: Maybe<Bonus_Wheels_User_Check_Min_Fields>;
};


/** aggregate fields of "bonus_wheels_user_check" */
export type Bonus_Wheels_User_Check_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_User_Check_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_user_check". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_User_Check_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_User_Check_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_User_Check_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_User_Check_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deposits_amount_check?: InputMaybe<Boolean_Comparison_Exp>;
  name_changed_check?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_user_check" */
export enum Bonus_Wheels_User_Check_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  BonusWheelsUserCheckPkey = 'bonus_wheels_user_check_pkey'
}

/** input type for inserting data into table "bonus_wheels_user_check" */
export type Bonus_Wheels_User_Check_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deposits_amount_check?: InputMaybe<Scalars['Boolean']>;
  name_changed_check?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Bonus_Wheels_User_Check_Max_Fields = {
  __typename?: 'bonus_wheels_user_check_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Bonus_Wheels_User_Check_Min_Fields = {
  __typename?: 'bonus_wheels_user_check_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "bonus_wheels_user_check" */
export type Bonus_Wheels_User_Check_Mutation_Response = {
  __typename?: 'bonus_wheels_user_check_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_User_Check>;
};

/** on_conflict condition type for table "bonus_wheels_user_check" */
export type Bonus_Wheels_User_Check_On_Conflict = {
  constraint: Bonus_Wheels_User_Check_Constraint;
  update_columns?: Array<Bonus_Wheels_User_Check_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_user_check". */
export type Bonus_Wheels_User_Check_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deposits_amount_check?: InputMaybe<Order_By>;
  name_changed_check?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "bonus_wheels_user_check_params" */
export type Bonus_Wheels_User_Check_Params = {
  __typename?: 'bonus_wheels_user_check_params';
  param_name: Scalars['String'];
  param_value: Scalars['String'];
};

/** aggregated selection of "bonus_wheels_user_check_params" */
export type Bonus_Wheels_User_Check_Params_Aggregate = {
  __typename?: 'bonus_wheels_user_check_params_aggregate';
  aggregate?: Maybe<Bonus_Wheels_User_Check_Params_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_User_Check_Params>;
};

/** aggregate fields of "bonus_wheels_user_check_params" */
export type Bonus_Wheels_User_Check_Params_Aggregate_Fields = {
  __typename?: 'bonus_wheels_user_check_params_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_User_Check_Params_Max_Fields>;
  min?: Maybe<Bonus_Wheels_User_Check_Params_Min_Fields>;
};


/** aggregate fields of "bonus_wheels_user_check_params" */
export type Bonus_Wheels_User_Check_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_User_Check_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_user_check_params". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_User_Check_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_User_Check_Params_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_User_Check_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_User_Check_Params_Bool_Exp>>;
  param_name?: InputMaybe<String_Comparison_Exp>;
  param_value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_user_check_params" */
export enum Bonus_Wheels_User_Check_Params_Constraint {
  /** unique or primary key constraint on columns "param_name" */
  BonusWheelsUserCheckParamsPkey = 'bonus_wheels_user_check_params_pkey'
}

/** input type for inserting data into table "bonus_wheels_user_check_params" */
export type Bonus_Wheels_User_Check_Params_Insert_Input = {
  param_name?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bonus_Wheels_User_Check_Params_Max_Fields = {
  __typename?: 'bonus_wheels_user_check_params_max_fields';
  param_name?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bonus_Wheels_User_Check_Params_Min_Fields = {
  __typename?: 'bonus_wheels_user_check_params_min_fields';
  param_name?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bonus_wheels_user_check_params" */
export type Bonus_Wheels_User_Check_Params_Mutation_Response = {
  __typename?: 'bonus_wheels_user_check_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_User_Check_Params>;
};

/** on_conflict condition type for table "bonus_wheels_user_check_params" */
export type Bonus_Wheels_User_Check_Params_On_Conflict = {
  constraint: Bonus_Wheels_User_Check_Params_Constraint;
  update_columns?: Array<Bonus_Wheels_User_Check_Params_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_user_check_params". */
export type Bonus_Wheels_User_Check_Params_Order_By = {
  param_name?: InputMaybe<Order_By>;
  param_value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheels_user_check_params */
export type Bonus_Wheels_User_Check_Params_Pk_Columns_Input = {
  param_name: Scalars['String'];
};

/** select columns of table "bonus_wheels_user_check_params" */
export enum Bonus_Wheels_User_Check_Params_Select_Column {
  /** column name */
  ParamName = 'param_name',
  /** column name */
  ParamValue = 'param_value'
}

/** input type for updating data in table "bonus_wheels_user_check_params" */
export type Bonus_Wheels_User_Check_Params_Set_Input = {
  param_name?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "bonus_wheels_user_check_params" */
export type Bonus_Wheels_User_Check_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_User_Check_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_User_Check_Params_Stream_Cursor_Value_Input = {
  param_name?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "bonus_wheels_user_check_params" */
export enum Bonus_Wheels_User_Check_Params_Update_Column {
  /** column name */
  ParamName = 'param_name',
  /** column name */
  ParamValue = 'param_value'
}

export type Bonus_Wheels_User_Check_Params_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_User_Check_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_User_Check_Params_Bool_Exp;
};

/** primary key columns input for table: bonus_wheels_user_check */
export type Bonus_Wheels_User_Check_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheels_user_check" */
export enum Bonus_Wheels_User_Check_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepositsAmountCheck = 'deposits_amount_check',
  /** column name */
  NameChangedCheck = 'name_changed_check',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "bonus_wheels_user_check" */
export type Bonus_Wheels_User_Check_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deposits_amount_check?: InputMaybe<Scalars['Boolean']>;
  name_changed_check?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "bonus_wheels_user_check" */
export type Bonus_Wheels_User_Check_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_User_Check_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_User_Check_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deposits_amount_check?: InputMaybe<Scalars['Boolean']>;
  name_changed_check?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "bonus_wheels_user_check" */
export enum Bonus_Wheels_User_Check_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepositsAmountCheck = 'deposits_amount_check',
  /** column name */
  NameChangedCheck = 'name_changed_check',
  /** column name */
  UserId = 'user_id'
}

export type Bonus_Wheels_User_Check_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_User_Check_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_User_Check_Bool_Exp;
};

/** columns and relationships of "bonus_wheels_user_offers" */
export type Bonus_Wheels_User_Offers = {
  __typename?: 'bonus_wheels_user_offers';
  /** An object relationship */
  bonus?: Maybe<Bonus_Wheels_Bonuses>;
  bonus_id: Scalars['uuid'];
  created_at: Scalars['timestamp'];
  offer_action: Scalars['String'];
  offer_claimed: Scalars['Boolean'];
  offer_id: Scalars['uuid'];
  /** An object relationship */
  offer_param?: Maybe<Bonus_Wheels_User_Offers_Params>;
  offer_stage: Scalars['Int'];
  updated_at: Scalars['timestamp'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "bonus_wheels_user_offers" */
export type Bonus_Wheels_User_Offers_Aggregate = {
  __typename?: 'bonus_wheels_user_offers_aggregate';
  aggregate?: Maybe<Bonus_Wheels_User_Offers_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_User_Offers>;
};

/** aggregate fields of "bonus_wheels_user_offers" */
export type Bonus_Wheels_User_Offers_Aggregate_Fields = {
  __typename?: 'bonus_wheels_user_offers_aggregate_fields';
  avg?: Maybe<Bonus_Wheels_User_Offers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_User_Offers_Max_Fields>;
  min?: Maybe<Bonus_Wheels_User_Offers_Min_Fields>;
  stddev?: Maybe<Bonus_Wheels_User_Offers_Stddev_Fields>;
  stddev_pop?: Maybe<Bonus_Wheels_User_Offers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonus_Wheels_User_Offers_Stddev_Samp_Fields>;
  sum?: Maybe<Bonus_Wheels_User_Offers_Sum_Fields>;
  var_pop?: Maybe<Bonus_Wheels_User_Offers_Var_Pop_Fields>;
  var_samp?: Maybe<Bonus_Wheels_User_Offers_Var_Samp_Fields>;
  variance?: Maybe<Bonus_Wheels_User_Offers_Variance_Fields>;
};


/** aggregate fields of "bonus_wheels_user_offers" */
export type Bonus_Wheels_User_Offers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_User_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bonus_Wheels_User_Offers_Avg_Fields = {
  __typename?: 'bonus_wheels_user_offers_avg_fields';
  offer_stage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_user_offers". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_User_Offers_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_User_Offers_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_User_Offers_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_User_Offers_Bool_Exp>>;
  bonus?: InputMaybe<Bonus_Wheels_Bonuses_Bool_Exp>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  offer_action?: InputMaybe<String_Comparison_Exp>;
  offer_claimed?: InputMaybe<Boolean_Comparison_Exp>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  offer_param?: InputMaybe<Bonus_Wheels_User_Offers_Params_Bool_Exp>;
  offer_stage?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_user_offers" */
export enum Bonus_Wheels_User_Offers_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  BonusWheelsUserOffersPkey = 'bonus_wheels_user_offers_pkey'
}

/** input type for incrementing numeric columns in table "bonus_wheels_user_offers" */
export type Bonus_Wheels_User_Offers_Inc_Input = {
  offer_stage?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bonus_wheels_user_offers" */
export type Bonus_Wheels_User_Offers_Insert_Input = {
  bonus?: InputMaybe<Bonus_Wheels_Bonuses_Obj_Rel_Insert_Input>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  offer_action?: InputMaybe<Scalars['String']>;
  offer_claimed?: InputMaybe<Scalars['Boolean']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_param?: InputMaybe<Bonus_Wheels_User_Offers_Params_Obj_Rel_Insert_Input>;
  offer_stage?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Bonus_Wheels_User_Offers_Max_Fields = {
  __typename?: 'bonus_wheels_user_offers_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  offer_action?: Maybe<Scalars['String']>;
  offer_id?: Maybe<Scalars['uuid']>;
  offer_stage?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Bonus_Wheels_User_Offers_Min_Fields = {
  __typename?: 'bonus_wheels_user_offers_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  offer_action?: Maybe<Scalars['String']>;
  offer_id?: Maybe<Scalars['uuid']>;
  offer_stage?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "bonus_wheels_user_offers" */
export type Bonus_Wheels_User_Offers_Mutation_Response = {
  __typename?: 'bonus_wheels_user_offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_User_Offers>;
};

/** on_conflict condition type for table "bonus_wheels_user_offers" */
export type Bonus_Wheels_User_Offers_On_Conflict = {
  constraint: Bonus_Wheels_User_Offers_Constraint;
  update_columns?: Array<Bonus_Wheels_User_Offers_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_user_offers". */
export type Bonus_Wheels_User_Offers_Order_By = {
  bonus?: InputMaybe<Bonus_Wheels_Bonuses_Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  offer_action?: InputMaybe<Order_By>;
  offer_claimed?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  offer_param?: InputMaybe<Bonus_Wheels_User_Offers_Params_Order_By>;
  offer_stage?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_Params = {
  __typename?: 'bonus_wheels_user_offers_params';
  bonus_id: Scalars['uuid'];
  button_action: Scalars['String'];
  button_link?: Maybe<Scalars['String']>;
  button_text: Scalars['String'];
  /** An array relationship */
  localization: Array<Bonus_Wheels_User_Offers_Params_Localization>;
  /** An aggregate relationship */
  localization_aggregate: Bonus_Wheels_User_Offers_Params_Localization_Aggregate;
  offer_description: Scalars['String'];
  offer_image_link: Scalars['String'];
  offer_title: Scalars['String'];
  stage: Scalars['Int'];
};


/** columns and relationships of "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_ParamsLocalizationArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>;
};


/** columns and relationships of "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_ParamsLocalization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>;
};

/** aggregated selection of "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_Params_Aggregate = {
  __typename?: 'bonus_wheels_user_offers_params_aggregate';
  aggregate?: Maybe<Bonus_Wheels_User_Offers_Params_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_User_Offers_Params>;
};

/** aggregate fields of "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_Params_Aggregate_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_aggregate_fields';
  avg?: Maybe<Bonus_Wheels_User_Offers_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_User_Offers_Params_Max_Fields>;
  min?: Maybe<Bonus_Wheels_User_Offers_Params_Min_Fields>;
  stddev?: Maybe<Bonus_Wheels_User_Offers_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Bonus_Wheels_User_Offers_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonus_Wheels_User_Offers_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Bonus_Wheels_User_Offers_Params_Sum_Fields>;
  var_pop?: Maybe<Bonus_Wheels_User_Offers_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Bonus_Wheels_User_Offers_Params_Var_Samp_Fields>;
  variance?: Maybe<Bonus_Wheels_User_Offers_Params_Variance_Fields>;
};


/** aggregate fields of "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bonus_Wheels_User_Offers_Params_Avg_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_avg_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_user_offers_params". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_User_Offers_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_User_Offers_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Bool_Exp>>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  button_action?: InputMaybe<String_Comparison_Exp>;
  button_link?: InputMaybe<String_Comparison_Exp>;
  button_text?: InputMaybe<String_Comparison_Exp>;
  localization?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>;
  localization_aggregate?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Aggregate_Bool_Exp>;
  offer_description?: InputMaybe<String_Comparison_Exp>;
  offer_image_link?: InputMaybe<String_Comparison_Exp>;
  offer_title?: InputMaybe<String_Comparison_Exp>;
  stage?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_user_offers_params" */
export enum Bonus_Wheels_User_Offers_Params_Constraint {
  /** unique or primary key constraint on columns "stage", "bonus_id" */
  BonusWheelsOffersParamsPkey = 'bonus_wheels_offers_params_pkey'
}

/** input type for incrementing numeric columns in table "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_Params_Inc_Input = {
  stage?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_Params_Insert_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  button_action?: InputMaybe<Scalars['String']>;
  button_link?: InputMaybe<Scalars['String']>;
  button_text?: InputMaybe<Scalars['String']>;
  localization?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Arr_Rel_Insert_Input>;
  offer_description?: InputMaybe<Scalars['String']>;
  offer_image_link?: InputMaybe<Scalars['String']>;
  offer_title?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization = {
  __typename?: 'bonus_wheels_user_offers_params_localization';
  bonus_id: Scalars['uuid'];
  button_text_localized: Scalars['String'];
  locale: Scalars['String'];
  offer_description_localized: Scalars['String'];
  offer_title_localized: Scalars['String'];
  stage: Scalars['Int'];
};

/** aggregated selection of "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Aggregate = {
  __typename?: 'bonus_wheels_user_offers_params_localization_aggregate';
  aggregate?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Aggregate_Fields>;
  nodes: Array<Bonus_Wheels_User_Offers_Params_Localization>;
};

export type Bonus_Wheels_User_Offers_Params_Localization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Aggregate_Bool_Exp_Count>;
};

export type Bonus_Wheels_User_Offers_Params_Localization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Aggregate_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_localization_aggregate_fields';
  avg?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Max_Fields>;
  min?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Min_Fields>;
  stddev?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Stddev_Fields>;
  stddev_pop?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Stddev_Samp_Fields>;
  sum?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Sum_Fields>;
  var_pop?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Var_Pop_Fields>;
  var_samp?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Var_Samp_Fields>;
  variance?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Variance_Fields>;
};


/** aggregate fields of "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Aggregate_Order_By = {
  avg?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Max_Order_By>;
  min?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Min_Order_By>;
  stddev?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Sum_Order_By>;
  var_pop?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Var_Samp_Order_By>;
  variance?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Arr_Rel_Insert_Input = {
  data: Array<Bonus_Wheels_User_Offers_Params_Localization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_On_Conflict>;
};

/** aggregate avg on columns */
export type Bonus_Wheels_User_Offers_Params_Localization_Avg_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_localization_avg_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Avg_Order_By = {
  stage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bonus_wheels_user_offers_params_localization". All fields are combined with a logical 'AND'. */
export type Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp = {
  _and?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>>;
  _not?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>;
  _or?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  button_text_localized?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  offer_description_localized?: InputMaybe<String_Comparison_Exp>;
  offer_title_localized?: InputMaybe<String_Comparison_Exp>;
  stage?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonus_wheels_user_offers_params_localization" */
export enum Bonus_Wheels_User_Offers_Params_Localization_Constraint {
  /** unique or primary key constraint on columns "stage", "bonus_id", "locale" */
  BonusWheelsUserOffersParamsLocalesPkey = 'bonus_wheels_user_offers_params_locales_pkey'
}

/** input type for incrementing numeric columns in table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Inc_Input = {
  stage?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Insert_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  button_text_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  offer_description_localized?: InputMaybe<Scalars['String']>;
  offer_title_localized?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Bonus_Wheels_User_Offers_Params_Localization_Max_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_localization_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  button_text_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  offer_description_localized?: Maybe<Scalars['String']>;
  offer_title_localized?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Max_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  button_text_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  offer_description_localized?: InputMaybe<Order_By>;
  offer_title_localized?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bonus_Wheels_User_Offers_Params_Localization_Min_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_localization_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  button_text_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  offer_description_localized?: Maybe<Scalars['String']>;
  offer_title_localized?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Min_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  button_text_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  offer_description_localized?: InputMaybe<Order_By>;
  offer_title_localized?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Mutation_Response = {
  __typename?: 'bonus_wheels_user_offers_params_localization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_User_Offers_Params_Localization>;
};

/** on_conflict condition type for table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_On_Conflict = {
  constraint: Bonus_Wheels_User_Offers_Params_Localization_Constraint;
  update_columns?: Array<Bonus_Wheels_User_Offers_Params_Localization_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_user_offers_params_localization". */
export type Bonus_Wheels_User_Offers_Params_Localization_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  button_text_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  offer_description_localized?: InputMaybe<Order_By>;
  offer_title_localized?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheels_user_offers_params_localization */
export type Bonus_Wheels_User_Offers_Params_Localization_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
  stage: Scalars['Int'];
};

/** select columns of table "bonus_wheels_user_offers_params_localization" */
export enum Bonus_Wheels_User_Offers_Params_Localization_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ButtonTextLocalized = 'button_text_localized',
  /** column name */
  Locale = 'locale',
  /** column name */
  OfferDescriptionLocalized = 'offer_description_localized',
  /** column name */
  OfferTitleLocalized = 'offer_title_localized',
  /** column name */
  Stage = 'stage'
}

/** input type for updating data in table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  button_text_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  offer_description_localized?: InputMaybe<Scalars['String']>;
  offer_title_localized?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bonus_Wheels_User_Offers_Params_Localization_Stddev_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_localization_stddev_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Stddev_Order_By = {
  stage?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bonus_Wheels_User_Offers_Params_Localization_Stddev_Pop_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_localization_stddev_pop_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Stddev_Pop_Order_By = {
  stage?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bonus_Wheels_User_Offers_Params_Localization_Stddev_Samp_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_localization_stddev_samp_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Stddev_Samp_Order_By = {
  stage?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_User_Offers_Params_Localization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_User_Offers_Params_Localization_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  button_text_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  offer_description_localized?: InputMaybe<Scalars['String']>;
  offer_title_localized?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Bonus_Wheels_User_Offers_Params_Localization_Sum_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_localization_sum_fields';
  stage?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Sum_Order_By = {
  stage?: InputMaybe<Order_By>;
};

/** update columns of table "bonus_wheels_user_offers_params_localization" */
export enum Bonus_Wheels_User_Offers_Params_Localization_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ButtonTextLocalized = 'button_text_localized',
  /** column name */
  Locale = 'locale',
  /** column name */
  OfferDescriptionLocalized = 'offer_description_localized',
  /** column name */
  OfferTitleLocalized = 'offer_title_localized',
  /** column name */
  Stage = 'stage'
}

export type Bonus_Wheels_User_Offers_Params_Localization_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonus_Wheels_User_Offers_Params_Localization_Var_Pop_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_localization_var_pop_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Var_Pop_Order_By = {
  stage?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bonus_Wheels_User_Offers_Params_Localization_Var_Samp_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_localization_var_samp_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Var_Samp_Order_By = {
  stage?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bonus_Wheels_User_Offers_Params_Localization_Variance_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_localization_variance_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bonus_wheels_user_offers_params_localization" */
export type Bonus_Wheels_User_Offers_Params_Localization_Variance_Order_By = {
  stage?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Bonus_Wheels_User_Offers_Params_Max_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  button_action?: Maybe<Scalars['String']>;
  button_link?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
  offer_description?: Maybe<Scalars['String']>;
  offer_image_link?: Maybe<Scalars['String']>;
  offer_title?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Bonus_Wheels_User_Offers_Params_Min_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  button_action?: Maybe<Scalars['String']>;
  button_link?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
  offer_description?: Maybe<Scalars['String']>;
  offer_image_link?: Maybe<Scalars['String']>;
  offer_title?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_Params_Mutation_Response = {
  __typename?: 'bonus_wheels_user_offers_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonus_Wheels_User_Offers_Params>;
};

/** input type for inserting object relation for remote table "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_Params_Obj_Rel_Insert_Input = {
  data: Bonus_Wheels_User_Offers_Params_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonus_Wheels_User_Offers_Params_On_Conflict>;
};

/** on_conflict condition type for table "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_Params_On_Conflict = {
  constraint: Bonus_Wheels_User_Offers_Params_Constraint;
  update_columns?: Array<Bonus_Wheels_User_Offers_Params_Update_Column>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "bonus_wheels_user_offers_params". */
export type Bonus_Wheels_User_Offers_Params_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  button_action?: InputMaybe<Order_By>;
  button_link?: InputMaybe<Order_By>;
  button_text?: InputMaybe<Order_By>;
  localization_aggregate?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Aggregate_Order_By>;
  offer_description?: InputMaybe<Order_By>;
  offer_image_link?: InputMaybe<Order_By>;
  offer_title?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonus_wheels_user_offers_params */
export type Bonus_Wheels_User_Offers_Params_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  stage: Scalars['Int'];
};

/** select columns of table "bonus_wheels_user_offers_params" */
export enum Bonus_Wheels_User_Offers_Params_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ButtonAction = 'button_action',
  /** column name */
  ButtonLink = 'button_link',
  /** column name */
  ButtonText = 'button_text',
  /** column name */
  OfferDescription = 'offer_description',
  /** column name */
  OfferImageLink = 'offer_image_link',
  /** column name */
  OfferTitle = 'offer_title',
  /** column name */
  Stage = 'stage'
}

/** input type for updating data in table "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_Params_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  button_action?: InputMaybe<Scalars['String']>;
  button_link?: InputMaybe<Scalars['String']>;
  button_text?: InputMaybe<Scalars['String']>;
  offer_description?: InputMaybe<Scalars['String']>;
  offer_image_link?: InputMaybe<Scalars['String']>;
  offer_title?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bonus_Wheels_User_Offers_Params_Stddev_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_stddev_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bonus_Wheels_User_Offers_Params_Stddev_Pop_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_stddev_pop_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bonus_Wheels_User_Offers_Params_Stddev_Samp_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_stddev_samp_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bonus_wheels_user_offers_params" */
export type Bonus_Wheels_User_Offers_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_User_Offers_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_User_Offers_Params_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  button_action?: InputMaybe<Scalars['String']>;
  button_link?: InputMaybe<Scalars['String']>;
  button_text?: InputMaybe<Scalars['String']>;
  offer_description?: InputMaybe<Scalars['String']>;
  offer_image_link?: InputMaybe<Scalars['String']>;
  offer_title?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Bonus_Wheels_User_Offers_Params_Sum_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_sum_fields';
  stage?: Maybe<Scalars['Int']>;
};

/** update columns of table "bonus_wheels_user_offers_params" */
export enum Bonus_Wheels_User_Offers_Params_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ButtonAction = 'button_action',
  /** column name */
  ButtonLink = 'button_link',
  /** column name */
  ButtonText = 'button_text',
  /** column name */
  OfferDescription = 'offer_description',
  /** column name */
  OfferImageLink = 'offer_image_link',
  /** column name */
  OfferTitle = 'offer_title',
  /** column name */
  Stage = 'stage'
}

export type Bonus_Wheels_User_Offers_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonus_Wheels_User_Offers_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_User_Offers_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_User_Offers_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonus_Wheels_User_Offers_Params_Var_Pop_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_var_pop_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bonus_Wheels_User_Offers_Params_Var_Samp_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_var_samp_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bonus_Wheels_User_Offers_Params_Variance_Fields = {
  __typename?: 'bonus_wheels_user_offers_params_variance_fields';
  stage?: Maybe<Scalars['Float']>;
};

/** primary key columns input for table: bonus_wheels_user_offers */
export type Bonus_Wheels_User_Offers_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "bonus_wheels_user_offers" */
export enum Bonus_Wheels_User_Offers_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OfferAction = 'offer_action',
  /** column name */
  OfferClaimed = 'offer_claimed',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferStage = 'offer_stage',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "bonus_wheels_user_offers" */
export type Bonus_Wheels_User_Offers_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  offer_action?: InputMaybe<Scalars['String']>;
  offer_claimed?: InputMaybe<Scalars['Boolean']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_stage?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Bonus_Wheels_User_Offers_Stddev_Fields = {
  __typename?: 'bonus_wheels_user_offers_stddev_fields';
  offer_stage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bonus_Wheels_User_Offers_Stddev_Pop_Fields = {
  __typename?: 'bonus_wheels_user_offers_stddev_pop_fields';
  offer_stage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bonus_Wheels_User_Offers_Stddev_Samp_Fields = {
  __typename?: 'bonus_wheels_user_offers_stddev_samp_fields';
  offer_stage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bonus_wheels_user_offers" */
export type Bonus_Wheels_User_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonus_Wheels_User_Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonus_Wheels_User_Offers_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  offer_action?: InputMaybe<Scalars['String']>;
  offer_claimed?: InputMaybe<Scalars['Boolean']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_stage?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Bonus_Wheels_User_Offers_Sum_Fields = {
  __typename?: 'bonus_wheels_user_offers_sum_fields';
  offer_stage?: Maybe<Scalars['Int']>;
};

/** update columns of table "bonus_wheels_user_offers" */
export enum Bonus_Wheels_User_Offers_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OfferAction = 'offer_action',
  /** column name */
  OfferClaimed = 'offer_claimed',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferStage = 'offer_stage',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Bonus_Wheels_User_Offers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonus_Wheels_User_Offers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonus_Wheels_User_Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonus_Wheels_User_Offers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonus_Wheels_User_Offers_Var_Pop_Fields = {
  __typename?: 'bonus_wheels_user_offers_var_pop_fields';
  offer_stage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bonus_Wheels_User_Offers_Var_Samp_Fields = {
  __typename?: 'bonus_wheels_user_offers_var_samp_fields';
  offer_stage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bonus_Wheels_User_Offers_Variance_Fields = {
  __typename?: 'bonus_wheels_user_offers_variance_fields';
  offer_stage?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "bonuses" */
export type Bonuses = {
  __typename?: 'bonuses';
  active: Scalars['Boolean'];
  button_active: Scalars['Boolean'];
  button_text?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  header: Scalars['String'];
  id: Scalars['uuid'];
  link?: Maybe<Scalars['String']>;
  sort: Scalars['Int'];
  /** An array relationship */
  user_got_bonus: Array<User_Got_Bonus>;
  /** An aggregate relationship */
  user_got_bonus_aggregate: User_Got_Bonus_Aggregate;
};


/** columns and relationships of "bonuses" */
export type BonusesUser_Got_BonusArgs = {
  distinct_on?: InputMaybe<Array<User_Got_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Got_Bonus_Order_By>>;
  where?: InputMaybe<User_Got_Bonus_Bool_Exp>;
};


/** columns and relationships of "bonuses" */
export type BonusesUser_Got_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Got_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Got_Bonus_Order_By>>;
  where?: InputMaybe<User_Got_Bonus_Bool_Exp>;
};

/** aggregated selection of "bonuses" */
export type Bonuses_Aggregate = {
  __typename?: 'bonuses_aggregate';
  aggregate?: Maybe<Bonuses_Aggregate_Fields>;
  nodes: Array<Bonuses>;
};

/** aggregate fields of "bonuses" */
export type Bonuses_Aggregate_Fields = {
  __typename?: 'bonuses_aggregate_fields';
  avg?: Maybe<Bonuses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bonuses_Max_Fields>;
  min?: Maybe<Bonuses_Min_Fields>;
  stddev?: Maybe<Bonuses_Stddev_Fields>;
  stddev_pop?: Maybe<Bonuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonuses_Stddev_Samp_Fields>;
  sum?: Maybe<Bonuses_Sum_Fields>;
  var_pop?: Maybe<Bonuses_Var_Pop_Fields>;
  var_samp?: Maybe<Bonuses_Var_Samp_Fields>;
  variance?: Maybe<Bonuses_Variance_Fields>;
};


/** aggregate fields of "bonuses" */
export type Bonuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bonuses_Avg_Fields = {
  __typename?: 'bonuses_avg_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bonuses". All fields are combined with a logical 'AND'. */
export type Bonuses_Bool_Exp = {
  _and?: InputMaybe<Array<Bonuses_Bool_Exp>>;
  _not?: InputMaybe<Bonuses_Bool_Exp>;
  _or?: InputMaybe<Array<Bonuses_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  button_active?: InputMaybe<Boolean_Comparison_Exp>;
  button_text?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  header?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
  user_got_bonus?: InputMaybe<User_Got_Bonus_Bool_Exp>;
  user_got_bonus_aggregate?: InputMaybe<User_Got_Bonus_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "bonuses" */
export enum Bonuses_Constraint {
  /** unique or primary key constraint on columns "id" */
  BonusesPkey = 'bonuses_pkey'
}

/** input type for incrementing numeric columns in table "bonuses" */
export type Bonuses_Inc_Input = {
  sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bonuses" */
export type Bonuses_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  button_active?: InputMaybe<Scalars['Boolean']>;
  button_text?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
  user_got_bonus?: InputMaybe<User_Got_Bonus_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Bonuses_Max_Fields = {
  __typename?: 'bonuses_max_fields';
  button_text?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Bonuses_Min_Fields = {
  __typename?: 'bonuses_min_fields';
  button_text?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "bonuses" */
export type Bonuses_Mutation_Response = {
  __typename?: 'bonuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonuses>;
};

/** input type for inserting object relation for remote table "bonuses" */
export type Bonuses_Obj_Rel_Insert_Input = {
  data: Bonuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonuses_On_Conflict>;
};

/** on_conflict condition type for table "bonuses" */
export type Bonuses_On_Conflict = {
  constraint: Bonuses_Constraint;
  update_columns?: Array<Bonuses_Update_Column>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};

/** Ordering options when selecting data from "bonuses". */
export type Bonuses_Order_By = {
  active?: InputMaybe<Order_By>;
  button_active?: InputMaybe<Order_By>;
  button_text?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
  user_got_bonus_aggregate?: InputMaybe<User_Got_Bonus_Aggregate_Order_By>;
};

/** primary key columns input for table: bonuses */
export type Bonuses_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "bonuses_register" */
export type Bonuses_Register = {
  __typename?: 'bonuses_register';
  bonus_id: Scalars['uuid'];
  bonus_name: Scalars['String'];
  bonus_param: Scalars['String'];
  created_at: Scalars['timestamp'];
  default_end_timer: Scalars['Int'];
  default_global_end: Scalars['Int'];
  is_active: Scalars['Boolean'];
  /** An array relationship */
  offers: Array<Bonuses_Register_Offers>;
  /** An aggregate relationship */
  offers_aggregate: Bonuses_Register_Offers_Aggregate;
};


/** columns and relationships of "bonuses_register" */
export type Bonuses_RegisterOffersArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Register_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Register_Offers_Order_By>>;
  where?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
};


/** columns and relationships of "bonuses_register" */
export type Bonuses_RegisterOffers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Register_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Register_Offers_Order_By>>;
  where?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
};

/** aggregated selection of "bonuses_register" */
export type Bonuses_Register_Aggregate = {
  __typename?: 'bonuses_register_aggregate';
  aggregate?: Maybe<Bonuses_Register_Aggregate_Fields>;
  nodes: Array<Bonuses_Register>;
};

/** aggregate fields of "bonuses_register" */
export type Bonuses_Register_Aggregate_Fields = {
  __typename?: 'bonuses_register_aggregate_fields';
  avg?: Maybe<Bonuses_Register_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bonuses_Register_Max_Fields>;
  min?: Maybe<Bonuses_Register_Min_Fields>;
  stddev?: Maybe<Bonuses_Register_Stddev_Fields>;
  stddev_pop?: Maybe<Bonuses_Register_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonuses_Register_Stddev_Samp_Fields>;
  sum?: Maybe<Bonuses_Register_Sum_Fields>;
  var_pop?: Maybe<Bonuses_Register_Var_Pop_Fields>;
  var_samp?: Maybe<Bonuses_Register_Var_Samp_Fields>;
  variance?: Maybe<Bonuses_Register_Variance_Fields>;
};


/** aggregate fields of "bonuses_register" */
export type Bonuses_Register_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonuses_Register_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bonuses_Register_Avg_Fields = {
  __typename?: 'bonuses_register_avg_fields';
  default_end_timer?: Maybe<Scalars['Float']>;
  default_global_end?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bonuses_register". All fields are combined with a logical 'AND'. */
export type Bonuses_Register_Bool_Exp = {
  _and?: InputMaybe<Array<Bonuses_Register_Bool_Exp>>;
  _not?: InputMaybe<Bonuses_Register_Bool_Exp>;
  _or?: InputMaybe<Array<Bonuses_Register_Bool_Exp>>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  bonus_name?: InputMaybe<String_Comparison_Exp>;
  bonus_param?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  default_end_timer?: InputMaybe<Int_Comparison_Exp>;
  default_global_end?: InputMaybe<Int_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  offers?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
  offers_aggregate?: InputMaybe<Bonuses_Register_Offers_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "bonuses_register" */
export enum Bonuses_Register_Constraint {
  /** unique or primary key constraint on columns "bonus_id" */
  BonusesRegisterPkey = 'bonuses_register_pkey'
}

/** input type for incrementing numeric columns in table "bonuses_register" */
export type Bonuses_Register_Inc_Input = {
  default_end_timer?: InputMaybe<Scalars['Int']>;
  default_global_end?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bonuses_register" */
export type Bonuses_Register_Insert_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_name?: InputMaybe<Scalars['String']>;
  bonus_param?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_end_timer?: InputMaybe<Scalars['Int']>;
  default_global_end?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  offers?: InputMaybe<Bonuses_Register_Offers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Bonuses_Register_Max_Fields = {
  __typename?: 'bonuses_register_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_name?: Maybe<Scalars['String']>;
  bonus_param?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  default_end_timer?: Maybe<Scalars['Int']>;
  default_global_end?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Bonuses_Register_Min_Fields = {
  __typename?: 'bonuses_register_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_name?: Maybe<Scalars['String']>;
  bonus_param?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  default_end_timer?: Maybe<Scalars['Int']>;
  default_global_end?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "bonuses_register" */
export type Bonuses_Register_Mutation_Response = {
  __typename?: 'bonuses_register_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonuses_Register>;
};

/** input type for inserting object relation for remote table "bonuses_register" */
export type Bonuses_Register_Obj_Rel_Insert_Input = {
  data: Bonuses_Register_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonuses_Register_On_Conflict>;
};

/** columns and relationships of "bonuses_register_offers" */
export type Bonuses_Register_Offers = {
  __typename?: 'bonuses_register_offers';
  bonus_id: Scalars['uuid'];
  /** An object relationship */
  bonus_register?: Maybe<Bonuses_Register>;
  claim_datetime?: Maybe<Scalars['timestamp']>;
  claimed: Scalars['Boolean'];
  current_active: Scalars['Boolean'];
  end_timer: Scalars['Int'];
  global_end_datetime?: Maybe<Scalars['timestamp']>;
  offer_value: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "bonuses_register_offers" */
export type Bonuses_Register_Offers_Aggregate = {
  __typename?: 'bonuses_register_offers_aggregate';
  aggregate?: Maybe<Bonuses_Register_Offers_Aggregate_Fields>;
  nodes: Array<Bonuses_Register_Offers>;
};

export type Bonuses_Register_Offers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Bonuses_Register_Offers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Bonuses_Register_Offers_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Bonuses_Register_Offers_Aggregate_Bool_Exp_Count>;
};

export type Bonuses_Register_Offers_Aggregate_Bool_Exp_Bool_And = {
  arguments: Bonuses_Register_Offers_Select_Column_Bonuses_Register_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Bonuses_Register_Offers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Bonuses_Register_Offers_Select_Column_Bonuses_Register_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Bonuses_Register_Offers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bonuses_Register_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "bonuses_register_offers" */
export type Bonuses_Register_Offers_Aggregate_Fields = {
  __typename?: 'bonuses_register_offers_aggregate_fields';
  avg?: Maybe<Bonuses_Register_Offers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bonuses_Register_Offers_Max_Fields>;
  min?: Maybe<Bonuses_Register_Offers_Min_Fields>;
  stddev?: Maybe<Bonuses_Register_Offers_Stddev_Fields>;
  stddev_pop?: Maybe<Bonuses_Register_Offers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bonuses_Register_Offers_Stddev_Samp_Fields>;
  sum?: Maybe<Bonuses_Register_Offers_Sum_Fields>;
  var_pop?: Maybe<Bonuses_Register_Offers_Var_Pop_Fields>;
  var_samp?: Maybe<Bonuses_Register_Offers_Var_Samp_Fields>;
  variance?: Maybe<Bonuses_Register_Offers_Variance_Fields>;
};


/** aggregate fields of "bonuses_register_offers" */
export type Bonuses_Register_Offers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bonuses_Register_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Aggregate_Order_By = {
  avg?: InputMaybe<Bonuses_Register_Offers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bonuses_Register_Offers_Max_Order_By>;
  min?: InputMaybe<Bonuses_Register_Offers_Min_Order_By>;
  stddev?: InputMaybe<Bonuses_Register_Offers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bonuses_Register_Offers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bonuses_Register_Offers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bonuses_Register_Offers_Sum_Order_By>;
  var_pop?: InputMaybe<Bonuses_Register_Offers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bonuses_Register_Offers_Var_Samp_Order_By>;
  variance?: InputMaybe<Bonuses_Register_Offers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Arr_Rel_Insert_Input = {
  data: Array<Bonuses_Register_Offers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bonuses_Register_Offers_On_Conflict>;
};

/** aggregate avg on columns */
export type Bonuses_Register_Offers_Avg_Fields = {
  __typename?: 'bonuses_register_offers_avg_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Avg_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bonuses_register_offers". All fields are combined with a logical 'AND'. */
export type Bonuses_Register_Offers_Bool_Exp = {
  _and?: InputMaybe<Array<Bonuses_Register_Offers_Bool_Exp>>;
  _not?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
  _or?: InputMaybe<Array<Bonuses_Register_Offers_Bool_Exp>>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  bonus_register?: InputMaybe<Bonuses_Register_Bool_Exp>;
  claim_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  claimed?: InputMaybe<Boolean_Comparison_Exp>;
  current_active?: InputMaybe<Boolean_Comparison_Exp>;
  end_timer?: InputMaybe<Int_Comparison_Exp>;
  global_end_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  offer_value?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "bonuses_register_offers" */
export enum Bonuses_Register_Offers_Constraint {
  /** unique or primary key constraint on columns "user_id", "bonus_id" */
  BonusesRegisterOffersPkey = 'bonuses_register_offers_pkey'
}

/** input type for incrementing numeric columns in table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Inc_Input = {
  end_timer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Insert_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_register?: InputMaybe<Bonuses_Register_Obj_Rel_Insert_Input>;
  claim_datetime?: InputMaybe<Scalars['timestamp']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  current_active?: InputMaybe<Scalars['Boolean']>;
  end_timer?: InputMaybe<Scalars['Int']>;
  global_end_datetime?: InputMaybe<Scalars['timestamp']>;
  offer_value?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Bonuses_Register_Offers_Max_Fields = {
  __typename?: 'bonuses_register_offers_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  claim_datetime?: Maybe<Scalars['timestamp']>;
  end_timer?: Maybe<Scalars['Int']>;
  global_end_datetime?: Maybe<Scalars['timestamp']>;
  offer_value?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Max_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  claim_datetime?: InputMaybe<Order_By>;
  end_timer?: InputMaybe<Order_By>;
  global_end_datetime?: InputMaybe<Order_By>;
  offer_value?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bonuses_Register_Offers_Min_Fields = {
  __typename?: 'bonuses_register_offers_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  claim_datetime?: Maybe<Scalars['timestamp']>;
  end_timer?: Maybe<Scalars['Int']>;
  global_end_datetime?: Maybe<Scalars['timestamp']>;
  offer_value?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Min_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  claim_datetime?: InputMaybe<Order_By>;
  end_timer?: InputMaybe<Order_By>;
  global_end_datetime?: InputMaybe<Order_By>;
  offer_value?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Mutation_Response = {
  __typename?: 'bonuses_register_offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bonuses_Register_Offers>;
};

/** on_conflict condition type for table "bonuses_register_offers" */
export type Bonuses_Register_Offers_On_Conflict = {
  constraint: Bonuses_Register_Offers_Constraint;
  update_columns?: Array<Bonuses_Register_Offers_Update_Column>;
  where?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "bonuses_register_offers". */
export type Bonuses_Register_Offers_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  bonus_register?: InputMaybe<Bonuses_Register_Order_By>;
  claim_datetime?: InputMaybe<Order_By>;
  claimed?: InputMaybe<Order_By>;
  current_active?: InputMaybe<Order_By>;
  end_timer?: InputMaybe<Order_By>;
  global_end_datetime?: InputMaybe<Order_By>;
  offer_value?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bonuses_register_offers */
export type Bonuses_Register_Offers_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "bonuses_register_offers" */
export enum Bonuses_Register_Offers_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ClaimDatetime = 'claim_datetime',
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CurrentActive = 'current_active',
  /** column name */
  EndTimer = 'end_timer',
  /** column name */
  GlobalEndDatetime = 'global_end_datetime',
  /** column name */
  OfferValue = 'offer_value',
  /** column name */
  UserId = 'user_id'
}

/** select "bonuses_register_offers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "bonuses_register_offers" */
export enum Bonuses_Register_Offers_Select_Column_Bonuses_Register_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CurrentActive = 'current_active'
}

/** select "bonuses_register_offers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "bonuses_register_offers" */
export enum Bonuses_Register_Offers_Select_Column_Bonuses_Register_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CurrentActive = 'current_active'
}

/** input type for updating data in table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  claim_datetime?: InputMaybe<Scalars['timestamp']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  current_active?: InputMaybe<Scalars['Boolean']>;
  end_timer?: InputMaybe<Scalars['Int']>;
  global_end_datetime?: InputMaybe<Scalars['timestamp']>;
  offer_value?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Bonuses_Register_Offers_Stddev_Fields = {
  __typename?: 'bonuses_register_offers_stddev_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Stddev_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bonuses_Register_Offers_Stddev_Pop_Fields = {
  __typename?: 'bonuses_register_offers_stddev_pop_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Stddev_Pop_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bonuses_Register_Offers_Stddev_Samp_Fields = {
  __typename?: 'bonuses_register_offers_stddev_samp_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Stddev_Samp_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonuses_Register_Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonuses_Register_Offers_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  claim_datetime?: InputMaybe<Scalars['timestamp']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  current_active?: InputMaybe<Scalars['Boolean']>;
  end_timer?: InputMaybe<Scalars['Int']>;
  global_end_datetime?: InputMaybe<Scalars['timestamp']>;
  offer_value?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Bonuses_Register_Offers_Sum_Fields = {
  __typename?: 'bonuses_register_offers_sum_fields';
  end_timer?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Sum_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** update columns of table "bonuses_register_offers" */
export enum Bonuses_Register_Offers_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ClaimDatetime = 'claim_datetime',
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CurrentActive = 'current_active',
  /** column name */
  EndTimer = 'end_timer',
  /** column name */
  GlobalEndDatetime = 'global_end_datetime',
  /** column name */
  OfferValue = 'offer_value',
  /** column name */
  UserId = 'user_id'
}

export type Bonuses_Register_Offers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonuses_Register_Offers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonuses_Register_Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonuses_Register_Offers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonuses_Register_Offers_Var_Pop_Fields = {
  __typename?: 'bonuses_register_offers_var_pop_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Var_Pop_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bonuses_Register_Offers_Var_Samp_Fields = {
  __typename?: 'bonuses_register_offers_var_samp_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Var_Samp_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bonuses_Register_Offers_Variance_Fields = {
  __typename?: 'bonuses_register_offers_variance_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bonuses_register_offers" */
export type Bonuses_Register_Offers_Variance_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "bonuses_register" */
export type Bonuses_Register_On_Conflict = {
  constraint: Bonuses_Register_Constraint;
  update_columns?: Array<Bonuses_Register_Update_Column>;
  where?: InputMaybe<Bonuses_Register_Bool_Exp>;
};

/** Ordering options when selecting data from "bonuses_register". */
export type Bonuses_Register_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  bonus_name?: InputMaybe<Order_By>;
  bonus_param?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_end_timer?: InputMaybe<Order_By>;
  default_global_end?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  offers_aggregate?: InputMaybe<Bonuses_Register_Offers_Aggregate_Order_By>;
};

/** primary key columns input for table: bonuses_register */
export type Bonuses_Register_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
};

/** select columns of table "bonuses_register" */
export enum Bonuses_Register_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusName = 'bonus_name',
  /** column name */
  BonusParam = 'bonus_param',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultEndTimer = 'default_end_timer',
  /** column name */
  DefaultGlobalEnd = 'default_global_end',
  /** column name */
  IsActive = 'is_active'
}

/** input type for updating data in table "bonuses_register" */
export type Bonuses_Register_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_name?: InputMaybe<Scalars['String']>;
  bonus_param?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_end_timer?: InputMaybe<Scalars['Int']>;
  default_global_end?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Bonuses_Register_Stddev_Fields = {
  __typename?: 'bonuses_register_stddev_fields';
  default_end_timer?: Maybe<Scalars['Float']>;
  default_global_end?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bonuses_Register_Stddev_Pop_Fields = {
  __typename?: 'bonuses_register_stddev_pop_fields';
  default_end_timer?: Maybe<Scalars['Float']>;
  default_global_end?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bonuses_Register_Stddev_Samp_Fields = {
  __typename?: 'bonuses_register_stddev_samp_fields';
  default_end_timer?: Maybe<Scalars['Float']>;
  default_global_end?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bonuses_register" */
export type Bonuses_Register_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonuses_Register_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonuses_Register_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_name?: InputMaybe<Scalars['String']>;
  bonus_param?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_end_timer?: InputMaybe<Scalars['Int']>;
  default_global_end?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Bonuses_Register_Sum_Fields = {
  __typename?: 'bonuses_register_sum_fields';
  default_end_timer?: Maybe<Scalars['Int']>;
  default_global_end?: Maybe<Scalars['Int']>;
};

/** update columns of table "bonuses_register" */
export enum Bonuses_Register_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusName = 'bonus_name',
  /** column name */
  BonusParam = 'bonus_param',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultEndTimer = 'default_end_timer',
  /** column name */
  DefaultGlobalEnd = 'default_global_end',
  /** column name */
  IsActive = 'is_active'
}

export type Bonuses_Register_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonuses_Register_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonuses_Register_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonuses_Register_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonuses_Register_Var_Pop_Fields = {
  __typename?: 'bonuses_register_var_pop_fields';
  default_end_timer?: Maybe<Scalars['Float']>;
  default_global_end?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bonuses_Register_Var_Samp_Fields = {
  __typename?: 'bonuses_register_var_samp_fields';
  default_end_timer?: Maybe<Scalars['Float']>;
  default_global_end?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bonuses_Register_Variance_Fields = {
  __typename?: 'bonuses_register_variance_fields';
  default_end_timer?: Maybe<Scalars['Float']>;
  default_global_end?: Maybe<Scalars['Float']>;
};

/** select columns of table "bonuses" */
export enum Bonuses_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ButtonActive = 'button_active',
  /** column name */
  ButtonText = 'button_text',
  /** column name */
  Description = 'description',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Sort = 'sort'
}

/** input type for updating data in table "bonuses" */
export type Bonuses_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  button_active?: InputMaybe<Scalars['Boolean']>;
  button_text?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bonuses_Stddev_Fields = {
  __typename?: 'bonuses_stddev_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bonuses_Stddev_Pop_Fields = {
  __typename?: 'bonuses_stddev_pop_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bonuses_Stddev_Samp_Fields = {
  __typename?: 'bonuses_stddev_samp_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bonuses" */
export type Bonuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bonuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bonuses_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  button_active?: InputMaybe<Scalars['Boolean']>;
  button_text?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Bonuses_Sum_Fields = {
  __typename?: 'bonuses_sum_fields';
  sort?: Maybe<Scalars['Int']>;
};

/** update columns of table "bonuses" */
export enum Bonuses_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ButtonActive = 'button_active',
  /** column name */
  ButtonText = 'button_text',
  /** column name */
  Description = 'description',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Sort = 'sort'
}

export type Bonuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bonuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bonuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bonuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bonuses_Var_Pop_Fields = {
  __typename?: 'bonuses_var_pop_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bonuses_Var_Samp_Fields = {
  __typename?: 'bonuses_var_samp_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bonuses_Variance_Fields = {
  __typename?: 'bonuses_variance_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** Содержимое наборов билетов для продажи */
export type Boosters_Set_Content = {
  __typename?: 'boosters_set_content';
  booster_detail?: Maybe<Lootbox_Booster_Items>;
  /** Идентификатор бустера в таблице lootbox_db.lootbox_booster_items */
  booster_id: Scalars['uuid'];
  content_id: Scalars['bigint'];
  /** Описание предмета */
  description: Scalars['String'];
  /** Количество бустеров в наборе */
  quantity: Scalars['Int'];
  /** Идентификатор набора */
  set_id: Scalars['bigint'];
};

/** aggregated selection of "boosters_set_content" */
export type Boosters_Set_Content_Aggregate = {
  __typename?: 'boosters_set_content_aggregate';
  aggregate?: Maybe<Boosters_Set_Content_Aggregate_Fields>;
  nodes: Array<Boosters_Set_Content>;
};

export type Boosters_Set_Content_Aggregate_Bool_Exp = {
  count?: InputMaybe<Boosters_Set_Content_Aggregate_Bool_Exp_Count>;
};

export type Boosters_Set_Content_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Boosters_Set_Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Boosters_Set_Content_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "boosters_set_content" */
export type Boosters_Set_Content_Aggregate_Fields = {
  __typename?: 'boosters_set_content_aggregate_fields';
  avg?: Maybe<Boosters_Set_Content_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Boosters_Set_Content_Max_Fields>;
  min?: Maybe<Boosters_Set_Content_Min_Fields>;
  stddev?: Maybe<Boosters_Set_Content_Stddev_Fields>;
  stddev_pop?: Maybe<Boosters_Set_Content_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Boosters_Set_Content_Stddev_Samp_Fields>;
  sum?: Maybe<Boosters_Set_Content_Sum_Fields>;
  var_pop?: Maybe<Boosters_Set_Content_Var_Pop_Fields>;
  var_samp?: Maybe<Boosters_Set_Content_Var_Samp_Fields>;
  variance?: Maybe<Boosters_Set_Content_Variance_Fields>;
};


/** aggregate fields of "boosters_set_content" */
export type Boosters_Set_Content_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Boosters_Set_Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "boosters_set_content" */
export type Boosters_Set_Content_Aggregate_Order_By = {
  avg?: InputMaybe<Boosters_Set_Content_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Boosters_Set_Content_Max_Order_By>;
  min?: InputMaybe<Boosters_Set_Content_Min_Order_By>;
  stddev?: InputMaybe<Boosters_Set_Content_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Boosters_Set_Content_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Boosters_Set_Content_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Boosters_Set_Content_Sum_Order_By>;
  var_pop?: InputMaybe<Boosters_Set_Content_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Boosters_Set_Content_Var_Samp_Order_By>;
  variance?: InputMaybe<Boosters_Set_Content_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "boosters_set_content" */
export type Boosters_Set_Content_Arr_Rel_Insert_Input = {
  data: Array<Boosters_Set_Content_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Boosters_Set_Content_On_Conflict>;
};

/** aggregate avg on columns */
export type Boosters_Set_Content_Avg_Fields = {
  __typename?: 'boosters_set_content_avg_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество бустеров в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "boosters_set_content" */
export type Boosters_Set_Content_Avg_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "boosters_set_content". All fields are combined with a logical 'AND'. */
export type Boosters_Set_Content_Bool_Exp = {
  _and?: InputMaybe<Array<Boosters_Set_Content_Bool_Exp>>;
  _not?: InputMaybe<Boosters_Set_Content_Bool_Exp>;
  _or?: InputMaybe<Array<Boosters_Set_Content_Bool_Exp>>;
  booster_id?: InputMaybe<Uuid_Comparison_Exp>;
  content_id?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  set_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "boosters_set_content" */
export enum Boosters_Set_Content_Constraint {
  /** unique or primary key constraint on columns "content_id" */
  BoostersSetContentPkey = 'boosters_set_content_pkey'
}

/** input type for incrementing numeric columns in table "boosters_set_content" */
export type Boosters_Set_Content_Inc_Input = {
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "boosters_set_content" */
export type Boosters_Set_Content_Insert_Input = {
  /** Идентификатор бустера в таблице lootbox_db.lootbox_booster_items */
  booster_id?: InputMaybe<Scalars['uuid']>;
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: InputMaybe<Scalars['String']>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Boosters_Set_Content_Max_Fields = {
  __typename?: 'boosters_set_content_max_fields';
  /** Идентификатор бустера в таблице lootbox_db.lootbox_booster_items */
  booster_id?: Maybe<Scalars['uuid']>;
  content_id?: Maybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: Maybe<Scalars['String']>;
  /** Количество бустеров в наборе */
  quantity?: Maybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "boosters_set_content" */
export type Boosters_Set_Content_Max_Order_By = {
  /** Идентификатор бустера в таблице lootbox_db.lootbox_booster_items */
  booster_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  /** Описание предмета */
  description?: InputMaybe<Order_By>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Boosters_Set_Content_Min_Fields = {
  __typename?: 'boosters_set_content_min_fields';
  /** Идентификатор бустера в таблице lootbox_db.lootbox_booster_items */
  booster_id?: Maybe<Scalars['uuid']>;
  content_id?: Maybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: Maybe<Scalars['String']>;
  /** Количество бустеров в наборе */
  quantity?: Maybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "boosters_set_content" */
export type Boosters_Set_Content_Min_Order_By = {
  /** Идентификатор бустера в таблице lootbox_db.lootbox_booster_items */
  booster_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  /** Описание предмета */
  description?: InputMaybe<Order_By>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "boosters_set_content" */
export type Boosters_Set_Content_Mutation_Response = {
  __typename?: 'boosters_set_content_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Boosters_Set_Content>;
};

/** on_conflict condition type for table "boosters_set_content" */
export type Boosters_Set_Content_On_Conflict = {
  constraint: Boosters_Set_Content_Constraint;
  update_columns?: Array<Boosters_Set_Content_Update_Column>;
  where?: InputMaybe<Boosters_Set_Content_Bool_Exp>;
};

/** Ordering options when selecting data from "boosters_set_content". */
export type Boosters_Set_Content_Order_By = {
  booster_id?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: boosters_set_content */
export type Boosters_Set_Content_Pk_Columns_Input = {
  content_id: Scalars['bigint'];
};

/** select columns of table "boosters_set_content" */
export enum Boosters_Set_Content_Select_Column {
  /** column name */
  BoosterId = 'booster_id',
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Description = 'description',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SetId = 'set_id'
}

/** input type for updating data in table "boosters_set_content" */
export type Boosters_Set_Content_Set_Input = {
  /** Идентификатор бустера в таблице lootbox_db.lootbox_booster_items */
  booster_id?: InputMaybe<Scalars['uuid']>;
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: InputMaybe<Scalars['String']>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Boosters_Set_Content_Stddev_Fields = {
  __typename?: 'boosters_set_content_stddev_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество бустеров в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "boosters_set_content" */
export type Boosters_Set_Content_Stddev_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Boosters_Set_Content_Stddev_Pop_Fields = {
  __typename?: 'boosters_set_content_stddev_pop_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество бустеров в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "boosters_set_content" */
export type Boosters_Set_Content_Stddev_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Boosters_Set_Content_Stddev_Samp_Fields = {
  __typename?: 'boosters_set_content_stddev_samp_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество бустеров в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "boosters_set_content" */
export type Boosters_Set_Content_Stddev_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "boosters_set_content" */
export type Boosters_Set_Content_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Boosters_Set_Content_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Boosters_Set_Content_Stream_Cursor_Value_Input = {
  /** Идентификатор бустера в таблице lootbox_db.lootbox_booster_items */
  booster_id?: InputMaybe<Scalars['uuid']>;
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: InputMaybe<Scalars['String']>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Boosters_Set_Content_Sum_Fields = {
  __typename?: 'boosters_set_content_sum_fields';
  content_id?: Maybe<Scalars['bigint']>;
  /** Количество бустеров в наборе */
  quantity?: Maybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "boosters_set_content" */
export type Boosters_Set_Content_Sum_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** update columns of table "boosters_set_content" */
export enum Boosters_Set_Content_Update_Column {
  /** column name */
  BoosterId = 'booster_id',
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Description = 'description',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SetId = 'set_id'
}

export type Boosters_Set_Content_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Boosters_Set_Content_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Boosters_Set_Content_Set_Input>;
  /** filter the rows which have to be updated */
  where: Boosters_Set_Content_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Boosters_Set_Content_Var_Pop_Fields = {
  __typename?: 'boosters_set_content_var_pop_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество бустеров в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "boosters_set_content" */
export type Boosters_Set_Content_Var_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Boosters_Set_Content_Var_Samp_Fields = {
  __typename?: 'boosters_set_content_var_samp_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество бустеров в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "boosters_set_content" */
export type Boosters_Set_Content_Var_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Boosters_Set_Content_Variance_Fields = {
  __typename?: 'boosters_set_content_variance_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество бустеров в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "boosters_set_content" */
export type Boosters_Set_Content_Variance_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество бустеров в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** Наборы билетов для продажи */
export type Boosters_Sets = {
  __typename?: 'boosters_sets';
  /** Описание набора */
  description: Scalars['String'];
  set_id: Scalars['bigint'];
};

/** aggregated selection of "boosters_sets" */
export type Boosters_Sets_Aggregate = {
  __typename?: 'boosters_sets_aggregate';
  aggregate?: Maybe<Boosters_Sets_Aggregate_Fields>;
  nodes: Array<Boosters_Sets>;
};

/** aggregate fields of "boosters_sets" */
export type Boosters_Sets_Aggregate_Fields = {
  __typename?: 'boosters_sets_aggregate_fields';
  avg?: Maybe<Boosters_Sets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Boosters_Sets_Max_Fields>;
  min?: Maybe<Boosters_Sets_Min_Fields>;
  stddev?: Maybe<Boosters_Sets_Stddev_Fields>;
  stddev_pop?: Maybe<Boosters_Sets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Boosters_Sets_Stddev_Samp_Fields>;
  sum?: Maybe<Boosters_Sets_Sum_Fields>;
  var_pop?: Maybe<Boosters_Sets_Var_Pop_Fields>;
  var_samp?: Maybe<Boosters_Sets_Var_Samp_Fields>;
  variance?: Maybe<Boosters_Sets_Variance_Fields>;
};


/** aggregate fields of "boosters_sets" */
export type Boosters_Sets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Boosters_Sets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Boosters_Sets_Avg_Fields = {
  __typename?: 'boosters_sets_avg_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "boosters_sets". All fields are combined with a logical 'AND'. */
export type Boosters_Sets_Bool_Exp = {
  _and?: InputMaybe<Array<Boosters_Sets_Bool_Exp>>;
  _not?: InputMaybe<Boosters_Sets_Bool_Exp>;
  _or?: InputMaybe<Array<Boosters_Sets_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  set_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "boosters_sets" */
export enum Boosters_Sets_Constraint {
  /** unique or primary key constraint on columns "set_id" */
  BoostersSetsPkey = 'boosters_sets_pkey'
}

/** input type for incrementing numeric columns in table "boosters_sets" */
export type Boosters_Sets_Inc_Input = {
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "boosters_sets" */
export type Boosters_Sets_Insert_Input = {
  /** Описание набора */
  description?: InputMaybe<Scalars['String']>;
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Boosters_Sets_Max_Fields = {
  __typename?: 'boosters_sets_max_fields';
  /** Описание набора */
  description?: Maybe<Scalars['String']>;
  set_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Boosters_Sets_Min_Fields = {
  __typename?: 'boosters_sets_min_fields';
  /** Описание набора */
  description?: Maybe<Scalars['String']>;
  set_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "boosters_sets" */
export type Boosters_Sets_Mutation_Response = {
  __typename?: 'boosters_sets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Boosters_Sets>;
};

/** on_conflict condition type for table "boosters_sets" */
export type Boosters_Sets_On_Conflict = {
  constraint: Boosters_Sets_Constraint;
  update_columns?: Array<Boosters_Sets_Update_Column>;
  where?: InputMaybe<Boosters_Sets_Bool_Exp>;
};

/** Ordering options when selecting data from "boosters_sets". */
export type Boosters_Sets_Order_By = {
  description?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: boosters_sets */
export type Boosters_Sets_Pk_Columns_Input = {
  set_id: Scalars['bigint'];
};

/** select columns of table "boosters_sets" */
export enum Boosters_Sets_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  SetId = 'set_id'
}

/** input type for updating data in table "boosters_sets" */
export type Boosters_Sets_Set_Input = {
  /** Описание набора */
  description?: InputMaybe<Scalars['String']>;
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Boosters_Sets_Stddev_Fields = {
  __typename?: 'boosters_sets_stddev_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Boosters_Sets_Stddev_Pop_Fields = {
  __typename?: 'boosters_sets_stddev_pop_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Boosters_Sets_Stddev_Samp_Fields = {
  __typename?: 'boosters_sets_stddev_samp_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "boosters_sets" */
export type Boosters_Sets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Boosters_Sets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Boosters_Sets_Stream_Cursor_Value_Input = {
  /** Описание набора */
  description?: InputMaybe<Scalars['String']>;
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Boosters_Sets_Sum_Fields = {
  __typename?: 'boosters_sets_sum_fields';
  set_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "boosters_sets" */
export enum Boosters_Sets_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  SetId = 'set_id'
}

export type Boosters_Sets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Boosters_Sets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Boosters_Sets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Boosters_Sets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Boosters_Sets_Var_Pop_Fields = {
  __typename?: 'boosters_sets_var_pop_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Boosters_Sets_Var_Samp_Fields = {
  __typename?: 'boosters_sets_var_samp_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Boosters_Sets_Variance_Fields = {
  __typename?: 'boosters_sets_variance_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "bot_account_settings" */
export type Bot_Account_Settings = {
  __typename?: 'bot_account_settings';
  active_bots_count: Scalars['Int'];
  case_open_count_end: Scalars['Int'];
  case_open_count_start: Scalars['Int'];
  id: Scalars['Int'];
  seconds_interval_end: Scalars['Int'];
  seconds_interval_start: Scalars['Int'];
  time_interval_end: Scalars['Int'];
  time_interval_start: Scalars['Int'];
  workers_count: Scalars['Int'];
};

/** aggregated selection of "bot_account_settings" */
export type Bot_Account_Settings_Aggregate = {
  __typename?: 'bot_account_settings_aggregate';
  aggregate?: Maybe<Bot_Account_Settings_Aggregate_Fields>;
  nodes: Array<Bot_Account_Settings>;
};

/** aggregate fields of "bot_account_settings" */
export type Bot_Account_Settings_Aggregate_Fields = {
  __typename?: 'bot_account_settings_aggregate_fields';
  avg?: Maybe<Bot_Account_Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bot_Account_Settings_Max_Fields>;
  min?: Maybe<Bot_Account_Settings_Min_Fields>;
  stddev?: Maybe<Bot_Account_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Bot_Account_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bot_Account_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Bot_Account_Settings_Sum_Fields>;
  var_pop?: Maybe<Bot_Account_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Bot_Account_Settings_Var_Samp_Fields>;
  variance?: Maybe<Bot_Account_Settings_Variance_Fields>;
};


/** aggregate fields of "bot_account_settings" */
export type Bot_Account_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bot_Account_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bot_Account_Settings_Avg_Fields = {
  __typename?: 'bot_account_settings_avg_fields';
  active_bots_count?: Maybe<Scalars['Float']>;
  case_open_count_end?: Maybe<Scalars['Float']>;
  case_open_count_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seconds_interval_end?: Maybe<Scalars['Float']>;
  seconds_interval_start?: Maybe<Scalars['Float']>;
  time_interval_end?: Maybe<Scalars['Float']>;
  time_interval_start?: Maybe<Scalars['Float']>;
  workers_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bot_account_settings". All fields are combined with a logical 'AND'. */
export type Bot_Account_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Bot_Account_Settings_Bool_Exp>>;
  _not?: InputMaybe<Bot_Account_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Bot_Account_Settings_Bool_Exp>>;
  active_bots_count?: InputMaybe<Int_Comparison_Exp>;
  case_open_count_end?: InputMaybe<Int_Comparison_Exp>;
  case_open_count_start?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  seconds_interval_end?: InputMaybe<Int_Comparison_Exp>;
  seconds_interval_start?: InputMaybe<Int_Comparison_Exp>;
  time_interval_end?: InputMaybe<Int_Comparison_Exp>;
  time_interval_start?: InputMaybe<Int_Comparison_Exp>;
  workers_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "bot_account_settings" */
export enum Bot_Account_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  BotAccountSettingsPkey = 'bot_account_settings_pkey'
}

/** input type for incrementing numeric columns in table "bot_account_settings" */
export type Bot_Account_Settings_Inc_Input = {
  active_bots_count?: InputMaybe<Scalars['Int']>;
  case_open_count_end?: InputMaybe<Scalars['Int']>;
  case_open_count_start?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  seconds_interval_end?: InputMaybe<Scalars['Int']>;
  seconds_interval_start?: InputMaybe<Scalars['Int']>;
  time_interval_end?: InputMaybe<Scalars['Int']>;
  time_interval_start?: InputMaybe<Scalars['Int']>;
  workers_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bot_account_settings" */
export type Bot_Account_Settings_Insert_Input = {
  active_bots_count?: InputMaybe<Scalars['Int']>;
  case_open_count_end?: InputMaybe<Scalars['Int']>;
  case_open_count_start?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  seconds_interval_end?: InputMaybe<Scalars['Int']>;
  seconds_interval_start?: InputMaybe<Scalars['Int']>;
  time_interval_end?: InputMaybe<Scalars['Int']>;
  time_interval_start?: InputMaybe<Scalars['Int']>;
  workers_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Bot_Account_Settings_Max_Fields = {
  __typename?: 'bot_account_settings_max_fields';
  active_bots_count?: Maybe<Scalars['Int']>;
  case_open_count_end?: Maybe<Scalars['Int']>;
  case_open_count_start?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  seconds_interval_end?: Maybe<Scalars['Int']>;
  seconds_interval_start?: Maybe<Scalars['Int']>;
  time_interval_end?: Maybe<Scalars['Int']>;
  time_interval_start?: Maybe<Scalars['Int']>;
  workers_count?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Bot_Account_Settings_Min_Fields = {
  __typename?: 'bot_account_settings_min_fields';
  active_bots_count?: Maybe<Scalars['Int']>;
  case_open_count_end?: Maybe<Scalars['Int']>;
  case_open_count_start?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  seconds_interval_end?: Maybe<Scalars['Int']>;
  seconds_interval_start?: Maybe<Scalars['Int']>;
  time_interval_end?: Maybe<Scalars['Int']>;
  time_interval_start?: Maybe<Scalars['Int']>;
  workers_count?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "bot_account_settings" */
export type Bot_Account_Settings_Mutation_Response = {
  __typename?: 'bot_account_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bot_Account_Settings>;
};

/** on_conflict condition type for table "bot_account_settings" */
export type Bot_Account_Settings_On_Conflict = {
  constraint: Bot_Account_Settings_Constraint;
  update_columns?: Array<Bot_Account_Settings_Update_Column>;
  where?: InputMaybe<Bot_Account_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "bot_account_settings". */
export type Bot_Account_Settings_Order_By = {
  active_bots_count?: InputMaybe<Order_By>;
  case_open_count_end?: InputMaybe<Order_By>;
  case_open_count_start?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  seconds_interval_end?: InputMaybe<Order_By>;
  seconds_interval_start?: InputMaybe<Order_By>;
  time_interval_end?: InputMaybe<Order_By>;
  time_interval_start?: InputMaybe<Order_By>;
  workers_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bot_account_settings */
export type Bot_Account_Settings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "bot_account_settings" */
export enum Bot_Account_Settings_Select_Column {
  /** column name */
  ActiveBotsCount = 'active_bots_count',
  /** column name */
  CaseOpenCountEnd = 'case_open_count_end',
  /** column name */
  CaseOpenCountStart = 'case_open_count_start',
  /** column name */
  Id = 'id',
  /** column name */
  SecondsIntervalEnd = 'seconds_interval_end',
  /** column name */
  SecondsIntervalStart = 'seconds_interval_start',
  /** column name */
  TimeIntervalEnd = 'time_interval_end',
  /** column name */
  TimeIntervalStart = 'time_interval_start',
  /** column name */
  WorkersCount = 'workers_count'
}

/** input type for updating data in table "bot_account_settings" */
export type Bot_Account_Settings_Set_Input = {
  active_bots_count?: InputMaybe<Scalars['Int']>;
  case_open_count_end?: InputMaybe<Scalars['Int']>;
  case_open_count_start?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  seconds_interval_end?: InputMaybe<Scalars['Int']>;
  seconds_interval_start?: InputMaybe<Scalars['Int']>;
  time_interval_end?: InputMaybe<Scalars['Int']>;
  time_interval_start?: InputMaybe<Scalars['Int']>;
  workers_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bot_Account_Settings_Stddev_Fields = {
  __typename?: 'bot_account_settings_stddev_fields';
  active_bots_count?: Maybe<Scalars['Float']>;
  case_open_count_end?: Maybe<Scalars['Float']>;
  case_open_count_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seconds_interval_end?: Maybe<Scalars['Float']>;
  seconds_interval_start?: Maybe<Scalars['Float']>;
  time_interval_end?: Maybe<Scalars['Float']>;
  time_interval_start?: Maybe<Scalars['Float']>;
  workers_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bot_Account_Settings_Stddev_Pop_Fields = {
  __typename?: 'bot_account_settings_stddev_pop_fields';
  active_bots_count?: Maybe<Scalars['Float']>;
  case_open_count_end?: Maybe<Scalars['Float']>;
  case_open_count_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seconds_interval_end?: Maybe<Scalars['Float']>;
  seconds_interval_start?: Maybe<Scalars['Float']>;
  time_interval_end?: Maybe<Scalars['Float']>;
  time_interval_start?: Maybe<Scalars['Float']>;
  workers_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bot_Account_Settings_Stddev_Samp_Fields = {
  __typename?: 'bot_account_settings_stddev_samp_fields';
  active_bots_count?: Maybe<Scalars['Float']>;
  case_open_count_end?: Maybe<Scalars['Float']>;
  case_open_count_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seconds_interval_end?: Maybe<Scalars['Float']>;
  seconds_interval_start?: Maybe<Scalars['Float']>;
  time_interval_end?: Maybe<Scalars['Float']>;
  time_interval_start?: Maybe<Scalars['Float']>;
  workers_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bot_account_settings" */
export type Bot_Account_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bot_Account_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bot_Account_Settings_Stream_Cursor_Value_Input = {
  active_bots_count?: InputMaybe<Scalars['Int']>;
  case_open_count_end?: InputMaybe<Scalars['Int']>;
  case_open_count_start?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  seconds_interval_end?: InputMaybe<Scalars['Int']>;
  seconds_interval_start?: InputMaybe<Scalars['Int']>;
  time_interval_end?: InputMaybe<Scalars['Int']>;
  time_interval_start?: InputMaybe<Scalars['Int']>;
  workers_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Bot_Account_Settings_Sum_Fields = {
  __typename?: 'bot_account_settings_sum_fields';
  active_bots_count?: Maybe<Scalars['Int']>;
  case_open_count_end?: Maybe<Scalars['Int']>;
  case_open_count_start?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  seconds_interval_end?: Maybe<Scalars['Int']>;
  seconds_interval_start?: Maybe<Scalars['Int']>;
  time_interval_end?: Maybe<Scalars['Int']>;
  time_interval_start?: Maybe<Scalars['Int']>;
  workers_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "bot_account_settings" */
export enum Bot_Account_Settings_Update_Column {
  /** column name */
  ActiveBotsCount = 'active_bots_count',
  /** column name */
  CaseOpenCountEnd = 'case_open_count_end',
  /** column name */
  CaseOpenCountStart = 'case_open_count_start',
  /** column name */
  Id = 'id',
  /** column name */
  SecondsIntervalEnd = 'seconds_interval_end',
  /** column name */
  SecondsIntervalStart = 'seconds_interval_start',
  /** column name */
  TimeIntervalEnd = 'time_interval_end',
  /** column name */
  TimeIntervalStart = 'time_interval_start',
  /** column name */
  WorkersCount = 'workers_count'
}

export type Bot_Account_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bot_Account_Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bot_Account_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bot_Account_Settings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bot_Account_Settings_Var_Pop_Fields = {
  __typename?: 'bot_account_settings_var_pop_fields';
  active_bots_count?: Maybe<Scalars['Float']>;
  case_open_count_end?: Maybe<Scalars['Float']>;
  case_open_count_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seconds_interval_end?: Maybe<Scalars['Float']>;
  seconds_interval_start?: Maybe<Scalars['Float']>;
  time_interval_end?: Maybe<Scalars['Float']>;
  time_interval_start?: Maybe<Scalars['Float']>;
  workers_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bot_Account_Settings_Var_Samp_Fields = {
  __typename?: 'bot_account_settings_var_samp_fields';
  active_bots_count?: Maybe<Scalars['Float']>;
  case_open_count_end?: Maybe<Scalars['Float']>;
  case_open_count_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seconds_interval_end?: Maybe<Scalars['Float']>;
  seconds_interval_start?: Maybe<Scalars['Float']>;
  time_interval_end?: Maybe<Scalars['Float']>;
  time_interval_start?: Maybe<Scalars['Float']>;
  workers_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bot_Account_Settings_Variance_Fields = {
  __typename?: 'bot_account_settings_variance_fields';
  active_bots_count?: Maybe<Scalars['Float']>;
  case_open_count_end?: Maybe<Scalars['Float']>;
  case_open_count_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seconds_interval_end?: Maybe<Scalars['Float']>;
  seconds_interval_start?: Maybe<Scalars['Float']>;
  time_interval_end?: Maybe<Scalars['Float']>;
  time_interval_start?: Maybe<Scalars['Float']>;
  workers_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "bots_lootboxes_opens_count" */
export type Bots_Lootboxes_Opens_Count = {
  __typename?: 'bots_lootboxes_opens_count';
  bot_id: Scalars['uuid'];
  lootbox_slug: Scalars['String'];
  opens_count: Scalars['Int'];
};

/** aggregated selection of "bots_lootboxes_opens_count" */
export type Bots_Lootboxes_Opens_Count_Aggregate = {
  __typename?: 'bots_lootboxes_opens_count_aggregate';
  aggregate?: Maybe<Bots_Lootboxes_Opens_Count_Aggregate_Fields>;
  nodes: Array<Bots_Lootboxes_Opens_Count>;
};

/** aggregate fields of "bots_lootboxes_opens_count" */
export type Bots_Lootboxes_Opens_Count_Aggregate_Fields = {
  __typename?: 'bots_lootboxes_opens_count_aggregate_fields';
  avg?: Maybe<Bots_Lootboxes_Opens_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bots_Lootboxes_Opens_Count_Max_Fields>;
  min?: Maybe<Bots_Lootboxes_Opens_Count_Min_Fields>;
  stddev?: Maybe<Bots_Lootboxes_Opens_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Bots_Lootboxes_Opens_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bots_Lootboxes_Opens_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Bots_Lootboxes_Opens_Count_Sum_Fields>;
  var_pop?: Maybe<Bots_Lootboxes_Opens_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Bots_Lootboxes_Opens_Count_Var_Samp_Fields>;
  variance?: Maybe<Bots_Lootboxes_Opens_Count_Variance_Fields>;
};


/** aggregate fields of "bots_lootboxes_opens_count" */
export type Bots_Lootboxes_Opens_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bots_Lootboxes_Opens_Count_Avg_Fields = {
  __typename?: 'bots_lootboxes_opens_count_avg_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bots_lootboxes_opens_count". All fields are combined with a logical 'AND'. */
export type Bots_Lootboxes_Opens_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Bool_Exp>>;
  _not?: InputMaybe<Bots_Lootboxes_Opens_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Bool_Exp>>;
  bot_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_slug?: InputMaybe<String_Comparison_Exp>;
  opens_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "bots_lootboxes_opens_count" */
export enum Bots_Lootboxes_Opens_Count_Constraint {
  /** unique or primary key constraint on columns "bot_id", "lootbox_slug" */
  BotsLootboxesOpensCountPkey = 'bots_lootboxes_opens_count_pkey'
}

/** input type for incrementing numeric columns in table "bots_lootboxes_opens_count" */
export type Bots_Lootboxes_Opens_Count_Inc_Input = {
  opens_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bots_lootboxes_opens_count" */
export type Bots_Lootboxes_Opens_Count_Insert_Input = {
  bot_id?: InputMaybe<Scalars['uuid']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  opens_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Bots_Lootboxes_Opens_Count_Max_Fields = {
  __typename?: 'bots_lootboxes_opens_count_max_fields';
  bot_id?: Maybe<Scalars['uuid']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  opens_count?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Bots_Lootboxes_Opens_Count_Min_Fields = {
  __typename?: 'bots_lootboxes_opens_count_min_fields';
  bot_id?: Maybe<Scalars['uuid']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  opens_count?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "bots_lootboxes_opens_count" */
export type Bots_Lootboxes_Opens_Count_Mutation_Response = {
  __typename?: 'bots_lootboxes_opens_count_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bots_Lootboxes_Opens_Count>;
};

/** on_conflict condition type for table "bots_lootboxes_opens_count" */
export type Bots_Lootboxes_Opens_Count_On_Conflict = {
  constraint: Bots_Lootboxes_Opens_Count_Constraint;
  update_columns?: Array<Bots_Lootboxes_Opens_Count_Update_Column>;
  where?: InputMaybe<Bots_Lootboxes_Opens_Count_Bool_Exp>;
};

/** Ordering options when selecting data from "bots_lootboxes_opens_count". */
export type Bots_Lootboxes_Opens_Count_Order_By = {
  bot_id?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  opens_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bots_lootboxes_opens_count */
export type Bots_Lootboxes_Opens_Count_Pk_Columns_Input = {
  bot_id: Scalars['uuid'];
  lootbox_slug: Scalars['String'];
};

/** select columns of table "bots_lootboxes_opens_count" */
export enum Bots_Lootboxes_Opens_Count_Select_Column {
  /** column name */
  BotId = 'bot_id',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  OpensCount = 'opens_count'
}

/** input type for updating data in table "bots_lootboxes_opens_count" */
export type Bots_Lootboxes_Opens_Count_Set_Input = {
  bot_id?: InputMaybe<Scalars['uuid']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  opens_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bots_Lootboxes_Opens_Count_Stddev_Fields = {
  __typename?: 'bots_lootboxes_opens_count_stddev_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bots_Lootboxes_Opens_Count_Stddev_Pop_Fields = {
  __typename?: 'bots_lootboxes_opens_count_stddev_pop_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bots_Lootboxes_Opens_Count_Stddev_Samp_Fields = {
  __typename?: 'bots_lootboxes_opens_count_stddev_samp_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bots_lootboxes_opens_count" */
export type Bots_Lootboxes_Opens_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bots_Lootboxes_Opens_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bots_Lootboxes_Opens_Count_Stream_Cursor_Value_Input = {
  bot_id?: InputMaybe<Scalars['uuid']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  opens_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Bots_Lootboxes_Opens_Count_Sum_Fields = {
  __typename?: 'bots_lootboxes_opens_count_sum_fields';
  opens_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "bots_lootboxes_opens_count" */
export enum Bots_Lootboxes_Opens_Count_Update_Column {
  /** column name */
  BotId = 'bot_id',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  OpensCount = 'opens_count'
}

export type Bots_Lootboxes_Opens_Count_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bots_Lootboxes_Opens_Count_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bots_Lootboxes_Opens_Count_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bots_Lootboxes_Opens_Count_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bots_Lootboxes_Opens_Count_Var_Pop_Fields = {
  __typename?: 'bots_lootboxes_opens_count_var_pop_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bots_Lootboxes_Opens_Count_Var_Samp_Fields = {
  __typename?: 'bots_lootboxes_opens_count_var_samp_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bots_Lootboxes_Opens_Count_Variance_Fields = {
  __typename?: 'bots_lootboxes_opens_count_variance_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

/** Содержимое наборов билетов для продажи */
export type Coins_Set_Content = {
  __typename?: 'coins_set_content';
  content_id: Scalars['bigint'];
  /** Описание предмета */
  description: Scalars['String'];
  /** Флаг, определяет является ли набор подарком */
  is_bonus: Scalars['Boolean'];
  /** Количество билетов в наборе */
  quantity: Scalars['Int'];
  /** Идентификатор набора */
  set_id: Scalars['bigint'];
};

/** aggregated selection of "coins_set_content" */
export type Coins_Set_Content_Aggregate = {
  __typename?: 'coins_set_content_aggregate';
  aggregate?: Maybe<Coins_Set_Content_Aggregate_Fields>;
  nodes: Array<Coins_Set_Content>;
};

export type Coins_Set_Content_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Coins_Set_Content_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Coins_Set_Content_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Coins_Set_Content_Aggregate_Bool_Exp_Count>;
};

export type Coins_Set_Content_Aggregate_Bool_Exp_Bool_And = {
  arguments: Coins_Set_Content_Select_Column_Coins_Set_Content_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Coins_Set_Content_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Coins_Set_Content_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Coins_Set_Content_Select_Column_Coins_Set_Content_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Coins_Set_Content_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Coins_Set_Content_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Coins_Set_Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Coins_Set_Content_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "coins_set_content" */
export type Coins_Set_Content_Aggregate_Fields = {
  __typename?: 'coins_set_content_aggregate_fields';
  avg?: Maybe<Coins_Set_Content_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Coins_Set_Content_Max_Fields>;
  min?: Maybe<Coins_Set_Content_Min_Fields>;
  stddev?: Maybe<Coins_Set_Content_Stddev_Fields>;
  stddev_pop?: Maybe<Coins_Set_Content_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Coins_Set_Content_Stddev_Samp_Fields>;
  sum?: Maybe<Coins_Set_Content_Sum_Fields>;
  var_pop?: Maybe<Coins_Set_Content_Var_Pop_Fields>;
  var_samp?: Maybe<Coins_Set_Content_Var_Samp_Fields>;
  variance?: Maybe<Coins_Set_Content_Variance_Fields>;
};


/** aggregate fields of "coins_set_content" */
export type Coins_Set_Content_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Coins_Set_Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coins_set_content" */
export type Coins_Set_Content_Aggregate_Order_By = {
  avg?: InputMaybe<Coins_Set_Content_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Coins_Set_Content_Max_Order_By>;
  min?: InputMaybe<Coins_Set_Content_Min_Order_By>;
  stddev?: InputMaybe<Coins_Set_Content_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Coins_Set_Content_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Coins_Set_Content_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Coins_Set_Content_Sum_Order_By>;
  var_pop?: InputMaybe<Coins_Set_Content_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Coins_Set_Content_Var_Samp_Order_By>;
  variance?: InputMaybe<Coins_Set_Content_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "coins_set_content" */
export type Coins_Set_Content_Arr_Rel_Insert_Input = {
  data: Array<Coins_Set_Content_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Coins_Set_Content_On_Conflict>;
};

/** aggregate avg on columns */
export type Coins_Set_Content_Avg_Fields = {
  __typename?: 'coins_set_content_avg_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coins_set_content" */
export type Coins_Set_Content_Avg_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "coins_set_content". All fields are combined with a logical 'AND'. */
export type Coins_Set_Content_Bool_Exp = {
  _and?: InputMaybe<Array<Coins_Set_Content_Bool_Exp>>;
  _not?: InputMaybe<Coins_Set_Content_Bool_Exp>;
  _or?: InputMaybe<Array<Coins_Set_Content_Bool_Exp>>;
  content_id?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  is_bonus?: InputMaybe<Boolean_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  set_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "coins_set_content" */
export enum Coins_Set_Content_Constraint {
  /** unique or primary key constraint on columns "content_id" */
  CoinsSetContentPkey = 'coins_set_content_pkey'
}

/** input type for incrementing numeric columns in table "coins_set_content" */
export type Coins_Set_Content_Inc_Input = {
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coins_set_content" */
export type Coins_Set_Content_Insert_Input = {
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: InputMaybe<Scalars['String']>;
  /** Флаг, определяет является ли набор подарком */
  is_bonus?: InputMaybe<Scalars['Boolean']>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Coins_Set_Content_Max_Fields = {
  __typename?: 'coins_set_content_max_fields';
  content_id?: Maybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: Maybe<Scalars['String']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "coins_set_content" */
export type Coins_Set_Content_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Описание предмета */
  description?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Coins_Set_Content_Min_Fields = {
  __typename?: 'coins_set_content_min_fields';
  content_id?: Maybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: Maybe<Scalars['String']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "coins_set_content" */
export type Coins_Set_Content_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Описание предмета */
  description?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "coins_set_content" */
export type Coins_Set_Content_Mutation_Response = {
  __typename?: 'coins_set_content_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coins_Set_Content>;
};

/** on_conflict condition type for table "coins_set_content" */
export type Coins_Set_Content_On_Conflict = {
  constraint: Coins_Set_Content_Constraint;
  update_columns?: Array<Coins_Set_Content_Update_Column>;
  where?: InputMaybe<Coins_Set_Content_Bool_Exp>;
};

/** Ordering options when selecting data from "coins_set_content". */
export type Coins_Set_Content_Order_By = {
  content_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  is_bonus?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: coins_set_content */
export type Coins_Set_Content_Pk_Columns_Input = {
  content_id: Scalars['bigint'];
};

/** select columns of table "coins_set_content" */
export enum Coins_Set_Content_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Description = 'description',
  /** column name */
  IsBonus = 'is_bonus',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SetId = 'set_id'
}

/** select "coins_set_content_aggregate_bool_exp_bool_and_arguments_columns" columns of table "coins_set_content" */
export enum Coins_Set_Content_Select_Column_Coins_Set_Content_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsBonus = 'is_bonus'
}

/** select "coins_set_content_aggregate_bool_exp_bool_or_arguments_columns" columns of table "coins_set_content" */
export enum Coins_Set_Content_Select_Column_Coins_Set_Content_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsBonus = 'is_bonus'
}

/** input type for updating data in table "coins_set_content" */
export type Coins_Set_Content_Set_Input = {
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: InputMaybe<Scalars['String']>;
  /** Флаг, определяет является ли набор подарком */
  is_bonus?: InputMaybe<Scalars['Boolean']>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Coins_Set_Content_Stddev_Fields = {
  __typename?: 'coins_set_content_stddev_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coins_set_content" */
export type Coins_Set_Content_Stddev_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Coins_Set_Content_Stddev_Pop_Fields = {
  __typename?: 'coins_set_content_stddev_pop_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coins_set_content" */
export type Coins_Set_Content_Stddev_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Coins_Set_Content_Stddev_Samp_Fields = {
  __typename?: 'coins_set_content_stddev_samp_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coins_set_content" */
export type Coins_Set_Content_Stddev_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "coins_set_content" */
export type Coins_Set_Content_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Coins_Set_Content_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Coins_Set_Content_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: InputMaybe<Scalars['String']>;
  /** Флаг, определяет является ли набор подарком */
  is_bonus?: InputMaybe<Scalars['Boolean']>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Coins_Set_Content_Sum_Fields = {
  __typename?: 'coins_set_content_sum_fields';
  content_id?: Maybe<Scalars['bigint']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "coins_set_content" */
export type Coins_Set_Content_Sum_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** update columns of table "coins_set_content" */
export enum Coins_Set_Content_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Description = 'description',
  /** column name */
  IsBonus = 'is_bonus',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SetId = 'set_id'
}

export type Coins_Set_Content_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Coins_Set_Content_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Coins_Set_Content_Set_Input>;
  /** filter the rows which have to be updated */
  where: Coins_Set_Content_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Coins_Set_Content_Var_Pop_Fields = {
  __typename?: 'coins_set_content_var_pop_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coins_set_content" */
export type Coins_Set_Content_Var_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Coins_Set_Content_Var_Samp_Fields = {
  __typename?: 'coins_set_content_var_samp_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coins_set_content" */
export type Coins_Set_Content_Var_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Coins_Set_Content_Variance_Fields = {
  __typename?: 'coins_set_content_variance_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coins_set_content" */
export type Coins_Set_Content_Variance_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** Наборы монет для продажи */
export type Coins_Sets = {
  __typename?: 'coins_sets';
  /** Описание набора */
  description: Scalars['String'];
  set_id: Scalars['bigint'];
};

/** aggregated selection of "coins_sets" */
export type Coins_Sets_Aggregate = {
  __typename?: 'coins_sets_aggregate';
  aggregate?: Maybe<Coins_Sets_Aggregate_Fields>;
  nodes: Array<Coins_Sets>;
};

/** aggregate fields of "coins_sets" */
export type Coins_Sets_Aggregate_Fields = {
  __typename?: 'coins_sets_aggregate_fields';
  avg?: Maybe<Coins_Sets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Coins_Sets_Max_Fields>;
  min?: Maybe<Coins_Sets_Min_Fields>;
  stddev?: Maybe<Coins_Sets_Stddev_Fields>;
  stddev_pop?: Maybe<Coins_Sets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Coins_Sets_Stddev_Samp_Fields>;
  sum?: Maybe<Coins_Sets_Sum_Fields>;
  var_pop?: Maybe<Coins_Sets_Var_Pop_Fields>;
  var_samp?: Maybe<Coins_Sets_Var_Samp_Fields>;
  variance?: Maybe<Coins_Sets_Variance_Fields>;
};


/** aggregate fields of "coins_sets" */
export type Coins_Sets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Coins_Sets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Coins_Sets_Avg_Fields = {
  __typename?: 'coins_sets_avg_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "coins_sets". All fields are combined with a logical 'AND'. */
export type Coins_Sets_Bool_Exp = {
  _and?: InputMaybe<Array<Coins_Sets_Bool_Exp>>;
  _not?: InputMaybe<Coins_Sets_Bool_Exp>;
  _or?: InputMaybe<Array<Coins_Sets_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  set_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "coins_sets" */
export enum Coins_Sets_Constraint {
  /** unique or primary key constraint on columns "set_id" */
  CoinsSetsPkey = 'coins_sets_pkey'
}

/** input type for incrementing numeric columns in table "coins_sets" */
export type Coins_Sets_Inc_Input = {
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "coins_sets" */
export type Coins_Sets_Insert_Input = {
  /** Описание набора */
  description?: InputMaybe<Scalars['String']>;
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Coins_Sets_Max_Fields = {
  __typename?: 'coins_sets_max_fields';
  /** Описание набора */
  description?: Maybe<Scalars['String']>;
  set_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Coins_Sets_Min_Fields = {
  __typename?: 'coins_sets_min_fields';
  /** Описание набора */
  description?: Maybe<Scalars['String']>;
  set_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "coins_sets" */
export type Coins_Sets_Mutation_Response = {
  __typename?: 'coins_sets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coins_Sets>;
};

/** on_conflict condition type for table "coins_sets" */
export type Coins_Sets_On_Conflict = {
  constraint: Coins_Sets_Constraint;
  update_columns?: Array<Coins_Sets_Update_Column>;
  where?: InputMaybe<Coins_Sets_Bool_Exp>;
};

/** Ordering options when selecting data from "coins_sets". */
export type Coins_Sets_Order_By = {
  description?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: coins_sets */
export type Coins_Sets_Pk_Columns_Input = {
  set_id: Scalars['bigint'];
};

/** select columns of table "coins_sets" */
export enum Coins_Sets_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  SetId = 'set_id'
}

/** input type for updating data in table "coins_sets" */
export type Coins_Sets_Set_Input = {
  /** Описание набора */
  description?: InputMaybe<Scalars['String']>;
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Coins_Sets_Stddev_Fields = {
  __typename?: 'coins_sets_stddev_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Coins_Sets_Stddev_Pop_Fields = {
  __typename?: 'coins_sets_stddev_pop_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Coins_Sets_Stddev_Samp_Fields = {
  __typename?: 'coins_sets_stddev_samp_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "coins_sets" */
export type Coins_Sets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Coins_Sets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Coins_Sets_Stream_Cursor_Value_Input = {
  /** Описание набора */
  description?: InputMaybe<Scalars['String']>;
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Coins_Sets_Sum_Fields = {
  __typename?: 'coins_sets_sum_fields';
  set_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "coins_sets" */
export enum Coins_Sets_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  SetId = 'set_id'
}

export type Coins_Sets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Coins_Sets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Coins_Sets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Coins_Sets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Coins_Sets_Var_Pop_Fields = {
  __typename?: 'coins_sets_var_pop_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Coins_Sets_Var_Samp_Fields = {
  __typename?: 'coins_sets_var_samp_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Coins_Sets_Variance_Fields = {
  __typename?: 'coins_sets_variance_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "company" */
export type Company = {
  __typename?: 'company';
  id: Scalars['Int'];
  name: Scalars['String'];
  sector: Scalars['String'];
};

/** aggregated selection of "company" */
export type Company_Aggregate = {
  __typename?: 'company_aggregate';
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_Fields = {
  __typename?: 'company_aggregate_fields';
  avg?: Maybe<Company_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
  stddev?: Maybe<Company_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Sum_Fields>;
  var_pop?: Maybe<Company_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Var_Samp_Fields>;
  variance?: Maybe<Company_Variance_Fields>;
};


/** aggregate fields of "company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Company_Avg_Fields = {
  __typename?: 'company_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Bool_Exp>>;
  _not?: InputMaybe<Company_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sector?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "company" */
export enum Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompanyPkey = 'company_pkey'
}

/** input type for incrementing numeric columns in table "company" */
export type Company_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company" */
export type Company_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  sector?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
  __typename?: 'company_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
  __typename?: 'company_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "company" */
export type Company_Mutation_Response = {
  __typename?: 'company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company>;
};

/** on_conflict condition type for table "company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns?: Array<Company_Update_Column>;
  where?: InputMaybe<Company_Bool_Exp>;
};

/** Ordering options when selecting data from "company". */
export type Company_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sector?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company */
export type Company_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company" */
export enum Company_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sector = 'sector'
}

/** input type for updating data in table "company" */
export type Company_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  sector?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Company_Stddev_Fields = {
  __typename?: 'company_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Stddev_Pop_Fields = {
  __typename?: 'company_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Stddev_Samp_Fields = {
  __typename?: 'company_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company" */
export type Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  sector?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Company_Sum_Fields = {
  __typename?: 'company_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "company" */
export enum Company_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sector = 'sector'
}

export type Company_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Var_Pop_Fields = {
  __typename?: 'company_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Var_Samp_Fields = {
  __typename?: 'company_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Variance_Fields = {
  __typename?: 'company_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "coupons" */
export type Coupons = {
  __typename?: 'coupons';
  amount: Scalars['Int'];
  code: Scalars['String'];
  description: Scalars['String'];
  end_date: Scalars['timestamptz'];
  id: Scalars['Int'];
  start_date: Scalars['timestamptz'];
};

/** aggregated selection of "coupons" */
export type Coupons_Aggregate = {
  __typename?: 'coupons_aggregate';
  aggregate?: Maybe<Coupons_Aggregate_Fields>;
  nodes: Array<Coupons>;
};

/** aggregate fields of "coupons" */
export type Coupons_Aggregate_Fields = {
  __typename?: 'coupons_aggregate_fields';
  avg?: Maybe<Coupons_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Coupons_Max_Fields>;
  min?: Maybe<Coupons_Min_Fields>;
  stddev?: Maybe<Coupons_Stddev_Fields>;
  stddev_pop?: Maybe<Coupons_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Coupons_Stddev_Samp_Fields>;
  sum?: Maybe<Coupons_Sum_Fields>;
  var_pop?: Maybe<Coupons_Var_Pop_Fields>;
  var_samp?: Maybe<Coupons_Var_Samp_Fields>;
  variance?: Maybe<Coupons_Variance_Fields>;
};


/** aggregate fields of "coupons" */
export type Coupons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Coupons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Coupons_Avg_Fields = {
  __typename?: 'coupons_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "coupons". All fields are combined with a logical 'AND'. */
export type Coupons_Bool_Exp = {
  _and?: InputMaybe<Array<Coupons_Bool_Exp>>;
  _not?: InputMaybe<Coupons_Bool_Exp>;
  _or?: InputMaybe<Array<Coupons_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "coupons" */
export enum Coupons_Constraint {
  /** unique or primary key constraint on columns "id" */
  CouponsPkey = 'coupons_pkey'
}

/** input type for incrementing numeric columns in table "coupons" */
export type Coupons_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coupons" */
export type Coupons_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Coupons_Max_Fields = {
  __typename?: 'coupons_max_fields';
  amount?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Coupons_Min_Fields = {
  __typename?: 'coupons_min_fields';
  amount?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "coupons" */
export type Coupons_Mutation_Response = {
  __typename?: 'coupons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coupons>;
};

/** on_conflict condition type for table "coupons" */
export type Coupons_On_Conflict = {
  constraint: Coupons_Constraint;
  update_columns?: Array<Coupons_Update_Column>;
  where?: InputMaybe<Coupons_Bool_Exp>;
};

/** Ordering options when selecting data from "coupons". */
export type Coupons_Order_By = {
  amount?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: coupons */
export type Coupons_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "coupons" */
export enum Coupons_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'start_date'
}

/** input type for updating data in table "coupons" */
export type Coupons_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Coupons_Stddev_Fields = {
  __typename?: 'coupons_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Coupons_Stddev_Pop_Fields = {
  __typename?: 'coupons_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Coupons_Stddev_Samp_Fields = {
  __typename?: 'coupons_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "coupons" */
export type Coupons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Coupons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Coupons_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Coupons_Sum_Fields = {
  __typename?: 'coupons_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "coupons" */
export enum Coupons_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'start_date'
}

export type Coupons_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Coupons_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Coupons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Coupons_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Coupons_Var_Pop_Fields = {
  __typename?: 'coupons_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Coupons_Var_Samp_Fields = {
  __typename?: 'coupons_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Coupons_Variance_Fields = {
  __typename?: 'coupons_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "csgomarket_token_balance" */
export type Csgomarket_Token_Balance = {
  __typename?: 'csgomarket_token_balance';
  balance?: Maybe<Scalars['float8']>;
  currency?: Maybe<Scalars['String']>;
  datetime: Scalars['timestamp'];
  token_id: Scalars['uuid'];
};

/** aggregated selection of "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Aggregate = {
  __typename?: 'csgomarket_token_balance_aggregate';
  aggregate?: Maybe<Csgomarket_Token_Balance_Aggregate_Fields>;
  nodes: Array<Csgomarket_Token_Balance>;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Csgomarket_Token_Balance_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Csgomarket_Token_Balance_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Csgomarket_Token_Balance_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Csgomarket_Token_Balance_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Csgomarket_Token_Balance_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Csgomarket_Token_Balance_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Csgomarket_Token_Balance_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Csgomarket_Token_Balance_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Csgomarket_Token_Balance_Aggregate_Bool_Exp_Var_Samp>;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp_Avg = {
  arguments: Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp_Corr = {
  arguments: Csgomarket_Token_Balance_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Csgomarket_Token_Balance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Csgomarket_Token_Balance_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp_Max = {
  arguments: Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp_Min = {
  arguments: Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp_Sum = {
  arguments: Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Csgomarket_Token_Balance_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Aggregate_Fields = {
  __typename?: 'csgomarket_token_balance_aggregate_fields';
  avg?: Maybe<Csgomarket_Token_Balance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Csgomarket_Token_Balance_Max_Fields>;
  min?: Maybe<Csgomarket_Token_Balance_Min_Fields>;
  stddev?: Maybe<Csgomarket_Token_Balance_Stddev_Fields>;
  stddev_pop?: Maybe<Csgomarket_Token_Balance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Csgomarket_Token_Balance_Stddev_Samp_Fields>;
  sum?: Maybe<Csgomarket_Token_Balance_Sum_Fields>;
  var_pop?: Maybe<Csgomarket_Token_Balance_Var_Pop_Fields>;
  var_samp?: Maybe<Csgomarket_Token_Balance_Var_Samp_Fields>;
  variance?: Maybe<Csgomarket_Token_Balance_Variance_Fields>;
};


/** aggregate fields of "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Csgomarket_Token_Balance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Aggregate_Order_By = {
  avg?: InputMaybe<Csgomarket_Token_Balance_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Csgomarket_Token_Balance_Max_Order_By>;
  min?: InputMaybe<Csgomarket_Token_Balance_Min_Order_By>;
  stddev?: InputMaybe<Csgomarket_Token_Balance_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Csgomarket_Token_Balance_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Csgomarket_Token_Balance_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Csgomarket_Token_Balance_Sum_Order_By>;
  var_pop?: InputMaybe<Csgomarket_Token_Balance_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Csgomarket_Token_Balance_Var_Samp_Order_By>;
  variance?: InputMaybe<Csgomarket_Token_Balance_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Arr_Rel_Insert_Input = {
  data: Array<Csgomarket_Token_Balance_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Csgomarket_Token_Balance_On_Conflict>;
};

/** aggregate avg on columns */
export type Csgomarket_Token_Balance_Avg_Fields = {
  __typename?: 'csgomarket_token_balance_avg_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Avg_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "csgomarket_token_balance". All fields are combined with a logical 'AND'. */
export type Csgomarket_Token_Balance_Bool_Exp = {
  _and?: InputMaybe<Array<Csgomarket_Token_Balance_Bool_Exp>>;
  _not?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
  _or?: InputMaybe<Array<Csgomarket_Token_Balance_Bool_Exp>>;
  balance?: InputMaybe<Float8_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  token_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "csgomarket_token_balance" */
export enum Csgomarket_Token_Balance_Constraint {
  /** unique or primary key constraint on columns "datetime", "token_id" */
  CsgomarketTokenBalancePkey = 'csgomarket_token_balance_pkey'
}

/** input type for incrementing numeric columns in table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Inc_Input = {
  balance?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Insert_Input = {
  balance?: InputMaybe<Scalars['float8']>;
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  token_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Csgomarket_Token_Balance_Max_Fields = {
  __typename?: 'csgomarket_token_balance_max_fields';
  balance?: Maybe<Scalars['float8']>;
  currency?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  token_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Max_Order_By = {
  balance?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Csgomarket_Token_Balance_Min_Fields = {
  __typename?: 'csgomarket_token_balance_min_fields';
  balance?: Maybe<Scalars['float8']>;
  currency?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  token_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Min_Order_By = {
  balance?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Mutation_Response = {
  __typename?: 'csgomarket_token_balance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Csgomarket_Token_Balance>;
};

/** on_conflict condition type for table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_On_Conflict = {
  constraint: Csgomarket_Token_Balance_Constraint;
  update_columns?: Array<Csgomarket_Token_Balance_Update_Column>;
  where?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
};

/** Ordering options when selecting data from "csgomarket_token_balance". */
export type Csgomarket_Token_Balance_Order_By = {
  balance?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: csgomarket_token_balance */
export type Csgomarket_Token_Balance_Pk_Columns_Input = {
  datetime: Scalars['timestamp'];
  token_id: Scalars['uuid'];
};

/** select columns of table "csgomarket_token_balance" */
export enum Csgomarket_Token_Balance_Select_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  Currency = 'currency',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  TokenId = 'token_id'
}

/** select "csgomarket_token_balance_aggregate_bool_exp_avg_arguments_columns" columns of table "csgomarket_token_balance" */
export enum Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Balance = 'balance'
}

/** select "csgomarket_token_balance_aggregate_bool_exp_corr_arguments_columns" columns of table "csgomarket_token_balance" */
export enum Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Balance = 'balance'
}

/** select "csgomarket_token_balance_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "csgomarket_token_balance" */
export enum Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Balance = 'balance'
}

/** select "csgomarket_token_balance_aggregate_bool_exp_max_arguments_columns" columns of table "csgomarket_token_balance" */
export enum Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Balance = 'balance'
}

/** select "csgomarket_token_balance_aggregate_bool_exp_min_arguments_columns" columns of table "csgomarket_token_balance" */
export enum Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Balance = 'balance'
}

/** select "csgomarket_token_balance_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "csgomarket_token_balance" */
export enum Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Balance = 'balance'
}

/** select "csgomarket_token_balance_aggregate_bool_exp_sum_arguments_columns" columns of table "csgomarket_token_balance" */
export enum Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Balance = 'balance'
}

/** select "csgomarket_token_balance_aggregate_bool_exp_var_samp_arguments_columns" columns of table "csgomarket_token_balance" */
export enum Csgomarket_Token_Balance_Select_Column_Csgomarket_Token_Balance_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Balance = 'balance'
}

/** input type for updating data in table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Set_Input = {
  balance?: InputMaybe<Scalars['float8']>;
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  token_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Csgomarket_Token_Balance_Stddev_Fields = {
  __typename?: 'csgomarket_token_balance_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Stddev_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Csgomarket_Token_Balance_Stddev_Pop_Fields = {
  __typename?: 'csgomarket_token_balance_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Stddev_Pop_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Csgomarket_Token_Balance_Stddev_Samp_Fields = {
  __typename?: 'csgomarket_token_balance_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Stddev_Samp_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Csgomarket_Token_Balance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Csgomarket_Token_Balance_Stream_Cursor_Value_Input = {
  balance?: InputMaybe<Scalars['float8']>;
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  token_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Csgomarket_Token_Balance_Sum_Fields = {
  __typename?: 'csgomarket_token_balance_sum_fields';
  balance?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Sum_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** update columns of table "csgomarket_token_balance" */
export enum Csgomarket_Token_Balance_Update_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  Currency = 'currency',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  TokenId = 'token_id'
}

export type Csgomarket_Token_Balance_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Csgomarket_Token_Balance_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Csgomarket_Token_Balance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Csgomarket_Token_Balance_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Csgomarket_Token_Balance_Var_Pop_Fields = {
  __typename?: 'csgomarket_token_balance_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Var_Pop_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Csgomarket_Token_Balance_Var_Samp_Fields = {
  __typename?: 'csgomarket_token_balance_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Var_Samp_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Csgomarket_Token_Balance_Variance_Fields = {
  __typename?: 'csgomarket_token_balance_variance_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "csgomarket_token_balance" */
export type Csgomarket_Token_Balance_Variance_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** columns and relationships of "csgomarket_tokens" */
export type Csgomarket_Tokens = {
  __typename?: 'csgomarket_tokens';
  /** An array relationship */
  csgomarket_token_balance: Array<Csgomarket_Token_Balance>;
  /** An aggregate relationship */
  csgomarket_token_balance_aggregate: Csgomarket_Token_Balance_Aggregate;
  /** An array relationship */
  qservice_data: Array<Qservice_Data>;
  /** An aggregate relationship */
  qservice_data_aggregate: Qservice_Data_Aggregate;
  token_id: Scalars['uuid'];
  token_value: Scalars['String'];
};


/** columns and relationships of "csgomarket_tokens" */
export type Csgomarket_TokensCsgomarket_Token_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Csgomarket_Token_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Csgomarket_Token_Balance_Order_By>>;
  where?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
};


/** columns and relationships of "csgomarket_tokens" */
export type Csgomarket_TokensCsgomarket_Token_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Csgomarket_Token_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Csgomarket_Token_Balance_Order_By>>;
  where?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
};


/** columns and relationships of "csgomarket_tokens" */
export type Csgomarket_TokensQservice_DataArgs = {
  distinct_on?: InputMaybe<Array<Qservice_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Qservice_Data_Order_By>>;
  where?: InputMaybe<Qservice_Data_Bool_Exp>;
};


/** columns and relationships of "csgomarket_tokens" */
export type Csgomarket_TokensQservice_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Qservice_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Qservice_Data_Order_By>>;
  where?: InputMaybe<Qservice_Data_Bool_Exp>;
};

/** aggregated selection of "csgomarket_tokens" */
export type Csgomarket_Tokens_Aggregate = {
  __typename?: 'csgomarket_tokens_aggregate';
  aggregate?: Maybe<Csgomarket_Tokens_Aggregate_Fields>;
  nodes: Array<Csgomarket_Tokens>;
};

/** aggregate fields of "csgomarket_tokens" */
export type Csgomarket_Tokens_Aggregate_Fields = {
  __typename?: 'csgomarket_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Csgomarket_Tokens_Max_Fields>;
  min?: Maybe<Csgomarket_Tokens_Min_Fields>;
};


/** aggregate fields of "csgomarket_tokens" */
export type Csgomarket_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Csgomarket_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "csgomarket_tokens". All fields are combined with a logical 'AND'. */
export type Csgomarket_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Csgomarket_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Csgomarket_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Csgomarket_Tokens_Bool_Exp>>;
  csgomarket_token_balance?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
  csgomarket_token_balance_aggregate?: InputMaybe<Csgomarket_Token_Balance_Aggregate_Bool_Exp>;
  qservice_data?: InputMaybe<Qservice_Data_Bool_Exp>;
  qservice_data_aggregate?: InputMaybe<Qservice_Data_Aggregate_Bool_Exp>;
  token_id?: InputMaybe<Uuid_Comparison_Exp>;
  token_value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "csgomarket_tokens" */
export enum Csgomarket_Tokens_Constraint {
  /** unique or primary key constraint on columns "token_id" */
  CsgomarketTokensPkey = 'csgomarket_tokens_pkey'
}

/** input type for inserting data into table "csgomarket_tokens" */
export type Csgomarket_Tokens_Insert_Input = {
  csgomarket_token_balance?: InputMaybe<Csgomarket_Token_Balance_Arr_Rel_Insert_Input>;
  qservice_data?: InputMaybe<Qservice_Data_Arr_Rel_Insert_Input>;
  token_id?: InputMaybe<Scalars['uuid']>;
  token_value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Csgomarket_Tokens_Max_Fields = {
  __typename?: 'csgomarket_tokens_max_fields';
  token_id?: Maybe<Scalars['uuid']>;
  token_value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Csgomarket_Tokens_Min_Fields = {
  __typename?: 'csgomarket_tokens_min_fields';
  token_id?: Maybe<Scalars['uuid']>;
  token_value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "csgomarket_tokens" */
export type Csgomarket_Tokens_Mutation_Response = {
  __typename?: 'csgomarket_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Csgomarket_Tokens>;
};

/** input type for inserting object relation for remote table "csgomarket_tokens" */
export type Csgomarket_Tokens_Obj_Rel_Insert_Input = {
  data: Csgomarket_Tokens_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Csgomarket_Tokens_On_Conflict>;
};

/** on_conflict condition type for table "csgomarket_tokens" */
export type Csgomarket_Tokens_On_Conflict = {
  constraint: Csgomarket_Tokens_Constraint;
  update_columns?: Array<Csgomarket_Tokens_Update_Column>;
  where?: InputMaybe<Csgomarket_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "csgomarket_tokens". */
export type Csgomarket_Tokens_Order_By = {
  csgomarket_token_balance_aggregate?: InputMaybe<Csgomarket_Token_Balance_Aggregate_Order_By>;
  qservice_data_aggregate?: InputMaybe<Qservice_Data_Aggregate_Order_By>;
  token_id?: InputMaybe<Order_By>;
  token_value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: csgomarket_tokens */
export type Csgomarket_Tokens_Pk_Columns_Input = {
  token_id: Scalars['uuid'];
};

/** select columns of table "csgomarket_tokens" */
export enum Csgomarket_Tokens_Select_Column {
  /** column name */
  TokenId = 'token_id',
  /** column name */
  TokenValue = 'token_value'
}

/** input type for updating data in table "csgomarket_tokens" */
export type Csgomarket_Tokens_Set_Input = {
  token_id?: InputMaybe<Scalars['uuid']>;
  token_value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "csgomarket_tokens" */
export type Csgomarket_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Csgomarket_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Csgomarket_Tokens_Stream_Cursor_Value_Input = {
  token_id?: InputMaybe<Scalars['uuid']>;
  token_value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "csgomarket_tokens" */
export enum Csgomarket_Tokens_Update_Column {
  /** column name */
  TokenId = 'token_id',
  /** column name */
  TokenValue = 'token_value'
}

export type Csgomarket_Tokens_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Csgomarket_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Csgomarket_Tokens_Bool_Exp;
};

/** columns and relationships of "currency_history" */
export type Currency_History = {
  __typename?: 'currency_history';
  created_at: Scalars['timestamp'];
  currency: Scalars['String'];
  currency_id: Scalars['Int'];
  display_name?: Maybe<Scalars['String']>;
  rate: Scalars['Float'];
};

/** aggregated selection of "currency_history" */
export type Currency_History_Aggregate = {
  __typename?: 'currency_history_aggregate';
  aggregate?: Maybe<Currency_History_Aggregate_Fields>;
  nodes: Array<Currency_History>;
};

/** aggregate fields of "currency_history" */
export type Currency_History_Aggregate_Fields = {
  __typename?: 'currency_history_aggregate_fields';
  avg?: Maybe<Currency_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Currency_History_Max_Fields>;
  min?: Maybe<Currency_History_Min_Fields>;
  stddev?: Maybe<Currency_History_Stddev_Fields>;
  stddev_pop?: Maybe<Currency_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Currency_History_Stddev_Samp_Fields>;
  sum?: Maybe<Currency_History_Sum_Fields>;
  var_pop?: Maybe<Currency_History_Var_Pop_Fields>;
  var_samp?: Maybe<Currency_History_Var_Samp_Fields>;
  variance?: Maybe<Currency_History_Variance_Fields>;
};


/** aggregate fields of "currency_history" */
export type Currency_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currency_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Currency_History_Avg_Fields = {
  __typename?: 'currency_history_avg_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "currency_history". All fields are combined with a logical 'AND'. */
export type Currency_History_Bool_Exp = {
  _and?: InputMaybe<Array<Currency_History_Bool_Exp>>;
  _not?: InputMaybe<Currency_History_Bool_Exp>;
  _or?: InputMaybe<Array<Currency_History_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  currency_id?: InputMaybe<Int_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  rate?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "currency_history" */
export enum Currency_History_Constraint {
  /** unique or primary key constraint on columns "created_at", "currency_id" */
  CurrencyHistoryPkey = 'currency_history_pkey'
}

/** input type for incrementing numeric columns in table "currency_history" */
export type Currency_History_Inc_Input = {
  currency_id?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "currency_history" */
export type Currency_History_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  currency_id?: InputMaybe<Scalars['Int']>;
  display_name?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Currency_History_Max_Fields = {
  __typename?: 'currency_history_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['Int']>;
  display_name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate min on columns */
export type Currency_History_Min_Fields = {
  __typename?: 'currency_history_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['Int']>;
  display_name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

/** response of any mutation on the table "currency_history" */
export type Currency_History_Mutation_Response = {
  __typename?: 'currency_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Currency_History>;
};

/** on_conflict condition type for table "currency_history" */
export type Currency_History_On_Conflict = {
  constraint: Currency_History_Constraint;
  update_columns?: Array<Currency_History_Update_Column>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};

/** Ordering options when selecting data from "currency_history". */
export type Currency_History_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  currency_id?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: currency_history */
export type Currency_History_Pk_Columns_Input = {
  created_at: Scalars['timestamp'];
  currency_id: Scalars['Int'];
};

/** select columns of table "currency_history" */
export enum Currency_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Rate = 'rate'
}

/** input type for updating data in table "currency_history" */
export type Currency_History_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  currency_id?: InputMaybe<Scalars['Int']>;
  display_name?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Currency_History_Stddev_Fields = {
  __typename?: 'currency_history_stddev_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Currency_History_Stddev_Pop_Fields = {
  __typename?: 'currency_history_stddev_pop_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Currency_History_Stddev_Samp_Fields = {
  __typename?: 'currency_history_stddev_samp_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "currency_history" */
export type Currency_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currency_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Currency_History_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  currency_id?: InputMaybe<Scalars['Int']>;
  display_name?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Currency_History_Sum_Fields = {
  __typename?: 'currency_history_sum_fields';
  currency_id?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Float']>;
};

/** update columns of table "currency_history" */
export enum Currency_History_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Rate = 'rate'
}

export type Currency_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Currency_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Currency_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Currency_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Currency_History_Var_Pop_Fields = {
  __typename?: 'currency_history_var_pop_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Currency_History_Var_Samp_Fields = {
  __typename?: 'currency_history_var_samp_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Currency_History_Variance_Fields = {
  __typename?: 'currency_history_variance_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "currencys" */
export type Currencys = {
  __typename?: 'currencys';
  created_at: Scalars['timestamp'];
  currency: Scalars['String'];
  currency_id: Scalars['Int'];
  display_name?: Maybe<Scalars['String']>;
  rate: Scalars['Float'];
};

/** aggregated selection of "currencys" */
export type Currencys_Aggregate = {
  __typename?: 'currencys_aggregate';
  aggregate?: Maybe<Currencys_Aggregate_Fields>;
  nodes: Array<Currencys>;
};

/** aggregate fields of "currencys" */
export type Currencys_Aggregate_Fields = {
  __typename?: 'currencys_aggregate_fields';
  avg?: Maybe<Currencys_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Currencys_Max_Fields>;
  min?: Maybe<Currencys_Min_Fields>;
  stddev?: Maybe<Currencys_Stddev_Fields>;
  stddev_pop?: Maybe<Currencys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Currencys_Stddev_Samp_Fields>;
  sum?: Maybe<Currencys_Sum_Fields>;
  var_pop?: Maybe<Currencys_Var_Pop_Fields>;
  var_samp?: Maybe<Currencys_Var_Samp_Fields>;
  variance?: Maybe<Currencys_Variance_Fields>;
};


/** aggregate fields of "currencys" */
export type Currencys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currencys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Currencys_Avg_Fields = {
  __typename?: 'currencys_avg_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "currencys". All fields are combined with a logical 'AND'. */
export type Currencys_Bool_Exp = {
  _and?: InputMaybe<Array<Currencys_Bool_Exp>>;
  _not?: InputMaybe<Currencys_Bool_Exp>;
  _or?: InputMaybe<Array<Currencys_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  currency_id?: InputMaybe<Int_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  rate?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "currencys" */
export enum Currencys_Constraint {
  /** unique or primary key constraint on columns "currency" */
  CurrencysCurrencyKey = 'currencys_currency_key',
  /** unique or primary key constraint on columns "currency_id" */
  CurrencysPkey = 'currencys_pkey'
}

/** input type for incrementing numeric columns in table "currencys" */
export type Currencys_Inc_Input = {
  currency_id?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "currencys" */
export type Currencys_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  currency_id?: InputMaybe<Scalars['Int']>;
  display_name?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Currencys_Max_Fields = {
  __typename?: 'currencys_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['Int']>;
  display_name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate min on columns */
export type Currencys_Min_Fields = {
  __typename?: 'currencys_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['Int']>;
  display_name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

/** response of any mutation on the table "currencys" */
export type Currencys_Mutation_Response = {
  __typename?: 'currencys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Currencys>;
};

/** input type for inserting object relation for remote table "currencys" */
export type Currencys_Obj_Rel_Insert_Input = {
  data: Currencys_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Currencys_On_Conflict>;
};

/** on_conflict condition type for table "currencys" */
export type Currencys_On_Conflict = {
  constraint: Currencys_Constraint;
  update_columns?: Array<Currencys_Update_Column>;
  where?: InputMaybe<Currencys_Bool_Exp>;
};

/** Ordering options when selecting data from "currencys". */
export type Currencys_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  currency_id?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
};

/** primary key columns input for table: currencys */
export type Currencys_Pk_Columns_Input = {
  currency_id: Scalars['Int'];
};

/** select columns of table "currencys" */
export enum Currencys_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Rate = 'rate'
}

/** input type for updating data in table "currencys" */
export type Currencys_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  currency_id?: InputMaybe<Scalars['Int']>;
  display_name?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Currencys_Stddev_Fields = {
  __typename?: 'currencys_stddev_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Currencys_Stddev_Pop_Fields = {
  __typename?: 'currencys_stddev_pop_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Currencys_Stddev_Samp_Fields = {
  __typename?: 'currencys_stddev_samp_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "currencys" */
export type Currencys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currencys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Currencys_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  currency_id?: InputMaybe<Scalars['Int']>;
  display_name?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Currencys_Sum_Fields = {
  __typename?: 'currencys_sum_fields';
  currency_id?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Float']>;
};

/** update columns of table "currencys" */
export enum Currencys_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Rate = 'rate'
}

export type Currencys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Currencys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Currencys_Set_Input>;
  /** filter the rows which have to be updated */
  where: Currencys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Currencys_Var_Pop_Fields = {
  __typename?: 'currencys_var_pop_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Currencys_Var_Samp_Fields = {
  __typename?: 'currencys_var_samp_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Currencys_Variance_Fields = {
  __typename?: 'currencys_variance_fields';
  currency_id?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Таблица для хранения сумм депозитов за сутки. amount - сумма депозитов в копейках; created_at - дата депозитов; */
export type Daily_Deposit_Sum = {
  __typename?: 'daily_deposit_sum';
  amount: Scalars['bigint'];
  created_at: Scalars['date'];
};

/** aggregated selection of "daily_deposit_sum" */
export type Daily_Deposit_Sum_Aggregate = {
  __typename?: 'daily_deposit_sum_aggregate';
  aggregate?: Maybe<Daily_Deposit_Sum_Aggregate_Fields>;
  nodes: Array<Daily_Deposit_Sum>;
};

/** aggregate fields of "daily_deposit_sum" */
export type Daily_Deposit_Sum_Aggregate_Fields = {
  __typename?: 'daily_deposit_sum_aggregate_fields';
  avg?: Maybe<Daily_Deposit_Sum_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Daily_Deposit_Sum_Max_Fields>;
  min?: Maybe<Daily_Deposit_Sum_Min_Fields>;
  stddev?: Maybe<Daily_Deposit_Sum_Stddev_Fields>;
  stddev_pop?: Maybe<Daily_Deposit_Sum_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Daily_Deposit_Sum_Stddev_Samp_Fields>;
  sum?: Maybe<Daily_Deposit_Sum_Sum_Fields>;
  var_pop?: Maybe<Daily_Deposit_Sum_Var_Pop_Fields>;
  var_samp?: Maybe<Daily_Deposit_Sum_Var_Samp_Fields>;
  variance?: Maybe<Daily_Deposit_Sum_Variance_Fields>;
};


/** aggregate fields of "daily_deposit_sum" */
export type Daily_Deposit_Sum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Daily_Deposit_Sum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Daily_Deposit_Sum_Avg_Fields = {
  __typename?: 'daily_deposit_sum_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "daily_deposit_sum". All fields are combined with a logical 'AND'. */
export type Daily_Deposit_Sum_Bool_Exp = {
  _and?: InputMaybe<Array<Daily_Deposit_Sum_Bool_Exp>>;
  _not?: InputMaybe<Daily_Deposit_Sum_Bool_Exp>;
  _or?: InputMaybe<Array<Daily_Deposit_Sum_Bool_Exp>>;
  amount?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "daily_deposit_sum" */
export enum Daily_Deposit_Sum_Constraint {
  /** unique or primary key constraint on columns "created_at" */
  DailyDepositSumPkey = 'daily_deposit_sum_pkey'
}

/** input type for incrementing numeric columns in table "daily_deposit_sum" */
export type Daily_Deposit_Sum_Inc_Input = {
  amount?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "daily_deposit_sum" */
export type Daily_Deposit_Sum_Insert_Input = {
  amount?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type Daily_Deposit_Sum_Max_Fields = {
  __typename?: 'daily_deposit_sum_max_fields';
  amount?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type Daily_Deposit_Sum_Min_Fields = {
  __typename?: 'daily_deposit_sum_min_fields';
  amount?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['date']>;
};

/** response of any mutation on the table "daily_deposit_sum" */
export type Daily_Deposit_Sum_Mutation_Response = {
  __typename?: 'daily_deposit_sum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Daily_Deposit_Sum>;
};

/** on_conflict condition type for table "daily_deposit_sum" */
export type Daily_Deposit_Sum_On_Conflict = {
  constraint: Daily_Deposit_Sum_Constraint;
  update_columns?: Array<Daily_Deposit_Sum_Update_Column>;
  where?: InputMaybe<Daily_Deposit_Sum_Bool_Exp>;
};

/** Ordering options when selecting data from "daily_deposit_sum". */
export type Daily_Deposit_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: daily_deposit_sum */
export type Daily_Deposit_Sum_Pk_Columns_Input = {
  created_at: Scalars['date'];
};

/** select columns of table "daily_deposit_sum" */
export enum Daily_Deposit_Sum_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at'
}

/** input type for updating data in table "daily_deposit_sum" */
export type Daily_Deposit_Sum_Set_Input = {
  amount?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type Daily_Deposit_Sum_Stddev_Fields = {
  __typename?: 'daily_deposit_sum_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Daily_Deposit_Sum_Stddev_Pop_Fields = {
  __typename?: 'daily_deposit_sum_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Daily_Deposit_Sum_Stddev_Samp_Fields = {
  __typename?: 'daily_deposit_sum_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "daily_deposit_sum" */
export type Daily_Deposit_Sum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Daily_Deposit_Sum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Daily_Deposit_Sum_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['date']>;
};

/** aggregate sum on columns */
export type Daily_Deposit_Sum_Sum_Fields = {
  __typename?: 'daily_deposit_sum_sum_fields';
  amount?: Maybe<Scalars['bigint']>;
};

/** update columns of table "daily_deposit_sum" */
export enum Daily_Deposit_Sum_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at'
}

export type Daily_Deposit_Sum_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Daily_Deposit_Sum_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Daily_Deposit_Sum_Set_Input>;
  /** filter the rows which have to be updated */
  where: Daily_Deposit_Sum_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Daily_Deposit_Sum_Var_Pop_Fields = {
  __typename?: 'daily_deposit_sum_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Daily_Deposit_Sum_Var_Samp_Fields = {
  __typename?: 'daily_deposit_sum_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Daily_Deposit_Sum_Variance_Fields = {
  __typename?: 'daily_deposit_sum_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "emails_from_upgrade_question" */
export type Emails_From_Upgrade_Question = {
  __typename?: 'emails_from_upgrade_question';
  email: Scalars['String'];
  id: Scalars['bigint'];
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "emails_from_upgrade_question" */
export type Emails_From_Upgrade_Question_Aggregate = {
  __typename?: 'emails_from_upgrade_question_aggregate';
  aggregate?: Maybe<Emails_From_Upgrade_Question_Aggregate_Fields>;
  nodes: Array<Emails_From_Upgrade_Question>;
};

/** aggregate fields of "emails_from_upgrade_question" */
export type Emails_From_Upgrade_Question_Aggregate_Fields = {
  __typename?: 'emails_from_upgrade_question_aggregate_fields';
  avg?: Maybe<Emails_From_Upgrade_Question_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Emails_From_Upgrade_Question_Max_Fields>;
  min?: Maybe<Emails_From_Upgrade_Question_Min_Fields>;
  stddev?: Maybe<Emails_From_Upgrade_Question_Stddev_Fields>;
  stddev_pop?: Maybe<Emails_From_Upgrade_Question_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Emails_From_Upgrade_Question_Stddev_Samp_Fields>;
  sum?: Maybe<Emails_From_Upgrade_Question_Sum_Fields>;
  var_pop?: Maybe<Emails_From_Upgrade_Question_Var_Pop_Fields>;
  var_samp?: Maybe<Emails_From_Upgrade_Question_Var_Samp_Fields>;
  variance?: Maybe<Emails_From_Upgrade_Question_Variance_Fields>;
};


/** aggregate fields of "emails_from_upgrade_question" */
export type Emails_From_Upgrade_Question_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Emails_From_Upgrade_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Emails_From_Upgrade_Question_Avg_Fields = {
  __typename?: 'emails_from_upgrade_question_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "emails_from_upgrade_question". All fields are combined with a logical 'AND'. */
export type Emails_From_Upgrade_Question_Bool_Exp = {
  _and?: InputMaybe<Array<Emails_From_Upgrade_Question_Bool_Exp>>;
  _not?: InputMaybe<Emails_From_Upgrade_Question_Bool_Exp>;
  _or?: InputMaybe<Array<Emails_From_Upgrade_Question_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "emails_from_upgrade_question" */
export enum Emails_From_Upgrade_Question_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailsFromUpgradeQuestionPkey = 'emails_from_upgrade_question_pkey'
}

/** input type for incrementing numeric columns in table "emails_from_upgrade_question" */
export type Emails_From_Upgrade_Question_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "emails_from_upgrade_question" */
export type Emails_From_Upgrade_Question_Insert_Input = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Emails_From_Upgrade_Question_Max_Fields = {
  __typename?: 'emails_from_upgrade_question_max_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Emails_From_Upgrade_Question_Min_Fields = {
  __typename?: 'emails_from_upgrade_question_min_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "emails_from_upgrade_question" */
export type Emails_From_Upgrade_Question_Mutation_Response = {
  __typename?: 'emails_from_upgrade_question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Emails_From_Upgrade_Question>;
};

/** on_conflict condition type for table "emails_from_upgrade_question" */
export type Emails_From_Upgrade_Question_On_Conflict = {
  constraint: Emails_From_Upgrade_Question_Constraint;
  update_columns?: Array<Emails_From_Upgrade_Question_Update_Column>;
  where?: InputMaybe<Emails_From_Upgrade_Question_Bool_Exp>;
};

/** Ordering options when selecting data from "emails_from_upgrade_question". */
export type Emails_From_Upgrade_Question_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: emails_from_upgrade_question */
export type Emails_From_Upgrade_Question_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "emails_from_upgrade_question" */
export enum Emails_From_Upgrade_Question_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "emails_from_upgrade_question" */
export type Emails_From_Upgrade_Question_Set_Input = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Emails_From_Upgrade_Question_Stddev_Fields = {
  __typename?: 'emails_from_upgrade_question_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Emails_From_Upgrade_Question_Stddev_Pop_Fields = {
  __typename?: 'emails_from_upgrade_question_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Emails_From_Upgrade_Question_Stddev_Samp_Fields = {
  __typename?: 'emails_from_upgrade_question_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "emails_from_upgrade_question" */
export type Emails_From_Upgrade_Question_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Emails_From_Upgrade_Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Emails_From_Upgrade_Question_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Emails_From_Upgrade_Question_Sum_Fields = {
  __typename?: 'emails_from_upgrade_question_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "emails_from_upgrade_question" */
export enum Emails_From_Upgrade_Question_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Emails_From_Upgrade_Question_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Emails_From_Upgrade_Question_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Emails_From_Upgrade_Question_Set_Input>;
  /** filter the rows which have to be updated */
  where: Emails_From_Upgrade_Question_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Emails_From_Upgrade_Question_Var_Pop_Fields = {
  __typename?: 'emails_from_upgrade_question_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Emails_From_Upgrade_Question_Var_Samp_Fields = {
  __typename?: 'emails_from_upgrade_question_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Emails_From_Upgrade_Question_Variance_Fields = {
  __typename?: 'emails_from_upgrade_question_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Методы выполнения действий. */
export type Execution_Methods = {
  __typename?: 'execution_methods';
  description: Scalars['String'];
  method_id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "execution_methods" */
export type Execution_Methods_Aggregate = {
  __typename?: 'execution_methods_aggregate';
  aggregate?: Maybe<Execution_Methods_Aggregate_Fields>;
  nodes: Array<Execution_Methods>;
};

/** aggregate fields of "execution_methods" */
export type Execution_Methods_Aggregate_Fields = {
  __typename?: 'execution_methods_aggregate_fields';
  avg?: Maybe<Execution_Methods_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Execution_Methods_Max_Fields>;
  min?: Maybe<Execution_Methods_Min_Fields>;
  stddev?: Maybe<Execution_Methods_Stddev_Fields>;
  stddev_pop?: Maybe<Execution_Methods_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Execution_Methods_Stddev_Samp_Fields>;
  sum?: Maybe<Execution_Methods_Sum_Fields>;
  var_pop?: Maybe<Execution_Methods_Var_Pop_Fields>;
  var_samp?: Maybe<Execution_Methods_Var_Samp_Fields>;
  variance?: Maybe<Execution_Methods_Variance_Fields>;
};


/** aggregate fields of "execution_methods" */
export type Execution_Methods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Execution_Methods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Execution_Methods_Avg_Fields = {
  __typename?: 'execution_methods_avg_fields';
  method_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "execution_methods". All fields are combined with a logical 'AND'. */
export type Execution_Methods_Bool_Exp = {
  _and?: InputMaybe<Array<Execution_Methods_Bool_Exp>>;
  _not?: InputMaybe<Execution_Methods_Bool_Exp>;
  _or?: InputMaybe<Array<Execution_Methods_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  method_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "execution_methods" */
export enum Execution_Methods_Constraint {
  /** unique or primary key constraint on columns "method_id" */
  ExecutionMethodsPkey = 'execution_methods_pkey'
}

/** input type for incrementing numeric columns in table "execution_methods" */
export type Execution_Methods_Inc_Input = {
  method_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "execution_methods" */
export type Execution_Methods_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  method_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Execution_Methods_Max_Fields = {
  __typename?: 'execution_methods_max_fields';
  description?: Maybe<Scalars['String']>;
  method_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Execution_Methods_Min_Fields = {
  __typename?: 'execution_methods_min_fields';
  description?: Maybe<Scalars['String']>;
  method_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "execution_methods" */
export type Execution_Methods_Mutation_Response = {
  __typename?: 'execution_methods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Execution_Methods>;
};

/** input type for inserting object relation for remote table "execution_methods" */
export type Execution_Methods_Obj_Rel_Insert_Input = {
  data: Execution_Methods_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Execution_Methods_On_Conflict>;
};

/** on_conflict condition type for table "execution_methods" */
export type Execution_Methods_On_Conflict = {
  constraint: Execution_Methods_Constraint;
  update_columns?: Array<Execution_Methods_Update_Column>;
  where?: InputMaybe<Execution_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "execution_methods". */
export type Execution_Methods_Order_By = {
  description?: InputMaybe<Order_By>;
  method_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: execution_methods */
export type Execution_Methods_Pk_Columns_Input = {
  method_id: Scalars['Int'];
};

/** select columns of table "execution_methods" */
export enum Execution_Methods_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  MethodId = 'method_id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "execution_methods" */
export type Execution_Methods_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  method_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Execution_Methods_Stddev_Fields = {
  __typename?: 'execution_methods_stddev_fields';
  method_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Execution_Methods_Stddev_Pop_Fields = {
  __typename?: 'execution_methods_stddev_pop_fields';
  method_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Execution_Methods_Stddev_Samp_Fields = {
  __typename?: 'execution_methods_stddev_samp_fields';
  method_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "execution_methods" */
export type Execution_Methods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Execution_Methods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Execution_Methods_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  method_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Execution_Methods_Sum_Fields = {
  __typename?: 'execution_methods_sum_fields';
  method_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "execution_methods" */
export enum Execution_Methods_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  MethodId = 'method_id',
  /** column name */
  Name = 'name'
}

export type Execution_Methods_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Execution_Methods_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Execution_Methods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Execution_Methods_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Execution_Methods_Var_Pop_Fields = {
  __typename?: 'execution_methods_var_pop_fields';
  method_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Execution_Methods_Var_Samp_Fields = {
  __typename?: 'execution_methods_var_samp_fields';
  method_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Execution_Methods_Variance_Fields = {
  __typename?: 'execution_methods_variance_fields';
  method_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "faq_table" */
export type Faq_Table = {
  __typename?: 'faq_table';
  active: Scalars['Boolean'];
  answer: Scalars['String'];
  id: Scalars['uuid'];
  question: Scalars['String'];
  sort: Scalars['Int'];
};

/** aggregated selection of "faq_table" */
export type Faq_Table_Aggregate = {
  __typename?: 'faq_table_aggregate';
  aggregate?: Maybe<Faq_Table_Aggregate_Fields>;
  nodes: Array<Faq_Table>;
};

/** aggregate fields of "faq_table" */
export type Faq_Table_Aggregate_Fields = {
  __typename?: 'faq_table_aggregate_fields';
  avg?: Maybe<Faq_Table_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Faq_Table_Max_Fields>;
  min?: Maybe<Faq_Table_Min_Fields>;
  stddev?: Maybe<Faq_Table_Stddev_Fields>;
  stddev_pop?: Maybe<Faq_Table_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Faq_Table_Stddev_Samp_Fields>;
  sum?: Maybe<Faq_Table_Sum_Fields>;
  var_pop?: Maybe<Faq_Table_Var_Pop_Fields>;
  var_samp?: Maybe<Faq_Table_Var_Samp_Fields>;
  variance?: Maybe<Faq_Table_Variance_Fields>;
};


/** aggregate fields of "faq_table" */
export type Faq_Table_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Faq_Table_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Faq_Table_Avg_Fields = {
  __typename?: 'faq_table_avg_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "faq_table". All fields are combined with a logical 'AND'. */
export type Faq_Table_Bool_Exp = {
  _and?: InputMaybe<Array<Faq_Table_Bool_Exp>>;
  _not?: InputMaybe<Faq_Table_Bool_Exp>;
  _or?: InputMaybe<Array<Faq_Table_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  answer?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "faq_table" */
export enum Faq_Table_Constraint {
  /** unique or primary key constraint on columns "id" */
  FaqTablePkey = 'faq_table_pkey'
}

/** input type for incrementing numeric columns in table "faq_table" */
export type Faq_Table_Inc_Input = {
  sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "faq_table" */
export type Faq_Table_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  answer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Faq_Table_Max_Fields = {
  __typename?: 'faq_table_max_fields';
  answer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Faq_Table_Min_Fields = {
  __typename?: 'faq_table_min_fields';
  answer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "faq_table" */
export type Faq_Table_Mutation_Response = {
  __typename?: 'faq_table_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Faq_Table>;
};

/** on_conflict condition type for table "faq_table" */
export type Faq_Table_On_Conflict = {
  constraint: Faq_Table_Constraint;
  update_columns?: Array<Faq_Table_Update_Column>;
  where?: InputMaybe<Faq_Table_Bool_Exp>;
};

/** Ordering options when selecting data from "faq_table". */
export type Faq_Table_Order_By = {
  active?: InputMaybe<Order_By>;
  answer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: faq_table */
export type Faq_Table_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "faq_table" */
export enum Faq_Table_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Answer = 'answer',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  Sort = 'sort'
}

/** input type for updating data in table "faq_table" */
export type Faq_Table_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  answer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Faq_Table_Stddev_Fields = {
  __typename?: 'faq_table_stddev_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Faq_Table_Stddev_Pop_Fields = {
  __typename?: 'faq_table_stddev_pop_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Faq_Table_Stddev_Samp_Fields = {
  __typename?: 'faq_table_stddev_samp_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "faq_table" */
export type Faq_Table_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Faq_Table_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Faq_Table_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  answer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Faq_Table_Sum_Fields = {
  __typename?: 'faq_table_sum_fields';
  sort?: Maybe<Scalars['Int']>;
};

/** update columns of table "faq_table" */
export enum Faq_Table_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Answer = 'answer',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  Sort = 'sort'
}

export type Faq_Table_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Faq_Table_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Faq_Table_Set_Input>;
  /** filter the rows which have to be updated */
  where: Faq_Table_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Faq_Table_Var_Pop_Fields = {
  __typename?: 'faq_table_var_pop_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Faq_Table_Var_Samp_Fields = {
  __typename?: 'faq_table_var_samp_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Faq_Table_Variance_Fields = {
  __typename?: 'faq_table_variance_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters = {
  __typename?: 'fixed_items_generator_boosters';
  /** An object relationship */
  booster?: Maybe<Lootbox_Booster_Items>;
  booster_id: Scalars['uuid'];
  generator_lootbox_id: Scalars['uuid'];
};

/** aggregated selection of "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_Aggregate = {
  __typename?: 'fixed_items_generator_boosters_aggregate';
  aggregate?: Maybe<Fixed_Items_Generator_Boosters_Aggregate_Fields>;
  nodes: Array<Fixed_Items_Generator_Boosters>;
};

export type Fixed_Items_Generator_Boosters_Aggregate_Bool_Exp = {
  count?: InputMaybe<Fixed_Items_Generator_Boosters_Aggregate_Bool_Exp_Count>;
};

export type Fixed_Items_Generator_Boosters_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Fixed_Items_Generator_Boosters_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_Aggregate_Fields = {
  __typename?: 'fixed_items_generator_boosters_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fixed_Items_Generator_Boosters_Max_Fields>;
  min?: Maybe<Fixed_Items_Generator_Boosters_Min_Fields>;
};


/** aggregate fields of "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Fixed_Items_Generator_Boosters_Max_Order_By>;
  min?: InputMaybe<Fixed_Items_Generator_Boosters_Min_Order_By>;
};

/** input type for inserting array relation for remote table "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_Arr_Rel_Insert_Input = {
  data: Array<Fixed_Items_Generator_Boosters_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Fixed_Items_Generator_Boosters_On_Conflict>;
};

/** Boolean expression to filter rows from the table "fixed_items_generator_boosters". All fields are combined with a logical 'AND'. */
export type Fixed_Items_Generator_Boosters_Bool_Exp = {
  _and?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Bool_Exp>>;
  _not?: InputMaybe<Fixed_Items_Generator_Boosters_Bool_Exp>;
  _or?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Bool_Exp>>;
  booster?: InputMaybe<Lootbox_Booster_Items_Bool_Exp>;
  booster_id?: InputMaybe<Uuid_Comparison_Exp>;
  generator_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "fixed_items_generator_boosters" */
export enum Fixed_Items_Generator_Boosters_Constraint {
  /** unique or primary key constraint on columns "booster_id", "generator_lootbox_id" */
  FixedItemsGeneratorBoostersPkey = 'fixed_items_generator_boosters_pkey'
}

/** input type for inserting data into table "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_Insert_Input = {
  booster?: InputMaybe<Lootbox_Booster_Items_Obj_Rel_Insert_Input>;
  booster_id?: InputMaybe<Scalars['uuid']>;
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Fixed_Items_Generator_Boosters_Max_Fields = {
  __typename?: 'fixed_items_generator_boosters_max_fields';
  booster_id?: Maybe<Scalars['uuid']>;
  generator_lootbox_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_Max_Order_By = {
  booster_id?: InputMaybe<Order_By>;
  generator_lootbox_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Fixed_Items_Generator_Boosters_Min_Fields = {
  __typename?: 'fixed_items_generator_boosters_min_fields';
  booster_id?: Maybe<Scalars['uuid']>;
  generator_lootbox_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_Min_Order_By = {
  booster_id?: InputMaybe<Order_By>;
  generator_lootbox_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_Mutation_Response = {
  __typename?: 'fixed_items_generator_boosters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fixed_Items_Generator_Boosters>;
};

/** on_conflict condition type for table "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_On_Conflict = {
  constraint: Fixed_Items_Generator_Boosters_Constraint;
  update_columns?: Array<Fixed_Items_Generator_Boosters_Update_Column>;
  where?: InputMaybe<Fixed_Items_Generator_Boosters_Bool_Exp>;
};

/** Ordering options when selecting data from "fixed_items_generator_boosters". */
export type Fixed_Items_Generator_Boosters_Order_By = {
  booster?: InputMaybe<Lootbox_Booster_Items_Order_By>;
  booster_id?: InputMaybe<Order_By>;
  generator_lootbox_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fixed_items_generator_boosters */
export type Fixed_Items_Generator_Boosters_Pk_Columns_Input = {
  booster_id: Scalars['uuid'];
  generator_lootbox_id: Scalars['uuid'];
};

/** select columns of table "fixed_items_generator_boosters" */
export enum Fixed_Items_Generator_Boosters_Select_Column {
  /** column name */
  BoosterId = 'booster_id',
  /** column name */
  GeneratorLootboxId = 'generator_lootbox_id'
}

/** input type for updating data in table "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_Set_Input = {
  booster_id?: InputMaybe<Scalars['uuid']>;
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "fixed_items_generator_boosters" */
export type Fixed_Items_Generator_Boosters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fixed_Items_Generator_Boosters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fixed_Items_Generator_Boosters_Stream_Cursor_Value_Input = {
  booster_id?: InputMaybe<Scalars['uuid']>;
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "fixed_items_generator_boosters" */
export enum Fixed_Items_Generator_Boosters_Update_Column {
  /** column name */
  BoosterId = 'booster_id',
  /** column name */
  GeneratorLootboxId = 'generator_lootbox_id'
}

export type Fixed_Items_Generator_Boosters_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fixed_Items_Generator_Boosters_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fixed_Items_Generator_Boosters_Bool_Exp;
};

/** columns and relationships of "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items = {
  __typename?: 'fixed_items_generator_items';
  generator_lootbox_id: Scalars['uuid'];
  item_data?: Maybe<Items>;
  item_hash_name: Scalars['String'];
};

/** aggregated selection of "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_Aggregate = {
  __typename?: 'fixed_items_generator_items_aggregate';
  aggregate?: Maybe<Fixed_Items_Generator_Items_Aggregate_Fields>;
  nodes: Array<Fixed_Items_Generator_Items>;
};

export type Fixed_Items_Generator_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Fixed_Items_Generator_Items_Aggregate_Bool_Exp_Count>;
};

export type Fixed_Items_Generator_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Fixed_Items_Generator_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Fixed_Items_Generator_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_Aggregate_Fields = {
  __typename?: 'fixed_items_generator_items_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fixed_Items_Generator_Items_Max_Fields>;
  min?: Maybe<Fixed_Items_Generator_Items_Min_Fields>;
};


/** aggregate fields of "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fixed_Items_Generator_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Fixed_Items_Generator_Items_Max_Order_By>;
  min?: InputMaybe<Fixed_Items_Generator_Items_Min_Order_By>;
};

/** input type for inserting array relation for remote table "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_Arr_Rel_Insert_Input = {
  data: Array<Fixed_Items_Generator_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Fixed_Items_Generator_Items_On_Conflict>;
};

/** Boolean expression to filter rows from the table "fixed_items_generator_items". All fields are combined with a logical 'AND'. */
export type Fixed_Items_Generator_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Fixed_Items_Generator_Items_Bool_Exp>>;
  _not?: InputMaybe<Fixed_Items_Generator_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Fixed_Items_Generator_Items_Bool_Exp>>;
  generator_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fixed_items_generator_items" */
export enum Fixed_Items_Generator_Items_Constraint {
  /** unique or primary key constraint on columns "item_hash_name", "generator_lootbox_id" */
  FixedItemsGeneratorItemsPkey = 'fixed_items_generator_items_pkey'
}

/** input type for inserting data into table "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_Insert_Input = {
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Fixed_Items_Generator_Items_Max_Fields = {
  __typename?: 'fixed_items_generator_items_max_fields';
  generator_lootbox_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_Max_Order_By = {
  generator_lootbox_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Fixed_Items_Generator_Items_Min_Fields = {
  __typename?: 'fixed_items_generator_items_min_fields';
  generator_lootbox_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_Min_Order_By = {
  generator_lootbox_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_Mutation_Response = {
  __typename?: 'fixed_items_generator_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fixed_Items_Generator_Items>;
};

/** on_conflict condition type for table "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_On_Conflict = {
  constraint: Fixed_Items_Generator_Items_Constraint;
  update_columns?: Array<Fixed_Items_Generator_Items_Update_Column>;
  where?: InputMaybe<Fixed_Items_Generator_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "fixed_items_generator_items". */
export type Fixed_Items_Generator_Items_Order_By = {
  generator_lootbox_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fixed_items_generator_items */
export type Fixed_Items_Generator_Items_Pk_Columns_Input = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};

/** select columns of table "fixed_items_generator_items" */
export enum Fixed_Items_Generator_Items_Select_Column {
  /** column name */
  GeneratorLootboxId = 'generator_lootbox_id',
  /** column name */
  ItemHashName = 'item_hash_name'
}

/** input type for updating data in table "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_Set_Input = {
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "fixed_items_generator_items" */
export type Fixed_Items_Generator_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fixed_Items_Generator_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fixed_Items_Generator_Items_Stream_Cursor_Value_Input = {
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "fixed_items_generator_items" */
export enum Fixed_Items_Generator_Items_Update_Column {
  /** column name */
  GeneratorLootboxId = 'generator_lootbox_id',
  /** column name */
  ItemHashName = 'item_hash_name'
}

export type Fixed_Items_Generator_Items_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fixed_Items_Generator_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fixed_Items_Generator_Items_Bool_Exp;
};

/** lootbox_category - id категории кейса в которую он попадет после генерации image_link - ссылка на картинку кейса target_price - целевая стоимость кейса в рублях (не копейках) open_max_count - максимальное количество открытий кейса.  */
export type Fixed_Items_Generator_Lootboxes = {
  __typename?: 'fixed_items_generator_lootboxes';
  add_jackpot: Scalars['Boolean'];
  amp_lootbox_id: Scalars['uuid'];
  badge?: Maybe<Scalars['String']>;
  boosters_limit: Scalars['Int'];
  fixed: Scalars['Boolean'];
  /** An array relationship */
  fixed_items_generator_boosters: Array<Fixed_Items_Generator_Boosters>;
  /** An aggregate relationship */
  fixed_items_generator_boosters_aggregate: Fixed_Items_Generator_Boosters_Aggregate;
  /** An array relationship */
  fixed_items_generator_items: Array<Fixed_Items_Generator_Items>;
  /** An aggregate relationship */
  fixed_items_generator_items_aggregate: Fixed_Items_Generator_Items_Aggregate;
  /** An object relationship */
  generator_mechanic?: Maybe<Lootboxes_Generator_Mechanics>;
  highest_rtp_param: Scalars['Int'];
  is_active: Scalars['Boolean'];
  last_generated_lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_category: Scalars['Int'];
  /** An object relationship */
  lootbox_category_data?: Maybe<Lootbox_Category>;
  lootbox_generator_id: Scalars['uuid'];
  lootbox_image_link: Scalars['String'];
  lootbox_name: Scalars['String'];
  lootbox_open_max_count?: Maybe<Scalars['Int']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_target_price: Scalars['Int'];
  /** An object relationship */
  lootboxes?: Maybe<Lootboxes>;
  lowest_rtp_param: Scalars['Int'];
  max_limit: Scalars['Int'];
  mechanic_id: Scalars['Int'];
  min_limit: Scalars['Int'];
  real_view: Scalars['Boolean'];
};


/** lootbox_category - id категории кейса в которую он попадет после генерации image_link - ссылка на картинку кейса target_price - целевая стоимость кейса в рублях (не копейках) open_max_count - максимальное количество открытий кейса.  */
export type Fixed_Items_Generator_LootboxesFixed_Items_Generator_BoostersArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Boosters_Bool_Exp>;
};


/** lootbox_category - id категории кейса в которую он попадет после генерации image_link - ссылка на картинку кейса target_price - целевая стоимость кейса в рублях (не копейках) open_max_count - максимальное количество открытий кейса.  */
export type Fixed_Items_Generator_LootboxesFixed_Items_Generator_Boosters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Boosters_Bool_Exp>;
};


/** lootbox_category - id категории кейса в которую он попадет после генерации image_link - ссылка на картинку кейса target_price - целевая стоимость кейса в рублях (не копейках) open_max_count - максимальное количество открытий кейса.  */
export type Fixed_Items_Generator_LootboxesFixed_Items_Generator_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Items_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Items_Bool_Exp>;
};


/** lootbox_category - id категории кейса в которую он попадет после генерации image_link - ссылка на картинку кейса target_price - целевая стоимость кейса в рублях (не копейках) open_max_count - максимальное количество открытий кейса.  */
export type Fixed_Items_Generator_LootboxesFixed_Items_Generator_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Items_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Items_Bool_Exp>;
};

/** aggregated selection of "fixed_items_generator_lootboxes" */
export type Fixed_Items_Generator_Lootboxes_Aggregate = {
  __typename?: 'fixed_items_generator_lootboxes_aggregate';
  aggregate?: Maybe<Fixed_Items_Generator_Lootboxes_Aggregate_Fields>;
  nodes: Array<Fixed_Items_Generator_Lootboxes>;
};

/** aggregate fields of "fixed_items_generator_lootboxes" */
export type Fixed_Items_Generator_Lootboxes_Aggregate_Fields = {
  __typename?: 'fixed_items_generator_lootboxes_aggregate_fields';
  avg?: Maybe<Fixed_Items_Generator_Lootboxes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fixed_Items_Generator_Lootboxes_Max_Fields>;
  min?: Maybe<Fixed_Items_Generator_Lootboxes_Min_Fields>;
  stddev?: Maybe<Fixed_Items_Generator_Lootboxes_Stddev_Fields>;
  stddev_pop?: Maybe<Fixed_Items_Generator_Lootboxes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fixed_Items_Generator_Lootboxes_Stddev_Samp_Fields>;
  sum?: Maybe<Fixed_Items_Generator_Lootboxes_Sum_Fields>;
  var_pop?: Maybe<Fixed_Items_Generator_Lootboxes_Var_Pop_Fields>;
  var_samp?: Maybe<Fixed_Items_Generator_Lootboxes_Var_Samp_Fields>;
  variance?: Maybe<Fixed_Items_Generator_Lootboxes_Variance_Fields>;
};


/** aggregate fields of "fixed_items_generator_lootboxes" */
export type Fixed_Items_Generator_Lootboxes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fixed_Items_Generator_Lootboxes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Fixed_Items_Generator_Lootboxes_Avg_Fields = {
  __typename?: 'fixed_items_generator_lootboxes_avg_fields';
  boosters_limit?: Maybe<Scalars['Float']>;
  highest_rtp_param?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  lowest_rtp_param?: Maybe<Scalars['Float']>;
  max_limit?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  min_limit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fixed_items_generator_lootboxes". All fields are combined with a logical 'AND'. */
export type Fixed_Items_Generator_Lootboxes_Bool_Exp = {
  _and?: InputMaybe<Array<Fixed_Items_Generator_Lootboxes_Bool_Exp>>;
  _not?: InputMaybe<Fixed_Items_Generator_Lootboxes_Bool_Exp>;
  _or?: InputMaybe<Array<Fixed_Items_Generator_Lootboxes_Bool_Exp>>;
  add_jackpot?: InputMaybe<Boolean_Comparison_Exp>;
  amp_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  badge?: InputMaybe<String_Comparison_Exp>;
  boosters_limit?: InputMaybe<Int_Comparison_Exp>;
  fixed?: InputMaybe<Boolean_Comparison_Exp>;
  fixed_items_generator_boosters?: InputMaybe<Fixed_Items_Generator_Boosters_Bool_Exp>;
  fixed_items_generator_boosters_aggregate?: InputMaybe<Fixed_Items_Generator_Boosters_Aggregate_Bool_Exp>;
  fixed_items_generator_items?: InputMaybe<Fixed_Items_Generator_Items_Bool_Exp>;
  fixed_items_generator_items_aggregate?: InputMaybe<Fixed_Items_Generator_Items_Aggregate_Bool_Exp>;
  generator_mechanic?: InputMaybe<Lootboxes_Generator_Mechanics_Bool_Exp>;
  highest_rtp_param?: InputMaybe<Int_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  last_generated_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_category?: InputMaybe<Int_Comparison_Exp>;
  lootbox_category_data?: InputMaybe<Lootbox_Category_Bool_Exp>;
  lootbox_generator_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_image_link?: InputMaybe<String_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  lootbox_open_max_count?: InputMaybe<Int_Comparison_Exp>;
  lootbox_slug?: InputMaybe<String_Comparison_Exp>;
  lootbox_target_price?: InputMaybe<Int_Comparison_Exp>;
  lootboxes?: InputMaybe<Lootboxes_Bool_Exp>;
  lowest_rtp_param?: InputMaybe<Int_Comparison_Exp>;
  max_limit?: InputMaybe<Int_Comparison_Exp>;
  mechanic_id?: InputMaybe<Int_Comparison_Exp>;
  min_limit?: InputMaybe<Int_Comparison_Exp>;
  real_view?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "fixed_items_generator_lootboxes" */
export enum Fixed_Items_Generator_Lootboxes_Constraint {
  /** unique or primary key constraint on columns "lootbox_generator_id" */
  FixedItemsGeneratorLootboxesPkey = 'fixed_items_generator_lootboxes_pkey'
}

/** input type for incrementing numeric columns in table "fixed_items_generator_lootboxes" */
export type Fixed_Items_Generator_Lootboxes_Inc_Input = {
  boosters_limit?: InputMaybe<Scalars['Int']>;
  highest_rtp_param?: InputMaybe<Scalars['Int']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_open_max_count?: InputMaybe<Scalars['Int']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  lowest_rtp_param?: InputMaybe<Scalars['Int']>;
  max_limit?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
  min_limit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "fixed_items_generator_lootboxes" */
export type Fixed_Items_Generator_Lootboxes_Insert_Input = {
  add_jackpot?: InputMaybe<Scalars['Boolean']>;
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  badge?: InputMaybe<Scalars['String']>;
  boosters_limit?: InputMaybe<Scalars['Int']>;
  fixed?: InputMaybe<Scalars['Boolean']>;
  fixed_items_generator_boosters?: InputMaybe<Fixed_Items_Generator_Boosters_Arr_Rel_Insert_Input>;
  fixed_items_generator_items?: InputMaybe<Fixed_Items_Generator_Items_Arr_Rel_Insert_Input>;
  generator_mechanic?: InputMaybe<Lootboxes_Generator_Mechanics_Obj_Rel_Insert_Input>;
  highest_rtp_param?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_generated_lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_category_data?: InputMaybe<Lootbox_Category_Obj_Rel_Insert_Input>;
  lootbox_generator_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_open_max_count?: InputMaybe<Scalars['Int']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  lootboxes?: InputMaybe<Lootboxes_Obj_Rel_Insert_Input>;
  lowest_rtp_param?: InputMaybe<Scalars['Int']>;
  max_limit?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
  min_limit?: InputMaybe<Scalars['Int']>;
  real_view?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Fixed_Items_Generator_Lootboxes_Max_Fields = {
  __typename?: 'fixed_items_generator_lootboxes_max_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  badge?: Maybe<Scalars['String']>;
  boosters_limit?: Maybe<Scalars['Int']>;
  highest_rtp_param?: Maybe<Scalars['Int']>;
  last_generated_lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_generator_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_open_max_count?: Maybe<Scalars['Int']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_target_price?: Maybe<Scalars['Int']>;
  lowest_rtp_param?: Maybe<Scalars['Int']>;
  max_limit?: Maybe<Scalars['Int']>;
  mechanic_id?: Maybe<Scalars['Int']>;
  min_limit?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Fixed_Items_Generator_Lootboxes_Min_Fields = {
  __typename?: 'fixed_items_generator_lootboxes_min_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  badge?: Maybe<Scalars['String']>;
  boosters_limit?: Maybe<Scalars['Int']>;
  highest_rtp_param?: Maybe<Scalars['Int']>;
  last_generated_lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_generator_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_open_max_count?: Maybe<Scalars['Int']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_target_price?: Maybe<Scalars['Int']>;
  lowest_rtp_param?: Maybe<Scalars['Int']>;
  max_limit?: Maybe<Scalars['Int']>;
  mechanic_id?: Maybe<Scalars['Int']>;
  min_limit?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "fixed_items_generator_lootboxes" */
export type Fixed_Items_Generator_Lootboxes_Mutation_Response = {
  __typename?: 'fixed_items_generator_lootboxes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fixed_Items_Generator_Lootboxes>;
};

/** on_conflict condition type for table "fixed_items_generator_lootboxes" */
export type Fixed_Items_Generator_Lootboxes_On_Conflict = {
  constraint: Fixed_Items_Generator_Lootboxes_Constraint;
  update_columns?: Array<Fixed_Items_Generator_Lootboxes_Update_Column>;
  where?: InputMaybe<Fixed_Items_Generator_Lootboxes_Bool_Exp>;
};

/** Ordering options when selecting data from "fixed_items_generator_lootboxes". */
export type Fixed_Items_Generator_Lootboxes_Order_By = {
  add_jackpot?: InputMaybe<Order_By>;
  amp_lootbox_id?: InputMaybe<Order_By>;
  badge?: InputMaybe<Order_By>;
  boosters_limit?: InputMaybe<Order_By>;
  fixed?: InputMaybe<Order_By>;
  fixed_items_generator_boosters_aggregate?: InputMaybe<Fixed_Items_Generator_Boosters_Aggregate_Order_By>;
  fixed_items_generator_items_aggregate?: InputMaybe<Fixed_Items_Generator_Items_Aggregate_Order_By>;
  generator_mechanic?: InputMaybe<Lootboxes_Generator_Mechanics_Order_By>;
  highest_rtp_param?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  last_generated_lootbox_id?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_category_data?: InputMaybe<Lootbox_Category_Order_By>;
  lootbox_generator_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  lootbox_target_price?: InputMaybe<Order_By>;
  lootboxes?: InputMaybe<Lootboxes_Order_By>;
  lowest_rtp_param?: InputMaybe<Order_By>;
  max_limit?: InputMaybe<Order_By>;
  mechanic_id?: InputMaybe<Order_By>;
  min_limit?: InputMaybe<Order_By>;
  real_view?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fixed_items_generator_lootboxes */
export type Fixed_Items_Generator_Lootboxes_Pk_Columns_Input = {
  lootbox_generator_id: Scalars['uuid'];
};

/** select columns of table "fixed_items_generator_lootboxes" */
export enum Fixed_Items_Generator_Lootboxes_Select_Column {
  /** column name */
  AddJackpot = 'add_jackpot',
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  Badge = 'badge',
  /** column name */
  BoostersLimit = 'boosters_limit',
  /** column name */
  Fixed = 'fixed',
  /** column name */
  HighestRtpParam = 'highest_rtp_param',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastGeneratedLootboxId = 'last_generated_lootbox_id',
  /** column name */
  LootboxCategory = 'lootbox_category',
  /** column name */
  LootboxGeneratorId = 'lootbox_generator_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxOpenMaxCount = 'lootbox_open_max_count',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxTargetPrice = 'lootbox_target_price',
  /** column name */
  LowestRtpParam = 'lowest_rtp_param',
  /** column name */
  MaxLimit = 'max_limit',
  /** column name */
  MechanicId = 'mechanic_id',
  /** column name */
  MinLimit = 'min_limit',
  /** column name */
  RealView = 'real_view'
}

/** input type for updating data in table "fixed_items_generator_lootboxes" */
export type Fixed_Items_Generator_Lootboxes_Set_Input = {
  add_jackpot?: InputMaybe<Scalars['Boolean']>;
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  badge?: InputMaybe<Scalars['String']>;
  boosters_limit?: InputMaybe<Scalars['Int']>;
  fixed?: InputMaybe<Scalars['Boolean']>;
  highest_rtp_param?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_generated_lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_generator_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_open_max_count?: InputMaybe<Scalars['Int']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  lowest_rtp_param?: InputMaybe<Scalars['Int']>;
  max_limit?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
  min_limit?: InputMaybe<Scalars['Int']>;
  real_view?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Fixed_Items_Generator_Lootboxes_Stddev_Fields = {
  __typename?: 'fixed_items_generator_lootboxes_stddev_fields';
  boosters_limit?: Maybe<Scalars['Float']>;
  highest_rtp_param?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  lowest_rtp_param?: Maybe<Scalars['Float']>;
  max_limit?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  min_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Fixed_Items_Generator_Lootboxes_Stddev_Pop_Fields = {
  __typename?: 'fixed_items_generator_lootboxes_stddev_pop_fields';
  boosters_limit?: Maybe<Scalars['Float']>;
  highest_rtp_param?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  lowest_rtp_param?: Maybe<Scalars['Float']>;
  max_limit?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  min_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Fixed_Items_Generator_Lootboxes_Stddev_Samp_Fields = {
  __typename?: 'fixed_items_generator_lootboxes_stddev_samp_fields';
  boosters_limit?: Maybe<Scalars['Float']>;
  highest_rtp_param?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  lowest_rtp_param?: Maybe<Scalars['Float']>;
  max_limit?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  min_limit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "fixed_items_generator_lootboxes" */
export type Fixed_Items_Generator_Lootboxes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fixed_Items_Generator_Lootboxes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fixed_Items_Generator_Lootboxes_Stream_Cursor_Value_Input = {
  add_jackpot?: InputMaybe<Scalars['Boolean']>;
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  badge?: InputMaybe<Scalars['String']>;
  boosters_limit?: InputMaybe<Scalars['Int']>;
  fixed?: InputMaybe<Scalars['Boolean']>;
  highest_rtp_param?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_generated_lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_generator_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_open_max_count?: InputMaybe<Scalars['Int']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  lowest_rtp_param?: InputMaybe<Scalars['Int']>;
  max_limit?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
  min_limit?: InputMaybe<Scalars['Int']>;
  real_view?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Fixed_Items_Generator_Lootboxes_Sum_Fields = {
  __typename?: 'fixed_items_generator_lootboxes_sum_fields';
  boosters_limit?: Maybe<Scalars['Int']>;
  highest_rtp_param?: Maybe<Scalars['Int']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_open_max_count?: Maybe<Scalars['Int']>;
  lootbox_target_price?: Maybe<Scalars['Int']>;
  lowest_rtp_param?: Maybe<Scalars['Int']>;
  max_limit?: Maybe<Scalars['Int']>;
  mechanic_id?: Maybe<Scalars['Int']>;
  min_limit?: Maybe<Scalars['Int']>;
};

/** update columns of table "fixed_items_generator_lootboxes" */
export enum Fixed_Items_Generator_Lootboxes_Update_Column {
  /** column name */
  AddJackpot = 'add_jackpot',
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  Badge = 'badge',
  /** column name */
  BoostersLimit = 'boosters_limit',
  /** column name */
  Fixed = 'fixed',
  /** column name */
  HighestRtpParam = 'highest_rtp_param',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastGeneratedLootboxId = 'last_generated_lootbox_id',
  /** column name */
  LootboxCategory = 'lootbox_category',
  /** column name */
  LootboxGeneratorId = 'lootbox_generator_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxOpenMaxCount = 'lootbox_open_max_count',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxTargetPrice = 'lootbox_target_price',
  /** column name */
  LowestRtpParam = 'lowest_rtp_param',
  /** column name */
  MaxLimit = 'max_limit',
  /** column name */
  MechanicId = 'mechanic_id',
  /** column name */
  MinLimit = 'min_limit',
  /** column name */
  RealView = 'real_view'
}

export type Fixed_Items_Generator_Lootboxes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Fixed_Items_Generator_Lootboxes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fixed_Items_Generator_Lootboxes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fixed_Items_Generator_Lootboxes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fixed_Items_Generator_Lootboxes_Var_Pop_Fields = {
  __typename?: 'fixed_items_generator_lootboxes_var_pop_fields';
  boosters_limit?: Maybe<Scalars['Float']>;
  highest_rtp_param?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  lowest_rtp_param?: Maybe<Scalars['Float']>;
  max_limit?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  min_limit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Fixed_Items_Generator_Lootboxes_Var_Samp_Fields = {
  __typename?: 'fixed_items_generator_lootboxes_var_samp_fields';
  boosters_limit?: Maybe<Scalars['Float']>;
  highest_rtp_param?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  lowest_rtp_param?: Maybe<Scalars['Float']>;
  max_limit?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  min_limit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Fixed_Items_Generator_Lootboxes_Variance_Fields = {
  __typename?: 'fixed_items_generator_lootboxes_variance_fields';
  boosters_limit?: Maybe<Scalars['Float']>;
  highest_rtp_param?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  lowest_rtp_param?: Maybe<Scalars['Float']>;
  max_limit?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  min_limit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Get_Ticket_Counts_By_Type_Args = {
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "giveaway_members" */
export type Giveaway_Members = {
  __typename?: 'giveaway_members';
  avatar?: Maybe<Scalars['String']>;
  bot: Scalars['Boolean'];
  created_at: Scalars['timestamp'];
  email_verify?: Maybe<User_Contacts>;
  /** An object relationship */
  giveaway: Giveaways;
  giveaway_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  user_profile?: Maybe<User_Profile>;
};

/** aggregated selection of "giveaway_members" */
export type Giveaway_Members_Aggregate = {
  __typename?: 'giveaway_members_aggregate';
  aggregate?: Maybe<Giveaway_Members_Aggregate_Fields>;
  nodes: Array<Giveaway_Members>;
};

export type Giveaway_Members_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Giveaway_Members_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Giveaway_Members_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Giveaway_Members_Aggregate_Bool_Exp_Count>;
};

export type Giveaway_Members_Aggregate_Bool_Exp_Bool_And = {
  arguments: Giveaway_Members_Select_Column_Giveaway_Members_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaway_Members_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Giveaway_Members_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Giveaway_Members_Select_Column_Giveaway_Members_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaway_Members_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Giveaway_Members_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Giveaway_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaway_Members_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "giveaway_members" */
export type Giveaway_Members_Aggregate_Fields = {
  __typename?: 'giveaway_members_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Giveaway_Members_Max_Fields>;
  min?: Maybe<Giveaway_Members_Min_Fields>;
};


/** aggregate fields of "giveaway_members" */
export type Giveaway_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Giveaway_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "giveaway_members" */
export type Giveaway_Members_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Giveaway_Members_Max_Order_By>;
  min?: InputMaybe<Giveaway_Members_Min_Order_By>;
};

/** input type for inserting array relation for remote table "giveaway_members" */
export type Giveaway_Members_Arr_Rel_Insert_Input = {
  data: Array<Giveaway_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Giveaway_Members_On_Conflict>;
};

/** Boolean expression to filter rows from the table "giveaway_members". All fields are combined with a logical 'AND'. */
export type Giveaway_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Giveaway_Members_Bool_Exp>>;
  _not?: InputMaybe<Giveaway_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Giveaway_Members_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  bot?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  giveaway?: InputMaybe<Giveaways_Bool_Exp>;
  giveaway_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "giveaway_members" */
export enum Giveaway_Members_Constraint {
  /** unique or primary key constraint on columns "giveaway_id", "user_id" */
  GiveawayMembersPkey = 'giveaway_members_pkey'
}

/** input type for inserting data into table "giveaway_members" */
export type Giveaway_Members_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  bot?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  giveaway?: InputMaybe<Giveaways_Obj_Rel_Insert_Input>;
  giveaway_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Giveaway_Members_Max_Fields = {
  __typename?: 'giveaway_members_max_fields';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  giveaway_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "giveaway_members" */
export type Giveaway_Members_Max_Order_By = {
  avatar?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  giveaway_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Giveaway_Members_Min_Fields = {
  __typename?: 'giveaway_members_min_fields';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  giveaway_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "giveaway_members" */
export type Giveaway_Members_Min_Order_By = {
  avatar?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  giveaway_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "giveaway_members" */
export type Giveaway_Members_Mutation_Response = {
  __typename?: 'giveaway_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Giveaway_Members>;
};

/** on_conflict condition type for table "giveaway_members" */
export type Giveaway_Members_On_Conflict = {
  constraint: Giveaway_Members_Constraint;
  update_columns?: Array<Giveaway_Members_Update_Column>;
  where?: InputMaybe<Giveaway_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "giveaway_members". */
export type Giveaway_Members_Order_By = {
  avatar?: InputMaybe<Order_By>;
  bot?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  giveaway?: InputMaybe<Giveaways_Order_By>;
  giveaway_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: giveaway_members */
export type Giveaway_Members_Pk_Columns_Input = {
  giveaway_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** columns and relationships of "giveaway_members_rating" */
export type Giveaway_Members_Rating = {
  __typename?: 'giveaway_members_rating';
  participation_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_profile?: Maybe<User_Profile>;
  /** An array relationship */
  winner: Array<Giveaways>;
  /** An aggregate relationship */
  winner_aggregate: Giveaways_Aggregate;
  won_count?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "giveaway_members_rating" */
export type Giveaway_Members_RatingWinnerArgs = {
  distinct_on?: InputMaybe<Array<Giveaways_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaways_Order_By>>;
  where?: InputMaybe<Giveaways_Bool_Exp>;
};


/** columns and relationships of "giveaway_members_rating" */
export type Giveaway_Members_RatingWinner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Giveaways_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaways_Order_By>>;
  where?: InputMaybe<Giveaways_Bool_Exp>;
};

/** aggregated selection of "giveaway_members_rating" */
export type Giveaway_Members_Rating_Aggregate = {
  __typename?: 'giveaway_members_rating_aggregate';
  aggregate?: Maybe<Giveaway_Members_Rating_Aggregate_Fields>;
  nodes: Array<Giveaway_Members_Rating>;
};

/** aggregate fields of "giveaway_members_rating" */
export type Giveaway_Members_Rating_Aggregate_Fields = {
  __typename?: 'giveaway_members_rating_aggregate_fields';
  avg?: Maybe<Giveaway_Members_Rating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Giveaway_Members_Rating_Max_Fields>;
  min?: Maybe<Giveaway_Members_Rating_Min_Fields>;
  stddev?: Maybe<Giveaway_Members_Rating_Stddev_Fields>;
  stddev_pop?: Maybe<Giveaway_Members_Rating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Giveaway_Members_Rating_Stddev_Samp_Fields>;
  sum?: Maybe<Giveaway_Members_Rating_Sum_Fields>;
  var_pop?: Maybe<Giveaway_Members_Rating_Var_Pop_Fields>;
  var_samp?: Maybe<Giveaway_Members_Rating_Var_Samp_Fields>;
  variance?: Maybe<Giveaway_Members_Rating_Variance_Fields>;
};


/** aggregate fields of "giveaway_members_rating" */
export type Giveaway_Members_Rating_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Giveaway_Members_Rating_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Giveaway_Members_Rating_Avg_Fields = {
  __typename?: 'giveaway_members_rating_avg_fields';
  participation_count?: Maybe<Scalars['Float']>;
  won_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "giveaway_members_rating". All fields are combined with a logical 'AND'. */
export type Giveaway_Members_Rating_Bool_Exp = {
  _and?: InputMaybe<Array<Giveaway_Members_Rating_Bool_Exp>>;
  _not?: InputMaybe<Giveaway_Members_Rating_Bool_Exp>;
  _or?: InputMaybe<Array<Giveaway_Members_Rating_Bool_Exp>>;
  participation_count?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  winner?: InputMaybe<Giveaways_Bool_Exp>;
  winner_aggregate?: InputMaybe<Giveaways_Aggregate_Bool_Exp>;
  won_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "giveaway_members_rating" */
export type Giveaway_Members_Rating_Insert_Input = {
  participation_count?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  winner?: InputMaybe<Giveaways_Arr_Rel_Insert_Input>;
  won_count?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Giveaway_Members_Rating_Max_Fields = {
  __typename?: 'giveaway_members_rating_max_fields';
  participation_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  won_count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Giveaway_Members_Rating_Min_Fields = {
  __typename?: 'giveaway_members_rating_min_fields';
  participation_count?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
  won_count?: Maybe<Scalars['bigint']>;
};

/** input type for inserting object relation for remote table "giveaway_members_rating" */
export type Giveaway_Members_Rating_Obj_Rel_Insert_Input = {
  data: Giveaway_Members_Rating_Insert_Input;
};

/** Ordering options when selecting data from "giveaway_members_rating". */
export type Giveaway_Members_Rating_Order_By = {
  participation_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  winner_aggregate?: InputMaybe<Giveaways_Aggregate_Order_By>;
  won_count?: InputMaybe<Order_By>;
};

/** select columns of table "giveaway_members_rating" */
export enum Giveaway_Members_Rating_Select_Column {
  /** column name */
  ParticipationCount = 'participation_count',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WonCount = 'won_count'
}

/** aggregate stddev on columns */
export type Giveaway_Members_Rating_Stddev_Fields = {
  __typename?: 'giveaway_members_rating_stddev_fields';
  participation_count?: Maybe<Scalars['Float']>;
  won_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Giveaway_Members_Rating_Stddev_Pop_Fields = {
  __typename?: 'giveaway_members_rating_stddev_pop_fields';
  participation_count?: Maybe<Scalars['Float']>;
  won_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Giveaway_Members_Rating_Stddev_Samp_Fields = {
  __typename?: 'giveaway_members_rating_stddev_samp_fields';
  participation_count?: Maybe<Scalars['Float']>;
  won_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "giveaway_members_rating" */
export type Giveaway_Members_Rating_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Giveaway_Members_Rating_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Giveaway_Members_Rating_Stream_Cursor_Value_Input = {
  participation_count?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  won_count?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Giveaway_Members_Rating_Sum_Fields = {
  __typename?: 'giveaway_members_rating_sum_fields';
  participation_count?: Maybe<Scalars['bigint']>;
  won_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Giveaway_Members_Rating_Var_Pop_Fields = {
  __typename?: 'giveaway_members_rating_var_pop_fields';
  participation_count?: Maybe<Scalars['Float']>;
  won_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Giveaway_Members_Rating_Var_Samp_Fields = {
  __typename?: 'giveaway_members_rating_var_samp_fields';
  participation_count?: Maybe<Scalars['Float']>;
  won_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Giveaway_Members_Rating_Variance_Fields = {
  __typename?: 'giveaway_members_rating_variance_fields';
  participation_count?: Maybe<Scalars['Float']>;
  won_count?: Maybe<Scalars['Float']>;
};

/** select columns of table "giveaway_members" */
export enum Giveaway_Members_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Bot = 'bot',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GiveawayId = 'giveaway_id',
  /** column name */
  UserId = 'user_id'
}

/** select "giveaway_members_aggregate_bool_exp_bool_and_arguments_columns" columns of table "giveaway_members" */
export enum Giveaway_Members_Select_Column_Giveaway_Members_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Bot = 'bot'
}

/** select "giveaway_members_aggregate_bool_exp_bool_or_arguments_columns" columns of table "giveaway_members" */
export enum Giveaway_Members_Select_Column_Giveaway_Members_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Bot = 'bot'
}

/** input type for updating data in table "giveaway_members" */
export type Giveaway_Members_Set_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  bot?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  giveaway_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "giveaway_members" */
export type Giveaway_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Giveaway_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Giveaway_Members_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  bot?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  giveaway_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "giveaway_members" */
export enum Giveaway_Members_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Bot = 'bot',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GiveawayId = 'giveaway_id',
  /** column name */
  UserId = 'user_id'
}

export type Giveaway_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Giveaway_Members_Set_Input>;
  /** filter the rows which have to be updated */
  where: Giveaway_Members_Bool_Exp;
};

/** columns and relationships of "giveaway_params" */
export type Giveaway_Params = {
  __typename?: 'giveaway_params';
  /** An object relationship */
  giveaway: Giveaways;
  giveaway_id: Scalars['uuid'];
  limit_users?: Maybe<Scalars['Int']>;
  minimal_bots?: Maybe<Scalars['Int']>;
  minimal_deposit?: Maybe<Scalars['Int']>;
  minimal_users?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "giveaway_params" */
export type Giveaway_Params_Aggregate = {
  __typename?: 'giveaway_params_aggregate';
  aggregate?: Maybe<Giveaway_Params_Aggregate_Fields>;
  nodes: Array<Giveaway_Params>;
};

export type Giveaway_Params_Aggregate_Bool_Exp = {
  count?: InputMaybe<Giveaway_Params_Aggregate_Bool_Exp_Count>;
};

export type Giveaway_Params_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Giveaway_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaway_Params_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "giveaway_params" */
export type Giveaway_Params_Aggregate_Fields = {
  __typename?: 'giveaway_params_aggregate_fields';
  avg?: Maybe<Giveaway_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Giveaway_Params_Max_Fields>;
  min?: Maybe<Giveaway_Params_Min_Fields>;
  stddev?: Maybe<Giveaway_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Giveaway_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Giveaway_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Giveaway_Params_Sum_Fields>;
  var_pop?: Maybe<Giveaway_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Giveaway_Params_Var_Samp_Fields>;
  variance?: Maybe<Giveaway_Params_Variance_Fields>;
};


/** aggregate fields of "giveaway_params" */
export type Giveaway_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Giveaway_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "giveaway_params" */
export type Giveaway_Params_Aggregate_Order_By = {
  avg?: InputMaybe<Giveaway_Params_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Giveaway_Params_Max_Order_By>;
  min?: InputMaybe<Giveaway_Params_Min_Order_By>;
  stddev?: InputMaybe<Giveaway_Params_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Giveaway_Params_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Giveaway_Params_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Giveaway_Params_Sum_Order_By>;
  var_pop?: InputMaybe<Giveaway_Params_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Giveaway_Params_Var_Samp_Order_By>;
  variance?: InputMaybe<Giveaway_Params_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "giveaway_params" */
export type Giveaway_Params_Arr_Rel_Insert_Input = {
  data: Array<Giveaway_Params_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Giveaway_Params_On_Conflict>;
};

/** aggregate avg on columns */
export type Giveaway_Params_Avg_Fields = {
  __typename?: 'giveaway_params_avg_fields';
  limit_users?: Maybe<Scalars['Float']>;
  minimal_bots?: Maybe<Scalars['Float']>;
  minimal_deposit?: Maybe<Scalars['Float']>;
  minimal_users?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "giveaway_params" */
export type Giveaway_Params_Avg_Order_By = {
  limit_users?: InputMaybe<Order_By>;
  minimal_bots?: InputMaybe<Order_By>;
  minimal_deposit?: InputMaybe<Order_By>;
  minimal_users?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "giveaway_params". All fields are combined with a logical 'AND'. */
export type Giveaway_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Giveaway_Params_Bool_Exp>>;
  _not?: InputMaybe<Giveaway_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Giveaway_Params_Bool_Exp>>;
  giveaway?: InputMaybe<Giveaways_Bool_Exp>;
  giveaway_id?: InputMaybe<Uuid_Comparison_Exp>;
  limit_users?: InputMaybe<Int_Comparison_Exp>;
  minimal_bots?: InputMaybe<Int_Comparison_Exp>;
  minimal_deposit?: InputMaybe<Int_Comparison_Exp>;
  minimal_users?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "giveaway_params" */
export enum Giveaway_Params_Constraint {
  /** unique or primary key constraint on columns "giveaway_id" */
  GiveawayParamsPkey = 'giveaway_params_pkey'
}

/** input type for incrementing numeric columns in table "giveaway_params" */
export type Giveaway_Params_Inc_Input = {
  limit_users?: InputMaybe<Scalars['Int']>;
  minimal_bots?: InputMaybe<Scalars['Int']>;
  minimal_deposit?: InputMaybe<Scalars['Int']>;
  minimal_users?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "giveaway_params" */
export type Giveaway_Params_Insert_Input = {
  giveaway?: InputMaybe<Giveaways_Obj_Rel_Insert_Input>;
  giveaway_id?: InputMaybe<Scalars['uuid']>;
  limit_users?: InputMaybe<Scalars['Int']>;
  minimal_bots?: InputMaybe<Scalars['Int']>;
  minimal_deposit?: InputMaybe<Scalars['Int']>;
  minimal_users?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Giveaway_Params_Max_Fields = {
  __typename?: 'giveaway_params_max_fields';
  giveaway_id?: Maybe<Scalars['uuid']>;
  limit_users?: Maybe<Scalars['Int']>;
  minimal_bots?: Maybe<Scalars['Int']>;
  minimal_deposit?: Maybe<Scalars['Int']>;
  minimal_users?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "giveaway_params" */
export type Giveaway_Params_Max_Order_By = {
  giveaway_id?: InputMaybe<Order_By>;
  limit_users?: InputMaybe<Order_By>;
  minimal_bots?: InputMaybe<Order_By>;
  minimal_deposit?: InputMaybe<Order_By>;
  minimal_users?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Giveaway_Params_Min_Fields = {
  __typename?: 'giveaway_params_min_fields';
  giveaway_id?: Maybe<Scalars['uuid']>;
  limit_users?: Maybe<Scalars['Int']>;
  minimal_bots?: Maybe<Scalars['Int']>;
  minimal_deposit?: Maybe<Scalars['Int']>;
  minimal_users?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "giveaway_params" */
export type Giveaway_Params_Min_Order_By = {
  giveaway_id?: InputMaybe<Order_By>;
  limit_users?: InputMaybe<Order_By>;
  minimal_bots?: InputMaybe<Order_By>;
  minimal_deposit?: InputMaybe<Order_By>;
  minimal_users?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "giveaway_params" */
export type Giveaway_Params_Mutation_Response = {
  __typename?: 'giveaway_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Giveaway_Params>;
};

/** on_conflict condition type for table "giveaway_params" */
export type Giveaway_Params_On_Conflict = {
  constraint: Giveaway_Params_Constraint;
  update_columns?: Array<Giveaway_Params_Update_Column>;
  where?: InputMaybe<Giveaway_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "giveaway_params". */
export type Giveaway_Params_Order_By = {
  giveaway?: InputMaybe<Giveaways_Order_By>;
  giveaway_id?: InputMaybe<Order_By>;
  limit_users?: InputMaybe<Order_By>;
  minimal_bots?: InputMaybe<Order_By>;
  minimal_deposit?: InputMaybe<Order_By>;
  minimal_users?: InputMaybe<Order_By>;
};

/** primary key columns input for table: giveaway_params */
export type Giveaway_Params_Pk_Columns_Input = {
  giveaway_id: Scalars['uuid'];
};

/** select columns of table "giveaway_params" */
export enum Giveaway_Params_Select_Column {
  /** column name */
  GiveawayId = 'giveaway_id',
  /** column name */
  LimitUsers = 'limit_users',
  /** column name */
  MinimalBots = 'minimal_bots',
  /** column name */
  MinimalDeposit = 'minimal_deposit',
  /** column name */
  MinimalUsers = 'minimal_users'
}

/** input type for updating data in table "giveaway_params" */
export type Giveaway_Params_Set_Input = {
  giveaway_id?: InputMaybe<Scalars['uuid']>;
  limit_users?: InputMaybe<Scalars['Int']>;
  minimal_bots?: InputMaybe<Scalars['Int']>;
  minimal_deposit?: InputMaybe<Scalars['Int']>;
  minimal_users?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Giveaway_Params_Stddev_Fields = {
  __typename?: 'giveaway_params_stddev_fields';
  limit_users?: Maybe<Scalars['Float']>;
  minimal_bots?: Maybe<Scalars['Float']>;
  minimal_deposit?: Maybe<Scalars['Float']>;
  minimal_users?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "giveaway_params" */
export type Giveaway_Params_Stddev_Order_By = {
  limit_users?: InputMaybe<Order_By>;
  minimal_bots?: InputMaybe<Order_By>;
  minimal_deposit?: InputMaybe<Order_By>;
  minimal_users?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Giveaway_Params_Stddev_Pop_Fields = {
  __typename?: 'giveaway_params_stddev_pop_fields';
  limit_users?: Maybe<Scalars['Float']>;
  minimal_bots?: Maybe<Scalars['Float']>;
  minimal_deposit?: Maybe<Scalars['Float']>;
  minimal_users?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "giveaway_params" */
export type Giveaway_Params_Stddev_Pop_Order_By = {
  limit_users?: InputMaybe<Order_By>;
  minimal_bots?: InputMaybe<Order_By>;
  minimal_deposit?: InputMaybe<Order_By>;
  minimal_users?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Giveaway_Params_Stddev_Samp_Fields = {
  __typename?: 'giveaway_params_stddev_samp_fields';
  limit_users?: Maybe<Scalars['Float']>;
  minimal_bots?: Maybe<Scalars['Float']>;
  minimal_deposit?: Maybe<Scalars['Float']>;
  minimal_users?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "giveaway_params" */
export type Giveaway_Params_Stddev_Samp_Order_By = {
  limit_users?: InputMaybe<Order_By>;
  minimal_bots?: InputMaybe<Order_By>;
  minimal_deposit?: InputMaybe<Order_By>;
  minimal_users?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "giveaway_params" */
export type Giveaway_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Giveaway_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Giveaway_Params_Stream_Cursor_Value_Input = {
  giveaway_id?: InputMaybe<Scalars['uuid']>;
  limit_users?: InputMaybe<Scalars['Int']>;
  minimal_bots?: InputMaybe<Scalars['Int']>;
  minimal_deposit?: InputMaybe<Scalars['Int']>;
  minimal_users?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Giveaway_Params_Sum_Fields = {
  __typename?: 'giveaway_params_sum_fields';
  limit_users?: Maybe<Scalars['Int']>;
  minimal_bots?: Maybe<Scalars['Int']>;
  minimal_deposit?: Maybe<Scalars['Int']>;
  minimal_users?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "giveaway_params" */
export type Giveaway_Params_Sum_Order_By = {
  limit_users?: InputMaybe<Order_By>;
  minimal_bots?: InputMaybe<Order_By>;
  minimal_deposit?: InputMaybe<Order_By>;
  minimal_users?: InputMaybe<Order_By>;
};

/** update columns of table "giveaway_params" */
export enum Giveaway_Params_Update_Column {
  /** column name */
  GiveawayId = 'giveaway_id',
  /** column name */
  LimitUsers = 'limit_users',
  /** column name */
  MinimalBots = 'minimal_bots',
  /** column name */
  MinimalDeposit = 'minimal_deposit',
  /** column name */
  MinimalUsers = 'minimal_users'
}

export type Giveaway_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Giveaway_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Giveaway_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Giveaway_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Giveaway_Params_Var_Pop_Fields = {
  __typename?: 'giveaway_params_var_pop_fields';
  limit_users?: Maybe<Scalars['Float']>;
  minimal_bots?: Maybe<Scalars['Float']>;
  minimal_deposit?: Maybe<Scalars['Float']>;
  minimal_users?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "giveaway_params" */
export type Giveaway_Params_Var_Pop_Order_By = {
  limit_users?: InputMaybe<Order_By>;
  minimal_bots?: InputMaybe<Order_By>;
  minimal_deposit?: InputMaybe<Order_By>;
  minimal_users?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Giveaway_Params_Var_Samp_Fields = {
  __typename?: 'giveaway_params_var_samp_fields';
  limit_users?: Maybe<Scalars['Float']>;
  minimal_bots?: Maybe<Scalars['Float']>;
  minimal_deposit?: Maybe<Scalars['Float']>;
  minimal_users?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "giveaway_params" */
export type Giveaway_Params_Var_Samp_Order_By = {
  limit_users?: InputMaybe<Order_By>;
  minimal_bots?: InputMaybe<Order_By>;
  minimal_deposit?: InputMaybe<Order_By>;
  minimal_users?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Giveaway_Params_Variance_Fields = {
  __typename?: 'giveaway_params_variance_fields';
  limit_users?: Maybe<Scalars['Float']>;
  minimal_bots?: Maybe<Scalars['Float']>;
  minimal_deposit?: Maybe<Scalars['Float']>;
  minimal_users?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "giveaway_params" */
export type Giveaway_Params_Variance_Order_By = {
  limit_users?: InputMaybe<Order_By>;
  minimal_bots?: InputMaybe<Order_By>;
  minimal_deposit?: InputMaybe<Order_By>;
  minimal_users?: InputMaybe<Order_By>;
};

/** columns and relationships of "giveaways" */
export type Giveaways = {
  __typename?: 'giveaways';
  active: Scalars['Boolean'];
  end_date: Scalars['timestamp'];
  fixed_price?: Maybe<Scalars['float8']>;
  giveaway_id: Scalars['uuid'];
  /** An array relationship */
  giveaway_members: Array<Giveaway_Members>;
  /** An aggregate relationship */
  giveaway_members_aggregate: Giveaway_Members_Aggregate;
  /** An array relationship */
  giveaway_params: Array<Giveaway_Params>;
  /** An aggregate relationship */
  giveaway_params_aggregate: Giveaway_Params_Aggregate;
  item_claimed?: Maybe<Scalars['Boolean']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id: Scalars['uuid'];
  item_image_link?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  /** An object relationship */
  members_rating?: Maybe<Giveaway_Members_Rating>;
  start_date: Scalars['timestamp'];
  type?: Maybe<Scalars['String']>;
  user_profile?: Maybe<User_Profile>;
  winner_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "giveaways" */
export type GiveawaysGiveaway_MembersArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Members_Order_By>>;
  where?: InputMaybe<Giveaway_Members_Bool_Exp>;
};


/** columns and relationships of "giveaways" */
export type GiveawaysGiveaway_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Members_Order_By>>;
  where?: InputMaybe<Giveaway_Members_Bool_Exp>;
};


/** columns and relationships of "giveaways" */
export type GiveawaysGiveaway_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Params_Order_By>>;
  where?: InputMaybe<Giveaway_Params_Bool_Exp>;
};


/** columns and relationships of "giveaways" */
export type GiveawaysGiveaway_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Params_Order_By>>;
  where?: InputMaybe<Giveaway_Params_Bool_Exp>;
};

/** aggregated selection of "giveaways" */
export type Giveaways_Aggregate = {
  __typename?: 'giveaways_aggregate';
  aggregate?: Maybe<Giveaways_Aggregate_Fields>;
  nodes: Array<Giveaways>;
};

export type Giveaways_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Giveaways_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Giveaways_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Giveaways_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Giveaways_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Giveaways_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Giveaways_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Giveaways_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Giveaways_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Giveaways_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Giveaways_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Giveaways_Aggregate_Bool_Exp_Var_Samp>;
};

export type Giveaways_Aggregate_Bool_Exp_Avg = {
  arguments: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaways_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Giveaways_Aggregate_Bool_Exp_Bool_And = {
  arguments: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaways_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Giveaways_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaways_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Giveaways_Aggregate_Bool_Exp_Corr = {
  arguments: Giveaways_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaways_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Giveaways_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Giveaways_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Giveaways_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaways_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Giveaways_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Giveaways_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaways_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Giveaways_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Giveaways_Aggregate_Bool_Exp_Max = {
  arguments: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaways_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Giveaways_Aggregate_Bool_Exp_Min = {
  arguments: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaways_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Giveaways_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaways_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Giveaways_Aggregate_Bool_Exp_Sum = {
  arguments: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaways_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Giveaways_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Giveaways_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "giveaways" */
export type Giveaways_Aggregate_Fields = {
  __typename?: 'giveaways_aggregate_fields';
  avg?: Maybe<Giveaways_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Giveaways_Max_Fields>;
  min?: Maybe<Giveaways_Min_Fields>;
  stddev?: Maybe<Giveaways_Stddev_Fields>;
  stddev_pop?: Maybe<Giveaways_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Giveaways_Stddev_Samp_Fields>;
  sum?: Maybe<Giveaways_Sum_Fields>;
  var_pop?: Maybe<Giveaways_Var_Pop_Fields>;
  var_samp?: Maybe<Giveaways_Var_Samp_Fields>;
  variance?: Maybe<Giveaways_Variance_Fields>;
};


/** aggregate fields of "giveaways" */
export type Giveaways_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Giveaways_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "giveaways" */
export type Giveaways_Aggregate_Order_By = {
  avg?: InputMaybe<Giveaways_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Giveaways_Max_Order_By>;
  min?: InputMaybe<Giveaways_Min_Order_By>;
  stddev?: InputMaybe<Giveaways_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Giveaways_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Giveaways_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Giveaways_Sum_Order_By>;
  var_pop?: InputMaybe<Giveaways_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Giveaways_Var_Samp_Order_By>;
  variance?: InputMaybe<Giveaways_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "giveaways" */
export type Giveaways_Arr_Rel_Insert_Input = {
  data: Array<Giveaways_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Giveaways_On_Conflict>;
};

/** aggregate avg on columns */
export type Giveaways_Avg_Fields = {
  __typename?: 'giveaways_avg_fields';
  fixed_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "giveaways" */
export type Giveaways_Avg_Order_By = {
  fixed_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "giveaways". All fields are combined with a logical 'AND'. */
export type Giveaways_Bool_Exp = {
  _and?: InputMaybe<Array<Giveaways_Bool_Exp>>;
  _not?: InputMaybe<Giveaways_Bool_Exp>;
  _or?: InputMaybe<Array<Giveaways_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  end_date?: InputMaybe<Timestamp_Comparison_Exp>;
  fixed_price?: InputMaybe<Float8_Comparison_Exp>;
  giveaway_id?: InputMaybe<Uuid_Comparison_Exp>;
  giveaway_members?: InputMaybe<Giveaway_Members_Bool_Exp>;
  giveaway_members_aggregate?: InputMaybe<Giveaway_Members_Aggregate_Bool_Exp>;
  giveaway_params?: InputMaybe<Giveaway_Params_Bool_Exp>;
  giveaway_params_aggregate?: InputMaybe<Giveaway_Params_Aggregate_Bool_Exp>;
  item_claimed?: InputMaybe<Boolean_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_image_link?: InputMaybe<String_Comparison_Exp>;
  item_quality?: InputMaybe<String_Comparison_Exp>;
  item_rarity?: InputMaybe<String_Comparison_Exp>;
  members_rating?: InputMaybe<Giveaway_Members_Rating_Bool_Exp>;
  start_date?: InputMaybe<Timestamp_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  winner_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "giveaways" */
export enum Giveaways_Constraint {
  /** unique or primary key constraint on columns "giveaway_id" */
  GiveawaysPkey = 'giveaways_pkey'
}

/** input type for incrementing numeric columns in table "giveaways" */
export type Giveaways_Inc_Input = {
  fixed_price?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "giveaways" */
export type Giveaways_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  end_date?: InputMaybe<Scalars['timestamp']>;
  fixed_price?: InputMaybe<Scalars['float8']>;
  giveaway_id?: InputMaybe<Scalars['uuid']>;
  giveaway_members?: InputMaybe<Giveaway_Members_Arr_Rel_Insert_Input>;
  giveaway_params?: InputMaybe<Giveaway_Params_Arr_Rel_Insert_Input>;
  item_claimed?: InputMaybe<Scalars['Boolean']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  members_rating?: InputMaybe<Giveaway_Members_Rating_Obj_Rel_Insert_Input>;
  start_date?: InputMaybe<Scalars['timestamp']>;
  type?: InputMaybe<Scalars['String']>;
  winner_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Giveaways_Max_Fields = {
  __typename?: 'giveaways_max_fields';
  end_date?: Maybe<Scalars['timestamp']>;
  fixed_price?: Maybe<Scalars['float8']>;
  giveaway_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamp']>;
  type?: Maybe<Scalars['String']>;
  winner_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "giveaways" */
export type Giveaways_Max_Order_By = {
  end_date?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  giveaway_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  winner_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Giveaways_Min_Fields = {
  __typename?: 'giveaways_min_fields';
  end_date?: Maybe<Scalars['timestamp']>;
  fixed_price?: Maybe<Scalars['float8']>;
  giveaway_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamp']>;
  type?: Maybe<Scalars['String']>;
  winner_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "giveaways" */
export type Giveaways_Min_Order_By = {
  end_date?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  giveaway_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  winner_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "giveaways" */
export type Giveaways_Mutation_Response = {
  __typename?: 'giveaways_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Giveaways>;
};

/** input type for inserting object relation for remote table "giveaways" */
export type Giveaways_Obj_Rel_Insert_Input = {
  data: Giveaways_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Giveaways_On_Conflict>;
};

/** on_conflict condition type for table "giveaways" */
export type Giveaways_On_Conflict = {
  constraint: Giveaways_Constraint;
  update_columns?: Array<Giveaways_Update_Column>;
  where?: InputMaybe<Giveaways_Bool_Exp>;
};

/** Ordering options when selecting data from "giveaways". */
export type Giveaways_Order_By = {
  active?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  giveaway_id?: InputMaybe<Order_By>;
  giveaway_members_aggregate?: InputMaybe<Giveaway_Members_Aggregate_Order_By>;
  giveaway_params_aggregate?: InputMaybe<Giveaway_Params_Aggregate_Order_By>;
  item_claimed?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  members_rating?: InputMaybe<Giveaway_Members_Rating_Order_By>;
  start_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  winner_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: giveaways */
export type Giveaways_Pk_Columns_Input = {
  giveaway_id: Scalars['uuid'];
};

/** select columns of table "giveaways" */
export enum Giveaways_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FixedPrice = 'fixed_price',
  /** column name */
  GiveawayId = 'giveaway_id',
  /** column name */
  ItemClaimed = 'item_claimed',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Type = 'type',
  /** column name */
  WinnerId = 'winner_id'
}

/** select "giveaways_aggregate_bool_exp_avg_arguments_columns" columns of table "giveaways" */
export enum Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  FixedPrice = 'fixed_price'
}

/** select "giveaways_aggregate_bool_exp_bool_and_arguments_columns" columns of table "giveaways" */
export enum Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  ItemClaimed = 'item_claimed'
}

/** select "giveaways_aggregate_bool_exp_bool_or_arguments_columns" columns of table "giveaways" */
export enum Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  ItemClaimed = 'item_claimed'
}

/** select "giveaways_aggregate_bool_exp_corr_arguments_columns" columns of table "giveaways" */
export enum Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  FixedPrice = 'fixed_price'
}

/** select "giveaways_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "giveaways" */
export enum Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  FixedPrice = 'fixed_price'
}

/** select "giveaways_aggregate_bool_exp_max_arguments_columns" columns of table "giveaways" */
export enum Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  FixedPrice = 'fixed_price'
}

/** select "giveaways_aggregate_bool_exp_min_arguments_columns" columns of table "giveaways" */
export enum Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  FixedPrice = 'fixed_price'
}

/** select "giveaways_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "giveaways" */
export enum Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  FixedPrice = 'fixed_price'
}

/** select "giveaways_aggregate_bool_exp_sum_arguments_columns" columns of table "giveaways" */
export enum Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  FixedPrice = 'fixed_price'
}

/** select "giveaways_aggregate_bool_exp_var_samp_arguments_columns" columns of table "giveaways" */
export enum Giveaways_Select_Column_Giveaways_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  FixedPrice = 'fixed_price'
}

/** input type for updating data in table "giveaways" */
export type Giveaways_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  end_date?: InputMaybe<Scalars['timestamp']>;
  fixed_price?: InputMaybe<Scalars['float8']>;
  giveaway_id?: InputMaybe<Scalars['uuid']>;
  item_claimed?: InputMaybe<Scalars['Boolean']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['timestamp']>;
  type?: InputMaybe<Scalars['String']>;
  winner_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Giveaways_Stddev_Fields = {
  __typename?: 'giveaways_stddev_fields';
  fixed_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "giveaways" */
export type Giveaways_Stddev_Order_By = {
  fixed_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Giveaways_Stddev_Pop_Fields = {
  __typename?: 'giveaways_stddev_pop_fields';
  fixed_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "giveaways" */
export type Giveaways_Stddev_Pop_Order_By = {
  fixed_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Giveaways_Stddev_Samp_Fields = {
  __typename?: 'giveaways_stddev_samp_fields';
  fixed_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "giveaways" */
export type Giveaways_Stddev_Samp_Order_By = {
  fixed_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "giveaways" */
export type Giveaways_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Giveaways_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Giveaways_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  end_date?: InputMaybe<Scalars['timestamp']>;
  fixed_price?: InputMaybe<Scalars['float8']>;
  giveaway_id?: InputMaybe<Scalars['uuid']>;
  item_claimed?: InputMaybe<Scalars['Boolean']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['timestamp']>;
  type?: InputMaybe<Scalars['String']>;
  winner_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Giveaways_Sum_Fields = {
  __typename?: 'giveaways_sum_fields';
  fixed_price?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "giveaways" */
export type Giveaways_Sum_Order_By = {
  fixed_price?: InputMaybe<Order_By>;
};

/** update columns of table "giveaways" */
export enum Giveaways_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FixedPrice = 'fixed_price',
  /** column name */
  GiveawayId = 'giveaway_id',
  /** column name */
  ItemClaimed = 'item_claimed',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Type = 'type',
  /** column name */
  WinnerId = 'winner_id'
}

export type Giveaways_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Giveaways_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Giveaways_Set_Input>;
  /** filter the rows which have to be updated */
  where: Giveaways_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Giveaways_Var_Pop_Fields = {
  __typename?: 'giveaways_var_pop_fields';
  fixed_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "giveaways" */
export type Giveaways_Var_Pop_Order_By = {
  fixed_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Giveaways_Var_Samp_Fields = {
  __typename?: 'giveaways_var_samp_fields';
  fixed_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "giveaways" */
export type Giveaways_Var_Samp_Order_By = {
  fixed_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Giveaways_Variance_Fields = {
  __typename?: 'giveaways_variance_fields';
  fixed_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "giveaways" */
export type Giveaways_Variance_Order_By = {
  fixed_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "group_items_csgomarket" */
export type Group_Items_Csgomarket = {
  __typename?: 'group_items_csgomarket';
  collection?: Maybe<Scalars['String']>;
  /** An array relationship */
  history_group_items_csgomarkets: Array<History_Group_Items_Csgomarket>;
  /** An aggregate relationship */
  history_group_items_csgomarkets_aggregate: History_Group_Items_Csgomarket_Aggregate;
  id: Scalars['uuid'];
  item_type?: Maybe<Scalars['String']>;
  /** An array relationship */
  items_csgomarkets: Array<Items_Csgomarket>;
  /** An aggregate relationship */
  items_csgomarkets_aggregate: Items_Csgomarket_Aggregate;
  /** An object relationship */
  items_type?: Maybe<Items_Type>;
  /** An array relationship */
  market_groups_conns: Array<Market_Groups_Conn>;
  /** An aggregate relationship */
  market_groups_conns_aggregate: Market_Groups_Conn_Aggregate;
  market_hash_name: Scalars['String'];
};


/** columns and relationships of "group_items_csgomarket" */
export type Group_Items_CsgomarketHistory_Group_Items_CsgomarketsArgs = {
  distinct_on?: InputMaybe<Array<History_Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Group_Items_Csgomarket_Bool_Exp>;
};


/** columns and relationships of "group_items_csgomarket" */
export type Group_Items_CsgomarketHistory_Group_Items_Csgomarkets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<History_Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Group_Items_Csgomarket_Bool_Exp>;
};


/** columns and relationships of "group_items_csgomarket" */
export type Group_Items_CsgomarketItems_CsgomarketsArgs = {
  distinct_on?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};


/** columns and relationships of "group_items_csgomarket" */
export type Group_Items_CsgomarketItems_Csgomarkets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};


/** columns and relationships of "group_items_csgomarket" */
export type Group_Items_CsgomarketMarket_Groups_ConnsArgs = {
  distinct_on?: InputMaybe<Array<Market_Groups_Conn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Market_Groups_Conn_Order_By>>;
  where?: InputMaybe<Market_Groups_Conn_Bool_Exp>;
};


/** columns and relationships of "group_items_csgomarket" */
export type Group_Items_CsgomarketMarket_Groups_Conns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Market_Groups_Conn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Market_Groups_Conn_Order_By>>;
  where?: InputMaybe<Market_Groups_Conn_Bool_Exp>;
};

/** aggregated selection of "group_items_csgomarket" */
export type Group_Items_Csgomarket_Aggregate = {
  __typename?: 'group_items_csgomarket_aggregate';
  aggregate?: Maybe<Group_Items_Csgomarket_Aggregate_Fields>;
  nodes: Array<Group_Items_Csgomarket>;
};

export type Group_Items_Csgomarket_Aggregate_Bool_Exp = {
  count?: InputMaybe<Group_Items_Csgomarket_Aggregate_Bool_Exp_Count>;
};

export type Group_Items_Csgomarket_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Group_Items_Csgomarket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "group_items_csgomarket" */
export type Group_Items_Csgomarket_Aggregate_Fields = {
  __typename?: 'group_items_csgomarket_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Group_Items_Csgomarket_Max_Fields>;
  min?: Maybe<Group_Items_Csgomarket_Min_Fields>;
};


/** aggregate fields of "group_items_csgomarket" */
export type Group_Items_Csgomarket_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Group_Items_Csgomarket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "group_items_csgomarket" */
export type Group_Items_Csgomarket_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Group_Items_Csgomarket_Max_Order_By>;
  min?: InputMaybe<Group_Items_Csgomarket_Min_Order_By>;
};

/** input type for inserting array relation for remote table "group_items_csgomarket" */
export type Group_Items_Csgomarket_Arr_Rel_Insert_Input = {
  data: Array<Group_Items_Csgomarket_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Group_Items_Csgomarket_On_Conflict>;
};

/** Boolean expression to filter rows from the table "group_items_csgomarket". All fields are combined with a logical 'AND'. */
export type Group_Items_Csgomarket_Bool_Exp = {
  _and?: InputMaybe<Array<Group_Items_Csgomarket_Bool_Exp>>;
  _not?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
  _or?: InputMaybe<Array<Group_Items_Csgomarket_Bool_Exp>>;
  collection?: InputMaybe<String_Comparison_Exp>;
  history_group_items_csgomarkets?: InputMaybe<History_Group_Items_Csgomarket_Bool_Exp>;
  history_group_items_csgomarkets_aggregate?: InputMaybe<History_Group_Items_Csgomarket_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item_type?: InputMaybe<String_Comparison_Exp>;
  items_csgomarkets?: InputMaybe<Items_Csgomarket_Bool_Exp>;
  items_csgomarkets_aggregate?: InputMaybe<Items_Csgomarket_Aggregate_Bool_Exp>;
  items_type?: InputMaybe<Items_Type_Bool_Exp>;
  market_groups_conns?: InputMaybe<Market_Groups_Conn_Bool_Exp>;
  market_groups_conns_aggregate?: InputMaybe<Market_Groups_Conn_Aggregate_Bool_Exp>;
  market_hash_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_items_csgomarket" */
export enum Group_Items_Csgomarket_Constraint {
  /** unique or primary key constraint on columns "market_hash_name" */
  GroupItemsCsgomarketMarketHashNameKey = 'group_items_csgomarket_market_hash_name_key',
  /** unique or primary key constraint on columns "id" */
  GroupItemsCsgomarketPkey = 'group_items_csgomarket_pkey'
}

/** input type for inserting data into table "group_items_csgomarket" */
export type Group_Items_Csgomarket_Insert_Input = {
  collection?: InputMaybe<Scalars['String']>;
  history_group_items_csgomarkets?: InputMaybe<History_Group_Items_Csgomarket_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  item_type?: InputMaybe<Scalars['String']>;
  items_csgomarkets?: InputMaybe<Items_Csgomarket_Arr_Rel_Insert_Input>;
  items_type?: InputMaybe<Items_Type_Obj_Rel_Insert_Input>;
  market_groups_conns?: InputMaybe<Market_Groups_Conn_Arr_Rel_Insert_Input>;
  market_hash_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Group_Items_Csgomarket_Max_Fields = {
  __typename?: 'group_items_csgomarket_max_fields';
  collection?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  item_type?: Maybe<Scalars['String']>;
  market_hash_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "group_items_csgomarket" */
export type Group_Items_Csgomarket_Max_Order_By = {
  collection?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  market_hash_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Group_Items_Csgomarket_Min_Fields = {
  __typename?: 'group_items_csgomarket_min_fields';
  collection?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  item_type?: Maybe<Scalars['String']>;
  market_hash_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "group_items_csgomarket" */
export type Group_Items_Csgomarket_Min_Order_By = {
  collection?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  market_hash_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "group_items_csgomarket" */
export type Group_Items_Csgomarket_Mutation_Response = {
  __typename?: 'group_items_csgomarket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Group_Items_Csgomarket>;
};

/** input type for inserting object relation for remote table "group_items_csgomarket" */
export type Group_Items_Csgomarket_Obj_Rel_Insert_Input = {
  data: Group_Items_Csgomarket_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Group_Items_Csgomarket_On_Conflict>;
};

/** on_conflict condition type for table "group_items_csgomarket" */
export type Group_Items_Csgomarket_On_Conflict = {
  constraint: Group_Items_Csgomarket_Constraint;
  update_columns?: Array<Group_Items_Csgomarket_Update_Column>;
  where?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
};

/** Ordering options when selecting data from "group_items_csgomarket". */
export type Group_Items_Csgomarket_Order_By = {
  collection?: InputMaybe<Order_By>;
  history_group_items_csgomarkets_aggregate?: InputMaybe<History_Group_Items_Csgomarket_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  items_csgomarkets_aggregate?: InputMaybe<Items_Csgomarket_Aggregate_Order_By>;
  items_type?: InputMaybe<Items_Type_Order_By>;
  market_groups_conns_aggregate?: InputMaybe<Market_Groups_Conn_Aggregate_Order_By>;
  market_hash_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: group_items_csgomarket */
export type Group_Items_Csgomarket_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "group_items_csgomarket" */
export enum Group_Items_Csgomarket_Select_Column {
  /** column name */
  Collection = 'collection',
  /** column name */
  Id = 'id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  MarketHashName = 'market_hash_name'
}

/** input type for updating data in table "group_items_csgomarket" */
export type Group_Items_Csgomarket_Set_Input = {
  collection?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_type?: InputMaybe<Scalars['String']>;
  market_hash_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "group_items_csgomarket" */
export type Group_Items_Csgomarket_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Group_Items_Csgomarket_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Group_Items_Csgomarket_Stream_Cursor_Value_Input = {
  collection?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_type?: InputMaybe<Scalars['String']>;
  market_hash_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "group_items_csgomarket" */
export enum Group_Items_Csgomarket_Update_Column {
  /** column name */
  Collection = 'collection',
  /** column name */
  Id = 'id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  MarketHashName = 'market_hash_name'
}

export type Group_Items_Csgomarket_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Group_Items_Csgomarket_Set_Input>;
  /** filter the rows which have to be updated */
  where: Group_Items_Csgomarket_Bool_Exp;
};

/** columns and relationships of "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket = {
  __typename?: 'history_group_items_csgomarket';
  created_at: Scalars['timestamp'];
  currency: Scalars['String'];
  /** An object relationship */
  group_items_csgomarket: Group_Items_Csgomarket;
  id: Scalars['uuid'];
  id_group_items: Scalars['uuid'];
  market_count: Scalars['Int'];
  min_price: Scalars['Float'];
};

/** aggregated selection of "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Aggregate = {
  __typename?: 'history_group_items_csgomarket_aggregate';
  aggregate?: Maybe<History_Group_Items_Csgomarket_Aggregate_Fields>;
  nodes: Array<History_Group_Items_Csgomarket>;
};

export type History_Group_Items_Csgomarket_Aggregate_Bool_Exp = {
  count?: InputMaybe<History_Group_Items_Csgomarket_Aggregate_Bool_Exp_Count>;
};

export type History_Group_Items_Csgomarket_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<History_Group_Items_Csgomarket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<History_Group_Items_Csgomarket_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Aggregate_Fields = {
  __typename?: 'history_group_items_csgomarket_aggregate_fields';
  avg?: Maybe<History_Group_Items_Csgomarket_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<History_Group_Items_Csgomarket_Max_Fields>;
  min?: Maybe<History_Group_Items_Csgomarket_Min_Fields>;
  stddev?: Maybe<History_Group_Items_Csgomarket_Stddev_Fields>;
  stddev_pop?: Maybe<History_Group_Items_Csgomarket_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<History_Group_Items_Csgomarket_Stddev_Samp_Fields>;
  sum?: Maybe<History_Group_Items_Csgomarket_Sum_Fields>;
  var_pop?: Maybe<History_Group_Items_Csgomarket_Var_Pop_Fields>;
  var_samp?: Maybe<History_Group_Items_Csgomarket_Var_Samp_Fields>;
  variance?: Maybe<History_Group_Items_Csgomarket_Variance_Fields>;
};


/** aggregate fields of "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<History_Group_Items_Csgomarket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Aggregate_Order_By = {
  avg?: InputMaybe<History_Group_Items_Csgomarket_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<History_Group_Items_Csgomarket_Max_Order_By>;
  min?: InputMaybe<History_Group_Items_Csgomarket_Min_Order_By>;
  stddev?: InputMaybe<History_Group_Items_Csgomarket_Stddev_Order_By>;
  stddev_pop?: InputMaybe<History_Group_Items_Csgomarket_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<History_Group_Items_Csgomarket_Stddev_Samp_Order_By>;
  sum?: InputMaybe<History_Group_Items_Csgomarket_Sum_Order_By>;
  var_pop?: InputMaybe<History_Group_Items_Csgomarket_Var_Pop_Order_By>;
  var_samp?: InputMaybe<History_Group_Items_Csgomarket_Var_Samp_Order_By>;
  variance?: InputMaybe<History_Group_Items_Csgomarket_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Arr_Rel_Insert_Input = {
  data: Array<History_Group_Items_Csgomarket_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<History_Group_Items_Csgomarket_On_Conflict>;
};

/** aggregate avg on columns */
export type History_Group_Items_Csgomarket_Avg_Fields = {
  __typename?: 'history_group_items_csgomarket_avg_fields';
  market_count?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Avg_Order_By = {
  market_count?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "history_group_items_csgomarket". All fields are combined with a logical 'AND'. */
export type History_Group_Items_Csgomarket_Bool_Exp = {
  _and?: InputMaybe<Array<History_Group_Items_Csgomarket_Bool_Exp>>;
  _not?: InputMaybe<History_Group_Items_Csgomarket_Bool_Exp>;
  _or?: InputMaybe<Array<History_Group_Items_Csgomarket_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  group_items_csgomarket?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  id_group_items?: InputMaybe<Uuid_Comparison_Exp>;
  market_count?: InputMaybe<Int_Comparison_Exp>;
  min_price?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "history_group_items_csgomarket" */
export enum History_Group_Items_Csgomarket_Constraint {
  /** unique or primary key constraint on columns "id_group_items", "created_at" */
  HistoryGroupItemsCsgomarketIdGroupItemsCreatedAtKey = 'history_group_items_csgomarket_id_group_items_created_at_key',
  /** unique or primary key constraint on columns "id" */
  HistoryGroupItemsCsgomarketPkey = 'history_group_items_csgomarket_pkey'
}

/** input type for incrementing numeric columns in table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Inc_Input = {
  market_count?: InputMaybe<Scalars['Int']>;
  min_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  group_items_csgomarket?: InputMaybe<Group_Items_Csgomarket_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  id_group_items?: InputMaybe<Scalars['uuid']>;
  market_count?: InputMaybe<Scalars['Int']>;
  min_price?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type History_Group_Items_Csgomarket_Max_Fields = {
  __typename?: 'history_group_items_csgomarket_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  id_group_items?: Maybe<Scalars['uuid']>;
  market_count?: Maybe<Scalars['Int']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_group_items?: InputMaybe<Order_By>;
  market_count?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type History_Group_Items_Csgomarket_Min_Fields = {
  __typename?: 'history_group_items_csgomarket_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  id_group_items?: Maybe<Scalars['uuid']>;
  market_count?: Maybe<Scalars['Int']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_group_items?: InputMaybe<Order_By>;
  market_count?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Mutation_Response = {
  __typename?: 'history_group_items_csgomarket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<History_Group_Items_Csgomarket>;
};

/** on_conflict condition type for table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_On_Conflict = {
  constraint: History_Group_Items_Csgomarket_Constraint;
  update_columns?: Array<History_Group_Items_Csgomarket_Update_Column>;
  where?: InputMaybe<History_Group_Items_Csgomarket_Bool_Exp>;
};

/** Ordering options when selecting data from "history_group_items_csgomarket". */
export type History_Group_Items_Csgomarket_Order_By = {
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  group_items_csgomarket?: InputMaybe<Group_Items_Csgomarket_Order_By>;
  id?: InputMaybe<Order_By>;
  id_group_items?: InputMaybe<Order_By>;
  market_count?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: history_group_items_csgomarket */
export type History_Group_Items_Csgomarket_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "history_group_items_csgomarket" */
export enum History_Group_Items_Csgomarket_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IdGroupItems = 'id_group_items',
  /** column name */
  MarketCount = 'market_count',
  /** column name */
  MinPrice = 'min_price'
}

/** input type for updating data in table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_group_items?: InputMaybe<Scalars['uuid']>;
  market_count?: InputMaybe<Scalars['Int']>;
  min_price?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type History_Group_Items_Csgomarket_Stddev_Fields = {
  __typename?: 'history_group_items_csgomarket_stddev_fields';
  market_count?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Stddev_Order_By = {
  market_count?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type History_Group_Items_Csgomarket_Stddev_Pop_Fields = {
  __typename?: 'history_group_items_csgomarket_stddev_pop_fields';
  market_count?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Stddev_Pop_Order_By = {
  market_count?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type History_Group_Items_Csgomarket_Stddev_Samp_Fields = {
  __typename?: 'history_group_items_csgomarket_stddev_samp_fields';
  market_count?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Stddev_Samp_Order_By = {
  market_count?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: History_Group_Items_Csgomarket_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type History_Group_Items_Csgomarket_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_group_items?: InputMaybe<Scalars['uuid']>;
  market_count?: InputMaybe<Scalars['Int']>;
  min_price?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type History_Group_Items_Csgomarket_Sum_Fields = {
  __typename?: 'history_group_items_csgomarket_sum_fields';
  market_count?: Maybe<Scalars['Int']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Sum_Order_By = {
  market_count?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** update columns of table "history_group_items_csgomarket" */
export enum History_Group_Items_Csgomarket_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IdGroupItems = 'id_group_items',
  /** column name */
  MarketCount = 'market_count',
  /** column name */
  MinPrice = 'min_price'
}

export type History_Group_Items_Csgomarket_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<History_Group_Items_Csgomarket_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<History_Group_Items_Csgomarket_Set_Input>;
  /** filter the rows which have to be updated */
  where: History_Group_Items_Csgomarket_Bool_Exp;
};

/** aggregate var_pop on columns */
export type History_Group_Items_Csgomarket_Var_Pop_Fields = {
  __typename?: 'history_group_items_csgomarket_var_pop_fields';
  market_count?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Var_Pop_Order_By = {
  market_count?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type History_Group_Items_Csgomarket_Var_Samp_Fields = {
  __typename?: 'history_group_items_csgomarket_var_samp_fields';
  market_count?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Var_Samp_Order_By = {
  market_count?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type History_Group_Items_Csgomarket_Variance_Fields = {
  __typename?: 'history_group_items_csgomarket_variance_fields';
  market_count?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "history_group_items_csgomarket" */
export type History_Group_Items_Csgomarket_Variance_Order_By = {
  market_count?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "history_items_csgomarket" */
export type History_Items_Csgomarket = {
  __typename?: 'history_items_csgomarket';
  avg_price?: Maybe<Scalars['Float']>;
  buy_order: Scalars['Float'];
  created_at: Scalars['timestamp'];
  currency?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  id_items: Scalars['uuid'];
  /** An object relationship */
  items_csgomarket: Items_Csgomarket;
  popularity_7d?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
};

/** aggregated selection of "history_items_csgomarket" */
export type History_Items_Csgomarket_Aggregate = {
  __typename?: 'history_items_csgomarket_aggregate';
  aggregate?: Maybe<History_Items_Csgomarket_Aggregate_Fields>;
  nodes: Array<History_Items_Csgomarket>;
};

export type History_Items_Csgomarket_Aggregate_Bool_Exp = {
  count?: InputMaybe<History_Items_Csgomarket_Aggregate_Bool_Exp_Count>;
};

export type History_Items_Csgomarket_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<History_Items_Csgomarket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<History_Items_Csgomarket_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "history_items_csgomarket" */
export type History_Items_Csgomarket_Aggregate_Fields = {
  __typename?: 'history_items_csgomarket_aggregate_fields';
  avg?: Maybe<History_Items_Csgomarket_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<History_Items_Csgomarket_Max_Fields>;
  min?: Maybe<History_Items_Csgomarket_Min_Fields>;
  stddev?: Maybe<History_Items_Csgomarket_Stddev_Fields>;
  stddev_pop?: Maybe<History_Items_Csgomarket_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<History_Items_Csgomarket_Stddev_Samp_Fields>;
  sum?: Maybe<History_Items_Csgomarket_Sum_Fields>;
  var_pop?: Maybe<History_Items_Csgomarket_Var_Pop_Fields>;
  var_samp?: Maybe<History_Items_Csgomarket_Var_Samp_Fields>;
  variance?: Maybe<History_Items_Csgomarket_Variance_Fields>;
};


/** aggregate fields of "history_items_csgomarket" */
export type History_Items_Csgomarket_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<History_Items_Csgomarket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "history_items_csgomarket" */
export type History_Items_Csgomarket_Aggregate_Order_By = {
  avg?: InputMaybe<History_Items_Csgomarket_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<History_Items_Csgomarket_Max_Order_By>;
  min?: InputMaybe<History_Items_Csgomarket_Min_Order_By>;
  stddev?: InputMaybe<History_Items_Csgomarket_Stddev_Order_By>;
  stddev_pop?: InputMaybe<History_Items_Csgomarket_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<History_Items_Csgomarket_Stddev_Samp_Order_By>;
  sum?: InputMaybe<History_Items_Csgomarket_Sum_Order_By>;
  var_pop?: InputMaybe<History_Items_Csgomarket_Var_Pop_Order_By>;
  var_samp?: InputMaybe<History_Items_Csgomarket_Var_Samp_Order_By>;
  variance?: InputMaybe<History_Items_Csgomarket_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "history_items_csgomarket" */
export type History_Items_Csgomarket_Arr_Rel_Insert_Input = {
  data: Array<History_Items_Csgomarket_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<History_Items_Csgomarket_On_Conflict>;
};

/** aggregate avg on columns */
export type History_Items_Csgomarket_Avg_Fields = {
  __typename?: 'history_items_csgomarket_avg_fields';
  avg_price?: Maybe<Scalars['Float']>;
  buy_order?: Maybe<Scalars['Float']>;
  popularity_7d?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "history_items_csgomarket" */
export type History_Items_Csgomarket_Avg_Order_By = {
  avg_price?: InputMaybe<Order_By>;
  buy_order?: InputMaybe<Order_By>;
  popularity_7d?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "history_items_csgomarket". All fields are combined with a logical 'AND'. */
export type History_Items_Csgomarket_Bool_Exp = {
  _and?: InputMaybe<Array<History_Items_Csgomarket_Bool_Exp>>;
  _not?: InputMaybe<History_Items_Csgomarket_Bool_Exp>;
  _or?: InputMaybe<Array<History_Items_Csgomarket_Bool_Exp>>;
  avg_price?: InputMaybe<Float_Comparison_Exp>;
  buy_order?: InputMaybe<Float_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  id_items?: InputMaybe<Uuid_Comparison_Exp>;
  items_csgomarket?: InputMaybe<Items_Csgomarket_Bool_Exp>;
  popularity_7d?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "history_items_csgomarket" */
export enum History_Items_Csgomarket_Constraint {
  /** unique or primary key constraint on columns "created_at", "id_items" */
  HistoryItemsCsgomarketIdItemsCreatedAtKey = 'history_items_csgomarket_id_items_created_at_key',
  /** unique or primary key constraint on columns "id" */
  HistoryItemsCsgomarketPkey = 'history_items_csgomarket_pkey'
}

/** input type for incrementing numeric columns in table "history_items_csgomarket" */
export type History_Items_Csgomarket_Inc_Input = {
  avg_price?: InputMaybe<Scalars['Float']>;
  buy_order?: InputMaybe<Scalars['Float']>;
  popularity_7d?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "history_items_csgomarket" */
export type History_Items_Csgomarket_Insert_Input = {
  avg_price?: InputMaybe<Scalars['Float']>;
  buy_order?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_items?: InputMaybe<Scalars['uuid']>;
  items_csgomarket?: InputMaybe<Items_Csgomarket_Obj_Rel_Insert_Input>;
  popularity_7d?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type History_Items_Csgomarket_Max_Fields = {
  __typename?: 'history_items_csgomarket_max_fields';
  avg_price?: Maybe<Scalars['Float']>;
  buy_order?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  id_items?: Maybe<Scalars['uuid']>;
  popularity_7d?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "history_items_csgomarket" */
export type History_Items_Csgomarket_Max_Order_By = {
  avg_price?: InputMaybe<Order_By>;
  buy_order?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_items?: InputMaybe<Order_By>;
  popularity_7d?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type History_Items_Csgomarket_Min_Fields = {
  __typename?: 'history_items_csgomarket_min_fields';
  avg_price?: Maybe<Scalars['Float']>;
  buy_order?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  id_items?: Maybe<Scalars['uuid']>;
  popularity_7d?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "history_items_csgomarket" */
export type History_Items_Csgomarket_Min_Order_By = {
  avg_price?: InputMaybe<Order_By>;
  buy_order?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_items?: InputMaybe<Order_By>;
  popularity_7d?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "history_items_csgomarket" */
export type History_Items_Csgomarket_Mutation_Response = {
  __typename?: 'history_items_csgomarket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<History_Items_Csgomarket>;
};

/** on_conflict condition type for table "history_items_csgomarket" */
export type History_Items_Csgomarket_On_Conflict = {
  constraint: History_Items_Csgomarket_Constraint;
  update_columns?: Array<History_Items_Csgomarket_Update_Column>;
  where?: InputMaybe<History_Items_Csgomarket_Bool_Exp>;
};

/** Ordering options when selecting data from "history_items_csgomarket". */
export type History_Items_Csgomarket_Order_By = {
  avg_price?: InputMaybe<Order_By>;
  buy_order?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_items?: InputMaybe<Order_By>;
  items_csgomarket?: InputMaybe<Items_Csgomarket_Order_By>;
  popularity_7d?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: history_items_csgomarket */
export type History_Items_Csgomarket_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "history_items_csgomarket" */
export enum History_Items_Csgomarket_Select_Column {
  /** column name */
  AvgPrice = 'avg_price',
  /** column name */
  BuyOrder = 'buy_order',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IdItems = 'id_items',
  /** column name */
  Popularity_7d = 'popularity_7d',
  /** column name */
  Price = 'price'
}

/** input type for updating data in table "history_items_csgomarket" */
export type History_Items_Csgomarket_Set_Input = {
  avg_price?: InputMaybe<Scalars['Float']>;
  buy_order?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_items?: InputMaybe<Scalars['uuid']>;
  popularity_7d?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type History_Items_Csgomarket_Stddev_Fields = {
  __typename?: 'history_items_csgomarket_stddev_fields';
  avg_price?: Maybe<Scalars['Float']>;
  buy_order?: Maybe<Scalars['Float']>;
  popularity_7d?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "history_items_csgomarket" */
export type History_Items_Csgomarket_Stddev_Order_By = {
  avg_price?: InputMaybe<Order_By>;
  buy_order?: InputMaybe<Order_By>;
  popularity_7d?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type History_Items_Csgomarket_Stddev_Pop_Fields = {
  __typename?: 'history_items_csgomarket_stddev_pop_fields';
  avg_price?: Maybe<Scalars['Float']>;
  buy_order?: Maybe<Scalars['Float']>;
  popularity_7d?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "history_items_csgomarket" */
export type History_Items_Csgomarket_Stddev_Pop_Order_By = {
  avg_price?: InputMaybe<Order_By>;
  buy_order?: InputMaybe<Order_By>;
  popularity_7d?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type History_Items_Csgomarket_Stddev_Samp_Fields = {
  __typename?: 'history_items_csgomarket_stddev_samp_fields';
  avg_price?: Maybe<Scalars['Float']>;
  buy_order?: Maybe<Scalars['Float']>;
  popularity_7d?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "history_items_csgomarket" */
export type History_Items_Csgomarket_Stddev_Samp_Order_By = {
  avg_price?: InputMaybe<Order_By>;
  buy_order?: InputMaybe<Order_By>;
  popularity_7d?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "history_items_csgomarket" */
export type History_Items_Csgomarket_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: History_Items_Csgomarket_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type History_Items_Csgomarket_Stream_Cursor_Value_Input = {
  avg_price?: InputMaybe<Scalars['Float']>;
  buy_order?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_items?: InputMaybe<Scalars['uuid']>;
  popularity_7d?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type History_Items_Csgomarket_Sum_Fields = {
  __typename?: 'history_items_csgomarket_sum_fields';
  avg_price?: Maybe<Scalars['Float']>;
  buy_order?: Maybe<Scalars['Float']>;
  popularity_7d?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "history_items_csgomarket" */
export type History_Items_Csgomarket_Sum_Order_By = {
  avg_price?: InputMaybe<Order_By>;
  buy_order?: InputMaybe<Order_By>;
  popularity_7d?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** update columns of table "history_items_csgomarket" */
export enum History_Items_Csgomarket_Update_Column {
  /** column name */
  AvgPrice = 'avg_price',
  /** column name */
  BuyOrder = 'buy_order',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IdItems = 'id_items',
  /** column name */
  Popularity_7d = 'popularity_7d',
  /** column name */
  Price = 'price'
}

export type History_Items_Csgomarket_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<History_Items_Csgomarket_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<History_Items_Csgomarket_Set_Input>;
  /** filter the rows which have to be updated */
  where: History_Items_Csgomarket_Bool_Exp;
};

/** aggregate var_pop on columns */
export type History_Items_Csgomarket_Var_Pop_Fields = {
  __typename?: 'history_items_csgomarket_var_pop_fields';
  avg_price?: Maybe<Scalars['Float']>;
  buy_order?: Maybe<Scalars['Float']>;
  popularity_7d?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "history_items_csgomarket" */
export type History_Items_Csgomarket_Var_Pop_Order_By = {
  avg_price?: InputMaybe<Order_By>;
  buy_order?: InputMaybe<Order_By>;
  popularity_7d?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type History_Items_Csgomarket_Var_Samp_Fields = {
  __typename?: 'history_items_csgomarket_var_samp_fields';
  avg_price?: Maybe<Scalars['Float']>;
  buy_order?: Maybe<Scalars['Float']>;
  popularity_7d?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "history_items_csgomarket" */
export type History_Items_Csgomarket_Var_Samp_Order_By = {
  avg_price?: InputMaybe<Order_By>;
  buy_order?: InputMaybe<Order_By>;
  popularity_7d?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type History_Items_Csgomarket_Variance_Fields = {
  __typename?: 'history_items_csgomarket_variance_fields';
  avg_price?: Maybe<Scalars['Float']>;
  buy_order?: Maybe<Scalars['Float']>;
  popularity_7d?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "history_items_csgomarket" */
export type History_Items_Csgomarket_Variance_Order_By = {
  avg_price?: InputMaybe<Order_By>;
  buy_order?: InputMaybe<Order_By>;
  popularity_7d?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** status: 1 - в процессе вывода, 2 - на проверке, если вывод завершился ошибкой и нужно перепроверить, выводится предмет или нет, 3 - успех, 4 - ошибка */
export type Item_Out_Transactions = {
  __typename?: 'item_out_transactions';
  alternative_item_data?: Maybe<Scalars['jsonb']>;
  amount: Scalars['Int'];
  amount_currency: Scalars['String'];
  created_at: Scalars['timestamp'];
  error_description?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  fee_currency?: Maybe<Scalars['String']>;
  item_out_request_id?: Maybe<Scalars['uuid']>;
  market_last_data?: Maybe<Scalars['jsonb']>;
  status: Scalars['Int'];
  status_description: Scalars['String'];
  token_id?: Maybe<Scalars['uuid']>;
  transaction_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
  user_id: Scalars['uuid'];
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
  /** An object relationship */
  user_items_in_inventory?: Maybe<User_Items_In_Inventory>;
  validations_count: Scalars['Int'];
};


/** status: 1 - в процессе вывода, 2 - на проверке, если вывод завершился ошибкой и нужно перепроверить, выводится предмет или нет, 3 - успех, 4 - ошибка */
export type Item_Out_TransactionsAlternative_Item_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** status: 1 - в процессе вывода, 2 - на проверке, если вывод завершился ошибкой и нужно перепроверить, выводится предмет или нет, 3 - успех, 4 - ошибка */
export type Item_Out_TransactionsMarket_Last_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "item_out_transactions" */
export type Item_Out_Transactions_Aggregate = {
  __typename?: 'item_out_transactions_aggregate';
  aggregate?: Maybe<Item_Out_Transactions_Aggregate_Fields>;
  nodes: Array<Item_Out_Transactions>;
};

/** aggregate fields of "item_out_transactions" */
export type Item_Out_Transactions_Aggregate_Fields = {
  __typename?: 'item_out_transactions_aggregate_fields';
  avg?: Maybe<Item_Out_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Item_Out_Transactions_Max_Fields>;
  min?: Maybe<Item_Out_Transactions_Min_Fields>;
  stddev?: Maybe<Item_Out_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Item_Out_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Item_Out_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Item_Out_Transactions_Sum_Fields>;
  var_pop?: Maybe<Item_Out_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Item_Out_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Item_Out_Transactions_Variance_Fields>;
};


/** aggregate fields of "item_out_transactions" */
export type Item_Out_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Item_Out_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Item_Out_Transactions_Append_Input = {
  alternative_item_data?: InputMaybe<Scalars['jsonb']>;
  market_last_data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Item_Out_Transactions_Avg_Fields = {
  __typename?: 'item_out_transactions_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  validations_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "item_out_transactions". All fields are combined with a logical 'AND'. */
export type Item_Out_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Item_Out_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Item_Out_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Item_Out_Transactions_Bool_Exp>>;
  alternative_item_data?: InputMaybe<Jsonb_Comparison_Exp>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  amount_currency?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  error_description?: InputMaybe<String_Comparison_Exp>;
  fee?: InputMaybe<Int_Comparison_Exp>;
  fee_currency?: InputMaybe<String_Comparison_Exp>;
  item_out_request_id?: InputMaybe<Uuid_Comparison_Exp>;
  market_last_data?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  status_description?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<Uuid_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_inventory_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_items_in_inventory?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
  validations_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "item_out_transactions" */
export enum Item_Out_Transactions_Constraint {
  /** unique or primary key constraint on columns "transaction_id" */
  ItemOutTransactionsPkey = 'item_out_transactions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Item_Out_Transactions_Delete_At_Path_Input = {
  alternative_item_data?: InputMaybe<Array<Scalars['String']>>;
  market_last_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Item_Out_Transactions_Delete_Elem_Input = {
  alternative_item_data?: InputMaybe<Scalars['Int']>;
  market_last_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Item_Out_Transactions_Delete_Key_Input = {
  alternative_item_data?: InputMaybe<Scalars['String']>;
  market_last_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "item_out_transactions" */
export type Item_Out_Transactions_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  fee?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  validations_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "item_out_transactions" */
export type Item_Out_Transactions_Insert_Input = {
  alternative_item_data?: InputMaybe<Scalars['jsonb']>;
  amount?: InputMaybe<Scalars['Int']>;
  amount_currency?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  error_description?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['Int']>;
  fee_currency?: InputMaybe<Scalars['String']>;
  item_out_request_id?: InputMaybe<Scalars['uuid']>;
  market_last_data?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['Int']>;
  status_description?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
  user_items_in_inventory?: InputMaybe<User_Items_In_Inventory_Obj_Rel_Insert_Input>;
  validations_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Item_Out_Transactions_Max_Fields = {
  __typename?: 'item_out_transactions_max_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_currency?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  error_description?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  fee_currency?: Maybe<Scalars['String']>;
  item_out_request_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['Int']>;
  status_description?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['uuid']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
  validations_count?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Item_Out_Transactions_Min_Fields = {
  __typename?: 'item_out_transactions_min_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_currency?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  error_description?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  fee_currency?: Maybe<Scalars['String']>;
  item_out_request_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['Int']>;
  status_description?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['uuid']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
  validations_count?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "item_out_transactions" */
export type Item_Out_Transactions_Mutation_Response = {
  __typename?: 'item_out_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Item_Out_Transactions>;
};

/** on_conflict condition type for table "item_out_transactions" */
export type Item_Out_Transactions_On_Conflict = {
  constraint: Item_Out_Transactions_Constraint;
  update_columns?: Array<Item_Out_Transactions_Update_Column>;
  where?: InputMaybe<Item_Out_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "item_out_transactions". */
export type Item_Out_Transactions_Order_By = {
  alternative_item_data?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  amount_currency?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  error_description?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  fee_currency?: InputMaybe<Order_By>;
  item_out_request_id?: InputMaybe<Order_By>;
  market_last_data?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_description?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
  user_item_id?: InputMaybe<Order_By>;
  user_items_in_inventory?: InputMaybe<User_Items_In_Inventory_Order_By>;
  validations_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: item_out_transactions */
export type Item_Out_Transactions_Pk_Columns_Input = {
  transaction_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Item_Out_Transactions_Prepend_Input = {
  alternative_item_data?: InputMaybe<Scalars['jsonb']>;
  market_last_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "item_out_transactions" */
export enum Item_Out_Transactions_Select_Column {
  /** column name */
  AlternativeItemData = 'alternative_item_data',
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountCurrency = 'amount_currency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorDescription = 'error_description',
  /** column name */
  Fee = 'fee',
  /** column name */
  FeeCurrency = 'fee_currency',
  /** column name */
  ItemOutRequestId = 'item_out_request_id',
  /** column name */
  MarketLastData = 'market_last_data',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDescription = 'status_description',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserItemId = 'user_item_id',
  /** column name */
  ValidationsCount = 'validations_count'
}

/** input type for updating data in table "item_out_transactions" */
export type Item_Out_Transactions_Set_Input = {
  alternative_item_data?: InputMaybe<Scalars['jsonb']>;
  amount?: InputMaybe<Scalars['Int']>;
  amount_currency?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  error_description?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['Int']>;
  fee_currency?: InputMaybe<Scalars['String']>;
  item_out_request_id?: InputMaybe<Scalars['uuid']>;
  market_last_data?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['Int']>;
  status_description?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
  validations_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Item_Out_Transactions_Stddev_Fields = {
  __typename?: 'item_out_transactions_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  validations_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Item_Out_Transactions_Stddev_Pop_Fields = {
  __typename?: 'item_out_transactions_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  validations_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Item_Out_Transactions_Stddev_Samp_Fields = {
  __typename?: 'item_out_transactions_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  validations_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "item_out_transactions" */
export type Item_Out_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Item_Out_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Item_Out_Transactions_Stream_Cursor_Value_Input = {
  alternative_item_data?: InputMaybe<Scalars['jsonb']>;
  amount?: InputMaybe<Scalars['Int']>;
  amount_currency?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  error_description?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['Int']>;
  fee_currency?: InputMaybe<Scalars['String']>;
  item_out_request_id?: InputMaybe<Scalars['uuid']>;
  market_last_data?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['Int']>;
  status_description?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
  validations_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Item_Out_Transactions_Sum_Fields = {
  __typename?: 'item_out_transactions_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  validations_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "item_out_transactions" */
export enum Item_Out_Transactions_Update_Column {
  /** column name */
  AlternativeItemData = 'alternative_item_data',
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountCurrency = 'amount_currency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorDescription = 'error_description',
  /** column name */
  Fee = 'fee',
  /** column name */
  FeeCurrency = 'fee_currency',
  /** column name */
  ItemOutRequestId = 'item_out_request_id',
  /** column name */
  MarketLastData = 'market_last_data',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDescription = 'status_description',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserItemId = 'user_item_id',
  /** column name */
  ValidationsCount = 'validations_count'
}

export type Item_Out_Transactions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Item_Out_Transactions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Item_Out_Transactions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Item_Out_Transactions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Item_Out_Transactions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Item_Out_Transactions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Item_Out_Transactions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Item_Out_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Item_Out_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Item_Out_Transactions_Var_Pop_Fields = {
  __typename?: 'item_out_transactions_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  validations_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Item_Out_Transactions_Var_Samp_Fields = {
  __typename?: 'item_out_transactions_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  validations_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Item_Out_Transactions_Variance_Fields = {
  __typename?: 'item_out_transactions_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  validations_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "items" */
export type Items = {
  __typename?: 'items';
  created_at: Scalars['timestamp'];
  item_collection?: Maybe<Scalars['Int']>;
  item_custom_image_link?: Maybe<Scalars['String']>;
  item_hash_name: Scalars['String'];
  /** An object relationship */
  item_hash_name_localized?: Maybe<Items_Localization>;
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_max_count_on_market: Scalars['Int'];
  item_min_price: Scalars['Float'];
  item_name: Scalars['String'];
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_steam_class_id: Scalars['bigint'];
  item_steam_instance_id?: Maybe<Scalars['bigint']>;
  item_type?: Maybe<Scalars['String']>;
  item_weapon?: Maybe<Scalars['String']>;
  /** An array relationship */
  items_histories: Array<Items_History>;
  /** An aggregate relationship */
  items_histories_aggregate: Items_History_Aggregate;
  /** An array relationship */
  localization: Array<Items_Localization>;
  /** An aggregate relationship */
  localization_aggregate: Items_Localization_Aggregate;
  /** An array relationship */
  localization_quality: Array<Items_Quality_Localization>;
  /** An aggregate relationship */
  localization_quality_aggregate: Items_Quality_Localization_Aggregate;
  market_source: Scalars['String'];
  /** An object relationship */
  name_localized?: Maybe<Items_Items_Names>;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "items" */
export type ItemsItems_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Items_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_History_Order_By>>;
  where?: InputMaybe<Items_History_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsItems_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_History_Order_By>>;
  where?: InputMaybe<Items_History_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsLocalizationArgs = {
  distinct_on?: InputMaybe<Array<Items_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Localization_Order_By>>;
  where?: InputMaybe<Items_Localization_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsLocalization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Localization_Order_By>>;
  where?: InputMaybe<Items_Localization_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsLocalization_QualityArgs = {
  distinct_on?: InputMaybe<Array<Items_Quality_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Quality_Localization_Order_By>>;
  where?: InputMaybe<Items_Quality_Localization_Bool_Exp>;
};


/** columns and relationships of "items" */
export type ItemsLocalization_Quality_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Quality_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Quality_Localization_Order_By>>;
  where?: InputMaybe<Items_Quality_Localization_Bool_Exp>;
};

/** aggregated selection of "items" */
export type Items_Aggregate = {
  __typename?: 'items_aggregate';
  aggregate?: Maybe<Items_Aggregate_Fields>;
  nodes: Array<Items>;
};

/** aggregate fields of "items" */
export type Items_Aggregate_Fields = {
  __typename?: 'items_aggregate_fields';
  avg?: Maybe<Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Items_Max_Fields>;
  min?: Maybe<Items_Min_Fields>;
  stddev?: Maybe<Items_Stddev_Fields>;
  stddev_pop?: Maybe<Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Items_Stddev_Samp_Fields>;
  sum?: Maybe<Items_Sum_Fields>;
  var_pop?: Maybe<Items_Var_Pop_Fields>;
  var_samp?: Maybe<Items_Var_Samp_Fields>;
  variance?: Maybe<Items_Variance_Fields>;
};


/** aggregate fields of "items" */
export type Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Items_Avg_Fields = {
  __typename?: 'items_avg_fields';
  item_collection?: Maybe<Scalars['Float']>;
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
  item_steam_class_id?: Maybe<Scalars['Float']>;
  item_steam_instance_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "items". All fields are combined with a logical 'AND'. */
export type Items_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Bool_Exp>>;
  _not?: InputMaybe<Items_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  item_collection?: InputMaybe<Int_Comparison_Exp>;
  item_custom_image_link?: InputMaybe<String_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_hash_name_localized?: InputMaybe<Items_Localization_Bool_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_image_link?: InputMaybe<String_Comparison_Exp>;
  item_max_count_on_market?: InputMaybe<Int_Comparison_Exp>;
  item_min_price?: InputMaybe<Float_Comparison_Exp>;
  item_name?: InputMaybe<String_Comparison_Exp>;
  item_quality?: InputMaybe<String_Comparison_Exp>;
  item_rarity?: InputMaybe<String_Comparison_Exp>;
  item_steam_class_id?: InputMaybe<Bigint_Comparison_Exp>;
  item_steam_instance_id?: InputMaybe<Bigint_Comparison_Exp>;
  item_type?: InputMaybe<String_Comparison_Exp>;
  item_weapon?: InputMaybe<String_Comparison_Exp>;
  items_histories?: InputMaybe<Items_History_Bool_Exp>;
  items_histories_aggregate?: InputMaybe<Items_History_Aggregate_Bool_Exp>;
  localization?: InputMaybe<Items_Localization_Bool_Exp>;
  localization_aggregate?: InputMaybe<Items_Localization_Aggregate_Bool_Exp>;
  localization_quality?: InputMaybe<Items_Quality_Localization_Bool_Exp>;
  localization_quality_aggregate?: InputMaybe<Items_Quality_Localization_Aggregate_Bool_Exp>;
  market_source?: InputMaybe<String_Comparison_Exp>;
  name_localized?: InputMaybe<Items_Items_Names_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "items" */
export enum Items_Constraint {
  /** unique or primary key constraint on columns "item_hash_name" */
  ItemsItemHashNameKey = 'items_item_hash_name_key',
  /** unique or primary key constraint on columns "item_id" */
  ItemsPkey = 'items_pkey'
}

/** columns and relationships of "items_csgomarket" */
export type Items_Csgomarket = {
  __typename?: 'items_csgomarket';
  class_id: Scalars['bigint'];
  /** An object relationship */
  group_items_csgomarket: Group_Items_Csgomarket;
  /** An array relationship */
  history_items_csgomarkets: Array<History_Items_Csgomarket>;
  /** An aggregate relationship */
  history_items_csgomarkets_aggregate: History_Items_Csgomarket_Aggregate;
  id: Scalars['uuid'];
  id_group_items: Scalars['uuid'];
  instance_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  items_quality?: Maybe<Items_Quality>;
  /** An object relationship */
  items_rarity?: Maybe<Items_Rarity>;
  /** An object relationship */
  localized_name?: Maybe<Items_Names>;
  market_hash_name?: Maybe<Scalars['String']>;
  min_price?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['String']>;
  rarity?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  url_steam_image?: Maybe<Scalars['String']>;
};


/** columns and relationships of "items_csgomarket" */
export type Items_CsgomarketHistory_Items_CsgomarketsArgs = {
  distinct_on?: InputMaybe<Array<History_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Items_Csgomarket_Bool_Exp>;
};


/** columns and relationships of "items_csgomarket" */
export type Items_CsgomarketHistory_Items_Csgomarkets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<History_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Items_Csgomarket_Bool_Exp>;
};

/** aggregated selection of "items_csgomarket" */
export type Items_Csgomarket_Aggregate = {
  __typename?: 'items_csgomarket_aggregate';
  aggregate?: Maybe<Items_Csgomarket_Aggregate_Fields>;
  nodes: Array<Items_Csgomarket>;
};

export type Items_Csgomarket_Aggregate_Bool_Exp = {
  count?: InputMaybe<Items_Csgomarket_Aggregate_Bool_Exp_Count>;
};

export type Items_Csgomarket_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Items_Csgomarket_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "items_csgomarket" */
export type Items_Csgomarket_Aggregate_Fields = {
  __typename?: 'items_csgomarket_aggregate_fields';
  avg?: Maybe<Items_Csgomarket_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Items_Csgomarket_Max_Fields>;
  min?: Maybe<Items_Csgomarket_Min_Fields>;
  stddev?: Maybe<Items_Csgomarket_Stddev_Fields>;
  stddev_pop?: Maybe<Items_Csgomarket_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Items_Csgomarket_Stddev_Samp_Fields>;
  sum?: Maybe<Items_Csgomarket_Sum_Fields>;
  var_pop?: Maybe<Items_Csgomarket_Var_Pop_Fields>;
  var_samp?: Maybe<Items_Csgomarket_Var_Samp_Fields>;
  variance?: Maybe<Items_Csgomarket_Variance_Fields>;
};


/** aggregate fields of "items_csgomarket" */
export type Items_Csgomarket_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "items_csgomarket" */
export type Items_Csgomarket_Aggregate_Order_By = {
  avg?: InputMaybe<Items_Csgomarket_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Items_Csgomarket_Max_Order_By>;
  min?: InputMaybe<Items_Csgomarket_Min_Order_By>;
  stddev?: InputMaybe<Items_Csgomarket_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Items_Csgomarket_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Items_Csgomarket_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Items_Csgomarket_Sum_Order_By>;
  var_pop?: InputMaybe<Items_Csgomarket_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Items_Csgomarket_Var_Samp_Order_By>;
  variance?: InputMaybe<Items_Csgomarket_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "items_csgomarket" */
export type Items_Csgomarket_Arr_Rel_Insert_Input = {
  data: Array<Items_Csgomarket_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_Csgomarket_On_Conflict>;
};

/** aggregate avg on columns */
export type Items_Csgomarket_Avg_Fields = {
  __typename?: 'items_csgomarket_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  instance_id?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "items_csgomarket" */
export type Items_Csgomarket_Avg_Order_By = {
  class_id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "items_csgomarket". All fields are combined with a logical 'AND'. */
export type Items_Csgomarket_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Csgomarket_Bool_Exp>>;
  _not?: InputMaybe<Items_Csgomarket_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Csgomarket_Bool_Exp>>;
  class_id?: InputMaybe<Bigint_Comparison_Exp>;
  group_items_csgomarket?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
  history_items_csgomarkets?: InputMaybe<History_Items_Csgomarket_Bool_Exp>;
  history_items_csgomarkets_aggregate?: InputMaybe<History_Items_Csgomarket_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  id_group_items?: InputMaybe<Uuid_Comparison_Exp>;
  instance_id?: InputMaybe<Bigint_Comparison_Exp>;
  items_quality?: InputMaybe<Items_Quality_Bool_Exp>;
  items_rarity?: InputMaybe<Items_Rarity_Bool_Exp>;
  localized_name?: InputMaybe<Items_Names_Bool_Exp>;
  market_hash_name?: InputMaybe<String_Comparison_Exp>;
  min_price?: InputMaybe<Float_Comparison_Exp>;
  quality?: InputMaybe<String_Comparison_Exp>;
  rarity?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  url_steam_image?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_csgomarket" */
export enum Items_Csgomarket_Constraint {
  /** unique or primary key constraint on columns "instance_id", "class_id" */
  ItemsCsgomarketClassIdInstanceIdKey = 'items_csgomarket_class_id_instance_id_key',
  /** unique or primary key constraint on columns "id" */
  ItemsCsgomarketPkey = 'items_csgomarket_pkey'
}

/** input type for incrementing numeric columns in table "items_csgomarket" */
export type Items_Csgomarket_Inc_Input = {
  class_id?: InputMaybe<Scalars['bigint']>;
  instance_id?: InputMaybe<Scalars['bigint']>;
  min_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "items_csgomarket" */
export type Items_Csgomarket_Insert_Input = {
  class_id?: InputMaybe<Scalars['bigint']>;
  group_items_csgomarket?: InputMaybe<Group_Items_Csgomarket_Obj_Rel_Insert_Input>;
  history_items_csgomarkets?: InputMaybe<History_Items_Csgomarket_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  id_group_items?: InputMaybe<Scalars['uuid']>;
  instance_id?: InputMaybe<Scalars['bigint']>;
  items_quality?: InputMaybe<Items_Quality_Obj_Rel_Insert_Input>;
  items_rarity?: InputMaybe<Items_Rarity_Obj_Rel_Insert_Input>;
  localized_name?: InputMaybe<Items_Names_Obj_Rel_Insert_Input>;
  market_hash_name?: InputMaybe<Scalars['String']>;
  min_price?: InputMaybe<Scalars['Float']>;
  quality?: InputMaybe<Scalars['String']>;
  rarity?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  url_steam_image?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Items_Csgomarket_Max_Fields = {
  __typename?: 'items_csgomarket_max_fields';
  class_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  id_group_items?: Maybe<Scalars['uuid']>;
  instance_id?: Maybe<Scalars['bigint']>;
  market_hash_name?: Maybe<Scalars['String']>;
  min_price?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['String']>;
  rarity?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  url_steam_image?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "items_csgomarket" */
export type Items_Csgomarket_Max_Order_By = {
  class_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_group_items?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  market_hash_name?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
  quality?: InputMaybe<Order_By>;
  rarity?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url_steam_image?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Items_Csgomarket_Min_Fields = {
  __typename?: 'items_csgomarket_min_fields';
  class_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  id_group_items?: Maybe<Scalars['uuid']>;
  instance_id?: Maybe<Scalars['bigint']>;
  market_hash_name?: Maybe<Scalars['String']>;
  min_price?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['String']>;
  rarity?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  url_steam_image?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "items_csgomarket" */
export type Items_Csgomarket_Min_Order_By = {
  class_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_group_items?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  market_hash_name?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
  quality?: InputMaybe<Order_By>;
  rarity?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url_steam_image?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "items_csgomarket" */
export type Items_Csgomarket_Mutation_Response = {
  __typename?: 'items_csgomarket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Csgomarket>;
};

/** input type for inserting object relation for remote table "items_csgomarket" */
export type Items_Csgomarket_Obj_Rel_Insert_Input = {
  data: Items_Csgomarket_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_Csgomarket_On_Conflict>;
};

/** on_conflict condition type for table "items_csgomarket" */
export type Items_Csgomarket_On_Conflict = {
  constraint: Items_Csgomarket_Constraint;
  update_columns?: Array<Items_Csgomarket_Update_Column>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};

/** Ordering options when selecting data from "items_csgomarket". */
export type Items_Csgomarket_Order_By = {
  class_id?: InputMaybe<Order_By>;
  group_items_csgomarket?: InputMaybe<Group_Items_Csgomarket_Order_By>;
  history_items_csgomarkets_aggregate?: InputMaybe<History_Items_Csgomarket_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  id_group_items?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  items_quality?: InputMaybe<Items_Quality_Order_By>;
  items_rarity?: InputMaybe<Items_Rarity_Order_By>;
  localized_name?: InputMaybe<Items_Names_Order_By>;
  market_hash_name?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
  quality?: InputMaybe<Order_By>;
  rarity?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url_steam_image?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_csgomarket */
export type Items_Csgomarket_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "items_csgomarket" */
export enum Items_Csgomarket_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdGroupItems = 'id_group_items',
  /** column name */
  InstanceId = 'instance_id',
  /** column name */
  MarketHashName = 'market_hash_name',
  /** column name */
  MinPrice = 'min_price',
  /** column name */
  Quality = 'quality',
  /** column name */
  Rarity = 'rarity',
  /** column name */
  RuName = 'ru_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UrlSteamImage = 'url_steam_image'
}

/** input type for updating data in table "items_csgomarket" */
export type Items_Csgomarket_Set_Input = {
  class_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_group_items?: InputMaybe<Scalars['uuid']>;
  instance_id?: InputMaybe<Scalars['bigint']>;
  market_hash_name?: InputMaybe<Scalars['String']>;
  min_price?: InputMaybe<Scalars['Float']>;
  quality?: InputMaybe<Scalars['String']>;
  rarity?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  url_steam_image?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Items_Csgomarket_Stddev_Fields = {
  __typename?: 'items_csgomarket_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  instance_id?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "items_csgomarket" */
export type Items_Csgomarket_Stddev_Order_By = {
  class_id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Items_Csgomarket_Stddev_Pop_Fields = {
  __typename?: 'items_csgomarket_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  instance_id?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "items_csgomarket" */
export type Items_Csgomarket_Stddev_Pop_Order_By = {
  class_id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Items_Csgomarket_Stddev_Samp_Fields = {
  __typename?: 'items_csgomarket_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  instance_id?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "items_csgomarket" */
export type Items_Csgomarket_Stddev_Samp_Order_By = {
  class_id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "items_csgomarket" */
export type Items_Csgomarket_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Csgomarket_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Csgomarket_Stream_Cursor_Value_Input = {
  class_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  id_group_items?: InputMaybe<Scalars['uuid']>;
  instance_id?: InputMaybe<Scalars['bigint']>;
  market_hash_name?: InputMaybe<Scalars['String']>;
  min_price?: InputMaybe<Scalars['Float']>;
  quality?: InputMaybe<Scalars['String']>;
  rarity?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  url_steam_image?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Items_Csgomarket_Sum_Fields = {
  __typename?: 'items_csgomarket_sum_fields';
  class_id?: Maybe<Scalars['bigint']>;
  instance_id?: Maybe<Scalars['bigint']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "items_csgomarket" */
export type Items_Csgomarket_Sum_Order_By = {
  class_id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** update columns of table "items_csgomarket" */
export enum Items_Csgomarket_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdGroupItems = 'id_group_items',
  /** column name */
  InstanceId = 'instance_id',
  /** column name */
  MarketHashName = 'market_hash_name',
  /** column name */
  MinPrice = 'min_price',
  /** column name */
  Quality = 'quality',
  /** column name */
  Rarity = 'rarity',
  /** column name */
  RuName = 'ru_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UrlSteamImage = 'url_steam_image'
}

export type Items_Csgomarket_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Items_Csgomarket_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Csgomarket_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Csgomarket_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Items_Csgomarket_Var_Pop_Fields = {
  __typename?: 'items_csgomarket_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  instance_id?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "items_csgomarket" */
export type Items_Csgomarket_Var_Pop_Order_By = {
  class_id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Items_Csgomarket_Var_Samp_Fields = {
  __typename?: 'items_csgomarket_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  instance_id?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "items_csgomarket" */
export type Items_Csgomarket_Var_Samp_Order_By = {
  class_id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Items_Csgomarket_Variance_Fields = {
  __typename?: 'items_csgomarket_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  instance_id?: Maybe<Scalars['Float']>;
  min_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "items_csgomarket" */
export type Items_Csgomarket_Variance_Order_By = {
  class_id?: InputMaybe<Order_By>;
  instance_id?: InputMaybe<Order_By>;
  min_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "items_drops" */
export type Items_Drops = {
  __typename?: 'items_drops';
  date_time: Scalars['timestamptz'];
  id: Scalars['uuid'];
  item_id: Scalars['uuid'];
  item_price: Scalars['Float'];
  lootbox_id: Scalars['uuid'];
};

/** aggregated selection of "items_drops" */
export type Items_Drops_Aggregate = {
  __typename?: 'items_drops_aggregate';
  aggregate?: Maybe<Items_Drops_Aggregate_Fields>;
  nodes: Array<Items_Drops>;
};

/** aggregate fields of "items_drops" */
export type Items_Drops_Aggregate_Fields = {
  __typename?: 'items_drops_aggregate_fields';
  avg?: Maybe<Items_Drops_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Items_Drops_Max_Fields>;
  min?: Maybe<Items_Drops_Min_Fields>;
  stddev?: Maybe<Items_Drops_Stddev_Fields>;
  stddev_pop?: Maybe<Items_Drops_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Items_Drops_Stddev_Samp_Fields>;
  sum?: Maybe<Items_Drops_Sum_Fields>;
  var_pop?: Maybe<Items_Drops_Var_Pop_Fields>;
  var_samp?: Maybe<Items_Drops_Var_Samp_Fields>;
  variance?: Maybe<Items_Drops_Variance_Fields>;
};


/** aggregate fields of "items_drops" */
export type Items_Drops_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Drops_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Items_Drops_Avg_Fields = {
  __typename?: 'items_drops_avg_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "items_drops". All fields are combined with a logical 'AND'. */
export type Items_Drops_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Drops_Bool_Exp>>;
  _not?: InputMaybe<Items_Drops_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Drops_Bool_Exp>>;
  date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_price?: InputMaybe<Float_Comparison_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_drops" */
export enum Items_Drops_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemsDropsPkey = 'items_drops_pkey'
}

/** input type for incrementing numeric columns in table "items_drops" */
export type Items_Drops_Inc_Input = {
  item_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "items_drops" */
export type Items_Drops_Insert_Input = {
  date_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Items_Drops_Max_Fields = {
  __typename?: 'items_drops_max_fields';
  date_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Items_Drops_Min_Fields = {
  __typename?: 'items_drops_min_fields';
  date_time?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "items_drops" */
export type Items_Drops_Mutation_Response = {
  __typename?: 'items_drops_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Drops>;
};

/** on_conflict condition type for table "items_drops" */
export type Items_Drops_On_Conflict = {
  constraint: Items_Drops_Constraint;
  update_columns?: Array<Items_Drops_Update_Column>;
  where?: InputMaybe<Items_Drops_Bool_Exp>;
};

/** Ordering options when selecting data from "items_drops". */
export type Items_Drops_Order_By = {
  date_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_drops */
export type Items_Drops_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "items_drops" */
export enum Items_Drops_Select_Column {
  /** column name */
  DateTime = 'date_time',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  LootboxId = 'lootbox_id'
}

/** input type for updating data in table "items_drops" */
export type Items_Drops_Set_Input = {
  date_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Items_Drops_Stddev_Fields = {
  __typename?: 'items_drops_stddev_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Items_Drops_Stddev_Pop_Fields = {
  __typename?: 'items_drops_stddev_pop_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Items_Drops_Stddev_Samp_Fields = {
  __typename?: 'items_drops_stddev_samp_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "items_drops" */
export type Items_Drops_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Drops_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Drops_Stream_Cursor_Value_Input = {
  date_time?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Items_Drops_Sum_Fields = {
  __typename?: 'items_drops_sum_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** update columns of table "items_drops" */
export enum Items_Drops_Update_Column {
  /** column name */
  DateTime = 'date_time',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  LootboxId = 'lootbox_id'
}

export type Items_Drops_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Items_Drops_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Drops_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Drops_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Items_Drops_Var_Pop_Fields = {
  __typename?: 'items_drops_var_pop_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Items_Drops_Var_Samp_Fields = {
  __typename?: 'items_drops_var_samp_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Items_Drops_Variance_Fields = {
  __typename?: 'items_drops_variance_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "items_for_upgrade" */
export type Items_For_Upgrade = {
  __typename?: 'items_for_upgrade';
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  /** A computed field, executes function "get_formated_item_image_link" */
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_price: Scalars['Int'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  /** A computed field, executes function "get_ru_name" */
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "items_for_upgrade" */
export type Items_For_Upgrade_Aggregate = {
  __typename?: 'items_for_upgrade_aggregate';
  aggregate?: Maybe<Items_For_Upgrade_Aggregate_Fields>;
  nodes: Array<Items_For_Upgrade>;
};

/** aggregate fields of "items_for_upgrade" */
export type Items_For_Upgrade_Aggregate_Fields = {
  __typename?: 'items_for_upgrade_aggregate_fields';
  avg?: Maybe<Items_For_Upgrade_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Items_For_Upgrade_Max_Fields>;
  min?: Maybe<Items_For_Upgrade_Min_Fields>;
  stddev?: Maybe<Items_For_Upgrade_Stddev_Fields>;
  stddev_pop?: Maybe<Items_For_Upgrade_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Items_For_Upgrade_Stddev_Samp_Fields>;
  sum?: Maybe<Items_For_Upgrade_Sum_Fields>;
  var_pop?: Maybe<Items_For_Upgrade_Var_Pop_Fields>;
  var_samp?: Maybe<Items_For_Upgrade_Var_Samp_Fields>;
  variance?: Maybe<Items_For_Upgrade_Variance_Fields>;
};


/** aggregate fields of "items_for_upgrade" */
export type Items_For_Upgrade_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_For_Upgrade_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Items_For_Upgrade_Avg_Fields = {
  __typename?: 'items_for_upgrade_avg_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "items_for_upgrade". All fields are combined with a logical 'AND'. */
export type Items_For_Upgrade_Bool_Exp = {
  _and?: InputMaybe<Array<Items_For_Upgrade_Bool_Exp>>;
  _not?: InputMaybe<Items_For_Upgrade_Bool_Exp>;
  _or?: InputMaybe<Array<Items_For_Upgrade_Bool_Exp>>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_image_link?: InputMaybe<String_Comparison_Exp>;
  item_image_link_formated?: InputMaybe<String_Comparison_Exp>;
  item_price?: InputMaybe<Int_Comparison_Exp>;
  item_quality?: InputMaybe<String_Comparison_Exp>;
  item_rarity?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_for_upgrade" */
export enum Items_For_Upgrade_Constraint {
  /** unique or primary key constraint on columns "item_id" */
  ItemsForUpgradePkey = 'items_for_upgrade_pkey'
}

/** input type for incrementing numeric columns in table "items_for_upgrade" */
export type Items_For_Upgrade_Inc_Input = {
  item_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "items_for_upgrade" */
export type Items_For_Upgrade_Insert_Input = {
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_price?: InputMaybe<Scalars['Int']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Items_For_Upgrade_Max_Fields = {
  __typename?: 'items_for_upgrade_max_fields';
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_formated_item_image_link" */
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Int']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_ru_name" */
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Items_For_Upgrade_Min_Fields = {
  __typename?: 'items_for_upgrade_min_fields';
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_formated_item_image_link" */
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Int']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_ru_name" */
  ru_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "items_for_upgrade" */
export type Items_For_Upgrade_Mutation_Response = {
  __typename?: 'items_for_upgrade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_For_Upgrade>;
};

/** on_conflict condition type for table "items_for_upgrade" */
export type Items_For_Upgrade_On_Conflict = {
  constraint: Items_For_Upgrade_Constraint;
  update_columns?: Array<Items_For_Upgrade_Update_Column>;
  where?: InputMaybe<Items_For_Upgrade_Bool_Exp>;
};

/** Ordering options when selecting data from "items_for_upgrade". */
export type Items_For_Upgrade_Order_By = {
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_image_link_formated?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_for_upgrade */
export type Items_For_Upgrade_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
};

/** select columns of table "items_for_upgrade" */
export enum Items_For_Upgrade_Select_Column {
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity'
}

/** input type for updating data in table "items_for_upgrade" */
export type Items_For_Upgrade_Set_Input = {
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_price?: InputMaybe<Scalars['Int']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Items_For_Upgrade_Stddev_Fields = {
  __typename?: 'items_for_upgrade_stddev_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Items_For_Upgrade_Stddev_Pop_Fields = {
  __typename?: 'items_for_upgrade_stddev_pop_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Items_For_Upgrade_Stddev_Samp_Fields = {
  __typename?: 'items_for_upgrade_stddev_samp_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "items_for_upgrade" */
export type Items_For_Upgrade_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_For_Upgrade_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_For_Upgrade_Stream_Cursor_Value_Input = {
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_price?: InputMaybe<Scalars['Int']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Items_For_Upgrade_Sum_Fields = {
  __typename?: 'items_for_upgrade_sum_fields';
  item_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "items_for_upgrade" */
export enum Items_For_Upgrade_Update_Column {
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity'
}

export type Items_For_Upgrade_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Items_For_Upgrade_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_For_Upgrade_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_For_Upgrade_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Items_For_Upgrade_Var_Pop_Fields = {
  __typename?: 'items_for_upgrade_var_pop_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Items_For_Upgrade_Var_Samp_Fields = {
  __typename?: 'items_for_upgrade_var_samp_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Items_For_Upgrade_Variance_Fields = {
  __typename?: 'items_for_upgrade_variance_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "items_history" */
export type Items_History = {
  __typename?: 'items_history';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  item: Items;
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_max_count_on_markets: Scalars['Int'];
  item_min_price: Scalars['Float'];
};

/** aggregated selection of "items_history" */
export type Items_History_Aggregate = {
  __typename?: 'items_history_aggregate';
  aggregate?: Maybe<Items_History_Aggregate_Fields>;
  nodes: Array<Items_History>;
};

export type Items_History_Aggregate_Bool_Exp = {
  count?: InputMaybe<Items_History_Aggregate_Bool_Exp_Count>;
};

export type Items_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Items_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Items_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "items_history" */
export type Items_History_Aggregate_Fields = {
  __typename?: 'items_history_aggregate_fields';
  avg?: Maybe<Items_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Items_History_Max_Fields>;
  min?: Maybe<Items_History_Min_Fields>;
  stddev?: Maybe<Items_History_Stddev_Fields>;
  stddev_pop?: Maybe<Items_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Items_History_Stddev_Samp_Fields>;
  sum?: Maybe<Items_History_Sum_Fields>;
  var_pop?: Maybe<Items_History_Var_Pop_Fields>;
  var_samp?: Maybe<Items_History_Var_Samp_Fields>;
  variance?: Maybe<Items_History_Variance_Fields>;
};


/** aggregate fields of "items_history" */
export type Items_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "items_history" */
export type Items_History_Aggregate_Order_By = {
  avg?: InputMaybe<Items_History_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Items_History_Max_Order_By>;
  min?: InputMaybe<Items_History_Min_Order_By>;
  stddev?: InputMaybe<Items_History_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Items_History_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Items_History_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Items_History_Sum_Order_By>;
  var_pop?: InputMaybe<Items_History_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Items_History_Var_Samp_Order_By>;
  variance?: InputMaybe<Items_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "items_history" */
export type Items_History_Arr_Rel_Insert_Input = {
  data: Array<Items_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Items_History_Avg_Fields = {
  __typename?: 'items_history_avg_fields';
  item_max_count_on_markets?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "items_history" */
export type Items_History_Avg_Order_By = {
  item_max_count_on_markets?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "items_history". All fields are combined with a logical 'AND'. */
export type Items_History_Bool_Exp = {
  _and?: InputMaybe<Array<Items_History_Bool_Exp>>;
  _not?: InputMaybe<Items_History_Bool_Exp>;
  _or?: InputMaybe<Array<Items_History_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_max_count_on_markets?: InputMaybe<Int_Comparison_Exp>;
  item_min_price?: InputMaybe<Float_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_history" */
export enum Items_History_Constraint {
  /** unique or primary key constraint on columns "item_id", "created_at" */
  ItemsHistoryPkey = 'items_history_pkey'
}

/** input type for incrementing numeric columns in table "items_history" */
export type Items_History_Inc_Input = {
  item_max_count_on_markets?: InputMaybe<Scalars['Int']>;
  item_min_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "items_history" */
export type Items_History_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_max_count_on_markets?: InputMaybe<Scalars['Int']>;
  item_min_price?: InputMaybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Items_History_Max_Fields = {
  __typename?: 'items_history_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_max_count_on_markets?: Maybe<Scalars['Int']>;
  item_min_price?: Maybe<Scalars['Float']>;
};

/** order by max() on columns of table "items_history" */
export type Items_History_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_max_count_on_markets?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Items_History_Min_Fields = {
  __typename?: 'items_history_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_max_count_on_markets?: Maybe<Scalars['Int']>;
  item_min_price?: Maybe<Scalars['Float']>;
};

/** order by min() on columns of table "items_history" */
export type Items_History_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_max_count_on_markets?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "items_history" */
export type Items_History_Mutation_Response = {
  __typename?: 'items_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_History>;
};

/** on_conflict condition type for table "items_history" */
export type Items_History_On_Conflict = {
  constraint: Items_History_Constraint;
  update_columns?: Array<Items_History_Update_Column>;
  where?: InputMaybe<Items_History_Bool_Exp>;
};

/** Ordering options when selecting data from "items_history". */
export type Items_History_Order_By = {
  created_at?: InputMaybe<Order_By>;
  item?: InputMaybe<Items_Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_max_count_on_markets?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_history */
export type Items_History_Pk_Columns_Input = {
  created_at: Scalars['timestamp'];
  item_id: Scalars['uuid'];
};

/** select columns of table "items_history" */
export enum Items_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemMaxCountOnMarkets = 'item_max_count_on_markets',
  /** column name */
  ItemMinPrice = 'item_min_price'
}

/** input type for updating data in table "items_history" */
export type Items_History_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_max_count_on_markets?: InputMaybe<Scalars['Int']>;
  item_min_price?: InputMaybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Items_History_Stddev_Fields = {
  __typename?: 'items_history_stddev_fields';
  item_max_count_on_markets?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "items_history" */
export type Items_History_Stddev_Order_By = {
  item_max_count_on_markets?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Items_History_Stddev_Pop_Fields = {
  __typename?: 'items_history_stddev_pop_fields';
  item_max_count_on_markets?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "items_history" */
export type Items_History_Stddev_Pop_Order_By = {
  item_max_count_on_markets?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Items_History_Stddev_Samp_Fields = {
  __typename?: 'items_history_stddev_samp_fields';
  item_max_count_on_markets?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "items_history" */
export type Items_History_Stddev_Samp_Order_By = {
  item_max_count_on_markets?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "items_history" */
export type Items_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_History_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_max_count_on_markets?: InputMaybe<Scalars['Int']>;
  item_min_price?: InputMaybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Items_History_Sum_Fields = {
  __typename?: 'items_history_sum_fields';
  item_max_count_on_markets?: Maybe<Scalars['Int']>;
  item_min_price?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "items_history" */
export type Items_History_Sum_Order_By = {
  item_max_count_on_markets?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
};

/** update columns of table "items_history" */
export enum Items_History_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemMaxCountOnMarkets = 'item_max_count_on_markets',
  /** column name */
  ItemMinPrice = 'item_min_price'
}

export type Items_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Items_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Items_History_Var_Pop_Fields = {
  __typename?: 'items_history_var_pop_fields';
  item_max_count_on_markets?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "items_history" */
export type Items_History_Var_Pop_Order_By = {
  item_max_count_on_markets?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Items_History_Var_Samp_Fields = {
  __typename?: 'items_history_var_samp_fields';
  item_max_count_on_markets?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "items_history" */
export type Items_History_Var_Samp_Order_By = {
  item_max_count_on_markets?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Items_History_Variance_Fields = {
  __typename?: 'items_history_variance_fields';
  item_max_count_on_markets?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "items_history" */
export type Items_History_Variance_Order_By = {
  item_max_count_on_markets?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "items_in_lootbox" */
export type Items_In_Lootbox = {
  __typename?: 'items_in_lootbox';
  item_alternative?: Maybe<Array<Scalars['String']>>;
  item_drop_chance: Scalars['Float'];
  item_id: Scalars['uuid'];
  item_start_price: Scalars['Float'];
  item_type: Scalars['String'];
  /** An object relationship */
  items_to_views?: Maybe<Items_In_Lootbox_View>;
  /** An object relationship */
  lootbox: Lootboxes;
  lootbox_id: Scalars['uuid'];
};

/** aggregated selection of "items_in_lootbox" */
export type Items_In_Lootbox_Aggregate = {
  __typename?: 'items_in_lootbox_aggregate';
  aggregate?: Maybe<Items_In_Lootbox_Aggregate_Fields>;
  nodes: Array<Items_In_Lootbox>;
};

export type Items_In_Lootbox_Aggregate_Bool_Exp = {
  count?: InputMaybe<Items_In_Lootbox_Aggregate_Bool_Exp_Count>;
};

export type Items_In_Lootbox_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Items_In_Lootbox_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Items_In_Lootbox_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "items_in_lootbox" */
export type Items_In_Lootbox_Aggregate_Fields = {
  __typename?: 'items_in_lootbox_aggregate_fields';
  avg?: Maybe<Items_In_Lootbox_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Items_In_Lootbox_Max_Fields>;
  min?: Maybe<Items_In_Lootbox_Min_Fields>;
  stddev?: Maybe<Items_In_Lootbox_Stddev_Fields>;
  stddev_pop?: Maybe<Items_In_Lootbox_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Items_In_Lootbox_Stddev_Samp_Fields>;
  sum?: Maybe<Items_In_Lootbox_Sum_Fields>;
  var_pop?: Maybe<Items_In_Lootbox_Var_Pop_Fields>;
  var_samp?: Maybe<Items_In_Lootbox_Var_Samp_Fields>;
  variance?: Maybe<Items_In_Lootbox_Variance_Fields>;
};


/** aggregate fields of "items_in_lootbox" */
export type Items_In_Lootbox_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_In_Lootbox_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "items_in_lootbox" */
export type Items_In_Lootbox_Aggregate_Order_By = {
  avg?: InputMaybe<Items_In_Lootbox_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Items_In_Lootbox_Max_Order_By>;
  min?: InputMaybe<Items_In_Lootbox_Min_Order_By>;
  stddev?: InputMaybe<Items_In_Lootbox_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Items_In_Lootbox_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Items_In_Lootbox_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Items_In_Lootbox_Sum_Order_By>;
  var_pop?: InputMaybe<Items_In_Lootbox_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Items_In_Lootbox_Var_Samp_Order_By>;
  variance?: InputMaybe<Items_In_Lootbox_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "items_in_lootbox" */
export type Items_In_Lootbox_Arr_Rel_Insert_Input = {
  data: Array<Items_In_Lootbox_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_In_Lootbox_On_Conflict>;
};

/** aggregate avg on columns */
export type Items_In_Lootbox_Avg_Fields = {
  __typename?: 'items_in_lootbox_avg_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "items_in_lootbox" */
export type Items_In_Lootbox_Avg_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "items_in_lootbox". All fields are combined with a logical 'AND'. */
export type Items_In_Lootbox_Bool_Exp = {
  _and?: InputMaybe<Array<Items_In_Lootbox_Bool_Exp>>;
  _not?: InputMaybe<Items_In_Lootbox_Bool_Exp>;
  _or?: InputMaybe<Array<Items_In_Lootbox_Bool_Exp>>;
  item_alternative?: InputMaybe<String_Array_Comparison_Exp>;
  item_drop_chance?: InputMaybe<Float_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_start_price?: InputMaybe<Float_Comparison_Exp>;
  item_type?: InputMaybe<String_Comparison_Exp>;
  items_to_views?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
  lootbox?: InputMaybe<Lootboxes_Bool_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_in_lootbox" */
export enum Items_In_Lootbox_Constraint {
  /** unique or primary key constraint on columns "lootbox_id", "item_id" */
  ItemsInLootboxPkey = 'items_in_lootbox_pkey'
}

/** input type for incrementing numeric columns in table "items_in_lootbox" */
export type Items_In_Lootbox_Inc_Input = {
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "items_in_lootbox" */
export type Items_In_Lootbox_Insert_Input = {
  item_alternative?: InputMaybe<Array<Scalars['String']>>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  items_to_views?: InputMaybe<Items_In_Lootbox_View_Obj_Rel_Insert_Input>;
  lootbox?: InputMaybe<Lootboxes_Obj_Rel_Insert_Input>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Items_In_Lootbox_Max_Fields = {
  __typename?: 'items_in_lootbox_max_fields';
  item_alternative?: Maybe<Array<Scalars['String']>>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_type?: Maybe<Scalars['String']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "items_in_lootbox" */
export type Items_In_Lootbox_Max_Order_By = {
  item_alternative?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Items_In_Lootbox_Min_Fields = {
  __typename?: 'items_in_lootbox_min_fields';
  item_alternative?: Maybe<Array<Scalars['String']>>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_type?: Maybe<Scalars['String']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "items_in_lootbox" */
export type Items_In_Lootbox_Min_Order_By = {
  item_alternative?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "items_in_lootbox" */
export type Items_In_Lootbox_Mutation_Response = {
  __typename?: 'items_in_lootbox_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_In_Lootbox>;
};

/** on_conflict condition type for table "items_in_lootbox" */
export type Items_In_Lootbox_On_Conflict = {
  constraint: Items_In_Lootbox_Constraint;
  update_columns?: Array<Items_In_Lootbox_Update_Column>;
  where?: InputMaybe<Items_In_Lootbox_Bool_Exp>;
};

/** Ordering options when selecting data from "items_in_lootbox". */
export type Items_In_Lootbox_Order_By = {
  item_alternative?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  items_to_views?: InputMaybe<Items_In_Lootbox_View_Order_By>;
  lootbox?: InputMaybe<Lootboxes_Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_in_lootbox */
export type Items_In_Lootbox_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};

/** select columns of table "items_in_lootbox" */
export enum Items_In_Lootbox_Select_Column {
  /** column name */
  ItemAlternative = 'item_alternative',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  LootboxId = 'lootbox_id'
}

/** input type for updating data in table "items_in_lootbox" */
export type Items_In_Lootbox_Set_Input = {
  item_alternative?: InputMaybe<Array<Scalars['String']>>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Items_In_Lootbox_Stddev_Fields = {
  __typename?: 'items_in_lootbox_stddev_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "items_in_lootbox" */
export type Items_In_Lootbox_Stddev_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Items_In_Lootbox_Stddev_Pop_Fields = {
  __typename?: 'items_in_lootbox_stddev_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "items_in_lootbox" */
export type Items_In_Lootbox_Stddev_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Items_In_Lootbox_Stddev_Samp_Fields = {
  __typename?: 'items_in_lootbox_stddev_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "items_in_lootbox" */
export type Items_In_Lootbox_Stddev_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "items_in_lootbox" */
export type Items_In_Lootbox_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_In_Lootbox_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_In_Lootbox_Stream_Cursor_Value_Input = {
  item_alternative?: InputMaybe<Array<Scalars['String']>>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Items_In_Lootbox_Sum_Fields = {
  __typename?: 'items_in_lootbox_sum_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "items_in_lootbox" */
export type Items_In_Lootbox_Sum_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** update columns of table "items_in_lootbox" */
export enum Items_In_Lootbox_Update_Column {
  /** column name */
  ItemAlternative = 'item_alternative',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  LootboxId = 'lootbox_id'
}

export type Items_In_Lootbox_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Items_In_Lootbox_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_In_Lootbox_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_In_Lootbox_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Items_In_Lootbox_Var_Pop_Fields = {
  __typename?: 'items_in_lootbox_var_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "items_in_lootbox" */
export type Items_In_Lootbox_Var_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Items_In_Lootbox_Var_Samp_Fields = {
  __typename?: 'items_in_lootbox_var_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "items_in_lootbox" */
export type Items_In_Lootbox_Var_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Items_In_Lootbox_Variance_Fields = {
  __typename?: 'items_in_lootbox_variance_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "items_in_lootbox" */
export type Items_In_Lootbox_Variance_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "items_in_lootbox_view" */
export type Items_In_Lootbox_View = {
  __typename?: 'items_in_lootbox_view';
  is_shown: Scalars['Boolean'];
  item_category: Scalars['String'];
  item_data?: Maybe<Items>;
  item_drop_chance: Scalars['Float'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Float'];
  item_to_market_db?: Maybe<Items_Csgomarket>;
  item_type: Scalars['String'];
  /** An array relationship */
  localization_booster_name: Array<Lootbox_Booster_Localization>;
  /** An aggregate relationship */
  localization_booster_name_aggregate: Lootbox_Booster_Localization_Aggregate;
  /** An object relationship */
  localized_name?: Maybe<Lootboxes_Items_Names>;
  /** An object relationship */
  lootbox: Lootboxes;
  lootbox_id: Scalars['uuid'];
  sort?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "items_in_lootbox_view" */
export type Items_In_Lootbox_ViewLocalization_Booster_NameArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Localization_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
};


/** columns and relationships of "items_in_lootbox_view" */
export type Items_In_Lootbox_ViewLocalization_Booster_Name_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Localization_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
};

/** aggregated selection of "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Aggregate = {
  __typename?: 'items_in_lootbox_view_aggregate';
  aggregate?: Maybe<Items_In_Lootbox_View_Aggregate_Fields>;
  nodes: Array<Items_In_Lootbox_View>;
};

export type Items_In_Lootbox_View_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Items_In_Lootbox_View_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Items_In_Lootbox_View_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Items_In_Lootbox_View_Aggregate_Bool_Exp_Count>;
};

export type Items_In_Lootbox_View_Aggregate_Bool_Exp_Bool_And = {
  arguments: Items_In_Lootbox_View_Select_Column_Items_In_Lootbox_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Items_In_Lootbox_View_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Items_In_Lootbox_View_Select_Column_Items_In_Lootbox_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Items_In_Lootbox_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Items_In_Lootbox_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Aggregate_Fields = {
  __typename?: 'items_in_lootbox_view_aggregate_fields';
  avg?: Maybe<Items_In_Lootbox_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Items_In_Lootbox_View_Max_Fields>;
  min?: Maybe<Items_In_Lootbox_View_Min_Fields>;
  stddev?: Maybe<Items_In_Lootbox_View_Stddev_Fields>;
  stddev_pop?: Maybe<Items_In_Lootbox_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Items_In_Lootbox_View_Stddev_Samp_Fields>;
  sum?: Maybe<Items_In_Lootbox_View_Sum_Fields>;
  var_pop?: Maybe<Items_In_Lootbox_View_Var_Pop_Fields>;
  var_samp?: Maybe<Items_In_Lootbox_View_Var_Samp_Fields>;
  variance?: Maybe<Items_In_Lootbox_View_Variance_Fields>;
};


/** aggregate fields of "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_In_Lootbox_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Aggregate_Order_By = {
  avg?: InputMaybe<Items_In_Lootbox_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Items_In_Lootbox_View_Max_Order_By>;
  min?: InputMaybe<Items_In_Lootbox_View_Min_Order_By>;
  stddev?: InputMaybe<Items_In_Lootbox_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Items_In_Lootbox_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Items_In_Lootbox_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Items_In_Lootbox_View_Sum_Order_By>;
  var_pop?: InputMaybe<Items_In_Lootbox_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Items_In_Lootbox_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Items_In_Lootbox_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Arr_Rel_Insert_Input = {
  data: Array<Items_In_Lootbox_View_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_In_Lootbox_View_On_Conflict>;
};

/** aggregate avg on columns */
export type Items_In_Lootbox_View_Avg_Fields = {
  __typename?: 'items_in_lootbox_view_avg_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Avg_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "items_in_lootbox_view". All fields are combined with a logical 'AND'. */
export type Items_In_Lootbox_View_Bool_Exp = {
  _and?: InputMaybe<Array<Items_In_Lootbox_View_Bool_Exp>>;
  _not?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
  _or?: InputMaybe<Array<Items_In_Lootbox_View_Bool_Exp>>;
  is_shown?: InputMaybe<Boolean_Comparison_Exp>;
  item_category?: InputMaybe<String_Comparison_Exp>;
  item_drop_chance?: InputMaybe<Float_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_image_link?: InputMaybe<String_Comparison_Exp>;
  item_max_show_price?: InputMaybe<Float_Comparison_Exp>;
  item_min_show_price?: InputMaybe<Float_Comparison_Exp>;
  item_quality?: InputMaybe<String_Comparison_Exp>;
  item_rarity?: InputMaybe<String_Comparison_Exp>;
  item_start_price?: InputMaybe<Float_Comparison_Exp>;
  item_type?: InputMaybe<String_Comparison_Exp>;
  localization_booster_name?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
  localization_booster_name_aggregate?: InputMaybe<Lootbox_Booster_Localization_Aggregate_Bool_Exp>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Bool_Exp>;
  lootbox?: InputMaybe<Lootboxes_Bool_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_in_lootbox_view" */
export enum Items_In_Lootbox_View_Constraint {
  /** unique or primary key constraint on columns "lootbox_id", "item_id" */
  ItemsInLootboxViewPkey = 'items_in_lootbox_view_pkey'
}

/** input type for incrementing numeric columns in table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Inc_Input = {
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Insert_Input = {
  is_shown?: InputMaybe<Scalars['Boolean']>;
  item_category?: InputMaybe<Scalars['String']>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  localization_booster_name?: InputMaybe<Lootbox_Booster_Localization_Arr_Rel_Insert_Input>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Obj_Rel_Insert_Input>;
  lootbox?: InputMaybe<Lootboxes_Obj_Rel_Insert_Input>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Items_In_Lootbox_View_Max_Fields = {
  __typename?: 'items_in_lootbox_view_max_fields';
  item_category?: Maybe<Scalars['String']>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_type?: Maybe<Scalars['String']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Max_Order_By = {
  item_category?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Items_In_Lootbox_View_Min_Fields = {
  __typename?: 'items_in_lootbox_view_min_fields';
  item_category?: Maybe<Scalars['String']>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_type?: Maybe<Scalars['String']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Min_Order_By = {
  item_category?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Mutation_Response = {
  __typename?: 'items_in_lootbox_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_In_Lootbox_View>;
};

/** input type for inserting object relation for remote table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Obj_Rel_Insert_Input = {
  data: Items_In_Lootbox_View_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_In_Lootbox_View_On_Conflict>;
};

/** on_conflict condition type for table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_On_Conflict = {
  constraint: Items_In_Lootbox_View_Constraint;
  update_columns?: Array<Items_In_Lootbox_View_Update_Column>;
  where?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
};

/** Ordering options when selecting data from "items_in_lootbox_view". */
export type Items_In_Lootbox_View_Order_By = {
  is_shown?: InputMaybe<Order_By>;
  item_category?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  localization_booster_name_aggregate?: InputMaybe<Lootbox_Booster_Localization_Aggregate_Order_By>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Order_By>;
  lootbox?: InputMaybe<Lootboxes_Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_in_lootbox_view */
export type Items_In_Lootbox_View_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};

/** select columns of table "items_in_lootbox_view" */
export enum Items_In_Lootbox_View_Select_Column {
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  ItemCategory = 'item_category',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemMaxShowPrice = 'item_max_show_price',
  /** column name */
  ItemMinShowPrice = 'item_min_show_price',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  Sort = 'sort'
}

/** select "items_in_lootbox_view_aggregate_bool_exp_bool_and_arguments_columns" columns of table "items_in_lootbox_view" */
export enum Items_In_Lootbox_View_Select_Column_Items_In_Lootbox_View_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsShown = 'is_shown'
}

/** select "items_in_lootbox_view_aggregate_bool_exp_bool_or_arguments_columns" columns of table "items_in_lootbox_view" */
export enum Items_In_Lootbox_View_Select_Column_Items_In_Lootbox_View_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsShown = 'is_shown'
}

/** input type for updating data in table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Set_Input = {
  is_shown?: InputMaybe<Scalars['Boolean']>;
  item_category?: InputMaybe<Scalars['String']>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Items_In_Lootbox_View_Stddev_Fields = {
  __typename?: 'items_in_lootbox_view_stddev_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Stddev_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Items_In_Lootbox_View_Stddev_Pop_Fields = {
  __typename?: 'items_in_lootbox_view_stddev_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Stddev_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Items_In_Lootbox_View_Stddev_Samp_Fields = {
  __typename?: 'items_in_lootbox_view_stddev_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Stddev_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_In_Lootbox_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_In_Lootbox_View_Stream_Cursor_Value_Input = {
  is_shown?: InputMaybe<Scalars['Boolean']>;
  item_category?: InputMaybe<Scalars['String']>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Items_In_Lootbox_View_Sum_Fields = {
  __typename?: 'items_in_lootbox_view_sum_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Sum_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** update columns of table "items_in_lootbox_view" */
export enum Items_In_Lootbox_View_Update_Column {
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  ItemCategory = 'item_category',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemMaxShowPrice = 'item_max_show_price',
  /** column name */
  ItemMinShowPrice = 'item_min_show_price',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  Sort = 'sort'
}

export type Items_In_Lootbox_View_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Items_In_Lootbox_View_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_In_Lootbox_View_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_In_Lootbox_View_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Items_In_Lootbox_View_Var_Pop_Fields = {
  __typename?: 'items_in_lootbox_view_var_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Var_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Items_In_Lootbox_View_Var_Samp_Fields = {
  __typename?: 'items_in_lootbox_view_var_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Var_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Items_In_Lootbox_View_Variance_Fields = {
  __typename?: 'items_in_lootbox_view_variance_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "items_in_lootbox_view" */
export type Items_In_Lootbox_View_Variance_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "items" */
export type Items_Inc_Input = {
  item_collection?: InputMaybe<Scalars['Int']>;
  item_max_count_on_market?: InputMaybe<Scalars['Int']>;
  item_min_price?: InputMaybe<Scalars['Float']>;
  item_steam_class_id?: InputMaybe<Scalars['bigint']>;
  item_steam_instance_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "items" */
export type Items_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_collection?: InputMaybe<Scalars['Int']>;
  item_custom_image_link?: InputMaybe<Scalars['String']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_hash_name_localized?: InputMaybe<Items_Localization_Obj_Rel_Insert_Input>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_count_on_market?: InputMaybe<Scalars['Int']>;
  item_min_price?: InputMaybe<Scalars['Float']>;
  item_name?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_steam_class_id?: InputMaybe<Scalars['bigint']>;
  item_steam_instance_id?: InputMaybe<Scalars['bigint']>;
  item_type?: InputMaybe<Scalars['String']>;
  item_weapon?: InputMaybe<Scalars['String']>;
  items_histories?: InputMaybe<Items_History_Arr_Rel_Insert_Input>;
  localization?: InputMaybe<Items_Localization_Arr_Rel_Insert_Input>;
  localization_quality?: InputMaybe<Items_Quality_Localization_Arr_Rel_Insert_Input>;
  market_source?: InputMaybe<Scalars['String']>;
  name_localized?: InputMaybe<Items_Items_Names_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** columns and relationships of "items_items_names" */
export type Items_Items_Names = {
  __typename?: 'items_items_names';
  name: Scalars['String'];
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "items_items_names" */
export type Items_Items_Names_Aggregate = {
  __typename?: 'items_items_names_aggregate';
  aggregate?: Maybe<Items_Items_Names_Aggregate_Fields>;
  nodes: Array<Items_Items_Names>;
};

/** aggregate fields of "items_items_names" */
export type Items_Items_Names_Aggregate_Fields = {
  __typename?: 'items_items_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Items_Names_Max_Fields>;
  min?: Maybe<Items_Items_Names_Min_Fields>;
};


/** aggregate fields of "items_items_names" */
export type Items_Items_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Items_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "items_items_names". All fields are combined with a logical 'AND'. */
export type Items_Items_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Items_Names_Bool_Exp>>;
  _not?: InputMaybe<Items_Items_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Items_Names_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_items_names" */
export enum Items_Items_Names_Constraint {
  /** unique or primary key constraint on columns "name" */
  ItemsNamesPkey = 'items_names_pkey'
}

/** input type for inserting data into table "items_items_names" */
export type Items_Items_Names_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Items_Items_Names_Max_Fields = {
  __typename?: 'items_items_names_max_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Items_Items_Names_Min_Fields = {
  __typename?: 'items_items_names_min_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "items_items_names" */
export type Items_Items_Names_Mutation_Response = {
  __typename?: 'items_items_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Items_Names>;
};

/** input type for inserting object relation for remote table "items_items_names" */
export type Items_Items_Names_Obj_Rel_Insert_Input = {
  data: Items_Items_Names_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_Items_Names_On_Conflict>;
};

/** on_conflict condition type for table "items_items_names" */
export type Items_Items_Names_On_Conflict = {
  constraint: Items_Items_Names_Constraint;
  update_columns?: Array<Items_Items_Names_Update_Column>;
  where?: InputMaybe<Items_Items_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "items_items_names". */
export type Items_Items_Names_Order_By = {
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_items_names */
export type Items_Items_Names_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "items_items_names" */
export enum Items_Items_Names_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

/** input type for updating data in table "items_items_names" */
export type Items_Items_Names_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "items_items_names" */
export type Items_Items_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Items_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Items_Names_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "items_items_names" */
export enum Items_Items_Names_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

export type Items_Items_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Items_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Items_Names_Bool_Exp;
};

/** columns and relationships of "items_localization" */
export type Items_Localization = {
  __typename?: 'items_localization';
  class_id: Scalars['String'];
  created_at: Scalars['timestamp'];
  item_name_key: Scalars['String'];
  item_name_localized: Scalars['String'];
  locale: Scalars['String'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "items_localization" */
export type Items_Localization_Aggregate = {
  __typename?: 'items_localization_aggregate';
  aggregate?: Maybe<Items_Localization_Aggregate_Fields>;
  nodes: Array<Items_Localization>;
};

export type Items_Localization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Items_Localization_Aggregate_Bool_Exp_Count>;
};

export type Items_Localization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Items_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Items_Localization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "items_localization" */
export type Items_Localization_Aggregate_Fields = {
  __typename?: 'items_localization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Localization_Max_Fields>;
  min?: Maybe<Items_Localization_Min_Fields>;
};


/** aggregate fields of "items_localization" */
export type Items_Localization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "items_localization" */
export type Items_Localization_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Items_Localization_Max_Order_By>;
  min?: InputMaybe<Items_Localization_Min_Order_By>;
};

/** input type for inserting array relation for remote table "items_localization" */
export type Items_Localization_Arr_Rel_Insert_Input = {
  data: Array<Items_Localization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_Localization_On_Conflict>;
};

/** Boolean expression to filter rows from the table "items_localization". All fields are combined with a logical 'AND'. */
export type Items_Localization_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Localization_Bool_Exp>>;
  _not?: InputMaybe<Items_Localization_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Localization_Bool_Exp>>;
  class_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  item_name_key?: InputMaybe<String_Comparison_Exp>;
  item_name_localized?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_localization" */
export enum Items_Localization_Constraint {
  /** unique or primary key constraint on columns "locale", "item_name_key" */
  ItemsLocalizationPkey = 'items_localization_pkey'
}

/** input type for inserting data into table "items_localization" */
export type Items_Localization_Insert_Input = {
  class_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_name_key?: InputMaybe<Scalars['String']>;
  item_name_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Items_Localization_Max_Fields = {
  __typename?: 'items_localization_max_fields';
  class_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  item_name_key?: Maybe<Scalars['String']>;
  item_name_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "items_localization" */
export type Items_Localization_Max_Order_By = {
  class_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  item_name_key?: InputMaybe<Order_By>;
  item_name_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Items_Localization_Min_Fields = {
  __typename?: 'items_localization_min_fields';
  class_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  item_name_key?: Maybe<Scalars['String']>;
  item_name_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "items_localization" */
export type Items_Localization_Min_Order_By = {
  class_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  item_name_key?: InputMaybe<Order_By>;
  item_name_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "items_localization" */
export type Items_Localization_Mutation_Response = {
  __typename?: 'items_localization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Localization>;
};

/** input type for inserting object relation for remote table "items_localization" */
export type Items_Localization_Obj_Rel_Insert_Input = {
  data: Items_Localization_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_Localization_On_Conflict>;
};

/** on_conflict condition type for table "items_localization" */
export type Items_Localization_On_Conflict = {
  constraint: Items_Localization_Constraint;
  update_columns?: Array<Items_Localization_Update_Column>;
  where?: InputMaybe<Items_Localization_Bool_Exp>;
};

/** Ordering options when selecting data from "items_localization". */
export type Items_Localization_Order_By = {
  class_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  item_name_key?: InputMaybe<Order_By>;
  item_name_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_localization */
export type Items_Localization_Pk_Columns_Input = {
  item_name_key: Scalars['String'];
  locale: Scalars['String'];
};

/** select columns of table "items_localization" */
export enum Items_Localization_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ItemNameKey = 'item_name_key',
  /** column name */
  ItemNameLocalized = 'item_name_localized',
  /** column name */
  Locale = 'locale',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "items_localization" */
export type Items_Localization_Set_Input = {
  class_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_name_key?: InputMaybe<Scalars['String']>;
  item_name_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "items_localization" */
export type Items_Localization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Localization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Localization_Stream_Cursor_Value_Input = {
  class_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_name_key?: InputMaybe<Scalars['String']>;
  item_name_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "items_localization" */
export enum Items_Localization_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ItemNameKey = 'item_name_key',
  /** column name */
  ItemNameLocalized = 'item_name_localized',
  /** column name */
  Locale = 'locale',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Items_Localization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Localization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Localization_Bool_Exp;
};

/** aggregate max on columns */
export type Items_Max_Fields = {
  __typename?: 'items_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  item_collection?: Maybe<Scalars['Int']>;
  item_custom_image_link?: Maybe<Scalars['String']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_max_count_on_market?: Maybe<Scalars['Int']>;
  item_min_price?: Maybe<Scalars['Float']>;
  item_name?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_steam_class_id?: Maybe<Scalars['bigint']>;
  item_steam_instance_id?: Maybe<Scalars['bigint']>;
  item_type?: Maybe<Scalars['String']>;
  item_weapon?: Maybe<Scalars['String']>;
  market_source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Items_Min_Fields = {
  __typename?: 'items_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  item_collection?: Maybe<Scalars['Int']>;
  item_custom_image_link?: Maybe<Scalars['String']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_max_count_on_market?: Maybe<Scalars['Int']>;
  item_min_price?: Maybe<Scalars['Float']>;
  item_name?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_steam_class_id?: Maybe<Scalars['bigint']>;
  item_steam_instance_id?: Maybe<Scalars['bigint']>;
  item_type?: Maybe<Scalars['String']>;
  item_weapon?: Maybe<Scalars['String']>;
  market_source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "items" */
export type Items_Mutation_Response = {
  __typename?: 'items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items>;
};

/** columns and relationships of "items_names" */
export type Items_Names = {
  __typename?: 'items_names';
  name: Scalars['String'];
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "items_names" */
export type Items_Names_Aggregate = {
  __typename?: 'items_names_aggregate';
  aggregate?: Maybe<Items_Names_Aggregate_Fields>;
  nodes: Array<Items_Names>;
};

/** aggregate fields of "items_names" */
export type Items_Names_Aggregate_Fields = {
  __typename?: 'items_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Names_Max_Fields>;
  min?: Maybe<Items_Names_Min_Fields>;
};


/** aggregate fields of "items_names" */
export type Items_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "items_names". All fields are combined with a logical 'AND'. */
export type Items_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Names_Bool_Exp>>;
  _not?: InputMaybe<Items_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Names_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_names" */
export enum Items_Names_Constraint {
  /** unique or primary key constraint on columns "name" */
  ItemsNamesPkey = 'items_names_pkey'
}

/** input type for inserting data into table "items_names" */
export type Items_Names_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Items_Names_Max_Fields = {
  __typename?: 'items_names_max_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Items_Names_Min_Fields = {
  __typename?: 'items_names_min_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "items_names" */
export type Items_Names_Mutation_Response = {
  __typename?: 'items_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Names>;
};

/** input type for inserting object relation for remote table "items_names" */
export type Items_Names_Obj_Rel_Insert_Input = {
  data: Items_Names_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_Names_On_Conflict>;
};

/** on_conflict condition type for table "items_names" */
export type Items_Names_On_Conflict = {
  constraint: Items_Names_Constraint;
  update_columns?: Array<Items_Names_Update_Column>;
  where?: InputMaybe<Items_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "items_names". */
export type Items_Names_Order_By = {
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_names */
export type Items_Names_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "items_names" */
export enum Items_Names_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

/** input type for updating data in table "items_names" */
export type Items_Names_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "items_names" */
export type Items_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Names_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "items_names" */
export enum Items_Names_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

export type Items_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Names_Bool_Exp;
};

/** input type for inserting object relation for remote table "items" */
export type Items_Obj_Rel_Insert_Input = {
  data: Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_On_Conflict>;
};

/** on_conflict condition type for table "items" */
export type Items_On_Conflict = {
  constraint: Items_Constraint;
  update_columns?: Array<Items_Update_Column>;
  where?: InputMaybe<Items_Bool_Exp>;
};

/** Ordering options when selecting data from "items". */
export type Items_Order_By = {
  created_at?: InputMaybe<Order_By>;
  item_collection?: InputMaybe<Order_By>;
  item_custom_image_link?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_hash_name_localized?: InputMaybe<Items_Localization_Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_count_on_market?: InputMaybe<Order_By>;
  item_min_price?: InputMaybe<Order_By>;
  item_name?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_steam_class_id?: InputMaybe<Order_By>;
  item_steam_instance_id?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  item_weapon?: InputMaybe<Order_By>;
  items_histories_aggregate?: InputMaybe<Items_History_Aggregate_Order_By>;
  localization_aggregate?: InputMaybe<Items_Localization_Aggregate_Order_By>;
  localization_quality_aggregate?: InputMaybe<Items_Quality_Localization_Aggregate_Order_By>;
  market_source?: InputMaybe<Order_By>;
  name_localized?: InputMaybe<Items_Items_Names_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items */
export type Items_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
};

/** columns and relationships of "items_quality" */
export type Items_Quality = {
  __typename?: 'items_quality';
  /** An array relationship */
  items_csgomarkets: Array<Items_Csgomarket>;
  /** An aggregate relationship */
  items_csgomarkets_aggregate: Items_Csgomarket_Aggregate;
  name: Scalars['String'];
  ru_name?: Maybe<Scalars['String']>;
};


/** columns and relationships of "items_quality" */
export type Items_QualityItems_CsgomarketsArgs = {
  distinct_on?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};


/** columns and relationships of "items_quality" */
export type Items_QualityItems_Csgomarkets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};

/** aggregated selection of "items_quality" */
export type Items_Quality_Aggregate = {
  __typename?: 'items_quality_aggregate';
  aggregate?: Maybe<Items_Quality_Aggregate_Fields>;
  nodes: Array<Items_Quality>;
};

/** aggregate fields of "items_quality" */
export type Items_Quality_Aggregate_Fields = {
  __typename?: 'items_quality_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Quality_Max_Fields>;
  min?: Maybe<Items_Quality_Min_Fields>;
};


/** aggregate fields of "items_quality" */
export type Items_Quality_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Quality_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "items_quality". All fields are combined with a logical 'AND'. */
export type Items_Quality_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Quality_Bool_Exp>>;
  _not?: InputMaybe<Items_Quality_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Quality_Bool_Exp>>;
  items_csgomarkets?: InputMaybe<Items_Csgomarket_Bool_Exp>;
  items_csgomarkets_aggregate?: InputMaybe<Items_Csgomarket_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_quality" */
export enum Items_Quality_Constraint {
  /** unique or primary key constraint on columns "name" */
  ItemsQualityNameKey = 'items_quality_name_key',
  /** unique or primary key constraint on columns "name" */
  ItemsQualityPkey = 'items_quality_pkey'
}

/** input type for inserting data into table "items_quality" */
export type Items_Quality_Insert_Input = {
  items_csgomarkets?: InputMaybe<Items_Csgomarket_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "items_quality_localization" */
export type Items_Quality_Localization = {
  __typename?: 'items_quality_localization';
  locale: Scalars['String'];
  quality_name_key: Scalars['String'];
  quality_name_localized: Scalars['String'];
};

/** aggregated selection of "items_quality_localization" */
export type Items_Quality_Localization_Aggregate = {
  __typename?: 'items_quality_localization_aggregate';
  aggregate?: Maybe<Items_Quality_Localization_Aggregate_Fields>;
  nodes: Array<Items_Quality_Localization>;
};

export type Items_Quality_Localization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Items_Quality_Localization_Aggregate_Bool_Exp_Count>;
};

export type Items_Quality_Localization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Items_Quality_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Items_Quality_Localization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "items_quality_localization" */
export type Items_Quality_Localization_Aggregate_Fields = {
  __typename?: 'items_quality_localization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Quality_Localization_Max_Fields>;
  min?: Maybe<Items_Quality_Localization_Min_Fields>;
};


/** aggregate fields of "items_quality_localization" */
export type Items_Quality_Localization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Quality_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "items_quality_localization" */
export type Items_Quality_Localization_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Items_Quality_Localization_Max_Order_By>;
  min?: InputMaybe<Items_Quality_Localization_Min_Order_By>;
};

/** input type for inserting array relation for remote table "items_quality_localization" */
export type Items_Quality_Localization_Arr_Rel_Insert_Input = {
  data: Array<Items_Quality_Localization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_Quality_Localization_On_Conflict>;
};

/** Boolean expression to filter rows from the table "items_quality_localization". All fields are combined with a logical 'AND'. */
export type Items_Quality_Localization_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Quality_Localization_Bool_Exp>>;
  _not?: InputMaybe<Items_Quality_Localization_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Quality_Localization_Bool_Exp>>;
  locale?: InputMaybe<String_Comparison_Exp>;
  quality_name_key?: InputMaybe<String_Comparison_Exp>;
  quality_name_localized?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_quality_localization" */
export enum Items_Quality_Localization_Constraint {
  /** unique or primary key constraint on columns "quality_name_key" */
  ItemsQualityNameKey = 'items_quality_name_key',
  /** unique or primary key constraint on columns "quality_name_key", "locale" */
  ItemsQualityPkey = 'items_quality_pkey'
}

/** input type for inserting data into table "items_quality_localization" */
export type Items_Quality_Localization_Insert_Input = {
  locale?: InputMaybe<Scalars['String']>;
  quality_name_key?: InputMaybe<Scalars['String']>;
  quality_name_localized?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Items_Quality_Localization_Max_Fields = {
  __typename?: 'items_quality_localization_max_fields';
  locale?: Maybe<Scalars['String']>;
  quality_name_key?: Maybe<Scalars['String']>;
  quality_name_localized?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "items_quality_localization" */
export type Items_Quality_Localization_Max_Order_By = {
  locale?: InputMaybe<Order_By>;
  quality_name_key?: InputMaybe<Order_By>;
  quality_name_localized?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Items_Quality_Localization_Min_Fields = {
  __typename?: 'items_quality_localization_min_fields';
  locale?: Maybe<Scalars['String']>;
  quality_name_key?: Maybe<Scalars['String']>;
  quality_name_localized?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "items_quality_localization" */
export type Items_Quality_Localization_Min_Order_By = {
  locale?: InputMaybe<Order_By>;
  quality_name_key?: InputMaybe<Order_By>;
  quality_name_localized?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "items_quality_localization" */
export type Items_Quality_Localization_Mutation_Response = {
  __typename?: 'items_quality_localization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Quality_Localization>;
};

/** on_conflict condition type for table "items_quality_localization" */
export type Items_Quality_Localization_On_Conflict = {
  constraint: Items_Quality_Localization_Constraint;
  update_columns?: Array<Items_Quality_Localization_Update_Column>;
  where?: InputMaybe<Items_Quality_Localization_Bool_Exp>;
};

/** Ordering options when selecting data from "items_quality_localization". */
export type Items_Quality_Localization_Order_By = {
  locale?: InputMaybe<Order_By>;
  quality_name_key?: InputMaybe<Order_By>;
  quality_name_localized?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_quality_localization */
export type Items_Quality_Localization_Pk_Columns_Input = {
  locale: Scalars['String'];
  quality_name_key: Scalars['String'];
};

/** select columns of table "items_quality_localization" */
export enum Items_Quality_Localization_Select_Column {
  /** column name */
  Locale = 'locale',
  /** column name */
  QualityNameKey = 'quality_name_key',
  /** column name */
  QualityNameLocalized = 'quality_name_localized'
}

/** input type for updating data in table "items_quality_localization" */
export type Items_Quality_Localization_Set_Input = {
  locale?: InputMaybe<Scalars['String']>;
  quality_name_key?: InputMaybe<Scalars['String']>;
  quality_name_localized?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "items_quality_localization" */
export type Items_Quality_Localization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Quality_Localization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Quality_Localization_Stream_Cursor_Value_Input = {
  locale?: InputMaybe<Scalars['String']>;
  quality_name_key?: InputMaybe<Scalars['String']>;
  quality_name_localized?: InputMaybe<Scalars['String']>;
};

/** update columns of table "items_quality_localization" */
export enum Items_Quality_Localization_Update_Column {
  /** column name */
  Locale = 'locale',
  /** column name */
  QualityNameKey = 'quality_name_key',
  /** column name */
  QualityNameLocalized = 'quality_name_localized'
}

export type Items_Quality_Localization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Quality_Localization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Quality_Localization_Bool_Exp;
};

/** aggregate max on columns */
export type Items_Quality_Max_Fields = {
  __typename?: 'items_quality_max_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Items_Quality_Min_Fields = {
  __typename?: 'items_quality_min_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "items_quality" */
export type Items_Quality_Mutation_Response = {
  __typename?: 'items_quality_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Quality>;
};

/** input type for inserting object relation for remote table "items_quality" */
export type Items_Quality_Obj_Rel_Insert_Input = {
  data: Items_Quality_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_Quality_On_Conflict>;
};

/** on_conflict condition type for table "items_quality" */
export type Items_Quality_On_Conflict = {
  constraint: Items_Quality_Constraint;
  update_columns?: Array<Items_Quality_Update_Column>;
  where?: InputMaybe<Items_Quality_Bool_Exp>;
};

/** Ordering options when selecting data from "items_quality". */
export type Items_Quality_Order_By = {
  items_csgomarkets_aggregate?: InputMaybe<Items_Csgomarket_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_quality */
export type Items_Quality_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "items_quality" */
export enum Items_Quality_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

/** input type for updating data in table "items_quality" */
export type Items_Quality_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "items_quality" */
export type Items_Quality_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Quality_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Quality_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "items_quality" */
export enum Items_Quality_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

export type Items_Quality_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Quality_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Quality_Bool_Exp;
};

/** columns and relationships of "items_rarity" */
export type Items_Rarity = {
  __typename?: 'items_rarity';
  color: Scalars['String'];
  /** An array relationship */
  items_csgomarkets: Array<Items_Csgomarket>;
  /** An aggregate relationship */
  items_csgomarkets_aggregate: Items_Csgomarket_Aggregate;
  name: Scalars['String'];
  ru_name: Scalars['String'];
};


/** columns and relationships of "items_rarity" */
export type Items_RarityItems_CsgomarketsArgs = {
  distinct_on?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};


/** columns and relationships of "items_rarity" */
export type Items_RarityItems_Csgomarkets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};

/** aggregated selection of "items_rarity" */
export type Items_Rarity_Aggregate = {
  __typename?: 'items_rarity_aggregate';
  aggregate?: Maybe<Items_Rarity_Aggregate_Fields>;
  nodes: Array<Items_Rarity>;
};

/** aggregate fields of "items_rarity" */
export type Items_Rarity_Aggregate_Fields = {
  __typename?: 'items_rarity_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Rarity_Max_Fields>;
  min?: Maybe<Items_Rarity_Min_Fields>;
};


/** aggregate fields of "items_rarity" */
export type Items_Rarity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Rarity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "items_rarity". All fields are combined with a logical 'AND'. */
export type Items_Rarity_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Rarity_Bool_Exp>>;
  _not?: InputMaybe<Items_Rarity_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Rarity_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  items_csgomarkets?: InputMaybe<Items_Csgomarket_Bool_Exp>;
  items_csgomarkets_aggregate?: InputMaybe<Items_Csgomarket_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_rarity" */
export enum Items_Rarity_Constraint {
  /** unique or primary key constraint on columns "name" */
  ItemsRarityNameKey = 'items_rarity_name_key',
  /** unique or primary key constraint on columns "name" */
  ItemsRarityPkey = 'items_rarity_pkey'
}

/** input type for inserting data into table "items_rarity" */
export type Items_Rarity_Insert_Input = {
  color?: InputMaybe<Scalars['String']>;
  items_csgomarkets?: InputMaybe<Items_Csgomarket_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "items_rarity_localization" */
export type Items_Rarity_Localization = {
  __typename?: 'items_rarity_localization';
  locale: Scalars['String'];
  rarity_name_key: Scalars['String'];
  rarity_name_localized: Scalars['String'];
};

/** aggregated selection of "items_rarity_localization" */
export type Items_Rarity_Localization_Aggregate = {
  __typename?: 'items_rarity_localization_aggregate';
  aggregate?: Maybe<Items_Rarity_Localization_Aggregate_Fields>;
  nodes: Array<Items_Rarity_Localization>;
};

/** aggregate fields of "items_rarity_localization" */
export type Items_Rarity_Localization_Aggregate_Fields = {
  __typename?: 'items_rarity_localization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Rarity_Localization_Max_Fields>;
  min?: Maybe<Items_Rarity_Localization_Min_Fields>;
};


/** aggregate fields of "items_rarity_localization" */
export type Items_Rarity_Localization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Rarity_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "items_rarity_localization". All fields are combined with a logical 'AND'. */
export type Items_Rarity_Localization_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Rarity_Localization_Bool_Exp>>;
  _not?: InputMaybe<Items_Rarity_Localization_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Rarity_Localization_Bool_Exp>>;
  locale?: InputMaybe<String_Comparison_Exp>;
  rarity_name_key?: InputMaybe<String_Comparison_Exp>;
  rarity_name_localized?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_rarity_localization" */
export enum Items_Rarity_Localization_Constraint {
  /** unique or primary key constraint on columns "rarity_name_key" */
  ItemsRarityNameKey = 'items_rarity_name_key',
  /** unique or primary key constraint on columns "rarity_name_key" */
  ItemsRarityPkey = 'items_rarity_pkey'
}

/** input type for inserting data into table "items_rarity_localization" */
export type Items_Rarity_Localization_Insert_Input = {
  locale?: InputMaybe<Scalars['String']>;
  rarity_name_key?: InputMaybe<Scalars['String']>;
  rarity_name_localized?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Items_Rarity_Localization_Max_Fields = {
  __typename?: 'items_rarity_localization_max_fields';
  locale?: Maybe<Scalars['String']>;
  rarity_name_key?: Maybe<Scalars['String']>;
  rarity_name_localized?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Items_Rarity_Localization_Min_Fields = {
  __typename?: 'items_rarity_localization_min_fields';
  locale?: Maybe<Scalars['String']>;
  rarity_name_key?: Maybe<Scalars['String']>;
  rarity_name_localized?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "items_rarity_localization" */
export type Items_Rarity_Localization_Mutation_Response = {
  __typename?: 'items_rarity_localization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Rarity_Localization>;
};

/** on_conflict condition type for table "items_rarity_localization" */
export type Items_Rarity_Localization_On_Conflict = {
  constraint: Items_Rarity_Localization_Constraint;
  update_columns?: Array<Items_Rarity_Localization_Update_Column>;
  where?: InputMaybe<Items_Rarity_Localization_Bool_Exp>;
};

/** Ordering options when selecting data from "items_rarity_localization". */
export type Items_Rarity_Localization_Order_By = {
  locale?: InputMaybe<Order_By>;
  rarity_name_key?: InputMaybe<Order_By>;
  rarity_name_localized?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_rarity_localization */
export type Items_Rarity_Localization_Pk_Columns_Input = {
  rarity_name_key: Scalars['String'];
};

/** select columns of table "items_rarity_localization" */
export enum Items_Rarity_Localization_Select_Column {
  /** column name */
  Locale = 'locale',
  /** column name */
  RarityNameKey = 'rarity_name_key',
  /** column name */
  RarityNameLocalized = 'rarity_name_localized'
}

/** input type for updating data in table "items_rarity_localization" */
export type Items_Rarity_Localization_Set_Input = {
  locale?: InputMaybe<Scalars['String']>;
  rarity_name_key?: InputMaybe<Scalars['String']>;
  rarity_name_localized?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "items_rarity_localization" */
export type Items_Rarity_Localization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Rarity_Localization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Rarity_Localization_Stream_Cursor_Value_Input = {
  locale?: InputMaybe<Scalars['String']>;
  rarity_name_key?: InputMaybe<Scalars['String']>;
  rarity_name_localized?: InputMaybe<Scalars['String']>;
};

/** update columns of table "items_rarity_localization" */
export enum Items_Rarity_Localization_Update_Column {
  /** column name */
  Locale = 'locale',
  /** column name */
  RarityNameKey = 'rarity_name_key',
  /** column name */
  RarityNameLocalized = 'rarity_name_localized'
}

export type Items_Rarity_Localization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Rarity_Localization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Rarity_Localization_Bool_Exp;
};

/** aggregate max on columns */
export type Items_Rarity_Max_Fields = {
  __typename?: 'items_rarity_max_fields';
  color?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Items_Rarity_Min_Fields = {
  __typename?: 'items_rarity_min_fields';
  color?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "items_rarity" */
export type Items_Rarity_Mutation_Response = {
  __typename?: 'items_rarity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Rarity>;
};

/** input type for inserting object relation for remote table "items_rarity" */
export type Items_Rarity_Obj_Rel_Insert_Input = {
  data: Items_Rarity_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_Rarity_On_Conflict>;
};

/** on_conflict condition type for table "items_rarity" */
export type Items_Rarity_On_Conflict = {
  constraint: Items_Rarity_Constraint;
  update_columns?: Array<Items_Rarity_Update_Column>;
  where?: InputMaybe<Items_Rarity_Bool_Exp>;
};

/** Ordering options when selecting data from "items_rarity". */
export type Items_Rarity_Order_By = {
  color?: InputMaybe<Order_By>;
  items_csgomarkets_aggregate?: InputMaybe<Items_Csgomarket_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_rarity */
export type Items_Rarity_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "items_rarity" */
export enum Items_Rarity_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

/** input type for updating data in table "items_rarity" */
export type Items_Rarity_Set_Input = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "items_rarity" */
export type Items_Rarity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Rarity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Rarity_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "items_rarity" */
export enum Items_Rarity_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

export type Items_Rarity_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Rarity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Rarity_Bool_Exp;
};

/** select columns of table "items" */
export enum Items_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ItemCollection = 'item_collection',
  /** column name */
  ItemCustomImageLink = 'item_custom_image_link',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemMaxCountOnMarket = 'item_max_count_on_market',
  /** column name */
  ItemMinPrice = 'item_min_price',
  /** column name */
  ItemName = 'item_name',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemSteamClassId = 'item_steam_class_id',
  /** column name */
  ItemSteamInstanceId = 'item_steam_instance_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  ItemWeapon = 'item_weapon',
  /** column name */
  MarketSource = 'market_source',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "items" */
export type Items_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_collection?: InputMaybe<Scalars['Int']>;
  item_custom_image_link?: InputMaybe<Scalars['String']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_count_on_market?: InputMaybe<Scalars['Int']>;
  item_min_price?: InputMaybe<Scalars['Float']>;
  item_name?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_steam_class_id?: InputMaybe<Scalars['bigint']>;
  item_steam_instance_id?: InputMaybe<Scalars['bigint']>;
  item_type?: InputMaybe<Scalars['String']>;
  item_weapon?: InputMaybe<Scalars['String']>;
  market_source?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Items_Stddev_Fields = {
  __typename?: 'items_stddev_fields';
  item_collection?: Maybe<Scalars['Float']>;
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
  item_steam_class_id?: Maybe<Scalars['Float']>;
  item_steam_instance_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Items_Stddev_Pop_Fields = {
  __typename?: 'items_stddev_pop_fields';
  item_collection?: Maybe<Scalars['Float']>;
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
  item_steam_class_id?: Maybe<Scalars['Float']>;
  item_steam_instance_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Items_Stddev_Samp_Fields = {
  __typename?: 'items_stddev_samp_fields';
  item_collection?: Maybe<Scalars['Float']>;
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
  item_steam_class_id?: Maybe<Scalars['Float']>;
  item_steam_instance_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "items" */
export type Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_collection?: InputMaybe<Scalars['Int']>;
  item_custom_image_link?: InputMaybe<Scalars['String']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_count_on_market?: InputMaybe<Scalars['Int']>;
  item_min_price?: InputMaybe<Scalars['Float']>;
  item_name?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_steam_class_id?: InputMaybe<Scalars['bigint']>;
  item_steam_instance_id?: InputMaybe<Scalars['bigint']>;
  item_type?: InputMaybe<Scalars['String']>;
  item_weapon?: InputMaybe<Scalars['String']>;
  market_source?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Items_Sum_Fields = {
  __typename?: 'items_sum_fields';
  item_collection?: Maybe<Scalars['Int']>;
  item_max_count_on_market?: Maybe<Scalars['Int']>;
  item_min_price?: Maybe<Scalars['Float']>;
  item_steam_class_id?: Maybe<Scalars['bigint']>;
  item_steam_instance_id?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "items_type" */
export type Items_Type = {
  __typename?: 'items_type';
  /** An array relationship */
  group_items_csgomarkets: Array<Group_Items_Csgomarket>;
  /** An aggregate relationship */
  group_items_csgomarkets_aggregate: Group_Items_Csgomarket_Aggregate;
  type: Scalars['String'];
};


/** columns and relationships of "items_type" */
export type Items_TypeGroup_Items_CsgomarketsArgs = {
  distinct_on?: InputMaybe<Array<Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
};


/** columns and relationships of "items_type" */
export type Items_TypeGroup_Items_Csgomarkets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
};

/** aggregated selection of "items_type" */
export type Items_Type_Aggregate = {
  __typename?: 'items_type_aggregate';
  aggregate?: Maybe<Items_Type_Aggregate_Fields>;
  nodes: Array<Items_Type>;
};

/** aggregate fields of "items_type" */
export type Items_Type_Aggregate_Fields = {
  __typename?: 'items_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Type_Max_Fields>;
  min?: Maybe<Items_Type_Min_Fields>;
};


/** aggregate fields of "items_type" */
export type Items_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "items_type". All fields are combined with a logical 'AND'. */
export type Items_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Type_Bool_Exp>>;
  _not?: InputMaybe<Items_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Type_Bool_Exp>>;
  group_items_csgomarkets?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
  group_items_csgomarkets_aggregate?: InputMaybe<Group_Items_Csgomarket_Aggregate_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_type" */
export enum Items_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  ItemsTypePkey = 'items_type_pkey'
}

/** input type for inserting data into table "items_type" */
export type Items_Type_Insert_Input = {
  group_items_csgomarkets?: InputMaybe<Group_Items_Csgomarket_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "items_type_localization" */
export type Items_Type_Localization = {
  __typename?: 'items_type_localization';
  locale: Scalars['String'];
  type_name_key: Scalars['String'];
  type_name_localized?: Maybe<Scalars['String']>;
};

/** aggregated selection of "items_type_localization" */
export type Items_Type_Localization_Aggregate = {
  __typename?: 'items_type_localization_aggregate';
  aggregate?: Maybe<Items_Type_Localization_Aggregate_Fields>;
  nodes: Array<Items_Type_Localization>;
};

/** aggregate fields of "items_type_localization" */
export type Items_Type_Localization_Aggregate_Fields = {
  __typename?: 'items_type_localization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Type_Localization_Max_Fields>;
  min?: Maybe<Items_Type_Localization_Min_Fields>;
};


/** aggregate fields of "items_type_localization" */
export type Items_Type_Localization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Type_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "items_type_localization". All fields are combined with a logical 'AND'. */
export type Items_Type_Localization_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Type_Localization_Bool_Exp>>;
  _not?: InputMaybe<Items_Type_Localization_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Type_Localization_Bool_Exp>>;
  locale?: InputMaybe<String_Comparison_Exp>;
  type_name_key?: InputMaybe<String_Comparison_Exp>;
  type_name_localized?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_type_localization" */
export enum Items_Type_Localization_Constraint {
  /** unique or primary key constraint on columns "type_name_key", "locale" */
  ItemsTypePkey = 'items_type_pkey'
}

/** input type for inserting data into table "items_type_localization" */
export type Items_Type_Localization_Insert_Input = {
  locale?: InputMaybe<Scalars['String']>;
  type_name_key?: InputMaybe<Scalars['String']>;
  type_name_localized?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Items_Type_Localization_Max_Fields = {
  __typename?: 'items_type_localization_max_fields';
  locale?: Maybe<Scalars['String']>;
  type_name_key?: Maybe<Scalars['String']>;
  type_name_localized?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Items_Type_Localization_Min_Fields = {
  __typename?: 'items_type_localization_min_fields';
  locale?: Maybe<Scalars['String']>;
  type_name_key?: Maybe<Scalars['String']>;
  type_name_localized?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "items_type_localization" */
export type Items_Type_Localization_Mutation_Response = {
  __typename?: 'items_type_localization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Type_Localization>;
};

/** on_conflict condition type for table "items_type_localization" */
export type Items_Type_Localization_On_Conflict = {
  constraint: Items_Type_Localization_Constraint;
  update_columns?: Array<Items_Type_Localization_Update_Column>;
  where?: InputMaybe<Items_Type_Localization_Bool_Exp>;
};

/** Ordering options when selecting data from "items_type_localization". */
export type Items_Type_Localization_Order_By = {
  locale?: InputMaybe<Order_By>;
  type_name_key?: InputMaybe<Order_By>;
  type_name_localized?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_type_localization */
export type Items_Type_Localization_Pk_Columns_Input = {
  locale: Scalars['String'];
  type_name_key: Scalars['String'];
};

/** select columns of table "items_type_localization" */
export enum Items_Type_Localization_Select_Column {
  /** column name */
  Locale = 'locale',
  /** column name */
  TypeNameKey = 'type_name_key',
  /** column name */
  TypeNameLocalized = 'type_name_localized'
}

/** input type for updating data in table "items_type_localization" */
export type Items_Type_Localization_Set_Input = {
  locale?: InputMaybe<Scalars['String']>;
  type_name_key?: InputMaybe<Scalars['String']>;
  type_name_localized?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "items_type_localization" */
export type Items_Type_Localization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Type_Localization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Type_Localization_Stream_Cursor_Value_Input = {
  locale?: InputMaybe<Scalars['String']>;
  type_name_key?: InputMaybe<Scalars['String']>;
  type_name_localized?: InputMaybe<Scalars['String']>;
};

/** update columns of table "items_type_localization" */
export enum Items_Type_Localization_Update_Column {
  /** column name */
  Locale = 'locale',
  /** column name */
  TypeNameKey = 'type_name_key',
  /** column name */
  TypeNameLocalized = 'type_name_localized'
}

export type Items_Type_Localization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Type_Localization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Type_Localization_Bool_Exp;
};

/** aggregate max on columns */
export type Items_Type_Max_Fields = {
  __typename?: 'items_type_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Items_Type_Min_Fields = {
  __typename?: 'items_type_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "items_type" */
export type Items_Type_Mutation_Response = {
  __typename?: 'items_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Type>;
};

/** input type for inserting object relation for remote table "items_type" */
export type Items_Type_Obj_Rel_Insert_Input = {
  data: Items_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_Type_On_Conflict>;
};

/** on_conflict condition type for table "items_type" */
export type Items_Type_On_Conflict = {
  constraint: Items_Type_Constraint;
  update_columns?: Array<Items_Type_Update_Column>;
  where?: InputMaybe<Items_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "items_type". */
export type Items_Type_Order_By = {
  group_items_csgomarkets_aggregate?: InputMaybe<Group_Items_Csgomarket_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_type */
export type Items_Type_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "items_type" */
export enum Items_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "items_type" */
export type Items_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "items_type" */
export type Items_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "items_type" */
export enum Items_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type Items_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Type_Bool_Exp;
};

/** update columns of table "items" */
export enum Items_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ItemCollection = 'item_collection',
  /** column name */
  ItemCustomImageLink = 'item_custom_image_link',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemMaxCountOnMarket = 'item_max_count_on_market',
  /** column name */
  ItemMinPrice = 'item_min_price',
  /** column name */
  ItemName = 'item_name',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemSteamClassId = 'item_steam_class_id',
  /** column name */
  ItemSteamInstanceId = 'item_steam_instance_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  ItemWeapon = 'item_weapon',
  /** column name */
  MarketSource = 'market_source',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Items_Var_Pop_Fields = {
  __typename?: 'items_var_pop_fields';
  item_collection?: Maybe<Scalars['Float']>;
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
  item_steam_class_id?: Maybe<Scalars['Float']>;
  item_steam_instance_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Items_Var_Samp_Fields = {
  __typename?: 'items_var_samp_fields';
  item_collection?: Maybe<Scalars['Float']>;
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
  item_steam_class_id?: Maybe<Scalars['Float']>;
  item_steam_instance_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Items_Variance_Fields = {
  __typename?: 'items_variance_fields';
  item_collection?: Maybe<Scalars['Float']>;
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  item_min_price?: Maybe<Scalars['Float']>;
  item_steam_class_id?: Maybe<Scalars['Float']>;
  item_steam_instance_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "items_weapon_localization" */
export type Items_Weapon_Localization = {
  __typename?: 'items_weapon_localization';
  locale: Scalars['String'];
  weapon_name_key: Scalars['String'];
  weapon_name_localized: Scalars['String'];
};

/** aggregated selection of "items_weapon_localization" */
export type Items_Weapon_Localization_Aggregate = {
  __typename?: 'items_weapon_localization_aggregate';
  aggregate?: Maybe<Items_Weapon_Localization_Aggregate_Fields>;
  nodes: Array<Items_Weapon_Localization>;
};

/** aggregate fields of "items_weapon_localization" */
export type Items_Weapon_Localization_Aggregate_Fields = {
  __typename?: 'items_weapon_localization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Weapon_Localization_Max_Fields>;
  min?: Maybe<Items_Weapon_Localization_Min_Fields>;
};


/** aggregate fields of "items_weapon_localization" */
export type Items_Weapon_Localization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Weapon_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "items_weapon_localization". All fields are combined with a logical 'AND'. */
export type Items_Weapon_Localization_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Weapon_Localization_Bool_Exp>>;
  _not?: InputMaybe<Items_Weapon_Localization_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Weapon_Localization_Bool_Exp>>;
  locale?: InputMaybe<String_Comparison_Exp>;
  weapon_name_key?: InputMaybe<String_Comparison_Exp>;
  weapon_name_localized?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_weapon_localization" */
export enum Items_Weapon_Localization_Constraint {
  /** unique or primary key constraint on columns "weapon_name_key", "locale" */
  ItemsWeaponsPkey = 'items_weapons_pkey'
}

/** input type for inserting data into table "items_weapon_localization" */
export type Items_Weapon_Localization_Insert_Input = {
  locale?: InputMaybe<Scalars['String']>;
  weapon_name_key?: InputMaybe<Scalars['String']>;
  weapon_name_localized?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Items_Weapon_Localization_Max_Fields = {
  __typename?: 'items_weapon_localization_max_fields';
  locale?: Maybe<Scalars['String']>;
  weapon_name_key?: Maybe<Scalars['String']>;
  weapon_name_localized?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Items_Weapon_Localization_Min_Fields = {
  __typename?: 'items_weapon_localization_min_fields';
  locale?: Maybe<Scalars['String']>;
  weapon_name_key?: Maybe<Scalars['String']>;
  weapon_name_localized?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "items_weapon_localization" */
export type Items_Weapon_Localization_Mutation_Response = {
  __typename?: 'items_weapon_localization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Weapon_Localization>;
};

/** on_conflict condition type for table "items_weapon_localization" */
export type Items_Weapon_Localization_On_Conflict = {
  constraint: Items_Weapon_Localization_Constraint;
  update_columns?: Array<Items_Weapon_Localization_Update_Column>;
  where?: InputMaybe<Items_Weapon_Localization_Bool_Exp>;
};

/** Ordering options when selecting data from "items_weapon_localization". */
export type Items_Weapon_Localization_Order_By = {
  locale?: InputMaybe<Order_By>;
  weapon_name_key?: InputMaybe<Order_By>;
  weapon_name_localized?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_weapon_localization */
export type Items_Weapon_Localization_Pk_Columns_Input = {
  locale: Scalars['String'];
  weapon_name_key: Scalars['String'];
};

/** select columns of table "items_weapon_localization" */
export enum Items_Weapon_Localization_Select_Column {
  /** column name */
  Locale = 'locale',
  /** column name */
  WeaponNameKey = 'weapon_name_key',
  /** column name */
  WeaponNameLocalized = 'weapon_name_localized'
}

/** input type for updating data in table "items_weapon_localization" */
export type Items_Weapon_Localization_Set_Input = {
  locale?: InputMaybe<Scalars['String']>;
  weapon_name_key?: InputMaybe<Scalars['String']>;
  weapon_name_localized?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "items_weapon_localization" */
export type Items_Weapon_Localization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Weapon_Localization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Weapon_Localization_Stream_Cursor_Value_Input = {
  locale?: InputMaybe<Scalars['String']>;
  weapon_name_key?: InputMaybe<Scalars['String']>;
  weapon_name_localized?: InputMaybe<Scalars['String']>;
};

/** update columns of table "items_weapon_localization" */
export enum Items_Weapon_Localization_Update_Column {
  /** column name */
  Locale = 'locale',
  /** column name */
  WeaponNameKey = 'weapon_name_key',
  /** column name */
  WeaponNameLocalized = 'weapon_name_localized'
}

export type Items_Weapon_Localization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Weapon_Localization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Weapon_Localization_Bool_Exp;
};

/** columns and relationships of "items_weapons" */
export type Items_Weapons = {
  __typename?: 'items_weapons';
  name: Scalars['String'];
  ru_name: Scalars['String'];
};

/** aggregated selection of "items_weapons" */
export type Items_Weapons_Aggregate = {
  __typename?: 'items_weapons_aggregate';
  aggregate?: Maybe<Items_Weapons_Aggregate_Fields>;
  nodes: Array<Items_Weapons>;
};

/** aggregate fields of "items_weapons" */
export type Items_Weapons_Aggregate_Fields = {
  __typename?: 'items_weapons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Weapons_Max_Fields>;
  min?: Maybe<Items_Weapons_Min_Fields>;
};


/** aggregate fields of "items_weapons" */
export type Items_Weapons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Weapons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "items_weapons". All fields are combined with a logical 'AND'. */
export type Items_Weapons_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Weapons_Bool_Exp>>;
  _not?: InputMaybe<Items_Weapons_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Weapons_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items_weapons" */
export enum Items_Weapons_Constraint {
  /** unique or primary key constraint on columns "name" */
  ItemsWeaponsPkey = 'items_weapons_pkey'
}

/** input type for inserting data into table "items_weapons" */
export type Items_Weapons_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Items_Weapons_Max_Fields = {
  __typename?: 'items_weapons_max_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Items_Weapons_Min_Fields = {
  __typename?: 'items_weapons_min_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "items_weapons" */
export type Items_Weapons_Mutation_Response = {
  __typename?: 'items_weapons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items_Weapons>;
};

/** on_conflict condition type for table "items_weapons" */
export type Items_Weapons_On_Conflict = {
  constraint: Items_Weapons_Constraint;
  update_columns?: Array<Items_Weapons_Update_Column>;
  where?: InputMaybe<Items_Weapons_Bool_Exp>;
};

/** Ordering options when selecting data from "items_weapons". */
export type Items_Weapons_Order_By = {
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items_weapons */
export type Items_Weapons_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "items_weapons" */
export enum Items_Weapons_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

/** input type for updating data in table "items_weapons" */
export type Items_Weapons_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "items_weapons" */
export type Items_Weapons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Weapons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Weapons_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "items_weapons" */
export enum Items_Weapons_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

export type Items_Weapons_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Weapons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Weapons_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export type List_Items_Random_Args = {
  seed?: InputMaybe<Scalars['seed_float']>;
};

/** Бустеры */
export type Lootbox_Booster_Items = {
  __typename?: 'lootbox_booster_items';
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Int'];
  /** An array relationship */
  localization: Array<Lootbox_Booster_Localization>;
  /** An aggregate relationship */
  localization_aggregate: Lootbox_Booster_Localization_Aggregate;
  /** An object relationship */
  new_year_competition_boosters_reward?: Maybe<New_Year_Competition_Boosters_Rewards>;
};


/** Бустеры */
export type Lootbox_Booster_ItemsLocalizationArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Localization_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
};


/** Бустеры */
export type Lootbox_Booster_ItemsLocalization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Localization_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
};

/** aggregated selection of "lootbox_booster_items" */
export type Lootbox_Booster_Items_Aggregate = {
  __typename?: 'lootbox_booster_items_aggregate';
  aggregate?: Maybe<Lootbox_Booster_Items_Aggregate_Fields>;
  nodes: Array<Lootbox_Booster_Items>;
};

/** aggregate fields of "lootbox_booster_items" */
export type Lootbox_Booster_Items_Aggregate_Fields = {
  __typename?: 'lootbox_booster_items_aggregate_fields';
  avg?: Maybe<Lootbox_Booster_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootbox_Booster_Items_Max_Fields>;
  min?: Maybe<Lootbox_Booster_Items_Min_Fields>;
  stddev?: Maybe<Lootbox_Booster_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Lootbox_Booster_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootbox_Booster_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Lootbox_Booster_Items_Sum_Fields>;
  var_pop?: Maybe<Lootbox_Booster_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Lootbox_Booster_Items_Var_Samp_Fields>;
  variance?: Maybe<Lootbox_Booster_Items_Variance_Fields>;
};


/** aggregate fields of "lootbox_booster_items" */
export type Lootbox_Booster_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootbox_Booster_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lootbox_Booster_Items_Avg_Fields = {
  __typename?: 'lootbox_booster_items_avg_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lootbox_booster_items". All fields are combined with a logical 'AND'. */
export type Lootbox_Booster_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Lootbox_Booster_Items_Bool_Exp>>;
  _not?: InputMaybe<Lootbox_Booster_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Lootbox_Booster_Items_Bool_Exp>>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_image_link?: InputMaybe<String_Comparison_Exp>;
  item_quality?: InputMaybe<String_Comparison_Exp>;
  item_rarity?: InputMaybe<String_Comparison_Exp>;
  item_start_price?: InputMaybe<Int_Comparison_Exp>;
  localization?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
  localization_aggregate?: InputMaybe<Lootbox_Booster_Localization_Aggregate_Bool_Exp>;
  new_year_competition_boosters_reward?: InputMaybe<New_Year_Competition_Boosters_Rewards_Bool_Exp>;
};

/** unique or primary key constraints on table "lootbox_booster_items" */
export enum Lootbox_Booster_Items_Constraint {
  /** unique or primary key constraint on columns "item_id" */
  LootboxBoosterItemsPkey = 'lootbox_booster_items_pkey'
}

/** input type for incrementing numeric columns in table "lootbox_booster_items" */
export type Lootbox_Booster_Items_Inc_Input = {
  item_start_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootbox_booster_items" */
export type Lootbox_Booster_Items_Insert_Input = {
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Int']>;
  localization?: InputMaybe<Lootbox_Booster_Localization_Arr_Rel_Insert_Input>;
  new_year_competition_boosters_reward?: InputMaybe<New_Year_Competition_Boosters_Rewards_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Lootbox_Booster_Items_Max_Fields = {
  __typename?: 'lootbox_booster_items_max_fields';
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Lootbox_Booster_Items_Min_Fields = {
  __typename?: 'lootbox_booster_items_min_fields';
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "lootbox_booster_items" */
export type Lootbox_Booster_Items_Mutation_Response = {
  __typename?: 'lootbox_booster_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootbox_Booster_Items>;
};

/** input type for inserting object relation for remote table "lootbox_booster_items" */
export type Lootbox_Booster_Items_Obj_Rel_Insert_Input = {
  data: Lootbox_Booster_Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootbox_Booster_Items_On_Conflict>;
};

/** on_conflict condition type for table "lootbox_booster_items" */
export type Lootbox_Booster_Items_On_Conflict = {
  constraint: Lootbox_Booster_Items_Constraint;
  update_columns?: Array<Lootbox_Booster_Items_Update_Column>;
  where?: InputMaybe<Lootbox_Booster_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "lootbox_booster_items". */
export type Lootbox_Booster_Items_Order_By = {
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  localization_aggregate?: InputMaybe<Lootbox_Booster_Localization_Aggregate_Order_By>;
  new_year_competition_boosters_reward?: InputMaybe<New_Year_Competition_Boosters_Rewards_Order_By>;
};

/** primary key columns input for table: lootbox_booster_items */
export type Lootbox_Booster_Items_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
};

/** select columns of table "lootbox_booster_items" */
export enum Lootbox_Booster_Items_Select_Column {
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price'
}

/** input type for updating data in table "lootbox_booster_items" */
export type Lootbox_Booster_Items_Set_Input = {
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lootbox_Booster_Items_Stddev_Fields = {
  __typename?: 'lootbox_booster_items_stddev_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lootbox_Booster_Items_Stddev_Pop_Fields = {
  __typename?: 'lootbox_booster_items_stddev_pop_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lootbox_Booster_Items_Stddev_Samp_Fields = {
  __typename?: 'lootbox_booster_items_stddev_samp_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lootbox_booster_items" */
export type Lootbox_Booster_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootbox_Booster_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootbox_Booster_Items_Stream_Cursor_Value_Input = {
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lootbox_Booster_Items_Sum_Fields = {
  __typename?: 'lootbox_booster_items_sum_fields';
  item_start_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "lootbox_booster_items" */
export enum Lootbox_Booster_Items_Update_Column {
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price'
}

export type Lootbox_Booster_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootbox_Booster_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootbox_Booster_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootbox_Booster_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootbox_Booster_Items_Var_Pop_Fields = {
  __typename?: 'lootbox_booster_items_var_pop_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lootbox_Booster_Items_Var_Samp_Fields = {
  __typename?: 'lootbox_booster_items_var_samp_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lootbox_Booster_Items_Variance_Fields = {
  __typename?: 'lootbox_booster_items_variance_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lootbox_booster_localization" */
export type Lootbox_Booster_Localization = {
  __typename?: 'lootbox_booster_localization';
  booster_name_key: Scalars['String'];
  booster_name_localized: Scalars['String'];
  locale: Scalars['String'];
};

/** aggregated selection of "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_Aggregate = {
  __typename?: 'lootbox_booster_localization_aggregate';
  aggregate?: Maybe<Lootbox_Booster_Localization_Aggregate_Fields>;
  nodes: Array<Lootbox_Booster_Localization>;
};

export type Lootbox_Booster_Localization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lootbox_Booster_Localization_Aggregate_Bool_Exp_Count>;
};

export type Lootbox_Booster_Localization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootbox_Booster_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_Aggregate_Fields = {
  __typename?: 'lootbox_booster_localization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lootbox_Booster_Localization_Max_Fields>;
  min?: Maybe<Lootbox_Booster_Localization_Min_Fields>;
};


/** aggregate fields of "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootbox_Booster_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootbox_Booster_Localization_Max_Order_By>;
  min?: InputMaybe<Lootbox_Booster_Localization_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_Arr_Rel_Insert_Input = {
  data: Array<Lootbox_Booster_Localization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootbox_Booster_Localization_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lootbox_booster_localization". All fields are combined with a logical 'AND'. */
export type Lootbox_Booster_Localization_Bool_Exp = {
  _and?: InputMaybe<Array<Lootbox_Booster_Localization_Bool_Exp>>;
  _not?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
  _or?: InputMaybe<Array<Lootbox_Booster_Localization_Bool_Exp>>;
  booster_name_key?: InputMaybe<String_Comparison_Exp>;
  booster_name_localized?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootbox_booster_localization" */
export enum Lootbox_Booster_Localization_Constraint {
  /** unique or primary key constraint on columns "locale", "booster_name_key" */
  LootboxBoosterLocalizationPkey = 'lootbox_booster_localization_pkey'
}

/** input type for inserting data into table "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_Insert_Input = {
  booster_name_key?: InputMaybe<Scalars['String']>;
  booster_name_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lootbox_Booster_Localization_Max_Fields = {
  __typename?: 'lootbox_booster_localization_max_fields';
  booster_name_key?: Maybe<Scalars['String']>;
  booster_name_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_Max_Order_By = {
  booster_name_key?: InputMaybe<Order_By>;
  booster_name_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootbox_Booster_Localization_Min_Fields = {
  __typename?: 'lootbox_booster_localization_min_fields';
  booster_name_key?: Maybe<Scalars['String']>;
  booster_name_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_Min_Order_By = {
  booster_name_key?: InputMaybe<Order_By>;
  booster_name_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_Mutation_Response = {
  __typename?: 'lootbox_booster_localization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootbox_Booster_Localization>;
};

/** on_conflict condition type for table "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_On_Conflict = {
  constraint: Lootbox_Booster_Localization_Constraint;
  update_columns?: Array<Lootbox_Booster_Localization_Update_Column>;
  where?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
};

/** Ordering options when selecting data from "lootbox_booster_localization". */
export type Lootbox_Booster_Localization_Order_By = {
  booster_name_key?: InputMaybe<Order_By>;
  booster_name_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootbox_booster_localization */
export type Lootbox_Booster_Localization_Pk_Columns_Input = {
  booster_name_key: Scalars['String'];
  locale: Scalars['String'];
};

/** select columns of table "lootbox_booster_localization" */
export enum Lootbox_Booster_Localization_Select_Column {
  /** column name */
  BoosterNameKey = 'booster_name_key',
  /** column name */
  BoosterNameLocalized = 'booster_name_localized',
  /** column name */
  Locale = 'locale'
}

/** input type for updating data in table "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_Set_Input = {
  booster_name_key?: InputMaybe<Scalars['String']>;
  booster_name_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lootbox_booster_localization" */
export type Lootbox_Booster_Localization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootbox_Booster_Localization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootbox_Booster_Localization_Stream_Cursor_Value_Input = {
  booster_name_key?: InputMaybe<Scalars['String']>;
  booster_name_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lootbox_booster_localization" */
export enum Lootbox_Booster_Localization_Update_Column {
  /** column name */
  BoosterNameKey = 'booster_name_key',
  /** column name */
  BoosterNameLocalized = 'booster_name_localized',
  /** column name */
  Locale = 'locale'
}

export type Lootbox_Booster_Localization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootbox_Booster_Localization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootbox_Booster_Localization_Bool_Exp;
};

/** columns and relationships of "lootbox_category" */
export type Lootbox_Category = {
  __typename?: 'lootbox_category';
  category_active: Scalars['Boolean'];
  category_anchor: Scalars['String'];
  category_id: Scalars['Int'];
  category_name: Scalars['String'];
  category_short_name: Scalars['String'];
  category_sort: Scalars['Int'];
  category_title: Scalars['String'];
  info_text?: Maybe<Scalars['String']>;
  /** An array relationship */
  lootboxes: Array<Lootboxes>;
  /** An aggregate relationship */
  lootboxes_aggregate: Lootboxes_Aggregate;
};


/** columns and relationships of "lootbox_category" */
export type Lootbox_CategoryLootboxesArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Bool_Exp>;
};


/** columns and relationships of "lootbox_category" */
export type Lootbox_CategoryLootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Bool_Exp>;
};

/** aggregated selection of "lootbox_category" */
export type Lootbox_Category_Aggregate = {
  __typename?: 'lootbox_category_aggregate';
  aggregate?: Maybe<Lootbox_Category_Aggregate_Fields>;
  nodes: Array<Lootbox_Category>;
};

/** aggregate fields of "lootbox_category" */
export type Lootbox_Category_Aggregate_Fields = {
  __typename?: 'lootbox_category_aggregate_fields';
  avg?: Maybe<Lootbox_Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootbox_Category_Max_Fields>;
  min?: Maybe<Lootbox_Category_Min_Fields>;
  stddev?: Maybe<Lootbox_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Lootbox_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootbox_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Lootbox_Category_Sum_Fields>;
  var_pop?: Maybe<Lootbox_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Lootbox_Category_Var_Samp_Fields>;
  variance?: Maybe<Lootbox_Category_Variance_Fields>;
};


/** aggregate fields of "lootbox_category" */
export type Lootbox_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootbox_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lootbox_Category_Avg_Fields = {
  __typename?: 'lootbox_category_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lootbox_category". All fields are combined with a logical 'AND'. */
export type Lootbox_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Lootbox_Category_Bool_Exp>>;
  _not?: InputMaybe<Lootbox_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Lootbox_Category_Bool_Exp>>;
  category_active?: InputMaybe<Boolean_Comparison_Exp>;
  category_anchor?: InputMaybe<String_Comparison_Exp>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  category_name?: InputMaybe<String_Comparison_Exp>;
  category_short_name?: InputMaybe<String_Comparison_Exp>;
  category_sort?: InputMaybe<Int_Comparison_Exp>;
  category_title?: InputMaybe<String_Comparison_Exp>;
  info_text?: InputMaybe<String_Comparison_Exp>;
  lootboxes?: InputMaybe<Lootboxes_Bool_Exp>;
  lootboxes_aggregate?: InputMaybe<Lootboxes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "lootbox_category" */
export enum Lootbox_Category_Constraint {
  /** unique or primary key constraint on columns "category_id" */
  LootboxCategoryPkey = 'lootbox_category_pkey'
}

/** input type for incrementing numeric columns in table "lootbox_category" */
export type Lootbox_Category_Inc_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  category_sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootbox_category" */
export type Lootbox_Category_Insert_Input = {
  category_active?: InputMaybe<Scalars['Boolean']>;
  category_anchor?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  category_name?: InputMaybe<Scalars['String']>;
  category_short_name?: InputMaybe<Scalars['String']>;
  category_sort?: InputMaybe<Scalars['Int']>;
  category_title?: InputMaybe<Scalars['String']>;
  info_text?: InputMaybe<Scalars['String']>;
  lootboxes?: InputMaybe<Lootboxes_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Lootbox_Category_Max_Fields = {
  __typename?: 'lootbox_category_max_fields';
  category_anchor?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  category_name?: Maybe<Scalars['String']>;
  category_short_name?: Maybe<Scalars['String']>;
  category_sort?: Maybe<Scalars['Int']>;
  category_title?: Maybe<Scalars['String']>;
  info_text?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lootbox_Category_Min_Fields = {
  __typename?: 'lootbox_category_min_fields';
  category_anchor?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  category_name?: Maybe<Scalars['String']>;
  category_short_name?: Maybe<Scalars['String']>;
  category_sort?: Maybe<Scalars['Int']>;
  category_title?: Maybe<Scalars['String']>;
  info_text?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lootbox_category" */
export type Lootbox_Category_Mutation_Response = {
  __typename?: 'lootbox_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootbox_Category>;
};

/** input type for inserting object relation for remote table "lootbox_category" */
export type Lootbox_Category_Obj_Rel_Insert_Input = {
  data: Lootbox_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootbox_Category_On_Conflict>;
};

/** on_conflict condition type for table "lootbox_category" */
export type Lootbox_Category_On_Conflict = {
  constraint: Lootbox_Category_Constraint;
  update_columns?: Array<Lootbox_Category_Update_Column>;
  where?: InputMaybe<Lootbox_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "lootbox_category". */
export type Lootbox_Category_Order_By = {
  category_active?: InputMaybe<Order_By>;
  category_anchor?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  category_name?: InputMaybe<Order_By>;
  category_short_name?: InputMaybe<Order_By>;
  category_sort?: InputMaybe<Order_By>;
  category_title?: InputMaybe<Order_By>;
  info_text?: InputMaybe<Order_By>;
  lootboxes_aggregate?: InputMaybe<Lootboxes_Aggregate_Order_By>;
};

/** primary key columns input for table: lootbox_category */
export type Lootbox_Category_Pk_Columns_Input = {
  category_id: Scalars['Int'];
};

/** select columns of table "lootbox_category" */
export enum Lootbox_Category_Select_Column {
  /** column name */
  CategoryActive = 'category_active',
  /** column name */
  CategoryAnchor = 'category_anchor',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CategoryName = 'category_name',
  /** column name */
  CategoryShortName = 'category_short_name',
  /** column name */
  CategorySort = 'category_sort',
  /** column name */
  CategoryTitle = 'category_title',
  /** column name */
  InfoText = 'info_text'
}

/** input type for updating data in table "lootbox_category" */
export type Lootbox_Category_Set_Input = {
  category_active?: InputMaybe<Scalars['Boolean']>;
  category_anchor?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  category_name?: InputMaybe<Scalars['String']>;
  category_short_name?: InputMaybe<Scalars['String']>;
  category_sort?: InputMaybe<Scalars['Int']>;
  category_title?: InputMaybe<Scalars['String']>;
  info_text?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Lootbox_Category_Stddev_Fields = {
  __typename?: 'lootbox_category_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lootbox_Category_Stddev_Pop_Fields = {
  __typename?: 'lootbox_category_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lootbox_Category_Stddev_Samp_Fields = {
  __typename?: 'lootbox_category_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lootbox_category" */
export type Lootbox_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootbox_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootbox_Category_Stream_Cursor_Value_Input = {
  category_active?: InputMaybe<Scalars['Boolean']>;
  category_anchor?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  category_name?: InputMaybe<Scalars['String']>;
  category_short_name?: InputMaybe<Scalars['String']>;
  category_sort?: InputMaybe<Scalars['Int']>;
  category_title?: InputMaybe<Scalars['String']>;
  info_text?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Lootbox_Category_Sum_Fields = {
  __typename?: 'lootbox_category_sum_fields';
  category_id?: Maybe<Scalars['Int']>;
  category_sort?: Maybe<Scalars['Int']>;
};

/** update columns of table "lootbox_category" */
export enum Lootbox_Category_Update_Column {
  /** column name */
  CategoryActive = 'category_active',
  /** column name */
  CategoryAnchor = 'category_anchor',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CategoryName = 'category_name',
  /** column name */
  CategoryShortName = 'category_short_name',
  /** column name */
  CategorySort = 'category_sort',
  /** column name */
  CategoryTitle = 'category_title',
  /** column name */
  InfoText = 'info_text'
}

export type Lootbox_Category_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootbox_Category_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootbox_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootbox_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootbox_Category_Var_Pop_Fields = {
  __typename?: 'lootbox_category_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lootbox_Category_Var_Samp_Fields = {
  __typename?: 'lootbox_category_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lootbox_Category_Variance_Fields = {
  __typename?: 'lootbox_category_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lootbox_items_sets" */
export type Lootbox_Items_Sets = {
  __typename?: 'lootbox_items_sets';
  item_alternative?: Maybe<Array<Scalars['String']>>;
  item_drop_chance: Scalars['Float'];
  item_id: Scalars['uuid'];
  item_start_price: Scalars['Float'];
  item_type: Scalars['String'];
  /** An object relationship */
  item_view?: Maybe<Items_In_Lootbox_View>;
  /** An object relationship */
  lootbox?: Maybe<Lootboxes>;
  lootbox_id: Scalars['uuid'];
  market_item_params?: Maybe<Items_Csgomarket>;
  set_id: Scalars['Int'];
  set_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lootbox_items_sets" */
export type Lootbox_Items_Sets_Aggregate = {
  __typename?: 'lootbox_items_sets_aggregate';
  aggregate?: Maybe<Lootbox_Items_Sets_Aggregate_Fields>;
  nodes: Array<Lootbox_Items_Sets>;
};

export type Lootbox_Items_Sets_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lootbox_Items_Sets_Aggregate_Bool_Exp_Count>;
};

export type Lootbox_Items_Sets_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootbox_Items_Sets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootbox_Items_Sets_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootbox_items_sets" */
export type Lootbox_Items_Sets_Aggregate_Fields = {
  __typename?: 'lootbox_items_sets_aggregate_fields';
  avg?: Maybe<Lootbox_Items_Sets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootbox_Items_Sets_Max_Fields>;
  min?: Maybe<Lootbox_Items_Sets_Min_Fields>;
  stddev?: Maybe<Lootbox_Items_Sets_Stddev_Fields>;
  stddev_pop?: Maybe<Lootbox_Items_Sets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootbox_Items_Sets_Stddev_Samp_Fields>;
  sum?: Maybe<Lootbox_Items_Sets_Sum_Fields>;
  var_pop?: Maybe<Lootbox_Items_Sets_Var_Pop_Fields>;
  var_samp?: Maybe<Lootbox_Items_Sets_Var_Samp_Fields>;
  variance?: Maybe<Lootbox_Items_Sets_Variance_Fields>;
};


/** aggregate fields of "lootbox_items_sets" */
export type Lootbox_Items_Sets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootbox_Items_Sets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Aggregate_Order_By = {
  avg?: InputMaybe<Lootbox_Items_Sets_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootbox_Items_Sets_Max_Order_By>;
  min?: InputMaybe<Lootbox_Items_Sets_Min_Order_By>;
  stddev?: InputMaybe<Lootbox_Items_Sets_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lootbox_Items_Sets_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lootbox_Items_Sets_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lootbox_Items_Sets_Sum_Order_By>;
  var_pop?: InputMaybe<Lootbox_Items_Sets_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lootbox_Items_Sets_Var_Samp_Order_By>;
  variance?: InputMaybe<Lootbox_Items_Sets_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Arr_Rel_Insert_Input = {
  data: Array<Lootbox_Items_Sets_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootbox_Items_Sets_On_Conflict>;
};

/** aggregate avg on columns */
export type Lootbox_Items_Sets_Avg_Fields = {
  __typename?: 'lootbox_items_sets_avg_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Avg_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lootbox_items_sets". All fields are combined with a logical 'AND'. */
export type Lootbox_Items_Sets_Bool_Exp = {
  _and?: InputMaybe<Array<Lootbox_Items_Sets_Bool_Exp>>;
  _not?: InputMaybe<Lootbox_Items_Sets_Bool_Exp>;
  _or?: InputMaybe<Array<Lootbox_Items_Sets_Bool_Exp>>;
  item_alternative?: InputMaybe<String_Array_Comparison_Exp>;
  item_drop_chance?: InputMaybe<Float_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_start_price?: InputMaybe<Float_Comparison_Exp>;
  item_type?: InputMaybe<String_Comparison_Exp>;
  item_view?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
  lootbox?: InputMaybe<Lootboxes_Bool_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  set_id?: InputMaybe<Int_Comparison_Exp>;
  set_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootbox_items_sets" */
export enum Lootbox_Items_Sets_Constraint {
  /** unique or primary key constraint on columns "lootbox_id", "item_id", "set_id" */
  LootboxItemsSetsPkey = 'lootbox_items_sets_pkey'
}

/** input type for incrementing numeric columns in table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Inc_Input = {
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  set_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Insert_Input = {
  item_alternative?: InputMaybe<Array<Scalars['String']>>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  item_view?: InputMaybe<Items_In_Lootbox_View_Obj_Rel_Insert_Input>;
  lootbox?: InputMaybe<Lootboxes_Obj_Rel_Insert_Input>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  set_id?: InputMaybe<Scalars['Int']>;
  set_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lootbox_Items_Sets_Max_Fields = {
  __typename?: 'lootbox_items_sets_max_fields';
  item_alternative?: Maybe<Array<Scalars['String']>>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_type?: Maybe<Scalars['String']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  set_id?: Maybe<Scalars['Int']>;
  set_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Max_Order_By = {
  item_alternative?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
  set_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootbox_Items_Sets_Min_Fields = {
  __typename?: 'lootbox_items_sets_min_fields';
  item_alternative?: Maybe<Array<Scalars['String']>>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_type?: Maybe<Scalars['String']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  set_id?: Maybe<Scalars['Int']>;
  set_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Min_Order_By = {
  item_alternative?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
  set_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Mutation_Response = {
  __typename?: 'lootbox_items_sets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootbox_Items_Sets>;
};

/** on_conflict condition type for table "lootbox_items_sets" */
export type Lootbox_Items_Sets_On_Conflict = {
  constraint: Lootbox_Items_Sets_Constraint;
  update_columns?: Array<Lootbox_Items_Sets_Update_Column>;
  where?: InputMaybe<Lootbox_Items_Sets_Bool_Exp>;
};

/** Ordering options when selecting data from "lootbox_items_sets". */
export type Lootbox_Items_Sets_Order_By = {
  item_alternative?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  item_view?: InputMaybe<Items_In_Lootbox_View_Order_By>;
  lootbox?: InputMaybe<Lootboxes_Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
  set_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootbox_items_sets */
export type Lootbox_Items_Sets_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
  set_id: Scalars['Int'];
};

/** select columns of table "lootbox_items_sets" */
export enum Lootbox_Items_Sets_Select_Column {
  /** column name */
  ItemAlternative = 'item_alternative',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  SetId = 'set_id',
  /** column name */
  SetName = 'set_name'
}

/** input type for updating data in table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Set_Input = {
  item_alternative?: InputMaybe<Array<Scalars['String']>>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  set_id?: InputMaybe<Scalars['Int']>;
  set_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Lootbox_Items_Sets_Stddev_Fields = {
  __typename?: 'lootbox_items_sets_stddev_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Stddev_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lootbox_Items_Sets_Stddev_Pop_Fields = {
  __typename?: 'lootbox_items_sets_stddev_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Stddev_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lootbox_Items_Sets_Stddev_Samp_Fields = {
  __typename?: 'lootbox_items_sets_stddev_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Stddev_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootbox_Items_Sets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootbox_Items_Sets_Stream_Cursor_Value_Input = {
  item_alternative?: InputMaybe<Array<Scalars['String']>>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  set_id?: InputMaybe<Scalars['Int']>;
  set_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Lootbox_Items_Sets_Sum_Fields = {
  __typename?: 'lootbox_items_sets_sum_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Sum_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** update columns of table "lootbox_items_sets" */
export enum Lootbox_Items_Sets_Update_Column {
  /** column name */
  ItemAlternative = 'item_alternative',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  SetId = 'set_id',
  /** column name */
  SetName = 'set_name'
}

export type Lootbox_Items_Sets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootbox_Items_Sets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootbox_Items_Sets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootbox_Items_Sets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootbox_Items_Sets_Var_Pop_Fields = {
  __typename?: 'lootbox_items_sets_var_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Var_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lootbox_Items_Sets_Var_Samp_Fields = {
  __typename?: 'lootbox_items_sets_var_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Var_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lootbox_Items_Sets_Variance_Fields = {
  __typename?: 'lootbox_items_sets_variance_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  set_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lootbox_items_sets" */
export type Lootbox_Items_Sets_Variance_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lootbox_service" */
export type Lootbox_Service = {
  __typename?: 'lootbox_service';
  description: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "lootbox_service" */
export type Lootbox_Service_Aggregate = {
  __typename?: 'lootbox_service_aggregate';
  aggregate?: Maybe<Lootbox_Service_Aggregate_Fields>;
  nodes: Array<Lootbox_Service>;
};

/** aggregate fields of "lootbox_service" */
export type Lootbox_Service_Aggregate_Fields = {
  __typename?: 'lootbox_service_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lootbox_Service_Max_Fields>;
  min?: Maybe<Lootbox_Service_Min_Fields>;
};


/** aggregate fields of "lootbox_service" */
export type Lootbox_Service_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootbox_Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lootbox_service". All fields are combined with a logical 'AND'. */
export type Lootbox_Service_Bool_Exp = {
  _and?: InputMaybe<Array<Lootbox_Service_Bool_Exp>>;
  _not?: InputMaybe<Lootbox_Service_Bool_Exp>;
  _or?: InputMaybe<Array<Lootbox_Service_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootbox_service" */
export enum Lootbox_Service_Constraint {
  /** unique or primary key constraint on columns "id" */
  LootboxServicePkey = 'lootbox_service_pkey'
}

/** input type for inserting data into table "lootbox_service" */
export type Lootbox_Service_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lootbox_Service_Max_Fields = {
  __typename?: 'lootbox_service_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lootbox_Service_Min_Fields = {
  __typename?: 'lootbox_service_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lootbox_service" */
export type Lootbox_Service_Mutation_Response = {
  __typename?: 'lootbox_service_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootbox_Service>;
};

/** on_conflict condition type for table "lootbox_service" */
export type Lootbox_Service_On_Conflict = {
  constraint: Lootbox_Service_Constraint;
  update_columns?: Array<Lootbox_Service_Update_Column>;
  where?: InputMaybe<Lootbox_Service_Bool_Exp>;
};

/** Ordering options when selecting data from "lootbox_service". */
export type Lootbox_Service_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootbox_service */
export type Lootbox_Service_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "lootbox_service" */
export enum Lootbox_Service_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "lootbox_service" */
export type Lootbox_Service_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lootbox_service" */
export type Lootbox_Service_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootbox_Service_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootbox_Service_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lootbox_service" */
export enum Lootbox_Service_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Lootbox_Service_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootbox_Service_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootbox_Service_Bool_Exp;
};

/** columns and relationships of "lootboxes" */
export type Lootboxes = {
  __typename?: 'lootboxes';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  badge?: Maybe<Scalars['String']>;
  /** An object relationship */
  current_to_parent?: Maybe<Lootboxes>;
  hidden: Scalars['Boolean'];
  /** An array relationship */
  items_in_lootbox_views: Array<Items_In_Lootbox_View>;
  /** An aggregate relationship */
  items_in_lootbox_views_aggregate: Items_In_Lootbox_View_Aggregate;
  /** An array relationship */
  items_in_lootboxes: Array<Items_In_Lootbox>;
  /** An aggregate relationship */
  items_in_lootboxes_aggregate: Items_In_Lootbox_Aggregate;
  lootbox_category?: Maybe<Scalars['Int']>;
  /** An object relationship */
  lootbox_category_data?: Maybe<Lootbox_Category>;
  lootbox_create_date_time?: Maybe<Scalars['timestamptz']>;
  lootbox_current_id?: Maybe<Scalars['uuid']>;
  lootbox_discount: Scalars['Float'];
  lootbox_drops: Array<User_Items_In_Inventory>;
  lootbox_drops_aggregate: User_Items_In_Inventory_Aggregate;
  lootbox_id: Scalars['uuid'];
  lootbox_image_link?: Maybe<Scalars['String']>;
  /** An array relationship */
  lootbox_items_sets: Array<Lootbox_Items_Sets>;
  /** An aggregate relationship */
  lootbox_items_sets_aggregate: Lootbox_Items_Sets_Aggregate;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_open_count: Scalars['Int'];
  lootbox_open_max_count?: Maybe<Scalars['Int']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_status?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  lootbox_top_drops: Array<Lootboxes_Top_Drop>;
  /** An aggregate relationship */
  lootbox_top_drops_aggregate: Lootboxes_Top_Drop_Aggregate;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "lootboxes" */
export type LootboxesItems_In_Lootbox_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_View_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
};


/** columns and relationships of "lootboxes" */
export type LootboxesItems_In_Lootbox_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_View_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
};


/** columns and relationships of "lootboxes" */
export type LootboxesItems_In_LootboxesArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_Bool_Exp>;
};


/** columns and relationships of "lootboxes" */
export type LootboxesItems_In_Lootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_Bool_Exp>;
};


/** columns and relationships of "lootboxes" */
export type LootboxesLootbox_DropsArgs = {
  distinct_on?: InputMaybe<Array<User_Items_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Items_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
};


/** columns and relationships of "lootboxes" */
export type LootboxesLootbox_Drops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Items_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Items_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
};


/** columns and relationships of "lootboxes" */
export type LootboxesLootbox_Items_SetsArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Items_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Items_Sets_Order_By>>;
  where?: InputMaybe<Lootbox_Items_Sets_Bool_Exp>;
};


/** columns and relationships of "lootboxes" */
export type LootboxesLootbox_Items_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Items_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Items_Sets_Order_By>>;
  where?: InputMaybe<Lootbox_Items_Sets_Bool_Exp>;
};


/** columns and relationships of "lootboxes" */
export type LootboxesLootbox_Top_DropsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Top_Drop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Top_Drop_Order_By>>;
  where?: InputMaybe<Lootboxes_Top_Drop_Bool_Exp>;
};


/** columns and relationships of "lootboxes" */
export type LootboxesLootbox_Top_Drops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Top_Drop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Top_Drop_Order_By>>;
  where?: InputMaybe<Lootboxes_Top_Drop_Bool_Exp>;
};

/** aggregated selection of "lootboxes" */
export type Lootboxes_Aggregate = {
  __typename?: 'lootboxes_aggregate';
  aggregate?: Maybe<Lootboxes_Aggregate_Fields>;
  nodes: Array<Lootboxes>;
};

export type Lootboxes_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Lootboxes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Lootboxes_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Lootboxes_Aggregate_Bool_Exp_Count>;
};

export type Lootboxes_Aggregate_Bool_Exp_Bool_And = {
  arguments: Lootboxes_Select_Column_Lootboxes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lootboxes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Lootboxes_Select_Column_Lootboxes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lootboxes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootboxes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootboxes" */
export type Lootboxes_Aggregate_Fields = {
  __typename?: 'lootboxes_aggregate_fields';
  avg?: Maybe<Lootboxes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Max_Fields>;
  min?: Maybe<Lootboxes_Min_Fields>;
  stddev?: Maybe<Lootboxes_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Variance_Fields>;
};


/** aggregate fields of "lootboxes" */
export type Lootboxes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootboxes" */
export type Lootboxes_Aggregate_Order_By = {
  avg?: InputMaybe<Lootboxes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootboxes_Max_Order_By>;
  min?: InputMaybe<Lootboxes_Min_Order_By>;
  stddev?: InputMaybe<Lootboxes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lootboxes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lootboxes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lootboxes_Sum_Order_By>;
  var_pop?: InputMaybe<Lootboxes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lootboxes_Var_Samp_Order_By>;
  variance?: InputMaybe<Lootboxes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lootboxes" */
export type Lootboxes_Arr_Rel_Insert_Input = {
  data: Array<Lootboxes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_On_Conflict>;
};

/** columns and relationships of "lootboxes_available_statuses" */
export type Lootboxes_Available_Statuses = {
  __typename?: 'lootboxes_available_statuses';
  button_link?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
  status_id: Scalars['Int'];
  status_sub_type: Scalars['String'];
  status_text: Scalars['String'];
  status_title: Scalars['String'];
};

/** aggregated selection of "lootboxes_available_statuses" */
export type Lootboxes_Available_Statuses_Aggregate = {
  __typename?: 'lootboxes_available_statuses_aggregate';
  aggregate?: Maybe<Lootboxes_Available_Statuses_Aggregate_Fields>;
  nodes: Array<Lootboxes_Available_Statuses>;
};

/** aggregate fields of "lootboxes_available_statuses" */
export type Lootboxes_Available_Statuses_Aggregate_Fields = {
  __typename?: 'lootboxes_available_statuses_aggregate_fields';
  avg?: Maybe<Lootboxes_Available_Statuses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Available_Statuses_Max_Fields>;
  min?: Maybe<Lootboxes_Available_Statuses_Min_Fields>;
  stddev?: Maybe<Lootboxes_Available_Statuses_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Available_Statuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Available_Statuses_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Available_Statuses_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Available_Statuses_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Available_Statuses_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Available_Statuses_Variance_Fields>;
};


/** aggregate fields of "lootboxes_available_statuses" */
export type Lootboxes_Available_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Available_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lootboxes_Available_Statuses_Avg_Fields = {
  __typename?: 'lootboxes_available_statuses_avg_fields';
  status_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lootboxes_available_statuses". All fields are combined with a logical 'AND'. */
export type Lootboxes_Available_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Available_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Available_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Available_Statuses_Bool_Exp>>;
  button_link?: InputMaybe<String_Comparison_Exp>;
  button_text?: InputMaybe<String_Comparison_Exp>;
  status_id?: InputMaybe<Int_Comparison_Exp>;
  status_sub_type?: InputMaybe<String_Comparison_Exp>;
  status_text?: InputMaybe<String_Comparison_Exp>;
  status_title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_available_statuses" */
export enum Lootboxes_Available_Statuses_Constraint {
  /** unique or primary key constraint on columns "status_id", "status_sub_type" */
  LootboxesAvailableStatusesPkey = 'lootboxes_available_statuses_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_available_statuses" */
export type Lootboxes_Available_Statuses_Inc_Input = {
  status_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes_available_statuses" */
export type Lootboxes_Available_Statuses_Insert_Input = {
  button_link?: InputMaybe<Scalars['String']>;
  button_text?: InputMaybe<Scalars['String']>;
  status_id?: InputMaybe<Scalars['Int']>;
  status_sub_type?: InputMaybe<Scalars['String']>;
  status_text?: InputMaybe<Scalars['String']>;
  status_title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lootboxes_Available_Statuses_Max_Fields = {
  __typename?: 'lootboxes_available_statuses_max_fields';
  button_link?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['Int']>;
  status_sub_type?: Maybe<Scalars['String']>;
  status_text?: Maybe<Scalars['String']>;
  status_title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lootboxes_Available_Statuses_Min_Fields = {
  __typename?: 'lootboxes_available_statuses_min_fields';
  button_link?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['Int']>;
  status_sub_type?: Maybe<Scalars['String']>;
  status_text?: Maybe<Scalars['String']>;
  status_title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lootboxes_available_statuses" */
export type Lootboxes_Available_Statuses_Mutation_Response = {
  __typename?: 'lootboxes_available_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Available_Statuses>;
};

/** on_conflict condition type for table "lootboxes_available_statuses" */
export type Lootboxes_Available_Statuses_On_Conflict = {
  constraint: Lootboxes_Available_Statuses_Constraint;
  update_columns?: Array<Lootboxes_Available_Statuses_Update_Column>;
  where?: InputMaybe<Lootboxes_Available_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_available_statuses". */
export type Lootboxes_Available_Statuses_Order_By = {
  button_link?: InputMaybe<Order_By>;
  button_text?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  status_sub_type?: InputMaybe<Order_By>;
  status_text?: InputMaybe<Order_By>;
  status_title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_available_statuses */
export type Lootboxes_Available_Statuses_Pk_Columns_Input = {
  status_id: Scalars['Int'];
  status_sub_type: Scalars['String'];
};

/** select columns of table "lootboxes_available_statuses" */
export enum Lootboxes_Available_Statuses_Select_Column {
  /** column name */
  ButtonLink = 'button_link',
  /** column name */
  ButtonText = 'button_text',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  StatusSubType = 'status_sub_type',
  /** column name */
  StatusText = 'status_text',
  /** column name */
  StatusTitle = 'status_title'
}

/** input type for updating data in table "lootboxes_available_statuses" */
export type Lootboxes_Available_Statuses_Set_Input = {
  button_link?: InputMaybe<Scalars['String']>;
  button_text?: InputMaybe<Scalars['String']>;
  status_id?: InputMaybe<Scalars['Int']>;
  status_sub_type?: InputMaybe<Scalars['String']>;
  status_text?: InputMaybe<Scalars['String']>;
  status_title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Available_Statuses_Stddev_Fields = {
  __typename?: 'lootboxes_available_statuses_stddev_fields';
  status_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Available_Statuses_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_available_statuses_stddev_pop_fields';
  status_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Available_Statuses_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_available_statuses_stddev_samp_fields';
  status_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lootboxes_available_statuses" */
export type Lootboxes_Available_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Available_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Available_Statuses_Stream_Cursor_Value_Input = {
  button_link?: InputMaybe<Scalars['String']>;
  button_text?: InputMaybe<Scalars['String']>;
  status_id?: InputMaybe<Scalars['Int']>;
  status_sub_type?: InputMaybe<Scalars['String']>;
  status_text?: InputMaybe<Scalars['String']>;
  status_title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Lootboxes_Available_Statuses_Sum_Fields = {
  __typename?: 'lootboxes_available_statuses_sum_fields';
  status_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "lootboxes_available_statuses" */
export enum Lootboxes_Available_Statuses_Update_Column {
  /** column name */
  ButtonLink = 'button_link',
  /** column name */
  ButtonText = 'button_text',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  StatusSubType = 'status_sub_type',
  /** column name */
  StatusText = 'status_text',
  /** column name */
  StatusTitle = 'status_title'
}

export type Lootboxes_Available_Statuses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Available_Statuses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Available_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Available_Statuses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Available_Statuses_Var_Pop_Fields = {
  __typename?: 'lootboxes_available_statuses_var_pop_fields';
  status_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Available_Statuses_Var_Samp_Fields = {
  __typename?: 'lootboxes_available_statuses_var_samp_fields';
  status_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lootboxes_Available_Statuses_Variance_Fields = {
  __typename?: 'lootboxes_available_statuses_variance_fields';
  status_id?: Maybe<Scalars['Float']>;
};

/** aggregate avg on columns */
export type Lootboxes_Avg_Fields = {
  __typename?: 'lootboxes_avg_fields';
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_discount?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_open_count?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lootboxes" */
export type Lootboxes_Avg_Order_By = {
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_discount?: InputMaybe<Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_open_count?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  lotbox_rec_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** columns and relationships of "lootboxes_bonus" */
export type Lootboxes_Bonus = {
  __typename?: 'lootboxes_bonus';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamp'];
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  /** An array relationship */
  items_in_lootbox: Array<Lootboxes_Bonus_Items>;
  /** An aggregate relationship */
  items_in_lootbox_aggregate: Lootboxes_Bonus_Items_Aggregate;
  /** An array relationship */
  items_view: Array<Lootboxes_Bonus_Views>;
  /** An aggregate relationship */
  items_view_aggregate: Lootboxes_Bonus_Views_Aggregate;
  lootbox_id: Scalars['uuid'];
  lootbox_image_link: Scalars['String'];
  lootbox_name: Scalars['String'];
  lootbox_slug: Scalars['String'];
  lootbox_status: Scalars['Boolean'];
  lootbox_target_price: Scalars['float8'];
};


/** columns and relationships of "lootboxes_bonus" */
export type Lootboxes_BonusItems_In_LootboxArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Items_Bool_Exp>;
};


/** columns and relationships of "lootboxes_bonus" */
export type Lootboxes_BonusItems_In_Lootbox_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Items_Bool_Exp>;
};


/** columns and relationships of "lootboxes_bonus" */
export type Lootboxes_BonusItems_ViewArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
};


/** columns and relationships of "lootboxes_bonus" */
export type Lootboxes_BonusItems_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
};

/** aggregated selection of "lootboxes_bonus" */
export type Lootboxes_Bonus_Aggregate = {
  __typename?: 'lootboxes_bonus_aggregate';
  aggregate?: Maybe<Lootboxes_Bonus_Aggregate_Fields>;
  nodes: Array<Lootboxes_Bonus>;
};

/** aggregate fields of "lootboxes_bonus" */
export type Lootboxes_Bonus_Aggregate_Fields = {
  __typename?: 'lootboxes_bonus_aggregate_fields';
  avg?: Maybe<Lootboxes_Bonus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Bonus_Max_Fields>;
  min?: Maybe<Lootboxes_Bonus_Min_Fields>;
  stddev?: Maybe<Lootboxes_Bonus_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Bonus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Bonus_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Bonus_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Bonus_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Bonus_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Bonus_Variance_Fields>;
};


/** aggregate fields of "lootboxes_bonus" */
export type Lootboxes_Bonus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Bonus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lootboxes_Bonus_Avg_Fields = {
  __typename?: 'lootboxes_bonus_avg_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lootboxes_bonus". All fields are combined with a logical 'AND'. */
export type Lootboxes_Bonus_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Bonus_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Bonus_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Bonus_Bool_Exp>>;
  amp_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  drop_limit_amount?: InputMaybe<Int_Comparison_Exp>;
  drop_limit_percent?: InputMaybe<Int_Comparison_Exp>;
  items_in_lootbox?: InputMaybe<Lootboxes_Bonus_Items_Bool_Exp>;
  items_in_lootbox_aggregate?: InputMaybe<Lootboxes_Bonus_Items_Aggregate_Bool_Exp>;
  items_view?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
  items_view_aggregate?: InputMaybe<Lootboxes_Bonus_Views_Aggregate_Bool_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_image_link?: InputMaybe<String_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  lootbox_slug?: InputMaybe<String_Comparison_Exp>;
  lootbox_status?: InputMaybe<Boolean_Comparison_Exp>;
  lootbox_target_price?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_bonus" */
export enum Lootboxes_Bonus_Constraint {
  /** unique or primary key constraint on columns "lootbox_id" */
  LootboxesBonusPkey = 'lootboxes_bonus_pkey'
}

/** columns and relationships of "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items = {
  __typename?: 'lootboxes_bonus_generator_items';
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};

/** aggregated selection of "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_Aggregate = {
  __typename?: 'lootboxes_bonus_generator_items_aggregate';
  aggregate?: Maybe<Lootboxes_Bonus_Generator_Items_Aggregate_Fields>;
  nodes: Array<Lootboxes_Bonus_Generator_Items>;
};

export type Lootboxes_Bonus_Generator_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lootboxes_Bonus_Generator_Items_Aggregate_Bool_Exp_Count>;
};

export type Lootboxes_Bonus_Generator_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Bonus_Generator_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_Aggregate_Fields = {
  __typename?: 'lootboxes_bonus_generator_items_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Bonus_Generator_Items_Max_Fields>;
  min?: Maybe<Lootboxes_Bonus_Generator_Items_Min_Fields>;
};


/** aggregate fields of "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootboxes_Bonus_Generator_Items_Max_Order_By>;
  min?: InputMaybe<Lootboxes_Bonus_Generator_Items_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_Arr_Rel_Insert_Input = {
  data: Array<Lootboxes_Bonus_Generator_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Bonus_Generator_Items_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lootboxes_bonus_generator_items". All fields are combined with a logical 'AND'. */
export type Lootboxes_Bonus_Generator_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Bonus_Generator_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Bool_Exp>>;
  generator_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_bonus_generator_items" */
export enum Lootboxes_Bonus_Generator_Items_Constraint {
  /** unique or primary key constraint on columns "item_hash_name", "generator_lootbox_id" */
  LootboxesBonusGeneratorItemsPkey = 'lootboxes_bonus_generator_items_pkey'
}

/** input type for inserting data into table "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_Insert_Input = {
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lootboxes_Bonus_Generator_Items_Max_Fields = {
  __typename?: 'lootboxes_bonus_generator_items_max_fields';
  generator_lootbox_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_Max_Order_By = {
  generator_lootbox_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootboxes_Bonus_Generator_Items_Min_Fields = {
  __typename?: 'lootboxes_bonus_generator_items_min_fields';
  generator_lootbox_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_Min_Order_By = {
  generator_lootbox_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_Mutation_Response = {
  __typename?: 'lootboxes_bonus_generator_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Bonus_Generator_Items>;
};

/** on_conflict condition type for table "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_On_Conflict = {
  constraint: Lootboxes_Bonus_Generator_Items_Constraint;
  update_columns?: Array<Lootboxes_Bonus_Generator_Items_Update_Column>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_bonus_generator_items". */
export type Lootboxes_Bonus_Generator_Items_Order_By = {
  generator_lootbox_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_bonus_generator_items */
export type Lootboxes_Bonus_Generator_Items_Pk_Columns_Input = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};

/** select columns of table "lootboxes_bonus_generator_items" */
export enum Lootboxes_Bonus_Generator_Items_Select_Column {
  /** column name */
  GeneratorLootboxId = 'generator_lootbox_id',
  /** column name */
  ItemHashName = 'item_hash_name'
}

/** input type for updating data in table "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_Set_Input = {
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lootboxes_bonus_generator_items" */
export type Lootboxes_Bonus_Generator_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Bonus_Generator_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Bonus_Generator_Items_Stream_Cursor_Value_Input = {
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lootboxes_bonus_generator_items" */
export enum Lootboxes_Bonus_Generator_Items_Update_Column {
  /** column name */
  GeneratorLootboxId = 'generator_lootbox_id',
  /** column name */
  ItemHashName = 'item_hash_name'
}

export type Lootboxes_Bonus_Generator_Items_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Bonus_Generator_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Bonus_Generator_Items_Bool_Exp;
};

/** columns and relationships of "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_Params = {
  __typename?: 'lootboxes_bonus_generator_params';
  amp_lootbox_id: Scalars['uuid'];
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  drop_min_amount?: Maybe<Scalars['Int']>;
  /** An array relationship */
  generator_items: Array<Lootboxes_Bonus_Generator_Items>;
  /** An aggregate relationship */
  generator_items_aggregate: Lootboxes_Bonus_Generator_Items_Aggregate;
  /** An object relationship */
  generator_mechanic?: Maybe<Lootboxes_Generator_Mechanics>;
  is_active: Scalars['Boolean'];
  last_generated_lootbox_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  last_lootbox?: Maybe<Lootboxes_Bonus>;
  lootbox_generator_id: Scalars['uuid'];
  lootbox_image_link: Scalars['String'];
  lootbox_name: Scalars['String'];
  lootbox_slug: Scalars['String'];
  lootbox_target_price: Scalars['Int'];
  mechanic_id: Scalars['Int'];
};


/** columns and relationships of "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_ParamsGenerator_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Items_Bool_Exp>;
};


/** columns and relationships of "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_ParamsGenerator_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Items_Bool_Exp>;
};

/** aggregated selection of "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_Params_Aggregate = {
  __typename?: 'lootboxes_bonus_generator_params_aggregate';
  aggregate?: Maybe<Lootboxes_Bonus_Generator_Params_Aggregate_Fields>;
  nodes: Array<Lootboxes_Bonus_Generator_Params>;
};

/** aggregate fields of "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_Params_Aggregate_Fields = {
  __typename?: 'lootboxes_bonus_generator_params_aggregate_fields';
  avg?: Maybe<Lootboxes_Bonus_Generator_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Bonus_Generator_Params_Max_Fields>;
  min?: Maybe<Lootboxes_Bonus_Generator_Params_Min_Fields>;
  stddev?: Maybe<Lootboxes_Bonus_Generator_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Bonus_Generator_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Bonus_Generator_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Bonus_Generator_Params_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Bonus_Generator_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Bonus_Generator_Params_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Bonus_Generator_Params_Variance_Fields>;
};


/** aggregate fields of "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Bonus_Generator_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lootboxes_Bonus_Generator_Params_Avg_Fields = {
  __typename?: 'lootboxes_bonus_generator_params_avg_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lootboxes_bonus_generator_params". All fields are combined with a logical 'AND'. */
export type Lootboxes_Bonus_Generator_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Bonus_Generator_Params_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Bonus_Generator_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Bonus_Generator_Params_Bool_Exp>>;
  amp_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  drop_limit_amount?: InputMaybe<Int_Comparison_Exp>;
  drop_limit_percent?: InputMaybe<Int_Comparison_Exp>;
  drop_min_amount?: InputMaybe<Int_Comparison_Exp>;
  generator_items?: InputMaybe<Lootboxes_Bonus_Generator_Items_Bool_Exp>;
  generator_items_aggregate?: InputMaybe<Lootboxes_Bonus_Generator_Items_Aggregate_Bool_Exp>;
  generator_mechanic?: InputMaybe<Lootboxes_Generator_Mechanics_Bool_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  last_generated_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  last_lootbox?: InputMaybe<Lootboxes_Bonus_Bool_Exp>;
  lootbox_generator_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_image_link?: InputMaybe<String_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  lootbox_slug?: InputMaybe<String_Comparison_Exp>;
  lootbox_target_price?: InputMaybe<Int_Comparison_Exp>;
  mechanic_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_bonus_generator_params" */
export enum Lootboxes_Bonus_Generator_Params_Constraint {
  /** unique or primary key constraint on columns "lootbox_generator_id" */
  LootboxesBonusGeneratorParamsPkey = 'lootboxes_bonus_generator_params_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_Params_Inc_Input = {
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  drop_min_amount?: InputMaybe<Scalars['Int']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_Params_Insert_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  drop_min_amount?: InputMaybe<Scalars['Int']>;
  generator_items?: InputMaybe<Lootboxes_Bonus_Generator_Items_Arr_Rel_Insert_Input>;
  generator_mechanic?: InputMaybe<Lootboxes_Generator_Mechanics_Obj_Rel_Insert_Input>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_generated_lootbox_id?: InputMaybe<Scalars['uuid']>;
  last_lootbox?: InputMaybe<Lootboxes_Bonus_Obj_Rel_Insert_Input>;
  lootbox_generator_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lootboxes_Bonus_Generator_Params_Max_Fields = {
  __typename?: 'lootboxes_bonus_generator_params_max_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  drop_min_amount?: Maybe<Scalars['Int']>;
  last_generated_lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_generator_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_target_price?: Maybe<Scalars['Int']>;
  mechanic_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Lootboxes_Bonus_Generator_Params_Min_Fields = {
  __typename?: 'lootboxes_bonus_generator_params_min_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  drop_min_amount?: Maybe<Scalars['Int']>;
  last_generated_lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_generator_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_target_price?: Maybe<Scalars['Int']>;
  mechanic_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_Params_Mutation_Response = {
  __typename?: 'lootboxes_bonus_generator_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Bonus_Generator_Params>;
};

/** on_conflict condition type for table "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_Params_On_Conflict = {
  constraint: Lootboxes_Bonus_Generator_Params_Constraint;
  update_columns?: Array<Lootboxes_Bonus_Generator_Params_Update_Column>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_bonus_generator_params". */
export type Lootboxes_Bonus_Generator_Params_Order_By = {
  amp_lootbox_id?: InputMaybe<Order_By>;
  drop_limit_amount?: InputMaybe<Order_By>;
  drop_limit_percent?: InputMaybe<Order_By>;
  drop_min_amount?: InputMaybe<Order_By>;
  generator_items_aggregate?: InputMaybe<Lootboxes_Bonus_Generator_Items_Aggregate_Order_By>;
  generator_mechanic?: InputMaybe<Lootboxes_Generator_Mechanics_Order_By>;
  is_active?: InputMaybe<Order_By>;
  last_generated_lootbox_id?: InputMaybe<Order_By>;
  last_lootbox?: InputMaybe<Lootboxes_Bonus_Order_By>;
  lootbox_generator_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  lootbox_target_price?: InputMaybe<Order_By>;
  mechanic_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_bonus_generator_params */
export type Lootboxes_Bonus_Generator_Params_Pk_Columns_Input = {
  lootbox_generator_id: Scalars['uuid'];
};

/** select columns of table "lootboxes_bonus_generator_params" */
export enum Lootboxes_Bonus_Generator_Params_Select_Column {
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  DropLimitAmount = 'drop_limit_amount',
  /** column name */
  DropLimitPercent = 'drop_limit_percent',
  /** column name */
  DropMinAmount = 'drop_min_amount',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastGeneratedLootboxId = 'last_generated_lootbox_id',
  /** column name */
  LootboxGeneratorId = 'lootbox_generator_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxTargetPrice = 'lootbox_target_price',
  /** column name */
  MechanicId = 'mechanic_id'
}

/** input type for updating data in table "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_Params_Set_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  drop_min_amount?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_generated_lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_generator_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Bonus_Generator_Params_Stddev_Fields = {
  __typename?: 'lootboxes_bonus_generator_params_stddev_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Bonus_Generator_Params_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_bonus_generator_params_stddev_pop_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Bonus_Generator_Params_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_bonus_generator_params_stddev_samp_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lootboxes_bonus_generator_params" */
export type Lootboxes_Bonus_Generator_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Bonus_Generator_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Bonus_Generator_Params_Stream_Cursor_Value_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  drop_min_amount?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_generated_lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_generator_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lootboxes_Bonus_Generator_Params_Sum_Fields = {
  __typename?: 'lootboxes_bonus_generator_params_sum_fields';
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  drop_min_amount?: Maybe<Scalars['Int']>;
  lootbox_target_price?: Maybe<Scalars['Int']>;
  mechanic_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "lootboxes_bonus_generator_params" */
export enum Lootboxes_Bonus_Generator_Params_Update_Column {
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  DropLimitAmount = 'drop_limit_amount',
  /** column name */
  DropLimitPercent = 'drop_limit_percent',
  /** column name */
  DropMinAmount = 'drop_min_amount',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastGeneratedLootboxId = 'last_generated_lootbox_id',
  /** column name */
  LootboxGeneratorId = 'lootbox_generator_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxTargetPrice = 'lootbox_target_price',
  /** column name */
  MechanicId = 'mechanic_id'
}

export type Lootboxes_Bonus_Generator_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Bonus_Generator_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Bonus_Generator_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Bonus_Generator_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Bonus_Generator_Params_Var_Pop_Fields = {
  __typename?: 'lootboxes_bonus_generator_params_var_pop_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Bonus_Generator_Params_Var_Samp_Fields = {
  __typename?: 'lootboxes_bonus_generator_params_var_samp_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lootboxes_Bonus_Generator_Params_Variance_Fields = {
  __typename?: 'lootboxes_bonus_generator_params_variance_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "lootboxes_bonus" */
export type Lootboxes_Bonus_Inc_Input = {
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  lootbox_target_price?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "lootboxes_bonus" */
export type Lootboxes_Bonus_Insert_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  items_in_lootbox?: InputMaybe<Lootboxes_Bonus_Items_Arr_Rel_Insert_Input>;
  items_view?: InputMaybe<Lootboxes_Bonus_Views_Arr_Rel_Insert_Input>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_status?: InputMaybe<Scalars['Boolean']>;
  lootbox_target_price?: InputMaybe<Scalars['float8']>;
};

/** columns and relationships of "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items = {
  __typename?: 'lootboxes_bonus_items';
  item_drop_chance: Scalars['Float'];
  item_id: Scalars['uuid'];
  item_price: Scalars['Float'];
  /** An object relationship */
  items_to_views?: Maybe<Lootboxes_Bonus_Views>;
  lootbox_id: Scalars['uuid'];
  /** An object relationship */
  lootboxes_bonus?: Maybe<Lootboxes_Bonus>;
};

/** aggregated selection of "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Aggregate = {
  __typename?: 'lootboxes_bonus_items_aggregate';
  aggregate?: Maybe<Lootboxes_Bonus_Items_Aggregate_Fields>;
  nodes: Array<Lootboxes_Bonus_Items>;
};

export type Lootboxes_Bonus_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lootboxes_Bonus_Items_Aggregate_Bool_Exp_Count>;
};

export type Lootboxes_Bonus_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootboxes_Bonus_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Bonus_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Aggregate_Fields = {
  __typename?: 'lootboxes_bonus_items_aggregate_fields';
  avg?: Maybe<Lootboxes_Bonus_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Bonus_Items_Max_Fields>;
  min?: Maybe<Lootboxes_Bonus_Items_Min_Fields>;
  stddev?: Maybe<Lootboxes_Bonus_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Bonus_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Bonus_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Bonus_Items_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Bonus_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Bonus_Items_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Bonus_Items_Variance_Fields>;
};


/** aggregate fields of "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Bonus_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Lootboxes_Bonus_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootboxes_Bonus_Items_Max_Order_By>;
  min?: InputMaybe<Lootboxes_Bonus_Items_Min_Order_By>;
  stddev?: InputMaybe<Lootboxes_Bonus_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lootboxes_Bonus_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lootboxes_Bonus_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lootboxes_Bonus_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Lootboxes_Bonus_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lootboxes_Bonus_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Lootboxes_Bonus_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Arr_Rel_Insert_Input = {
  data: Array<Lootboxes_Bonus_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Bonus_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Lootboxes_Bonus_Items_Avg_Fields = {
  __typename?: 'lootboxes_bonus_items_avg_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Avg_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lootboxes_bonus_items". All fields are combined with a logical 'AND'. */
export type Lootboxes_Bonus_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Bonus_Items_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Bonus_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Bonus_Items_Bool_Exp>>;
  item_drop_chance?: InputMaybe<Float_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_price?: InputMaybe<Float_Comparison_Exp>;
  items_to_views?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootboxes_bonus?: InputMaybe<Lootboxes_Bonus_Bool_Exp>;
};

/** unique or primary key constraints on table "lootboxes_bonus_items" */
export enum Lootboxes_Bonus_Items_Constraint {
  /** unique or primary key constraint on columns "lootbox_id", "item_id" */
  LootboxesBonusItemsPkey = 'lootboxes_bonus_items_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Inc_Input = {
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Insert_Input = {
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  items_to_views?: InputMaybe<Lootboxes_Bonus_Views_Obj_Rel_Insert_Input>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootboxes_bonus?: InputMaybe<Lootboxes_Bonus_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Lootboxes_Bonus_Items_Max_Fields = {
  __typename?: 'lootboxes_bonus_items_max_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Max_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootboxes_Bonus_Items_Min_Fields = {
  __typename?: 'lootboxes_bonus_items_min_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Min_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Mutation_Response = {
  __typename?: 'lootboxes_bonus_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Bonus_Items>;
};

/** on_conflict condition type for table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_On_Conflict = {
  constraint: Lootboxes_Bonus_Items_Constraint;
  update_columns?: Array<Lootboxes_Bonus_Items_Update_Column>;
  where?: InputMaybe<Lootboxes_Bonus_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_bonus_items". */
export type Lootboxes_Bonus_Items_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  items_to_views?: InputMaybe<Lootboxes_Bonus_Views_Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootboxes_bonus?: InputMaybe<Lootboxes_Bonus_Order_By>;
};

/** primary key columns input for table: lootboxes_bonus_items */
export type Lootboxes_Bonus_Items_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};

/** select columns of table "lootboxes_bonus_items" */
export enum Lootboxes_Bonus_Items_Select_Column {
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  LootboxId = 'lootbox_id'
}

/** input type for updating data in table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Set_Input = {
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Bonus_Items_Stddev_Fields = {
  __typename?: 'lootboxes_bonus_items_stddev_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Stddev_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Bonus_Items_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_bonus_items_stddev_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Stddev_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Bonus_Items_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_bonus_items_stddev_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Stddev_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Bonus_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Bonus_Items_Stream_Cursor_Value_Input = {
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Lootboxes_Bonus_Items_Sum_Fields = {
  __typename?: 'lootboxes_bonus_items_sum_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Sum_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** update columns of table "lootboxes_bonus_items" */
export enum Lootboxes_Bonus_Items_Update_Column {
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  LootboxId = 'lootbox_id'
}

export type Lootboxes_Bonus_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Bonus_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Bonus_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Bonus_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Bonus_Items_Var_Pop_Fields = {
  __typename?: 'lootboxes_bonus_items_var_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Var_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Bonus_Items_Var_Samp_Fields = {
  __typename?: 'lootboxes_bonus_items_var_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Var_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lootboxes_Bonus_Items_Variance_Fields = {
  __typename?: 'lootboxes_bonus_items_variance_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lootboxes_bonus_items" */
export type Lootboxes_Bonus_Items_Variance_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Lootboxes_Bonus_Max_Fields = {
  __typename?: 'lootboxes_bonus_max_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_target_price?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Lootboxes_Bonus_Min_Fields = {
  __typename?: 'lootboxes_bonus_min_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_target_price?: Maybe<Scalars['float8']>;
};

/** response of any mutation on the table "lootboxes_bonus" */
export type Lootboxes_Bonus_Mutation_Response = {
  __typename?: 'lootboxes_bonus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Bonus>;
};

/** input type for inserting object relation for remote table "lootboxes_bonus" */
export type Lootboxes_Bonus_Obj_Rel_Insert_Input = {
  data: Lootboxes_Bonus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Bonus_On_Conflict>;
};

/** on_conflict condition type for table "lootboxes_bonus" */
export type Lootboxes_Bonus_On_Conflict = {
  constraint: Lootboxes_Bonus_Constraint;
  update_columns?: Array<Lootboxes_Bonus_Update_Column>;
  where?: InputMaybe<Lootboxes_Bonus_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_bonus". */
export type Lootboxes_Bonus_Order_By = {
  amp_lootbox_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  drop_limit_amount?: InputMaybe<Order_By>;
  drop_limit_percent?: InputMaybe<Order_By>;
  items_in_lootbox_aggregate?: InputMaybe<Lootboxes_Bonus_Items_Aggregate_Order_By>;
  items_view_aggregate?: InputMaybe<Lootboxes_Bonus_Views_Aggregate_Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  lootbox_status?: InputMaybe<Order_By>;
  lootbox_target_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_bonus */
export type Lootboxes_Bonus_Pk_Columns_Input = {
  lootbox_id: Scalars['uuid'];
};

/** select columns of table "lootboxes_bonus" */
export enum Lootboxes_Bonus_Select_Column {
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DropLimitAmount = 'drop_limit_amount',
  /** column name */
  DropLimitPercent = 'drop_limit_percent',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxStatus = 'lootbox_status',
  /** column name */
  LootboxTargetPrice = 'lootbox_target_price'
}

/** input type for updating data in table "lootboxes_bonus" */
export type Lootboxes_Bonus_Set_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_status?: InputMaybe<Scalars['Boolean']>;
  lootbox_target_price?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Bonus_Stddev_Fields = {
  __typename?: 'lootboxes_bonus_stddev_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Bonus_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_bonus_stddev_pop_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Bonus_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_bonus_stddev_samp_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lootboxes_bonus" */
export type Lootboxes_Bonus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Bonus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Bonus_Stream_Cursor_Value_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_status?: InputMaybe<Scalars['Boolean']>;
  lootbox_target_price?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Lootboxes_Bonus_Sum_Fields = {
  __typename?: 'lootboxes_bonus_sum_fields';
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  lootbox_target_price?: Maybe<Scalars['float8']>;
};

/** update columns of table "lootboxes_bonus" */
export enum Lootboxes_Bonus_Update_Column {
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DropLimitAmount = 'drop_limit_amount',
  /** column name */
  DropLimitPercent = 'drop_limit_percent',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxStatus = 'lootbox_status',
  /** column name */
  LootboxTargetPrice = 'lootbox_target_price'
}

export type Lootboxes_Bonus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Bonus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Bonus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Bonus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Bonus_Var_Pop_Fields = {
  __typename?: 'lootboxes_bonus_var_pop_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Bonus_Var_Samp_Fields = {
  __typename?: 'lootboxes_bonus_var_samp_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lootboxes_Bonus_Variance_Fields = {
  __typename?: 'lootboxes_bonus_variance_fields';
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views = {
  __typename?: 'lootboxes_bonus_views';
  is_shown: Scalars['Boolean'];
  item_category: Scalars['String'];
  item_drop_chance: Scalars['Float'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Float'];
  item_type: Scalars['String'];
  /** An object relationship */
  localized_name?: Maybe<Lootboxes_Items_Names>;
  lootbox_id: Scalars['uuid'];
  /** An object relationship */
  lootboxes_bonus?: Maybe<Lootboxes_Bonus>;
  sort: Scalars['Int'];
};

/** aggregated selection of "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Aggregate = {
  __typename?: 'lootboxes_bonus_views_aggregate';
  aggregate?: Maybe<Lootboxes_Bonus_Views_Aggregate_Fields>;
  nodes: Array<Lootboxes_Bonus_Views>;
};

export type Lootboxes_Bonus_Views_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Lootboxes_Bonus_Views_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Lootboxes_Bonus_Views_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Lootboxes_Bonus_Views_Aggregate_Bool_Exp_Count>;
};

export type Lootboxes_Bonus_Views_Aggregate_Bool_Exp_Bool_And = {
  arguments: Lootboxes_Bonus_Views_Select_Column_Lootboxes_Bonus_Views_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lootboxes_Bonus_Views_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Lootboxes_Bonus_Views_Select_Column_Lootboxes_Bonus_Views_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lootboxes_Bonus_Views_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootboxes_Bonus_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Aggregate_Fields = {
  __typename?: 'lootboxes_bonus_views_aggregate_fields';
  avg?: Maybe<Lootboxes_Bonus_Views_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Bonus_Views_Max_Fields>;
  min?: Maybe<Lootboxes_Bonus_Views_Min_Fields>;
  stddev?: Maybe<Lootboxes_Bonus_Views_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Bonus_Views_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Bonus_Views_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Bonus_Views_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Bonus_Views_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Bonus_Views_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Bonus_Views_Variance_Fields>;
};


/** aggregate fields of "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Bonus_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Aggregate_Order_By = {
  avg?: InputMaybe<Lootboxes_Bonus_Views_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootboxes_Bonus_Views_Max_Order_By>;
  min?: InputMaybe<Lootboxes_Bonus_Views_Min_Order_By>;
  stddev?: InputMaybe<Lootboxes_Bonus_Views_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lootboxes_Bonus_Views_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lootboxes_Bonus_Views_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lootboxes_Bonus_Views_Sum_Order_By>;
  var_pop?: InputMaybe<Lootboxes_Bonus_Views_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lootboxes_Bonus_Views_Var_Samp_Order_By>;
  variance?: InputMaybe<Lootboxes_Bonus_Views_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Arr_Rel_Insert_Input = {
  data: Array<Lootboxes_Bonus_Views_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Bonus_Views_On_Conflict>;
};

/** aggregate avg on columns */
export type Lootboxes_Bonus_Views_Avg_Fields = {
  __typename?: 'lootboxes_bonus_views_avg_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Avg_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lootboxes_bonus_views". All fields are combined with a logical 'AND'. */
export type Lootboxes_Bonus_Views_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Bonus_Views_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Bonus_Views_Bool_Exp>>;
  is_shown?: InputMaybe<Boolean_Comparison_Exp>;
  item_category?: InputMaybe<String_Comparison_Exp>;
  item_drop_chance?: InputMaybe<Float_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_image_link?: InputMaybe<String_Comparison_Exp>;
  item_max_show_price?: InputMaybe<Float_Comparison_Exp>;
  item_min_show_price?: InputMaybe<Float_Comparison_Exp>;
  item_quality?: InputMaybe<String_Comparison_Exp>;
  item_rarity?: InputMaybe<String_Comparison_Exp>;
  item_start_price?: InputMaybe<Float_Comparison_Exp>;
  item_type?: InputMaybe<String_Comparison_Exp>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Bool_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootboxes_bonus?: InputMaybe<Lootboxes_Bonus_Bool_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_bonus_views" */
export enum Lootboxes_Bonus_Views_Constraint {
  /** unique or primary key constraint on columns "lootbox_id", "item_id" */
  LootboxesBonusViewsPkey = 'lootboxes_bonus_views_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Inc_Input = {
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Insert_Input = {
  is_shown?: InputMaybe<Scalars['Boolean']>;
  item_category?: InputMaybe<Scalars['String']>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Obj_Rel_Insert_Input>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootboxes_bonus?: InputMaybe<Lootboxes_Bonus_Obj_Rel_Insert_Input>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lootboxes_Bonus_Views_Max_Fields = {
  __typename?: 'lootboxes_bonus_views_max_fields';
  item_category?: Maybe<Scalars['String']>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_type?: Maybe<Scalars['String']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Max_Order_By = {
  item_category?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootboxes_Bonus_Views_Min_Fields = {
  __typename?: 'lootboxes_bonus_views_min_fields';
  item_category?: Maybe<Scalars['String']>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_type?: Maybe<Scalars['String']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Min_Order_By = {
  item_category?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Mutation_Response = {
  __typename?: 'lootboxes_bonus_views_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Bonus_Views>;
};

/** input type for inserting object relation for remote table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Obj_Rel_Insert_Input = {
  data: Lootboxes_Bonus_Views_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Bonus_Views_On_Conflict>;
};

/** on_conflict condition type for table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_On_Conflict = {
  constraint: Lootboxes_Bonus_Views_Constraint;
  update_columns?: Array<Lootboxes_Bonus_Views_Update_Column>;
  where?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_bonus_views". */
export type Lootboxes_Bonus_Views_Order_By = {
  is_shown?: InputMaybe<Order_By>;
  item_category?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootboxes_bonus?: InputMaybe<Lootboxes_Bonus_Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_bonus_views */
export type Lootboxes_Bonus_Views_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};

/** select columns of table "lootboxes_bonus_views" */
export enum Lootboxes_Bonus_Views_Select_Column {
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  ItemCategory = 'item_category',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemMaxShowPrice = 'item_max_show_price',
  /** column name */
  ItemMinShowPrice = 'item_min_show_price',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  Sort = 'sort'
}

/** select "lootboxes_bonus_views_aggregate_bool_exp_bool_and_arguments_columns" columns of table "lootboxes_bonus_views" */
export enum Lootboxes_Bonus_Views_Select_Column_Lootboxes_Bonus_Views_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsShown = 'is_shown'
}

/** select "lootboxes_bonus_views_aggregate_bool_exp_bool_or_arguments_columns" columns of table "lootboxes_bonus_views" */
export enum Lootboxes_Bonus_Views_Select_Column_Lootboxes_Bonus_Views_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsShown = 'is_shown'
}

/** input type for updating data in table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Set_Input = {
  is_shown?: InputMaybe<Scalars['Boolean']>;
  item_category?: InputMaybe<Scalars['String']>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Bonus_Views_Stddev_Fields = {
  __typename?: 'lootboxes_bonus_views_stddev_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Stddev_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Bonus_Views_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_bonus_views_stddev_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Stddev_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Bonus_Views_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_bonus_views_stddev_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Stddev_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Bonus_Views_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Bonus_Views_Stream_Cursor_Value_Input = {
  is_shown?: InputMaybe<Scalars['Boolean']>;
  item_category?: InputMaybe<Scalars['String']>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lootboxes_Bonus_Views_Sum_Fields = {
  __typename?: 'lootboxes_bonus_views_sum_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Sum_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** update columns of table "lootboxes_bonus_views" */
export enum Lootboxes_Bonus_Views_Update_Column {
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  ItemCategory = 'item_category',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemMaxShowPrice = 'item_max_show_price',
  /** column name */
  ItemMinShowPrice = 'item_min_show_price',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  Sort = 'sort'
}

export type Lootboxes_Bonus_Views_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Bonus_Views_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Bonus_Views_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Bonus_Views_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Bonus_Views_Var_Pop_Fields = {
  __typename?: 'lootboxes_bonus_views_var_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Var_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Bonus_Views_Var_Samp_Fields = {
  __typename?: 'lootboxes_bonus_views_var_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Var_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lootboxes_Bonus_Views_Variance_Fields = {
  __typename?: 'lootboxes_bonus_views_variance_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lootboxes_bonus_views" */
export type Lootboxes_Bonus_Views_Variance_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lootboxes". All fields are combined with a logical 'AND'. */
export type Lootboxes_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Bool_Exp>>;
  amp_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  badge?: InputMaybe<String_Comparison_Exp>;
  current_to_parent?: InputMaybe<Lootboxes_Bool_Exp>;
  hidden?: InputMaybe<Boolean_Comparison_Exp>;
  items_in_lootbox_views?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
  items_in_lootbox_views_aggregate?: InputMaybe<Items_In_Lootbox_View_Aggregate_Bool_Exp>;
  items_in_lootboxes?: InputMaybe<Items_In_Lootbox_Bool_Exp>;
  items_in_lootboxes_aggregate?: InputMaybe<Items_In_Lootbox_Aggregate_Bool_Exp>;
  lootbox_category?: InputMaybe<Int_Comparison_Exp>;
  lootbox_category_data?: InputMaybe<Lootbox_Category_Bool_Exp>;
  lootbox_create_date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  lootbox_current_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_discount?: InputMaybe<Float_Comparison_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_image_link?: InputMaybe<String_Comparison_Exp>;
  lootbox_items_sets?: InputMaybe<Lootbox_Items_Sets_Bool_Exp>;
  lootbox_items_sets_aggregate?: InputMaybe<Lootbox_Items_Sets_Aggregate_Bool_Exp>;
  lootbox_max_price?: InputMaybe<Float_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  lootbox_open_count?: InputMaybe<Int_Comparison_Exp>;
  lootbox_open_max_count?: InputMaybe<Int_Comparison_Exp>;
  lootbox_price?: InputMaybe<Float_Comparison_Exp>;
  lootbox_slug?: InputMaybe<String_Comparison_Exp>;
  lootbox_status?: InputMaybe<Boolean_Comparison_Exp>;
  lootbox_top_drops?: InputMaybe<Lootboxes_Top_Drop_Bool_Exp>;
  lootbox_top_drops_aggregate?: InputMaybe<Lootboxes_Top_Drop_Aggregate_Bool_Exp>;
  lotbox_rec_price?: InputMaybe<Float_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes" */
export enum Lootboxes_Constraint {
  /** unique or primary key constraint on columns "lootbox_id" */
  LootboxesPkey = 'lootboxes_pkey'
}

/** columns and relationships of "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_Category = {
  __typename?: 'lootboxes_dynamic_category';
  category_active: Scalars['Boolean'];
  category_anchor: Scalars['String'];
  category_id: Scalars['Int'];
  category_name: Scalars['String'];
  category_short_name: Scalars['String'];
  category_sort: Scalars['Int'];
  category_title: Scalars['String'];
  info_text?: Maybe<Scalars['String']>;
  lootbox_dublicate: Scalars['Boolean'];
  /** An array relationship */
  lootboxes_dynamic_category_lootboxes: Array<Lootboxes_Dynamic_Category_Lootboxes>;
  /** An aggregate relationship */
  lootboxes_dynamic_category_lootboxes_aggregate: Lootboxes_Dynamic_Category_Lootboxes_Aggregate;
};


/** columns and relationships of "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_CategoryLootboxes_Dynamic_Category_LootboxesArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>;
};


/** columns and relationships of "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_CategoryLootboxes_Dynamic_Category_Lootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>;
};

/** aggregated selection of "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_Category_Aggregate = {
  __typename?: 'lootboxes_dynamic_category_aggregate';
  aggregate?: Maybe<Lootboxes_Dynamic_Category_Aggregate_Fields>;
  nodes: Array<Lootboxes_Dynamic_Category>;
};

/** aggregate fields of "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_Category_Aggregate_Fields = {
  __typename?: 'lootboxes_dynamic_category_aggregate_fields';
  avg?: Maybe<Lootboxes_Dynamic_Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Dynamic_Category_Max_Fields>;
  min?: Maybe<Lootboxes_Dynamic_Category_Min_Fields>;
  stddev?: Maybe<Lootboxes_Dynamic_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Dynamic_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Dynamic_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Dynamic_Category_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Dynamic_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Dynamic_Category_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Dynamic_Category_Variance_Fields>;
};


/** aggregate fields of "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Dynamic_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lootboxes_Dynamic_Category_Avg_Fields = {
  __typename?: 'lootboxes_dynamic_category_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lootboxes_dynamic_category". All fields are combined with a logical 'AND'. */
export type Lootboxes_Dynamic_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Dynamic_Category_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Dynamic_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Dynamic_Category_Bool_Exp>>;
  category_active?: InputMaybe<Boolean_Comparison_Exp>;
  category_anchor?: InputMaybe<String_Comparison_Exp>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  category_name?: InputMaybe<String_Comparison_Exp>;
  category_short_name?: InputMaybe<String_Comparison_Exp>;
  category_sort?: InputMaybe<Int_Comparison_Exp>;
  category_title?: InputMaybe<String_Comparison_Exp>;
  info_text?: InputMaybe<String_Comparison_Exp>;
  lootbox_dublicate?: InputMaybe<Boolean_Comparison_Exp>;
  lootboxes_dynamic_category_lootboxes?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>;
  lootboxes_dynamic_category_lootboxes_aggregate?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "lootboxes_dynamic_category" */
export enum Lootboxes_Dynamic_Category_Constraint {
  /** unique or primary key constraint on columns "category_id" */
  LootboxesDynamicCategoryPkey = 'lootboxes_dynamic_category_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_Category_Inc_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  category_sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_Category_Insert_Input = {
  category_active?: InputMaybe<Scalars['Boolean']>;
  category_anchor?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  category_name?: InputMaybe<Scalars['String']>;
  category_short_name?: InputMaybe<Scalars['String']>;
  category_sort?: InputMaybe<Scalars['Int']>;
  category_title?: InputMaybe<Scalars['String']>;
  info_text?: InputMaybe<Scalars['String']>;
  lootbox_dublicate?: InputMaybe<Scalars['Boolean']>;
  lootboxes_dynamic_category_lootboxes?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes = {
  __typename?: 'lootboxes_dynamic_category_lootboxes';
  category_id: Scalars['Int'];
  lootbox_slug: Scalars['String'];
  /** An array relationship */
  lootboxes: Array<Lootboxes>;
  /** An aggregate relationship */
  lootboxes_aggregate: Lootboxes_Aggregate;
  /** An object relationship */
  lootboxes_dynamic_category: Lootboxes_Dynamic_Category;
  sort: Scalars['Int'];
};


/** columns and relationships of "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_LootboxesLootboxesArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Bool_Exp>;
};


/** columns and relationships of "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_LootboxesLootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Bool_Exp>;
};

/** aggregated selection of "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Aggregate = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_aggregate';
  aggregate?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Aggregate_Fields>;
  nodes: Array<Lootboxes_Dynamic_Category_Lootboxes>;
};

export type Lootboxes_Dynamic_Category_Lootboxes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Aggregate_Bool_Exp_Count>;
};

export type Lootboxes_Dynamic_Category_Lootboxes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Aggregate_Fields = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_aggregate_fields';
  avg?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Max_Fields>;
  min?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Min_Fields>;
  stddev?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Variance_Fields>;
};


/** aggregate fields of "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Aggregate_Order_By = {
  avg?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Max_Order_By>;
  min?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Min_Order_By>;
  stddev?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Sum_Order_By>;
  var_pop?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Var_Samp_Order_By>;
  variance?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Arr_Rel_Insert_Input = {
  data: Array<Lootboxes_Dynamic_Category_Lootboxes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_On_Conflict>;
};

/** aggregate avg on columns */
export type Lootboxes_Dynamic_Category_Lootboxes_Avg_Fields = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lootboxes_dynamic_category_lootboxes". All fields are combined with a logical 'AND'. */
export type Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  lootbox_slug?: InputMaybe<String_Comparison_Exp>;
  lootboxes?: InputMaybe<Lootboxes_Bool_Exp>;
  lootboxes_aggregate?: InputMaybe<Lootboxes_Aggregate_Bool_Exp>;
  lootboxes_dynamic_category?: InputMaybe<Lootboxes_Dynamic_Category_Bool_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_dynamic_category_lootboxes" */
export enum Lootboxes_Dynamic_Category_Lootboxes_Constraint {
  /** unique or primary key constraint on columns "category_id", "lootbox_slug" */
  LootboxesDynamicCategoryLootboxesPkey = 'lootboxes_dynamic_category_lootboxes_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Inc_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Insert_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootboxes?: InputMaybe<Lootboxes_Arr_Rel_Insert_Input>;
  lootboxes_dynamic_category?: InputMaybe<Lootboxes_Dynamic_Category_Obj_Rel_Insert_Input>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lootboxes_Dynamic_Category_Lootboxes_Max_Fields = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_max_fields';
  category_id?: Maybe<Scalars['Int']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootboxes_Dynamic_Category_Lootboxes_Min_Fields = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_min_fields';
  category_id?: Maybe<Scalars['Int']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Mutation_Response = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Dynamic_Category_Lootboxes>;
};

/** on_conflict condition type for table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_On_Conflict = {
  constraint: Lootboxes_Dynamic_Category_Lootboxes_Constraint;
  update_columns?: Array<Lootboxes_Dynamic_Category_Lootboxes_Update_Column>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_dynamic_category_lootboxes". */
export type Lootboxes_Dynamic_Category_Lootboxes_Order_By = {
  category_id?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  lootboxes_aggregate?: InputMaybe<Lootboxes_Aggregate_Order_By>;
  lootboxes_dynamic_category?: InputMaybe<Lootboxes_Dynamic_Category_Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_dynamic_category_lootboxes */
export type Lootboxes_Dynamic_Category_Lootboxes_Pk_Columns_Input = {
  category_id: Scalars['Int'];
  lootbox_slug: Scalars['String'];
};

/** select columns of table "lootboxes_dynamic_category_lootboxes" */
export enum Lootboxes_Dynamic_Category_Lootboxes_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  Sort = 'sort'
}

/** input type for updating data in table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Set_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Dynamic_Category_Lootboxes_Stddev_Fields = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Dynamic_Category_Lootboxes_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Dynamic_Category_Lootboxes_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Dynamic_Category_Lootboxes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Dynamic_Category_Lootboxes_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lootboxes_Dynamic_Category_Lootboxes_Sum_Fields = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_sum_fields';
  category_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** update columns of table "lootboxes_dynamic_category_lootboxes" */
export enum Lootboxes_Dynamic_Category_Lootboxes_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  Sort = 'sort'
}

export type Lootboxes_Dynamic_Category_Lootboxes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Dynamic_Category_Lootboxes_Var_Pop_Fields = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Dynamic_Category_Lootboxes_Var_Samp_Fields = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lootboxes_Dynamic_Category_Lootboxes_Variance_Fields = {
  __typename?: 'lootboxes_dynamic_category_lootboxes_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lootboxes_dynamic_category_lootboxes" */
export type Lootboxes_Dynamic_Category_Lootboxes_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Lootboxes_Dynamic_Category_Max_Fields = {
  __typename?: 'lootboxes_dynamic_category_max_fields';
  category_anchor?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  category_name?: Maybe<Scalars['String']>;
  category_short_name?: Maybe<Scalars['String']>;
  category_sort?: Maybe<Scalars['Int']>;
  category_title?: Maybe<Scalars['String']>;
  info_text?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lootboxes_Dynamic_Category_Min_Fields = {
  __typename?: 'lootboxes_dynamic_category_min_fields';
  category_anchor?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  category_name?: Maybe<Scalars['String']>;
  category_short_name?: Maybe<Scalars['String']>;
  category_sort?: Maybe<Scalars['Int']>;
  category_title?: Maybe<Scalars['String']>;
  info_text?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_Category_Mutation_Response = {
  __typename?: 'lootboxes_dynamic_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Dynamic_Category>;
};

/** input type for inserting object relation for remote table "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_Category_Obj_Rel_Insert_Input = {
  data: Lootboxes_Dynamic_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Dynamic_Category_On_Conflict>;
};

/** on_conflict condition type for table "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_Category_On_Conflict = {
  constraint: Lootboxes_Dynamic_Category_Constraint;
  update_columns?: Array<Lootboxes_Dynamic_Category_Update_Column>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_dynamic_category". */
export type Lootboxes_Dynamic_Category_Order_By = {
  category_active?: InputMaybe<Order_By>;
  category_anchor?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  category_name?: InputMaybe<Order_By>;
  category_short_name?: InputMaybe<Order_By>;
  category_sort?: InputMaybe<Order_By>;
  category_title?: InputMaybe<Order_By>;
  info_text?: InputMaybe<Order_By>;
  lootbox_dublicate?: InputMaybe<Order_By>;
  lootboxes_dynamic_category_lootboxes_aggregate?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Aggregate_Order_By>;
};

/** primary key columns input for table: lootboxes_dynamic_category */
export type Lootboxes_Dynamic_Category_Pk_Columns_Input = {
  category_id: Scalars['Int'];
};

/** select columns of table "lootboxes_dynamic_category" */
export enum Lootboxes_Dynamic_Category_Select_Column {
  /** column name */
  CategoryActive = 'category_active',
  /** column name */
  CategoryAnchor = 'category_anchor',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CategoryName = 'category_name',
  /** column name */
  CategoryShortName = 'category_short_name',
  /** column name */
  CategorySort = 'category_sort',
  /** column name */
  CategoryTitle = 'category_title',
  /** column name */
  InfoText = 'info_text',
  /** column name */
  LootboxDublicate = 'lootbox_dublicate'
}

/** input type for updating data in table "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_Category_Set_Input = {
  category_active?: InputMaybe<Scalars['Boolean']>;
  category_anchor?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  category_name?: InputMaybe<Scalars['String']>;
  category_short_name?: InputMaybe<Scalars['String']>;
  category_sort?: InputMaybe<Scalars['Int']>;
  category_title?: InputMaybe<Scalars['String']>;
  info_text?: InputMaybe<Scalars['String']>;
  lootbox_dublicate?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Dynamic_Category_Stddev_Fields = {
  __typename?: 'lootboxes_dynamic_category_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Dynamic_Category_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_dynamic_category_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Dynamic_Category_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_dynamic_category_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lootboxes_dynamic_category" */
export type Lootboxes_Dynamic_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Dynamic_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Dynamic_Category_Stream_Cursor_Value_Input = {
  category_active?: InputMaybe<Scalars['Boolean']>;
  category_anchor?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  category_name?: InputMaybe<Scalars['String']>;
  category_short_name?: InputMaybe<Scalars['String']>;
  category_sort?: InputMaybe<Scalars['Int']>;
  category_title?: InputMaybe<Scalars['String']>;
  info_text?: InputMaybe<Scalars['String']>;
  lootbox_dublicate?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Lootboxes_Dynamic_Category_Sum_Fields = {
  __typename?: 'lootboxes_dynamic_category_sum_fields';
  category_id?: Maybe<Scalars['Int']>;
  category_sort?: Maybe<Scalars['Int']>;
};

/** update columns of table "lootboxes_dynamic_category" */
export enum Lootboxes_Dynamic_Category_Update_Column {
  /** column name */
  CategoryActive = 'category_active',
  /** column name */
  CategoryAnchor = 'category_anchor',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CategoryName = 'category_name',
  /** column name */
  CategoryShortName = 'category_short_name',
  /** column name */
  CategorySort = 'category_sort',
  /** column name */
  CategoryTitle = 'category_title',
  /** column name */
  InfoText = 'info_text',
  /** column name */
  LootboxDublicate = 'lootbox_dublicate'
}

export type Lootboxes_Dynamic_Category_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Dynamic_Category_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Dynamic_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Dynamic_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Dynamic_Category_Var_Pop_Fields = {
  __typename?: 'lootboxes_dynamic_category_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Dynamic_Category_Var_Samp_Fields = {
  __typename?: 'lootboxes_dynamic_category_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lootboxes_Dynamic_Category_Variance_Fields = {
  __typename?: 'lootboxes_dynamic_category_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lootboxes_free" */
export type Lootboxes_Free = {
  __typename?: 'lootboxes_free';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  coin_price: Scalars['Int'];
  /** Флаг, указывающий, является ли кейс мистическим */
  is_mistery?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  items_in_lootbox: Array<Lootboxes_Free_Items>;
  /** An aggregate relationship */
  items_in_lootbox_aggregate: Lootboxes_Free_Items_Aggregate;
  /** An array relationship */
  items_view: Array<Lootboxes_Free_Views>;
  /** An aggregate relationship */
  items_view_aggregate: Lootboxes_Free_Views_Aggregate;
  lootbox_available_amount: Scalars['Float'];
  lootbox_category: Scalars['Int'];
  lootbox_create_datetime: Scalars['timestamp'];
  lootbox_id: Scalars['uuid'];
  lootbox_image_link: Scalars['String'];
  lootbox_name: Scalars['String'];
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_status: Scalars['Boolean'];
  sort: Scalars['Int'];
  user_takes: Array<User_Takes_Free_Lootbox>;
  user_takes_aggregate: User_Takes_Free_Lootbox_Aggregate;
};


/** columns and relationships of "lootboxes_free" */
export type Lootboxes_FreeItems_In_LootboxArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Items_Bool_Exp>;
};


/** columns and relationships of "lootboxes_free" */
export type Lootboxes_FreeItems_In_Lootbox_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Items_Bool_Exp>;
};


/** columns and relationships of "lootboxes_free" */
export type Lootboxes_FreeItems_ViewArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
};


/** columns and relationships of "lootboxes_free" */
export type Lootboxes_FreeItems_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
};


/** columns and relationships of "lootboxes_free" */
export type Lootboxes_FreeUser_TakesArgs = {
  distinct_on?: InputMaybe<Array<User_Takes_Free_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Takes_Free_Lootbox_Order_By>>;
  where?: InputMaybe<User_Takes_Free_Lootbox_Bool_Exp>;
};


/** columns and relationships of "lootboxes_free" */
export type Lootboxes_FreeUser_Takes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Takes_Free_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Takes_Free_Lootbox_Order_By>>;
  where?: InputMaybe<User_Takes_Free_Lootbox_Bool_Exp>;
};

/** aggregated selection of "lootboxes_free" */
export type Lootboxes_Free_Aggregate = {
  __typename?: 'lootboxes_free_aggregate';
  aggregate?: Maybe<Lootboxes_Free_Aggregate_Fields>;
  nodes: Array<Lootboxes_Free>;
};

export type Lootboxes_Free_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Lootboxes_Free_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Lootboxes_Free_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Lootboxes_Free_Aggregate_Bool_Exp_Count>;
};

export type Lootboxes_Free_Aggregate_Bool_Exp_Bool_And = {
  arguments: Lootboxes_Free_Select_Column_Lootboxes_Free_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Free_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lootboxes_Free_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Lootboxes_Free_Select_Column_Lootboxes_Free_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Free_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lootboxes_Free_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootboxes_Free_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Free_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootboxes_free" */
export type Lootboxes_Free_Aggregate_Fields = {
  __typename?: 'lootboxes_free_aggregate_fields';
  avg?: Maybe<Lootboxes_Free_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Free_Max_Fields>;
  min?: Maybe<Lootboxes_Free_Min_Fields>;
  stddev?: Maybe<Lootboxes_Free_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Free_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Free_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Free_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Free_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Free_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Free_Variance_Fields>;
};


/** aggregate fields of "lootboxes_free" */
export type Lootboxes_Free_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Free_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootboxes_free" */
export type Lootboxes_Free_Aggregate_Order_By = {
  avg?: InputMaybe<Lootboxes_Free_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootboxes_Free_Max_Order_By>;
  min?: InputMaybe<Lootboxes_Free_Min_Order_By>;
  stddev?: InputMaybe<Lootboxes_Free_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lootboxes_Free_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lootboxes_Free_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lootboxes_Free_Sum_Order_By>;
  var_pop?: InputMaybe<Lootboxes_Free_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lootboxes_Free_Var_Samp_Order_By>;
  variance?: InputMaybe<Lootboxes_Free_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lootboxes_free" */
export type Lootboxes_Free_Arr_Rel_Insert_Input = {
  data: Array<Lootboxes_Free_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Free_On_Conflict>;
};

/** aggregate avg on columns */
export type Lootboxes_Free_Avg_Fields = {
  __typename?: 'lootboxes_free_avg_fields';
  coin_price?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lootboxes_free" */
export type Lootboxes_Free_Avg_Order_By = {
  coin_price?: InputMaybe<Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lootboxes_free". All fields are combined with a logical 'AND'. */
export type Lootboxes_Free_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Free_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Free_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Free_Bool_Exp>>;
  amp_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  coin_price?: InputMaybe<Int_Comparison_Exp>;
  is_mistery?: InputMaybe<Boolean_Comparison_Exp>;
  items_in_lootbox?: InputMaybe<Lootboxes_Free_Items_Bool_Exp>;
  items_in_lootbox_aggregate?: InputMaybe<Lootboxes_Free_Items_Aggregate_Bool_Exp>;
  items_view?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
  items_view_aggregate?: InputMaybe<Lootboxes_Free_Views_Aggregate_Bool_Exp>;
  lootbox_available_amount?: InputMaybe<Float_Comparison_Exp>;
  lootbox_category?: InputMaybe<Int_Comparison_Exp>;
  lootbox_create_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_image_link?: InputMaybe<String_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  lootbox_slug?: InputMaybe<String_Comparison_Exp>;
  lootbox_status?: InputMaybe<Boolean_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "lootboxes_free_category" */
export type Lootboxes_Free_Category = {
  __typename?: 'lootboxes_free_category';
  category_active: Scalars['Boolean'];
  category_anchor: Scalars['String'];
  category_id: Scalars['Int'];
  category_name: Scalars['String'];
  category_short_name: Scalars['String'];
  category_sort: Scalars['Int'];
  category_title: Scalars['String'];
  /** fetch data from the table: "lootboxes_free" */
  lootboxes_free: Array<Lootboxes_Free>;
  /** fetch aggregated fields from the table: "lootboxes_free" */
  lootboxes_free_aggregate: Lootboxes_Free_Aggregate;
};


/** columns and relationships of "lootboxes_free_category" */
export type Lootboxes_Free_CategoryLootboxes_FreeArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Bool_Exp>;
};


/** columns and relationships of "lootboxes_free_category" */
export type Lootboxes_Free_CategoryLootboxes_Free_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Bool_Exp>;
};

/** aggregated selection of "lootboxes_free_category" */
export type Lootboxes_Free_Category_Aggregate = {
  __typename?: 'lootboxes_free_category_aggregate';
  aggregate?: Maybe<Lootboxes_Free_Category_Aggregate_Fields>;
  nodes: Array<Lootboxes_Free_Category>;
};

/** aggregate fields of "lootboxes_free_category" */
export type Lootboxes_Free_Category_Aggregate_Fields = {
  __typename?: 'lootboxes_free_category_aggregate_fields';
  avg?: Maybe<Lootboxes_Free_Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Free_Category_Max_Fields>;
  min?: Maybe<Lootboxes_Free_Category_Min_Fields>;
  stddev?: Maybe<Lootboxes_Free_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Free_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Free_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Free_Category_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Free_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Free_Category_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Free_Category_Variance_Fields>;
};


/** aggregate fields of "lootboxes_free_category" */
export type Lootboxes_Free_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Free_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lootboxes_Free_Category_Avg_Fields = {
  __typename?: 'lootboxes_free_category_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lootboxes_free_category". All fields are combined with a logical 'AND'. */
export type Lootboxes_Free_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Free_Category_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Free_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Free_Category_Bool_Exp>>;
  category_active?: InputMaybe<Boolean_Comparison_Exp>;
  category_anchor?: InputMaybe<String_Comparison_Exp>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  category_name?: InputMaybe<String_Comparison_Exp>;
  category_short_name?: InputMaybe<String_Comparison_Exp>;
  category_sort?: InputMaybe<Int_Comparison_Exp>;
  category_title?: InputMaybe<String_Comparison_Exp>;
  lootboxes_free?: InputMaybe<Lootboxes_Free_Bool_Exp>;
  lootboxes_free_aggregate?: InputMaybe<Lootboxes_Free_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "lootboxes_free_category" */
export enum Lootboxes_Free_Category_Constraint {
  /** unique or primary key constraint on columns "category_id" */
  LootboxesFreeCategoryPkey = 'lootboxes_free_category_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_free_category" */
export type Lootboxes_Free_Category_Inc_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  category_sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes_free_category" */
export type Lootboxes_Free_Category_Insert_Input = {
  category_active?: InputMaybe<Scalars['Boolean']>;
  category_anchor?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  category_name?: InputMaybe<Scalars['String']>;
  category_short_name?: InputMaybe<Scalars['String']>;
  category_sort?: InputMaybe<Scalars['Int']>;
  category_title?: InputMaybe<Scalars['String']>;
  lootboxes_free?: InputMaybe<Lootboxes_Free_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Lootboxes_Free_Category_Max_Fields = {
  __typename?: 'lootboxes_free_category_max_fields';
  category_anchor?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  category_name?: Maybe<Scalars['String']>;
  category_short_name?: Maybe<Scalars['String']>;
  category_sort?: Maybe<Scalars['Int']>;
  category_title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lootboxes_Free_Category_Min_Fields = {
  __typename?: 'lootboxes_free_category_min_fields';
  category_anchor?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  category_name?: Maybe<Scalars['String']>;
  category_short_name?: Maybe<Scalars['String']>;
  category_sort?: Maybe<Scalars['Int']>;
  category_title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lootboxes_free_category" */
export type Lootboxes_Free_Category_Mutation_Response = {
  __typename?: 'lootboxes_free_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Free_Category>;
};

/** on_conflict condition type for table "lootboxes_free_category" */
export type Lootboxes_Free_Category_On_Conflict = {
  constraint: Lootboxes_Free_Category_Constraint;
  update_columns?: Array<Lootboxes_Free_Category_Update_Column>;
  where?: InputMaybe<Lootboxes_Free_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_free_category". */
export type Lootboxes_Free_Category_Order_By = {
  category_active?: InputMaybe<Order_By>;
  category_anchor?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  category_name?: InputMaybe<Order_By>;
  category_short_name?: InputMaybe<Order_By>;
  category_sort?: InputMaybe<Order_By>;
  category_title?: InputMaybe<Order_By>;
  lootboxes_free_aggregate?: InputMaybe<Lootboxes_Free_Aggregate_Order_By>;
};

/** primary key columns input for table: lootboxes_free_category */
export type Lootboxes_Free_Category_Pk_Columns_Input = {
  category_id: Scalars['Int'];
};

/** select columns of table "lootboxes_free_category" */
export enum Lootboxes_Free_Category_Select_Column {
  /** column name */
  CategoryActive = 'category_active',
  /** column name */
  CategoryAnchor = 'category_anchor',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CategoryName = 'category_name',
  /** column name */
  CategoryShortName = 'category_short_name',
  /** column name */
  CategorySort = 'category_sort',
  /** column name */
  CategoryTitle = 'category_title'
}

/** input type for updating data in table "lootboxes_free_category" */
export type Lootboxes_Free_Category_Set_Input = {
  category_active?: InputMaybe<Scalars['Boolean']>;
  category_anchor?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  category_name?: InputMaybe<Scalars['String']>;
  category_short_name?: InputMaybe<Scalars['String']>;
  category_sort?: InputMaybe<Scalars['Int']>;
  category_title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Free_Category_Stddev_Fields = {
  __typename?: 'lootboxes_free_category_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Free_Category_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_free_category_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Free_Category_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_free_category_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lootboxes_free_category" */
export type Lootboxes_Free_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Free_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Free_Category_Stream_Cursor_Value_Input = {
  category_active?: InputMaybe<Scalars['Boolean']>;
  category_anchor?: InputMaybe<Scalars['String']>;
  category_id?: InputMaybe<Scalars['Int']>;
  category_name?: InputMaybe<Scalars['String']>;
  category_short_name?: InputMaybe<Scalars['String']>;
  category_sort?: InputMaybe<Scalars['Int']>;
  category_title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Lootboxes_Free_Category_Sum_Fields = {
  __typename?: 'lootboxes_free_category_sum_fields';
  category_id?: Maybe<Scalars['Int']>;
  category_sort?: Maybe<Scalars['Int']>;
};

/** update columns of table "lootboxes_free_category" */
export enum Lootboxes_Free_Category_Update_Column {
  /** column name */
  CategoryActive = 'category_active',
  /** column name */
  CategoryAnchor = 'category_anchor',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CategoryName = 'category_name',
  /** column name */
  CategoryShortName = 'category_short_name',
  /** column name */
  CategorySort = 'category_sort',
  /** column name */
  CategoryTitle = 'category_title'
}

export type Lootboxes_Free_Category_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Free_Category_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Free_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Free_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Free_Category_Var_Pop_Fields = {
  __typename?: 'lootboxes_free_category_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Free_Category_Var_Samp_Fields = {
  __typename?: 'lootboxes_free_category_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lootboxes_Free_Category_Variance_Fields = {
  __typename?: 'lootboxes_free_category_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  category_sort?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "lootboxes_free" */
export enum Lootboxes_Free_Constraint {
  /** unique or primary key constraint on columns "lootbox_id" */
  LootboxesFreePkey = 'lootboxes_free_pkey'
}

/** columns and relationships of "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items = {
  __typename?: 'lootboxes_free_generator_items';
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};

/** aggregated selection of "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_Aggregate = {
  __typename?: 'lootboxes_free_generator_items_aggregate';
  aggregate?: Maybe<Lootboxes_Free_Generator_Items_Aggregate_Fields>;
  nodes: Array<Lootboxes_Free_Generator_Items>;
};

export type Lootboxes_Free_Generator_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lootboxes_Free_Generator_Items_Aggregate_Bool_Exp_Count>;
};

export type Lootboxes_Free_Generator_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Free_Generator_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_Aggregate_Fields = {
  __typename?: 'lootboxes_free_generator_items_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Free_Generator_Items_Max_Fields>;
  min?: Maybe<Lootboxes_Free_Generator_Items_Min_Fields>;
};


/** aggregate fields of "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootboxes_Free_Generator_Items_Max_Order_By>;
  min?: InputMaybe<Lootboxes_Free_Generator_Items_Min_Order_By>;
};

/** input type for inserting array relation for remote table "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_Arr_Rel_Insert_Input = {
  data: Array<Lootboxes_Free_Generator_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Free_Generator_Items_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lootboxes_free_generator_items". All fields are combined with a logical 'AND'. */
export type Lootboxes_Free_Generator_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Free_Generator_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Bool_Exp>>;
  generator_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_free_generator_items" */
export enum Lootboxes_Free_Generator_Items_Constraint {
  /** unique or primary key constraint on columns "item_hash_name", "generator_lootbox_id" */
  LootboxesFreeGeneratorItemsPkey = 'lootboxes_free_generator_items_pkey'
}

/** input type for inserting data into table "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_Insert_Input = {
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lootboxes_Free_Generator_Items_Max_Fields = {
  __typename?: 'lootboxes_free_generator_items_max_fields';
  generator_lootbox_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_Max_Order_By = {
  generator_lootbox_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootboxes_Free_Generator_Items_Min_Fields = {
  __typename?: 'lootboxes_free_generator_items_min_fields';
  generator_lootbox_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_Min_Order_By = {
  generator_lootbox_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_Mutation_Response = {
  __typename?: 'lootboxes_free_generator_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Free_Generator_Items>;
};

/** on_conflict condition type for table "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_On_Conflict = {
  constraint: Lootboxes_Free_Generator_Items_Constraint;
  update_columns?: Array<Lootboxes_Free_Generator_Items_Update_Column>;
  where?: InputMaybe<Lootboxes_Free_Generator_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_free_generator_items". */
export type Lootboxes_Free_Generator_Items_Order_By = {
  generator_lootbox_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_free_generator_items */
export type Lootboxes_Free_Generator_Items_Pk_Columns_Input = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};

/** select columns of table "lootboxes_free_generator_items" */
export enum Lootboxes_Free_Generator_Items_Select_Column {
  /** column name */
  GeneratorLootboxId = 'generator_lootbox_id',
  /** column name */
  ItemHashName = 'item_hash_name'
}

/** input type for updating data in table "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_Set_Input = {
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lootboxes_free_generator_items" */
export type Lootboxes_Free_Generator_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Free_Generator_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Free_Generator_Items_Stream_Cursor_Value_Input = {
  generator_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lootboxes_free_generator_items" */
export enum Lootboxes_Free_Generator_Items_Update_Column {
  /** column name */
  GeneratorLootboxId = 'generator_lootbox_id',
  /** column name */
  ItemHashName = 'item_hash_name'
}

export type Lootboxes_Free_Generator_Items_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Free_Generator_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Free_Generator_Items_Bool_Exp;
};

/** columns and relationships of "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_Params = {
  __typename?: 'lootboxes_free_generator_params';
  amp_lootbox_id: Scalars['uuid'];
  coin_target_price: Scalars['Int'];
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  drop_min_amount: Scalars['Int'];
  /** An array relationship */
  generator_items: Array<Lootboxes_Free_Generator_Items>;
  /** An aggregate relationship */
  generator_items_aggregate: Lootboxes_Free_Generator_Items_Aggregate;
  /** An object relationship */
  generator_mechanic?: Maybe<Lootboxes_Generator_Mechanics>;
  is_active: Scalars['Boolean'];
  /** Флаг, указывающий, является ли кейс мистическим */
  is_mistery: Scalars['Boolean'];
  last_generated_lootbox_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  last_lootbox?: Maybe<Lootboxes_Free>;
  lootbox_available_amount: Scalars['Float'];
  lootbox_category: Scalars['Int'];
  lootbox_generator_id: Scalars['uuid'];
  lootbox_image_link: Scalars['String'];
  lootbox_name: Scalars['String'];
  lootbox_slug: Scalars['String'];
  lootbox_target_price?: Maybe<Scalars['Int']>;
  max_lootbox_price_multiply_amount: Scalars['Int'];
  mechanic_id: Scalars['Int'];
  sort: Scalars['Int'];
  target_rtp: Scalars['Int'];
};


/** columns and relationships of "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_ParamsGenerator_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Items_Bool_Exp>;
};


/** columns and relationships of "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_ParamsGenerator_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Items_Bool_Exp>;
};

/** aggregated selection of "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_Params_Aggregate = {
  __typename?: 'lootboxes_free_generator_params_aggregate';
  aggregate?: Maybe<Lootboxes_Free_Generator_Params_Aggregate_Fields>;
  nodes: Array<Lootboxes_Free_Generator_Params>;
};

/** aggregate fields of "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_Params_Aggregate_Fields = {
  __typename?: 'lootboxes_free_generator_params_aggregate_fields';
  avg?: Maybe<Lootboxes_Free_Generator_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Free_Generator_Params_Max_Fields>;
  min?: Maybe<Lootboxes_Free_Generator_Params_Min_Fields>;
  stddev?: Maybe<Lootboxes_Free_Generator_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Free_Generator_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Free_Generator_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Free_Generator_Params_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Free_Generator_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Free_Generator_Params_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Free_Generator_Params_Variance_Fields>;
};


/** aggregate fields of "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Free_Generator_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lootboxes_Free_Generator_Params_Avg_Fields = {
  __typename?: 'lootboxes_free_generator_params_avg_fields';
  coin_target_price?: Maybe<Scalars['Float']>;
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  max_lootbox_price_multiply_amount?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lootboxes_free_generator_params". All fields are combined with a logical 'AND'. */
export type Lootboxes_Free_Generator_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Free_Generator_Params_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Free_Generator_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Free_Generator_Params_Bool_Exp>>;
  amp_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  coin_target_price?: InputMaybe<Int_Comparison_Exp>;
  drop_limit_amount?: InputMaybe<Int_Comparison_Exp>;
  drop_limit_percent?: InputMaybe<Int_Comparison_Exp>;
  drop_min_amount?: InputMaybe<Int_Comparison_Exp>;
  generator_items?: InputMaybe<Lootboxes_Free_Generator_Items_Bool_Exp>;
  generator_items_aggregate?: InputMaybe<Lootboxes_Free_Generator_Items_Aggregate_Bool_Exp>;
  generator_mechanic?: InputMaybe<Lootboxes_Generator_Mechanics_Bool_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_mistery?: InputMaybe<Boolean_Comparison_Exp>;
  last_generated_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  last_lootbox?: InputMaybe<Lootboxes_Free_Bool_Exp>;
  lootbox_available_amount?: InputMaybe<Float_Comparison_Exp>;
  lootbox_category?: InputMaybe<Int_Comparison_Exp>;
  lootbox_generator_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_image_link?: InputMaybe<String_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  lootbox_slug?: InputMaybe<String_Comparison_Exp>;
  lootbox_target_price?: InputMaybe<Int_Comparison_Exp>;
  max_lootbox_price_multiply_amount?: InputMaybe<Int_Comparison_Exp>;
  mechanic_id?: InputMaybe<Int_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
  target_rtp?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_free_generator_params" */
export enum Lootboxes_Free_Generator_Params_Constraint {
  /** unique or primary key constraint on columns "lootbox_slug" */
  LootboxesFreeGeneratorParamsLootboxSlugKey = 'lootboxes_free_generator_params_lootbox_slug_key',
  /** unique or primary key constraint on columns "lootbox_generator_id" */
  LootboxesFreeGeneratorParamsPkey = 'lootboxes_free_generator_params_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_Params_Inc_Input = {
  coin_target_price?: InputMaybe<Scalars['Int']>;
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  drop_min_amount?: InputMaybe<Scalars['Int']>;
  lootbox_available_amount?: InputMaybe<Scalars['Float']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  max_lootbox_price_multiply_amount?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
  target_rtp?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_Params_Insert_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  coin_target_price?: InputMaybe<Scalars['Int']>;
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  drop_min_amount?: InputMaybe<Scalars['Int']>;
  generator_items?: InputMaybe<Lootboxes_Free_Generator_Items_Arr_Rel_Insert_Input>;
  generator_mechanic?: InputMaybe<Lootboxes_Generator_Mechanics_Obj_Rel_Insert_Input>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Флаг, указывающий, является ли кейс мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  last_generated_lootbox_id?: InputMaybe<Scalars['uuid']>;
  last_lootbox?: InputMaybe<Lootboxes_Free_Obj_Rel_Insert_Input>;
  lootbox_available_amount?: InputMaybe<Scalars['Float']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_generator_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  max_lootbox_price_multiply_amount?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
  target_rtp?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lootboxes_Free_Generator_Params_Max_Fields = {
  __typename?: 'lootboxes_free_generator_params_max_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  coin_target_price?: Maybe<Scalars['Int']>;
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  drop_min_amount?: Maybe<Scalars['Int']>;
  last_generated_lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_generator_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_target_price?: Maybe<Scalars['Int']>;
  max_lootbox_price_multiply_amount?: Maybe<Scalars['Int']>;
  mechanic_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  target_rtp?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Lootboxes_Free_Generator_Params_Min_Fields = {
  __typename?: 'lootboxes_free_generator_params_min_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  coin_target_price?: Maybe<Scalars['Int']>;
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  drop_min_amount?: Maybe<Scalars['Int']>;
  last_generated_lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_generator_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lootbox_target_price?: Maybe<Scalars['Int']>;
  max_lootbox_price_multiply_amount?: Maybe<Scalars['Int']>;
  mechanic_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  target_rtp?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_Params_Mutation_Response = {
  __typename?: 'lootboxes_free_generator_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Free_Generator_Params>;
};

/** on_conflict condition type for table "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_Params_On_Conflict = {
  constraint: Lootboxes_Free_Generator_Params_Constraint;
  update_columns?: Array<Lootboxes_Free_Generator_Params_Update_Column>;
  where?: InputMaybe<Lootboxes_Free_Generator_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_free_generator_params". */
export type Lootboxes_Free_Generator_Params_Order_By = {
  amp_lootbox_id?: InputMaybe<Order_By>;
  coin_target_price?: InputMaybe<Order_By>;
  drop_limit_amount?: InputMaybe<Order_By>;
  drop_limit_percent?: InputMaybe<Order_By>;
  drop_min_amount?: InputMaybe<Order_By>;
  generator_items_aggregate?: InputMaybe<Lootboxes_Free_Generator_Items_Aggregate_Order_By>;
  generator_mechanic?: InputMaybe<Lootboxes_Generator_Mechanics_Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_mistery?: InputMaybe<Order_By>;
  last_generated_lootbox_id?: InputMaybe<Order_By>;
  last_lootbox?: InputMaybe<Lootboxes_Free_Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_generator_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  lootbox_target_price?: InputMaybe<Order_By>;
  max_lootbox_price_multiply_amount?: InputMaybe<Order_By>;
  mechanic_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
  target_rtp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_free_generator_params */
export type Lootboxes_Free_Generator_Params_Pk_Columns_Input = {
  lootbox_generator_id: Scalars['uuid'];
};

/** select columns of table "lootboxes_free_generator_params" */
export enum Lootboxes_Free_Generator_Params_Select_Column {
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  CoinTargetPrice = 'coin_target_price',
  /** column name */
  DropLimitAmount = 'drop_limit_amount',
  /** column name */
  DropLimitPercent = 'drop_limit_percent',
  /** column name */
  DropMinAmount = 'drop_min_amount',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsMistery = 'is_mistery',
  /** column name */
  LastGeneratedLootboxId = 'last_generated_lootbox_id',
  /** column name */
  LootboxAvailableAmount = 'lootbox_available_amount',
  /** column name */
  LootboxCategory = 'lootbox_category',
  /** column name */
  LootboxGeneratorId = 'lootbox_generator_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxTargetPrice = 'lootbox_target_price',
  /** column name */
  MaxLootboxPriceMultiplyAmount = 'max_lootbox_price_multiply_amount',
  /** column name */
  MechanicId = 'mechanic_id',
  /** column name */
  Sort = 'sort',
  /** column name */
  TargetRtp = 'target_rtp'
}

/** input type for updating data in table "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_Params_Set_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  coin_target_price?: InputMaybe<Scalars['Int']>;
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  drop_min_amount?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Флаг, указывающий, является ли кейс мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  last_generated_lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_available_amount?: InputMaybe<Scalars['Float']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_generator_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  max_lootbox_price_multiply_amount?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
  target_rtp?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Free_Generator_Params_Stddev_Fields = {
  __typename?: 'lootboxes_free_generator_params_stddev_fields';
  coin_target_price?: Maybe<Scalars['Float']>;
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  max_lootbox_price_multiply_amount?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Free_Generator_Params_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_free_generator_params_stddev_pop_fields';
  coin_target_price?: Maybe<Scalars['Float']>;
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  max_lootbox_price_multiply_amount?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Free_Generator_Params_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_free_generator_params_stddev_samp_fields';
  coin_target_price?: Maybe<Scalars['Float']>;
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  max_lootbox_price_multiply_amount?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lootboxes_free_generator_params" */
export type Lootboxes_Free_Generator_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Free_Generator_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Free_Generator_Params_Stream_Cursor_Value_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  coin_target_price?: InputMaybe<Scalars['Int']>;
  drop_limit_amount?: InputMaybe<Scalars['Int']>;
  drop_limit_percent?: InputMaybe<Scalars['Int']>;
  drop_min_amount?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Флаг, указывающий, является ли кейс мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  last_generated_lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_available_amount?: InputMaybe<Scalars['Float']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_generator_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_target_price?: InputMaybe<Scalars['Int']>;
  max_lootbox_price_multiply_amount?: InputMaybe<Scalars['Int']>;
  mechanic_id?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
  target_rtp?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lootboxes_Free_Generator_Params_Sum_Fields = {
  __typename?: 'lootboxes_free_generator_params_sum_fields';
  coin_target_price?: Maybe<Scalars['Int']>;
  drop_limit_amount?: Maybe<Scalars['Int']>;
  drop_limit_percent?: Maybe<Scalars['Int']>;
  drop_min_amount?: Maybe<Scalars['Int']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_target_price?: Maybe<Scalars['Int']>;
  max_lootbox_price_multiply_amount?: Maybe<Scalars['Int']>;
  mechanic_id?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  target_rtp?: Maybe<Scalars['Int']>;
};

/** update columns of table "lootboxes_free_generator_params" */
export enum Lootboxes_Free_Generator_Params_Update_Column {
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  CoinTargetPrice = 'coin_target_price',
  /** column name */
  DropLimitAmount = 'drop_limit_amount',
  /** column name */
  DropLimitPercent = 'drop_limit_percent',
  /** column name */
  DropMinAmount = 'drop_min_amount',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsMistery = 'is_mistery',
  /** column name */
  LastGeneratedLootboxId = 'last_generated_lootbox_id',
  /** column name */
  LootboxAvailableAmount = 'lootbox_available_amount',
  /** column name */
  LootboxCategory = 'lootbox_category',
  /** column name */
  LootboxGeneratorId = 'lootbox_generator_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxTargetPrice = 'lootbox_target_price',
  /** column name */
  MaxLootboxPriceMultiplyAmount = 'max_lootbox_price_multiply_amount',
  /** column name */
  MechanicId = 'mechanic_id',
  /** column name */
  Sort = 'sort',
  /** column name */
  TargetRtp = 'target_rtp'
}

export type Lootboxes_Free_Generator_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Free_Generator_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Free_Generator_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Free_Generator_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Free_Generator_Params_Var_Pop_Fields = {
  __typename?: 'lootboxes_free_generator_params_var_pop_fields';
  coin_target_price?: Maybe<Scalars['Float']>;
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  max_lootbox_price_multiply_amount?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Free_Generator_Params_Var_Samp_Fields = {
  __typename?: 'lootboxes_free_generator_params_var_samp_fields';
  coin_target_price?: Maybe<Scalars['Float']>;
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  max_lootbox_price_multiply_amount?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lootboxes_Free_Generator_Params_Variance_Fields = {
  __typename?: 'lootboxes_free_generator_params_variance_fields';
  coin_target_price?: Maybe<Scalars['Float']>;
  drop_limit_amount?: Maybe<Scalars['Float']>;
  drop_limit_percent?: Maybe<Scalars['Float']>;
  drop_min_amount?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_target_price?: Maybe<Scalars['Float']>;
  max_lootbox_price_multiply_amount?: Maybe<Scalars['Float']>;
  mechanic_id?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "lootboxes_free" */
export type Lootboxes_Free_Inc_Input = {
  coin_price?: InputMaybe<Scalars['Int']>;
  lootbox_available_amount?: InputMaybe<Scalars['Float']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes_free" */
export type Lootboxes_Free_Insert_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  coin_price?: InputMaybe<Scalars['Int']>;
  /** Флаг, указывающий, является ли кейс мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  items_in_lootbox?: InputMaybe<Lootboxes_Free_Items_Arr_Rel_Insert_Input>;
  items_view?: InputMaybe<Lootboxes_Free_Views_Arr_Rel_Insert_Input>;
  lootbox_available_amount?: InputMaybe<Scalars['Float']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_create_datetime?: InputMaybe<Scalars['timestamp']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_status?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "lootboxes_free_items" */
export type Lootboxes_Free_Items = {
  __typename?: 'lootboxes_free_items';
  item_alternative?: Maybe<Array<Scalars['String']>>;
  item_drop_chance: Scalars['Float'];
  item_id: Scalars['uuid'];
  item_price: Scalars['Float'];
  /** An object relationship */
  items_to_views?: Maybe<Lootboxes_Free_Views>;
  lootbox_id: Scalars['uuid'];
  /** An object relationship */
  lootboxes_free?: Maybe<Lootboxes_Free>;
};

/** aggregated selection of "lootboxes_free_items" */
export type Lootboxes_Free_Items_Aggregate = {
  __typename?: 'lootboxes_free_items_aggregate';
  aggregate?: Maybe<Lootboxes_Free_Items_Aggregate_Fields>;
  nodes: Array<Lootboxes_Free_Items>;
};

export type Lootboxes_Free_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lootboxes_Free_Items_Aggregate_Bool_Exp_Count>;
};

export type Lootboxes_Free_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootboxes_Free_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Free_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootboxes_free_items" */
export type Lootboxes_Free_Items_Aggregate_Fields = {
  __typename?: 'lootboxes_free_items_aggregate_fields';
  avg?: Maybe<Lootboxes_Free_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Free_Items_Max_Fields>;
  min?: Maybe<Lootboxes_Free_Items_Min_Fields>;
  stddev?: Maybe<Lootboxes_Free_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Free_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Free_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Free_Items_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Free_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Free_Items_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Free_Items_Variance_Fields>;
};


/** aggregate fields of "lootboxes_free_items" */
export type Lootboxes_Free_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Free_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Lootboxes_Free_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootboxes_Free_Items_Max_Order_By>;
  min?: InputMaybe<Lootboxes_Free_Items_Min_Order_By>;
  stddev?: InputMaybe<Lootboxes_Free_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lootboxes_Free_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lootboxes_Free_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lootboxes_Free_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Lootboxes_Free_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lootboxes_Free_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Lootboxes_Free_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Arr_Rel_Insert_Input = {
  data: Array<Lootboxes_Free_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Free_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Lootboxes_Free_Items_Avg_Fields = {
  __typename?: 'lootboxes_free_items_avg_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Avg_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lootboxes_free_items". All fields are combined with a logical 'AND'. */
export type Lootboxes_Free_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Free_Items_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Free_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Free_Items_Bool_Exp>>;
  item_alternative?: InputMaybe<String_Array_Comparison_Exp>;
  item_drop_chance?: InputMaybe<Float_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_price?: InputMaybe<Float_Comparison_Exp>;
  items_to_views?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootboxes_free?: InputMaybe<Lootboxes_Free_Bool_Exp>;
};

/** unique or primary key constraints on table "lootboxes_free_items" */
export enum Lootboxes_Free_Items_Constraint {
  /** unique or primary key constraint on columns "lootbox_id", "item_id" */
  LootboxesFreeItemsPkey = 'lootboxes_free_items_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Inc_Input = {
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Insert_Input = {
  item_alternative?: InputMaybe<Array<Scalars['String']>>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  items_to_views?: InputMaybe<Lootboxes_Free_Views_Obj_Rel_Insert_Input>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootboxes_free?: InputMaybe<Lootboxes_Free_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Lootboxes_Free_Items_Max_Fields = {
  __typename?: 'lootboxes_free_items_max_fields';
  item_alternative?: Maybe<Array<Scalars['String']>>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Max_Order_By = {
  item_alternative?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootboxes_Free_Items_Min_Fields = {
  __typename?: 'lootboxes_free_items_min_fields';
  item_alternative?: Maybe<Array<Scalars['String']>>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Min_Order_By = {
  item_alternative?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Mutation_Response = {
  __typename?: 'lootboxes_free_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Free_Items>;
};

/** on_conflict condition type for table "lootboxes_free_items" */
export type Lootboxes_Free_Items_On_Conflict = {
  constraint: Lootboxes_Free_Items_Constraint;
  update_columns?: Array<Lootboxes_Free_Items_Update_Column>;
  where?: InputMaybe<Lootboxes_Free_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_free_items". */
export type Lootboxes_Free_Items_Order_By = {
  item_alternative?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  items_to_views?: InputMaybe<Lootboxes_Free_Views_Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootboxes_free?: InputMaybe<Lootboxes_Free_Order_By>;
};

/** primary key columns input for table: lootboxes_free_items */
export type Lootboxes_Free_Items_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};

/** select columns of table "lootboxes_free_items" */
export enum Lootboxes_Free_Items_Select_Column {
  /** column name */
  ItemAlternative = 'item_alternative',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  LootboxId = 'lootbox_id'
}

/** input type for updating data in table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Set_Input = {
  item_alternative?: InputMaybe<Array<Scalars['String']>>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Free_Items_Stddev_Fields = {
  __typename?: 'lootboxes_free_items_stddev_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Stddev_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Free_Items_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_free_items_stddev_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Stddev_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Free_Items_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_free_items_stddev_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Stddev_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Free_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Free_Items_Stream_Cursor_Value_Input = {
  item_alternative?: InputMaybe<Array<Scalars['String']>>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Lootboxes_Free_Items_Sum_Fields = {
  __typename?: 'lootboxes_free_items_sum_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Sum_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** update columns of table "lootboxes_free_items" */
export enum Lootboxes_Free_Items_Update_Column {
  /** column name */
  ItemAlternative = 'item_alternative',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  LootboxId = 'lootbox_id'
}

export type Lootboxes_Free_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Free_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Free_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Free_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Free_Items_Var_Pop_Fields = {
  __typename?: 'lootboxes_free_items_var_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Var_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Free_Items_Var_Samp_Fields = {
  __typename?: 'lootboxes_free_items_var_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Var_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lootboxes_Free_Items_Variance_Fields = {
  __typename?: 'lootboxes_free_items_variance_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lootboxes_free_items" */
export type Lootboxes_Free_Items_Variance_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Lootboxes_Free_Max_Fields = {
  __typename?: 'lootboxes_free_max_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  coin_price?: Maybe<Scalars['Int']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_create_datetime?: Maybe<Scalars['timestamp']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lootboxes_free" */
export type Lootboxes_Free_Max_Order_By = {
  amp_lootbox_id?: InputMaybe<Order_By>;
  coin_price?: InputMaybe<Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_create_datetime?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootboxes_Free_Min_Fields = {
  __typename?: 'lootboxes_free_min_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  coin_price?: Maybe<Scalars['Int']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_create_datetime?: Maybe<Scalars['timestamp']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lootboxes_free" */
export type Lootboxes_Free_Min_Order_By = {
  amp_lootbox_id?: InputMaybe<Order_By>;
  coin_price?: InputMaybe<Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_create_datetime?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootboxes_free" */
export type Lootboxes_Free_Mutation_Response = {
  __typename?: 'lootboxes_free_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Free>;
};

/** input type for inserting object relation for remote table "lootboxes_free" */
export type Lootboxes_Free_Obj_Rel_Insert_Input = {
  data: Lootboxes_Free_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Free_On_Conflict>;
};

/** on_conflict condition type for table "lootboxes_free" */
export type Lootboxes_Free_On_Conflict = {
  constraint: Lootboxes_Free_Constraint;
  update_columns?: Array<Lootboxes_Free_Update_Column>;
  where?: InputMaybe<Lootboxes_Free_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_free". */
export type Lootboxes_Free_Order_By = {
  amp_lootbox_id?: InputMaybe<Order_By>;
  coin_price?: InputMaybe<Order_By>;
  is_mistery?: InputMaybe<Order_By>;
  items_in_lootbox_aggregate?: InputMaybe<Lootboxes_Free_Items_Aggregate_Order_By>;
  items_view_aggregate?: InputMaybe<Lootboxes_Free_Views_Aggregate_Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_create_datetime?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  lootbox_status?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_free */
export type Lootboxes_Free_Pk_Columns_Input = {
  lootbox_id: Scalars['uuid'];
};

/** select columns of table "lootboxes_free" */
export enum Lootboxes_Free_Select_Column {
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  CoinPrice = 'coin_price',
  /** column name */
  IsMistery = 'is_mistery',
  /** column name */
  LootboxAvailableAmount = 'lootbox_available_amount',
  /** column name */
  LootboxCategory = 'lootbox_category',
  /** column name */
  LootboxCreateDatetime = 'lootbox_create_datetime',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxStatus = 'lootbox_status',
  /** column name */
  Sort = 'sort'
}

/** select "lootboxes_free_aggregate_bool_exp_bool_and_arguments_columns" columns of table "lootboxes_free" */
export enum Lootboxes_Free_Select_Column_Lootboxes_Free_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsMistery = 'is_mistery',
  /** column name */
  LootboxStatus = 'lootbox_status'
}

/** select "lootboxes_free_aggregate_bool_exp_bool_or_arguments_columns" columns of table "lootboxes_free" */
export enum Lootboxes_Free_Select_Column_Lootboxes_Free_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsMistery = 'is_mistery',
  /** column name */
  LootboxStatus = 'lootbox_status'
}

/** input type for updating data in table "lootboxes_free" */
export type Lootboxes_Free_Set_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  coin_price?: InputMaybe<Scalars['Int']>;
  /** Флаг, указывающий, является ли кейс мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  lootbox_available_amount?: InputMaybe<Scalars['Float']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_create_datetime?: InputMaybe<Scalars['timestamp']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_status?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Free_Stddev_Fields = {
  __typename?: 'lootboxes_free_stddev_fields';
  coin_price?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lootboxes_free" */
export type Lootboxes_Free_Stddev_Order_By = {
  coin_price?: InputMaybe<Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Free_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_free_stddev_pop_fields';
  coin_price?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lootboxes_free" */
export type Lootboxes_Free_Stddev_Pop_Order_By = {
  coin_price?: InputMaybe<Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Free_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_free_stddev_samp_fields';
  coin_price?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lootboxes_free" */
export type Lootboxes_Free_Stddev_Samp_Order_By = {
  coin_price?: InputMaybe<Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lootboxes_free" */
export type Lootboxes_Free_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Free_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Free_Stream_Cursor_Value_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  coin_price?: InputMaybe<Scalars['Int']>;
  /** Флаг, указывающий, является ли кейс мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  lootbox_available_amount?: InputMaybe<Scalars['Float']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_create_datetime?: InputMaybe<Scalars['timestamp']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_status?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lootboxes_Free_Sum_Fields = {
  __typename?: 'lootboxes_free_sum_fields';
  coin_price?: Maybe<Scalars['Int']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lootboxes_free" */
export type Lootboxes_Free_Sum_Order_By = {
  coin_price?: InputMaybe<Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** update columns of table "lootboxes_free" */
export enum Lootboxes_Free_Update_Column {
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  CoinPrice = 'coin_price',
  /** column name */
  IsMistery = 'is_mistery',
  /** column name */
  LootboxAvailableAmount = 'lootbox_available_amount',
  /** column name */
  LootboxCategory = 'lootbox_category',
  /** column name */
  LootboxCreateDatetime = 'lootbox_create_datetime',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxStatus = 'lootbox_status',
  /** column name */
  Sort = 'sort'
}

export type Lootboxes_Free_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Free_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Free_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Free_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Free_Var_Pop_Fields = {
  __typename?: 'lootboxes_free_var_pop_fields';
  coin_price?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lootboxes_free" */
export type Lootboxes_Free_Var_Pop_Order_By = {
  coin_price?: InputMaybe<Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Free_Var_Samp_Fields = {
  __typename?: 'lootboxes_free_var_samp_fields';
  coin_price?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lootboxes_free" */
export type Lootboxes_Free_Var_Samp_Order_By = {
  coin_price?: InputMaybe<Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lootboxes_Free_Variance_Fields = {
  __typename?: 'lootboxes_free_variance_fields';
  coin_price?: Maybe<Scalars['Float']>;
  lootbox_available_amount?: Maybe<Scalars['Float']>;
  lootbox_category?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lootboxes_free" */
export type Lootboxes_Free_Variance_Order_By = {
  coin_price?: InputMaybe<Order_By>;
  lootbox_available_amount?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** columns and relationships of "lootboxes_free_views" */
export type Lootboxes_Free_Views = {
  __typename?: 'lootboxes_free_views';
  is_shown: Scalars['Boolean'];
  item_category: Scalars['String'];
  item_data?: Maybe<Items>;
  item_drop_chance: Scalars['Float'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality: Scalars['String'];
  item_rarity: Scalars['String'];
  item_start_price: Scalars['Float'];
  item_type: Scalars['String'];
  /** An object relationship */
  localized_name?: Maybe<Lootboxes_Items_Names>;
  lootbox_id: Scalars['uuid'];
  /** An object relationship */
  lootboxes_free: Lootboxes_Free;
  sort: Scalars['Int'];
};

/** aggregated selection of "lootboxes_free_views" */
export type Lootboxes_Free_Views_Aggregate = {
  __typename?: 'lootboxes_free_views_aggregate';
  aggregate?: Maybe<Lootboxes_Free_Views_Aggregate_Fields>;
  nodes: Array<Lootboxes_Free_Views>;
};

export type Lootboxes_Free_Views_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Lootboxes_Free_Views_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Lootboxes_Free_Views_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Lootboxes_Free_Views_Aggregate_Bool_Exp_Count>;
};

export type Lootboxes_Free_Views_Aggregate_Bool_Exp_Bool_And = {
  arguments: Lootboxes_Free_Views_Select_Column_Lootboxes_Free_Views_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lootboxes_Free_Views_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Lootboxes_Free_Views_Select_Column_Lootboxes_Free_Views_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lootboxes_Free_Views_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootboxes_Free_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootboxes_free_views" */
export type Lootboxes_Free_Views_Aggregate_Fields = {
  __typename?: 'lootboxes_free_views_aggregate_fields';
  avg?: Maybe<Lootboxes_Free_Views_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Free_Views_Max_Fields>;
  min?: Maybe<Lootboxes_Free_Views_Min_Fields>;
  stddev?: Maybe<Lootboxes_Free_Views_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Free_Views_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Free_Views_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Free_Views_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Free_Views_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Free_Views_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Free_Views_Variance_Fields>;
};


/** aggregate fields of "lootboxes_free_views" */
export type Lootboxes_Free_Views_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Free_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Aggregate_Order_By = {
  avg?: InputMaybe<Lootboxes_Free_Views_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootboxes_Free_Views_Max_Order_By>;
  min?: InputMaybe<Lootboxes_Free_Views_Min_Order_By>;
  stddev?: InputMaybe<Lootboxes_Free_Views_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lootboxes_Free_Views_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lootboxes_Free_Views_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lootboxes_Free_Views_Sum_Order_By>;
  var_pop?: InputMaybe<Lootboxes_Free_Views_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lootboxes_Free_Views_Var_Samp_Order_By>;
  variance?: InputMaybe<Lootboxes_Free_Views_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Arr_Rel_Insert_Input = {
  data: Array<Lootboxes_Free_Views_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Free_Views_On_Conflict>;
};

/** aggregate avg on columns */
export type Lootboxes_Free_Views_Avg_Fields = {
  __typename?: 'lootboxes_free_views_avg_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Avg_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lootboxes_free_views". All fields are combined with a logical 'AND'. */
export type Lootboxes_Free_Views_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Free_Views_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Free_Views_Bool_Exp>>;
  is_shown?: InputMaybe<Boolean_Comparison_Exp>;
  item_category?: InputMaybe<String_Comparison_Exp>;
  item_drop_chance?: InputMaybe<Float_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_image_link?: InputMaybe<String_Comparison_Exp>;
  item_max_show_price?: InputMaybe<Float_Comparison_Exp>;
  item_min_show_price?: InputMaybe<Float_Comparison_Exp>;
  item_quality?: InputMaybe<String_Comparison_Exp>;
  item_rarity?: InputMaybe<String_Comparison_Exp>;
  item_start_price?: InputMaybe<Float_Comparison_Exp>;
  item_type?: InputMaybe<String_Comparison_Exp>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Bool_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootboxes_free?: InputMaybe<Lootboxes_Free_Bool_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_free_views" */
export enum Lootboxes_Free_Views_Constraint {
  /** unique or primary key constraint on columns "lootbox_id", "item_id" */
  LootboxesFreeViewsPkey = 'lootboxes_free_views_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Inc_Input = {
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Insert_Input = {
  is_shown?: InputMaybe<Scalars['Boolean']>;
  item_category?: InputMaybe<Scalars['String']>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Obj_Rel_Insert_Input>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootboxes_free?: InputMaybe<Lootboxes_Free_Obj_Rel_Insert_Input>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lootboxes_Free_Views_Max_Fields = {
  __typename?: 'lootboxes_free_views_max_fields';
  item_category?: Maybe<Scalars['String']>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_type?: Maybe<Scalars['String']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Max_Order_By = {
  item_category?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootboxes_Free_Views_Min_Fields = {
  __typename?: 'lootboxes_free_views_min_fields';
  item_category?: Maybe<Scalars['String']>;
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_type?: Maybe<Scalars['String']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Min_Order_By = {
  item_category?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Mutation_Response = {
  __typename?: 'lootboxes_free_views_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Free_Views>;
};

/** input type for inserting object relation for remote table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Obj_Rel_Insert_Input = {
  data: Lootboxes_Free_Views_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Free_Views_On_Conflict>;
};

/** on_conflict condition type for table "lootboxes_free_views" */
export type Lootboxes_Free_Views_On_Conflict = {
  constraint: Lootboxes_Free_Views_Constraint;
  update_columns?: Array<Lootboxes_Free_Views_Update_Column>;
  where?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_free_views". */
export type Lootboxes_Free_Views_Order_By = {
  is_shown?: InputMaybe<Order_By>;
  item_category?: InputMaybe<Order_By>;
  item_drop_chance?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootboxes_free?: InputMaybe<Lootboxes_Free_Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_free_views */
export type Lootboxes_Free_Views_Pk_Columns_Input = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};

/** select columns of table "lootboxes_free_views" */
export enum Lootboxes_Free_Views_Select_Column {
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  ItemCategory = 'item_category',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemMaxShowPrice = 'item_max_show_price',
  /** column name */
  ItemMinShowPrice = 'item_min_show_price',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  Sort = 'sort'
}

/** select "lootboxes_free_views_aggregate_bool_exp_bool_and_arguments_columns" columns of table "lootboxes_free_views" */
export enum Lootboxes_Free_Views_Select_Column_Lootboxes_Free_Views_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsShown = 'is_shown'
}

/** select "lootboxes_free_views_aggregate_bool_exp_bool_or_arguments_columns" columns of table "lootboxes_free_views" */
export enum Lootboxes_Free_Views_Select_Column_Lootboxes_Free_Views_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsShown = 'is_shown'
}

/** input type for updating data in table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Set_Input = {
  is_shown?: InputMaybe<Scalars['Boolean']>;
  item_category?: InputMaybe<Scalars['String']>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Free_Views_Stddev_Fields = {
  __typename?: 'lootboxes_free_views_stddev_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Stddev_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Free_Views_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_free_views_stddev_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Stddev_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Free_Views_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_free_views_stddev_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Stddev_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Free_Views_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Free_Views_Stream_Cursor_Value_Input = {
  is_shown?: InputMaybe<Scalars['Boolean']>;
  item_category?: InputMaybe<Scalars['String']>;
  item_drop_chance?: InputMaybe<Scalars['Float']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_show_price?: InputMaybe<Scalars['Float']>;
  item_min_show_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_type?: InputMaybe<Scalars['String']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lootboxes_Free_Views_Sum_Fields = {
  __typename?: 'lootboxes_free_views_sum_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Sum_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** update columns of table "lootboxes_free_views" */
export enum Lootboxes_Free_Views_Update_Column {
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  ItemCategory = 'item_category',
  /** column name */
  ItemDropChance = 'item_drop_chance',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemMaxShowPrice = 'item_max_show_price',
  /** column name */
  ItemMinShowPrice = 'item_min_show_price',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  Sort = 'sort'
}

export type Lootboxes_Free_Views_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Free_Views_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Free_Views_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Free_Views_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Free_Views_Var_Pop_Fields = {
  __typename?: 'lootboxes_free_views_var_pop_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Var_Pop_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Free_Views_Var_Samp_Fields = {
  __typename?: 'lootboxes_free_views_var_samp_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Var_Samp_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lootboxes_Free_Views_Variance_Fields = {
  __typename?: 'lootboxes_free_views_variance_fields';
  item_drop_chance?: Maybe<Scalars['Float']>;
  item_max_show_price?: Maybe<Scalars['Float']>;
  item_min_show_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lootboxes_free_views" */
export type Lootboxes_Free_Views_Variance_Order_By = {
  item_drop_chance?: InputMaybe<Order_By>;
  item_max_show_price?: InputMaybe<Order_By>;
  item_min_show_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** columns and relationships of "lootboxes_generator_mechanics" */
export type Lootboxes_Generator_Mechanics = {
  __typename?: 'lootboxes_generator_mechanics';
  cat_high_prob: Scalars['Float'];
  cat_low_prob: Scalars['Float'];
  cat_med_prob: Scalars['Float'];
  cat_very_high_prob: Scalars['Float'];
  cat_very_low_prob: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  max_rtp: Scalars['Int'];
  min_rtp: Scalars['Int'];
  name: Scalars['String'];
  target_rtp: Scalars['Int'];
};

/** aggregated selection of "lootboxes_generator_mechanics" */
export type Lootboxes_Generator_Mechanics_Aggregate = {
  __typename?: 'lootboxes_generator_mechanics_aggregate';
  aggregate?: Maybe<Lootboxes_Generator_Mechanics_Aggregate_Fields>;
  nodes: Array<Lootboxes_Generator_Mechanics>;
};

/** aggregate fields of "lootboxes_generator_mechanics" */
export type Lootboxes_Generator_Mechanics_Aggregate_Fields = {
  __typename?: 'lootboxes_generator_mechanics_aggregate_fields';
  avg?: Maybe<Lootboxes_Generator_Mechanics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Generator_Mechanics_Max_Fields>;
  min?: Maybe<Lootboxes_Generator_Mechanics_Min_Fields>;
  stddev?: Maybe<Lootboxes_Generator_Mechanics_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Generator_Mechanics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Generator_Mechanics_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Generator_Mechanics_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Generator_Mechanics_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Generator_Mechanics_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Generator_Mechanics_Variance_Fields>;
};


/** aggregate fields of "lootboxes_generator_mechanics" */
export type Lootboxes_Generator_Mechanics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Generator_Mechanics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lootboxes_Generator_Mechanics_Avg_Fields = {
  __typename?: 'lootboxes_generator_mechanics_avg_fields';
  cat_high_prob?: Maybe<Scalars['Float']>;
  cat_low_prob?: Maybe<Scalars['Float']>;
  cat_med_prob?: Maybe<Scalars['Float']>;
  cat_very_high_prob?: Maybe<Scalars['Float']>;
  cat_very_low_prob?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_rtp?: Maybe<Scalars['Float']>;
  min_rtp?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lootboxes_generator_mechanics". All fields are combined with a logical 'AND'. */
export type Lootboxes_Generator_Mechanics_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Generator_Mechanics_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Generator_Mechanics_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Generator_Mechanics_Bool_Exp>>;
  cat_high_prob?: InputMaybe<Float_Comparison_Exp>;
  cat_low_prob?: InputMaybe<Float_Comparison_Exp>;
  cat_med_prob?: InputMaybe<Float_Comparison_Exp>;
  cat_very_high_prob?: InputMaybe<Float_Comparison_Exp>;
  cat_very_low_prob?: InputMaybe<Float_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  max_rtp?: InputMaybe<Int_Comparison_Exp>;
  min_rtp?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  target_rtp?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_generator_mechanics" */
export enum Lootboxes_Generator_Mechanics_Constraint {
  /** unique or primary key constraint on columns "id" */
  LootboxesGeneratorMachanicsPkey = 'lootboxes_generator_machanics_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_generator_mechanics" */
export type Lootboxes_Generator_Mechanics_Inc_Input = {
  cat_high_prob?: InputMaybe<Scalars['Float']>;
  cat_low_prob?: InputMaybe<Scalars['Float']>;
  cat_med_prob?: InputMaybe<Scalars['Float']>;
  cat_very_high_prob?: InputMaybe<Scalars['Float']>;
  cat_very_low_prob?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  max_rtp?: InputMaybe<Scalars['Int']>;
  min_rtp?: InputMaybe<Scalars['Int']>;
  target_rtp?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes_generator_mechanics" */
export type Lootboxes_Generator_Mechanics_Insert_Input = {
  cat_high_prob?: InputMaybe<Scalars['Float']>;
  cat_low_prob?: InputMaybe<Scalars['Float']>;
  cat_med_prob?: InputMaybe<Scalars['Float']>;
  cat_very_high_prob?: InputMaybe<Scalars['Float']>;
  cat_very_low_prob?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_rtp?: InputMaybe<Scalars['Int']>;
  min_rtp?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  target_rtp?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lootboxes_Generator_Mechanics_Max_Fields = {
  __typename?: 'lootboxes_generator_mechanics_max_fields';
  cat_high_prob?: Maybe<Scalars['Float']>;
  cat_low_prob?: Maybe<Scalars['Float']>;
  cat_med_prob?: Maybe<Scalars['Float']>;
  cat_very_high_prob?: Maybe<Scalars['Float']>;
  cat_very_low_prob?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_rtp?: Maybe<Scalars['Int']>;
  min_rtp?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  target_rtp?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Lootboxes_Generator_Mechanics_Min_Fields = {
  __typename?: 'lootboxes_generator_mechanics_min_fields';
  cat_high_prob?: Maybe<Scalars['Float']>;
  cat_low_prob?: Maybe<Scalars['Float']>;
  cat_med_prob?: Maybe<Scalars['Float']>;
  cat_very_high_prob?: Maybe<Scalars['Float']>;
  cat_very_low_prob?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_rtp?: Maybe<Scalars['Int']>;
  min_rtp?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  target_rtp?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "lootboxes_generator_mechanics" */
export type Lootboxes_Generator_Mechanics_Mutation_Response = {
  __typename?: 'lootboxes_generator_mechanics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Generator_Mechanics>;
};

/** input type for inserting object relation for remote table "lootboxes_generator_mechanics" */
export type Lootboxes_Generator_Mechanics_Obj_Rel_Insert_Input = {
  data: Lootboxes_Generator_Mechanics_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Generator_Mechanics_On_Conflict>;
};

/** on_conflict condition type for table "lootboxes_generator_mechanics" */
export type Lootboxes_Generator_Mechanics_On_Conflict = {
  constraint: Lootboxes_Generator_Mechanics_Constraint;
  update_columns?: Array<Lootboxes_Generator_Mechanics_Update_Column>;
  where?: InputMaybe<Lootboxes_Generator_Mechanics_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_generator_mechanics". */
export type Lootboxes_Generator_Mechanics_Order_By = {
  cat_high_prob?: InputMaybe<Order_By>;
  cat_low_prob?: InputMaybe<Order_By>;
  cat_med_prob?: InputMaybe<Order_By>;
  cat_very_high_prob?: InputMaybe<Order_By>;
  cat_very_low_prob?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_rtp?: InputMaybe<Order_By>;
  min_rtp?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  target_rtp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_generator_mechanics */
export type Lootboxes_Generator_Mechanics_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "lootboxes_generator_mechanics" */
export enum Lootboxes_Generator_Mechanics_Select_Column {
  /** column name */
  CatHighProb = 'cat_high_prob',
  /** column name */
  CatLowProb = 'cat_low_prob',
  /** column name */
  CatMedProb = 'cat_med_prob',
  /** column name */
  CatVeryHighProb = 'cat_very_high_prob',
  /** column name */
  CatVeryLowProb = 'cat_very_low_prob',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxRtp = 'max_rtp',
  /** column name */
  MinRtp = 'min_rtp',
  /** column name */
  Name = 'name',
  /** column name */
  TargetRtp = 'target_rtp'
}

/** input type for updating data in table "lootboxes_generator_mechanics" */
export type Lootboxes_Generator_Mechanics_Set_Input = {
  cat_high_prob?: InputMaybe<Scalars['Float']>;
  cat_low_prob?: InputMaybe<Scalars['Float']>;
  cat_med_prob?: InputMaybe<Scalars['Float']>;
  cat_very_high_prob?: InputMaybe<Scalars['Float']>;
  cat_very_low_prob?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_rtp?: InputMaybe<Scalars['Int']>;
  min_rtp?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  target_rtp?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Generator_Mechanics_Stddev_Fields = {
  __typename?: 'lootboxes_generator_mechanics_stddev_fields';
  cat_high_prob?: Maybe<Scalars['Float']>;
  cat_low_prob?: Maybe<Scalars['Float']>;
  cat_med_prob?: Maybe<Scalars['Float']>;
  cat_very_high_prob?: Maybe<Scalars['Float']>;
  cat_very_low_prob?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_rtp?: Maybe<Scalars['Float']>;
  min_rtp?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Generator_Mechanics_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_generator_mechanics_stddev_pop_fields';
  cat_high_prob?: Maybe<Scalars['Float']>;
  cat_low_prob?: Maybe<Scalars['Float']>;
  cat_med_prob?: Maybe<Scalars['Float']>;
  cat_very_high_prob?: Maybe<Scalars['Float']>;
  cat_very_low_prob?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_rtp?: Maybe<Scalars['Float']>;
  min_rtp?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Generator_Mechanics_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_generator_mechanics_stddev_samp_fields';
  cat_high_prob?: Maybe<Scalars['Float']>;
  cat_low_prob?: Maybe<Scalars['Float']>;
  cat_med_prob?: Maybe<Scalars['Float']>;
  cat_very_high_prob?: Maybe<Scalars['Float']>;
  cat_very_low_prob?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_rtp?: Maybe<Scalars['Float']>;
  min_rtp?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lootboxes_generator_mechanics" */
export type Lootboxes_Generator_Mechanics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Generator_Mechanics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Generator_Mechanics_Stream_Cursor_Value_Input = {
  cat_high_prob?: InputMaybe<Scalars['Float']>;
  cat_low_prob?: InputMaybe<Scalars['Float']>;
  cat_med_prob?: InputMaybe<Scalars['Float']>;
  cat_very_high_prob?: InputMaybe<Scalars['Float']>;
  cat_very_low_prob?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  max_rtp?: InputMaybe<Scalars['Int']>;
  min_rtp?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  target_rtp?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lootboxes_Generator_Mechanics_Sum_Fields = {
  __typename?: 'lootboxes_generator_mechanics_sum_fields';
  cat_high_prob?: Maybe<Scalars['Float']>;
  cat_low_prob?: Maybe<Scalars['Float']>;
  cat_med_prob?: Maybe<Scalars['Float']>;
  cat_very_high_prob?: Maybe<Scalars['Float']>;
  cat_very_low_prob?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  max_rtp?: Maybe<Scalars['Int']>;
  min_rtp?: Maybe<Scalars['Int']>;
  target_rtp?: Maybe<Scalars['Int']>;
};

/** update columns of table "lootboxes_generator_mechanics" */
export enum Lootboxes_Generator_Mechanics_Update_Column {
  /** column name */
  CatHighProb = 'cat_high_prob',
  /** column name */
  CatLowProb = 'cat_low_prob',
  /** column name */
  CatMedProb = 'cat_med_prob',
  /** column name */
  CatVeryHighProb = 'cat_very_high_prob',
  /** column name */
  CatVeryLowProb = 'cat_very_low_prob',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxRtp = 'max_rtp',
  /** column name */
  MinRtp = 'min_rtp',
  /** column name */
  Name = 'name',
  /** column name */
  TargetRtp = 'target_rtp'
}

export type Lootboxes_Generator_Mechanics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Generator_Mechanics_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Generator_Mechanics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Generator_Mechanics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Generator_Mechanics_Var_Pop_Fields = {
  __typename?: 'lootboxes_generator_mechanics_var_pop_fields';
  cat_high_prob?: Maybe<Scalars['Float']>;
  cat_low_prob?: Maybe<Scalars['Float']>;
  cat_med_prob?: Maybe<Scalars['Float']>;
  cat_very_high_prob?: Maybe<Scalars['Float']>;
  cat_very_low_prob?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_rtp?: Maybe<Scalars['Float']>;
  min_rtp?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Generator_Mechanics_Var_Samp_Fields = {
  __typename?: 'lootboxes_generator_mechanics_var_samp_fields';
  cat_high_prob?: Maybe<Scalars['Float']>;
  cat_low_prob?: Maybe<Scalars['Float']>;
  cat_med_prob?: Maybe<Scalars['Float']>;
  cat_very_high_prob?: Maybe<Scalars['Float']>;
  cat_very_low_prob?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_rtp?: Maybe<Scalars['Float']>;
  min_rtp?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lootboxes_Generator_Mechanics_Variance_Fields = {
  __typename?: 'lootboxes_generator_mechanics_variance_fields';
  cat_high_prob?: Maybe<Scalars['Float']>;
  cat_low_prob?: Maybe<Scalars['Float']>;
  cat_med_prob?: Maybe<Scalars['Float']>;
  cat_very_high_prob?: Maybe<Scalars['Float']>;
  cat_very_low_prob?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_rtp?: Maybe<Scalars['Float']>;
  min_rtp?: Maybe<Scalars['Float']>;
  target_rtp?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lootboxes_generator_params" */
export type Lootboxes_Generator_Params = {
  __typename?: 'lootboxes_generator_params';
  alpha: Scalars['Float'];
  beta: Scalars['Float'];
  fixed_items?: Maybe<Array<Scalars['String']>>;
  is_active: Scalars['Boolean'];
  items?: Maybe<Scalars['jsonb']>;
  jackpot_max_koef?: Maybe<Scalars['Int']>;
  jackpot_min_koef?: Maybe<Scalars['Int']>;
  last_gen_success: Scalars['Boolean'];
  lootbox_fixed_price?: Maybe<Scalars['Int']>;
  lootbox_max_price?: Maybe<Scalars['Int']>;
  lootbox_min_price?: Maybe<Scalars['Int']>;
  more_items: Scalars['Boolean'];
  more_items_multiply: Scalars['Int'];
  parent_lootbox_id: Scalars['uuid'];
  theta: Scalars['Float'];
  unbalanced: Scalars['Boolean'];
  use_parent_price: Scalars['Boolean'];
};


/** columns and relationships of "lootboxes_generator_params" */
export type Lootboxes_Generator_ParamsItemsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "lootboxes_generator_params" */
export type Lootboxes_Generator_Params_Aggregate = {
  __typename?: 'lootboxes_generator_params_aggregate';
  aggregate?: Maybe<Lootboxes_Generator_Params_Aggregate_Fields>;
  nodes: Array<Lootboxes_Generator_Params>;
};

/** aggregate fields of "lootboxes_generator_params" */
export type Lootboxes_Generator_Params_Aggregate_Fields = {
  __typename?: 'lootboxes_generator_params_aggregate_fields';
  avg?: Maybe<Lootboxes_Generator_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Generator_Params_Max_Fields>;
  min?: Maybe<Lootboxes_Generator_Params_Min_Fields>;
  stddev?: Maybe<Lootboxes_Generator_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Generator_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Generator_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Generator_Params_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Generator_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Generator_Params_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Generator_Params_Variance_Fields>;
};


/** aggregate fields of "lootboxes_generator_params" */
export type Lootboxes_Generator_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Generator_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Lootboxes_Generator_Params_Append_Input = {
  items?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Lootboxes_Generator_Params_Avg_Fields = {
  __typename?: 'lootboxes_generator_params_avg_fields';
  alpha?: Maybe<Scalars['Float']>;
  beta?: Maybe<Scalars['Float']>;
  jackpot_max_koef?: Maybe<Scalars['Float']>;
  jackpot_min_koef?: Maybe<Scalars['Float']>;
  lootbox_fixed_price?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_min_price?: Maybe<Scalars['Float']>;
  more_items_multiply?: Maybe<Scalars['Float']>;
  theta?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lootboxes_generator_params". All fields are combined with a logical 'AND'. */
export type Lootboxes_Generator_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Generator_Params_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Generator_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Generator_Params_Bool_Exp>>;
  alpha?: InputMaybe<Float_Comparison_Exp>;
  beta?: InputMaybe<Float_Comparison_Exp>;
  fixed_items?: InputMaybe<String_Array_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  items?: InputMaybe<Jsonb_Comparison_Exp>;
  jackpot_max_koef?: InputMaybe<Int_Comparison_Exp>;
  jackpot_min_koef?: InputMaybe<Int_Comparison_Exp>;
  last_gen_success?: InputMaybe<Boolean_Comparison_Exp>;
  lootbox_fixed_price?: InputMaybe<Int_Comparison_Exp>;
  lootbox_max_price?: InputMaybe<Int_Comparison_Exp>;
  lootbox_min_price?: InputMaybe<Int_Comparison_Exp>;
  more_items?: InputMaybe<Boolean_Comparison_Exp>;
  more_items_multiply?: InputMaybe<Int_Comparison_Exp>;
  parent_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  theta?: InputMaybe<Float_Comparison_Exp>;
  unbalanced?: InputMaybe<Boolean_Comparison_Exp>;
  use_parent_price?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_generator_params" */
export enum Lootboxes_Generator_Params_Constraint {
  /** unique or primary key constraint on columns "parent_lootbox_id" */
  LootboxesGeneratorParamsPkey = 'lootboxes_generator_params_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Lootboxes_Generator_Params_Delete_At_Path_Input = {
  items?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Lootboxes_Generator_Params_Delete_Elem_Input = {
  items?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Lootboxes_Generator_Params_Delete_Key_Input = {
  items?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "lootboxes_generator_params" */
export type Lootboxes_Generator_Params_Inc_Input = {
  alpha?: InputMaybe<Scalars['Float']>;
  beta?: InputMaybe<Scalars['Float']>;
  jackpot_max_koef?: InputMaybe<Scalars['Int']>;
  jackpot_min_koef?: InputMaybe<Scalars['Int']>;
  lootbox_fixed_price?: InputMaybe<Scalars['Int']>;
  lootbox_max_price?: InputMaybe<Scalars['Int']>;
  lootbox_min_price?: InputMaybe<Scalars['Int']>;
  more_items_multiply?: InputMaybe<Scalars['Int']>;
  theta?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "lootboxes_generator_params" */
export type Lootboxes_Generator_Params_Insert_Input = {
  alpha?: InputMaybe<Scalars['Float']>;
  beta?: InputMaybe<Scalars['Float']>;
  fixed_items?: InputMaybe<Array<Scalars['String']>>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Scalars['jsonb']>;
  jackpot_max_koef?: InputMaybe<Scalars['Int']>;
  jackpot_min_koef?: InputMaybe<Scalars['Int']>;
  last_gen_success?: InputMaybe<Scalars['Boolean']>;
  lootbox_fixed_price?: InputMaybe<Scalars['Int']>;
  lootbox_max_price?: InputMaybe<Scalars['Int']>;
  lootbox_min_price?: InputMaybe<Scalars['Int']>;
  more_items?: InputMaybe<Scalars['Boolean']>;
  more_items_multiply?: InputMaybe<Scalars['Int']>;
  parent_lootbox_id?: InputMaybe<Scalars['uuid']>;
  theta?: InputMaybe<Scalars['Float']>;
  unbalanced?: InputMaybe<Scalars['Boolean']>;
  use_parent_price?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Lootboxes_Generator_Params_Max_Fields = {
  __typename?: 'lootboxes_generator_params_max_fields';
  alpha?: Maybe<Scalars['Float']>;
  beta?: Maybe<Scalars['Float']>;
  fixed_items?: Maybe<Array<Scalars['String']>>;
  jackpot_max_koef?: Maybe<Scalars['Int']>;
  jackpot_min_koef?: Maybe<Scalars['Int']>;
  lootbox_fixed_price?: Maybe<Scalars['Int']>;
  lootbox_max_price?: Maybe<Scalars['Int']>;
  lootbox_min_price?: Maybe<Scalars['Int']>;
  more_items_multiply?: Maybe<Scalars['Int']>;
  parent_lootbox_id?: Maybe<Scalars['uuid']>;
  theta?: Maybe<Scalars['Float']>;
};

/** aggregate min on columns */
export type Lootboxes_Generator_Params_Min_Fields = {
  __typename?: 'lootboxes_generator_params_min_fields';
  alpha?: Maybe<Scalars['Float']>;
  beta?: Maybe<Scalars['Float']>;
  fixed_items?: Maybe<Array<Scalars['String']>>;
  jackpot_max_koef?: Maybe<Scalars['Int']>;
  jackpot_min_koef?: Maybe<Scalars['Int']>;
  lootbox_fixed_price?: Maybe<Scalars['Int']>;
  lootbox_max_price?: Maybe<Scalars['Int']>;
  lootbox_min_price?: Maybe<Scalars['Int']>;
  more_items_multiply?: Maybe<Scalars['Int']>;
  parent_lootbox_id?: Maybe<Scalars['uuid']>;
  theta?: Maybe<Scalars['Float']>;
};

/** response of any mutation on the table "lootboxes_generator_params" */
export type Lootboxes_Generator_Params_Mutation_Response = {
  __typename?: 'lootboxes_generator_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Generator_Params>;
};

/** on_conflict condition type for table "lootboxes_generator_params" */
export type Lootboxes_Generator_Params_On_Conflict = {
  constraint: Lootboxes_Generator_Params_Constraint;
  update_columns?: Array<Lootboxes_Generator_Params_Update_Column>;
  where?: InputMaybe<Lootboxes_Generator_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_generator_params". */
export type Lootboxes_Generator_Params_Order_By = {
  alpha?: InputMaybe<Order_By>;
  beta?: InputMaybe<Order_By>;
  fixed_items?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  items?: InputMaybe<Order_By>;
  jackpot_max_koef?: InputMaybe<Order_By>;
  jackpot_min_koef?: InputMaybe<Order_By>;
  last_gen_success?: InputMaybe<Order_By>;
  lootbox_fixed_price?: InputMaybe<Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_min_price?: InputMaybe<Order_By>;
  more_items?: InputMaybe<Order_By>;
  more_items_multiply?: InputMaybe<Order_By>;
  parent_lootbox_id?: InputMaybe<Order_By>;
  theta?: InputMaybe<Order_By>;
  unbalanced?: InputMaybe<Order_By>;
  use_parent_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_generator_params */
export type Lootboxes_Generator_Params_Pk_Columns_Input = {
  parent_lootbox_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Lootboxes_Generator_Params_Prepend_Input = {
  items?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "lootboxes_generator_params" */
export enum Lootboxes_Generator_Params_Select_Column {
  /** column name */
  Alpha = 'alpha',
  /** column name */
  Beta = 'beta',
  /** column name */
  FixedItems = 'fixed_items',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Items = 'items',
  /** column name */
  JackpotMaxKoef = 'jackpot_max_koef',
  /** column name */
  JackpotMinKoef = 'jackpot_min_koef',
  /** column name */
  LastGenSuccess = 'last_gen_success',
  /** column name */
  LootboxFixedPrice = 'lootbox_fixed_price',
  /** column name */
  LootboxMaxPrice = 'lootbox_max_price',
  /** column name */
  LootboxMinPrice = 'lootbox_min_price',
  /** column name */
  MoreItems = 'more_items',
  /** column name */
  MoreItemsMultiply = 'more_items_multiply',
  /** column name */
  ParentLootboxId = 'parent_lootbox_id',
  /** column name */
  Theta = 'theta',
  /** column name */
  Unbalanced = 'unbalanced',
  /** column name */
  UseParentPrice = 'use_parent_price'
}

/** input type for updating data in table "lootboxes_generator_params" */
export type Lootboxes_Generator_Params_Set_Input = {
  alpha?: InputMaybe<Scalars['Float']>;
  beta?: InputMaybe<Scalars['Float']>;
  fixed_items?: InputMaybe<Array<Scalars['String']>>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Scalars['jsonb']>;
  jackpot_max_koef?: InputMaybe<Scalars['Int']>;
  jackpot_min_koef?: InputMaybe<Scalars['Int']>;
  last_gen_success?: InputMaybe<Scalars['Boolean']>;
  lootbox_fixed_price?: InputMaybe<Scalars['Int']>;
  lootbox_max_price?: InputMaybe<Scalars['Int']>;
  lootbox_min_price?: InputMaybe<Scalars['Int']>;
  more_items?: InputMaybe<Scalars['Boolean']>;
  more_items_multiply?: InputMaybe<Scalars['Int']>;
  parent_lootbox_id?: InputMaybe<Scalars['uuid']>;
  theta?: InputMaybe<Scalars['Float']>;
  unbalanced?: InputMaybe<Scalars['Boolean']>;
  use_parent_price?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Generator_Params_Stddev_Fields = {
  __typename?: 'lootboxes_generator_params_stddev_fields';
  alpha?: Maybe<Scalars['Float']>;
  beta?: Maybe<Scalars['Float']>;
  jackpot_max_koef?: Maybe<Scalars['Float']>;
  jackpot_min_koef?: Maybe<Scalars['Float']>;
  lootbox_fixed_price?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_min_price?: Maybe<Scalars['Float']>;
  more_items_multiply?: Maybe<Scalars['Float']>;
  theta?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Generator_Params_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_generator_params_stddev_pop_fields';
  alpha?: Maybe<Scalars['Float']>;
  beta?: Maybe<Scalars['Float']>;
  jackpot_max_koef?: Maybe<Scalars['Float']>;
  jackpot_min_koef?: Maybe<Scalars['Float']>;
  lootbox_fixed_price?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_min_price?: Maybe<Scalars['Float']>;
  more_items_multiply?: Maybe<Scalars['Float']>;
  theta?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Generator_Params_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_generator_params_stddev_samp_fields';
  alpha?: Maybe<Scalars['Float']>;
  beta?: Maybe<Scalars['Float']>;
  jackpot_max_koef?: Maybe<Scalars['Float']>;
  jackpot_min_koef?: Maybe<Scalars['Float']>;
  lootbox_fixed_price?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_min_price?: Maybe<Scalars['Float']>;
  more_items_multiply?: Maybe<Scalars['Float']>;
  theta?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "lootboxes_generator_params" */
export type Lootboxes_Generator_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Generator_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Generator_Params_Stream_Cursor_Value_Input = {
  alpha?: InputMaybe<Scalars['Float']>;
  beta?: InputMaybe<Scalars['Float']>;
  fixed_items?: InputMaybe<Array<Scalars['String']>>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Scalars['jsonb']>;
  jackpot_max_koef?: InputMaybe<Scalars['Int']>;
  jackpot_min_koef?: InputMaybe<Scalars['Int']>;
  last_gen_success?: InputMaybe<Scalars['Boolean']>;
  lootbox_fixed_price?: InputMaybe<Scalars['Int']>;
  lootbox_max_price?: InputMaybe<Scalars['Int']>;
  lootbox_min_price?: InputMaybe<Scalars['Int']>;
  more_items?: InputMaybe<Scalars['Boolean']>;
  more_items_multiply?: InputMaybe<Scalars['Int']>;
  parent_lootbox_id?: InputMaybe<Scalars['uuid']>;
  theta?: InputMaybe<Scalars['Float']>;
  unbalanced?: InputMaybe<Scalars['Boolean']>;
  use_parent_price?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Lootboxes_Generator_Params_Sum_Fields = {
  __typename?: 'lootboxes_generator_params_sum_fields';
  alpha?: Maybe<Scalars['Float']>;
  beta?: Maybe<Scalars['Float']>;
  jackpot_max_koef?: Maybe<Scalars['Int']>;
  jackpot_min_koef?: Maybe<Scalars['Int']>;
  lootbox_fixed_price?: Maybe<Scalars['Int']>;
  lootbox_max_price?: Maybe<Scalars['Int']>;
  lootbox_min_price?: Maybe<Scalars['Int']>;
  more_items_multiply?: Maybe<Scalars['Int']>;
  theta?: Maybe<Scalars['Float']>;
};

/** update columns of table "lootboxes_generator_params" */
export enum Lootboxes_Generator_Params_Update_Column {
  /** column name */
  Alpha = 'alpha',
  /** column name */
  Beta = 'beta',
  /** column name */
  FixedItems = 'fixed_items',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Items = 'items',
  /** column name */
  JackpotMaxKoef = 'jackpot_max_koef',
  /** column name */
  JackpotMinKoef = 'jackpot_min_koef',
  /** column name */
  LastGenSuccess = 'last_gen_success',
  /** column name */
  LootboxFixedPrice = 'lootbox_fixed_price',
  /** column name */
  LootboxMaxPrice = 'lootbox_max_price',
  /** column name */
  LootboxMinPrice = 'lootbox_min_price',
  /** column name */
  MoreItems = 'more_items',
  /** column name */
  MoreItemsMultiply = 'more_items_multiply',
  /** column name */
  ParentLootboxId = 'parent_lootbox_id',
  /** column name */
  Theta = 'theta',
  /** column name */
  Unbalanced = 'unbalanced',
  /** column name */
  UseParentPrice = 'use_parent_price'
}

export type Lootboxes_Generator_Params_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Lootboxes_Generator_Params_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Lootboxes_Generator_Params_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Lootboxes_Generator_Params_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Lootboxes_Generator_Params_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Generator_Params_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Lootboxes_Generator_Params_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Generator_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Generator_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Generator_Params_Var_Pop_Fields = {
  __typename?: 'lootboxes_generator_params_var_pop_fields';
  alpha?: Maybe<Scalars['Float']>;
  beta?: Maybe<Scalars['Float']>;
  jackpot_max_koef?: Maybe<Scalars['Float']>;
  jackpot_min_koef?: Maybe<Scalars['Float']>;
  lootbox_fixed_price?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_min_price?: Maybe<Scalars['Float']>;
  more_items_multiply?: Maybe<Scalars['Float']>;
  theta?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Generator_Params_Var_Samp_Fields = {
  __typename?: 'lootboxes_generator_params_var_samp_fields';
  alpha?: Maybe<Scalars['Float']>;
  beta?: Maybe<Scalars['Float']>;
  jackpot_max_koef?: Maybe<Scalars['Float']>;
  jackpot_min_koef?: Maybe<Scalars['Float']>;
  lootbox_fixed_price?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_min_price?: Maybe<Scalars['Float']>;
  more_items_multiply?: Maybe<Scalars['Float']>;
  theta?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lootboxes_Generator_Params_Variance_Fields = {
  __typename?: 'lootboxes_generator_params_variance_fields';
  alpha?: Maybe<Scalars['Float']>;
  beta?: Maybe<Scalars['Float']>;
  jackpot_max_koef?: Maybe<Scalars['Float']>;
  jackpot_min_koef?: Maybe<Scalars['Float']>;
  lootbox_fixed_price?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_min_price?: Maybe<Scalars['Float']>;
  more_items_multiply?: Maybe<Scalars['Float']>;
  theta?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "lootboxes_history" */
export type Lootboxes_History = {
  __typename?: 'lootboxes_history';
  created_datetime: Scalars['timestamp'];
  lootbox_id: Scalars['uuid'];
  lootbox_parent_id: Scalars['uuid'];
};

/** aggregated selection of "lootboxes_history" */
export type Lootboxes_History_Aggregate = {
  __typename?: 'lootboxes_history_aggregate';
  aggregate?: Maybe<Lootboxes_History_Aggregate_Fields>;
  nodes: Array<Lootboxes_History>;
};

/** aggregate fields of "lootboxes_history" */
export type Lootboxes_History_Aggregate_Fields = {
  __typename?: 'lootboxes_history_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_History_Max_Fields>;
  min?: Maybe<Lootboxes_History_Min_Fields>;
};


/** aggregate fields of "lootboxes_history" */
export type Lootboxes_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lootboxes_history". All fields are combined with a logical 'AND'. */
export type Lootboxes_History_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_History_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_History_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_History_Bool_Exp>>;
  created_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_parent_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_history" */
export enum Lootboxes_History_Constraint {
  /** unique or primary key constraint on columns "lootbox_parent_id", "lootbox_id" */
  LootboxesItemsHistoryPkey = 'lootboxes_items_history_pkey'
}

/** input type for inserting data into table "lootboxes_history" */
export type Lootboxes_History_Insert_Input = {
  created_datetime?: InputMaybe<Scalars['timestamp']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_parent_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Lootboxes_History_Max_Fields = {
  __typename?: 'lootboxes_history_max_fields';
  created_datetime?: Maybe<Scalars['timestamp']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_parent_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Lootboxes_History_Min_Fields = {
  __typename?: 'lootboxes_history_min_fields';
  created_datetime?: Maybe<Scalars['timestamp']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_parent_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "lootboxes_history" */
export type Lootboxes_History_Mutation_Response = {
  __typename?: 'lootboxes_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_History>;
};

/** on_conflict condition type for table "lootboxes_history" */
export type Lootboxes_History_On_Conflict = {
  constraint: Lootboxes_History_Constraint;
  update_columns?: Array<Lootboxes_History_Update_Column>;
  where?: InputMaybe<Lootboxes_History_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_history". */
export type Lootboxes_History_Order_By = {
  created_datetime?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_parent_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_history */
export type Lootboxes_History_Pk_Columns_Input = {
  lootbox_id: Scalars['uuid'];
  lootbox_parent_id: Scalars['uuid'];
};

/** select columns of table "lootboxes_history" */
export enum Lootboxes_History_Select_Column {
  /** column name */
  CreatedDatetime = 'created_datetime',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxParentId = 'lootbox_parent_id'
}

/** input type for updating data in table "lootboxes_history" */
export type Lootboxes_History_Set_Input = {
  created_datetime?: InputMaybe<Scalars['timestamp']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_parent_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "lootboxes_history" */
export type Lootboxes_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_History_Stream_Cursor_Value_Input = {
  created_datetime?: InputMaybe<Scalars['timestamp']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_parent_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "lootboxes_history" */
export enum Lootboxes_History_Update_Column {
  /** column name */
  CreatedDatetime = 'created_datetime',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxParentId = 'lootbox_parent_id'
}

export type Lootboxes_History_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_History_Bool_Exp;
};

/** input type for incrementing numeric columns in table "lootboxes" */
export type Lootboxes_Inc_Input = {
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_discount?: InputMaybe<Scalars['Float']>;
  lootbox_max_price?: InputMaybe<Scalars['Float']>;
  lootbox_open_count?: InputMaybe<Scalars['Int']>;
  lootbox_open_max_count?: InputMaybe<Scalars['Int']>;
  lootbox_price?: InputMaybe<Scalars['Float']>;
  lotbox_rec_price?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes" */
export type Lootboxes_Insert_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  badge?: InputMaybe<Scalars['String']>;
  current_to_parent?: InputMaybe<Lootboxes_Obj_Rel_Insert_Input>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  items_in_lootbox_views?: InputMaybe<Items_In_Lootbox_View_Arr_Rel_Insert_Input>;
  items_in_lootboxes?: InputMaybe<Items_In_Lootbox_Arr_Rel_Insert_Input>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_category_data?: InputMaybe<Lootbox_Category_Obj_Rel_Insert_Input>;
  lootbox_create_date_time?: InputMaybe<Scalars['timestamptz']>;
  lootbox_current_id?: InputMaybe<Scalars['uuid']>;
  lootbox_discount?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_items_sets?: InputMaybe<Lootbox_Items_Sets_Arr_Rel_Insert_Input>;
  lootbox_max_price?: InputMaybe<Scalars['Float']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_open_count?: InputMaybe<Scalars['Int']>;
  lootbox_open_max_count?: InputMaybe<Scalars['Int']>;
  lootbox_price?: InputMaybe<Scalars['Float']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_status?: InputMaybe<Scalars['Boolean']>;
  lootbox_top_drops?: InputMaybe<Lootboxes_Top_Drop_Arr_Rel_Insert_Input>;
  lotbox_rec_price?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "lootboxes_items_names" */
export type Lootboxes_Items_Names = {
  __typename?: 'lootboxes_items_names';
  name: Scalars['String'];
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lootboxes_items_names" */
export type Lootboxes_Items_Names_Aggregate = {
  __typename?: 'lootboxes_items_names_aggregate';
  aggregate?: Maybe<Lootboxes_Items_Names_Aggregate_Fields>;
  nodes: Array<Lootboxes_Items_Names>;
};

/** aggregate fields of "lootboxes_items_names" */
export type Lootboxes_Items_Names_Aggregate_Fields = {
  __typename?: 'lootboxes_items_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Items_Names_Max_Fields>;
  min?: Maybe<Lootboxes_Items_Names_Min_Fields>;
};


/** aggregate fields of "lootboxes_items_names" */
export type Lootboxes_Items_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Items_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lootboxes_items_names". All fields are combined with a logical 'AND'. */
export type Lootboxes_Items_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Items_Names_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Items_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Items_Names_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_items_names" */
export enum Lootboxes_Items_Names_Constraint {
  /** unique or primary key constraint on columns "name" */
  ItemsNamesPkey = 'items_names_pkey'
}

/** input type for inserting data into table "lootboxes_items_names" */
export type Lootboxes_Items_Names_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lootboxes_Items_Names_Max_Fields = {
  __typename?: 'lootboxes_items_names_max_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lootboxes_Items_Names_Min_Fields = {
  __typename?: 'lootboxes_items_names_min_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lootboxes_items_names" */
export type Lootboxes_Items_Names_Mutation_Response = {
  __typename?: 'lootboxes_items_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Items_Names>;
};

/** input type for inserting object relation for remote table "lootboxes_items_names" */
export type Lootboxes_Items_Names_Obj_Rel_Insert_Input = {
  data: Lootboxes_Items_Names_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Items_Names_On_Conflict>;
};

/** on_conflict condition type for table "lootboxes_items_names" */
export type Lootboxes_Items_Names_On_Conflict = {
  constraint: Lootboxes_Items_Names_Constraint;
  update_columns?: Array<Lootboxes_Items_Names_Update_Column>;
  where?: InputMaybe<Lootboxes_Items_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_items_names". */
export type Lootboxes_Items_Names_Order_By = {
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_items_names */
export type Lootboxes_Items_Names_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "lootboxes_items_names" */
export enum Lootboxes_Items_Names_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

/** input type for updating data in table "lootboxes_items_names" */
export type Lootboxes_Items_Names_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lootboxes_items_names" */
export type Lootboxes_Items_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Items_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Items_Names_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lootboxes_items_names" */
export enum Lootboxes_Items_Names_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

export type Lootboxes_Items_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Items_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Items_Names_Bool_Exp;
};

/** aggregate max on columns */
export type Lootboxes_Max_Fields = {
  __typename?: 'lootboxes_max_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  badge?: Maybe<Scalars['String']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_create_date_time?: Maybe<Scalars['timestamptz']>;
  lootbox_current_id?: Maybe<Scalars['uuid']>;
  lootbox_discount?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_open_count?: Maybe<Scalars['Int']>;
  lootbox_open_max_count?: Maybe<Scalars['Int']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lootboxes" */
export type Lootboxes_Max_Order_By = {
  amp_lootbox_id?: InputMaybe<Order_By>;
  badge?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_create_date_time?: InputMaybe<Order_By>;
  lootbox_current_id?: InputMaybe<Order_By>;
  lootbox_discount?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_open_count?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  lotbox_rec_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootboxes_Min_Fields = {
  __typename?: 'lootboxes_min_fields';
  amp_lootbox_id?: Maybe<Scalars['uuid']>;
  badge?: Maybe<Scalars['String']>;
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_create_date_time?: Maybe<Scalars['timestamptz']>;
  lootbox_current_id?: Maybe<Scalars['uuid']>;
  lootbox_discount?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_open_count?: Maybe<Scalars['Int']>;
  lootbox_open_max_count?: Maybe<Scalars['Int']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lootboxes" */
export type Lootboxes_Min_Order_By = {
  amp_lootbox_id?: InputMaybe<Order_By>;
  badge?: InputMaybe<Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_create_date_time?: InputMaybe<Order_By>;
  lootbox_current_id?: InputMaybe<Order_By>;
  lootbox_discount?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_open_count?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  lotbox_rec_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootboxes" */
export type Lootboxes_Mutation_Response = {
  __typename?: 'lootboxes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes>;
};

/** input type for inserting object relation for remote table "lootboxes" */
export type Lootboxes_Obj_Rel_Insert_Input = {
  data: Lootboxes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_On_Conflict>;
};

/** on_conflict condition type for table "lootboxes" */
export type Lootboxes_On_Conflict = {
  constraint: Lootboxes_Constraint;
  update_columns?: Array<Lootboxes_Update_Column>;
  where?: InputMaybe<Lootboxes_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes". */
export type Lootboxes_Order_By = {
  amp_lootbox_id?: InputMaybe<Order_By>;
  badge?: InputMaybe<Order_By>;
  current_to_parent?: InputMaybe<Lootboxes_Order_By>;
  hidden?: InputMaybe<Order_By>;
  items_in_lootbox_views_aggregate?: InputMaybe<Items_In_Lootbox_View_Aggregate_Order_By>;
  items_in_lootboxes_aggregate?: InputMaybe<Items_In_Lootbox_Aggregate_Order_By>;
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_category_data?: InputMaybe<Lootbox_Category_Order_By>;
  lootbox_create_date_time?: InputMaybe<Order_By>;
  lootbox_current_id?: InputMaybe<Order_By>;
  lootbox_discount?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_items_sets_aggregate?: InputMaybe<Lootbox_Items_Sets_Aggregate_Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_open_count?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  lootbox_status?: InputMaybe<Order_By>;
  lootbox_top_drops_aggregate?: InputMaybe<Lootboxes_Top_Drop_Aggregate_Order_By>;
  lotbox_rec_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes */
export type Lootboxes_Pk_Columns_Input = {
  lootbox_id: Scalars['uuid'];
};

/** select columns of table "lootboxes" */
export enum Lootboxes_Select_Column {
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  Badge = 'badge',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  LootboxCategory = 'lootbox_category',
  /** column name */
  LootboxCreateDateTime = 'lootbox_create_date_time',
  /** column name */
  LootboxCurrentId = 'lootbox_current_id',
  /** column name */
  LootboxDiscount = 'lootbox_discount',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxMaxPrice = 'lootbox_max_price',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxOpenCount = 'lootbox_open_count',
  /** column name */
  LootboxOpenMaxCount = 'lootbox_open_max_count',
  /** column name */
  LootboxPrice = 'lootbox_price',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxStatus = 'lootbox_status',
  /** column name */
  LotboxRecPrice = 'lotbox_rec_price',
  /** column name */
  Sort = 'sort'
}

/** select "lootboxes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "lootboxes" */
export enum Lootboxes_Select_Column_Lootboxes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
  /** column name */
  LootboxStatus = 'lootbox_status'
}

/** select "lootboxes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "lootboxes" */
export enum Lootboxes_Select_Column_Lootboxes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
  /** column name */
  LootboxStatus = 'lootbox_status'
}

/** input type for updating data in table "lootboxes" */
export type Lootboxes_Set_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  badge?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_create_date_time?: InputMaybe<Scalars['timestamptz']>;
  lootbox_current_id?: InputMaybe<Scalars['uuid']>;
  lootbox_discount?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_max_price?: InputMaybe<Scalars['Float']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_open_count?: InputMaybe<Scalars['Int']>;
  lootbox_open_max_count?: InputMaybe<Scalars['Int']>;
  lootbox_price?: InputMaybe<Scalars['Float']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_status?: InputMaybe<Scalars['Boolean']>;
  lotbox_rec_price?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Stddev_Fields = {
  __typename?: 'lootboxes_stddev_fields';
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_discount?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_open_count?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lootboxes" */
export type Lootboxes_Stddev_Order_By = {
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_discount?: InputMaybe<Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_open_count?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  lotbox_rec_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_stddev_pop_fields';
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_discount?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_open_count?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lootboxes" */
export type Lootboxes_Stddev_Pop_Order_By = {
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_discount?: InputMaybe<Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_open_count?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  lotbox_rec_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_stddev_samp_fields';
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_discount?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_open_count?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lootboxes" */
export type Lootboxes_Stddev_Samp_Order_By = {
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_discount?: InputMaybe<Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_open_count?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  lotbox_rec_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lootboxes" */
export type Lootboxes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Stream_Cursor_Value_Input = {
  amp_lootbox_id?: InputMaybe<Scalars['uuid']>;
  badge?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  lootbox_category?: InputMaybe<Scalars['Int']>;
  lootbox_create_date_time?: InputMaybe<Scalars['timestamptz']>;
  lootbox_current_id?: InputMaybe<Scalars['uuid']>;
  lootbox_discount?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_max_price?: InputMaybe<Scalars['Float']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_open_count?: InputMaybe<Scalars['Int']>;
  lootbox_open_max_count?: InputMaybe<Scalars['Int']>;
  lootbox_price?: InputMaybe<Scalars['Float']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  lootbox_status?: InputMaybe<Scalars['Boolean']>;
  lotbox_rec_price?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Lootboxes_Sum_Fields = {
  __typename?: 'lootboxes_sum_fields';
  lootbox_category?: Maybe<Scalars['Int']>;
  lootbox_discount?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_open_count?: Maybe<Scalars['Int']>;
  lootbox_open_max_count?: Maybe<Scalars['Int']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lootboxes" */
export type Lootboxes_Sum_Order_By = {
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_discount?: InputMaybe<Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_open_count?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  lotbox_rec_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** columns and relationships of "lootboxes_top_drop" */
export type Lootboxes_Top_Drop = {
  __typename?: 'lootboxes_top_drop';
  drop_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
  item_image_link_formated: Scalars['String'];
  item_quality: Scalars['String'];
  item_rariry: Scalars['String'];
  item_start_price: Scalars['Int'];
  /** An object relationship */
  localized_name?: Maybe<Lootboxes_Items_Names>;
  /** An object relationship */
  lootbox?: Maybe<Lootboxes>;
  lootbox_id: Scalars['uuid'];
  lootbox_image_link: Scalars['String'];
  lootbox_name: Scalars['String'];
  lootbox_slug: Scalars['String'];
  receiving_datetime: Scalars['timestamp'];
  user_avatar: Scalars['String'];
  user_id: Scalars['uuid'];
  user_name: Scalars['String'];
};

/** aggregated selection of "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Aggregate = {
  __typename?: 'lootboxes_top_drop_aggregate';
  aggregate?: Maybe<Lootboxes_Top_Drop_Aggregate_Fields>;
  nodes: Array<Lootboxes_Top_Drop>;
};

export type Lootboxes_Top_Drop_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lootboxes_Top_Drop_Aggregate_Bool_Exp_Count>;
};

export type Lootboxes_Top_Drop_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lootboxes_Top_Drop_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lootboxes_Top_Drop_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Aggregate_Fields = {
  __typename?: 'lootboxes_top_drop_aggregate_fields';
  avg?: Maybe<Lootboxes_Top_Drop_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lootboxes_Top_Drop_Max_Fields>;
  min?: Maybe<Lootboxes_Top_Drop_Min_Fields>;
  stddev?: Maybe<Lootboxes_Top_Drop_Stddev_Fields>;
  stddev_pop?: Maybe<Lootboxes_Top_Drop_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lootboxes_Top_Drop_Stddev_Samp_Fields>;
  sum?: Maybe<Lootboxes_Top_Drop_Sum_Fields>;
  var_pop?: Maybe<Lootboxes_Top_Drop_Var_Pop_Fields>;
  var_samp?: Maybe<Lootboxes_Top_Drop_Var_Samp_Fields>;
  variance?: Maybe<Lootboxes_Top_Drop_Variance_Fields>;
};


/** aggregate fields of "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lootboxes_Top_Drop_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Aggregate_Order_By = {
  avg?: InputMaybe<Lootboxes_Top_Drop_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lootboxes_Top_Drop_Max_Order_By>;
  min?: InputMaybe<Lootboxes_Top_Drop_Min_Order_By>;
  stddev?: InputMaybe<Lootboxes_Top_Drop_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lootboxes_Top_Drop_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lootboxes_Top_Drop_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lootboxes_Top_Drop_Sum_Order_By>;
  var_pop?: InputMaybe<Lootboxes_Top_Drop_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lootboxes_Top_Drop_Var_Samp_Order_By>;
  variance?: InputMaybe<Lootboxes_Top_Drop_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Arr_Rel_Insert_Input = {
  data: Array<Lootboxes_Top_Drop_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lootboxes_Top_Drop_On_Conflict>;
};

/** aggregate avg on columns */
export type Lootboxes_Top_Drop_Avg_Fields = {
  __typename?: 'lootboxes_top_drop_avg_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Avg_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lootboxes_top_drop". All fields are combined with a logical 'AND'. */
export type Lootboxes_Top_Drop_Bool_Exp = {
  _and?: InputMaybe<Array<Lootboxes_Top_Drop_Bool_Exp>>;
  _not?: InputMaybe<Lootboxes_Top_Drop_Bool_Exp>;
  _or?: InputMaybe<Array<Lootboxes_Top_Drop_Bool_Exp>>;
  drop_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_image_link_formated?: InputMaybe<String_Comparison_Exp>;
  item_quality?: InputMaybe<String_Comparison_Exp>;
  item_rariry?: InputMaybe<String_Comparison_Exp>;
  item_start_price?: InputMaybe<Int_Comparison_Exp>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Bool_Exp>;
  lootbox?: InputMaybe<Lootboxes_Bool_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_image_link?: InputMaybe<String_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  lootbox_slug?: InputMaybe<String_Comparison_Exp>;
  receiving_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  user_avatar?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lootboxes_top_drop" */
export enum Lootboxes_Top_Drop_Constraint {
  /** unique or primary key constraint on columns "drop_id" */
  LootboxesTopDropPkey = 'lootboxes_top_drop_pkey'
}

/** input type for incrementing numeric columns in table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Inc_Input = {
  item_start_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Insert_Input = {
  drop_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_image_link_formated?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rariry?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Int']>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Obj_Rel_Insert_Input>;
  lootbox?: InputMaybe<Lootboxes_Obj_Rel_Insert_Input>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  receiving_datetime?: InputMaybe<Scalars['timestamp']>;
  user_avatar?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lootboxes_Top_Drop_Max_Fields = {
  __typename?: 'lootboxes_top_drop_max_fields';
  drop_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rariry?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Int']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  receiving_datetime?: Maybe<Scalars['timestamp']>;
  user_avatar?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Max_Order_By = {
  drop_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_image_link_formated?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rariry?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  receiving_datetime?: InputMaybe<Order_By>;
  user_avatar?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lootboxes_Top_Drop_Min_Fields = {
  __typename?: 'lootboxes_top_drop_min_fields';
  drop_id?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rariry?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Int']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  receiving_datetime?: Maybe<Scalars['timestamp']>;
  user_avatar?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Min_Order_By = {
  drop_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_image_link_formated?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rariry?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  receiving_datetime?: InputMaybe<Order_By>;
  user_avatar?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Mutation_Response = {
  __typename?: 'lootboxes_top_drop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lootboxes_Top_Drop>;
};

/** on_conflict condition type for table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_On_Conflict = {
  constraint: Lootboxes_Top_Drop_Constraint;
  update_columns?: Array<Lootboxes_Top_Drop_Update_Column>;
  where?: InputMaybe<Lootboxes_Top_Drop_Bool_Exp>;
};

/** Ordering options when selecting data from "lootboxes_top_drop". */
export type Lootboxes_Top_Drop_Order_By = {
  drop_id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_image_link_formated?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rariry?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  localized_name?: InputMaybe<Lootboxes_Items_Names_Order_By>;
  lootbox?: InputMaybe<Lootboxes_Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  receiving_datetime?: InputMaybe<Order_By>;
  user_avatar?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lootboxes_top_drop */
export type Lootboxes_Top_Drop_Pk_Columns_Input = {
  drop_id: Scalars['uuid'];
};

/** select columns of table "lootboxes_top_drop" */
export enum Lootboxes_Top_Drop_Select_Column {
  /** column name */
  DropId = 'drop_id',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemImageLinkFormated = 'item_image_link_formated',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRariry = 'item_rariry',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  ReceivingDatetime = 'receiving_datetime',
  /** column name */
  UserAvatar = 'user_avatar',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Set_Input = {
  drop_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_image_link_formated?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rariry?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Int']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  receiving_datetime?: InputMaybe<Scalars['timestamp']>;
  user_avatar?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Lootboxes_Top_Drop_Stddev_Fields = {
  __typename?: 'lootboxes_top_drop_stddev_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Stddev_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lootboxes_Top_Drop_Stddev_Pop_Fields = {
  __typename?: 'lootboxes_top_drop_stddev_pop_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Stddev_Pop_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lootboxes_Top_Drop_Stddev_Samp_Fields = {
  __typename?: 'lootboxes_top_drop_stddev_samp_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Stddev_Samp_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lootboxes_Top_Drop_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lootboxes_Top_Drop_Stream_Cursor_Value_Input = {
  drop_id?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_image_link_formated?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rariry?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Int']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  receiving_datetime?: InputMaybe<Scalars['timestamp']>;
  user_avatar?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Lootboxes_Top_Drop_Sum_Fields = {
  __typename?: 'lootboxes_top_drop_sum_fields';
  item_start_price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Sum_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** update columns of table "lootboxes_top_drop" */
export enum Lootboxes_Top_Drop_Update_Column {
  /** column name */
  DropId = 'drop_id',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemImageLinkFormated = 'item_image_link_formated',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRariry = 'item_rariry',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  ReceivingDatetime = 'receiving_datetime',
  /** column name */
  UserAvatar = 'user_avatar',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Lootboxes_Top_Drop_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Top_Drop_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Top_Drop_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Top_Drop_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Top_Drop_Var_Pop_Fields = {
  __typename?: 'lootboxes_top_drop_var_pop_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Var_Pop_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Top_Drop_Var_Samp_Fields = {
  __typename?: 'lootboxes_top_drop_var_samp_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Var_Samp_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lootboxes_Top_Drop_Variance_Fields = {
  __typename?: 'lootboxes_top_drop_variance_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lootboxes_top_drop" */
export type Lootboxes_Top_Drop_Variance_Order_By = {
  item_start_price?: InputMaybe<Order_By>;
};

/** update columns of table "lootboxes" */
export enum Lootboxes_Update_Column {
  /** column name */
  AmpLootboxId = 'amp_lootbox_id',
  /** column name */
  Badge = 'badge',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  LootboxCategory = 'lootbox_category',
  /** column name */
  LootboxCreateDateTime = 'lootbox_create_date_time',
  /** column name */
  LootboxCurrentId = 'lootbox_current_id',
  /** column name */
  LootboxDiscount = 'lootbox_discount',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxMaxPrice = 'lootbox_max_price',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxOpenCount = 'lootbox_open_count',
  /** column name */
  LootboxOpenMaxCount = 'lootbox_open_max_count',
  /** column name */
  LootboxPrice = 'lootbox_price',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  LootboxStatus = 'lootbox_status',
  /** column name */
  LotboxRecPrice = 'lotbox_rec_price',
  /** column name */
  Sort = 'sort'
}

export type Lootboxes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lootboxes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lootboxes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lootboxes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lootboxes_Var_Pop_Fields = {
  __typename?: 'lootboxes_var_pop_fields';
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_discount?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_open_count?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lootboxes" */
export type Lootboxes_Var_Pop_Order_By = {
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_discount?: InputMaybe<Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_open_count?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  lotbox_rec_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lootboxes_Var_Samp_Fields = {
  __typename?: 'lootboxes_var_samp_fields';
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_discount?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_open_count?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lootboxes" */
export type Lootboxes_Var_Samp_Order_By = {
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_discount?: InputMaybe<Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_open_count?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  lotbox_rec_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lootboxes_Variance_Fields = {
  __typename?: 'lootboxes_variance_fields';
  lootbox_category?: Maybe<Scalars['Float']>;
  lootbox_discount?: Maybe<Scalars['Float']>;
  lootbox_max_price?: Maybe<Scalars['Float']>;
  lootbox_open_count?: Maybe<Scalars['Float']>;
  lootbox_open_max_count?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
  lotbox_rec_price?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lootboxes" */
export type Lootboxes_Variance_Order_By = {
  lootbox_category?: InputMaybe<Order_By>;
  lootbox_discount?: InputMaybe<Order_By>;
  lootbox_max_price?: InputMaybe<Order_By>;
  lootbox_open_count?: InputMaybe<Order_By>;
  lootbox_open_max_count?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  lotbox_rec_price?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** columns and relationships of "mail_autosending_types" */
export type Mail_Autosending_Types = {
  __typename?: 'mail_autosending_types';
  is_active: Scalars['Boolean'];
  /** An object relationship */
  mail_bonus?: Maybe<Mail_Bonuses>;
  mail_bonus_id?: Maybe<Scalars['uuid']>;
  mail_id: Scalars['uuid'];
  /** An array relationship */
  mail_mailing_schedulings: Array<Mail_Mailing_Scheduling>;
  /** An aggregate relationship */
  mail_mailing_schedulings_aggregate: Mail_Mailing_Scheduling_Aggregate;
  /** An array relationship */
  mail_mailing_triggers_groups: Array<Mail_Mailing_Triggers_Groups>;
  /** An aggregate relationship */
  mail_mailing_triggers_groups_aggregate: Mail_Mailing_Triggers_Groups_Aggregate;
  mail_tags?: Maybe<Array<Scalars['String']>>;
  mail_unique_trigger?: Maybe<Scalars['String']>;
  onetime_trigger: Scalars['Boolean'];
  template_name: Scalars['String'];
  template_params?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "mail_autosending_types" */
export type Mail_Autosending_TypesMail_Mailing_SchedulingsArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Scheduling_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
};


/** columns and relationships of "mail_autosending_types" */
export type Mail_Autosending_TypesMail_Mailing_Schedulings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Scheduling_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
};


/** columns and relationships of "mail_autosending_types" */
export type Mail_Autosending_TypesMail_Mailing_Triggers_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
};


/** columns and relationships of "mail_autosending_types" */
export type Mail_Autosending_TypesMail_Mailing_Triggers_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
};


/** columns and relationships of "mail_autosending_types" */
export type Mail_Autosending_TypesTemplate_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "mail_autosending_types" */
export type Mail_Autosending_Types_Aggregate = {
  __typename?: 'mail_autosending_types_aggregate';
  aggregate?: Maybe<Mail_Autosending_Types_Aggregate_Fields>;
  nodes: Array<Mail_Autosending_Types>;
};

/** aggregate fields of "mail_autosending_types" */
export type Mail_Autosending_Types_Aggregate_Fields = {
  __typename?: 'mail_autosending_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mail_Autosending_Types_Max_Fields>;
  min?: Maybe<Mail_Autosending_Types_Min_Fields>;
};


/** aggregate fields of "mail_autosending_types" */
export type Mail_Autosending_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Autosending_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mail_Autosending_Types_Append_Input = {
  template_params?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "mail_autosending_types". All fields are combined with a logical 'AND'. */
export type Mail_Autosending_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Autosending_Types_Bool_Exp>>;
  _not?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Mail_Autosending_Types_Bool_Exp>>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  mail_bonus?: InputMaybe<Mail_Bonuses_Bool_Exp>;
  mail_bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  mail_id?: InputMaybe<Uuid_Comparison_Exp>;
  mail_mailing_schedulings?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
  mail_mailing_schedulings_aggregate?: InputMaybe<Mail_Mailing_Scheduling_Aggregate_Bool_Exp>;
  mail_mailing_triggers_groups?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
  mail_mailing_triggers_groups_aggregate?: InputMaybe<Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp>;
  mail_tags?: InputMaybe<String_Array_Comparison_Exp>;
  mail_unique_trigger?: InputMaybe<String_Comparison_Exp>;
  onetime_trigger?: InputMaybe<Boolean_Comparison_Exp>;
  template_name?: InputMaybe<String_Comparison_Exp>;
  template_params?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "mail_autosending_types" */
export enum Mail_Autosending_Types_Constraint {
  /** unique or primary key constraint on columns "mail_unique_trigger" */
  MailAutosendingTypesMailUniqueTriggerKey = 'mail_autosending_types_mail_unique_trigger_key',
  /** unique or primary key constraint on columns "mail_id" */
  MailAutosendingTypesPkey = 'mail_autosending_types_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mail_Autosending_Types_Delete_At_Path_Input = {
  template_params?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mail_Autosending_Types_Delete_Elem_Input = {
  template_params?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mail_Autosending_Types_Delete_Key_Input = {
  template_params?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "mail_autosending_types" */
export type Mail_Autosending_Types_Insert_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  mail_bonus?: InputMaybe<Mail_Bonuses_Obj_Rel_Insert_Input>;
  mail_bonus_id?: InputMaybe<Scalars['uuid']>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  mail_mailing_schedulings?: InputMaybe<Mail_Mailing_Scheduling_Arr_Rel_Insert_Input>;
  mail_mailing_triggers_groups?: InputMaybe<Mail_Mailing_Triggers_Groups_Arr_Rel_Insert_Input>;
  mail_tags?: InputMaybe<Array<Scalars['String']>>;
  mail_unique_trigger?: InputMaybe<Scalars['String']>;
  onetime_trigger?: InputMaybe<Scalars['Boolean']>;
  template_name?: InputMaybe<Scalars['String']>;
  template_params?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Mail_Autosending_Types_Max_Fields = {
  __typename?: 'mail_autosending_types_max_fields';
  mail_bonus_id?: Maybe<Scalars['uuid']>;
  mail_id?: Maybe<Scalars['uuid']>;
  mail_tags?: Maybe<Array<Scalars['String']>>;
  mail_unique_trigger?: Maybe<Scalars['String']>;
  template_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Mail_Autosending_Types_Min_Fields = {
  __typename?: 'mail_autosending_types_min_fields';
  mail_bonus_id?: Maybe<Scalars['uuid']>;
  mail_id?: Maybe<Scalars['uuid']>;
  mail_tags?: Maybe<Array<Scalars['String']>>;
  mail_unique_trigger?: Maybe<Scalars['String']>;
  template_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mail_autosending_types" */
export type Mail_Autosending_Types_Mutation_Response = {
  __typename?: 'mail_autosending_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mail_Autosending_Types>;
};

/** input type for inserting object relation for remote table "mail_autosending_types" */
export type Mail_Autosending_Types_Obj_Rel_Insert_Input = {
  data: Mail_Autosending_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mail_Autosending_Types_On_Conflict>;
};

/** on_conflict condition type for table "mail_autosending_types" */
export type Mail_Autosending_Types_On_Conflict = {
  constraint: Mail_Autosending_Types_Constraint;
  update_columns?: Array<Mail_Autosending_Types_Update_Column>;
  where?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "mail_autosending_types". */
export type Mail_Autosending_Types_Order_By = {
  is_active?: InputMaybe<Order_By>;
  mail_bonus?: InputMaybe<Mail_Bonuses_Order_By>;
  mail_bonus_id?: InputMaybe<Order_By>;
  mail_id?: InputMaybe<Order_By>;
  mail_mailing_schedulings_aggregate?: InputMaybe<Mail_Mailing_Scheduling_Aggregate_Order_By>;
  mail_mailing_triggers_groups_aggregate?: InputMaybe<Mail_Mailing_Triggers_Groups_Aggregate_Order_By>;
  mail_tags?: InputMaybe<Order_By>;
  mail_unique_trigger?: InputMaybe<Order_By>;
  onetime_trigger?: InputMaybe<Order_By>;
  template_name?: InputMaybe<Order_By>;
  template_params?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mail_autosending_types */
export type Mail_Autosending_Types_Pk_Columns_Input = {
  mail_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mail_Autosending_Types_Prepend_Input = {
  template_params?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "mail_autosending_types" */
export enum Mail_Autosending_Types_Select_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MailBonusId = 'mail_bonus_id',
  /** column name */
  MailId = 'mail_id',
  /** column name */
  MailTags = 'mail_tags',
  /** column name */
  MailUniqueTrigger = 'mail_unique_trigger',
  /** column name */
  OnetimeTrigger = 'onetime_trigger',
  /** column name */
  TemplateName = 'template_name',
  /** column name */
  TemplateParams = 'template_params'
}

/** input type for updating data in table "mail_autosending_types" */
export type Mail_Autosending_Types_Set_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  mail_bonus_id?: InputMaybe<Scalars['uuid']>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  mail_tags?: InputMaybe<Array<Scalars['String']>>;
  mail_unique_trigger?: InputMaybe<Scalars['String']>;
  onetime_trigger?: InputMaybe<Scalars['Boolean']>;
  template_name?: InputMaybe<Scalars['String']>;
  template_params?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "mail_autosending_types" */
export type Mail_Autosending_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Autosending_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mail_Autosending_Types_Stream_Cursor_Value_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  mail_bonus_id?: InputMaybe<Scalars['uuid']>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  mail_tags?: InputMaybe<Array<Scalars['String']>>;
  mail_unique_trigger?: InputMaybe<Scalars['String']>;
  onetime_trigger?: InputMaybe<Scalars['Boolean']>;
  template_name?: InputMaybe<Scalars['String']>;
  template_params?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "mail_autosending_types" */
export enum Mail_Autosending_Types_Update_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MailBonusId = 'mail_bonus_id',
  /** column name */
  MailId = 'mail_id',
  /** column name */
  MailTags = 'mail_tags',
  /** column name */
  MailUniqueTrigger = 'mail_unique_trigger',
  /** column name */
  OnetimeTrigger = 'onetime_trigger',
  /** column name */
  TemplateName = 'template_name',
  /** column name */
  TemplateParams = 'template_params'
}

export type Mail_Autosending_Types_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mail_Autosending_Types_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mail_Autosending_Types_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mail_Autosending_Types_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mail_Autosending_Types_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mail_Autosending_Types_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Autosending_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mail_Autosending_Types_Bool_Exp;
};

/** columns and relationships of "mail_bonuses" */
export type Mail_Bonuses = {
  __typename?: 'mail_bonuses';
  bonus_additional_value?: Maybe<Scalars['jsonb']>;
  bonus_id: Scalars['uuid'];
  bonus_type: Scalars['String'];
  bonus_value: Scalars['String'];
  /** An array relationship */
  mail_bonuses_offers: Array<Mail_Bonuses_Offers>;
  /** An aggregate relationship */
  mail_bonuses_offers_aggregate: Mail_Bonuses_Offers_Aggregate;
};


/** columns and relationships of "mail_bonuses" */
export type Mail_BonusesBonus_Additional_ValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "mail_bonuses" */
export type Mail_BonusesMail_Bonuses_OffersArgs = {
  distinct_on?: InputMaybe<Array<Mail_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
};


/** columns and relationships of "mail_bonuses" */
export type Mail_BonusesMail_Bonuses_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
};

/** aggregated selection of "mail_bonuses" */
export type Mail_Bonuses_Aggregate = {
  __typename?: 'mail_bonuses_aggregate';
  aggregate?: Maybe<Mail_Bonuses_Aggregate_Fields>;
  nodes: Array<Mail_Bonuses>;
};

/** aggregate fields of "mail_bonuses" */
export type Mail_Bonuses_Aggregate_Fields = {
  __typename?: 'mail_bonuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mail_Bonuses_Max_Fields>;
  min?: Maybe<Mail_Bonuses_Min_Fields>;
};


/** aggregate fields of "mail_bonuses" */
export type Mail_Bonuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Bonuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mail_Bonuses_Append_Input = {
  bonus_additional_value?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "mail_bonuses". All fields are combined with a logical 'AND'. */
export type Mail_Bonuses_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Bonuses_Bool_Exp>>;
  _not?: InputMaybe<Mail_Bonuses_Bool_Exp>;
  _or?: InputMaybe<Array<Mail_Bonuses_Bool_Exp>>;
  bonus_additional_value?: InputMaybe<Jsonb_Comparison_Exp>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  bonus_type?: InputMaybe<String_Comparison_Exp>;
  bonus_value?: InputMaybe<String_Comparison_Exp>;
  mail_bonuses_offers?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
  mail_bonuses_offers_aggregate?: InputMaybe<Mail_Bonuses_Offers_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "mail_bonuses" */
export enum Mail_Bonuses_Constraint {
  /** unique or primary key constraint on columns "bonus_id" */
  MailBonusesPkey = 'mail_bonuses_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mail_Bonuses_Delete_At_Path_Input = {
  bonus_additional_value?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mail_Bonuses_Delete_Elem_Input = {
  bonus_additional_value?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mail_Bonuses_Delete_Key_Input = {
  bonus_additional_value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "mail_bonuses" */
export type Mail_Bonuses_Insert_Input = {
  bonus_additional_value?: InputMaybe<Scalars['jsonb']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_type?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['String']>;
  mail_bonuses_offers?: InputMaybe<Mail_Bonuses_Offers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Mail_Bonuses_Max_Fields = {
  __typename?: 'mail_bonuses_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_type?: Maybe<Scalars['String']>;
  bonus_value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Mail_Bonuses_Min_Fields = {
  __typename?: 'mail_bonuses_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_type?: Maybe<Scalars['String']>;
  bonus_value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mail_bonuses" */
export type Mail_Bonuses_Mutation_Response = {
  __typename?: 'mail_bonuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mail_Bonuses>;
};

/** input type for inserting object relation for remote table "mail_bonuses" */
export type Mail_Bonuses_Obj_Rel_Insert_Input = {
  data: Mail_Bonuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mail_Bonuses_On_Conflict>;
};

/** columns and relationships of "mail_bonuses_offers" */
export type Mail_Bonuses_Offers = {
  __typename?: 'mail_bonuses_offers';
  bonus_id: Scalars['uuid'];
  claim_datetime?: Maybe<Scalars['timestamp']>;
  claimed: Scalars['Boolean'];
  created_at: Scalars['timestamp'];
  current_active: Scalars['Boolean'];
  end_timer?: Maybe<Scalars['Int']>;
  /** An object relationship */
  mail_bonuse: Mail_Bonuses;
  offer_value: Scalars['String'];
  updated_at: Scalars['timestamp'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Aggregate = {
  __typename?: 'mail_bonuses_offers_aggregate';
  aggregate?: Maybe<Mail_Bonuses_Offers_Aggregate_Fields>;
  nodes: Array<Mail_Bonuses_Offers>;
};

export type Mail_Bonuses_Offers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mail_Bonuses_Offers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mail_Bonuses_Offers_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mail_Bonuses_Offers_Aggregate_Bool_Exp_Count>;
};

export type Mail_Bonuses_Offers_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mail_Bonuses_Offers_Select_Column_Mail_Bonuses_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mail_Bonuses_Offers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mail_Bonuses_Offers_Select_Column_Mail_Bonuses_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mail_Bonuses_Offers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mail_Bonuses_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Aggregate_Fields = {
  __typename?: 'mail_bonuses_offers_aggregate_fields';
  avg?: Maybe<Mail_Bonuses_Offers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mail_Bonuses_Offers_Max_Fields>;
  min?: Maybe<Mail_Bonuses_Offers_Min_Fields>;
  stddev?: Maybe<Mail_Bonuses_Offers_Stddev_Fields>;
  stddev_pop?: Maybe<Mail_Bonuses_Offers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mail_Bonuses_Offers_Stddev_Samp_Fields>;
  sum?: Maybe<Mail_Bonuses_Offers_Sum_Fields>;
  var_pop?: Maybe<Mail_Bonuses_Offers_Var_Pop_Fields>;
  var_samp?: Maybe<Mail_Bonuses_Offers_Var_Samp_Fields>;
  variance?: Maybe<Mail_Bonuses_Offers_Variance_Fields>;
};


/** aggregate fields of "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Bonuses_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Aggregate_Order_By = {
  avg?: InputMaybe<Mail_Bonuses_Offers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mail_Bonuses_Offers_Max_Order_By>;
  min?: InputMaybe<Mail_Bonuses_Offers_Min_Order_By>;
  stddev?: InputMaybe<Mail_Bonuses_Offers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mail_Bonuses_Offers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mail_Bonuses_Offers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mail_Bonuses_Offers_Sum_Order_By>;
  var_pop?: InputMaybe<Mail_Bonuses_Offers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mail_Bonuses_Offers_Var_Samp_Order_By>;
  variance?: InputMaybe<Mail_Bonuses_Offers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Arr_Rel_Insert_Input = {
  data: Array<Mail_Bonuses_Offers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mail_Bonuses_Offers_On_Conflict>;
};

/** aggregate avg on columns */
export type Mail_Bonuses_Offers_Avg_Fields = {
  __typename?: 'mail_bonuses_offers_avg_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Avg_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mail_bonuses_offers". All fields are combined with a logical 'AND'. */
export type Mail_Bonuses_Offers_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Bonuses_Offers_Bool_Exp>>;
  _not?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
  _or?: InputMaybe<Array<Mail_Bonuses_Offers_Bool_Exp>>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  claim_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  claimed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  current_active?: InputMaybe<Boolean_Comparison_Exp>;
  end_timer?: InputMaybe<Int_Comparison_Exp>;
  mail_bonuse?: InputMaybe<Mail_Bonuses_Bool_Exp>;
  offer_value?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mail_bonuses_offers" */
export enum Mail_Bonuses_Offers_Constraint {
  /** unique or primary key constraint on columns "user_id", "bonus_id" */
  MailBonusesOffersPkey = 'mail_bonuses_offers_pkey'
}

/** input type for incrementing numeric columns in table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Inc_Input = {
  end_timer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Insert_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  claim_datetime?: InputMaybe<Scalars['timestamp']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  current_active?: InputMaybe<Scalars['Boolean']>;
  end_timer?: InputMaybe<Scalars['Int']>;
  mail_bonuse?: InputMaybe<Mail_Bonuses_Obj_Rel_Insert_Input>;
  offer_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mail_Bonuses_Offers_Max_Fields = {
  __typename?: 'mail_bonuses_offers_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  claim_datetime?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamp']>;
  end_timer?: Maybe<Scalars['Int']>;
  offer_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Max_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  claim_datetime?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_timer?: InputMaybe<Order_By>;
  offer_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mail_Bonuses_Offers_Min_Fields = {
  __typename?: 'mail_bonuses_offers_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  claim_datetime?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamp']>;
  end_timer?: Maybe<Scalars['Int']>;
  offer_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Min_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  claim_datetime?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_timer?: InputMaybe<Order_By>;
  offer_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Mutation_Response = {
  __typename?: 'mail_bonuses_offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mail_Bonuses_Offers>;
};

/** on_conflict condition type for table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_On_Conflict = {
  constraint: Mail_Bonuses_Offers_Constraint;
  update_columns?: Array<Mail_Bonuses_Offers_Update_Column>;
  where?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "mail_bonuses_offers". */
export type Mail_Bonuses_Offers_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  claim_datetime?: InputMaybe<Order_By>;
  claimed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_active?: InputMaybe<Order_By>;
  end_timer?: InputMaybe<Order_By>;
  mail_bonuse?: InputMaybe<Mail_Bonuses_Order_By>;
  offer_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mail_bonuses_offers */
export type Mail_Bonuses_Offers_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "mail_bonuses_offers" */
export enum Mail_Bonuses_Offers_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ClaimDatetime = 'claim_datetime',
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentActive = 'current_active',
  /** column name */
  EndTimer = 'end_timer',
  /** column name */
  OfferValue = 'offer_value',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "mail_bonuses_offers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "mail_bonuses_offers" */
export enum Mail_Bonuses_Offers_Select_Column_Mail_Bonuses_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CurrentActive = 'current_active'
}

/** select "mail_bonuses_offers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "mail_bonuses_offers" */
export enum Mail_Bonuses_Offers_Select_Column_Mail_Bonuses_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CurrentActive = 'current_active'
}

/** input type for updating data in table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  claim_datetime?: InputMaybe<Scalars['timestamp']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  current_active?: InputMaybe<Scalars['Boolean']>;
  end_timer?: InputMaybe<Scalars['Int']>;
  offer_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Mail_Bonuses_Offers_Stddev_Fields = {
  __typename?: 'mail_bonuses_offers_stddev_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Stddev_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mail_Bonuses_Offers_Stddev_Pop_Fields = {
  __typename?: 'mail_bonuses_offers_stddev_pop_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Stddev_Pop_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mail_Bonuses_Offers_Stddev_Samp_Fields = {
  __typename?: 'mail_bonuses_offers_stddev_samp_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Stddev_Samp_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Bonuses_Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mail_Bonuses_Offers_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  claim_datetime?: InputMaybe<Scalars['timestamp']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  current_active?: InputMaybe<Scalars['Boolean']>;
  end_timer?: InputMaybe<Scalars['Int']>;
  offer_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Mail_Bonuses_Offers_Sum_Fields = {
  __typename?: 'mail_bonuses_offers_sum_fields';
  end_timer?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Sum_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** update columns of table "mail_bonuses_offers" */
export enum Mail_Bonuses_Offers_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ClaimDatetime = 'claim_datetime',
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentActive = 'current_active',
  /** column name */
  EndTimer = 'end_timer',
  /** column name */
  OfferValue = 'offer_value',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mail_Bonuses_Offers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mail_Bonuses_Offers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Bonuses_Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mail_Bonuses_Offers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mail_Bonuses_Offers_Var_Pop_Fields = {
  __typename?: 'mail_bonuses_offers_var_pop_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Var_Pop_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mail_Bonuses_Offers_Var_Samp_Fields = {
  __typename?: 'mail_bonuses_offers_var_samp_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Var_Samp_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mail_Bonuses_Offers_Variance_Fields = {
  __typename?: 'mail_bonuses_offers_variance_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "mail_bonuses_offers" */
export type Mail_Bonuses_Offers_Variance_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "mail_bonuses" */
export type Mail_Bonuses_On_Conflict = {
  constraint: Mail_Bonuses_Constraint;
  update_columns?: Array<Mail_Bonuses_Update_Column>;
  where?: InputMaybe<Mail_Bonuses_Bool_Exp>;
};

/** Ordering options when selecting data from "mail_bonuses". */
export type Mail_Bonuses_Order_By = {
  bonus_additional_value?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  bonus_type?: InputMaybe<Order_By>;
  bonus_value?: InputMaybe<Order_By>;
  mail_bonuses_offers_aggregate?: InputMaybe<Mail_Bonuses_Offers_Aggregate_Order_By>;
};

/** primary key columns input for table: mail_bonuses */
export type Mail_Bonuses_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mail_Bonuses_Prepend_Input = {
  bonus_additional_value?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "mail_bonuses" */
export enum Mail_Bonuses_Select_Column {
  /** column name */
  BonusAdditionalValue = 'bonus_additional_value',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusType = 'bonus_type',
  /** column name */
  BonusValue = 'bonus_value'
}

/** input type for updating data in table "mail_bonuses" */
export type Mail_Bonuses_Set_Input = {
  bonus_additional_value?: InputMaybe<Scalars['jsonb']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_type?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "mail_bonuses" */
export type Mail_Bonuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Bonuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mail_Bonuses_Stream_Cursor_Value_Input = {
  bonus_additional_value?: InputMaybe<Scalars['jsonb']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_type?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "mail_bonuses" */
export enum Mail_Bonuses_Update_Column {
  /** column name */
  BonusAdditionalValue = 'bonus_additional_value',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusType = 'bonus_type',
  /** column name */
  BonusValue = 'bonus_value'
}

export type Mail_Bonuses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mail_Bonuses_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mail_Bonuses_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mail_Bonuses_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mail_Bonuses_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mail_Bonuses_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Bonuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mail_Bonuses_Bool_Exp;
};

/** columns and relationships of "mail_mailing_callbacks" */
export type Mail_Mailing_Callbacks = {
  __typename?: 'mail_mailing_callbacks';
  callback_raw?: Maybe<Scalars['jsonb']>;
  client_info?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamp'];
  delivery_status?: Maybe<Scalars['jsonb']>;
  event: Scalars['String'];
  event_id: Scalars['String'];
  geolocation?: Maybe<Scalars['jsonb']>;
  ip?: Maybe<Scalars['String']>;
  mail_mg_id: Scalars['String'];
  tags?: Maybe<Scalars['jsonb']>;
  url?: Maybe<Scalars['String']>;
  user_email: Scalars['String'];
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "mail_mailing_callbacks" */
export type Mail_Mailing_CallbacksCallback_RawArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "mail_mailing_callbacks" */
export type Mail_Mailing_CallbacksClient_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "mail_mailing_callbacks" */
export type Mail_Mailing_CallbacksDelivery_StatusArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "mail_mailing_callbacks" */
export type Mail_Mailing_CallbacksGeolocationArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "mail_mailing_callbacks" */
export type Mail_Mailing_CallbacksTagsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "mail_mailing_callbacks" */
export type Mail_Mailing_Callbacks_Aggregate = {
  __typename?: 'mail_mailing_callbacks_aggregate';
  aggregate?: Maybe<Mail_Mailing_Callbacks_Aggregate_Fields>;
  nodes: Array<Mail_Mailing_Callbacks>;
};

/** aggregate fields of "mail_mailing_callbacks" */
export type Mail_Mailing_Callbacks_Aggregate_Fields = {
  __typename?: 'mail_mailing_callbacks_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mail_Mailing_Callbacks_Max_Fields>;
  min?: Maybe<Mail_Mailing_Callbacks_Min_Fields>;
};


/** aggregate fields of "mail_mailing_callbacks" */
export type Mail_Mailing_Callbacks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Mailing_Callbacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mail_Mailing_Callbacks_Append_Input = {
  callback_raw?: InputMaybe<Scalars['jsonb']>;
  client_info?: InputMaybe<Scalars['jsonb']>;
  delivery_status?: InputMaybe<Scalars['jsonb']>;
  geolocation?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "mail_mailing_callbacks". All fields are combined with a logical 'AND'. */
export type Mail_Mailing_Callbacks_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Mailing_Callbacks_Bool_Exp>>;
  _not?: InputMaybe<Mail_Mailing_Callbacks_Bool_Exp>;
  _or?: InputMaybe<Array<Mail_Mailing_Callbacks_Bool_Exp>>;
  callback_raw?: InputMaybe<Jsonb_Comparison_Exp>;
  client_info?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  delivery_status?: InputMaybe<Jsonb_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  event_id?: InputMaybe<String_Comparison_Exp>;
  geolocation?: InputMaybe<Jsonb_Comparison_Exp>;
  ip?: InputMaybe<String_Comparison_Exp>;
  mail_mg_id?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<Jsonb_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user_email?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mail_mailing_callbacks" */
export enum Mail_Mailing_Callbacks_Constraint {
  /** unique or primary key constraint on columns "event_id" */
  MailMailingCallbacksPkey = 'mail_mailing_callbacks_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mail_Mailing_Callbacks_Delete_At_Path_Input = {
  callback_raw?: InputMaybe<Array<Scalars['String']>>;
  client_info?: InputMaybe<Array<Scalars['String']>>;
  delivery_status?: InputMaybe<Array<Scalars['String']>>;
  geolocation?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mail_Mailing_Callbacks_Delete_Elem_Input = {
  callback_raw?: InputMaybe<Scalars['Int']>;
  client_info?: InputMaybe<Scalars['Int']>;
  delivery_status?: InputMaybe<Scalars['Int']>;
  geolocation?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mail_Mailing_Callbacks_Delete_Key_Input = {
  callback_raw?: InputMaybe<Scalars['String']>;
  client_info?: InputMaybe<Scalars['String']>;
  delivery_status?: InputMaybe<Scalars['String']>;
  geolocation?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "mail_mailing_callbacks" */
export type Mail_Mailing_Callbacks_Insert_Input = {
  callback_raw?: InputMaybe<Scalars['jsonb']>;
  client_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  delivery_status?: InputMaybe<Scalars['jsonb']>;
  event?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['String']>;
  geolocation?: InputMaybe<Scalars['jsonb']>;
  ip?: InputMaybe<Scalars['String']>;
  mail_mg_id?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  url?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mail_Mailing_Callbacks_Max_Fields = {
  __typename?: 'mail_mailing_callbacks_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  event?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  mail_mg_id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Mail_Mailing_Callbacks_Min_Fields = {
  __typename?: 'mail_mailing_callbacks_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  event?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  mail_mg_id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "mail_mailing_callbacks" */
export type Mail_Mailing_Callbacks_Mutation_Response = {
  __typename?: 'mail_mailing_callbacks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mail_Mailing_Callbacks>;
};

/** on_conflict condition type for table "mail_mailing_callbacks" */
export type Mail_Mailing_Callbacks_On_Conflict = {
  constraint: Mail_Mailing_Callbacks_Constraint;
  update_columns?: Array<Mail_Mailing_Callbacks_Update_Column>;
  where?: InputMaybe<Mail_Mailing_Callbacks_Bool_Exp>;
};

/** Ordering options when selecting data from "mail_mailing_callbacks". */
export type Mail_Mailing_Callbacks_Order_By = {
  callback_raw?: InputMaybe<Order_By>;
  client_info?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_status?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  geolocation?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  mail_mg_id?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mail_mailing_callbacks */
export type Mail_Mailing_Callbacks_Pk_Columns_Input = {
  event_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mail_Mailing_Callbacks_Prepend_Input = {
  callback_raw?: InputMaybe<Scalars['jsonb']>;
  client_info?: InputMaybe<Scalars['jsonb']>;
  delivery_status?: InputMaybe<Scalars['jsonb']>;
  geolocation?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "mail_mailing_callbacks" */
export enum Mail_Mailing_Callbacks_Select_Column {
  /** column name */
  CallbackRaw = 'callback_raw',
  /** column name */
  ClientInfo = 'client_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryStatus = 'delivery_status',
  /** column name */
  Event = 'event',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Geolocation = 'geolocation',
  /** column name */
  Ip = 'ip',
  /** column name */
  MailMgId = 'mail_mg_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  Url = 'url',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "mail_mailing_callbacks" */
export type Mail_Mailing_Callbacks_Set_Input = {
  callback_raw?: InputMaybe<Scalars['jsonb']>;
  client_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  delivery_status?: InputMaybe<Scalars['jsonb']>;
  event?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['String']>;
  geolocation?: InputMaybe<Scalars['jsonb']>;
  ip?: InputMaybe<Scalars['String']>;
  mail_mg_id?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  url?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "mail_mailing_callbacks" */
export type Mail_Mailing_Callbacks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Mailing_Callbacks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mail_Mailing_Callbacks_Stream_Cursor_Value_Input = {
  callback_raw?: InputMaybe<Scalars['jsonb']>;
  client_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  delivery_status?: InputMaybe<Scalars['jsonb']>;
  event?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['String']>;
  geolocation?: InputMaybe<Scalars['jsonb']>;
  ip?: InputMaybe<Scalars['String']>;
  mail_mg_id?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  url?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "mail_mailing_callbacks" */
export enum Mail_Mailing_Callbacks_Update_Column {
  /** column name */
  CallbackRaw = 'callback_raw',
  /** column name */
  ClientInfo = 'client_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryStatus = 'delivery_status',
  /** column name */
  Event = 'event',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Geolocation = 'geolocation',
  /** column name */
  Ip = 'ip',
  /** column name */
  MailMgId = 'mail_mg_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  Url = 'url',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  UserId = 'user_id'
}

export type Mail_Mailing_Callbacks_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mail_Mailing_Callbacks_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mail_Mailing_Callbacks_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mail_Mailing_Callbacks_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mail_Mailing_Callbacks_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mail_Mailing_Callbacks_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Mailing_Callbacks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mail_Mailing_Callbacks_Bool_Exp;
};

/** columns and relationships of "mail_mailing_lines" */
export type Mail_Mailing_Lines = {
  __typename?: 'mail_mailing_lines';
  break_condition?: Maybe<Scalars['String']>;
  /** An object relationship */
  mail?: Maybe<Mail_Autosending_Types>;
  mail_id: Scalars['uuid'];
  /** An object relationship */
  next_mail?: Maybe<Mail_Autosending_Types>;
  next_mail_hours_skip?: Maybe<Scalars['Int']>;
  next_mail_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  previous_mail?: Maybe<Mail_Autosending_Types>;
  previous_mail_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "mail_mailing_lines" */
export type Mail_Mailing_Lines_Aggregate = {
  __typename?: 'mail_mailing_lines_aggregate';
  aggregate?: Maybe<Mail_Mailing_Lines_Aggregate_Fields>;
  nodes: Array<Mail_Mailing_Lines>;
};

/** aggregate fields of "mail_mailing_lines" */
export type Mail_Mailing_Lines_Aggregate_Fields = {
  __typename?: 'mail_mailing_lines_aggregate_fields';
  avg?: Maybe<Mail_Mailing_Lines_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mail_Mailing_Lines_Max_Fields>;
  min?: Maybe<Mail_Mailing_Lines_Min_Fields>;
  stddev?: Maybe<Mail_Mailing_Lines_Stddev_Fields>;
  stddev_pop?: Maybe<Mail_Mailing_Lines_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mail_Mailing_Lines_Stddev_Samp_Fields>;
  sum?: Maybe<Mail_Mailing_Lines_Sum_Fields>;
  var_pop?: Maybe<Mail_Mailing_Lines_Var_Pop_Fields>;
  var_samp?: Maybe<Mail_Mailing_Lines_Var_Samp_Fields>;
  variance?: Maybe<Mail_Mailing_Lines_Variance_Fields>;
};


/** aggregate fields of "mail_mailing_lines" */
export type Mail_Mailing_Lines_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Mailing_Lines_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Mail_Mailing_Lines_Avg_Fields = {
  __typename?: 'mail_mailing_lines_avg_fields';
  next_mail_hours_skip?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mail_mailing_lines". All fields are combined with a logical 'AND'. */
export type Mail_Mailing_Lines_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Mailing_Lines_Bool_Exp>>;
  _not?: InputMaybe<Mail_Mailing_Lines_Bool_Exp>;
  _or?: InputMaybe<Array<Mail_Mailing_Lines_Bool_Exp>>;
  break_condition?: InputMaybe<String_Comparison_Exp>;
  mail?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
  mail_id?: InputMaybe<Uuid_Comparison_Exp>;
  next_mail?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
  next_mail_hours_skip?: InputMaybe<Int_Comparison_Exp>;
  next_mail_id?: InputMaybe<Uuid_Comparison_Exp>;
  previous_mail?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
  previous_mail_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mail_mailing_lines" */
export enum Mail_Mailing_Lines_Constraint {
  /** unique or primary key constraint on columns "mail_id" */
  MailMailingLinesPkey = 'mail_mailing_lines_pkey'
}

/** input type for incrementing numeric columns in table "mail_mailing_lines" */
export type Mail_Mailing_Lines_Inc_Input = {
  next_mail_hours_skip?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "mail_mailing_lines" */
export type Mail_Mailing_Lines_Insert_Input = {
  break_condition?: InputMaybe<Scalars['String']>;
  mail?: InputMaybe<Mail_Autosending_Types_Obj_Rel_Insert_Input>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  next_mail?: InputMaybe<Mail_Autosending_Types_Obj_Rel_Insert_Input>;
  next_mail_hours_skip?: InputMaybe<Scalars['Int']>;
  next_mail_id?: InputMaybe<Scalars['uuid']>;
  previous_mail?: InputMaybe<Mail_Autosending_Types_Obj_Rel_Insert_Input>;
  previous_mail_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mail_Mailing_Lines_Max_Fields = {
  __typename?: 'mail_mailing_lines_max_fields';
  break_condition?: Maybe<Scalars['String']>;
  mail_id?: Maybe<Scalars['uuid']>;
  next_mail_hours_skip?: Maybe<Scalars['Int']>;
  next_mail_id?: Maybe<Scalars['uuid']>;
  previous_mail_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Mail_Mailing_Lines_Min_Fields = {
  __typename?: 'mail_mailing_lines_min_fields';
  break_condition?: Maybe<Scalars['String']>;
  mail_id?: Maybe<Scalars['uuid']>;
  next_mail_hours_skip?: Maybe<Scalars['Int']>;
  next_mail_id?: Maybe<Scalars['uuid']>;
  previous_mail_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "mail_mailing_lines" */
export type Mail_Mailing_Lines_Mutation_Response = {
  __typename?: 'mail_mailing_lines_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mail_Mailing_Lines>;
};

/** input type for inserting object relation for remote table "mail_mailing_lines" */
export type Mail_Mailing_Lines_Obj_Rel_Insert_Input = {
  data: Mail_Mailing_Lines_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mail_Mailing_Lines_On_Conflict>;
};

/** on_conflict condition type for table "mail_mailing_lines" */
export type Mail_Mailing_Lines_On_Conflict = {
  constraint: Mail_Mailing_Lines_Constraint;
  update_columns?: Array<Mail_Mailing_Lines_Update_Column>;
  where?: InputMaybe<Mail_Mailing_Lines_Bool_Exp>;
};

/** Ordering options when selecting data from "mail_mailing_lines". */
export type Mail_Mailing_Lines_Order_By = {
  break_condition?: InputMaybe<Order_By>;
  mail?: InputMaybe<Mail_Autosending_Types_Order_By>;
  mail_id?: InputMaybe<Order_By>;
  next_mail?: InputMaybe<Mail_Autosending_Types_Order_By>;
  next_mail_hours_skip?: InputMaybe<Order_By>;
  next_mail_id?: InputMaybe<Order_By>;
  previous_mail?: InputMaybe<Mail_Autosending_Types_Order_By>;
  previous_mail_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mail_mailing_lines */
export type Mail_Mailing_Lines_Pk_Columns_Input = {
  mail_id: Scalars['uuid'];
};

/** select columns of table "mail_mailing_lines" */
export enum Mail_Mailing_Lines_Select_Column {
  /** column name */
  BreakCondition = 'break_condition',
  /** column name */
  MailId = 'mail_id',
  /** column name */
  NextMailHoursSkip = 'next_mail_hours_skip',
  /** column name */
  NextMailId = 'next_mail_id',
  /** column name */
  PreviousMailId = 'previous_mail_id'
}

/** input type for updating data in table "mail_mailing_lines" */
export type Mail_Mailing_Lines_Set_Input = {
  break_condition?: InputMaybe<Scalars['String']>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  next_mail_hours_skip?: InputMaybe<Scalars['Int']>;
  next_mail_id?: InputMaybe<Scalars['uuid']>;
  previous_mail_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Mail_Mailing_Lines_Stddev_Fields = {
  __typename?: 'mail_mailing_lines_stddev_fields';
  next_mail_hours_skip?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mail_Mailing_Lines_Stddev_Pop_Fields = {
  __typename?: 'mail_mailing_lines_stddev_pop_fields';
  next_mail_hours_skip?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mail_Mailing_Lines_Stddev_Samp_Fields = {
  __typename?: 'mail_mailing_lines_stddev_samp_fields';
  next_mail_hours_skip?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "mail_mailing_lines" */
export type Mail_Mailing_Lines_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Mailing_Lines_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mail_Mailing_Lines_Stream_Cursor_Value_Input = {
  break_condition?: InputMaybe<Scalars['String']>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  next_mail_hours_skip?: InputMaybe<Scalars['Int']>;
  next_mail_id?: InputMaybe<Scalars['uuid']>;
  previous_mail_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Mail_Mailing_Lines_Sum_Fields = {
  __typename?: 'mail_mailing_lines_sum_fields';
  next_mail_hours_skip?: Maybe<Scalars['Int']>;
};

/** update columns of table "mail_mailing_lines" */
export enum Mail_Mailing_Lines_Update_Column {
  /** column name */
  BreakCondition = 'break_condition',
  /** column name */
  MailId = 'mail_id',
  /** column name */
  NextMailHoursSkip = 'next_mail_hours_skip',
  /** column name */
  NextMailId = 'next_mail_id',
  /** column name */
  PreviousMailId = 'previous_mail_id'
}

export type Mail_Mailing_Lines_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mail_Mailing_Lines_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Mailing_Lines_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mail_Mailing_Lines_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mail_Mailing_Lines_Var_Pop_Fields = {
  __typename?: 'mail_mailing_lines_var_pop_fields';
  next_mail_hours_skip?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mail_Mailing_Lines_Var_Samp_Fields = {
  __typename?: 'mail_mailing_lines_var_samp_fields';
  next_mail_hours_skip?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mail_Mailing_Lines_Variance_Fields = {
  __typename?: 'mail_mailing_lines_variance_fields';
  next_mail_hours_skip?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mail_mailing_lists" */
export type Mail_Mailing_Lists = {
  __typename?: 'mail_mailing_lists';
  list_description?: Maybe<Scalars['String']>;
  list_name: Scalars['String'];
  mailgun_list_mail?: Maybe<Scalars['String']>;
  public: Scalars['Boolean'];
  subscribers_count: Scalars['Int'];
};

/** aggregated selection of "mail_mailing_lists" */
export type Mail_Mailing_Lists_Aggregate = {
  __typename?: 'mail_mailing_lists_aggregate';
  aggregate?: Maybe<Mail_Mailing_Lists_Aggregate_Fields>;
  nodes: Array<Mail_Mailing_Lists>;
};

/** aggregate fields of "mail_mailing_lists" */
export type Mail_Mailing_Lists_Aggregate_Fields = {
  __typename?: 'mail_mailing_lists_aggregate_fields';
  avg?: Maybe<Mail_Mailing_Lists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mail_Mailing_Lists_Max_Fields>;
  min?: Maybe<Mail_Mailing_Lists_Min_Fields>;
  stddev?: Maybe<Mail_Mailing_Lists_Stddev_Fields>;
  stddev_pop?: Maybe<Mail_Mailing_Lists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mail_Mailing_Lists_Stddev_Samp_Fields>;
  sum?: Maybe<Mail_Mailing_Lists_Sum_Fields>;
  var_pop?: Maybe<Mail_Mailing_Lists_Var_Pop_Fields>;
  var_samp?: Maybe<Mail_Mailing_Lists_Var_Samp_Fields>;
  variance?: Maybe<Mail_Mailing_Lists_Variance_Fields>;
};


/** aggregate fields of "mail_mailing_lists" */
export type Mail_Mailing_Lists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Mailing_Lists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Mail_Mailing_Lists_Avg_Fields = {
  __typename?: 'mail_mailing_lists_avg_fields';
  subscribers_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mail_mailing_lists". All fields are combined with a logical 'AND'. */
export type Mail_Mailing_Lists_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Mailing_Lists_Bool_Exp>>;
  _not?: InputMaybe<Mail_Mailing_Lists_Bool_Exp>;
  _or?: InputMaybe<Array<Mail_Mailing_Lists_Bool_Exp>>;
  list_description?: InputMaybe<String_Comparison_Exp>;
  list_name?: InputMaybe<String_Comparison_Exp>;
  mailgun_list_mail?: InputMaybe<String_Comparison_Exp>;
  public?: InputMaybe<Boolean_Comparison_Exp>;
  subscribers_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "mail_mailing_lists" */
export enum Mail_Mailing_Lists_Constraint {
  /** unique or primary key constraint on columns "list_name" */
  MailMailingListsPkey = 'mail_mailing_lists_pkey'
}

/** input type for incrementing numeric columns in table "mail_mailing_lists" */
export type Mail_Mailing_Lists_Inc_Input = {
  subscribers_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "mail_mailing_lists" */
export type Mail_Mailing_Lists_Insert_Input = {
  list_description?: InputMaybe<Scalars['String']>;
  list_name?: InputMaybe<Scalars['String']>;
  mailgun_list_mail?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  subscribers_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Mail_Mailing_Lists_Max_Fields = {
  __typename?: 'mail_mailing_lists_max_fields';
  list_description?: Maybe<Scalars['String']>;
  list_name?: Maybe<Scalars['String']>;
  mailgun_list_mail?: Maybe<Scalars['String']>;
  subscribers_count?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Mail_Mailing_Lists_Min_Fields = {
  __typename?: 'mail_mailing_lists_min_fields';
  list_description?: Maybe<Scalars['String']>;
  list_name?: Maybe<Scalars['String']>;
  mailgun_list_mail?: Maybe<Scalars['String']>;
  subscribers_count?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "mail_mailing_lists" */
export type Mail_Mailing_Lists_Mutation_Response = {
  __typename?: 'mail_mailing_lists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mail_Mailing_Lists>;
};

/** on_conflict condition type for table "mail_mailing_lists" */
export type Mail_Mailing_Lists_On_Conflict = {
  constraint: Mail_Mailing_Lists_Constraint;
  update_columns?: Array<Mail_Mailing_Lists_Update_Column>;
  where?: InputMaybe<Mail_Mailing_Lists_Bool_Exp>;
};

/** Ordering options when selecting data from "mail_mailing_lists". */
export type Mail_Mailing_Lists_Order_By = {
  list_description?: InputMaybe<Order_By>;
  list_name?: InputMaybe<Order_By>;
  mailgun_list_mail?: InputMaybe<Order_By>;
  public?: InputMaybe<Order_By>;
  subscribers_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mail_mailing_lists */
export type Mail_Mailing_Lists_Pk_Columns_Input = {
  list_name: Scalars['String'];
};

/** select columns of table "mail_mailing_lists" */
export enum Mail_Mailing_Lists_Select_Column {
  /** column name */
  ListDescription = 'list_description',
  /** column name */
  ListName = 'list_name',
  /** column name */
  MailgunListMail = 'mailgun_list_mail',
  /** column name */
  Public = 'public',
  /** column name */
  SubscribersCount = 'subscribers_count'
}

/** input type for updating data in table "mail_mailing_lists" */
export type Mail_Mailing_Lists_Set_Input = {
  list_description?: InputMaybe<Scalars['String']>;
  list_name?: InputMaybe<Scalars['String']>;
  mailgun_list_mail?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  subscribers_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Mail_Mailing_Lists_Stddev_Fields = {
  __typename?: 'mail_mailing_lists_stddev_fields';
  subscribers_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mail_Mailing_Lists_Stddev_Pop_Fields = {
  __typename?: 'mail_mailing_lists_stddev_pop_fields';
  subscribers_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mail_Mailing_Lists_Stddev_Samp_Fields = {
  __typename?: 'mail_mailing_lists_stddev_samp_fields';
  subscribers_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "mail_mailing_lists" */
export type Mail_Mailing_Lists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Mailing_Lists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mail_Mailing_Lists_Stream_Cursor_Value_Input = {
  list_description?: InputMaybe<Scalars['String']>;
  list_name?: InputMaybe<Scalars['String']>;
  mailgun_list_mail?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  subscribers_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Mail_Mailing_Lists_Sum_Fields = {
  __typename?: 'mail_mailing_lists_sum_fields';
  subscribers_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "mail_mailing_lists" */
export enum Mail_Mailing_Lists_Update_Column {
  /** column name */
  ListDescription = 'list_description',
  /** column name */
  ListName = 'list_name',
  /** column name */
  MailgunListMail = 'mailgun_list_mail',
  /** column name */
  Public = 'public',
  /** column name */
  SubscribersCount = 'subscribers_count'
}

export type Mail_Mailing_Lists_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mail_Mailing_Lists_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Mailing_Lists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mail_Mailing_Lists_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mail_Mailing_Lists_Var_Pop_Fields = {
  __typename?: 'mail_mailing_lists_var_pop_fields';
  subscribers_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mail_Mailing_Lists_Var_Samp_Fields = {
  __typename?: 'mail_mailing_lists_var_samp_fields';
  subscribers_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mail_Mailing_Lists_Variance_Fields = {
  __typename?: 'mail_mailing_lists_variance_fields';
  subscribers_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling = {
  __typename?: 'mail_mailing_scheduling';
  cancelled_by_trigger: Scalars['Boolean'];
  created_at: Scalars['timestamp'];
  dispatch_time: Scalars['timestamp'];
  dispatched: Scalars['Boolean'];
  /** An object relationship */
  mail_autosending_type: Mail_Autosending_Types;
  mail_id: Scalars['uuid'];
  /** An object relationship */
  mails_line?: Maybe<Mail_Mailing_Lines>;
  should_validate_trigger: Scalars['Boolean'];
  template_params?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamp'];
  user_id: Scalars['uuid'];
  user_params?: Maybe<Scalars['jsonb']>;
  user_profile?: Maybe<User_Profile>;
};


/** columns and relationships of "mail_mailing_scheduling" */
export type Mail_Mailing_SchedulingTemplate_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "mail_mailing_scheduling" */
export type Mail_Mailing_SchedulingUser_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_Aggregate = {
  __typename?: 'mail_mailing_scheduling_aggregate';
  aggregate?: Maybe<Mail_Mailing_Scheduling_Aggregate_Fields>;
  nodes: Array<Mail_Mailing_Scheduling>;
};

export type Mail_Mailing_Scheduling_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mail_Mailing_Scheduling_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mail_Mailing_Scheduling_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mail_Mailing_Scheduling_Aggregate_Bool_Exp_Count>;
};

export type Mail_Mailing_Scheduling_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mail_Mailing_Scheduling_Select_Column_Mail_Mailing_Scheduling_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mail_Mailing_Scheduling_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mail_Mailing_Scheduling_Select_Column_Mail_Mailing_Scheduling_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mail_Mailing_Scheduling_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mail_Mailing_Scheduling_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_Aggregate_Fields = {
  __typename?: 'mail_mailing_scheduling_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mail_Mailing_Scheduling_Max_Fields>;
  min?: Maybe<Mail_Mailing_Scheduling_Min_Fields>;
};


/** aggregate fields of "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Mailing_Scheduling_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mail_Mailing_Scheduling_Max_Order_By>;
  min?: InputMaybe<Mail_Mailing_Scheduling_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mail_Mailing_Scheduling_Append_Input = {
  template_params?: InputMaybe<Scalars['jsonb']>;
  user_params?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_Arr_Rel_Insert_Input = {
  data: Array<Mail_Mailing_Scheduling_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mail_Mailing_Scheduling_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mail_mailing_scheduling". All fields are combined with a logical 'AND'. */
export type Mail_Mailing_Scheduling_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Mailing_Scheduling_Bool_Exp>>;
  _not?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
  _or?: InputMaybe<Array<Mail_Mailing_Scheduling_Bool_Exp>>;
  cancelled_by_trigger?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  dispatch_time?: InputMaybe<Timestamp_Comparison_Exp>;
  dispatched?: InputMaybe<Boolean_Comparison_Exp>;
  mail_autosending_type?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
  mail_id?: InputMaybe<Uuid_Comparison_Exp>;
  mails_line?: InputMaybe<Mail_Mailing_Lines_Bool_Exp>;
  should_validate_trigger?: InputMaybe<Boolean_Comparison_Exp>;
  template_params?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_params?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "mail_mailing_scheduling" */
export enum Mail_Mailing_Scheduling_Constraint {
  /** unique or primary key constraint on columns "user_id", "mail_id" */
  MailMailingSchedulingPkey = 'mail_mailing_scheduling_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mail_Mailing_Scheduling_Delete_At_Path_Input = {
  template_params?: InputMaybe<Array<Scalars['String']>>;
  user_params?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mail_Mailing_Scheduling_Delete_Elem_Input = {
  template_params?: InputMaybe<Scalars['Int']>;
  user_params?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mail_Mailing_Scheduling_Delete_Key_Input = {
  template_params?: InputMaybe<Scalars['String']>;
  user_params?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_Insert_Input = {
  cancelled_by_trigger?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dispatch_time?: InputMaybe<Scalars['timestamp']>;
  dispatched?: InputMaybe<Scalars['Boolean']>;
  mail_autosending_type?: InputMaybe<Mail_Autosending_Types_Obj_Rel_Insert_Input>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  mails_line?: InputMaybe<Mail_Mailing_Lines_Obj_Rel_Insert_Input>;
  should_validate_trigger?: InputMaybe<Scalars['Boolean']>;
  template_params?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_params?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Mail_Mailing_Scheduling_Max_Fields = {
  __typename?: 'mail_mailing_scheduling_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  dispatch_time?: Maybe<Scalars['timestamp']>;
  mail_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dispatch_time?: InputMaybe<Order_By>;
  mail_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mail_Mailing_Scheduling_Min_Fields = {
  __typename?: 'mail_mailing_scheduling_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  dispatch_time?: Maybe<Scalars['timestamp']>;
  mail_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dispatch_time?: InputMaybe<Order_By>;
  mail_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_Mutation_Response = {
  __typename?: 'mail_mailing_scheduling_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mail_Mailing_Scheduling>;
};

/** on_conflict condition type for table "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_On_Conflict = {
  constraint: Mail_Mailing_Scheduling_Constraint;
  update_columns?: Array<Mail_Mailing_Scheduling_Update_Column>;
  where?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
};

/** Ordering options when selecting data from "mail_mailing_scheduling". */
export type Mail_Mailing_Scheduling_Order_By = {
  cancelled_by_trigger?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dispatch_time?: InputMaybe<Order_By>;
  dispatched?: InputMaybe<Order_By>;
  mail_autosending_type?: InputMaybe<Mail_Autosending_Types_Order_By>;
  mail_id?: InputMaybe<Order_By>;
  mails_line?: InputMaybe<Mail_Mailing_Lines_Order_By>;
  should_validate_trigger?: InputMaybe<Order_By>;
  template_params?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_params?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mail_mailing_scheduling */
export type Mail_Mailing_Scheduling_Pk_Columns_Input = {
  mail_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mail_Mailing_Scheduling_Prepend_Input = {
  template_params?: InputMaybe<Scalars['jsonb']>;
  user_params?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "mail_mailing_scheduling" */
export enum Mail_Mailing_Scheduling_Select_Column {
  /** column name */
  CancelledByTrigger = 'cancelled_by_trigger',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DispatchTime = 'dispatch_time',
  /** column name */
  Dispatched = 'dispatched',
  /** column name */
  MailId = 'mail_id',
  /** column name */
  ShouldValidateTrigger = 'should_validate_trigger',
  /** column name */
  TemplateParams = 'template_params',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserParams = 'user_params'
}

/** select "mail_mailing_scheduling_aggregate_bool_exp_bool_and_arguments_columns" columns of table "mail_mailing_scheduling" */
export enum Mail_Mailing_Scheduling_Select_Column_Mail_Mailing_Scheduling_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CancelledByTrigger = 'cancelled_by_trigger',
  /** column name */
  Dispatched = 'dispatched',
  /** column name */
  ShouldValidateTrigger = 'should_validate_trigger'
}

/** select "mail_mailing_scheduling_aggregate_bool_exp_bool_or_arguments_columns" columns of table "mail_mailing_scheduling" */
export enum Mail_Mailing_Scheduling_Select_Column_Mail_Mailing_Scheduling_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CancelledByTrigger = 'cancelled_by_trigger',
  /** column name */
  Dispatched = 'dispatched',
  /** column name */
  ShouldValidateTrigger = 'should_validate_trigger'
}

/** input type for updating data in table "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_Set_Input = {
  cancelled_by_trigger?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dispatch_time?: InputMaybe<Scalars['timestamp']>;
  dispatched?: InputMaybe<Scalars['Boolean']>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  should_validate_trigger?: InputMaybe<Scalars['Boolean']>;
  template_params?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_params?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "mail_mailing_scheduling" */
export type Mail_Mailing_Scheduling_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Mailing_Scheduling_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mail_Mailing_Scheduling_Stream_Cursor_Value_Input = {
  cancelled_by_trigger?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dispatch_time?: InputMaybe<Scalars['timestamp']>;
  dispatched?: InputMaybe<Scalars['Boolean']>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  should_validate_trigger?: InputMaybe<Scalars['Boolean']>;
  template_params?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_params?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "mail_mailing_scheduling" */
export enum Mail_Mailing_Scheduling_Update_Column {
  /** column name */
  CancelledByTrigger = 'cancelled_by_trigger',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DispatchTime = 'dispatch_time',
  /** column name */
  Dispatched = 'dispatched',
  /** column name */
  MailId = 'mail_id',
  /** column name */
  ShouldValidateTrigger = 'should_validate_trigger',
  /** column name */
  TemplateParams = 'template_params',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserParams = 'user_params'
}

export type Mail_Mailing_Scheduling_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mail_Mailing_Scheduling_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mail_Mailing_Scheduling_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mail_Mailing_Scheduling_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mail_Mailing_Scheduling_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mail_Mailing_Scheduling_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Mailing_Scheduling_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mail_Mailing_Scheduling_Bool_Exp;
};

/** columns and relationships of "mail_mailing_sending_log" */
export type Mail_Mailing_Sending_Log = {
  __typename?: 'mail_mailing_sending_log';
  created_at: Scalars['timestamp'];
  delivered: Scalars['Boolean'];
  delivered_at?: Maybe<Scalars['timestamp']>;
  mail_mg_id: Scalars['String'];
  mail_template: Scalars['String'];
  mail_template_params?: Maybe<Scalars['jsonb']>;
  opened: Scalars['Boolean'];
  opened_at?: Maybe<Scalars['timestamp']>;
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "mail_mailing_sending_log" */
export type Mail_Mailing_Sending_LogMail_Template_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "mail_mailing_sending_log" */
export type Mail_Mailing_Sending_Log_Aggregate = {
  __typename?: 'mail_mailing_sending_log_aggregate';
  aggregate?: Maybe<Mail_Mailing_Sending_Log_Aggregate_Fields>;
  nodes: Array<Mail_Mailing_Sending_Log>;
};

/** aggregate fields of "mail_mailing_sending_log" */
export type Mail_Mailing_Sending_Log_Aggregate_Fields = {
  __typename?: 'mail_mailing_sending_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mail_Mailing_Sending_Log_Max_Fields>;
  min?: Maybe<Mail_Mailing_Sending_Log_Min_Fields>;
};


/** aggregate fields of "mail_mailing_sending_log" */
export type Mail_Mailing_Sending_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Mailing_Sending_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mail_Mailing_Sending_Log_Append_Input = {
  mail_template_params?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "mail_mailing_sending_log". All fields are combined with a logical 'AND'. */
export type Mail_Mailing_Sending_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Mailing_Sending_Log_Bool_Exp>>;
  _not?: InputMaybe<Mail_Mailing_Sending_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Mail_Mailing_Sending_Log_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  delivered?: InputMaybe<Boolean_Comparison_Exp>;
  delivered_at?: InputMaybe<Timestamp_Comparison_Exp>;
  mail_mg_id?: InputMaybe<String_Comparison_Exp>;
  mail_template?: InputMaybe<String_Comparison_Exp>;
  mail_template_params?: InputMaybe<Jsonb_Comparison_Exp>;
  opened?: InputMaybe<Boolean_Comparison_Exp>;
  opened_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mail_mailing_sending_log" */
export enum Mail_Mailing_Sending_Log_Constraint {
  /** unique or primary key constraint on columns "mail_mg_id" */
  MailMailingSendingLogPkey = 'mail_mailing_sending_log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mail_Mailing_Sending_Log_Delete_At_Path_Input = {
  mail_template_params?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mail_Mailing_Sending_Log_Delete_Elem_Input = {
  mail_template_params?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mail_Mailing_Sending_Log_Delete_Key_Input = {
  mail_template_params?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "mail_mailing_sending_log" */
export type Mail_Mailing_Sending_Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  delivered?: InputMaybe<Scalars['Boolean']>;
  delivered_at?: InputMaybe<Scalars['timestamp']>;
  mail_mg_id?: InputMaybe<Scalars['String']>;
  mail_template?: InputMaybe<Scalars['String']>;
  mail_template_params?: InputMaybe<Scalars['jsonb']>;
  opened?: InputMaybe<Scalars['Boolean']>;
  opened_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mail_Mailing_Sending_Log_Max_Fields = {
  __typename?: 'mail_mailing_sending_log_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  delivered_at?: Maybe<Scalars['timestamp']>;
  mail_mg_id?: Maybe<Scalars['String']>;
  mail_template?: Maybe<Scalars['String']>;
  opened_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Mail_Mailing_Sending_Log_Min_Fields = {
  __typename?: 'mail_mailing_sending_log_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  delivered_at?: Maybe<Scalars['timestamp']>;
  mail_mg_id?: Maybe<Scalars['String']>;
  mail_template?: Maybe<Scalars['String']>;
  opened_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "mail_mailing_sending_log" */
export type Mail_Mailing_Sending_Log_Mutation_Response = {
  __typename?: 'mail_mailing_sending_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mail_Mailing_Sending_Log>;
};

/** on_conflict condition type for table "mail_mailing_sending_log" */
export type Mail_Mailing_Sending_Log_On_Conflict = {
  constraint: Mail_Mailing_Sending_Log_Constraint;
  update_columns?: Array<Mail_Mailing_Sending_Log_Update_Column>;
  where?: InputMaybe<Mail_Mailing_Sending_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "mail_mailing_sending_log". */
export type Mail_Mailing_Sending_Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivered?: InputMaybe<Order_By>;
  delivered_at?: InputMaybe<Order_By>;
  mail_mg_id?: InputMaybe<Order_By>;
  mail_template?: InputMaybe<Order_By>;
  mail_template_params?: InputMaybe<Order_By>;
  opened?: InputMaybe<Order_By>;
  opened_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mail_mailing_sending_log */
export type Mail_Mailing_Sending_Log_Pk_Columns_Input = {
  mail_mg_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mail_Mailing_Sending_Log_Prepend_Input = {
  mail_template_params?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "mail_mailing_sending_log" */
export enum Mail_Mailing_Sending_Log_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Delivered = 'delivered',
  /** column name */
  DeliveredAt = 'delivered_at',
  /** column name */
  MailMgId = 'mail_mg_id',
  /** column name */
  MailTemplate = 'mail_template',
  /** column name */
  MailTemplateParams = 'mail_template_params',
  /** column name */
  Opened = 'opened',
  /** column name */
  OpenedAt = 'opened_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "mail_mailing_sending_log" */
export type Mail_Mailing_Sending_Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  delivered?: InputMaybe<Scalars['Boolean']>;
  delivered_at?: InputMaybe<Scalars['timestamp']>;
  mail_mg_id?: InputMaybe<Scalars['String']>;
  mail_template?: InputMaybe<Scalars['String']>;
  mail_template_params?: InputMaybe<Scalars['jsonb']>;
  opened?: InputMaybe<Scalars['Boolean']>;
  opened_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "mail_mailing_sending_log" */
export type Mail_Mailing_Sending_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Mailing_Sending_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mail_Mailing_Sending_Log_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  delivered?: InputMaybe<Scalars['Boolean']>;
  delivered_at?: InputMaybe<Scalars['timestamp']>;
  mail_mg_id?: InputMaybe<Scalars['String']>;
  mail_template?: InputMaybe<Scalars['String']>;
  mail_template_params?: InputMaybe<Scalars['jsonb']>;
  opened?: InputMaybe<Scalars['Boolean']>;
  opened_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "mail_mailing_sending_log" */
export enum Mail_Mailing_Sending_Log_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Delivered = 'delivered',
  /** column name */
  DeliveredAt = 'delivered_at',
  /** column name */
  MailMgId = 'mail_mg_id',
  /** column name */
  MailTemplate = 'mail_template',
  /** column name */
  MailTemplateParams = 'mail_template_params',
  /** column name */
  Opened = 'opened',
  /** column name */
  OpenedAt = 'opened_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Mail_Mailing_Sending_Log_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mail_Mailing_Sending_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mail_Mailing_Sending_Log_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mail_Mailing_Sending_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mail_Mailing_Sending_Log_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mail_Mailing_Sending_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Mailing_Sending_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mail_Mailing_Sending_Log_Bool_Exp;
};

/** columns and relationships of "mail_mailing_triggers" */
export type Mail_Mailing_Triggers = {
  __typename?: 'mail_mailing_triggers';
  /** An object relationship */
  mail_triggers_group?: Maybe<Mail_Mailing_Triggers_Groups>;
  operator: Scalars['String'];
  trigger_name: Scalars['String'];
  trigger_param?: Maybe<Scalars['String']>;
  trigger_type: Scalars['String'];
  trigger_value?: Maybe<Scalars['String']>;
  triggers_group_id: Scalars['uuid'];
};

/** aggregated selection of "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_Aggregate = {
  __typename?: 'mail_mailing_triggers_aggregate';
  aggregate?: Maybe<Mail_Mailing_Triggers_Aggregate_Fields>;
  nodes: Array<Mail_Mailing_Triggers>;
};

export type Mail_Mailing_Triggers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mail_Mailing_Triggers_Aggregate_Bool_Exp_Count>;
};

export type Mail_Mailing_Triggers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mail_Mailing_Triggers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mail_Mailing_Triggers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_Aggregate_Fields = {
  __typename?: 'mail_mailing_triggers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mail_Mailing_Triggers_Max_Fields>;
  min?: Maybe<Mail_Mailing_Triggers_Min_Fields>;
};


/** aggregate fields of "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Mailing_Triggers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mail_Mailing_Triggers_Max_Order_By>;
  min?: InputMaybe<Mail_Mailing_Triggers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_Arr_Rel_Insert_Input = {
  data: Array<Mail_Mailing_Triggers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mail_Mailing_Triggers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mail_mailing_triggers". All fields are combined with a logical 'AND'. */
export type Mail_Mailing_Triggers_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Mailing_Triggers_Bool_Exp>>;
  _not?: InputMaybe<Mail_Mailing_Triggers_Bool_Exp>;
  _or?: InputMaybe<Array<Mail_Mailing_Triggers_Bool_Exp>>;
  mail_triggers_group?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
  operator?: InputMaybe<String_Comparison_Exp>;
  trigger_name?: InputMaybe<String_Comparison_Exp>;
  trigger_param?: InputMaybe<String_Comparison_Exp>;
  trigger_type?: InputMaybe<String_Comparison_Exp>;
  trigger_value?: InputMaybe<String_Comparison_Exp>;
  triggers_group_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mail_mailing_triggers" */
export enum Mail_Mailing_Triggers_Constraint {
  /** unique or primary key constraint on columns "trigger_name", "triggers_group_id" */
  MailMailingTriggersPkey = 'mail_mailing_triggers_pkey'
}

/** columns and relationships of "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups = {
  __typename?: 'mail_mailing_triggers_groups';
  group_and_clause: Scalars['Boolean'];
  /** An object relationship */
  mail_autosending_type: Mail_Autosending_Types;
  mail_id: Scalars['uuid'];
  /** An array relationship */
  mailing_triggers: Array<Mail_Mailing_Triggers>;
  /** An aggregate relationship */
  mailing_triggers_aggregate: Mail_Mailing_Triggers_Aggregate;
  triggers_group_id: Scalars['uuid'];
};


/** columns and relationships of "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_GroupsMailing_TriggersArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Bool_Exp>;
};


/** columns and relationships of "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_GroupsMailing_Triggers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Bool_Exp>;
};

/** aggregated selection of "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Aggregate = {
  __typename?: 'mail_mailing_triggers_groups_aggregate';
  aggregate?: Maybe<Mail_Mailing_Triggers_Groups_Aggregate_Fields>;
  nodes: Array<Mail_Mailing_Triggers_Groups>;
};

export type Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp_Count>;
};

export type Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mail_Mailing_Triggers_Groups_Select_Column_Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mail_Mailing_Triggers_Groups_Select_Column_Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Aggregate_Fields = {
  __typename?: 'mail_mailing_triggers_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mail_Mailing_Triggers_Groups_Max_Fields>;
  min?: Maybe<Mail_Mailing_Triggers_Groups_Min_Fields>;
};


/** aggregate fields of "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mail_Mailing_Triggers_Groups_Max_Order_By>;
  min?: InputMaybe<Mail_Mailing_Triggers_Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Arr_Rel_Insert_Input = {
  data: Array<Mail_Mailing_Triggers_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mail_Mailing_Triggers_Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mail_mailing_triggers_groups". All fields are combined with a logical 'AND'. */
export type Mail_Mailing_Triggers_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Bool_Exp>>;
  _not?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Bool_Exp>>;
  group_and_clause?: InputMaybe<Boolean_Comparison_Exp>;
  mail_autosending_type?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
  mail_id?: InputMaybe<Uuid_Comparison_Exp>;
  mailing_triggers?: InputMaybe<Mail_Mailing_Triggers_Bool_Exp>;
  mailing_triggers_aggregate?: InputMaybe<Mail_Mailing_Triggers_Aggregate_Bool_Exp>;
  triggers_group_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mail_mailing_triggers_groups" */
export enum Mail_Mailing_Triggers_Groups_Constraint {
  /** unique or primary key constraint on columns "triggers_group_id", "mail_id" */
  MailMailingTriggersGroupsPkey = 'mail_mailing_triggers_groups_pkey'
}

/** input type for inserting data into table "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Insert_Input = {
  group_and_clause?: InputMaybe<Scalars['Boolean']>;
  mail_autosending_type?: InputMaybe<Mail_Autosending_Types_Obj_Rel_Insert_Input>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  mailing_triggers?: InputMaybe<Mail_Mailing_Triggers_Arr_Rel_Insert_Input>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mail_Mailing_Triggers_Groups_Max_Fields = {
  __typename?: 'mail_mailing_triggers_groups_max_fields';
  mail_id?: Maybe<Scalars['uuid']>;
  triggers_group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Max_Order_By = {
  mail_id?: InputMaybe<Order_By>;
  triggers_group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mail_Mailing_Triggers_Groups_Min_Fields = {
  __typename?: 'mail_mailing_triggers_groups_min_fields';
  mail_id?: Maybe<Scalars['uuid']>;
  triggers_group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Min_Order_By = {
  mail_id?: InputMaybe<Order_By>;
  triggers_group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Mutation_Response = {
  __typename?: 'mail_mailing_triggers_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mail_Mailing_Triggers_Groups>;
};

/** input type for inserting object relation for remote table "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Obj_Rel_Insert_Input = {
  data: Mail_Mailing_Triggers_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mail_Mailing_Triggers_Groups_On_Conflict>;
};

/** on_conflict condition type for table "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_On_Conflict = {
  constraint: Mail_Mailing_Triggers_Groups_Constraint;
  update_columns?: Array<Mail_Mailing_Triggers_Groups_Update_Column>;
  where?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "mail_mailing_triggers_groups". */
export type Mail_Mailing_Triggers_Groups_Order_By = {
  group_and_clause?: InputMaybe<Order_By>;
  mail_autosending_type?: InputMaybe<Mail_Autosending_Types_Order_By>;
  mail_id?: InputMaybe<Order_By>;
  mailing_triggers_aggregate?: InputMaybe<Mail_Mailing_Triggers_Aggregate_Order_By>;
  triggers_group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mail_mailing_triggers_groups */
export type Mail_Mailing_Triggers_Groups_Pk_Columns_Input = {
  mail_id: Scalars['uuid'];
  triggers_group_id: Scalars['uuid'];
};

/** select columns of table "mail_mailing_triggers_groups" */
export enum Mail_Mailing_Triggers_Groups_Select_Column {
  /** column name */
  GroupAndClause = 'group_and_clause',
  /** column name */
  MailId = 'mail_id',
  /** column name */
  TriggersGroupId = 'triggers_group_id'
}

/** select "mail_mailing_triggers_groups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "mail_mailing_triggers_groups" */
export enum Mail_Mailing_Triggers_Groups_Select_Column_Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  GroupAndClause = 'group_and_clause'
}

/** select "mail_mailing_triggers_groups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "mail_mailing_triggers_groups" */
export enum Mail_Mailing_Triggers_Groups_Select_Column_Mail_Mailing_Triggers_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  GroupAndClause = 'group_and_clause'
}

/** input type for updating data in table "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Set_Input = {
  group_and_clause?: InputMaybe<Scalars['Boolean']>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "mail_mailing_triggers_groups" */
export type Mail_Mailing_Triggers_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Mailing_Triggers_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mail_Mailing_Triggers_Groups_Stream_Cursor_Value_Input = {
  group_and_clause?: InputMaybe<Scalars['Boolean']>;
  mail_id?: InputMaybe<Scalars['uuid']>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "mail_mailing_triggers_groups" */
export enum Mail_Mailing_Triggers_Groups_Update_Column {
  /** column name */
  GroupAndClause = 'group_and_clause',
  /** column name */
  MailId = 'mail_id',
  /** column name */
  TriggersGroupId = 'triggers_group_id'
}

export type Mail_Mailing_Triggers_Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Mailing_Triggers_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mail_Mailing_Triggers_Groups_Bool_Exp;
};

/** input type for inserting data into table "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_Insert_Input = {
  mail_triggers_group?: InputMaybe<Mail_Mailing_Triggers_Groups_Obj_Rel_Insert_Input>;
  operator?: InputMaybe<Scalars['String']>;
  trigger_name?: InputMaybe<Scalars['String']>;
  trigger_param?: InputMaybe<Scalars['String']>;
  trigger_type?: InputMaybe<Scalars['String']>;
  trigger_value?: InputMaybe<Scalars['String']>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mail_Mailing_Triggers_Max_Fields = {
  __typename?: 'mail_mailing_triggers_max_fields';
  operator?: Maybe<Scalars['String']>;
  trigger_name?: Maybe<Scalars['String']>;
  trigger_param?: Maybe<Scalars['String']>;
  trigger_type?: Maybe<Scalars['String']>;
  trigger_value?: Maybe<Scalars['String']>;
  triggers_group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_Max_Order_By = {
  operator?: InputMaybe<Order_By>;
  trigger_name?: InputMaybe<Order_By>;
  trigger_param?: InputMaybe<Order_By>;
  trigger_type?: InputMaybe<Order_By>;
  trigger_value?: InputMaybe<Order_By>;
  triggers_group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mail_Mailing_Triggers_Min_Fields = {
  __typename?: 'mail_mailing_triggers_min_fields';
  operator?: Maybe<Scalars['String']>;
  trigger_name?: Maybe<Scalars['String']>;
  trigger_param?: Maybe<Scalars['String']>;
  trigger_type?: Maybe<Scalars['String']>;
  trigger_value?: Maybe<Scalars['String']>;
  triggers_group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_Min_Order_By = {
  operator?: InputMaybe<Order_By>;
  trigger_name?: InputMaybe<Order_By>;
  trigger_param?: InputMaybe<Order_By>;
  trigger_type?: InputMaybe<Order_By>;
  trigger_value?: InputMaybe<Order_By>;
  triggers_group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_Mutation_Response = {
  __typename?: 'mail_mailing_triggers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mail_Mailing_Triggers>;
};

/** on_conflict condition type for table "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_On_Conflict = {
  constraint: Mail_Mailing_Triggers_Constraint;
  update_columns?: Array<Mail_Mailing_Triggers_Update_Column>;
  where?: InputMaybe<Mail_Mailing_Triggers_Bool_Exp>;
};

/** Ordering options when selecting data from "mail_mailing_triggers". */
export type Mail_Mailing_Triggers_Order_By = {
  mail_triggers_group?: InputMaybe<Mail_Mailing_Triggers_Groups_Order_By>;
  operator?: InputMaybe<Order_By>;
  trigger_name?: InputMaybe<Order_By>;
  trigger_param?: InputMaybe<Order_By>;
  trigger_type?: InputMaybe<Order_By>;
  trigger_value?: InputMaybe<Order_By>;
  triggers_group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mail_mailing_triggers */
export type Mail_Mailing_Triggers_Pk_Columns_Input = {
  trigger_name: Scalars['String'];
  triggers_group_id: Scalars['uuid'];
};

/** select columns of table "mail_mailing_triggers" */
export enum Mail_Mailing_Triggers_Select_Column {
  /** column name */
  Operator = 'operator',
  /** column name */
  TriggerName = 'trigger_name',
  /** column name */
  TriggerParam = 'trigger_param',
  /** column name */
  TriggerType = 'trigger_type',
  /** column name */
  TriggerValue = 'trigger_value',
  /** column name */
  TriggersGroupId = 'triggers_group_id'
}

/** input type for updating data in table "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_Set_Input = {
  operator?: InputMaybe<Scalars['String']>;
  trigger_name?: InputMaybe<Scalars['String']>;
  trigger_param?: InputMaybe<Scalars['String']>;
  trigger_type?: InputMaybe<Scalars['String']>;
  trigger_value?: InputMaybe<Scalars['String']>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "mail_mailing_triggers" */
export type Mail_Mailing_Triggers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Mailing_Triggers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mail_Mailing_Triggers_Stream_Cursor_Value_Input = {
  operator?: InputMaybe<Scalars['String']>;
  trigger_name?: InputMaybe<Scalars['String']>;
  trigger_param?: InputMaybe<Scalars['String']>;
  trigger_type?: InputMaybe<Scalars['String']>;
  trigger_value?: InputMaybe<Scalars['String']>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "mail_mailing_triggers" */
export enum Mail_Mailing_Triggers_Update_Column {
  /** column name */
  Operator = 'operator',
  /** column name */
  TriggerName = 'trigger_name',
  /** column name */
  TriggerParam = 'trigger_param',
  /** column name */
  TriggerType = 'trigger_type',
  /** column name */
  TriggerValue = 'trigger_value',
  /** column name */
  TriggersGroupId = 'triggers_group_id'
}

export type Mail_Mailing_Triggers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Mailing_Triggers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mail_Mailing_Triggers_Bool_Exp;
};

/** columns and relationships of "mail_templates" */
export type Mail_Templates = {
  __typename?: 'mail_templates';
  generate_disable_alerts_link: Scalars['Boolean'];
  subject?: Maybe<Scalars['String']>;
  template_data?: Maybe<Scalars['String']>;
  template_name: Scalars['String'];
  template_params?: Maybe<Scalars['jsonb']>;
  user_params?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "mail_templates" */
export type Mail_TemplatesTemplate_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "mail_templates" */
export type Mail_TemplatesUser_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "mail_templates" */
export type Mail_Templates_Aggregate = {
  __typename?: 'mail_templates_aggregate';
  aggregate?: Maybe<Mail_Templates_Aggregate_Fields>;
  nodes: Array<Mail_Templates>;
};

/** aggregate fields of "mail_templates" */
export type Mail_Templates_Aggregate_Fields = {
  __typename?: 'mail_templates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mail_Templates_Max_Fields>;
  min?: Maybe<Mail_Templates_Min_Fields>;
};


/** aggregate fields of "mail_templates" */
export type Mail_Templates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mail_Templates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mail_Templates_Append_Input = {
  template_params?: InputMaybe<Scalars['jsonb']>;
  user_params?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "mail_templates". All fields are combined with a logical 'AND'. */
export type Mail_Templates_Bool_Exp = {
  _and?: InputMaybe<Array<Mail_Templates_Bool_Exp>>;
  _not?: InputMaybe<Mail_Templates_Bool_Exp>;
  _or?: InputMaybe<Array<Mail_Templates_Bool_Exp>>;
  generate_disable_alerts_link?: InputMaybe<Boolean_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  template_data?: InputMaybe<String_Comparison_Exp>;
  template_name?: InputMaybe<String_Comparison_Exp>;
  template_params?: InputMaybe<Jsonb_Comparison_Exp>;
  user_params?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "mail_templates" */
export enum Mail_Templates_Constraint {
  /** unique or primary key constraint on columns "template_name" */
  MailTemplatesPkey = 'mail_templates_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mail_Templates_Delete_At_Path_Input = {
  template_params?: InputMaybe<Array<Scalars['String']>>;
  user_params?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mail_Templates_Delete_Elem_Input = {
  template_params?: InputMaybe<Scalars['Int']>;
  user_params?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mail_Templates_Delete_Key_Input = {
  template_params?: InputMaybe<Scalars['String']>;
  user_params?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "mail_templates" */
export type Mail_Templates_Insert_Input = {
  generate_disable_alerts_link?: InputMaybe<Scalars['Boolean']>;
  subject?: InputMaybe<Scalars['String']>;
  template_data?: InputMaybe<Scalars['String']>;
  template_name?: InputMaybe<Scalars['String']>;
  template_params?: InputMaybe<Scalars['jsonb']>;
  user_params?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Mail_Templates_Max_Fields = {
  __typename?: 'mail_templates_max_fields';
  subject?: Maybe<Scalars['String']>;
  template_data?: Maybe<Scalars['String']>;
  template_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Mail_Templates_Min_Fields = {
  __typename?: 'mail_templates_min_fields';
  subject?: Maybe<Scalars['String']>;
  template_data?: Maybe<Scalars['String']>;
  template_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mail_templates" */
export type Mail_Templates_Mutation_Response = {
  __typename?: 'mail_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mail_Templates>;
};

/** on_conflict condition type for table "mail_templates" */
export type Mail_Templates_On_Conflict = {
  constraint: Mail_Templates_Constraint;
  update_columns?: Array<Mail_Templates_Update_Column>;
  where?: InputMaybe<Mail_Templates_Bool_Exp>;
};

/** Ordering options when selecting data from "mail_templates". */
export type Mail_Templates_Order_By = {
  generate_disable_alerts_link?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  template_data?: InputMaybe<Order_By>;
  template_name?: InputMaybe<Order_By>;
  template_params?: InputMaybe<Order_By>;
  user_params?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mail_templates */
export type Mail_Templates_Pk_Columns_Input = {
  template_name: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mail_Templates_Prepend_Input = {
  template_params?: InputMaybe<Scalars['jsonb']>;
  user_params?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "mail_templates" */
export enum Mail_Templates_Select_Column {
  /** column name */
  GenerateDisableAlertsLink = 'generate_disable_alerts_link',
  /** column name */
  Subject = 'subject',
  /** column name */
  TemplateData = 'template_data',
  /** column name */
  TemplateName = 'template_name',
  /** column name */
  TemplateParams = 'template_params',
  /** column name */
  UserParams = 'user_params'
}

/** input type for updating data in table "mail_templates" */
export type Mail_Templates_Set_Input = {
  generate_disable_alerts_link?: InputMaybe<Scalars['Boolean']>;
  subject?: InputMaybe<Scalars['String']>;
  template_data?: InputMaybe<Scalars['String']>;
  template_name?: InputMaybe<Scalars['String']>;
  template_params?: InputMaybe<Scalars['jsonb']>;
  user_params?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "mail_templates" */
export type Mail_Templates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mail_Templates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mail_Templates_Stream_Cursor_Value_Input = {
  generate_disable_alerts_link?: InputMaybe<Scalars['Boolean']>;
  subject?: InputMaybe<Scalars['String']>;
  template_data?: InputMaybe<Scalars['String']>;
  template_name?: InputMaybe<Scalars['String']>;
  template_params?: InputMaybe<Scalars['jsonb']>;
  user_params?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "mail_templates" */
export enum Mail_Templates_Update_Column {
  /** column name */
  GenerateDisableAlertsLink = 'generate_disable_alerts_link',
  /** column name */
  Subject = 'subject',
  /** column name */
  TemplateData = 'template_data',
  /** column name */
  TemplateName = 'template_name',
  /** column name */
  TemplateParams = 'template_params',
  /** column name */
  UserParams = 'user_params'
}

export type Mail_Templates_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mail_Templates_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mail_Templates_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mail_Templates_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mail_Templates_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mail_Templates_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mail_Templates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mail_Templates_Bool_Exp;
};

/** columns and relationships of "mainpage_counters" */
export type Mainpage_Counters = {
  __typename?: 'mainpage_counters';
  for_show: Scalars['Boolean'];
  id: Scalars['uuid'];
  param_name: Scalars['String'];
  param_text?: Maybe<Scalars['String']>;
  param_value: Scalars['Int'];
  sort?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "mainpage_counters" */
export type Mainpage_Counters_Aggregate = {
  __typename?: 'mainpage_counters_aggregate';
  aggregate?: Maybe<Mainpage_Counters_Aggregate_Fields>;
  nodes: Array<Mainpage_Counters>;
};

/** aggregate fields of "mainpage_counters" */
export type Mainpage_Counters_Aggregate_Fields = {
  __typename?: 'mainpage_counters_aggregate_fields';
  avg?: Maybe<Mainpage_Counters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mainpage_Counters_Max_Fields>;
  min?: Maybe<Mainpage_Counters_Min_Fields>;
  stddev?: Maybe<Mainpage_Counters_Stddev_Fields>;
  stddev_pop?: Maybe<Mainpage_Counters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mainpage_Counters_Stddev_Samp_Fields>;
  sum?: Maybe<Mainpage_Counters_Sum_Fields>;
  var_pop?: Maybe<Mainpage_Counters_Var_Pop_Fields>;
  var_samp?: Maybe<Mainpage_Counters_Var_Samp_Fields>;
  variance?: Maybe<Mainpage_Counters_Variance_Fields>;
};


/** aggregate fields of "mainpage_counters" */
export type Mainpage_Counters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mainpage_Counters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Mainpage_Counters_Avg_Fields = {
  __typename?: 'mainpage_counters_avg_fields';
  param_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mainpage_counters". All fields are combined with a logical 'AND'. */
export type Mainpage_Counters_Bool_Exp = {
  _and?: InputMaybe<Array<Mainpage_Counters_Bool_Exp>>;
  _not?: InputMaybe<Mainpage_Counters_Bool_Exp>;
  _or?: InputMaybe<Array<Mainpage_Counters_Bool_Exp>>;
  for_show?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  param_name?: InputMaybe<String_Comparison_Exp>;
  param_text?: InputMaybe<String_Comparison_Exp>;
  param_value?: InputMaybe<Int_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "mainpage_counters" */
export enum Mainpage_Counters_Constraint {
  /** unique or primary key constraint on columns "id" */
  MainpageCountersPkey = 'mainpage_counters_pkey'
}

/** input type for incrementing numeric columns in table "mainpage_counters" */
export type Mainpage_Counters_Inc_Input = {
  param_value?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "mainpage_counters" */
export type Mainpage_Counters_Insert_Input = {
  for_show?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  param_name?: InputMaybe<Scalars['String']>;
  param_text?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Mainpage_Counters_Max_Fields = {
  __typename?: 'mainpage_counters_max_fields';
  id?: Maybe<Scalars['uuid']>;
  param_name?: Maybe<Scalars['String']>;
  param_text?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Mainpage_Counters_Min_Fields = {
  __typename?: 'mainpage_counters_min_fields';
  id?: Maybe<Scalars['uuid']>;
  param_name?: Maybe<Scalars['String']>;
  param_text?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "mainpage_counters" */
export type Mainpage_Counters_Mutation_Response = {
  __typename?: 'mainpage_counters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mainpage_Counters>;
};

/** on_conflict condition type for table "mainpage_counters" */
export type Mainpage_Counters_On_Conflict = {
  constraint: Mainpage_Counters_Constraint;
  update_columns?: Array<Mainpage_Counters_Update_Column>;
  where?: InputMaybe<Mainpage_Counters_Bool_Exp>;
};

/** Ordering options when selecting data from "mainpage_counters". */
export type Mainpage_Counters_Order_By = {
  for_show?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  param_name?: InputMaybe<Order_By>;
  param_text?: InputMaybe<Order_By>;
  param_value?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mainpage_counters */
export type Mainpage_Counters_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mainpage_counters" */
export enum Mainpage_Counters_Select_Column {
  /** column name */
  ForShow = 'for_show',
  /** column name */
  Id = 'id',
  /** column name */
  ParamName = 'param_name',
  /** column name */
  ParamText = 'param_text',
  /** column name */
  ParamValue = 'param_value',
  /** column name */
  Sort = 'sort'
}

/** input type for updating data in table "mainpage_counters" */
export type Mainpage_Counters_Set_Input = {
  for_show?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  param_name?: InputMaybe<Scalars['String']>;
  param_text?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Mainpage_Counters_Stddev_Fields = {
  __typename?: 'mainpage_counters_stddev_fields';
  param_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mainpage_Counters_Stddev_Pop_Fields = {
  __typename?: 'mainpage_counters_stddev_pop_fields';
  param_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mainpage_Counters_Stddev_Samp_Fields = {
  __typename?: 'mainpage_counters_stddev_samp_fields';
  param_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "mainpage_counters" */
export type Mainpage_Counters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mainpage_Counters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mainpage_Counters_Stream_Cursor_Value_Input = {
  for_show?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  param_name?: InputMaybe<Scalars['String']>;
  param_text?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Mainpage_Counters_Sum_Fields = {
  __typename?: 'mainpage_counters_sum_fields';
  param_value?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

/** update columns of table "mainpage_counters" */
export enum Mainpage_Counters_Update_Column {
  /** column name */
  ForShow = 'for_show',
  /** column name */
  Id = 'id',
  /** column name */
  ParamName = 'param_name',
  /** column name */
  ParamText = 'param_text',
  /** column name */
  ParamValue = 'param_value',
  /** column name */
  Sort = 'sort'
}

export type Mainpage_Counters_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mainpage_Counters_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mainpage_Counters_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mainpage_Counters_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mainpage_Counters_Var_Pop_Fields = {
  __typename?: 'mainpage_counters_var_pop_fields';
  param_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mainpage_Counters_Var_Samp_Fields = {
  __typename?: 'mainpage_counters_var_samp_fields';
  param_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mainpage_Counters_Variance_Fields = {
  __typename?: 'mainpage_counters_variance_fields';
  param_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "maintenance_mode" */
export type Maintenance_Mode = {
  __typename?: 'maintenance_mode';
  background_color?: Maybe<Scalars['String']>;
  is_active: Scalars['Boolean'];
  main_text_desktop: Scalars['String'];
  main_text_mobile: Scalars['String'];
  mode_description: Scalars['String'];
  mode_type: Scalars['String'];
  show_type: Scalars['Int'];
};

/** aggregated selection of "maintenance_mode" */
export type Maintenance_Mode_Aggregate = {
  __typename?: 'maintenance_mode_aggregate';
  aggregate?: Maybe<Maintenance_Mode_Aggregate_Fields>;
  nodes: Array<Maintenance_Mode>;
};

/** aggregate fields of "maintenance_mode" */
export type Maintenance_Mode_Aggregate_Fields = {
  __typename?: 'maintenance_mode_aggregate_fields';
  avg?: Maybe<Maintenance_Mode_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Maintenance_Mode_Max_Fields>;
  min?: Maybe<Maintenance_Mode_Min_Fields>;
  stddev?: Maybe<Maintenance_Mode_Stddev_Fields>;
  stddev_pop?: Maybe<Maintenance_Mode_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Maintenance_Mode_Stddev_Samp_Fields>;
  sum?: Maybe<Maintenance_Mode_Sum_Fields>;
  var_pop?: Maybe<Maintenance_Mode_Var_Pop_Fields>;
  var_samp?: Maybe<Maintenance_Mode_Var_Samp_Fields>;
  variance?: Maybe<Maintenance_Mode_Variance_Fields>;
};


/** aggregate fields of "maintenance_mode" */
export type Maintenance_Mode_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Maintenance_Mode_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Maintenance_Mode_Avg_Fields = {
  __typename?: 'maintenance_mode_avg_fields';
  show_type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "maintenance_mode". All fields are combined with a logical 'AND'. */
export type Maintenance_Mode_Bool_Exp = {
  _and?: InputMaybe<Array<Maintenance_Mode_Bool_Exp>>;
  _not?: InputMaybe<Maintenance_Mode_Bool_Exp>;
  _or?: InputMaybe<Array<Maintenance_Mode_Bool_Exp>>;
  background_color?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  main_text_desktop?: InputMaybe<String_Comparison_Exp>;
  main_text_mobile?: InputMaybe<String_Comparison_Exp>;
  mode_description?: InputMaybe<String_Comparison_Exp>;
  mode_type?: InputMaybe<String_Comparison_Exp>;
  show_type?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "maintenance_mode" */
export enum Maintenance_Mode_Constraint {
  /** unique or primary key constraint on columns "mode_type" */
  MaintenanceModePkey = 'maintenance_mode_pkey'
}

/** input type for incrementing numeric columns in table "maintenance_mode" */
export type Maintenance_Mode_Inc_Input = {
  show_type?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "maintenance_mode" */
export type Maintenance_Mode_Insert_Input = {
  background_color?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  main_text_desktop?: InputMaybe<Scalars['String']>;
  main_text_mobile?: InputMaybe<Scalars['String']>;
  mode_description?: InputMaybe<Scalars['String']>;
  mode_type?: InputMaybe<Scalars['String']>;
  show_type?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Maintenance_Mode_Max_Fields = {
  __typename?: 'maintenance_mode_max_fields';
  background_color?: Maybe<Scalars['String']>;
  main_text_desktop?: Maybe<Scalars['String']>;
  main_text_mobile?: Maybe<Scalars['String']>;
  mode_description?: Maybe<Scalars['String']>;
  mode_type?: Maybe<Scalars['String']>;
  show_type?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Maintenance_Mode_Min_Fields = {
  __typename?: 'maintenance_mode_min_fields';
  background_color?: Maybe<Scalars['String']>;
  main_text_desktop?: Maybe<Scalars['String']>;
  main_text_mobile?: Maybe<Scalars['String']>;
  mode_description?: Maybe<Scalars['String']>;
  mode_type?: Maybe<Scalars['String']>;
  show_type?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "maintenance_mode" */
export type Maintenance_Mode_Mutation_Response = {
  __typename?: 'maintenance_mode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Maintenance_Mode>;
};

/** on_conflict condition type for table "maintenance_mode" */
export type Maintenance_Mode_On_Conflict = {
  constraint: Maintenance_Mode_Constraint;
  update_columns?: Array<Maintenance_Mode_Update_Column>;
  where?: InputMaybe<Maintenance_Mode_Bool_Exp>;
};

/** Ordering options when selecting data from "maintenance_mode". */
export type Maintenance_Mode_Order_By = {
  background_color?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  main_text_desktop?: InputMaybe<Order_By>;
  main_text_mobile?: InputMaybe<Order_By>;
  mode_description?: InputMaybe<Order_By>;
  mode_type?: InputMaybe<Order_By>;
  show_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: maintenance_mode */
export type Maintenance_Mode_Pk_Columns_Input = {
  mode_type: Scalars['String'];
};

/** select columns of table "maintenance_mode" */
export enum Maintenance_Mode_Select_Column {
  /** column name */
  BackgroundColor = 'background_color',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MainTextDesktop = 'main_text_desktop',
  /** column name */
  MainTextMobile = 'main_text_mobile',
  /** column name */
  ModeDescription = 'mode_description',
  /** column name */
  ModeType = 'mode_type',
  /** column name */
  ShowType = 'show_type'
}

/** input type for updating data in table "maintenance_mode" */
export type Maintenance_Mode_Set_Input = {
  background_color?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  main_text_desktop?: InputMaybe<Scalars['String']>;
  main_text_mobile?: InputMaybe<Scalars['String']>;
  mode_description?: InputMaybe<Scalars['String']>;
  mode_type?: InputMaybe<Scalars['String']>;
  show_type?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Maintenance_Mode_Stddev_Fields = {
  __typename?: 'maintenance_mode_stddev_fields';
  show_type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Maintenance_Mode_Stddev_Pop_Fields = {
  __typename?: 'maintenance_mode_stddev_pop_fields';
  show_type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Maintenance_Mode_Stddev_Samp_Fields = {
  __typename?: 'maintenance_mode_stddev_samp_fields';
  show_type?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "maintenance_mode" */
export type Maintenance_Mode_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Maintenance_Mode_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Maintenance_Mode_Stream_Cursor_Value_Input = {
  background_color?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  main_text_desktop?: InputMaybe<Scalars['String']>;
  main_text_mobile?: InputMaybe<Scalars['String']>;
  mode_description?: InputMaybe<Scalars['String']>;
  mode_type?: InputMaybe<Scalars['String']>;
  show_type?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Maintenance_Mode_Sum_Fields = {
  __typename?: 'maintenance_mode_sum_fields';
  show_type?: Maybe<Scalars['Int']>;
};

/** update columns of table "maintenance_mode" */
export enum Maintenance_Mode_Update_Column {
  /** column name */
  BackgroundColor = 'background_color',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MainTextDesktop = 'main_text_desktop',
  /** column name */
  MainTextMobile = 'main_text_mobile',
  /** column name */
  ModeDescription = 'mode_description',
  /** column name */
  ModeType = 'mode_type',
  /** column name */
  ShowType = 'show_type'
}

export type Maintenance_Mode_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Maintenance_Mode_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Maintenance_Mode_Set_Input>;
  /** filter the rows which have to be updated */
  where: Maintenance_Mode_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Maintenance_Mode_Var_Pop_Fields = {
  __typename?: 'maintenance_mode_var_pop_fields';
  show_type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Maintenance_Mode_Var_Samp_Fields = {
  __typename?: 'maintenance_mode_var_samp_fields';
  show_type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Maintenance_Mode_Variance_Fields = {
  __typename?: 'maintenance_mode_variance_fields';
  show_type?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "market_groups_conn" */
export type Market_Groups_Conn = {
  __typename?: 'market_groups_conn';
  /** An object relationship */
  group_items_csgomarket: Group_Items_Csgomarket;
  id_group_items: Scalars['uuid'];
  item_hash_name: Scalars['String'];
  item_image_link?: Maybe<Scalars['String']>;
  item_max_count_on_market?: Maybe<Scalars['Int']>;
  item_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  items_csgomarket?: Maybe<Items_Csgomarket>;
  min_item_class_id: Scalars['bigint'];
  min_item_instance_id: Scalars['bigint'];
  min_item_price: Scalars['Float'];
  /** An object relationship */
  name_localized?: Maybe<Items_Names>;
  quality?: Maybe<Scalars['String']>;
  /** An object relationship */
  quality_localized?: Maybe<Items_Quality>;
  rarity?: Maybe<Scalars['String']>;
  /** An object relationship */
  rarity_localized?: Maybe<Items_Rarity>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "market_groups_conn" */
export type Market_Groups_Conn_Aggregate = {
  __typename?: 'market_groups_conn_aggregate';
  aggregate?: Maybe<Market_Groups_Conn_Aggregate_Fields>;
  nodes: Array<Market_Groups_Conn>;
};

export type Market_Groups_Conn_Aggregate_Bool_Exp = {
  count?: InputMaybe<Market_Groups_Conn_Aggregate_Bool_Exp_Count>;
};

export type Market_Groups_Conn_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Market_Groups_Conn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Market_Groups_Conn_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "market_groups_conn" */
export type Market_Groups_Conn_Aggregate_Fields = {
  __typename?: 'market_groups_conn_aggregate_fields';
  avg?: Maybe<Market_Groups_Conn_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Market_Groups_Conn_Max_Fields>;
  min?: Maybe<Market_Groups_Conn_Min_Fields>;
  stddev?: Maybe<Market_Groups_Conn_Stddev_Fields>;
  stddev_pop?: Maybe<Market_Groups_Conn_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Market_Groups_Conn_Stddev_Samp_Fields>;
  sum?: Maybe<Market_Groups_Conn_Sum_Fields>;
  var_pop?: Maybe<Market_Groups_Conn_Var_Pop_Fields>;
  var_samp?: Maybe<Market_Groups_Conn_Var_Samp_Fields>;
  variance?: Maybe<Market_Groups_Conn_Variance_Fields>;
};


/** aggregate fields of "market_groups_conn" */
export type Market_Groups_Conn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Market_Groups_Conn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "market_groups_conn" */
export type Market_Groups_Conn_Aggregate_Order_By = {
  avg?: InputMaybe<Market_Groups_Conn_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Market_Groups_Conn_Max_Order_By>;
  min?: InputMaybe<Market_Groups_Conn_Min_Order_By>;
  stddev?: InputMaybe<Market_Groups_Conn_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Market_Groups_Conn_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Market_Groups_Conn_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Market_Groups_Conn_Sum_Order_By>;
  var_pop?: InputMaybe<Market_Groups_Conn_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Market_Groups_Conn_Var_Samp_Order_By>;
  variance?: InputMaybe<Market_Groups_Conn_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "market_groups_conn" */
export type Market_Groups_Conn_Arr_Rel_Insert_Input = {
  data: Array<Market_Groups_Conn_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Market_Groups_Conn_On_Conflict>;
};

/** aggregate avg on columns */
export type Market_Groups_Conn_Avg_Fields = {
  __typename?: 'market_groups_conn_avg_fields';
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  min_item_class_id?: Maybe<Scalars['Float']>;
  min_item_instance_id?: Maybe<Scalars['Float']>;
  min_item_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "market_groups_conn" */
export type Market_Groups_Conn_Avg_Order_By = {
  item_max_count_on_market?: InputMaybe<Order_By>;
  min_item_class_id?: InputMaybe<Order_By>;
  min_item_instance_id?: InputMaybe<Order_By>;
  min_item_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "market_groups_conn". All fields are combined with a logical 'AND'. */
export type Market_Groups_Conn_Bool_Exp = {
  _and?: InputMaybe<Array<Market_Groups_Conn_Bool_Exp>>;
  _not?: InputMaybe<Market_Groups_Conn_Bool_Exp>;
  _or?: InputMaybe<Array<Market_Groups_Conn_Bool_Exp>>;
  group_items_csgomarket?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
  id_group_items?: InputMaybe<Uuid_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_image_link?: InputMaybe<String_Comparison_Exp>;
  item_max_count_on_market?: InputMaybe<Int_Comparison_Exp>;
  item_type?: InputMaybe<String_Comparison_Exp>;
  items_csgomarket?: InputMaybe<Items_Csgomarket_Bool_Exp>;
  min_item_class_id?: InputMaybe<Bigint_Comparison_Exp>;
  min_item_instance_id?: InputMaybe<Bigint_Comparison_Exp>;
  min_item_price?: InputMaybe<Float_Comparison_Exp>;
  name_localized?: InputMaybe<Items_Names_Bool_Exp>;
  quality?: InputMaybe<String_Comparison_Exp>;
  quality_localized?: InputMaybe<Items_Quality_Bool_Exp>;
  rarity?: InputMaybe<String_Comparison_Exp>;
  rarity_localized?: InputMaybe<Items_Rarity_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "market_groups_conn" */
export enum Market_Groups_Conn_Constraint {
  /** unique or primary key constraint on columns "item_hash_name" */
  MarketGroupsConnPkey = 'market_groups_conn_pkey'
}

/** input type for incrementing numeric columns in table "market_groups_conn" */
export type Market_Groups_Conn_Inc_Input = {
  item_max_count_on_market?: InputMaybe<Scalars['Int']>;
  min_item_class_id?: InputMaybe<Scalars['bigint']>;
  min_item_instance_id?: InputMaybe<Scalars['bigint']>;
  min_item_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "market_groups_conn" */
export type Market_Groups_Conn_Insert_Input = {
  group_items_csgomarket?: InputMaybe<Group_Items_Csgomarket_Obj_Rel_Insert_Input>;
  id_group_items?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_count_on_market?: InputMaybe<Scalars['Int']>;
  item_type?: InputMaybe<Scalars['String']>;
  items_csgomarket?: InputMaybe<Items_Csgomarket_Obj_Rel_Insert_Input>;
  min_item_class_id?: InputMaybe<Scalars['bigint']>;
  min_item_instance_id?: InputMaybe<Scalars['bigint']>;
  min_item_price?: InputMaybe<Scalars['Float']>;
  name_localized?: InputMaybe<Items_Names_Obj_Rel_Insert_Input>;
  quality?: InputMaybe<Scalars['String']>;
  quality_localized?: InputMaybe<Items_Quality_Obj_Rel_Insert_Input>;
  rarity?: InputMaybe<Scalars['String']>;
  rarity_localized?: InputMaybe<Items_Rarity_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Market_Groups_Conn_Max_Fields = {
  __typename?: 'market_groups_conn_max_fields';
  id_group_items?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_max_count_on_market?: Maybe<Scalars['Int']>;
  item_type?: Maybe<Scalars['String']>;
  min_item_class_id?: Maybe<Scalars['bigint']>;
  min_item_instance_id?: Maybe<Scalars['bigint']>;
  min_item_price?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['String']>;
  rarity?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "market_groups_conn" */
export type Market_Groups_Conn_Max_Order_By = {
  id_group_items?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_count_on_market?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  min_item_class_id?: InputMaybe<Order_By>;
  min_item_instance_id?: InputMaybe<Order_By>;
  min_item_price?: InputMaybe<Order_By>;
  quality?: InputMaybe<Order_By>;
  rarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Market_Groups_Conn_Min_Fields = {
  __typename?: 'market_groups_conn_min_fields';
  id_group_items?: Maybe<Scalars['uuid']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_image_link?: Maybe<Scalars['String']>;
  item_max_count_on_market?: Maybe<Scalars['Int']>;
  item_type?: Maybe<Scalars['String']>;
  min_item_class_id?: Maybe<Scalars['bigint']>;
  min_item_instance_id?: Maybe<Scalars['bigint']>;
  min_item_price?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['String']>;
  rarity?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "market_groups_conn" */
export type Market_Groups_Conn_Min_Order_By = {
  id_group_items?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_count_on_market?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  min_item_class_id?: InputMaybe<Order_By>;
  min_item_instance_id?: InputMaybe<Order_By>;
  min_item_price?: InputMaybe<Order_By>;
  quality?: InputMaybe<Order_By>;
  rarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "market_groups_conn" */
export type Market_Groups_Conn_Mutation_Response = {
  __typename?: 'market_groups_conn_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Market_Groups_Conn>;
};

/** on_conflict condition type for table "market_groups_conn" */
export type Market_Groups_Conn_On_Conflict = {
  constraint: Market_Groups_Conn_Constraint;
  update_columns?: Array<Market_Groups_Conn_Update_Column>;
  where?: InputMaybe<Market_Groups_Conn_Bool_Exp>;
};

/** Ordering options when selecting data from "market_groups_conn". */
export type Market_Groups_Conn_Order_By = {
  group_items_csgomarket?: InputMaybe<Group_Items_Csgomarket_Order_By>;
  id_group_items?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_max_count_on_market?: InputMaybe<Order_By>;
  item_type?: InputMaybe<Order_By>;
  items_csgomarket?: InputMaybe<Items_Csgomarket_Order_By>;
  min_item_class_id?: InputMaybe<Order_By>;
  min_item_instance_id?: InputMaybe<Order_By>;
  min_item_price?: InputMaybe<Order_By>;
  name_localized?: InputMaybe<Items_Names_Order_By>;
  quality?: InputMaybe<Order_By>;
  quality_localized?: InputMaybe<Items_Quality_Order_By>;
  rarity?: InputMaybe<Order_By>;
  rarity_localized?: InputMaybe<Items_Rarity_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: market_groups_conn */
export type Market_Groups_Conn_Pk_Columns_Input = {
  item_hash_name: Scalars['String'];
};

/** select columns of table "market_groups_conn" */
export enum Market_Groups_Conn_Select_Column {
  /** column name */
  IdGroupItems = 'id_group_items',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemMaxCountOnMarket = 'item_max_count_on_market',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  MinItemClassId = 'min_item_class_id',
  /** column name */
  MinItemInstanceId = 'min_item_instance_id',
  /** column name */
  MinItemPrice = 'min_item_price',
  /** column name */
  Quality = 'quality',
  /** column name */
  Rarity = 'rarity',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "market_groups_conn" */
export type Market_Groups_Conn_Set_Input = {
  id_group_items?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_count_on_market?: InputMaybe<Scalars['Int']>;
  item_type?: InputMaybe<Scalars['String']>;
  min_item_class_id?: InputMaybe<Scalars['bigint']>;
  min_item_instance_id?: InputMaybe<Scalars['bigint']>;
  min_item_price?: InputMaybe<Scalars['Float']>;
  quality?: InputMaybe<Scalars['String']>;
  rarity?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Market_Groups_Conn_Stddev_Fields = {
  __typename?: 'market_groups_conn_stddev_fields';
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  min_item_class_id?: Maybe<Scalars['Float']>;
  min_item_instance_id?: Maybe<Scalars['Float']>;
  min_item_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "market_groups_conn" */
export type Market_Groups_Conn_Stddev_Order_By = {
  item_max_count_on_market?: InputMaybe<Order_By>;
  min_item_class_id?: InputMaybe<Order_By>;
  min_item_instance_id?: InputMaybe<Order_By>;
  min_item_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Market_Groups_Conn_Stddev_Pop_Fields = {
  __typename?: 'market_groups_conn_stddev_pop_fields';
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  min_item_class_id?: Maybe<Scalars['Float']>;
  min_item_instance_id?: Maybe<Scalars['Float']>;
  min_item_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "market_groups_conn" */
export type Market_Groups_Conn_Stddev_Pop_Order_By = {
  item_max_count_on_market?: InputMaybe<Order_By>;
  min_item_class_id?: InputMaybe<Order_By>;
  min_item_instance_id?: InputMaybe<Order_By>;
  min_item_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Market_Groups_Conn_Stddev_Samp_Fields = {
  __typename?: 'market_groups_conn_stddev_samp_fields';
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  min_item_class_id?: Maybe<Scalars['Float']>;
  min_item_instance_id?: Maybe<Scalars['Float']>;
  min_item_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "market_groups_conn" */
export type Market_Groups_Conn_Stddev_Samp_Order_By = {
  item_max_count_on_market?: InputMaybe<Order_By>;
  min_item_class_id?: InputMaybe<Order_By>;
  min_item_instance_id?: InputMaybe<Order_By>;
  min_item_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "market_groups_conn" */
export type Market_Groups_Conn_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Market_Groups_Conn_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Market_Groups_Conn_Stream_Cursor_Value_Input = {
  id_group_items?: InputMaybe<Scalars['uuid']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_max_count_on_market?: InputMaybe<Scalars['Int']>;
  item_type?: InputMaybe<Scalars['String']>;
  min_item_class_id?: InputMaybe<Scalars['bigint']>;
  min_item_instance_id?: InputMaybe<Scalars['bigint']>;
  min_item_price?: InputMaybe<Scalars['Float']>;
  quality?: InputMaybe<Scalars['String']>;
  rarity?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Market_Groups_Conn_Sum_Fields = {
  __typename?: 'market_groups_conn_sum_fields';
  item_max_count_on_market?: Maybe<Scalars['Int']>;
  min_item_class_id?: Maybe<Scalars['bigint']>;
  min_item_instance_id?: Maybe<Scalars['bigint']>;
  min_item_price?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "market_groups_conn" */
export type Market_Groups_Conn_Sum_Order_By = {
  item_max_count_on_market?: InputMaybe<Order_By>;
  min_item_class_id?: InputMaybe<Order_By>;
  min_item_instance_id?: InputMaybe<Order_By>;
  min_item_price?: InputMaybe<Order_By>;
};

/** update columns of table "market_groups_conn" */
export enum Market_Groups_Conn_Update_Column {
  /** column name */
  IdGroupItems = 'id_group_items',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemMaxCountOnMarket = 'item_max_count_on_market',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  MinItemClassId = 'min_item_class_id',
  /** column name */
  MinItemInstanceId = 'min_item_instance_id',
  /** column name */
  MinItemPrice = 'min_item_price',
  /** column name */
  Quality = 'quality',
  /** column name */
  Rarity = 'rarity',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Market_Groups_Conn_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Market_Groups_Conn_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Market_Groups_Conn_Set_Input>;
  /** filter the rows which have to be updated */
  where: Market_Groups_Conn_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Market_Groups_Conn_Var_Pop_Fields = {
  __typename?: 'market_groups_conn_var_pop_fields';
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  min_item_class_id?: Maybe<Scalars['Float']>;
  min_item_instance_id?: Maybe<Scalars['Float']>;
  min_item_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "market_groups_conn" */
export type Market_Groups_Conn_Var_Pop_Order_By = {
  item_max_count_on_market?: InputMaybe<Order_By>;
  min_item_class_id?: InputMaybe<Order_By>;
  min_item_instance_id?: InputMaybe<Order_By>;
  min_item_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Market_Groups_Conn_Var_Samp_Fields = {
  __typename?: 'market_groups_conn_var_samp_fields';
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  min_item_class_id?: Maybe<Scalars['Float']>;
  min_item_instance_id?: Maybe<Scalars['Float']>;
  min_item_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "market_groups_conn" */
export type Market_Groups_Conn_Var_Samp_Order_By = {
  item_max_count_on_market?: InputMaybe<Order_By>;
  min_item_class_id?: InputMaybe<Order_By>;
  min_item_instance_id?: InputMaybe<Order_By>;
  min_item_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Market_Groups_Conn_Variance_Fields = {
  __typename?: 'market_groups_conn_variance_fields';
  item_max_count_on_market?: Maybe<Scalars['Float']>;
  min_item_class_id?: Maybe<Scalars['Float']>;
  min_item_instance_id?: Maybe<Scalars['Float']>;
  min_item_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "market_groups_conn" */
export type Market_Groups_Conn_Variance_Order_By = {
  item_max_count_on_market?: InputMaybe<Order_By>;
  min_item_class_id?: InputMaybe<Order_By>;
  min_item_instance_id?: InputMaybe<Order_By>;
  min_item_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "metas" */
export type Metas = {
  __typename?: 'metas';
  meta_global_main_key: Scalars['String'];
  meta_global_sub_key: Scalars['String'];
  meta_key: Scalars['String'];
  meta_value_json?: Maybe<Scalars['jsonb']>;
  meta_value_text?: Maybe<Scalars['String']>;
};


/** columns and relationships of "metas" */
export type MetasMeta_Value_JsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "metas" */
export type Metas_Aggregate = {
  __typename?: 'metas_aggregate';
  aggregate?: Maybe<Metas_Aggregate_Fields>;
  nodes: Array<Metas>;
};

/** aggregate fields of "metas" */
export type Metas_Aggregate_Fields = {
  __typename?: 'metas_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Metas_Max_Fields>;
  min?: Maybe<Metas_Min_Fields>;
};


/** aggregate fields of "metas" */
export type Metas_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metas_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Metas_Append_Input = {
  meta_value_json?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "metas". All fields are combined with a logical 'AND'. */
export type Metas_Bool_Exp = {
  _and?: InputMaybe<Array<Metas_Bool_Exp>>;
  _not?: InputMaybe<Metas_Bool_Exp>;
  _or?: InputMaybe<Array<Metas_Bool_Exp>>;
  meta_global_main_key?: InputMaybe<String_Comparison_Exp>;
  meta_global_sub_key?: InputMaybe<String_Comparison_Exp>;
  meta_key?: InputMaybe<String_Comparison_Exp>;
  meta_value_json?: InputMaybe<Jsonb_Comparison_Exp>;
  meta_value_text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "metas" */
export enum Metas_Constraint {
  /** unique or primary key constraint on columns "meta_global_main_key", "meta_global_sub_key", "meta_key" */
  MetasPkey = 'metas_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Metas_Delete_At_Path_Input = {
  meta_value_json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Metas_Delete_Elem_Input = {
  meta_value_json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Metas_Delete_Key_Input = {
  meta_value_json?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "metas" */
export type Metas_Insert_Input = {
  meta_global_main_key?: InputMaybe<Scalars['String']>;
  meta_global_sub_key?: InputMaybe<Scalars['String']>;
  meta_key?: InputMaybe<Scalars['String']>;
  meta_value_json?: InputMaybe<Scalars['jsonb']>;
  meta_value_text?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Metas_Max_Fields = {
  __typename?: 'metas_max_fields';
  meta_global_main_key?: Maybe<Scalars['String']>;
  meta_global_sub_key?: Maybe<Scalars['String']>;
  meta_key?: Maybe<Scalars['String']>;
  meta_value_text?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Metas_Min_Fields = {
  __typename?: 'metas_min_fields';
  meta_global_main_key?: Maybe<Scalars['String']>;
  meta_global_sub_key?: Maybe<Scalars['String']>;
  meta_key?: Maybe<Scalars['String']>;
  meta_value_text?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "metas" */
export type Metas_Mutation_Response = {
  __typename?: 'metas_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Metas>;
};

/** on_conflict condition type for table "metas" */
export type Metas_On_Conflict = {
  constraint: Metas_Constraint;
  update_columns?: Array<Metas_Update_Column>;
  where?: InputMaybe<Metas_Bool_Exp>;
};

/** Ordering options when selecting data from "metas". */
export type Metas_Order_By = {
  meta_global_main_key?: InputMaybe<Order_By>;
  meta_global_sub_key?: InputMaybe<Order_By>;
  meta_key?: InputMaybe<Order_By>;
  meta_value_json?: InputMaybe<Order_By>;
  meta_value_text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: metas */
export type Metas_Pk_Columns_Input = {
  meta_global_main_key: Scalars['String'];
  meta_global_sub_key: Scalars['String'];
  meta_key: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Metas_Prepend_Input = {
  meta_value_json?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "metas" */
export enum Metas_Select_Column {
  /** column name */
  MetaGlobalMainKey = 'meta_global_main_key',
  /** column name */
  MetaGlobalSubKey = 'meta_global_sub_key',
  /** column name */
  MetaKey = 'meta_key',
  /** column name */
  MetaValueJson = 'meta_value_json',
  /** column name */
  MetaValueText = 'meta_value_text'
}

/** input type for updating data in table "metas" */
export type Metas_Set_Input = {
  meta_global_main_key?: InputMaybe<Scalars['String']>;
  meta_global_sub_key?: InputMaybe<Scalars['String']>;
  meta_key?: InputMaybe<Scalars['String']>;
  meta_value_json?: InputMaybe<Scalars['jsonb']>;
  meta_value_text?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "metas" */
export type Metas_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metas_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Metas_Stream_Cursor_Value_Input = {
  meta_global_main_key?: InputMaybe<Scalars['String']>;
  meta_global_sub_key?: InputMaybe<Scalars['String']>;
  meta_key?: InputMaybe<Scalars['String']>;
  meta_value_json?: InputMaybe<Scalars['jsonb']>;
  meta_value_text?: InputMaybe<Scalars['String']>;
};

/** update columns of table "metas" */
export enum Metas_Update_Column {
  /** column name */
  MetaGlobalMainKey = 'meta_global_main_key',
  /** column name */
  MetaGlobalSubKey = 'meta_global_sub_key',
  /** column name */
  MetaKey = 'meta_key',
  /** column name */
  MetaValueJson = 'meta_value_json',
  /** column name */
  MetaValueText = 'meta_value_text'
}

export type Metas_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Metas_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Metas_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Metas_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Metas_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Metas_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Metas_Set_Input>;
  /** filter the rows which have to be updated */
  where: Metas_Bool_Exp;
};

/** columns and relationships of "missions" */
export type Missions = {
  __typename?: 'missions';
  complete_link?: Maybe<Scalars['String']>;
  complete_link_params?: Maybe<Scalars['jsonb']>;
  complete_text?: Maybe<Scalars['String']>;
  is_active: Scalars['Boolean'];
  mission_id: Scalars['uuid'];
  mission_image?: Maybe<Scalars['String']>;
  mission_slug?: Maybe<Scalars['String']>;
  mission_text: Scalars['String'];
  mission_title: Scalars['String'];
  mission_type: Scalars['Int'];
  mission_value: Scalars['bigint'];
  /** An object relationship */
  missions_onetime_params?: Maybe<Missions_Onetime_Params>;
  /** An array relationship */
  missions_onetime_users_progress: Array<Missions_Onetime_Users_Progress>;
  /** An aggregate relationship */
  missions_onetime_users_progress_aggregate: Missions_Onetime_Users_Progress_Aggregate;
  /** An object relationship */
  missions_permanent_params?: Maybe<Missions_Permanent_Params>;
  /** An array relationship */
  missions_permanent_users_progress: Array<Missions_Permanent_User_Progress>;
  /** An aggregate relationship */
  missions_permanent_users_progress_aggregate: Missions_Permanent_User_Progress_Aggregate;
  /** An array relationship */
  missions_tags: Array<Missions_Tags>;
  /** An aggregate relationship */
  missions_tags_aggregate: Missions_Tags_Aggregate;
  sort: Scalars['Int'];
};


/** columns and relationships of "missions" */
export type MissionsComplete_Link_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "missions" */
export type MissionsMissions_Onetime_Users_ProgressArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Users_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Users_Progress_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
};


/** columns and relationships of "missions" */
export type MissionsMissions_Onetime_Users_Progress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Users_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Users_Progress_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
};


/** columns and relationships of "missions" */
export type MissionsMissions_Permanent_Users_ProgressArgs = {
  distinct_on?: InputMaybe<Array<Missions_Permanent_User_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Permanent_User_Progress_Order_By>>;
  where?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
};


/** columns and relationships of "missions" */
export type MissionsMissions_Permanent_Users_Progress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Permanent_User_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Permanent_User_Progress_Order_By>>;
  where?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
};


/** columns and relationships of "missions" */
export type MissionsMissions_TagsArgs = {
  distinct_on?: InputMaybe<Array<Missions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Tags_Order_By>>;
  where?: InputMaybe<Missions_Tags_Bool_Exp>;
};


/** columns and relationships of "missions" */
export type MissionsMissions_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Tags_Order_By>>;
  where?: InputMaybe<Missions_Tags_Bool_Exp>;
};

/** aggregated selection of "missions" */
export type Missions_Aggregate = {
  __typename?: 'missions_aggregate';
  aggregate?: Maybe<Missions_Aggregate_Fields>;
  nodes: Array<Missions>;
};

/** aggregate fields of "missions" */
export type Missions_Aggregate_Fields = {
  __typename?: 'missions_aggregate_fields';
  avg?: Maybe<Missions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Missions_Max_Fields>;
  min?: Maybe<Missions_Min_Fields>;
  stddev?: Maybe<Missions_Stddev_Fields>;
  stddev_pop?: Maybe<Missions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Missions_Stddev_Samp_Fields>;
  sum?: Maybe<Missions_Sum_Fields>;
  var_pop?: Maybe<Missions_Var_Pop_Fields>;
  var_samp?: Maybe<Missions_Var_Samp_Fields>;
  variance?: Maybe<Missions_Variance_Fields>;
};


/** aggregate fields of "missions" */
export type Missions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Missions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Missions_Append_Input = {
  complete_link_params?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Missions_Avg_Fields = {
  __typename?: 'missions_avg_fields';
  mission_type?: Maybe<Scalars['Float']>;
  mission_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "missions". All fields are combined with a logical 'AND'. */
export type Missions_Bool_Exp = {
  _and?: InputMaybe<Array<Missions_Bool_Exp>>;
  _not?: InputMaybe<Missions_Bool_Exp>;
  _or?: InputMaybe<Array<Missions_Bool_Exp>>;
  complete_link?: InputMaybe<String_Comparison_Exp>;
  complete_link_params?: InputMaybe<Jsonb_Comparison_Exp>;
  complete_text?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  mission_id?: InputMaybe<Uuid_Comparison_Exp>;
  mission_image?: InputMaybe<String_Comparison_Exp>;
  mission_slug?: InputMaybe<String_Comparison_Exp>;
  mission_text?: InputMaybe<String_Comparison_Exp>;
  mission_title?: InputMaybe<String_Comparison_Exp>;
  mission_type?: InputMaybe<Int_Comparison_Exp>;
  mission_value?: InputMaybe<Bigint_Comparison_Exp>;
  missions_onetime_params?: InputMaybe<Missions_Onetime_Params_Bool_Exp>;
  missions_onetime_users_progress?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
  missions_onetime_users_progress_aggregate?: InputMaybe<Missions_Onetime_Users_Progress_Aggregate_Bool_Exp>;
  missions_permanent_params?: InputMaybe<Missions_Permanent_Params_Bool_Exp>;
  missions_permanent_users_progress?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
  missions_permanent_users_progress_aggregate?: InputMaybe<Missions_Permanent_User_Progress_Aggregate_Bool_Exp>;
  missions_tags?: InputMaybe<Missions_Tags_Bool_Exp>;
  missions_tags_aggregate?: InputMaybe<Missions_Tags_Aggregate_Bool_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "missions" */
export enum Missions_Constraint {
  /** unique or primary key constraint on columns "mission_id" */
  MissionsPkey = 'missions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Missions_Delete_At_Path_Input = {
  complete_link_params?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Missions_Delete_Elem_Input = {
  complete_link_params?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Missions_Delete_Key_Input = {
  complete_link_params?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "missions" */
export type Missions_Inc_Input = {
  mission_type?: InputMaybe<Scalars['Int']>;
  mission_value?: InputMaybe<Scalars['bigint']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "missions" */
export type Missions_Insert_Input = {
  complete_link?: InputMaybe<Scalars['String']>;
  complete_link_params?: InputMaybe<Scalars['jsonb']>;
  complete_text?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  mission_image?: InputMaybe<Scalars['String']>;
  mission_slug?: InputMaybe<Scalars['String']>;
  mission_text?: InputMaybe<Scalars['String']>;
  mission_title?: InputMaybe<Scalars['String']>;
  mission_type?: InputMaybe<Scalars['Int']>;
  mission_value?: InputMaybe<Scalars['bigint']>;
  missions_onetime_params?: InputMaybe<Missions_Onetime_Params_Obj_Rel_Insert_Input>;
  missions_onetime_users_progress?: InputMaybe<Missions_Onetime_Users_Progress_Arr_Rel_Insert_Input>;
  missions_permanent_params?: InputMaybe<Missions_Permanent_Params_Obj_Rel_Insert_Input>;
  missions_permanent_users_progress?: InputMaybe<Missions_Permanent_User_Progress_Arr_Rel_Insert_Input>;
  missions_tags?: InputMaybe<Missions_Tags_Arr_Rel_Insert_Input>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Missions_Max_Fields = {
  __typename?: 'missions_max_fields';
  complete_link?: Maybe<Scalars['String']>;
  complete_text?: Maybe<Scalars['String']>;
  mission_id?: Maybe<Scalars['uuid']>;
  mission_image?: Maybe<Scalars['String']>;
  mission_slug?: Maybe<Scalars['String']>;
  mission_text?: Maybe<Scalars['String']>;
  mission_title?: Maybe<Scalars['String']>;
  mission_type?: Maybe<Scalars['Int']>;
  mission_value?: Maybe<Scalars['bigint']>;
  sort?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Missions_Min_Fields = {
  __typename?: 'missions_min_fields';
  complete_link?: Maybe<Scalars['String']>;
  complete_text?: Maybe<Scalars['String']>;
  mission_id?: Maybe<Scalars['uuid']>;
  mission_image?: Maybe<Scalars['String']>;
  mission_slug?: Maybe<Scalars['String']>;
  mission_text?: Maybe<Scalars['String']>;
  mission_title?: Maybe<Scalars['String']>;
  mission_type?: Maybe<Scalars['Int']>;
  mission_value?: Maybe<Scalars['bigint']>;
  sort?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "missions" */
export type Missions_Mutation_Response = {
  __typename?: 'missions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Missions>;
};

/** input type for inserting object relation for remote table "missions" */
export type Missions_Obj_Rel_Insert_Input = {
  data: Missions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Missions_On_Conflict>;
};

/** on_conflict condition type for table "missions" */
export type Missions_On_Conflict = {
  constraint: Missions_Constraint;
  update_columns?: Array<Missions_Update_Column>;
  where?: InputMaybe<Missions_Bool_Exp>;
};

/** columns and relationships of "missions_onetime_bonus_case_claims" */
export type Missions_Onetime_Bonus_Case_Claims = {
  __typename?: 'missions_onetime_bonus_case_claims';
  created_at: Scalars['timestamp'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "missions_onetime_bonus_case_claims" */
export type Missions_Onetime_Bonus_Case_Claims_Aggregate = {
  __typename?: 'missions_onetime_bonus_case_claims_aggregate';
  aggregate?: Maybe<Missions_Onetime_Bonus_Case_Claims_Aggregate_Fields>;
  nodes: Array<Missions_Onetime_Bonus_Case_Claims>;
};

/** aggregate fields of "missions_onetime_bonus_case_claims" */
export type Missions_Onetime_Bonus_Case_Claims_Aggregate_Fields = {
  __typename?: 'missions_onetime_bonus_case_claims_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Missions_Onetime_Bonus_Case_Claims_Max_Fields>;
  min?: Maybe<Missions_Onetime_Bonus_Case_Claims_Min_Fields>;
};


/** aggregate fields of "missions_onetime_bonus_case_claims" */
export type Missions_Onetime_Bonus_Case_Claims_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Missions_Onetime_Bonus_Case_Claims_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "missions_onetime_bonus_case_claims". All fields are combined with a logical 'AND'. */
export type Missions_Onetime_Bonus_Case_Claims_Bool_Exp = {
  _and?: InputMaybe<Array<Missions_Onetime_Bonus_Case_Claims_Bool_Exp>>;
  _not?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_Bool_Exp>;
  _or?: InputMaybe<Array<Missions_Onetime_Bonus_Case_Claims_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "missions_onetime_bonus_case_claims" */
export enum Missions_Onetime_Bonus_Case_Claims_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  MissionsOnetimeBonusCaseClaimsPkey = 'missions_onetime_bonus_case_claims_pkey'
}

/** input type for inserting data into table "missions_onetime_bonus_case_claims" */
export type Missions_Onetime_Bonus_Case_Claims_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Missions_Onetime_Bonus_Case_Claims_Max_Fields = {
  __typename?: 'missions_onetime_bonus_case_claims_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Missions_Onetime_Bonus_Case_Claims_Min_Fields = {
  __typename?: 'missions_onetime_bonus_case_claims_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "missions_onetime_bonus_case_claims" */
export type Missions_Onetime_Bonus_Case_Claims_Mutation_Response = {
  __typename?: 'missions_onetime_bonus_case_claims_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Missions_Onetime_Bonus_Case_Claims>;
};

/** on_conflict condition type for table "missions_onetime_bonus_case_claims" */
export type Missions_Onetime_Bonus_Case_Claims_On_Conflict = {
  constraint: Missions_Onetime_Bonus_Case_Claims_Constraint;
  update_columns?: Array<Missions_Onetime_Bonus_Case_Claims_Update_Column>;
  where?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_Bool_Exp>;
};

/** Ordering options when selecting data from "missions_onetime_bonus_case_claims". */
export type Missions_Onetime_Bonus_Case_Claims_Order_By = {
  created_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: missions_onetime_bonus_case_claims */
export type Missions_Onetime_Bonus_Case_Claims_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "missions_onetime_bonus_case_claims" */
export enum Missions_Onetime_Bonus_Case_Claims_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "missions_onetime_bonus_case_claims" */
export type Missions_Onetime_Bonus_Case_Claims_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "missions_onetime_bonus_case_claims" */
export type Missions_Onetime_Bonus_Case_Claims_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Missions_Onetime_Bonus_Case_Claims_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Missions_Onetime_Bonus_Case_Claims_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "missions_onetime_bonus_case_claims" */
export enum Missions_Onetime_Bonus_Case_Claims_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UserId = 'user_id'
}

export type Missions_Onetime_Bonus_Case_Claims_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_Set_Input>;
  /** filter the rows which have to be updated */
  where: Missions_Onetime_Bonus_Case_Claims_Bool_Exp;
};

/** columns and relationships of "missions_onetime_params" */
export type Missions_Onetime_Params = {
  __typename?: 'missions_onetime_params';
  /** An object relationship */
  mission: Missions;
  mission_complete_by_click: Scalars['Boolean'];
  mission_complete_param_count: Scalars['Int'];
  mission_event_params?: Maybe<Scalars['jsonb']>;
  mission_event_subtype?: Maybe<Scalars['String']>;
  mission_event_type?: Maybe<Scalars['String']>;
  mission_id: Scalars['uuid'];
};


/** columns and relationships of "missions_onetime_params" */
export type Missions_Onetime_ParamsMission_Event_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "missions_onetime_params" */
export type Missions_Onetime_Params_Aggregate = {
  __typename?: 'missions_onetime_params_aggregate';
  aggregate?: Maybe<Missions_Onetime_Params_Aggregate_Fields>;
  nodes: Array<Missions_Onetime_Params>;
};

/** aggregate fields of "missions_onetime_params" */
export type Missions_Onetime_Params_Aggregate_Fields = {
  __typename?: 'missions_onetime_params_aggregate_fields';
  avg?: Maybe<Missions_Onetime_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Missions_Onetime_Params_Max_Fields>;
  min?: Maybe<Missions_Onetime_Params_Min_Fields>;
  stddev?: Maybe<Missions_Onetime_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Missions_Onetime_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Missions_Onetime_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Missions_Onetime_Params_Sum_Fields>;
  var_pop?: Maybe<Missions_Onetime_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Missions_Onetime_Params_Var_Samp_Fields>;
  variance?: Maybe<Missions_Onetime_Params_Variance_Fields>;
};


/** aggregate fields of "missions_onetime_params" */
export type Missions_Onetime_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Missions_Onetime_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Missions_Onetime_Params_Append_Input = {
  mission_event_params?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Missions_Onetime_Params_Avg_Fields = {
  __typename?: 'missions_onetime_params_avg_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "missions_onetime_params". All fields are combined with a logical 'AND'. */
export type Missions_Onetime_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Missions_Onetime_Params_Bool_Exp>>;
  _not?: InputMaybe<Missions_Onetime_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Missions_Onetime_Params_Bool_Exp>>;
  mission?: InputMaybe<Missions_Bool_Exp>;
  mission_complete_by_click?: InputMaybe<Boolean_Comparison_Exp>;
  mission_complete_param_count?: InputMaybe<Int_Comparison_Exp>;
  mission_event_params?: InputMaybe<Jsonb_Comparison_Exp>;
  mission_event_subtype?: InputMaybe<String_Comparison_Exp>;
  mission_event_type?: InputMaybe<String_Comparison_Exp>;
  mission_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "missions_onetime_params" */
export enum Missions_Onetime_Params_Constraint {
  /** unique or primary key constraint on columns "mission_id" */
  MissionsOnetimeParamsPkey = 'missions_onetime_params_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Missions_Onetime_Params_Delete_At_Path_Input = {
  mission_event_params?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Missions_Onetime_Params_Delete_Elem_Input = {
  mission_event_params?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Missions_Onetime_Params_Delete_Key_Input = {
  mission_event_params?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "missions_onetime_params" */
export type Missions_Onetime_Params_Inc_Input = {
  mission_complete_param_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "missions_onetime_params" */
export type Missions_Onetime_Params_Insert_Input = {
  mission?: InputMaybe<Missions_Obj_Rel_Insert_Input>;
  mission_complete_by_click?: InputMaybe<Scalars['Boolean']>;
  mission_complete_param_count?: InputMaybe<Scalars['Int']>;
  mission_event_params?: InputMaybe<Scalars['jsonb']>;
  mission_event_subtype?: InputMaybe<Scalars['String']>;
  mission_event_type?: InputMaybe<Scalars['String']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Missions_Onetime_Params_Max_Fields = {
  __typename?: 'missions_onetime_params_max_fields';
  mission_complete_param_count?: Maybe<Scalars['Int']>;
  mission_event_subtype?: Maybe<Scalars['String']>;
  mission_event_type?: Maybe<Scalars['String']>;
  mission_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Missions_Onetime_Params_Min_Fields = {
  __typename?: 'missions_onetime_params_min_fields';
  mission_complete_param_count?: Maybe<Scalars['Int']>;
  mission_event_subtype?: Maybe<Scalars['String']>;
  mission_event_type?: Maybe<Scalars['String']>;
  mission_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "missions_onetime_params" */
export type Missions_Onetime_Params_Mutation_Response = {
  __typename?: 'missions_onetime_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Missions_Onetime_Params>;
};

/** input type for inserting object relation for remote table "missions_onetime_params" */
export type Missions_Onetime_Params_Obj_Rel_Insert_Input = {
  data: Missions_Onetime_Params_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Missions_Onetime_Params_On_Conflict>;
};

/** on_conflict condition type for table "missions_onetime_params" */
export type Missions_Onetime_Params_On_Conflict = {
  constraint: Missions_Onetime_Params_Constraint;
  update_columns?: Array<Missions_Onetime_Params_Update_Column>;
  where?: InputMaybe<Missions_Onetime_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "missions_onetime_params". */
export type Missions_Onetime_Params_Order_By = {
  mission?: InputMaybe<Missions_Order_By>;
  mission_complete_by_click?: InputMaybe<Order_By>;
  mission_complete_param_count?: InputMaybe<Order_By>;
  mission_event_params?: InputMaybe<Order_By>;
  mission_event_subtype?: InputMaybe<Order_By>;
  mission_event_type?: InputMaybe<Order_By>;
  mission_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: missions_onetime_params */
export type Missions_Onetime_Params_Pk_Columns_Input = {
  mission_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Missions_Onetime_Params_Prepend_Input = {
  mission_event_params?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "missions_onetime_params" */
export enum Missions_Onetime_Params_Select_Column {
  /** column name */
  MissionCompleteByClick = 'mission_complete_by_click',
  /** column name */
  MissionCompleteParamCount = 'mission_complete_param_count',
  /** column name */
  MissionEventParams = 'mission_event_params',
  /** column name */
  MissionEventSubtype = 'mission_event_subtype',
  /** column name */
  MissionEventType = 'mission_event_type',
  /** column name */
  MissionId = 'mission_id'
}

/** input type for updating data in table "missions_onetime_params" */
export type Missions_Onetime_Params_Set_Input = {
  mission_complete_by_click?: InputMaybe<Scalars['Boolean']>;
  mission_complete_param_count?: InputMaybe<Scalars['Int']>;
  mission_event_params?: InputMaybe<Scalars['jsonb']>;
  mission_event_subtype?: InputMaybe<Scalars['String']>;
  mission_event_type?: InputMaybe<Scalars['String']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Missions_Onetime_Params_Stddev_Fields = {
  __typename?: 'missions_onetime_params_stddev_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Missions_Onetime_Params_Stddev_Pop_Fields = {
  __typename?: 'missions_onetime_params_stddev_pop_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Missions_Onetime_Params_Stddev_Samp_Fields = {
  __typename?: 'missions_onetime_params_stddev_samp_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "missions_onetime_params" */
export type Missions_Onetime_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Missions_Onetime_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Missions_Onetime_Params_Stream_Cursor_Value_Input = {
  mission_complete_by_click?: InputMaybe<Scalars['Boolean']>;
  mission_complete_param_count?: InputMaybe<Scalars['Int']>;
  mission_event_params?: InputMaybe<Scalars['jsonb']>;
  mission_event_subtype?: InputMaybe<Scalars['String']>;
  mission_event_type?: InputMaybe<Scalars['String']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Missions_Onetime_Params_Sum_Fields = {
  __typename?: 'missions_onetime_params_sum_fields';
  mission_complete_param_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "missions_onetime_params" */
export enum Missions_Onetime_Params_Update_Column {
  /** column name */
  MissionCompleteByClick = 'mission_complete_by_click',
  /** column name */
  MissionCompleteParamCount = 'mission_complete_param_count',
  /** column name */
  MissionEventParams = 'mission_event_params',
  /** column name */
  MissionEventSubtype = 'mission_event_subtype',
  /** column name */
  MissionEventType = 'mission_event_type',
  /** column name */
  MissionId = 'mission_id'
}

export type Missions_Onetime_Params_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Missions_Onetime_Params_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Missions_Onetime_Params_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Missions_Onetime_Params_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Missions_Onetime_Params_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Missions_Onetime_Params_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Missions_Onetime_Params_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Missions_Onetime_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Missions_Onetime_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Missions_Onetime_Params_Var_Pop_Fields = {
  __typename?: 'missions_onetime_params_var_pop_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Missions_Onetime_Params_Var_Samp_Fields = {
  __typename?: 'missions_onetime_params_var_samp_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Missions_Onetime_Params_Variance_Fields = {
  __typename?: 'missions_onetime_params_variance_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress = {
  __typename?: 'missions_onetime_users_progress';
  complete_progress: Scalars['Int'];
  created_at: Scalars['timestamp'];
  current_progress: Scalars['Int'];
  is_completed: Scalars['Boolean'];
  /** An object relationship */
  mission?: Maybe<Missions>;
  mission_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Aggregate = {
  __typename?: 'missions_onetime_users_progress_aggregate';
  aggregate?: Maybe<Missions_Onetime_Users_Progress_Aggregate_Fields>;
  nodes: Array<Missions_Onetime_Users_Progress>;
};

export type Missions_Onetime_Users_Progress_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Missions_Onetime_Users_Progress_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Missions_Onetime_Users_Progress_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Missions_Onetime_Users_Progress_Aggregate_Bool_Exp_Count>;
};

export type Missions_Onetime_Users_Progress_Aggregate_Bool_Exp_Bool_And = {
  arguments: Missions_Onetime_Users_Progress_Select_Column_Missions_Onetime_Users_Progress_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Missions_Onetime_Users_Progress_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Missions_Onetime_Users_Progress_Select_Column_Missions_Onetime_Users_Progress_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Missions_Onetime_Users_Progress_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Missions_Onetime_Users_Progress_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Aggregate_Fields = {
  __typename?: 'missions_onetime_users_progress_aggregate_fields';
  avg?: Maybe<Missions_Onetime_Users_Progress_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Missions_Onetime_Users_Progress_Max_Fields>;
  min?: Maybe<Missions_Onetime_Users_Progress_Min_Fields>;
  stddev?: Maybe<Missions_Onetime_Users_Progress_Stddev_Fields>;
  stddev_pop?: Maybe<Missions_Onetime_Users_Progress_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Missions_Onetime_Users_Progress_Stddev_Samp_Fields>;
  sum?: Maybe<Missions_Onetime_Users_Progress_Sum_Fields>;
  var_pop?: Maybe<Missions_Onetime_Users_Progress_Var_Pop_Fields>;
  var_samp?: Maybe<Missions_Onetime_Users_Progress_Var_Samp_Fields>;
  variance?: Maybe<Missions_Onetime_Users_Progress_Variance_Fields>;
};


/** aggregate fields of "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Missions_Onetime_Users_Progress_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Aggregate_Order_By = {
  avg?: InputMaybe<Missions_Onetime_Users_Progress_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Missions_Onetime_Users_Progress_Max_Order_By>;
  min?: InputMaybe<Missions_Onetime_Users_Progress_Min_Order_By>;
  stddev?: InputMaybe<Missions_Onetime_Users_Progress_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Missions_Onetime_Users_Progress_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Missions_Onetime_Users_Progress_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Missions_Onetime_Users_Progress_Sum_Order_By>;
  var_pop?: InputMaybe<Missions_Onetime_Users_Progress_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Missions_Onetime_Users_Progress_Var_Samp_Order_By>;
  variance?: InputMaybe<Missions_Onetime_Users_Progress_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Arr_Rel_Insert_Input = {
  data: Array<Missions_Onetime_Users_Progress_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Missions_Onetime_Users_Progress_On_Conflict>;
};

/** aggregate avg on columns */
export type Missions_Onetime_Users_Progress_Avg_Fields = {
  __typename?: 'missions_onetime_users_progress_avg_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Avg_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "missions_onetime_users_progress". All fields are combined with a logical 'AND'. */
export type Missions_Onetime_Users_Progress_Bool_Exp = {
  _and?: InputMaybe<Array<Missions_Onetime_Users_Progress_Bool_Exp>>;
  _not?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
  _or?: InputMaybe<Array<Missions_Onetime_Users_Progress_Bool_Exp>>;
  complete_progress?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  current_progress?: InputMaybe<Int_Comparison_Exp>;
  is_completed?: InputMaybe<Boolean_Comparison_Exp>;
  mission?: InputMaybe<Missions_Bool_Exp>;
  mission_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "missions_onetime_users_progress" */
export enum Missions_Onetime_Users_Progress_Constraint {
  /** unique or primary key constraint on columns "user_id", "mission_id" */
  MissionsOnetimeUsersProgressPkey = 'missions_onetime_users_progress_pkey'
}

/** input type for incrementing numeric columns in table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Inc_Input = {
  complete_progress?: InputMaybe<Scalars['Int']>;
  current_progress?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Insert_Input = {
  complete_progress?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  current_progress?: InputMaybe<Scalars['Int']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  mission?: InputMaybe<Missions_Obj_Rel_Insert_Input>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Missions_Onetime_Users_Progress_Max_Fields = {
  __typename?: 'missions_onetime_users_progress_max_fields';
  complete_progress?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current_progress?: Maybe<Scalars['Int']>;
  mission_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Max_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
  mission_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Missions_Onetime_Users_Progress_Min_Fields = {
  __typename?: 'missions_onetime_users_progress_min_fields';
  complete_progress?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  current_progress?: Maybe<Scalars['Int']>;
  mission_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Min_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
  mission_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Mutation_Response = {
  __typename?: 'missions_onetime_users_progress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Missions_Onetime_Users_Progress>;
};

/** on_conflict condition type for table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_On_Conflict = {
  constraint: Missions_Onetime_Users_Progress_Constraint;
  update_columns?: Array<Missions_Onetime_Users_Progress_Update_Column>;
  where?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
};

/** Ordering options when selecting data from "missions_onetime_users_progress". */
export type Missions_Onetime_Users_Progress_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
  is_completed?: InputMaybe<Order_By>;
  mission?: InputMaybe<Missions_Order_By>;
  mission_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: missions_onetime_users_progress */
export type Missions_Onetime_Users_Progress_Pk_Columns_Input = {
  mission_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "missions_onetime_users_progress" */
export enum Missions_Onetime_Users_Progress_Select_Column {
  /** column name */
  CompleteProgress = 'complete_progress',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentProgress = 'current_progress',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "missions_onetime_users_progress_aggregate_bool_exp_bool_and_arguments_columns" columns of table "missions_onetime_users_progress" */
export enum Missions_Onetime_Users_Progress_Select_Column_Missions_Onetime_Users_Progress_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** select "missions_onetime_users_progress_aggregate_bool_exp_bool_or_arguments_columns" columns of table "missions_onetime_users_progress" */
export enum Missions_Onetime_Users_Progress_Select_Column_Missions_Onetime_Users_Progress_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCompleted = 'is_completed'
}

/** input type for updating data in table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Set_Input = {
  complete_progress?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  current_progress?: InputMaybe<Scalars['Int']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Missions_Onetime_Users_Progress_Stddev_Fields = {
  __typename?: 'missions_onetime_users_progress_stddev_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Stddev_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Missions_Onetime_Users_Progress_Stddev_Pop_Fields = {
  __typename?: 'missions_onetime_users_progress_stddev_pop_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Stddev_Pop_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Missions_Onetime_Users_Progress_Stddev_Samp_Fields = {
  __typename?: 'missions_onetime_users_progress_stddev_samp_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Stddev_Samp_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Missions_Onetime_Users_Progress_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Missions_Onetime_Users_Progress_Stream_Cursor_Value_Input = {
  complete_progress?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  current_progress?: InputMaybe<Scalars['Int']>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Missions_Onetime_Users_Progress_Sum_Fields = {
  __typename?: 'missions_onetime_users_progress_sum_fields';
  complete_progress?: Maybe<Scalars['Int']>;
  current_progress?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Sum_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** update columns of table "missions_onetime_users_progress" */
export enum Missions_Onetime_Users_Progress_Update_Column {
  /** column name */
  CompleteProgress = 'complete_progress',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentProgress = 'current_progress',
  /** column name */
  IsCompleted = 'is_completed',
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Missions_Onetime_Users_Progress_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Missions_Onetime_Users_Progress_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Missions_Onetime_Users_Progress_Set_Input>;
  /** filter the rows which have to be updated */
  where: Missions_Onetime_Users_Progress_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Missions_Onetime_Users_Progress_Var_Pop_Fields = {
  __typename?: 'missions_onetime_users_progress_var_pop_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Var_Pop_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Missions_Onetime_Users_Progress_Var_Samp_Fields = {
  __typename?: 'missions_onetime_users_progress_var_samp_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Var_Samp_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Missions_Onetime_Users_Progress_Variance_Fields = {
  __typename?: 'missions_onetime_users_progress_variance_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "missions_onetime_users_progress" */
export type Missions_Onetime_Users_Progress_Variance_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "missions". */
export type Missions_Order_By = {
  complete_link?: InputMaybe<Order_By>;
  complete_link_params?: InputMaybe<Order_By>;
  complete_text?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  mission_id?: InputMaybe<Order_By>;
  mission_image?: InputMaybe<Order_By>;
  mission_slug?: InputMaybe<Order_By>;
  mission_text?: InputMaybe<Order_By>;
  mission_title?: InputMaybe<Order_By>;
  mission_type?: InputMaybe<Order_By>;
  mission_value?: InputMaybe<Order_By>;
  missions_onetime_params?: InputMaybe<Missions_Onetime_Params_Order_By>;
  missions_onetime_users_progress_aggregate?: InputMaybe<Missions_Onetime_Users_Progress_Aggregate_Order_By>;
  missions_permanent_params?: InputMaybe<Missions_Permanent_Params_Order_By>;
  missions_permanent_users_progress_aggregate?: InputMaybe<Missions_Permanent_User_Progress_Aggregate_Order_By>;
  missions_tags_aggregate?: InputMaybe<Missions_Tags_Aggregate_Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** columns and relationships of "missions_permanent_params" */
export type Missions_Permanent_Params = {
  __typename?: 'missions_permanent_params';
  /** An object relationship */
  mission: Missions;
  mission_complete_param_count: Scalars['Int'];
  mission_event_params?: Maybe<Scalars['jsonb']>;
  mission_event_subtype?: Maybe<Scalars['String']>;
  mission_event_type?: Maybe<Scalars['String']>;
  mission_id: Scalars['uuid'];
  timer_seconds?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "missions_permanent_params" */
export type Missions_Permanent_ParamsMission_Event_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "missions_permanent_params" */
export type Missions_Permanent_Params_Aggregate = {
  __typename?: 'missions_permanent_params_aggregate';
  aggregate?: Maybe<Missions_Permanent_Params_Aggregate_Fields>;
  nodes: Array<Missions_Permanent_Params>;
};

/** aggregate fields of "missions_permanent_params" */
export type Missions_Permanent_Params_Aggregate_Fields = {
  __typename?: 'missions_permanent_params_aggregate_fields';
  avg?: Maybe<Missions_Permanent_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Missions_Permanent_Params_Max_Fields>;
  min?: Maybe<Missions_Permanent_Params_Min_Fields>;
  stddev?: Maybe<Missions_Permanent_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Missions_Permanent_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Missions_Permanent_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Missions_Permanent_Params_Sum_Fields>;
  var_pop?: Maybe<Missions_Permanent_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Missions_Permanent_Params_Var_Samp_Fields>;
  variance?: Maybe<Missions_Permanent_Params_Variance_Fields>;
};


/** aggregate fields of "missions_permanent_params" */
export type Missions_Permanent_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Missions_Permanent_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Missions_Permanent_Params_Append_Input = {
  mission_event_params?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Missions_Permanent_Params_Avg_Fields = {
  __typename?: 'missions_permanent_params_avg_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
  timer_seconds?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "missions_permanent_params". All fields are combined with a logical 'AND'. */
export type Missions_Permanent_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Missions_Permanent_Params_Bool_Exp>>;
  _not?: InputMaybe<Missions_Permanent_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Missions_Permanent_Params_Bool_Exp>>;
  mission?: InputMaybe<Missions_Bool_Exp>;
  mission_complete_param_count?: InputMaybe<Int_Comparison_Exp>;
  mission_event_params?: InputMaybe<Jsonb_Comparison_Exp>;
  mission_event_subtype?: InputMaybe<String_Comparison_Exp>;
  mission_event_type?: InputMaybe<String_Comparison_Exp>;
  mission_id?: InputMaybe<Uuid_Comparison_Exp>;
  timer_seconds?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "missions_permanent_params" */
export enum Missions_Permanent_Params_Constraint {
  /** unique or primary key constraint on columns "mission_id" */
  MissionsPermanentParamsPkey = 'missions_permanent_params_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Missions_Permanent_Params_Delete_At_Path_Input = {
  mission_event_params?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Missions_Permanent_Params_Delete_Elem_Input = {
  mission_event_params?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Missions_Permanent_Params_Delete_Key_Input = {
  mission_event_params?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "missions_permanent_params" */
export type Missions_Permanent_Params_Inc_Input = {
  mission_complete_param_count?: InputMaybe<Scalars['Int']>;
  timer_seconds?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "missions_permanent_params" */
export type Missions_Permanent_Params_Insert_Input = {
  mission?: InputMaybe<Missions_Obj_Rel_Insert_Input>;
  mission_complete_param_count?: InputMaybe<Scalars['Int']>;
  mission_event_params?: InputMaybe<Scalars['jsonb']>;
  mission_event_subtype?: InputMaybe<Scalars['String']>;
  mission_event_type?: InputMaybe<Scalars['String']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  timer_seconds?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Missions_Permanent_Params_Max_Fields = {
  __typename?: 'missions_permanent_params_max_fields';
  mission_complete_param_count?: Maybe<Scalars['Int']>;
  mission_event_subtype?: Maybe<Scalars['String']>;
  mission_event_type?: Maybe<Scalars['String']>;
  mission_id?: Maybe<Scalars['uuid']>;
  timer_seconds?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Missions_Permanent_Params_Min_Fields = {
  __typename?: 'missions_permanent_params_min_fields';
  mission_complete_param_count?: Maybe<Scalars['Int']>;
  mission_event_subtype?: Maybe<Scalars['String']>;
  mission_event_type?: Maybe<Scalars['String']>;
  mission_id?: Maybe<Scalars['uuid']>;
  timer_seconds?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "missions_permanent_params" */
export type Missions_Permanent_Params_Mutation_Response = {
  __typename?: 'missions_permanent_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Missions_Permanent_Params>;
};

/** input type for inserting object relation for remote table "missions_permanent_params" */
export type Missions_Permanent_Params_Obj_Rel_Insert_Input = {
  data: Missions_Permanent_Params_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Missions_Permanent_Params_On_Conflict>;
};

/** on_conflict condition type for table "missions_permanent_params" */
export type Missions_Permanent_Params_On_Conflict = {
  constraint: Missions_Permanent_Params_Constraint;
  update_columns?: Array<Missions_Permanent_Params_Update_Column>;
  where?: InputMaybe<Missions_Permanent_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "missions_permanent_params". */
export type Missions_Permanent_Params_Order_By = {
  mission?: InputMaybe<Missions_Order_By>;
  mission_complete_param_count?: InputMaybe<Order_By>;
  mission_event_params?: InputMaybe<Order_By>;
  mission_event_subtype?: InputMaybe<Order_By>;
  mission_event_type?: InputMaybe<Order_By>;
  mission_id?: InputMaybe<Order_By>;
  timer_seconds?: InputMaybe<Order_By>;
};

/** primary key columns input for table: missions_permanent_params */
export type Missions_Permanent_Params_Pk_Columns_Input = {
  mission_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Missions_Permanent_Params_Prepend_Input = {
  mission_event_params?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "missions_permanent_params" */
export enum Missions_Permanent_Params_Select_Column {
  /** column name */
  MissionCompleteParamCount = 'mission_complete_param_count',
  /** column name */
  MissionEventParams = 'mission_event_params',
  /** column name */
  MissionEventSubtype = 'mission_event_subtype',
  /** column name */
  MissionEventType = 'mission_event_type',
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  TimerSeconds = 'timer_seconds'
}

/** input type for updating data in table "missions_permanent_params" */
export type Missions_Permanent_Params_Set_Input = {
  mission_complete_param_count?: InputMaybe<Scalars['Int']>;
  mission_event_params?: InputMaybe<Scalars['jsonb']>;
  mission_event_subtype?: InputMaybe<Scalars['String']>;
  mission_event_type?: InputMaybe<Scalars['String']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  timer_seconds?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Missions_Permanent_Params_Stddev_Fields = {
  __typename?: 'missions_permanent_params_stddev_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
  timer_seconds?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Missions_Permanent_Params_Stddev_Pop_Fields = {
  __typename?: 'missions_permanent_params_stddev_pop_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
  timer_seconds?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Missions_Permanent_Params_Stddev_Samp_Fields = {
  __typename?: 'missions_permanent_params_stddev_samp_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
  timer_seconds?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "missions_permanent_params" */
export type Missions_Permanent_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Missions_Permanent_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Missions_Permanent_Params_Stream_Cursor_Value_Input = {
  mission_complete_param_count?: InputMaybe<Scalars['Int']>;
  mission_event_params?: InputMaybe<Scalars['jsonb']>;
  mission_event_subtype?: InputMaybe<Scalars['String']>;
  mission_event_type?: InputMaybe<Scalars['String']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  timer_seconds?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Missions_Permanent_Params_Sum_Fields = {
  __typename?: 'missions_permanent_params_sum_fields';
  mission_complete_param_count?: Maybe<Scalars['Int']>;
  timer_seconds?: Maybe<Scalars['Int']>;
};

/** update columns of table "missions_permanent_params" */
export enum Missions_Permanent_Params_Update_Column {
  /** column name */
  MissionCompleteParamCount = 'mission_complete_param_count',
  /** column name */
  MissionEventParams = 'mission_event_params',
  /** column name */
  MissionEventSubtype = 'mission_event_subtype',
  /** column name */
  MissionEventType = 'mission_event_type',
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  TimerSeconds = 'timer_seconds'
}

export type Missions_Permanent_Params_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Missions_Permanent_Params_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Missions_Permanent_Params_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Missions_Permanent_Params_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Missions_Permanent_Params_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Missions_Permanent_Params_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Missions_Permanent_Params_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Missions_Permanent_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Missions_Permanent_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Missions_Permanent_Params_Var_Pop_Fields = {
  __typename?: 'missions_permanent_params_var_pop_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
  timer_seconds?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Missions_Permanent_Params_Var_Samp_Fields = {
  __typename?: 'missions_permanent_params_var_samp_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
  timer_seconds?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Missions_Permanent_Params_Variance_Fields = {
  __typename?: 'missions_permanent_params_variance_fields';
  mission_complete_param_count?: Maybe<Scalars['Float']>;
  timer_seconds?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress = {
  __typename?: 'missions_permanent_user_progress';
  complete_progress: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  current_progress: Scalars['Int'];
  /** An object relationship */
  mission: Missions;
  mission_id: Scalars['uuid'];
  period_claimed: Scalars['Boolean'];
  start_datetime: Scalars['timestamp'];
  started: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Aggregate = {
  __typename?: 'missions_permanent_user_progress_aggregate';
  aggregate?: Maybe<Missions_Permanent_User_Progress_Aggregate_Fields>;
  nodes: Array<Missions_Permanent_User_Progress>;
};

export type Missions_Permanent_User_Progress_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Missions_Permanent_User_Progress_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Missions_Permanent_User_Progress_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Missions_Permanent_User_Progress_Aggregate_Bool_Exp_Count>;
};

export type Missions_Permanent_User_Progress_Aggregate_Bool_Exp_Bool_And = {
  arguments: Missions_Permanent_User_Progress_Select_Column_Missions_Permanent_User_Progress_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Missions_Permanent_User_Progress_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Missions_Permanent_User_Progress_Select_Column_Missions_Permanent_User_Progress_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Missions_Permanent_User_Progress_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Missions_Permanent_User_Progress_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Aggregate_Fields = {
  __typename?: 'missions_permanent_user_progress_aggregate_fields';
  avg?: Maybe<Missions_Permanent_User_Progress_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Missions_Permanent_User_Progress_Max_Fields>;
  min?: Maybe<Missions_Permanent_User_Progress_Min_Fields>;
  stddev?: Maybe<Missions_Permanent_User_Progress_Stddev_Fields>;
  stddev_pop?: Maybe<Missions_Permanent_User_Progress_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Missions_Permanent_User_Progress_Stddev_Samp_Fields>;
  sum?: Maybe<Missions_Permanent_User_Progress_Sum_Fields>;
  var_pop?: Maybe<Missions_Permanent_User_Progress_Var_Pop_Fields>;
  var_samp?: Maybe<Missions_Permanent_User_Progress_Var_Samp_Fields>;
  variance?: Maybe<Missions_Permanent_User_Progress_Variance_Fields>;
};


/** aggregate fields of "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Missions_Permanent_User_Progress_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Aggregate_Order_By = {
  avg?: InputMaybe<Missions_Permanent_User_Progress_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Missions_Permanent_User_Progress_Max_Order_By>;
  min?: InputMaybe<Missions_Permanent_User_Progress_Min_Order_By>;
  stddev?: InputMaybe<Missions_Permanent_User_Progress_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Missions_Permanent_User_Progress_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Missions_Permanent_User_Progress_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Missions_Permanent_User_Progress_Sum_Order_By>;
  var_pop?: InputMaybe<Missions_Permanent_User_Progress_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Missions_Permanent_User_Progress_Var_Samp_Order_By>;
  variance?: InputMaybe<Missions_Permanent_User_Progress_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Arr_Rel_Insert_Input = {
  data: Array<Missions_Permanent_User_Progress_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Missions_Permanent_User_Progress_On_Conflict>;
};

/** aggregate avg on columns */
export type Missions_Permanent_User_Progress_Avg_Fields = {
  __typename?: 'missions_permanent_user_progress_avg_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Avg_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "missions_permanent_user_progress". All fields are combined with a logical 'AND'. */
export type Missions_Permanent_User_Progress_Bool_Exp = {
  _and?: InputMaybe<Array<Missions_Permanent_User_Progress_Bool_Exp>>;
  _not?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
  _or?: InputMaybe<Array<Missions_Permanent_User_Progress_Bool_Exp>>;
  complete_progress?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_progress?: InputMaybe<Int_Comparison_Exp>;
  mission?: InputMaybe<Missions_Bool_Exp>;
  mission_id?: InputMaybe<Uuid_Comparison_Exp>;
  period_claimed?: InputMaybe<Boolean_Comparison_Exp>;
  start_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  started?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "missions_permanent_user_progress" */
export enum Missions_Permanent_User_Progress_Constraint {
  /** unique or primary key constraint on columns "user_id", "mission_id" */
  MissionsPermanentUserProgressPkey = 'missions_permanent_user_progress_pkey'
}

/** input type for incrementing numeric columns in table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Inc_Input = {
  complete_progress?: InputMaybe<Scalars['Int']>;
  current_progress?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Insert_Input = {
  complete_progress?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_progress?: InputMaybe<Scalars['Int']>;
  mission?: InputMaybe<Missions_Obj_Rel_Insert_Input>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  period_claimed?: InputMaybe<Scalars['Boolean']>;
  start_datetime?: InputMaybe<Scalars['timestamp']>;
  started?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Missions_Permanent_User_Progress_Max_Fields = {
  __typename?: 'missions_permanent_user_progress_max_fields';
  complete_progress?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_progress?: Maybe<Scalars['Int']>;
  mission_id?: Maybe<Scalars['uuid']>;
  start_datetime?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Max_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
  mission_id?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Missions_Permanent_User_Progress_Min_Fields = {
  __typename?: 'missions_permanent_user_progress_min_fields';
  complete_progress?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_progress?: Maybe<Scalars['Int']>;
  mission_id?: Maybe<Scalars['uuid']>;
  start_datetime?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Min_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
  mission_id?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Mutation_Response = {
  __typename?: 'missions_permanent_user_progress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Missions_Permanent_User_Progress>;
};

/** on_conflict condition type for table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_On_Conflict = {
  constraint: Missions_Permanent_User_Progress_Constraint;
  update_columns?: Array<Missions_Permanent_User_Progress_Update_Column>;
  where?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
};

/** Ordering options when selecting data from "missions_permanent_user_progress". */
export type Missions_Permanent_User_Progress_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
  mission?: InputMaybe<Missions_Order_By>;
  mission_id?: InputMaybe<Order_By>;
  period_claimed?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  started?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: missions_permanent_user_progress */
export type Missions_Permanent_User_Progress_Pk_Columns_Input = {
  mission_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "missions_permanent_user_progress" */
export enum Missions_Permanent_User_Progress_Select_Column {
  /** column name */
  CompleteProgress = 'complete_progress',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentProgress = 'current_progress',
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  PeriodClaimed = 'period_claimed',
  /** column name */
  StartDatetime = 'start_datetime',
  /** column name */
  Started = 'started',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "missions_permanent_user_progress_aggregate_bool_exp_bool_and_arguments_columns" columns of table "missions_permanent_user_progress" */
export enum Missions_Permanent_User_Progress_Select_Column_Missions_Permanent_User_Progress_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PeriodClaimed = 'period_claimed',
  /** column name */
  Started = 'started'
}

/** select "missions_permanent_user_progress_aggregate_bool_exp_bool_or_arguments_columns" columns of table "missions_permanent_user_progress" */
export enum Missions_Permanent_User_Progress_Select_Column_Missions_Permanent_User_Progress_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PeriodClaimed = 'period_claimed',
  /** column name */
  Started = 'started'
}

/** input type for updating data in table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Set_Input = {
  complete_progress?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_progress?: InputMaybe<Scalars['Int']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  period_claimed?: InputMaybe<Scalars['Boolean']>;
  start_datetime?: InputMaybe<Scalars['timestamp']>;
  started?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Missions_Permanent_User_Progress_Stddev_Fields = {
  __typename?: 'missions_permanent_user_progress_stddev_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Stddev_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Missions_Permanent_User_Progress_Stddev_Pop_Fields = {
  __typename?: 'missions_permanent_user_progress_stddev_pop_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Stddev_Pop_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Missions_Permanent_User_Progress_Stddev_Samp_Fields = {
  __typename?: 'missions_permanent_user_progress_stddev_samp_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Stddev_Samp_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Missions_Permanent_User_Progress_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Missions_Permanent_User_Progress_Stream_Cursor_Value_Input = {
  complete_progress?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_progress?: InputMaybe<Scalars['Int']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  period_claimed?: InputMaybe<Scalars['Boolean']>;
  start_datetime?: InputMaybe<Scalars['timestamp']>;
  started?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Missions_Permanent_User_Progress_Sum_Fields = {
  __typename?: 'missions_permanent_user_progress_sum_fields';
  complete_progress?: Maybe<Scalars['Int']>;
  current_progress?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Sum_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** update columns of table "missions_permanent_user_progress" */
export enum Missions_Permanent_User_Progress_Update_Column {
  /** column name */
  CompleteProgress = 'complete_progress',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentProgress = 'current_progress',
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  PeriodClaimed = 'period_claimed',
  /** column name */
  StartDatetime = 'start_datetime',
  /** column name */
  Started = 'started',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Missions_Permanent_User_Progress_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Missions_Permanent_User_Progress_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Missions_Permanent_User_Progress_Set_Input>;
  /** filter the rows which have to be updated */
  where: Missions_Permanent_User_Progress_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Missions_Permanent_User_Progress_Var_Pop_Fields = {
  __typename?: 'missions_permanent_user_progress_var_pop_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Var_Pop_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Missions_Permanent_User_Progress_Var_Samp_Fields = {
  __typename?: 'missions_permanent_user_progress_var_samp_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Var_Samp_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Missions_Permanent_User_Progress_Variance_Fields = {
  __typename?: 'missions_permanent_user_progress_variance_fields';
  complete_progress?: Maybe<Scalars['Float']>;
  current_progress?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "missions_permanent_user_progress" */
export type Missions_Permanent_User_Progress_Variance_Order_By = {
  complete_progress?: InputMaybe<Order_By>;
  current_progress?: InputMaybe<Order_By>;
};

/** primary key columns input for table: missions */
export type Missions_Pk_Columns_Input = {
  mission_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Missions_Prepend_Input = {
  complete_link_params?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "missions" */
export enum Missions_Select_Column {
  /** column name */
  CompleteLink = 'complete_link',
  /** column name */
  CompleteLinkParams = 'complete_link_params',
  /** column name */
  CompleteText = 'complete_text',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  MissionImage = 'mission_image',
  /** column name */
  MissionSlug = 'mission_slug',
  /** column name */
  MissionText = 'mission_text',
  /** column name */
  MissionTitle = 'mission_title',
  /** column name */
  MissionType = 'mission_type',
  /** column name */
  MissionValue = 'mission_value',
  /** column name */
  Sort = 'sort'
}

/** input type for updating data in table "missions" */
export type Missions_Set_Input = {
  complete_link?: InputMaybe<Scalars['String']>;
  complete_link_params?: InputMaybe<Scalars['jsonb']>;
  complete_text?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  mission_image?: InputMaybe<Scalars['String']>;
  mission_slug?: InputMaybe<Scalars['String']>;
  mission_text?: InputMaybe<Scalars['String']>;
  mission_title?: InputMaybe<Scalars['String']>;
  mission_type?: InputMaybe<Scalars['Int']>;
  mission_value?: InputMaybe<Scalars['bigint']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Missions_Stddev_Fields = {
  __typename?: 'missions_stddev_fields';
  mission_type?: Maybe<Scalars['Float']>;
  mission_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Missions_Stddev_Pop_Fields = {
  __typename?: 'missions_stddev_pop_fields';
  mission_type?: Maybe<Scalars['Float']>;
  mission_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Missions_Stddev_Samp_Fields = {
  __typename?: 'missions_stddev_samp_fields';
  mission_type?: Maybe<Scalars['Float']>;
  mission_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "missions" */
export type Missions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Missions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Missions_Stream_Cursor_Value_Input = {
  complete_link?: InputMaybe<Scalars['String']>;
  complete_link_params?: InputMaybe<Scalars['jsonb']>;
  complete_text?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  mission_id?: InputMaybe<Scalars['uuid']>;
  mission_image?: InputMaybe<Scalars['String']>;
  mission_slug?: InputMaybe<Scalars['String']>;
  mission_text?: InputMaybe<Scalars['String']>;
  mission_title?: InputMaybe<Scalars['String']>;
  mission_type?: InputMaybe<Scalars['Int']>;
  mission_value?: InputMaybe<Scalars['bigint']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Missions_Sum_Fields = {
  __typename?: 'missions_sum_fields';
  mission_type?: Maybe<Scalars['Int']>;
  mission_value?: Maybe<Scalars['bigint']>;
  sort?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "missions_tags" */
export type Missions_Tags = {
  __typename?: 'missions_tags';
  mission_id: Scalars['uuid'];
  mission_tag: Scalars['String'];
  /** An object relationship */
  tag_name?: Maybe<Missions_Tags_Names>;
};

/** aggregated selection of "missions_tags" */
export type Missions_Tags_Aggregate = {
  __typename?: 'missions_tags_aggregate';
  aggregate?: Maybe<Missions_Tags_Aggregate_Fields>;
  nodes: Array<Missions_Tags>;
};

export type Missions_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Missions_Tags_Aggregate_Bool_Exp_Count>;
};

export type Missions_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Missions_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Missions_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "missions_tags" */
export type Missions_Tags_Aggregate_Fields = {
  __typename?: 'missions_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Missions_Tags_Max_Fields>;
  min?: Maybe<Missions_Tags_Min_Fields>;
};


/** aggregate fields of "missions_tags" */
export type Missions_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Missions_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "missions_tags" */
export type Missions_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Missions_Tags_Max_Order_By>;
  min?: InputMaybe<Missions_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "missions_tags" */
export type Missions_Tags_Arr_Rel_Insert_Input = {
  data: Array<Missions_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Missions_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "missions_tags". All fields are combined with a logical 'AND'. */
export type Missions_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Missions_Tags_Bool_Exp>>;
  _not?: InputMaybe<Missions_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Missions_Tags_Bool_Exp>>;
  mission_id?: InputMaybe<Uuid_Comparison_Exp>;
  mission_tag?: InputMaybe<String_Comparison_Exp>;
  tag_name?: InputMaybe<Missions_Tags_Names_Bool_Exp>;
};

/** unique or primary key constraints on table "missions_tags" */
export enum Missions_Tags_Constraint {
  /** unique or primary key constraint on columns "mission_tag", "mission_id" */
  MissionsTagsPkey = 'missions_tags_pkey'
}

/** input type for inserting data into table "missions_tags" */
export type Missions_Tags_Insert_Input = {
  mission_id?: InputMaybe<Scalars['uuid']>;
  mission_tag?: InputMaybe<Scalars['String']>;
  tag_name?: InputMaybe<Missions_Tags_Names_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Missions_Tags_Max_Fields = {
  __typename?: 'missions_tags_max_fields';
  mission_id?: Maybe<Scalars['uuid']>;
  mission_tag?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "missions_tags" */
export type Missions_Tags_Max_Order_By = {
  mission_id?: InputMaybe<Order_By>;
  mission_tag?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Missions_Tags_Min_Fields = {
  __typename?: 'missions_tags_min_fields';
  mission_id?: Maybe<Scalars['uuid']>;
  mission_tag?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "missions_tags" */
export type Missions_Tags_Min_Order_By = {
  mission_id?: InputMaybe<Order_By>;
  mission_tag?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "missions_tags" */
export type Missions_Tags_Mutation_Response = {
  __typename?: 'missions_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Missions_Tags>;
};

/** columns and relationships of "missions_tags_names" */
export type Missions_Tags_Names = {
  __typename?: 'missions_tags_names';
  en_name: Scalars['String'];
  ru_name: Scalars['String'];
  tag_title: Scalars['String'];
};

/** aggregated selection of "missions_tags_names" */
export type Missions_Tags_Names_Aggregate = {
  __typename?: 'missions_tags_names_aggregate';
  aggregate?: Maybe<Missions_Tags_Names_Aggregate_Fields>;
  nodes: Array<Missions_Tags_Names>;
};

/** aggregate fields of "missions_tags_names" */
export type Missions_Tags_Names_Aggregate_Fields = {
  __typename?: 'missions_tags_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Missions_Tags_Names_Max_Fields>;
  min?: Maybe<Missions_Tags_Names_Min_Fields>;
};


/** aggregate fields of "missions_tags_names" */
export type Missions_Tags_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Missions_Tags_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "missions_tags_names". All fields are combined with a logical 'AND'. */
export type Missions_Tags_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Missions_Tags_Names_Bool_Exp>>;
  _not?: InputMaybe<Missions_Tags_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Missions_Tags_Names_Bool_Exp>>;
  en_name?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
  tag_title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "missions_tags_names" */
export enum Missions_Tags_Names_Constraint {
  /** unique or primary key constraint on columns "tag_title" */
  MissionsTagsNamesPkey = 'missions_tags_names_pkey'
}

/** input type for inserting data into table "missions_tags_names" */
export type Missions_Tags_Names_Insert_Input = {
  en_name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
  tag_title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Missions_Tags_Names_Max_Fields = {
  __typename?: 'missions_tags_names_max_fields';
  en_name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
  tag_title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Missions_Tags_Names_Min_Fields = {
  __typename?: 'missions_tags_names_min_fields';
  en_name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
  tag_title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "missions_tags_names" */
export type Missions_Tags_Names_Mutation_Response = {
  __typename?: 'missions_tags_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Missions_Tags_Names>;
};

/** input type for inserting object relation for remote table "missions_tags_names" */
export type Missions_Tags_Names_Obj_Rel_Insert_Input = {
  data: Missions_Tags_Names_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Missions_Tags_Names_On_Conflict>;
};

/** on_conflict condition type for table "missions_tags_names" */
export type Missions_Tags_Names_On_Conflict = {
  constraint: Missions_Tags_Names_Constraint;
  update_columns?: Array<Missions_Tags_Names_Update_Column>;
  where?: InputMaybe<Missions_Tags_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "missions_tags_names". */
export type Missions_Tags_Names_Order_By = {
  en_name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
  tag_title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: missions_tags_names */
export type Missions_Tags_Names_Pk_Columns_Input = {
  tag_title: Scalars['String'];
};

/** select columns of table "missions_tags_names" */
export enum Missions_Tags_Names_Select_Column {
  /** column name */
  EnName = 'en_name',
  /** column name */
  RuName = 'ru_name',
  /** column name */
  TagTitle = 'tag_title'
}

/** input type for updating data in table "missions_tags_names" */
export type Missions_Tags_Names_Set_Input = {
  en_name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
  tag_title?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "missions_tags_names" */
export type Missions_Tags_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Missions_Tags_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Missions_Tags_Names_Stream_Cursor_Value_Input = {
  en_name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
  tag_title?: InputMaybe<Scalars['String']>;
};

/** update columns of table "missions_tags_names" */
export enum Missions_Tags_Names_Update_Column {
  /** column name */
  EnName = 'en_name',
  /** column name */
  RuName = 'ru_name',
  /** column name */
  TagTitle = 'tag_title'
}

export type Missions_Tags_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Missions_Tags_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Missions_Tags_Names_Bool_Exp;
};

/** on_conflict condition type for table "missions_tags" */
export type Missions_Tags_On_Conflict = {
  constraint: Missions_Tags_Constraint;
  update_columns?: Array<Missions_Tags_Update_Column>;
  where?: InputMaybe<Missions_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "missions_tags". */
export type Missions_Tags_Order_By = {
  mission_id?: InputMaybe<Order_By>;
  mission_tag?: InputMaybe<Order_By>;
  tag_name?: InputMaybe<Missions_Tags_Names_Order_By>;
};

/** primary key columns input for table: missions_tags */
export type Missions_Tags_Pk_Columns_Input = {
  mission_id: Scalars['uuid'];
  mission_tag: Scalars['String'];
};

/** select columns of table "missions_tags" */
export enum Missions_Tags_Select_Column {
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  MissionTag = 'mission_tag'
}

/** input type for updating data in table "missions_tags" */
export type Missions_Tags_Set_Input = {
  mission_id?: InputMaybe<Scalars['uuid']>;
  mission_tag?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "missions_tags" */
export type Missions_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Missions_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Missions_Tags_Stream_Cursor_Value_Input = {
  mission_id?: InputMaybe<Scalars['uuid']>;
  mission_tag?: InputMaybe<Scalars['String']>;
};

/** update columns of table "missions_tags" */
export enum Missions_Tags_Update_Column {
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  MissionTag = 'mission_tag'
}

export type Missions_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Missions_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Missions_Tags_Bool_Exp;
};

/** update columns of table "missions" */
export enum Missions_Update_Column {
  /** column name */
  CompleteLink = 'complete_link',
  /** column name */
  CompleteLinkParams = 'complete_link_params',
  /** column name */
  CompleteText = 'complete_text',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MissionId = 'mission_id',
  /** column name */
  MissionImage = 'mission_image',
  /** column name */
  MissionSlug = 'mission_slug',
  /** column name */
  MissionText = 'mission_text',
  /** column name */
  MissionTitle = 'mission_title',
  /** column name */
  MissionType = 'mission_type',
  /** column name */
  MissionValue = 'mission_value',
  /** column name */
  Sort = 'sort'
}

export type Missions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Missions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Missions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Missions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Missions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Missions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Missions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Missions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Missions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Missions_Var_Pop_Fields = {
  __typename?: 'missions_var_pop_fields';
  mission_type?: Maybe<Scalars['Float']>;
  mission_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Missions_Var_Samp_Fields = {
  __typename?: 'missions_var_samp_fields';
  mission_type?: Maybe<Scalars['Float']>;
  mission_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Missions_Variance_Fields = {
  __typename?: 'missions_variance_fields';
  mission_type?: Maybe<Scalars['Float']>;
  mission_value?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "missions_vk_links" */
export type Missions_Vk_Links = {
  __typename?: 'missions_vk_links';
  bdate?: Maybe<Scalars['String']>;
  can_access_closed?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  deactivated?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  has_mobile?: Maybe<Scalars['Int']>;
  has_photo?: Maybe<Scalars['Int']>;
  is_closed: Scalars['Boolean'];
  last_name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile_picture?: Maybe<Scalars['String']>;
  screen_name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['Int']>;
  user_id: Scalars['uuid'];
  user_slug?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Int']>;
  vk_full_name?: Maybe<Scalars['String']>;
  vk_id: Scalars['String'];
  vk_nickname?: Maybe<Scalars['String']>;
  vk_profile_url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "missions_vk_links" */
export type Missions_Vk_Links_Aggregate = {
  __typename?: 'missions_vk_links_aggregate';
  aggregate?: Maybe<Missions_Vk_Links_Aggregate_Fields>;
  nodes: Array<Missions_Vk_Links>;
};

/** aggregate fields of "missions_vk_links" */
export type Missions_Vk_Links_Aggregate_Fields = {
  __typename?: 'missions_vk_links_aggregate_fields';
  avg?: Maybe<Missions_Vk_Links_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Missions_Vk_Links_Max_Fields>;
  min?: Maybe<Missions_Vk_Links_Min_Fields>;
  stddev?: Maybe<Missions_Vk_Links_Stddev_Fields>;
  stddev_pop?: Maybe<Missions_Vk_Links_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Missions_Vk_Links_Stddev_Samp_Fields>;
  sum?: Maybe<Missions_Vk_Links_Sum_Fields>;
  var_pop?: Maybe<Missions_Vk_Links_Var_Pop_Fields>;
  var_samp?: Maybe<Missions_Vk_Links_Var_Samp_Fields>;
  variance?: Maybe<Missions_Vk_Links_Variance_Fields>;
};


/** aggregate fields of "missions_vk_links" */
export type Missions_Vk_Links_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Missions_Vk_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Missions_Vk_Links_Avg_Fields = {
  __typename?: 'missions_vk_links_avg_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "missions_vk_links". All fields are combined with a logical 'AND'. */
export type Missions_Vk_Links_Bool_Exp = {
  _and?: InputMaybe<Array<Missions_Vk_Links_Bool_Exp>>;
  _not?: InputMaybe<Missions_Vk_Links_Bool_Exp>;
  _or?: InputMaybe<Array<Missions_Vk_Links_Bool_Exp>>;
  bdate?: InputMaybe<String_Comparison_Exp>;
  can_access_closed?: InputMaybe<Boolean_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deactivated?: InputMaybe<Boolean_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  has_mobile?: InputMaybe<Int_Comparison_Exp>;
  has_photo?: InputMaybe<Int_Comparison_Exp>;
  is_closed?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  nickname?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  profile_picture?: InputMaybe<String_Comparison_Exp>;
  screen_name?: InputMaybe<String_Comparison_Exp>;
  sex?: InputMaybe<Int_Comparison_Exp>;
  timezone?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_slug?: InputMaybe<String_Comparison_Exp>;
  verified?: InputMaybe<Int_Comparison_Exp>;
  vk_full_name?: InputMaybe<String_Comparison_Exp>;
  vk_id?: InputMaybe<String_Comparison_Exp>;
  vk_nickname?: InputMaybe<String_Comparison_Exp>;
  vk_profile_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "missions_vk_links" */
export enum Missions_Vk_Links_Constraint {
  /** unique or primary key constraint on columns "vk_id" */
  MissionsVkLinksPkey = 'missions_vk_links_pkey'
}

/** input type for incrementing numeric columns in table "missions_vk_links" */
export type Missions_Vk_Links_Inc_Input = {
  has_mobile?: InputMaybe<Scalars['Int']>;
  has_photo?: InputMaybe<Scalars['Int']>;
  sex?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['Int']>;
  verified?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "missions_vk_links" */
export type Missions_Vk_Links_Insert_Input = {
  bdate?: InputMaybe<Scalars['String']>;
  can_access_closed?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_mobile?: InputMaybe<Scalars['Int']>;
  has_photo?: InputMaybe<Scalars['Int']>;
  is_closed?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  screen_name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_slug?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Int']>;
  vk_full_name?: InputMaybe<Scalars['String']>;
  vk_id?: InputMaybe<Scalars['String']>;
  vk_nickname?: InputMaybe<Scalars['String']>;
  vk_profile_url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Missions_Vk_Links_Max_Fields = {
  __typename?: 'missions_vk_links_max_fields';
  bdate?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  has_mobile?: Maybe<Scalars['Int']>;
  has_photo?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile_picture?: Maybe<Scalars['String']>;
  screen_name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_slug?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Int']>;
  vk_full_name?: Maybe<Scalars['String']>;
  vk_id?: Maybe<Scalars['String']>;
  vk_nickname?: Maybe<Scalars['String']>;
  vk_profile_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Missions_Vk_Links_Min_Fields = {
  __typename?: 'missions_vk_links_min_fields';
  bdate?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  has_mobile?: Maybe<Scalars['Int']>;
  has_photo?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile_picture?: Maybe<Scalars['String']>;
  screen_name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_slug?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Int']>;
  vk_full_name?: Maybe<Scalars['String']>;
  vk_id?: Maybe<Scalars['String']>;
  vk_nickname?: Maybe<Scalars['String']>;
  vk_profile_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "missions_vk_links" */
export type Missions_Vk_Links_Mutation_Response = {
  __typename?: 'missions_vk_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Missions_Vk_Links>;
};

/** on_conflict condition type for table "missions_vk_links" */
export type Missions_Vk_Links_On_Conflict = {
  constraint: Missions_Vk_Links_Constraint;
  update_columns?: Array<Missions_Vk_Links_Update_Column>;
  where?: InputMaybe<Missions_Vk_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "missions_vk_links". */
export type Missions_Vk_Links_Order_By = {
  bdate?: InputMaybe<Order_By>;
  can_access_closed?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  has_mobile?: InputMaybe<Order_By>;
  has_photo?: InputMaybe<Order_By>;
  is_closed?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  profile_picture?: InputMaybe<Order_By>;
  screen_name?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_slug?: InputMaybe<Order_By>;
  verified?: InputMaybe<Order_By>;
  vk_full_name?: InputMaybe<Order_By>;
  vk_id?: InputMaybe<Order_By>;
  vk_nickname?: InputMaybe<Order_By>;
  vk_profile_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: missions_vk_links */
export type Missions_Vk_Links_Pk_Columns_Input = {
  vk_id: Scalars['String'];
};

/** select columns of table "missions_vk_links" */
export enum Missions_Vk_Links_Select_Column {
  /** column name */
  Bdate = 'bdate',
  /** column name */
  CanAccessClosed = 'can_access_closed',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deactivated = 'deactivated',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasMobile = 'has_mobile',
  /** column name */
  HasPhoto = 'has_photo',
  /** column name */
  IsClosed = 'is_closed',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfilePicture = 'profile_picture',
  /** column name */
  ScreenName = 'screen_name',
  /** column name */
  Sex = 'sex',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserSlug = 'user_slug',
  /** column name */
  Verified = 'verified',
  /** column name */
  VkFullName = 'vk_full_name',
  /** column name */
  VkId = 'vk_id',
  /** column name */
  VkNickname = 'vk_nickname',
  /** column name */
  VkProfileUrl = 'vk_profile_url'
}

/** input type for updating data in table "missions_vk_links" */
export type Missions_Vk_Links_Set_Input = {
  bdate?: InputMaybe<Scalars['String']>;
  can_access_closed?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_mobile?: InputMaybe<Scalars['Int']>;
  has_photo?: InputMaybe<Scalars['Int']>;
  is_closed?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  screen_name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_slug?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Int']>;
  vk_full_name?: InputMaybe<Scalars['String']>;
  vk_id?: InputMaybe<Scalars['String']>;
  vk_nickname?: InputMaybe<Scalars['String']>;
  vk_profile_url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Missions_Vk_Links_Stddev_Fields = {
  __typename?: 'missions_vk_links_stddev_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Missions_Vk_Links_Stddev_Pop_Fields = {
  __typename?: 'missions_vk_links_stddev_pop_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Missions_Vk_Links_Stddev_Samp_Fields = {
  __typename?: 'missions_vk_links_stddev_samp_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "missions_vk_links" */
export type Missions_Vk_Links_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Missions_Vk_Links_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Missions_Vk_Links_Stream_Cursor_Value_Input = {
  bdate?: InputMaybe<Scalars['String']>;
  can_access_closed?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_mobile?: InputMaybe<Scalars['Int']>;
  has_photo?: InputMaybe<Scalars['Int']>;
  is_closed?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  screen_name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_slug?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Int']>;
  vk_full_name?: InputMaybe<Scalars['String']>;
  vk_id?: InputMaybe<Scalars['String']>;
  vk_nickname?: InputMaybe<Scalars['String']>;
  vk_profile_url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Missions_Vk_Links_Sum_Fields = {
  __typename?: 'missions_vk_links_sum_fields';
  has_mobile?: Maybe<Scalars['Int']>;
  has_photo?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['Int']>;
  verified?: Maybe<Scalars['Int']>;
};

/** update columns of table "missions_vk_links" */
export enum Missions_Vk_Links_Update_Column {
  /** column name */
  Bdate = 'bdate',
  /** column name */
  CanAccessClosed = 'can_access_closed',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deactivated = 'deactivated',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasMobile = 'has_mobile',
  /** column name */
  HasPhoto = 'has_photo',
  /** column name */
  IsClosed = 'is_closed',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfilePicture = 'profile_picture',
  /** column name */
  ScreenName = 'screen_name',
  /** column name */
  Sex = 'sex',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserSlug = 'user_slug',
  /** column name */
  Verified = 'verified',
  /** column name */
  VkFullName = 'vk_full_name',
  /** column name */
  VkId = 'vk_id',
  /** column name */
  VkNickname = 'vk_nickname',
  /** column name */
  VkProfileUrl = 'vk_profile_url'
}

export type Missions_Vk_Links_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Missions_Vk_Links_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Missions_Vk_Links_Set_Input>;
  /** filter the rows which have to be updated */
  where: Missions_Vk_Links_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Missions_Vk_Links_Var_Pop_Fields = {
  __typename?: 'missions_vk_links_var_pop_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Missions_Vk_Links_Var_Samp_Fields = {
  __typename?: 'missions_vk_links_var_samp_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Missions_Vk_Links_Variance_Fields = {
  __typename?: 'missions_vk_links_variance_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  AccOpenBonusLootBox: AccOpenOutput;
  AccOpenFreeLootBox?: Maybe<AccOpenOutput>;
  AccOpenLootBox?: Maybe<AccOpenOutput>;
  AcceptTerms: Scalars['String'];
  AddOffer?: Maybe<AddOfferResponse>;
  AddUserToTeamAction?: Maybe<ParticipantsRatingModel>;
  AdminUpdateUserMailAlertsActivity: UserMailAlertsUpdateAnswer;
  BotsReloadData: Scalars['String'];
  BotsSwitch: Scalars['String'];
  BuyBoosters?: Maybe<BuyingBoostersAnswer>;
  CheckBonusWheelNickname: UserWheelNickname;
  /** Очистка блок выполнения процессов юзера */
  ClearUsersQueue: Scalars['String'];
  ConsumeBoostersAction?: Maybe<ScoreProcessModel>;
  CreateUserPaymentTransaction: CreateUserPaymentTransactionOutput;
  DeactivateOffer?: Maybe<AnswerBaseModel>;
  EditUserReferalLink: EditUserReferalLinkOutput;
  ExcludeUsersFromAmplitudeStatByID?: Maybe<Result>;
  Force1PayCallbacj: Scalars['String'];
  FormItemsPriceDelta?: Maybe<FakeOutput>;
  FormMainReport: Scalars['json'];
  GetItemsByName?: Maybe<OutputMarketInfo>;
  GetItemsInLootbox?: Maybe<GetItemsInLootboxOutput>;
  GetUserBonusWheelSkin?: Maybe<BonusWheelGetBonusSkinAnswer>;
  LoadLootboxesFromDatabase: Scalars['jsonb'];
  ManageUserFirebaseTokenActivity: ManageUserFirebaseTokenActivityAnswer;
  ManageUserMailAlertsActivity: ManageUserMailAlertsActivityAnswer;
  OpenBonusLootbox?: Maybe<Array<Maybe<OpenOutput>>>;
  OpenFreeLootBox?: Maybe<Array<Maybe<OpenOutput>>>;
  OpenLootBox?: Maybe<OpenOutput>;
  OpenLootBoxMass?: Maybe<Array<Maybe<OpenOutput>>>;
  OutUserItem?: Maybe<OutUserItemAnswerModel>;
  ParseImageForItemsFromSteam2: Scalars['String'];
  ParseImagesFromSteam: Scalars['String'];
  ParseItemsFromCsgoMarket1: Scalars['String'];
  ParseItemsMarketDBToItemDB3: Scalars['String'];
  ProcessUserPermMissionProgress?: Maybe<ProcMissionProgressResponse>;
  ReconnectAccountHasuraConn: Scalars['String'];
  ReconnectAnalyzerHasuraConn: Scalars['String'];
  ReconnectAuthorizationHasuraConn: Scalars['String'];
  ReconnectLootboxHasuraConn: Scalars['String'];
  ReconnectQueueHasuraConn: Scalars['String'];
  ReloadHighrollCategory: BackgroundServiceAnswerBaseModel;
  ReloadTrendLootboxes: BackgroundServiceAnswerBaseModel;
  ResetTokensQueue: Scalars['String'];
  RollTMPBWhll: RollUserBonusWheelAnswer;
  RollUserBonusWheel?: Maybe<RollUserBonusWheelAnswer>;
  RollingWheel: RollingWheelRewardAnswer;
  RunDailyJobs: Scalars['String'];
  RunLootboxMassGeneration?: Maybe<MassGeneratorOutput>;
  ScheduleUserMailByUniqueTrigger: ScheduleUserMailByUniqueTriggerAnswer;
  ScheduleUserPushByUniqueTrigger: ScheduleUserPushByUniqueTriggerAnswer;
  SellItem?: Maybe<SellOutput>;
  SendItemToSteam?: Maybe<BuyOutput>;
  SendItemToSteamNew?: Maybe<BuyOutput>;
  SendItemToUser?: Maybe<BuyOutput>;
  SendItemToUserInSteam: SendItemToUserInSteamAnswerModel;
  SendWebPushToTopic: SendWebPushToTopicOutput;
  SendWebPushToUser: SendWebPushToUserOutput;
  SendWssEvent: Scalars['jsonb'];
  /** Проверку выбора пользователя в игре Что лучше */
  Store__CheckChoice?: Maybe<ChoiceValidationResponse>;
  /** Получение подарочных монет в магазине */
  Store__DailyCoinsClaim?: Maybe<DailyCoinsClaimAnswer>;
  /** Покупка предложения в магазине */
  Store__Purchase?: Maybe<AnswerBaseModel>;
  /** Метод покупки предмета в магазине */
  Store__SkinPurchase?: Maybe<SkinPurchaseResponse>;
  TestHasuraRequest: Scalars['String'];
  UpdateItemsForUpgrade: Scalars['String'];
  UpdateTokenBalance?: Maybe<Array<Maybe<UpdateTokenBalanceOutput>>>;
  UpdateTokens?: Maybe<UpdateTokensOutput>;
  UpdateUserHelloBonus: UserHelloBonusClaimOutput;
  UpdateUserMailAlertsActivity: UserMailAlertsUpdateAnswer;
  UpdateUserUpgradeParams: Scalars['String'];
  UpdateUserWebPushActivity: UserWebPushUpdateAnswer;
  UpgradeUserItem?: Maybe<UserUpgradeAnswer>;
  UseRegisterBonus?: Maybe<RegBonusOutput>;
  VerifyUserEmail: VerifyUserEmailAnswer;
  WithdrawUserReferalBalance: WithdrawUserReferalBalanceOutput;
  /** Конечная точка для покупки айтема на бирже */
  buyItem?: Maybe<BuyOutput>;
  /** delete data from the table: "account_items_names" */
  delete_account_items_names?: Maybe<Account_Items_Names_Mutation_Response>;
  /** delete single row from the table: "account_items_names" */
  delete_account_items_names_by_pk?: Maybe<Account_Items_Names>;
  /** delete data from the table: "admin_items_names" */
  delete_admin_items_names?: Maybe<Admin_Items_Names_Mutation_Response>;
  /** delete single row from the table: "admin_items_names" */
  delete_admin_items_names_by_pk?: Maybe<Admin_Items_Names>;
  /** delete data from the table: "admin_user_params" */
  delete_admin_user_params?: Maybe<Admin_User_Params_Mutation_Response>;
  /** delete single row from the table: "admin_user_params" */
  delete_admin_user_params_by_pk?: Maybe<Admin_User_Params>;
  /** delete data from the table: "amp_sessions_data" */
  delete_amp_sessions_data?: Maybe<Amp_Sessions_Data_Mutation_Response>;
  /** delete single row from the table: "amp_sessions_data" */
  delete_amp_sessions_data_by_pk?: Maybe<Amp_Sessions_Data>;
  /** delete data from the table: "banned_ips" */
  delete_banned_ips?: Maybe<Banned_Ips_Mutation_Response>;
  /** delete single row from the table: "banned_ips" */
  delete_banned_ips_by_pk?: Maybe<Banned_Ips>;
  /** delete data from the table: "banned_users_for_multiaccount" */
  delete_banned_users_for_multiaccount?: Maybe<Banned_Users_For_Multiaccount_Mutation_Response>;
  /** delete single row from the table: "banned_users_for_multiaccount" */
  delete_banned_users_for_multiaccount_by_pk?: Maybe<Banned_Users_For_Multiaccount>;
  /** delete data from the table: "bill" */
  delete_bill?: Maybe<Bill_Mutation_Response>;
  /** delete single row from the table: "bill" */
  delete_bill_by_pk?: Maybe<Bill>;
  /** delete data from the table: "bonus_params" */
  delete_bonus_params?: Maybe<Bonus_Params_Mutation_Response>;
  /** delete single row from the table: "bonus_params" */
  delete_bonus_params_by_pk?: Maybe<Bonus_Params>;
  /** delete data from the table: "bonus_wheel_rewards" */
  delete_bonus_wheel_rewards?: Maybe<Bonus_Wheel_Rewards_Mutation_Response>;
  /** delete single row from the table: "bonus_wheel_rewards" */
  delete_bonus_wheel_rewards_by_pk?: Maybe<Bonus_Wheel_Rewards>;
  /** delete data from the table: "bonus_wheel_ticket_type" */
  delete_bonus_wheel_ticket_type?: Maybe<Bonus_Wheel_Ticket_Type_Mutation_Response>;
  /** delete single row from the table: "bonus_wheel_ticket_type" */
  delete_bonus_wheel_ticket_type_by_pk?: Maybe<Bonus_Wheel_Ticket_Type>;
  /** delete data from the table: "bonus_wheel_ticket_type_name_localization" */
  delete_bonus_wheel_ticket_type_name_localization?: Maybe<Bonus_Wheel_Ticket_Type_Name_Localization_Mutation_Response>;
  /** delete single row from the table: "bonus_wheel_ticket_type_name_localization" */
  delete_bonus_wheel_ticket_type_name_localization_by_pk?: Maybe<Bonus_Wheel_Ticket_Type_Name_Localization>;
  /** delete data from the table: "bonus_wheel_user_rewards" */
  delete_bonus_wheel_user_rewards?: Maybe<Bonus_Wheel_User_Rewards_Mutation_Response>;
  /** delete single row from the table: "bonus_wheel_user_rewards" */
  delete_bonus_wheel_user_rewards_by_pk?: Maybe<Bonus_Wheel_User_Rewards>;
  /** delete data from the table: "bonus_wheel_user_tickets" */
  delete_bonus_wheel_user_tickets?: Maybe<Bonus_Wheel_User_Tickets_Mutation_Response>;
  /** delete single row from the table: "bonus_wheel_user_tickets" */
  delete_bonus_wheel_user_tickets_by_pk?: Maybe<Bonus_Wheel_User_Tickets>;
  /** delete data from the table: "bonus_wheels" */
  delete_bonus_wheels?: Maybe<Bonus_Wheels_Mutation_Response>;
  /** delete data from the table: "bonus_wheels_bonus_skins" */
  delete_bonus_wheels_bonus_skins?: Maybe<Bonus_Wheels_Bonus_Skins_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_bonus_skins" */
  delete_bonus_wheels_bonus_skins_by_pk?: Maybe<Bonus_Wheels_Bonus_Skins>;
  /** delete data from the table: "bonus_wheels_bonuses" */
  delete_bonus_wheels_bonuses?: Maybe<Bonus_Wheels_Bonuses_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_bonuses" */
  delete_bonus_wheels_bonuses_by_pk?: Maybe<Bonus_Wheels_Bonuses>;
  /** delete data from the table: "bonus_wheels_bonuses_localization" */
  delete_bonus_wheels_bonuses_localization?: Maybe<Bonus_Wheels_Bonuses_Localization_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_bonuses_localization" */
  delete_bonus_wheels_bonuses_localization_by_pk?: Maybe<Bonus_Wheels_Bonuses_Localization>;
  /** delete data from the table: "bonus_wheels_bonuses_params" */
  delete_bonus_wheels_bonuses_params?: Maybe<Bonus_Wheels_Bonuses_Params_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_bonuses_params" */
  delete_bonus_wheels_bonuses_params_by_pk?: Maybe<Bonus_Wheels_Bonuses_Params>;
  /** delete data from the table: "bonus_wheels_bonuses_params_localization" */
  delete_bonus_wheels_bonuses_params_localization?: Maybe<Bonus_Wheels_Bonuses_Params_Localization_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_bonuses_params_localization" */
  delete_bonus_wheels_bonuses_params_localization_by_pk?: Maybe<Bonus_Wheels_Bonuses_Params_Localization>;
  /** delete single row from the table: "bonus_wheels" */
  delete_bonus_wheels_by_pk?: Maybe<Bonus_Wheels>;
  /** delete data from the table: "bonus_wheels_generator" */
  delete_bonus_wheels_generator?: Maybe<Bonus_Wheels_Generator_Mutation_Response>;
  /** delete data from the table: "bonus_wheels_generator_bonuses" */
  delete_bonus_wheels_generator_bonuses?: Maybe<Bonus_Wheels_Generator_Bonuses_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_generator_bonuses" */
  delete_bonus_wheels_generator_bonuses_by_pk?: Maybe<Bonus_Wheels_Generator_Bonuses>;
  /** delete single row from the table: "bonus_wheels_generator" */
  delete_bonus_wheels_generator_by_pk?: Maybe<Bonus_Wheels_Generator>;
  /** delete data from the table: "bonus_wheels_items_in_wheel" */
  delete_bonus_wheels_items_in_wheel?: Maybe<Bonus_Wheels_Items_In_Wheel_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_items_in_wheel" */
  delete_bonus_wheels_items_in_wheel_by_pk?: Maybe<Bonus_Wheels_Items_In_Wheel>;
  /** delete data from the table: "bonus_wheels_user_check" */
  delete_bonus_wheels_user_check?: Maybe<Bonus_Wheels_User_Check_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_user_check" */
  delete_bonus_wheels_user_check_by_pk?: Maybe<Bonus_Wheels_User_Check>;
  /** delete data from the table: "bonus_wheels_user_check_params" */
  delete_bonus_wheels_user_check_params?: Maybe<Bonus_Wheels_User_Check_Params_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_user_check_params" */
  delete_bonus_wheels_user_check_params_by_pk?: Maybe<Bonus_Wheels_User_Check_Params>;
  /** delete data from the table: "bonus_wheels_user_offers" */
  delete_bonus_wheels_user_offers?: Maybe<Bonus_Wheels_User_Offers_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_user_offers" */
  delete_bonus_wheels_user_offers_by_pk?: Maybe<Bonus_Wheels_User_Offers>;
  /** delete data from the table: "bonus_wheels_user_offers_params" */
  delete_bonus_wheels_user_offers_params?: Maybe<Bonus_Wheels_User_Offers_Params_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_user_offers_params" */
  delete_bonus_wheels_user_offers_params_by_pk?: Maybe<Bonus_Wheels_User_Offers_Params>;
  /** delete data from the table: "bonus_wheels_user_offers_params_localization" */
  delete_bonus_wheels_user_offers_params_localization?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Mutation_Response>;
  /** delete single row from the table: "bonus_wheels_user_offers_params_localization" */
  delete_bonus_wheels_user_offers_params_localization_by_pk?: Maybe<Bonus_Wheels_User_Offers_Params_Localization>;
  /** delete data from the table: "bonuses" */
  delete_bonuses?: Maybe<Bonuses_Mutation_Response>;
  /** delete single row from the table: "bonuses" */
  delete_bonuses_by_pk?: Maybe<Bonuses>;
  /** delete data from the table: "bonuses_register" */
  delete_bonuses_register?: Maybe<Bonuses_Register_Mutation_Response>;
  /** delete single row from the table: "bonuses_register" */
  delete_bonuses_register_by_pk?: Maybe<Bonuses_Register>;
  /** delete data from the table: "bonuses_register_offers" */
  delete_bonuses_register_offers?: Maybe<Bonuses_Register_Offers_Mutation_Response>;
  /** delete single row from the table: "bonuses_register_offers" */
  delete_bonuses_register_offers_by_pk?: Maybe<Bonuses_Register_Offers>;
  /** delete data from the table: "boosters_set_content" */
  delete_boosters_set_content?: Maybe<Boosters_Set_Content_Mutation_Response>;
  /** delete single row from the table: "boosters_set_content" */
  delete_boosters_set_content_by_pk?: Maybe<Boosters_Set_Content>;
  /** delete data from the table: "boosters_sets" */
  delete_boosters_sets?: Maybe<Boosters_Sets_Mutation_Response>;
  /** delete single row from the table: "boosters_sets" */
  delete_boosters_sets_by_pk?: Maybe<Boosters_Sets>;
  /** delete data from the table: "bot_account_settings" */
  delete_bot_account_settings?: Maybe<Bot_Account_Settings_Mutation_Response>;
  /** delete single row from the table: "bot_account_settings" */
  delete_bot_account_settings_by_pk?: Maybe<Bot_Account_Settings>;
  /** delete data from the table: "bots_lootboxes_opens_count" */
  delete_bots_lootboxes_opens_count?: Maybe<Bots_Lootboxes_Opens_Count_Mutation_Response>;
  /** delete single row from the table: "bots_lootboxes_opens_count" */
  delete_bots_lootboxes_opens_count_by_pk?: Maybe<Bots_Lootboxes_Opens_Count>;
  /** delete data from the table: "coins_set_content" */
  delete_coins_set_content?: Maybe<Coins_Set_Content_Mutation_Response>;
  /** delete single row from the table: "coins_set_content" */
  delete_coins_set_content_by_pk?: Maybe<Coins_Set_Content>;
  /** delete data from the table: "coins_sets" */
  delete_coins_sets?: Maybe<Coins_Sets_Mutation_Response>;
  /** delete single row from the table: "coins_sets" */
  delete_coins_sets_by_pk?: Maybe<Coins_Sets>;
  /** delete data from the table: "company" */
  delete_company?: Maybe<Company_Mutation_Response>;
  /** delete single row from the table: "company" */
  delete_company_by_pk?: Maybe<Company>;
  /** delete data from the table: "coupons" */
  delete_coupons?: Maybe<Coupons_Mutation_Response>;
  /** delete single row from the table: "coupons" */
  delete_coupons_by_pk?: Maybe<Coupons>;
  /** delete data from the table: "csgomarket_token_balance" */
  delete_csgomarket_token_balance?: Maybe<Csgomarket_Token_Balance_Mutation_Response>;
  /** delete single row from the table: "csgomarket_token_balance" */
  delete_csgomarket_token_balance_by_pk?: Maybe<Csgomarket_Token_Balance>;
  /** delete data from the table: "csgomarket_tokens" */
  delete_csgomarket_tokens?: Maybe<Csgomarket_Tokens_Mutation_Response>;
  /** delete single row from the table: "csgomarket_tokens" */
  delete_csgomarket_tokens_by_pk?: Maybe<Csgomarket_Tokens>;
  /** delete data from the table: "currency_history" */
  delete_currency_history?: Maybe<Currency_History_Mutation_Response>;
  /** delete single row from the table: "currency_history" */
  delete_currency_history_by_pk?: Maybe<Currency_History>;
  /** delete data from the table: "currencys" */
  delete_currencys?: Maybe<Currencys_Mutation_Response>;
  /** delete single row from the table: "currencys" */
  delete_currencys_by_pk?: Maybe<Currencys>;
  /** delete data from the table: "daily_deposit_sum" */
  delete_daily_deposit_sum?: Maybe<Daily_Deposit_Sum_Mutation_Response>;
  /** delete single row from the table: "daily_deposit_sum" */
  delete_daily_deposit_sum_by_pk?: Maybe<Daily_Deposit_Sum>;
  /** delete data from the table: "emails_from_upgrade_question" */
  delete_emails_from_upgrade_question?: Maybe<Emails_From_Upgrade_Question_Mutation_Response>;
  /** delete single row from the table: "emails_from_upgrade_question" */
  delete_emails_from_upgrade_question_by_pk?: Maybe<Emails_From_Upgrade_Question>;
  /** delete data from the table: "execution_methods" */
  delete_execution_methods?: Maybe<Execution_Methods_Mutation_Response>;
  /** delete single row from the table: "execution_methods" */
  delete_execution_methods_by_pk?: Maybe<Execution_Methods>;
  /** delete data from the table: "faq_table" */
  delete_faq_table?: Maybe<Faq_Table_Mutation_Response>;
  /** delete single row from the table: "faq_table" */
  delete_faq_table_by_pk?: Maybe<Faq_Table>;
  /** delete data from the table: "fixed_items_generator_boosters" */
  delete_fixed_items_generator_boosters?: Maybe<Fixed_Items_Generator_Boosters_Mutation_Response>;
  /** delete single row from the table: "fixed_items_generator_boosters" */
  delete_fixed_items_generator_boosters_by_pk?: Maybe<Fixed_Items_Generator_Boosters>;
  /** delete data from the table: "fixed_items_generator_items" */
  delete_fixed_items_generator_items?: Maybe<Fixed_Items_Generator_Items_Mutation_Response>;
  /** delete single row from the table: "fixed_items_generator_items" */
  delete_fixed_items_generator_items_by_pk?: Maybe<Fixed_Items_Generator_Items>;
  /** delete data from the table: "fixed_items_generator_lootboxes" */
  delete_fixed_items_generator_lootboxes?: Maybe<Fixed_Items_Generator_Lootboxes_Mutation_Response>;
  /** delete single row from the table: "fixed_items_generator_lootboxes" */
  delete_fixed_items_generator_lootboxes_by_pk?: Maybe<Fixed_Items_Generator_Lootboxes>;
  /** delete data from the table: "giveaway_members" */
  delete_giveaway_members?: Maybe<Giveaway_Members_Mutation_Response>;
  /** delete single row from the table: "giveaway_members" */
  delete_giveaway_members_by_pk?: Maybe<Giveaway_Members>;
  /** delete data from the table: "giveaway_params" */
  delete_giveaway_params?: Maybe<Giveaway_Params_Mutation_Response>;
  /** delete single row from the table: "giveaway_params" */
  delete_giveaway_params_by_pk?: Maybe<Giveaway_Params>;
  /** delete data from the table: "giveaways" */
  delete_giveaways?: Maybe<Giveaways_Mutation_Response>;
  /** delete single row from the table: "giveaways" */
  delete_giveaways_by_pk?: Maybe<Giveaways>;
  /** delete data from the table: "group_items_csgomarket" */
  delete_group_items_csgomarket?: Maybe<Group_Items_Csgomarket_Mutation_Response>;
  /** delete single row from the table: "group_items_csgomarket" */
  delete_group_items_csgomarket_by_pk?: Maybe<Group_Items_Csgomarket>;
  /** delete data from the table: "history_group_items_csgomarket" */
  delete_history_group_items_csgomarket?: Maybe<History_Group_Items_Csgomarket_Mutation_Response>;
  /** delete single row from the table: "history_group_items_csgomarket" */
  delete_history_group_items_csgomarket_by_pk?: Maybe<History_Group_Items_Csgomarket>;
  /** delete data from the table: "history_items_csgomarket" */
  delete_history_items_csgomarket?: Maybe<History_Items_Csgomarket_Mutation_Response>;
  /** delete single row from the table: "history_items_csgomarket" */
  delete_history_items_csgomarket_by_pk?: Maybe<History_Items_Csgomarket>;
  /** delete data from the table: "item_out_transactions" */
  delete_item_out_transactions?: Maybe<Item_Out_Transactions_Mutation_Response>;
  /** delete single row from the table: "item_out_transactions" */
  delete_item_out_transactions_by_pk?: Maybe<Item_Out_Transactions>;
  /** delete data from the table: "items" */
  delete_items?: Maybe<Items_Mutation_Response>;
  /** delete single row from the table: "items" */
  delete_items_by_pk?: Maybe<Items>;
  /** delete data from the table: "items_csgomarket" */
  delete_items_csgomarket?: Maybe<Items_Csgomarket_Mutation_Response>;
  /** delete single row from the table: "items_csgomarket" */
  delete_items_csgomarket_by_pk?: Maybe<Items_Csgomarket>;
  /** delete data from the table: "items_drops" */
  delete_items_drops?: Maybe<Items_Drops_Mutation_Response>;
  /** delete single row from the table: "items_drops" */
  delete_items_drops_by_pk?: Maybe<Items_Drops>;
  /** delete data from the table: "items_for_upgrade" */
  delete_items_for_upgrade?: Maybe<Items_For_Upgrade_Mutation_Response>;
  /** delete single row from the table: "items_for_upgrade" */
  delete_items_for_upgrade_by_pk?: Maybe<Items_For_Upgrade>;
  /** delete data from the table: "items_history" */
  delete_items_history?: Maybe<Items_History_Mutation_Response>;
  /** delete single row from the table: "items_history" */
  delete_items_history_by_pk?: Maybe<Items_History>;
  /** delete data from the table: "items_in_lootbox" */
  delete_items_in_lootbox?: Maybe<Items_In_Lootbox_Mutation_Response>;
  /** delete single row from the table: "items_in_lootbox" */
  delete_items_in_lootbox_by_pk?: Maybe<Items_In_Lootbox>;
  /** delete data from the table: "items_in_lootbox_view" */
  delete_items_in_lootbox_view?: Maybe<Items_In_Lootbox_View_Mutation_Response>;
  /** delete single row from the table: "items_in_lootbox_view" */
  delete_items_in_lootbox_view_by_pk?: Maybe<Items_In_Lootbox_View>;
  /** delete data from the table: "items_items_names" */
  delete_items_items_names?: Maybe<Items_Items_Names_Mutation_Response>;
  /** delete single row from the table: "items_items_names" */
  delete_items_items_names_by_pk?: Maybe<Items_Items_Names>;
  /** delete data from the table: "items_localization" */
  delete_items_localization?: Maybe<Items_Localization_Mutation_Response>;
  /** delete single row from the table: "items_localization" */
  delete_items_localization_by_pk?: Maybe<Items_Localization>;
  /** delete data from the table: "items_names" */
  delete_items_names?: Maybe<Items_Names_Mutation_Response>;
  /** delete single row from the table: "items_names" */
  delete_items_names_by_pk?: Maybe<Items_Names>;
  /** delete data from the table: "items_quality" */
  delete_items_quality?: Maybe<Items_Quality_Mutation_Response>;
  /** delete single row from the table: "items_quality" */
  delete_items_quality_by_pk?: Maybe<Items_Quality>;
  /** delete data from the table: "items_quality_localization" */
  delete_items_quality_localization?: Maybe<Items_Quality_Localization_Mutation_Response>;
  /** delete single row from the table: "items_quality_localization" */
  delete_items_quality_localization_by_pk?: Maybe<Items_Quality_Localization>;
  /** delete data from the table: "items_rarity" */
  delete_items_rarity?: Maybe<Items_Rarity_Mutation_Response>;
  /** delete single row from the table: "items_rarity" */
  delete_items_rarity_by_pk?: Maybe<Items_Rarity>;
  /** delete data from the table: "items_rarity_localization" */
  delete_items_rarity_localization?: Maybe<Items_Rarity_Localization_Mutation_Response>;
  /** delete single row from the table: "items_rarity_localization" */
  delete_items_rarity_localization_by_pk?: Maybe<Items_Rarity_Localization>;
  /** delete data from the table: "items_type" */
  delete_items_type?: Maybe<Items_Type_Mutation_Response>;
  /** delete single row from the table: "items_type" */
  delete_items_type_by_pk?: Maybe<Items_Type>;
  /** delete data from the table: "items_type_localization" */
  delete_items_type_localization?: Maybe<Items_Type_Localization_Mutation_Response>;
  /** delete single row from the table: "items_type_localization" */
  delete_items_type_localization_by_pk?: Maybe<Items_Type_Localization>;
  /** delete data from the table: "items_weapon_localization" */
  delete_items_weapon_localization?: Maybe<Items_Weapon_Localization_Mutation_Response>;
  /** delete single row from the table: "items_weapon_localization" */
  delete_items_weapon_localization_by_pk?: Maybe<Items_Weapon_Localization>;
  /** delete data from the table: "items_weapons" */
  delete_items_weapons?: Maybe<Items_Weapons_Mutation_Response>;
  /** delete single row from the table: "items_weapons" */
  delete_items_weapons_by_pk?: Maybe<Items_Weapons>;
  /** delete data from the table: "lootbox_booster_items" */
  delete_lootbox_booster_items?: Maybe<Lootbox_Booster_Items_Mutation_Response>;
  /** delete single row from the table: "lootbox_booster_items" */
  delete_lootbox_booster_items_by_pk?: Maybe<Lootbox_Booster_Items>;
  /** delete data from the table: "lootbox_booster_localization" */
  delete_lootbox_booster_localization?: Maybe<Lootbox_Booster_Localization_Mutation_Response>;
  /** delete single row from the table: "lootbox_booster_localization" */
  delete_lootbox_booster_localization_by_pk?: Maybe<Lootbox_Booster_Localization>;
  /** delete data from the table: "lootbox_category" */
  delete_lootbox_category?: Maybe<Lootbox_Category_Mutation_Response>;
  /** delete single row from the table: "lootbox_category" */
  delete_lootbox_category_by_pk?: Maybe<Lootbox_Category>;
  /** delete data from the table: "lootbox_items_sets" */
  delete_lootbox_items_sets?: Maybe<Lootbox_Items_Sets_Mutation_Response>;
  /** delete single row from the table: "lootbox_items_sets" */
  delete_lootbox_items_sets_by_pk?: Maybe<Lootbox_Items_Sets>;
  /** delete data from the table: "lootbox_service" */
  delete_lootbox_service?: Maybe<Lootbox_Service_Mutation_Response>;
  /** delete single row from the table: "lootbox_service" */
  delete_lootbox_service_by_pk?: Maybe<Lootbox_Service>;
  /** delete data from the table: "lootboxes" */
  delete_lootboxes?: Maybe<Lootboxes_Mutation_Response>;
  /** delete data from the table: "lootboxes_available_statuses" */
  delete_lootboxes_available_statuses?: Maybe<Lootboxes_Available_Statuses_Mutation_Response>;
  /** delete single row from the table: "lootboxes_available_statuses" */
  delete_lootboxes_available_statuses_by_pk?: Maybe<Lootboxes_Available_Statuses>;
  /** delete data from the table: "lootboxes_bonus" */
  delete_lootboxes_bonus?: Maybe<Lootboxes_Bonus_Mutation_Response>;
  /** delete single row from the table: "lootboxes_bonus" */
  delete_lootboxes_bonus_by_pk?: Maybe<Lootboxes_Bonus>;
  /** delete data from the table: "lootboxes_bonus_generator_items" */
  delete_lootboxes_bonus_generator_items?: Maybe<Lootboxes_Bonus_Generator_Items_Mutation_Response>;
  /** delete single row from the table: "lootboxes_bonus_generator_items" */
  delete_lootboxes_bonus_generator_items_by_pk?: Maybe<Lootboxes_Bonus_Generator_Items>;
  /** delete data from the table: "lootboxes_bonus_generator_params" */
  delete_lootboxes_bonus_generator_params?: Maybe<Lootboxes_Bonus_Generator_Params_Mutation_Response>;
  /** delete single row from the table: "lootboxes_bonus_generator_params" */
  delete_lootboxes_bonus_generator_params_by_pk?: Maybe<Lootboxes_Bonus_Generator_Params>;
  /** delete data from the table: "lootboxes_bonus_items" */
  delete_lootboxes_bonus_items?: Maybe<Lootboxes_Bonus_Items_Mutation_Response>;
  /** delete single row from the table: "lootboxes_bonus_items" */
  delete_lootboxes_bonus_items_by_pk?: Maybe<Lootboxes_Bonus_Items>;
  /** delete data from the table: "lootboxes_bonus_views" */
  delete_lootboxes_bonus_views?: Maybe<Lootboxes_Bonus_Views_Mutation_Response>;
  /** delete single row from the table: "lootboxes_bonus_views" */
  delete_lootboxes_bonus_views_by_pk?: Maybe<Lootboxes_Bonus_Views>;
  /** delete single row from the table: "lootboxes" */
  delete_lootboxes_by_pk?: Maybe<Lootboxes>;
  /** delete data from the table: "lootboxes_dynamic_category" */
  delete_lootboxes_dynamic_category?: Maybe<Lootboxes_Dynamic_Category_Mutation_Response>;
  /** delete single row from the table: "lootboxes_dynamic_category" */
  delete_lootboxes_dynamic_category_by_pk?: Maybe<Lootboxes_Dynamic_Category>;
  /** delete data from the table: "lootboxes_dynamic_category_lootboxes" */
  delete_lootboxes_dynamic_category_lootboxes?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Mutation_Response>;
  /** delete single row from the table: "lootboxes_dynamic_category_lootboxes" */
  delete_lootboxes_dynamic_category_lootboxes_by_pk?: Maybe<Lootboxes_Dynamic_Category_Lootboxes>;
  /** delete data from the table: "lootboxes_free" */
  delete_lootboxes_free?: Maybe<Lootboxes_Free_Mutation_Response>;
  /** delete single row from the table: "lootboxes_free" */
  delete_lootboxes_free_by_pk?: Maybe<Lootboxes_Free>;
  /** delete data from the table: "lootboxes_free_category" */
  delete_lootboxes_free_category?: Maybe<Lootboxes_Free_Category_Mutation_Response>;
  /** delete single row from the table: "lootboxes_free_category" */
  delete_lootboxes_free_category_by_pk?: Maybe<Lootboxes_Free_Category>;
  /** delete data from the table: "lootboxes_free_generator_items" */
  delete_lootboxes_free_generator_items?: Maybe<Lootboxes_Free_Generator_Items_Mutation_Response>;
  /** delete single row from the table: "lootboxes_free_generator_items" */
  delete_lootboxes_free_generator_items_by_pk?: Maybe<Lootboxes_Free_Generator_Items>;
  /** delete data from the table: "lootboxes_free_generator_params" */
  delete_lootboxes_free_generator_params?: Maybe<Lootboxes_Free_Generator_Params_Mutation_Response>;
  /** delete single row from the table: "lootboxes_free_generator_params" */
  delete_lootboxes_free_generator_params_by_pk?: Maybe<Lootboxes_Free_Generator_Params>;
  /** delete data from the table: "lootboxes_free_items" */
  delete_lootboxes_free_items?: Maybe<Lootboxes_Free_Items_Mutation_Response>;
  /** delete single row from the table: "lootboxes_free_items" */
  delete_lootboxes_free_items_by_pk?: Maybe<Lootboxes_Free_Items>;
  /** delete data from the table: "lootboxes_free_views" */
  delete_lootboxes_free_views?: Maybe<Lootboxes_Free_Views_Mutation_Response>;
  /** delete single row from the table: "lootboxes_free_views" */
  delete_lootboxes_free_views_by_pk?: Maybe<Lootboxes_Free_Views>;
  /** delete data from the table: "lootboxes_generator_mechanics" */
  delete_lootboxes_generator_mechanics?: Maybe<Lootboxes_Generator_Mechanics_Mutation_Response>;
  /** delete single row from the table: "lootboxes_generator_mechanics" */
  delete_lootboxes_generator_mechanics_by_pk?: Maybe<Lootboxes_Generator_Mechanics>;
  /** delete data from the table: "lootboxes_generator_params" */
  delete_lootboxes_generator_params?: Maybe<Lootboxes_Generator_Params_Mutation_Response>;
  /** delete single row from the table: "lootboxes_generator_params" */
  delete_lootboxes_generator_params_by_pk?: Maybe<Lootboxes_Generator_Params>;
  /** delete data from the table: "lootboxes_history" */
  delete_lootboxes_history?: Maybe<Lootboxes_History_Mutation_Response>;
  /** delete single row from the table: "lootboxes_history" */
  delete_lootboxes_history_by_pk?: Maybe<Lootboxes_History>;
  /** delete data from the table: "lootboxes_items_names" */
  delete_lootboxes_items_names?: Maybe<Lootboxes_Items_Names_Mutation_Response>;
  /** delete single row from the table: "lootboxes_items_names" */
  delete_lootboxes_items_names_by_pk?: Maybe<Lootboxes_Items_Names>;
  /** delete data from the table: "lootboxes_top_drop" */
  delete_lootboxes_top_drop?: Maybe<Lootboxes_Top_Drop_Mutation_Response>;
  /** delete single row from the table: "lootboxes_top_drop" */
  delete_lootboxes_top_drop_by_pk?: Maybe<Lootboxes_Top_Drop>;
  /** delete data from the table: "mail_autosending_types" */
  delete_mail_autosending_types?: Maybe<Mail_Autosending_Types_Mutation_Response>;
  /** delete single row from the table: "mail_autosending_types" */
  delete_mail_autosending_types_by_pk?: Maybe<Mail_Autosending_Types>;
  /** delete data from the table: "mail_bonuses" */
  delete_mail_bonuses?: Maybe<Mail_Bonuses_Mutation_Response>;
  /** delete single row from the table: "mail_bonuses" */
  delete_mail_bonuses_by_pk?: Maybe<Mail_Bonuses>;
  /** delete data from the table: "mail_bonuses_offers" */
  delete_mail_bonuses_offers?: Maybe<Mail_Bonuses_Offers_Mutation_Response>;
  /** delete single row from the table: "mail_bonuses_offers" */
  delete_mail_bonuses_offers_by_pk?: Maybe<Mail_Bonuses_Offers>;
  /** delete data from the table: "mail_mailing_callbacks" */
  delete_mail_mailing_callbacks?: Maybe<Mail_Mailing_Callbacks_Mutation_Response>;
  /** delete single row from the table: "mail_mailing_callbacks" */
  delete_mail_mailing_callbacks_by_pk?: Maybe<Mail_Mailing_Callbacks>;
  /** delete data from the table: "mail_mailing_lines" */
  delete_mail_mailing_lines?: Maybe<Mail_Mailing_Lines_Mutation_Response>;
  /** delete single row from the table: "mail_mailing_lines" */
  delete_mail_mailing_lines_by_pk?: Maybe<Mail_Mailing_Lines>;
  /** delete data from the table: "mail_mailing_lists" */
  delete_mail_mailing_lists?: Maybe<Mail_Mailing_Lists_Mutation_Response>;
  /** delete single row from the table: "mail_mailing_lists" */
  delete_mail_mailing_lists_by_pk?: Maybe<Mail_Mailing_Lists>;
  /** delete data from the table: "mail_mailing_scheduling" */
  delete_mail_mailing_scheduling?: Maybe<Mail_Mailing_Scheduling_Mutation_Response>;
  /** delete single row from the table: "mail_mailing_scheduling" */
  delete_mail_mailing_scheduling_by_pk?: Maybe<Mail_Mailing_Scheduling>;
  /** delete data from the table: "mail_mailing_sending_log" */
  delete_mail_mailing_sending_log?: Maybe<Mail_Mailing_Sending_Log_Mutation_Response>;
  /** delete single row from the table: "mail_mailing_sending_log" */
  delete_mail_mailing_sending_log_by_pk?: Maybe<Mail_Mailing_Sending_Log>;
  /** delete data from the table: "mail_mailing_triggers" */
  delete_mail_mailing_triggers?: Maybe<Mail_Mailing_Triggers_Mutation_Response>;
  /** delete single row from the table: "mail_mailing_triggers" */
  delete_mail_mailing_triggers_by_pk?: Maybe<Mail_Mailing_Triggers>;
  /** delete data from the table: "mail_mailing_triggers_groups" */
  delete_mail_mailing_triggers_groups?: Maybe<Mail_Mailing_Triggers_Groups_Mutation_Response>;
  /** delete single row from the table: "mail_mailing_triggers_groups" */
  delete_mail_mailing_triggers_groups_by_pk?: Maybe<Mail_Mailing_Triggers_Groups>;
  /** delete data from the table: "mail_templates" */
  delete_mail_templates?: Maybe<Mail_Templates_Mutation_Response>;
  /** delete single row from the table: "mail_templates" */
  delete_mail_templates_by_pk?: Maybe<Mail_Templates>;
  /** delete data from the table: "mainpage_counters" */
  delete_mainpage_counters?: Maybe<Mainpage_Counters_Mutation_Response>;
  /** delete single row from the table: "mainpage_counters" */
  delete_mainpage_counters_by_pk?: Maybe<Mainpage_Counters>;
  /** delete data from the table: "maintenance_mode" */
  delete_maintenance_mode?: Maybe<Maintenance_Mode_Mutation_Response>;
  /** delete single row from the table: "maintenance_mode" */
  delete_maintenance_mode_by_pk?: Maybe<Maintenance_Mode>;
  /** delete data from the table: "market_groups_conn" */
  delete_market_groups_conn?: Maybe<Market_Groups_Conn_Mutation_Response>;
  /** delete single row from the table: "market_groups_conn" */
  delete_market_groups_conn_by_pk?: Maybe<Market_Groups_Conn>;
  /** delete data from the table: "metas" */
  delete_metas?: Maybe<Metas_Mutation_Response>;
  /** delete single row from the table: "metas" */
  delete_metas_by_pk?: Maybe<Metas>;
  /** delete data from the table: "missions" */
  delete_missions?: Maybe<Missions_Mutation_Response>;
  /** delete single row from the table: "missions" */
  delete_missions_by_pk?: Maybe<Missions>;
  /** delete data from the table: "missions_onetime_bonus_case_claims" */
  delete_missions_onetime_bonus_case_claims?: Maybe<Missions_Onetime_Bonus_Case_Claims_Mutation_Response>;
  /** delete single row from the table: "missions_onetime_bonus_case_claims" */
  delete_missions_onetime_bonus_case_claims_by_pk?: Maybe<Missions_Onetime_Bonus_Case_Claims>;
  /** delete data from the table: "missions_onetime_params" */
  delete_missions_onetime_params?: Maybe<Missions_Onetime_Params_Mutation_Response>;
  /** delete single row from the table: "missions_onetime_params" */
  delete_missions_onetime_params_by_pk?: Maybe<Missions_Onetime_Params>;
  /** delete data from the table: "missions_onetime_users_progress" */
  delete_missions_onetime_users_progress?: Maybe<Missions_Onetime_Users_Progress_Mutation_Response>;
  /** delete single row from the table: "missions_onetime_users_progress" */
  delete_missions_onetime_users_progress_by_pk?: Maybe<Missions_Onetime_Users_Progress>;
  /** delete data from the table: "missions_permanent_params" */
  delete_missions_permanent_params?: Maybe<Missions_Permanent_Params_Mutation_Response>;
  /** delete single row from the table: "missions_permanent_params" */
  delete_missions_permanent_params_by_pk?: Maybe<Missions_Permanent_Params>;
  /** delete data from the table: "missions_permanent_user_progress" */
  delete_missions_permanent_user_progress?: Maybe<Missions_Permanent_User_Progress_Mutation_Response>;
  /** delete single row from the table: "missions_permanent_user_progress" */
  delete_missions_permanent_user_progress_by_pk?: Maybe<Missions_Permanent_User_Progress>;
  /** delete data from the table: "missions_tags" */
  delete_missions_tags?: Maybe<Missions_Tags_Mutation_Response>;
  /** delete single row from the table: "missions_tags" */
  delete_missions_tags_by_pk?: Maybe<Missions_Tags>;
  /** delete data from the table: "missions_tags_names" */
  delete_missions_tags_names?: Maybe<Missions_Tags_Names_Mutation_Response>;
  /** delete single row from the table: "missions_tags_names" */
  delete_missions_tags_names_by_pk?: Maybe<Missions_Tags_Names>;
  /** delete data from the table: "missions_vk_links" */
  delete_missions_vk_links?: Maybe<Missions_Vk_Links_Mutation_Response>;
  /** delete single row from the table: "missions_vk_links" */
  delete_missions_vk_links_by_pk?: Maybe<Missions_Vk_Links>;
  /** delete data from the table: "new_year_competition_boosters_rewards" */
  delete_new_year_competition_boosters_rewards?: Maybe<New_Year_Competition_Boosters_Rewards_Mutation_Response>;
  /** delete single row from the table: "new_year_competition_boosters_rewards" */
  delete_new_year_competition_boosters_rewards_by_pk?: Maybe<New_Year_Competition_Boosters_Rewards>;
  /** delete data from the table: "new_year_competition_participants" */
  delete_new_year_competition_participants?: Maybe<New_Year_Competition_Participants_Mutation_Response>;
  /** delete single row from the table: "new_year_competition_participants" */
  delete_new_year_competition_participants_by_pk?: Maybe<New_Year_Competition_Participants>;
  /** delete data from the table: "new_year_competition_participants_performance" */
  delete_new_year_competition_participants_performance?: Maybe<New_Year_Competition_Participants_Performance_Mutation_Response>;
  /** delete single row from the table: "new_year_competition_participants_performance" */
  delete_new_year_competition_participants_performance_by_pk?: Maybe<New_Year_Competition_Participants_Performance>;
  /** delete data from the table: "new_year_competition_teams" */
  delete_new_year_competition_teams?: Maybe<New_Year_Competition_Teams_Mutation_Response>;
  /** delete single row from the table: "new_year_competition_teams" */
  delete_new_year_competition_teams_by_pk?: Maybe<New_Year_Competition_Teams>;
  /** delete data from the table: "operation_types" */
  delete_operation_types?: Maybe<Operation_Types_Mutation_Response>;
  /** delete single row from the table: "operation_types" */
  delete_operation_types_by_pk?: Maybe<Operation_Types>;
  /** delete data from the table: "parsers_timestamp_log" */
  delete_parsers_timestamp_log?: Maybe<Parsers_Timestamp_Log_Mutation_Response>;
  /** delete single row from the table: "parsers_timestamp_log" */
  delete_parsers_timestamp_log_by_pk?: Maybe<Parsers_Timestamp_Log>;
  /** delete data from the table: "partner_balance_transactions" */
  delete_partner_balance_transactions?: Maybe<Partner_Balance_Transactions_Mutation_Response>;
  /** delete single row from the table: "partner_balance_transactions" */
  delete_partner_balance_transactions_by_pk?: Maybe<Partner_Balance_Transactions>;
  /** delete data from the table: "partner_offer_types" */
  delete_partner_offer_types?: Maybe<Partner_Offer_Types_Mutation_Response>;
  /** delete single row from the table: "partner_offer_types" */
  delete_partner_offer_types_by_pk?: Maybe<Partner_Offer_Types>;
  /** delete data from the table: "partner_params" */
  delete_partner_params?: Maybe<Partner_Params_Mutation_Response>;
  /** delete data from the table: "partner_params_audit" */
  delete_partner_params_audit?: Maybe<Partner_Params_Audit_Mutation_Response>;
  /** delete single row from the table: "partner_params_audit" */
  delete_partner_params_audit_by_pk?: Maybe<Partner_Params_Audit>;
  /** delete single row from the table: "partner_params" */
  delete_partner_params_by_pk?: Maybe<Partner_Params>;
  /** delete data from the table: "passport_info" */
  delete_passport_info?: Maybe<Passport_Info_Mutation_Response>;
  /** delete single row from the table: "passport_info" */
  delete_passport_info_by_pk?: Maybe<Passport_Info>;
  /** delete data from the table: "payment_methods" */
  delete_payment_methods?: Maybe<Payment_Methods_Mutation_Response>;
  /** delete single row from the table: "payment_methods" */
  delete_payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** delete data from the table: "payment_methods_view" */
  delete_payment_methods_view?: Maybe<Payment_Methods_View_Mutation_Response>;
  /** delete single row from the table: "payment_methods_view" */
  delete_payment_methods_view_by_pk?: Maybe<Payment_Methods_View>;
  /** delete data from the table: "payment_providers" */
  delete_payment_providers?: Maybe<Payment_Providers_Mutation_Response>;
  /** delete single row from the table: "payment_providers" */
  delete_payment_providers_by_pk?: Maybe<Payment_Providers>;
  /** delete data from the table: "payment_recurring_tokens" */
  delete_payment_recurring_tokens?: Maybe<Payment_Recurring_Tokens_Mutation_Response>;
  /** delete single row from the table: "payment_recurring_tokens" */
  delete_payment_recurring_tokens_by_pk?: Maybe<Payment_Recurring_Tokens>;
  /** delete data from the table: "payment_requisites" */
  delete_payment_requisites?: Maybe<Payment_Requisites_Mutation_Response>;
  /** delete single row from the table: "payment_requisites" */
  delete_payment_requisites_by_pk?: Maybe<Payment_Requisites>;
  /** delete data from the table: "payment_system_1payment_transaction_requests" */
  delete_payment_system_1payment_transaction_requests?: Maybe<Payment_System_1payment_Transaction_Requests_Mutation_Response>;
  /** delete single row from the table: "payment_system_1payment_transaction_requests" */
  delete_payment_system_1payment_transaction_requests_by_pk?: Maybe<Payment_System_1payment_Transaction_Requests>;
  /** delete data from the table: "payment_system_free_kassa_callbacks" */
  delete_payment_system_free_kassa_callbacks?: Maybe<Payment_System_Free_Kassa_Callbacks_Mutation_Response>;
  /** delete single row from the table: "payment_system_free_kassa_callbacks" */
  delete_payment_system_free_kassa_callbacks_by_pk?: Maybe<Payment_System_Free_Kassa_Callbacks>;
  /** delete data from the table: "payment_system_free_kassa_transaction_requests" */
  delete_payment_system_free_kassa_transaction_requests?: Maybe<Payment_System_Free_Kassa_Transaction_Requests_Mutation_Response>;
  /** delete single row from the table: "payment_system_free_kassa_transaction_requests" */
  delete_payment_system_free_kassa_transaction_requests_by_pk?: Maybe<Payment_System_Free_Kassa_Transaction_Requests>;
  /** delete data from the table: "payment_system_main_callbacks" */
  delete_payment_system_main_callbacks?: Maybe<Payment_System_Main_Callbacks_Mutation_Response>;
  /** delete single row from the table: "payment_system_main_callbacks" */
  delete_payment_system_main_callbacks_by_pk?: Maybe<Payment_System_Main_Callbacks>;
  /** delete data from the table: "payment_system_main_transaction_requests" */
  delete_payment_system_main_transaction_requests?: Maybe<Payment_System_Main_Transaction_Requests_Mutation_Response>;
  /** delete single row from the table: "payment_system_main_transaction_requests" */
  delete_payment_system_main_transaction_requests_by_pk?: Maybe<Payment_System_Main_Transaction_Requests>;
  /** delete data from the table: "payment_systems_1payment_callbacks" */
  delete_payment_systems_1payment_callbacks?: Maybe<Payment_Systems_1payment_Callbacks_Mutation_Response>;
  /** delete single row from the table: "payment_systems_1payment_callbacks" */
  delete_payment_systems_1payment_callbacks_by_pk?: Maybe<Payment_Systems_1payment_Callbacks>;
  /** delete data from the table: "payment_systems_callbacks" */
  delete_payment_systems_callbacks?: Maybe<Payment_Systems_Callbacks_Mutation_Response>;
  /** delete single row from the table: "payment_systems_callbacks" */
  delete_payment_systems_callbacks_by_pk?: Maybe<Payment_Systems_Callbacks>;
  /** delete data from the table: "payment_systems_data" */
  delete_payment_systems_data?: Maybe<Payment_Systems_Data_Mutation_Response>;
  /** delete single row from the table: "payment_systems_data" */
  delete_payment_systems_data_by_pk?: Maybe<Payment_Systems_Data>;
  /** delete data from the table: "payment_systems_methods" */
  delete_payment_systems_methods?: Maybe<Payment_Systems_Methods_Mutation_Response>;
  /** delete single row from the table: "payment_systems_methods" */
  delete_payment_systems_methods_by_pk?: Maybe<Payment_Systems_Methods>;
  /** delete data from the table: "payment_systems_transaction_requests" */
  delete_payment_systems_transaction_requests?: Maybe<Payment_Systems_Transaction_Requests_Mutation_Response>;
  /** delete single row from the table: "payment_systems_transaction_requests" */
  delete_payment_systems_transaction_requests_by_pk?: Maybe<Payment_Systems_Transaction_Requests>;
  /** delete data from the table: "payment_transactions" */
  delete_payment_transactions?: Maybe<Payment_Transactions_Mutation_Response>;
  /** delete single row from the table: "payment_transactions" */
  delete_payment_transactions_by_pk?: Maybe<Payment_Transactions>;
  /** delete data from the table: "profile_items_names" */
  delete_profile_items_names?: Maybe<Profile_Items_Names_Mutation_Response>;
  /** delete single row from the table: "profile_items_names" */
  delete_profile_items_names_by_pk?: Maybe<Profile_Items_Names>;
  /** delete data from the table: "promo_lootboxes" */
  delete_promo_lootboxes?: Maybe<Promo_Lootboxes_Mutation_Response>;
  /** delete single row from the table: "promo_lootboxes" */
  delete_promo_lootboxes_by_pk?: Maybe<Promo_Lootboxes>;
  /** delete data from the table: "promo_users" */
  delete_promo_users?: Maybe<Promo_Users_Mutation_Response>;
  /** delete single row from the table: "promo_users" */
  delete_promo_users_by_pk?: Maybe<Promo_Users>;
  /** delete data from the table: "promo_values" */
  delete_promo_values?: Maybe<Promo_Values_Mutation_Response>;
  /** delete single row from the table: "promo_values" */
  delete_promo_values_by_pk?: Maybe<Promo_Values>;
  /** delete data from the table: "promocode_activations" */
  delete_promocode_activations?: Maybe<Promocode_Activations_Mutation_Response>;
  /** delete single row from the table: "promocode_activations" */
  delete_promocode_activations_by_pk?: Maybe<Promocode_Activations>;
  /** delete data from the table: "promocodes" */
  delete_promocodes?: Maybe<Promocodes_Mutation_Response>;
  /** delete single row from the table: "promocodes" */
  delete_promocodes_by_pk?: Maybe<Promocodes>;
  /** delete data from the table: "ps_types" */
  delete_ps_types?: Maybe<Ps_Types_Mutation_Response>;
  /** delete single row from the table: "ps_types" */
  delete_ps_types_by_pk?: Maybe<Ps_Types>;
  /** delete data from the table: "qservice_data" */
  delete_qservice_data?: Maybe<Qservice_Data_Mutation_Response>;
  /** delete single row from the table: "qservice_data" */
  delete_qservice_data_by_pk?: Maybe<Qservice_Data>;
  /** delete data from the table: "statistic_bonus_wheels_users_offers" */
  delete_statistic_bonus_wheels_users_offers?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Mutation_Response>;
  /** delete single row from the table: "statistic_bonus_wheels_users_offers" */
  delete_statistic_bonus_wheels_users_offers_by_pk?: Maybe<Statistic_Bonus_Wheels_Users_Offers>;
  /** delete data from the table: "statistic_button_again_press" */
  delete_statistic_button_again_press?: Maybe<Statistic_Button_Again_Press_Mutation_Response>;
  /** delete single row from the table: "statistic_button_again_press" */
  delete_statistic_button_again_press_by_pk?: Maybe<Statistic_Button_Again_Press>;
  /** delete data from the table: "statistic_fast_roll_trigger" */
  delete_statistic_fast_roll_trigger?: Maybe<Statistic_Fast_Roll_Trigger_Mutation_Response>;
  /** delete single row from the table: "statistic_fast_roll_trigger" */
  delete_statistic_fast_roll_trigger_by_pk?: Maybe<Statistic_Fast_Roll_Trigger>;
  /** delete data from the table: "statistic_inventory_opens" */
  delete_statistic_inventory_opens?: Maybe<Statistic_Inventory_Opens_Mutation_Response>;
  /** delete single row from the table: "statistic_inventory_opens" */
  delete_statistic_inventory_opens_by_pk?: Maybe<Statistic_Inventory_Opens>;
  /** delete data from the table: "statistic_items_drops" */
  delete_statistic_items_drops?: Maybe<Statistic_Items_Drops_Mutation_Response>;
  /** delete single row from the table: "statistic_items_drops" */
  delete_statistic_items_drops_by_pk?: Maybe<Statistic_Items_Drops>;
  /** delete data from the table: "statistic_items_names" */
  delete_statistic_items_names?: Maybe<Statistic_Items_Names_Mutation_Response>;
  /** delete single row from the table: "statistic_items_names" */
  delete_statistic_items_names_by_pk?: Maybe<Statistic_Items_Names>;
  /** delete data from the table: "statistic_items_output_fail" */
  delete_statistic_items_output_fail?: Maybe<Statistic_Items_Output_Fail_Mutation_Response>;
  /** delete single row from the table: "statistic_items_output_fail" */
  delete_statistic_items_output_fail_by_pk?: Maybe<Statistic_Items_Output_Fail>;
  /** delete data from the table: "statistic_items_output_success" */
  delete_statistic_items_output_success?: Maybe<Statistic_Items_Output_Success_Mutation_Response>;
  /** delete single row from the table: "statistic_items_output_success" */
  delete_statistic_items_output_success_by_pk?: Maybe<Statistic_Items_Output_Success>;
  /** delete data from the table: "statistic_lootbox_opens" */
  delete_statistic_lootbox_opens?: Maybe<Statistic_Lootbox_Opens_Mutation_Response>;
  /** delete single row from the table: "statistic_lootbox_opens" */
  delete_statistic_lootbox_opens_by_pk?: Maybe<Statistic_Lootbox_Opens>;
  /** delete data from the table: "statistic_lootbox_views" */
  delete_statistic_lootbox_views?: Maybe<Statistic_Lootbox_Views_Mutation_Response>;
  /** delete single row from the table: "statistic_lootbox_views" */
  delete_statistic_lootbox_views_by_pk?: Maybe<Statistic_Lootbox_Views>;
  /** delete data from the table: "statistic_mainpage_visits" */
  delete_statistic_mainpage_visits?: Maybe<Statistic_Mainpage_Visits_Mutation_Response>;
  /** delete single row from the table: "statistic_mainpage_visits" */
  delete_statistic_mainpage_visits_by_pk?: Maybe<Statistic_Mainpage_Visits>;
  /** delete data from the table: "statistic_promocode_full_activations" */
  delete_statistic_promocode_full_activations?: Maybe<Statistic_Promocode_Full_Activations_Mutation_Response>;
  /** delete single row from the table: "statistic_promocode_full_activations" */
  delete_statistic_promocode_full_activations_by_pk?: Maybe<Statistic_Promocode_Full_Activations>;
  /** delete data from the table: "statistic_promocode_success_activations" */
  delete_statistic_promocode_success_activations?: Maybe<Statistic_Promocode_Success_Activations_Mutation_Response>;
  /** delete single row from the table: "statistic_promocode_success_activations" */
  delete_statistic_promocode_success_activations_by_pk?: Maybe<Statistic_Promocode_Success_Activations>;
  /** delete data from the table: "statistic_sell_all_items" */
  delete_statistic_sell_all_items?: Maybe<Statistic_Sell_All_Items_Mutation_Response>;
  /** delete single row from the table: "statistic_sell_all_items" */
  delete_statistic_sell_all_items_by_pk?: Maybe<Statistic_Sell_All_Items>;
  /** delete data from the table: "statistic_sell_item" */
  delete_statistic_sell_item?: Maybe<Statistic_Sell_Item_Mutation_Response>;
  /** delete single row from the table: "statistic_sell_item" */
  delete_statistic_sell_item_by_pk?: Maybe<Statistic_Sell_Item>;
  /** delete data from the table: "statistic_user_deposit_success_callbacks" */
  delete_statistic_user_deposit_success_callbacks?: Maybe<Statistic_User_Deposit_Success_Callbacks_Mutation_Response>;
  /** delete single row from the table: "statistic_user_deposit_success_callbacks" */
  delete_statistic_user_deposit_success_callbacks_by_pk?: Maybe<Statistic_User_Deposit_Success_Callbacks>;
  /** delete data from the table: "statistic_user_deposits_transaction_requests" */
  delete_statistic_user_deposits_transaction_requests?: Maybe<Statistic_User_Deposits_Transaction_Requests_Mutation_Response>;
  /** delete single row from the table: "statistic_user_deposits_transaction_requests" */
  delete_statistic_user_deposits_transaction_requests_by_pk?: Maybe<Statistic_User_Deposits_Transaction_Requests>;
  /** delete data from the table: "statistic_user_free_lootboxes_opens" */
  delete_statistic_user_free_lootboxes_opens?: Maybe<Statistic_User_Free_Lootboxes_Opens_Mutation_Response>;
  /** delete single row from the table: "statistic_user_free_lootboxes_opens" */
  delete_statistic_user_free_lootboxes_opens_by_pk?: Maybe<Statistic_User_Free_Lootboxes_Opens>;
  /** delete data from the table: "statistic_user_registration" */
  delete_statistic_user_registration?: Maybe<Statistic_User_Registration_Mutation_Response>;
  /** delete single row from the table: "statistic_user_registration" */
  delete_statistic_user_registration_by_pk?: Maybe<Statistic_User_Registration>;
  /** delete data from the table: "stocks" */
  delete_stocks?: Maybe<Stocks_Mutation_Response>;
  /** delete single row from the table: "stocks" */
  delete_stocks_by_pk?: Maybe<Stocks>;
  /** delete data from the table: "store_offer_badges" */
  delete_store_offer_badges?: Maybe<Store_Offer_Badges_Mutation_Response>;
  /** delete single row from the table: "store_offer_badges" */
  delete_store_offer_badges_by_pk?: Maybe<Store_Offer_Badges>;
  /** delete data from the table: "store_offer_boosters" */
  delete_store_offer_boosters?: Maybe<Store_Offer_Boosters_Mutation_Response>;
  /** delete single row from the table: "store_offer_boosters" */
  delete_store_offer_boosters_by_pk?: Maybe<Store_Offer_Boosters>;
  /** delete data from the table: "store_offer_coins" */
  delete_store_offer_coins?: Maybe<Store_Offer_Coins_Mutation_Response>;
  /** delete single row from the table: "store_offer_coins" */
  delete_store_offer_coins_by_pk?: Maybe<Store_Offer_Coins>;
  /** delete data from the table: "store_offer_lootboxes" */
  delete_store_offer_lootboxes?: Maybe<Store_Offer_Lootboxes_Mutation_Response>;
  /** delete single row from the table: "store_offer_lootboxes" */
  delete_store_offer_lootboxes_by_pk?: Maybe<Store_Offer_Lootboxes>;
  /** delete data from the table: "store_offer_sales" */
  delete_store_offer_sales?: Maybe<Store_Offer_Sales_Mutation_Response>;
  /** delete single row from the table: "store_offer_sales" */
  delete_store_offer_sales_by_pk?: Maybe<Store_Offer_Sales>;
  /** delete data from the table: "store_offer_skins" */
  delete_store_offer_skins?: Maybe<Store_Offer_Skins_Mutation_Response>;
  /** delete single row from the table: "store_offer_skins" */
  delete_store_offer_skins_by_pk?: Maybe<Store_Offer_Skins>;
  /** delete data from the table: "store_offer_tickets" */
  delete_store_offer_tickets?: Maybe<Store_Offer_Tickets_Mutation_Response>;
  /** delete single row from the table: "store_offer_tickets" */
  delete_store_offer_tickets_by_pk?: Maybe<Store_Offer_Tickets>;
  /** delete data from the table: "store_offer_types" */
  delete_store_offer_types?: Maybe<Store_Offer_Types_Mutation_Response>;
  /** delete single row from the table: "store_offer_types" */
  delete_store_offer_types_by_pk?: Maybe<Store_Offer_Types>;
  /** delete data from the table: "store_offers" */
  delete_store_offers?: Maybe<Store_Offers_Mutation_Response>;
  /** delete single row from the table: "store_offers" */
  delete_store_offers_by_pk?: Maybe<Store_Offers>;
  /** delete data from the table: "survey_data_links" */
  delete_survey_data_links?: Maybe<Survey_Data_Links_Mutation_Response>;
  /** delete single row from the table: "survey_data_links" */
  delete_survey_data_links_by_pk?: Maybe<Survey_Data_Links>;
  /** delete data from the table: "telegram_admin_params" */
  delete_telegram_admin_params?: Maybe<Telegram_Admin_Params_Mutation_Response>;
  /** delete single row from the table: "telegram_admin_params" */
  delete_telegram_admin_params_by_pk?: Maybe<Telegram_Admin_Params>;
  /** delete data from the table: "ticket_counts_result" */
  delete_ticket_counts_result?: Maybe<Ticket_Counts_Result_Mutation_Response>;
  /** delete data from the table: "tickets_set_content" */
  delete_tickets_set_content?: Maybe<Tickets_Set_Content_Mutation_Response>;
  /** delete single row from the table: "tickets_set_content" */
  delete_tickets_set_content_by_pk?: Maybe<Tickets_Set_Content>;
  /** delete data from the table: "tickets_sets" */
  delete_tickets_sets?: Maybe<Tickets_Sets_Mutation_Response>;
  /** delete single row from the table: "tickets_sets" */
  delete_tickets_sets_by_pk?: Maybe<Tickets_Sets>;
  /** delete data from the table: "top_drop_strip" */
  delete_top_drop_strip?: Maybe<Top_Drop_Strip_Mutation_Response>;
  /** delete single row from the table: "top_drop_strip" */
  delete_top_drop_strip_by_pk?: Maybe<Top_Drop_Strip>;
  /** delete data from the table: "transaction" */
  delete_transaction?: Maybe<Transaction_Mutation_Response>;
  /** delete single row from the table: "transaction" */
  delete_transaction_by_pk?: Maybe<Transaction>;
  /** delete data from the table: "url_mappings" */
  delete_url_mappings?: Maybe<Url_Mappings_Mutation_Response>;
  /** delete single row from the table: "url_mappings" */
  delete_url_mappings_by_pk?: Maybe<Url_Mappings>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "user_account" */
  delete_user_account?: Maybe<User_Account_Mutation_Response>;
  /** delete single row from the table: "user_account" */
  delete_user_account_by_pk?: Maybe<User_Account>;
  /** delete data from the table: "user_auth" */
  delete_user_auth?: Maybe<User_Auth_Mutation_Response>;
  /** delete single row from the table: "user_auth" */
  delete_user_auth_by_pk?: Maybe<User_Auth>;
  /** delete data from the table: "user_bonus_wallet" */
  delete_user_bonus_wallet?: Maybe<User_Bonus_Wallet_Mutation_Response>;
  /** delete single row from the table: "user_bonus_wallet" */
  delete_user_bonus_wallet_by_pk?: Maybe<User_Bonus_Wallet>;
  /** delete data from the table: "user_bonus_wallet_transactions" */
  delete_user_bonus_wallet_transactions?: Maybe<User_Bonus_Wallet_Transactions_Mutation_Response>;
  /** delete single row from the table: "user_bonus_wallet_transactions" */
  delete_user_bonus_wallet_transactions_by_pk?: Maybe<User_Bonus_Wallet_Transactions>;
  /** delete data from the table: "user_boosters_in_inventory" */
  delete_user_boosters_in_inventory?: Maybe<User_Boosters_In_Inventory_Mutation_Response>;
  /** delete single row from the table: "user_boosters_in_inventory" */
  delete_user_boosters_in_inventory_by_pk?: Maybe<User_Boosters_In_Inventory>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_contacts" */
  delete_user_contacts?: Maybe<User_Contacts_Mutation_Response>;
  /** delete single row from the table: "user_contacts" */
  delete_user_contacts_by_pk?: Maybe<User_Contacts>;
  /** delete data from the table: "user_correct_answers_rewards" */
  delete_user_correct_answers_rewards?: Maybe<User_Correct_Answers_Rewards_Mutation_Response>;
  /** delete single row from the table: "user_correct_answers_rewards" */
  delete_user_correct_answers_rewards_by_pk?: Maybe<User_Correct_Answers_Rewards>;
  /** delete data from the table: "user_daily_coins_claims" */
  delete_user_daily_coins_claims?: Maybe<User_Daily_Coins_Claims_Mutation_Response>;
  /** delete single row from the table: "user_daily_coins_claims" */
  delete_user_daily_coins_claims_by_pk?: Maybe<User_Daily_Coins_Claims>;
  /** delete data from the table: "user_daily_reward_counts" */
  delete_user_daily_reward_counts?: Maybe<User_Daily_Reward_Counts_Mutation_Response>;
  /** delete single row from the table: "user_daily_reward_counts" */
  delete_user_daily_reward_counts_by_pk?: Maybe<User_Daily_Reward_Counts>;
  /** delete data from the table: "user_email_verification_data" */
  delete_user_email_verification_data?: Maybe<User_Email_Verification_Data_Mutation_Response>;
  /** delete single row from the table: "user_email_verification_data" */
  delete_user_email_verification_data_by_pk?: Maybe<User_Email_Verification_Data>;
  /** delete data from the table: "user_events" */
  delete_user_events?: Maybe<User_Events_Mutation_Response>;
  /** delete single row from the table: "user_events" */
  delete_user_events_by_pk?: Maybe<User_Events>;
  /** delete data from the table: "user_got_bonus" */
  delete_user_got_bonus?: Maybe<User_Got_Bonus_Mutation_Response>;
  /** delete single row from the table: "user_got_bonus" */
  delete_user_got_bonus_by_pk?: Maybe<User_Got_Bonus>;
  /** delete data from the table: "user_inventory" */
  delete_user_inventory?: Maybe<User_Inventory_Mutation_Response>;
  /** delete single row from the table: "user_inventory" */
  delete_user_inventory_by_pk?: Maybe<User_Inventory>;
  /** delete data from the table: "user_inventory_transactions" */
  delete_user_inventory_transactions?: Maybe<User_Inventory_Transactions_Mutation_Response>;
  /** delete single row from the table: "user_inventory_transactions" */
  delete_user_inventory_transactions_by_pk?: Maybe<User_Inventory_Transactions>;
  /** delete data from the table: "user_items_in_inventory" */
  delete_user_items_in_inventory?: Maybe<User_Items_In_Inventory_Mutation_Response>;
  /** delete single row from the table: "user_items_in_inventory" */
  delete_user_items_in_inventory_by_pk?: Maybe<User_Items_In_Inventory>;
  /** delete data from the table: "user_lootbox_opens_count" */
  delete_user_lootbox_opens_count?: Maybe<User_Lootbox_Opens_Count_Mutation_Response>;
  /** delete single row from the table: "user_lootbox_opens_count" */
  delete_user_lootbox_opens_count_by_pk?: Maybe<User_Lootbox_Opens_Count>;
  /** delete data from the table: "user_marking" */
  delete_user_marking?: Maybe<User_Marking_Mutation_Response>;
  /** delete single row from the table: "user_marking" */
  delete_user_marking_by_pk?: Maybe<User_Marking>;
  /** delete data from the table: "user_markings" */
  delete_user_markings?: Maybe<User_Markings_Mutation_Response>;
  /** delete single row from the table: "user_markings" */
  delete_user_markings_by_pk?: Maybe<User_Markings>;
  /** delete data from the table: "user_output_limits" */
  delete_user_output_limits?: Maybe<User_Output_Limits_Mutation_Response>;
  /** delete single row from the table: "user_output_limits" */
  delete_user_output_limits_by_pk?: Maybe<User_Output_Limits>;
  /** delete data from the table: "user_profile" */
  delete_user_profile?: Maybe<User_Profile_Mutation_Response>;
  /** delete single row from the table: "user_profile" */
  delete_user_profile_by_pk?: Maybe<User_Profile>;
  /** delete data from the table: "user_push_tokens" */
  delete_user_push_tokens?: Maybe<User_Push_Tokens_Mutation_Response>;
  /** delete single row from the table: "user_push_tokens" */
  delete_user_push_tokens_by_pk?: Maybe<User_Push_Tokens>;
  /** delete data from the table: "user_push_topics" */
  delete_user_push_topics?: Maybe<User_Push_Topics_Mutation_Response>;
  /** delete single row from the table: "user_push_topics" */
  delete_user_push_topics_by_pk?: Maybe<User_Push_Topics>;
  /** delete data from the table: "user_referal_levels_params" */
  delete_user_referal_levels_params?: Maybe<User_Referal_Levels_Params_Mutation_Response>;
  /** delete single row from the table: "user_referal_levels_params" */
  delete_user_referal_levels_params_by_pk?: Maybe<User_Referal_Levels_Params>;
  /** delete data from the table: "user_referal_params" */
  delete_user_referal_params?: Maybe<User_Referal_Params_Mutation_Response>;
  /** delete single row from the table: "user_referal_params" */
  delete_user_referal_params_by_pk?: Maybe<User_Referal_Params>;
  /** delete data from the table: "user_referals" */
  delete_user_referals?: Maybe<User_Referals_Mutation_Response>;
  /** delete data from the table: "user_referals_balance_transactions" */
  delete_user_referals_balance_transactions?: Maybe<User_Referals_Balance_Transactions_Mutation_Response>;
  /** delete single row from the table: "user_referals_balance_transactions" */
  delete_user_referals_balance_transactions_by_pk?: Maybe<User_Referals_Balance_Transactions>;
  /** delete data from the table: "user_referals_bonus_history" */
  delete_user_referals_bonus_history?: Maybe<User_Referals_Bonus_History_Mutation_Response>;
  /** delete single row from the table: "user_referals_bonus_history" */
  delete_user_referals_bonus_history_by_pk?: Maybe<User_Referals_Bonus_History>;
  /** delete single row from the table: "user_referals" */
  delete_user_referals_by_pk?: Maybe<User_Referals>;
  /** delete data from the table: "user_referral" */
  delete_user_referral?: Maybe<User_Referral_Mutation_Response>;
  /** delete data from the table: "user_referral_association" */
  delete_user_referral_association?: Maybe<User_Referral_Association_Mutation_Response>;
  /** delete single row from the table: "user_referral_association" */
  delete_user_referral_association_by_pk?: Maybe<User_Referral_Association>;
  /** delete data from the table: "user_referral_bonus_type" */
  delete_user_referral_bonus_type?: Maybe<User_Referral_Bonus_Type_Mutation_Response>;
  /** delete single row from the table: "user_referral_bonus_type" */
  delete_user_referral_bonus_type_by_pk?: Maybe<User_Referral_Bonus_Type>;
  /** delete single row from the table: "user_referral" */
  delete_user_referral_by_pk?: Maybe<User_Referral>;
  /** delete data from the table: "user_referral_levels_params" */
  delete_user_referral_levels_params?: Maybe<User_Referral_Levels_Params_Mutation_Response>;
  /** delete single row from the table: "user_referral_levels_params" */
  delete_user_referral_levels_params_by_pk?: Maybe<User_Referral_Levels_Params>;
  /** delete data from the table: "user_referral_offer_bonus" */
  delete_user_referral_offer_bonus?: Maybe<User_Referral_Offer_Bonus_Mutation_Response>;
  /** delete single row from the table: "user_referral_offer_bonus" */
  delete_user_referral_offer_bonus_by_pk?: Maybe<User_Referral_Offer_Bonus>;
  /** delete data from the table: "user_referral_offers" */
  delete_user_referral_offers?: Maybe<User_Referral_Offers_Mutation_Response>;
  /** delete single row from the table: "user_referral_offers" */
  delete_user_referral_offers_by_pk?: Maybe<User_Referral_Offers>;
  /** delete data from the table: "user_referral_params" */
  delete_user_referral_params?: Maybe<User_Referral_Params_Mutation_Response>;
  /** delete single row from the table: "user_referral_params" */
  delete_user_referral_params_by_pk?: Maybe<User_Referral_Params>;
  /** delete data from the table: "user_referrals_balance_transactions" */
  delete_user_referrals_balance_transactions?: Maybe<User_Referrals_Balance_Transactions_Mutation_Response>;
  /** delete single row from the table: "user_referrals_balance_transactions" */
  delete_user_referrals_balance_transactions_by_pk?: Maybe<User_Referrals_Balance_Transactions>;
  /** delete data from the table: "user_seq_number" */
  delete_user_seq_number?: Maybe<User_Seq_Number_Mutation_Response>;
  /** delete single row from the table: "user_seq_number" */
  delete_user_seq_number_by_pk?: Maybe<User_Seq_Number>;
  /** delete data from the table: "user_settings" */
  delete_user_settings?: Maybe<User_Settings_Mutation_Response>;
  /** delete single row from the table: "user_settings" */
  delete_user_settings_by_pk?: Maybe<User_Settings>;
  /** delete data from the table: "user_statistic" */
  delete_user_statistic?: Maybe<User_Statistic_Mutation_Response>;
  /** delete single row from the table: "user_statistic" */
  delete_user_statistic_by_pk?: Maybe<User_Statistic>;
  /** delete data from the table: "user_steam_output_alternatives" */
  delete_user_steam_output_alternatives?: Maybe<User_Steam_Output_Alternatives_Mutation_Response>;
  /** delete single row from the table: "user_steam_output_alternatives" */
  delete_user_steam_output_alternatives_by_pk?: Maybe<User_Steam_Output_Alternatives>;
  /** delete data from the table: "user_steam_output_watcher" */
  delete_user_steam_output_watcher?: Maybe<User_Steam_Output_Watcher_Mutation_Response>;
  /** delete single row from the table: "user_steam_output_watcher" */
  delete_user_steam_output_watcher_by_pk?: Maybe<User_Steam_Output_Watcher>;
  /** delete data from the table: "user_takes_free_lootbox" */
  delete_user_takes_free_lootbox?: Maybe<User_Takes_Free_Lootbox_Mutation_Response>;
  /** delete single row from the table: "user_takes_free_lootbox" */
  delete_user_takes_free_lootbox_by_pk?: Maybe<User_Takes_Free_Lootbox>;
  /** delete data from the table: "user_upgrade_params" */
  delete_user_upgrade_params?: Maybe<User_Upgrade_Params_Mutation_Response>;
  /** delete single row from the table: "user_upgrade_params" */
  delete_user_upgrade_params_by_pk?: Maybe<User_Upgrade_Params>;
  /** delete data from the table: "user_upgrade_results" */
  delete_user_upgrade_results?: Maybe<User_Upgrade_Results_Mutation_Response>;
  /** delete single row from the table: "user_upgrade_results" */
  delete_user_upgrade_results_by_pk?: Maybe<User_Upgrade_Results>;
  /** delete data from the table: "user_vk_data" */
  delete_user_vk_data?: Maybe<User_Vk_Data_Mutation_Response>;
  /** delete single row from the table: "user_vk_data" */
  delete_user_vk_data_by_pk?: Maybe<User_Vk_Data>;
  /** delete data from the table: "user_wallet" */
  delete_user_wallet?: Maybe<User_Wallet_Mutation_Response>;
  /** delete data from the table: "user_wallet_audit" */
  delete_user_wallet_audit?: Maybe<User_Wallet_Audit_Mutation_Response>;
  /** delete single row from the table: "user_wallet_audit" */
  delete_user_wallet_audit_by_pk?: Maybe<User_Wallet_Audit>;
  /** delete single row from the table: "user_wallet" */
  delete_user_wallet_by_pk?: Maybe<User_Wallet>;
  /** delete data from the table: "user_wallet_transactions" */
  delete_user_wallet_transactions?: Maybe<User_Wallet_Transactions_Mutation_Response>;
  /** delete single row from the table: "user_wallet_transactions" */
  delete_user_wallet_transactions_by_pk?: Maybe<User_Wallet_Transactions>;
  /** delete data from the table: "user_whitelist" */
  delete_user_whitelist?: Maybe<User_Whitelist_Mutation_Response>;
  /** delete single row from the table: "user_whitelist" */
  delete_user_whitelist_by_pk?: Maybe<User_Whitelist>;
  /** delete data from the table: "utm_visits_data" */
  delete_utm_visits_data?: Maybe<Utm_Visits_Data_Mutation_Response>;
  /** delete single row from the table: "utm_visits_data" */
  delete_utm_visits_data_by_pk?: Maybe<Utm_Visits_Data>;
  /** delete data from the table: "webpushes_autosending_types" */
  delete_webpushes_autosending_types?: Maybe<Webpushes_Autosending_Types_Mutation_Response>;
  /** delete single row from the table: "webpushes_autosending_types" */
  delete_webpushes_autosending_types_by_pk?: Maybe<Webpushes_Autosending_Types>;
  /** delete data from the table: "webpushes_autosending_types_localization" */
  delete_webpushes_autosending_types_localization?: Maybe<Webpushes_Autosending_Types_Localization_Mutation_Response>;
  /** delete single row from the table: "webpushes_autosending_types_localization" */
  delete_webpushes_autosending_types_localization_by_pk?: Maybe<Webpushes_Autosending_Types_Localization>;
  /** delete data from the table: "webpushes_bonuses" */
  delete_webpushes_bonuses?: Maybe<Webpushes_Bonuses_Mutation_Response>;
  /** delete single row from the table: "webpushes_bonuses" */
  delete_webpushes_bonuses_by_pk?: Maybe<Webpushes_Bonuses>;
  /** delete data from the table: "webpushes_bonuses_offers" */
  delete_webpushes_bonuses_offers?: Maybe<Webpushes_Bonuses_Offers_Mutation_Response>;
  /** delete single row from the table: "webpushes_bonuses_offers" */
  delete_webpushes_bonuses_offers_by_pk?: Maybe<Webpushes_Bonuses_Offers>;
  /** delete data from the table: "webpushes_lines" */
  delete_webpushes_lines?: Maybe<Webpushes_Lines_Mutation_Response>;
  /** delete single row from the table: "webpushes_lines" */
  delete_webpushes_lines_by_pk?: Maybe<Webpushes_Lines>;
  /** delete data from the table: "webpushes_scheduling" */
  delete_webpushes_scheduling?: Maybe<Webpushes_Scheduling_Mutation_Response>;
  /** delete single row from the table: "webpushes_scheduling" */
  delete_webpushes_scheduling_by_pk?: Maybe<Webpushes_Scheduling>;
  /** delete data from the table: "webpushes_triggers" */
  delete_webpushes_triggers?: Maybe<Webpushes_Triggers_Mutation_Response>;
  /** delete single row from the table: "webpushes_triggers" */
  delete_webpushes_triggers_by_pk?: Maybe<Webpushes_Triggers>;
  /** delete data from the table: "webpushes_triggers_groups" */
  delete_webpushes_triggers_groups?: Maybe<Webpushes_Triggers_Groups_Mutation_Response>;
  /** delete single row from the table: "webpushes_triggers_groups" */
  delete_webpushes_triggers_groups_by_pk?: Maybe<Webpushes_Triggers_Groups>;
  /** insert data into the table: "account_items_names" */
  insert_account_items_names?: Maybe<Account_Items_Names_Mutation_Response>;
  /** insert a single row into the table: "account_items_names" */
  insert_account_items_names_one?: Maybe<Account_Items_Names>;
  /** insert data into the table: "admin_items_names" */
  insert_admin_items_names?: Maybe<Admin_Items_Names_Mutation_Response>;
  /** insert a single row into the table: "admin_items_names" */
  insert_admin_items_names_one?: Maybe<Admin_Items_Names>;
  /** insert data into the table: "admin_user_params" */
  insert_admin_user_params?: Maybe<Admin_User_Params_Mutation_Response>;
  /** insert a single row into the table: "admin_user_params" */
  insert_admin_user_params_one?: Maybe<Admin_User_Params>;
  /** insert data into the table: "amp_sessions_data" */
  insert_amp_sessions_data?: Maybe<Amp_Sessions_Data_Mutation_Response>;
  /** insert a single row into the table: "amp_sessions_data" */
  insert_amp_sessions_data_one?: Maybe<Amp_Sessions_Data>;
  /** insert data into the table: "banned_ips" */
  insert_banned_ips?: Maybe<Banned_Ips_Mutation_Response>;
  /** insert a single row into the table: "banned_ips" */
  insert_banned_ips_one?: Maybe<Banned_Ips>;
  /** insert data into the table: "banned_users_for_multiaccount" */
  insert_banned_users_for_multiaccount?: Maybe<Banned_Users_For_Multiaccount_Mutation_Response>;
  /** insert a single row into the table: "banned_users_for_multiaccount" */
  insert_banned_users_for_multiaccount_one?: Maybe<Banned_Users_For_Multiaccount>;
  /** insert data into the table: "bill" */
  insert_bill?: Maybe<Bill_Mutation_Response>;
  /** insert a single row into the table: "bill" */
  insert_bill_one?: Maybe<Bill>;
  /** insert data into the table: "bonus_params" */
  insert_bonus_params?: Maybe<Bonus_Params_Mutation_Response>;
  /** insert a single row into the table: "bonus_params" */
  insert_bonus_params_one?: Maybe<Bonus_Params>;
  /** insert data into the table: "bonus_wheel_rewards" */
  insert_bonus_wheel_rewards?: Maybe<Bonus_Wheel_Rewards_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheel_rewards" */
  insert_bonus_wheel_rewards_one?: Maybe<Bonus_Wheel_Rewards>;
  /** insert data into the table: "bonus_wheel_ticket_type" */
  insert_bonus_wheel_ticket_type?: Maybe<Bonus_Wheel_Ticket_Type_Mutation_Response>;
  /** insert data into the table: "bonus_wheel_ticket_type_name_localization" */
  insert_bonus_wheel_ticket_type_name_localization?: Maybe<Bonus_Wheel_Ticket_Type_Name_Localization_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheel_ticket_type_name_localization" */
  insert_bonus_wheel_ticket_type_name_localization_one?: Maybe<Bonus_Wheel_Ticket_Type_Name_Localization>;
  /** insert a single row into the table: "bonus_wheel_ticket_type" */
  insert_bonus_wheel_ticket_type_one?: Maybe<Bonus_Wheel_Ticket_Type>;
  /** insert data into the table: "bonus_wheel_user_rewards" */
  insert_bonus_wheel_user_rewards?: Maybe<Bonus_Wheel_User_Rewards_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheel_user_rewards" */
  insert_bonus_wheel_user_rewards_one?: Maybe<Bonus_Wheel_User_Rewards>;
  /** insert data into the table: "bonus_wheel_user_tickets" */
  insert_bonus_wheel_user_tickets?: Maybe<Bonus_Wheel_User_Tickets_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheel_user_tickets" */
  insert_bonus_wheel_user_tickets_one?: Maybe<Bonus_Wheel_User_Tickets>;
  /** insert data into the table: "bonus_wheels" */
  insert_bonus_wheels?: Maybe<Bonus_Wheels_Mutation_Response>;
  /** insert data into the table: "bonus_wheels_bonus_skins" */
  insert_bonus_wheels_bonus_skins?: Maybe<Bonus_Wheels_Bonus_Skins_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheels_bonus_skins" */
  insert_bonus_wheels_bonus_skins_one?: Maybe<Bonus_Wheels_Bonus_Skins>;
  /** insert data into the table: "bonus_wheels_bonuses" */
  insert_bonus_wheels_bonuses?: Maybe<Bonus_Wheels_Bonuses_Mutation_Response>;
  /** insert data into the table: "bonus_wheels_bonuses_localization" */
  insert_bonus_wheels_bonuses_localization?: Maybe<Bonus_Wheels_Bonuses_Localization_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheels_bonuses_localization" */
  insert_bonus_wheels_bonuses_localization_one?: Maybe<Bonus_Wheels_Bonuses_Localization>;
  /** insert a single row into the table: "bonus_wheels_bonuses" */
  insert_bonus_wheels_bonuses_one?: Maybe<Bonus_Wheels_Bonuses>;
  /** insert data into the table: "bonus_wheels_bonuses_params" */
  insert_bonus_wheels_bonuses_params?: Maybe<Bonus_Wheels_Bonuses_Params_Mutation_Response>;
  /** insert data into the table: "bonus_wheels_bonuses_params_localization" */
  insert_bonus_wheels_bonuses_params_localization?: Maybe<Bonus_Wheels_Bonuses_Params_Localization_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheels_bonuses_params_localization" */
  insert_bonus_wheels_bonuses_params_localization_one?: Maybe<Bonus_Wheels_Bonuses_Params_Localization>;
  /** insert a single row into the table: "bonus_wheels_bonuses_params" */
  insert_bonus_wheels_bonuses_params_one?: Maybe<Bonus_Wheels_Bonuses_Params>;
  /** insert data into the table: "bonus_wheels_generator" */
  insert_bonus_wheels_generator?: Maybe<Bonus_Wheels_Generator_Mutation_Response>;
  /** insert data into the table: "bonus_wheels_generator_bonuses" */
  insert_bonus_wheels_generator_bonuses?: Maybe<Bonus_Wheels_Generator_Bonuses_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheels_generator_bonuses" */
  insert_bonus_wheels_generator_bonuses_one?: Maybe<Bonus_Wheels_Generator_Bonuses>;
  /** insert a single row into the table: "bonus_wheels_generator" */
  insert_bonus_wheels_generator_one?: Maybe<Bonus_Wheels_Generator>;
  /** insert data into the table: "bonus_wheels_items_in_wheel" */
  insert_bonus_wheels_items_in_wheel?: Maybe<Bonus_Wheels_Items_In_Wheel_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheels_items_in_wheel" */
  insert_bonus_wheels_items_in_wheel_one?: Maybe<Bonus_Wheels_Items_In_Wheel>;
  /** insert a single row into the table: "bonus_wheels" */
  insert_bonus_wheels_one?: Maybe<Bonus_Wheels>;
  /** insert data into the table: "bonus_wheels_user_check" */
  insert_bonus_wheels_user_check?: Maybe<Bonus_Wheels_User_Check_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheels_user_check" */
  insert_bonus_wheels_user_check_one?: Maybe<Bonus_Wheels_User_Check>;
  /** insert data into the table: "bonus_wheels_user_check_params" */
  insert_bonus_wheels_user_check_params?: Maybe<Bonus_Wheels_User_Check_Params_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheels_user_check_params" */
  insert_bonus_wheels_user_check_params_one?: Maybe<Bonus_Wheels_User_Check_Params>;
  /** insert data into the table: "bonus_wheels_user_offers" */
  insert_bonus_wheels_user_offers?: Maybe<Bonus_Wheels_User_Offers_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheels_user_offers" */
  insert_bonus_wheels_user_offers_one?: Maybe<Bonus_Wheels_User_Offers>;
  /** insert data into the table: "bonus_wheels_user_offers_params" */
  insert_bonus_wheels_user_offers_params?: Maybe<Bonus_Wheels_User_Offers_Params_Mutation_Response>;
  /** insert data into the table: "bonus_wheels_user_offers_params_localization" */
  insert_bonus_wheels_user_offers_params_localization?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Mutation_Response>;
  /** insert a single row into the table: "bonus_wheels_user_offers_params_localization" */
  insert_bonus_wheels_user_offers_params_localization_one?: Maybe<Bonus_Wheels_User_Offers_Params_Localization>;
  /** insert a single row into the table: "bonus_wheels_user_offers_params" */
  insert_bonus_wheels_user_offers_params_one?: Maybe<Bonus_Wheels_User_Offers_Params>;
  /** insert data into the table: "bonuses" */
  insert_bonuses?: Maybe<Bonuses_Mutation_Response>;
  /** insert a single row into the table: "bonuses" */
  insert_bonuses_one?: Maybe<Bonuses>;
  /** insert data into the table: "bonuses_register" */
  insert_bonuses_register?: Maybe<Bonuses_Register_Mutation_Response>;
  /** insert data into the table: "bonuses_register_offers" */
  insert_bonuses_register_offers?: Maybe<Bonuses_Register_Offers_Mutation_Response>;
  /** insert a single row into the table: "bonuses_register_offers" */
  insert_bonuses_register_offers_one?: Maybe<Bonuses_Register_Offers>;
  /** insert a single row into the table: "bonuses_register" */
  insert_bonuses_register_one?: Maybe<Bonuses_Register>;
  /** insert data into the table: "boosters_set_content" */
  insert_boosters_set_content?: Maybe<Boosters_Set_Content_Mutation_Response>;
  /** insert a single row into the table: "boosters_set_content" */
  insert_boosters_set_content_one?: Maybe<Boosters_Set_Content>;
  /** insert data into the table: "boosters_sets" */
  insert_boosters_sets?: Maybe<Boosters_Sets_Mutation_Response>;
  /** insert a single row into the table: "boosters_sets" */
  insert_boosters_sets_one?: Maybe<Boosters_Sets>;
  /** insert data into the table: "bot_account_settings" */
  insert_bot_account_settings?: Maybe<Bot_Account_Settings_Mutation_Response>;
  /** insert a single row into the table: "bot_account_settings" */
  insert_bot_account_settings_one?: Maybe<Bot_Account_Settings>;
  /** insert data into the table: "bots_lootboxes_opens_count" */
  insert_bots_lootboxes_opens_count?: Maybe<Bots_Lootboxes_Opens_Count_Mutation_Response>;
  /** insert a single row into the table: "bots_lootboxes_opens_count" */
  insert_bots_lootboxes_opens_count_one?: Maybe<Bots_Lootboxes_Opens_Count>;
  /** insert data into the table: "coins_set_content" */
  insert_coins_set_content?: Maybe<Coins_Set_Content_Mutation_Response>;
  /** insert a single row into the table: "coins_set_content" */
  insert_coins_set_content_one?: Maybe<Coins_Set_Content>;
  /** insert data into the table: "coins_sets" */
  insert_coins_sets?: Maybe<Coins_Sets_Mutation_Response>;
  /** insert a single row into the table: "coins_sets" */
  insert_coins_sets_one?: Maybe<Coins_Sets>;
  /** insert data into the table: "company" */
  insert_company?: Maybe<Company_Mutation_Response>;
  /** insert a single row into the table: "company" */
  insert_company_one?: Maybe<Company>;
  /** insert data into the table: "coupons" */
  insert_coupons?: Maybe<Coupons_Mutation_Response>;
  /** insert a single row into the table: "coupons" */
  insert_coupons_one?: Maybe<Coupons>;
  /** insert data into the table: "csgomarket_token_balance" */
  insert_csgomarket_token_balance?: Maybe<Csgomarket_Token_Balance_Mutation_Response>;
  /** insert a single row into the table: "csgomarket_token_balance" */
  insert_csgomarket_token_balance_one?: Maybe<Csgomarket_Token_Balance>;
  /** insert data into the table: "csgomarket_tokens" */
  insert_csgomarket_tokens?: Maybe<Csgomarket_Tokens_Mutation_Response>;
  /** insert a single row into the table: "csgomarket_tokens" */
  insert_csgomarket_tokens_one?: Maybe<Csgomarket_Tokens>;
  /** insert data into the table: "currency_history" */
  insert_currency_history?: Maybe<Currency_History_Mutation_Response>;
  /** insert a single row into the table: "currency_history" */
  insert_currency_history_one?: Maybe<Currency_History>;
  /** insert data into the table: "currencys" */
  insert_currencys?: Maybe<Currencys_Mutation_Response>;
  /** insert a single row into the table: "currencys" */
  insert_currencys_one?: Maybe<Currencys>;
  /** insert data into the table: "daily_deposit_sum" */
  insert_daily_deposit_sum?: Maybe<Daily_Deposit_Sum_Mutation_Response>;
  /** insert a single row into the table: "daily_deposit_sum" */
  insert_daily_deposit_sum_one?: Maybe<Daily_Deposit_Sum>;
  /** insert data into the table: "emails_from_upgrade_question" */
  insert_emails_from_upgrade_question?: Maybe<Emails_From_Upgrade_Question_Mutation_Response>;
  /** insert a single row into the table: "emails_from_upgrade_question" */
  insert_emails_from_upgrade_question_one?: Maybe<Emails_From_Upgrade_Question>;
  /** insert data into the table: "execution_methods" */
  insert_execution_methods?: Maybe<Execution_Methods_Mutation_Response>;
  /** insert a single row into the table: "execution_methods" */
  insert_execution_methods_one?: Maybe<Execution_Methods>;
  /** insert data into the table: "faq_table" */
  insert_faq_table?: Maybe<Faq_Table_Mutation_Response>;
  /** insert a single row into the table: "faq_table" */
  insert_faq_table_one?: Maybe<Faq_Table>;
  /** insert data into the table: "fixed_items_generator_boosters" */
  insert_fixed_items_generator_boosters?: Maybe<Fixed_Items_Generator_Boosters_Mutation_Response>;
  /** insert a single row into the table: "fixed_items_generator_boosters" */
  insert_fixed_items_generator_boosters_one?: Maybe<Fixed_Items_Generator_Boosters>;
  /** insert data into the table: "fixed_items_generator_items" */
  insert_fixed_items_generator_items?: Maybe<Fixed_Items_Generator_Items_Mutation_Response>;
  /** insert a single row into the table: "fixed_items_generator_items" */
  insert_fixed_items_generator_items_one?: Maybe<Fixed_Items_Generator_Items>;
  /** insert data into the table: "fixed_items_generator_lootboxes" */
  insert_fixed_items_generator_lootboxes?: Maybe<Fixed_Items_Generator_Lootboxes_Mutation_Response>;
  /** insert a single row into the table: "fixed_items_generator_lootboxes" */
  insert_fixed_items_generator_lootboxes_one?: Maybe<Fixed_Items_Generator_Lootboxes>;
  /** insert data into the table: "giveaway_members" */
  insert_giveaway_members?: Maybe<Giveaway_Members_Mutation_Response>;
  /** insert a single row into the table: "giveaway_members" */
  insert_giveaway_members_one?: Maybe<Giveaway_Members>;
  /** insert data into the table: "giveaway_params" */
  insert_giveaway_params?: Maybe<Giveaway_Params_Mutation_Response>;
  /** insert a single row into the table: "giveaway_params" */
  insert_giveaway_params_one?: Maybe<Giveaway_Params>;
  /** insert data into the table: "giveaways" */
  insert_giveaways?: Maybe<Giveaways_Mutation_Response>;
  /** insert a single row into the table: "giveaways" */
  insert_giveaways_one?: Maybe<Giveaways>;
  /** insert data into the table: "group_items_csgomarket" */
  insert_group_items_csgomarket?: Maybe<Group_Items_Csgomarket_Mutation_Response>;
  /** insert a single row into the table: "group_items_csgomarket" */
  insert_group_items_csgomarket_one?: Maybe<Group_Items_Csgomarket>;
  /** insert data into the table: "history_group_items_csgomarket" */
  insert_history_group_items_csgomarket?: Maybe<History_Group_Items_Csgomarket_Mutation_Response>;
  /** insert a single row into the table: "history_group_items_csgomarket" */
  insert_history_group_items_csgomarket_one?: Maybe<History_Group_Items_Csgomarket>;
  /** insert data into the table: "history_items_csgomarket" */
  insert_history_items_csgomarket?: Maybe<History_Items_Csgomarket_Mutation_Response>;
  /** insert a single row into the table: "history_items_csgomarket" */
  insert_history_items_csgomarket_one?: Maybe<History_Items_Csgomarket>;
  /** insert data into the table: "item_out_transactions" */
  insert_item_out_transactions?: Maybe<Item_Out_Transactions_Mutation_Response>;
  /** insert a single row into the table: "item_out_transactions" */
  insert_item_out_transactions_one?: Maybe<Item_Out_Transactions>;
  /** insert data into the table: "items" */
  insert_items?: Maybe<Items_Mutation_Response>;
  /** insert data into the table: "items_csgomarket" */
  insert_items_csgomarket?: Maybe<Items_Csgomarket_Mutation_Response>;
  /** insert a single row into the table: "items_csgomarket" */
  insert_items_csgomarket_one?: Maybe<Items_Csgomarket>;
  /** insert data into the table: "items_drops" */
  insert_items_drops?: Maybe<Items_Drops_Mutation_Response>;
  /** insert a single row into the table: "items_drops" */
  insert_items_drops_one?: Maybe<Items_Drops>;
  /** insert data into the table: "items_for_upgrade" */
  insert_items_for_upgrade?: Maybe<Items_For_Upgrade_Mutation_Response>;
  /** insert a single row into the table: "items_for_upgrade" */
  insert_items_for_upgrade_one?: Maybe<Items_For_Upgrade>;
  /** insert data into the table: "items_history" */
  insert_items_history?: Maybe<Items_History_Mutation_Response>;
  /** insert a single row into the table: "items_history" */
  insert_items_history_one?: Maybe<Items_History>;
  /** insert data into the table: "items_in_lootbox" */
  insert_items_in_lootbox?: Maybe<Items_In_Lootbox_Mutation_Response>;
  /** insert a single row into the table: "items_in_lootbox" */
  insert_items_in_lootbox_one?: Maybe<Items_In_Lootbox>;
  /** insert data into the table: "items_in_lootbox_view" */
  insert_items_in_lootbox_view?: Maybe<Items_In_Lootbox_View_Mutation_Response>;
  /** insert a single row into the table: "items_in_lootbox_view" */
  insert_items_in_lootbox_view_one?: Maybe<Items_In_Lootbox_View>;
  /** insert data into the table: "items_items_names" */
  insert_items_items_names?: Maybe<Items_Items_Names_Mutation_Response>;
  /** insert a single row into the table: "items_items_names" */
  insert_items_items_names_one?: Maybe<Items_Items_Names>;
  /** insert data into the table: "items_localization" */
  insert_items_localization?: Maybe<Items_Localization_Mutation_Response>;
  /** insert a single row into the table: "items_localization" */
  insert_items_localization_one?: Maybe<Items_Localization>;
  /** insert data into the table: "items_names" */
  insert_items_names?: Maybe<Items_Names_Mutation_Response>;
  /** insert a single row into the table: "items_names" */
  insert_items_names_one?: Maybe<Items_Names>;
  /** insert a single row into the table: "items" */
  insert_items_one?: Maybe<Items>;
  /** insert data into the table: "items_quality" */
  insert_items_quality?: Maybe<Items_Quality_Mutation_Response>;
  /** insert data into the table: "items_quality_localization" */
  insert_items_quality_localization?: Maybe<Items_Quality_Localization_Mutation_Response>;
  /** insert a single row into the table: "items_quality_localization" */
  insert_items_quality_localization_one?: Maybe<Items_Quality_Localization>;
  /** insert a single row into the table: "items_quality" */
  insert_items_quality_one?: Maybe<Items_Quality>;
  /** insert data into the table: "items_rarity" */
  insert_items_rarity?: Maybe<Items_Rarity_Mutation_Response>;
  /** insert data into the table: "items_rarity_localization" */
  insert_items_rarity_localization?: Maybe<Items_Rarity_Localization_Mutation_Response>;
  /** insert a single row into the table: "items_rarity_localization" */
  insert_items_rarity_localization_one?: Maybe<Items_Rarity_Localization>;
  /** insert a single row into the table: "items_rarity" */
  insert_items_rarity_one?: Maybe<Items_Rarity>;
  /** insert data into the table: "items_type" */
  insert_items_type?: Maybe<Items_Type_Mutation_Response>;
  /** insert data into the table: "items_type_localization" */
  insert_items_type_localization?: Maybe<Items_Type_Localization_Mutation_Response>;
  /** insert a single row into the table: "items_type_localization" */
  insert_items_type_localization_one?: Maybe<Items_Type_Localization>;
  /** insert a single row into the table: "items_type" */
  insert_items_type_one?: Maybe<Items_Type>;
  /** insert data into the table: "items_weapon_localization" */
  insert_items_weapon_localization?: Maybe<Items_Weapon_Localization_Mutation_Response>;
  /** insert a single row into the table: "items_weapon_localization" */
  insert_items_weapon_localization_one?: Maybe<Items_Weapon_Localization>;
  /** insert data into the table: "items_weapons" */
  insert_items_weapons?: Maybe<Items_Weapons_Mutation_Response>;
  /** insert a single row into the table: "items_weapons" */
  insert_items_weapons_one?: Maybe<Items_Weapons>;
  /** insert data into the table: "lootbox_booster_items" */
  insert_lootbox_booster_items?: Maybe<Lootbox_Booster_Items_Mutation_Response>;
  /** insert a single row into the table: "lootbox_booster_items" */
  insert_lootbox_booster_items_one?: Maybe<Lootbox_Booster_Items>;
  /** insert data into the table: "lootbox_booster_localization" */
  insert_lootbox_booster_localization?: Maybe<Lootbox_Booster_Localization_Mutation_Response>;
  /** insert a single row into the table: "lootbox_booster_localization" */
  insert_lootbox_booster_localization_one?: Maybe<Lootbox_Booster_Localization>;
  /** insert data into the table: "lootbox_category" */
  insert_lootbox_category?: Maybe<Lootbox_Category_Mutation_Response>;
  /** insert a single row into the table: "lootbox_category" */
  insert_lootbox_category_one?: Maybe<Lootbox_Category>;
  /** insert data into the table: "lootbox_items_sets" */
  insert_lootbox_items_sets?: Maybe<Lootbox_Items_Sets_Mutation_Response>;
  /** insert a single row into the table: "lootbox_items_sets" */
  insert_lootbox_items_sets_one?: Maybe<Lootbox_Items_Sets>;
  /** insert data into the table: "lootbox_service" */
  insert_lootbox_service?: Maybe<Lootbox_Service_Mutation_Response>;
  /** insert a single row into the table: "lootbox_service" */
  insert_lootbox_service_one?: Maybe<Lootbox_Service>;
  /** insert data into the table: "lootboxes" */
  insert_lootboxes?: Maybe<Lootboxes_Mutation_Response>;
  /** insert data into the table: "lootboxes_available_statuses" */
  insert_lootboxes_available_statuses?: Maybe<Lootboxes_Available_Statuses_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_available_statuses" */
  insert_lootboxes_available_statuses_one?: Maybe<Lootboxes_Available_Statuses>;
  /** insert data into the table: "lootboxes_bonus" */
  insert_lootboxes_bonus?: Maybe<Lootboxes_Bonus_Mutation_Response>;
  /** insert data into the table: "lootboxes_bonus_generator_items" */
  insert_lootboxes_bonus_generator_items?: Maybe<Lootboxes_Bonus_Generator_Items_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_bonus_generator_items" */
  insert_lootboxes_bonus_generator_items_one?: Maybe<Lootboxes_Bonus_Generator_Items>;
  /** insert data into the table: "lootboxes_bonus_generator_params" */
  insert_lootboxes_bonus_generator_params?: Maybe<Lootboxes_Bonus_Generator_Params_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_bonus_generator_params" */
  insert_lootboxes_bonus_generator_params_one?: Maybe<Lootboxes_Bonus_Generator_Params>;
  /** insert data into the table: "lootboxes_bonus_items" */
  insert_lootboxes_bonus_items?: Maybe<Lootboxes_Bonus_Items_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_bonus_items" */
  insert_lootboxes_bonus_items_one?: Maybe<Lootboxes_Bonus_Items>;
  /** insert a single row into the table: "lootboxes_bonus" */
  insert_lootboxes_bonus_one?: Maybe<Lootboxes_Bonus>;
  /** insert data into the table: "lootboxes_bonus_views" */
  insert_lootboxes_bonus_views?: Maybe<Lootboxes_Bonus_Views_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_bonus_views" */
  insert_lootboxes_bonus_views_one?: Maybe<Lootboxes_Bonus_Views>;
  /** insert data into the table: "lootboxes_dynamic_category" */
  insert_lootboxes_dynamic_category?: Maybe<Lootboxes_Dynamic_Category_Mutation_Response>;
  /** insert data into the table: "lootboxes_dynamic_category_lootboxes" */
  insert_lootboxes_dynamic_category_lootboxes?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_dynamic_category_lootboxes" */
  insert_lootboxes_dynamic_category_lootboxes_one?: Maybe<Lootboxes_Dynamic_Category_Lootboxes>;
  /** insert a single row into the table: "lootboxes_dynamic_category" */
  insert_lootboxes_dynamic_category_one?: Maybe<Lootboxes_Dynamic_Category>;
  /** insert data into the table: "lootboxes_free" */
  insert_lootboxes_free?: Maybe<Lootboxes_Free_Mutation_Response>;
  /** insert data into the table: "lootboxes_free_category" */
  insert_lootboxes_free_category?: Maybe<Lootboxes_Free_Category_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_free_category" */
  insert_lootboxes_free_category_one?: Maybe<Lootboxes_Free_Category>;
  /** insert data into the table: "lootboxes_free_generator_items" */
  insert_lootboxes_free_generator_items?: Maybe<Lootboxes_Free_Generator_Items_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_free_generator_items" */
  insert_lootboxes_free_generator_items_one?: Maybe<Lootboxes_Free_Generator_Items>;
  /** insert data into the table: "lootboxes_free_generator_params" */
  insert_lootboxes_free_generator_params?: Maybe<Lootboxes_Free_Generator_Params_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_free_generator_params" */
  insert_lootboxes_free_generator_params_one?: Maybe<Lootboxes_Free_Generator_Params>;
  /** insert data into the table: "lootboxes_free_items" */
  insert_lootboxes_free_items?: Maybe<Lootboxes_Free_Items_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_free_items" */
  insert_lootboxes_free_items_one?: Maybe<Lootboxes_Free_Items>;
  /** insert a single row into the table: "lootboxes_free" */
  insert_lootboxes_free_one?: Maybe<Lootboxes_Free>;
  /** insert data into the table: "lootboxes_free_views" */
  insert_lootboxes_free_views?: Maybe<Lootboxes_Free_Views_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_free_views" */
  insert_lootboxes_free_views_one?: Maybe<Lootboxes_Free_Views>;
  /** insert data into the table: "lootboxes_generator_mechanics" */
  insert_lootboxes_generator_mechanics?: Maybe<Lootboxes_Generator_Mechanics_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_generator_mechanics" */
  insert_lootboxes_generator_mechanics_one?: Maybe<Lootboxes_Generator_Mechanics>;
  /** insert data into the table: "lootboxes_generator_params" */
  insert_lootboxes_generator_params?: Maybe<Lootboxes_Generator_Params_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_generator_params" */
  insert_lootboxes_generator_params_one?: Maybe<Lootboxes_Generator_Params>;
  /** insert data into the table: "lootboxes_history" */
  insert_lootboxes_history?: Maybe<Lootboxes_History_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_history" */
  insert_lootboxes_history_one?: Maybe<Lootboxes_History>;
  /** insert data into the table: "lootboxes_items_names" */
  insert_lootboxes_items_names?: Maybe<Lootboxes_Items_Names_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_items_names" */
  insert_lootboxes_items_names_one?: Maybe<Lootboxes_Items_Names>;
  /** insert a single row into the table: "lootboxes" */
  insert_lootboxes_one?: Maybe<Lootboxes>;
  /** insert data into the table: "lootboxes_top_drop" */
  insert_lootboxes_top_drop?: Maybe<Lootboxes_Top_Drop_Mutation_Response>;
  /** insert a single row into the table: "lootboxes_top_drop" */
  insert_lootboxes_top_drop_one?: Maybe<Lootboxes_Top_Drop>;
  /** insert data into the table: "mail_autosending_types" */
  insert_mail_autosending_types?: Maybe<Mail_Autosending_Types_Mutation_Response>;
  /** insert a single row into the table: "mail_autosending_types" */
  insert_mail_autosending_types_one?: Maybe<Mail_Autosending_Types>;
  /** insert data into the table: "mail_bonuses" */
  insert_mail_bonuses?: Maybe<Mail_Bonuses_Mutation_Response>;
  /** insert data into the table: "mail_bonuses_offers" */
  insert_mail_bonuses_offers?: Maybe<Mail_Bonuses_Offers_Mutation_Response>;
  /** insert a single row into the table: "mail_bonuses_offers" */
  insert_mail_bonuses_offers_one?: Maybe<Mail_Bonuses_Offers>;
  /** insert a single row into the table: "mail_bonuses" */
  insert_mail_bonuses_one?: Maybe<Mail_Bonuses>;
  /** insert data into the table: "mail_mailing_callbacks" */
  insert_mail_mailing_callbacks?: Maybe<Mail_Mailing_Callbacks_Mutation_Response>;
  /** insert a single row into the table: "mail_mailing_callbacks" */
  insert_mail_mailing_callbacks_one?: Maybe<Mail_Mailing_Callbacks>;
  /** insert data into the table: "mail_mailing_lines" */
  insert_mail_mailing_lines?: Maybe<Mail_Mailing_Lines_Mutation_Response>;
  /** insert a single row into the table: "mail_mailing_lines" */
  insert_mail_mailing_lines_one?: Maybe<Mail_Mailing_Lines>;
  /** insert data into the table: "mail_mailing_lists" */
  insert_mail_mailing_lists?: Maybe<Mail_Mailing_Lists_Mutation_Response>;
  /** insert a single row into the table: "mail_mailing_lists" */
  insert_mail_mailing_lists_one?: Maybe<Mail_Mailing_Lists>;
  /** insert data into the table: "mail_mailing_scheduling" */
  insert_mail_mailing_scheduling?: Maybe<Mail_Mailing_Scheduling_Mutation_Response>;
  /** insert a single row into the table: "mail_mailing_scheduling" */
  insert_mail_mailing_scheduling_one?: Maybe<Mail_Mailing_Scheduling>;
  /** insert data into the table: "mail_mailing_sending_log" */
  insert_mail_mailing_sending_log?: Maybe<Mail_Mailing_Sending_Log_Mutation_Response>;
  /** insert a single row into the table: "mail_mailing_sending_log" */
  insert_mail_mailing_sending_log_one?: Maybe<Mail_Mailing_Sending_Log>;
  /** insert data into the table: "mail_mailing_triggers" */
  insert_mail_mailing_triggers?: Maybe<Mail_Mailing_Triggers_Mutation_Response>;
  /** insert data into the table: "mail_mailing_triggers_groups" */
  insert_mail_mailing_triggers_groups?: Maybe<Mail_Mailing_Triggers_Groups_Mutation_Response>;
  /** insert a single row into the table: "mail_mailing_triggers_groups" */
  insert_mail_mailing_triggers_groups_one?: Maybe<Mail_Mailing_Triggers_Groups>;
  /** insert a single row into the table: "mail_mailing_triggers" */
  insert_mail_mailing_triggers_one?: Maybe<Mail_Mailing_Triggers>;
  /** insert data into the table: "mail_templates" */
  insert_mail_templates?: Maybe<Mail_Templates_Mutation_Response>;
  /** insert a single row into the table: "mail_templates" */
  insert_mail_templates_one?: Maybe<Mail_Templates>;
  /** insert data into the table: "mainpage_counters" */
  insert_mainpage_counters?: Maybe<Mainpage_Counters_Mutation_Response>;
  /** insert a single row into the table: "mainpage_counters" */
  insert_mainpage_counters_one?: Maybe<Mainpage_Counters>;
  /** insert data into the table: "maintenance_mode" */
  insert_maintenance_mode?: Maybe<Maintenance_Mode_Mutation_Response>;
  /** insert a single row into the table: "maintenance_mode" */
  insert_maintenance_mode_one?: Maybe<Maintenance_Mode>;
  /** insert data into the table: "market_groups_conn" */
  insert_market_groups_conn?: Maybe<Market_Groups_Conn_Mutation_Response>;
  /** insert a single row into the table: "market_groups_conn" */
  insert_market_groups_conn_one?: Maybe<Market_Groups_Conn>;
  /** insert data into the table: "metas" */
  insert_metas?: Maybe<Metas_Mutation_Response>;
  /** insert a single row into the table: "metas" */
  insert_metas_one?: Maybe<Metas>;
  /** insert data into the table: "missions" */
  insert_missions?: Maybe<Missions_Mutation_Response>;
  /** insert a single row into the table: "missions" */
  insert_missions_one?: Maybe<Missions>;
  /** insert data into the table: "missions_onetime_bonus_case_claims" */
  insert_missions_onetime_bonus_case_claims?: Maybe<Missions_Onetime_Bonus_Case_Claims_Mutation_Response>;
  /** insert a single row into the table: "missions_onetime_bonus_case_claims" */
  insert_missions_onetime_bonus_case_claims_one?: Maybe<Missions_Onetime_Bonus_Case_Claims>;
  /** insert data into the table: "missions_onetime_params" */
  insert_missions_onetime_params?: Maybe<Missions_Onetime_Params_Mutation_Response>;
  /** insert a single row into the table: "missions_onetime_params" */
  insert_missions_onetime_params_one?: Maybe<Missions_Onetime_Params>;
  /** insert data into the table: "missions_onetime_users_progress" */
  insert_missions_onetime_users_progress?: Maybe<Missions_Onetime_Users_Progress_Mutation_Response>;
  /** insert a single row into the table: "missions_onetime_users_progress" */
  insert_missions_onetime_users_progress_one?: Maybe<Missions_Onetime_Users_Progress>;
  /** insert data into the table: "missions_permanent_params" */
  insert_missions_permanent_params?: Maybe<Missions_Permanent_Params_Mutation_Response>;
  /** insert a single row into the table: "missions_permanent_params" */
  insert_missions_permanent_params_one?: Maybe<Missions_Permanent_Params>;
  /** insert data into the table: "missions_permanent_user_progress" */
  insert_missions_permanent_user_progress?: Maybe<Missions_Permanent_User_Progress_Mutation_Response>;
  /** insert a single row into the table: "missions_permanent_user_progress" */
  insert_missions_permanent_user_progress_one?: Maybe<Missions_Permanent_User_Progress>;
  /** insert data into the table: "missions_tags" */
  insert_missions_tags?: Maybe<Missions_Tags_Mutation_Response>;
  /** insert data into the table: "missions_tags_names" */
  insert_missions_tags_names?: Maybe<Missions_Tags_Names_Mutation_Response>;
  /** insert a single row into the table: "missions_tags_names" */
  insert_missions_tags_names_one?: Maybe<Missions_Tags_Names>;
  /** insert a single row into the table: "missions_tags" */
  insert_missions_tags_one?: Maybe<Missions_Tags>;
  /** insert data into the table: "missions_vk_links" */
  insert_missions_vk_links?: Maybe<Missions_Vk_Links_Mutation_Response>;
  /** insert a single row into the table: "missions_vk_links" */
  insert_missions_vk_links_one?: Maybe<Missions_Vk_Links>;
  /** insert data into the table: "new_year_competition_boosters_rewards" */
  insert_new_year_competition_boosters_rewards?: Maybe<New_Year_Competition_Boosters_Rewards_Mutation_Response>;
  /** insert a single row into the table: "new_year_competition_boosters_rewards" */
  insert_new_year_competition_boosters_rewards_one?: Maybe<New_Year_Competition_Boosters_Rewards>;
  /** insert data into the table: "new_year_competition_participants" */
  insert_new_year_competition_participants?: Maybe<New_Year_Competition_Participants_Mutation_Response>;
  /** insert a single row into the table: "new_year_competition_participants" */
  insert_new_year_competition_participants_one?: Maybe<New_Year_Competition_Participants>;
  /** insert data into the table: "new_year_competition_participants_performance" */
  insert_new_year_competition_participants_performance?: Maybe<New_Year_Competition_Participants_Performance_Mutation_Response>;
  /** insert a single row into the table: "new_year_competition_participants_performance" */
  insert_new_year_competition_participants_performance_one?: Maybe<New_Year_Competition_Participants_Performance>;
  /** insert data into the table: "new_year_competition_teams" */
  insert_new_year_competition_teams?: Maybe<New_Year_Competition_Teams_Mutation_Response>;
  /** insert a single row into the table: "new_year_competition_teams" */
  insert_new_year_competition_teams_one?: Maybe<New_Year_Competition_Teams>;
  /** insert data into the table: "operation_types" */
  insert_operation_types?: Maybe<Operation_Types_Mutation_Response>;
  /** insert a single row into the table: "operation_types" */
  insert_operation_types_one?: Maybe<Operation_Types>;
  /** insert data into the table: "parsers_timestamp_log" */
  insert_parsers_timestamp_log?: Maybe<Parsers_Timestamp_Log_Mutation_Response>;
  /** insert a single row into the table: "parsers_timestamp_log" */
  insert_parsers_timestamp_log_one?: Maybe<Parsers_Timestamp_Log>;
  /** insert data into the table: "partner_balance_transactions" */
  insert_partner_balance_transactions?: Maybe<Partner_Balance_Transactions_Mutation_Response>;
  /** insert a single row into the table: "partner_balance_transactions" */
  insert_partner_balance_transactions_one?: Maybe<Partner_Balance_Transactions>;
  /** insert data into the table: "partner_offer_types" */
  insert_partner_offer_types?: Maybe<Partner_Offer_Types_Mutation_Response>;
  /** insert a single row into the table: "partner_offer_types" */
  insert_partner_offer_types_one?: Maybe<Partner_Offer_Types>;
  /** insert data into the table: "partner_params" */
  insert_partner_params?: Maybe<Partner_Params_Mutation_Response>;
  /** insert data into the table: "partner_params_audit" */
  insert_partner_params_audit?: Maybe<Partner_Params_Audit_Mutation_Response>;
  /** insert a single row into the table: "partner_params_audit" */
  insert_partner_params_audit_one?: Maybe<Partner_Params_Audit>;
  /** insert a single row into the table: "partner_params" */
  insert_partner_params_one?: Maybe<Partner_Params>;
  /** insert data into the table: "passport_info" */
  insert_passport_info?: Maybe<Passport_Info_Mutation_Response>;
  /** insert a single row into the table: "passport_info" */
  insert_passport_info_one?: Maybe<Passport_Info>;
  /** insert data into the table: "payment_methods" */
  insert_payment_methods?: Maybe<Payment_Methods_Mutation_Response>;
  /** insert a single row into the table: "payment_methods" */
  insert_payment_methods_one?: Maybe<Payment_Methods>;
  /** insert data into the table: "payment_methods_view" */
  insert_payment_methods_view?: Maybe<Payment_Methods_View_Mutation_Response>;
  /** insert a single row into the table: "payment_methods_view" */
  insert_payment_methods_view_one?: Maybe<Payment_Methods_View>;
  /** insert data into the table: "payment_providers" */
  insert_payment_providers?: Maybe<Payment_Providers_Mutation_Response>;
  /** insert a single row into the table: "payment_providers" */
  insert_payment_providers_one?: Maybe<Payment_Providers>;
  /** insert data into the table: "payment_recurring_tokens" */
  insert_payment_recurring_tokens?: Maybe<Payment_Recurring_Tokens_Mutation_Response>;
  /** insert a single row into the table: "payment_recurring_tokens" */
  insert_payment_recurring_tokens_one?: Maybe<Payment_Recurring_Tokens>;
  /** insert data into the table: "payment_requisites" */
  insert_payment_requisites?: Maybe<Payment_Requisites_Mutation_Response>;
  /** insert a single row into the table: "payment_requisites" */
  insert_payment_requisites_one?: Maybe<Payment_Requisites>;
  /** insert data into the table: "payment_system_1payment_transaction_requests" */
  insert_payment_system_1payment_transaction_requests?: Maybe<Payment_System_1payment_Transaction_Requests_Mutation_Response>;
  /** insert a single row into the table: "payment_system_1payment_transaction_requests" */
  insert_payment_system_1payment_transaction_requests_one?: Maybe<Payment_System_1payment_Transaction_Requests>;
  /** insert data into the table: "payment_system_free_kassa_callbacks" */
  insert_payment_system_free_kassa_callbacks?: Maybe<Payment_System_Free_Kassa_Callbacks_Mutation_Response>;
  /** insert a single row into the table: "payment_system_free_kassa_callbacks" */
  insert_payment_system_free_kassa_callbacks_one?: Maybe<Payment_System_Free_Kassa_Callbacks>;
  /** insert data into the table: "payment_system_free_kassa_transaction_requests" */
  insert_payment_system_free_kassa_transaction_requests?: Maybe<Payment_System_Free_Kassa_Transaction_Requests_Mutation_Response>;
  /** insert a single row into the table: "payment_system_free_kassa_transaction_requests" */
  insert_payment_system_free_kassa_transaction_requests_one?: Maybe<Payment_System_Free_Kassa_Transaction_Requests>;
  /** insert data into the table: "payment_system_main_callbacks" */
  insert_payment_system_main_callbacks?: Maybe<Payment_System_Main_Callbacks_Mutation_Response>;
  /** insert a single row into the table: "payment_system_main_callbacks" */
  insert_payment_system_main_callbacks_one?: Maybe<Payment_System_Main_Callbacks>;
  /** insert data into the table: "payment_system_main_transaction_requests" */
  insert_payment_system_main_transaction_requests?: Maybe<Payment_System_Main_Transaction_Requests_Mutation_Response>;
  /** insert a single row into the table: "payment_system_main_transaction_requests" */
  insert_payment_system_main_transaction_requests_one?: Maybe<Payment_System_Main_Transaction_Requests>;
  /** insert data into the table: "payment_systems_1payment_callbacks" */
  insert_payment_systems_1payment_callbacks?: Maybe<Payment_Systems_1payment_Callbacks_Mutation_Response>;
  /** insert a single row into the table: "payment_systems_1payment_callbacks" */
  insert_payment_systems_1payment_callbacks_one?: Maybe<Payment_Systems_1payment_Callbacks>;
  /** insert data into the table: "payment_systems_callbacks" */
  insert_payment_systems_callbacks?: Maybe<Payment_Systems_Callbacks_Mutation_Response>;
  /** insert a single row into the table: "payment_systems_callbacks" */
  insert_payment_systems_callbacks_one?: Maybe<Payment_Systems_Callbacks>;
  /** insert data into the table: "payment_systems_data" */
  insert_payment_systems_data?: Maybe<Payment_Systems_Data_Mutation_Response>;
  /** insert a single row into the table: "payment_systems_data" */
  insert_payment_systems_data_one?: Maybe<Payment_Systems_Data>;
  /** insert data into the table: "payment_systems_methods" */
  insert_payment_systems_methods?: Maybe<Payment_Systems_Methods_Mutation_Response>;
  /** insert a single row into the table: "payment_systems_methods" */
  insert_payment_systems_methods_one?: Maybe<Payment_Systems_Methods>;
  /** insert data into the table: "payment_systems_transaction_requests" */
  insert_payment_systems_transaction_requests?: Maybe<Payment_Systems_Transaction_Requests_Mutation_Response>;
  /** insert a single row into the table: "payment_systems_transaction_requests" */
  insert_payment_systems_transaction_requests_one?: Maybe<Payment_Systems_Transaction_Requests>;
  /** insert data into the table: "payment_transactions" */
  insert_payment_transactions?: Maybe<Payment_Transactions_Mutation_Response>;
  /** insert a single row into the table: "payment_transactions" */
  insert_payment_transactions_one?: Maybe<Payment_Transactions>;
  /** insert data into the table: "profile_items_names" */
  insert_profile_items_names?: Maybe<Profile_Items_Names_Mutation_Response>;
  /** insert a single row into the table: "profile_items_names" */
  insert_profile_items_names_one?: Maybe<Profile_Items_Names>;
  /** insert data into the table: "promo_lootboxes" */
  insert_promo_lootboxes?: Maybe<Promo_Lootboxes_Mutation_Response>;
  /** insert a single row into the table: "promo_lootboxes" */
  insert_promo_lootboxes_one?: Maybe<Promo_Lootboxes>;
  /** insert data into the table: "promo_users" */
  insert_promo_users?: Maybe<Promo_Users_Mutation_Response>;
  /** insert a single row into the table: "promo_users" */
  insert_promo_users_one?: Maybe<Promo_Users>;
  /** insert data into the table: "promo_values" */
  insert_promo_values?: Maybe<Promo_Values_Mutation_Response>;
  /** insert a single row into the table: "promo_values" */
  insert_promo_values_one?: Maybe<Promo_Values>;
  /** insert data into the table: "promocode_activations" */
  insert_promocode_activations?: Maybe<Promocode_Activations_Mutation_Response>;
  /** insert a single row into the table: "promocode_activations" */
  insert_promocode_activations_one?: Maybe<Promocode_Activations>;
  /** insert data into the table: "promocodes" */
  insert_promocodes?: Maybe<Promocodes_Mutation_Response>;
  /** insert a single row into the table: "promocodes" */
  insert_promocodes_one?: Maybe<Promocodes>;
  /** insert data into the table: "ps_types" */
  insert_ps_types?: Maybe<Ps_Types_Mutation_Response>;
  /** insert a single row into the table: "ps_types" */
  insert_ps_types_one?: Maybe<Ps_Types>;
  /** insert data into the table: "qservice_data" */
  insert_qservice_data?: Maybe<Qservice_Data_Mutation_Response>;
  /** insert a single row into the table: "qservice_data" */
  insert_qservice_data_one?: Maybe<Qservice_Data>;
  /** insert data into the table: "statistic_bonus_wheels_users_offers" */
  insert_statistic_bonus_wheels_users_offers?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Mutation_Response>;
  /** insert a single row into the table: "statistic_bonus_wheels_users_offers" */
  insert_statistic_bonus_wheels_users_offers_one?: Maybe<Statistic_Bonus_Wheels_Users_Offers>;
  /** insert data into the table: "statistic_button_again_press" */
  insert_statistic_button_again_press?: Maybe<Statistic_Button_Again_Press_Mutation_Response>;
  /** insert a single row into the table: "statistic_button_again_press" */
  insert_statistic_button_again_press_one?: Maybe<Statistic_Button_Again_Press>;
  /** insert data into the table: "statistic_fast_roll_trigger" */
  insert_statistic_fast_roll_trigger?: Maybe<Statistic_Fast_Roll_Trigger_Mutation_Response>;
  /** insert a single row into the table: "statistic_fast_roll_trigger" */
  insert_statistic_fast_roll_trigger_one?: Maybe<Statistic_Fast_Roll_Trigger>;
  /** insert data into the table: "statistic_inventory_opens" */
  insert_statistic_inventory_opens?: Maybe<Statistic_Inventory_Opens_Mutation_Response>;
  /** insert a single row into the table: "statistic_inventory_opens" */
  insert_statistic_inventory_opens_one?: Maybe<Statistic_Inventory_Opens>;
  /** insert data into the table: "statistic_items_drops" */
  insert_statistic_items_drops?: Maybe<Statistic_Items_Drops_Mutation_Response>;
  /** insert a single row into the table: "statistic_items_drops" */
  insert_statistic_items_drops_one?: Maybe<Statistic_Items_Drops>;
  /** insert data into the table: "statistic_items_names" */
  insert_statistic_items_names?: Maybe<Statistic_Items_Names_Mutation_Response>;
  /** insert a single row into the table: "statistic_items_names" */
  insert_statistic_items_names_one?: Maybe<Statistic_Items_Names>;
  /** insert data into the table: "statistic_items_output_fail" */
  insert_statistic_items_output_fail?: Maybe<Statistic_Items_Output_Fail_Mutation_Response>;
  /** insert a single row into the table: "statistic_items_output_fail" */
  insert_statistic_items_output_fail_one?: Maybe<Statistic_Items_Output_Fail>;
  /** insert data into the table: "statistic_items_output_success" */
  insert_statistic_items_output_success?: Maybe<Statistic_Items_Output_Success_Mutation_Response>;
  /** insert a single row into the table: "statistic_items_output_success" */
  insert_statistic_items_output_success_one?: Maybe<Statistic_Items_Output_Success>;
  /** insert data into the table: "statistic_lootbox_opens" */
  insert_statistic_lootbox_opens?: Maybe<Statistic_Lootbox_Opens_Mutation_Response>;
  /** insert a single row into the table: "statistic_lootbox_opens" */
  insert_statistic_lootbox_opens_one?: Maybe<Statistic_Lootbox_Opens>;
  /** insert data into the table: "statistic_lootbox_views" */
  insert_statistic_lootbox_views?: Maybe<Statistic_Lootbox_Views_Mutation_Response>;
  /** insert a single row into the table: "statistic_lootbox_views" */
  insert_statistic_lootbox_views_one?: Maybe<Statistic_Lootbox_Views>;
  /** insert data into the table: "statistic_mainpage_visits" */
  insert_statistic_mainpage_visits?: Maybe<Statistic_Mainpage_Visits_Mutation_Response>;
  /** insert a single row into the table: "statistic_mainpage_visits" */
  insert_statistic_mainpage_visits_one?: Maybe<Statistic_Mainpage_Visits>;
  /** insert data into the table: "statistic_promocode_full_activations" */
  insert_statistic_promocode_full_activations?: Maybe<Statistic_Promocode_Full_Activations_Mutation_Response>;
  /** insert a single row into the table: "statistic_promocode_full_activations" */
  insert_statistic_promocode_full_activations_one?: Maybe<Statistic_Promocode_Full_Activations>;
  /** insert data into the table: "statistic_promocode_success_activations" */
  insert_statistic_promocode_success_activations?: Maybe<Statistic_Promocode_Success_Activations_Mutation_Response>;
  /** insert a single row into the table: "statistic_promocode_success_activations" */
  insert_statistic_promocode_success_activations_one?: Maybe<Statistic_Promocode_Success_Activations>;
  /** insert data into the table: "statistic_sell_all_items" */
  insert_statistic_sell_all_items?: Maybe<Statistic_Sell_All_Items_Mutation_Response>;
  /** insert a single row into the table: "statistic_sell_all_items" */
  insert_statistic_sell_all_items_one?: Maybe<Statistic_Sell_All_Items>;
  /** insert data into the table: "statistic_sell_item" */
  insert_statistic_sell_item?: Maybe<Statistic_Sell_Item_Mutation_Response>;
  /** insert a single row into the table: "statistic_sell_item" */
  insert_statistic_sell_item_one?: Maybe<Statistic_Sell_Item>;
  /** insert data into the table: "statistic_user_deposit_success_callbacks" */
  insert_statistic_user_deposit_success_callbacks?: Maybe<Statistic_User_Deposit_Success_Callbacks_Mutation_Response>;
  /** insert a single row into the table: "statistic_user_deposit_success_callbacks" */
  insert_statistic_user_deposit_success_callbacks_one?: Maybe<Statistic_User_Deposit_Success_Callbacks>;
  /** insert data into the table: "statistic_user_deposits_transaction_requests" */
  insert_statistic_user_deposits_transaction_requests?: Maybe<Statistic_User_Deposits_Transaction_Requests_Mutation_Response>;
  /** insert a single row into the table: "statistic_user_deposits_transaction_requests" */
  insert_statistic_user_deposits_transaction_requests_one?: Maybe<Statistic_User_Deposits_Transaction_Requests>;
  /** insert data into the table: "statistic_user_free_lootboxes_opens" */
  insert_statistic_user_free_lootboxes_opens?: Maybe<Statistic_User_Free_Lootboxes_Opens_Mutation_Response>;
  /** insert a single row into the table: "statistic_user_free_lootboxes_opens" */
  insert_statistic_user_free_lootboxes_opens_one?: Maybe<Statistic_User_Free_Lootboxes_Opens>;
  /** insert data into the table: "statistic_user_registration" */
  insert_statistic_user_registration?: Maybe<Statistic_User_Registration_Mutation_Response>;
  /** insert a single row into the table: "statistic_user_registration" */
  insert_statistic_user_registration_one?: Maybe<Statistic_User_Registration>;
  /** insert data into the table: "stocks" */
  insert_stocks?: Maybe<Stocks_Mutation_Response>;
  /** insert a single row into the table: "stocks" */
  insert_stocks_one?: Maybe<Stocks>;
  /** insert data into the table: "store_offer_badges" */
  insert_store_offer_badges?: Maybe<Store_Offer_Badges_Mutation_Response>;
  /** insert a single row into the table: "store_offer_badges" */
  insert_store_offer_badges_one?: Maybe<Store_Offer_Badges>;
  /** insert data into the table: "store_offer_boosters" */
  insert_store_offer_boosters?: Maybe<Store_Offer_Boosters_Mutation_Response>;
  /** insert a single row into the table: "store_offer_boosters" */
  insert_store_offer_boosters_one?: Maybe<Store_Offer_Boosters>;
  /** insert data into the table: "store_offer_coins" */
  insert_store_offer_coins?: Maybe<Store_Offer_Coins_Mutation_Response>;
  /** insert a single row into the table: "store_offer_coins" */
  insert_store_offer_coins_one?: Maybe<Store_Offer_Coins>;
  /** insert data into the table: "store_offer_lootboxes" */
  insert_store_offer_lootboxes?: Maybe<Store_Offer_Lootboxes_Mutation_Response>;
  /** insert a single row into the table: "store_offer_lootboxes" */
  insert_store_offer_lootboxes_one?: Maybe<Store_Offer_Lootboxes>;
  /** insert data into the table: "store_offer_sales" */
  insert_store_offer_sales?: Maybe<Store_Offer_Sales_Mutation_Response>;
  /** insert a single row into the table: "store_offer_sales" */
  insert_store_offer_sales_one?: Maybe<Store_Offer_Sales>;
  /** insert data into the table: "store_offer_skins" */
  insert_store_offer_skins?: Maybe<Store_Offer_Skins_Mutation_Response>;
  /** insert a single row into the table: "store_offer_skins" */
  insert_store_offer_skins_one?: Maybe<Store_Offer_Skins>;
  /** insert data into the table: "store_offer_tickets" */
  insert_store_offer_tickets?: Maybe<Store_Offer_Tickets_Mutation_Response>;
  /** insert a single row into the table: "store_offer_tickets" */
  insert_store_offer_tickets_one?: Maybe<Store_Offer_Tickets>;
  /** insert data into the table: "store_offer_types" */
  insert_store_offer_types?: Maybe<Store_Offer_Types_Mutation_Response>;
  /** insert a single row into the table: "store_offer_types" */
  insert_store_offer_types_one?: Maybe<Store_Offer_Types>;
  /** insert data into the table: "store_offers" */
  insert_store_offers?: Maybe<Store_Offers_Mutation_Response>;
  /** insert a single row into the table: "store_offers" */
  insert_store_offers_one?: Maybe<Store_Offers>;
  /** insert data into the table: "survey_data_links" */
  insert_survey_data_links?: Maybe<Survey_Data_Links_Mutation_Response>;
  /** insert a single row into the table: "survey_data_links" */
  insert_survey_data_links_one?: Maybe<Survey_Data_Links>;
  /** insert data into the table: "telegram_admin_params" */
  insert_telegram_admin_params?: Maybe<Telegram_Admin_Params_Mutation_Response>;
  /** insert a single row into the table: "telegram_admin_params" */
  insert_telegram_admin_params_one?: Maybe<Telegram_Admin_Params>;
  /** insert data into the table: "ticket_counts_result" */
  insert_ticket_counts_result?: Maybe<Ticket_Counts_Result_Mutation_Response>;
  /** insert a single row into the table: "ticket_counts_result" */
  insert_ticket_counts_result_one?: Maybe<Ticket_Counts_Result>;
  /** insert data into the table: "tickets_set_content" */
  insert_tickets_set_content?: Maybe<Tickets_Set_Content_Mutation_Response>;
  /** insert a single row into the table: "tickets_set_content" */
  insert_tickets_set_content_one?: Maybe<Tickets_Set_Content>;
  /** insert data into the table: "tickets_sets" */
  insert_tickets_sets?: Maybe<Tickets_Sets_Mutation_Response>;
  /** insert a single row into the table: "tickets_sets" */
  insert_tickets_sets_one?: Maybe<Tickets_Sets>;
  /** insert data into the table: "top_drop_strip" */
  insert_top_drop_strip?: Maybe<Top_Drop_Strip_Mutation_Response>;
  /** insert a single row into the table: "top_drop_strip" */
  insert_top_drop_strip_one?: Maybe<Top_Drop_Strip>;
  /** insert data into the table: "transaction" */
  insert_transaction?: Maybe<Transaction_Mutation_Response>;
  /** insert a single row into the table: "transaction" */
  insert_transaction_one?: Maybe<Transaction>;
  /** insert data into the table: "url_mappings" */
  insert_url_mappings?: Maybe<Url_Mappings_Mutation_Response>;
  /** insert a single row into the table: "url_mappings" */
  insert_url_mappings_one?: Maybe<Url_Mappings>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "user_account" */
  insert_user_account?: Maybe<User_Account_Mutation_Response>;
  /** insert a single row into the table: "user_account" */
  insert_user_account_one?: Maybe<User_Account>;
  /** insert data into the table: "user_auth" */
  insert_user_auth?: Maybe<User_Auth_Mutation_Response>;
  /** insert a single row into the table: "user_auth" */
  insert_user_auth_one?: Maybe<User_Auth>;
  /** insert data into the table: "user_bonus_wallet" */
  insert_user_bonus_wallet?: Maybe<User_Bonus_Wallet_Mutation_Response>;
  /** insert a single row into the table: "user_bonus_wallet" */
  insert_user_bonus_wallet_one?: Maybe<User_Bonus_Wallet>;
  /** insert data into the table: "user_bonus_wallet_transactions" */
  insert_user_bonus_wallet_transactions?: Maybe<User_Bonus_Wallet_Transactions_Mutation_Response>;
  /** insert a single row into the table: "user_bonus_wallet_transactions" */
  insert_user_bonus_wallet_transactions_one?: Maybe<User_Bonus_Wallet_Transactions>;
  /** insert data into the table: "user_boosters_in_inventory" */
  insert_user_boosters_in_inventory?: Maybe<User_Boosters_In_Inventory_Mutation_Response>;
  /** insert a single row into the table: "user_boosters_in_inventory" */
  insert_user_boosters_in_inventory_one?: Maybe<User_Boosters_In_Inventory>;
  /** insert data into the table: "user_contacts" */
  insert_user_contacts?: Maybe<User_Contacts_Mutation_Response>;
  /** insert a single row into the table: "user_contacts" */
  insert_user_contacts_one?: Maybe<User_Contacts>;
  /** insert data into the table: "user_correct_answers_rewards" */
  insert_user_correct_answers_rewards?: Maybe<User_Correct_Answers_Rewards_Mutation_Response>;
  /** insert a single row into the table: "user_correct_answers_rewards" */
  insert_user_correct_answers_rewards_one?: Maybe<User_Correct_Answers_Rewards>;
  /** insert data into the table: "user_daily_coins_claims" */
  insert_user_daily_coins_claims?: Maybe<User_Daily_Coins_Claims_Mutation_Response>;
  /** insert a single row into the table: "user_daily_coins_claims" */
  insert_user_daily_coins_claims_one?: Maybe<User_Daily_Coins_Claims>;
  /** insert data into the table: "user_daily_reward_counts" */
  insert_user_daily_reward_counts?: Maybe<User_Daily_Reward_Counts_Mutation_Response>;
  /** insert a single row into the table: "user_daily_reward_counts" */
  insert_user_daily_reward_counts_one?: Maybe<User_Daily_Reward_Counts>;
  /** insert data into the table: "user_email_verification_data" */
  insert_user_email_verification_data?: Maybe<User_Email_Verification_Data_Mutation_Response>;
  /** insert a single row into the table: "user_email_verification_data" */
  insert_user_email_verification_data_one?: Maybe<User_Email_Verification_Data>;
  /** insert data into the table: "user_events" */
  insert_user_events?: Maybe<User_Events_Mutation_Response>;
  /** insert a single row into the table: "user_events" */
  insert_user_events_one?: Maybe<User_Events>;
  /** insert data into the table: "user_got_bonus" */
  insert_user_got_bonus?: Maybe<User_Got_Bonus_Mutation_Response>;
  /** insert a single row into the table: "user_got_bonus" */
  insert_user_got_bonus_one?: Maybe<User_Got_Bonus>;
  /** insert data into the table: "user_inventory" */
  insert_user_inventory?: Maybe<User_Inventory_Mutation_Response>;
  /** insert a single row into the table: "user_inventory" */
  insert_user_inventory_one?: Maybe<User_Inventory>;
  /** insert data into the table: "user_inventory_transactions" */
  insert_user_inventory_transactions?: Maybe<User_Inventory_Transactions_Mutation_Response>;
  /** insert a single row into the table: "user_inventory_transactions" */
  insert_user_inventory_transactions_one?: Maybe<User_Inventory_Transactions>;
  /** insert data into the table: "user_items_in_inventory" */
  insert_user_items_in_inventory?: Maybe<User_Items_In_Inventory_Mutation_Response>;
  /** insert a single row into the table: "user_items_in_inventory" */
  insert_user_items_in_inventory_one?: Maybe<User_Items_In_Inventory>;
  /** insert data into the table: "user_lootbox_opens_count" */
  insert_user_lootbox_opens_count?: Maybe<User_Lootbox_Opens_Count_Mutation_Response>;
  /** insert a single row into the table: "user_lootbox_opens_count" */
  insert_user_lootbox_opens_count_one?: Maybe<User_Lootbox_Opens_Count>;
  /** insert data into the table: "user_marking" */
  insert_user_marking?: Maybe<User_Marking_Mutation_Response>;
  /** insert a single row into the table: "user_marking" */
  insert_user_marking_one?: Maybe<User_Marking>;
  /** insert data into the table: "user_markings" */
  insert_user_markings?: Maybe<User_Markings_Mutation_Response>;
  /** insert a single row into the table: "user_markings" */
  insert_user_markings_one?: Maybe<User_Markings>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_output_limits" */
  insert_user_output_limits?: Maybe<User_Output_Limits_Mutation_Response>;
  /** insert a single row into the table: "user_output_limits" */
  insert_user_output_limits_one?: Maybe<User_Output_Limits>;
  /** insert data into the table: "user_profile" */
  insert_user_profile?: Maybe<User_Profile_Mutation_Response>;
  /** insert a single row into the table: "user_profile" */
  insert_user_profile_one?: Maybe<User_Profile>;
  /** insert data into the table: "user_push_tokens" */
  insert_user_push_tokens?: Maybe<User_Push_Tokens_Mutation_Response>;
  /** insert a single row into the table: "user_push_tokens" */
  insert_user_push_tokens_one?: Maybe<User_Push_Tokens>;
  /** insert data into the table: "user_push_topics" */
  insert_user_push_topics?: Maybe<User_Push_Topics_Mutation_Response>;
  /** insert a single row into the table: "user_push_topics" */
  insert_user_push_topics_one?: Maybe<User_Push_Topics>;
  /** insert data into the table: "user_referal_levels_params" */
  insert_user_referal_levels_params?: Maybe<User_Referal_Levels_Params_Mutation_Response>;
  /** insert a single row into the table: "user_referal_levels_params" */
  insert_user_referal_levels_params_one?: Maybe<User_Referal_Levels_Params>;
  /** insert data into the table: "user_referal_params" */
  insert_user_referal_params?: Maybe<User_Referal_Params_Mutation_Response>;
  /** insert a single row into the table: "user_referal_params" */
  insert_user_referal_params_one?: Maybe<User_Referal_Params>;
  /** insert data into the table: "user_referals" */
  insert_user_referals?: Maybe<User_Referals_Mutation_Response>;
  /** insert data into the table: "user_referals_balance_transactions" */
  insert_user_referals_balance_transactions?: Maybe<User_Referals_Balance_Transactions_Mutation_Response>;
  /** insert a single row into the table: "user_referals_balance_transactions" */
  insert_user_referals_balance_transactions_one?: Maybe<User_Referals_Balance_Transactions>;
  /** insert data into the table: "user_referals_bonus_history" */
  insert_user_referals_bonus_history?: Maybe<User_Referals_Bonus_History_Mutation_Response>;
  /** insert a single row into the table: "user_referals_bonus_history" */
  insert_user_referals_bonus_history_one?: Maybe<User_Referals_Bonus_History>;
  /** insert a single row into the table: "user_referals" */
  insert_user_referals_one?: Maybe<User_Referals>;
  /** insert data into the table: "user_referral" */
  insert_user_referral?: Maybe<User_Referral_Mutation_Response>;
  /** insert data into the table: "user_referral_association" */
  insert_user_referral_association?: Maybe<User_Referral_Association_Mutation_Response>;
  /** insert a single row into the table: "user_referral_association" */
  insert_user_referral_association_one?: Maybe<User_Referral_Association>;
  /** insert data into the table: "user_referral_bonus_type" */
  insert_user_referral_bonus_type?: Maybe<User_Referral_Bonus_Type_Mutation_Response>;
  /** insert a single row into the table: "user_referral_bonus_type" */
  insert_user_referral_bonus_type_one?: Maybe<User_Referral_Bonus_Type>;
  /** insert data into the table: "user_referral_levels_params" */
  insert_user_referral_levels_params?: Maybe<User_Referral_Levels_Params_Mutation_Response>;
  /** insert a single row into the table: "user_referral_levels_params" */
  insert_user_referral_levels_params_one?: Maybe<User_Referral_Levels_Params>;
  /** insert data into the table: "user_referral_offer_bonus" */
  insert_user_referral_offer_bonus?: Maybe<User_Referral_Offer_Bonus_Mutation_Response>;
  /** insert a single row into the table: "user_referral_offer_bonus" */
  insert_user_referral_offer_bonus_one?: Maybe<User_Referral_Offer_Bonus>;
  /** insert data into the table: "user_referral_offers" */
  insert_user_referral_offers?: Maybe<User_Referral_Offers_Mutation_Response>;
  /** insert a single row into the table: "user_referral_offers" */
  insert_user_referral_offers_one?: Maybe<User_Referral_Offers>;
  /** insert a single row into the table: "user_referral" */
  insert_user_referral_one?: Maybe<User_Referral>;
  /** insert data into the table: "user_referral_params" */
  insert_user_referral_params?: Maybe<User_Referral_Params_Mutation_Response>;
  /** insert a single row into the table: "user_referral_params" */
  insert_user_referral_params_one?: Maybe<User_Referral_Params>;
  /** insert data into the table: "user_referrals_balance_transactions" */
  insert_user_referrals_balance_transactions?: Maybe<User_Referrals_Balance_Transactions_Mutation_Response>;
  /** insert a single row into the table: "user_referrals_balance_transactions" */
  insert_user_referrals_balance_transactions_one?: Maybe<User_Referrals_Balance_Transactions>;
  /** insert data into the table: "user_seq_number" */
  insert_user_seq_number?: Maybe<User_Seq_Number_Mutation_Response>;
  /** insert a single row into the table: "user_seq_number" */
  insert_user_seq_number_one?: Maybe<User_Seq_Number>;
  /** insert data into the table: "user_settings" */
  insert_user_settings?: Maybe<User_Settings_Mutation_Response>;
  /** insert a single row into the table: "user_settings" */
  insert_user_settings_one?: Maybe<User_Settings>;
  /** insert data into the table: "user_statistic" */
  insert_user_statistic?: Maybe<User_Statistic_Mutation_Response>;
  /** insert a single row into the table: "user_statistic" */
  insert_user_statistic_one?: Maybe<User_Statistic>;
  /** insert data into the table: "user_steam_output_alternatives" */
  insert_user_steam_output_alternatives?: Maybe<User_Steam_Output_Alternatives_Mutation_Response>;
  /** insert a single row into the table: "user_steam_output_alternatives" */
  insert_user_steam_output_alternatives_one?: Maybe<User_Steam_Output_Alternatives>;
  /** insert data into the table: "user_steam_output_watcher" */
  insert_user_steam_output_watcher?: Maybe<User_Steam_Output_Watcher_Mutation_Response>;
  /** insert a single row into the table: "user_steam_output_watcher" */
  insert_user_steam_output_watcher_one?: Maybe<User_Steam_Output_Watcher>;
  /** insert data into the table: "user_takes_free_lootbox" */
  insert_user_takes_free_lootbox?: Maybe<User_Takes_Free_Lootbox_Mutation_Response>;
  /** insert a single row into the table: "user_takes_free_lootbox" */
  insert_user_takes_free_lootbox_one?: Maybe<User_Takes_Free_Lootbox>;
  /** insert data into the table: "user_upgrade_params" */
  insert_user_upgrade_params?: Maybe<User_Upgrade_Params_Mutation_Response>;
  /** insert a single row into the table: "user_upgrade_params" */
  insert_user_upgrade_params_one?: Maybe<User_Upgrade_Params>;
  /** insert data into the table: "user_upgrade_results" */
  insert_user_upgrade_results?: Maybe<User_Upgrade_Results_Mutation_Response>;
  /** insert a single row into the table: "user_upgrade_results" */
  insert_user_upgrade_results_one?: Maybe<User_Upgrade_Results>;
  /** insert data into the table: "user_vk_data" */
  insert_user_vk_data?: Maybe<User_Vk_Data_Mutation_Response>;
  /** insert a single row into the table: "user_vk_data" */
  insert_user_vk_data_one?: Maybe<User_Vk_Data>;
  /** insert data into the table: "user_wallet" */
  insert_user_wallet?: Maybe<User_Wallet_Mutation_Response>;
  /** insert data into the table: "user_wallet_audit" */
  insert_user_wallet_audit?: Maybe<User_Wallet_Audit_Mutation_Response>;
  /** insert a single row into the table: "user_wallet_audit" */
  insert_user_wallet_audit_one?: Maybe<User_Wallet_Audit>;
  /** insert a single row into the table: "user_wallet" */
  insert_user_wallet_one?: Maybe<User_Wallet>;
  /** insert data into the table: "user_wallet_transactions" */
  insert_user_wallet_transactions?: Maybe<User_Wallet_Transactions_Mutation_Response>;
  /** insert a single row into the table: "user_wallet_transactions" */
  insert_user_wallet_transactions_one?: Maybe<User_Wallet_Transactions>;
  /** insert data into the table: "user_whitelist" */
  insert_user_whitelist?: Maybe<User_Whitelist_Mutation_Response>;
  /** insert a single row into the table: "user_whitelist" */
  insert_user_whitelist_one?: Maybe<User_Whitelist>;
  /** insert data into the table: "utm_visits_data" */
  insert_utm_visits_data?: Maybe<Utm_Visits_Data_Mutation_Response>;
  /** insert a single row into the table: "utm_visits_data" */
  insert_utm_visits_data_one?: Maybe<Utm_Visits_Data>;
  /** insert data into the table: "webpushes_autosending_types" */
  insert_webpushes_autosending_types?: Maybe<Webpushes_Autosending_Types_Mutation_Response>;
  /** insert data into the table: "webpushes_autosending_types_localization" */
  insert_webpushes_autosending_types_localization?: Maybe<Webpushes_Autosending_Types_Localization_Mutation_Response>;
  /** insert a single row into the table: "webpushes_autosending_types_localization" */
  insert_webpushes_autosending_types_localization_one?: Maybe<Webpushes_Autosending_Types_Localization>;
  /** insert a single row into the table: "webpushes_autosending_types" */
  insert_webpushes_autosending_types_one?: Maybe<Webpushes_Autosending_Types>;
  /** insert data into the table: "webpushes_bonuses" */
  insert_webpushes_bonuses?: Maybe<Webpushes_Bonuses_Mutation_Response>;
  /** insert data into the table: "webpushes_bonuses_offers" */
  insert_webpushes_bonuses_offers?: Maybe<Webpushes_Bonuses_Offers_Mutation_Response>;
  /** insert a single row into the table: "webpushes_bonuses_offers" */
  insert_webpushes_bonuses_offers_one?: Maybe<Webpushes_Bonuses_Offers>;
  /** insert a single row into the table: "webpushes_bonuses" */
  insert_webpushes_bonuses_one?: Maybe<Webpushes_Bonuses>;
  /** insert data into the table: "webpushes_lines" */
  insert_webpushes_lines?: Maybe<Webpushes_Lines_Mutation_Response>;
  /** insert a single row into the table: "webpushes_lines" */
  insert_webpushes_lines_one?: Maybe<Webpushes_Lines>;
  /** insert data into the table: "webpushes_scheduling" */
  insert_webpushes_scheduling?: Maybe<Webpushes_Scheduling_Mutation_Response>;
  /** insert a single row into the table: "webpushes_scheduling" */
  insert_webpushes_scheduling_one?: Maybe<Webpushes_Scheduling>;
  /** insert data into the table: "webpushes_triggers" */
  insert_webpushes_triggers?: Maybe<Webpushes_Triggers_Mutation_Response>;
  /** insert data into the table: "webpushes_triggers_groups" */
  insert_webpushes_triggers_groups?: Maybe<Webpushes_Triggers_Groups_Mutation_Response>;
  /** insert a single row into the table: "webpushes_triggers_groups" */
  insert_webpushes_triggers_groups_one?: Maybe<Webpushes_Triggers_Groups>;
  /** insert a single row into the table: "webpushes_triggers" */
  insert_webpushes_triggers_one?: Maybe<Webpushes_Triggers>;
  refreshToken?: Maybe<RefreshTokenOutput>;
  /** update data of the table: "account_items_names" */
  update_account_items_names?: Maybe<Account_Items_Names_Mutation_Response>;
  /** update single row of the table: "account_items_names" */
  update_account_items_names_by_pk?: Maybe<Account_Items_Names>;
  /** update multiples rows of table: "account_items_names" */
  update_account_items_names_many?: Maybe<Array<Maybe<Account_Items_Names_Mutation_Response>>>;
  /** update data of the table: "admin_items_names" */
  update_admin_items_names?: Maybe<Admin_Items_Names_Mutation_Response>;
  /** update single row of the table: "admin_items_names" */
  update_admin_items_names_by_pk?: Maybe<Admin_Items_Names>;
  /** update multiples rows of table: "admin_items_names" */
  update_admin_items_names_many?: Maybe<Array<Maybe<Admin_Items_Names_Mutation_Response>>>;
  /** update data of the table: "admin_user_params" */
  update_admin_user_params?: Maybe<Admin_User_Params_Mutation_Response>;
  /** update single row of the table: "admin_user_params" */
  update_admin_user_params_by_pk?: Maybe<Admin_User_Params>;
  /** update multiples rows of table: "admin_user_params" */
  update_admin_user_params_many?: Maybe<Array<Maybe<Admin_User_Params_Mutation_Response>>>;
  /** update data of the table: "amp_sessions_data" */
  update_amp_sessions_data?: Maybe<Amp_Sessions_Data_Mutation_Response>;
  /** update single row of the table: "amp_sessions_data" */
  update_amp_sessions_data_by_pk?: Maybe<Amp_Sessions_Data>;
  /** update multiples rows of table: "amp_sessions_data" */
  update_amp_sessions_data_many?: Maybe<Array<Maybe<Amp_Sessions_Data_Mutation_Response>>>;
  /** update data of the table: "banned_ips" */
  update_banned_ips?: Maybe<Banned_Ips_Mutation_Response>;
  /** update single row of the table: "banned_ips" */
  update_banned_ips_by_pk?: Maybe<Banned_Ips>;
  /** update multiples rows of table: "banned_ips" */
  update_banned_ips_many?: Maybe<Array<Maybe<Banned_Ips_Mutation_Response>>>;
  /** update data of the table: "banned_users_for_multiaccount" */
  update_banned_users_for_multiaccount?: Maybe<Banned_Users_For_Multiaccount_Mutation_Response>;
  /** update single row of the table: "banned_users_for_multiaccount" */
  update_banned_users_for_multiaccount_by_pk?: Maybe<Banned_Users_For_Multiaccount>;
  /** update multiples rows of table: "banned_users_for_multiaccount" */
  update_banned_users_for_multiaccount_many?: Maybe<Array<Maybe<Banned_Users_For_Multiaccount_Mutation_Response>>>;
  /** update data of the table: "bill" */
  update_bill?: Maybe<Bill_Mutation_Response>;
  /** update single row of the table: "bill" */
  update_bill_by_pk?: Maybe<Bill>;
  /** update multiples rows of table: "bill" */
  update_bill_many?: Maybe<Array<Maybe<Bill_Mutation_Response>>>;
  /** update data of the table: "bonus_params" */
  update_bonus_params?: Maybe<Bonus_Params_Mutation_Response>;
  /** update single row of the table: "bonus_params" */
  update_bonus_params_by_pk?: Maybe<Bonus_Params>;
  /** update multiples rows of table: "bonus_params" */
  update_bonus_params_many?: Maybe<Array<Maybe<Bonus_Params_Mutation_Response>>>;
  /** update data of the table: "bonus_wheel_rewards" */
  update_bonus_wheel_rewards?: Maybe<Bonus_Wheel_Rewards_Mutation_Response>;
  /** update single row of the table: "bonus_wheel_rewards" */
  update_bonus_wheel_rewards_by_pk?: Maybe<Bonus_Wheel_Rewards>;
  /** update multiples rows of table: "bonus_wheel_rewards" */
  update_bonus_wheel_rewards_many?: Maybe<Array<Maybe<Bonus_Wheel_Rewards_Mutation_Response>>>;
  /** update data of the table: "bonus_wheel_ticket_type" */
  update_bonus_wheel_ticket_type?: Maybe<Bonus_Wheel_Ticket_Type_Mutation_Response>;
  /** update single row of the table: "bonus_wheel_ticket_type" */
  update_bonus_wheel_ticket_type_by_pk?: Maybe<Bonus_Wheel_Ticket_Type>;
  /** update multiples rows of table: "bonus_wheel_ticket_type" */
  update_bonus_wheel_ticket_type_many?: Maybe<Array<Maybe<Bonus_Wheel_Ticket_Type_Mutation_Response>>>;
  /** update data of the table: "bonus_wheel_ticket_type_name_localization" */
  update_bonus_wheel_ticket_type_name_localization?: Maybe<Bonus_Wheel_Ticket_Type_Name_Localization_Mutation_Response>;
  /** update single row of the table: "bonus_wheel_ticket_type_name_localization" */
  update_bonus_wheel_ticket_type_name_localization_by_pk?: Maybe<Bonus_Wheel_Ticket_Type_Name_Localization>;
  /** update multiples rows of table: "bonus_wheel_ticket_type_name_localization" */
  update_bonus_wheel_ticket_type_name_localization_many?: Maybe<Array<Maybe<Bonus_Wheel_Ticket_Type_Name_Localization_Mutation_Response>>>;
  /** update data of the table: "bonus_wheel_user_rewards" */
  update_bonus_wheel_user_rewards?: Maybe<Bonus_Wheel_User_Rewards_Mutation_Response>;
  /** update single row of the table: "bonus_wheel_user_rewards" */
  update_bonus_wheel_user_rewards_by_pk?: Maybe<Bonus_Wheel_User_Rewards>;
  /** update multiples rows of table: "bonus_wheel_user_rewards" */
  update_bonus_wheel_user_rewards_many?: Maybe<Array<Maybe<Bonus_Wheel_User_Rewards_Mutation_Response>>>;
  /** update data of the table: "bonus_wheel_user_tickets" */
  update_bonus_wheel_user_tickets?: Maybe<Bonus_Wheel_User_Tickets_Mutation_Response>;
  /** update single row of the table: "bonus_wheel_user_tickets" */
  update_bonus_wheel_user_tickets_by_pk?: Maybe<Bonus_Wheel_User_Tickets>;
  /** update multiples rows of table: "bonus_wheel_user_tickets" */
  update_bonus_wheel_user_tickets_many?: Maybe<Array<Maybe<Bonus_Wheel_User_Tickets_Mutation_Response>>>;
  /** update data of the table: "bonus_wheels" */
  update_bonus_wheels?: Maybe<Bonus_Wheels_Mutation_Response>;
  /** update data of the table: "bonus_wheels_bonus_skins" */
  update_bonus_wheels_bonus_skins?: Maybe<Bonus_Wheels_Bonus_Skins_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_bonus_skins" */
  update_bonus_wheels_bonus_skins_by_pk?: Maybe<Bonus_Wheels_Bonus_Skins>;
  /** update multiples rows of table: "bonus_wheels_bonus_skins" */
  update_bonus_wheels_bonus_skins_many?: Maybe<Array<Maybe<Bonus_Wheels_Bonus_Skins_Mutation_Response>>>;
  /** update data of the table: "bonus_wheels_bonuses" */
  update_bonus_wheels_bonuses?: Maybe<Bonus_Wheels_Bonuses_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_bonuses" */
  update_bonus_wheels_bonuses_by_pk?: Maybe<Bonus_Wheels_Bonuses>;
  /** update data of the table: "bonus_wheels_bonuses_localization" */
  update_bonus_wheels_bonuses_localization?: Maybe<Bonus_Wheels_Bonuses_Localization_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_bonuses_localization" */
  update_bonus_wheels_bonuses_localization_by_pk?: Maybe<Bonus_Wheels_Bonuses_Localization>;
  /** update multiples rows of table: "bonus_wheels_bonuses_localization" */
  update_bonus_wheels_bonuses_localization_many?: Maybe<Array<Maybe<Bonus_Wheels_Bonuses_Localization_Mutation_Response>>>;
  /** update multiples rows of table: "bonus_wheels_bonuses" */
  update_bonus_wheels_bonuses_many?: Maybe<Array<Maybe<Bonus_Wheels_Bonuses_Mutation_Response>>>;
  /** update data of the table: "bonus_wheels_bonuses_params" */
  update_bonus_wheels_bonuses_params?: Maybe<Bonus_Wheels_Bonuses_Params_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_bonuses_params" */
  update_bonus_wheels_bonuses_params_by_pk?: Maybe<Bonus_Wheels_Bonuses_Params>;
  /** update data of the table: "bonus_wheels_bonuses_params_localization" */
  update_bonus_wheels_bonuses_params_localization?: Maybe<Bonus_Wheels_Bonuses_Params_Localization_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_bonuses_params_localization" */
  update_bonus_wheels_bonuses_params_localization_by_pk?: Maybe<Bonus_Wheels_Bonuses_Params_Localization>;
  /** update multiples rows of table: "bonus_wheels_bonuses_params_localization" */
  update_bonus_wheels_bonuses_params_localization_many?: Maybe<Array<Maybe<Bonus_Wheels_Bonuses_Params_Localization_Mutation_Response>>>;
  /** update multiples rows of table: "bonus_wheels_bonuses_params" */
  update_bonus_wheels_bonuses_params_many?: Maybe<Array<Maybe<Bonus_Wheels_Bonuses_Params_Mutation_Response>>>;
  /** update single row of the table: "bonus_wheels" */
  update_bonus_wheels_by_pk?: Maybe<Bonus_Wheels>;
  /** update data of the table: "bonus_wheels_generator" */
  update_bonus_wheels_generator?: Maybe<Bonus_Wheels_Generator_Mutation_Response>;
  /** update data of the table: "bonus_wheels_generator_bonuses" */
  update_bonus_wheels_generator_bonuses?: Maybe<Bonus_Wheels_Generator_Bonuses_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_generator_bonuses" */
  update_bonus_wheels_generator_bonuses_by_pk?: Maybe<Bonus_Wheels_Generator_Bonuses>;
  /** update multiples rows of table: "bonus_wheels_generator_bonuses" */
  update_bonus_wheels_generator_bonuses_many?: Maybe<Array<Maybe<Bonus_Wheels_Generator_Bonuses_Mutation_Response>>>;
  /** update single row of the table: "bonus_wheels_generator" */
  update_bonus_wheels_generator_by_pk?: Maybe<Bonus_Wheels_Generator>;
  /** update multiples rows of table: "bonus_wheels_generator" */
  update_bonus_wheels_generator_many?: Maybe<Array<Maybe<Bonus_Wheels_Generator_Mutation_Response>>>;
  /** update data of the table: "bonus_wheels_items_in_wheel" */
  update_bonus_wheels_items_in_wheel?: Maybe<Bonus_Wheels_Items_In_Wheel_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_items_in_wheel" */
  update_bonus_wheels_items_in_wheel_by_pk?: Maybe<Bonus_Wheels_Items_In_Wheel>;
  /** update multiples rows of table: "bonus_wheels_items_in_wheel" */
  update_bonus_wheels_items_in_wheel_many?: Maybe<Array<Maybe<Bonus_Wheels_Items_In_Wheel_Mutation_Response>>>;
  /** update multiples rows of table: "bonus_wheels" */
  update_bonus_wheels_many?: Maybe<Array<Maybe<Bonus_Wheels_Mutation_Response>>>;
  /** update data of the table: "bonus_wheels_user_check" */
  update_bonus_wheels_user_check?: Maybe<Bonus_Wheels_User_Check_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_user_check" */
  update_bonus_wheels_user_check_by_pk?: Maybe<Bonus_Wheels_User_Check>;
  /** update multiples rows of table: "bonus_wheels_user_check" */
  update_bonus_wheels_user_check_many?: Maybe<Array<Maybe<Bonus_Wheels_User_Check_Mutation_Response>>>;
  /** update data of the table: "bonus_wheels_user_check_params" */
  update_bonus_wheels_user_check_params?: Maybe<Bonus_Wheels_User_Check_Params_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_user_check_params" */
  update_bonus_wheels_user_check_params_by_pk?: Maybe<Bonus_Wheels_User_Check_Params>;
  /** update multiples rows of table: "bonus_wheels_user_check_params" */
  update_bonus_wheels_user_check_params_many?: Maybe<Array<Maybe<Bonus_Wheels_User_Check_Params_Mutation_Response>>>;
  /** update data of the table: "bonus_wheels_user_offers" */
  update_bonus_wheels_user_offers?: Maybe<Bonus_Wheels_User_Offers_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_user_offers" */
  update_bonus_wheels_user_offers_by_pk?: Maybe<Bonus_Wheels_User_Offers>;
  /** update multiples rows of table: "bonus_wheels_user_offers" */
  update_bonus_wheels_user_offers_many?: Maybe<Array<Maybe<Bonus_Wheels_User_Offers_Mutation_Response>>>;
  /** update data of the table: "bonus_wheels_user_offers_params" */
  update_bonus_wheels_user_offers_params?: Maybe<Bonus_Wheels_User_Offers_Params_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_user_offers_params" */
  update_bonus_wheels_user_offers_params_by_pk?: Maybe<Bonus_Wheels_User_Offers_Params>;
  /** update data of the table: "bonus_wheels_user_offers_params_localization" */
  update_bonus_wheels_user_offers_params_localization?: Maybe<Bonus_Wheels_User_Offers_Params_Localization_Mutation_Response>;
  /** update single row of the table: "bonus_wheels_user_offers_params_localization" */
  update_bonus_wheels_user_offers_params_localization_by_pk?: Maybe<Bonus_Wheels_User_Offers_Params_Localization>;
  /** update multiples rows of table: "bonus_wheels_user_offers_params_localization" */
  update_bonus_wheels_user_offers_params_localization_many?: Maybe<Array<Maybe<Bonus_Wheels_User_Offers_Params_Localization_Mutation_Response>>>;
  /** update multiples rows of table: "bonus_wheels_user_offers_params" */
  update_bonus_wheels_user_offers_params_many?: Maybe<Array<Maybe<Bonus_Wheels_User_Offers_Params_Mutation_Response>>>;
  /** update data of the table: "bonuses" */
  update_bonuses?: Maybe<Bonuses_Mutation_Response>;
  /** update single row of the table: "bonuses" */
  update_bonuses_by_pk?: Maybe<Bonuses>;
  /** update multiples rows of table: "bonuses" */
  update_bonuses_many?: Maybe<Array<Maybe<Bonuses_Mutation_Response>>>;
  /** update data of the table: "bonuses_register" */
  update_bonuses_register?: Maybe<Bonuses_Register_Mutation_Response>;
  /** update single row of the table: "bonuses_register" */
  update_bonuses_register_by_pk?: Maybe<Bonuses_Register>;
  /** update multiples rows of table: "bonuses_register" */
  update_bonuses_register_many?: Maybe<Array<Maybe<Bonuses_Register_Mutation_Response>>>;
  /** update data of the table: "bonuses_register_offers" */
  update_bonuses_register_offers?: Maybe<Bonuses_Register_Offers_Mutation_Response>;
  /** update single row of the table: "bonuses_register_offers" */
  update_bonuses_register_offers_by_pk?: Maybe<Bonuses_Register_Offers>;
  /** update multiples rows of table: "bonuses_register_offers" */
  update_bonuses_register_offers_many?: Maybe<Array<Maybe<Bonuses_Register_Offers_Mutation_Response>>>;
  /** update data of the table: "boosters_set_content" */
  update_boosters_set_content?: Maybe<Boosters_Set_Content_Mutation_Response>;
  /** update single row of the table: "boosters_set_content" */
  update_boosters_set_content_by_pk?: Maybe<Boosters_Set_Content>;
  /** update multiples rows of table: "boosters_set_content" */
  update_boosters_set_content_many?: Maybe<Array<Maybe<Boosters_Set_Content_Mutation_Response>>>;
  /** update data of the table: "boosters_sets" */
  update_boosters_sets?: Maybe<Boosters_Sets_Mutation_Response>;
  /** update single row of the table: "boosters_sets" */
  update_boosters_sets_by_pk?: Maybe<Boosters_Sets>;
  /** update multiples rows of table: "boosters_sets" */
  update_boosters_sets_many?: Maybe<Array<Maybe<Boosters_Sets_Mutation_Response>>>;
  /** update data of the table: "bot_account_settings" */
  update_bot_account_settings?: Maybe<Bot_Account_Settings_Mutation_Response>;
  /** update single row of the table: "bot_account_settings" */
  update_bot_account_settings_by_pk?: Maybe<Bot_Account_Settings>;
  /** update multiples rows of table: "bot_account_settings" */
  update_bot_account_settings_many?: Maybe<Array<Maybe<Bot_Account_Settings_Mutation_Response>>>;
  /** update data of the table: "bots_lootboxes_opens_count" */
  update_bots_lootboxes_opens_count?: Maybe<Bots_Lootboxes_Opens_Count_Mutation_Response>;
  /** update single row of the table: "bots_lootboxes_opens_count" */
  update_bots_lootboxes_opens_count_by_pk?: Maybe<Bots_Lootboxes_Opens_Count>;
  /** update multiples rows of table: "bots_lootboxes_opens_count" */
  update_bots_lootboxes_opens_count_many?: Maybe<Array<Maybe<Bots_Lootboxes_Opens_Count_Mutation_Response>>>;
  /** update data of the table: "coins_set_content" */
  update_coins_set_content?: Maybe<Coins_Set_Content_Mutation_Response>;
  /** update single row of the table: "coins_set_content" */
  update_coins_set_content_by_pk?: Maybe<Coins_Set_Content>;
  /** update multiples rows of table: "coins_set_content" */
  update_coins_set_content_many?: Maybe<Array<Maybe<Coins_Set_Content_Mutation_Response>>>;
  /** update data of the table: "coins_sets" */
  update_coins_sets?: Maybe<Coins_Sets_Mutation_Response>;
  /** update single row of the table: "coins_sets" */
  update_coins_sets_by_pk?: Maybe<Coins_Sets>;
  /** update multiples rows of table: "coins_sets" */
  update_coins_sets_many?: Maybe<Array<Maybe<Coins_Sets_Mutation_Response>>>;
  /** update data of the table: "company" */
  update_company?: Maybe<Company_Mutation_Response>;
  /** update single row of the table: "company" */
  update_company_by_pk?: Maybe<Company>;
  /** update multiples rows of table: "company" */
  update_company_many?: Maybe<Array<Maybe<Company_Mutation_Response>>>;
  /** update data of the table: "coupons" */
  update_coupons?: Maybe<Coupons_Mutation_Response>;
  /** update single row of the table: "coupons" */
  update_coupons_by_pk?: Maybe<Coupons>;
  /** update multiples rows of table: "coupons" */
  update_coupons_many?: Maybe<Array<Maybe<Coupons_Mutation_Response>>>;
  /** update data of the table: "csgomarket_token_balance" */
  update_csgomarket_token_balance?: Maybe<Csgomarket_Token_Balance_Mutation_Response>;
  /** update single row of the table: "csgomarket_token_balance" */
  update_csgomarket_token_balance_by_pk?: Maybe<Csgomarket_Token_Balance>;
  /** update multiples rows of table: "csgomarket_token_balance" */
  update_csgomarket_token_balance_many?: Maybe<Array<Maybe<Csgomarket_Token_Balance_Mutation_Response>>>;
  /** update data of the table: "csgomarket_tokens" */
  update_csgomarket_tokens?: Maybe<Csgomarket_Tokens_Mutation_Response>;
  /** update single row of the table: "csgomarket_tokens" */
  update_csgomarket_tokens_by_pk?: Maybe<Csgomarket_Tokens>;
  /** update multiples rows of table: "csgomarket_tokens" */
  update_csgomarket_tokens_many?: Maybe<Array<Maybe<Csgomarket_Tokens_Mutation_Response>>>;
  /** update data of the table: "currency_history" */
  update_currency_history?: Maybe<Currency_History_Mutation_Response>;
  /** update single row of the table: "currency_history" */
  update_currency_history_by_pk?: Maybe<Currency_History>;
  /** update multiples rows of table: "currency_history" */
  update_currency_history_many?: Maybe<Array<Maybe<Currency_History_Mutation_Response>>>;
  /** update data of the table: "currencys" */
  update_currencys?: Maybe<Currencys_Mutation_Response>;
  /** update single row of the table: "currencys" */
  update_currencys_by_pk?: Maybe<Currencys>;
  /** update multiples rows of table: "currencys" */
  update_currencys_many?: Maybe<Array<Maybe<Currencys_Mutation_Response>>>;
  /** update data of the table: "daily_deposit_sum" */
  update_daily_deposit_sum?: Maybe<Daily_Deposit_Sum_Mutation_Response>;
  /** update single row of the table: "daily_deposit_sum" */
  update_daily_deposit_sum_by_pk?: Maybe<Daily_Deposit_Sum>;
  /** update multiples rows of table: "daily_deposit_sum" */
  update_daily_deposit_sum_many?: Maybe<Array<Maybe<Daily_Deposit_Sum_Mutation_Response>>>;
  /** update data of the table: "emails_from_upgrade_question" */
  update_emails_from_upgrade_question?: Maybe<Emails_From_Upgrade_Question_Mutation_Response>;
  /** update single row of the table: "emails_from_upgrade_question" */
  update_emails_from_upgrade_question_by_pk?: Maybe<Emails_From_Upgrade_Question>;
  /** update multiples rows of table: "emails_from_upgrade_question" */
  update_emails_from_upgrade_question_many?: Maybe<Array<Maybe<Emails_From_Upgrade_Question_Mutation_Response>>>;
  /** update data of the table: "execution_methods" */
  update_execution_methods?: Maybe<Execution_Methods_Mutation_Response>;
  /** update single row of the table: "execution_methods" */
  update_execution_methods_by_pk?: Maybe<Execution_Methods>;
  /** update multiples rows of table: "execution_methods" */
  update_execution_methods_many?: Maybe<Array<Maybe<Execution_Methods_Mutation_Response>>>;
  /** update data of the table: "faq_table" */
  update_faq_table?: Maybe<Faq_Table_Mutation_Response>;
  /** update single row of the table: "faq_table" */
  update_faq_table_by_pk?: Maybe<Faq_Table>;
  /** update multiples rows of table: "faq_table" */
  update_faq_table_many?: Maybe<Array<Maybe<Faq_Table_Mutation_Response>>>;
  /** update data of the table: "fixed_items_generator_boosters" */
  update_fixed_items_generator_boosters?: Maybe<Fixed_Items_Generator_Boosters_Mutation_Response>;
  /** update single row of the table: "fixed_items_generator_boosters" */
  update_fixed_items_generator_boosters_by_pk?: Maybe<Fixed_Items_Generator_Boosters>;
  /** update multiples rows of table: "fixed_items_generator_boosters" */
  update_fixed_items_generator_boosters_many?: Maybe<Array<Maybe<Fixed_Items_Generator_Boosters_Mutation_Response>>>;
  /** update data of the table: "fixed_items_generator_items" */
  update_fixed_items_generator_items?: Maybe<Fixed_Items_Generator_Items_Mutation_Response>;
  /** update single row of the table: "fixed_items_generator_items" */
  update_fixed_items_generator_items_by_pk?: Maybe<Fixed_Items_Generator_Items>;
  /** update multiples rows of table: "fixed_items_generator_items" */
  update_fixed_items_generator_items_many?: Maybe<Array<Maybe<Fixed_Items_Generator_Items_Mutation_Response>>>;
  /** update data of the table: "fixed_items_generator_lootboxes" */
  update_fixed_items_generator_lootboxes?: Maybe<Fixed_Items_Generator_Lootboxes_Mutation_Response>;
  /** update single row of the table: "fixed_items_generator_lootboxes" */
  update_fixed_items_generator_lootboxes_by_pk?: Maybe<Fixed_Items_Generator_Lootboxes>;
  /** update multiples rows of table: "fixed_items_generator_lootboxes" */
  update_fixed_items_generator_lootboxes_many?: Maybe<Array<Maybe<Fixed_Items_Generator_Lootboxes_Mutation_Response>>>;
  /** update data of the table: "giveaway_members" */
  update_giveaway_members?: Maybe<Giveaway_Members_Mutation_Response>;
  /** update single row of the table: "giveaway_members" */
  update_giveaway_members_by_pk?: Maybe<Giveaway_Members>;
  /** update multiples rows of table: "giveaway_members" */
  update_giveaway_members_many?: Maybe<Array<Maybe<Giveaway_Members_Mutation_Response>>>;
  /** update data of the table: "giveaway_params" */
  update_giveaway_params?: Maybe<Giveaway_Params_Mutation_Response>;
  /** update single row of the table: "giveaway_params" */
  update_giveaway_params_by_pk?: Maybe<Giveaway_Params>;
  /** update multiples rows of table: "giveaway_params" */
  update_giveaway_params_many?: Maybe<Array<Maybe<Giveaway_Params_Mutation_Response>>>;
  /** update data of the table: "giveaways" */
  update_giveaways?: Maybe<Giveaways_Mutation_Response>;
  /** update single row of the table: "giveaways" */
  update_giveaways_by_pk?: Maybe<Giveaways>;
  /** update multiples rows of table: "giveaways" */
  update_giveaways_many?: Maybe<Array<Maybe<Giveaways_Mutation_Response>>>;
  /** update data of the table: "group_items_csgomarket" */
  update_group_items_csgomarket?: Maybe<Group_Items_Csgomarket_Mutation_Response>;
  /** update single row of the table: "group_items_csgomarket" */
  update_group_items_csgomarket_by_pk?: Maybe<Group_Items_Csgomarket>;
  /** update multiples rows of table: "group_items_csgomarket" */
  update_group_items_csgomarket_many?: Maybe<Array<Maybe<Group_Items_Csgomarket_Mutation_Response>>>;
  /** update data of the table: "history_group_items_csgomarket" */
  update_history_group_items_csgomarket?: Maybe<History_Group_Items_Csgomarket_Mutation_Response>;
  /** update single row of the table: "history_group_items_csgomarket" */
  update_history_group_items_csgomarket_by_pk?: Maybe<History_Group_Items_Csgomarket>;
  /** update multiples rows of table: "history_group_items_csgomarket" */
  update_history_group_items_csgomarket_many?: Maybe<Array<Maybe<History_Group_Items_Csgomarket_Mutation_Response>>>;
  /** update data of the table: "history_items_csgomarket" */
  update_history_items_csgomarket?: Maybe<History_Items_Csgomarket_Mutation_Response>;
  /** update single row of the table: "history_items_csgomarket" */
  update_history_items_csgomarket_by_pk?: Maybe<History_Items_Csgomarket>;
  /** update multiples rows of table: "history_items_csgomarket" */
  update_history_items_csgomarket_many?: Maybe<Array<Maybe<History_Items_Csgomarket_Mutation_Response>>>;
  /** update data of the table: "item_out_transactions" */
  update_item_out_transactions?: Maybe<Item_Out_Transactions_Mutation_Response>;
  /** update single row of the table: "item_out_transactions" */
  update_item_out_transactions_by_pk?: Maybe<Item_Out_Transactions>;
  /** update multiples rows of table: "item_out_transactions" */
  update_item_out_transactions_many?: Maybe<Array<Maybe<Item_Out_Transactions_Mutation_Response>>>;
  /** update data of the table: "items" */
  update_items?: Maybe<Items_Mutation_Response>;
  /** update single row of the table: "items" */
  update_items_by_pk?: Maybe<Items>;
  /** update data of the table: "items_csgomarket" */
  update_items_csgomarket?: Maybe<Items_Csgomarket_Mutation_Response>;
  /** update single row of the table: "items_csgomarket" */
  update_items_csgomarket_by_pk?: Maybe<Items_Csgomarket>;
  /** update multiples rows of table: "items_csgomarket" */
  update_items_csgomarket_many?: Maybe<Array<Maybe<Items_Csgomarket_Mutation_Response>>>;
  /** update data of the table: "items_drops" */
  update_items_drops?: Maybe<Items_Drops_Mutation_Response>;
  /** update single row of the table: "items_drops" */
  update_items_drops_by_pk?: Maybe<Items_Drops>;
  /** update multiples rows of table: "items_drops" */
  update_items_drops_many?: Maybe<Array<Maybe<Items_Drops_Mutation_Response>>>;
  /** update data of the table: "items_for_upgrade" */
  update_items_for_upgrade?: Maybe<Items_For_Upgrade_Mutation_Response>;
  /** update single row of the table: "items_for_upgrade" */
  update_items_for_upgrade_by_pk?: Maybe<Items_For_Upgrade>;
  /** update multiples rows of table: "items_for_upgrade" */
  update_items_for_upgrade_many?: Maybe<Array<Maybe<Items_For_Upgrade_Mutation_Response>>>;
  /** update data of the table: "items_history" */
  update_items_history?: Maybe<Items_History_Mutation_Response>;
  /** update single row of the table: "items_history" */
  update_items_history_by_pk?: Maybe<Items_History>;
  /** update multiples rows of table: "items_history" */
  update_items_history_many?: Maybe<Array<Maybe<Items_History_Mutation_Response>>>;
  /** update data of the table: "items_in_lootbox" */
  update_items_in_lootbox?: Maybe<Items_In_Lootbox_Mutation_Response>;
  /** update single row of the table: "items_in_lootbox" */
  update_items_in_lootbox_by_pk?: Maybe<Items_In_Lootbox>;
  /** update multiples rows of table: "items_in_lootbox" */
  update_items_in_lootbox_many?: Maybe<Array<Maybe<Items_In_Lootbox_Mutation_Response>>>;
  /** update data of the table: "items_in_lootbox_view" */
  update_items_in_lootbox_view?: Maybe<Items_In_Lootbox_View_Mutation_Response>;
  /** update single row of the table: "items_in_lootbox_view" */
  update_items_in_lootbox_view_by_pk?: Maybe<Items_In_Lootbox_View>;
  /** update multiples rows of table: "items_in_lootbox_view" */
  update_items_in_lootbox_view_many?: Maybe<Array<Maybe<Items_In_Lootbox_View_Mutation_Response>>>;
  /** update data of the table: "items_items_names" */
  update_items_items_names?: Maybe<Items_Items_Names_Mutation_Response>;
  /** update single row of the table: "items_items_names" */
  update_items_items_names_by_pk?: Maybe<Items_Items_Names>;
  /** update multiples rows of table: "items_items_names" */
  update_items_items_names_many?: Maybe<Array<Maybe<Items_Items_Names_Mutation_Response>>>;
  /** update data of the table: "items_localization" */
  update_items_localization?: Maybe<Items_Localization_Mutation_Response>;
  /** update single row of the table: "items_localization" */
  update_items_localization_by_pk?: Maybe<Items_Localization>;
  /** update multiples rows of table: "items_localization" */
  update_items_localization_many?: Maybe<Array<Maybe<Items_Localization_Mutation_Response>>>;
  /** update multiples rows of table: "items" */
  update_items_many?: Maybe<Array<Maybe<Items_Mutation_Response>>>;
  /** update data of the table: "items_names" */
  update_items_names?: Maybe<Items_Names_Mutation_Response>;
  /** update single row of the table: "items_names" */
  update_items_names_by_pk?: Maybe<Items_Names>;
  /** update multiples rows of table: "items_names" */
  update_items_names_many?: Maybe<Array<Maybe<Items_Names_Mutation_Response>>>;
  /** update data of the table: "items_quality" */
  update_items_quality?: Maybe<Items_Quality_Mutation_Response>;
  /** update single row of the table: "items_quality" */
  update_items_quality_by_pk?: Maybe<Items_Quality>;
  /** update data of the table: "items_quality_localization" */
  update_items_quality_localization?: Maybe<Items_Quality_Localization_Mutation_Response>;
  /** update single row of the table: "items_quality_localization" */
  update_items_quality_localization_by_pk?: Maybe<Items_Quality_Localization>;
  /** update multiples rows of table: "items_quality_localization" */
  update_items_quality_localization_many?: Maybe<Array<Maybe<Items_Quality_Localization_Mutation_Response>>>;
  /** update multiples rows of table: "items_quality" */
  update_items_quality_many?: Maybe<Array<Maybe<Items_Quality_Mutation_Response>>>;
  /** update data of the table: "items_rarity" */
  update_items_rarity?: Maybe<Items_Rarity_Mutation_Response>;
  /** update single row of the table: "items_rarity" */
  update_items_rarity_by_pk?: Maybe<Items_Rarity>;
  /** update data of the table: "items_rarity_localization" */
  update_items_rarity_localization?: Maybe<Items_Rarity_Localization_Mutation_Response>;
  /** update single row of the table: "items_rarity_localization" */
  update_items_rarity_localization_by_pk?: Maybe<Items_Rarity_Localization>;
  /** update multiples rows of table: "items_rarity_localization" */
  update_items_rarity_localization_many?: Maybe<Array<Maybe<Items_Rarity_Localization_Mutation_Response>>>;
  /** update multiples rows of table: "items_rarity" */
  update_items_rarity_many?: Maybe<Array<Maybe<Items_Rarity_Mutation_Response>>>;
  /** update data of the table: "items_type" */
  update_items_type?: Maybe<Items_Type_Mutation_Response>;
  /** update single row of the table: "items_type" */
  update_items_type_by_pk?: Maybe<Items_Type>;
  /** update data of the table: "items_type_localization" */
  update_items_type_localization?: Maybe<Items_Type_Localization_Mutation_Response>;
  /** update single row of the table: "items_type_localization" */
  update_items_type_localization_by_pk?: Maybe<Items_Type_Localization>;
  /** update multiples rows of table: "items_type_localization" */
  update_items_type_localization_many?: Maybe<Array<Maybe<Items_Type_Localization_Mutation_Response>>>;
  /** update multiples rows of table: "items_type" */
  update_items_type_many?: Maybe<Array<Maybe<Items_Type_Mutation_Response>>>;
  /** update data of the table: "items_weapon_localization" */
  update_items_weapon_localization?: Maybe<Items_Weapon_Localization_Mutation_Response>;
  /** update single row of the table: "items_weapon_localization" */
  update_items_weapon_localization_by_pk?: Maybe<Items_Weapon_Localization>;
  /** update multiples rows of table: "items_weapon_localization" */
  update_items_weapon_localization_many?: Maybe<Array<Maybe<Items_Weapon_Localization_Mutation_Response>>>;
  /** update data of the table: "items_weapons" */
  update_items_weapons?: Maybe<Items_Weapons_Mutation_Response>;
  /** update single row of the table: "items_weapons" */
  update_items_weapons_by_pk?: Maybe<Items_Weapons>;
  /** update multiples rows of table: "items_weapons" */
  update_items_weapons_many?: Maybe<Array<Maybe<Items_Weapons_Mutation_Response>>>;
  /** update data of the table: "lootbox_booster_items" */
  update_lootbox_booster_items?: Maybe<Lootbox_Booster_Items_Mutation_Response>;
  /** update single row of the table: "lootbox_booster_items" */
  update_lootbox_booster_items_by_pk?: Maybe<Lootbox_Booster_Items>;
  /** update multiples rows of table: "lootbox_booster_items" */
  update_lootbox_booster_items_many?: Maybe<Array<Maybe<Lootbox_Booster_Items_Mutation_Response>>>;
  /** update data of the table: "lootbox_booster_localization" */
  update_lootbox_booster_localization?: Maybe<Lootbox_Booster_Localization_Mutation_Response>;
  /** update single row of the table: "lootbox_booster_localization" */
  update_lootbox_booster_localization_by_pk?: Maybe<Lootbox_Booster_Localization>;
  /** update multiples rows of table: "lootbox_booster_localization" */
  update_lootbox_booster_localization_many?: Maybe<Array<Maybe<Lootbox_Booster_Localization_Mutation_Response>>>;
  /** update data of the table: "lootbox_category" */
  update_lootbox_category?: Maybe<Lootbox_Category_Mutation_Response>;
  /** update single row of the table: "lootbox_category" */
  update_lootbox_category_by_pk?: Maybe<Lootbox_Category>;
  /** update multiples rows of table: "lootbox_category" */
  update_lootbox_category_many?: Maybe<Array<Maybe<Lootbox_Category_Mutation_Response>>>;
  /** update data of the table: "lootbox_items_sets" */
  update_lootbox_items_sets?: Maybe<Lootbox_Items_Sets_Mutation_Response>;
  /** update single row of the table: "lootbox_items_sets" */
  update_lootbox_items_sets_by_pk?: Maybe<Lootbox_Items_Sets>;
  /** update multiples rows of table: "lootbox_items_sets" */
  update_lootbox_items_sets_many?: Maybe<Array<Maybe<Lootbox_Items_Sets_Mutation_Response>>>;
  /** update data of the table: "lootbox_service" */
  update_lootbox_service?: Maybe<Lootbox_Service_Mutation_Response>;
  /** update single row of the table: "lootbox_service" */
  update_lootbox_service_by_pk?: Maybe<Lootbox_Service>;
  /** update multiples rows of table: "lootbox_service" */
  update_lootbox_service_many?: Maybe<Array<Maybe<Lootbox_Service_Mutation_Response>>>;
  /** update data of the table: "lootboxes" */
  update_lootboxes?: Maybe<Lootboxes_Mutation_Response>;
  /** update data of the table: "lootboxes_available_statuses" */
  update_lootboxes_available_statuses?: Maybe<Lootboxes_Available_Statuses_Mutation_Response>;
  /** update single row of the table: "lootboxes_available_statuses" */
  update_lootboxes_available_statuses_by_pk?: Maybe<Lootboxes_Available_Statuses>;
  /** update multiples rows of table: "lootboxes_available_statuses" */
  update_lootboxes_available_statuses_many?: Maybe<Array<Maybe<Lootboxes_Available_Statuses_Mutation_Response>>>;
  /** update data of the table: "lootboxes_bonus" */
  update_lootboxes_bonus?: Maybe<Lootboxes_Bonus_Mutation_Response>;
  /** update single row of the table: "lootboxes_bonus" */
  update_lootboxes_bonus_by_pk?: Maybe<Lootboxes_Bonus>;
  /** update data of the table: "lootboxes_bonus_generator_items" */
  update_lootboxes_bonus_generator_items?: Maybe<Lootboxes_Bonus_Generator_Items_Mutation_Response>;
  /** update single row of the table: "lootboxes_bonus_generator_items" */
  update_lootboxes_bonus_generator_items_by_pk?: Maybe<Lootboxes_Bonus_Generator_Items>;
  /** update multiples rows of table: "lootboxes_bonus_generator_items" */
  update_lootboxes_bonus_generator_items_many?: Maybe<Array<Maybe<Lootboxes_Bonus_Generator_Items_Mutation_Response>>>;
  /** update data of the table: "lootboxes_bonus_generator_params" */
  update_lootboxes_bonus_generator_params?: Maybe<Lootboxes_Bonus_Generator_Params_Mutation_Response>;
  /** update single row of the table: "lootboxes_bonus_generator_params" */
  update_lootboxes_bonus_generator_params_by_pk?: Maybe<Lootboxes_Bonus_Generator_Params>;
  /** update multiples rows of table: "lootboxes_bonus_generator_params" */
  update_lootboxes_bonus_generator_params_many?: Maybe<Array<Maybe<Lootboxes_Bonus_Generator_Params_Mutation_Response>>>;
  /** update data of the table: "lootboxes_bonus_items" */
  update_lootboxes_bonus_items?: Maybe<Lootboxes_Bonus_Items_Mutation_Response>;
  /** update single row of the table: "lootboxes_bonus_items" */
  update_lootboxes_bonus_items_by_pk?: Maybe<Lootboxes_Bonus_Items>;
  /** update multiples rows of table: "lootboxes_bonus_items" */
  update_lootboxes_bonus_items_many?: Maybe<Array<Maybe<Lootboxes_Bonus_Items_Mutation_Response>>>;
  /** update multiples rows of table: "lootboxes_bonus" */
  update_lootboxes_bonus_many?: Maybe<Array<Maybe<Lootboxes_Bonus_Mutation_Response>>>;
  /** update data of the table: "lootboxes_bonus_views" */
  update_lootboxes_bonus_views?: Maybe<Lootboxes_Bonus_Views_Mutation_Response>;
  /** update single row of the table: "lootboxes_bonus_views" */
  update_lootboxes_bonus_views_by_pk?: Maybe<Lootboxes_Bonus_Views>;
  /** update multiples rows of table: "lootboxes_bonus_views" */
  update_lootboxes_bonus_views_many?: Maybe<Array<Maybe<Lootboxes_Bonus_Views_Mutation_Response>>>;
  /** update single row of the table: "lootboxes" */
  update_lootboxes_by_pk?: Maybe<Lootboxes>;
  /** update data of the table: "lootboxes_dynamic_category" */
  update_lootboxes_dynamic_category?: Maybe<Lootboxes_Dynamic_Category_Mutation_Response>;
  /** update single row of the table: "lootboxes_dynamic_category" */
  update_lootboxes_dynamic_category_by_pk?: Maybe<Lootboxes_Dynamic_Category>;
  /** update data of the table: "lootboxes_dynamic_category_lootboxes" */
  update_lootboxes_dynamic_category_lootboxes?: Maybe<Lootboxes_Dynamic_Category_Lootboxes_Mutation_Response>;
  /** update single row of the table: "lootboxes_dynamic_category_lootboxes" */
  update_lootboxes_dynamic_category_lootboxes_by_pk?: Maybe<Lootboxes_Dynamic_Category_Lootboxes>;
  /** update multiples rows of table: "lootboxes_dynamic_category_lootboxes" */
  update_lootboxes_dynamic_category_lootboxes_many?: Maybe<Array<Maybe<Lootboxes_Dynamic_Category_Lootboxes_Mutation_Response>>>;
  /** update multiples rows of table: "lootboxes_dynamic_category" */
  update_lootboxes_dynamic_category_many?: Maybe<Array<Maybe<Lootboxes_Dynamic_Category_Mutation_Response>>>;
  /** update data of the table: "lootboxes_free" */
  update_lootboxes_free?: Maybe<Lootboxes_Free_Mutation_Response>;
  /** update single row of the table: "lootboxes_free" */
  update_lootboxes_free_by_pk?: Maybe<Lootboxes_Free>;
  /** update data of the table: "lootboxes_free_category" */
  update_lootboxes_free_category?: Maybe<Lootboxes_Free_Category_Mutation_Response>;
  /** update single row of the table: "lootboxes_free_category" */
  update_lootboxes_free_category_by_pk?: Maybe<Lootboxes_Free_Category>;
  /** update multiples rows of table: "lootboxes_free_category" */
  update_lootboxes_free_category_many?: Maybe<Array<Maybe<Lootboxes_Free_Category_Mutation_Response>>>;
  /** update data of the table: "lootboxes_free_generator_items" */
  update_lootboxes_free_generator_items?: Maybe<Lootboxes_Free_Generator_Items_Mutation_Response>;
  /** update single row of the table: "lootboxes_free_generator_items" */
  update_lootboxes_free_generator_items_by_pk?: Maybe<Lootboxes_Free_Generator_Items>;
  /** update multiples rows of table: "lootboxes_free_generator_items" */
  update_lootboxes_free_generator_items_many?: Maybe<Array<Maybe<Lootboxes_Free_Generator_Items_Mutation_Response>>>;
  /** update data of the table: "lootboxes_free_generator_params" */
  update_lootboxes_free_generator_params?: Maybe<Lootboxes_Free_Generator_Params_Mutation_Response>;
  /** update single row of the table: "lootboxes_free_generator_params" */
  update_lootboxes_free_generator_params_by_pk?: Maybe<Lootboxes_Free_Generator_Params>;
  /** update multiples rows of table: "lootboxes_free_generator_params" */
  update_lootboxes_free_generator_params_many?: Maybe<Array<Maybe<Lootboxes_Free_Generator_Params_Mutation_Response>>>;
  /** update data of the table: "lootboxes_free_items" */
  update_lootboxes_free_items?: Maybe<Lootboxes_Free_Items_Mutation_Response>;
  /** update single row of the table: "lootboxes_free_items" */
  update_lootboxes_free_items_by_pk?: Maybe<Lootboxes_Free_Items>;
  /** update multiples rows of table: "lootboxes_free_items" */
  update_lootboxes_free_items_many?: Maybe<Array<Maybe<Lootboxes_Free_Items_Mutation_Response>>>;
  /** update multiples rows of table: "lootboxes_free" */
  update_lootboxes_free_many?: Maybe<Array<Maybe<Lootboxes_Free_Mutation_Response>>>;
  /** update data of the table: "lootboxes_free_views" */
  update_lootboxes_free_views?: Maybe<Lootboxes_Free_Views_Mutation_Response>;
  /** update single row of the table: "lootboxes_free_views" */
  update_lootboxes_free_views_by_pk?: Maybe<Lootboxes_Free_Views>;
  /** update multiples rows of table: "lootboxes_free_views" */
  update_lootboxes_free_views_many?: Maybe<Array<Maybe<Lootboxes_Free_Views_Mutation_Response>>>;
  /** update data of the table: "lootboxes_generator_mechanics" */
  update_lootboxes_generator_mechanics?: Maybe<Lootboxes_Generator_Mechanics_Mutation_Response>;
  /** update single row of the table: "lootboxes_generator_mechanics" */
  update_lootboxes_generator_mechanics_by_pk?: Maybe<Lootboxes_Generator_Mechanics>;
  /** update multiples rows of table: "lootboxes_generator_mechanics" */
  update_lootboxes_generator_mechanics_many?: Maybe<Array<Maybe<Lootboxes_Generator_Mechanics_Mutation_Response>>>;
  /** update data of the table: "lootboxes_generator_params" */
  update_lootboxes_generator_params?: Maybe<Lootboxes_Generator_Params_Mutation_Response>;
  /** update single row of the table: "lootboxes_generator_params" */
  update_lootboxes_generator_params_by_pk?: Maybe<Lootboxes_Generator_Params>;
  /** update multiples rows of table: "lootboxes_generator_params" */
  update_lootboxes_generator_params_many?: Maybe<Array<Maybe<Lootboxes_Generator_Params_Mutation_Response>>>;
  /** update data of the table: "lootboxes_history" */
  update_lootboxes_history?: Maybe<Lootboxes_History_Mutation_Response>;
  /** update single row of the table: "lootboxes_history" */
  update_lootboxes_history_by_pk?: Maybe<Lootboxes_History>;
  /** update multiples rows of table: "lootboxes_history" */
  update_lootboxes_history_many?: Maybe<Array<Maybe<Lootboxes_History_Mutation_Response>>>;
  /** update data of the table: "lootboxes_items_names" */
  update_lootboxes_items_names?: Maybe<Lootboxes_Items_Names_Mutation_Response>;
  /** update single row of the table: "lootboxes_items_names" */
  update_lootboxes_items_names_by_pk?: Maybe<Lootboxes_Items_Names>;
  /** update multiples rows of table: "lootboxes_items_names" */
  update_lootboxes_items_names_many?: Maybe<Array<Maybe<Lootboxes_Items_Names_Mutation_Response>>>;
  /** update multiples rows of table: "lootboxes" */
  update_lootboxes_many?: Maybe<Array<Maybe<Lootboxes_Mutation_Response>>>;
  /** update data of the table: "lootboxes_top_drop" */
  update_lootboxes_top_drop?: Maybe<Lootboxes_Top_Drop_Mutation_Response>;
  /** update single row of the table: "lootboxes_top_drop" */
  update_lootboxes_top_drop_by_pk?: Maybe<Lootboxes_Top_Drop>;
  /** update multiples rows of table: "lootboxes_top_drop" */
  update_lootboxes_top_drop_many?: Maybe<Array<Maybe<Lootboxes_Top_Drop_Mutation_Response>>>;
  /** update data of the table: "mail_autosending_types" */
  update_mail_autosending_types?: Maybe<Mail_Autosending_Types_Mutation_Response>;
  /** update single row of the table: "mail_autosending_types" */
  update_mail_autosending_types_by_pk?: Maybe<Mail_Autosending_Types>;
  /** update multiples rows of table: "mail_autosending_types" */
  update_mail_autosending_types_many?: Maybe<Array<Maybe<Mail_Autosending_Types_Mutation_Response>>>;
  /** update data of the table: "mail_bonuses" */
  update_mail_bonuses?: Maybe<Mail_Bonuses_Mutation_Response>;
  /** update single row of the table: "mail_bonuses" */
  update_mail_bonuses_by_pk?: Maybe<Mail_Bonuses>;
  /** update multiples rows of table: "mail_bonuses" */
  update_mail_bonuses_many?: Maybe<Array<Maybe<Mail_Bonuses_Mutation_Response>>>;
  /** update data of the table: "mail_bonuses_offers" */
  update_mail_bonuses_offers?: Maybe<Mail_Bonuses_Offers_Mutation_Response>;
  /** update single row of the table: "mail_bonuses_offers" */
  update_mail_bonuses_offers_by_pk?: Maybe<Mail_Bonuses_Offers>;
  /** update multiples rows of table: "mail_bonuses_offers" */
  update_mail_bonuses_offers_many?: Maybe<Array<Maybe<Mail_Bonuses_Offers_Mutation_Response>>>;
  /** update data of the table: "mail_mailing_callbacks" */
  update_mail_mailing_callbacks?: Maybe<Mail_Mailing_Callbacks_Mutation_Response>;
  /** update single row of the table: "mail_mailing_callbacks" */
  update_mail_mailing_callbacks_by_pk?: Maybe<Mail_Mailing_Callbacks>;
  /** update multiples rows of table: "mail_mailing_callbacks" */
  update_mail_mailing_callbacks_many?: Maybe<Array<Maybe<Mail_Mailing_Callbacks_Mutation_Response>>>;
  /** update data of the table: "mail_mailing_lines" */
  update_mail_mailing_lines?: Maybe<Mail_Mailing_Lines_Mutation_Response>;
  /** update single row of the table: "mail_mailing_lines" */
  update_mail_mailing_lines_by_pk?: Maybe<Mail_Mailing_Lines>;
  /** update multiples rows of table: "mail_mailing_lines" */
  update_mail_mailing_lines_many?: Maybe<Array<Maybe<Mail_Mailing_Lines_Mutation_Response>>>;
  /** update data of the table: "mail_mailing_lists" */
  update_mail_mailing_lists?: Maybe<Mail_Mailing_Lists_Mutation_Response>;
  /** update single row of the table: "mail_mailing_lists" */
  update_mail_mailing_lists_by_pk?: Maybe<Mail_Mailing_Lists>;
  /** update multiples rows of table: "mail_mailing_lists" */
  update_mail_mailing_lists_many?: Maybe<Array<Maybe<Mail_Mailing_Lists_Mutation_Response>>>;
  /** update data of the table: "mail_mailing_scheduling" */
  update_mail_mailing_scheduling?: Maybe<Mail_Mailing_Scheduling_Mutation_Response>;
  /** update single row of the table: "mail_mailing_scheduling" */
  update_mail_mailing_scheduling_by_pk?: Maybe<Mail_Mailing_Scheduling>;
  /** update multiples rows of table: "mail_mailing_scheduling" */
  update_mail_mailing_scheduling_many?: Maybe<Array<Maybe<Mail_Mailing_Scheduling_Mutation_Response>>>;
  /** update data of the table: "mail_mailing_sending_log" */
  update_mail_mailing_sending_log?: Maybe<Mail_Mailing_Sending_Log_Mutation_Response>;
  /** update single row of the table: "mail_mailing_sending_log" */
  update_mail_mailing_sending_log_by_pk?: Maybe<Mail_Mailing_Sending_Log>;
  /** update multiples rows of table: "mail_mailing_sending_log" */
  update_mail_mailing_sending_log_many?: Maybe<Array<Maybe<Mail_Mailing_Sending_Log_Mutation_Response>>>;
  /** update data of the table: "mail_mailing_triggers" */
  update_mail_mailing_triggers?: Maybe<Mail_Mailing_Triggers_Mutation_Response>;
  /** update single row of the table: "mail_mailing_triggers" */
  update_mail_mailing_triggers_by_pk?: Maybe<Mail_Mailing_Triggers>;
  /** update data of the table: "mail_mailing_triggers_groups" */
  update_mail_mailing_triggers_groups?: Maybe<Mail_Mailing_Triggers_Groups_Mutation_Response>;
  /** update single row of the table: "mail_mailing_triggers_groups" */
  update_mail_mailing_triggers_groups_by_pk?: Maybe<Mail_Mailing_Triggers_Groups>;
  /** update multiples rows of table: "mail_mailing_triggers_groups" */
  update_mail_mailing_triggers_groups_many?: Maybe<Array<Maybe<Mail_Mailing_Triggers_Groups_Mutation_Response>>>;
  /** update multiples rows of table: "mail_mailing_triggers" */
  update_mail_mailing_triggers_many?: Maybe<Array<Maybe<Mail_Mailing_Triggers_Mutation_Response>>>;
  /** update data of the table: "mail_templates" */
  update_mail_templates?: Maybe<Mail_Templates_Mutation_Response>;
  /** update single row of the table: "mail_templates" */
  update_mail_templates_by_pk?: Maybe<Mail_Templates>;
  /** update multiples rows of table: "mail_templates" */
  update_mail_templates_many?: Maybe<Array<Maybe<Mail_Templates_Mutation_Response>>>;
  /** update data of the table: "mainpage_counters" */
  update_mainpage_counters?: Maybe<Mainpage_Counters_Mutation_Response>;
  /** update single row of the table: "mainpage_counters" */
  update_mainpage_counters_by_pk?: Maybe<Mainpage_Counters>;
  /** update multiples rows of table: "mainpage_counters" */
  update_mainpage_counters_many?: Maybe<Array<Maybe<Mainpage_Counters_Mutation_Response>>>;
  /** update data of the table: "maintenance_mode" */
  update_maintenance_mode?: Maybe<Maintenance_Mode_Mutation_Response>;
  /** update single row of the table: "maintenance_mode" */
  update_maintenance_mode_by_pk?: Maybe<Maintenance_Mode>;
  /** update multiples rows of table: "maintenance_mode" */
  update_maintenance_mode_many?: Maybe<Array<Maybe<Maintenance_Mode_Mutation_Response>>>;
  /** update data of the table: "market_groups_conn" */
  update_market_groups_conn?: Maybe<Market_Groups_Conn_Mutation_Response>;
  /** update single row of the table: "market_groups_conn" */
  update_market_groups_conn_by_pk?: Maybe<Market_Groups_Conn>;
  /** update multiples rows of table: "market_groups_conn" */
  update_market_groups_conn_many?: Maybe<Array<Maybe<Market_Groups_Conn_Mutation_Response>>>;
  /** update data of the table: "metas" */
  update_metas?: Maybe<Metas_Mutation_Response>;
  /** update single row of the table: "metas" */
  update_metas_by_pk?: Maybe<Metas>;
  /** update multiples rows of table: "metas" */
  update_metas_many?: Maybe<Array<Maybe<Metas_Mutation_Response>>>;
  /** update data of the table: "missions" */
  update_missions?: Maybe<Missions_Mutation_Response>;
  /** update single row of the table: "missions" */
  update_missions_by_pk?: Maybe<Missions>;
  /** update multiples rows of table: "missions" */
  update_missions_many?: Maybe<Array<Maybe<Missions_Mutation_Response>>>;
  /** update data of the table: "missions_onetime_bonus_case_claims" */
  update_missions_onetime_bonus_case_claims?: Maybe<Missions_Onetime_Bonus_Case_Claims_Mutation_Response>;
  /** update single row of the table: "missions_onetime_bonus_case_claims" */
  update_missions_onetime_bonus_case_claims_by_pk?: Maybe<Missions_Onetime_Bonus_Case_Claims>;
  /** update multiples rows of table: "missions_onetime_bonus_case_claims" */
  update_missions_onetime_bonus_case_claims_many?: Maybe<Array<Maybe<Missions_Onetime_Bonus_Case_Claims_Mutation_Response>>>;
  /** update data of the table: "missions_onetime_params" */
  update_missions_onetime_params?: Maybe<Missions_Onetime_Params_Mutation_Response>;
  /** update single row of the table: "missions_onetime_params" */
  update_missions_onetime_params_by_pk?: Maybe<Missions_Onetime_Params>;
  /** update multiples rows of table: "missions_onetime_params" */
  update_missions_onetime_params_many?: Maybe<Array<Maybe<Missions_Onetime_Params_Mutation_Response>>>;
  /** update data of the table: "missions_onetime_users_progress" */
  update_missions_onetime_users_progress?: Maybe<Missions_Onetime_Users_Progress_Mutation_Response>;
  /** update single row of the table: "missions_onetime_users_progress" */
  update_missions_onetime_users_progress_by_pk?: Maybe<Missions_Onetime_Users_Progress>;
  /** update multiples rows of table: "missions_onetime_users_progress" */
  update_missions_onetime_users_progress_many?: Maybe<Array<Maybe<Missions_Onetime_Users_Progress_Mutation_Response>>>;
  /** update data of the table: "missions_permanent_params" */
  update_missions_permanent_params?: Maybe<Missions_Permanent_Params_Mutation_Response>;
  /** update single row of the table: "missions_permanent_params" */
  update_missions_permanent_params_by_pk?: Maybe<Missions_Permanent_Params>;
  /** update multiples rows of table: "missions_permanent_params" */
  update_missions_permanent_params_many?: Maybe<Array<Maybe<Missions_Permanent_Params_Mutation_Response>>>;
  /** update data of the table: "missions_permanent_user_progress" */
  update_missions_permanent_user_progress?: Maybe<Missions_Permanent_User_Progress_Mutation_Response>;
  /** update single row of the table: "missions_permanent_user_progress" */
  update_missions_permanent_user_progress_by_pk?: Maybe<Missions_Permanent_User_Progress>;
  /** update multiples rows of table: "missions_permanent_user_progress" */
  update_missions_permanent_user_progress_many?: Maybe<Array<Maybe<Missions_Permanent_User_Progress_Mutation_Response>>>;
  /** update data of the table: "missions_tags" */
  update_missions_tags?: Maybe<Missions_Tags_Mutation_Response>;
  /** update single row of the table: "missions_tags" */
  update_missions_tags_by_pk?: Maybe<Missions_Tags>;
  /** update multiples rows of table: "missions_tags" */
  update_missions_tags_many?: Maybe<Array<Maybe<Missions_Tags_Mutation_Response>>>;
  /** update data of the table: "missions_tags_names" */
  update_missions_tags_names?: Maybe<Missions_Tags_Names_Mutation_Response>;
  /** update single row of the table: "missions_tags_names" */
  update_missions_tags_names_by_pk?: Maybe<Missions_Tags_Names>;
  /** update multiples rows of table: "missions_tags_names" */
  update_missions_tags_names_many?: Maybe<Array<Maybe<Missions_Tags_Names_Mutation_Response>>>;
  /** update data of the table: "missions_vk_links" */
  update_missions_vk_links?: Maybe<Missions_Vk_Links_Mutation_Response>;
  /** update single row of the table: "missions_vk_links" */
  update_missions_vk_links_by_pk?: Maybe<Missions_Vk_Links>;
  /** update multiples rows of table: "missions_vk_links" */
  update_missions_vk_links_many?: Maybe<Array<Maybe<Missions_Vk_Links_Mutation_Response>>>;
  /** update data of the table: "new_year_competition_boosters_rewards" */
  update_new_year_competition_boosters_rewards?: Maybe<New_Year_Competition_Boosters_Rewards_Mutation_Response>;
  /** update single row of the table: "new_year_competition_boosters_rewards" */
  update_new_year_competition_boosters_rewards_by_pk?: Maybe<New_Year_Competition_Boosters_Rewards>;
  /** update multiples rows of table: "new_year_competition_boosters_rewards" */
  update_new_year_competition_boosters_rewards_many?: Maybe<Array<Maybe<New_Year_Competition_Boosters_Rewards_Mutation_Response>>>;
  /** update data of the table: "new_year_competition_participants" */
  update_new_year_competition_participants?: Maybe<New_Year_Competition_Participants_Mutation_Response>;
  /** update single row of the table: "new_year_competition_participants" */
  update_new_year_competition_participants_by_pk?: Maybe<New_Year_Competition_Participants>;
  /** update multiples rows of table: "new_year_competition_participants" */
  update_new_year_competition_participants_many?: Maybe<Array<Maybe<New_Year_Competition_Participants_Mutation_Response>>>;
  /** update data of the table: "new_year_competition_participants_performance" */
  update_new_year_competition_participants_performance?: Maybe<New_Year_Competition_Participants_Performance_Mutation_Response>;
  /** update single row of the table: "new_year_competition_participants_performance" */
  update_new_year_competition_participants_performance_by_pk?: Maybe<New_Year_Competition_Participants_Performance>;
  /** update multiples rows of table: "new_year_competition_participants_performance" */
  update_new_year_competition_participants_performance_many?: Maybe<Array<Maybe<New_Year_Competition_Participants_Performance_Mutation_Response>>>;
  /** update data of the table: "new_year_competition_teams" */
  update_new_year_competition_teams?: Maybe<New_Year_Competition_Teams_Mutation_Response>;
  /** update single row of the table: "new_year_competition_teams" */
  update_new_year_competition_teams_by_pk?: Maybe<New_Year_Competition_Teams>;
  /** update multiples rows of table: "new_year_competition_teams" */
  update_new_year_competition_teams_many?: Maybe<Array<Maybe<New_Year_Competition_Teams_Mutation_Response>>>;
  /** update data of the table: "operation_types" */
  update_operation_types?: Maybe<Operation_Types_Mutation_Response>;
  /** update single row of the table: "operation_types" */
  update_operation_types_by_pk?: Maybe<Operation_Types>;
  /** update multiples rows of table: "operation_types" */
  update_operation_types_many?: Maybe<Array<Maybe<Operation_Types_Mutation_Response>>>;
  /** update data of the table: "parsers_timestamp_log" */
  update_parsers_timestamp_log?: Maybe<Parsers_Timestamp_Log_Mutation_Response>;
  /** update single row of the table: "parsers_timestamp_log" */
  update_parsers_timestamp_log_by_pk?: Maybe<Parsers_Timestamp_Log>;
  /** update multiples rows of table: "parsers_timestamp_log" */
  update_parsers_timestamp_log_many?: Maybe<Array<Maybe<Parsers_Timestamp_Log_Mutation_Response>>>;
  /** update data of the table: "partner_balance_transactions" */
  update_partner_balance_transactions?: Maybe<Partner_Balance_Transactions_Mutation_Response>;
  /** update single row of the table: "partner_balance_transactions" */
  update_partner_balance_transactions_by_pk?: Maybe<Partner_Balance_Transactions>;
  /** update multiples rows of table: "partner_balance_transactions" */
  update_partner_balance_transactions_many?: Maybe<Array<Maybe<Partner_Balance_Transactions_Mutation_Response>>>;
  /** update data of the table: "partner_offer_types" */
  update_partner_offer_types?: Maybe<Partner_Offer_Types_Mutation_Response>;
  /** update single row of the table: "partner_offer_types" */
  update_partner_offer_types_by_pk?: Maybe<Partner_Offer_Types>;
  /** update multiples rows of table: "partner_offer_types" */
  update_partner_offer_types_many?: Maybe<Array<Maybe<Partner_Offer_Types_Mutation_Response>>>;
  /** update data of the table: "partner_params" */
  update_partner_params?: Maybe<Partner_Params_Mutation_Response>;
  /** update data of the table: "partner_params_audit" */
  update_partner_params_audit?: Maybe<Partner_Params_Audit_Mutation_Response>;
  /** update single row of the table: "partner_params_audit" */
  update_partner_params_audit_by_pk?: Maybe<Partner_Params_Audit>;
  /** update multiples rows of table: "partner_params_audit" */
  update_partner_params_audit_many?: Maybe<Array<Maybe<Partner_Params_Audit_Mutation_Response>>>;
  /** update single row of the table: "partner_params" */
  update_partner_params_by_pk?: Maybe<Partner_Params>;
  /** update multiples rows of table: "partner_params" */
  update_partner_params_many?: Maybe<Array<Maybe<Partner_Params_Mutation_Response>>>;
  /** update data of the table: "passport_info" */
  update_passport_info?: Maybe<Passport_Info_Mutation_Response>;
  /** update single row of the table: "passport_info" */
  update_passport_info_by_pk?: Maybe<Passport_Info>;
  /** update multiples rows of table: "passport_info" */
  update_passport_info_many?: Maybe<Array<Maybe<Passport_Info_Mutation_Response>>>;
  /** update data of the table: "payment_methods" */
  update_payment_methods?: Maybe<Payment_Methods_Mutation_Response>;
  /** update single row of the table: "payment_methods" */
  update_payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** update multiples rows of table: "payment_methods" */
  update_payment_methods_many?: Maybe<Array<Maybe<Payment_Methods_Mutation_Response>>>;
  /** update data of the table: "payment_methods_view" */
  update_payment_methods_view?: Maybe<Payment_Methods_View_Mutation_Response>;
  /** update single row of the table: "payment_methods_view" */
  update_payment_methods_view_by_pk?: Maybe<Payment_Methods_View>;
  /** update multiples rows of table: "payment_methods_view" */
  update_payment_methods_view_many?: Maybe<Array<Maybe<Payment_Methods_View_Mutation_Response>>>;
  /** update data of the table: "payment_providers" */
  update_payment_providers?: Maybe<Payment_Providers_Mutation_Response>;
  /** update single row of the table: "payment_providers" */
  update_payment_providers_by_pk?: Maybe<Payment_Providers>;
  /** update multiples rows of table: "payment_providers" */
  update_payment_providers_many?: Maybe<Array<Maybe<Payment_Providers_Mutation_Response>>>;
  /** update data of the table: "payment_recurring_tokens" */
  update_payment_recurring_tokens?: Maybe<Payment_Recurring_Tokens_Mutation_Response>;
  /** update single row of the table: "payment_recurring_tokens" */
  update_payment_recurring_tokens_by_pk?: Maybe<Payment_Recurring_Tokens>;
  /** update multiples rows of table: "payment_recurring_tokens" */
  update_payment_recurring_tokens_many?: Maybe<Array<Maybe<Payment_Recurring_Tokens_Mutation_Response>>>;
  /** update data of the table: "payment_requisites" */
  update_payment_requisites?: Maybe<Payment_Requisites_Mutation_Response>;
  /** update single row of the table: "payment_requisites" */
  update_payment_requisites_by_pk?: Maybe<Payment_Requisites>;
  /** update multiples rows of table: "payment_requisites" */
  update_payment_requisites_many?: Maybe<Array<Maybe<Payment_Requisites_Mutation_Response>>>;
  /** update data of the table: "payment_system_1payment_transaction_requests" */
  update_payment_system_1payment_transaction_requests?: Maybe<Payment_System_1payment_Transaction_Requests_Mutation_Response>;
  /** update single row of the table: "payment_system_1payment_transaction_requests" */
  update_payment_system_1payment_transaction_requests_by_pk?: Maybe<Payment_System_1payment_Transaction_Requests>;
  /** update multiples rows of table: "payment_system_1payment_transaction_requests" */
  update_payment_system_1payment_transaction_requests_many?: Maybe<Array<Maybe<Payment_System_1payment_Transaction_Requests_Mutation_Response>>>;
  /** update data of the table: "payment_system_free_kassa_callbacks" */
  update_payment_system_free_kassa_callbacks?: Maybe<Payment_System_Free_Kassa_Callbacks_Mutation_Response>;
  /** update single row of the table: "payment_system_free_kassa_callbacks" */
  update_payment_system_free_kassa_callbacks_by_pk?: Maybe<Payment_System_Free_Kassa_Callbacks>;
  /** update multiples rows of table: "payment_system_free_kassa_callbacks" */
  update_payment_system_free_kassa_callbacks_many?: Maybe<Array<Maybe<Payment_System_Free_Kassa_Callbacks_Mutation_Response>>>;
  /** update data of the table: "payment_system_free_kassa_transaction_requests" */
  update_payment_system_free_kassa_transaction_requests?: Maybe<Payment_System_Free_Kassa_Transaction_Requests_Mutation_Response>;
  /** update single row of the table: "payment_system_free_kassa_transaction_requests" */
  update_payment_system_free_kassa_transaction_requests_by_pk?: Maybe<Payment_System_Free_Kassa_Transaction_Requests>;
  /** update multiples rows of table: "payment_system_free_kassa_transaction_requests" */
  update_payment_system_free_kassa_transaction_requests_many?: Maybe<Array<Maybe<Payment_System_Free_Kassa_Transaction_Requests_Mutation_Response>>>;
  /** update data of the table: "payment_system_main_callbacks" */
  update_payment_system_main_callbacks?: Maybe<Payment_System_Main_Callbacks_Mutation_Response>;
  /** update single row of the table: "payment_system_main_callbacks" */
  update_payment_system_main_callbacks_by_pk?: Maybe<Payment_System_Main_Callbacks>;
  /** update multiples rows of table: "payment_system_main_callbacks" */
  update_payment_system_main_callbacks_many?: Maybe<Array<Maybe<Payment_System_Main_Callbacks_Mutation_Response>>>;
  /** update data of the table: "payment_system_main_transaction_requests" */
  update_payment_system_main_transaction_requests?: Maybe<Payment_System_Main_Transaction_Requests_Mutation_Response>;
  /** update single row of the table: "payment_system_main_transaction_requests" */
  update_payment_system_main_transaction_requests_by_pk?: Maybe<Payment_System_Main_Transaction_Requests>;
  /** update multiples rows of table: "payment_system_main_transaction_requests" */
  update_payment_system_main_transaction_requests_many?: Maybe<Array<Maybe<Payment_System_Main_Transaction_Requests_Mutation_Response>>>;
  /** update data of the table: "payment_systems_1payment_callbacks" */
  update_payment_systems_1payment_callbacks?: Maybe<Payment_Systems_1payment_Callbacks_Mutation_Response>;
  /** update single row of the table: "payment_systems_1payment_callbacks" */
  update_payment_systems_1payment_callbacks_by_pk?: Maybe<Payment_Systems_1payment_Callbacks>;
  /** update multiples rows of table: "payment_systems_1payment_callbacks" */
  update_payment_systems_1payment_callbacks_many?: Maybe<Array<Maybe<Payment_Systems_1payment_Callbacks_Mutation_Response>>>;
  /** update data of the table: "payment_systems_callbacks" */
  update_payment_systems_callbacks?: Maybe<Payment_Systems_Callbacks_Mutation_Response>;
  /** update single row of the table: "payment_systems_callbacks" */
  update_payment_systems_callbacks_by_pk?: Maybe<Payment_Systems_Callbacks>;
  /** update multiples rows of table: "payment_systems_callbacks" */
  update_payment_systems_callbacks_many?: Maybe<Array<Maybe<Payment_Systems_Callbacks_Mutation_Response>>>;
  /** update data of the table: "payment_systems_data" */
  update_payment_systems_data?: Maybe<Payment_Systems_Data_Mutation_Response>;
  /** update single row of the table: "payment_systems_data" */
  update_payment_systems_data_by_pk?: Maybe<Payment_Systems_Data>;
  /** update multiples rows of table: "payment_systems_data" */
  update_payment_systems_data_many?: Maybe<Array<Maybe<Payment_Systems_Data_Mutation_Response>>>;
  /** update data of the table: "payment_systems_methods" */
  update_payment_systems_methods?: Maybe<Payment_Systems_Methods_Mutation_Response>;
  /** update single row of the table: "payment_systems_methods" */
  update_payment_systems_methods_by_pk?: Maybe<Payment_Systems_Methods>;
  /** update multiples rows of table: "payment_systems_methods" */
  update_payment_systems_methods_many?: Maybe<Array<Maybe<Payment_Systems_Methods_Mutation_Response>>>;
  /** update data of the table: "payment_systems_transaction_requests" */
  update_payment_systems_transaction_requests?: Maybe<Payment_Systems_Transaction_Requests_Mutation_Response>;
  /** update single row of the table: "payment_systems_transaction_requests" */
  update_payment_systems_transaction_requests_by_pk?: Maybe<Payment_Systems_Transaction_Requests>;
  /** update multiples rows of table: "payment_systems_transaction_requests" */
  update_payment_systems_transaction_requests_many?: Maybe<Array<Maybe<Payment_Systems_Transaction_Requests_Mutation_Response>>>;
  /** update data of the table: "payment_transactions" */
  update_payment_transactions?: Maybe<Payment_Transactions_Mutation_Response>;
  /** update single row of the table: "payment_transactions" */
  update_payment_transactions_by_pk?: Maybe<Payment_Transactions>;
  /** update multiples rows of table: "payment_transactions" */
  update_payment_transactions_many?: Maybe<Array<Maybe<Payment_Transactions_Mutation_Response>>>;
  /** update data of the table: "profile_items_names" */
  update_profile_items_names?: Maybe<Profile_Items_Names_Mutation_Response>;
  /** update single row of the table: "profile_items_names" */
  update_profile_items_names_by_pk?: Maybe<Profile_Items_Names>;
  /** update multiples rows of table: "profile_items_names" */
  update_profile_items_names_many?: Maybe<Array<Maybe<Profile_Items_Names_Mutation_Response>>>;
  /** update data of the table: "promo_lootboxes" */
  update_promo_lootboxes?: Maybe<Promo_Lootboxes_Mutation_Response>;
  /** update single row of the table: "promo_lootboxes" */
  update_promo_lootboxes_by_pk?: Maybe<Promo_Lootboxes>;
  /** update multiples rows of table: "promo_lootboxes" */
  update_promo_lootboxes_many?: Maybe<Array<Maybe<Promo_Lootboxes_Mutation_Response>>>;
  /** update data of the table: "promo_users" */
  update_promo_users?: Maybe<Promo_Users_Mutation_Response>;
  /** update single row of the table: "promo_users" */
  update_promo_users_by_pk?: Maybe<Promo_Users>;
  /** update multiples rows of table: "promo_users" */
  update_promo_users_many?: Maybe<Array<Maybe<Promo_Users_Mutation_Response>>>;
  /** update data of the table: "promo_values" */
  update_promo_values?: Maybe<Promo_Values_Mutation_Response>;
  /** update single row of the table: "promo_values" */
  update_promo_values_by_pk?: Maybe<Promo_Values>;
  /** update multiples rows of table: "promo_values" */
  update_promo_values_many?: Maybe<Array<Maybe<Promo_Values_Mutation_Response>>>;
  /** update data of the table: "promocode_activations" */
  update_promocode_activations?: Maybe<Promocode_Activations_Mutation_Response>;
  /** update single row of the table: "promocode_activations" */
  update_promocode_activations_by_pk?: Maybe<Promocode_Activations>;
  /** update multiples rows of table: "promocode_activations" */
  update_promocode_activations_many?: Maybe<Array<Maybe<Promocode_Activations_Mutation_Response>>>;
  /** update data of the table: "promocodes" */
  update_promocodes?: Maybe<Promocodes_Mutation_Response>;
  /** update single row of the table: "promocodes" */
  update_promocodes_by_pk?: Maybe<Promocodes>;
  /** update multiples rows of table: "promocodes" */
  update_promocodes_many?: Maybe<Array<Maybe<Promocodes_Mutation_Response>>>;
  /** update data of the table: "ps_types" */
  update_ps_types?: Maybe<Ps_Types_Mutation_Response>;
  /** update single row of the table: "ps_types" */
  update_ps_types_by_pk?: Maybe<Ps_Types>;
  /** update multiples rows of table: "ps_types" */
  update_ps_types_many?: Maybe<Array<Maybe<Ps_Types_Mutation_Response>>>;
  /** update data of the table: "qservice_data" */
  update_qservice_data?: Maybe<Qservice_Data_Mutation_Response>;
  /** update single row of the table: "qservice_data" */
  update_qservice_data_by_pk?: Maybe<Qservice_Data>;
  /** update multiples rows of table: "qservice_data" */
  update_qservice_data_many?: Maybe<Array<Maybe<Qservice_Data_Mutation_Response>>>;
  /** update data of the table: "statistic_bonus_wheels_users_offers" */
  update_statistic_bonus_wheels_users_offers?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Mutation_Response>;
  /** update single row of the table: "statistic_bonus_wheels_users_offers" */
  update_statistic_bonus_wheels_users_offers_by_pk?: Maybe<Statistic_Bonus_Wheels_Users_Offers>;
  /** update multiples rows of table: "statistic_bonus_wheels_users_offers" */
  update_statistic_bonus_wheels_users_offers_many?: Maybe<Array<Maybe<Statistic_Bonus_Wheels_Users_Offers_Mutation_Response>>>;
  /** update data of the table: "statistic_button_again_press" */
  update_statistic_button_again_press?: Maybe<Statistic_Button_Again_Press_Mutation_Response>;
  /** update single row of the table: "statistic_button_again_press" */
  update_statistic_button_again_press_by_pk?: Maybe<Statistic_Button_Again_Press>;
  /** update multiples rows of table: "statistic_button_again_press" */
  update_statistic_button_again_press_many?: Maybe<Array<Maybe<Statistic_Button_Again_Press_Mutation_Response>>>;
  /** update data of the table: "statistic_fast_roll_trigger" */
  update_statistic_fast_roll_trigger?: Maybe<Statistic_Fast_Roll_Trigger_Mutation_Response>;
  /** update single row of the table: "statistic_fast_roll_trigger" */
  update_statistic_fast_roll_trigger_by_pk?: Maybe<Statistic_Fast_Roll_Trigger>;
  /** update multiples rows of table: "statistic_fast_roll_trigger" */
  update_statistic_fast_roll_trigger_many?: Maybe<Array<Maybe<Statistic_Fast_Roll_Trigger_Mutation_Response>>>;
  /** update data of the table: "statistic_inventory_opens" */
  update_statistic_inventory_opens?: Maybe<Statistic_Inventory_Opens_Mutation_Response>;
  /** update single row of the table: "statistic_inventory_opens" */
  update_statistic_inventory_opens_by_pk?: Maybe<Statistic_Inventory_Opens>;
  /** update multiples rows of table: "statistic_inventory_opens" */
  update_statistic_inventory_opens_many?: Maybe<Array<Maybe<Statistic_Inventory_Opens_Mutation_Response>>>;
  /** update data of the table: "statistic_items_drops" */
  update_statistic_items_drops?: Maybe<Statistic_Items_Drops_Mutation_Response>;
  /** update single row of the table: "statistic_items_drops" */
  update_statistic_items_drops_by_pk?: Maybe<Statistic_Items_Drops>;
  /** update multiples rows of table: "statistic_items_drops" */
  update_statistic_items_drops_many?: Maybe<Array<Maybe<Statistic_Items_Drops_Mutation_Response>>>;
  /** update data of the table: "statistic_items_names" */
  update_statistic_items_names?: Maybe<Statistic_Items_Names_Mutation_Response>;
  /** update single row of the table: "statistic_items_names" */
  update_statistic_items_names_by_pk?: Maybe<Statistic_Items_Names>;
  /** update multiples rows of table: "statistic_items_names" */
  update_statistic_items_names_many?: Maybe<Array<Maybe<Statistic_Items_Names_Mutation_Response>>>;
  /** update data of the table: "statistic_items_output_fail" */
  update_statistic_items_output_fail?: Maybe<Statistic_Items_Output_Fail_Mutation_Response>;
  /** update single row of the table: "statistic_items_output_fail" */
  update_statistic_items_output_fail_by_pk?: Maybe<Statistic_Items_Output_Fail>;
  /** update multiples rows of table: "statistic_items_output_fail" */
  update_statistic_items_output_fail_many?: Maybe<Array<Maybe<Statistic_Items_Output_Fail_Mutation_Response>>>;
  /** update data of the table: "statistic_items_output_success" */
  update_statistic_items_output_success?: Maybe<Statistic_Items_Output_Success_Mutation_Response>;
  /** update single row of the table: "statistic_items_output_success" */
  update_statistic_items_output_success_by_pk?: Maybe<Statistic_Items_Output_Success>;
  /** update multiples rows of table: "statistic_items_output_success" */
  update_statistic_items_output_success_many?: Maybe<Array<Maybe<Statistic_Items_Output_Success_Mutation_Response>>>;
  /** update data of the table: "statistic_lootbox_opens" */
  update_statistic_lootbox_opens?: Maybe<Statistic_Lootbox_Opens_Mutation_Response>;
  /** update single row of the table: "statistic_lootbox_opens" */
  update_statistic_lootbox_opens_by_pk?: Maybe<Statistic_Lootbox_Opens>;
  /** update multiples rows of table: "statistic_lootbox_opens" */
  update_statistic_lootbox_opens_many?: Maybe<Array<Maybe<Statistic_Lootbox_Opens_Mutation_Response>>>;
  /** update data of the table: "statistic_lootbox_views" */
  update_statistic_lootbox_views?: Maybe<Statistic_Lootbox_Views_Mutation_Response>;
  /** update single row of the table: "statistic_lootbox_views" */
  update_statistic_lootbox_views_by_pk?: Maybe<Statistic_Lootbox_Views>;
  /** update multiples rows of table: "statistic_lootbox_views" */
  update_statistic_lootbox_views_many?: Maybe<Array<Maybe<Statistic_Lootbox_Views_Mutation_Response>>>;
  /** update data of the table: "statistic_mainpage_visits" */
  update_statistic_mainpage_visits?: Maybe<Statistic_Mainpage_Visits_Mutation_Response>;
  /** update single row of the table: "statistic_mainpage_visits" */
  update_statistic_mainpage_visits_by_pk?: Maybe<Statistic_Mainpage_Visits>;
  /** update multiples rows of table: "statistic_mainpage_visits" */
  update_statistic_mainpage_visits_many?: Maybe<Array<Maybe<Statistic_Mainpage_Visits_Mutation_Response>>>;
  /** update data of the table: "statistic_promocode_full_activations" */
  update_statistic_promocode_full_activations?: Maybe<Statistic_Promocode_Full_Activations_Mutation_Response>;
  /** update single row of the table: "statistic_promocode_full_activations" */
  update_statistic_promocode_full_activations_by_pk?: Maybe<Statistic_Promocode_Full_Activations>;
  /** update multiples rows of table: "statistic_promocode_full_activations" */
  update_statistic_promocode_full_activations_many?: Maybe<Array<Maybe<Statistic_Promocode_Full_Activations_Mutation_Response>>>;
  /** update data of the table: "statistic_promocode_success_activations" */
  update_statistic_promocode_success_activations?: Maybe<Statistic_Promocode_Success_Activations_Mutation_Response>;
  /** update single row of the table: "statistic_promocode_success_activations" */
  update_statistic_promocode_success_activations_by_pk?: Maybe<Statistic_Promocode_Success_Activations>;
  /** update multiples rows of table: "statistic_promocode_success_activations" */
  update_statistic_promocode_success_activations_many?: Maybe<Array<Maybe<Statistic_Promocode_Success_Activations_Mutation_Response>>>;
  /** update data of the table: "statistic_sell_all_items" */
  update_statistic_sell_all_items?: Maybe<Statistic_Sell_All_Items_Mutation_Response>;
  /** update single row of the table: "statistic_sell_all_items" */
  update_statistic_sell_all_items_by_pk?: Maybe<Statistic_Sell_All_Items>;
  /** update multiples rows of table: "statistic_sell_all_items" */
  update_statistic_sell_all_items_many?: Maybe<Array<Maybe<Statistic_Sell_All_Items_Mutation_Response>>>;
  /** update data of the table: "statistic_sell_item" */
  update_statistic_sell_item?: Maybe<Statistic_Sell_Item_Mutation_Response>;
  /** update single row of the table: "statistic_sell_item" */
  update_statistic_sell_item_by_pk?: Maybe<Statistic_Sell_Item>;
  /** update multiples rows of table: "statistic_sell_item" */
  update_statistic_sell_item_many?: Maybe<Array<Maybe<Statistic_Sell_Item_Mutation_Response>>>;
  /** update data of the table: "statistic_user_deposit_success_callbacks" */
  update_statistic_user_deposit_success_callbacks?: Maybe<Statistic_User_Deposit_Success_Callbacks_Mutation_Response>;
  /** update single row of the table: "statistic_user_deposit_success_callbacks" */
  update_statistic_user_deposit_success_callbacks_by_pk?: Maybe<Statistic_User_Deposit_Success_Callbacks>;
  /** update multiples rows of table: "statistic_user_deposit_success_callbacks" */
  update_statistic_user_deposit_success_callbacks_many?: Maybe<Array<Maybe<Statistic_User_Deposit_Success_Callbacks_Mutation_Response>>>;
  /** update data of the table: "statistic_user_deposits_transaction_requests" */
  update_statistic_user_deposits_transaction_requests?: Maybe<Statistic_User_Deposits_Transaction_Requests_Mutation_Response>;
  /** update single row of the table: "statistic_user_deposits_transaction_requests" */
  update_statistic_user_deposits_transaction_requests_by_pk?: Maybe<Statistic_User_Deposits_Transaction_Requests>;
  /** update multiples rows of table: "statistic_user_deposits_transaction_requests" */
  update_statistic_user_deposits_transaction_requests_many?: Maybe<Array<Maybe<Statistic_User_Deposits_Transaction_Requests_Mutation_Response>>>;
  /** update data of the table: "statistic_user_free_lootboxes_opens" */
  update_statistic_user_free_lootboxes_opens?: Maybe<Statistic_User_Free_Lootboxes_Opens_Mutation_Response>;
  /** update single row of the table: "statistic_user_free_lootboxes_opens" */
  update_statistic_user_free_lootboxes_opens_by_pk?: Maybe<Statistic_User_Free_Lootboxes_Opens>;
  /** update multiples rows of table: "statistic_user_free_lootboxes_opens" */
  update_statistic_user_free_lootboxes_opens_many?: Maybe<Array<Maybe<Statistic_User_Free_Lootboxes_Opens_Mutation_Response>>>;
  /** update data of the table: "statistic_user_registration" */
  update_statistic_user_registration?: Maybe<Statistic_User_Registration_Mutation_Response>;
  /** update single row of the table: "statistic_user_registration" */
  update_statistic_user_registration_by_pk?: Maybe<Statistic_User_Registration>;
  /** update multiples rows of table: "statistic_user_registration" */
  update_statistic_user_registration_many?: Maybe<Array<Maybe<Statistic_User_Registration_Mutation_Response>>>;
  /** update data of the table: "stocks" */
  update_stocks?: Maybe<Stocks_Mutation_Response>;
  /** update single row of the table: "stocks" */
  update_stocks_by_pk?: Maybe<Stocks>;
  /** update multiples rows of table: "stocks" */
  update_stocks_many?: Maybe<Array<Maybe<Stocks_Mutation_Response>>>;
  /** update data of the table: "store_offer_badges" */
  update_store_offer_badges?: Maybe<Store_Offer_Badges_Mutation_Response>;
  /** update single row of the table: "store_offer_badges" */
  update_store_offer_badges_by_pk?: Maybe<Store_Offer_Badges>;
  /** update multiples rows of table: "store_offer_badges" */
  update_store_offer_badges_many?: Maybe<Array<Maybe<Store_Offer_Badges_Mutation_Response>>>;
  /** update data of the table: "store_offer_boosters" */
  update_store_offer_boosters?: Maybe<Store_Offer_Boosters_Mutation_Response>;
  /** update single row of the table: "store_offer_boosters" */
  update_store_offer_boosters_by_pk?: Maybe<Store_Offer_Boosters>;
  /** update multiples rows of table: "store_offer_boosters" */
  update_store_offer_boosters_many?: Maybe<Array<Maybe<Store_Offer_Boosters_Mutation_Response>>>;
  /** update data of the table: "store_offer_coins" */
  update_store_offer_coins?: Maybe<Store_Offer_Coins_Mutation_Response>;
  /** update single row of the table: "store_offer_coins" */
  update_store_offer_coins_by_pk?: Maybe<Store_Offer_Coins>;
  /** update multiples rows of table: "store_offer_coins" */
  update_store_offer_coins_many?: Maybe<Array<Maybe<Store_Offer_Coins_Mutation_Response>>>;
  /** update data of the table: "store_offer_lootboxes" */
  update_store_offer_lootboxes?: Maybe<Store_Offer_Lootboxes_Mutation_Response>;
  /** update single row of the table: "store_offer_lootboxes" */
  update_store_offer_lootboxes_by_pk?: Maybe<Store_Offer_Lootboxes>;
  /** update multiples rows of table: "store_offer_lootboxes" */
  update_store_offer_lootboxes_many?: Maybe<Array<Maybe<Store_Offer_Lootboxes_Mutation_Response>>>;
  /** update data of the table: "store_offer_sales" */
  update_store_offer_sales?: Maybe<Store_Offer_Sales_Mutation_Response>;
  /** update single row of the table: "store_offer_sales" */
  update_store_offer_sales_by_pk?: Maybe<Store_Offer_Sales>;
  /** update multiples rows of table: "store_offer_sales" */
  update_store_offer_sales_many?: Maybe<Array<Maybe<Store_Offer_Sales_Mutation_Response>>>;
  /** update data of the table: "store_offer_skins" */
  update_store_offer_skins?: Maybe<Store_Offer_Skins_Mutation_Response>;
  /** update single row of the table: "store_offer_skins" */
  update_store_offer_skins_by_pk?: Maybe<Store_Offer_Skins>;
  /** update multiples rows of table: "store_offer_skins" */
  update_store_offer_skins_many?: Maybe<Array<Maybe<Store_Offer_Skins_Mutation_Response>>>;
  /** update data of the table: "store_offer_tickets" */
  update_store_offer_tickets?: Maybe<Store_Offer_Tickets_Mutation_Response>;
  /** update single row of the table: "store_offer_tickets" */
  update_store_offer_tickets_by_pk?: Maybe<Store_Offer_Tickets>;
  /** update multiples rows of table: "store_offer_tickets" */
  update_store_offer_tickets_many?: Maybe<Array<Maybe<Store_Offer_Tickets_Mutation_Response>>>;
  /** update data of the table: "store_offer_types" */
  update_store_offer_types?: Maybe<Store_Offer_Types_Mutation_Response>;
  /** update single row of the table: "store_offer_types" */
  update_store_offer_types_by_pk?: Maybe<Store_Offer_Types>;
  /** update multiples rows of table: "store_offer_types" */
  update_store_offer_types_many?: Maybe<Array<Maybe<Store_Offer_Types_Mutation_Response>>>;
  /** update data of the table: "store_offers" */
  update_store_offers?: Maybe<Store_Offers_Mutation_Response>;
  /** update single row of the table: "store_offers" */
  update_store_offers_by_pk?: Maybe<Store_Offers>;
  /** update multiples rows of table: "store_offers" */
  update_store_offers_many?: Maybe<Array<Maybe<Store_Offers_Mutation_Response>>>;
  /** update data of the table: "survey_data_links" */
  update_survey_data_links?: Maybe<Survey_Data_Links_Mutation_Response>;
  /** update single row of the table: "survey_data_links" */
  update_survey_data_links_by_pk?: Maybe<Survey_Data_Links>;
  /** update multiples rows of table: "survey_data_links" */
  update_survey_data_links_many?: Maybe<Array<Maybe<Survey_Data_Links_Mutation_Response>>>;
  /** update data of the table: "telegram_admin_params" */
  update_telegram_admin_params?: Maybe<Telegram_Admin_Params_Mutation_Response>;
  /** update single row of the table: "telegram_admin_params" */
  update_telegram_admin_params_by_pk?: Maybe<Telegram_Admin_Params>;
  /** update multiples rows of table: "telegram_admin_params" */
  update_telegram_admin_params_many?: Maybe<Array<Maybe<Telegram_Admin_Params_Mutation_Response>>>;
  /** update data of the table: "ticket_counts_result" */
  update_ticket_counts_result?: Maybe<Ticket_Counts_Result_Mutation_Response>;
  /** update multiples rows of table: "ticket_counts_result" */
  update_ticket_counts_result_many?: Maybe<Array<Maybe<Ticket_Counts_Result_Mutation_Response>>>;
  /** update data of the table: "tickets_set_content" */
  update_tickets_set_content?: Maybe<Tickets_Set_Content_Mutation_Response>;
  /** update single row of the table: "tickets_set_content" */
  update_tickets_set_content_by_pk?: Maybe<Tickets_Set_Content>;
  /** update multiples rows of table: "tickets_set_content" */
  update_tickets_set_content_many?: Maybe<Array<Maybe<Tickets_Set_Content_Mutation_Response>>>;
  /** update data of the table: "tickets_sets" */
  update_tickets_sets?: Maybe<Tickets_Sets_Mutation_Response>;
  /** update single row of the table: "tickets_sets" */
  update_tickets_sets_by_pk?: Maybe<Tickets_Sets>;
  /** update multiples rows of table: "tickets_sets" */
  update_tickets_sets_many?: Maybe<Array<Maybe<Tickets_Sets_Mutation_Response>>>;
  /** update data of the table: "top_drop_strip" */
  update_top_drop_strip?: Maybe<Top_Drop_Strip_Mutation_Response>;
  /** update single row of the table: "top_drop_strip" */
  update_top_drop_strip_by_pk?: Maybe<Top_Drop_Strip>;
  /** update multiples rows of table: "top_drop_strip" */
  update_top_drop_strip_many?: Maybe<Array<Maybe<Top_Drop_Strip_Mutation_Response>>>;
  /** update data of the table: "transaction" */
  update_transaction?: Maybe<Transaction_Mutation_Response>;
  /** update single row of the table: "transaction" */
  update_transaction_by_pk?: Maybe<Transaction>;
  /** update multiples rows of table: "transaction" */
  update_transaction_many?: Maybe<Array<Maybe<Transaction_Mutation_Response>>>;
  /** update data of the table: "url_mappings" */
  update_url_mappings?: Maybe<Url_Mappings_Mutation_Response>;
  /** update single row of the table: "url_mappings" */
  update_url_mappings_by_pk?: Maybe<Url_Mappings>;
  /** update multiples rows of table: "url_mappings" */
  update_url_mappings_many?: Maybe<Array<Maybe<Url_Mappings_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update data of the table: "user_account" */
  update_user_account?: Maybe<User_Account_Mutation_Response>;
  /** update single row of the table: "user_account" */
  update_user_account_by_pk?: Maybe<User_Account>;
  /** update multiples rows of table: "user_account" */
  update_user_account_many?: Maybe<Array<Maybe<User_Account_Mutation_Response>>>;
  /** update data of the table: "user_auth" */
  update_user_auth?: Maybe<User_Auth_Mutation_Response>;
  /** update single row of the table: "user_auth" */
  update_user_auth_by_pk?: Maybe<User_Auth>;
  /** update multiples rows of table: "user_auth" */
  update_user_auth_many?: Maybe<Array<Maybe<User_Auth_Mutation_Response>>>;
  /** update data of the table: "user_bonus_wallet" */
  update_user_bonus_wallet?: Maybe<User_Bonus_Wallet_Mutation_Response>;
  /** update single row of the table: "user_bonus_wallet" */
  update_user_bonus_wallet_by_pk?: Maybe<User_Bonus_Wallet>;
  /** update multiples rows of table: "user_bonus_wallet" */
  update_user_bonus_wallet_many?: Maybe<Array<Maybe<User_Bonus_Wallet_Mutation_Response>>>;
  /** update data of the table: "user_bonus_wallet_transactions" */
  update_user_bonus_wallet_transactions?: Maybe<User_Bonus_Wallet_Transactions_Mutation_Response>;
  /** update single row of the table: "user_bonus_wallet_transactions" */
  update_user_bonus_wallet_transactions_by_pk?: Maybe<User_Bonus_Wallet_Transactions>;
  /** update multiples rows of table: "user_bonus_wallet_transactions" */
  update_user_bonus_wallet_transactions_many?: Maybe<Array<Maybe<User_Bonus_Wallet_Transactions_Mutation_Response>>>;
  /** update data of the table: "user_boosters_in_inventory" */
  update_user_boosters_in_inventory?: Maybe<User_Boosters_In_Inventory_Mutation_Response>;
  /** update single row of the table: "user_boosters_in_inventory" */
  update_user_boosters_in_inventory_by_pk?: Maybe<User_Boosters_In_Inventory>;
  /** update multiples rows of table: "user_boosters_in_inventory" */
  update_user_boosters_in_inventory_many?: Maybe<Array<Maybe<User_Boosters_In_Inventory_Mutation_Response>>>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_contacts" */
  update_user_contacts?: Maybe<User_Contacts_Mutation_Response>;
  /** update single row of the table: "user_contacts" */
  update_user_contacts_by_pk?: Maybe<User_Contacts>;
  /** update multiples rows of table: "user_contacts" */
  update_user_contacts_many?: Maybe<Array<Maybe<User_Contacts_Mutation_Response>>>;
  /** update data of the table: "user_correct_answers_rewards" */
  update_user_correct_answers_rewards?: Maybe<User_Correct_Answers_Rewards_Mutation_Response>;
  /** update single row of the table: "user_correct_answers_rewards" */
  update_user_correct_answers_rewards_by_pk?: Maybe<User_Correct_Answers_Rewards>;
  /** update multiples rows of table: "user_correct_answers_rewards" */
  update_user_correct_answers_rewards_many?: Maybe<Array<Maybe<User_Correct_Answers_Rewards_Mutation_Response>>>;
  /** update data of the table: "user_daily_coins_claims" */
  update_user_daily_coins_claims?: Maybe<User_Daily_Coins_Claims_Mutation_Response>;
  /** update single row of the table: "user_daily_coins_claims" */
  update_user_daily_coins_claims_by_pk?: Maybe<User_Daily_Coins_Claims>;
  /** update multiples rows of table: "user_daily_coins_claims" */
  update_user_daily_coins_claims_many?: Maybe<Array<Maybe<User_Daily_Coins_Claims_Mutation_Response>>>;
  /** update data of the table: "user_daily_reward_counts" */
  update_user_daily_reward_counts?: Maybe<User_Daily_Reward_Counts_Mutation_Response>;
  /** update single row of the table: "user_daily_reward_counts" */
  update_user_daily_reward_counts_by_pk?: Maybe<User_Daily_Reward_Counts>;
  /** update multiples rows of table: "user_daily_reward_counts" */
  update_user_daily_reward_counts_many?: Maybe<Array<Maybe<User_Daily_Reward_Counts_Mutation_Response>>>;
  /** update data of the table: "user_email_verification_data" */
  update_user_email_verification_data?: Maybe<User_Email_Verification_Data_Mutation_Response>;
  /** update single row of the table: "user_email_verification_data" */
  update_user_email_verification_data_by_pk?: Maybe<User_Email_Verification_Data>;
  /** update multiples rows of table: "user_email_verification_data" */
  update_user_email_verification_data_many?: Maybe<Array<Maybe<User_Email_Verification_Data_Mutation_Response>>>;
  /** update data of the table: "user_events" */
  update_user_events?: Maybe<User_Events_Mutation_Response>;
  /** update single row of the table: "user_events" */
  update_user_events_by_pk?: Maybe<User_Events>;
  /** update multiples rows of table: "user_events" */
  update_user_events_many?: Maybe<Array<Maybe<User_Events_Mutation_Response>>>;
  /** update data of the table: "user_got_bonus" */
  update_user_got_bonus?: Maybe<User_Got_Bonus_Mutation_Response>;
  /** update single row of the table: "user_got_bonus" */
  update_user_got_bonus_by_pk?: Maybe<User_Got_Bonus>;
  /** update multiples rows of table: "user_got_bonus" */
  update_user_got_bonus_many?: Maybe<Array<Maybe<User_Got_Bonus_Mutation_Response>>>;
  /** update data of the table: "user_inventory" */
  update_user_inventory?: Maybe<User_Inventory_Mutation_Response>;
  /** update single row of the table: "user_inventory" */
  update_user_inventory_by_pk?: Maybe<User_Inventory>;
  /** update multiples rows of table: "user_inventory" */
  update_user_inventory_many?: Maybe<Array<Maybe<User_Inventory_Mutation_Response>>>;
  /** update data of the table: "user_inventory_transactions" */
  update_user_inventory_transactions?: Maybe<User_Inventory_Transactions_Mutation_Response>;
  /** update single row of the table: "user_inventory_transactions" */
  update_user_inventory_transactions_by_pk?: Maybe<User_Inventory_Transactions>;
  /** update multiples rows of table: "user_inventory_transactions" */
  update_user_inventory_transactions_many?: Maybe<Array<Maybe<User_Inventory_Transactions_Mutation_Response>>>;
  /** update data of the table: "user_items_in_inventory" */
  update_user_items_in_inventory?: Maybe<User_Items_In_Inventory_Mutation_Response>;
  /** update single row of the table: "user_items_in_inventory" */
  update_user_items_in_inventory_by_pk?: Maybe<User_Items_In_Inventory>;
  /** update multiples rows of table: "user_items_in_inventory" */
  update_user_items_in_inventory_many?: Maybe<Array<Maybe<User_Items_In_Inventory_Mutation_Response>>>;
  /** update data of the table: "user_lootbox_opens_count" */
  update_user_lootbox_opens_count?: Maybe<User_Lootbox_Opens_Count_Mutation_Response>;
  /** update single row of the table: "user_lootbox_opens_count" */
  update_user_lootbox_opens_count_by_pk?: Maybe<User_Lootbox_Opens_Count>;
  /** update multiples rows of table: "user_lootbox_opens_count" */
  update_user_lootbox_opens_count_many?: Maybe<Array<Maybe<User_Lootbox_Opens_Count_Mutation_Response>>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "user_marking" */
  update_user_marking?: Maybe<User_Marking_Mutation_Response>;
  /** update single row of the table: "user_marking" */
  update_user_marking_by_pk?: Maybe<User_Marking>;
  /** update multiples rows of table: "user_marking" */
  update_user_marking_many?: Maybe<Array<Maybe<User_Marking_Mutation_Response>>>;
  /** update data of the table: "user_markings" */
  update_user_markings?: Maybe<User_Markings_Mutation_Response>;
  /** update single row of the table: "user_markings" */
  update_user_markings_by_pk?: Maybe<User_Markings>;
  /** update multiples rows of table: "user_markings" */
  update_user_markings_many?: Maybe<Array<Maybe<User_Markings_Mutation_Response>>>;
  /** update data of the table: "user_output_limits" */
  update_user_output_limits?: Maybe<User_Output_Limits_Mutation_Response>;
  /** update single row of the table: "user_output_limits" */
  update_user_output_limits_by_pk?: Maybe<User_Output_Limits>;
  /** update multiples rows of table: "user_output_limits" */
  update_user_output_limits_many?: Maybe<Array<Maybe<User_Output_Limits_Mutation_Response>>>;
  /** update data of the table: "user_profile" */
  update_user_profile?: Maybe<User_Profile_Mutation_Response>;
  /** update single row of the table: "user_profile" */
  update_user_profile_by_pk?: Maybe<User_Profile>;
  /** update multiples rows of table: "user_profile" */
  update_user_profile_many?: Maybe<Array<Maybe<User_Profile_Mutation_Response>>>;
  /** update data of the table: "user_push_tokens" */
  update_user_push_tokens?: Maybe<User_Push_Tokens_Mutation_Response>;
  /** update single row of the table: "user_push_tokens" */
  update_user_push_tokens_by_pk?: Maybe<User_Push_Tokens>;
  /** update multiples rows of table: "user_push_tokens" */
  update_user_push_tokens_many?: Maybe<Array<Maybe<User_Push_Tokens_Mutation_Response>>>;
  /** update data of the table: "user_push_topics" */
  update_user_push_topics?: Maybe<User_Push_Topics_Mutation_Response>;
  /** update single row of the table: "user_push_topics" */
  update_user_push_topics_by_pk?: Maybe<User_Push_Topics>;
  /** update multiples rows of table: "user_push_topics" */
  update_user_push_topics_many?: Maybe<Array<Maybe<User_Push_Topics_Mutation_Response>>>;
  /** update data of the table: "user_referal_levels_params" */
  update_user_referal_levels_params?: Maybe<User_Referal_Levels_Params_Mutation_Response>;
  /** update single row of the table: "user_referal_levels_params" */
  update_user_referal_levels_params_by_pk?: Maybe<User_Referal_Levels_Params>;
  /** update multiples rows of table: "user_referal_levels_params" */
  update_user_referal_levels_params_many?: Maybe<Array<Maybe<User_Referal_Levels_Params_Mutation_Response>>>;
  /** update data of the table: "user_referal_params" */
  update_user_referal_params?: Maybe<User_Referal_Params_Mutation_Response>;
  /** update single row of the table: "user_referal_params" */
  update_user_referal_params_by_pk?: Maybe<User_Referal_Params>;
  /** update multiples rows of table: "user_referal_params" */
  update_user_referal_params_many?: Maybe<Array<Maybe<User_Referal_Params_Mutation_Response>>>;
  /** update data of the table: "user_referals" */
  update_user_referals?: Maybe<User_Referals_Mutation_Response>;
  /** update data of the table: "user_referals_balance_transactions" */
  update_user_referals_balance_transactions?: Maybe<User_Referals_Balance_Transactions_Mutation_Response>;
  /** update single row of the table: "user_referals_balance_transactions" */
  update_user_referals_balance_transactions_by_pk?: Maybe<User_Referals_Balance_Transactions>;
  /** update multiples rows of table: "user_referals_balance_transactions" */
  update_user_referals_balance_transactions_many?: Maybe<Array<Maybe<User_Referals_Balance_Transactions_Mutation_Response>>>;
  /** update data of the table: "user_referals_bonus_history" */
  update_user_referals_bonus_history?: Maybe<User_Referals_Bonus_History_Mutation_Response>;
  /** update single row of the table: "user_referals_bonus_history" */
  update_user_referals_bonus_history_by_pk?: Maybe<User_Referals_Bonus_History>;
  /** update multiples rows of table: "user_referals_bonus_history" */
  update_user_referals_bonus_history_many?: Maybe<Array<Maybe<User_Referals_Bonus_History_Mutation_Response>>>;
  /** update single row of the table: "user_referals" */
  update_user_referals_by_pk?: Maybe<User_Referals>;
  /** update multiples rows of table: "user_referals" */
  update_user_referals_many?: Maybe<Array<Maybe<User_Referals_Mutation_Response>>>;
  /** update data of the table: "user_referral" */
  update_user_referral?: Maybe<User_Referral_Mutation_Response>;
  /** update data of the table: "user_referral_association" */
  update_user_referral_association?: Maybe<User_Referral_Association_Mutation_Response>;
  /** update single row of the table: "user_referral_association" */
  update_user_referral_association_by_pk?: Maybe<User_Referral_Association>;
  /** update multiples rows of table: "user_referral_association" */
  update_user_referral_association_many?: Maybe<Array<Maybe<User_Referral_Association_Mutation_Response>>>;
  /** update data of the table: "user_referral_bonus_type" */
  update_user_referral_bonus_type?: Maybe<User_Referral_Bonus_Type_Mutation_Response>;
  /** update single row of the table: "user_referral_bonus_type" */
  update_user_referral_bonus_type_by_pk?: Maybe<User_Referral_Bonus_Type>;
  /** update multiples rows of table: "user_referral_bonus_type" */
  update_user_referral_bonus_type_many?: Maybe<Array<Maybe<User_Referral_Bonus_Type_Mutation_Response>>>;
  /** update single row of the table: "user_referral" */
  update_user_referral_by_pk?: Maybe<User_Referral>;
  /** update data of the table: "user_referral_levels_params" */
  update_user_referral_levels_params?: Maybe<User_Referral_Levels_Params_Mutation_Response>;
  /** update single row of the table: "user_referral_levels_params" */
  update_user_referral_levels_params_by_pk?: Maybe<User_Referral_Levels_Params>;
  /** update multiples rows of table: "user_referral_levels_params" */
  update_user_referral_levels_params_many?: Maybe<Array<Maybe<User_Referral_Levels_Params_Mutation_Response>>>;
  /** update multiples rows of table: "user_referral" */
  update_user_referral_many?: Maybe<Array<Maybe<User_Referral_Mutation_Response>>>;
  /** update data of the table: "user_referral_offer_bonus" */
  update_user_referral_offer_bonus?: Maybe<User_Referral_Offer_Bonus_Mutation_Response>;
  /** update single row of the table: "user_referral_offer_bonus" */
  update_user_referral_offer_bonus_by_pk?: Maybe<User_Referral_Offer_Bonus>;
  /** update multiples rows of table: "user_referral_offer_bonus" */
  update_user_referral_offer_bonus_many?: Maybe<Array<Maybe<User_Referral_Offer_Bonus_Mutation_Response>>>;
  /** update data of the table: "user_referral_offers" */
  update_user_referral_offers?: Maybe<User_Referral_Offers_Mutation_Response>;
  /** update single row of the table: "user_referral_offers" */
  update_user_referral_offers_by_pk?: Maybe<User_Referral_Offers>;
  /** update multiples rows of table: "user_referral_offers" */
  update_user_referral_offers_many?: Maybe<Array<Maybe<User_Referral_Offers_Mutation_Response>>>;
  /** update data of the table: "user_referral_params" */
  update_user_referral_params?: Maybe<User_Referral_Params_Mutation_Response>;
  /** update single row of the table: "user_referral_params" */
  update_user_referral_params_by_pk?: Maybe<User_Referral_Params>;
  /** update multiples rows of table: "user_referral_params" */
  update_user_referral_params_many?: Maybe<Array<Maybe<User_Referral_Params_Mutation_Response>>>;
  /** update data of the table: "user_referrals_balance_transactions" */
  update_user_referrals_balance_transactions?: Maybe<User_Referrals_Balance_Transactions_Mutation_Response>;
  /** update single row of the table: "user_referrals_balance_transactions" */
  update_user_referrals_balance_transactions_by_pk?: Maybe<User_Referrals_Balance_Transactions>;
  /** update multiples rows of table: "user_referrals_balance_transactions" */
  update_user_referrals_balance_transactions_many?: Maybe<Array<Maybe<User_Referrals_Balance_Transactions_Mutation_Response>>>;
  /** update data of the table: "user_seq_number" */
  update_user_seq_number?: Maybe<User_Seq_Number_Mutation_Response>;
  /** update single row of the table: "user_seq_number" */
  update_user_seq_number_by_pk?: Maybe<User_Seq_Number>;
  /** update multiples rows of table: "user_seq_number" */
  update_user_seq_number_many?: Maybe<Array<Maybe<User_Seq_Number_Mutation_Response>>>;
  /** update data of the table: "user_settings" */
  update_user_settings?: Maybe<User_Settings_Mutation_Response>;
  /** update single row of the table: "user_settings" */
  update_user_settings_by_pk?: Maybe<User_Settings>;
  /** update multiples rows of table: "user_settings" */
  update_user_settings_many?: Maybe<Array<Maybe<User_Settings_Mutation_Response>>>;
  /** update data of the table: "user_statistic" */
  update_user_statistic?: Maybe<User_Statistic_Mutation_Response>;
  /** update single row of the table: "user_statistic" */
  update_user_statistic_by_pk?: Maybe<User_Statistic>;
  /** update multiples rows of table: "user_statistic" */
  update_user_statistic_many?: Maybe<Array<Maybe<User_Statistic_Mutation_Response>>>;
  /** update data of the table: "user_steam_output_alternatives" */
  update_user_steam_output_alternatives?: Maybe<User_Steam_Output_Alternatives_Mutation_Response>;
  /** update single row of the table: "user_steam_output_alternatives" */
  update_user_steam_output_alternatives_by_pk?: Maybe<User_Steam_Output_Alternatives>;
  /** update multiples rows of table: "user_steam_output_alternatives" */
  update_user_steam_output_alternatives_many?: Maybe<Array<Maybe<User_Steam_Output_Alternatives_Mutation_Response>>>;
  /** update data of the table: "user_steam_output_watcher" */
  update_user_steam_output_watcher?: Maybe<User_Steam_Output_Watcher_Mutation_Response>;
  /** update single row of the table: "user_steam_output_watcher" */
  update_user_steam_output_watcher_by_pk?: Maybe<User_Steam_Output_Watcher>;
  /** update multiples rows of table: "user_steam_output_watcher" */
  update_user_steam_output_watcher_many?: Maybe<Array<Maybe<User_Steam_Output_Watcher_Mutation_Response>>>;
  /** update data of the table: "user_takes_free_lootbox" */
  update_user_takes_free_lootbox?: Maybe<User_Takes_Free_Lootbox_Mutation_Response>;
  /** update single row of the table: "user_takes_free_lootbox" */
  update_user_takes_free_lootbox_by_pk?: Maybe<User_Takes_Free_Lootbox>;
  /** update multiples rows of table: "user_takes_free_lootbox" */
  update_user_takes_free_lootbox_many?: Maybe<Array<Maybe<User_Takes_Free_Lootbox_Mutation_Response>>>;
  /** update data of the table: "user_upgrade_params" */
  update_user_upgrade_params?: Maybe<User_Upgrade_Params_Mutation_Response>;
  /** update single row of the table: "user_upgrade_params" */
  update_user_upgrade_params_by_pk?: Maybe<User_Upgrade_Params>;
  /** update multiples rows of table: "user_upgrade_params" */
  update_user_upgrade_params_many?: Maybe<Array<Maybe<User_Upgrade_Params_Mutation_Response>>>;
  /** update data of the table: "user_upgrade_results" */
  update_user_upgrade_results?: Maybe<User_Upgrade_Results_Mutation_Response>;
  /** update single row of the table: "user_upgrade_results" */
  update_user_upgrade_results_by_pk?: Maybe<User_Upgrade_Results>;
  /** update multiples rows of table: "user_upgrade_results" */
  update_user_upgrade_results_many?: Maybe<Array<Maybe<User_Upgrade_Results_Mutation_Response>>>;
  /** update data of the table: "user_vk_data" */
  update_user_vk_data?: Maybe<User_Vk_Data_Mutation_Response>;
  /** update single row of the table: "user_vk_data" */
  update_user_vk_data_by_pk?: Maybe<User_Vk_Data>;
  /** update multiples rows of table: "user_vk_data" */
  update_user_vk_data_many?: Maybe<Array<Maybe<User_Vk_Data_Mutation_Response>>>;
  /** update data of the table: "user_wallet" */
  update_user_wallet?: Maybe<User_Wallet_Mutation_Response>;
  /** update data of the table: "user_wallet_audit" */
  update_user_wallet_audit?: Maybe<User_Wallet_Audit_Mutation_Response>;
  /** update single row of the table: "user_wallet_audit" */
  update_user_wallet_audit_by_pk?: Maybe<User_Wallet_Audit>;
  /** update multiples rows of table: "user_wallet_audit" */
  update_user_wallet_audit_many?: Maybe<Array<Maybe<User_Wallet_Audit_Mutation_Response>>>;
  /** update single row of the table: "user_wallet" */
  update_user_wallet_by_pk?: Maybe<User_Wallet>;
  /** update multiples rows of table: "user_wallet" */
  update_user_wallet_many?: Maybe<Array<Maybe<User_Wallet_Mutation_Response>>>;
  /** update data of the table: "user_wallet_transactions" */
  update_user_wallet_transactions?: Maybe<User_Wallet_Transactions_Mutation_Response>;
  /** update single row of the table: "user_wallet_transactions" */
  update_user_wallet_transactions_by_pk?: Maybe<User_Wallet_Transactions>;
  /** update multiples rows of table: "user_wallet_transactions" */
  update_user_wallet_transactions_many?: Maybe<Array<Maybe<User_Wallet_Transactions_Mutation_Response>>>;
  /** update data of the table: "user_whitelist" */
  update_user_whitelist?: Maybe<User_Whitelist_Mutation_Response>;
  /** update single row of the table: "user_whitelist" */
  update_user_whitelist_by_pk?: Maybe<User_Whitelist>;
  /** update multiples rows of table: "user_whitelist" */
  update_user_whitelist_many?: Maybe<Array<Maybe<User_Whitelist_Mutation_Response>>>;
  /** update data of the table: "utm_visits_data" */
  update_utm_visits_data?: Maybe<Utm_Visits_Data_Mutation_Response>;
  /** update single row of the table: "utm_visits_data" */
  update_utm_visits_data_by_pk?: Maybe<Utm_Visits_Data>;
  /** update multiples rows of table: "utm_visits_data" */
  update_utm_visits_data_many?: Maybe<Array<Maybe<Utm_Visits_Data_Mutation_Response>>>;
  /** update data of the table: "webpushes_autosending_types" */
  update_webpushes_autosending_types?: Maybe<Webpushes_Autosending_Types_Mutation_Response>;
  /** update single row of the table: "webpushes_autosending_types" */
  update_webpushes_autosending_types_by_pk?: Maybe<Webpushes_Autosending_Types>;
  /** update data of the table: "webpushes_autosending_types_localization" */
  update_webpushes_autosending_types_localization?: Maybe<Webpushes_Autosending_Types_Localization_Mutation_Response>;
  /** update single row of the table: "webpushes_autosending_types_localization" */
  update_webpushes_autosending_types_localization_by_pk?: Maybe<Webpushes_Autosending_Types_Localization>;
  /** update multiples rows of table: "webpushes_autosending_types_localization" */
  update_webpushes_autosending_types_localization_many?: Maybe<Array<Maybe<Webpushes_Autosending_Types_Localization_Mutation_Response>>>;
  /** update multiples rows of table: "webpushes_autosending_types" */
  update_webpushes_autosending_types_many?: Maybe<Array<Maybe<Webpushes_Autosending_Types_Mutation_Response>>>;
  /** update data of the table: "webpushes_bonuses" */
  update_webpushes_bonuses?: Maybe<Webpushes_Bonuses_Mutation_Response>;
  /** update single row of the table: "webpushes_bonuses" */
  update_webpushes_bonuses_by_pk?: Maybe<Webpushes_Bonuses>;
  /** update multiples rows of table: "webpushes_bonuses" */
  update_webpushes_bonuses_many?: Maybe<Array<Maybe<Webpushes_Bonuses_Mutation_Response>>>;
  /** update data of the table: "webpushes_bonuses_offers" */
  update_webpushes_bonuses_offers?: Maybe<Webpushes_Bonuses_Offers_Mutation_Response>;
  /** update single row of the table: "webpushes_bonuses_offers" */
  update_webpushes_bonuses_offers_by_pk?: Maybe<Webpushes_Bonuses_Offers>;
  /** update multiples rows of table: "webpushes_bonuses_offers" */
  update_webpushes_bonuses_offers_many?: Maybe<Array<Maybe<Webpushes_Bonuses_Offers_Mutation_Response>>>;
  /** update data of the table: "webpushes_lines" */
  update_webpushes_lines?: Maybe<Webpushes_Lines_Mutation_Response>;
  /** update single row of the table: "webpushes_lines" */
  update_webpushes_lines_by_pk?: Maybe<Webpushes_Lines>;
  /** update multiples rows of table: "webpushes_lines" */
  update_webpushes_lines_many?: Maybe<Array<Maybe<Webpushes_Lines_Mutation_Response>>>;
  /** update data of the table: "webpushes_scheduling" */
  update_webpushes_scheduling?: Maybe<Webpushes_Scheduling_Mutation_Response>;
  /** update single row of the table: "webpushes_scheduling" */
  update_webpushes_scheduling_by_pk?: Maybe<Webpushes_Scheduling>;
  /** update multiples rows of table: "webpushes_scheduling" */
  update_webpushes_scheduling_many?: Maybe<Array<Maybe<Webpushes_Scheduling_Mutation_Response>>>;
  /** update data of the table: "webpushes_triggers" */
  update_webpushes_triggers?: Maybe<Webpushes_Triggers_Mutation_Response>;
  /** update single row of the table: "webpushes_triggers" */
  update_webpushes_triggers_by_pk?: Maybe<Webpushes_Triggers>;
  /** update data of the table: "webpushes_triggers_groups" */
  update_webpushes_triggers_groups?: Maybe<Webpushes_Triggers_Groups_Mutation_Response>;
  /** update single row of the table: "webpushes_triggers_groups" */
  update_webpushes_triggers_groups_by_pk?: Maybe<Webpushes_Triggers_Groups>;
  /** update multiples rows of table: "webpushes_triggers_groups" */
  update_webpushes_triggers_groups_many?: Maybe<Array<Maybe<Webpushes_Triggers_Groups_Mutation_Response>>>;
  /** update multiples rows of table: "webpushes_triggers" */
  update_webpushes_triggers_many?: Maybe<Array<Maybe<Webpushes_Triggers_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootAccOpenBonusLootBoxArgs = {
  lootbox_slug: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAccOpenFreeLootBoxArgs = {
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootAccOpenLootBoxArgs = {
  open_input: LootboxInput;
};


/** mutation root */
export type Mutation_RootAddOfferArgs = {
  add_offer_dto?: InputMaybe<AddOfferDto>;
};


/** mutation root */
export type Mutation_RootAddUserToTeamActionArgs = {
  team_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAdminUpdateUserMailAlertsActivityArgs = {
  dto: AdminUpdateUserMailAlertsActivityInput;
};


/** mutation root */
export type Mutation_RootBuyBoostersArgs = {
  boosters: Scalars['String'];
};


/** mutation root */
export type Mutation_RootConsumeBoostersActionArgs = {
  boosters: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateUserPaymentTransactionArgs = {
  transaction_data: CreateUserPaymentTransactionInput;
};


/** mutation root */
export type Mutation_RootDeactivateOfferArgs = {
  offer_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootEditUserReferalLinkArgs = {
  new_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootExcludeUsersFromAmplitudeStatByIdArgs = {
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootForce1PayCallbacjArgs = {
  data: Force1PayCallbackInput;
};


/** mutation root */
export type Mutation_RootFormItemsPriceDeltaArgs = {
  arg1?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootFormMainReportArgs = {
  dto: MainReportInput;
};


/** mutation root */
export type Mutation_RootGetItemsByNameArgs = {
  arg1: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGetItemsInLootboxArgs = {
  arg1: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootManageUserFirebaseTokenActivityArgs = {
  dto: ManageUserFirebaseTokenActivityDto;
};


/** mutation root */
export type Mutation_RootManageUserMailAlertsActivityArgs = {
  dto: ManageUserMailAlertsActivityDto;
};


/** mutation root */
export type Mutation_RootOpenBonusLootboxArgs = {
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootOpenFreeLootBoxArgs = {
  arg1: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootOpenLootBoxArgs = {
  arg1: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootOpenLootBoxMassArgs = {
  arg1: Scalars['uuid'];
  count: Scalars['Int'];
  set?: InputMaybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootOutUserItemArgs = {
  dto?: InputMaybe<OutUserItemDto>;
};


/** mutation root */
export type Mutation_RootProcessUserPermMissionProgressArgs = {
  mission_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootRollingWheelArgs = {
  ticket_type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootScheduleUserMailByUniqueTriggerArgs = {
  dto: ScheduleUserMailByEventDto;
};


/** mutation root */
export type Mutation_RootScheduleUserPushByUniqueTriggerArgs = {
  dto: ScheduleUserPushByEventDto;
};


/** mutation root */
export type Mutation_RootSellItemArgs = {
  item_data: ItemSellData;
};


/** mutation root */
export type Mutation_RootSendItemToSteamArgs = {
  arg1: SendItemToSteamInput;
};


/** mutation root */
export type Mutation_RootSendItemToSteamNewArgs = {
  item_data: SendItemToSteamInputNew;
};


/** mutation root */
export type Mutation_RootSendItemToUserArgs = {
  item_data: ItemSendData;
};


/** mutation root */
export type Mutation_RootSendItemToUserInSteamArgs = {
  dto: SendItemToUserInSteamDto;
};


/** mutation root */
export type Mutation_RootSendWebPushToTopicArgs = {
  dto: SendWebPushToTopicInput;
};


/** mutation root */
export type Mutation_RootSendWebPushToUserArgs = {
  dto: SendWebPushToUserInput;
};


/** mutation root */
export type Mutation_RootSendWssEventArgs = {
  event_data?: InputMaybe<UserEventData>;
};


/** mutation root */
export type Mutation_RootStore__CheckChoiceArgs = {
  game_data?: InputMaybe<GameData>;
};


/** mutation root */
export type Mutation_RootStore__PurchaseArgs = {
  offer_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootStore__SkinPurchaseArgs = {
  offer_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateTokenBalanceArgs = {
  pass_to_tg?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootUpdateUserHelloBonusArgs = {
  claim_input: UserHelloBonusClaimInput;
};


/** mutation root */
export type Mutation_RootUpdateUserMailAlertsActivityArgs = {
  is_active: Scalars['Boolean'];
};


/** mutation root */
export type Mutation_RootUpdateUserWebPushActivityArgs = {
  dto: UpdateUserWebPushActivityDto;
};


/** mutation root */
export type Mutation_RootUpgradeUserItemArgs = {
  upgrade_input: UserUpgradeInput;
};


/** mutation root */
export type Mutation_RootUseRegisterBonusArgs = {
  input: RegBonusInput;
};


/** mutation root */
export type Mutation_RootVerifyUserEmailArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootWithdrawUserReferalBalanceArgs = {
  out_type: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootBuyItemArgs = {
  arg1: BuyInput;
};


/** mutation root */
export type Mutation_RootDelete_Account_Items_NamesArgs = {
  where: Account_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Admin_Items_NamesArgs = {
  where: Admin_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Admin_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Admin_User_ParamsArgs = {
  where: Admin_User_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Admin_User_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Amp_Sessions_DataArgs = {
  where: Amp_Sessions_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Amp_Sessions_Data_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Banned_IpsArgs = {
  where: Banned_Ips_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Banned_Ips_By_PkArgs = {
  ip: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Banned_Users_For_MultiaccountArgs = {
  where: Banned_Users_For_Multiaccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Banned_Users_For_Multiaccount_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_BillArgs = {
  where: Bill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bill_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_ParamsArgs = {
  where: Bonus_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Params_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  param_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheel_RewardsArgs = {
  where: Bonus_Wheel_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheel_Rewards_By_PkArgs = {
  reward_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheel_Ticket_TypeArgs = {
  where: Bonus_Wheel_Ticket_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheel_Ticket_Type_By_PkArgs = {
  ticket_type_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheel_Ticket_Type_Name_LocalizationArgs = {
  where: Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheel_Ticket_Type_Name_Localization_By_PkArgs = {
  locale: Scalars['String'];
  ticket_type_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheel_User_RewardsArgs = {
  where: Bonus_Wheel_User_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheel_User_Rewards_By_PkArgs = {
  user_reward_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheel_User_TicketsArgs = {
  where: Bonus_Wheel_User_Tickets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheel_User_Tickets_By_PkArgs = {
  ticket_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_WheelsArgs = {
  where: Bonus_Wheels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Bonus_SkinsArgs = {
  where: Bonus_Wheels_Bonus_Skins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Bonus_Skins_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_BonusesArgs = {
  where: Bonus_Wheels_Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Bonuses_LocalizationArgs = {
  where: Bonus_Wheels_Bonuses_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Bonuses_Localization_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Bonuses_ParamsArgs = {
  where: Bonus_Wheels_Bonuses_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Bonuses_Params_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Bonuses_Params_LocalizationArgs = {
  where: Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Bonuses_Params_Localization_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_By_PkArgs = {
  wheel_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_GeneratorArgs = {
  where: Bonus_Wheels_Generator_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Generator_BonusesArgs = {
  where: Bonus_Wheels_Generator_Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Generator_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  wheel_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Generator_By_PkArgs = {
  wheel_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Items_In_WheelArgs = {
  where: Bonus_Wheels_Items_In_Wheel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_Items_In_Wheel_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  wheel_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_User_CheckArgs = {
  where: Bonus_Wheels_User_Check_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_User_Check_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_User_Check_ParamsArgs = {
  where: Bonus_Wheels_User_Check_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_User_Check_Params_By_PkArgs = {
  param_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_User_OffersArgs = {
  where: Bonus_Wheels_User_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_User_Offers_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_User_Offers_ParamsArgs = {
  where: Bonus_Wheels_User_Offers_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_User_Offers_Params_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  stage: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_User_Offers_Params_LocalizationArgs = {
  where: Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonus_Wheels_User_Offers_Params_Localization_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
  stage: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BonusesArgs = {
  where: Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonuses_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonuses_RegisterArgs = {
  where: Bonuses_Register_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonuses_Register_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bonuses_Register_OffersArgs = {
  where: Bonuses_Register_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bonuses_Register_Offers_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Boosters_Set_ContentArgs = {
  where: Boosters_Set_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Boosters_Set_Content_By_PkArgs = {
  content_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Boosters_SetsArgs = {
  where: Boosters_Sets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Boosters_Sets_By_PkArgs = {
  set_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Bot_Account_SettingsArgs = {
  where: Bot_Account_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bot_Account_Settings_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Bots_Lootboxes_Opens_CountArgs = {
  where: Bots_Lootboxes_Opens_Count_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bots_Lootboxes_Opens_Count_By_PkArgs = {
  bot_id: Scalars['uuid'];
  lootbox_slug: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Coins_Set_ContentArgs = {
  where: Coins_Set_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coins_Set_Content_By_PkArgs = {
  content_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Coins_SetsArgs = {
  where: Coins_Sets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coins_Sets_By_PkArgs = {
  set_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CouponsArgs = {
  where: Coupons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coupons_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Csgomarket_Token_BalanceArgs = {
  where: Csgomarket_Token_Balance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Csgomarket_Token_Balance_By_PkArgs = {
  datetime: Scalars['timestamp'];
  token_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Csgomarket_TokensArgs = {
  where: Csgomarket_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Csgomarket_Tokens_By_PkArgs = {
  token_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Currency_HistoryArgs = {
  where: Currency_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Currency_History_By_PkArgs = {
  created_at: Scalars['timestamp'];
  currency_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CurrencysArgs = {
  where: Currencys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Currencys_By_PkArgs = {
  currency_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Daily_Deposit_SumArgs = {
  where: Daily_Deposit_Sum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Daily_Deposit_Sum_By_PkArgs = {
  created_at: Scalars['date'];
};


/** mutation root */
export type Mutation_RootDelete_Emails_From_Upgrade_QuestionArgs = {
  where: Emails_From_Upgrade_Question_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Emails_From_Upgrade_Question_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Execution_MethodsArgs = {
  where: Execution_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Execution_Methods_By_PkArgs = {
  method_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Faq_TableArgs = {
  where: Faq_Table_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Faq_Table_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Fixed_Items_Generator_BoostersArgs = {
  where: Fixed_Items_Generator_Boosters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fixed_Items_Generator_Boosters_By_PkArgs = {
  booster_id: Scalars['uuid'];
  generator_lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Fixed_Items_Generator_ItemsArgs = {
  where: Fixed_Items_Generator_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fixed_Items_Generator_Items_By_PkArgs = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Fixed_Items_Generator_LootboxesArgs = {
  where: Fixed_Items_Generator_Lootboxes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fixed_Items_Generator_Lootboxes_By_PkArgs = {
  lootbox_generator_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Giveaway_MembersArgs = {
  where: Giveaway_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Giveaway_Members_By_PkArgs = {
  giveaway_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Giveaway_ParamsArgs = {
  where: Giveaway_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Giveaway_Params_By_PkArgs = {
  giveaway_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GiveawaysArgs = {
  where: Giveaways_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Giveaways_By_PkArgs = {
  giveaway_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Group_Items_CsgomarketArgs = {
  where: Group_Items_Csgomarket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Group_Items_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_History_Group_Items_CsgomarketArgs = {
  where: History_Group_Items_Csgomarket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_History_Group_Items_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_History_Items_CsgomarketArgs = {
  where: History_Items_Csgomarket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_History_Items_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Item_Out_TransactionsArgs = {
  where: Item_Out_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Item_Out_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ItemsArgs = {
  where: Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_By_PkArgs = {
  item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Items_CsgomarketArgs = {
  where: Items_Csgomarket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Items_DropsArgs = {
  where: Items_Drops_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Drops_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Items_For_UpgradeArgs = {
  where: Items_For_Upgrade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_For_Upgrade_By_PkArgs = {
  item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Items_HistoryArgs = {
  where: Items_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_History_By_PkArgs = {
  created_at: Scalars['timestamp'];
  item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Items_In_LootboxArgs = {
  where: Items_In_Lootbox_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_In_Lootbox_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Items_In_Lootbox_ViewArgs = {
  where: Items_In_Lootbox_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_In_Lootbox_View_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Items_Items_NamesArgs = {
  where: Items_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Items_LocalizationArgs = {
  where: Items_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Localization_By_PkArgs = {
  item_name_key: Scalars['String'];
  locale: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Items_NamesArgs = {
  where: Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Items_QualityArgs = {
  where: Items_Quality_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Quality_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Items_Quality_LocalizationArgs = {
  where: Items_Quality_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Quality_Localization_By_PkArgs = {
  locale: Scalars['String'];
  quality_name_key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Items_RarityArgs = {
  where: Items_Rarity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Rarity_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Items_Rarity_LocalizationArgs = {
  where: Items_Rarity_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Rarity_Localization_By_PkArgs = {
  rarity_name_key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Items_TypeArgs = {
  where: Items_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Type_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Items_Type_LocalizationArgs = {
  where: Items_Type_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Type_Localization_By_PkArgs = {
  locale: Scalars['String'];
  type_name_key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Items_Weapon_LocalizationArgs = {
  where: Items_Weapon_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Weapon_Localization_By_PkArgs = {
  locale: Scalars['String'];
  weapon_name_key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Items_WeaponsArgs = {
  where: Items_Weapons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_Weapons_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lootbox_Booster_ItemsArgs = {
  where: Lootbox_Booster_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootbox_Booster_Items_By_PkArgs = {
  item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootbox_Booster_LocalizationArgs = {
  where: Lootbox_Booster_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootbox_Booster_Localization_By_PkArgs = {
  booster_name_key: Scalars['String'];
  locale: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lootbox_CategoryArgs = {
  where: Lootbox_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootbox_Category_By_PkArgs = {
  category_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lootbox_Items_SetsArgs = {
  where: Lootbox_Items_Sets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootbox_Items_Sets_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
  set_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lootbox_ServiceArgs = {
  where: Lootbox_Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootbox_Service_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LootboxesArgs = {
  where: Lootboxes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Available_StatusesArgs = {
  where: Lootboxes_Available_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Available_Statuses_By_PkArgs = {
  status_id: Scalars['Int'];
  status_sub_type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_BonusArgs = {
  where: Lootboxes_Bonus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Bonus_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Bonus_Generator_ItemsArgs = {
  where: Lootboxes_Bonus_Generator_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Bonus_Generator_Items_By_PkArgs = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Bonus_Generator_ParamsArgs = {
  where: Lootboxes_Bonus_Generator_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Bonus_Generator_Params_By_PkArgs = {
  lootbox_generator_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Bonus_ItemsArgs = {
  where: Lootboxes_Bonus_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Bonus_Items_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Bonus_ViewsArgs = {
  where: Lootboxes_Bonus_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Bonus_Views_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Dynamic_CategoryArgs = {
  where: Lootboxes_Dynamic_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Dynamic_Category_By_PkArgs = {
  category_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Dynamic_Category_LootboxesArgs = {
  where: Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Dynamic_Category_Lootboxes_By_PkArgs = {
  category_id: Scalars['Int'];
  lootbox_slug: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_FreeArgs = {
  where: Lootboxes_Free_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Free_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Free_CategoryArgs = {
  where: Lootboxes_Free_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Free_Category_By_PkArgs = {
  category_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Free_Generator_ItemsArgs = {
  where: Lootboxes_Free_Generator_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Free_Generator_Items_By_PkArgs = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Free_Generator_ParamsArgs = {
  where: Lootboxes_Free_Generator_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Free_Generator_Params_By_PkArgs = {
  lootbox_generator_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Free_ItemsArgs = {
  where: Lootboxes_Free_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Free_Items_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Free_ViewsArgs = {
  where: Lootboxes_Free_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Free_Views_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Generator_MechanicsArgs = {
  where: Lootboxes_Generator_Mechanics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Generator_Mechanics_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Generator_ParamsArgs = {
  where: Lootboxes_Generator_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Generator_Params_By_PkArgs = {
  parent_lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_HistoryArgs = {
  where: Lootboxes_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_History_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
  lootbox_parent_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Items_NamesArgs = {
  where: Lootboxes_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Top_DropArgs = {
  where: Lootboxes_Top_Drop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lootboxes_Top_Drop_By_PkArgs = {
  drop_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mail_Autosending_TypesArgs = {
  where: Mail_Autosending_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mail_Autosending_Types_By_PkArgs = {
  mail_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mail_BonusesArgs = {
  where: Mail_Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mail_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mail_Bonuses_OffersArgs = {
  where: Mail_Bonuses_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mail_Bonuses_Offers_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_CallbacksArgs = {
  where: Mail_Mailing_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_Callbacks_By_PkArgs = {
  event_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_LinesArgs = {
  where: Mail_Mailing_Lines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_Lines_By_PkArgs = {
  mail_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_ListsArgs = {
  where: Mail_Mailing_Lists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_Lists_By_PkArgs = {
  list_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_SchedulingArgs = {
  where: Mail_Mailing_Scheduling_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_Scheduling_By_PkArgs = {
  mail_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_Sending_LogArgs = {
  where: Mail_Mailing_Sending_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_Sending_Log_By_PkArgs = {
  mail_mg_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_TriggersArgs = {
  where: Mail_Mailing_Triggers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_Triggers_By_PkArgs = {
  trigger_name: Scalars['String'];
  triggers_group_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_Triggers_GroupsArgs = {
  where: Mail_Mailing_Triggers_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mail_Mailing_Triggers_Groups_By_PkArgs = {
  mail_id: Scalars['uuid'];
  triggers_group_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mail_TemplatesArgs = {
  where: Mail_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mail_Templates_By_PkArgs = {
  template_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Mainpage_CountersArgs = {
  where: Mainpage_Counters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mainpage_Counters_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Maintenance_ModeArgs = {
  where: Maintenance_Mode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Maintenance_Mode_By_PkArgs = {
  mode_type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Market_Groups_ConnArgs = {
  where: Market_Groups_Conn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Market_Groups_Conn_By_PkArgs = {
  item_hash_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_MetasArgs = {
  where: Metas_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Metas_By_PkArgs = {
  meta_global_main_key: Scalars['String'];
  meta_global_sub_key: Scalars['String'];
  meta_key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_MissionsArgs = {
  where: Missions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Missions_By_PkArgs = {
  mission_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Missions_Onetime_Bonus_Case_ClaimsArgs = {
  where: Missions_Onetime_Bonus_Case_Claims_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Missions_Onetime_Bonus_Case_Claims_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Missions_Onetime_ParamsArgs = {
  where: Missions_Onetime_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Missions_Onetime_Params_By_PkArgs = {
  mission_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Missions_Onetime_Users_ProgressArgs = {
  where: Missions_Onetime_Users_Progress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Missions_Onetime_Users_Progress_By_PkArgs = {
  mission_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Missions_Permanent_ParamsArgs = {
  where: Missions_Permanent_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Missions_Permanent_Params_By_PkArgs = {
  mission_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Missions_Permanent_User_ProgressArgs = {
  where: Missions_Permanent_User_Progress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Missions_Permanent_User_Progress_By_PkArgs = {
  mission_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Missions_TagsArgs = {
  where: Missions_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Missions_Tags_By_PkArgs = {
  mission_id: Scalars['uuid'];
  mission_tag: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Missions_Tags_NamesArgs = {
  where: Missions_Tags_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Missions_Tags_Names_By_PkArgs = {
  tag_title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Missions_Vk_LinksArgs = {
  where: Missions_Vk_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Missions_Vk_Links_By_PkArgs = {
  vk_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_New_Year_Competition_Boosters_RewardsArgs = {
  where: New_Year_Competition_Boosters_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_New_Year_Competition_Boosters_Rewards_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_New_Year_Competition_ParticipantsArgs = {
  where: New_Year_Competition_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_New_Year_Competition_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_New_Year_Competition_Participants_PerformanceArgs = {
  where: New_Year_Competition_Participants_Performance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_New_Year_Competition_Participants_Performance_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_New_Year_Competition_TeamsArgs = {
  where: New_Year_Competition_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_New_Year_Competition_Teams_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Operation_TypesArgs = {
  where: Operation_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Operation_Types_By_PkArgs = {
  operation_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Parsers_Timestamp_LogArgs = {
  where: Parsers_Timestamp_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Parsers_Timestamp_Log_By_PkArgs = {
  parser_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Balance_TransactionsArgs = {
  where: Partner_Balance_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Balance_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Offer_TypesArgs = {
  where: Partner_Offer_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Offer_Types_By_PkArgs = {
  type_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_ParamsArgs = {
  where: Partner_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Params_AuditArgs = {
  where: Partner_Params_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Params_Audit_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Passport_InfoArgs = {
  where: Passport_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Passport_Info_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_MethodsArgs = {
  where: Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Methods_By_PkArgs = {
  pm_method_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_Methods_ViewArgs = {
  where: Payment_Methods_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Methods_View_By_PkArgs = {
  method_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_ProvidersArgs = {
  where: Payment_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Providers_By_PkArgs = {
  ps_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_Recurring_TokensArgs = {
  where: Payment_Recurring_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Recurring_Tokens_By_PkArgs = {
  pmrecur_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_RequisitesArgs = {
  where: Payment_Requisites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Requisites_By_PkArgs = {
  pmrq_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_System_1payment_Transaction_RequestsArgs = {
  where: Payment_System_1payment_Transaction_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_System_1payment_Transaction_Requests_By_PkArgs = {
  payment_transaction_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_System_Free_Kassa_CallbacksArgs = {
  where: Payment_System_Free_Kassa_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_System_Free_Kassa_Callbacks_By_PkArgs = {
  MERCHANT_ORDER_ID: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_System_Free_Kassa_Transaction_RequestsArgs = {
  where: Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_System_Free_Kassa_Transaction_Requests_By_PkArgs = {
  o: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_System_Main_CallbacksArgs = {
  where: Payment_System_Main_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_System_Main_Callbacks_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_System_Main_Transaction_RequestsArgs = {
  where: Payment_System_Main_Transaction_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_System_Main_Transaction_Requests_By_PkArgs = {
  orderId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_Systems_1payment_CallbacksArgs = {
  where: Payment_Systems_1payment_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Systems_1payment_Callbacks_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_Systems_CallbacksArgs = {
  where: Payment_Systems_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Systems_Callbacks_By_PkArgs = {
  transaction_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_Systems_DataArgs = {
  where: Payment_Systems_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Systems_Data_By_PkArgs = {
  ps_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_Systems_MethodsArgs = {
  where: Payment_Systems_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Systems_Methods_By_PkArgs = {
  pm_id: Scalars['Int'];
  pm_ps_type: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_Systems_Transaction_RequestsArgs = {
  where: Payment_Systems_Transaction_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Systems_Transaction_Requests_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_TransactionsArgs = {
  where: Payment_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Items_NamesArgs = {
  where: Profile_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Promo_LootboxesArgs = {
  where: Promo_Lootboxes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Promo_Lootboxes_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Promo_UsersArgs = {
  where: Promo_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Promo_Users_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Promo_ValuesArgs = {
  where: Promo_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Promo_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Promocode_ActivationsArgs = {
  where: Promocode_Activations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Promocode_Activations_By_PkArgs = {
  activation_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PromocodesArgs = {
  where: Promocodes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Promocodes_By_PkArgs = {
  promocode_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ps_TypesArgs = {
  where: Ps_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ps_Types_By_PkArgs = {
  ps_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Qservice_DataArgs = {
  where: Qservice_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Qservice_Data_By_PkArgs = {
  request_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Bonus_Wheels_Users_OffersArgs = {
  where: Statistic_Bonus_Wheels_Users_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Bonus_Wheels_Users_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Button_Again_PressArgs = {
  where: Statistic_Button_Again_Press_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Button_Again_Press_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Fast_Roll_TriggerArgs = {
  where: Statistic_Fast_Roll_Trigger_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Fast_Roll_Trigger_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Inventory_OpensArgs = {
  where: Statistic_Inventory_Opens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Inventory_Opens_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Items_DropsArgs = {
  where: Statistic_Items_Drops_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Items_Drops_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Items_NamesArgs = {
  where: Statistic_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Items_Output_FailArgs = {
  where: Statistic_Items_Output_Fail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Items_Output_Fail_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Items_Output_SuccessArgs = {
  where: Statistic_Items_Output_Success_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Items_Output_Success_By_PkArgs = {
  user_item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Lootbox_OpensArgs = {
  where: Statistic_Lootbox_Opens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Lootbox_Opens_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Lootbox_ViewsArgs = {
  where: Statistic_Lootbox_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Lootbox_Views_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Mainpage_VisitsArgs = {
  where: Statistic_Mainpage_Visits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Mainpage_Visits_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Promocode_Full_ActivationsArgs = {
  where: Statistic_Promocode_Full_Activations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Promocode_Full_Activations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Promocode_Success_ActivationsArgs = {
  where: Statistic_Promocode_Success_Activations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Promocode_Success_Activations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Sell_All_ItemsArgs = {
  where: Statistic_Sell_All_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Sell_All_Items_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Sell_ItemArgs = {
  where: Statistic_Sell_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_Sell_Item_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_User_Deposit_Success_CallbacksArgs = {
  where: Statistic_User_Deposit_Success_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_User_Deposit_Success_Callbacks_By_PkArgs = {
  transaction_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_User_Deposits_Transaction_RequestsArgs = {
  where: Statistic_User_Deposits_Transaction_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_User_Deposits_Transaction_Requests_By_PkArgs = {
  transaction_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_User_Free_Lootboxes_OpensArgs = {
  where: Statistic_User_Free_Lootboxes_Opens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_User_Free_Lootboxes_Opens_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Statistic_User_RegistrationArgs = {
  where: Statistic_User_Registration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Statistic_User_Registration_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_StocksArgs = {
  where: Stocks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stocks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_BadgesArgs = {
  where: Store_Offer_Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_Badges_By_PkArgs = {
  badge_value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_BoostersArgs = {
  where: Store_Offer_Boosters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_Boosters_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_CoinsArgs = {
  where: Store_Offer_Coins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_Coins_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_LootboxesArgs = {
  where: Store_Offer_Lootboxes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_Lootboxes_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_SalesArgs = {
  where: Store_Offer_Sales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_Sales_By_PkArgs = {
  sale_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_SkinsArgs = {
  where: Store_Offer_Skins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_Skins_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_TicketsArgs = {
  where: Store_Offer_Tickets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_Tickets_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_TypesArgs = {
  where: Store_Offer_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Offer_Types_By_PkArgs = {
  type_value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_OffersArgs = {
  where: Store_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Survey_Data_LinksArgs = {
  where: Survey_Data_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Survey_Data_Links_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Telegram_Admin_ParamsArgs = {
  where: Telegram_Admin_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Telegram_Admin_Params_By_PkArgs = {
  param_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Ticket_Counts_ResultArgs = {
  where: Ticket_Counts_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tickets_Set_ContentArgs = {
  where: Tickets_Set_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tickets_Set_Content_By_PkArgs = {
  content_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Tickets_SetsArgs = {
  where: Tickets_Sets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tickets_Sets_By_PkArgs = {
  set_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Top_Drop_StripArgs = {
  where: Top_Drop_Strip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Top_Drop_Strip_By_PkArgs = {
  record_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TransactionArgs = {
  where: Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
  order_num: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Url_MappingsArgs = {
  where: Url_Mappings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Url_Mappings_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_AccountArgs = {
  where: User_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Account_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_AuthArgs = {
  where: User_Auth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Auth_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Bonus_WalletArgs = {
  where: User_Bonus_Wallet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Bonus_Wallet_By_PkArgs = {
  user_bonus_wallet_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Bonus_Wallet_TransactionsArgs = {
  where: User_Bonus_Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Bonus_Wallet_Transactions_By_PkArgs = {
  operation_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Boosters_In_InventoryArgs = {
  where: User_Boosters_In_Inventory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Boosters_In_Inventory_By_PkArgs = {
  user_booster_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_ContactsArgs = {
  where: User_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Contacts_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Correct_Answers_RewardsArgs = {
  where: User_Correct_Answers_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Correct_Answers_Rewards_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Daily_Coins_ClaimsArgs = {
  where: User_Daily_Coins_Claims_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Daily_Coins_Claims_By_PkArgs = {
  claim_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Daily_Reward_CountsArgs = {
  where: User_Daily_Reward_Counts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Daily_Reward_Counts_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Email_Verification_DataArgs = {
  where: User_Email_Verification_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Email_Verification_Data_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_EventsArgs = {
  where: User_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Events_By_PkArgs = {
  event_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Got_BonusArgs = {
  where: User_Got_Bonus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Got_Bonus_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  last_datetime: Scalars['timestamp'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_InventoryArgs = {
  where: User_Inventory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Inventory_By_PkArgs = {
  user_inventory_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Inventory_TransactionsArgs = {
  where: User_Inventory_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Inventory_Transactions_By_PkArgs = {
  operation_id: Scalars['uuid'];
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Items_In_InventoryArgs = {
  where: User_Items_In_Inventory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Items_In_Inventory_By_PkArgs = {
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Lootbox_Opens_CountArgs = {
  where: User_Lootbox_Opens_Count_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Lootbox_Opens_Count_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_MarkingArgs = {
  where: User_Marking_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Marking_By_PkArgs = {
  user_marking_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_MarkingsArgs = {
  where: User_Markings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Markings_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Output_LimitsArgs = {
  where: User_Output_Limits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Output_Limits_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_ProfileArgs = {
  where: User_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Profile_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Push_TokensArgs = {
  where: User_Push_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Push_Tokens_By_PkArgs = {
  token_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Push_TopicsArgs = {
  where: User_Push_Topics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Push_Topics_By_PkArgs = {
  topic_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Referal_Levels_ParamsArgs = {
  where: User_Referal_Levels_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referal_Levels_Params_By_PkArgs = {
  referal_level: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_Referal_ParamsArgs = {
  where: User_Referal_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referal_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_ReferalsArgs = {
  where: User_Referals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referals_Balance_TransactionsArgs = {
  where: User_Referals_Balance_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referals_Balance_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Referals_Bonus_HistoryArgs = {
  where: User_Referals_Bonus_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referals_Bonus_History_By_PkArgs = {
  hisoty_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Referals_By_PkArgs = {
  owner_user_id: Scalars['uuid'];
  referal_user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_ReferralArgs = {
  where: User_Referral_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_AssociationArgs = {
  where: User_Referral_Association_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_Association_By_PkArgs = {
  offer_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_Bonus_TypeArgs = {
  where: User_Referral_Bonus_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_Bonus_Type_By_PkArgs = {
  referral_bonus_type_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_By_PkArgs = {
  owner_user_id: Scalars['uuid'];
  referral_user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_Levels_ParamsArgs = {
  where: User_Referral_Levels_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_Levels_Params_By_PkArgs = {
  referral_level: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_Offer_BonusArgs = {
  where: User_Referral_Offer_Bonus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_Offer_Bonus_By_PkArgs = {
  offer_bonus_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_OffersArgs = {
  where: User_Referral_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_ParamsArgs = {
  where: User_Referral_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referral_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Referrals_Balance_TransactionsArgs = {
  where: User_Referrals_Balance_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Referrals_Balance_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Seq_NumberArgs = {
  where: User_Seq_Number_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Seq_Number_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_SettingsArgs = {
  where: User_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Settings_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_StatisticArgs = {
  where: User_Statistic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Statistic_By_PkArgs = {
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Steam_Output_AlternativesArgs = {
  where: User_Steam_Output_Alternatives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Steam_Output_Alternatives_By_PkArgs = {
  output_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Steam_Output_WatcherArgs = {
  where: User_Steam_Output_Watcher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Steam_Output_Watcher_By_PkArgs = {
  order_custom_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Takes_Free_LootboxArgs = {
  where: User_Takes_Free_Lootbox_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Takes_Free_Lootbox_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Upgrade_ParamsArgs = {
  where: User_Upgrade_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Upgrade_Params_By_PkArgs = {
  param_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_Upgrade_ResultsArgs = {
  where: User_Upgrade_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Upgrade_Results_By_PkArgs = {
  start_item_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Vk_DataArgs = {
  where: User_Vk_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Vk_Data_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_WalletArgs = {
  where: User_Wallet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Wallet_AuditArgs = {
  where: User_Wallet_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Wallet_Audit_By_PkArgs = {
  uuid: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Wallet_By_PkArgs = {
  user_wallet_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Wallet_TransactionsArgs = {
  where: User_Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Wallet_Transactions_By_PkArgs = {
  operation_id: Scalars['uuid'];
  user_wallet_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_WhitelistArgs = {
  where: User_Whitelist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Whitelist_By_PkArgs = {
  user_data: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Utm_Visits_DataArgs = {
  where: Utm_Visits_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Utm_Visits_Data_By_PkArgs = {
  visit_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Autosending_TypesArgs = {
  where: Webpushes_Autosending_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Autosending_Types_By_PkArgs = {
  webpush_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Autosending_Types_LocalizationArgs = {
  where: Webpushes_Autosending_Types_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Autosending_Types_Localization_By_PkArgs = {
  locale: Scalars['String'];
  webpush_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_BonusesArgs = {
  where: Webpushes_Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Bonuses_OffersArgs = {
  where: Webpushes_Bonuses_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Bonuses_Offers_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_LinesArgs = {
  where: Webpushes_Lines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Lines_By_PkArgs = {
  webpush_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_SchedulingArgs = {
  where: Webpushes_Scheduling_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Scheduling_By_PkArgs = {
  user_id: Scalars['uuid'];
  webpush_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_TriggersArgs = {
  where: Webpushes_Triggers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Triggers_By_PkArgs = {
  trigger_name: Scalars['String'];
  triggers_group_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Triggers_GroupsArgs = {
  where: Webpushes_Triggers_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Webpushes_Triggers_Groups_By_PkArgs = {
  triggers_group_id: Scalars['uuid'];
  webpush_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Account_Items_NamesArgs = {
  objects: Array<Account_Items_Names_Insert_Input>;
  on_conflict?: InputMaybe<Account_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Items_Names_OneArgs = {
  object: Account_Items_Names_Insert_Input;
  on_conflict?: InputMaybe<Account_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_Items_NamesArgs = {
  objects: Array<Admin_Items_Names_Insert_Input>;
  on_conflict?: InputMaybe<Admin_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_Items_Names_OneArgs = {
  object: Admin_Items_Names_Insert_Input;
  on_conflict?: InputMaybe<Admin_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_User_ParamsArgs = {
  objects: Array<Admin_User_Params_Insert_Input>;
  on_conflict?: InputMaybe<Admin_User_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_User_Params_OneArgs = {
  object: Admin_User_Params_Insert_Input;
  on_conflict?: InputMaybe<Admin_User_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Amp_Sessions_DataArgs = {
  objects: Array<Amp_Sessions_Data_Insert_Input>;
  on_conflict?: InputMaybe<Amp_Sessions_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Amp_Sessions_Data_OneArgs = {
  object: Amp_Sessions_Data_Insert_Input;
  on_conflict?: InputMaybe<Amp_Sessions_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Banned_IpsArgs = {
  objects: Array<Banned_Ips_Insert_Input>;
  on_conflict?: InputMaybe<Banned_Ips_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Banned_Ips_OneArgs = {
  object: Banned_Ips_Insert_Input;
  on_conflict?: InputMaybe<Banned_Ips_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Banned_Users_For_MultiaccountArgs = {
  objects: Array<Banned_Users_For_Multiaccount_Insert_Input>;
  on_conflict?: InputMaybe<Banned_Users_For_Multiaccount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Banned_Users_For_Multiaccount_OneArgs = {
  object: Banned_Users_For_Multiaccount_Insert_Input;
  on_conflict?: InputMaybe<Banned_Users_For_Multiaccount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BillArgs = {
  objects: Array<Bill_Insert_Input>;
  on_conflict?: InputMaybe<Bill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bill_OneArgs = {
  object: Bill_Insert_Input;
  on_conflict?: InputMaybe<Bill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_ParamsArgs = {
  objects: Array<Bonus_Params_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Params_OneArgs = {
  object: Bonus_Params_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheel_RewardsArgs = {
  objects: Array<Bonus_Wheel_Rewards_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheel_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheel_Rewards_OneArgs = {
  object: Bonus_Wheel_Rewards_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheel_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheel_Ticket_TypeArgs = {
  objects: Array<Bonus_Wheel_Ticket_Type_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheel_Ticket_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheel_Ticket_Type_Name_LocalizationArgs = {
  objects: Array<Bonus_Wheel_Ticket_Type_Name_Localization_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheel_Ticket_Type_Name_Localization_OneArgs = {
  object: Bonus_Wheel_Ticket_Type_Name_Localization_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheel_Ticket_Type_OneArgs = {
  object: Bonus_Wheel_Ticket_Type_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheel_Ticket_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheel_User_RewardsArgs = {
  objects: Array<Bonus_Wheel_User_Rewards_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheel_User_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheel_User_Rewards_OneArgs = {
  object: Bonus_Wheel_User_Rewards_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheel_User_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheel_User_TicketsArgs = {
  objects: Array<Bonus_Wheel_User_Tickets_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheel_User_Tickets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheel_User_Tickets_OneArgs = {
  object: Bonus_Wheel_User_Tickets_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheel_User_Tickets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_WheelsArgs = {
  objects: Array<Bonus_Wheels_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Bonus_SkinsArgs = {
  objects: Array<Bonus_Wheels_Bonus_Skins_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_Bonus_Skins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Bonus_Skins_OneArgs = {
  object: Bonus_Wheels_Bonus_Skins_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_Bonus_Skins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_BonusesArgs = {
  objects: Array<Bonus_Wheels_Bonuses_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Bonuses_LocalizationArgs = {
  objects: Array<Bonus_Wheels_Bonuses_Localization_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Bonuses_Localization_OneArgs = {
  object: Bonus_Wheels_Bonuses_Localization_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Bonuses_OneArgs = {
  object: Bonus_Wheels_Bonuses_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Bonuses_ParamsArgs = {
  objects: Array<Bonus_Wheels_Bonuses_Params_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Bonuses_Params_LocalizationArgs = {
  objects: Array<Bonus_Wheels_Bonuses_Params_Localization_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Bonuses_Params_Localization_OneArgs = {
  object: Bonus_Wheels_Bonuses_Params_Localization_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Bonuses_Params_OneArgs = {
  object: Bonus_Wheels_Bonuses_Params_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_Bonuses_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_GeneratorArgs = {
  objects: Array<Bonus_Wheels_Generator_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_Generator_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Generator_BonusesArgs = {
  objects: Array<Bonus_Wheels_Generator_Bonuses_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_Generator_Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Generator_Bonuses_OneArgs = {
  object: Bonus_Wheels_Generator_Bonuses_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_Generator_Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Generator_OneArgs = {
  object: Bonus_Wheels_Generator_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_Generator_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Items_In_WheelArgs = {
  objects: Array<Bonus_Wheels_Items_In_Wheel_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_Items_In_Wheel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_Items_In_Wheel_OneArgs = {
  object: Bonus_Wheels_Items_In_Wheel_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_Items_In_Wheel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_OneArgs = {
  object: Bonus_Wheels_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_User_CheckArgs = {
  objects: Array<Bonus_Wheels_User_Check_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_User_Check_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_User_Check_OneArgs = {
  object: Bonus_Wheels_User_Check_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_User_Check_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_User_Check_ParamsArgs = {
  objects: Array<Bonus_Wheels_User_Check_Params_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_User_Check_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_User_Check_Params_OneArgs = {
  object: Bonus_Wheels_User_Check_Params_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_User_Check_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_User_OffersArgs = {
  objects: Array<Bonus_Wheels_User_Offers_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_User_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_User_Offers_OneArgs = {
  object: Bonus_Wheels_User_Offers_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_User_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_User_Offers_ParamsArgs = {
  objects: Array<Bonus_Wheels_User_Offers_Params_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_User_Offers_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_User_Offers_Params_LocalizationArgs = {
  objects: Array<Bonus_Wheels_User_Offers_Params_Localization_Insert_Input>;
  on_conflict?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_User_Offers_Params_Localization_OneArgs = {
  object: Bonus_Wheels_User_Offers_Params_Localization_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonus_Wheels_User_Offers_Params_OneArgs = {
  object: Bonus_Wheels_User_Offers_Params_Insert_Input;
  on_conflict?: InputMaybe<Bonus_Wheels_User_Offers_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BonusesArgs = {
  objects: Array<Bonuses_Insert_Input>;
  on_conflict?: InputMaybe<Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonuses_OneArgs = {
  object: Bonuses_Insert_Input;
  on_conflict?: InputMaybe<Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonuses_RegisterArgs = {
  objects: Array<Bonuses_Register_Insert_Input>;
  on_conflict?: InputMaybe<Bonuses_Register_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonuses_Register_OffersArgs = {
  objects: Array<Bonuses_Register_Offers_Insert_Input>;
  on_conflict?: InputMaybe<Bonuses_Register_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonuses_Register_Offers_OneArgs = {
  object: Bonuses_Register_Offers_Insert_Input;
  on_conflict?: InputMaybe<Bonuses_Register_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bonuses_Register_OneArgs = {
  object: Bonuses_Register_Insert_Input;
  on_conflict?: InputMaybe<Bonuses_Register_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Boosters_Set_ContentArgs = {
  objects: Array<Boosters_Set_Content_Insert_Input>;
  on_conflict?: InputMaybe<Boosters_Set_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Boosters_Set_Content_OneArgs = {
  object: Boosters_Set_Content_Insert_Input;
  on_conflict?: InputMaybe<Boosters_Set_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Boosters_SetsArgs = {
  objects: Array<Boosters_Sets_Insert_Input>;
  on_conflict?: InputMaybe<Boosters_Sets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Boosters_Sets_OneArgs = {
  object: Boosters_Sets_Insert_Input;
  on_conflict?: InputMaybe<Boosters_Sets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bot_Account_SettingsArgs = {
  objects: Array<Bot_Account_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Bot_Account_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bot_Account_Settings_OneArgs = {
  object: Bot_Account_Settings_Insert_Input;
  on_conflict?: InputMaybe<Bot_Account_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bots_Lootboxes_Opens_CountArgs = {
  objects: Array<Bots_Lootboxes_Opens_Count_Insert_Input>;
  on_conflict?: InputMaybe<Bots_Lootboxes_Opens_Count_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bots_Lootboxes_Opens_Count_OneArgs = {
  object: Bots_Lootboxes_Opens_Count_Insert_Input;
  on_conflict?: InputMaybe<Bots_Lootboxes_Opens_Count_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coins_Set_ContentArgs = {
  objects: Array<Coins_Set_Content_Insert_Input>;
  on_conflict?: InputMaybe<Coins_Set_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coins_Set_Content_OneArgs = {
  object: Coins_Set_Content_Insert_Input;
  on_conflict?: InputMaybe<Coins_Set_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coins_SetsArgs = {
  objects: Array<Coins_Sets_Insert_Input>;
  on_conflict?: InputMaybe<Coins_Sets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coins_Sets_OneArgs = {
  object: Coins_Sets_Insert_Input;
  on_conflict?: InputMaybe<Coins_Sets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CouponsArgs = {
  objects: Array<Coupons_Insert_Input>;
  on_conflict?: InputMaybe<Coupons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coupons_OneArgs = {
  object: Coupons_Insert_Input;
  on_conflict?: InputMaybe<Coupons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Csgomarket_Token_BalanceArgs = {
  objects: Array<Csgomarket_Token_Balance_Insert_Input>;
  on_conflict?: InputMaybe<Csgomarket_Token_Balance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Csgomarket_Token_Balance_OneArgs = {
  object: Csgomarket_Token_Balance_Insert_Input;
  on_conflict?: InputMaybe<Csgomarket_Token_Balance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Csgomarket_TokensArgs = {
  objects: Array<Csgomarket_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Csgomarket_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Csgomarket_Tokens_OneArgs = {
  object: Csgomarket_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Csgomarket_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_HistoryArgs = {
  objects: Array<Currency_History_Insert_Input>;
  on_conflict?: InputMaybe<Currency_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_History_OneArgs = {
  object: Currency_History_Insert_Input;
  on_conflict?: InputMaybe<Currency_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CurrencysArgs = {
  objects: Array<Currencys_Insert_Input>;
  on_conflict?: InputMaybe<Currencys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currencys_OneArgs = {
  object: Currencys_Insert_Input;
  on_conflict?: InputMaybe<Currencys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Daily_Deposit_SumArgs = {
  objects: Array<Daily_Deposit_Sum_Insert_Input>;
  on_conflict?: InputMaybe<Daily_Deposit_Sum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Daily_Deposit_Sum_OneArgs = {
  object: Daily_Deposit_Sum_Insert_Input;
  on_conflict?: InputMaybe<Daily_Deposit_Sum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Emails_From_Upgrade_QuestionArgs = {
  objects: Array<Emails_From_Upgrade_Question_Insert_Input>;
  on_conflict?: InputMaybe<Emails_From_Upgrade_Question_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Emails_From_Upgrade_Question_OneArgs = {
  object: Emails_From_Upgrade_Question_Insert_Input;
  on_conflict?: InputMaybe<Emails_From_Upgrade_Question_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Execution_MethodsArgs = {
  objects: Array<Execution_Methods_Insert_Input>;
  on_conflict?: InputMaybe<Execution_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Execution_Methods_OneArgs = {
  object: Execution_Methods_Insert_Input;
  on_conflict?: InputMaybe<Execution_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Faq_TableArgs = {
  objects: Array<Faq_Table_Insert_Input>;
  on_conflict?: InputMaybe<Faq_Table_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Faq_Table_OneArgs = {
  object: Faq_Table_Insert_Input;
  on_conflict?: InputMaybe<Faq_Table_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fixed_Items_Generator_BoostersArgs = {
  objects: Array<Fixed_Items_Generator_Boosters_Insert_Input>;
  on_conflict?: InputMaybe<Fixed_Items_Generator_Boosters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fixed_Items_Generator_Boosters_OneArgs = {
  object: Fixed_Items_Generator_Boosters_Insert_Input;
  on_conflict?: InputMaybe<Fixed_Items_Generator_Boosters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fixed_Items_Generator_ItemsArgs = {
  objects: Array<Fixed_Items_Generator_Items_Insert_Input>;
  on_conflict?: InputMaybe<Fixed_Items_Generator_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fixed_Items_Generator_Items_OneArgs = {
  object: Fixed_Items_Generator_Items_Insert_Input;
  on_conflict?: InputMaybe<Fixed_Items_Generator_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fixed_Items_Generator_LootboxesArgs = {
  objects: Array<Fixed_Items_Generator_Lootboxes_Insert_Input>;
  on_conflict?: InputMaybe<Fixed_Items_Generator_Lootboxes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fixed_Items_Generator_Lootboxes_OneArgs = {
  object: Fixed_Items_Generator_Lootboxes_Insert_Input;
  on_conflict?: InputMaybe<Fixed_Items_Generator_Lootboxes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Giveaway_MembersArgs = {
  objects: Array<Giveaway_Members_Insert_Input>;
  on_conflict?: InputMaybe<Giveaway_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Giveaway_Members_OneArgs = {
  object: Giveaway_Members_Insert_Input;
  on_conflict?: InputMaybe<Giveaway_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Giveaway_ParamsArgs = {
  objects: Array<Giveaway_Params_Insert_Input>;
  on_conflict?: InputMaybe<Giveaway_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Giveaway_Params_OneArgs = {
  object: Giveaway_Params_Insert_Input;
  on_conflict?: InputMaybe<Giveaway_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GiveawaysArgs = {
  objects: Array<Giveaways_Insert_Input>;
  on_conflict?: InputMaybe<Giveaways_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Giveaways_OneArgs = {
  object: Giveaways_Insert_Input;
  on_conflict?: InputMaybe<Giveaways_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_Items_CsgomarketArgs = {
  objects: Array<Group_Items_Csgomarket_Insert_Input>;
  on_conflict?: InputMaybe<Group_Items_Csgomarket_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_Items_Csgomarket_OneArgs = {
  object: Group_Items_Csgomarket_Insert_Input;
  on_conflict?: InputMaybe<Group_Items_Csgomarket_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_History_Group_Items_CsgomarketArgs = {
  objects: Array<History_Group_Items_Csgomarket_Insert_Input>;
  on_conflict?: InputMaybe<History_Group_Items_Csgomarket_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_History_Group_Items_Csgomarket_OneArgs = {
  object: History_Group_Items_Csgomarket_Insert_Input;
  on_conflict?: InputMaybe<History_Group_Items_Csgomarket_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_History_Items_CsgomarketArgs = {
  objects: Array<History_Items_Csgomarket_Insert_Input>;
  on_conflict?: InputMaybe<History_Items_Csgomarket_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_History_Items_Csgomarket_OneArgs = {
  object: History_Items_Csgomarket_Insert_Input;
  on_conflict?: InputMaybe<History_Items_Csgomarket_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Item_Out_TransactionsArgs = {
  objects: Array<Item_Out_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Item_Out_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Item_Out_Transactions_OneArgs = {
  object: Item_Out_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Item_Out_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ItemsArgs = {
  objects: Array<Items_Insert_Input>;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_CsgomarketArgs = {
  objects: Array<Items_Csgomarket_Insert_Input>;
  on_conflict?: InputMaybe<Items_Csgomarket_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Csgomarket_OneArgs = {
  object: Items_Csgomarket_Insert_Input;
  on_conflict?: InputMaybe<Items_Csgomarket_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_DropsArgs = {
  objects: Array<Items_Drops_Insert_Input>;
  on_conflict?: InputMaybe<Items_Drops_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Drops_OneArgs = {
  object: Items_Drops_Insert_Input;
  on_conflict?: InputMaybe<Items_Drops_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_For_UpgradeArgs = {
  objects: Array<Items_For_Upgrade_Insert_Input>;
  on_conflict?: InputMaybe<Items_For_Upgrade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_For_Upgrade_OneArgs = {
  object: Items_For_Upgrade_Insert_Input;
  on_conflict?: InputMaybe<Items_For_Upgrade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_HistoryArgs = {
  objects: Array<Items_History_Insert_Input>;
  on_conflict?: InputMaybe<Items_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_History_OneArgs = {
  object: Items_History_Insert_Input;
  on_conflict?: InputMaybe<Items_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_In_LootboxArgs = {
  objects: Array<Items_In_Lootbox_Insert_Input>;
  on_conflict?: InputMaybe<Items_In_Lootbox_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_In_Lootbox_OneArgs = {
  object: Items_In_Lootbox_Insert_Input;
  on_conflict?: InputMaybe<Items_In_Lootbox_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_In_Lootbox_ViewArgs = {
  objects: Array<Items_In_Lootbox_View_Insert_Input>;
  on_conflict?: InputMaybe<Items_In_Lootbox_View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_In_Lootbox_View_OneArgs = {
  object: Items_In_Lootbox_View_Insert_Input;
  on_conflict?: InputMaybe<Items_In_Lootbox_View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Items_NamesArgs = {
  objects: Array<Items_Items_Names_Insert_Input>;
  on_conflict?: InputMaybe<Items_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Items_Names_OneArgs = {
  object: Items_Items_Names_Insert_Input;
  on_conflict?: InputMaybe<Items_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_LocalizationArgs = {
  objects: Array<Items_Localization_Insert_Input>;
  on_conflict?: InputMaybe<Items_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Localization_OneArgs = {
  object: Items_Localization_Insert_Input;
  on_conflict?: InputMaybe<Items_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_NamesArgs = {
  objects: Array<Items_Names_Insert_Input>;
  on_conflict?: InputMaybe<Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Names_OneArgs = {
  object: Items_Names_Insert_Input;
  on_conflict?: InputMaybe<Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_OneArgs = {
  object: Items_Insert_Input;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_QualityArgs = {
  objects: Array<Items_Quality_Insert_Input>;
  on_conflict?: InputMaybe<Items_Quality_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Quality_LocalizationArgs = {
  objects: Array<Items_Quality_Localization_Insert_Input>;
  on_conflict?: InputMaybe<Items_Quality_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Quality_Localization_OneArgs = {
  object: Items_Quality_Localization_Insert_Input;
  on_conflict?: InputMaybe<Items_Quality_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Quality_OneArgs = {
  object: Items_Quality_Insert_Input;
  on_conflict?: InputMaybe<Items_Quality_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_RarityArgs = {
  objects: Array<Items_Rarity_Insert_Input>;
  on_conflict?: InputMaybe<Items_Rarity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Rarity_LocalizationArgs = {
  objects: Array<Items_Rarity_Localization_Insert_Input>;
  on_conflict?: InputMaybe<Items_Rarity_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Rarity_Localization_OneArgs = {
  object: Items_Rarity_Localization_Insert_Input;
  on_conflict?: InputMaybe<Items_Rarity_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Rarity_OneArgs = {
  object: Items_Rarity_Insert_Input;
  on_conflict?: InputMaybe<Items_Rarity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_TypeArgs = {
  objects: Array<Items_Type_Insert_Input>;
  on_conflict?: InputMaybe<Items_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Type_LocalizationArgs = {
  objects: Array<Items_Type_Localization_Insert_Input>;
  on_conflict?: InputMaybe<Items_Type_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Type_Localization_OneArgs = {
  object: Items_Type_Localization_Insert_Input;
  on_conflict?: InputMaybe<Items_Type_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Type_OneArgs = {
  object: Items_Type_Insert_Input;
  on_conflict?: InputMaybe<Items_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Weapon_LocalizationArgs = {
  objects: Array<Items_Weapon_Localization_Insert_Input>;
  on_conflict?: InputMaybe<Items_Weapon_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Weapon_Localization_OneArgs = {
  object: Items_Weapon_Localization_Insert_Input;
  on_conflict?: InputMaybe<Items_Weapon_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_WeaponsArgs = {
  objects: Array<Items_Weapons_Insert_Input>;
  on_conflict?: InputMaybe<Items_Weapons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_Weapons_OneArgs = {
  object: Items_Weapons_Insert_Input;
  on_conflict?: InputMaybe<Items_Weapons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootbox_Booster_ItemsArgs = {
  objects: Array<Lootbox_Booster_Items_Insert_Input>;
  on_conflict?: InputMaybe<Lootbox_Booster_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootbox_Booster_Items_OneArgs = {
  object: Lootbox_Booster_Items_Insert_Input;
  on_conflict?: InputMaybe<Lootbox_Booster_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootbox_Booster_LocalizationArgs = {
  objects: Array<Lootbox_Booster_Localization_Insert_Input>;
  on_conflict?: InputMaybe<Lootbox_Booster_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootbox_Booster_Localization_OneArgs = {
  object: Lootbox_Booster_Localization_Insert_Input;
  on_conflict?: InputMaybe<Lootbox_Booster_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootbox_CategoryArgs = {
  objects: Array<Lootbox_Category_Insert_Input>;
  on_conflict?: InputMaybe<Lootbox_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootbox_Category_OneArgs = {
  object: Lootbox_Category_Insert_Input;
  on_conflict?: InputMaybe<Lootbox_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootbox_Items_SetsArgs = {
  objects: Array<Lootbox_Items_Sets_Insert_Input>;
  on_conflict?: InputMaybe<Lootbox_Items_Sets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootbox_Items_Sets_OneArgs = {
  object: Lootbox_Items_Sets_Insert_Input;
  on_conflict?: InputMaybe<Lootbox_Items_Sets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootbox_ServiceArgs = {
  objects: Array<Lootbox_Service_Insert_Input>;
  on_conflict?: InputMaybe<Lootbox_Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootbox_Service_OneArgs = {
  object: Lootbox_Service_Insert_Input;
  on_conflict?: InputMaybe<Lootbox_Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LootboxesArgs = {
  objects: Array<Lootboxes_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Available_StatusesArgs = {
  objects: Array<Lootboxes_Available_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Available_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Available_Statuses_OneArgs = {
  object: Lootboxes_Available_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Available_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_BonusArgs = {
  objects: Array<Lootboxes_Bonus_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Bonus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Bonus_Generator_ItemsArgs = {
  objects: Array<Lootboxes_Bonus_Generator_Items_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Bonus_Generator_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Bonus_Generator_Items_OneArgs = {
  object: Lootboxes_Bonus_Generator_Items_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Bonus_Generator_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Bonus_Generator_ParamsArgs = {
  objects: Array<Lootboxes_Bonus_Generator_Params_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Bonus_Generator_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Bonus_Generator_Params_OneArgs = {
  object: Lootboxes_Bonus_Generator_Params_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Bonus_Generator_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Bonus_ItemsArgs = {
  objects: Array<Lootboxes_Bonus_Items_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Bonus_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Bonus_Items_OneArgs = {
  object: Lootboxes_Bonus_Items_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Bonus_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Bonus_OneArgs = {
  object: Lootboxes_Bonus_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Bonus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Bonus_ViewsArgs = {
  objects: Array<Lootboxes_Bonus_Views_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Bonus_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Bonus_Views_OneArgs = {
  object: Lootboxes_Bonus_Views_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Bonus_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Dynamic_CategoryArgs = {
  objects: Array<Lootboxes_Dynamic_Category_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Dynamic_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Dynamic_Category_LootboxesArgs = {
  objects: Array<Lootboxes_Dynamic_Category_Lootboxes_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Dynamic_Category_Lootboxes_OneArgs = {
  object: Lootboxes_Dynamic_Category_Lootboxes_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Dynamic_Category_OneArgs = {
  object: Lootboxes_Dynamic_Category_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Dynamic_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_FreeArgs = {
  objects: Array<Lootboxes_Free_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Free_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Free_CategoryArgs = {
  objects: Array<Lootboxes_Free_Category_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Free_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Free_Category_OneArgs = {
  object: Lootboxes_Free_Category_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Free_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Free_Generator_ItemsArgs = {
  objects: Array<Lootboxes_Free_Generator_Items_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Free_Generator_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Free_Generator_Items_OneArgs = {
  object: Lootboxes_Free_Generator_Items_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Free_Generator_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Free_Generator_ParamsArgs = {
  objects: Array<Lootboxes_Free_Generator_Params_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Free_Generator_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Free_Generator_Params_OneArgs = {
  object: Lootboxes_Free_Generator_Params_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Free_Generator_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Free_ItemsArgs = {
  objects: Array<Lootboxes_Free_Items_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Free_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Free_Items_OneArgs = {
  object: Lootboxes_Free_Items_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Free_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Free_OneArgs = {
  object: Lootboxes_Free_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Free_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Free_ViewsArgs = {
  objects: Array<Lootboxes_Free_Views_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Free_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Free_Views_OneArgs = {
  object: Lootboxes_Free_Views_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Free_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Generator_MechanicsArgs = {
  objects: Array<Lootboxes_Generator_Mechanics_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Generator_Mechanics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Generator_Mechanics_OneArgs = {
  object: Lootboxes_Generator_Mechanics_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Generator_Mechanics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Generator_ParamsArgs = {
  objects: Array<Lootboxes_Generator_Params_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Generator_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Generator_Params_OneArgs = {
  object: Lootboxes_Generator_Params_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Generator_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_HistoryArgs = {
  objects: Array<Lootboxes_History_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_History_OneArgs = {
  object: Lootboxes_History_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Items_NamesArgs = {
  objects: Array<Lootboxes_Items_Names_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Items_Names_OneArgs = {
  object: Lootboxes_Items_Names_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_OneArgs = {
  object: Lootboxes_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Top_DropArgs = {
  objects: Array<Lootboxes_Top_Drop_Insert_Input>;
  on_conflict?: InputMaybe<Lootboxes_Top_Drop_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lootboxes_Top_Drop_OneArgs = {
  object: Lootboxes_Top_Drop_Insert_Input;
  on_conflict?: InputMaybe<Lootboxes_Top_Drop_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Autosending_TypesArgs = {
  objects: Array<Mail_Autosending_Types_Insert_Input>;
  on_conflict?: InputMaybe<Mail_Autosending_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Autosending_Types_OneArgs = {
  object: Mail_Autosending_Types_Insert_Input;
  on_conflict?: InputMaybe<Mail_Autosending_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_BonusesArgs = {
  objects: Array<Mail_Bonuses_Insert_Input>;
  on_conflict?: InputMaybe<Mail_Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Bonuses_OffersArgs = {
  objects: Array<Mail_Bonuses_Offers_Insert_Input>;
  on_conflict?: InputMaybe<Mail_Bonuses_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Bonuses_Offers_OneArgs = {
  object: Mail_Bonuses_Offers_Insert_Input;
  on_conflict?: InputMaybe<Mail_Bonuses_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Bonuses_OneArgs = {
  object: Mail_Bonuses_Insert_Input;
  on_conflict?: InputMaybe<Mail_Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_CallbacksArgs = {
  objects: Array<Mail_Mailing_Callbacks_Insert_Input>;
  on_conflict?: InputMaybe<Mail_Mailing_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_Callbacks_OneArgs = {
  object: Mail_Mailing_Callbacks_Insert_Input;
  on_conflict?: InputMaybe<Mail_Mailing_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_LinesArgs = {
  objects: Array<Mail_Mailing_Lines_Insert_Input>;
  on_conflict?: InputMaybe<Mail_Mailing_Lines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_Lines_OneArgs = {
  object: Mail_Mailing_Lines_Insert_Input;
  on_conflict?: InputMaybe<Mail_Mailing_Lines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_ListsArgs = {
  objects: Array<Mail_Mailing_Lists_Insert_Input>;
  on_conflict?: InputMaybe<Mail_Mailing_Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_Lists_OneArgs = {
  object: Mail_Mailing_Lists_Insert_Input;
  on_conflict?: InputMaybe<Mail_Mailing_Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_SchedulingArgs = {
  objects: Array<Mail_Mailing_Scheduling_Insert_Input>;
  on_conflict?: InputMaybe<Mail_Mailing_Scheduling_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_Scheduling_OneArgs = {
  object: Mail_Mailing_Scheduling_Insert_Input;
  on_conflict?: InputMaybe<Mail_Mailing_Scheduling_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_Sending_LogArgs = {
  objects: Array<Mail_Mailing_Sending_Log_Insert_Input>;
  on_conflict?: InputMaybe<Mail_Mailing_Sending_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_Sending_Log_OneArgs = {
  object: Mail_Mailing_Sending_Log_Insert_Input;
  on_conflict?: InputMaybe<Mail_Mailing_Sending_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_TriggersArgs = {
  objects: Array<Mail_Mailing_Triggers_Insert_Input>;
  on_conflict?: InputMaybe<Mail_Mailing_Triggers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_Triggers_GroupsArgs = {
  objects: Array<Mail_Mailing_Triggers_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Mail_Mailing_Triggers_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_Triggers_Groups_OneArgs = {
  object: Mail_Mailing_Triggers_Groups_Insert_Input;
  on_conflict?: InputMaybe<Mail_Mailing_Triggers_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Mailing_Triggers_OneArgs = {
  object: Mail_Mailing_Triggers_Insert_Input;
  on_conflict?: InputMaybe<Mail_Mailing_Triggers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_TemplatesArgs = {
  objects: Array<Mail_Templates_Insert_Input>;
  on_conflict?: InputMaybe<Mail_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mail_Templates_OneArgs = {
  object: Mail_Templates_Insert_Input;
  on_conflict?: InputMaybe<Mail_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mainpage_CountersArgs = {
  objects: Array<Mainpage_Counters_Insert_Input>;
  on_conflict?: InputMaybe<Mainpage_Counters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mainpage_Counters_OneArgs = {
  object: Mainpage_Counters_Insert_Input;
  on_conflict?: InputMaybe<Mainpage_Counters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Maintenance_ModeArgs = {
  objects: Array<Maintenance_Mode_Insert_Input>;
  on_conflict?: InputMaybe<Maintenance_Mode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Maintenance_Mode_OneArgs = {
  object: Maintenance_Mode_Insert_Input;
  on_conflict?: InputMaybe<Maintenance_Mode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Market_Groups_ConnArgs = {
  objects: Array<Market_Groups_Conn_Insert_Input>;
  on_conflict?: InputMaybe<Market_Groups_Conn_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Market_Groups_Conn_OneArgs = {
  object: Market_Groups_Conn_Insert_Input;
  on_conflict?: InputMaybe<Market_Groups_Conn_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MetasArgs = {
  objects: Array<Metas_Insert_Input>;
  on_conflict?: InputMaybe<Metas_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Metas_OneArgs = {
  object: Metas_Insert_Input;
  on_conflict?: InputMaybe<Metas_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MissionsArgs = {
  objects: Array<Missions_Insert_Input>;
  on_conflict?: InputMaybe<Missions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_OneArgs = {
  object: Missions_Insert_Input;
  on_conflict?: InputMaybe<Missions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Onetime_Bonus_Case_ClaimsArgs = {
  objects: Array<Missions_Onetime_Bonus_Case_Claims_Insert_Input>;
  on_conflict?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Onetime_Bonus_Case_Claims_OneArgs = {
  object: Missions_Onetime_Bonus_Case_Claims_Insert_Input;
  on_conflict?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Onetime_ParamsArgs = {
  objects: Array<Missions_Onetime_Params_Insert_Input>;
  on_conflict?: InputMaybe<Missions_Onetime_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Onetime_Params_OneArgs = {
  object: Missions_Onetime_Params_Insert_Input;
  on_conflict?: InputMaybe<Missions_Onetime_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Onetime_Users_ProgressArgs = {
  objects: Array<Missions_Onetime_Users_Progress_Insert_Input>;
  on_conflict?: InputMaybe<Missions_Onetime_Users_Progress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Onetime_Users_Progress_OneArgs = {
  object: Missions_Onetime_Users_Progress_Insert_Input;
  on_conflict?: InputMaybe<Missions_Onetime_Users_Progress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Permanent_ParamsArgs = {
  objects: Array<Missions_Permanent_Params_Insert_Input>;
  on_conflict?: InputMaybe<Missions_Permanent_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Permanent_Params_OneArgs = {
  object: Missions_Permanent_Params_Insert_Input;
  on_conflict?: InputMaybe<Missions_Permanent_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Permanent_User_ProgressArgs = {
  objects: Array<Missions_Permanent_User_Progress_Insert_Input>;
  on_conflict?: InputMaybe<Missions_Permanent_User_Progress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Permanent_User_Progress_OneArgs = {
  object: Missions_Permanent_User_Progress_Insert_Input;
  on_conflict?: InputMaybe<Missions_Permanent_User_Progress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_TagsArgs = {
  objects: Array<Missions_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Missions_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Tags_NamesArgs = {
  objects: Array<Missions_Tags_Names_Insert_Input>;
  on_conflict?: InputMaybe<Missions_Tags_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Tags_Names_OneArgs = {
  object: Missions_Tags_Names_Insert_Input;
  on_conflict?: InputMaybe<Missions_Tags_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Tags_OneArgs = {
  object: Missions_Tags_Insert_Input;
  on_conflict?: InputMaybe<Missions_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Vk_LinksArgs = {
  objects: Array<Missions_Vk_Links_Insert_Input>;
  on_conflict?: InputMaybe<Missions_Vk_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Missions_Vk_Links_OneArgs = {
  object: Missions_Vk_Links_Insert_Input;
  on_conflict?: InputMaybe<Missions_Vk_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_Year_Competition_Boosters_RewardsArgs = {
  objects: Array<New_Year_Competition_Boosters_Rewards_Insert_Input>;
  on_conflict?: InputMaybe<New_Year_Competition_Boosters_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_Year_Competition_Boosters_Rewards_OneArgs = {
  object: New_Year_Competition_Boosters_Rewards_Insert_Input;
  on_conflict?: InputMaybe<New_Year_Competition_Boosters_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_Year_Competition_ParticipantsArgs = {
  objects: Array<New_Year_Competition_Participants_Insert_Input>;
  on_conflict?: InputMaybe<New_Year_Competition_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_Year_Competition_Participants_OneArgs = {
  object: New_Year_Competition_Participants_Insert_Input;
  on_conflict?: InputMaybe<New_Year_Competition_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_Year_Competition_Participants_PerformanceArgs = {
  objects: Array<New_Year_Competition_Participants_Performance_Insert_Input>;
  on_conflict?: InputMaybe<New_Year_Competition_Participants_Performance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_Year_Competition_Participants_Performance_OneArgs = {
  object: New_Year_Competition_Participants_Performance_Insert_Input;
  on_conflict?: InputMaybe<New_Year_Competition_Participants_Performance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_Year_Competition_TeamsArgs = {
  objects: Array<New_Year_Competition_Teams_Insert_Input>;
  on_conflict?: InputMaybe<New_Year_Competition_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_New_Year_Competition_Teams_OneArgs = {
  object: New_Year_Competition_Teams_Insert_Input;
  on_conflict?: InputMaybe<New_Year_Competition_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_TypesArgs = {
  objects: Array<Operation_Types_Insert_Input>;
  on_conflict?: InputMaybe<Operation_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Operation_Types_OneArgs = {
  object: Operation_Types_Insert_Input;
  on_conflict?: InputMaybe<Operation_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parsers_Timestamp_LogArgs = {
  objects: Array<Parsers_Timestamp_Log_Insert_Input>;
  on_conflict?: InputMaybe<Parsers_Timestamp_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parsers_Timestamp_Log_OneArgs = {
  object: Parsers_Timestamp_Log_Insert_Input;
  on_conflict?: InputMaybe<Parsers_Timestamp_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Balance_TransactionsArgs = {
  objects: Array<Partner_Balance_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Balance_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Balance_Transactions_OneArgs = {
  object: Partner_Balance_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Partner_Balance_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Offer_TypesArgs = {
  objects: Array<Partner_Offer_Types_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Offer_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Offer_Types_OneArgs = {
  object: Partner_Offer_Types_Insert_Input;
  on_conflict?: InputMaybe<Partner_Offer_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_ParamsArgs = {
  objects: Array<Partner_Params_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Params_AuditArgs = {
  objects: Array<Partner_Params_Audit_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Params_Audit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Params_Audit_OneArgs = {
  object: Partner_Params_Audit_Insert_Input;
  on_conflict?: InputMaybe<Partner_Params_Audit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Params_OneArgs = {
  object: Partner_Params_Insert_Input;
  on_conflict?: InputMaybe<Partner_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Passport_InfoArgs = {
  objects: Array<Passport_Info_Insert_Input>;
  on_conflict?: InputMaybe<Passport_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Passport_Info_OneArgs = {
  object: Passport_Info_Insert_Input;
  on_conflict?: InputMaybe<Passport_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_MethodsArgs = {
  objects: Array<Payment_Methods_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Methods_OneArgs = {
  object: Payment_Methods_Insert_Input;
  on_conflict?: InputMaybe<Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Methods_ViewArgs = {
  objects: Array<Payment_Methods_View_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Methods_View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Methods_View_OneArgs = {
  object: Payment_Methods_View_Insert_Input;
  on_conflict?: InputMaybe<Payment_Methods_View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_ProvidersArgs = {
  objects: Array<Payment_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Providers_OneArgs = {
  object: Payment_Providers_Insert_Input;
  on_conflict?: InputMaybe<Payment_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Recurring_TokensArgs = {
  objects: Array<Payment_Recurring_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Recurring_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Recurring_Tokens_OneArgs = {
  object: Payment_Recurring_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Payment_Recurring_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_RequisitesArgs = {
  objects: Array<Payment_Requisites_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Requisites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Requisites_OneArgs = {
  object: Payment_Requisites_Insert_Input;
  on_conflict?: InputMaybe<Payment_Requisites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_System_1payment_Transaction_RequestsArgs = {
  objects: Array<Payment_System_1payment_Transaction_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Payment_System_1payment_Transaction_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_System_1payment_Transaction_Requests_OneArgs = {
  object: Payment_System_1payment_Transaction_Requests_Insert_Input;
  on_conflict?: InputMaybe<Payment_System_1payment_Transaction_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_System_Free_Kassa_CallbacksArgs = {
  objects: Array<Payment_System_Free_Kassa_Callbacks_Insert_Input>;
  on_conflict?: InputMaybe<Payment_System_Free_Kassa_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_System_Free_Kassa_Callbacks_OneArgs = {
  object: Payment_System_Free_Kassa_Callbacks_Insert_Input;
  on_conflict?: InputMaybe<Payment_System_Free_Kassa_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_System_Free_Kassa_Transaction_RequestsArgs = {
  objects: Array<Payment_System_Free_Kassa_Transaction_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_System_Free_Kassa_Transaction_Requests_OneArgs = {
  object: Payment_System_Free_Kassa_Transaction_Requests_Insert_Input;
  on_conflict?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_System_Main_CallbacksArgs = {
  objects: Array<Payment_System_Main_Callbacks_Insert_Input>;
  on_conflict?: InputMaybe<Payment_System_Main_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_System_Main_Callbacks_OneArgs = {
  object: Payment_System_Main_Callbacks_Insert_Input;
  on_conflict?: InputMaybe<Payment_System_Main_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_System_Main_Transaction_RequestsArgs = {
  objects: Array<Payment_System_Main_Transaction_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Payment_System_Main_Transaction_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_System_Main_Transaction_Requests_OneArgs = {
  object: Payment_System_Main_Transaction_Requests_Insert_Input;
  on_conflict?: InputMaybe<Payment_System_Main_Transaction_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Systems_1payment_CallbacksArgs = {
  objects: Array<Payment_Systems_1payment_Callbacks_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Systems_1payment_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Systems_1payment_Callbacks_OneArgs = {
  object: Payment_Systems_1payment_Callbacks_Insert_Input;
  on_conflict?: InputMaybe<Payment_Systems_1payment_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Systems_CallbacksArgs = {
  objects: Array<Payment_Systems_Callbacks_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Systems_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Systems_Callbacks_OneArgs = {
  object: Payment_Systems_Callbacks_Insert_Input;
  on_conflict?: InputMaybe<Payment_Systems_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Systems_DataArgs = {
  objects: Array<Payment_Systems_Data_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Systems_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Systems_Data_OneArgs = {
  object: Payment_Systems_Data_Insert_Input;
  on_conflict?: InputMaybe<Payment_Systems_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Systems_MethodsArgs = {
  objects: Array<Payment_Systems_Methods_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Systems_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Systems_Methods_OneArgs = {
  object: Payment_Systems_Methods_Insert_Input;
  on_conflict?: InputMaybe<Payment_Systems_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Systems_Transaction_RequestsArgs = {
  objects: Array<Payment_Systems_Transaction_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Systems_Transaction_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Systems_Transaction_Requests_OneArgs = {
  object: Payment_Systems_Transaction_Requests_Insert_Input;
  on_conflict?: InputMaybe<Payment_Systems_Transaction_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_TransactionsArgs = {
  objects: Array<Payment_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Transactions_OneArgs = {
  object: Payment_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Payment_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Items_NamesArgs = {
  objects: Array<Profile_Items_Names_Insert_Input>;
  on_conflict?: InputMaybe<Profile_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Items_Names_OneArgs = {
  object: Profile_Items_Names_Insert_Input;
  on_conflict?: InputMaybe<Profile_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promo_LootboxesArgs = {
  objects: Array<Promo_Lootboxes_Insert_Input>;
  on_conflict?: InputMaybe<Promo_Lootboxes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promo_Lootboxes_OneArgs = {
  object: Promo_Lootboxes_Insert_Input;
  on_conflict?: InputMaybe<Promo_Lootboxes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promo_UsersArgs = {
  objects: Array<Promo_Users_Insert_Input>;
  on_conflict?: InputMaybe<Promo_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promo_Users_OneArgs = {
  object: Promo_Users_Insert_Input;
  on_conflict?: InputMaybe<Promo_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promo_ValuesArgs = {
  objects: Array<Promo_Values_Insert_Input>;
  on_conflict?: InputMaybe<Promo_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promo_Values_OneArgs = {
  object: Promo_Values_Insert_Input;
  on_conflict?: InputMaybe<Promo_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promocode_ActivationsArgs = {
  objects: Array<Promocode_Activations_Insert_Input>;
  on_conflict?: InputMaybe<Promocode_Activations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promocode_Activations_OneArgs = {
  object: Promocode_Activations_Insert_Input;
  on_conflict?: InputMaybe<Promocode_Activations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromocodesArgs = {
  objects: Array<Promocodes_Insert_Input>;
  on_conflict?: InputMaybe<Promocodes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Promocodes_OneArgs = {
  object: Promocodes_Insert_Input;
  on_conflict?: InputMaybe<Promocodes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ps_TypesArgs = {
  objects: Array<Ps_Types_Insert_Input>;
  on_conflict?: InputMaybe<Ps_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ps_Types_OneArgs = {
  object: Ps_Types_Insert_Input;
  on_conflict?: InputMaybe<Ps_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Qservice_DataArgs = {
  objects: Array<Qservice_Data_Insert_Input>;
  on_conflict?: InputMaybe<Qservice_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Qservice_Data_OneArgs = {
  object: Qservice_Data_Insert_Input;
  on_conflict?: InputMaybe<Qservice_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Bonus_Wheels_Users_OffersArgs = {
  objects: Array<Statistic_Bonus_Wheels_Users_Offers_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Bonus_Wheels_Users_Offers_OneArgs = {
  object: Statistic_Bonus_Wheels_Users_Offers_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Button_Again_PressArgs = {
  objects: Array<Statistic_Button_Again_Press_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Button_Again_Press_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Button_Again_Press_OneArgs = {
  object: Statistic_Button_Again_Press_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Button_Again_Press_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Fast_Roll_TriggerArgs = {
  objects: Array<Statistic_Fast_Roll_Trigger_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Fast_Roll_Trigger_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Fast_Roll_Trigger_OneArgs = {
  object: Statistic_Fast_Roll_Trigger_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Fast_Roll_Trigger_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Inventory_OpensArgs = {
  objects: Array<Statistic_Inventory_Opens_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Inventory_Opens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Inventory_Opens_OneArgs = {
  object: Statistic_Inventory_Opens_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Inventory_Opens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Items_DropsArgs = {
  objects: Array<Statistic_Items_Drops_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Items_Drops_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Items_Drops_OneArgs = {
  object: Statistic_Items_Drops_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Items_Drops_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Items_NamesArgs = {
  objects: Array<Statistic_Items_Names_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Items_Names_OneArgs = {
  object: Statistic_Items_Names_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Items_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Items_Output_FailArgs = {
  objects: Array<Statistic_Items_Output_Fail_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Items_Output_Fail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Items_Output_Fail_OneArgs = {
  object: Statistic_Items_Output_Fail_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Items_Output_Fail_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Items_Output_SuccessArgs = {
  objects: Array<Statistic_Items_Output_Success_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Items_Output_Success_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Items_Output_Success_OneArgs = {
  object: Statistic_Items_Output_Success_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Items_Output_Success_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Lootbox_OpensArgs = {
  objects: Array<Statistic_Lootbox_Opens_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Lootbox_Opens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Lootbox_Opens_OneArgs = {
  object: Statistic_Lootbox_Opens_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Lootbox_Opens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Lootbox_ViewsArgs = {
  objects: Array<Statistic_Lootbox_Views_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Lootbox_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Lootbox_Views_OneArgs = {
  object: Statistic_Lootbox_Views_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Lootbox_Views_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Mainpage_VisitsArgs = {
  objects: Array<Statistic_Mainpage_Visits_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Mainpage_Visits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Mainpage_Visits_OneArgs = {
  object: Statistic_Mainpage_Visits_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Mainpage_Visits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Promocode_Full_ActivationsArgs = {
  objects: Array<Statistic_Promocode_Full_Activations_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Promocode_Full_Activations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Promocode_Full_Activations_OneArgs = {
  object: Statistic_Promocode_Full_Activations_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Promocode_Full_Activations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Promocode_Success_ActivationsArgs = {
  objects: Array<Statistic_Promocode_Success_Activations_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Promocode_Success_Activations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Promocode_Success_Activations_OneArgs = {
  object: Statistic_Promocode_Success_Activations_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Promocode_Success_Activations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Sell_All_ItemsArgs = {
  objects: Array<Statistic_Sell_All_Items_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Sell_All_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Sell_All_Items_OneArgs = {
  object: Statistic_Sell_All_Items_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Sell_All_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Sell_ItemArgs = {
  objects: Array<Statistic_Sell_Item_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_Sell_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_Sell_Item_OneArgs = {
  object: Statistic_Sell_Item_Insert_Input;
  on_conflict?: InputMaybe<Statistic_Sell_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_User_Deposit_Success_CallbacksArgs = {
  objects: Array<Statistic_User_Deposit_Success_Callbacks_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_User_Deposit_Success_Callbacks_OneArgs = {
  object: Statistic_User_Deposit_Success_Callbacks_Insert_Input;
  on_conflict?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_User_Deposits_Transaction_RequestsArgs = {
  objects: Array<Statistic_User_Deposits_Transaction_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_User_Deposits_Transaction_Requests_OneArgs = {
  object: Statistic_User_Deposits_Transaction_Requests_Insert_Input;
  on_conflict?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_User_Free_Lootboxes_OpensArgs = {
  objects: Array<Statistic_User_Free_Lootboxes_Opens_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_User_Free_Lootboxes_Opens_OneArgs = {
  object: Statistic_User_Free_Lootboxes_Opens_Insert_Input;
  on_conflict?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_User_RegistrationArgs = {
  objects: Array<Statistic_User_Registration_Insert_Input>;
  on_conflict?: InputMaybe<Statistic_User_Registration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Statistic_User_Registration_OneArgs = {
  object: Statistic_User_Registration_Insert_Input;
  on_conflict?: InputMaybe<Statistic_User_Registration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StocksArgs = {
  objects: Array<Stocks_Insert_Input>;
  on_conflict?: InputMaybe<Stocks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stocks_OneArgs = {
  object: Stocks_Insert_Input;
  on_conflict?: InputMaybe<Stocks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_BadgesArgs = {
  objects: Array<Store_Offer_Badges_Insert_Input>;
  on_conflict?: InputMaybe<Store_Offer_Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_Badges_OneArgs = {
  object: Store_Offer_Badges_Insert_Input;
  on_conflict?: InputMaybe<Store_Offer_Badges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_BoostersArgs = {
  objects: Array<Store_Offer_Boosters_Insert_Input>;
  on_conflict?: InputMaybe<Store_Offer_Boosters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_Boosters_OneArgs = {
  object: Store_Offer_Boosters_Insert_Input;
  on_conflict?: InputMaybe<Store_Offer_Boosters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_CoinsArgs = {
  objects: Array<Store_Offer_Coins_Insert_Input>;
  on_conflict?: InputMaybe<Store_Offer_Coins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_Coins_OneArgs = {
  object: Store_Offer_Coins_Insert_Input;
  on_conflict?: InputMaybe<Store_Offer_Coins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_LootboxesArgs = {
  objects: Array<Store_Offer_Lootboxes_Insert_Input>;
  on_conflict?: InputMaybe<Store_Offer_Lootboxes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_Lootboxes_OneArgs = {
  object: Store_Offer_Lootboxes_Insert_Input;
  on_conflict?: InputMaybe<Store_Offer_Lootboxes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_SalesArgs = {
  objects: Array<Store_Offer_Sales_Insert_Input>;
  on_conflict?: InputMaybe<Store_Offer_Sales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_Sales_OneArgs = {
  object: Store_Offer_Sales_Insert_Input;
  on_conflict?: InputMaybe<Store_Offer_Sales_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_SkinsArgs = {
  objects: Array<Store_Offer_Skins_Insert_Input>;
  on_conflict?: InputMaybe<Store_Offer_Skins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_Skins_OneArgs = {
  object: Store_Offer_Skins_Insert_Input;
  on_conflict?: InputMaybe<Store_Offer_Skins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_TicketsArgs = {
  objects: Array<Store_Offer_Tickets_Insert_Input>;
  on_conflict?: InputMaybe<Store_Offer_Tickets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_Tickets_OneArgs = {
  object: Store_Offer_Tickets_Insert_Input;
  on_conflict?: InputMaybe<Store_Offer_Tickets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_TypesArgs = {
  objects: Array<Store_Offer_Types_Insert_Input>;
  on_conflict?: InputMaybe<Store_Offer_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offer_Types_OneArgs = {
  object: Store_Offer_Types_Insert_Input;
  on_conflict?: InputMaybe<Store_Offer_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_OffersArgs = {
  objects: Array<Store_Offers_Insert_Input>;
  on_conflict?: InputMaybe<Store_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Offers_OneArgs = {
  object: Store_Offers_Insert_Input;
  on_conflict?: InputMaybe<Store_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Survey_Data_LinksArgs = {
  objects: Array<Survey_Data_Links_Insert_Input>;
  on_conflict?: InputMaybe<Survey_Data_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Survey_Data_Links_OneArgs = {
  object: Survey_Data_Links_Insert_Input;
  on_conflict?: InputMaybe<Survey_Data_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Telegram_Admin_ParamsArgs = {
  objects: Array<Telegram_Admin_Params_Insert_Input>;
  on_conflict?: InputMaybe<Telegram_Admin_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Telegram_Admin_Params_OneArgs = {
  object: Telegram_Admin_Params_Insert_Input;
  on_conflict?: InputMaybe<Telegram_Admin_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ticket_Counts_ResultArgs = {
  objects: Array<Ticket_Counts_Result_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Ticket_Counts_Result_OneArgs = {
  object: Ticket_Counts_Result_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Tickets_Set_ContentArgs = {
  objects: Array<Tickets_Set_Content_Insert_Input>;
  on_conflict?: InputMaybe<Tickets_Set_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tickets_Set_Content_OneArgs = {
  object: Tickets_Set_Content_Insert_Input;
  on_conflict?: InputMaybe<Tickets_Set_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tickets_SetsArgs = {
  objects: Array<Tickets_Sets_Insert_Input>;
  on_conflict?: InputMaybe<Tickets_Sets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tickets_Sets_OneArgs = {
  object: Tickets_Sets_Insert_Input;
  on_conflict?: InputMaybe<Tickets_Sets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Top_Drop_StripArgs = {
  objects: Array<Top_Drop_Strip_Insert_Input>;
  on_conflict?: InputMaybe<Top_Drop_Strip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Top_Drop_Strip_OneArgs = {
  object: Top_Drop_Strip_Insert_Input;
  on_conflict?: InputMaybe<Top_Drop_Strip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransactionArgs = {
  objects: Array<Transaction_Insert_Input>;
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transaction_OneArgs = {
  object: Transaction_Insert_Input;
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Url_MappingsArgs = {
  objects: Array<Url_Mappings_Insert_Input>;
  on_conflict?: InputMaybe<Url_Mappings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Url_Mappings_OneArgs = {
  object: Url_Mappings_Insert_Input;
  on_conflict?: InputMaybe<Url_Mappings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_AccountArgs = {
  objects: Array<User_Account_Insert_Input>;
  on_conflict?: InputMaybe<User_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Account_OneArgs = {
  object: User_Account_Insert_Input;
  on_conflict?: InputMaybe<User_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_AuthArgs = {
  objects: Array<User_Auth_Insert_Input>;
  on_conflict?: InputMaybe<User_Auth_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Auth_OneArgs = {
  object: User_Auth_Insert_Input;
  on_conflict?: InputMaybe<User_Auth_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Bonus_WalletArgs = {
  objects: Array<User_Bonus_Wallet_Insert_Input>;
  on_conflict?: InputMaybe<User_Bonus_Wallet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Bonus_Wallet_OneArgs = {
  object: User_Bonus_Wallet_Insert_Input;
  on_conflict?: InputMaybe<User_Bonus_Wallet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Bonus_Wallet_TransactionsArgs = {
  objects: Array<User_Bonus_Wallet_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<User_Bonus_Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Bonus_Wallet_Transactions_OneArgs = {
  object: User_Bonus_Wallet_Transactions_Insert_Input;
  on_conflict?: InputMaybe<User_Bonus_Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Boosters_In_InventoryArgs = {
  objects: Array<User_Boosters_In_Inventory_Insert_Input>;
  on_conflict?: InputMaybe<User_Boosters_In_Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Boosters_In_Inventory_OneArgs = {
  object: User_Boosters_In_Inventory_Insert_Input;
  on_conflict?: InputMaybe<User_Boosters_In_Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_ContactsArgs = {
  objects: Array<User_Contacts_Insert_Input>;
  on_conflict?: InputMaybe<User_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Contacts_OneArgs = {
  object: User_Contacts_Insert_Input;
  on_conflict?: InputMaybe<User_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Correct_Answers_RewardsArgs = {
  objects: Array<User_Correct_Answers_Rewards_Insert_Input>;
  on_conflict?: InputMaybe<User_Correct_Answers_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Correct_Answers_Rewards_OneArgs = {
  object: User_Correct_Answers_Rewards_Insert_Input;
  on_conflict?: InputMaybe<User_Correct_Answers_Rewards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Daily_Coins_ClaimsArgs = {
  objects: Array<User_Daily_Coins_Claims_Insert_Input>;
  on_conflict?: InputMaybe<User_Daily_Coins_Claims_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Daily_Coins_Claims_OneArgs = {
  object: User_Daily_Coins_Claims_Insert_Input;
  on_conflict?: InputMaybe<User_Daily_Coins_Claims_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Daily_Reward_CountsArgs = {
  objects: Array<User_Daily_Reward_Counts_Insert_Input>;
  on_conflict?: InputMaybe<User_Daily_Reward_Counts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Daily_Reward_Counts_OneArgs = {
  object: User_Daily_Reward_Counts_Insert_Input;
  on_conflict?: InputMaybe<User_Daily_Reward_Counts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Email_Verification_DataArgs = {
  objects: Array<User_Email_Verification_Data_Insert_Input>;
  on_conflict?: InputMaybe<User_Email_Verification_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Email_Verification_Data_OneArgs = {
  object: User_Email_Verification_Data_Insert_Input;
  on_conflict?: InputMaybe<User_Email_Verification_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_EventsArgs = {
  objects: Array<User_Events_Insert_Input>;
  on_conflict?: InputMaybe<User_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Events_OneArgs = {
  object: User_Events_Insert_Input;
  on_conflict?: InputMaybe<User_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Got_BonusArgs = {
  objects: Array<User_Got_Bonus_Insert_Input>;
  on_conflict?: InputMaybe<User_Got_Bonus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Got_Bonus_OneArgs = {
  object: User_Got_Bonus_Insert_Input;
  on_conflict?: InputMaybe<User_Got_Bonus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InventoryArgs = {
  objects: Array<User_Inventory_Insert_Input>;
  on_conflict?: InputMaybe<User_Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Inventory_OneArgs = {
  object: User_Inventory_Insert_Input;
  on_conflict?: InputMaybe<User_Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Inventory_TransactionsArgs = {
  objects: Array<User_Inventory_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<User_Inventory_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Inventory_Transactions_OneArgs = {
  object: User_Inventory_Transactions_Insert_Input;
  on_conflict?: InputMaybe<User_Inventory_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Items_In_InventoryArgs = {
  objects: Array<User_Items_In_Inventory_Insert_Input>;
  on_conflict?: InputMaybe<User_Items_In_Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Items_In_Inventory_OneArgs = {
  object: User_Items_In_Inventory_Insert_Input;
  on_conflict?: InputMaybe<User_Items_In_Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Lootbox_Opens_CountArgs = {
  objects: Array<User_Lootbox_Opens_Count_Insert_Input>;
  on_conflict?: InputMaybe<User_Lootbox_Opens_Count_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Lootbox_Opens_Count_OneArgs = {
  object: User_Lootbox_Opens_Count_Insert_Input;
  on_conflict?: InputMaybe<User_Lootbox_Opens_Count_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_MarkingArgs = {
  objects: Array<User_Marking_Insert_Input>;
  on_conflict?: InputMaybe<User_Marking_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Marking_OneArgs = {
  object: User_Marking_Insert_Input;
  on_conflict?: InputMaybe<User_Marking_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_MarkingsArgs = {
  objects: Array<User_Markings_Insert_Input>;
  on_conflict?: InputMaybe<User_Markings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Markings_OneArgs = {
  object: User_Markings_Insert_Input;
  on_conflict?: InputMaybe<User_Markings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Output_LimitsArgs = {
  objects: Array<User_Output_Limits_Insert_Input>;
  on_conflict?: InputMaybe<User_Output_Limits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Output_Limits_OneArgs = {
  object: User_Output_Limits_Insert_Input;
  on_conflict?: InputMaybe<User_Output_Limits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_ProfileArgs = {
  objects: Array<User_Profile_Insert_Input>;
  on_conflict?: InputMaybe<User_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Profile_OneArgs = {
  object: User_Profile_Insert_Input;
  on_conflict?: InputMaybe<User_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Push_TokensArgs = {
  objects: Array<User_Push_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<User_Push_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Push_Tokens_OneArgs = {
  object: User_Push_Tokens_Insert_Input;
  on_conflict?: InputMaybe<User_Push_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Push_TopicsArgs = {
  objects: Array<User_Push_Topics_Insert_Input>;
  on_conflict?: InputMaybe<User_Push_Topics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Push_Topics_OneArgs = {
  object: User_Push_Topics_Insert_Input;
  on_conflict?: InputMaybe<User_Push_Topics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referal_Levels_ParamsArgs = {
  objects: Array<User_Referal_Levels_Params_Insert_Input>;
  on_conflict?: InputMaybe<User_Referal_Levels_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referal_Levels_Params_OneArgs = {
  object: User_Referal_Levels_Params_Insert_Input;
  on_conflict?: InputMaybe<User_Referal_Levels_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referal_ParamsArgs = {
  objects: Array<User_Referal_Params_Insert_Input>;
  on_conflict?: InputMaybe<User_Referal_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referal_Params_OneArgs = {
  object: User_Referal_Params_Insert_Input;
  on_conflict?: InputMaybe<User_Referal_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_ReferalsArgs = {
  objects: Array<User_Referals_Insert_Input>;
  on_conflict?: InputMaybe<User_Referals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referals_Balance_TransactionsArgs = {
  objects: Array<User_Referals_Balance_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<User_Referals_Balance_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referals_Balance_Transactions_OneArgs = {
  object: User_Referals_Balance_Transactions_Insert_Input;
  on_conflict?: InputMaybe<User_Referals_Balance_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referals_Bonus_HistoryArgs = {
  objects: Array<User_Referals_Bonus_History_Insert_Input>;
  on_conflict?: InputMaybe<User_Referals_Bonus_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referals_Bonus_History_OneArgs = {
  object: User_Referals_Bonus_History_Insert_Input;
  on_conflict?: InputMaybe<User_Referals_Bonus_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referals_OneArgs = {
  object: User_Referals_Insert_Input;
  on_conflict?: InputMaybe<User_Referals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_ReferralArgs = {
  objects: Array<User_Referral_Insert_Input>;
  on_conflict?: InputMaybe<User_Referral_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_AssociationArgs = {
  objects: Array<User_Referral_Association_Insert_Input>;
  on_conflict?: InputMaybe<User_Referral_Association_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_Association_OneArgs = {
  object: User_Referral_Association_Insert_Input;
  on_conflict?: InputMaybe<User_Referral_Association_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_Bonus_TypeArgs = {
  objects: Array<User_Referral_Bonus_Type_Insert_Input>;
  on_conflict?: InputMaybe<User_Referral_Bonus_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_Bonus_Type_OneArgs = {
  object: User_Referral_Bonus_Type_Insert_Input;
  on_conflict?: InputMaybe<User_Referral_Bonus_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_Levels_ParamsArgs = {
  objects: Array<User_Referral_Levels_Params_Insert_Input>;
  on_conflict?: InputMaybe<User_Referral_Levels_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_Levels_Params_OneArgs = {
  object: User_Referral_Levels_Params_Insert_Input;
  on_conflict?: InputMaybe<User_Referral_Levels_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_Offer_BonusArgs = {
  objects: Array<User_Referral_Offer_Bonus_Insert_Input>;
  on_conflict?: InputMaybe<User_Referral_Offer_Bonus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_Offer_Bonus_OneArgs = {
  object: User_Referral_Offer_Bonus_Insert_Input;
  on_conflict?: InputMaybe<User_Referral_Offer_Bonus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_OffersArgs = {
  objects: Array<User_Referral_Offers_Insert_Input>;
  on_conflict?: InputMaybe<User_Referral_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_Offers_OneArgs = {
  object: User_Referral_Offers_Insert_Input;
  on_conflict?: InputMaybe<User_Referral_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_OneArgs = {
  object: User_Referral_Insert_Input;
  on_conflict?: InputMaybe<User_Referral_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_ParamsArgs = {
  objects: Array<User_Referral_Params_Insert_Input>;
  on_conflict?: InputMaybe<User_Referral_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referral_Params_OneArgs = {
  object: User_Referral_Params_Insert_Input;
  on_conflict?: InputMaybe<User_Referral_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referrals_Balance_TransactionsArgs = {
  objects: Array<User_Referrals_Balance_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<User_Referrals_Balance_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Referrals_Balance_Transactions_OneArgs = {
  object: User_Referrals_Balance_Transactions_Insert_Input;
  on_conflict?: InputMaybe<User_Referrals_Balance_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Seq_NumberArgs = {
  objects: Array<User_Seq_Number_Insert_Input>;
  on_conflict?: InputMaybe<User_Seq_Number_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Seq_Number_OneArgs = {
  object: User_Seq_Number_Insert_Input;
  on_conflict?: InputMaybe<User_Seq_Number_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_SettingsArgs = {
  objects: Array<User_Settings_Insert_Input>;
  on_conflict?: InputMaybe<User_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Settings_OneArgs = {
  object: User_Settings_Insert_Input;
  on_conflict?: InputMaybe<User_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_StatisticArgs = {
  objects: Array<User_Statistic_Insert_Input>;
  on_conflict?: InputMaybe<User_Statistic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Statistic_OneArgs = {
  object: User_Statistic_Insert_Input;
  on_conflict?: InputMaybe<User_Statistic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Steam_Output_AlternativesArgs = {
  objects: Array<User_Steam_Output_Alternatives_Insert_Input>;
  on_conflict?: InputMaybe<User_Steam_Output_Alternatives_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Steam_Output_Alternatives_OneArgs = {
  object: User_Steam_Output_Alternatives_Insert_Input;
  on_conflict?: InputMaybe<User_Steam_Output_Alternatives_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Steam_Output_WatcherArgs = {
  objects: Array<User_Steam_Output_Watcher_Insert_Input>;
  on_conflict?: InputMaybe<User_Steam_Output_Watcher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Steam_Output_Watcher_OneArgs = {
  object: User_Steam_Output_Watcher_Insert_Input;
  on_conflict?: InputMaybe<User_Steam_Output_Watcher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Takes_Free_LootboxArgs = {
  objects: Array<User_Takes_Free_Lootbox_Insert_Input>;
  on_conflict?: InputMaybe<User_Takes_Free_Lootbox_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Takes_Free_Lootbox_OneArgs = {
  object: User_Takes_Free_Lootbox_Insert_Input;
  on_conflict?: InputMaybe<User_Takes_Free_Lootbox_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Upgrade_ParamsArgs = {
  objects: Array<User_Upgrade_Params_Insert_Input>;
  on_conflict?: InputMaybe<User_Upgrade_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Upgrade_Params_OneArgs = {
  object: User_Upgrade_Params_Insert_Input;
  on_conflict?: InputMaybe<User_Upgrade_Params_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Upgrade_ResultsArgs = {
  objects: Array<User_Upgrade_Results_Insert_Input>;
  on_conflict?: InputMaybe<User_Upgrade_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Upgrade_Results_OneArgs = {
  object: User_Upgrade_Results_Insert_Input;
  on_conflict?: InputMaybe<User_Upgrade_Results_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Vk_DataArgs = {
  objects: Array<User_Vk_Data_Insert_Input>;
  on_conflict?: InputMaybe<User_Vk_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Vk_Data_OneArgs = {
  object: User_Vk_Data_Insert_Input;
  on_conflict?: InputMaybe<User_Vk_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_WalletArgs = {
  objects: Array<User_Wallet_Insert_Input>;
  on_conflict?: InputMaybe<User_Wallet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Wallet_AuditArgs = {
  objects: Array<User_Wallet_Audit_Insert_Input>;
  on_conflict?: InputMaybe<User_Wallet_Audit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Wallet_Audit_OneArgs = {
  object: User_Wallet_Audit_Insert_Input;
  on_conflict?: InputMaybe<User_Wallet_Audit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Wallet_OneArgs = {
  object: User_Wallet_Insert_Input;
  on_conflict?: InputMaybe<User_Wallet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Wallet_TransactionsArgs = {
  objects: Array<User_Wallet_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<User_Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Wallet_Transactions_OneArgs = {
  object: User_Wallet_Transactions_Insert_Input;
  on_conflict?: InputMaybe<User_Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_WhitelistArgs = {
  objects: Array<User_Whitelist_Insert_Input>;
  on_conflict?: InputMaybe<User_Whitelist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Whitelist_OneArgs = {
  object: User_Whitelist_Insert_Input;
  on_conflict?: InputMaybe<User_Whitelist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Utm_Visits_DataArgs = {
  objects: Array<Utm_Visits_Data_Insert_Input>;
  on_conflict?: InputMaybe<Utm_Visits_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Utm_Visits_Data_OneArgs = {
  object: Utm_Visits_Data_Insert_Input;
  on_conflict?: InputMaybe<Utm_Visits_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Autosending_TypesArgs = {
  objects: Array<Webpushes_Autosending_Types_Insert_Input>;
  on_conflict?: InputMaybe<Webpushes_Autosending_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Autosending_Types_LocalizationArgs = {
  objects: Array<Webpushes_Autosending_Types_Localization_Insert_Input>;
  on_conflict?: InputMaybe<Webpushes_Autosending_Types_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Autosending_Types_Localization_OneArgs = {
  object: Webpushes_Autosending_Types_Localization_Insert_Input;
  on_conflict?: InputMaybe<Webpushes_Autosending_Types_Localization_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Autosending_Types_OneArgs = {
  object: Webpushes_Autosending_Types_Insert_Input;
  on_conflict?: InputMaybe<Webpushes_Autosending_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_BonusesArgs = {
  objects: Array<Webpushes_Bonuses_Insert_Input>;
  on_conflict?: InputMaybe<Webpushes_Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Bonuses_OffersArgs = {
  objects: Array<Webpushes_Bonuses_Offers_Insert_Input>;
  on_conflict?: InputMaybe<Webpushes_Bonuses_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Bonuses_Offers_OneArgs = {
  object: Webpushes_Bonuses_Offers_Insert_Input;
  on_conflict?: InputMaybe<Webpushes_Bonuses_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Bonuses_OneArgs = {
  object: Webpushes_Bonuses_Insert_Input;
  on_conflict?: InputMaybe<Webpushes_Bonuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_LinesArgs = {
  objects: Array<Webpushes_Lines_Insert_Input>;
  on_conflict?: InputMaybe<Webpushes_Lines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Lines_OneArgs = {
  object: Webpushes_Lines_Insert_Input;
  on_conflict?: InputMaybe<Webpushes_Lines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_SchedulingArgs = {
  objects: Array<Webpushes_Scheduling_Insert_Input>;
  on_conflict?: InputMaybe<Webpushes_Scheduling_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Scheduling_OneArgs = {
  object: Webpushes_Scheduling_Insert_Input;
  on_conflict?: InputMaybe<Webpushes_Scheduling_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_TriggersArgs = {
  objects: Array<Webpushes_Triggers_Insert_Input>;
  on_conflict?: InputMaybe<Webpushes_Triggers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Triggers_GroupsArgs = {
  objects: Array<Webpushes_Triggers_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Webpushes_Triggers_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Triggers_Groups_OneArgs = {
  object: Webpushes_Triggers_Groups_Insert_Input;
  on_conflict?: InputMaybe<Webpushes_Triggers_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Webpushes_Triggers_OneArgs = {
  object: Webpushes_Triggers_Insert_Input;
  on_conflict?: InputMaybe<Webpushes_Triggers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Items_NamesArgs = {
  _set?: InputMaybe<Account_Items_Names_Set_Input>;
  where: Account_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Items_Names_By_PkArgs = {
  _set?: InputMaybe<Account_Items_Names_Set_Input>;
  pk_columns: Account_Items_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Items_Names_ManyArgs = {
  updates: Array<Account_Items_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_Items_NamesArgs = {
  _set?: InputMaybe<Admin_Items_Names_Set_Input>;
  where: Admin_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_Items_Names_By_PkArgs = {
  _set?: InputMaybe<Admin_Items_Names_Set_Input>;
  pk_columns: Admin_Items_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_Items_Names_ManyArgs = {
  updates: Array<Admin_Items_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_User_ParamsArgs = {
  _inc?: InputMaybe<Admin_User_Params_Inc_Input>;
  _set?: InputMaybe<Admin_User_Params_Set_Input>;
  where: Admin_User_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_User_Params_By_PkArgs = {
  _inc?: InputMaybe<Admin_User_Params_Inc_Input>;
  _set?: InputMaybe<Admin_User_Params_Set_Input>;
  pk_columns: Admin_User_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_User_Params_ManyArgs = {
  updates: Array<Admin_User_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Amp_Sessions_DataArgs = {
  _inc?: InputMaybe<Amp_Sessions_Data_Inc_Input>;
  _set?: InputMaybe<Amp_Sessions_Data_Set_Input>;
  where: Amp_Sessions_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Amp_Sessions_Data_By_PkArgs = {
  _inc?: InputMaybe<Amp_Sessions_Data_Inc_Input>;
  _set?: InputMaybe<Amp_Sessions_Data_Set_Input>;
  pk_columns: Amp_Sessions_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Amp_Sessions_Data_ManyArgs = {
  updates: Array<Amp_Sessions_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Banned_IpsArgs = {
  _set?: InputMaybe<Banned_Ips_Set_Input>;
  where: Banned_Ips_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Banned_Ips_By_PkArgs = {
  _set?: InputMaybe<Banned_Ips_Set_Input>;
  pk_columns: Banned_Ips_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Banned_Ips_ManyArgs = {
  updates: Array<Banned_Ips_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Banned_Users_For_MultiaccountArgs = {
  _append?: InputMaybe<Banned_Users_For_Multiaccount_Append_Input>;
  _delete_at_path?: InputMaybe<Banned_Users_For_Multiaccount_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Banned_Users_For_Multiaccount_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Banned_Users_For_Multiaccount_Delete_Key_Input>;
  _inc?: InputMaybe<Banned_Users_For_Multiaccount_Inc_Input>;
  _prepend?: InputMaybe<Banned_Users_For_Multiaccount_Prepend_Input>;
  _set?: InputMaybe<Banned_Users_For_Multiaccount_Set_Input>;
  where: Banned_Users_For_Multiaccount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Banned_Users_For_Multiaccount_By_PkArgs = {
  _append?: InputMaybe<Banned_Users_For_Multiaccount_Append_Input>;
  _delete_at_path?: InputMaybe<Banned_Users_For_Multiaccount_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Banned_Users_For_Multiaccount_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Banned_Users_For_Multiaccount_Delete_Key_Input>;
  _inc?: InputMaybe<Banned_Users_For_Multiaccount_Inc_Input>;
  _prepend?: InputMaybe<Banned_Users_For_Multiaccount_Prepend_Input>;
  _set?: InputMaybe<Banned_Users_For_Multiaccount_Set_Input>;
  pk_columns: Banned_Users_For_Multiaccount_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Banned_Users_For_Multiaccount_ManyArgs = {
  updates: Array<Banned_Users_For_Multiaccount_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BillArgs = {
  _set?: InputMaybe<Bill_Set_Input>;
  where: Bill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bill_By_PkArgs = {
  _set?: InputMaybe<Bill_Set_Input>;
  pk_columns: Bill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bill_ManyArgs = {
  updates: Array<Bill_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_ParamsArgs = {
  _set?: InputMaybe<Bonus_Params_Set_Input>;
  where: Bonus_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Params_By_PkArgs = {
  _set?: InputMaybe<Bonus_Params_Set_Input>;
  pk_columns: Bonus_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Params_ManyArgs = {
  updates: Array<Bonus_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_RewardsArgs = {
  _set?: InputMaybe<Bonus_Wheel_Rewards_Set_Input>;
  where: Bonus_Wheel_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_Rewards_By_PkArgs = {
  _set?: InputMaybe<Bonus_Wheel_Rewards_Set_Input>;
  pk_columns: Bonus_Wheel_Rewards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_Rewards_ManyArgs = {
  updates: Array<Bonus_Wheel_Rewards_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_Ticket_TypeArgs = {
  _inc?: InputMaybe<Bonus_Wheel_Ticket_Type_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheel_Ticket_Type_Set_Input>;
  where: Bonus_Wheel_Ticket_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_Ticket_Type_By_PkArgs = {
  _inc?: InputMaybe<Bonus_Wheel_Ticket_Type_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheel_Ticket_Type_Set_Input>;
  pk_columns: Bonus_Wheel_Ticket_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_Ticket_Type_ManyArgs = {
  updates: Array<Bonus_Wheel_Ticket_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_Ticket_Type_Name_LocalizationArgs = {
  _set?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Set_Input>;
  where: Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_Ticket_Type_Name_Localization_By_PkArgs = {
  _set?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Set_Input>;
  pk_columns: Bonus_Wheel_Ticket_Type_Name_Localization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_Ticket_Type_Name_Localization_ManyArgs = {
  updates: Array<Bonus_Wheel_Ticket_Type_Name_Localization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_User_RewardsArgs = {
  _set?: InputMaybe<Bonus_Wheel_User_Rewards_Set_Input>;
  where: Bonus_Wheel_User_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_User_Rewards_By_PkArgs = {
  _set?: InputMaybe<Bonus_Wheel_User_Rewards_Set_Input>;
  pk_columns: Bonus_Wheel_User_Rewards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_User_Rewards_ManyArgs = {
  updates: Array<Bonus_Wheel_User_Rewards_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_User_TicketsArgs = {
  _set?: InputMaybe<Bonus_Wheel_User_Tickets_Set_Input>;
  where: Bonus_Wheel_User_Tickets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_User_Tickets_By_PkArgs = {
  _set?: InputMaybe<Bonus_Wheel_User_Tickets_Set_Input>;
  pk_columns: Bonus_Wheel_User_Tickets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheel_User_Tickets_ManyArgs = {
  updates: Array<Bonus_Wheel_User_Tickets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_WheelsArgs = {
  _set?: InputMaybe<Bonus_Wheels_Set_Input>;
  where: Bonus_Wheels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonus_SkinsArgs = {
  _inc?: InputMaybe<Bonus_Wheels_Bonus_Skins_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_Bonus_Skins_Set_Input>;
  where: Bonus_Wheels_Bonus_Skins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonus_Skins_By_PkArgs = {
  _inc?: InputMaybe<Bonus_Wheels_Bonus_Skins_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_Bonus_Skins_Set_Input>;
  pk_columns: Bonus_Wheels_Bonus_Skins_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonus_Skins_ManyArgs = {
  updates: Array<Bonus_Wheels_Bonus_Skins_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_BonusesArgs = {
  _inc?: InputMaybe<Bonus_Wheels_Bonuses_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Set_Input>;
  where: Bonus_Wheels_Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonuses_By_PkArgs = {
  _inc?: InputMaybe<Bonus_Wheels_Bonuses_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Set_Input>;
  pk_columns: Bonus_Wheels_Bonuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonuses_LocalizationArgs = {
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Set_Input>;
  where: Bonus_Wheels_Bonuses_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonuses_Localization_By_PkArgs = {
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Set_Input>;
  pk_columns: Bonus_Wheels_Bonuses_Localization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonuses_Localization_ManyArgs = {
  updates: Array<Bonus_Wheels_Bonuses_Localization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonuses_ManyArgs = {
  updates: Array<Bonus_Wheels_Bonuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonuses_ParamsArgs = {
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Params_Set_Input>;
  where: Bonus_Wheels_Bonuses_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonuses_Params_By_PkArgs = {
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Params_Set_Input>;
  pk_columns: Bonus_Wheels_Bonuses_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonuses_Params_LocalizationArgs = {
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Set_Input>;
  where: Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonuses_Params_Localization_By_PkArgs = {
  _set?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Set_Input>;
  pk_columns: Bonus_Wheels_Bonuses_Params_Localization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonuses_Params_Localization_ManyArgs = {
  updates: Array<Bonus_Wheels_Bonuses_Params_Localization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Bonuses_Params_ManyArgs = {
  updates: Array<Bonus_Wheels_Bonuses_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_By_PkArgs = {
  _set?: InputMaybe<Bonus_Wheels_Set_Input>;
  pk_columns: Bonus_Wheels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_GeneratorArgs = {
  _inc?: InputMaybe<Bonus_Wheels_Generator_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_Generator_Set_Input>;
  where: Bonus_Wheels_Generator_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Generator_BonusesArgs = {
  _set?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Set_Input>;
  where: Bonus_Wheels_Generator_Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Generator_Bonuses_By_PkArgs = {
  _set?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Set_Input>;
  pk_columns: Bonus_Wheels_Generator_Bonuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Generator_Bonuses_ManyArgs = {
  updates: Array<Bonus_Wheels_Generator_Bonuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Generator_By_PkArgs = {
  _inc?: InputMaybe<Bonus_Wheels_Generator_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_Generator_Set_Input>;
  pk_columns: Bonus_Wheels_Generator_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Generator_ManyArgs = {
  updates: Array<Bonus_Wheels_Generator_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Items_In_WheelArgs = {
  _inc?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Set_Input>;
  where: Bonus_Wheels_Items_In_Wheel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Items_In_Wheel_By_PkArgs = {
  _inc?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Set_Input>;
  pk_columns: Bonus_Wheels_Items_In_Wheel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_Items_In_Wheel_ManyArgs = {
  updates: Array<Bonus_Wheels_Items_In_Wheel_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_ManyArgs = {
  updates: Array<Bonus_Wheels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_CheckArgs = {
  _set?: InputMaybe<Bonus_Wheels_User_Check_Set_Input>;
  where: Bonus_Wheels_User_Check_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Check_By_PkArgs = {
  _set?: InputMaybe<Bonus_Wheels_User_Check_Set_Input>;
  pk_columns: Bonus_Wheels_User_Check_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Check_ManyArgs = {
  updates: Array<Bonus_Wheels_User_Check_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Check_ParamsArgs = {
  _set?: InputMaybe<Bonus_Wheels_User_Check_Params_Set_Input>;
  where: Bonus_Wheels_User_Check_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Check_Params_By_PkArgs = {
  _set?: InputMaybe<Bonus_Wheels_User_Check_Params_Set_Input>;
  pk_columns: Bonus_Wheels_User_Check_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Check_Params_ManyArgs = {
  updates: Array<Bonus_Wheels_User_Check_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_OffersArgs = {
  _inc?: InputMaybe<Bonus_Wheels_User_Offers_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_User_Offers_Set_Input>;
  where: Bonus_Wheels_User_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Offers_By_PkArgs = {
  _inc?: InputMaybe<Bonus_Wheels_User_Offers_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_User_Offers_Set_Input>;
  pk_columns: Bonus_Wheels_User_Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Offers_ManyArgs = {
  updates: Array<Bonus_Wheels_User_Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Offers_ParamsArgs = {
  _inc?: InputMaybe<Bonus_Wheels_User_Offers_Params_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_User_Offers_Params_Set_Input>;
  where: Bonus_Wheels_User_Offers_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Offers_Params_By_PkArgs = {
  _inc?: InputMaybe<Bonus_Wheels_User_Offers_Params_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_User_Offers_Params_Set_Input>;
  pk_columns: Bonus_Wheels_User_Offers_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Offers_Params_LocalizationArgs = {
  _inc?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Set_Input>;
  where: Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Offers_Params_Localization_By_PkArgs = {
  _inc?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Inc_Input>;
  _set?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Set_Input>;
  pk_columns: Bonus_Wheels_User_Offers_Params_Localization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Offers_Params_Localization_ManyArgs = {
  updates: Array<Bonus_Wheels_User_Offers_Params_Localization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonus_Wheels_User_Offers_Params_ManyArgs = {
  updates: Array<Bonus_Wheels_User_Offers_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BonusesArgs = {
  _inc?: InputMaybe<Bonuses_Inc_Input>;
  _set?: InputMaybe<Bonuses_Set_Input>;
  where: Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonuses_By_PkArgs = {
  _inc?: InputMaybe<Bonuses_Inc_Input>;
  _set?: InputMaybe<Bonuses_Set_Input>;
  pk_columns: Bonuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonuses_ManyArgs = {
  updates: Array<Bonuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonuses_RegisterArgs = {
  _inc?: InputMaybe<Bonuses_Register_Inc_Input>;
  _set?: InputMaybe<Bonuses_Register_Set_Input>;
  where: Bonuses_Register_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonuses_Register_By_PkArgs = {
  _inc?: InputMaybe<Bonuses_Register_Inc_Input>;
  _set?: InputMaybe<Bonuses_Register_Set_Input>;
  pk_columns: Bonuses_Register_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonuses_Register_ManyArgs = {
  updates: Array<Bonuses_Register_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bonuses_Register_OffersArgs = {
  _inc?: InputMaybe<Bonuses_Register_Offers_Inc_Input>;
  _set?: InputMaybe<Bonuses_Register_Offers_Set_Input>;
  where: Bonuses_Register_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bonuses_Register_Offers_By_PkArgs = {
  _inc?: InputMaybe<Bonuses_Register_Offers_Inc_Input>;
  _set?: InputMaybe<Bonuses_Register_Offers_Set_Input>;
  pk_columns: Bonuses_Register_Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bonuses_Register_Offers_ManyArgs = {
  updates: Array<Bonuses_Register_Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Boosters_Set_ContentArgs = {
  _inc?: InputMaybe<Boosters_Set_Content_Inc_Input>;
  _set?: InputMaybe<Boosters_Set_Content_Set_Input>;
  where: Boosters_Set_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Boosters_Set_Content_By_PkArgs = {
  _inc?: InputMaybe<Boosters_Set_Content_Inc_Input>;
  _set?: InputMaybe<Boosters_Set_Content_Set_Input>;
  pk_columns: Boosters_Set_Content_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Boosters_Set_Content_ManyArgs = {
  updates: Array<Boosters_Set_Content_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Boosters_SetsArgs = {
  _inc?: InputMaybe<Boosters_Sets_Inc_Input>;
  _set?: InputMaybe<Boosters_Sets_Set_Input>;
  where: Boosters_Sets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Boosters_Sets_By_PkArgs = {
  _inc?: InputMaybe<Boosters_Sets_Inc_Input>;
  _set?: InputMaybe<Boosters_Sets_Set_Input>;
  pk_columns: Boosters_Sets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Boosters_Sets_ManyArgs = {
  updates: Array<Boosters_Sets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bot_Account_SettingsArgs = {
  _inc?: InputMaybe<Bot_Account_Settings_Inc_Input>;
  _set?: InputMaybe<Bot_Account_Settings_Set_Input>;
  where: Bot_Account_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bot_Account_Settings_By_PkArgs = {
  _inc?: InputMaybe<Bot_Account_Settings_Inc_Input>;
  _set?: InputMaybe<Bot_Account_Settings_Set_Input>;
  pk_columns: Bot_Account_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bot_Account_Settings_ManyArgs = {
  updates: Array<Bot_Account_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bots_Lootboxes_Opens_CountArgs = {
  _inc?: InputMaybe<Bots_Lootboxes_Opens_Count_Inc_Input>;
  _set?: InputMaybe<Bots_Lootboxes_Opens_Count_Set_Input>;
  where: Bots_Lootboxes_Opens_Count_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bots_Lootboxes_Opens_Count_By_PkArgs = {
  _inc?: InputMaybe<Bots_Lootboxes_Opens_Count_Inc_Input>;
  _set?: InputMaybe<Bots_Lootboxes_Opens_Count_Set_Input>;
  pk_columns: Bots_Lootboxes_Opens_Count_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bots_Lootboxes_Opens_Count_ManyArgs = {
  updates: Array<Bots_Lootboxes_Opens_Count_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Coins_Set_ContentArgs = {
  _inc?: InputMaybe<Coins_Set_Content_Inc_Input>;
  _set?: InputMaybe<Coins_Set_Content_Set_Input>;
  where: Coins_Set_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coins_Set_Content_By_PkArgs = {
  _inc?: InputMaybe<Coins_Set_Content_Inc_Input>;
  _set?: InputMaybe<Coins_Set_Content_Set_Input>;
  pk_columns: Coins_Set_Content_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Coins_Set_Content_ManyArgs = {
  updates: Array<Coins_Set_Content_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Coins_SetsArgs = {
  _inc?: InputMaybe<Coins_Sets_Inc_Input>;
  _set?: InputMaybe<Coins_Sets_Set_Input>;
  where: Coins_Sets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coins_Sets_By_PkArgs = {
  _inc?: InputMaybe<Coins_Sets_Inc_Input>;
  _set?: InputMaybe<Coins_Sets_Set_Input>;
  pk_columns: Coins_Sets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Coins_Sets_ManyArgs = {
  updates: Array<Coins_Sets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_By_PkArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_ManyArgs = {
  updates: Array<Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CouponsArgs = {
  _inc?: InputMaybe<Coupons_Inc_Input>;
  _set?: InputMaybe<Coupons_Set_Input>;
  where: Coupons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coupons_By_PkArgs = {
  _inc?: InputMaybe<Coupons_Inc_Input>;
  _set?: InputMaybe<Coupons_Set_Input>;
  pk_columns: Coupons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Coupons_ManyArgs = {
  updates: Array<Coupons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Csgomarket_Token_BalanceArgs = {
  _inc?: InputMaybe<Csgomarket_Token_Balance_Inc_Input>;
  _set?: InputMaybe<Csgomarket_Token_Balance_Set_Input>;
  where: Csgomarket_Token_Balance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Csgomarket_Token_Balance_By_PkArgs = {
  _inc?: InputMaybe<Csgomarket_Token_Balance_Inc_Input>;
  _set?: InputMaybe<Csgomarket_Token_Balance_Set_Input>;
  pk_columns: Csgomarket_Token_Balance_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Csgomarket_Token_Balance_ManyArgs = {
  updates: Array<Csgomarket_Token_Balance_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Csgomarket_TokensArgs = {
  _set?: InputMaybe<Csgomarket_Tokens_Set_Input>;
  where: Csgomarket_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Csgomarket_Tokens_By_PkArgs = {
  _set?: InputMaybe<Csgomarket_Tokens_Set_Input>;
  pk_columns: Csgomarket_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Csgomarket_Tokens_ManyArgs = {
  updates: Array<Csgomarket_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_HistoryArgs = {
  _inc?: InputMaybe<Currency_History_Inc_Input>;
  _set?: InputMaybe<Currency_History_Set_Input>;
  where: Currency_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_History_By_PkArgs = {
  _inc?: InputMaybe<Currency_History_Inc_Input>;
  _set?: InputMaybe<Currency_History_Set_Input>;
  pk_columns: Currency_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_History_ManyArgs = {
  updates: Array<Currency_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CurrencysArgs = {
  _inc?: InputMaybe<Currencys_Inc_Input>;
  _set?: InputMaybe<Currencys_Set_Input>;
  where: Currencys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Currencys_By_PkArgs = {
  _inc?: InputMaybe<Currencys_Inc_Input>;
  _set?: InputMaybe<Currencys_Set_Input>;
  pk_columns: Currencys_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Currencys_ManyArgs = {
  updates: Array<Currencys_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Daily_Deposit_SumArgs = {
  _inc?: InputMaybe<Daily_Deposit_Sum_Inc_Input>;
  _set?: InputMaybe<Daily_Deposit_Sum_Set_Input>;
  where: Daily_Deposit_Sum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Daily_Deposit_Sum_By_PkArgs = {
  _inc?: InputMaybe<Daily_Deposit_Sum_Inc_Input>;
  _set?: InputMaybe<Daily_Deposit_Sum_Set_Input>;
  pk_columns: Daily_Deposit_Sum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Daily_Deposit_Sum_ManyArgs = {
  updates: Array<Daily_Deposit_Sum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Emails_From_Upgrade_QuestionArgs = {
  _inc?: InputMaybe<Emails_From_Upgrade_Question_Inc_Input>;
  _set?: InputMaybe<Emails_From_Upgrade_Question_Set_Input>;
  where: Emails_From_Upgrade_Question_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Emails_From_Upgrade_Question_By_PkArgs = {
  _inc?: InputMaybe<Emails_From_Upgrade_Question_Inc_Input>;
  _set?: InputMaybe<Emails_From_Upgrade_Question_Set_Input>;
  pk_columns: Emails_From_Upgrade_Question_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Emails_From_Upgrade_Question_ManyArgs = {
  updates: Array<Emails_From_Upgrade_Question_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Execution_MethodsArgs = {
  _inc?: InputMaybe<Execution_Methods_Inc_Input>;
  _set?: InputMaybe<Execution_Methods_Set_Input>;
  where: Execution_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Execution_Methods_By_PkArgs = {
  _inc?: InputMaybe<Execution_Methods_Inc_Input>;
  _set?: InputMaybe<Execution_Methods_Set_Input>;
  pk_columns: Execution_Methods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Execution_Methods_ManyArgs = {
  updates: Array<Execution_Methods_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_TableArgs = {
  _inc?: InputMaybe<Faq_Table_Inc_Input>;
  _set?: InputMaybe<Faq_Table_Set_Input>;
  where: Faq_Table_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_Table_By_PkArgs = {
  _inc?: InputMaybe<Faq_Table_Inc_Input>;
  _set?: InputMaybe<Faq_Table_Set_Input>;
  pk_columns: Faq_Table_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Faq_Table_ManyArgs = {
  updates: Array<Faq_Table_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fixed_Items_Generator_BoostersArgs = {
  _set?: InputMaybe<Fixed_Items_Generator_Boosters_Set_Input>;
  where: Fixed_Items_Generator_Boosters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fixed_Items_Generator_Boosters_By_PkArgs = {
  _set?: InputMaybe<Fixed_Items_Generator_Boosters_Set_Input>;
  pk_columns: Fixed_Items_Generator_Boosters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fixed_Items_Generator_Boosters_ManyArgs = {
  updates: Array<Fixed_Items_Generator_Boosters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fixed_Items_Generator_ItemsArgs = {
  _set?: InputMaybe<Fixed_Items_Generator_Items_Set_Input>;
  where: Fixed_Items_Generator_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fixed_Items_Generator_Items_By_PkArgs = {
  _set?: InputMaybe<Fixed_Items_Generator_Items_Set_Input>;
  pk_columns: Fixed_Items_Generator_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fixed_Items_Generator_Items_ManyArgs = {
  updates: Array<Fixed_Items_Generator_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fixed_Items_Generator_LootboxesArgs = {
  _inc?: InputMaybe<Fixed_Items_Generator_Lootboxes_Inc_Input>;
  _set?: InputMaybe<Fixed_Items_Generator_Lootboxes_Set_Input>;
  where: Fixed_Items_Generator_Lootboxes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fixed_Items_Generator_Lootboxes_By_PkArgs = {
  _inc?: InputMaybe<Fixed_Items_Generator_Lootboxes_Inc_Input>;
  _set?: InputMaybe<Fixed_Items_Generator_Lootboxes_Set_Input>;
  pk_columns: Fixed_Items_Generator_Lootboxes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fixed_Items_Generator_Lootboxes_ManyArgs = {
  updates: Array<Fixed_Items_Generator_Lootboxes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Giveaway_MembersArgs = {
  _set?: InputMaybe<Giveaway_Members_Set_Input>;
  where: Giveaway_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Giveaway_Members_By_PkArgs = {
  _set?: InputMaybe<Giveaway_Members_Set_Input>;
  pk_columns: Giveaway_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Giveaway_Members_ManyArgs = {
  updates: Array<Giveaway_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Giveaway_ParamsArgs = {
  _inc?: InputMaybe<Giveaway_Params_Inc_Input>;
  _set?: InputMaybe<Giveaway_Params_Set_Input>;
  where: Giveaway_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Giveaway_Params_By_PkArgs = {
  _inc?: InputMaybe<Giveaway_Params_Inc_Input>;
  _set?: InputMaybe<Giveaway_Params_Set_Input>;
  pk_columns: Giveaway_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Giveaway_Params_ManyArgs = {
  updates: Array<Giveaway_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GiveawaysArgs = {
  _inc?: InputMaybe<Giveaways_Inc_Input>;
  _set?: InputMaybe<Giveaways_Set_Input>;
  where: Giveaways_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Giveaways_By_PkArgs = {
  _inc?: InputMaybe<Giveaways_Inc_Input>;
  _set?: InputMaybe<Giveaways_Set_Input>;
  pk_columns: Giveaways_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Giveaways_ManyArgs = {
  updates: Array<Giveaways_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Items_CsgomarketArgs = {
  _set?: InputMaybe<Group_Items_Csgomarket_Set_Input>;
  where: Group_Items_Csgomarket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Items_Csgomarket_By_PkArgs = {
  _set?: InputMaybe<Group_Items_Csgomarket_Set_Input>;
  pk_columns: Group_Items_Csgomarket_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Items_Csgomarket_ManyArgs = {
  updates: Array<Group_Items_Csgomarket_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_History_Group_Items_CsgomarketArgs = {
  _inc?: InputMaybe<History_Group_Items_Csgomarket_Inc_Input>;
  _set?: InputMaybe<History_Group_Items_Csgomarket_Set_Input>;
  where: History_Group_Items_Csgomarket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_History_Group_Items_Csgomarket_By_PkArgs = {
  _inc?: InputMaybe<History_Group_Items_Csgomarket_Inc_Input>;
  _set?: InputMaybe<History_Group_Items_Csgomarket_Set_Input>;
  pk_columns: History_Group_Items_Csgomarket_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_History_Group_Items_Csgomarket_ManyArgs = {
  updates: Array<History_Group_Items_Csgomarket_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_History_Items_CsgomarketArgs = {
  _inc?: InputMaybe<History_Items_Csgomarket_Inc_Input>;
  _set?: InputMaybe<History_Items_Csgomarket_Set_Input>;
  where: History_Items_Csgomarket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_History_Items_Csgomarket_By_PkArgs = {
  _inc?: InputMaybe<History_Items_Csgomarket_Inc_Input>;
  _set?: InputMaybe<History_Items_Csgomarket_Set_Input>;
  pk_columns: History_Items_Csgomarket_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_History_Items_Csgomarket_ManyArgs = {
  updates: Array<History_Items_Csgomarket_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Item_Out_TransactionsArgs = {
  _append?: InputMaybe<Item_Out_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Item_Out_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Item_Out_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Item_Out_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Item_Out_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Item_Out_Transactions_Prepend_Input>;
  _set?: InputMaybe<Item_Out_Transactions_Set_Input>;
  where: Item_Out_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Item_Out_Transactions_By_PkArgs = {
  _append?: InputMaybe<Item_Out_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Item_Out_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Item_Out_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Item_Out_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Item_Out_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Item_Out_Transactions_Prepend_Input>;
  _set?: InputMaybe<Item_Out_Transactions_Set_Input>;
  pk_columns: Item_Out_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Item_Out_Transactions_ManyArgs = {
  updates: Array<Item_Out_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ItemsArgs = {
  _inc?: InputMaybe<Items_Inc_Input>;
  _set?: InputMaybe<Items_Set_Input>;
  where: Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_By_PkArgs = {
  _inc?: InputMaybe<Items_Inc_Input>;
  _set?: InputMaybe<Items_Set_Input>;
  pk_columns: Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_CsgomarketArgs = {
  _inc?: InputMaybe<Items_Csgomarket_Inc_Input>;
  _set?: InputMaybe<Items_Csgomarket_Set_Input>;
  where: Items_Csgomarket_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Csgomarket_By_PkArgs = {
  _inc?: InputMaybe<Items_Csgomarket_Inc_Input>;
  _set?: InputMaybe<Items_Csgomarket_Set_Input>;
  pk_columns: Items_Csgomarket_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Csgomarket_ManyArgs = {
  updates: Array<Items_Csgomarket_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_DropsArgs = {
  _inc?: InputMaybe<Items_Drops_Inc_Input>;
  _set?: InputMaybe<Items_Drops_Set_Input>;
  where: Items_Drops_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Drops_By_PkArgs = {
  _inc?: InputMaybe<Items_Drops_Inc_Input>;
  _set?: InputMaybe<Items_Drops_Set_Input>;
  pk_columns: Items_Drops_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Drops_ManyArgs = {
  updates: Array<Items_Drops_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_For_UpgradeArgs = {
  _inc?: InputMaybe<Items_For_Upgrade_Inc_Input>;
  _set?: InputMaybe<Items_For_Upgrade_Set_Input>;
  where: Items_For_Upgrade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_For_Upgrade_By_PkArgs = {
  _inc?: InputMaybe<Items_For_Upgrade_Inc_Input>;
  _set?: InputMaybe<Items_For_Upgrade_Set_Input>;
  pk_columns: Items_For_Upgrade_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_For_Upgrade_ManyArgs = {
  updates: Array<Items_For_Upgrade_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_HistoryArgs = {
  _inc?: InputMaybe<Items_History_Inc_Input>;
  _set?: InputMaybe<Items_History_Set_Input>;
  where: Items_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_History_By_PkArgs = {
  _inc?: InputMaybe<Items_History_Inc_Input>;
  _set?: InputMaybe<Items_History_Set_Input>;
  pk_columns: Items_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_History_ManyArgs = {
  updates: Array<Items_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_In_LootboxArgs = {
  _inc?: InputMaybe<Items_In_Lootbox_Inc_Input>;
  _set?: InputMaybe<Items_In_Lootbox_Set_Input>;
  where: Items_In_Lootbox_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_In_Lootbox_By_PkArgs = {
  _inc?: InputMaybe<Items_In_Lootbox_Inc_Input>;
  _set?: InputMaybe<Items_In_Lootbox_Set_Input>;
  pk_columns: Items_In_Lootbox_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_In_Lootbox_ManyArgs = {
  updates: Array<Items_In_Lootbox_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_In_Lootbox_ViewArgs = {
  _inc?: InputMaybe<Items_In_Lootbox_View_Inc_Input>;
  _set?: InputMaybe<Items_In_Lootbox_View_Set_Input>;
  where: Items_In_Lootbox_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_In_Lootbox_View_By_PkArgs = {
  _inc?: InputMaybe<Items_In_Lootbox_View_Inc_Input>;
  _set?: InputMaybe<Items_In_Lootbox_View_Set_Input>;
  pk_columns: Items_In_Lootbox_View_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_In_Lootbox_View_ManyArgs = {
  updates: Array<Items_In_Lootbox_View_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Items_NamesArgs = {
  _set?: InputMaybe<Items_Items_Names_Set_Input>;
  where: Items_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Items_Names_By_PkArgs = {
  _set?: InputMaybe<Items_Items_Names_Set_Input>;
  pk_columns: Items_Items_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Items_Names_ManyArgs = {
  updates: Array<Items_Items_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_LocalizationArgs = {
  _set?: InputMaybe<Items_Localization_Set_Input>;
  where: Items_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Localization_By_PkArgs = {
  _set?: InputMaybe<Items_Localization_Set_Input>;
  pk_columns: Items_Localization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Localization_ManyArgs = {
  updates: Array<Items_Localization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_ManyArgs = {
  updates: Array<Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_NamesArgs = {
  _set?: InputMaybe<Items_Names_Set_Input>;
  where: Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Names_By_PkArgs = {
  _set?: InputMaybe<Items_Names_Set_Input>;
  pk_columns: Items_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Names_ManyArgs = {
  updates: Array<Items_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_QualityArgs = {
  _set?: InputMaybe<Items_Quality_Set_Input>;
  where: Items_Quality_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Quality_By_PkArgs = {
  _set?: InputMaybe<Items_Quality_Set_Input>;
  pk_columns: Items_Quality_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Quality_LocalizationArgs = {
  _set?: InputMaybe<Items_Quality_Localization_Set_Input>;
  where: Items_Quality_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Quality_Localization_By_PkArgs = {
  _set?: InputMaybe<Items_Quality_Localization_Set_Input>;
  pk_columns: Items_Quality_Localization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Quality_Localization_ManyArgs = {
  updates: Array<Items_Quality_Localization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Quality_ManyArgs = {
  updates: Array<Items_Quality_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_RarityArgs = {
  _set?: InputMaybe<Items_Rarity_Set_Input>;
  where: Items_Rarity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Rarity_By_PkArgs = {
  _set?: InputMaybe<Items_Rarity_Set_Input>;
  pk_columns: Items_Rarity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Rarity_LocalizationArgs = {
  _set?: InputMaybe<Items_Rarity_Localization_Set_Input>;
  where: Items_Rarity_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Rarity_Localization_By_PkArgs = {
  _set?: InputMaybe<Items_Rarity_Localization_Set_Input>;
  pk_columns: Items_Rarity_Localization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Rarity_Localization_ManyArgs = {
  updates: Array<Items_Rarity_Localization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Rarity_ManyArgs = {
  updates: Array<Items_Rarity_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_TypeArgs = {
  _set?: InputMaybe<Items_Type_Set_Input>;
  where: Items_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Type_By_PkArgs = {
  _set?: InputMaybe<Items_Type_Set_Input>;
  pk_columns: Items_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Type_LocalizationArgs = {
  _set?: InputMaybe<Items_Type_Localization_Set_Input>;
  where: Items_Type_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Type_Localization_By_PkArgs = {
  _set?: InputMaybe<Items_Type_Localization_Set_Input>;
  pk_columns: Items_Type_Localization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Type_Localization_ManyArgs = {
  updates: Array<Items_Type_Localization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Type_ManyArgs = {
  updates: Array<Items_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Weapon_LocalizationArgs = {
  _set?: InputMaybe<Items_Weapon_Localization_Set_Input>;
  where: Items_Weapon_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Weapon_Localization_By_PkArgs = {
  _set?: InputMaybe<Items_Weapon_Localization_Set_Input>;
  pk_columns: Items_Weapon_Localization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Weapon_Localization_ManyArgs = {
  updates: Array<Items_Weapon_Localization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Items_WeaponsArgs = {
  _set?: InputMaybe<Items_Weapons_Set_Input>;
  where: Items_Weapons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Weapons_By_PkArgs = {
  _set?: InputMaybe<Items_Weapons_Set_Input>;
  pk_columns: Items_Weapons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_Weapons_ManyArgs = {
  updates: Array<Items_Weapons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Booster_ItemsArgs = {
  _inc?: InputMaybe<Lootbox_Booster_Items_Inc_Input>;
  _set?: InputMaybe<Lootbox_Booster_Items_Set_Input>;
  where: Lootbox_Booster_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Booster_Items_By_PkArgs = {
  _inc?: InputMaybe<Lootbox_Booster_Items_Inc_Input>;
  _set?: InputMaybe<Lootbox_Booster_Items_Set_Input>;
  pk_columns: Lootbox_Booster_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Booster_Items_ManyArgs = {
  updates: Array<Lootbox_Booster_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Booster_LocalizationArgs = {
  _set?: InputMaybe<Lootbox_Booster_Localization_Set_Input>;
  where: Lootbox_Booster_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Booster_Localization_By_PkArgs = {
  _set?: InputMaybe<Lootbox_Booster_Localization_Set_Input>;
  pk_columns: Lootbox_Booster_Localization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Booster_Localization_ManyArgs = {
  updates: Array<Lootbox_Booster_Localization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_CategoryArgs = {
  _inc?: InputMaybe<Lootbox_Category_Inc_Input>;
  _set?: InputMaybe<Lootbox_Category_Set_Input>;
  where: Lootbox_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Category_By_PkArgs = {
  _inc?: InputMaybe<Lootbox_Category_Inc_Input>;
  _set?: InputMaybe<Lootbox_Category_Set_Input>;
  pk_columns: Lootbox_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Category_ManyArgs = {
  updates: Array<Lootbox_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Items_SetsArgs = {
  _inc?: InputMaybe<Lootbox_Items_Sets_Inc_Input>;
  _set?: InputMaybe<Lootbox_Items_Sets_Set_Input>;
  where: Lootbox_Items_Sets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Items_Sets_By_PkArgs = {
  _inc?: InputMaybe<Lootbox_Items_Sets_Inc_Input>;
  _set?: InputMaybe<Lootbox_Items_Sets_Set_Input>;
  pk_columns: Lootbox_Items_Sets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Items_Sets_ManyArgs = {
  updates: Array<Lootbox_Items_Sets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_ServiceArgs = {
  _set?: InputMaybe<Lootbox_Service_Set_Input>;
  where: Lootbox_Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Service_By_PkArgs = {
  _set?: InputMaybe<Lootbox_Service_Set_Input>;
  pk_columns: Lootbox_Service_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootbox_Service_ManyArgs = {
  updates: Array<Lootbox_Service_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LootboxesArgs = {
  _inc?: InputMaybe<Lootboxes_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Set_Input>;
  where: Lootboxes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Available_StatusesArgs = {
  _inc?: InputMaybe<Lootboxes_Available_Statuses_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Available_Statuses_Set_Input>;
  where: Lootboxes_Available_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Available_Statuses_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Available_Statuses_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Available_Statuses_Set_Input>;
  pk_columns: Lootboxes_Available_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Available_Statuses_ManyArgs = {
  updates: Array<Lootboxes_Available_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_BonusArgs = {
  _inc?: InputMaybe<Lootboxes_Bonus_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Bonus_Set_Input>;
  where: Lootboxes_Bonus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Bonus_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Bonus_Set_Input>;
  pk_columns: Lootboxes_Bonus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_Generator_ItemsArgs = {
  _set?: InputMaybe<Lootboxes_Bonus_Generator_Items_Set_Input>;
  where: Lootboxes_Bonus_Generator_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_Generator_Items_By_PkArgs = {
  _set?: InputMaybe<Lootboxes_Bonus_Generator_Items_Set_Input>;
  pk_columns: Lootboxes_Bonus_Generator_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_Generator_Items_ManyArgs = {
  updates: Array<Lootboxes_Bonus_Generator_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_Generator_ParamsArgs = {
  _inc?: InputMaybe<Lootboxes_Bonus_Generator_Params_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Bonus_Generator_Params_Set_Input>;
  where: Lootboxes_Bonus_Generator_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_Generator_Params_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Bonus_Generator_Params_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Bonus_Generator_Params_Set_Input>;
  pk_columns: Lootboxes_Bonus_Generator_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_Generator_Params_ManyArgs = {
  updates: Array<Lootboxes_Bonus_Generator_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_ItemsArgs = {
  _inc?: InputMaybe<Lootboxes_Bonus_Items_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Bonus_Items_Set_Input>;
  where: Lootboxes_Bonus_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_Items_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Bonus_Items_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Bonus_Items_Set_Input>;
  pk_columns: Lootboxes_Bonus_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_Items_ManyArgs = {
  updates: Array<Lootboxes_Bonus_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_ManyArgs = {
  updates: Array<Lootboxes_Bonus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_ViewsArgs = {
  _inc?: InputMaybe<Lootboxes_Bonus_Views_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Bonus_Views_Set_Input>;
  where: Lootboxes_Bonus_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_Views_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Bonus_Views_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Bonus_Views_Set_Input>;
  pk_columns: Lootboxes_Bonus_Views_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Bonus_Views_ManyArgs = {
  updates: Array<Lootboxes_Bonus_Views_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Set_Input>;
  pk_columns: Lootboxes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Dynamic_CategoryArgs = {
  _inc?: InputMaybe<Lootboxes_Dynamic_Category_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Dynamic_Category_Set_Input>;
  where: Lootboxes_Dynamic_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Dynamic_Category_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Dynamic_Category_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Dynamic_Category_Set_Input>;
  pk_columns: Lootboxes_Dynamic_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Dynamic_Category_LootboxesArgs = {
  _inc?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Set_Input>;
  where: Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Dynamic_Category_Lootboxes_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Set_Input>;
  pk_columns: Lootboxes_Dynamic_Category_Lootboxes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Dynamic_Category_Lootboxes_ManyArgs = {
  updates: Array<Lootboxes_Dynamic_Category_Lootboxes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Dynamic_Category_ManyArgs = {
  updates: Array<Lootboxes_Dynamic_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_FreeArgs = {
  _inc?: InputMaybe<Lootboxes_Free_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Free_Set_Input>;
  where: Lootboxes_Free_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Free_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Free_Set_Input>;
  pk_columns: Lootboxes_Free_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_CategoryArgs = {
  _inc?: InputMaybe<Lootboxes_Free_Category_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Free_Category_Set_Input>;
  where: Lootboxes_Free_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Category_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Free_Category_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Free_Category_Set_Input>;
  pk_columns: Lootboxes_Free_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Category_ManyArgs = {
  updates: Array<Lootboxes_Free_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Generator_ItemsArgs = {
  _set?: InputMaybe<Lootboxes_Free_Generator_Items_Set_Input>;
  where: Lootboxes_Free_Generator_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Generator_Items_By_PkArgs = {
  _set?: InputMaybe<Lootboxes_Free_Generator_Items_Set_Input>;
  pk_columns: Lootboxes_Free_Generator_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Generator_Items_ManyArgs = {
  updates: Array<Lootboxes_Free_Generator_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Generator_ParamsArgs = {
  _inc?: InputMaybe<Lootboxes_Free_Generator_Params_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Free_Generator_Params_Set_Input>;
  where: Lootboxes_Free_Generator_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Generator_Params_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Free_Generator_Params_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Free_Generator_Params_Set_Input>;
  pk_columns: Lootboxes_Free_Generator_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Generator_Params_ManyArgs = {
  updates: Array<Lootboxes_Free_Generator_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_ItemsArgs = {
  _inc?: InputMaybe<Lootboxes_Free_Items_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Free_Items_Set_Input>;
  where: Lootboxes_Free_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Items_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Free_Items_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Free_Items_Set_Input>;
  pk_columns: Lootboxes_Free_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Items_ManyArgs = {
  updates: Array<Lootboxes_Free_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_ManyArgs = {
  updates: Array<Lootboxes_Free_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_ViewsArgs = {
  _inc?: InputMaybe<Lootboxes_Free_Views_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Free_Views_Set_Input>;
  where: Lootboxes_Free_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Views_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Free_Views_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Free_Views_Set_Input>;
  pk_columns: Lootboxes_Free_Views_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Free_Views_ManyArgs = {
  updates: Array<Lootboxes_Free_Views_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Generator_MechanicsArgs = {
  _inc?: InputMaybe<Lootboxes_Generator_Mechanics_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Generator_Mechanics_Set_Input>;
  where: Lootboxes_Generator_Mechanics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Generator_Mechanics_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Generator_Mechanics_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Generator_Mechanics_Set_Input>;
  pk_columns: Lootboxes_Generator_Mechanics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Generator_Mechanics_ManyArgs = {
  updates: Array<Lootboxes_Generator_Mechanics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Generator_ParamsArgs = {
  _append?: InputMaybe<Lootboxes_Generator_Params_Append_Input>;
  _delete_at_path?: InputMaybe<Lootboxes_Generator_Params_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Lootboxes_Generator_Params_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Lootboxes_Generator_Params_Delete_Key_Input>;
  _inc?: InputMaybe<Lootboxes_Generator_Params_Inc_Input>;
  _prepend?: InputMaybe<Lootboxes_Generator_Params_Prepend_Input>;
  _set?: InputMaybe<Lootboxes_Generator_Params_Set_Input>;
  where: Lootboxes_Generator_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Generator_Params_By_PkArgs = {
  _append?: InputMaybe<Lootboxes_Generator_Params_Append_Input>;
  _delete_at_path?: InputMaybe<Lootboxes_Generator_Params_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Lootboxes_Generator_Params_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Lootboxes_Generator_Params_Delete_Key_Input>;
  _inc?: InputMaybe<Lootboxes_Generator_Params_Inc_Input>;
  _prepend?: InputMaybe<Lootboxes_Generator_Params_Prepend_Input>;
  _set?: InputMaybe<Lootboxes_Generator_Params_Set_Input>;
  pk_columns: Lootboxes_Generator_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Generator_Params_ManyArgs = {
  updates: Array<Lootboxes_Generator_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_HistoryArgs = {
  _set?: InputMaybe<Lootboxes_History_Set_Input>;
  where: Lootboxes_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_History_By_PkArgs = {
  _set?: InputMaybe<Lootboxes_History_Set_Input>;
  pk_columns: Lootboxes_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_History_ManyArgs = {
  updates: Array<Lootboxes_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Items_NamesArgs = {
  _set?: InputMaybe<Lootboxes_Items_Names_Set_Input>;
  where: Lootboxes_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Items_Names_By_PkArgs = {
  _set?: InputMaybe<Lootboxes_Items_Names_Set_Input>;
  pk_columns: Lootboxes_Items_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Items_Names_ManyArgs = {
  updates: Array<Lootboxes_Items_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_ManyArgs = {
  updates: Array<Lootboxes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Top_DropArgs = {
  _inc?: InputMaybe<Lootboxes_Top_Drop_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Top_Drop_Set_Input>;
  where: Lootboxes_Top_Drop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Top_Drop_By_PkArgs = {
  _inc?: InputMaybe<Lootboxes_Top_Drop_Inc_Input>;
  _set?: InputMaybe<Lootboxes_Top_Drop_Set_Input>;
  pk_columns: Lootboxes_Top_Drop_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lootboxes_Top_Drop_ManyArgs = {
  updates: Array<Lootboxes_Top_Drop_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Autosending_TypesArgs = {
  _append?: InputMaybe<Mail_Autosending_Types_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Autosending_Types_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Autosending_Types_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Autosending_Types_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Autosending_Types_Prepend_Input>;
  _set?: InputMaybe<Mail_Autosending_Types_Set_Input>;
  where: Mail_Autosending_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Autosending_Types_By_PkArgs = {
  _append?: InputMaybe<Mail_Autosending_Types_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Autosending_Types_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Autosending_Types_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Autosending_Types_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Autosending_Types_Prepend_Input>;
  _set?: InputMaybe<Mail_Autosending_Types_Set_Input>;
  pk_columns: Mail_Autosending_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Autosending_Types_ManyArgs = {
  updates: Array<Mail_Autosending_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_BonusesArgs = {
  _append?: InputMaybe<Mail_Bonuses_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Bonuses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Bonuses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Bonuses_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Bonuses_Prepend_Input>;
  _set?: InputMaybe<Mail_Bonuses_Set_Input>;
  where: Mail_Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Bonuses_By_PkArgs = {
  _append?: InputMaybe<Mail_Bonuses_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Bonuses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Bonuses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Bonuses_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Bonuses_Prepend_Input>;
  _set?: InputMaybe<Mail_Bonuses_Set_Input>;
  pk_columns: Mail_Bonuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Bonuses_ManyArgs = {
  updates: Array<Mail_Bonuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Bonuses_OffersArgs = {
  _inc?: InputMaybe<Mail_Bonuses_Offers_Inc_Input>;
  _set?: InputMaybe<Mail_Bonuses_Offers_Set_Input>;
  where: Mail_Bonuses_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Bonuses_Offers_By_PkArgs = {
  _inc?: InputMaybe<Mail_Bonuses_Offers_Inc_Input>;
  _set?: InputMaybe<Mail_Bonuses_Offers_Set_Input>;
  pk_columns: Mail_Bonuses_Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Bonuses_Offers_ManyArgs = {
  updates: Array<Mail_Bonuses_Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_CallbacksArgs = {
  _append?: InputMaybe<Mail_Mailing_Callbacks_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Mailing_Callbacks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Mailing_Callbacks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Mailing_Callbacks_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Mailing_Callbacks_Prepend_Input>;
  _set?: InputMaybe<Mail_Mailing_Callbacks_Set_Input>;
  where: Mail_Mailing_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Callbacks_By_PkArgs = {
  _append?: InputMaybe<Mail_Mailing_Callbacks_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Mailing_Callbacks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Mailing_Callbacks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Mailing_Callbacks_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Mailing_Callbacks_Prepend_Input>;
  _set?: InputMaybe<Mail_Mailing_Callbacks_Set_Input>;
  pk_columns: Mail_Mailing_Callbacks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Callbacks_ManyArgs = {
  updates: Array<Mail_Mailing_Callbacks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_LinesArgs = {
  _inc?: InputMaybe<Mail_Mailing_Lines_Inc_Input>;
  _set?: InputMaybe<Mail_Mailing_Lines_Set_Input>;
  where: Mail_Mailing_Lines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Lines_By_PkArgs = {
  _inc?: InputMaybe<Mail_Mailing_Lines_Inc_Input>;
  _set?: InputMaybe<Mail_Mailing_Lines_Set_Input>;
  pk_columns: Mail_Mailing_Lines_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Lines_ManyArgs = {
  updates: Array<Mail_Mailing_Lines_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_ListsArgs = {
  _inc?: InputMaybe<Mail_Mailing_Lists_Inc_Input>;
  _set?: InputMaybe<Mail_Mailing_Lists_Set_Input>;
  where: Mail_Mailing_Lists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Lists_By_PkArgs = {
  _inc?: InputMaybe<Mail_Mailing_Lists_Inc_Input>;
  _set?: InputMaybe<Mail_Mailing_Lists_Set_Input>;
  pk_columns: Mail_Mailing_Lists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Lists_ManyArgs = {
  updates: Array<Mail_Mailing_Lists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_SchedulingArgs = {
  _append?: InputMaybe<Mail_Mailing_Scheduling_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Mailing_Scheduling_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Mailing_Scheduling_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Mailing_Scheduling_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Mailing_Scheduling_Prepend_Input>;
  _set?: InputMaybe<Mail_Mailing_Scheduling_Set_Input>;
  where: Mail_Mailing_Scheduling_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Scheduling_By_PkArgs = {
  _append?: InputMaybe<Mail_Mailing_Scheduling_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Mailing_Scheduling_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Mailing_Scheduling_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Mailing_Scheduling_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Mailing_Scheduling_Prepend_Input>;
  _set?: InputMaybe<Mail_Mailing_Scheduling_Set_Input>;
  pk_columns: Mail_Mailing_Scheduling_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Scheduling_ManyArgs = {
  updates: Array<Mail_Mailing_Scheduling_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Sending_LogArgs = {
  _append?: InputMaybe<Mail_Mailing_Sending_Log_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Mailing_Sending_Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Mailing_Sending_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Mailing_Sending_Log_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Mailing_Sending_Log_Prepend_Input>;
  _set?: InputMaybe<Mail_Mailing_Sending_Log_Set_Input>;
  where: Mail_Mailing_Sending_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Sending_Log_By_PkArgs = {
  _append?: InputMaybe<Mail_Mailing_Sending_Log_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Mailing_Sending_Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Mailing_Sending_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Mailing_Sending_Log_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Mailing_Sending_Log_Prepend_Input>;
  _set?: InputMaybe<Mail_Mailing_Sending_Log_Set_Input>;
  pk_columns: Mail_Mailing_Sending_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Sending_Log_ManyArgs = {
  updates: Array<Mail_Mailing_Sending_Log_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_TriggersArgs = {
  _set?: InputMaybe<Mail_Mailing_Triggers_Set_Input>;
  where: Mail_Mailing_Triggers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Triggers_By_PkArgs = {
  _set?: InputMaybe<Mail_Mailing_Triggers_Set_Input>;
  pk_columns: Mail_Mailing_Triggers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Triggers_GroupsArgs = {
  _set?: InputMaybe<Mail_Mailing_Triggers_Groups_Set_Input>;
  where: Mail_Mailing_Triggers_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Triggers_Groups_By_PkArgs = {
  _set?: InputMaybe<Mail_Mailing_Triggers_Groups_Set_Input>;
  pk_columns: Mail_Mailing_Triggers_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Triggers_Groups_ManyArgs = {
  updates: Array<Mail_Mailing_Triggers_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Mailing_Triggers_ManyArgs = {
  updates: Array<Mail_Mailing_Triggers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_TemplatesArgs = {
  _append?: InputMaybe<Mail_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Templates_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Templates_Prepend_Input>;
  _set?: InputMaybe<Mail_Templates_Set_Input>;
  where: Mail_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Templates_By_PkArgs = {
  _append?: InputMaybe<Mail_Templates_Append_Input>;
  _delete_at_path?: InputMaybe<Mail_Templates_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Mail_Templates_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Mail_Templates_Delete_Key_Input>;
  _prepend?: InputMaybe<Mail_Templates_Prepend_Input>;
  _set?: InputMaybe<Mail_Templates_Set_Input>;
  pk_columns: Mail_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mail_Templates_ManyArgs = {
  updates: Array<Mail_Templates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mainpage_CountersArgs = {
  _inc?: InputMaybe<Mainpage_Counters_Inc_Input>;
  _set?: InputMaybe<Mainpage_Counters_Set_Input>;
  where: Mainpage_Counters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mainpage_Counters_By_PkArgs = {
  _inc?: InputMaybe<Mainpage_Counters_Inc_Input>;
  _set?: InputMaybe<Mainpage_Counters_Set_Input>;
  pk_columns: Mainpage_Counters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mainpage_Counters_ManyArgs = {
  updates: Array<Mainpage_Counters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Maintenance_ModeArgs = {
  _inc?: InputMaybe<Maintenance_Mode_Inc_Input>;
  _set?: InputMaybe<Maintenance_Mode_Set_Input>;
  where: Maintenance_Mode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Maintenance_Mode_By_PkArgs = {
  _inc?: InputMaybe<Maintenance_Mode_Inc_Input>;
  _set?: InputMaybe<Maintenance_Mode_Set_Input>;
  pk_columns: Maintenance_Mode_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Maintenance_Mode_ManyArgs = {
  updates: Array<Maintenance_Mode_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Market_Groups_ConnArgs = {
  _inc?: InputMaybe<Market_Groups_Conn_Inc_Input>;
  _set?: InputMaybe<Market_Groups_Conn_Set_Input>;
  where: Market_Groups_Conn_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Market_Groups_Conn_By_PkArgs = {
  _inc?: InputMaybe<Market_Groups_Conn_Inc_Input>;
  _set?: InputMaybe<Market_Groups_Conn_Set_Input>;
  pk_columns: Market_Groups_Conn_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Market_Groups_Conn_ManyArgs = {
  updates: Array<Market_Groups_Conn_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MetasArgs = {
  _append?: InputMaybe<Metas_Append_Input>;
  _delete_at_path?: InputMaybe<Metas_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Metas_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Metas_Delete_Key_Input>;
  _prepend?: InputMaybe<Metas_Prepend_Input>;
  _set?: InputMaybe<Metas_Set_Input>;
  where: Metas_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Metas_By_PkArgs = {
  _append?: InputMaybe<Metas_Append_Input>;
  _delete_at_path?: InputMaybe<Metas_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Metas_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Metas_Delete_Key_Input>;
  _prepend?: InputMaybe<Metas_Prepend_Input>;
  _set?: InputMaybe<Metas_Set_Input>;
  pk_columns: Metas_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Metas_ManyArgs = {
  updates: Array<Metas_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MissionsArgs = {
  _append?: InputMaybe<Missions_Append_Input>;
  _delete_at_path?: InputMaybe<Missions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Missions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Missions_Delete_Key_Input>;
  _inc?: InputMaybe<Missions_Inc_Input>;
  _prepend?: InputMaybe<Missions_Prepend_Input>;
  _set?: InputMaybe<Missions_Set_Input>;
  where: Missions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_By_PkArgs = {
  _append?: InputMaybe<Missions_Append_Input>;
  _delete_at_path?: InputMaybe<Missions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Missions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Missions_Delete_Key_Input>;
  _inc?: InputMaybe<Missions_Inc_Input>;
  _prepend?: InputMaybe<Missions_Prepend_Input>;
  _set?: InputMaybe<Missions_Set_Input>;
  pk_columns: Missions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_ManyArgs = {
  updates: Array<Missions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Onetime_Bonus_Case_ClaimsArgs = {
  _set?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_Set_Input>;
  where: Missions_Onetime_Bonus_Case_Claims_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Onetime_Bonus_Case_Claims_By_PkArgs = {
  _set?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_Set_Input>;
  pk_columns: Missions_Onetime_Bonus_Case_Claims_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Onetime_Bonus_Case_Claims_ManyArgs = {
  updates: Array<Missions_Onetime_Bonus_Case_Claims_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Onetime_ParamsArgs = {
  _append?: InputMaybe<Missions_Onetime_Params_Append_Input>;
  _delete_at_path?: InputMaybe<Missions_Onetime_Params_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Missions_Onetime_Params_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Missions_Onetime_Params_Delete_Key_Input>;
  _inc?: InputMaybe<Missions_Onetime_Params_Inc_Input>;
  _prepend?: InputMaybe<Missions_Onetime_Params_Prepend_Input>;
  _set?: InputMaybe<Missions_Onetime_Params_Set_Input>;
  where: Missions_Onetime_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Onetime_Params_By_PkArgs = {
  _append?: InputMaybe<Missions_Onetime_Params_Append_Input>;
  _delete_at_path?: InputMaybe<Missions_Onetime_Params_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Missions_Onetime_Params_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Missions_Onetime_Params_Delete_Key_Input>;
  _inc?: InputMaybe<Missions_Onetime_Params_Inc_Input>;
  _prepend?: InputMaybe<Missions_Onetime_Params_Prepend_Input>;
  _set?: InputMaybe<Missions_Onetime_Params_Set_Input>;
  pk_columns: Missions_Onetime_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Onetime_Params_ManyArgs = {
  updates: Array<Missions_Onetime_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Onetime_Users_ProgressArgs = {
  _inc?: InputMaybe<Missions_Onetime_Users_Progress_Inc_Input>;
  _set?: InputMaybe<Missions_Onetime_Users_Progress_Set_Input>;
  where: Missions_Onetime_Users_Progress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Onetime_Users_Progress_By_PkArgs = {
  _inc?: InputMaybe<Missions_Onetime_Users_Progress_Inc_Input>;
  _set?: InputMaybe<Missions_Onetime_Users_Progress_Set_Input>;
  pk_columns: Missions_Onetime_Users_Progress_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Onetime_Users_Progress_ManyArgs = {
  updates: Array<Missions_Onetime_Users_Progress_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Permanent_ParamsArgs = {
  _append?: InputMaybe<Missions_Permanent_Params_Append_Input>;
  _delete_at_path?: InputMaybe<Missions_Permanent_Params_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Missions_Permanent_Params_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Missions_Permanent_Params_Delete_Key_Input>;
  _inc?: InputMaybe<Missions_Permanent_Params_Inc_Input>;
  _prepend?: InputMaybe<Missions_Permanent_Params_Prepend_Input>;
  _set?: InputMaybe<Missions_Permanent_Params_Set_Input>;
  where: Missions_Permanent_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Permanent_Params_By_PkArgs = {
  _append?: InputMaybe<Missions_Permanent_Params_Append_Input>;
  _delete_at_path?: InputMaybe<Missions_Permanent_Params_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Missions_Permanent_Params_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Missions_Permanent_Params_Delete_Key_Input>;
  _inc?: InputMaybe<Missions_Permanent_Params_Inc_Input>;
  _prepend?: InputMaybe<Missions_Permanent_Params_Prepend_Input>;
  _set?: InputMaybe<Missions_Permanent_Params_Set_Input>;
  pk_columns: Missions_Permanent_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Permanent_Params_ManyArgs = {
  updates: Array<Missions_Permanent_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Permanent_User_ProgressArgs = {
  _inc?: InputMaybe<Missions_Permanent_User_Progress_Inc_Input>;
  _set?: InputMaybe<Missions_Permanent_User_Progress_Set_Input>;
  where: Missions_Permanent_User_Progress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Permanent_User_Progress_By_PkArgs = {
  _inc?: InputMaybe<Missions_Permanent_User_Progress_Inc_Input>;
  _set?: InputMaybe<Missions_Permanent_User_Progress_Set_Input>;
  pk_columns: Missions_Permanent_User_Progress_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Permanent_User_Progress_ManyArgs = {
  updates: Array<Missions_Permanent_User_Progress_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_TagsArgs = {
  _set?: InputMaybe<Missions_Tags_Set_Input>;
  where: Missions_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Tags_By_PkArgs = {
  _set?: InputMaybe<Missions_Tags_Set_Input>;
  pk_columns: Missions_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Tags_ManyArgs = {
  updates: Array<Missions_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Tags_NamesArgs = {
  _set?: InputMaybe<Missions_Tags_Names_Set_Input>;
  where: Missions_Tags_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Tags_Names_By_PkArgs = {
  _set?: InputMaybe<Missions_Tags_Names_Set_Input>;
  pk_columns: Missions_Tags_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Tags_Names_ManyArgs = {
  updates: Array<Missions_Tags_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Vk_LinksArgs = {
  _inc?: InputMaybe<Missions_Vk_Links_Inc_Input>;
  _set?: InputMaybe<Missions_Vk_Links_Set_Input>;
  where: Missions_Vk_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Vk_Links_By_PkArgs = {
  _inc?: InputMaybe<Missions_Vk_Links_Inc_Input>;
  _set?: InputMaybe<Missions_Vk_Links_Set_Input>;
  pk_columns: Missions_Vk_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Missions_Vk_Links_ManyArgs = {
  updates: Array<Missions_Vk_Links_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_Boosters_RewardsArgs = {
  _inc?: InputMaybe<New_Year_Competition_Boosters_Rewards_Inc_Input>;
  _set?: InputMaybe<New_Year_Competition_Boosters_Rewards_Set_Input>;
  where: New_Year_Competition_Boosters_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_Boosters_Rewards_By_PkArgs = {
  _inc?: InputMaybe<New_Year_Competition_Boosters_Rewards_Inc_Input>;
  _set?: InputMaybe<New_Year_Competition_Boosters_Rewards_Set_Input>;
  pk_columns: New_Year_Competition_Boosters_Rewards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_Boosters_Rewards_ManyArgs = {
  updates: Array<New_Year_Competition_Boosters_Rewards_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_ParticipantsArgs = {
  _inc?: InputMaybe<New_Year_Competition_Participants_Inc_Input>;
  _set?: InputMaybe<New_Year_Competition_Participants_Set_Input>;
  where: New_Year_Competition_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_Participants_By_PkArgs = {
  _inc?: InputMaybe<New_Year_Competition_Participants_Inc_Input>;
  _set?: InputMaybe<New_Year_Competition_Participants_Set_Input>;
  pk_columns: New_Year_Competition_Participants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_Participants_ManyArgs = {
  updates: Array<New_Year_Competition_Participants_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_Participants_PerformanceArgs = {
  _inc?: InputMaybe<New_Year_Competition_Participants_Performance_Inc_Input>;
  _set?: InputMaybe<New_Year_Competition_Participants_Performance_Set_Input>;
  where: New_Year_Competition_Participants_Performance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_Participants_Performance_By_PkArgs = {
  _inc?: InputMaybe<New_Year_Competition_Participants_Performance_Inc_Input>;
  _set?: InputMaybe<New_Year_Competition_Participants_Performance_Set_Input>;
  pk_columns: New_Year_Competition_Participants_Performance_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_Participants_Performance_ManyArgs = {
  updates: Array<New_Year_Competition_Participants_Performance_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_TeamsArgs = {
  _inc?: InputMaybe<New_Year_Competition_Teams_Inc_Input>;
  _set?: InputMaybe<New_Year_Competition_Teams_Set_Input>;
  where: New_Year_Competition_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_Teams_By_PkArgs = {
  _inc?: InputMaybe<New_Year_Competition_Teams_Inc_Input>;
  _set?: InputMaybe<New_Year_Competition_Teams_Set_Input>;
  pk_columns: New_Year_Competition_Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_New_Year_Competition_Teams_ManyArgs = {
  updates: Array<New_Year_Competition_Teams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_TypesArgs = {
  _inc?: InputMaybe<Operation_Types_Inc_Input>;
  _set?: InputMaybe<Operation_Types_Set_Input>;
  where: Operation_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Types_By_PkArgs = {
  _inc?: InputMaybe<Operation_Types_Inc_Input>;
  _set?: InputMaybe<Operation_Types_Set_Input>;
  pk_columns: Operation_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Operation_Types_ManyArgs = {
  updates: Array<Operation_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Parsers_Timestamp_LogArgs = {
  _set?: InputMaybe<Parsers_Timestamp_Log_Set_Input>;
  where: Parsers_Timestamp_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Parsers_Timestamp_Log_By_PkArgs = {
  _set?: InputMaybe<Parsers_Timestamp_Log_Set_Input>;
  pk_columns: Parsers_Timestamp_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Parsers_Timestamp_Log_ManyArgs = {
  updates: Array<Parsers_Timestamp_Log_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Balance_TransactionsArgs = {
  _inc?: InputMaybe<Partner_Balance_Transactions_Inc_Input>;
  _set?: InputMaybe<Partner_Balance_Transactions_Set_Input>;
  where: Partner_Balance_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Balance_Transactions_By_PkArgs = {
  _inc?: InputMaybe<Partner_Balance_Transactions_Inc_Input>;
  _set?: InputMaybe<Partner_Balance_Transactions_Set_Input>;
  pk_columns: Partner_Balance_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Balance_Transactions_ManyArgs = {
  updates: Array<Partner_Balance_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Offer_TypesArgs = {
  _inc?: InputMaybe<Partner_Offer_Types_Inc_Input>;
  _set?: InputMaybe<Partner_Offer_Types_Set_Input>;
  where: Partner_Offer_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Offer_Types_By_PkArgs = {
  _inc?: InputMaybe<Partner_Offer_Types_Inc_Input>;
  _set?: InputMaybe<Partner_Offer_Types_Set_Input>;
  pk_columns: Partner_Offer_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Offer_Types_ManyArgs = {
  updates: Array<Partner_Offer_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_ParamsArgs = {
  _inc?: InputMaybe<Partner_Params_Inc_Input>;
  _set?: InputMaybe<Partner_Params_Set_Input>;
  where: Partner_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Params_AuditArgs = {
  _inc?: InputMaybe<Partner_Params_Audit_Inc_Input>;
  _set?: InputMaybe<Partner_Params_Audit_Set_Input>;
  where: Partner_Params_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Params_Audit_By_PkArgs = {
  _inc?: InputMaybe<Partner_Params_Audit_Inc_Input>;
  _set?: InputMaybe<Partner_Params_Audit_Set_Input>;
  pk_columns: Partner_Params_Audit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Params_Audit_ManyArgs = {
  updates: Array<Partner_Params_Audit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Params_By_PkArgs = {
  _inc?: InputMaybe<Partner_Params_Inc_Input>;
  _set?: InputMaybe<Partner_Params_Set_Input>;
  pk_columns: Partner_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Params_ManyArgs = {
  updates: Array<Partner_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Passport_InfoArgs = {
  _inc?: InputMaybe<Passport_Info_Inc_Input>;
  _set?: InputMaybe<Passport_Info_Set_Input>;
  where: Passport_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Passport_Info_By_PkArgs = {
  _inc?: InputMaybe<Passport_Info_Inc_Input>;
  _set?: InputMaybe<Passport_Info_Set_Input>;
  pk_columns: Passport_Info_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Passport_Info_ManyArgs = {
  updates: Array<Passport_Info_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_MethodsArgs = {
  _inc?: InputMaybe<Payment_Methods_Inc_Input>;
  _set?: InputMaybe<Payment_Methods_Set_Input>;
  where: Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Methods_By_PkArgs = {
  _inc?: InputMaybe<Payment_Methods_Inc_Input>;
  _set?: InputMaybe<Payment_Methods_Set_Input>;
  pk_columns: Payment_Methods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Methods_ManyArgs = {
  updates: Array<Payment_Methods_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Methods_ViewArgs = {
  _inc?: InputMaybe<Payment_Methods_View_Inc_Input>;
  _set?: InputMaybe<Payment_Methods_View_Set_Input>;
  where: Payment_Methods_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Methods_View_By_PkArgs = {
  _inc?: InputMaybe<Payment_Methods_View_Inc_Input>;
  _set?: InputMaybe<Payment_Methods_View_Set_Input>;
  pk_columns: Payment_Methods_View_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Methods_View_ManyArgs = {
  updates: Array<Payment_Methods_View_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_ProvidersArgs = {
  _append?: InputMaybe<Payment_Providers_Append_Input>;
  _delete_at_path?: InputMaybe<Payment_Providers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Payment_Providers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Payment_Providers_Delete_Key_Input>;
  _prepend?: InputMaybe<Payment_Providers_Prepend_Input>;
  _set?: InputMaybe<Payment_Providers_Set_Input>;
  where: Payment_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Providers_By_PkArgs = {
  _append?: InputMaybe<Payment_Providers_Append_Input>;
  _delete_at_path?: InputMaybe<Payment_Providers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Payment_Providers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Payment_Providers_Delete_Key_Input>;
  _prepend?: InputMaybe<Payment_Providers_Prepend_Input>;
  _set?: InputMaybe<Payment_Providers_Set_Input>;
  pk_columns: Payment_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Providers_ManyArgs = {
  updates: Array<Payment_Providers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Recurring_TokensArgs = {
  _inc?: InputMaybe<Payment_Recurring_Tokens_Inc_Input>;
  _set?: InputMaybe<Payment_Recurring_Tokens_Set_Input>;
  where: Payment_Recurring_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Recurring_Tokens_By_PkArgs = {
  _inc?: InputMaybe<Payment_Recurring_Tokens_Inc_Input>;
  _set?: InputMaybe<Payment_Recurring_Tokens_Set_Input>;
  pk_columns: Payment_Recurring_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Recurring_Tokens_ManyArgs = {
  updates: Array<Payment_Recurring_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_RequisitesArgs = {
  _inc?: InputMaybe<Payment_Requisites_Inc_Input>;
  _set?: InputMaybe<Payment_Requisites_Set_Input>;
  where: Payment_Requisites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Requisites_By_PkArgs = {
  _inc?: InputMaybe<Payment_Requisites_Inc_Input>;
  _set?: InputMaybe<Payment_Requisites_Set_Input>;
  pk_columns: Payment_Requisites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Requisites_ManyArgs = {
  updates: Array<Payment_Requisites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_1payment_Transaction_RequestsArgs = {
  _inc?: InputMaybe<Payment_System_1payment_Transaction_Requests_Inc_Input>;
  _set?: InputMaybe<Payment_System_1payment_Transaction_Requests_Set_Input>;
  where: Payment_System_1payment_Transaction_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_1payment_Transaction_Requests_By_PkArgs = {
  _inc?: InputMaybe<Payment_System_1payment_Transaction_Requests_Inc_Input>;
  _set?: InputMaybe<Payment_System_1payment_Transaction_Requests_Set_Input>;
  pk_columns: Payment_System_1payment_Transaction_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_1payment_Transaction_Requests_ManyArgs = {
  updates: Array<Payment_System_1payment_Transaction_Requests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Free_Kassa_CallbacksArgs = {
  _set?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Set_Input>;
  where: Payment_System_Free_Kassa_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Free_Kassa_Callbacks_By_PkArgs = {
  _set?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Set_Input>;
  pk_columns: Payment_System_Free_Kassa_Callbacks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Free_Kassa_Callbacks_ManyArgs = {
  updates: Array<Payment_System_Free_Kassa_Callbacks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Free_Kassa_Transaction_RequestsArgs = {
  _set?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_Set_Input>;
  where: Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Free_Kassa_Transaction_Requests_By_PkArgs = {
  _set?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_Set_Input>;
  pk_columns: Payment_System_Free_Kassa_Transaction_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Free_Kassa_Transaction_Requests_ManyArgs = {
  updates: Array<Payment_System_Free_Kassa_Transaction_Requests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Main_CallbacksArgs = {
  _set?: InputMaybe<Payment_System_Main_Callbacks_Set_Input>;
  where: Payment_System_Main_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Main_Callbacks_By_PkArgs = {
  _set?: InputMaybe<Payment_System_Main_Callbacks_Set_Input>;
  pk_columns: Payment_System_Main_Callbacks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Main_Callbacks_ManyArgs = {
  updates: Array<Payment_System_Main_Callbacks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Main_Transaction_RequestsArgs = {
  _inc?: InputMaybe<Payment_System_Main_Transaction_Requests_Inc_Input>;
  _set?: InputMaybe<Payment_System_Main_Transaction_Requests_Set_Input>;
  where: Payment_System_Main_Transaction_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Main_Transaction_Requests_By_PkArgs = {
  _inc?: InputMaybe<Payment_System_Main_Transaction_Requests_Inc_Input>;
  _set?: InputMaybe<Payment_System_Main_Transaction_Requests_Set_Input>;
  pk_columns: Payment_System_Main_Transaction_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_System_Main_Transaction_Requests_ManyArgs = {
  updates: Array<Payment_System_Main_Transaction_Requests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_1payment_CallbacksArgs = {
  _set?: InputMaybe<Payment_Systems_1payment_Callbacks_Set_Input>;
  where: Payment_Systems_1payment_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_1payment_Callbacks_By_PkArgs = {
  _set?: InputMaybe<Payment_Systems_1payment_Callbacks_Set_Input>;
  pk_columns: Payment_Systems_1payment_Callbacks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_1payment_Callbacks_ManyArgs = {
  updates: Array<Payment_Systems_1payment_Callbacks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_CallbacksArgs = {
  _inc?: InputMaybe<Payment_Systems_Callbacks_Inc_Input>;
  _set?: InputMaybe<Payment_Systems_Callbacks_Set_Input>;
  where: Payment_Systems_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_Callbacks_By_PkArgs = {
  _inc?: InputMaybe<Payment_Systems_Callbacks_Inc_Input>;
  _set?: InputMaybe<Payment_Systems_Callbacks_Set_Input>;
  pk_columns: Payment_Systems_Callbacks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_Callbacks_ManyArgs = {
  updates: Array<Payment_Systems_Callbacks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_DataArgs = {
  _set?: InputMaybe<Payment_Systems_Data_Set_Input>;
  where: Payment_Systems_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_Data_By_PkArgs = {
  _set?: InputMaybe<Payment_Systems_Data_Set_Input>;
  pk_columns: Payment_Systems_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_Data_ManyArgs = {
  updates: Array<Payment_Systems_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_MethodsArgs = {
  _inc?: InputMaybe<Payment_Systems_Methods_Inc_Input>;
  _set?: InputMaybe<Payment_Systems_Methods_Set_Input>;
  where: Payment_Systems_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_Methods_By_PkArgs = {
  _inc?: InputMaybe<Payment_Systems_Methods_Inc_Input>;
  _set?: InputMaybe<Payment_Systems_Methods_Set_Input>;
  pk_columns: Payment_Systems_Methods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_Methods_ManyArgs = {
  updates: Array<Payment_Systems_Methods_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_Transaction_RequestsArgs = {
  _inc?: InputMaybe<Payment_Systems_Transaction_Requests_Inc_Input>;
  _set?: InputMaybe<Payment_Systems_Transaction_Requests_Set_Input>;
  where: Payment_Systems_Transaction_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_Transaction_Requests_By_PkArgs = {
  _inc?: InputMaybe<Payment_Systems_Transaction_Requests_Inc_Input>;
  _set?: InputMaybe<Payment_Systems_Transaction_Requests_Set_Input>;
  pk_columns: Payment_Systems_Transaction_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Systems_Transaction_Requests_ManyArgs = {
  updates: Array<Payment_Systems_Transaction_Requests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_TransactionsArgs = {
  _append?: InputMaybe<Payment_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Payment_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Payment_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Payment_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Payment_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Payment_Transactions_Prepend_Input>;
  _set?: InputMaybe<Payment_Transactions_Set_Input>;
  where: Payment_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Transactions_By_PkArgs = {
  _append?: InputMaybe<Payment_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Payment_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Payment_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Payment_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Payment_Transactions_Inc_Input>;
  _prepend?: InputMaybe<Payment_Transactions_Prepend_Input>;
  _set?: InputMaybe<Payment_Transactions_Set_Input>;
  pk_columns: Payment_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Transactions_ManyArgs = {
  updates: Array<Payment_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Items_NamesArgs = {
  _set?: InputMaybe<Profile_Items_Names_Set_Input>;
  where: Profile_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Items_Names_By_PkArgs = {
  _set?: InputMaybe<Profile_Items_Names_Set_Input>;
  pk_columns: Profile_Items_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Items_Names_ManyArgs = {
  updates: Array<Profile_Items_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_LootboxesArgs = {
  _set?: InputMaybe<Promo_Lootboxes_Set_Input>;
  where: Promo_Lootboxes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_Lootboxes_By_PkArgs = {
  _set?: InputMaybe<Promo_Lootboxes_Set_Input>;
  pk_columns: Promo_Lootboxes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_Lootboxes_ManyArgs = {
  updates: Array<Promo_Lootboxes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_UsersArgs = {
  _set?: InputMaybe<Promo_Users_Set_Input>;
  where: Promo_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_Users_By_PkArgs = {
  _set?: InputMaybe<Promo_Users_Set_Input>;
  pk_columns: Promo_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_Users_ManyArgs = {
  updates: Array<Promo_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_ValuesArgs = {
  _inc?: InputMaybe<Promo_Values_Inc_Input>;
  _set?: InputMaybe<Promo_Values_Set_Input>;
  where: Promo_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_Values_By_PkArgs = {
  _inc?: InputMaybe<Promo_Values_Inc_Input>;
  _set?: InputMaybe<Promo_Values_Set_Input>;
  pk_columns: Promo_Values_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Promo_Values_ManyArgs = {
  updates: Array<Promo_Values_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Promocode_ActivationsArgs = {
  _inc?: InputMaybe<Promocode_Activations_Inc_Input>;
  _set?: InputMaybe<Promocode_Activations_Set_Input>;
  where: Promocode_Activations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Promocode_Activations_By_PkArgs = {
  _inc?: InputMaybe<Promocode_Activations_Inc_Input>;
  _set?: InputMaybe<Promocode_Activations_Set_Input>;
  pk_columns: Promocode_Activations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Promocode_Activations_ManyArgs = {
  updates: Array<Promocode_Activations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PromocodesArgs = {
  _inc?: InputMaybe<Promocodes_Inc_Input>;
  _set?: InputMaybe<Promocodes_Set_Input>;
  where: Promocodes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Promocodes_By_PkArgs = {
  _inc?: InputMaybe<Promocodes_Inc_Input>;
  _set?: InputMaybe<Promocodes_Set_Input>;
  pk_columns: Promocodes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Promocodes_ManyArgs = {
  updates: Array<Promocodes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ps_TypesArgs = {
  _inc?: InputMaybe<Ps_Types_Inc_Input>;
  _set?: InputMaybe<Ps_Types_Set_Input>;
  where: Ps_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ps_Types_By_PkArgs = {
  _inc?: InputMaybe<Ps_Types_Inc_Input>;
  _set?: InputMaybe<Ps_Types_Set_Input>;
  pk_columns: Ps_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ps_Types_ManyArgs = {
  updates: Array<Ps_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Qservice_DataArgs = {
  _inc?: InputMaybe<Qservice_Data_Inc_Input>;
  _set?: InputMaybe<Qservice_Data_Set_Input>;
  where: Qservice_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Qservice_Data_By_PkArgs = {
  _inc?: InputMaybe<Qservice_Data_Inc_Input>;
  _set?: InputMaybe<Qservice_Data_Set_Input>;
  pk_columns: Qservice_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Qservice_Data_ManyArgs = {
  updates: Array<Qservice_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Bonus_Wheels_Users_OffersArgs = {
  _inc?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Inc_Input>;
  _set?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Set_Input>;
  where: Statistic_Bonus_Wheels_Users_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Bonus_Wheels_Users_Offers_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Inc_Input>;
  _set?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Set_Input>;
  pk_columns: Statistic_Bonus_Wheels_Users_Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Bonus_Wheels_Users_Offers_ManyArgs = {
  updates: Array<Statistic_Bonus_Wheels_Users_Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Button_Again_PressArgs = {
  _inc?: InputMaybe<Statistic_Button_Again_Press_Inc_Input>;
  _set?: InputMaybe<Statistic_Button_Again_Press_Set_Input>;
  where: Statistic_Button_Again_Press_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Button_Again_Press_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Button_Again_Press_Inc_Input>;
  _set?: InputMaybe<Statistic_Button_Again_Press_Set_Input>;
  pk_columns: Statistic_Button_Again_Press_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Button_Again_Press_ManyArgs = {
  updates: Array<Statistic_Button_Again_Press_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Fast_Roll_TriggerArgs = {
  _inc?: InputMaybe<Statistic_Fast_Roll_Trigger_Inc_Input>;
  _set?: InputMaybe<Statistic_Fast_Roll_Trigger_Set_Input>;
  where: Statistic_Fast_Roll_Trigger_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Fast_Roll_Trigger_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Fast_Roll_Trigger_Inc_Input>;
  _set?: InputMaybe<Statistic_Fast_Roll_Trigger_Set_Input>;
  pk_columns: Statistic_Fast_Roll_Trigger_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Fast_Roll_Trigger_ManyArgs = {
  updates: Array<Statistic_Fast_Roll_Trigger_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Inventory_OpensArgs = {
  _inc?: InputMaybe<Statistic_Inventory_Opens_Inc_Input>;
  _set?: InputMaybe<Statistic_Inventory_Opens_Set_Input>;
  where: Statistic_Inventory_Opens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Inventory_Opens_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Inventory_Opens_Inc_Input>;
  _set?: InputMaybe<Statistic_Inventory_Opens_Set_Input>;
  pk_columns: Statistic_Inventory_Opens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Inventory_Opens_ManyArgs = {
  updates: Array<Statistic_Inventory_Opens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_DropsArgs = {
  _inc?: InputMaybe<Statistic_Items_Drops_Inc_Input>;
  _set?: InputMaybe<Statistic_Items_Drops_Set_Input>;
  where: Statistic_Items_Drops_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_Drops_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Items_Drops_Inc_Input>;
  _set?: InputMaybe<Statistic_Items_Drops_Set_Input>;
  pk_columns: Statistic_Items_Drops_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_Drops_ManyArgs = {
  updates: Array<Statistic_Items_Drops_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_NamesArgs = {
  _set?: InputMaybe<Statistic_Items_Names_Set_Input>;
  where: Statistic_Items_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_Names_By_PkArgs = {
  _set?: InputMaybe<Statistic_Items_Names_Set_Input>;
  pk_columns: Statistic_Items_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_Names_ManyArgs = {
  updates: Array<Statistic_Items_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_Output_FailArgs = {
  _inc?: InputMaybe<Statistic_Items_Output_Fail_Inc_Input>;
  _set?: InputMaybe<Statistic_Items_Output_Fail_Set_Input>;
  where: Statistic_Items_Output_Fail_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_Output_Fail_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Items_Output_Fail_Inc_Input>;
  _set?: InputMaybe<Statistic_Items_Output_Fail_Set_Input>;
  pk_columns: Statistic_Items_Output_Fail_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_Output_Fail_ManyArgs = {
  updates: Array<Statistic_Items_Output_Fail_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_Output_SuccessArgs = {
  _inc?: InputMaybe<Statistic_Items_Output_Success_Inc_Input>;
  _set?: InputMaybe<Statistic_Items_Output_Success_Set_Input>;
  where: Statistic_Items_Output_Success_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_Output_Success_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Items_Output_Success_Inc_Input>;
  _set?: InputMaybe<Statistic_Items_Output_Success_Set_Input>;
  pk_columns: Statistic_Items_Output_Success_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Items_Output_Success_ManyArgs = {
  updates: Array<Statistic_Items_Output_Success_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Lootbox_OpensArgs = {
  _inc?: InputMaybe<Statistic_Lootbox_Opens_Inc_Input>;
  _set?: InputMaybe<Statistic_Lootbox_Opens_Set_Input>;
  where: Statistic_Lootbox_Opens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Lootbox_Opens_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Lootbox_Opens_Inc_Input>;
  _set?: InputMaybe<Statistic_Lootbox_Opens_Set_Input>;
  pk_columns: Statistic_Lootbox_Opens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Lootbox_Opens_ManyArgs = {
  updates: Array<Statistic_Lootbox_Opens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Lootbox_ViewsArgs = {
  _inc?: InputMaybe<Statistic_Lootbox_Views_Inc_Input>;
  _set?: InputMaybe<Statistic_Lootbox_Views_Set_Input>;
  where: Statistic_Lootbox_Views_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Lootbox_Views_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Lootbox_Views_Inc_Input>;
  _set?: InputMaybe<Statistic_Lootbox_Views_Set_Input>;
  pk_columns: Statistic_Lootbox_Views_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Lootbox_Views_ManyArgs = {
  updates: Array<Statistic_Lootbox_Views_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Mainpage_VisitsArgs = {
  _inc?: InputMaybe<Statistic_Mainpage_Visits_Inc_Input>;
  _set?: InputMaybe<Statistic_Mainpage_Visits_Set_Input>;
  where: Statistic_Mainpage_Visits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Mainpage_Visits_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Mainpage_Visits_Inc_Input>;
  _set?: InputMaybe<Statistic_Mainpage_Visits_Set_Input>;
  pk_columns: Statistic_Mainpage_Visits_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Mainpage_Visits_ManyArgs = {
  updates: Array<Statistic_Mainpage_Visits_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Promocode_Full_ActivationsArgs = {
  _inc?: InputMaybe<Statistic_Promocode_Full_Activations_Inc_Input>;
  _set?: InputMaybe<Statistic_Promocode_Full_Activations_Set_Input>;
  where: Statistic_Promocode_Full_Activations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Promocode_Full_Activations_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Promocode_Full_Activations_Inc_Input>;
  _set?: InputMaybe<Statistic_Promocode_Full_Activations_Set_Input>;
  pk_columns: Statistic_Promocode_Full_Activations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Promocode_Full_Activations_ManyArgs = {
  updates: Array<Statistic_Promocode_Full_Activations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Promocode_Success_ActivationsArgs = {
  _inc?: InputMaybe<Statistic_Promocode_Success_Activations_Inc_Input>;
  _set?: InputMaybe<Statistic_Promocode_Success_Activations_Set_Input>;
  where: Statistic_Promocode_Success_Activations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Promocode_Success_Activations_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Promocode_Success_Activations_Inc_Input>;
  _set?: InputMaybe<Statistic_Promocode_Success_Activations_Set_Input>;
  pk_columns: Statistic_Promocode_Success_Activations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Promocode_Success_Activations_ManyArgs = {
  updates: Array<Statistic_Promocode_Success_Activations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Sell_All_ItemsArgs = {
  _inc?: InputMaybe<Statistic_Sell_All_Items_Inc_Input>;
  _set?: InputMaybe<Statistic_Sell_All_Items_Set_Input>;
  where: Statistic_Sell_All_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Sell_All_Items_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Sell_All_Items_Inc_Input>;
  _set?: InputMaybe<Statistic_Sell_All_Items_Set_Input>;
  pk_columns: Statistic_Sell_All_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Sell_All_Items_ManyArgs = {
  updates: Array<Statistic_Sell_All_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Sell_ItemArgs = {
  _inc?: InputMaybe<Statistic_Sell_Item_Inc_Input>;
  _set?: InputMaybe<Statistic_Sell_Item_Set_Input>;
  where: Statistic_Sell_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Sell_Item_By_PkArgs = {
  _inc?: InputMaybe<Statistic_Sell_Item_Inc_Input>;
  _set?: InputMaybe<Statistic_Sell_Item_Set_Input>;
  pk_columns: Statistic_Sell_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_Sell_Item_ManyArgs = {
  updates: Array<Statistic_Sell_Item_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_Deposit_Success_CallbacksArgs = {
  _inc?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Inc_Input>;
  _set?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Set_Input>;
  where: Statistic_User_Deposit_Success_Callbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_Deposit_Success_Callbacks_By_PkArgs = {
  _inc?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Inc_Input>;
  _set?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Set_Input>;
  pk_columns: Statistic_User_Deposit_Success_Callbacks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_Deposit_Success_Callbacks_ManyArgs = {
  updates: Array<Statistic_User_Deposit_Success_Callbacks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_Deposits_Transaction_RequestsArgs = {
  _inc?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Inc_Input>;
  _set?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Set_Input>;
  where: Statistic_User_Deposits_Transaction_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_Deposits_Transaction_Requests_By_PkArgs = {
  _inc?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Inc_Input>;
  _set?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Set_Input>;
  pk_columns: Statistic_User_Deposits_Transaction_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_Deposits_Transaction_Requests_ManyArgs = {
  updates: Array<Statistic_User_Deposits_Transaction_Requests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_Free_Lootboxes_OpensArgs = {
  _inc?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Inc_Input>;
  _set?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Set_Input>;
  where: Statistic_User_Free_Lootboxes_Opens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_Free_Lootboxes_Opens_By_PkArgs = {
  _inc?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Inc_Input>;
  _set?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Set_Input>;
  pk_columns: Statistic_User_Free_Lootboxes_Opens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_Free_Lootboxes_Opens_ManyArgs = {
  updates: Array<Statistic_User_Free_Lootboxes_Opens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_RegistrationArgs = {
  _set?: InputMaybe<Statistic_User_Registration_Set_Input>;
  where: Statistic_User_Registration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_Registration_By_PkArgs = {
  _set?: InputMaybe<Statistic_User_Registration_Set_Input>;
  pk_columns: Statistic_User_Registration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Statistic_User_Registration_ManyArgs = {
  updates: Array<Statistic_User_Registration_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StocksArgs = {
  _inc?: InputMaybe<Stocks_Inc_Input>;
  _set?: InputMaybe<Stocks_Set_Input>;
  where: Stocks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stocks_By_PkArgs = {
  _inc?: InputMaybe<Stocks_Inc_Input>;
  _set?: InputMaybe<Stocks_Set_Input>;
  pk_columns: Stocks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stocks_ManyArgs = {
  updates: Array<Stocks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_BadgesArgs = {
  _set?: InputMaybe<Store_Offer_Badges_Set_Input>;
  where: Store_Offer_Badges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Badges_By_PkArgs = {
  _set?: InputMaybe<Store_Offer_Badges_Set_Input>;
  pk_columns: Store_Offer_Badges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Badges_ManyArgs = {
  updates: Array<Store_Offer_Badges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_BoostersArgs = {
  _inc?: InputMaybe<Store_Offer_Boosters_Inc_Input>;
  _set?: InputMaybe<Store_Offer_Boosters_Set_Input>;
  where: Store_Offer_Boosters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Boosters_By_PkArgs = {
  _inc?: InputMaybe<Store_Offer_Boosters_Inc_Input>;
  _set?: InputMaybe<Store_Offer_Boosters_Set_Input>;
  pk_columns: Store_Offer_Boosters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Boosters_ManyArgs = {
  updates: Array<Store_Offer_Boosters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_CoinsArgs = {
  _inc?: InputMaybe<Store_Offer_Coins_Inc_Input>;
  _set?: InputMaybe<Store_Offer_Coins_Set_Input>;
  where: Store_Offer_Coins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Coins_By_PkArgs = {
  _inc?: InputMaybe<Store_Offer_Coins_Inc_Input>;
  _set?: InputMaybe<Store_Offer_Coins_Set_Input>;
  pk_columns: Store_Offer_Coins_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Coins_ManyArgs = {
  updates: Array<Store_Offer_Coins_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_LootboxesArgs = {
  _inc?: InputMaybe<Store_Offer_Lootboxes_Inc_Input>;
  _set?: InputMaybe<Store_Offer_Lootboxes_Set_Input>;
  where: Store_Offer_Lootboxes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Lootboxes_By_PkArgs = {
  _inc?: InputMaybe<Store_Offer_Lootboxes_Inc_Input>;
  _set?: InputMaybe<Store_Offer_Lootboxes_Set_Input>;
  pk_columns: Store_Offer_Lootboxes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Lootboxes_ManyArgs = {
  updates: Array<Store_Offer_Lootboxes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_SalesArgs = {
  _set?: InputMaybe<Store_Offer_Sales_Set_Input>;
  where: Store_Offer_Sales_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Sales_By_PkArgs = {
  _set?: InputMaybe<Store_Offer_Sales_Set_Input>;
  pk_columns: Store_Offer_Sales_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Sales_ManyArgs = {
  updates: Array<Store_Offer_Sales_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_SkinsArgs = {
  _inc?: InputMaybe<Store_Offer_Skins_Inc_Input>;
  _set?: InputMaybe<Store_Offer_Skins_Set_Input>;
  where: Store_Offer_Skins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Skins_By_PkArgs = {
  _inc?: InputMaybe<Store_Offer_Skins_Inc_Input>;
  _set?: InputMaybe<Store_Offer_Skins_Set_Input>;
  pk_columns: Store_Offer_Skins_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Skins_ManyArgs = {
  updates: Array<Store_Offer_Skins_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_TicketsArgs = {
  _inc?: InputMaybe<Store_Offer_Tickets_Inc_Input>;
  _set?: InputMaybe<Store_Offer_Tickets_Set_Input>;
  where: Store_Offer_Tickets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Tickets_By_PkArgs = {
  _inc?: InputMaybe<Store_Offer_Tickets_Inc_Input>;
  _set?: InputMaybe<Store_Offer_Tickets_Set_Input>;
  pk_columns: Store_Offer_Tickets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Tickets_ManyArgs = {
  updates: Array<Store_Offer_Tickets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_TypesArgs = {
  _set?: InputMaybe<Store_Offer_Types_Set_Input>;
  where: Store_Offer_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Types_By_PkArgs = {
  _set?: InputMaybe<Store_Offer_Types_Set_Input>;
  pk_columns: Store_Offer_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offer_Types_ManyArgs = {
  updates: Array<Store_Offer_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Store_OffersArgs = {
  _inc?: InputMaybe<Store_Offers_Inc_Input>;
  _set?: InputMaybe<Store_Offers_Set_Input>;
  where: Store_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offers_By_PkArgs = {
  _inc?: InputMaybe<Store_Offers_Inc_Input>;
  _set?: InputMaybe<Store_Offers_Set_Input>;
  pk_columns: Store_Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Offers_ManyArgs = {
  updates: Array<Store_Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Survey_Data_LinksArgs = {
  _set?: InputMaybe<Survey_Data_Links_Set_Input>;
  where: Survey_Data_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Survey_Data_Links_By_PkArgs = {
  _set?: InputMaybe<Survey_Data_Links_Set_Input>;
  pk_columns: Survey_Data_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Survey_Data_Links_ManyArgs = {
  updates: Array<Survey_Data_Links_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Telegram_Admin_ParamsArgs = {
  _inc?: InputMaybe<Telegram_Admin_Params_Inc_Input>;
  _set?: InputMaybe<Telegram_Admin_Params_Set_Input>;
  where: Telegram_Admin_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Telegram_Admin_Params_By_PkArgs = {
  _inc?: InputMaybe<Telegram_Admin_Params_Inc_Input>;
  _set?: InputMaybe<Telegram_Admin_Params_Set_Input>;
  pk_columns: Telegram_Admin_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Telegram_Admin_Params_ManyArgs = {
  updates: Array<Telegram_Admin_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ticket_Counts_ResultArgs = {
  _inc?: InputMaybe<Ticket_Counts_Result_Inc_Input>;
  _set?: InputMaybe<Ticket_Counts_Result_Set_Input>;
  where: Ticket_Counts_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ticket_Counts_Result_ManyArgs = {
  updates: Array<Ticket_Counts_Result_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tickets_Set_ContentArgs = {
  _inc?: InputMaybe<Tickets_Set_Content_Inc_Input>;
  _set?: InputMaybe<Tickets_Set_Content_Set_Input>;
  where: Tickets_Set_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tickets_Set_Content_By_PkArgs = {
  _inc?: InputMaybe<Tickets_Set_Content_Inc_Input>;
  _set?: InputMaybe<Tickets_Set_Content_Set_Input>;
  pk_columns: Tickets_Set_Content_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tickets_Set_Content_ManyArgs = {
  updates: Array<Tickets_Set_Content_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tickets_SetsArgs = {
  _inc?: InputMaybe<Tickets_Sets_Inc_Input>;
  _set?: InputMaybe<Tickets_Sets_Set_Input>;
  where: Tickets_Sets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tickets_Sets_By_PkArgs = {
  _inc?: InputMaybe<Tickets_Sets_Inc_Input>;
  _set?: InputMaybe<Tickets_Sets_Set_Input>;
  pk_columns: Tickets_Sets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tickets_Sets_ManyArgs = {
  updates: Array<Tickets_Sets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Top_Drop_StripArgs = {
  _inc?: InputMaybe<Top_Drop_Strip_Inc_Input>;
  _set?: InputMaybe<Top_Drop_Strip_Set_Input>;
  where: Top_Drop_Strip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Top_Drop_Strip_By_PkArgs = {
  _inc?: InputMaybe<Top_Drop_Strip_Inc_Input>;
  _set?: InputMaybe<Top_Drop_Strip_Set_Input>;
  pk_columns: Top_Drop_Strip_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Top_Drop_Strip_ManyArgs = {
  updates: Array<Top_Drop_Strip_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TransactionArgs = {
  _inc?: InputMaybe<Transaction_Inc_Input>;
  _set?: InputMaybe<Transaction_Set_Input>;
  where: Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transaction_By_PkArgs = {
  _inc?: InputMaybe<Transaction_Inc_Input>;
  _set?: InputMaybe<Transaction_Set_Input>;
  pk_columns: Transaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transaction_ManyArgs = {
  updates: Array<Transaction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Url_MappingsArgs = {
  _set?: InputMaybe<Url_Mappings_Set_Input>;
  where: Url_Mappings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Url_Mappings_By_PkArgs = {
  _set?: InputMaybe<Url_Mappings_Set_Input>;
  pk_columns: Url_Mappings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Url_Mappings_ManyArgs = {
  updates: Array<Url_Mappings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_AccountArgs = {
  _set?: InputMaybe<User_Account_Set_Input>;
  where: User_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Account_By_PkArgs = {
  _set?: InputMaybe<User_Account_Set_Input>;
  pk_columns: User_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Account_ManyArgs = {
  updates: Array<User_Account_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_AuthArgs = {
  _set?: InputMaybe<User_Auth_Set_Input>;
  where: User_Auth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_By_PkArgs = {
  _set?: InputMaybe<User_Auth_Set_Input>;
  pk_columns: User_Auth_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_ManyArgs = {
  updates: Array<User_Auth_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Bonus_WalletArgs = {
  _inc?: InputMaybe<User_Bonus_Wallet_Inc_Input>;
  _set?: InputMaybe<User_Bonus_Wallet_Set_Input>;
  where: User_Bonus_Wallet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Bonus_Wallet_By_PkArgs = {
  _inc?: InputMaybe<User_Bonus_Wallet_Inc_Input>;
  _set?: InputMaybe<User_Bonus_Wallet_Set_Input>;
  pk_columns: User_Bonus_Wallet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Bonus_Wallet_ManyArgs = {
  updates: Array<User_Bonus_Wallet_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Bonus_Wallet_TransactionsArgs = {
  _inc?: InputMaybe<User_Bonus_Wallet_Transactions_Inc_Input>;
  _set?: InputMaybe<User_Bonus_Wallet_Transactions_Set_Input>;
  where: User_Bonus_Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Bonus_Wallet_Transactions_By_PkArgs = {
  _inc?: InputMaybe<User_Bonus_Wallet_Transactions_Inc_Input>;
  _set?: InputMaybe<User_Bonus_Wallet_Transactions_Set_Input>;
  pk_columns: User_Bonus_Wallet_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Bonus_Wallet_Transactions_ManyArgs = {
  updates: Array<User_Bonus_Wallet_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Boosters_In_InventoryArgs = {
  _inc?: InputMaybe<User_Boosters_In_Inventory_Inc_Input>;
  _set?: InputMaybe<User_Boosters_In_Inventory_Set_Input>;
  where: User_Boosters_In_Inventory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Boosters_In_Inventory_By_PkArgs = {
  _inc?: InputMaybe<User_Boosters_In_Inventory_Inc_Input>;
  _set?: InputMaybe<User_Boosters_In_Inventory_Set_Input>;
  pk_columns: User_Boosters_In_Inventory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Boosters_In_Inventory_ManyArgs = {
  updates: Array<User_Boosters_In_Inventory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ContactsArgs = {
  _set?: InputMaybe<User_Contacts_Set_Input>;
  where: User_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Contacts_By_PkArgs = {
  _set?: InputMaybe<User_Contacts_Set_Input>;
  pk_columns: User_Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Contacts_ManyArgs = {
  updates: Array<User_Contacts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Correct_Answers_RewardsArgs = {
  _set?: InputMaybe<User_Correct_Answers_Rewards_Set_Input>;
  where: User_Correct_Answers_Rewards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Correct_Answers_Rewards_By_PkArgs = {
  _set?: InputMaybe<User_Correct_Answers_Rewards_Set_Input>;
  pk_columns: User_Correct_Answers_Rewards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Correct_Answers_Rewards_ManyArgs = {
  updates: Array<User_Correct_Answers_Rewards_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Daily_Coins_ClaimsArgs = {
  _set?: InputMaybe<User_Daily_Coins_Claims_Set_Input>;
  where: User_Daily_Coins_Claims_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Daily_Coins_Claims_By_PkArgs = {
  _set?: InputMaybe<User_Daily_Coins_Claims_Set_Input>;
  pk_columns: User_Daily_Coins_Claims_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Daily_Coins_Claims_ManyArgs = {
  updates: Array<User_Daily_Coins_Claims_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Daily_Reward_CountsArgs = {
  _inc?: InputMaybe<User_Daily_Reward_Counts_Inc_Input>;
  _set?: InputMaybe<User_Daily_Reward_Counts_Set_Input>;
  where: User_Daily_Reward_Counts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Daily_Reward_Counts_By_PkArgs = {
  _inc?: InputMaybe<User_Daily_Reward_Counts_Inc_Input>;
  _set?: InputMaybe<User_Daily_Reward_Counts_Set_Input>;
  pk_columns: User_Daily_Reward_Counts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Daily_Reward_Counts_ManyArgs = {
  updates: Array<User_Daily_Reward_Counts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Email_Verification_DataArgs = {
  _set?: InputMaybe<User_Email_Verification_Data_Set_Input>;
  where: User_Email_Verification_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Email_Verification_Data_By_PkArgs = {
  _set?: InputMaybe<User_Email_Verification_Data_Set_Input>;
  pk_columns: User_Email_Verification_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Email_Verification_Data_ManyArgs = {
  updates: Array<User_Email_Verification_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_EventsArgs = {
  _append?: InputMaybe<User_Events_Append_Input>;
  _delete_at_path?: InputMaybe<User_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Events_Prepend_Input>;
  _set?: InputMaybe<User_Events_Set_Input>;
  where: User_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Events_By_PkArgs = {
  _append?: InputMaybe<User_Events_Append_Input>;
  _delete_at_path?: InputMaybe<User_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Events_Prepend_Input>;
  _set?: InputMaybe<User_Events_Set_Input>;
  pk_columns: User_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Events_ManyArgs = {
  updates: Array<User_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Got_BonusArgs = {
  _set?: InputMaybe<User_Got_Bonus_Set_Input>;
  where: User_Got_Bonus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Got_Bonus_By_PkArgs = {
  _set?: InputMaybe<User_Got_Bonus_Set_Input>;
  pk_columns: User_Got_Bonus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Got_Bonus_ManyArgs = {
  updates: Array<User_Got_Bonus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_InventoryArgs = {
  _set?: InputMaybe<User_Inventory_Set_Input>;
  where: User_Inventory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Inventory_By_PkArgs = {
  _set?: InputMaybe<User_Inventory_Set_Input>;
  pk_columns: User_Inventory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Inventory_ManyArgs = {
  updates: Array<User_Inventory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Inventory_TransactionsArgs = {
  _inc?: InputMaybe<User_Inventory_Transactions_Inc_Input>;
  _set?: InputMaybe<User_Inventory_Transactions_Set_Input>;
  where: User_Inventory_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Inventory_Transactions_By_PkArgs = {
  _inc?: InputMaybe<User_Inventory_Transactions_Inc_Input>;
  _set?: InputMaybe<User_Inventory_Transactions_Set_Input>;
  pk_columns: User_Inventory_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Inventory_Transactions_ManyArgs = {
  updates: Array<User_Inventory_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Items_In_InventoryArgs = {
  _append?: InputMaybe<User_Items_In_Inventory_Append_Input>;
  _delete_at_path?: InputMaybe<User_Items_In_Inventory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Items_In_Inventory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Items_In_Inventory_Delete_Key_Input>;
  _inc?: InputMaybe<User_Items_In_Inventory_Inc_Input>;
  _prepend?: InputMaybe<User_Items_In_Inventory_Prepend_Input>;
  _set?: InputMaybe<User_Items_In_Inventory_Set_Input>;
  where: User_Items_In_Inventory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Items_In_Inventory_By_PkArgs = {
  _append?: InputMaybe<User_Items_In_Inventory_Append_Input>;
  _delete_at_path?: InputMaybe<User_Items_In_Inventory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Items_In_Inventory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Items_In_Inventory_Delete_Key_Input>;
  _inc?: InputMaybe<User_Items_In_Inventory_Inc_Input>;
  _prepend?: InputMaybe<User_Items_In_Inventory_Prepend_Input>;
  _set?: InputMaybe<User_Items_In_Inventory_Set_Input>;
  pk_columns: User_Items_In_Inventory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Items_In_Inventory_ManyArgs = {
  updates: Array<User_Items_In_Inventory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Lootbox_Opens_CountArgs = {
  _inc?: InputMaybe<User_Lootbox_Opens_Count_Inc_Input>;
  _set?: InputMaybe<User_Lootbox_Opens_Count_Set_Input>;
  where: User_Lootbox_Opens_Count_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Lootbox_Opens_Count_By_PkArgs = {
  _inc?: InputMaybe<User_Lootbox_Opens_Count_Inc_Input>;
  _set?: InputMaybe<User_Lootbox_Opens_Count_Set_Input>;
  pk_columns: User_Lootbox_Opens_Count_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Lootbox_Opens_Count_ManyArgs = {
  updates: Array<User_Lootbox_Opens_Count_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_MarkingArgs = {
  _set?: InputMaybe<User_Marking_Set_Input>;
  where: User_Marking_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Marking_By_PkArgs = {
  _set?: InputMaybe<User_Marking_Set_Input>;
  pk_columns: User_Marking_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Marking_ManyArgs = {
  updates: Array<User_Marking_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_MarkingsArgs = {
  _set?: InputMaybe<User_Markings_Set_Input>;
  where: User_Markings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Markings_By_PkArgs = {
  _set?: InputMaybe<User_Markings_Set_Input>;
  pk_columns: User_Markings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Markings_ManyArgs = {
  updates: Array<User_Markings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Output_LimitsArgs = {
  _inc?: InputMaybe<User_Output_Limits_Inc_Input>;
  _set?: InputMaybe<User_Output_Limits_Set_Input>;
  where: User_Output_Limits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Output_Limits_By_PkArgs = {
  _inc?: InputMaybe<User_Output_Limits_Inc_Input>;
  _set?: InputMaybe<User_Output_Limits_Set_Input>;
  pk_columns: User_Output_Limits_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Output_Limits_ManyArgs = {
  updates: Array<User_Output_Limits_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_ProfileArgs = {
  _inc?: InputMaybe<User_Profile_Inc_Input>;
  _set?: InputMaybe<User_Profile_Set_Input>;
  where: User_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Profile_By_PkArgs = {
  _inc?: InputMaybe<User_Profile_Inc_Input>;
  _set?: InputMaybe<User_Profile_Set_Input>;
  pk_columns: User_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Profile_ManyArgs = {
  updates: Array<User_Profile_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Push_TokensArgs = {
  _set?: InputMaybe<User_Push_Tokens_Set_Input>;
  where: User_Push_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Push_Tokens_By_PkArgs = {
  _set?: InputMaybe<User_Push_Tokens_Set_Input>;
  pk_columns: User_Push_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Push_Tokens_ManyArgs = {
  updates: Array<User_Push_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Push_TopicsArgs = {
  _set?: InputMaybe<User_Push_Topics_Set_Input>;
  where: User_Push_Topics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Push_Topics_By_PkArgs = {
  _set?: InputMaybe<User_Push_Topics_Set_Input>;
  pk_columns: User_Push_Topics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Push_Topics_ManyArgs = {
  updates: Array<User_Push_Topics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referal_Levels_ParamsArgs = {
  _inc?: InputMaybe<User_Referal_Levels_Params_Inc_Input>;
  _set?: InputMaybe<User_Referal_Levels_Params_Set_Input>;
  where: User_Referal_Levels_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referal_Levels_Params_By_PkArgs = {
  _inc?: InputMaybe<User_Referal_Levels_Params_Inc_Input>;
  _set?: InputMaybe<User_Referal_Levels_Params_Set_Input>;
  pk_columns: User_Referal_Levels_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referal_Levels_Params_ManyArgs = {
  updates: Array<User_Referal_Levels_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referal_ParamsArgs = {
  _inc?: InputMaybe<User_Referal_Params_Inc_Input>;
  _set?: InputMaybe<User_Referal_Params_Set_Input>;
  where: User_Referal_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referal_Params_By_PkArgs = {
  _inc?: InputMaybe<User_Referal_Params_Inc_Input>;
  _set?: InputMaybe<User_Referal_Params_Set_Input>;
  pk_columns: User_Referal_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referal_Params_ManyArgs = {
  updates: Array<User_Referal_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_ReferalsArgs = {
  _inc?: InputMaybe<User_Referals_Inc_Input>;
  _set?: InputMaybe<User_Referals_Set_Input>;
  where: User_Referals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referals_Balance_TransactionsArgs = {
  _append?: InputMaybe<User_Referals_Balance_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<User_Referals_Balance_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Referals_Balance_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Referals_Balance_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<User_Referals_Balance_Transactions_Inc_Input>;
  _prepend?: InputMaybe<User_Referals_Balance_Transactions_Prepend_Input>;
  _set?: InputMaybe<User_Referals_Balance_Transactions_Set_Input>;
  where: User_Referals_Balance_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referals_Balance_Transactions_By_PkArgs = {
  _append?: InputMaybe<User_Referals_Balance_Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<User_Referals_Balance_Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Referals_Balance_Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Referals_Balance_Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<User_Referals_Balance_Transactions_Inc_Input>;
  _prepend?: InputMaybe<User_Referals_Balance_Transactions_Prepend_Input>;
  _set?: InputMaybe<User_Referals_Balance_Transactions_Set_Input>;
  pk_columns: User_Referals_Balance_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referals_Balance_Transactions_ManyArgs = {
  updates: Array<User_Referals_Balance_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referals_Bonus_HistoryArgs = {
  _inc?: InputMaybe<User_Referals_Bonus_History_Inc_Input>;
  _set?: InputMaybe<User_Referals_Bonus_History_Set_Input>;
  where: User_Referals_Bonus_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referals_Bonus_History_By_PkArgs = {
  _inc?: InputMaybe<User_Referals_Bonus_History_Inc_Input>;
  _set?: InputMaybe<User_Referals_Bonus_History_Set_Input>;
  pk_columns: User_Referals_Bonus_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referals_Bonus_History_ManyArgs = {
  updates: Array<User_Referals_Bonus_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referals_By_PkArgs = {
  _inc?: InputMaybe<User_Referals_Inc_Input>;
  _set?: InputMaybe<User_Referals_Set_Input>;
  pk_columns: User_Referals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referals_ManyArgs = {
  updates: Array<User_Referals_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_ReferralArgs = {
  _set?: InputMaybe<User_Referral_Set_Input>;
  where: User_Referral_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_AssociationArgs = {
  _set?: InputMaybe<User_Referral_Association_Set_Input>;
  where: User_Referral_Association_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Association_By_PkArgs = {
  _set?: InputMaybe<User_Referral_Association_Set_Input>;
  pk_columns: User_Referral_Association_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Association_ManyArgs = {
  updates: Array<User_Referral_Association_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Bonus_TypeArgs = {
  _inc?: InputMaybe<User_Referral_Bonus_Type_Inc_Input>;
  _set?: InputMaybe<User_Referral_Bonus_Type_Set_Input>;
  where: User_Referral_Bonus_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Bonus_Type_By_PkArgs = {
  _inc?: InputMaybe<User_Referral_Bonus_Type_Inc_Input>;
  _set?: InputMaybe<User_Referral_Bonus_Type_Set_Input>;
  pk_columns: User_Referral_Bonus_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Bonus_Type_ManyArgs = {
  updates: Array<User_Referral_Bonus_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_By_PkArgs = {
  _set?: InputMaybe<User_Referral_Set_Input>;
  pk_columns: User_Referral_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Levels_ParamsArgs = {
  _inc?: InputMaybe<User_Referral_Levels_Params_Inc_Input>;
  _set?: InputMaybe<User_Referral_Levels_Params_Set_Input>;
  where: User_Referral_Levels_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Levels_Params_By_PkArgs = {
  _inc?: InputMaybe<User_Referral_Levels_Params_Inc_Input>;
  _set?: InputMaybe<User_Referral_Levels_Params_Set_Input>;
  pk_columns: User_Referral_Levels_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Levels_Params_ManyArgs = {
  updates: Array<User_Referral_Levels_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_ManyArgs = {
  updates: Array<User_Referral_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Offer_BonusArgs = {
  _set?: InputMaybe<User_Referral_Offer_Bonus_Set_Input>;
  where: User_Referral_Offer_Bonus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Offer_Bonus_By_PkArgs = {
  _set?: InputMaybe<User_Referral_Offer_Bonus_Set_Input>;
  pk_columns: User_Referral_Offer_Bonus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Offer_Bonus_ManyArgs = {
  updates: Array<User_Referral_Offer_Bonus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_OffersArgs = {
  _set?: InputMaybe<User_Referral_Offers_Set_Input>;
  where: User_Referral_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Offers_By_PkArgs = {
  _set?: InputMaybe<User_Referral_Offers_Set_Input>;
  pk_columns: User_Referral_Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Offers_ManyArgs = {
  updates: Array<User_Referral_Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_ParamsArgs = {
  _inc?: InputMaybe<User_Referral_Params_Inc_Input>;
  _set?: InputMaybe<User_Referral_Params_Set_Input>;
  where: User_Referral_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Params_By_PkArgs = {
  _inc?: InputMaybe<User_Referral_Params_Inc_Input>;
  _set?: InputMaybe<User_Referral_Params_Set_Input>;
  pk_columns: User_Referral_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referral_Params_ManyArgs = {
  updates: Array<User_Referral_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referrals_Balance_TransactionsArgs = {
  _inc?: InputMaybe<User_Referrals_Balance_Transactions_Inc_Input>;
  _set?: InputMaybe<User_Referrals_Balance_Transactions_Set_Input>;
  where: User_Referrals_Balance_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referrals_Balance_Transactions_By_PkArgs = {
  _inc?: InputMaybe<User_Referrals_Balance_Transactions_Inc_Input>;
  _set?: InputMaybe<User_Referrals_Balance_Transactions_Set_Input>;
  pk_columns: User_Referrals_Balance_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Referrals_Balance_Transactions_ManyArgs = {
  updates: Array<User_Referrals_Balance_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Seq_NumberArgs = {
  _inc?: InputMaybe<User_Seq_Number_Inc_Input>;
  _set?: InputMaybe<User_Seq_Number_Set_Input>;
  where: User_Seq_Number_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Seq_Number_By_PkArgs = {
  _inc?: InputMaybe<User_Seq_Number_Inc_Input>;
  _set?: InputMaybe<User_Seq_Number_Set_Input>;
  pk_columns: User_Seq_Number_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Seq_Number_ManyArgs = {
  updates: Array<User_Seq_Number_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_SettingsArgs = {
  _set?: InputMaybe<User_Settings_Set_Input>;
  where: User_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Settings_By_PkArgs = {
  _set?: InputMaybe<User_Settings_Set_Input>;
  pk_columns: User_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Settings_ManyArgs = {
  updates: Array<User_Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_StatisticArgs = {
  _append?: InputMaybe<User_Statistic_Append_Input>;
  _delete_at_path?: InputMaybe<User_Statistic_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Statistic_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Statistic_Delete_Key_Input>;
  _inc?: InputMaybe<User_Statistic_Inc_Input>;
  _prepend?: InputMaybe<User_Statistic_Prepend_Input>;
  _set?: InputMaybe<User_Statistic_Set_Input>;
  where: User_Statistic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Statistic_By_PkArgs = {
  _append?: InputMaybe<User_Statistic_Append_Input>;
  _delete_at_path?: InputMaybe<User_Statistic_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Statistic_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Statistic_Delete_Key_Input>;
  _inc?: InputMaybe<User_Statistic_Inc_Input>;
  _prepend?: InputMaybe<User_Statistic_Prepend_Input>;
  _set?: InputMaybe<User_Statistic_Set_Input>;
  pk_columns: User_Statistic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Statistic_ManyArgs = {
  updates: Array<User_Statistic_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Steam_Output_AlternativesArgs = {
  _append?: InputMaybe<User_Steam_Output_Alternatives_Append_Input>;
  _delete_at_path?: InputMaybe<User_Steam_Output_Alternatives_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Steam_Output_Alternatives_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Steam_Output_Alternatives_Delete_Key_Input>;
  _inc?: InputMaybe<User_Steam_Output_Alternatives_Inc_Input>;
  _prepend?: InputMaybe<User_Steam_Output_Alternatives_Prepend_Input>;
  _set?: InputMaybe<User_Steam_Output_Alternatives_Set_Input>;
  where: User_Steam_Output_Alternatives_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Steam_Output_Alternatives_By_PkArgs = {
  _append?: InputMaybe<User_Steam_Output_Alternatives_Append_Input>;
  _delete_at_path?: InputMaybe<User_Steam_Output_Alternatives_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Steam_Output_Alternatives_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Steam_Output_Alternatives_Delete_Key_Input>;
  _inc?: InputMaybe<User_Steam_Output_Alternatives_Inc_Input>;
  _prepend?: InputMaybe<User_Steam_Output_Alternatives_Prepend_Input>;
  _set?: InputMaybe<User_Steam_Output_Alternatives_Set_Input>;
  pk_columns: User_Steam_Output_Alternatives_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Steam_Output_Alternatives_ManyArgs = {
  updates: Array<User_Steam_Output_Alternatives_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Steam_Output_WatcherArgs = {
  _append?: InputMaybe<User_Steam_Output_Watcher_Append_Input>;
  _delete_at_path?: InputMaybe<User_Steam_Output_Watcher_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Steam_Output_Watcher_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Steam_Output_Watcher_Delete_Key_Input>;
  _inc?: InputMaybe<User_Steam_Output_Watcher_Inc_Input>;
  _prepend?: InputMaybe<User_Steam_Output_Watcher_Prepend_Input>;
  _set?: InputMaybe<User_Steam_Output_Watcher_Set_Input>;
  where: User_Steam_Output_Watcher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Steam_Output_Watcher_By_PkArgs = {
  _append?: InputMaybe<User_Steam_Output_Watcher_Append_Input>;
  _delete_at_path?: InputMaybe<User_Steam_Output_Watcher_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Steam_Output_Watcher_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Steam_Output_Watcher_Delete_Key_Input>;
  _inc?: InputMaybe<User_Steam_Output_Watcher_Inc_Input>;
  _prepend?: InputMaybe<User_Steam_Output_Watcher_Prepend_Input>;
  _set?: InputMaybe<User_Steam_Output_Watcher_Set_Input>;
  pk_columns: User_Steam_Output_Watcher_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Steam_Output_Watcher_ManyArgs = {
  updates: Array<User_Steam_Output_Watcher_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Takes_Free_LootboxArgs = {
  _set?: InputMaybe<User_Takes_Free_Lootbox_Set_Input>;
  where: User_Takes_Free_Lootbox_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Takes_Free_Lootbox_By_PkArgs = {
  _set?: InputMaybe<User_Takes_Free_Lootbox_Set_Input>;
  pk_columns: User_Takes_Free_Lootbox_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Takes_Free_Lootbox_ManyArgs = {
  updates: Array<User_Takes_Free_Lootbox_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Upgrade_ParamsArgs = {
  _inc?: InputMaybe<User_Upgrade_Params_Inc_Input>;
  _set?: InputMaybe<User_Upgrade_Params_Set_Input>;
  where: User_Upgrade_Params_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Upgrade_Params_By_PkArgs = {
  _inc?: InputMaybe<User_Upgrade_Params_Inc_Input>;
  _set?: InputMaybe<User_Upgrade_Params_Set_Input>;
  pk_columns: User_Upgrade_Params_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Upgrade_Params_ManyArgs = {
  updates: Array<User_Upgrade_Params_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Upgrade_ResultsArgs = {
  _append?: InputMaybe<User_Upgrade_Results_Append_Input>;
  _delete_at_path?: InputMaybe<User_Upgrade_Results_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Upgrade_Results_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Upgrade_Results_Delete_Key_Input>;
  _inc?: InputMaybe<User_Upgrade_Results_Inc_Input>;
  _prepend?: InputMaybe<User_Upgrade_Results_Prepend_Input>;
  _set?: InputMaybe<User_Upgrade_Results_Set_Input>;
  where: User_Upgrade_Results_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Upgrade_Results_By_PkArgs = {
  _append?: InputMaybe<User_Upgrade_Results_Append_Input>;
  _delete_at_path?: InputMaybe<User_Upgrade_Results_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Upgrade_Results_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Upgrade_Results_Delete_Key_Input>;
  _inc?: InputMaybe<User_Upgrade_Results_Inc_Input>;
  _prepend?: InputMaybe<User_Upgrade_Results_Prepend_Input>;
  _set?: InputMaybe<User_Upgrade_Results_Set_Input>;
  pk_columns: User_Upgrade_Results_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Upgrade_Results_ManyArgs = {
  updates: Array<User_Upgrade_Results_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Vk_DataArgs = {
  _inc?: InputMaybe<User_Vk_Data_Inc_Input>;
  _set?: InputMaybe<User_Vk_Data_Set_Input>;
  where: User_Vk_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Vk_Data_By_PkArgs = {
  _inc?: InputMaybe<User_Vk_Data_Inc_Input>;
  _set?: InputMaybe<User_Vk_Data_Set_Input>;
  pk_columns: User_Vk_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Vk_Data_ManyArgs = {
  updates: Array<User_Vk_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_WalletArgs = {
  _inc?: InputMaybe<User_Wallet_Inc_Input>;
  _set?: InputMaybe<User_Wallet_Set_Input>;
  where: User_Wallet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Wallet_AuditArgs = {
  _inc?: InputMaybe<User_Wallet_Audit_Inc_Input>;
  _set?: InputMaybe<User_Wallet_Audit_Set_Input>;
  where: User_Wallet_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Wallet_Audit_By_PkArgs = {
  _inc?: InputMaybe<User_Wallet_Audit_Inc_Input>;
  _set?: InputMaybe<User_Wallet_Audit_Set_Input>;
  pk_columns: User_Wallet_Audit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Wallet_Audit_ManyArgs = {
  updates: Array<User_Wallet_Audit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Wallet_By_PkArgs = {
  _inc?: InputMaybe<User_Wallet_Inc_Input>;
  _set?: InputMaybe<User_Wallet_Set_Input>;
  pk_columns: User_Wallet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Wallet_ManyArgs = {
  updates: Array<User_Wallet_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Wallet_TransactionsArgs = {
  _inc?: InputMaybe<User_Wallet_Transactions_Inc_Input>;
  _set?: InputMaybe<User_Wallet_Transactions_Set_Input>;
  where: User_Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Wallet_Transactions_By_PkArgs = {
  _inc?: InputMaybe<User_Wallet_Transactions_Inc_Input>;
  _set?: InputMaybe<User_Wallet_Transactions_Set_Input>;
  pk_columns: User_Wallet_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Wallet_Transactions_ManyArgs = {
  updates: Array<User_Wallet_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_WhitelistArgs = {
  _set?: InputMaybe<User_Whitelist_Set_Input>;
  where: User_Whitelist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Whitelist_By_PkArgs = {
  _set?: InputMaybe<User_Whitelist_Set_Input>;
  pk_columns: User_Whitelist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Whitelist_ManyArgs = {
  updates: Array<User_Whitelist_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Utm_Visits_DataArgs = {
  _append?: InputMaybe<Utm_Visits_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Utm_Visits_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Utm_Visits_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Utm_Visits_Data_Delete_Key_Input>;
  _inc?: InputMaybe<Utm_Visits_Data_Inc_Input>;
  _prepend?: InputMaybe<Utm_Visits_Data_Prepend_Input>;
  _set?: InputMaybe<Utm_Visits_Data_Set_Input>;
  where: Utm_Visits_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Utm_Visits_Data_By_PkArgs = {
  _append?: InputMaybe<Utm_Visits_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Utm_Visits_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Utm_Visits_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Utm_Visits_Data_Delete_Key_Input>;
  _inc?: InputMaybe<Utm_Visits_Data_Inc_Input>;
  _prepend?: InputMaybe<Utm_Visits_Data_Prepend_Input>;
  _set?: InputMaybe<Utm_Visits_Data_Set_Input>;
  pk_columns: Utm_Visits_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Utm_Visits_Data_ManyArgs = {
  updates: Array<Utm_Visits_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Autosending_TypesArgs = {
  _set?: InputMaybe<Webpushes_Autosending_Types_Set_Input>;
  where: Webpushes_Autosending_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Autosending_Types_By_PkArgs = {
  _set?: InputMaybe<Webpushes_Autosending_Types_Set_Input>;
  pk_columns: Webpushes_Autosending_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Autosending_Types_LocalizationArgs = {
  _set?: InputMaybe<Webpushes_Autosending_Types_Localization_Set_Input>;
  where: Webpushes_Autosending_Types_Localization_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Autosending_Types_Localization_By_PkArgs = {
  _set?: InputMaybe<Webpushes_Autosending_Types_Localization_Set_Input>;
  pk_columns: Webpushes_Autosending_Types_Localization_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Autosending_Types_Localization_ManyArgs = {
  updates: Array<Webpushes_Autosending_Types_Localization_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Autosending_Types_ManyArgs = {
  updates: Array<Webpushes_Autosending_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_BonusesArgs = {
  _append?: InputMaybe<Webpushes_Bonuses_Append_Input>;
  _delete_at_path?: InputMaybe<Webpushes_Bonuses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Webpushes_Bonuses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Webpushes_Bonuses_Delete_Key_Input>;
  _prepend?: InputMaybe<Webpushes_Bonuses_Prepend_Input>;
  _set?: InputMaybe<Webpushes_Bonuses_Set_Input>;
  where: Webpushes_Bonuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Bonuses_By_PkArgs = {
  _append?: InputMaybe<Webpushes_Bonuses_Append_Input>;
  _delete_at_path?: InputMaybe<Webpushes_Bonuses_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Webpushes_Bonuses_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Webpushes_Bonuses_Delete_Key_Input>;
  _prepend?: InputMaybe<Webpushes_Bonuses_Prepend_Input>;
  _set?: InputMaybe<Webpushes_Bonuses_Set_Input>;
  pk_columns: Webpushes_Bonuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Bonuses_ManyArgs = {
  updates: Array<Webpushes_Bonuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Bonuses_OffersArgs = {
  _inc?: InputMaybe<Webpushes_Bonuses_Offers_Inc_Input>;
  _set?: InputMaybe<Webpushes_Bonuses_Offers_Set_Input>;
  where: Webpushes_Bonuses_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Bonuses_Offers_By_PkArgs = {
  _inc?: InputMaybe<Webpushes_Bonuses_Offers_Inc_Input>;
  _set?: InputMaybe<Webpushes_Bonuses_Offers_Set_Input>;
  pk_columns: Webpushes_Bonuses_Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Bonuses_Offers_ManyArgs = {
  updates: Array<Webpushes_Bonuses_Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_LinesArgs = {
  _inc?: InputMaybe<Webpushes_Lines_Inc_Input>;
  _set?: InputMaybe<Webpushes_Lines_Set_Input>;
  where: Webpushes_Lines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Lines_By_PkArgs = {
  _inc?: InputMaybe<Webpushes_Lines_Inc_Input>;
  _set?: InputMaybe<Webpushes_Lines_Set_Input>;
  pk_columns: Webpushes_Lines_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Lines_ManyArgs = {
  updates: Array<Webpushes_Lines_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_SchedulingArgs = {
  _set?: InputMaybe<Webpushes_Scheduling_Set_Input>;
  where: Webpushes_Scheduling_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Scheduling_By_PkArgs = {
  _set?: InputMaybe<Webpushes_Scheduling_Set_Input>;
  pk_columns: Webpushes_Scheduling_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Scheduling_ManyArgs = {
  updates: Array<Webpushes_Scheduling_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_TriggersArgs = {
  _set?: InputMaybe<Webpushes_Triggers_Set_Input>;
  where: Webpushes_Triggers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Triggers_By_PkArgs = {
  _set?: InputMaybe<Webpushes_Triggers_Set_Input>;
  pk_columns: Webpushes_Triggers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Triggers_GroupsArgs = {
  _set?: InputMaybe<Webpushes_Triggers_Groups_Set_Input>;
  where: Webpushes_Triggers_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Triggers_Groups_By_PkArgs = {
  _set?: InputMaybe<Webpushes_Triggers_Groups_Set_Input>;
  pk_columns: Webpushes_Triggers_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Triggers_Groups_ManyArgs = {
  updates: Array<Webpushes_Triggers_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Webpushes_Triggers_ManyArgs = {
  updates: Array<Webpushes_Triggers_Updates>;
};

/** Вознаграждение за сжигание бустеров в новогодней акции */
export type New_Year_Competition_Boosters_Rewards = {
  __typename?: 'new_year_competition_boosters_rewards';
  booster_id: Scalars['uuid'];
  booster_image_link?: Maybe<Scalars['String']>;
  cost: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  lootbox_booster_item: Lootbox_Booster_Items;
  original_cost?: Maybe<Scalars['Int']>;
  prize: Scalars['Int'];
  score: Scalars['Int'];
};

/** aggregated selection of "new_year_competition_boosters_rewards" */
export type New_Year_Competition_Boosters_Rewards_Aggregate = {
  __typename?: 'new_year_competition_boosters_rewards_aggregate';
  aggregate?: Maybe<New_Year_Competition_Boosters_Rewards_Aggregate_Fields>;
  nodes: Array<New_Year_Competition_Boosters_Rewards>;
};

/** aggregate fields of "new_year_competition_boosters_rewards" */
export type New_Year_Competition_Boosters_Rewards_Aggregate_Fields = {
  __typename?: 'new_year_competition_boosters_rewards_aggregate_fields';
  avg?: Maybe<New_Year_Competition_Boosters_Rewards_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<New_Year_Competition_Boosters_Rewards_Max_Fields>;
  min?: Maybe<New_Year_Competition_Boosters_Rewards_Min_Fields>;
  stddev?: Maybe<New_Year_Competition_Boosters_Rewards_Stddev_Fields>;
  stddev_pop?: Maybe<New_Year_Competition_Boosters_Rewards_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<New_Year_Competition_Boosters_Rewards_Stddev_Samp_Fields>;
  sum?: Maybe<New_Year_Competition_Boosters_Rewards_Sum_Fields>;
  var_pop?: Maybe<New_Year_Competition_Boosters_Rewards_Var_Pop_Fields>;
  var_samp?: Maybe<New_Year_Competition_Boosters_Rewards_Var_Samp_Fields>;
  variance?: Maybe<New_Year_Competition_Boosters_Rewards_Variance_Fields>;
};


/** aggregate fields of "new_year_competition_boosters_rewards" */
export type New_Year_Competition_Boosters_Rewards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<New_Year_Competition_Boosters_Rewards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type New_Year_Competition_Boosters_Rewards_Avg_Fields = {
  __typename?: 'new_year_competition_boosters_rewards_avg_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_cost?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "new_year_competition_boosters_rewards". All fields are combined with a logical 'AND'. */
export type New_Year_Competition_Boosters_Rewards_Bool_Exp = {
  _and?: InputMaybe<Array<New_Year_Competition_Boosters_Rewards_Bool_Exp>>;
  _not?: InputMaybe<New_Year_Competition_Boosters_Rewards_Bool_Exp>;
  _or?: InputMaybe<Array<New_Year_Competition_Boosters_Rewards_Bool_Exp>>;
  booster_id?: InputMaybe<Uuid_Comparison_Exp>;
  booster_image_link?: InputMaybe<String_Comparison_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lootbox_booster_item?: InputMaybe<Lootbox_Booster_Items_Bool_Exp>;
  original_cost?: InputMaybe<Int_Comparison_Exp>;
  prize?: InputMaybe<Int_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "new_year_competition_boosters_rewards" */
export enum New_Year_Competition_Boosters_Rewards_Constraint {
  /** unique or primary key constraint on columns "booster_id" */
  NewYearCompetitionBoostersRewardsBoosterIdKey = 'new_year_competition_boosters_rewards_booster_id_key',
  /** unique or primary key constraint on columns "cost" */
  NewYearCompetitionBoostersRewardsCostKey = 'new_year_competition_boosters_rewards_cost_key',
  /** unique or primary key constraint on columns "id" */
  NewYearCompetitionBoostersRewardsPkey = 'new_year_competition_boosters_rewards_pkey',
  /** unique or primary key constraint on columns "prize" */
  NewYearCompetitionBoostersRewardsPrizeKey = 'new_year_competition_boosters_rewards_prize_key',
  /** unique or primary key constraint on columns "score" */
  NewYearCompetitionBoostersRewardsScoreKey = 'new_year_competition_boosters_rewards_score_key'
}

/** input type for incrementing numeric columns in table "new_year_competition_boosters_rewards" */
export type New_Year_Competition_Boosters_Rewards_Inc_Input = {
  cost?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  original_cost?: InputMaybe<Scalars['Int']>;
  prize?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "new_year_competition_boosters_rewards" */
export type New_Year_Competition_Boosters_Rewards_Insert_Input = {
  booster_id?: InputMaybe<Scalars['uuid']>;
  booster_image_link?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lootbox_booster_item?: InputMaybe<Lootbox_Booster_Items_Obj_Rel_Insert_Input>;
  original_cost?: InputMaybe<Scalars['Int']>;
  prize?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type New_Year_Competition_Boosters_Rewards_Max_Fields = {
  __typename?: 'new_year_competition_boosters_rewards_max_fields';
  booster_id?: Maybe<Scalars['uuid']>;
  booster_image_link?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  original_cost?: Maybe<Scalars['Int']>;
  prize?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type New_Year_Competition_Boosters_Rewards_Min_Fields = {
  __typename?: 'new_year_competition_boosters_rewards_min_fields';
  booster_id?: Maybe<Scalars['uuid']>;
  booster_image_link?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  original_cost?: Maybe<Scalars['Int']>;
  prize?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "new_year_competition_boosters_rewards" */
export type New_Year_Competition_Boosters_Rewards_Mutation_Response = {
  __typename?: 'new_year_competition_boosters_rewards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<New_Year_Competition_Boosters_Rewards>;
};

/** input type for inserting object relation for remote table "new_year_competition_boosters_rewards" */
export type New_Year_Competition_Boosters_Rewards_Obj_Rel_Insert_Input = {
  data: New_Year_Competition_Boosters_Rewards_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<New_Year_Competition_Boosters_Rewards_On_Conflict>;
};

/** on_conflict condition type for table "new_year_competition_boosters_rewards" */
export type New_Year_Competition_Boosters_Rewards_On_Conflict = {
  constraint: New_Year_Competition_Boosters_Rewards_Constraint;
  update_columns?: Array<New_Year_Competition_Boosters_Rewards_Update_Column>;
  where?: InputMaybe<New_Year_Competition_Boosters_Rewards_Bool_Exp>;
};

/** Ordering options when selecting data from "new_year_competition_boosters_rewards". */
export type New_Year_Competition_Boosters_Rewards_Order_By = {
  booster_id?: InputMaybe<Order_By>;
  booster_image_link?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lootbox_booster_item?: InputMaybe<Lootbox_Booster_Items_Order_By>;
  original_cost?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** primary key columns input for table: new_year_competition_boosters_rewards */
export type New_Year_Competition_Boosters_Rewards_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "new_year_competition_boosters_rewards" */
export enum New_Year_Competition_Boosters_Rewards_Select_Column {
  /** column name */
  BoosterId = 'booster_id',
  /** column name */
  BoosterImageLink = 'booster_image_link',
  /** column name */
  Cost = 'cost',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalCost = 'original_cost',
  /** column name */
  Prize = 'prize',
  /** column name */
  Score = 'score'
}

/** input type for updating data in table "new_year_competition_boosters_rewards" */
export type New_Year_Competition_Boosters_Rewards_Set_Input = {
  booster_id?: InputMaybe<Scalars['uuid']>;
  booster_image_link?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  original_cost?: InputMaybe<Scalars['Int']>;
  prize?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type New_Year_Competition_Boosters_Rewards_Stddev_Fields = {
  __typename?: 'new_year_competition_boosters_rewards_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_cost?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type New_Year_Competition_Boosters_Rewards_Stddev_Pop_Fields = {
  __typename?: 'new_year_competition_boosters_rewards_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_cost?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type New_Year_Competition_Boosters_Rewards_Stddev_Samp_Fields = {
  __typename?: 'new_year_competition_boosters_rewards_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_cost?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "new_year_competition_boosters_rewards" */
export type New_Year_Competition_Boosters_Rewards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: New_Year_Competition_Boosters_Rewards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type New_Year_Competition_Boosters_Rewards_Stream_Cursor_Value_Input = {
  booster_id?: InputMaybe<Scalars['uuid']>;
  booster_image_link?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  original_cost?: InputMaybe<Scalars['Int']>;
  prize?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type New_Year_Competition_Boosters_Rewards_Sum_Fields = {
  __typename?: 'new_year_competition_boosters_rewards_sum_fields';
  cost?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  original_cost?: Maybe<Scalars['Int']>;
  prize?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
};

/** update columns of table "new_year_competition_boosters_rewards" */
export enum New_Year_Competition_Boosters_Rewards_Update_Column {
  /** column name */
  BoosterId = 'booster_id',
  /** column name */
  BoosterImageLink = 'booster_image_link',
  /** column name */
  Cost = 'cost',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalCost = 'original_cost',
  /** column name */
  Prize = 'prize',
  /** column name */
  Score = 'score'
}

export type New_Year_Competition_Boosters_Rewards_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<New_Year_Competition_Boosters_Rewards_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<New_Year_Competition_Boosters_Rewards_Set_Input>;
  /** filter the rows which have to be updated */
  where: New_Year_Competition_Boosters_Rewards_Bool_Exp;
};

/** aggregate var_pop on columns */
export type New_Year_Competition_Boosters_Rewards_Var_Pop_Fields = {
  __typename?: 'new_year_competition_boosters_rewards_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_cost?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type New_Year_Competition_Boosters_Rewards_Var_Samp_Fields = {
  __typename?: 'new_year_competition_boosters_rewards_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_cost?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type New_Year_Competition_Boosters_Rewards_Variance_Fields = {
  __typename?: 'new_year_competition_boosters_rewards_variance_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_cost?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Участники у новогодней акции */
export type New_Year_Competition_Participants = {
  __typename?: 'new_year_competition_participants';
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  /** An object relationship */
  new_year_competition_team: New_Year_Competition_Teams;
  team_id: Scalars['Int'];
  /** An object relationship */
  user_account: User_Account;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "new_year_competition_participants" */
export type New_Year_Competition_Participants_Aggregate = {
  __typename?: 'new_year_competition_participants_aggregate';
  aggregate?: Maybe<New_Year_Competition_Participants_Aggregate_Fields>;
  nodes: Array<New_Year_Competition_Participants>;
};

export type New_Year_Competition_Participants_Aggregate_Bool_Exp = {
  count?: InputMaybe<New_Year_Competition_Participants_Aggregate_Bool_Exp_Count>;
};

export type New_Year_Competition_Participants_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<New_Year_Competition_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<New_Year_Competition_Participants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "new_year_competition_participants" */
export type New_Year_Competition_Participants_Aggregate_Fields = {
  __typename?: 'new_year_competition_participants_aggregate_fields';
  avg?: Maybe<New_Year_Competition_Participants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<New_Year_Competition_Participants_Max_Fields>;
  min?: Maybe<New_Year_Competition_Participants_Min_Fields>;
  stddev?: Maybe<New_Year_Competition_Participants_Stddev_Fields>;
  stddev_pop?: Maybe<New_Year_Competition_Participants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<New_Year_Competition_Participants_Stddev_Samp_Fields>;
  sum?: Maybe<New_Year_Competition_Participants_Sum_Fields>;
  var_pop?: Maybe<New_Year_Competition_Participants_Var_Pop_Fields>;
  var_samp?: Maybe<New_Year_Competition_Participants_Var_Samp_Fields>;
  variance?: Maybe<New_Year_Competition_Participants_Variance_Fields>;
};


/** aggregate fields of "new_year_competition_participants" */
export type New_Year_Competition_Participants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<New_Year_Competition_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Aggregate_Order_By = {
  avg?: InputMaybe<New_Year_Competition_Participants_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<New_Year_Competition_Participants_Max_Order_By>;
  min?: InputMaybe<New_Year_Competition_Participants_Min_Order_By>;
  stddev?: InputMaybe<New_Year_Competition_Participants_Stddev_Order_By>;
  stddev_pop?: InputMaybe<New_Year_Competition_Participants_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<New_Year_Competition_Participants_Stddev_Samp_Order_By>;
  sum?: InputMaybe<New_Year_Competition_Participants_Sum_Order_By>;
  var_pop?: InputMaybe<New_Year_Competition_Participants_Var_Pop_Order_By>;
  var_samp?: InputMaybe<New_Year_Competition_Participants_Var_Samp_Order_By>;
  variance?: InputMaybe<New_Year_Competition_Participants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Arr_Rel_Insert_Input = {
  data: Array<New_Year_Competition_Participants_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<New_Year_Competition_Participants_On_Conflict>;
};

/** aggregate avg on columns */
export type New_Year_Competition_Participants_Avg_Fields = {
  __typename?: 'new_year_competition_participants_avg_fields';
  id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "new_year_competition_participants". All fields are combined with a logical 'AND'. */
export type New_Year_Competition_Participants_Bool_Exp = {
  _and?: InputMaybe<Array<New_Year_Competition_Participants_Bool_Exp>>;
  _not?: InputMaybe<New_Year_Competition_Participants_Bool_Exp>;
  _or?: InputMaybe<Array<New_Year_Competition_Participants_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  new_year_competition_team?: InputMaybe<New_Year_Competition_Teams_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  user_account?: InputMaybe<User_Account_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "new_year_competition_participants" */
export enum New_Year_Competition_Participants_Constraint {
  /** unique or primary key constraint on columns "id" */
  NewYearCompetitionParticipantsPkey = 'new_year_competition_participants_pkey',
  /** unique or primary key constraint on columns "user_id" */
  NewYearCompetitionParticipantsUserIdKey = 'new_year_competition_participants_user_id_key'
}

/** input type for incrementing numeric columns in table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  team_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  new_year_competition_team?: InputMaybe<New_Year_Competition_Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['Int']>;
  user_account?: InputMaybe<User_Account_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type New_Year_Competition_Participants_Max_Fields = {
  __typename?: 'new_year_competition_participants_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  team_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type New_Year_Competition_Participants_Min_Fields = {
  __typename?: 'new_year_competition_participants_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  team_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Mutation_Response = {
  __typename?: 'new_year_competition_participants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<New_Year_Competition_Participants>;
};

/** on_conflict condition type for table "new_year_competition_participants" */
export type New_Year_Competition_Participants_On_Conflict = {
  constraint: New_Year_Competition_Participants_Constraint;
  update_columns?: Array<New_Year_Competition_Participants_Update_Column>;
  where?: InputMaybe<New_Year_Competition_Participants_Bool_Exp>;
};

/** Ordering options when selecting data from "new_year_competition_participants". */
export type New_Year_Competition_Participants_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_year_competition_team?: InputMaybe<New_Year_Competition_Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_account?: InputMaybe<User_Account_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Текущие разультаты новогодней акции */
export type New_Year_Competition_Participants_Performance = {
  __typename?: 'new_year_competition_participants_performance';
  booster_id: Scalars['uuid'];
  count: Scalars['Int'];
  created_at: Scalars['timestamp'];
  id: Scalars['bigint'];
  prize: Scalars['Int'];
  score: Scalars['Int'];
  total_prize?: Maybe<Scalars['Int']>;
  total_score?: Maybe<Scalars['Int']>;
  /** An object relationship */
  user_account: User_Account;
  user_id: Scalars['uuid'];
  user_profile?: Maybe<User_Profile>;
};

/** aggregated selection of "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Aggregate = {
  __typename?: 'new_year_competition_participants_performance_aggregate';
  aggregate?: Maybe<New_Year_Competition_Participants_Performance_Aggregate_Fields>;
  nodes: Array<New_Year_Competition_Participants_Performance>;
};

export type New_Year_Competition_Participants_Performance_Aggregate_Bool_Exp = {
  count?: InputMaybe<New_Year_Competition_Participants_Performance_Aggregate_Bool_Exp_Count>;
};

export type New_Year_Competition_Participants_Performance_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<New_Year_Competition_Participants_Performance_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Aggregate_Fields = {
  __typename?: 'new_year_competition_participants_performance_aggregate_fields';
  avg?: Maybe<New_Year_Competition_Participants_Performance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<New_Year_Competition_Participants_Performance_Max_Fields>;
  min?: Maybe<New_Year_Competition_Participants_Performance_Min_Fields>;
  stddev?: Maybe<New_Year_Competition_Participants_Performance_Stddev_Fields>;
  stddev_pop?: Maybe<New_Year_Competition_Participants_Performance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<New_Year_Competition_Participants_Performance_Stddev_Samp_Fields>;
  sum?: Maybe<New_Year_Competition_Participants_Performance_Sum_Fields>;
  var_pop?: Maybe<New_Year_Competition_Participants_Performance_Var_Pop_Fields>;
  var_samp?: Maybe<New_Year_Competition_Participants_Performance_Var_Samp_Fields>;
  variance?: Maybe<New_Year_Competition_Participants_Performance_Variance_Fields>;
};


/** aggregate fields of "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Aggregate_Order_By = {
  avg?: InputMaybe<New_Year_Competition_Participants_Performance_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<New_Year_Competition_Participants_Performance_Max_Order_By>;
  min?: InputMaybe<New_Year_Competition_Participants_Performance_Min_Order_By>;
  stddev?: InputMaybe<New_Year_Competition_Participants_Performance_Stddev_Order_By>;
  stddev_pop?: InputMaybe<New_Year_Competition_Participants_Performance_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<New_Year_Competition_Participants_Performance_Stddev_Samp_Order_By>;
  sum?: InputMaybe<New_Year_Competition_Participants_Performance_Sum_Order_By>;
  var_pop?: InputMaybe<New_Year_Competition_Participants_Performance_Var_Pop_Order_By>;
  var_samp?: InputMaybe<New_Year_Competition_Participants_Performance_Var_Samp_Order_By>;
  variance?: InputMaybe<New_Year_Competition_Participants_Performance_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Arr_Rel_Insert_Input = {
  data: Array<New_Year_Competition_Participants_Performance_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<New_Year_Competition_Participants_Performance_On_Conflict>;
};

/** aggregate avg on columns */
export type New_Year_Competition_Participants_Performance_Avg_Fields = {
  __typename?: 'new_year_competition_participants_performance_avg_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_prize?: Maybe<Scalars['Float']>;
  total_score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Avg_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_prize?: InputMaybe<Order_By>;
  total_score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "new_year_competition_participants_performance". All fields are combined with a logical 'AND'. */
export type New_Year_Competition_Participants_Performance_Bool_Exp = {
  _and?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Bool_Exp>>;
  _not?: InputMaybe<New_Year_Competition_Participants_Performance_Bool_Exp>;
  _or?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Bool_Exp>>;
  booster_id?: InputMaybe<Uuid_Comparison_Exp>;
  count?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  prize?: InputMaybe<Int_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  total_prize?: InputMaybe<Int_Comparison_Exp>;
  total_score?: InputMaybe<Int_Comparison_Exp>;
  user_account?: InputMaybe<User_Account_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "new_year_competition_participants_performance" */
export enum New_Year_Competition_Participants_Performance_Constraint {
  /** unique or primary key constraint on columns "id" */
  NewYearCompetitionParticipantsPerformancePkey = 'new_year_competition_participants_performance_pkey'
}

/** input type for incrementing numeric columns in table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Inc_Input = {
  count?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  prize?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Insert_Input = {
  booster_id?: InputMaybe<Scalars['uuid']>;
  count?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  prize?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  user_account?: InputMaybe<User_Account_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type New_Year_Competition_Participants_Performance_Max_Fields = {
  __typename?: 'new_year_competition_participants_performance_max_fields';
  booster_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  prize?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  total_prize?: Maybe<Scalars['Int']>;
  total_score?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Max_Order_By = {
  booster_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_prize?: InputMaybe<Order_By>;
  total_score?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type New_Year_Competition_Participants_Performance_Min_Fields = {
  __typename?: 'new_year_competition_participants_performance_min_fields';
  booster_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  prize?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  total_prize?: Maybe<Scalars['Int']>;
  total_score?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Min_Order_By = {
  booster_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_prize?: InputMaybe<Order_By>;
  total_score?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Mutation_Response = {
  __typename?: 'new_year_competition_participants_performance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<New_Year_Competition_Participants_Performance>;
};

/** on_conflict condition type for table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_On_Conflict = {
  constraint: New_Year_Competition_Participants_Performance_Constraint;
  update_columns?: Array<New_Year_Competition_Participants_Performance_Update_Column>;
  where?: InputMaybe<New_Year_Competition_Participants_Performance_Bool_Exp>;
};

/** Ordering options when selecting data from "new_year_competition_participants_performance". */
export type New_Year_Competition_Participants_Performance_Order_By = {
  booster_id?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_prize?: InputMaybe<Order_By>;
  total_score?: InputMaybe<Order_By>;
  user_account?: InputMaybe<User_Account_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: new_year_competition_participants_performance */
export type New_Year_Competition_Participants_Performance_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "new_year_competition_participants_performance" */
export enum New_Year_Competition_Participants_Performance_Select_Column {
  /** column name */
  BoosterId = 'booster_id',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Prize = 'prize',
  /** column name */
  Score = 'score',
  /** column name */
  TotalPrize = 'total_prize',
  /** column name */
  TotalScore = 'total_score',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Set_Input = {
  booster_id?: InputMaybe<Scalars['uuid']>;
  count?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  prize?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type New_Year_Competition_Participants_Performance_Stddev_Fields = {
  __typename?: 'new_year_competition_participants_performance_stddev_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_prize?: Maybe<Scalars['Float']>;
  total_score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Stddev_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_prize?: InputMaybe<Order_By>;
  total_score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type New_Year_Competition_Participants_Performance_Stddev_Pop_Fields = {
  __typename?: 'new_year_competition_participants_performance_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_prize?: Maybe<Scalars['Float']>;
  total_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Stddev_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_prize?: InputMaybe<Order_By>;
  total_score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type New_Year_Competition_Participants_Performance_Stddev_Samp_Fields = {
  __typename?: 'new_year_competition_participants_performance_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_prize?: Maybe<Scalars['Float']>;
  total_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Stddev_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_prize?: InputMaybe<Order_By>;
  total_score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: New_Year_Competition_Participants_Performance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type New_Year_Competition_Participants_Performance_Stream_Cursor_Value_Input = {
  booster_id?: InputMaybe<Scalars['uuid']>;
  count?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  prize?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['Int']>;
  total_prize?: InputMaybe<Scalars['Int']>;
  total_score?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type New_Year_Competition_Participants_Performance_Sum_Fields = {
  __typename?: 'new_year_competition_participants_performance_sum_fields';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  prize?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  total_prize?: Maybe<Scalars['Int']>;
  total_score?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Sum_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_prize?: InputMaybe<Order_By>;
  total_score?: InputMaybe<Order_By>;
};

/** update columns of table "new_year_competition_participants_performance" */
export enum New_Year_Competition_Participants_Performance_Update_Column {
  /** column name */
  BoosterId = 'booster_id',
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Prize = 'prize',
  /** column name */
  Score = 'score',
  /** column name */
  UserId = 'user_id'
}

export type New_Year_Competition_Participants_Performance_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<New_Year_Competition_Participants_Performance_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<New_Year_Competition_Participants_Performance_Set_Input>;
  /** filter the rows which have to be updated */
  where: New_Year_Competition_Participants_Performance_Bool_Exp;
};

/** aggregate var_pop on columns */
export type New_Year_Competition_Participants_Performance_Var_Pop_Fields = {
  __typename?: 'new_year_competition_participants_performance_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_prize?: Maybe<Scalars['Float']>;
  total_score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Var_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_prize?: InputMaybe<Order_By>;
  total_score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type New_Year_Competition_Participants_Performance_Var_Samp_Fields = {
  __typename?: 'new_year_competition_participants_performance_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_prize?: Maybe<Scalars['Float']>;
  total_score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Var_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_prize?: InputMaybe<Order_By>;
  total_score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type New_Year_Competition_Participants_Performance_Variance_Fields = {
  __typename?: 'new_year_competition_participants_performance_variance_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  prize?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_prize?: Maybe<Scalars['Float']>;
  total_score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "new_year_competition_participants_performance" */
export type New_Year_Competition_Participants_Performance_Variance_Order_By = {
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  prize?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  total_prize?: InputMaybe<Order_By>;
  total_score?: InputMaybe<Order_By>;
};

/** primary key columns input for table: new_year_competition_participants */
export type New_Year_Competition_Participants_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "new_year_competition_participants_rating" */
export type New_Year_Competition_Participants_Rating = {
  __typename?: 'new_year_competition_participants_rating';
  row_number?: Maybe<Scalars['bigint']>;
  team_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  user?: Maybe<User_Account>;
  user_id?: Maybe<Scalars['uuid']>;
  user_profile?: Maybe<User_Profile>;
};

/** aggregated selection of "new_year_competition_participants_rating" */
export type New_Year_Competition_Participants_Rating_Aggregate = {
  __typename?: 'new_year_competition_participants_rating_aggregate';
  aggregate?: Maybe<New_Year_Competition_Participants_Rating_Aggregate_Fields>;
  nodes: Array<New_Year_Competition_Participants_Rating>;
};

/** aggregate fields of "new_year_competition_participants_rating" */
export type New_Year_Competition_Participants_Rating_Aggregate_Fields = {
  __typename?: 'new_year_competition_participants_rating_aggregate_fields';
  avg?: Maybe<New_Year_Competition_Participants_Rating_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<New_Year_Competition_Participants_Rating_Max_Fields>;
  min?: Maybe<New_Year_Competition_Participants_Rating_Min_Fields>;
  stddev?: Maybe<New_Year_Competition_Participants_Rating_Stddev_Fields>;
  stddev_pop?: Maybe<New_Year_Competition_Participants_Rating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<New_Year_Competition_Participants_Rating_Stddev_Samp_Fields>;
  sum?: Maybe<New_Year_Competition_Participants_Rating_Sum_Fields>;
  var_pop?: Maybe<New_Year_Competition_Participants_Rating_Var_Pop_Fields>;
  var_samp?: Maybe<New_Year_Competition_Participants_Rating_Var_Samp_Fields>;
  variance?: Maybe<New_Year_Competition_Participants_Rating_Variance_Fields>;
};


/** aggregate fields of "new_year_competition_participants_rating" */
export type New_Year_Competition_Participants_Rating_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<New_Year_Competition_Participants_Rating_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type New_Year_Competition_Participants_Rating_Avg_Fields = {
  __typename?: 'new_year_competition_participants_rating_avg_fields';
  row_number?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "new_year_competition_participants_rating". All fields are combined with a logical 'AND'. */
export type New_Year_Competition_Participants_Rating_Bool_Exp = {
  _and?: InputMaybe<Array<New_Year_Competition_Participants_Rating_Bool_Exp>>;
  _not?: InputMaybe<New_Year_Competition_Participants_Rating_Bool_Exp>;
  _or?: InputMaybe<Array<New_Year_Competition_Participants_Rating_Bool_Exp>>;
  row_number?: InputMaybe<Bigint_Comparison_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
  user?: InputMaybe<User_Account_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type New_Year_Competition_Participants_Rating_Max_Fields = {
  __typename?: 'new_year_competition_participants_rating_max_fields';
  row_number?: Maybe<Scalars['bigint']>;
  team_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type New_Year_Competition_Participants_Rating_Min_Fields = {
  __typename?: 'new_year_competition_participants_rating_min_fields';
  row_number?: Maybe<Scalars['bigint']>;
  team_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "new_year_competition_participants_rating". */
export type New_Year_Competition_Participants_Rating_Order_By = {
  row_number?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Account_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "new_year_competition_participants_rating" */
export enum New_Year_Competition_Participants_Rating_Select_Column {
  /** column name */
  RowNumber = 'row_number',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Total = 'total',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type New_Year_Competition_Participants_Rating_Stddev_Fields = {
  __typename?: 'new_year_competition_participants_rating_stddev_fields';
  row_number?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type New_Year_Competition_Participants_Rating_Stddev_Pop_Fields = {
  __typename?: 'new_year_competition_participants_rating_stddev_pop_fields';
  row_number?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type New_Year_Competition_Participants_Rating_Stddev_Samp_Fields = {
  __typename?: 'new_year_competition_participants_rating_stddev_samp_fields';
  row_number?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "new_year_competition_participants_rating" */
export type New_Year_Competition_Participants_Rating_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: New_Year_Competition_Participants_Rating_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type New_Year_Competition_Participants_Rating_Stream_Cursor_Value_Input = {
  row_number?: InputMaybe<Scalars['bigint']>;
  team_id?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type New_Year_Competition_Participants_Rating_Sum_Fields = {
  __typename?: 'new_year_competition_participants_rating_sum_fields';
  row_number?: Maybe<Scalars['bigint']>;
  team_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type New_Year_Competition_Participants_Rating_Var_Pop_Fields = {
  __typename?: 'new_year_competition_participants_rating_var_pop_fields';
  row_number?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type New_Year_Competition_Participants_Rating_Var_Samp_Fields = {
  __typename?: 'new_year_competition_participants_rating_var_samp_fields';
  row_number?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type New_Year_Competition_Participants_Rating_Variance_Fields = {
  __typename?: 'new_year_competition_participants_rating_variance_fields';
  row_number?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** select columns of table "new_year_competition_participants" */
export enum New_Year_Competition_Participants_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  team_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type New_Year_Competition_Participants_Stddev_Fields = {
  __typename?: 'new_year_competition_participants_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type New_Year_Competition_Participants_Stddev_Pop_Fields = {
  __typename?: 'new_year_competition_participants_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type New_Year_Competition_Participants_Stddev_Samp_Fields = {
  __typename?: 'new_year_competition_participants_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: New_Year_Competition_Participants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type New_Year_Competition_Participants_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  team_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type New_Year_Competition_Participants_Sum_Fields = {
  __typename?: 'new_year_competition_participants_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** update columns of table "new_year_competition_participants" */
export enum New_Year_Competition_Participants_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

export type New_Year_Competition_Participants_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<New_Year_Competition_Participants_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<New_Year_Competition_Participants_Set_Input>;
  /** filter the rows which have to be updated */
  where: New_Year_Competition_Participants_Bool_Exp;
};

/** aggregate var_pop on columns */
export type New_Year_Competition_Participants_Var_Pop_Fields = {
  __typename?: 'new_year_competition_participants_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type New_Year_Competition_Participants_Var_Samp_Fields = {
  __typename?: 'new_year_competition_participants_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type New_Year_Competition_Participants_Variance_Fields = {
  __typename?: 'new_year_competition_participants_variance_fields';
  id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "new_year_competition_participants" */
export type New_Year_Competition_Participants_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Команды участников в новогодней акции */
export type New_Year_Competition_Teams = {
  __typename?: 'new_year_competition_teams';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  new_year_competition_participants: Array<New_Year_Competition_Participants>;
  /** An aggregate relationship */
  new_year_competition_participants_aggregate: New_Year_Competition_Participants_Aggregate;
};


/** Команды участников в новогодней акции */
export type New_Year_Competition_TeamsNew_Year_Competition_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Bool_Exp>;
};


/** Команды участников в новогодней акции */
export type New_Year_Competition_TeamsNew_Year_Competition_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Bool_Exp>;
};

/** aggregated selection of "new_year_competition_teams" */
export type New_Year_Competition_Teams_Aggregate = {
  __typename?: 'new_year_competition_teams_aggregate';
  aggregate?: Maybe<New_Year_Competition_Teams_Aggregate_Fields>;
  nodes: Array<New_Year_Competition_Teams>;
};

/** aggregate fields of "new_year_competition_teams" */
export type New_Year_Competition_Teams_Aggregate_Fields = {
  __typename?: 'new_year_competition_teams_aggregate_fields';
  avg?: Maybe<New_Year_Competition_Teams_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<New_Year_Competition_Teams_Max_Fields>;
  min?: Maybe<New_Year_Competition_Teams_Min_Fields>;
  stddev?: Maybe<New_Year_Competition_Teams_Stddev_Fields>;
  stddev_pop?: Maybe<New_Year_Competition_Teams_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<New_Year_Competition_Teams_Stddev_Samp_Fields>;
  sum?: Maybe<New_Year_Competition_Teams_Sum_Fields>;
  var_pop?: Maybe<New_Year_Competition_Teams_Var_Pop_Fields>;
  var_samp?: Maybe<New_Year_Competition_Teams_Var_Samp_Fields>;
  variance?: Maybe<New_Year_Competition_Teams_Variance_Fields>;
};


/** aggregate fields of "new_year_competition_teams" */
export type New_Year_Competition_Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<New_Year_Competition_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type New_Year_Competition_Teams_Avg_Fields = {
  __typename?: 'new_year_competition_teams_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "new_year_competition_teams". All fields are combined with a logical 'AND'. */
export type New_Year_Competition_Teams_Bool_Exp = {
  _and?: InputMaybe<Array<New_Year_Competition_Teams_Bool_Exp>>;
  _not?: InputMaybe<New_Year_Competition_Teams_Bool_Exp>;
  _or?: InputMaybe<Array<New_Year_Competition_Teams_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  new_year_competition_participants?: InputMaybe<New_Year_Competition_Participants_Bool_Exp>;
  new_year_competition_participants_aggregate?: InputMaybe<New_Year_Competition_Participants_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "new_year_competition_teams" */
export enum New_Year_Competition_Teams_Constraint {
  /** unique or primary key constraint on columns "name" */
  NewYearCompetitionTeamNameKey = 'new_year_competition_team_name_key',
  /** unique or primary key constraint on columns "id" */
  NewYearCompetitionTeamPkey = 'new_year_competition_team_pkey'
}

/** input type for incrementing numeric columns in table "new_year_competition_teams" */
export type New_Year_Competition_Teams_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "new_year_competition_teams" */
export type New_Year_Competition_Teams_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  new_year_competition_participants?: InputMaybe<New_Year_Competition_Participants_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type New_Year_Competition_Teams_Max_Fields = {
  __typename?: 'new_year_competition_teams_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type New_Year_Competition_Teams_Min_Fields = {
  __typename?: 'new_year_competition_teams_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "new_year_competition_teams" */
export type New_Year_Competition_Teams_Mutation_Response = {
  __typename?: 'new_year_competition_teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<New_Year_Competition_Teams>;
};

/** input type for inserting object relation for remote table "new_year_competition_teams" */
export type New_Year_Competition_Teams_Obj_Rel_Insert_Input = {
  data: New_Year_Competition_Teams_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<New_Year_Competition_Teams_On_Conflict>;
};

/** on_conflict condition type for table "new_year_competition_teams" */
export type New_Year_Competition_Teams_On_Conflict = {
  constraint: New_Year_Competition_Teams_Constraint;
  update_columns?: Array<New_Year_Competition_Teams_Update_Column>;
  where?: InputMaybe<New_Year_Competition_Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "new_year_competition_teams". */
export type New_Year_Competition_Teams_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  new_year_competition_participants_aggregate?: InputMaybe<New_Year_Competition_Participants_Aggregate_Order_By>;
};

/** primary key columns input for table: new_year_competition_teams */
export type New_Year_Competition_Teams_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "new_year_competition_teams" */
export enum New_Year_Competition_Teams_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "new_year_competition_teams" */
export type New_Year_Competition_Teams_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type New_Year_Competition_Teams_Stddev_Fields = {
  __typename?: 'new_year_competition_teams_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type New_Year_Competition_Teams_Stddev_Pop_Fields = {
  __typename?: 'new_year_competition_teams_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type New_Year_Competition_Teams_Stddev_Samp_Fields = {
  __typename?: 'new_year_competition_teams_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "new_year_competition_teams" */
export type New_Year_Competition_Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: New_Year_Competition_Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type New_Year_Competition_Teams_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type New_Year_Competition_Teams_Sum_Fields = {
  __typename?: 'new_year_competition_teams_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "new_year_competition_teams" */
export enum New_Year_Competition_Teams_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type New_Year_Competition_Teams_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<New_Year_Competition_Teams_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<New_Year_Competition_Teams_Set_Input>;
  /** filter the rows which have to be updated */
  where: New_Year_Competition_Teams_Bool_Exp;
};

/** aggregate var_pop on columns */
export type New_Year_Competition_Teams_Var_Pop_Fields = {
  __typename?: 'new_year_competition_teams_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type New_Year_Competition_Teams_Var_Samp_Fields = {
  __typename?: 'new_year_competition_teams_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type New_Year_Competition_Teams_Variance_Fields = {
  __typename?: 'new_year_competition_teams_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "new_year_competition_winner_team" */
export type New_Year_Competition_Winner_Team = {
  __typename?: 'new_year_competition_winner_team';
  row_number?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['numeric']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "new_year_competition_winner_team" */
export type New_Year_Competition_Winner_Team_Aggregate = {
  __typename?: 'new_year_competition_winner_team_aggregate';
  aggregate?: Maybe<New_Year_Competition_Winner_Team_Aggregate_Fields>;
  nodes: Array<New_Year_Competition_Winner_Team>;
};

/** aggregate fields of "new_year_competition_winner_team" */
export type New_Year_Competition_Winner_Team_Aggregate_Fields = {
  __typename?: 'new_year_competition_winner_team_aggregate_fields';
  avg?: Maybe<New_Year_Competition_Winner_Team_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<New_Year_Competition_Winner_Team_Max_Fields>;
  min?: Maybe<New_Year_Competition_Winner_Team_Min_Fields>;
  stddev?: Maybe<New_Year_Competition_Winner_Team_Stddev_Fields>;
  stddev_pop?: Maybe<New_Year_Competition_Winner_Team_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<New_Year_Competition_Winner_Team_Stddev_Samp_Fields>;
  sum?: Maybe<New_Year_Competition_Winner_Team_Sum_Fields>;
  var_pop?: Maybe<New_Year_Competition_Winner_Team_Var_Pop_Fields>;
  var_samp?: Maybe<New_Year_Competition_Winner_Team_Var_Samp_Fields>;
  variance?: Maybe<New_Year_Competition_Winner_Team_Variance_Fields>;
};


/** aggregate fields of "new_year_competition_winner_team" */
export type New_Year_Competition_Winner_Team_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<New_Year_Competition_Winner_Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type New_Year_Competition_Winner_Team_Avg_Fields = {
  __typename?: 'new_year_competition_winner_team_avg_fields';
  row_number?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "new_year_competition_winner_team". All fields are combined with a logical 'AND'. */
export type New_Year_Competition_Winner_Team_Bool_Exp = {
  _and?: InputMaybe<Array<New_Year_Competition_Winner_Team_Bool_Exp>>;
  _not?: InputMaybe<New_Year_Competition_Winner_Team_Bool_Exp>;
  _or?: InputMaybe<Array<New_Year_Competition_Winner_Team_Bool_Exp>>;
  row_number?: InputMaybe<Bigint_Comparison_Exp>;
  score?: InputMaybe<Numeric_Comparison_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type New_Year_Competition_Winner_Team_Max_Fields = {
  __typename?: 'new_year_competition_winner_team_max_fields';
  row_number?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['numeric']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type New_Year_Competition_Winner_Team_Min_Fields = {
  __typename?: 'new_year_competition_winner_team_min_fields';
  row_number?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['numeric']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "new_year_competition_winner_team". */
export type New_Year_Competition_Winner_Team_Order_By = {
  row_number?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** select columns of table "new_year_competition_winner_team" */
export enum New_Year_Competition_Winner_Team_Select_Column {
  /** column name */
  RowNumber = 'row_number',
  /** column name */
  Score = 'score',
  /** column name */
  TeamId = 'team_id'
}

/** aggregate stddev on columns */
export type New_Year_Competition_Winner_Team_Stddev_Fields = {
  __typename?: 'new_year_competition_winner_team_stddev_fields';
  row_number?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type New_Year_Competition_Winner_Team_Stddev_Pop_Fields = {
  __typename?: 'new_year_competition_winner_team_stddev_pop_fields';
  row_number?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type New_Year_Competition_Winner_Team_Stddev_Samp_Fields = {
  __typename?: 'new_year_competition_winner_team_stddev_samp_fields';
  row_number?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "new_year_competition_winner_team" */
export type New_Year_Competition_Winner_Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: New_Year_Competition_Winner_Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type New_Year_Competition_Winner_Team_Stream_Cursor_Value_Input = {
  row_number?: InputMaybe<Scalars['bigint']>;
  score?: InputMaybe<Scalars['numeric']>;
  team_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type New_Year_Competition_Winner_Team_Sum_Fields = {
  __typename?: 'new_year_competition_winner_team_sum_fields';
  row_number?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['numeric']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type New_Year_Competition_Winner_Team_Var_Pop_Fields = {
  __typename?: 'new_year_competition_winner_team_var_pop_fields';
  row_number?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type New_Year_Competition_Winner_Team_Var_Samp_Fields = {
  __typename?: 'new_year_competition_winner_team_var_samp_fields';
  row_number?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type New_Year_Competition_Winner_Team_Variance_Fields = {
  __typename?: 'new_year_competition_winner_team_variance_fields';
  row_number?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** Типы операций. */
export type Operation_Types = {
  __typename?: 'operation_types';
  description: Scalars['String'];
  name: Scalars['String'];
  operation_id: Scalars['Int'];
  /** An array relationship */
  partner_balance_transactions: Array<Partner_Balance_Transactions>;
  /** An aggregate relationship */
  partner_balance_transactions_aggregate: Partner_Balance_Transactions_Aggregate;
};


/** Типы операций. */
export type Operation_TypesPartner_Balance_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balance_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
};


/** Типы операций. */
export type Operation_TypesPartner_Balance_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balance_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
};

/** aggregated selection of "operation_types" */
export type Operation_Types_Aggregate = {
  __typename?: 'operation_types_aggregate';
  aggregate?: Maybe<Operation_Types_Aggregate_Fields>;
  nodes: Array<Operation_Types>;
};

/** aggregate fields of "operation_types" */
export type Operation_Types_Aggregate_Fields = {
  __typename?: 'operation_types_aggregate_fields';
  avg?: Maybe<Operation_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Operation_Types_Max_Fields>;
  min?: Maybe<Operation_Types_Min_Fields>;
  stddev?: Maybe<Operation_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Operation_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Operation_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Operation_Types_Sum_Fields>;
  var_pop?: Maybe<Operation_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Operation_Types_Var_Samp_Fields>;
  variance?: Maybe<Operation_Types_Variance_Fields>;
};


/** aggregate fields of "operation_types" */
export type Operation_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Operation_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Operation_Types_Avg_Fields = {
  __typename?: 'operation_types_avg_fields';
  operation_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "operation_types". All fields are combined with a logical 'AND'. */
export type Operation_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Operation_Types_Bool_Exp>>;
  _not?: InputMaybe<Operation_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Operation_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  operation_id?: InputMaybe<Int_Comparison_Exp>;
  partner_balance_transactions?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
  partner_balance_transactions_aggregate?: InputMaybe<Partner_Balance_Transactions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "operation_types" */
export enum Operation_Types_Constraint {
  /** unique or primary key constraint on columns "operation_id" */
  OperationTypesPkey = 'operation_types_pkey'
}

/** input type for incrementing numeric columns in table "operation_types" */
export type Operation_Types_Inc_Input = {
  operation_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "operation_types" */
export type Operation_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  partner_balance_transactions?: InputMaybe<Partner_Balance_Transactions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Operation_Types_Max_Fields = {
  __typename?: 'operation_types_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operation_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Operation_Types_Min_Fields = {
  __typename?: 'operation_types_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operation_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "operation_types" */
export type Operation_Types_Mutation_Response = {
  __typename?: 'operation_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operation_Types>;
};

/** input type for inserting object relation for remote table "operation_types" */
export type Operation_Types_Obj_Rel_Insert_Input = {
  data: Operation_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Operation_Types_On_Conflict>;
};

/** on_conflict condition type for table "operation_types" */
export type Operation_Types_On_Conflict = {
  constraint: Operation_Types_Constraint;
  update_columns?: Array<Operation_Types_Update_Column>;
  where?: InputMaybe<Operation_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "operation_types". */
export type Operation_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  partner_balance_transactions_aggregate?: InputMaybe<Partner_Balance_Transactions_Aggregate_Order_By>;
};

/** primary key columns input for table: operation_types */
export type Operation_Types_Pk_Columns_Input = {
  operation_id: Scalars['Int'];
};

/** select columns of table "operation_types" */
export enum Operation_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  OperationId = 'operation_id'
}

/** input type for updating data in table "operation_types" */
export type Operation_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  operation_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Operation_Types_Stddev_Fields = {
  __typename?: 'operation_types_stddev_fields';
  operation_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Operation_Types_Stddev_Pop_Fields = {
  __typename?: 'operation_types_stddev_pop_fields';
  operation_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Operation_Types_Stddev_Samp_Fields = {
  __typename?: 'operation_types_stddev_samp_fields';
  operation_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "operation_types" */
export type Operation_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operation_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operation_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  operation_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Operation_Types_Sum_Fields = {
  __typename?: 'operation_types_sum_fields';
  operation_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "operation_types" */
export enum Operation_Types_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  OperationId = 'operation_id'
}

export type Operation_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Operation_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operation_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operation_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Operation_Types_Var_Pop_Fields = {
  __typename?: 'operation_types_var_pop_fields';
  operation_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Operation_Types_Var_Samp_Fields = {
  __typename?: 'operation_types_var_samp_fields';
  operation_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Operation_Types_Variance_Fields = {
  __typename?: 'operation_types_variance_fields';
  operation_id?: Maybe<Scalars['Float']>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Pages_Count_User_Inventory_Args = {
  per_page?: InputMaybe<Scalars['Int']>;
};

export type Pages_For_Boosters_User_Inventory_Args = {
  per_page?: InputMaybe<Scalars['Int']>;
};

export type Pages_For_Sale_Count_User_Inventory_Args = {
  per_page?: InputMaybe<Scalars['Int']>;
};

export type Pages_For_Upgrade_Count_User_Inventory_Args = {
  per_page?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "parsers_timestamp_log" */
export type Parsers_Timestamp_Log = {
  __typename?: 'parsers_timestamp_log';
  end_at: Scalars['timestamp'];
  parser_name: Scalars['String'];
  start_at: Scalars['timestamp'];
};

/** aggregated selection of "parsers_timestamp_log" */
export type Parsers_Timestamp_Log_Aggregate = {
  __typename?: 'parsers_timestamp_log_aggregate';
  aggregate?: Maybe<Parsers_Timestamp_Log_Aggregate_Fields>;
  nodes: Array<Parsers_Timestamp_Log>;
};

/** aggregate fields of "parsers_timestamp_log" */
export type Parsers_Timestamp_Log_Aggregate_Fields = {
  __typename?: 'parsers_timestamp_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Parsers_Timestamp_Log_Max_Fields>;
  min?: Maybe<Parsers_Timestamp_Log_Min_Fields>;
};


/** aggregate fields of "parsers_timestamp_log" */
export type Parsers_Timestamp_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parsers_Timestamp_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "parsers_timestamp_log". All fields are combined with a logical 'AND'. */
export type Parsers_Timestamp_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Parsers_Timestamp_Log_Bool_Exp>>;
  _not?: InputMaybe<Parsers_Timestamp_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Parsers_Timestamp_Log_Bool_Exp>>;
  end_at?: InputMaybe<Timestamp_Comparison_Exp>;
  parser_name?: InputMaybe<String_Comparison_Exp>;
  start_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "parsers_timestamp_log" */
export enum Parsers_Timestamp_Log_Constraint {
  /** unique or primary key constraint on columns "parser_name" */
  ParsersTimestampLogPkey = 'parsers_timestamp_log_pkey'
}

/** input type for inserting data into table "parsers_timestamp_log" */
export type Parsers_Timestamp_Log_Insert_Input = {
  end_at?: InputMaybe<Scalars['timestamp']>;
  parser_name?: InputMaybe<Scalars['String']>;
  start_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Parsers_Timestamp_Log_Max_Fields = {
  __typename?: 'parsers_timestamp_log_max_fields';
  end_at?: Maybe<Scalars['timestamp']>;
  parser_name?: Maybe<Scalars['String']>;
  start_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Parsers_Timestamp_Log_Min_Fields = {
  __typename?: 'parsers_timestamp_log_min_fields';
  end_at?: Maybe<Scalars['timestamp']>;
  parser_name?: Maybe<Scalars['String']>;
  start_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "parsers_timestamp_log" */
export type Parsers_Timestamp_Log_Mutation_Response = {
  __typename?: 'parsers_timestamp_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Parsers_Timestamp_Log>;
};

/** on_conflict condition type for table "parsers_timestamp_log" */
export type Parsers_Timestamp_Log_On_Conflict = {
  constraint: Parsers_Timestamp_Log_Constraint;
  update_columns?: Array<Parsers_Timestamp_Log_Update_Column>;
  where?: InputMaybe<Parsers_Timestamp_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "parsers_timestamp_log". */
export type Parsers_Timestamp_Log_Order_By = {
  end_at?: InputMaybe<Order_By>;
  parser_name?: InputMaybe<Order_By>;
  start_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parsers_timestamp_log */
export type Parsers_Timestamp_Log_Pk_Columns_Input = {
  parser_name: Scalars['String'];
};

/** select columns of table "parsers_timestamp_log" */
export enum Parsers_Timestamp_Log_Select_Column {
  /** column name */
  EndAt = 'end_at',
  /** column name */
  ParserName = 'parser_name',
  /** column name */
  StartAt = 'start_at'
}

/** input type for updating data in table "parsers_timestamp_log" */
export type Parsers_Timestamp_Log_Set_Input = {
  end_at?: InputMaybe<Scalars['timestamp']>;
  parser_name?: InputMaybe<Scalars['String']>;
  start_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "parsers_timestamp_log" */
export type Parsers_Timestamp_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Parsers_Timestamp_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Parsers_Timestamp_Log_Stream_Cursor_Value_Input = {
  end_at?: InputMaybe<Scalars['timestamp']>;
  parser_name?: InputMaybe<Scalars['String']>;
  start_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "parsers_timestamp_log" */
export enum Parsers_Timestamp_Log_Update_Column {
  /** column name */
  EndAt = 'end_at',
  /** column name */
  ParserName = 'parser_name',
  /** column name */
  StartAt = 'start_at'
}

export type Parsers_Timestamp_Log_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Parsers_Timestamp_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Parsers_Timestamp_Log_Bool_Exp;
};

/** Таблица транзакций балансов партнёров реферальной программы. operation_type: 1 - пополнение, 2 - списание(выплата); execution_method: 1- вручную через базу, 2 - в процессе выполнения задачи, 3 - через бота; */
export type Partner_Balance_Transactions = {
  __typename?: 'partner_balance_transactions';
  created_at: Scalars['timestamp'];
  execution_method: Scalars['Int'];
  /** An object relationship */
  execution_method_object: Execution_Methods;
  operation_type: Scalars['Int'];
  /** An object relationship */
  operation_type_object: Operation_Types;
  /** An object relationship */
  partner_param: Partner_Params;
  transaction_amount: Scalars['Int'];
  transaction_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "partner_balance_transactions" */
export type Partner_Balance_Transactions_Aggregate = {
  __typename?: 'partner_balance_transactions_aggregate';
  aggregate?: Maybe<Partner_Balance_Transactions_Aggregate_Fields>;
  nodes: Array<Partner_Balance_Transactions>;
};

export type Partner_Balance_Transactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Partner_Balance_Transactions_Aggregate_Bool_Exp_Count>;
};

export type Partner_Balance_Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Partner_Balance_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "partner_balance_transactions" */
export type Partner_Balance_Transactions_Aggregate_Fields = {
  __typename?: 'partner_balance_transactions_aggregate_fields';
  avg?: Maybe<Partner_Balance_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Balance_Transactions_Max_Fields>;
  min?: Maybe<Partner_Balance_Transactions_Min_Fields>;
  stddev?: Maybe<Partner_Balance_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Balance_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Balance_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Balance_Transactions_Sum_Fields>;
  var_pop?: Maybe<Partner_Balance_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Balance_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Partner_Balance_Transactions_Variance_Fields>;
};


/** aggregate fields of "partner_balance_transactions" */
export type Partner_Balance_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Balance_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Aggregate_Order_By = {
  avg?: InputMaybe<Partner_Balance_Transactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Partner_Balance_Transactions_Max_Order_By>;
  min?: InputMaybe<Partner_Balance_Transactions_Min_Order_By>;
  stddev?: InputMaybe<Partner_Balance_Transactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Partner_Balance_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Partner_Balance_Transactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Partner_Balance_Transactions_Sum_Order_By>;
  var_pop?: InputMaybe<Partner_Balance_Transactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Partner_Balance_Transactions_Var_Samp_Order_By>;
  variance?: InputMaybe<Partner_Balance_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Partner_Balance_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Balance_Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Partner_Balance_Transactions_Avg_Fields = {
  __typename?: 'partner_balance_transactions_avg_fields';
  execution_method?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Avg_Order_By = {
  execution_method?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "partner_balance_transactions". All fields are combined with a logical 'AND'. */
export type Partner_Balance_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Balance_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Balance_Transactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  execution_method?: InputMaybe<Int_Comparison_Exp>;
  execution_method_object?: InputMaybe<Execution_Methods_Bool_Exp>;
  operation_type?: InputMaybe<Int_Comparison_Exp>;
  operation_type_object?: InputMaybe<Operation_Types_Bool_Exp>;
  partner_param?: InputMaybe<Partner_Params_Bool_Exp>;
  transaction_amount?: InputMaybe<Int_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "partner_balance_transactions" */
export enum Partner_Balance_Transactions_Constraint {
  /** unique or primary key constraint on columns "transaction_id" */
  PartnerBalanceTransactionsPkey = 'partner_balance_transactions_pkey'
}

/** input type for incrementing numeric columns in table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Inc_Input = {
  execution_method?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  transaction_amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  execution_method?: InputMaybe<Scalars['Int']>;
  execution_method_object?: InputMaybe<Execution_Methods_Obj_Rel_Insert_Input>;
  operation_type?: InputMaybe<Scalars['Int']>;
  operation_type_object?: InputMaybe<Operation_Types_Obj_Rel_Insert_Input>;
  partner_param?: InputMaybe<Partner_Params_Obj_Rel_Insert_Input>;
  transaction_amount?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Partner_Balance_Transactions_Max_Fields = {
  __typename?: 'partner_balance_transactions_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  execution_method?: Maybe<Scalars['Int']>;
  operation_type?: Maybe<Scalars['Int']>;
  transaction_amount?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  execution_method?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Partner_Balance_Transactions_Min_Fields = {
  __typename?: 'partner_balance_transactions_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  execution_method?: Maybe<Scalars['Int']>;
  operation_type?: Maybe<Scalars['Int']>;
  transaction_amount?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  execution_method?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Mutation_Response = {
  __typename?: 'partner_balance_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Balance_Transactions>;
};

/** on_conflict condition type for table "partner_balance_transactions" */
export type Partner_Balance_Transactions_On_Conflict = {
  constraint: Partner_Balance_Transactions_Constraint;
  update_columns?: Array<Partner_Balance_Transactions_Update_Column>;
  where?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "partner_balance_transactions". */
export type Partner_Balance_Transactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  execution_method?: InputMaybe<Order_By>;
  execution_method_object?: InputMaybe<Execution_Methods_Order_By>;
  operation_type?: InputMaybe<Order_By>;
  operation_type_object?: InputMaybe<Operation_Types_Order_By>;
  partner_param?: InputMaybe<Partner_Params_Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_balance_transactions */
export type Partner_Balance_Transactions_Pk_Columns_Input = {
  transaction_id: Scalars['uuid'];
};

/** select columns of table "partner_balance_transactions" */
export enum Partner_Balance_Transactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExecutionMethod = 'execution_method',
  /** column name */
  OperationType = 'operation_type',
  /** column name */
  TransactionAmount = 'transaction_amount',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  execution_method?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  transaction_amount?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Partner_Balance_Transactions_Stddev_Fields = {
  __typename?: 'partner_balance_transactions_stddev_fields';
  execution_method?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Stddev_Order_By = {
  execution_method?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Partner_Balance_Transactions_Stddev_Pop_Fields = {
  __typename?: 'partner_balance_transactions_stddev_pop_fields';
  execution_method?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Stddev_Pop_Order_By = {
  execution_method?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Partner_Balance_Transactions_Stddev_Samp_Fields = {
  __typename?: 'partner_balance_transactions_stddev_samp_fields';
  execution_method?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Stddev_Samp_Order_By = {
  execution_method?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Balance_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Balance_Transactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  execution_method?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  transaction_amount?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Partner_Balance_Transactions_Sum_Fields = {
  __typename?: 'partner_balance_transactions_sum_fields';
  execution_method?: Maybe<Scalars['Int']>;
  operation_type?: Maybe<Scalars['Int']>;
  transaction_amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Sum_Order_By = {
  execution_method?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
};

/** update columns of table "partner_balance_transactions" */
export enum Partner_Balance_Transactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExecutionMethod = 'execution_method',
  /** column name */
  OperationType = 'operation_type',
  /** column name */
  TransactionAmount = 'transaction_amount',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Balance_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Balance_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Balance_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Balance_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Balance_Transactions_Var_Pop_Fields = {
  __typename?: 'partner_balance_transactions_var_pop_fields';
  execution_method?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Var_Pop_Order_By = {
  execution_method?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Partner_Balance_Transactions_Var_Samp_Fields = {
  __typename?: 'partner_balance_transactions_var_samp_fields';
  execution_method?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Var_Samp_Order_By = {
  execution_method?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Partner_Balance_Transactions_Variance_Fields = {
  __typename?: 'partner_balance_transactions_variance_fields';
  execution_method?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "partner_balance_transactions" */
export type Partner_Balance_Transactions_Variance_Order_By = {
  execution_method?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
};

/** Справочник типов предложений партнёров реферальной программы. */
export type Partner_Offer_Types = {
  __typename?: 'partner_offer_types';
  description: Scalars['String'];
  name: Scalars['String'];
  type_id: Scalars['Int'];
};

/** aggregated selection of "partner_offer_types" */
export type Partner_Offer_Types_Aggregate = {
  __typename?: 'partner_offer_types_aggregate';
  aggregate?: Maybe<Partner_Offer_Types_Aggregate_Fields>;
  nodes: Array<Partner_Offer_Types>;
};

/** aggregate fields of "partner_offer_types" */
export type Partner_Offer_Types_Aggregate_Fields = {
  __typename?: 'partner_offer_types_aggregate_fields';
  avg?: Maybe<Partner_Offer_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Offer_Types_Max_Fields>;
  min?: Maybe<Partner_Offer_Types_Min_Fields>;
  stddev?: Maybe<Partner_Offer_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Offer_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Offer_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Offer_Types_Sum_Fields>;
  var_pop?: Maybe<Partner_Offer_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Offer_Types_Var_Samp_Fields>;
  variance?: Maybe<Partner_Offer_Types_Variance_Fields>;
};


/** aggregate fields of "partner_offer_types" */
export type Partner_Offer_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Offer_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Offer_Types_Avg_Fields = {
  __typename?: 'partner_offer_types_avg_fields';
  type_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "partner_offer_types". All fields are combined with a logical 'AND'. */
export type Partner_Offer_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Offer_Types_Bool_Exp>>;
  _not?: InputMaybe<Partner_Offer_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Offer_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  type_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "partner_offer_types" */
export enum Partner_Offer_Types_Constraint {
  /** unique or primary key constraint on columns "name" */
  PartnerOfferTypesNameKey = 'partner_offer_types_name_key',
  /** unique or primary key constraint on columns "type_id" */
  PartnerOfferTypesPkey = 'partner_offer_types_pkey'
}

/** input type for incrementing numeric columns in table "partner_offer_types" */
export type Partner_Offer_Types_Inc_Input = {
  type_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "partner_offer_types" */
export type Partner_Offer_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Partner_Offer_Types_Max_Fields = {
  __typename?: 'partner_offer_types_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Partner_Offer_Types_Min_Fields = {
  __typename?: 'partner_offer_types_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "partner_offer_types" */
export type Partner_Offer_Types_Mutation_Response = {
  __typename?: 'partner_offer_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Offer_Types>;
};

/** on_conflict condition type for table "partner_offer_types" */
export type Partner_Offer_Types_On_Conflict = {
  constraint: Partner_Offer_Types_Constraint;
  update_columns?: Array<Partner_Offer_Types_Update_Column>;
  where?: InputMaybe<Partner_Offer_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "partner_offer_types". */
export type Partner_Offer_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_offer_types */
export type Partner_Offer_Types_Pk_Columns_Input = {
  type_id: Scalars['Int'];
};

/** select columns of table "partner_offer_types" */
export enum Partner_Offer_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  TypeId = 'type_id'
}

/** input type for updating data in table "partner_offer_types" */
export type Partner_Offer_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Partner_Offer_Types_Stddev_Fields = {
  __typename?: 'partner_offer_types_stddev_fields';
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Offer_Types_Stddev_Pop_Fields = {
  __typename?: 'partner_offer_types_stddev_pop_fields';
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Offer_Types_Stddev_Samp_Fields = {
  __typename?: 'partner_offer_types_stddev_samp_fields';
  type_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_offer_types" */
export type Partner_Offer_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Offer_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Offer_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Partner_Offer_Types_Sum_Fields = {
  __typename?: 'partner_offer_types_sum_fields';
  type_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "partner_offer_types" */
export enum Partner_Offer_Types_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  TypeId = 'type_id'
}

export type Partner_Offer_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Offer_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Offer_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Offer_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Offer_Types_Var_Pop_Fields = {
  __typename?: 'partner_offer_types_var_pop_fields';
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Offer_Types_Var_Samp_Fields = {
  __typename?: 'partner_offer_types_var_samp_fields';
  type_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Offer_Types_Variance_Fields = {
  __typename?: 'partner_offer_types_variance_fields';
  type_id?: Maybe<Scalars['Float']>;
};

/** Таблица данных партнёра реферальной программы. */
export type Partner_Params = {
  __typename?: 'partner_params';
  /** An array relationship */
  partner_balance_transactions: Array<Partner_Balance_Transactions>;
  /** An aggregate relationship */
  partner_balance_transactions_aggregate: Partner_Balance_Transactions_Aggregate;
  payout_balance: Scalars['bigint'];
  referrals_count: Scalars['bigint'];
  total_balance: Scalars['bigint'];
  /** An object relationship */
  user_account: User_Account;
  user_id: Scalars['uuid'];
  user_profile?: Maybe<User_Profile>;
  /** An array relationship */
  user_referral_offers: Array<User_Referral_Offers>;
  /** An aggregate relationship */
  user_referral_offers_aggregate: User_Referral_Offers_Aggregate;
};


/** Таблица данных партнёра реферальной программы. */
export type Partner_ParamsPartner_Balance_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balance_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
};


/** Таблица данных партнёра реферальной программы. */
export type Partner_ParamsPartner_Balance_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balance_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
};


/** Таблица данных партнёра реферальной программы. */
export type Partner_ParamsUser_Referral_OffersArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offers_Order_By>>;
  where?: InputMaybe<User_Referral_Offers_Bool_Exp>;
};


/** Таблица данных партнёра реферальной программы. */
export type Partner_ParamsUser_Referral_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offers_Order_By>>;
  where?: InputMaybe<User_Referral_Offers_Bool_Exp>;
};

/** aggregated selection of "partner_params" */
export type Partner_Params_Aggregate = {
  __typename?: 'partner_params_aggregate';
  aggregate?: Maybe<Partner_Params_Aggregate_Fields>;
  nodes: Array<Partner_Params>;
};

/** aggregate fields of "partner_params" */
export type Partner_Params_Aggregate_Fields = {
  __typename?: 'partner_params_aggregate_fields';
  avg?: Maybe<Partner_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Params_Max_Fields>;
  min?: Maybe<Partner_Params_Min_Fields>;
  stddev?: Maybe<Partner_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Params_Sum_Fields>;
  var_pop?: Maybe<Partner_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Params_Var_Samp_Fields>;
  variance?: Maybe<Partner_Params_Variance_Fields>;
};


/** aggregate fields of "partner_params" */
export type Partner_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Таблица изменения данных таблицы partner_params. */
export type Partner_Params_Audit = {
  __typename?: 'partner_params_audit';
  created_at: Scalars['timestamp'];
  operation: Scalars['String'];
  payout_balance: Scalars['bigint'];
  referrals_count: Scalars['bigint'];
  total_balance: Scalars['bigint'];
  transaction_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "partner_params_audit" */
export type Partner_Params_Audit_Aggregate = {
  __typename?: 'partner_params_audit_aggregate';
  aggregate?: Maybe<Partner_Params_Audit_Aggregate_Fields>;
  nodes: Array<Partner_Params_Audit>;
};

/** aggregate fields of "partner_params_audit" */
export type Partner_Params_Audit_Aggregate_Fields = {
  __typename?: 'partner_params_audit_aggregate_fields';
  avg?: Maybe<Partner_Params_Audit_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Params_Audit_Max_Fields>;
  min?: Maybe<Partner_Params_Audit_Min_Fields>;
  stddev?: Maybe<Partner_Params_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Params_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Params_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Params_Audit_Sum_Fields>;
  var_pop?: Maybe<Partner_Params_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Params_Audit_Var_Samp_Fields>;
  variance?: Maybe<Partner_Params_Audit_Variance_Fields>;
};


/** aggregate fields of "partner_params_audit" */
export type Partner_Params_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Partner_Params_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Params_Audit_Avg_Fields = {
  __typename?: 'partner_params_audit_avg_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "partner_params_audit". All fields are combined with a logical 'AND'. */
export type Partner_Params_Audit_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Params_Audit_Bool_Exp>>;
  _not?: InputMaybe<Partner_Params_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Params_Audit_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  operation?: InputMaybe<String_Comparison_Exp>;
  payout_balance?: InputMaybe<Bigint_Comparison_Exp>;
  referrals_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_balance?: InputMaybe<Bigint_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "partner_params_audit" */
export enum Partner_Params_Audit_Constraint {
  /** unique or primary key constraint on columns "transaction_id" */
  PartnerParamsAuditPkey = 'partner_params_audit_pkey'
}

/** input type for incrementing numeric columns in table "partner_params_audit" */
export type Partner_Params_Audit_Inc_Input = {
  payout_balance?: InputMaybe<Scalars['bigint']>;
  referrals_count?: InputMaybe<Scalars['bigint']>;
  total_balance?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "partner_params_audit" */
export type Partner_Params_Audit_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  operation?: InputMaybe<Scalars['String']>;
  payout_balance?: InputMaybe<Scalars['bigint']>;
  referrals_count?: InputMaybe<Scalars['bigint']>;
  total_balance?: InputMaybe<Scalars['bigint']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Partner_Params_Audit_Max_Fields = {
  __typename?: 'partner_params_audit_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  operation?: Maybe<Scalars['String']>;
  payout_balance?: Maybe<Scalars['bigint']>;
  referrals_count?: Maybe<Scalars['bigint']>;
  total_balance?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Partner_Params_Audit_Min_Fields = {
  __typename?: 'partner_params_audit_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  operation?: Maybe<Scalars['String']>;
  payout_balance?: Maybe<Scalars['bigint']>;
  referrals_count?: Maybe<Scalars['bigint']>;
  total_balance?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "partner_params_audit" */
export type Partner_Params_Audit_Mutation_Response = {
  __typename?: 'partner_params_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Params_Audit>;
};

/** on_conflict condition type for table "partner_params_audit" */
export type Partner_Params_Audit_On_Conflict = {
  constraint: Partner_Params_Audit_Constraint;
  update_columns?: Array<Partner_Params_Audit_Update_Column>;
  where?: InputMaybe<Partner_Params_Audit_Bool_Exp>;
};

/** Ordering options when selecting data from "partner_params_audit". */
export type Partner_Params_Audit_Order_By = {
  created_at?: InputMaybe<Order_By>;
  operation?: InputMaybe<Order_By>;
  payout_balance?: InputMaybe<Order_By>;
  referrals_count?: InputMaybe<Order_By>;
  total_balance?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_params_audit */
export type Partner_Params_Audit_Pk_Columns_Input = {
  transaction_id: Scalars['uuid'];
};

/** select columns of table "partner_params_audit" */
export enum Partner_Params_Audit_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Operation = 'operation',
  /** column name */
  PayoutBalance = 'payout_balance',
  /** column name */
  ReferralsCount = 'referrals_count',
  /** column name */
  TotalBalance = 'total_balance',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "partner_params_audit" */
export type Partner_Params_Audit_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  operation?: InputMaybe<Scalars['String']>;
  payout_balance?: InputMaybe<Scalars['bigint']>;
  referrals_count?: InputMaybe<Scalars['bigint']>;
  total_balance?: InputMaybe<Scalars['bigint']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Partner_Params_Audit_Stddev_Fields = {
  __typename?: 'partner_params_audit_stddev_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Params_Audit_Stddev_Pop_Fields = {
  __typename?: 'partner_params_audit_stddev_pop_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Params_Audit_Stddev_Samp_Fields = {
  __typename?: 'partner_params_audit_stddev_samp_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_params_audit" */
export type Partner_Params_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Params_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Params_Audit_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  operation?: InputMaybe<Scalars['String']>;
  payout_balance?: InputMaybe<Scalars['bigint']>;
  referrals_count?: InputMaybe<Scalars['bigint']>;
  total_balance?: InputMaybe<Scalars['bigint']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Partner_Params_Audit_Sum_Fields = {
  __typename?: 'partner_params_audit_sum_fields';
  payout_balance?: Maybe<Scalars['bigint']>;
  referrals_count?: Maybe<Scalars['bigint']>;
  total_balance?: Maybe<Scalars['bigint']>;
};

/** update columns of table "partner_params_audit" */
export enum Partner_Params_Audit_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Operation = 'operation',
  /** column name */
  PayoutBalance = 'payout_balance',
  /** column name */
  ReferralsCount = 'referrals_count',
  /** column name */
  TotalBalance = 'total_balance',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Params_Audit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Params_Audit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Params_Audit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Params_Audit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Params_Audit_Var_Pop_Fields = {
  __typename?: 'partner_params_audit_var_pop_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Params_Audit_Var_Samp_Fields = {
  __typename?: 'partner_params_audit_var_samp_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Params_Audit_Variance_Fields = {
  __typename?: 'partner_params_audit_variance_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** aggregate avg on columns */
export type Partner_Params_Avg_Fields = {
  __typename?: 'partner_params_avg_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "partner_params". All fields are combined with a logical 'AND'. */
export type Partner_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Params_Bool_Exp>>;
  _not?: InputMaybe<Partner_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Params_Bool_Exp>>;
  partner_balance_transactions?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
  partner_balance_transactions_aggregate?: InputMaybe<Partner_Balance_Transactions_Aggregate_Bool_Exp>;
  payout_balance?: InputMaybe<Bigint_Comparison_Exp>;
  referrals_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_balance?: InputMaybe<Bigint_Comparison_Exp>;
  user_account?: InputMaybe<User_Account_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_referral_offers?: InputMaybe<User_Referral_Offers_Bool_Exp>;
  user_referral_offers_aggregate?: InputMaybe<User_Referral_Offers_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "partner_params" */
export enum Partner_Params_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  PartnerParamsPkey = 'partner_params_pkey'
}

/** input type for incrementing numeric columns in table "partner_params" */
export type Partner_Params_Inc_Input = {
  payout_balance?: InputMaybe<Scalars['bigint']>;
  referrals_count?: InputMaybe<Scalars['bigint']>;
  total_balance?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "partner_params" */
export type Partner_Params_Insert_Input = {
  partner_balance_transactions?: InputMaybe<Partner_Balance_Transactions_Arr_Rel_Insert_Input>;
  payout_balance?: InputMaybe<Scalars['bigint']>;
  referrals_count?: InputMaybe<Scalars['bigint']>;
  total_balance?: InputMaybe<Scalars['bigint']>;
  user_account?: InputMaybe<User_Account_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_referral_offers?: InputMaybe<User_Referral_Offers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Partner_Params_Max_Fields = {
  __typename?: 'partner_params_max_fields';
  payout_balance?: Maybe<Scalars['bigint']>;
  referrals_count?: Maybe<Scalars['bigint']>;
  total_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Partner_Params_Min_Fields = {
  __typename?: 'partner_params_min_fields';
  payout_balance?: Maybe<Scalars['bigint']>;
  referrals_count?: Maybe<Scalars['bigint']>;
  total_balance?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "partner_params" */
export type Partner_Params_Mutation_Response = {
  __typename?: 'partner_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Params>;
};

/** input type for inserting object relation for remote table "partner_params" */
export type Partner_Params_Obj_Rel_Insert_Input = {
  data: Partner_Params_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Params_On_Conflict>;
};

/** on_conflict condition type for table "partner_params" */
export type Partner_Params_On_Conflict = {
  constraint: Partner_Params_Constraint;
  update_columns?: Array<Partner_Params_Update_Column>;
  where?: InputMaybe<Partner_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "partner_params". */
export type Partner_Params_Order_By = {
  partner_balance_transactions_aggregate?: InputMaybe<Partner_Balance_Transactions_Aggregate_Order_By>;
  payout_balance?: InputMaybe<Order_By>;
  referrals_count?: InputMaybe<Order_By>;
  total_balance?: InputMaybe<Order_By>;
  user_account?: InputMaybe<User_Account_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_referral_offers_aggregate?: InputMaybe<User_Referral_Offers_Aggregate_Order_By>;
};

/** primary key columns input for table: partner_params */
export type Partner_Params_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "partner_params" */
export enum Partner_Params_Select_Column {
  /** column name */
  PayoutBalance = 'payout_balance',
  /** column name */
  ReferralsCount = 'referrals_count',
  /** column name */
  TotalBalance = 'total_balance',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "partner_params" */
export type Partner_Params_Set_Input = {
  payout_balance?: InputMaybe<Scalars['bigint']>;
  referrals_count?: InputMaybe<Scalars['bigint']>;
  total_balance?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Partner_Params_Stddev_Fields = {
  __typename?: 'partner_params_stddev_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Params_Stddev_Pop_Fields = {
  __typename?: 'partner_params_stddev_pop_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Params_Stddev_Samp_Fields = {
  __typename?: 'partner_params_stddev_samp_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "partner_params" */
export type Partner_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Params_Stream_Cursor_Value_Input = {
  payout_balance?: InputMaybe<Scalars['bigint']>;
  referrals_count?: InputMaybe<Scalars['bigint']>;
  total_balance?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Partner_Params_Sum_Fields = {
  __typename?: 'partner_params_sum_fields';
  payout_balance?: Maybe<Scalars['bigint']>;
  referrals_count?: Maybe<Scalars['bigint']>;
  total_balance?: Maybe<Scalars['bigint']>;
};

/** update columns of table "partner_params" */
export enum Partner_Params_Update_Column {
  /** column name */
  PayoutBalance = 'payout_balance',
  /** column name */
  ReferralsCount = 'referrals_count',
  /** column name */
  TotalBalance = 'total_balance',
  /** column name */
  UserId = 'user_id'
}

export type Partner_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Partner_Params_Var_Pop_Fields = {
  __typename?: 'partner_params_var_pop_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Params_Var_Samp_Fields = {
  __typename?: 'partner_params_var_samp_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Params_Variance_Fields = {
  __typename?: 'partner_params_variance_fields';
  payout_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  total_balance?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "passport_info" */
export type Passport_Info = {
  __typename?: 'passport_info';
  id: Scalars['Int'];
  number: Scalars['Int'];
  registration: Scalars['String'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "passport_info" */
export type Passport_Info_Aggregate = {
  __typename?: 'passport_info_aggregate';
  aggregate?: Maybe<Passport_Info_Aggregate_Fields>;
  nodes: Array<Passport_Info>;
};

/** aggregate fields of "passport_info" */
export type Passport_Info_Aggregate_Fields = {
  __typename?: 'passport_info_aggregate_fields';
  avg?: Maybe<Passport_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Passport_Info_Max_Fields>;
  min?: Maybe<Passport_Info_Min_Fields>;
  stddev?: Maybe<Passport_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Passport_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Passport_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Passport_Info_Sum_Fields>;
  var_pop?: Maybe<Passport_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Passport_Info_Var_Samp_Fields>;
  variance?: Maybe<Passport_Info_Variance_Fields>;
};


/** aggregate fields of "passport_info" */
export type Passport_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Passport_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Passport_Info_Avg_Fields = {
  __typename?: 'passport_info_avg_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "passport_info". All fields are combined with a logical 'AND'. */
export type Passport_Info_Bool_Exp = {
  _and?: InputMaybe<Array<Passport_Info_Bool_Exp>>;
  _not?: InputMaybe<Passport_Info_Bool_Exp>;
  _or?: InputMaybe<Array<Passport_Info_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  number?: InputMaybe<Int_Comparison_Exp>;
  registration?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "passport_info" */
export enum Passport_Info_Constraint {
  /** unique or primary key constraint on columns "id" */
  PassportInfoPkey = 'passport_info_pkey',
  /** unique or primary key constraint on columns "user_id" */
  PassportInfoUserIdKey = 'passport_info_user_id_key'
}

/** input type for incrementing numeric columns in table "passport_info" */
export type Passport_Info_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "passport_info" */
export type Passport_Info_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['Int']>;
  registration?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Passport_Info_Max_Fields = {
  __typename?: 'passport_info_max_fields';
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  registration?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Passport_Info_Min_Fields = {
  __typename?: 'passport_info_min_fields';
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  registration?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "passport_info" */
export type Passport_Info_Mutation_Response = {
  __typename?: 'passport_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Passport_Info>;
};

/** input type for inserting object relation for remote table "passport_info" */
export type Passport_Info_Obj_Rel_Insert_Input = {
  data: Passport_Info_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Passport_Info_On_Conflict>;
};

/** on_conflict condition type for table "passport_info" */
export type Passport_Info_On_Conflict = {
  constraint: Passport_Info_Constraint;
  update_columns?: Array<Passport_Info_Update_Column>;
  where?: InputMaybe<Passport_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "passport_info". */
export type Passport_Info_Order_By = {
  id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  registration?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: passport_info */
export type Passport_Info_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "passport_info" */
export enum Passport_Info_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  Registration = 'registration',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "passport_info" */
export type Passport_Info_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['Int']>;
  registration?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Passport_Info_Stddev_Fields = {
  __typename?: 'passport_info_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Passport_Info_Stddev_Pop_Fields = {
  __typename?: 'passport_info_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Passport_Info_Stddev_Samp_Fields = {
  __typename?: 'passport_info_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "passport_info" */
export type Passport_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Passport_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Passport_Info_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['Int']>;
  registration?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Passport_Info_Sum_Fields = {
  __typename?: 'passport_info_sum_fields';
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
};

/** update columns of table "passport_info" */
export enum Passport_Info_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  Registration = 'registration',
  /** column name */
  UserId = 'user_id'
}

export type Passport_Info_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Passport_Info_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Passport_Info_Set_Input>;
  /** filter the rows which have to be updated */
  where: Passport_Info_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Passport_Info_Var_Pop_Fields = {
  __typename?: 'passport_info_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Passport_Info_Var_Samp_Fields = {
  __typename?: 'passport_info_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Passport_Info_Variance_Fields = {
  __typename?: 'passport_info_variance_fields';
  id?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "payment_methods" */
export type Payment_Methods = {
  __typename?: 'payment_methods';
  display_name?: Maybe<Scalars['String']>;
  pm_method?: Maybe<Scalars['String']>;
  pm_method_id: Scalars['Int'];
  pm_sub_method?: Maybe<Scalars['String']>;
};

/** aggregated selection of "payment_methods" */
export type Payment_Methods_Aggregate = {
  __typename?: 'payment_methods_aggregate';
  aggregate?: Maybe<Payment_Methods_Aggregate_Fields>;
  nodes: Array<Payment_Methods>;
};

/** aggregate fields of "payment_methods" */
export type Payment_Methods_Aggregate_Fields = {
  __typename?: 'payment_methods_aggregate_fields';
  avg?: Maybe<Payment_Methods_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Methods_Max_Fields>;
  min?: Maybe<Payment_Methods_Min_Fields>;
  stddev?: Maybe<Payment_Methods_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Methods_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Methods_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Methods_Sum_Fields>;
  var_pop?: Maybe<Payment_Methods_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Methods_Var_Samp_Fields>;
  variance?: Maybe<Payment_Methods_Variance_Fields>;
};


/** aggregate fields of "payment_methods" */
export type Payment_Methods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Methods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Payment_Methods_Avg_Fields = {
  __typename?: 'payment_methods_avg_fields';
  pm_method_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payment_methods". All fields are combined with a logical 'AND'. */
export type Payment_Methods_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Methods_Bool_Exp>>;
  _not?: InputMaybe<Payment_Methods_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Methods_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  pm_method?: InputMaybe<String_Comparison_Exp>;
  pm_method_id?: InputMaybe<Int_Comparison_Exp>;
  pm_sub_method?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_methods" */
export enum Payment_Methods_Constraint {
  /** unique or primary key constraint on columns "pm_method_id" */
  PaymentMethodsPkey = 'payment_methods_pkey'
}

/** input type for incrementing numeric columns in table "payment_methods" */
export type Payment_Methods_Inc_Input = {
  pm_method_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_methods" */
export type Payment_Methods_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  pm_method?: InputMaybe<Scalars['String']>;
  pm_method_id?: InputMaybe<Scalars['Int']>;
  pm_sub_method?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_Methods_Max_Fields = {
  __typename?: 'payment_methods_max_fields';
  display_name?: Maybe<Scalars['String']>;
  pm_method?: Maybe<Scalars['String']>;
  pm_method_id?: Maybe<Scalars['Int']>;
  pm_sub_method?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Methods_Min_Fields = {
  __typename?: 'payment_methods_min_fields';
  display_name?: Maybe<Scalars['String']>;
  pm_method?: Maybe<Scalars['String']>;
  pm_method_id?: Maybe<Scalars['Int']>;
  pm_sub_method?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_methods" */
export type Payment_Methods_Mutation_Response = {
  __typename?: 'payment_methods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Methods>;
};

/** input type for inserting object relation for remote table "payment_methods" */
export type Payment_Methods_Obj_Rel_Insert_Input = {
  data: Payment_Methods_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Methods_On_Conflict>;
};

/** on_conflict condition type for table "payment_methods" */
export type Payment_Methods_On_Conflict = {
  constraint: Payment_Methods_Constraint;
  update_columns?: Array<Payment_Methods_Update_Column>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_methods". */
export type Payment_Methods_Order_By = {
  display_name?: InputMaybe<Order_By>;
  pm_method?: InputMaybe<Order_By>;
  pm_method_id?: InputMaybe<Order_By>;
  pm_sub_method?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_methods */
export type Payment_Methods_Pk_Columns_Input = {
  pm_method_id: Scalars['Int'];
};

/** select columns of table "payment_methods" */
export enum Payment_Methods_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  PmMethod = 'pm_method',
  /** column name */
  PmMethodId = 'pm_method_id',
  /** column name */
  PmSubMethod = 'pm_sub_method'
}

/** input type for updating data in table "payment_methods" */
export type Payment_Methods_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  pm_method?: InputMaybe<Scalars['String']>;
  pm_method_id?: InputMaybe<Scalars['Int']>;
  pm_sub_method?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Payment_Methods_Stddev_Fields = {
  __typename?: 'payment_methods_stddev_fields';
  pm_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payment_Methods_Stddev_Pop_Fields = {
  __typename?: 'payment_methods_stddev_pop_fields';
  pm_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payment_Methods_Stddev_Samp_Fields = {
  __typename?: 'payment_methods_stddev_samp_fields';
  pm_method_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "payment_methods" */
export type Payment_Methods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Methods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Methods_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  pm_method?: InputMaybe<Scalars['String']>;
  pm_method_id?: InputMaybe<Scalars['Int']>;
  pm_sub_method?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Payment_Methods_Sum_Fields = {
  __typename?: 'payment_methods_sum_fields';
  pm_method_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "payment_methods" */
export enum Payment_Methods_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  PmMethod = 'pm_method',
  /** column name */
  PmMethodId = 'pm_method_id',
  /** column name */
  PmSubMethod = 'pm_sub_method'
}

export type Payment_Methods_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Methods_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Methods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Methods_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Methods_Var_Pop_Fields = {
  __typename?: 'payment_methods_var_pop_fields';
  pm_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payment_Methods_Var_Samp_Fields = {
  __typename?: 'payment_methods_var_samp_fields';
  pm_method_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payment_Methods_Variance_Fields = {
  __typename?: 'payment_methods_variance_fields';
  pm_method_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "payment_methods_view" */
export type Payment_Methods_View = {
  __typename?: 'payment_methods_view';
  commission?: Maybe<Scalars['Float']>;
  currency: Scalars['String'];
  display_name: Scalars['String'];
  is_active: Scalars['Boolean'];
  /** флаг для вывода информации, что метод относится к p2p */
  is_p2p: Scalars['Boolean'];
  /** флаг для отображения метода в списке популярных методов пополнения */
  is_popular: Scalars['Boolean'];
  max_amount: Scalars['Float'];
  method: Scalars['String'];
  method_id: Scalars['uuid'];
  min_amount: Scalars['Float'];
  pic?: Maybe<Scalars['String']>;
  pic_asset_name?: Maybe<Scalars['String']>;
  pic_mobile?: Maybe<Scalars['String']>;
  pic_mobile_asset_name?: Maybe<Scalars['String']>;
  ps_type: Scalars['Int'];
  sort: Scalars['Int'];
  sub_method: Scalars['String'];
  tab_id: Scalars['Int'];
};

/** aggregated selection of "payment_methods_view" */
export type Payment_Methods_View_Aggregate = {
  __typename?: 'payment_methods_view_aggregate';
  aggregate?: Maybe<Payment_Methods_View_Aggregate_Fields>;
  nodes: Array<Payment_Methods_View>;
};

/** aggregate fields of "payment_methods_view" */
export type Payment_Methods_View_Aggregate_Fields = {
  __typename?: 'payment_methods_view_aggregate_fields';
  avg?: Maybe<Payment_Methods_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Methods_View_Max_Fields>;
  min?: Maybe<Payment_Methods_View_Min_Fields>;
  stddev?: Maybe<Payment_Methods_View_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Methods_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Methods_View_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Methods_View_Sum_Fields>;
  var_pop?: Maybe<Payment_Methods_View_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Methods_View_Var_Samp_Fields>;
  variance?: Maybe<Payment_Methods_View_Variance_Fields>;
};


/** aggregate fields of "payment_methods_view" */
export type Payment_Methods_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Methods_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Payment_Methods_View_Avg_Fields = {
  __typename?: 'payment_methods_view_avg_fields';
  commission?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  ps_type?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  tab_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payment_methods_view". All fields are combined with a logical 'AND'. */
export type Payment_Methods_View_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Methods_View_Bool_Exp>>;
  _not?: InputMaybe<Payment_Methods_View_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Methods_View_Bool_Exp>>;
  commission?: InputMaybe<Float_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_p2p?: InputMaybe<Boolean_Comparison_Exp>;
  is_popular?: InputMaybe<Boolean_Comparison_Exp>;
  max_amount?: InputMaybe<Float_Comparison_Exp>;
  method?: InputMaybe<String_Comparison_Exp>;
  method_id?: InputMaybe<Uuid_Comparison_Exp>;
  min_amount?: InputMaybe<Float_Comparison_Exp>;
  pic?: InputMaybe<String_Comparison_Exp>;
  pic_asset_name?: InputMaybe<String_Comparison_Exp>;
  pic_mobile?: InputMaybe<String_Comparison_Exp>;
  pic_mobile_asset_name?: InputMaybe<String_Comparison_Exp>;
  ps_type?: InputMaybe<Int_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
  sub_method?: InputMaybe<String_Comparison_Exp>;
  tab_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_methods_view" */
export enum Payment_Methods_View_Constraint {
  /** unique or primary key constraint on columns "method_id" */
  PaymentMethodsViewPkey = 'payment_methods_view_pkey'
}

/** input type for incrementing numeric columns in table "payment_methods_view" */
export type Payment_Methods_View_Inc_Input = {
  commission?: InputMaybe<Scalars['Float']>;
  max_amount?: InputMaybe<Scalars['Float']>;
  min_amount?: InputMaybe<Scalars['Float']>;
  ps_type?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
  tab_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_methods_view" */
export type Payment_Methods_View_Insert_Input = {
  commission?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** флаг для вывода информации, что метод относится к p2p */
  is_p2p?: InputMaybe<Scalars['Boolean']>;
  /** флаг для отображения метода в списке популярных методов пополнения */
  is_popular?: InputMaybe<Scalars['Boolean']>;
  max_amount?: InputMaybe<Scalars['Float']>;
  method?: InputMaybe<Scalars['String']>;
  method_id?: InputMaybe<Scalars['uuid']>;
  min_amount?: InputMaybe<Scalars['Float']>;
  pic?: InputMaybe<Scalars['String']>;
  pic_asset_name?: InputMaybe<Scalars['String']>;
  pic_mobile?: InputMaybe<Scalars['String']>;
  pic_mobile_asset_name?: InputMaybe<Scalars['String']>;
  ps_type?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
  sub_method?: InputMaybe<Scalars['String']>;
  tab_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Payment_Methods_View_Max_Fields = {
  __typename?: 'payment_methods_view_max_fields';
  commission?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  max_amount?: Maybe<Scalars['Float']>;
  method?: Maybe<Scalars['String']>;
  method_id?: Maybe<Scalars['uuid']>;
  min_amount?: Maybe<Scalars['Float']>;
  pic?: Maybe<Scalars['String']>;
  pic_asset_name?: Maybe<Scalars['String']>;
  pic_mobile?: Maybe<Scalars['String']>;
  pic_mobile_asset_name?: Maybe<Scalars['String']>;
  ps_type?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  sub_method?: Maybe<Scalars['String']>;
  tab_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Payment_Methods_View_Min_Fields = {
  __typename?: 'payment_methods_view_min_fields';
  commission?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  max_amount?: Maybe<Scalars['Float']>;
  method?: Maybe<Scalars['String']>;
  method_id?: Maybe<Scalars['uuid']>;
  min_amount?: Maybe<Scalars['Float']>;
  pic?: Maybe<Scalars['String']>;
  pic_asset_name?: Maybe<Scalars['String']>;
  pic_mobile?: Maybe<Scalars['String']>;
  pic_mobile_asset_name?: Maybe<Scalars['String']>;
  ps_type?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  sub_method?: Maybe<Scalars['String']>;
  tab_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "payment_methods_view" */
export type Payment_Methods_View_Mutation_Response = {
  __typename?: 'payment_methods_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Methods_View>;
};

/** on_conflict condition type for table "payment_methods_view" */
export type Payment_Methods_View_On_Conflict = {
  constraint: Payment_Methods_View_Constraint;
  update_columns?: Array<Payment_Methods_View_Update_Column>;
  where?: InputMaybe<Payment_Methods_View_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_methods_view". */
export type Payment_Methods_View_Order_By = {
  commission?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_p2p?: InputMaybe<Order_By>;
  is_popular?: InputMaybe<Order_By>;
  max_amount?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  method_id?: InputMaybe<Order_By>;
  min_amount?: InputMaybe<Order_By>;
  pic?: InputMaybe<Order_By>;
  pic_asset_name?: InputMaybe<Order_By>;
  pic_mobile?: InputMaybe<Order_By>;
  pic_mobile_asset_name?: InputMaybe<Order_By>;
  ps_type?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
  sub_method?: InputMaybe<Order_By>;
  tab_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_methods_view */
export type Payment_Methods_View_Pk_Columns_Input = {
  method_id: Scalars['uuid'];
};

/** select columns of table "payment_methods_view" */
export enum Payment_Methods_View_Select_Column {
  /** column name */
  Commission = 'commission',
  /** column name */
  Currency = 'currency',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsP2p = 'is_p2p',
  /** column name */
  IsPopular = 'is_popular',
  /** column name */
  MaxAmount = 'max_amount',
  /** column name */
  Method = 'method',
  /** column name */
  MethodId = 'method_id',
  /** column name */
  MinAmount = 'min_amount',
  /** column name */
  Pic = 'pic',
  /** column name */
  PicAssetName = 'pic_asset_name',
  /** column name */
  PicMobile = 'pic_mobile',
  /** column name */
  PicMobileAssetName = 'pic_mobile_asset_name',
  /** column name */
  PsType = 'ps_type',
  /** column name */
  Sort = 'sort',
  /** column name */
  SubMethod = 'sub_method',
  /** column name */
  TabId = 'tab_id'
}

/** input type for updating data in table "payment_methods_view" */
export type Payment_Methods_View_Set_Input = {
  commission?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** флаг для вывода информации, что метод относится к p2p */
  is_p2p?: InputMaybe<Scalars['Boolean']>;
  /** флаг для отображения метода в списке популярных методов пополнения */
  is_popular?: InputMaybe<Scalars['Boolean']>;
  max_amount?: InputMaybe<Scalars['Float']>;
  method?: InputMaybe<Scalars['String']>;
  method_id?: InputMaybe<Scalars['uuid']>;
  min_amount?: InputMaybe<Scalars['Float']>;
  pic?: InputMaybe<Scalars['String']>;
  pic_asset_name?: InputMaybe<Scalars['String']>;
  pic_mobile?: InputMaybe<Scalars['String']>;
  pic_mobile_asset_name?: InputMaybe<Scalars['String']>;
  ps_type?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
  sub_method?: InputMaybe<Scalars['String']>;
  tab_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Payment_Methods_View_Stddev_Fields = {
  __typename?: 'payment_methods_view_stddev_fields';
  commission?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  ps_type?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  tab_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payment_Methods_View_Stddev_Pop_Fields = {
  __typename?: 'payment_methods_view_stddev_pop_fields';
  commission?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  ps_type?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  tab_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payment_Methods_View_Stddev_Samp_Fields = {
  __typename?: 'payment_methods_view_stddev_samp_fields';
  commission?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  ps_type?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  tab_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "payment_methods_view" */
export type Payment_Methods_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Methods_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Methods_View_Stream_Cursor_Value_Input = {
  commission?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** флаг для вывода информации, что метод относится к p2p */
  is_p2p?: InputMaybe<Scalars['Boolean']>;
  /** флаг для отображения метода в списке популярных методов пополнения */
  is_popular?: InputMaybe<Scalars['Boolean']>;
  max_amount?: InputMaybe<Scalars['Float']>;
  method?: InputMaybe<Scalars['String']>;
  method_id?: InputMaybe<Scalars['uuid']>;
  min_amount?: InputMaybe<Scalars['Float']>;
  pic?: InputMaybe<Scalars['String']>;
  pic_asset_name?: InputMaybe<Scalars['String']>;
  pic_mobile?: InputMaybe<Scalars['String']>;
  pic_mobile_asset_name?: InputMaybe<Scalars['String']>;
  ps_type?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['Int']>;
  sub_method?: InputMaybe<Scalars['String']>;
  tab_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Payment_Methods_View_Sum_Fields = {
  __typename?: 'payment_methods_view_sum_fields';
  commission?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  ps_type?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  tab_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "payment_methods_view" */
export enum Payment_Methods_View_Update_Column {
  /** column name */
  Commission = 'commission',
  /** column name */
  Currency = 'currency',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsP2p = 'is_p2p',
  /** column name */
  IsPopular = 'is_popular',
  /** column name */
  MaxAmount = 'max_amount',
  /** column name */
  Method = 'method',
  /** column name */
  MethodId = 'method_id',
  /** column name */
  MinAmount = 'min_amount',
  /** column name */
  Pic = 'pic',
  /** column name */
  PicAssetName = 'pic_asset_name',
  /** column name */
  PicMobile = 'pic_mobile',
  /** column name */
  PicMobileAssetName = 'pic_mobile_asset_name',
  /** column name */
  PsType = 'ps_type',
  /** column name */
  Sort = 'sort',
  /** column name */
  SubMethod = 'sub_method',
  /** column name */
  TabId = 'tab_id'
}

export type Payment_Methods_View_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Methods_View_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Methods_View_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Methods_View_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Methods_View_Var_Pop_Fields = {
  __typename?: 'payment_methods_view_var_pop_fields';
  commission?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  ps_type?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  tab_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payment_Methods_View_Var_Samp_Fields = {
  __typename?: 'payment_methods_view_var_samp_fields';
  commission?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  ps_type?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  tab_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payment_Methods_View_Variance_Fields = {
  __typename?: 'payment_methods_view_variance_fields';
  commission?: Maybe<Scalars['Float']>;
  max_amount?: Maybe<Scalars['Float']>;
  min_amount?: Maybe<Scalars['Float']>;
  ps_type?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Float']>;
  tab_id?: Maybe<Scalars['Float']>;
};

/** Таблица данных провайдеров платежей. merchant_data - это данные провайдера платежа(partner_id или shop_id) для идентификации партнёра */
export type Payment_Providers = {
  __typename?: 'payment_providers';
  callback_url?: Maybe<Scalars['String']>;
  default_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  display_name: Scalars['String'];
  failure_url?: Maybe<Scalars['String']>;
  is_active: Scalars['Boolean'];
  merchant_data?: Maybe<Scalars['String']>;
  project_data?: Maybe<Scalars['String']>;
  project_key1?: Maybe<Scalars['String']>;
  project_key2?: Maybe<Scalars['String']>;
  ps_id: Scalars['uuid'];
  ps_urls?: Maybe<Scalars['jsonb']>;
  success_url?: Maybe<Scalars['String']>;
};


/** Таблица данных провайдеров платежей. merchant_data - это данные провайдера платежа(partner_id или shop_id) для идентификации партнёра */
export type Payment_ProvidersPs_UrlsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "payment_providers" */
export type Payment_Providers_Aggregate = {
  __typename?: 'payment_providers_aggregate';
  aggregate?: Maybe<Payment_Providers_Aggregate_Fields>;
  nodes: Array<Payment_Providers>;
};

/** aggregate fields of "payment_providers" */
export type Payment_Providers_Aggregate_Fields = {
  __typename?: 'payment_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_Providers_Max_Fields>;
  min?: Maybe<Payment_Providers_Min_Fields>;
};


/** aggregate fields of "payment_providers" */
export type Payment_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Payment_Providers_Append_Input = {
  ps_urls?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "payment_providers". All fields are combined with a logical 'AND'. */
export type Payment_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Providers_Bool_Exp>>;
  _not?: InputMaybe<Payment_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Providers_Bool_Exp>>;
  callback_url?: InputMaybe<String_Comparison_Exp>;
  default_url?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  failure_url?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  merchant_data?: InputMaybe<String_Comparison_Exp>;
  project_data?: InputMaybe<String_Comparison_Exp>;
  project_key1?: InputMaybe<String_Comparison_Exp>;
  project_key2?: InputMaybe<String_Comparison_Exp>;
  ps_id?: InputMaybe<Uuid_Comparison_Exp>;
  ps_urls?: InputMaybe<Jsonb_Comparison_Exp>;
  success_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_providers" */
export enum Payment_Providers_Constraint {
  /** unique or primary key constraint on columns "ps_id" */
  PaymentSystemsProvidersPkey = 'payment_systems_providers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Payment_Providers_Delete_At_Path_Input = {
  ps_urls?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Payment_Providers_Delete_Elem_Input = {
  ps_urls?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Payment_Providers_Delete_Key_Input = {
  ps_urls?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "payment_providers" */
export type Payment_Providers_Insert_Input = {
  callback_url?: InputMaybe<Scalars['String']>;
  default_url?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  failure_url?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  merchant_data?: InputMaybe<Scalars['String']>;
  project_data?: InputMaybe<Scalars['String']>;
  project_key1?: InputMaybe<Scalars['String']>;
  project_key2?: InputMaybe<Scalars['String']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_urls?: InputMaybe<Scalars['jsonb']>;
  success_url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_Providers_Max_Fields = {
  __typename?: 'payment_providers_max_fields';
  callback_url?: Maybe<Scalars['String']>;
  default_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  failure_url?: Maybe<Scalars['String']>;
  merchant_data?: Maybe<Scalars['String']>;
  project_data?: Maybe<Scalars['String']>;
  project_key1?: Maybe<Scalars['String']>;
  project_key2?: Maybe<Scalars['String']>;
  ps_id?: Maybe<Scalars['uuid']>;
  success_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Providers_Min_Fields = {
  __typename?: 'payment_providers_min_fields';
  callback_url?: Maybe<Scalars['String']>;
  default_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  failure_url?: Maybe<Scalars['String']>;
  merchant_data?: Maybe<Scalars['String']>;
  project_data?: Maybe<Scalars['String']>;
  project_key1?: Maybe<Scalars['String']>;
  project_key2?: Maybe<Scalars['String']>;
  ps_id?: Maybe<Scalars['uuid']>;
  success_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_providers" */
export type Payment_Providers_Mutation_Response = {
  __typename?: 'payment_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Providers>;
};

/** input type for inserting object relation for remote table "payment_providers" */
export type Payment_Providers_Obj_Rel_Insert_Input = {
  data: Payment_Providers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Providers_On_Conflict>;
};

/** on_conflict condition type for table "payment_providers" */
export type Payment_Providers_On_Conflict = {
  constraint: Payment_Providers_Constraint;
  update_columns?: Array<Payment_Providers_Update_Column>;
  where?: InputMaybe<Payment_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_providers". */
export type Payment_Providers_Order_By = {
  callback_url?: InputMaybe<Order_By>;
  default_url?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  failure_url?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  merchant_data?: InputMaybe<Order_By>;
  project_data?: InputMaybe<Order_By>;
  project_key1?: InputMaybe<Order_By>;
  project_key2?: InputMaybe<Order_By>;
  ps_id?: InputMaybe<Order_By>;
  ps_urls?: InputMaybe<Order_By>;
  success_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_providers */
export type Payment_Providers_Pk_Columns_Input = {
  ps_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Payment_Providers_Prepend_Input = {
  ps_urls?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "payment_providers" */
export enum Payment_Providers_Select_Column {
  /** column name */
  CallbackUrl = 'callback_url',
  /** column name */
  DefaultUrl = 'default_url',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FailureUrl = 'failure_url',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MerchantData = 'merchant_data',
  /** column name */
  ProjectData = 'project_data',
  /** column name */
  ProjectKey1 = 'project_key1',
  /** column name */
  ProjectKey2 = 'project_key2',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PsUrls = 'ps_urls',
  /** column name */
  SuccessUrl = 'success_url'
}

/** input type for updating data in table "payment_providers" */
export type Payment_Providers_Set_Input = {
  callback_url?: InputMaybe<Scalars['String']>;
  default_url?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  failure_url?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  merchant_data?: InputMaybe<Scalars['String']>;
  project_data?: InputMaybe<Scalars['String']>;
  project_key1?: InputMaybe<Scalars['String']>;
  project_key2?: InputMaybe<Scalars['String']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_urls?: InputMaybe<Scalars['jsonb']>;
  success_url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_providers" */
export type Payment_Providers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Providers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Providers_Stream_Cursor_Value_Input = {
  callback_url?: InputMaybe<Scalars['String']>;
  default_url?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  failure_url?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  merchant_data?: InputMaybe<Scalars['String']>;
  project_data?: InputMaybe<Scalars['String']>;
  project_key1?: InputMaybe<Scalars['String']>;
  project_key2?: InputMaybe<Scalars['String']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_urls?: InputMaybe<Scalars['jsonb']>;
  success_url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "payment_providers" */
export enum Payment_Providers_Update_Column {
  /** column name */
  CallbackUrl = 'callback_url',
  /** column name */
  DefaultUrl = 'default_url',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FailureUrl = 'failure_url',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MerchantData = 'merchant_data',
  /** column name */
  ProjectData = 'project_data',
  /** column name */
  ProjectKey1 = 'project_key1',
  /** column name */
  ProjectKey2 = 'project_key2',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PsUrls = 'ps_urls',
  /** column name */
  SuccessUrl = 'success_url'
}

export type Payment_Providers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Payment_Providers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Payment_Providers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Payment_Providers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Payment_Providers_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Payment_Providers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Providers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Providers_Bool_Exp;
};

/** columns and relationships of "payment_recurring_tokens" */
export type Payment_Recurring_Tokens = {
  __typename?: 'payment_recurring_tokens';
  created_at: Scalars['timestamp'];
  expiration_date?: Maybe<Scalars['timestamp']>;
  is_active: Scalars['Boolean'];
  pm_method: Scalars['Int'];
  pmrecur_id: Scalars['uuid'];
  ps_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "payment_recurring_tokens" */
export type Payment_Recurring_Tokens_Aggregate = {
  __typename?: 'payment_recurring_tokens_aggregate';
  aggregate?: Maybe<Payment_Recurring_Tokens_Aggregate_Fields>;
  nodes: Array<Payment_Recurring_Tokens>;
};

/** aggregate fields of "payment_recurring_tokens" */
export type Payment_Recurring_Tokens_Aggregate_Fields = {
  __typename?: 'payment_recurring_tokens_aggregate_fields';
  avg?: Maybe<Payment_Recurring_Tokens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Recurring_Tokens_Max_Fields>;
  min?: Maybe<Payment_Recurring_Tokens_Min_Fields>;
  stddev?: Maybe<Payment_Recurring_Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Recurring_Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Recurring_Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Recurring_Tokens_Sum_Fields>;
  var_pop?: Maybe<Payment_Recurring_Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Recurring_Tokens_Var_Samp_Fields>;
  variance?: Maybe<Payment_Recurring_Tokens_Variance_Fields>;
};


/** aggregate fields of "payment_recurring_tokens" */
export type Payment_Recurring_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Recurring_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Payment_Recurring_Tokens_Avg_Fields = {
  __typename?: 'payment_recurring_tokens_avg_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payment_recurring_tokens". All fields are combined with a logical 'AND'. */
export type Payment_Recurring_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Recurring_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Payment_Recurring_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Recurring_Tokens_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  expiration_date?: InputMaybe<Timestamp_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  pm_method?: InputMaybe<Int_Comparison_Exp>;
  pmrecur_id?: InputMaybe<Uuid_Comparison_Exp>;
  ps_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_recurring_tokens" */
export enum Payment_Recurring_Tokens_Constraint {
  /** unique or primary key constraint on columns "pmrecur_id" */
  PaymentRecurringTokensPkey = 'payment_recurring_tokens_pkey'
}

/** input type for incrementing numeric columns in table "payment_recurring_tokens" */
export type Payment_Recurring_Tokens_Inc_Input = {
  pm_method?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_recurring_tokens" */
export type Payment_Recurring_Tokens_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  expiration_date?: InputMaybe<Scalars['timestamp']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  pm_method?: InputMaybe<Scalars['Int']>;
  pmrecur_id?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Payment_Recurring_Tokens_Max_Fields = {
  __typename?: 'payment_recurring_tokens_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  expiration_date?: Maybe<Scalars['timestamp']>;
  pm_method?: Maybe<Scalars['Int']>;
  pmrecur_id?: Maybe<Scalars['uuid']>;
  ps_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Payment_Recurring_Tokens_Min_Fields = {
  __typename?: 'payment_recurring_tokens_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  expiration_date?: Maybe<Scalars['timestamp']>;
  pm_method?: Maybe<Scalars['Int']>;
  pmrecur_id?: Maybe<Scalars['uuid']>;
  ps_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "payment_recurring_tokens" */
export type Payment_Recurring_Tokens_Mutation_Response = {
  __typename?: 'payment_recurring_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Recurring_Tokens>;
};

/** on_conflict condition type for table "payment_recurring_tokens" */
export type Payment_Recurring_Tokens_On_Conflict = {
  constraint: Payment_Recurring_Tokens_Constraint;
  update_columns?: Array<Payment_Recurring_Tokens_Update_Column>;
  where?: InputMaybe<Payment_Recurring_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_recurring_tokens". */
export type Payment_Recurring_Tokens_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  pm_method?: InputMaybe<Order_By>;
  pmrecur_id?: InputMaybe<Order_By>;
  ps_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_recurring_tokens */
export type Payment_Recurring_Tokens_Pk_Columns_Input = {
  pmrecur_id: Scalars['uuid'];
};

/** select columns of table "payment_recurring_tokens" */
export enum Payment_Recurring_Tokens_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PmMethod = 'pm_method',
  /** column name */
  PmrecurId = 'pmrecur_id',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "payment_recurring_tokens" */
export type Payment_Recurring_Tokens_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  expiration_date?: InputMaybe<Scalars['timestamp']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  pm_method?: InputMaybe<Scalars['Int']>;
  pmrecur_id?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Payment_Recurring_Tokens_Stddev_Fields = {
  __typename?: 'payment_recurring_tokens_stddev_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payment_Recurring_Tokens_Stddev_Pop_Fields = {
  __typename?: 'payment_recurring_tokens_stddev_pop_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payment_Recurring_Tokens_Stddev_Samp_Fields = {
  __typename?: 'payment_recurring_tokens_stddev_samp_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "payment_recurring_tokens" */
export type Payment_Recurring_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Recurring_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Recurring_Tokens_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  expiration_date?: InputMaybe<Scalars['timestamp']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  pm_method?: InputMaybe<Scalars['Int']>;
  pmrecur_id?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Payment_Recurring_Tokens_Sum_Fields = {
  __typename?: 'payment_recurring_tokens_sum_fields';
  pm_method?: Maybe<Scalars['Int']>;
};

/** update columns of table "payment_recurring_tokens" */
export enum Payment_Recurring_Tokens_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PmMethod = 'pm_method',
  /** column name */
  PmrecurId = 'pmrecur_id',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Payment_Recurring_Tokens_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Recurring_Tokens_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Recurring_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Recurring_Tokens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Recurring_Tokens_Var_Pop_Fields = {
  __typename?: 'payment_recurring_tokens_var_pop_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payment_Recurring_Tokens_Var_Samp_Fields = {
  __typename?: 'payment_recurring_tokens_var_samp_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payment_Recurring_Tokens_Variance_Fields = {
  __typename?: 'payment_recurring_tokens_variance_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "payment_requisites" */
export type Payment_Requisites = {
  __typename?: 'payment_requisites';
  created_at?: Maybe<Scalars['timestamp']>;
  pm_method: Scalars['Int'];
  pmrq_id: Scalars['uuid'];
  requisite_mask: Scalars['String'];
};

/** aggregated selection of "payment_requisites" */
export type Payment_Requisites_Aggregate = {
  __typename?: 'payment_requisites_aggregate';
  aggregate?: Maybe<Payment_Requisites_Aggregate_Fields>;
  nodes: Array<Payment_Requisites>;
};

/** aggregate fields of "payment_requisites" */
export type Payment_Requisites_Aggregate_Fields = {
  __typename?: 'payment_requisites_aggregate_fields';
  avg?: Maybe<Payment_Requisites_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Requisites_Max_Fields>;
  min?: Maybe<Payment_Requisites_Min_Fields>;
  stddev?: Maybe<Payment_Requisites_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Requisites_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Requisites_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Requisites_Sum_Fields>;
  var_pop?: Maybe<Payment_Requisites_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Requisites_Var_Samp_Fields>;
  variance?: Maybe<Payment_Requisites_Variance_Fields>;
};


/** aggregate fields of "payment_requisites" */
export type Payment_Requisites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Requisites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Payment_Requisites_Avg_Fields = {
  __typename?: 'payment_requisites_avg_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payment_requisites". All fields are combined with a logical 'AND'. */
export type Payment_Requisites_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Requisites_Bool_Exp>>;
  _not?: InputMaybe<Payment_Requisites_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Requisites_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  pm_method?: InputMaybe<Int_Comparison_Exp>;
  pmrq_id?: InputMaybe<Uuid_Comparison_Exp>;
  requisite_mask?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_requisites" */
export enum Payment_Requisites_Constraint {
  /** unique or primary key constraint on columns "pmrq_id" */
  PaymentRequisitesPkey = 'payment_requisites_pkey'
}

/** input type for incrementing numeric columns in table "payment_requisites" */
export type Payment_Requisites_Inc_Input = {
  pm_method?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_requisites" */
export type Payment_Requisites_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  pm_method?: InputMaybe<Scalars['Int']>;
  pmrq_id?: InputMaybe<Scalars['uuid']>;
  requisite_mask?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_Requisites_Max_Fields = {
  __typename?: 'payment_requisites_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  pm_method?: Maybe<Scalars['Int']>;
  pmrq_id?: Maybe<Scalars['uuid']>;
  requisite_mask?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Requisites_Min_Fields = {
  __typename?: 'payment_requisites_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  pm_method?: Maybe<Scalars['Int']>;
  pmrq_id?: Maybe<Scalars['uuid']>;
  requisite_mask?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_requisites" */
export type Payment_Requisites_Mutation_Response = {
  __typename?: 'payment_requisites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Requisites>;
};

/** on_conflict condition type for table "payment_requisites" */
export type Payment_Requisites_On_Conflict = {
  constraint: Payment_Requisites_Constraint;
  update_columns?: Array<Payment_Requisites_Update_Column>;
  where?: InputMaybe<Payment_Requisites_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_requisites". */
export type Payment_Requisites_Order_By = {
  created_at?: InputMaybe<Order_By>;
  pm_method?: InputMaybe<Order_By>;
  pmrq_id?: InputMaybe<Order_By>;
  requisite_mask?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_requisites */
export type Payment_Requisites_Pk_Columns_Input = {
  pmrq_id: Scalars['uuid'];
};

/** select columns of table "payment_requisites" */
export enum Payment_Requisites_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PmMethod = 'pm_method',
  /** column name */
  PmrqId = 'pmrq_id',
  /** column name */
  RequisiteMask = 'requisite_mask'
}

/** input type for updating data in table "payment_requisites" */
export type Payment_Requisites_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  pm_method?: InputMaybe<Scalars['Int']>;
  pmrq_id?: InputMaybe<Scalars['uuid']>;
  requisite_mask?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Payment_Requisites_Stddev_Fields = {
  __typename?: 'payment_requisites_stddev_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payment_Requisites_Stddev_Pop_Fields = {
  __typename?: 'payment_requisites_stddev_pop_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payment_Requisites_Stddev_Samp_Fields = {
  __typename?: 'payment_requisites_stddev_samp_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "payment_requisites" */
export type Payment_Requisites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Requisites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Requisites_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  pm_method?: InputMaybe<Scalars['Int']>;
  pmrq_id?: InputMaybe<Scalars['uuid']>;
  requisite_mask?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Payment_Requisites_Sum_Fields = {
  __typename?: 'payment_requisites_sum_fields';
  pm_method?: Maybe<Scalars['Int']>;
};

/** update columns of table "payment_requisites" */
export enum Payment_Requisites_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PmMethod = 'pm_method',
  /** column name */
  PmrqId = 'pmrq_id',
  /** column name */
  RequisiteMask = 'requisite_mask'
}

export type Payment_Requisites_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Requisites_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Requisites_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Requisites_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Requisites_Var_Pop_Fields = {
  __typename?: 'payment_requisites_var_pop_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payment_Requisites_Var_Samp_Fields = {
  __typename?: 'payment_requisites_var_samp_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payment_Requisites_Variance_Fields = {
  __typename?: 'payment_requisites_variance_fields';
  pm_method?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "payment_system_1payment_transaction_requests" */
export type Payment_System_1payment_Transaction_Requests = {
  __typename?: 'payment_system_1payment_transaction_requests';
  amount: Scalars['Float'];
  /** An object relationship */
  callback?: Maybe<Payment_Systems_1payment_Callbacks>;
  datetime: Scalars['timestamp'];
  description?: Maybe<Scalars['String']>;
  failure_url?: Maybe<Scalars['String']>;
  partner_id: Scalars['Int'];
  payment_transaction_id: Scalars['uuid'];
  payment_type?: Maybe<Scalars['String']>;
  project_id: Scalars['Int'];
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  ps_id: Scalars['uuid'];
  salt: Scalars['String'];
  shop_url?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success_url?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_data?: Maybe<Scalars['String']>;
  user_id: Scalars['uuid'];
  user_ip?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "payment_system_1payment_transaction_requests" */
export type Payment_System_1payment_Transaction_Requests_Aggregate = {
  __typename?: 'payment_system_1payment_transaction_requests_aggregate';
  aggregate?: Maybe<Payment_System_1payment_Transaction_Requests_Aggregate_Fields>;
  nodes: Array<Payment_System_1payment_Transaction_Requests>;
};

/** aggregate fields of "payment_system_1payment_transaction_requests" */
export type Payment_System_1payment_Transaction_Requests_Aggregate_Fields = {
  __typename?: 'payment_system_1payment_transaction_requests_aggregate_fields';
  avg?: Maybe<Payment_System_1payment_Transaction_Requests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_System_1payment_Transaction_Requests_Max_Fields>;
  min?: Maybe<Payment_System_1payment_Transaction_Requests_Min_Fields>;
  stddev?: Maybe<Payment_System_1payment_Transaction_Requests_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_System_1payment_Transaction_Requests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_System_1payment_Transaction_Requests_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_System_1payment_Transaction_Requests_Sum_Fields>;
  var_pop?: Maybe<Payment_System_1payment_Transaction_Requests_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_System_1payment_Transaction_Requests_Var_Samp_Fields>;
  variance?: Maybe<Payment_System_1payment_Transaction_Requests_Variance_Fields>;
};


/** aggregate fields of "payment_system_1payment_transaction_requests" */
export type Payment_System_1payment_Transaction_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_System_1payment_Transaction_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Payment_System_1payment_Transaction_Requests_Avg_Fields = {
  __typename?: 'payment_system_1payment_transaction_requests_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payment_system_1payment_transaction_requests". All fields are combined with a logical 'AND'. */
export type Payment_System_1payment_Transaction_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_System_1payment_Transaction_Requests_Bool_Exp>>;
  _not?: InputMaybe<Payment_System_1payment_Transaction_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_System_1payment_Transaction_Requests_Bool_Exp>>;
  amount?: InputMaybe<Float_Comparison_Exp>;
  callback?: InputMaybe<Payment_Systems_1payment_Callbacks_Bool_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  failure_url?: InputMaybe<String_Comparison_Exp>;
  partner_id?: InputMaybe<Int_Comparison_Exp>;
  payment_transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  payment_type?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Int_Comparison_Exp>;
  promo_activation_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  ps_id?: InputMaybe<Uuid_Comparison_Exp>;
  salt?: InputMaybe<String_Comparison_Exp>;
  shop_url?: InputMaybe<String_Comparison_Exp>;
  sign?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  success_url?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  user_data?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_ip?: InputMaybe<String_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_system_1payment_transaction_requests" */
export enum Payment_System_1payment_Transaction_Requests_Constraint {
  /** unique or primary key constraint on columns "payment_transaction_id" */
  PaymentSystem_1paymentTransactionRequestsPkey = 'payment_system_1payment_transaction_requests_pkey'
}

/** input type for incrementing numeric columns in table "payment_system_1payment_transaction_requests" */
export type Payment_System_1payment_Transaction_Requests_Inc_Input = {
  amount?: InputMaybe<Scalars['Float']>;
  partner_id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_system_1payment_transaction_requests" */
export type Payment_System_1payment_Transaction_Requests_Insert_Input = {
  amount?: InputMaybe<Scalars['Float']>;
  callback?: InputMaybe<Payment_Systems_1payment_Callbacks_Obj_Rel_Insert_Input>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  failure_url?: InputMaybe<Scalars['String']>;
  partner_id?: InputMaybe<Scalars['Int']>;
  payment_transaction_id?: InputMaybe<Scalars['uuid']>;
  payment_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['Int']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  salt?: InputMaybe<Scalars['String']>;
  shop_url?: InputMaybe<Scalars['String']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user_data?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_ip?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_System_1payment_Transaction_Requests_Max_Fields = {
  __typename?: 'payment_system_1payment_transaction_requests_max_fields';
  amount?: Maybe<Scalars['Float']>;
  datetime?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  failure_url?: Maybe<Scalars['String']>;
  partner_id?: Maybe<Scalars['Int']>;
  payment_transaction_id?: Maybe<Scalars['uuid']>;
  payment_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['Int']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  ps_id?: Maybe<Scalars['uuid']>;
  salt?: Maybe<Scalars['String']>;
  shop_url?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success_url?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_data?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_ip?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_System_1payment_Transaction_Requests_Min_Fields = {
  __typename?: 'payment_system_1payment_transaction_requests_min_fields';
  amount?: Maybe<Scalars['Float']>;
  datetime?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  failure_url?: Maybe<Scalars['String']>;
  partner_id?: Maybe<Scalars['Int']>;
  payment_transaction_id?: Maybe<Scalars['uuid']>;
  payment_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['Int']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  ps_id?: Maybe<Scalars['uuid']>;
  salt?: Maybe<Scalars['String']>;
  shop_url?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success_url?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user_data?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_ip?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_system_1payment_transaction_requests" */
export type Payment_System_1payment_Transaction_Requests_Mutation_Response = {
  __typename?: 'payment_system_1payment_transaction_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_System_1payment_Transaction_Requests>;
};

/** on_conflict condition type for table "payment_system_1payment_transaction_requests" */
export type Payment_System_1payment_Transaction_Requests_On_Conflict = {
  constraint: Payment_System_1payment_Transaction_Requests_Constraint;
  update_columns?: Array<Payment_System_1payment_Transaction_Requests_Update_Column>;
  where?: InputMaybe<Payment_System_1payment_Transaction_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_system_1payment_transaction_requests". */
export type Payment_System_1payment_Transaction_Requests_Order_By = {
  amount?: InputMaybe<Order_By>;
  callback?: InputMaybe<Payment_Systems_1payment_Callbacks_Order_By>;
  datetime?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  failure_url?: InputMaybe<Order_By>;
  partner_id?: InputMaybe<Order_By>;
  payment_transaction_id?: InputMaybe<Order_By>;
  payment_type?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  promo_activation_uuid?: InputMaybe<Order_By>;
  ps_id?: InputMaybe<Order_By>;
  salt?: InputMaybe<Order_By>;
  shop_url?: InputMaybe<Order_By>;
  sign?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  success_url?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  user_data?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_ip?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_system_1payment_transaction_requests */
export type Payment_System_1payment_Transaction_Requests_Pk_Columns_Input = {
  payment_transaction_id: Scalars['uuid'];
};

/** select columns of table "payment_system_1payment_transaction_requests" */
export enum Payment_System_1payment_Transaction_Requests_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Description = 'description',
  /** column name */
  FailureUrl = 'failure_url',
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  PaymentTransactionId = 'payment_transaction_id',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  Salt = 'salt',
  /** column name */
  ShopUrl = 'shop_url',
  /** column name */
  Sign = 'sign',
  /** column name */
  Status = 'status',
  /** column name */
  SuccessUrl = 'success_url',
  /** column name */
  Token = 'token',
  /** column name */
  UserData = 'user_data',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIp = 'user_ip',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "payment_system_1payment_transaction_requests" */
export type Payment_System_1payment_Transaction_Requests_Set_Input = {
  amount?: InputMaybe<Scalars['Float']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  failure_url?: InputMaybe<Scalars['String']>;
  partner_id?: InputMaybe<Scalars['Int']>;
  payment_transaction_id?: InputMaybe<Scalars['uuid']>;
  payment_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['Int']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  salt?: InputMaybe<Scalars['String']>;
  shop_url?: InputMaybe<Scalars['String']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user_data?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_ip?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Payment_System_1payment_Transaction_Requests_Stddev_Fields = {
  __typename?: 'payment_system_1payment_transaction_requests_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payment_System_1payment_Transaction_Requests_Stddev_Pop_Fields = {
  __typename?: 'payment_system_1payment_transaction_requests_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payment_System_1payment_Transaction_Requests_Stddev_Samp_Fields = {
  __typename?: 'payment_system_1payment_transaction_requests_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "payment_system_1payment_transaction_requests" */
export type Payment_System_1payment_Transaction_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_System_1payment_Transaction_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_System_1payment_Transaction_Requests_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Float']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  failure_url?: InputMaybe<Scalars['String']>;
  partner_id?: InputMaybe<Scalars['Int']>;
  payment_transaction_id?: InputMaybe<Scalars['uuid']>;
  payment_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['Int']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  salt?: InputMaybe<Scalars['String']>;
  shop_url?: InputMaybe<Scalars['String']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user_data?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_ip?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Payment_System_1payment_Transaction_Requests_Sum_Fields = {
  __typename?: 'payment_system_1payment_transaction_requests_sum_fields';
  amount?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "payment_system_1payment_transaction_requests" */
export enum Payment_System_1payment_Transaction_Requests_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Description = 'description',
  /** column name */
  FailureUrl = 'failure_url',
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  PaymentTransactionId = 'payment_transaction_id',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  Salt = 'salt',
  /** column name */
  ShopUrl = 'shop_url',
  /** column name */
  Sign = 'sign',
  /** column name */
  Status = 'status',
  /** column name */
  SuccessUrl = 'success_url',
  /** column name */
  Token = 'token',
  /** column name */
  UserData = 'user_data',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIp = 'user_ip',
  /** column name */
  UserName = 'user_name'
}

export type Payment_System_1payment_Transaction_Requests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_System_1payment_Transaction_Requests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_System_1payment_Transaction_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_System_1payment_Transaction_Requests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_System_1payment_Transaction_Requests_Var_Pop_Fields = {
  __typename?: 'payment_system_1payment_transaction_requests_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payment_System_1payment_Transaction_Requests_Var_Samp_Fields = {
  __typename?: 'payment_system_1payment_transaction_requests_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payment_System_1payment_Transaction_Requests_Variance_Fields = {
  __typename?: 'payment_system_1payment_transaction_requests_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "payment_system_free_kassa_callbacks" */
export type Payment_System_Free_Kassa_Callbacks = {
  __typename?: 'payment_system_free_kassa_callbacks';
  AMOUNT: Scalars['String'];
  CUR_ID: Scalars['String'];
  MERCHANT_ID: Scalars['String'];
  MERCHANT_ORDER_ID: Scalars['uuid'];
  P_EMAIL?: Maybe<Scalars['String']>;
  P_PHONE?: Maybe<Scalars['String']>;
  SIGN: Scalars['String'];
  commission: Scalars['String'];
  datetime: Scalars['timestamp'];
  intid: Scalars['String'];
  payer_account?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  us_userid: Scalars['uuid'];
  us_username?: Maybe<Scalars['String']>;
};

/** aggregated selection of "payment_system_free_kassa_callbacks" */
export type Payment_System_Free_Kassa_Callbacks_Aggregate = {
  __typename?: 'payment_system_free_kassa_callbacks_aggregate';
  aggregate?: Maybe<Payment_System_Free_Kassa_Callbacks_Aggregate_Fields>;
  nodes: Array<Payment_System_Free_Kassa_Callbacks>;
};

/** aggregate fields of "payment_system_free_kassa_callbacks" */
export type Payment_System_Free_Kassa_Callbacks_Aggregate_Fields = {
  __typename?: 'payment_system_free_kassa_callbacks_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_System_Free_Kassa_Callbacks_Max_Fields>;
  min?: Maybe<Payment_System_Free_Kassa_Callbacks_Min_Fields>;
};


/** aggregate fields of "payment_system_free_kassa_callbacks" */
export type Payment_System_Free_Kassa_Callbacks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_System_Free_Kassa_Callbacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payment_system_free_kassa_callbacks". All fields are combined with a logical 'AND'. */
export type Payment_System_Free_Kassa_Callbacks_Bool_Exp = {
  AMOUNT?: InputMaybe<String_Comparison_Exp>;
  CUR_ID?: InputMaybe<String_Comparison_Exp>;
  MERCHANT_ID?: InputMaybe<String_Comparison_Exp>;
  MERCHANT_ORDER_ID?: InputMaybe<Uuid_Comparison_Exp>;
  P_EMAIL?: InputMaybe<String_Comparison_Exp>;
  P_PHONE?: InputMaybe<String_Comparison_Exp>;
  SIGN?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Payment_System_Free_Kassa_Callbacks_Bool_Exp>>;
  _not?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_System_Free_Kassa_Callbacks_Bool_Exp>>;
  commission?: InputMaybe<String_Comparison_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  intid?: InputMaybe<String_Comparison_Exp>;
  payer_account?: InputMaybe<String_Comparison_Exp>;
  promo_activation_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  us_userid?: InputMaybe<Uuid_Comparison_Exp>;
  us_username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_system_free_kassa_callbacks" */
export enum Payment_System_Free_Kassa_Callbacks_Constraint {
  /** unique or primary key constraint on columns "MERCHANT_ORDER_ID" */
  PaymentSystemFreeKassaCallbacksPkey = 'payment_system_free_kassa_callbacks_pkey'
}

/** input type for inserting data into table "payment_system_free_kassa_callbacks" */
export type Payment_System_Free_Kassa_Callbacks_Insert_Input = {
  AMOUNT?: InputMaybe<Scalars['String']>;
  CUR_ID?: InputMaybe<Scalars['String']>;
  MERCHANT_ID?: InputMaybe<Scalars['String']>;
  MERCHANT_ORDER_ID?: InputMaybe<Scalars['uuid']>;
  P_EMAIL?: InputMaybe<Scalars['String']>;
  P_PHONE?: InputMaybe<Scalars['String']>;
  SIGN?: InputMaybe<Scalars['String']>;
  commission?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  intid?: InputMaybe<Scalars['String']>;
  payer_account?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  us_userid?: InputMaybe<Scalars['uuid']>;
  us_username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_System_Free_Kassa_Callbacks_Max_Fields = {
  __typename?: 'payment_system_free_kassa_callbacks_max_fields';
  AMOUNT?: Maybe<Scalars['String']>;
  CUR_ID?: Maybe<Scalars['String']>;
  MERCHANT_ID?: Maybe<Scalars['String']>;
  MERCHANT_ORDER_ID?: Maybe<Scalars['uuid']>;
  P_EMAIL?: Maybe<Scalars['String']>;
  P_PHONE?: Maybe<Scalars['String']>;
  SIGN?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  intid?: Maybe<Scalars['String']>;
  payer_account?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  us_userid?: Maybe<Scalars['uuid']>;
  us_username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_System_Free_Kassa_Callbacks_Min_Fields = {
  __typename?: 'payment_system_free_kassa_callbacks_min_fields';
  AMOUNT?: Maybe<Scalars['String']>;
  CUR_ID?: Maybe<Scalars['String']>;
  MERCHANT_ID?: Maybe<Scalars['String']>;
  MERCHANT_ORDER_ID?: Maybe<Scalars['uuid']>;
  P_EMAIL?: Maybe<Scalars['String']>;
  P_PHONE?: Maybe<Scalars['String']>;
  SIGN?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  intid?: Maybe<Scalars['String']>;
  payer_account?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  us_userid?: Maybe<Scalars['uuid']>;
  us_username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_system_free_kassa_callbacks" */
export type Payment_System_Free_Kassa_Callbacks_Mutation_Response = {
  __typename?: 'payment_system_free_kassa_callbacks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_System_Free_Kassa_Callbacks>;
};

/** input type for inserting object relation for remote table "payment_system_free_kassa_callbacks" */
export type Payment_System_Free_Kassa_Callbacks_Obj_Rel_Insert_Input = {
  data: Payment_System_Free_Kassa_Callbacks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_System_Free_Kassa_Callbacks_On_Conflict>;
};

/** on_conflict condition type for table "payment_system_free_kassa_callbacks" */
export type Payment_System_Free_Kassa_Callbacks_On_Conflict = {
  constraint: Payment_System_Free_Kassa_Callbacks_Constraint;
  update_columns?: Array<Payment_System_Free_Kassa_Callbacks_Update_Column>;
  where?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_system_free_kassa_callbacks". */
export type Payment_System_Free_Kassa_Callbacks_Order_By = {
  AMOUNT?: InputMaybe<Order_By>;
  CUR_ID?: InputMaybe<Order_By>;
  MERCHANT_ID?: InputMaybe<Order_By>;
  MERCHANT_ORDER_ID?: InputMaybe<Order_By>;
  P_EMAIL?: InputMaybe<Order_By>;
  P_PHONE?: InputMaybe<Order_By>;
  SIGN?: InputMaybe<Order_By>;
  commission?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  intid?: InputMaybe<Order_By>;
  payer_account?: InputMaybe<Order_By>;
  promo_activation_uuid?: InputMaybe<Order_By>;
  us_userid?: InputMaybe<Order_By>;
  us_username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_system_free_kassa_callbacks */
export type Payment_System_Free_Kassa_Callbacks_Pk_Columns_Input = {
  MERCHANT_ORDER_ID: Scalars['uuid'];
};

/** select columns of table "payment_system_free_kassa_callbacks" */
export enum Payment_System_Free_Kassa_Callbacks_Select_Column {
  /** column name */
  Amount = 'AMOUNT',
  /** column name */
  CurId = 'CUR_ID',
  /** column name */
  MerchantId = 'MERCHANT_ID',
  /** column name */
  MerchantOrderId = 'MERCHANT_ORDER_ID',
  /** column name */
  PEmail = 'P_EMAIL',
  /** column name */
  PPhone = 'P_PHONE',
  /** column name */
  Sign = 'SIGN',
  /** column name */
  Commission = 'commission',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Intid = 'intid',
  /** column name */
  PayerAccount = 'payer_account',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  UsUserid = 'us_userid',
  /** column name */
  UsUsername = 'us_username'
}

/** input type for updating data in table "payment_system_free_kassa_callbacks" */
export type Payment_System_Free_Kassa_Callbacks_Set_Input = {
  AMOUNT?: InputMaybe<Scalars['String']>;
  CUR_ID?: InputMaybe<Scalars['String']>;
  MERCHANT_ID?: InputMaybe<Scalars['String']>;
  MERCHANT_ORDER_ID?: InputMaybe<Scalars['uuid']>;
  P_EMAIL?: InputMaybe<Scalars['String']>;
  P_PHONE?: InputMaybe<Scalars['String']>;
  SIGN?: InputMaybe<Scalars['String']>;
  commission?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  intid?: InputMaybe<Scalars['String']>;
  payer_account?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  us_userid?: InputMaybe<Scalars['uuid']>;
  us_username?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_system_free_kassa_callbacks" */
export type Payment_System_Free_Kassa_Callbacks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_System_Free_Kassa_Callbacks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_System_Free_Kassa_Callbacks_Stream_Cursor_Value_Input = {
  AMOUNT?: InputMaybe<Scalars['String']>;
  CUR_ID?: InputMaybe<Scalars['String']>;
  MERCHANT_ID?: InputMaybe<Scalars['String']>;
  MERCHANT_ORDER_ID?: InputMaybe<Scalars['uuid']>;
  P_EMAIL?: InputMaybe<Scalars['String']>;
  P_PHONE?: InputMaybe<Scalars['String']>;
  SIGN?: InputMaybe<Scalars['String']>;
  commission?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  intid?: InputMaybe<Scalars['String']>;
  payer_account?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  us_userid?: InputMaybe<Scalars['uuid']>;
  us_username?: InputMaybe<Scalars['String']>;
};

/** update columns of table "payment_system_free_kassa_callbacks" */
export enum Payment_System_Free_Kassa_Callbacks_Update_Column {
  /** column name */
  Amount = 'AMOUNT',
  /** column name */
  CurId = 'CUR_ID',
  /** column name */
  MerchantId = 'MERCHANT_ID',
  /** column name */
  MerchantOrderId = 'MERCHANT_ORDER_ID',
  /** column name */
  PEmail = 'P_EMAIL',
  /** column name */
  PPhone = 'P_PHONE',
  /** column name */
  Sign = 'SIGN',
  /** column name */
  Commission = 'commission',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Intid = 'intid',
  /** column name */
  PayerAccount = 'payer_account',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  UsUserid = 'us_userid',
  /** column name */
  UsUsername = 'us_username'
}

export type Payment_System_Free_Kassa_Callbacks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_System_Free_Kassa_Callbacks_Bool_Exp;
};

/** columns and relationships of "payment_system_free_kassa_transaction_requests" */
export type Payment_System_Free_Kassa_Transaction_Requests = {
  __typename?: 'payment_system_free_kassa_transaction_requests';
  /** An object relationship */
  callback?: Maybe<Payment_System_Free_Kassa_Callbacks>;
  currency: Scalars['String'];
  datetime: Scalars['timestamp'];
  em?: Maybe<Scalars['String']>;
  i?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  m: Scalars['String'];
  o: Scalars['uuid'];
  oa: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  ps_id?: Maybe<Scalars['uuid']>;
  ps_url?: Maybe<Scalars['String']>;
  s: Scalars['String'];
  salt: Scalars['String'];
  status: Scalars['String'];
  us_userid: Scalars['uuid'];
  us_username?: Maybe<Scalars['String']>;
  user_ip?: Maybe<Scalars['String']>;
};

/** aggregated selection of "payment_system_free_kassa_transaction_requests" */
export type Payment_System_Free_Kassa_Transaction_Requests_Aggregate = {
  __typename?: 'payment_system_free_kassa_transaction_requests_aggregate';
  aggregate?: Maybe<Payment_System_Free_Kassa_Transaction_Requests_Aggregate_Fields>;
  nodes: Array<Payment_System_Free_Kassa_Transaction_Requests>;
};

/** aggregate fields of "payment_system_free_kassa_transaction_requests" */
export type Payment_System_Free_Kassa_Transaction_Requests_Aggregate_Fields = {
  __typename?: 'payment_system_free_kassa_transaction_requests_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_System_Free_Kassa_Transaction_Requests_Max_Fields>;
  min?: Maybe<Payment_System_Free_Kassa_Transaction_Requests_Min_Fields>;
};


/** aggregate fields of "payment_system_free_kassa_transaction_requests" */
export type Payment_System_Free_Kassa_Transaction_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_System_Free_Kassa_Transaction_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payment_system_free_kassa_transaction_requests". All fields are combined with a logical 'AND'. */
export type Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp>>;
  _not?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp>>;
  callback?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Bool_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  em?: InputMaybe<String_Comparison_Exp>;
  i?: InputMaybe<String_Comparison_Exp>;
  lang?: InputMaybe<String_Comparison_Exp>;
  m?: InputMaybe<String_Comparison_Exp>;
  o?: InputMaybe<Uuid_Comparison_Exp>;
  oa?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  promo_activation_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  ps_id?: InputMaybe<Uuid_Comparison_Exp>;
  ps_url?: InputMaybe<String_Comparison_Exp>;
  s?: InputMaybe<String_Comparison_Exp>;
  salt?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  us_userid?: InputMaybe<Uuid_Comparison_Exp>;
  us_username?: InputMaybe<String_Comparison_Exp>;
  user_ip?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_system_free_kassa_transaction_requests" */
export enum Payment_System_Free_Kassa_Transaction_Requests_Constraint {
  /** unique or primary key constraint on columns "o" */
  PaymentSystemFreeKassaTransactionRequestsPkey = 'payment_system_free_kassa_transaction_requests_pkey'
}

/** input type for inserting data into table "payment_system_free_kassa_transaction_requests" */
export type Payment_System_Free_Kassa_Transaction_Requests_Insert_Input = {
  callback?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Obj_Rel_Insert_Input>;
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  em?: InputMaybe<Scalars['String']>;
  i?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  m?: InputMaybe<Scalars['String']>;
  o?: InputMaybe<Scalars['uuid']>;
  oa?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_url?: InputMaybe<Scalars['String']>;
  s?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  us_userid?: InputMaybe<Scalars['uuid']>;
  us_username?: InputMaybe<Scalars['String']>;
  user_ip?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_System_Free_Kassa_Transaction_Requests_Max_Fields = {
  __typename?: 'payment_system_free_kassa_transaction_requests_max_fields';
  currency?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  em?: Maybe<Scalars['String']>;
  i?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  m?: Maybe<Scalars['String']>;
  o?: Maybe<Scalars['uuid']>;
  oa?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  ps_id?: Maybe<Scalars['uuid']>;
  ps_url?: Maybe<Scalars['String']>;
  s?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  us_userid?: Maybe<Scalars['uuid']>;
  us_username?: Maybe<Scalars['String']>;
  user_ip?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_System_Free_Kassa_Transaction_Requests_Min_Fields = {
  __typename?: 'payment_system_free_kassa_transaction_requests_min_fields';
  currency?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  em?: Maybe<Scalars['String']>;
  i?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  m?: Maybe<Scalars['String']>;
  o?: Maybe<Scalars['uuid']>;
  oa?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  ps_id?: Maybe<Scalars['uuid']>;
  ps_url?: Maybe<Scalars['String']>;
  s?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  us_userid?: Maybe<Scalars['uuid']>;
  us_username?: Maybe<Scalars['String']>;
  user_ip?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_system_free_kassa_transaction_requests" */
export type Payment_System_Free_Kassa_Transaction_Requests_Mutation_Response = {
  __typename?: 'payment_system_free_kassa_transaction_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_System_Free_Kassa_Transaction_Requests>;
};

/** on_conflict condition type for table "payment_system_free_kassa_transaction_requests" */
export type Payment_System_Free_Kassa_Transaction_Requests_On_Conflict = {
  constraint: Payment_System_Free_Kassa_Transaction_Requests_Constraint;
  update_columns?: Array<Payment_System_Free_Kassa_Transaction_Requests_Update_Column>;
  where?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_system_free_kassa_transaction_requests". */
export type Payment_System_Free_Kassa_Transaction_Requests_Order_By = {
  callback?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Order_By>;
  currency?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  em?: InputMaybe<Order_By>;
  i?: InputMaybe<Order_By>;
  lang?: InputMaybe<Order_By>;
  m?: InputMaybe<Order_By>;
  o?: InputMaybe<Order_By>;
  oa?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  promo_activation_uuid?: InputMaybe<Order_By>;
  ps_id?: InputMaybe<Order_By>;
  ps_url?: InputMaybe<Order_By>;
  s?: InputMaybe<Order_By>;
  salt?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  us_userid?: InputMaybe<Order_By>;
  us_username?: InputMaybe<Order_By>;
  user_ip?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_system_free_kassa_transaction_requests */
export type Payment_System_Free_Kassa_Transaction_Requests_Pk_Columns_Input = {
  o: Scalars['uuid'];
};

/** select columns of table "payment_system_free_kassa_transaction_requests" */
export enum Payment_System_Free_Kassa_Transaction_Requests_Select_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Em = 'em',
  /** column name */
  I = 'i',
  /** column name */
  Lang = 'lang',
  /** column name */
  M = 'm',
  /** column name */
  O = 'o',
  /** column name */
  Oa = 'oa',
  /** column name */
  Phone = 'phone',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PsUrl = 'ps_url',
  /** column name */
  S = 's',
  /** column name */
  Salt = 'salt',
  /** column name */
  Status = 'status',
  /** column name */
  UsUserid = 'us_userid',
  /** column name */
  UsUsername = 'us_username',
  /** column name */
  UserIp = 'user_ip'
}

/** input type for updating data in table "payment_system_free_kassa_transaction_requests" */
export type Payment_System_Free_Kassa_Transaction_Requests_Set_Input = {
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  em?: InputMaybe<Scalars['String']>;
  i?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  m?: InputMaybe<Scalars['String']>;
  o?: InputMaybe<Scalars['uuid']>;
  oa?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_url?: InputMaybe<Scalars['String']>;
  s?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  us_userid?: InputMaybe<Scalars['uuid']>;
  us_username?: InputMaybe<Scalars['String']>;
  user_ip?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_system_free_kassa_transaction_requests" */
export type Payment_System_Free_Kassa_Transaction_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_System_Free_Kassa_Transaction_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_System_Free_Kassa_Transaction_Requests_Stream_Cursor_Value_Input = {
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  em?: InputMaybe<Scalars['String']>;
  i?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  m?: InputMaybe<Scalars['String']>;
  o?: InputMaybe<Scalars['uuid']>;
  oa?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_url?: InputMaybe<Scalars['String']>;
  s?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  us_userid?: InputMaybe<Scalars['uuid']>;
  us_username?: InputMaybe<Scalars['String']>;
  user_ip?: InputMaybe<Scalars['String']>;
};

/** update columns of table "payment_system_free_kassa_transaction_requests" */
export enum Payment_System_Free_Kassa_Transaction_Requests_Update_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Em = 'em',
  /** column name */
  I = 'i',
  /** column name */
  Lang = 'lang',
  /** column name */
  M = 'm',
  /** column name */
  O = 'o',
  /** column name */
  Oa = 'oa',
  /** column name */
  Phone = 'phone',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PsUrl = 'ps_url',
  /** column name */
  S = 's',
  /** column name */
  Salt = 'salt',
  /** column name */
  Status = 'status',
  /** column name */
  UsUserid = 'us_userid',
  /** column name */
  UsUsername = 'us_username',
  /** column name */
  UserIp = 'user_ip'
}

export type Payment_System_Free_Kassa_Transaction_Requests_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp;
};

/** columns and relationships of "payment_system_main_callbacks" */
export type Payment_System_Main_Callbacks = {
  __typename?: 'payment_system_main_callbacks';
  amount?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  datetime: Scalars['timestamp'];
  id?: Maybe<Scalars['String']>;
  orderAmount?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  our_status?: Maybe<Scalars['String']>;
  paidAmount?: Maybe<Scalars['String']>;
  paymentSystem?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  sign?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transaction_id: Scalars['uuid'];
  user_comment?: Maybe<Scalars['String']>;
};

/** aggregated selection of "payment_system_main_callbacks" */
export type Payment_System_Main_Callbacks_Aggregate = {
  __typename?: 'payment_system_main_callbacks_aggregate';
  aggregate?: Maybe<Payment_System_Main_Callbacks_Aggregate_Fields>;
  nodes: Array<Payment_System_Main_Callbacks>;
};

/** aggregate fields of "payment_system_main_callbacks" */
export type Payment_System_Main_Callbacks_Aggregate_Fields = {
  __typename?: 'payment_system_main_callbacks_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_System_Main_Callbacks_Max_Fields>;
  min?: Maybe<Payment_System_Main_Callbacks_Min_Fields>;
};


/** aggregate fields of "payment_system_main_callbacks" */
export type Payment_System_Main_Callbacks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_System_Main_Callbacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payment_system_main_callbacks". All fields are combined with a logical 'AND'. */
export type Payment_System_Main_Callbacks_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_System_Main_Callbacks_Bool_Exp>>;
  _not?: InputMaybe<Payment_System_Main_Callbacks_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_System_Main_Callbacks_Bool_Exp>>;
  amount?: InputMaybe<String_Comparison_Exp>;
  commission?: InputMaybe<String_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  orderAmount?: InputMaybe<String_Comparison_Exp>;
  orderId?: InputMaybe<String_Comparison_Exp>;
  our_status?: InputMaybe<String_Comparison_Exp>;
  paidAmount?: InputMaybe<String_Comparison_Exp>;
  paymentSystem?: InputMaybe<String_Comparison_Exp>;
  promo_activation_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  sign?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_comment?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_system_main_callbacks" */
export enum Payment_System_Main_Callbacks_Constraint {
  /** unique or primary key constraint on columns "transaction_id" */
  PaymentSystemMainCallbacksPkey = 'payment_system_main_callbacks_pkey'
}

/** input type for inserting data into table "payment_system_main_callbacks" */
export type Payment_System_Main_Callbacks_Insert_Input = {
  amount?: InputMaybe<Scalars['String']>;
  commission?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  orderAmount?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  our_status?: InputMaybe<Scalars['String']>;
  paidAmount?: InputMaybe<Scalars['String']>;
  paymentSystem?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_comment?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_System_Main_Callbacks_Max_Fields = {
  __typename?: 'payment_system_main_callbacks_max_fields';
  amount?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['String']>;
  orderAmount?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  our_status?: Maybe<Scalars['String']>;
  paidAmount?: Maybe<Scalars['String']>;
  paymentSystem?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  sign?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_comment?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_System_Main_Callbacks_Min_Fields = {
  __typename?: 'payment_system_main_callbacks_min_fields';
  amount?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['String']>;
  orderAmount?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  our_status?: Maybe<Scalars['String']>;
  paidAmount?: Maybe<Scalars['String']>;
  paymentSystem?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  sign?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_comment?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_system_main_callbacks" */
export type Payment_System_Main_Callbacks_Mutation_Response = {
  __typename?: 'payment_system_main_callbacks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_System_Main_Callbacks>;
};

/** input type for inserting object relation for remote table "payment_system_main_callbacks" */
export type Payment_System_Main_Callbacks_Obj_Rel_Insert_Input = {
  data: Payment_System_Main_Callbacks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_System_Main_Callbacks_On_Conflict>;
};

/** on_conflict condition type for table "payment_system_main_callbacks" */
export type Payment_System_Main_Callbacks_On_Conflict = {
  constraint: Payment_System_Main_Callbacks_Constraint;
  update_columns?: Array<Payment_System_Main_Callbacks_Update_Column>;
  where?: InputMaybe<Payment_System_Main_Callbacks_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_system_main_callbacks". */
export type Payment_System_Main_Callbacks_Order_By = {
  amount?: InputMaybe<Order_By>;
  commission?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderAmount?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  our_status?: InputMaybe<Order_By>;
  paidAmount?: InputMaybe<Order_By>;
  paymentSystem?: InputMaybe<Order_By>;
  promo_activation_uuid?: InputMaybe<Order_By>;
  sign?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_comment?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_system_main_callbacks */
export type Payment_System_Main_Callbacks_Pk_Columns_Input = {
  transaction_id: Scalars['uuid'];
};

/** select columns of table "payment_system_main_callbacks" */
export enum Payment_System_Main_Callbacks_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Commission = 'commission',
  /** column name */
  Currency = 'currency',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  OrderAmount = 'orderAmount',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  OurStatus = 'our_status',
  /** column name */
  PaidAmount = 'paidAmount',
  /** column name */
  PaymentSystem = 'paymentSystem',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  Sign = 'sign',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserComment = 'user_comment'
}

/** input type for updating data in table "payment_system_main_callbacks" */
export type Payment_System_Main_Callbacks_Set_Input = {
  amount?: InputMaybe<Scalars['String']>;
  commission?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  orderAmount?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  our_status?: InputMaybe<Scalars['String']>;
  paidAmount?: InputMaybe<Scalars['String']>;
  paymentSystem?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_comment?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_system_main_callbacks" */
export type Payment_System_Main_Callbacks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_System_Main_Callbacks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_System_Main_Callbacks_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['String']>;
  commission?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  orderAmount?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  our_status?: InputMaybe<Scalars['String']>;
  paidAmount?: InputMaybe<Scalars['String']>;
  paymentSystem?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_comment?: InputMaybe<Scalars['String']>;
};

/** update columns of table "payment_system_main_callbacks" */
export enum Payment_System_Main_Callbacks_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Commission = 'commission',
  /** column name */
  Currency = 'currency',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  OrderAmount = 'orderAmount',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  OurStatus = 'our_status',
  /** column name */
  PaidAmount = 'paidAmount',
  /** column name */
  PaymentSystem = 'paymentSystem',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  Sign = 'sign',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserComment = 'user_comment'
}

export type Payment_System_Main_Callbacks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_System_Main_Callbacks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_System_Main_Callbacks_Bool_Exp;
};

/** columns and relationships of "payment_system_main_transaction_requests" */
export type Payment_System_Main_Transaction_Requests = {
  __typename?: 'payment_system_main_transaction_requests';
  amount?: Maybe<Scalars['Int']>;
  callback_url?: Maybe<Scalars['String']>;
  datetime: Scalars['timestamp'];
  fail_url?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  ps_id: Scalars['uuid'];
  ps_transaction_id?: Maybe<Scalars['String']>;
  ps_url?: Maybe<Scalars['String']>;
  ps_value?: Maybe<Scalars['String']>;
  public_key?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success_url?: Maybe<Scalars['String']>;
  user_comment?: Maybe<Scalars['String']>;
  user_ip?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "payment_system_main_transaction_requests" */
export type Payment_System_Main_Transaction_Requests_Aggregate = {
  __typename?: 'payment_system_main_transaction_requests_aggregate';
  aggregate?: Maybe<Payment_System_Main_Transaction_Requests_Aggregate_Fields>;
  nodes: Array<Payment_System_Main_Transaction_Requests>;
};

/** aggregate fields of "payment_system_main_transaction_requests" */
export type Payment_System_Main_Transaction_Requests_Aggregate_Fields = {
  __typename?: 'payment_system_main_transaction_requests_aggregate_fields';
  avg?: Maybe<Payment_System_Main_Transaction_Requests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_System_Main_Transaction_Requests_Max_Fields>;
  min?: Maybe<Payment_System_Main_Transaction_Requests_Min_Fields>;
  stddev?: Maybe<Payment_System_Main_Transaction_Requests_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_System_Main_Transaction_Requests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_System_Main_Transaction_Requests_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_System_Main_Transaction_Requests_Sum_Fields>;
  var_pop?: Maybe<Payment_System_Main_Transaction_Requests_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_System_Main_Transaction_Requests_Var_Samp_Fields>;
  variance?: Maybe<Payment_System_Main_Transaction_Requests_Variance_Fields>;
};


/** aggregate fields of "payment_system_main_transaction_requests" */
export type Payment_System_Main_Transaction_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_System_Main_Transaction_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Payment_System_Main_Transaction_Requests_Avg_Fields = {
  __typename?: 'payment_system_main_transaction_requests_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payment_system_main_transaction_requests". All fields are combined with a logical 'AND'. */
export type Payment_System_Main_Transaction_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_System_Main_Transaction_Requests_Bool_Exp>>;
  _not?: InputMaybe<Payment_System_Main_Transaction_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_System_Main_Transaction_Requests_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  callback_url?: InputMaybe<String_Comparison_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  fail_url?: InputMaybe<String_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  orderId?: InputMaybe<String_Comparison_Exp>;
  promo_activation_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  ps_id?: InputMaybe<Uuid_Comparison_Exp>;
  ps_transaction_id?: InputMaybe<String_Comparison_Exp>;
  ps_url?: InputMaybe<String_Comparison_Exp>;
  ps_value?: InputMaybe<String_Comparison_Exp>;
  public_key?: InputMaybe<String_Comparison_Exp>;
  salt?: InputMaybe<String_Comparison_Exp>;
  sign?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  success_url?: InputMaybe<String_Comparison_Exp>;
  user_comment?: InputMaybe<String_Comparison_Exp>;
  user_ip?: InputMaybe<String_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_system_main_transaction_requests" */
export enum Payment_System_Main_Transaction_Requests_Constraint {
  /** unique or primary key constraint on columns "orderId" */
  PaumentSystemMainTransactionRequestsPkey = 'paument_system_main_transaction_requests_pkey'
}

/** input type for incrementing numeric columns in table "payment_system_main_transaction_requests" */
export type Payment_System_Main_Transaction_Requests_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_system_main_transaction_requests" */
export type Payment_System_Main_Transaction_Requests_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  callback_url?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  fail_url?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_transaction_id?: InputMaybe<Scalars['String']>;
  ps_url?: InputMaybe<Scalars['String']>;
  ps_value?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
  user_comment?: InputMaybe<Scalars['String']>;
  user_ip?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_System_Main_Transaction_Requests_Max_Fields = {
  __typename?: 'payment_system_main_transaction_requests_max_fields';
  amount?: Maybe<Scalars['Int']>;
  callback_url?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  fail_url?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  ps_id?: Maybe<Scalars['uuid']>;
  ps_transaction_id?: Maybe<Scalars['String']>;
  ps_url?: Maybe<Scalars['String']>;
  ps_value?: Maybe<Scalars['String']>;
  public_key?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success_url?: Maybe<Scalars['String']>;
  user_comment?: Maybe<Scalars['String']>;
  user_ip?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_System_Main_Transaction_Requests_Min_Fields = {
  __typename?: 'payment_system_main_transaction_requests_min_fields';
  amount?: Maybe<Scalars['Int']>;
  callback_url?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  fail_url?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  ps_id?: Maybe<Scalars['uuid']>;
  ps_transaction_id?: Maybe<Scalars['String']>;
  ps_url?: Maybe<Scalars['String']>;
  ps_value?: Maybe<Scalars['String']>;
  public_key?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success_url?: Maybe<Scalars['String']>;
  user_comment?: Maybe<Scalars['String']>;
  user_ip?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_system_main_transaction_requests" */
export type Payment_System_Main_Transaction_Requests_Mutation_Response = {
  __typename?: 'payment_system_main_transaction_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_System_Main_Transaction_Requests>;
};

/** on_conflict condition type for table "payment_system_main_transaction_requests" */
export type Payment_System_Main_Transaction_Requests_On_Conflict = {
  constraint: Payment_System_Main_Transaction_Requests_Constraint;
  update_columns?: Array<Payment_System_Main_Transaction_Requests_Update_Column>;
  where?: InputMaybe<Payment_System_Main_Transaction_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_system_main_transaction_requests". */
export type Payment_System_Main_Transaction_Requests_Order_By = {
  amount?: InputMaybe<Order_By>;
  callback_url?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  fail_url?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  promo_activation_uuid?: InputMaybe<Order_By>;
  ps_id?: InputMaybe<Order_By>;
  ps_transaction_id?: InputMaybe<Order_By>;
  ps_url?: InputMaybe<Order_By>;
  ps_value?: InputMaybe<Order_By>;
  public_key?: InputMaybe<Order_By>;
  salt?: InputMaybe<Order_By>;
  sign?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  success_url?: InputMaybe<Order_By>;
  user_comment?: InputMaybe<Order_By>;
  user_ip?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_system_main_transaction_requests */
export type Payment_System_Main_Transaction_Requests_Pk_Columns_Input = {
  orderId: Scalars['String'];
};

/** select columns of table "payment_system_main_transaction_requests" */
export enum Payment_System_Main_Transaction_Requests_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CallbackUrl = 'callback_url',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FailUrl = 'fail_url',
  /** column name */
  Key = 'key',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PsTransactionId = 'ps_transaction_id',
  /** column name */
  PsUrl = 'ps_url',
  /** column name */
  PsValue = 'ps_value',
  /** column name */
  PublicKey = 'public_key',
  /** column name */
  Salt = 'salt',
  /** column name */
  Sign = 'sign',
  /** column name */
  Status = 'status',
  /** column name */
  SuccessUrl = 'success_url',
  /** column name */
  UserComment = 'user_comment',
  /** column name */
  UserIp = 'user_ip',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "payment_system_main_transaction_requests" */
export type Payment_System_Main_Transaction_Requests_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  callback_url?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  fail_url?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_transaction_id?: InputMaybe<Scalars['String']>;
  ps_url?: InputMaybe<Scalars['String']>;
  ps_value?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
  user_comment?: InputMaybe<Scalars['String']>;
  user_ip?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Payment_System_Main_Transaction_Requests_Stddev_Fields = {
  __typename?: 'payment_system_main_transaction_requests_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payment_System_Main_Transaction_Requests_Stddev_Pop_Fields = {
  __typename?: 'payment_system_main_transaction_requests_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payment_System_Main_Transaction_Requests_Stddev_Samp_Fields = {
  __typename?: 'payment_system_main_transaction_requests_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "payment_system_main_transaction_requests" */
export type Payment_System_Main_Transaction_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_System_Main_Transaction_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_System_Main_Transaction_Requests_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  callback_url?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  fail_url?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_transaction_id?: InputMaybe<Scalars['String']>;
  ps_url?: InputMaybe<Scalars['String']>;
  ps_value?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
  user_comment?: InputMaybe<Scalars['String']>;
  user_ip?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Payment_System_Main_Transaction_Requests_Sum_Fields = {
  __typename?: 'payment_system_main_transaction_requests_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** update columns of table "payment_system_main_transaction_requests" */
export enum Payment_System_Main_Transaction_Requests_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CallbackUrl = 'callback_url',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FailUrl = 'fail_url',
  /** column name */
  Key = 'key',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PsTransactionId = 'ps_transaction_id',
  /** column name */
  PsUrl = 'ps_url',
  /** column name */
  PsValue = 'ps_value',
  /** column name */
  PublicKey = 'public_key',
  /** column name */
  Salt = 'salt',
  /** column name */
  Sign = 'sign',
  /** column name */
  Status = 'status',
  /** column name */
  SuccessUrl = 'success_url',
  /** column name */
  UserComment = 'user_comment',
  /** column name */
  UserIp = 'user_ip',
  /** column name */
  UserName = 'user_name'
}

export type Payment_System_Main_Transaction_Requests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_System_Main_Transaction_Requests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_System_Main_Transaction_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_System_Main_Transaction_Requests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_System_Main_Transaction_Requests_Var_Pop_Fields = {
  __typename?: 'payment_system_main_transaction_requests_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payment_System_Main_Transaction_Requests_Var_Samp_Fields = {
  __typename?: 'payment_system_main_transaction_requests_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payment_System_Main_Transaction_Requests_Variance_Fields = {
  __typename?: 'payment_system_main_transaction_requests_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "payment_systems_1payment_callbacks" */
export type Payment_Systems_1payment_Callbacks = {
  __typename?: 'payment_systems_1payment_callbacks';
  account?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  datetime: Scalars['timestamp'];
  init_time?: Maybe<Scalars['String']>;
  merchant_price: Scalars['String'];
  order_id: Scalars['String'];
  pan?: Maybe<Scalars['String']>;
  payment_type: Scalars['String'];
  project_id: Scalars['String'];
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  sign: Scalars['String'];
  status: Scalars['String'];
  status_code?: Maybe<Scalars['String']>;
  status_description: Scalars['String'];
  status_time?: Maybe<Scalars['String']>;
  test?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  transaction_id: Scalars['uuid'];
  user_data: Scalars['String'];
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  user_price: Scalars['String'];
};

/** aggregated selection of "payment_systems_1payment_callbacks" */
export type Payment_Systems_1payment_Callbacks_Aggregate = {
  __typename?: 'payment_systems_1payment_callbacks_aggregate';
  aggregate?: Maybe<Payment_Systems_1payment_Callbacks_Aggregate_Fields>;
  nodes: Array<Payment_Systems_1payment_Callbacks>;
};

/** aggregate fields of "payment_systems_1payment_callbacks" */
export type Payment_Systems_1payment_Callbacks_Aggregate_Fields = {
  __typename?: 'payment_systems_1payment_callbacks_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_Systems_1payment_Callbacks_Max_Fields>;
  min?: Maybe<Payment_Systems_1payment_Callbacks_Min_Fields>;
};


/** aggregate fields of "payment_systems_1payment_callbacks" */
export type Payment_Systems_1payment_Callbacks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Systems_1payment_Callbacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payment_systems_1payment_callbacks". All fields are combined with a logical 'AND'. */
export type Payment_Systems_1payment_Callbacks_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Systems_1payment_Callbacks_Bool_Exp>>;
  _not?: InputMaybe<Payment_Systems_1payment_Callbacks_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Systems_1payment_Callbacks_Bool_Exp>>;
  account?: InputMaybe<String_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  init_time?: InputMaybe<String_Comparison_Exp>;
  merchant_price?: InputMaybe<String_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  pan?: InputMaybe<String_Comparison_Exp>;
  payment_type?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<String_Comparison_Exp>;
  promo_activation_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  sign?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  status_code?: InputMaybe<String_Comparison_Exp>;
  status_description?: InputMaybe<String_Comparison_Exp>;
  status_time?: InputMaybe<String_Comparison_Exp>;
  test?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_data?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
  user_price?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_systems_1payment_callbacks" */
export enum Payment_Systems_1payment_Callbacks_Constraint {
  /** unique or primary key constraint on columns "transaction_id" */
  PaymentSystems_1paymentCallbacksPkey = 'payment_systems_1payment_callbacks_pkey'
}

/** input type for inserting data into table "payment_systems_1payment_callbacks" */
export type Payment_Systems_1payment_Callbacks_Insert_Input = {
  account?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  init_time?: InputMaybe<Scalars['String']>;
  merchant_price?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  pan?: InputMaybe<Scalars['String']>;
  payment_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_code?: InputMaybe<Scalars['String']>;
  status_description?: InputMaybe<Scalars['String']>;
  status_time?: InputMaybe<Scalars['String']>;
  test?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_data?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
  user_price?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_Systems_1payment_Callbacks_Max_Fields = {
  __typename?: 'payment_systems_1payment_callbacks_max_fields';
  account?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  init_time?: Maybe<Scalars['String']>;
  merchant_price?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['String']>;
  pan?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  sign?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['String']>;
  status_description?: Maybe<Scalars['String']>;
  status_time?: Maybe<Scalars['String']>;
  test?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_data?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  user_price?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Systems_1payment_Callbacks_Min_Fields = {
  __typename?: 'payment_systems_1payment_callbacks_min_fields';
  account?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  init_time?: Maybe<Scalars['String']>;
  merchant_price?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['String']>;
  pan?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  promo_activation_uuid?: Maybe<Scalars['uuid']>;
  sign?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['String']>;
  status_description?: Maybe<Scalars['String']>;
  status_time?: Maybe<Scalars['String']>;
  test?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_data?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
  user_price?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_systems_1payment_callbacks" */
export type Payment_Systems_1payment_Callbacks_Mutation_Response = {
  __typename?: 'payment_systems_1payment_callbacks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Systems_1payment_Callbacks>;
};

/** input type for inserting object relation for remote table "payment_systems_1payment_callbacks" */
export type Payment_Systems_1payment_Callbacks_Obj_Rel_Insert_Input = {
  data: Payment_Systems_1payment_Callbacks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Systems_1payment_Callbacks_On_Conflict>;
};

/** on_conflict condition type for table "payment_systems_1payment_callbacks" */
export type Payment_Systems_1payment_Callbacks_On_Conflict = {
  constraint: Payment_Systems_1payment_Callbacks_Constraint;
  update_columns?: Array<Payment_Systems_1payment_Callbacks_Update_Column>;
  where?: InputMaybe<Payment_Systems_1payment_Callbacks_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_systems_1payment_callbacks". */
export type Payment_Systems_1payment_Callbacks_Order_By = {
  account?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  init_time?: InputMaybe<Order_By>;
  merchant_price?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  pan?: InputMaybe<Order_By>;
  payment_type?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  promo_activation_uuid?: InputMaybe<Order_By>;
  sign?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_code?: InputMaybe<Order_By>;
  status_description?: InputMaybe<Order_By>;
  status_time?: InputMaybe<Order_By>;
  test?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_data?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
  user_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_systems_1payment_callbacks */
export type Payment_Systems_1payment_Callbacks_Pk_Columns_Input = {
  transaction_id: Scalars['uuid'];
};

/** select columns of table "payment_systems_1payment_callbacks" */
export enum Payment_Systems_1payment_Callbacks_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Currency = 'currency',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  InitTime = 'init_time',
  /** column name */
  MerchantPrice = 'merchant_price',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Pan = 'pan',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  Sign = 'sign',
  /** column name */
  Status = 'status',
  /** column name */
  StatusCode = 'status_code',
  /** column name */
  StatusDescription = 'status_description',
  /** column name */
  StatusTime = 'status_time',
  /** column name */
  Test = 'test',
  /** column name */
  Token = 'token',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserData = 'user_data',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name',
  /** column name */
  UserPrice = 'user_price'
}

/** input type for updating data in table "payment_systems_1payment_callbacks" */
export type Payment_Systems_1payment_Callbacks_Set_Input = {
  account?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  init_time?: InputMaybe<Scalars['String']>;
  merchant_price?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  pan?: InputMaybe<Scalars['String']>;
  payment_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_code?: InputMaybe<Scalars['String']>;
  status_description?: InputMaybe<Scalars['String']>;
  status_time?: InputMaybe<Scalars['String']>;
  test?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_data?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
  user_price?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_systems_1payment_callbacks" */
export type Payment_Systems_1payment_Callbacks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Systems_1payment_Callbacks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Systems_1payment_Callbacks_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  init_time?: InputMaybe<Scalars['String']>;
  merchant_price?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  pan?: InputMaybe<Scalars['String']>;
  payment_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  promo_activation_uuid?: InputMaybe<Scalars['uuid']>;
  sign?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  status_code?: InputMaybe<Scalars['String']>;
  status_description?: InputMaybe<Scalars['String']>;
  status_time?: InputMaybe<Scalars['String']>;
  test?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_data?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
  user_price?: InputMaybe<Scalars['String']>;
};

/** update columns of table "payment_systems_1payment_callbacks" */
export enum Payment_Systems_1payment_Callbacks_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Currency = 'currency',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  InitTime = 'init_time',
  /** column name */
  MerchantPrice = 'merchant_price',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Pan = 'pan',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  PromoActivationUuid = 'promo_activation_uuid',
  /** column name */
  Sign = 'sign',
  /** column name */
  Status = 'status',
  /** column name */
  StatusCode = 'status_code',
  /** column name */
  StatusDescription = 'status_description',
  /** column name */
  StatusTime = 'status_time',
  /** column name */
  Test = 'test',
  /** column name */
  Token = 'token',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserData = 'user_data',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name',
  /** column name */
  UserPrice = 'user_price'
}

export type Payment_Systems_1payment_Callbacks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Systems_1payment_Callbacks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Systems_1payment_Callbacks_Bool_Exp;
};

/** columns and relationships of "payment_systems_callbacks" */
export type Payment_Systems_Callbacks = {
  __typename?: 'payment_systems_callbacks';
  amount?: Maybe<Scalars['Int']>;
  card?: Maybe<Scalars['String']>;
  datetime: Scalars['timestamp'];
  key?: Maybe<Scalars['String']>;
  our_status?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transaction_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Aggregate = {
  __typename?: 'payment_systems_callbacks_aggregate';
  aggregate?: Maybe<Payment_Systems_Callbacks_Aggregate_Fields>;
  nodes: Array<Payment_Systems_Callbacks>;
};

export type Payment_Systems_Callbacks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Payment_Systems_Callbacks_Aggregate_Bool_Exp_Count>;
};

export type Payment_Systems_Callbacks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Payment_Systems_Callbacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payment_Systems_Callbacks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Aggregate_Fields = {
  __typename?: 'payment_systems_callbacks_aggregate_fields';
  avg?: Maybe<Payment_Systems_Callbacks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Systems_Callbacks_Max_Fields>;
  min?: Maybe<Payment_Systems_Callbacks_Min_Fields>;
  stddev?: Maybe<Payment_Systems_Callbacks_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Systems_Callbacks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Systems_Callbacks_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Systems_Callbacks_Sum_Fields>;
  var_pop?: Maybe<Payment_Systems_Callbacks_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Systems_Callbacks_Var_Samp_Fields>;
  variance?: Maybe<Payment_Systems_Callbacks_Variance_Fields>;
};


/** aggregate fields of "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Systems_Callbacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Aggregate_Order_By = {
  avg?: InputMaybe<Payment_Systems_Callbacks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Payment_Systems_Callbacks_Max_Order_By>;
  min?: InputMaybe<Payment_Systems_Callbacks_Min_Order_By>;
  stddev?: InputMaybe<Payment_Systems_Callbacks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Payment_Systems_Callbacks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Payment_Systems_Callbacks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Payment_Systems_Callbacks_Sum_Order_By>;
  var_pop?: InputMaybe<Payment_Systems_Callbacks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Payment_Systems_Callbacks_Var_Samp_Order_By>;
  variance?: InputMaybe<Payment_Systems_Callbacks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Arr_Rel_Insert_Input = {
  data: Array<Payment_Systems_Callbacks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Systems_Callbacks_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Systems_Callbacks_Avg_Fields = {
  __typename?: 'payment_systems_callbacks_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment_systems_callbacks". All fields are combined with a logical 'AND'. */
export type Payment_Systems_Callbacks_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Systems_Callbacks_Bool_Exp>>;
  _not?: InputMaybe<Payment_Systems_Callbacks_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Systems_Callbacks_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  card?: InputMaybe<String_Comparison_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  our_status?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_systems_callbacks" */
export enum Payment_Systems_Callbacks_Constraint {
  /** unique or primary key constraint on columns "user_id", "transaction_id" */
  PaymentSystemsCallbacksPkey = 'payment_systems_callbacks_pkey'
}

/** input type for incrementing numeric columns in table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  card?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  key?: InputMaybe<Scalars['String']>;
  our_status?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Payment_Systems_Callbacks_Max_Fields = {
  __typename?: 'payment_systems_callbacks_max_fields';
  amount?: Maybe<Scalars['Int']>;
  card?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  key?: Maybe<Scalars['String']>;
  our_status?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  card?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  our_status?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Systems_Callbacks_Min_Fields = {
  __typename?: 'payment_systems_callbacks_min_fields';
  amount?: Maybe<Scalars['Int']>;
  card?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  key?: Maybe<Scalars['String']>;
  our_status?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  card?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  our_status?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Mutation_Response = {
  __typename?: 'payment_systems_callbacks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Systems_Callbacks>;
};

/** on_conflict condition type for table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_On_Conflict = {
  constraint: Payment_Systems_Callbacks_Constraint;
  update_columns?: Array<Payment_Systems_Callbacks_Update_Column>;
  where?: InputMaybe<Payment_Systems_Callbacks_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_systems_callbacks". */
export type Payment_Systems_Callbacks_Order_By = {
  amount?: InputMaybe<Order_By>;
  card?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  our_status?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_systems_callbacks */
export type Payment_Systems_Callbacks_Pk_Columns_Input = {
  transaction_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "payment_systems_callbacks" */
export enum Payment_Systems_Callbacks_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Card = 'card',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Key = 'key',
  /** column name */
  OurStatus = 'our_status',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  card?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  key?: InputMaybe<Scalars['String']>;
  our_status?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Payment_Systems_Callbacks_Stddev_Fields = {
  __typename?: 'payment_systems_callbacks_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Systems_Callbacks_Stddev_Pop_Fields = {
  __typename?: 'payment_systems_callbacks_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Systems_Callbacks_Stddev_Samp_Fields = {
  __typename?: 'payment_systems_callbacks_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Systems_Callbacks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Systems_Callbacks_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  card?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  key?: InputMaybe<Scalars['String']>;
  our_status?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Payment_Systems_Callbacks_Sum_Fields = {
  __typename?: 'payment_systems_callbacks_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "payment_systems_callbacks" */
export enum Payment_Systems_Callbacks_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Card = 'card',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Key = 'key',
  /** column name */
  OurStatus = 'our_status',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

export type Payment_Systems_Callbacks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Systems_Callbacks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Systems_Callbacks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Systems_Callbacks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Systems_Callbacks_Var_Pop_Fields = {
  __typename?: 'payment_systems_callbacks_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Systems_Callbacks_Var_Samp_Fields = {
  __typename?: 'payment_systems_callbacks_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Systems_Callbacks_Variance_Fields = {
  __typename?: 'payment_systems_callbacks_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment_systems_callbacks" */
export type Payment_Systems_Callbacks_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "payment_systems_data" */
export type Payment_Systems_Data = {
  __typename?: 'payment_systems_data';
  additional_key_1?: Maybe<Scalars['String']>;
  additional_key_2?: Maybe<Scalars['String']>;
  broker_id: Scalars['String'];
  callback_url?: Maybe<Scalars['String']>;
  fail_url: Scalars['String'];
  ps_id: Scalars['uuid'];
  ps_url: Scalars['String'];
  ps_value?: Maybe<Scalars['String']>;
  public_key?: Maybe<Scalars['String']>;
  salt: Scalars['String'];
  salt2?: Maybe<Scalars['String']>;
  success_url: Scalars['String'];
};

/** aggregated selection of "payment_systems_data" */
export type Payment_Systems_Data_Aggregate = {
  __typename?: 'payment_systems_data_aggregate';
  aggregate?: Maybe<Payment_Systems_Data_Aggregate_Fields>;
  nodes: Array<Payment_Systems_Data>;
};

/** aggregate fields of "payment_systems_data" */
export type Payment_Systems_Data_Aggregate_Fields = {
  __typename?: 'payment_systems_data_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_Systems_Data_Max_Fields>;
  min?: Maybe<Payment_Systems_Data_Min_Fields>;
};


/** aggregate fields of "payment_systems_data" */
export type Payment_Systems_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Systems_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payment_systems_data". All fields are combined with a logical 'AND'. */
export type Payment_Systems_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Systems_Data_Bool_Exp>>;
  _not?: InputMaybe<Payment_Systems_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Systems_Data_Bool_Exp>>;
  additional_key_1?: InputMaybe<String_Comparison_Exp>;
  additional_key_2?: InputMaybe<String_Comparison_Exp>;
  broker_id?: InputMaybe<String_Comparison_Exp>;
  callback_url?: InputMaybe<String_Comparison_Exp>;
  fail_url?: InputMaybe<String_Comparison_Exp>;
  ps_id?: InputMaybe<Uuid_Comparison_Exp>;
  ps_url?: InputMaybe<String_Comparison_Exp>;
  ps_value?: InputMaybe<String_Comparison_Exp>;
  public_key?: InputMaybe<String_Comparison_Exp>;
  salt?: InputMaybe<String_Comparison_Exp>;
  salt2?: InputMaybe<String_Comparison_Exp>;
  success_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_systems_data" */
export enum Payment_Systems_Data_Constraint {
  /** unique or primary key constraint on columns "ps_id" */
  PaymentSystemsDataPkey = 'payment_systems_data_pkey'
}

/** input type for inserting data into table "payment_systems_data" */
export type Payment_Systems_Data_Insert_Input = {
  additional_key_1?: InputMaybe<Scalars['String']>;
  additional_key_2?: InputMaybe<Scalars['String']>;
  broker_id?: InputMaybe<Scalars['String']>;
  callback_url?: InputMaybe<Scalars['String']>;
  fail_url?: InputMaybe<Scalars['String']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_url?: InputMaybe<Scalars['String']>;
  ps_value?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  salt2?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_Systems_Data_Max_Fields = {
  __typename?: 'payment_systems_data_max_fields';
  additional_key_1?: Maybe<Scalars['String']>;
  additional_key_2?: Maybe<Scalars['String']>;
  broker_id?: Maybe<Scalars['String']>;
  callback_url?: Maybe<Scalars['String']>;
  fail_url?: Maybe<Scalars['String']>;
  ps_id?: Maybe<Scalars['uuid']>;
  ps_url?: Maybe<Scalars['String']>;
  ps_value?: Maybe<Scalars['String']>;
  public_key?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  salt2?: Maybe<Scalars['String']>;
  success_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Systems_Data_Min_Fields = {
  __typename?: 'payment_systems_data_min_fields';
  additional_key_1?: Maybe<Scalars['String']>;
  additional_key_2?: Maybe<Scalars['String']>;
  broker_id?: Maybe<Scalars['String']>;
  callback_url?: Maybe<Scalars['String']>;
  fail_url?: Maybe<Scalars['String']>;
  ps_id?: Maybe<Scalars['uuid']>;
  ps_url?: Maybe<Scalars['String']>;
  ps_value?: Maybe<Scalars['String']>;
  public_key?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  salt2?: Maybe<Scalars['String']>;
  success_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_systems_data" */
export type Payment_Systems_Data_Mutation_Response = {
  __typename?: 'payment_systems_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Systems_Data>;
};

/** on_conflict condition type for table "payment_systems_data" */
export type Payment_Systems_Data_On_Conflict = {
  constraint: Payment_Systems_Data_Constraint;
  update_columns?: Array<Payment_Systems_Data_Update_Column>;
  where?: InputMaybe<Payment_Systems_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_systems_data". */
export type Payment_Systems_Data_Order_By = {
  additional_key_1?: InputMaybe<Order_By>;
  additional_key_2?: InputMaybe<Order_By>;
  broker_id?: InputMaybe<Order_By>;
  callback_url?: InputMaybe<Order_By>;
  fail_url?: InputMaybe<Order_By>;
  ps_id?: InputMaybe<Order_By>;
  ps_url?: InputMaybe<Order_By>;
  ps_value?: InputMaybe<Order_By>;
  public_key?: InputMaybe<Order_By>;
  salt?: InputMaybe<Order_By>;
  salt2?: InputMaybe<Order_By>;
  success_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_systems_data */
export type Payment_Systems_Data_Pk_Columns_Input = {
  ps_id: Scalars['uuid'];
};

/** select columns of table "payment_systems_data" */
export enum Payment_Systems_Data_Select_Column {
  /** column name */
  AdditionalKey_1 = 'additional_key_1',
  /** column name */
  AdditionalKey_2 = 'additional_key_2',
  /** column name */
  BrokerId = 'broker_id',
  /** column name */
  CallbackUrl = 'callback_url',
  /** column name */
  FailUrl = 'fail_url',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PsUrl = 'ps_url',
  /** column name */
  PsValue = 'ps_value',
  /** column name */
  PublicKey = 'public_key',
  /** column name */
  Salt = 'salt',
  /** column name */
  Salt2 = 'salt2',
  /** column name */
  SuccessUrl = 'success_url'
}

/** input type for updating data in table "payment_systems_data" */
export type Payment_Systems_Data_Set_Input = {
  additional_key_1?: InputMaybe<Scalars['String']>;
  additional_key_2?: InputMaybe<Scalars['String']>;
  broker_id?: InputMaybe<Scalars['String']>;
  callback_url?: InputMaybe<Scalars['String']>;
  fail_url?: InputMaybe<Scalars['String']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_url?: InputMaybe<Scalars['String']>;
  ps_value?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  salt2?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_systems_data" */
export type Payment_Systems_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Systems_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Systems_Data_Stream_Cursor_Value_Input = {
  additional_key_1?: InputMaybe<Scalars['String']>;
  additional_key_2?: InputMaybe<Scalars['String']>;
  broker_id?: InputMaybe<Scalars['String']>;
  callback_url?: InputMaybe<Scalars['String']>;
  fail_url?: InputMaybe<Scalars['String']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  ps_url?: InputMaybe<Scalars['String']>;
  ps_value?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  salt2?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "payment_systems_data" */
export enum Payment_Systems_Data_Update_Column {
  /** column name */
  AdditionalKey_1 = 'additional_key_1',
  /** column name */
  AdditionalKey_2 = 'additional_key_2',
  /** column name */
  BrokerId = 'broker_id',
  /** column name */
  CallbackUrl = 'callback_url',
  /** column name */
  FailUrl = 'fail_url',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PsUrl = 'ps_url',
  /** column name */
  PsValue = 'ps_value',
  /** column name */
  PublicKey = 'public_key',
  /** column name */
  Salt = 'salt',
  /** column name */
  Salt2 = 'salt2',
  /** column name */
  SuccessUrl = 'success_url'
}

export type Payment_Systems_Data_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Systems_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Systems_Data_Bool_Exp;
};

/** Таблица для настройки методов оплаты в платёжных системах. pm_ps_method - поле для хранения внутренних, заданных платёжными системами, идентификаторов методов платежей; */
export type Payment_Systems_Methods = {
  __typename?: 'payment_systems_methods';
  commision?: Maybe<Scalars['Float']>;
  /** An object relationship */
  payment_currency?: Maybe<Currencys>;
  /** An object relationship */
  payment_method_data?: Maybe<Payment_Methods>;
  pm_active: Scalars['Boolean'];
  pm_currency: Scalars['String'];
  pm_display_name: Scalars['String'];
  pm_id: Scalars['Int'];
  pm_max_value: Scalars['Int'];
  pm_method: Scalars['String'];
  pm_min_value: Scalars['Int'];
  pm_pic_link?: Maybe<Scalars['String']>;
  pm_ps_method: Scalars['String'];
  pm_ps_type: Scalars['Int'];
  pm_sort?: Maybe<Scalars['Int']>;
  pm_sub_method: Scalars['String'];
};

/** aggregated selection of "payment_systems_methods" */
export type Payment_Systems_Methods_Aggregate = {
  __typename?: 'payment_systems_methods_aggregate';
  aggregate?: Maybe<Payment_Systems_Methods_Aggregate_Fields>;
  nodes: Array<Payment_Systems_Methods>;
};

export type Payment_Systems_Methods_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Payment_Systems_Methods_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Payment_Systems_Methods_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Payment_Systems_Methods_Aggregate_Bool_Exp_Count>;
};

export type Payment_Systems_Methods_Aggregate_Bool_Exp_Bool_And = {
  arguments: Payment_Systems_Methods_Select_Column_Payment_Systems_Methods_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Payment_Systems_Methods_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Payment_Systems_Methods_Select_Column_Payment_Systems_Methods_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Payment_Systems_Methods_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Payment_Systems_Methods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "payment_systems_methods" */
export type Payment_Systems_Methods_Aggregate_Fields = {
  __typename?: 'payment_systems_methods_aggregate_fields';
  avg?: Maybe<Payment_Systems_Methods_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Systems_Methods_Max_Fields>;
  min?: Maybe<Payment_Systems_Methods_Min_Fields>;
  stddev?: Maybe<Payment_Systems_Methods_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Systems_Methods_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Systems_Methods_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Systems_Methods_Sum_Fields>;
  var_pop?: Maybe<Payment_Systems_Methods_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Systems_Methods_Var_Samp_Fields>;
  variance?: Maybe<Payment_Systems_Methods_Variance_Fields>;
};


/** aggregate fields of "payment_systems_methods" */
export type Payment_Systems_Methods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Systems_Methods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment_systems_methods" */
export type Payment_Systems_Methods_Aggregate_Order_By = {
  avg?: InputMaybe<Payment_Systems_Methods_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Payment_Systems_Methods_Max_Order_By>;
  min?: InputMaybe<Payment_Systems_Methods_Min_Order_By>;
  stddev?: InputMaybe<Payment_Systems_Methods_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Payment_Systems_Methods_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Payment_Systems_Methods_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Payment_Systems_Methods_Sum_Order_By>;
  var_pop?: InputMaybe<Payment_Systems_Methods_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Payment_Systems_Methods_Var_Samp_Order_By>;
  variance?: InputMaybe<Payment_Systems_Methods_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment_systems_methods" */
export type Payment_Systems_Methods_Arr_Rel_Insert_Input = {
  data: Array<Payment_Systems_Methods_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Systems_Methods_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Systems_Methods_Avg_Fields = {
  __typename?: 'payment_systems_methods_avg_fields';
  commision?: Maybe<Scalars['Float']>;
  pm_id?: Maybe<Scalars['Float']>;
  pm_max_value?: Maybe<Scalars['Float']>;
  pm_min_value?: Maybe<Scalars['Float']>;
  pm_ps_type?: Maybe<Scalars['Float']>;
  pm_sort?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment_systems_methods" */
export type Payment_Systems_Methods_Avg_Order_By = {
  commision?: InputMaybe<Order_By>;
  pm_id?: InputMaybe<Order_By>;
  pm_max_value?: InputMaybe<Order_By>;
  pm_min_value?: InputMaybe<Order_By>;
  pm_ps_type?: InputMaybe<Order_By>;
  pm_sort?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment_systems_methods". All fields are combined with a logical 'AND'. */
export type Payment_Systems_Methods_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Systems_Methods_Bool_Exp>>;
  _not?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Systems_Methods_Bool_Exp>>;
  commision?: InputMaybe<Float_Comparison_Exp>;
  payment_currency?: InputMaybe<Currencys_Bool_Exp>;
  payment_method_data?: InputMaybe<Payment_Methods_Bool_Exp>;
  pm_active?: InputMaybe<Boolean_Comparison_Exp>;
  pm_currency?: InputMaybe<String_Comparison_Exp>;
  pm_display_name?: InputMaybe<String_Comparison_Exp>;
  pm_id?: InputMaybe<Int_Comparison_Exp>;
  pm_max_value?: InputMaybe<Int_Comparison_Exp>;
  pm_method?: InputMaybe<String_Comparison_Exp>;
  pm_min_value?: InputMaybe<Int_Comparison_Exp>;
  pm_pic_link?: InputMaybe<String_Comparison_Exp>;
  pm_ps_method?: InputMaybe<String_Comparison_Exp>;
  pm_ps_type?: InputMaybe<Int_Comparison_Exp>;
  pm_sort?: InputMaybe<Int_Comparison_Exp>;
  pm_sub_method?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_systems_methods" */
export enum Payment_Systems_Methods_Constraint {
  /** unique or primary key constraint on columns "pm_ps_type", "pm_id" */
  PaymentSystemsMethodsPkey = 'payment_systems_methods_pkey'
}

/** input type for incrementing numeric columns in table "payment_systems_methods" */
export type Payment_Systems_Methods_Inc_Input = {
  commision?: InputMaybe<Scalars['Float']>;
  pm_id?: InputMaybe<Scalars['Int']>;
  pm_max_value?: InputMaybe<Scalars['Int']>;
  pm_min_value?: InputMaybe<Scalars['Int']>;
  pm_ps_type?: InputMaybe<Scalars['Int']>;
  pm_sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_systems_methods" */
export type Payment_Systems_Methods_Insert_Input = {
  commision?: InputMaybe<Scalars['Float']>;
  payment_currency?: InputMaybe<Currencys_Obj_Rel_Insert_Input>;
  payment_method_data?: InputMaybe<Payment_Methods_Obj_Rel_Insert_Input>;
  pm_active?: InputMaybe<Scalars['Boolean']>;
  pm_currency?: InputMaybe<Scalars['String']>;
  pm_display_name?: InputMaybe<Scalars['String']>;
  pm_id?: InputMaybe<Scalars['Int']>;
  pm_max_value?: InputMaybe<Scalars['Int']>;
  pm_method?: InputMaybe<Scalars['String']>;
  pm_min_value?: InputMaybe<Scalars['Int']>;
  pm_pic_link?: InputMaybe<Scalars['String']>;
  pm_ps_method?: InputMaybe<Scalars['String']>;
  pm_ps_type?: InputMaybe<Scalars['Int']>;
  pm_sort?: InputMaybe<Scalars['Int']>;
  pm_sub_method?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_Systems_Methods_Max_Fields = {
  __typename?: 'payment_systems_methods_max_fields';
  commision?: Maybe<Scalars['Float']>;
  pm_currency?: Maybe<Scalars['String']>;
  pm_display_name?: Maybe<Scalars['String']>;
  pm_id?: Maybe<Scalars['Int']>;
  pm_max_value?: Maybe<Scalars['Int']>;
  pm_method?: Maybe<Scalars['String']>;
  pm_min_value?: Maybe<Scalars['Int']>;
  pm_pic_link?: Maybe<Scalars['String']>;
  pm_ps_method?: Maybe<Scalars['String']>;
  pm_ps_type?: Maybe<Scalars['Int']>;
  pm_sort?: Maybe<Scalars['Int']>;
  pm_sub_method?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "payment_systems_methods" */
export type Payment_Systems_Methods_Max_Order_By = {
  commision?: InputMaybe<Order_By>;
  pm_currency?: InputMaybe<Order_By>;
  pm_display_name?: InputMaybe<Order_By>;
  pm_id?: InputMaybe<Order_By>;
  pm_max_value?: InputMaybe<Order_By>;
  pm_method?: InputMaybe<Order_By>;
  pm_min_value?: InputMaybe<Order_By>;
  pm_pic_link?: InputMaybe<Order_By>;
  pm_ps_method?: InputMaybe<Order_By>;
  pm_ps_type?: InputMaybe<Order_By>;
  pm_sort?: InputMaybe<Order_By>;
  pm_sub_method?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Systems_Methods_Min_Fields = {
  __typename?: 'payment_systems_methods_min_fields';
  commision?: Maybe<Scalars['Float']>;
  pm_currency?: Maybe<Scalars['String']>;
  pm_display_name?: Maybe<Scalars['String']>;
  pm_id?: Maybe<Scalars['Int']>;
  pm_max_value?: Maybe<Scalars['Int']>;
  pm_method?: Maybe<Scalars['String']>;
  pm_min_value?: Maybe<Scalars['Int']>;
  pm_pic_link?: Maybe<Scalars['String']>;
  pm_ps_method?: Maybe<Scalars['String']>;
  pm_ps_type?: Maybe<Scalars['Int']>;
  pm_sort?: Maybe<Scalars['Int']>;
  pm_sub_method?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "payment_systems_methods" */
export type Payment_Systems_Methods_Min_Order_By = {
  commision?: InputMaybe<Order_By>;
  pm_currency?: InputMaybe<Order_By>;
  pm_display_name?: InputMaybe<Order_By>;
  pm_id?: InputMaybe<Order_By>;
  pm_max_value?: InputMaybe<Order_By>;
  pm_method?: InputMaybe<Order_By>;
  pm_min_value?: InputMaybe<Order_By>;
  pm_pic_link?: InputMaybe<Order_By>;
  pm_ps_method?: InputMaybe<Order_By>;
  pm_ps_type?: InputMaybe<Order_By>;
  pm_sort?: InputMaybe<Order_By>;
  pm_sub_method?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "payment_systems_methods" */
export type Payment_Systems_Methods_Mutation_Response = {
  __typename?: 'payment_systems_methods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Systems_Methods>;
};

/** on_conflict condition type for table "payment_systems_methods" */
export type Payment_Systems_Methods_On_Conflict = {
  constraint: Payment_Systems_Methods_Constraint;
  update_columns?: Array<Payment_Systems_Methods_Update_Column>;
  where?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_systems_methods". */
export type Payment_Systems_Methods_Order_By = {
  commision?: InputMaybe<Order_By>;
  payment_currency?: InputMaybe<Currencys_Order_By>;
  payment_method_data?: InputMaybe<Payment_Methods_Order_By>;
  pm_active?: InputMaybe<Order_By>;
  pm_currency?: InputMaybe<Order_By>;
  pm_display_name?: InputMaybe<Order_By>;
  pm_id?: InputMaybe<Order_By>;
  pm_max_value?: InputMaybe<Order_By>;
  pm_method?: InputMaybe<Order_By>;
  pm_min_value?: InputMaybe<Order_By>;
  pm_pic_link?: InputMaybe<Order_By>;
  pm_ps_method?: InputMaybe<Order_By>;
  pm_ps_type?: InputMaybe<Order_By>;
  pm_sort?: InputMaybe<Order_By>;
  pm_sub_method?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_systems_methods */
export type Payment_Systems_Methods_Pk_Columns_Input = {
  pm_id: Scalars['Int'];
  pm_ps_type: Scalars['Int'];
};

/** select columns of table "payment_systems_methods" */
export enum Payment_Systems_Methods_Select_Column {
  /** column name */
  Commision = 'commision',
  /** column name */
  PmActive = 'pm_active',
  /** column name */
  PmCurrency = 'pm_currency',
  /** column name */
  PmDisplayName = 'pm_display_name',
  /** column name */
  PmId = 'pm_id',
  /** column name */
  PmMaxValue = 'pm_max_value',
  /** column name */
  PmMethod = 'pm_method',
  /** column name */
  PmMinValue = 'pm_min_value',
  /** column name */
  PmPicLink = 'pm_pic_link',
  /** column name */
  PmPsMethod = 'pm_ps_method',
  /** column name */
  PmPsType = 'pm_ps_type',
  /** column name */
  PmSort = 'pm_sort',
  /** column name */
  PmSubMethod = 'pm_sub_method'
}

/** select "payment_systems_methods_aggregate_bool_exp_bool_and_arguments_columns" columns of table "payment_systems_methods" */
export enum Payment_Systems_Methods_Select_Column_Payment_Systems_Methods_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PmActive = 'pm_active'
}

/** select "payment_systems_methods_aggregate_bool_exp_bool_or_arguments_columns" columns of table "payment_systems_methods" */
export enum Payment_Systems_Methods_Select_Column_Payment_Systems_Methods_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PmActive = 'pm_active'
}

/** input type for updating data in table "payment_systems_methods" */
export type Payment_Systems_Methods_Set_Input = {
  commision?: InputMaybe<Scalars['Float']>;
  pm_active?: InputMaybe<Scalars['Boolean']>;
  pm_currency?: InputMaybe<Scalars['String']>;
  pm_display_name?: InputMaybe<Scalars['String']>;
  pm_id?: InputMaybe<Scalars['Int']>;
  pm_max_value?: InputMaybe<Scalars['Int']>;
  pm_method?: InputMaybe<Scalars['String']>;
  pm_min_value?: InputMaybe<Scalars['Int']>;
  pm_pic_link?: InputMaybe<Scalars['String']>;
  pm_ps_method?: InputMaybe<Scalars['String']>;
  pm_ps_type?: InputMaybe<Scalars['Int']>;
  pm_sort?: InputMaybe<Scalars['Int']>;
  pm_sub_method?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Payment_Systems_Methods_Stddev_Fields = {
  __typename?: 'payment_systems_methods_stddev_fields';
  commision?: Maybe<Scalars['Float']>;
  pm_id?: Maybe<Scalars['Float']>;
  pm_max_value?: Maybe<Scalars['Float']>;
  pm_min_value?: Maybe<Scalars['Float']>;
  pm_ps_type?: Maybe<Scalars['Float']>;
  pm_sort?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment_systems_methods" */
export type Payment_Systems_Methods_Stddev_Order_By = {
  commision?: InputMaybe<Order_By>;
  pm_id?: InputMaybe<Order_By>;
  pm_max_value?: InputMaybe<Order_By>;
  pm_min_value?: InputMaybe<Order_By>;
  pm_ps_type?: InputMaybe<Order_By>;
  pm_sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Systems_Methods_Stddev_Pop_Fields = {
  __typename?: 'payment_systems_methods_stddev_pop_fields';
  commision?: Maybe<Scalars['Float']>;
  pm_id?: Maybe<Scalars['Float']>;
  pm_max_value?: Maybe<Scalars['Float']>;
  pm_min_value?: Maybe<Scalars['Float']>;
  pm_ps_type?: Maybe<Scalars['Float']>;
  pm_sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment_systems_methods" */
export type Payment_Systems_Methods_Stddev_Pop_Order_By = {
  commision?: InputMaybe<Order_By>;
  pm_id?: InputMaybe<Order_By>;
  pm_max_value?: InputMaybe<Order_By>;
  pm_min_value?: InputMaybe<Order_By>;
  pm_ps_type?: InputMaybe<Order_By>;
  pm_sort?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Systems_Methods_Stddev_Samp_Fields = {
  __typename?: 'payment_systems_methods_stddev_samp_fields';
  commision?: Maybe<Scalars['Float']>;
  pm_id?: Maybe<Scalars['Float']>;
  pm_max_value?: Maybe<Scalars['Float']>;
  pm_min_value?: Maybe<Scalars['Float']>;
  pm_ps_type?: Maybe<Scalars['Float']>;
  pm_sort?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment_systems_methods" */
export type Payment_Systems_Methods_Stddev_Samp_Order_By = {
  commision?: InputMaybe<Order_By>;
  pm_id?: InputMaybe<Order_By>;
  pm_max_value?: InputMaybe<Order_By>;
  pm_min_value?: InputMaybe<Order_By>;
  pm_ps_type?: InputMaybe<Order_By>;
  pm_sort?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "payment_systems_methods" */
export type Payment_Systems_Methods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Systems_Methods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Systems_Methods_Stream_Cursor_Value_Input = {
  commision?: InputMaybe<Scalars['Float']>;
  pm_active?: InputMaybe<Scalars['Boolean']>;
  pm_currency?: InputMaybe<Scalars['String']>;
  pm_display_name?: InputMaybe<Scalars['String']>;
  pm_id?: InputMaybe<Scalars['Int']>;
  pm_max_value?: InputMaybe<Scalars['Int']>;
  pm_method?: InputMaybe<Scalars['String']>;
  pm_min_value?: InputMaybe<Scalars['Int']>;
  pm_pic_link?: InputMaybe<Scalars['String']>;
  pm_ps_method?: InputMaybe<Scalars['String']>;
  pm_ps_type?: InputMaybe<Scalars['Int']>;
  pm_sort?: InputMaybe<Scalars['Int']>;
  pm_sub_method?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Payment_Systems_Methods_Sum_Fields = {
  __typename?: 'payment_systems_methods_sum_fields';
  commision?: Maybe<Scalars['Float']>;
  pm_id?: Maybe<Scalars['Int']>;
  pm_max_value?: Maybe<Scalars['Int']>;
  pm_min_value?: Maybe<Scalars['Int']>;
  pm_ps_type?: Maybe<Scalars['Int']>;
  pm_sort?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payment_systems_methods" */
export type Payment_Systems_Methods_Sum_Order_By = {
  commision?: InputMaybe<Order_By>;
  pm_id?: InputMaybe<Order_By>;
  pm_max_value?: InputMaybe<Order_By>;
  pm_min_value?: InputMaybe<Order_By>;
  pm_ps_type?: InputMaybe<Order_By>;
  pm_sort?: InputMaybe<Order_By>;
};

/** update columns of table "payment_systems_methods" */
export enum Payment_Systems_Methods_Update_Column {
  /** column name */
  Commision = 'commision',
  /** column name */
  PmActive = 'pm_active',
  /** column name */
  PmCurrency = 'pm_currency',
  /** column name */
  PmDisplayName = 'pm_display_name',
  /** column name */
  PmId = 'pm_id',
  /** column name */
  PmMaxValue = 'pm_max_value',
  /** column name */
  PmMethod = 'pm_method',
  /** column name */
  PmMinValue = 'pm_min_value',
  /** column name */
  PmPicLink = 'pm_pic_link',
  /** column name */
  PmPsMethod = 'pm_ps_method',
  /** column name */
  PmPsType = 'pm_ps_type',
  /** column name */
  PmSort = 'pm_sort',
  /** column name */
  PmSubMethod = 'pm_sub_method'
}

export type Payment_Systems_Methods_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Systems_Methods_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Systems_Methods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Systems_Methods_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Systems_Methods_Var_Pop_Fields = {
  __typename?: 'payment_systems_methods_var_pop_fields';
  commision?: Maybe<Scalars['Float']>;
  pm_id?: Maybe<Scalars['Float']>;
  pm_max_value?: Maybe<Scalars['Float']>;
  pm_min_value?: Maybe<Scalars['Float']>;
  pm_ps_type?: Maybe<Scalars['Float']>;
  pm_sort?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment_systems_methods" */
export type Payment_Systems_Methods_Var_Pop_Order_By = {
  commision?: InputMaybe<Order_By>;
  pm_id?: InputMaybe<Order_By>;
  pm_max_value?: InputMaybe<Order_By>;
  pm_min_value?: InputMaybe<Order_By>;
  pm_ps_type?: InputMaybe<Order_By>;
  pm_sort?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Systems_Methods_Var_Samp_Fields = {
  __typename?: 'payment_systems_methods_var_samp_fields';
  commision?: Maybe<Scalars['Float']>;
  pm_id?: Maybe<Scalars['Float']>;
  pm_max_value?: Maybe<Scalars['Float']>;
  pm_min_value?: Maybe<Scalars['Float']>;
  pm_ps_type?: Maybe<Scalars['Float']>;
  pm_sort?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment_systems_methods" */
export type Payment_Systems_Methods_Var_Samp_Order_By = {
  commision?: InputMaybe<Order_By>;
  pm_id?: InputMaybe<Order_By>;
  pm_max_value?: InputMaybe<Order_By>;
  pm_min_value?: InputMaybe<Order_By>;
  pm_ps_type?: InputMaybe<Order_By>;
  pm_sort?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Systems_Methods_Variance_Fields = {
  __typename?: 'payment_systems_methods_variance_fields';
  commision?: Maybe<Scalars['Float']>;
  pm_id?: Maybe<Scalars['Float']>;
  pm_max_value?: Maybe<Scalars['Float']>;
  pm_min_value?: Maybe<Scalars['Float']>;
  pm_ps_type?: Maybe<Scalars['Float']>;
  pm_sort?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment_systems_methods" */
export type Payment_Systems_Methods_Variance_Order_By = {
  commision?: InputMaybe<Order_By>;
  pm_id?: InputMaybe<Order_By>;
  pm_max_value?: InputMaybe<Order_By>;
  pm_min_value?: InputMaybe<Order_By>;
  pm_ps_type?: InputMaybe<Order_By>;
  pm_sort?: InputMaybe<Order_By>;
};

/** columns and relationships of "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_Requests = {
  __typename?: 'payment_systems_transaction_requests';
  amount: Scalars['Int'];
  broker_id: Scalars['String'];
  callback_url: Scalars['String'];
  datetime: Scalars['timestamp'];
  fail_url: Scalars['String'];
  key: Scalars['String'];
  ps_id: Scalars['uuid'];
  salt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success_url: Scalars['String'];
  /** An array relationship */
  transact_to_callbacks: Array<Payment_Systems_Callbacks>;
  /** An aggregate relationship */
  transact_to_callbacks_aggregate: Payment_Systems_Callbacks_Aggregate;
  transaction_id: Scalars['uuid'];
  user_id: Scalars['String'];
  user_ip?: Maybe<Scalars['String']>;
};


/** columns and relationships of "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_RequestsTransact_To_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_Systems_Callbacks_Bool_Exp>;
};


/** columns and relationships of "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_RequestsTransact_To_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_Systems_Callbacks_Bool_Exp>;
};

/** aggregated selection of "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_Requests_Aggregate = {
  __typename?: 'payment_systems_transaction_requests_aggregate';
  aggregate?: Maybe<Payment_Systems_Transaction_Requests_Aggregate_Fields>;
  nodes: Array<Payment_Systems_Transaction_Requests>;
};

/** aggregate fields of "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_Requests_Aggregate_Fields = {
  __typename?: 'payment_systems_transaction_requests_aggregate_fields';
  avg?: Maybe<Payment_Systems_Transaction_Requests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Systems_Transaction_Requests_Max_Fields>;
  min?: Maybe<Payment_Systems_Transaction_Requests_Min_Fields>;
  stddev?: Maybe<Payment_Systems_Transaction_Requests_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Systems_Transaction_Requests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Systems_Transaction_Requests_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Systems_Transaction_Requests_Sum_Fields>;
  var_pop?: Maybe<Payment_Systems_Transaction_Requests_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Systems_Transaction_Requests_Var_Samp_Fields>;
  variance?: Maybe<Payment_Systems_Transaction_Requests_Variance_Fields>;
};


/** aggregate fields of "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Systems_Transaction_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Payment_Systems_Transaction_Requests_Avg_Fields = {
  __typename?: 'payment_systems_transaction_requests_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payment_systems_transaction_requests". All fields are combined with a logical 'AND'. */
export type Payment_Systems_Transaction_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Systems_Transaction_Requests_Bool_Exp>>;
  _not?: InputMaybe<Payment_Systems_Transaction_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Systems_Transaction_Requests_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  broker_id?: InputMaybe<String_Comparison_Exp>;
  callback_url?: InputMaybe<String_Comparison_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  fail_url?: InputMaybe<String_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  ps_id?: InputMaybe<Uuid_Comparison_Exp>;
  salt?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  success_url?: InputMaybe<String_Comparison_Exp>;
  transact_to_callbacks?: InputMaybe<Payment_Systems_Callbacks_Bool_Exp>;
  transact_to_callbacks_aggregate?: InputMaybe<Payment_Systems_Callbacks_Aggregate_Bool_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_ip?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_systems_transaction_requests" */
export enum Payment_Systems_Transaction_Requests_Constraint {
  /** unique or primary key constraint on columns "transaction_id" */
  PaymentSystemsTransactionRequestsPkey = 'payment_systems_transaction_requests_pkey'
}

/** input type for incrementing numeric columns in table "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_Requests_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_Requests_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  broker_id?: InputMaybe<Scalars['String']>;
  callback_url?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  fail_url?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  salt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
  transact_to_callbacks?: InputMaybe<Payment_Systems_Callbacks_Arr_Rel_Insert_Input>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_ip?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_Systems_Transaction_Requests_Max_Fields = {
  __typename?: 'payment_systems_transaction_requests_max_fields';
  amount?: Maybe<Scalars['Int']>;
  broker_id?: Maybe<Scalars['String']>;
  callback_url?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  fail_url?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  ps_id?: Maybe<Scalars['uuid']>;
  salt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success_url?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
  user_ip?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Systems_Transaction_Requests_Min_Fields = {
  __typename?: 'payment_systems_transaction_requests_min_fields';
  amount?: Maybe<Scalars['Int']>;
  broker_id?: Maybe<Scalars['String']>;
  callback_url?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['timestamp']>;
  fail_url?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  ps_id?: Maybe<Scalars['uuid']>;
  salt?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success_url?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
  user_ip?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_Requests_Mutation_Response = {
  __typename?: 'payment_systems_transaction_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Systems_Transaction_Requests>;
};

/** on_conflict condition type for table "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_Requests_On_Conflict = {
  constraint: Payment_Systems_Transaction_Requests_Constraint;
  update_columns?: Array<Payment_Systems_Transaction_Requests_Update_Column>;
  where?: InputMaybe<Payment_Systems_Transaction_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_systems_transaction_requests". */
export type Payment_Systems_Transaction_Requests_Order_By = {
  amount?: InputMaybe<Order_By>;
  broker_id?: InputMaybe<Order_By>;
  callback_url?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  fail_url?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  ps_id?: InputMaybe<Order_By>;
  salt?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  success_url?: InputMaybe<Order_By>;
  transact_to_callbacks_aggregate?: InputMaybe<Payment_Systems_Callbacks_Aggregate_Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_ip?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_systems_transaction_requests */
export type Payment_Systems_Transaction_Requests_Pk_Columns_Input = {
  transaction_id: Scalars['uuid'];
};

/** select columns of table "payment_systems_transaction_requests" */
export enum Payment_Systems_Transaction_Requests_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BrokerId = 'broker_id',
  /** column name */
  CallbackUrl = 'callback_url',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FailUrl = 'fail_url',
  /** column name */
  Key = 'key',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  Salt = 'salt',
  /** column name */
  Status = 'status',
  /** column name */
  SuccessUrl = 'success_url',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIp = 'user_ip'
}

/** input type for updating data in table "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_Requests_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  broker_id?: InputMaybe<Scalars['String']>;
  callback_url?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  fail_url?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  salt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_ip?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Payment_Systems_Transaction_Requests_Stddev_Fields = {
  __typename?: 'payment_systems_transaction_requests_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payment_Systems_Transaction_Requests_Stddev_Pop_Fields = {
  __typename?: 'payment_systems_transaction_requests_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payment_Systems_Transaction_Requests_Stddev_Samp_Fields = {
  __typename?: 'payment_systems_transaction_requests_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "payment_systems_transaction_requests" */
export type Payment_Systems_Transaction_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Systems_Transaction_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Systems_Transaction_Requests_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  broker_id?: InputMaybe<Scalars['String']>;
  callback_url?: InputMaybe<Scalars['String']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  fail_url?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  salt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  success_url?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_ip?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Payment_Systems_Transaction_Requests_Sum_Fields = {
  __typename?: 'payment_systems_transaction_requests_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** update columns of table "payment_systems_transaction_requests" */
export enum Payment_Systems_Transaction_Requests_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BrokerId = 'broker_id',
  /** column name */
  CallbackUrl = 'callback_url',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  FailUrl = 'fail_url',
  /** column name */
  Key = 'key',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  Salt = 'salt',
  /** column name */
  Status = 'status',
  /** column name */
  SuccessUrl = 'success_url',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIp = 'user_ip'
}

export type Payment_Systems_Transaction_Requests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Systems_Transaction_Requests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Systems_Transaction_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Systems_Transaction_Requests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Systems_Transaction_Requests_Var_Pop_Fields = {
  __typename?: 'payment_systems_transaction_requests_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payment_Systems_Transaction_Requests_Var_Samp_Fields = {
  __typename?: 'payment_systems_transaction_requests_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payment_Systems_Transaction_Requests_Variance_Fields = {
  __typename?: 'payment_systems_transaction_requests_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** status: 1 - ожидает оплаты, 2 - успешно, 3 - ошибка */
export type Payment_Transactions = {
  __typename?: 'payment_transactions';
  amount: Scalars['Int'];
  callback_data?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamp'];
  currency: Scalars['Int'];
  extra_data?: Maybe<Scalars['jsonb']>;
  fee?: Maybe<Scalars['Int']>;
  fee_currency?: Maybe<Scalars['Int']>;
  pay_account?: Maybe<Scalars['String']>;
  /** An object relationship */
  payment_currency?: Maybe<Currencys>;
  /** An object relationship */
  payment_method?: Maybe<Payment_Methods>;
  /** An object relationship */
  payment_provider?: Maybe<Payment_Providers>;
  pm_comment?: Maybe<Scalars['String']>;
  pm_method: Scalars['Int'];
  pmrecur_id?: Maybe<Scalars['uuid']>;
  pmrq_id?: Maybe<Scalars['uuid']>;
  ps_id: Scalars['uuid'];
  pst_id?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  status_description: Scalars['String'];
  transaction_id: Scalars['uuid'];
  transaction_key?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamp'];
  user_contacts?: Maybe<Scalars['jsonb']>;
  user_id: Scalars['uuid'];
};


/** status: 1 - ожидает оплаты, 2 - успешно, 3 - ошибка */
export type Payment_TransactionsCallback_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** status: 1 - ожидает оплаты, 2 - успешно, 3 - ошибка */
export type Payment_TransactionsExtra_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** status: 1 - ожидает оплаты, 2 - успешно, 3 - ошибка */
export type Payment_TransactionsUser_ContactsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "payment_transactions" */
export type Payment_Transactions_Aggregate = {
  __typename?: 'payment_transactions_aggregate';
  aggregate?: Maybe<Payment_Transactions_Aggregate_Fields>;
  nodes: Array<Payment_Transactions>;
};

/** aggregate fields of "payment_transactions" */
export type Payment_Transactions_Aggregate_Fields = {
  __typename?: 'payment_transactions_aggregate_fields';
  avg?: Maybe<Payment_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Transactions_Max_Fields>;
  min?: Maybe<Payment_Transactions_Min_Fields>;
  stddev?: Maybe<Payment_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Transactions_Sum_Fields>;
  var_pop?: Maybe<Payment_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Payment_Transactions_Variance_Fields>;
};


/** aggregate fields of "payment_transactions" */
export type Payment_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Payment_Transactions_Append_Input = {
  callback_data?: InputMaybe<Scalars['jsonb']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  user_contacts?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Payment_Transactions_Avg_Fields = {
  __typename?: 'payment_transactions_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  fee_currency?: Maybe<Scalars['Float']>;
  pm_method?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payment_transactions". All fields are combined with a logical 'AND'. */
export type Payment_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Payment_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Transactions_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  callback_data?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  currency?: InputMaybe<Int_Comparison_Exp>;
  extra_data?: InputMaybe<Jsonb_Comparison_Exp>;
  fee?: InputMaybe<Int_Comparison_Exp>;
  fee_currency?: InputMaybe<Int_Comparison_Exp>;
  pay_account?: InputMaybe<String_Comparison_Exp>;
  payment_currency?: InputMaybe<Currencys_Bool_Exp>;
  payment_method?: InputMaybe<Payment_Methods_Bool_Exp>;
  payment_provider?: InputMaybe<Payment_Providers_Bool_Exp>;
  pm_comment?: InputMaybe<String_Comparison_Exp>;
  pm_method?: InputMaybe<Int_Comparison_Exp>;
  pmrecur_id?: InputMaybe<Uuid_Comparison_Exp>;
  pmrq_id?: InputMaybe<Uuid_Comparison_Exp>;
  ps_id?: InputMaybe<Uuid_Comparison_Exp>;
  pst_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  status_description?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  transaction_key?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_contacts?: InputMaybe<Jsonb_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_transactions" */
export enum Payment_Transactions_Constraint {
  /** unique or primary key constraint on columns "transaction_id" */
  PaymentTransactionsPkey = 'payment_transactions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Payment_Transactions_Delete_At_Path_Input = {
  callback_data?: InputMaybe<Array<Scalars['String']>>;
  extra_data?: InputMaybe<Array<Scalars['String']>>;
  user_contacts?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Payment_Transactions_Delete_Elem_Input = {
  callback_data?: InputMaybe<Scalars['Int']>;
  extra_data?: InputMaybe<Scalars['Int']>;
  user_contacts?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Payment_Transactions_Delete_Key_Input = {
  callback_data?: InputMaybe<Scalars['String']>;
  extra_data?: InputMaybe<Scalars['String']>;
  user_contacts?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "payment_transactions" */
export type Payment_Transactions_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['Int']>;
  fee?: InputMaybe<Scalars['Int']>;
  fee_currency?: InputMaybe<Scalars['Int']>;
  pm_method?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_transactions" */
export type Payment_Transactions_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  callback_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['Int']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  fee?: InputMaybe<Scalars['Int']>;
  fee_currency?: InputMaybe<Scalars['Int']>;
  pay_account?: InputMaybe<Scalars['String']>;
  payment_currency?: InputMaybe<Currencys_Obj_Rel_Insert_Input>;
  payment_method?: InputMaybe<Payment_Methods_Obj_Rel_Insert_Input>;
  payment_provider?: InputMaybe<Payment_Providers_Obj_Rel_Insert_Input>;
  pm_comment?: InputMaybe<Scalars['String']>;
  pm_method?: InputMaybe<Scalars['Int']>;
  pmrecur_id?: InputMaybe<Scalars['uuid']>;
  pmrq_id?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  pst_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  status_description?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  transaction_key?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_contacts?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Payment_Transactions_Max_Fields = {
  __typename?: 'payment_transactions_max_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  fee_currency?: Maybe<Scalars['Int']>;
  pay_account?: Maybe<Scalars['String']>;
  pm_comment?: Maybe<Scalars['String']>;
  pm_method?: Maybe<Scalars['Int']>;
  pmrecur_id?: Maybe<Scalars['uuid']>;
  pmrq_id?: Maybe<Scalars['uuid']>;
  ps_id?: Maybe<Scalars['uuid']>;
  pst_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  status_description?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  transaction_key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Payment_Transactions_Min_Fields = {
  __typename?: 'payment_transactions_min_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  fee_currency?: Maybe<Scalars['Int']>;
  pay_account?: Maybe<Scalars['String']>;
  pm_comment?: Maybe<Scalars['String']>;
  pm_method?: Maybe<Scalars['Int']>;
  pmrecur_id?: Maybe<Scalars['uuid']>;
  pmrq_id?: Maybe<Scalars['uuid']>;
  ps_id?: Maybe<Scalars['uuid']>;
  pst_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  status_description?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  transaction_key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "payment_transactions" */
export type Payment_Transactions_Mutation_Response = {
  __typename?: 'payment_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Transactions>;
};

/** on_conflict condition type for table "payment_transactions" */
export type Payment_Transactions_On_Conflict = {
  constraint: Payment_Transactions_Constraint;
  update_columns?: Array<Payment_Transactions_Update_Column>;
  where?: InputMaybe<Payment_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_transactions". */
export type Payment_Transactions_Order_By = {
  amount?: InputMaybe<Order_By>;
  callback_data?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  extra_data?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  fee_currency?: InputMaybe<Order_By>;
  pay_account?: InputMaybe<Order_By>;
  payment_currency?: InputMaybe<Currencys_Order_By>;
  payment_method?: InputMaybe<Payment_Methods_Order_By>;
  payment_provider?: InputMaybe<Payment_Providers_Order_By>;
  pm_comment?: InputMaybe<Order_By>;
  pm_method?: InputMaybe<Order_By>;
  pmrecur_id?: InputMaybe<Order_By>;
  pmrq_id?: InputMaybe<Order_By>;
  ps_id?: InputMaybe<Order_By>;
  pst_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_description?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_key?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_contacts?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_transactions */
export type Payment_Transactions_Pk_Columns_Input = {
  transaction_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Payment_Transactions_Prepend_Input = {
  callback_data?: InputMaybe<Scalars['jsonb']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  user_contacts?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "payment_transactions" */
export enum Payment_Transactions_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CallbackData = 'callback_data',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  ExtraData = 'extra_data',
  /** column name */
  Fee = 'fee',
  /** column name */
  FeeCurrency = 'fee_currency',
  /** column name */
  PayAccount = 'pay_account',
  /** column name */
  PmComment = 'pm_comment',
  /** column name */
  PmMethod = 'pm_method',
  /** column name */
  PmrecurId = 'pmrecur_id',
  /** column name */
  PmrqId = 'pmrq_id',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PstId = 'pst_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDescription = 'status_description',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionKey = 'transaction_key',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserContacts = 'user_contacts',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "payment_transactions" */
export type Payment_Transactions_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  callback_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['Int']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  fee?: InputMaybe<Scalars['Int']>;
  fee_currency?: InputMaybe<Scalars['Int']>;
  pay_account?: InputMaybe<Scalars['String']>;
  pm_comment?: InputMaybe<Scalars['String']>;
  pm_method?: InputMaybe<Scalars['Int']>;
  pmrecur_id?: InputMaybe<Scalars['uuid']>;
  pmrq_id?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  pst_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  status_description?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  transaction_key?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_contacts?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Payment_Transactions_Stddev_Fields = {
  __typename?: 'payment_transactions_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  fee_currency?: Maybe<Scalars['Float']>;
  pm_method?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payment_Transactions_Stddev_Pop_Fields = {
  __typename?: 'payment_transactions_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  fee_currency?: Maybe<Scalars['Float']>;
  pm_method?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payment_Transactions_Stddev_Samp_Fields = {
  __typename?: 'payment_transactions_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  fee_currency?: Maybe<Scalars['Float']>;
  pm_method?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "payment_transactions" */
export type Payment_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Transactions_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  callback_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  currency?: InputMaybe<Scalars['Int']>;
  extra_data?: InputMaybe<Scalars['jsonb']>;
  fee?: InputMaybe<Scalars['Int']>;
  fee_currency?: InputMaybe<Scalars['Int']>;
  pay_account?: InputMaybe<Scalars['String']>;
  pm_comment?: InputMaybe<Scalars['String']>;
  pm_method?: InputMaybe<Scalars['Int']>;
  pmrecur_id?: InputMaybe<Scalars['uuid']>;
  pmrq_id?: InputMaybe<Scalars['uuid']>;
  ps_id?: InputMaybe<Scalars['uuid']>;
  pst_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  status_description?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  transaction_key?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_contacts?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Payment_Transactions_Sum_Fields = {
  __typename?: 'payment_transactions_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  fee_currency?: Maybe<Scalars['Int']>;
  pm_method?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** update columns of table "payment_transactions" */
export enum Payment_Transactions_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CallbackData = 'callback_data',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  ExtraData = 'extra_data',
  /** column name */
  Fee = 'fee',
  /** column name */
  FeeCurrency = 'fee_currency',
  /** column name */
  PayAccount = 'pay_account',
  /** column name */
  PmComment = 'pm_comment',
  /** column name */
  PmMethod = 'pm_method',
  /** column name */
  PmrecurId = 'pmrecur_id',
  /** column name */
  PmrqId = 'pmrq_id',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PstId = 'pst_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDescription = 'status_description',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionKey = 'transaction_key',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserContacts = 'user_contacts',
  /** column name */
  UserId = 'user_id'
}

export type Payment_Transactions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Payment_Transactions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Payment_Transactions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Payment_Transactions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Payment_Transactions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Transactions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Payment_Transactions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Transactions_Var_Pop_Fields = {
  __typename?: 'payment_transactions_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  fee_currency?: Maybe<Scalars['Float']>;
  pm_method?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payment_Transactions_Var_Samp_Fields = {
  __typename?: 'payment_transactions_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  fee_currency?: Maybe<Scalars['Float']>;
  pm_method?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payment_Transactions_Variance_Fields = {
  __typename?: 'payment_transactions_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  fee_currency?: Maybe<Scalars['Float']>;
  pm_method?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "profile_items_names" */
export type Profile_Items_Names = {
  __typename?: 'profile_items_names';
  name: Scalars['String'];
  ru_name: Scalars['String'];
};

/** aggregated selection of "profile_items_names" */
export type Profile_Items_Names_Aggregate = {
  __typename?: 'profile_items_names_aggregate';
  aggregate?: Maybe<Profile_Items_Names_Aggregate_Fields>;
  nodes: Array<Profile_Items_Names>;
};

/** aggregate fields of "profile_items_names" */
export type Profile_Items_Names_Aggregate_Fields = {
  __typename?: 'profile_items_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Items_Names_Max_Fields>;
  min?: Maybe<Profile_Items_Names_Min_Fields>;
};


/** aggregate fields of "profile_items_names" */
export type Profile_Items_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profile_Items_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile_items_names" */
export type Profile_Items_Names_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Profile_Items_Names_Max_Order_By>;
  min?: InputMaybe<Profile_Items_Names_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "profile_items_names". All fields are combined with a logical 'AND'. */
export type Profile_Items_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Profile_Items_Names_Bool_Exp>>;
  _not?: InputMaybe<Profile_Items_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Profile_Items_Names_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_items_names" */
export enum Profile_Items_Names_Constraint {
  /** unique or primary key constraint on columns "name" */
  ItemsNamesPkey = 'items_names_pkey'
}

/** input type for inserting data into table "profile_items_names" */
export type Profile_Items_Names_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Profile_Items_Names_Max_Fields = {
  __typename?: 'profile_items_names_max_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "profile_items_names" */
export type Profile_Items_Names_Max_Order_By = {
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Items_Names_Min_Fields = {
  __typename?: 'profile_items_names_min_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "profile_items_names" */
export type Profile_Items_Names_Min_Order_By = {
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "profile_items_names" */
export type Profile_Items_Names_Mutation_Response = {
  __typename?: 'profile_items_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Items_Names>;
};

/** on_conflict condition type for table "profile_items_names" */
export type Profile_Items_Names_On_Conflict = {
  constraint: Profile_Items_Names_Constraint;
  update_columns?: Array<Profile_Items_Names_Update_Column>;
  where?: InputMaybe<Profile_Items_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_items_names". */
export type Profile_Items_Names_Order_By = {
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profile_items_names */
export type Profile_Items_Names_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "profile_items_names" */
export enum Profile_Items_Names_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

/** input type for updating data in table "profile_items_names" */
export type Profile_Items_Names_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "profile_items_names" */
export type Profile_Items_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Profile_Items_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Profile_Items_Names_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "profile_items_names" */
export enum Profile_Items_Names_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

export type Profile_Items_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Profile_Items_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Profile_Items_Names_Bool_Exp;
};

/** columns and relationships of "promo_lootboxes" */
export type Promo_Lootboxes = {
  __typename?: 'promo_lootboxes';
  active: Scalars['Boolean'];
  lootbox_id: Scalars['uuid'];
};

/** aggregated selection of "promo_lootboxes" */
export type Promo_Lootboxes_Aggregate = {
  __typename?: 'promo_lootboxes_aggregate';
  aggregate?: Maybe<Promo_Lootboxes_Aggregate_Fields>;
  nodes: Array<Promo_Lootboxes>;
};

/** aggregate fields of "promo_lootboxes" */
export type Promo_Lootboxes_Aggregate_Fields = {
  __typename?: 'promo_lootboxes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Promo_Lootboxes_Max_Fields>;
  min?: Maybe<Promo_Lootboxes_Min_Fields>;
};


/** aggregate fields of "promo_lootboxes" */
export type Promo_Lootboxes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Promo_Lootboxes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "promo_lootboxes". All fields are combined with a logical 'AND'. */
export type Promo_Lootboxes_Bool_Exp = {
  _and?: InputMaybe<Array<Promo_Lootboxes_Bool_Exp>>;
  _not?: InputMaybe<Promo_Lootboxes_Bool_Exp>;
  _or?: InputMaybe<Array<Promo_Lootboxes_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "promo_lootboxes" */
export enum Promo_Lootboxes_Constraint {
  /** unique or primary key constraint on columns "lootbox_id" */
  PromoLootboxesPkey = 'promo_lootboxes_pkey'
}

/** input type for inserting data into table "promo_lootboxes" */
export type Promo_Lootboxes_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Promo_Lootboxes_Max_Fields = {
  __typename?: 'promo_lootboxes_max_fields';
  lootbox_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Promo_Lootboxes_Min_Fields = {
  __typename?: 'promo_lootboxes_min_fields';
  lootbox_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "promo_lootboxes" */
export type Promo_Lootboxes_Mutation_Response = {
  __typename?: 'promo_lootboxes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Promo_Lootboxes>;
};

/** on_conflict condition type for table "promo_lootboxes" */
export type Promo_Lootboxes_On_Conflict = {
  constraint: Promo_Lootboxes_Constraint;
  update_columns?: Array<Promo_Lootboxes_Update_Column>;
  where?: InputMaybe<Promo_Lootboxes_Bool_Exp>;
};

/** Ordering options when selecting data from "promo_lootboxes". */
export type Promo_Lootboxes_Order_By = {
  active?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: promo_lootboxes */
export type Promo_Lootboxes_Pk_Columns_Input = {
  lootbox_id: Scalars['uuid'];
};

/** select columns of table "promo_lootboxes" */
export enum Promo_Lootboxes_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  LootboxId = 'lootbox_id'
}

/** input type for updating data in table "promo_lootboxes" */
export type Promo_Lootboxes_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "promo_lootboxes" */
export type Promo_Lootboxes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promo_Lootboxes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Promo_Lootboxes_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "promo_lootboxes" */
export enum Promo_Lootboxes_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  LootboxId = 'lootbox_id'
}

export type Promo_Lootboxes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Promo_Lootboxes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Promo_Lootboxes_Bool_Exp;
};

/** columns and relationships of "promo_users" */
export type Promo_Users = {
  __typename?: 'promo_users';
  active: Scalars['Boolean'];
  to_user_account?: Maybe<User_Account>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "promo_users" */
export type Promo_Users_Aggregate = {
  __typename?: 'promo_users_aggregate';
  aggregate?: Maybe<Promo_Users_Aggregate_Fields>;
  nodes: Array<Promo_Users>;
};

/** aggregate fields of "promo_users" */
export type Promo_Users_Aggregate_Fields = {
  __typename?: 'promo_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Promo_Users_Max_Fields>;
  min?: Maybe<Promo_Users_Min_Fields>;
};


/** aggregate fields of "promo_users" */
export type Promo_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Promo_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "promo_users". All fields are combined with a logical 'AND'. */
export type Promo_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Promo_Users_Bool_Exp>>;
  _not?: InputMaybe<Promo_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Promo_Users_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "promo_users" */
export enum Promo_Users_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  PromoUsersPkey = 'promo_users_pkey'
}

/** input type for inserting data into table "promo_users" */
export type Promo_Users_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Promo_Users_Max_Fields = {
  __typename?: 'promo_users_max_fields';
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Promo_Users_Min_Fields = {
  __typename?: 'promo_users_min_fields';
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "promo_users" */
export type Promo_Users_Mutation_Response = {
  __typename?: 'promo_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Promo_Users>;
};

/** on_conflict condition type for table "promo_users" */
export type Promo_Users_On_Conflict = {
  constraint: Promo_Users_Constraint;
  update_columns?: Array<Promo_Users_Update_Column>;
  where?: InputMaybe<Promo_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "promo_users". */
export type Promo_Users_Order_By = {
  active?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: promo_users */
export type Promo_Users_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "promo_users" */
export enum Promo_Users_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "promo_users" */
export type Promo_Users_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "promo_users" */
export type Promo_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promo_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Promo_Users_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "promo_users" */
export enum Promo_Users_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  UserId = 'user_id'
}

export type Promo_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Promo_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Promo_Users_Bool_Exp;
};

/** columns and relationships of "promo_values" */
export type Promo_Values = {
  __typename?: 'promo_values';
  active_users: Scalars['Int'];
  id: Scalars['uuid'];
  lootbox_opens: Scalars['Int'];
};

/** aggregated selection of "promo_values" */
export type Promo_Values_Aggregate = {
  __typename?: 'promo_values_aggregate';
  aggregate?: Maybe<Promo_Values_Aggregate_Fields>;
  nodes: Array<Promo_Values>;
};

/** aggregate fields of "promo_values" */
export type Promo_Values_Aggregate_Fields = {
  __typename?: 'promo_values_aggregate_fields';
  avg?: Maybe<Promo_Values_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Promo_Values_Max_Fields>;
  min?: Maybe<Promo_Values_Min_Fields>;
  stddev?: Maybe<Promo_Values_Stddev_Fields>;
  stddev_pop?: Maybe<Promo_Values_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Promo_Values_Stddev_Samp_Fields>;
  sum?: Maybe<Promo_Values_Sum_Fields>;
  var_pop?: Maybe<Promo_Values_Var_Pop_Fields>;
  var_samp?: Maybe<Promo_Values_Var_Samp_Fields>;
  variance?: Maybe<Promo_Values_Variance_Fields>;
};


/** aggregate fields of "promo_values" */
export type Promo_Values_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Promo_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Promo_Values_Avg_Fields = {
  __typename?: 'promo_values_avg_fields';
  active_users?: Maybe<Scalars['Float']>;
  lootbox_opens?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "promo_values". All fields are combined with a logical 'AND'. */
export type Promo_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Promo_Values_Bool_Exp>>;
  _not?: InputMaybe<Promo_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Promo_Values_Bool_Exp>>;
  active_users?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_opens?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "promo_values" */
export enum Promo_Values_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromoValuesPkey = 'promo_values_pkey'
}

/** input type for incrementing numeric columns in table "promo_values" */
export type Promo_Values_Inc_Input = {
  active_users?: InputMaybe<Scalars['Int']>;
  lootbox_opens?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "promo_values" */
export type Promo_Values_Insert_Input = {
  active_users?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  lootbox_opens?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Promo_Values_Max_Fields = {
  __typename?: 'promo_values_max_fields';
  active_users?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  lootbox_opens?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Promo_Values_Min_Fields = {
  __typename?: 'promo_values_min_fields';
  active_users?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  lootbox_opens?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "promo_values" */
export type Promo_Values_Mutation_Response = {
  __typename?: 'promo_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Promo_Values>;
};

/** on_conflict condition type for table "promo_values" */
export type Promo_Values_On_Conflict = {
  constraint: Promo_Values_Constraint;
  update_columns?: Array<Promo_Values_Update_Column>;
  where?: InputMaybe<Promo_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "promo_values". */
export type Promo_Values_Order_By = {
  active_users?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lootbox_opens?: InputMaybe<Order_By>;
};

/** primary key columns input for table: promo_values */
export type Promo_Values_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "promo_values" */
export enum Promo_Values_Select_Column {
  /** column name */
  ActiveUsers = 'active_users',
  /** column name */
  Id = 'id',
  /** column name */
  LootboxOpens = 'lootbox_opens'
}

/** input type for updating data in table "promo_values" */
export type Promo_Values_Set_Input = {
  active_users?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  lootbox_opens?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Promo_Values_Stddev_Fields = {
  __typename?: 'promo_values_stddev_fields';
  active_users?: Maybe<Scalars['Float']>;
  lootbox_opens?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Promo_Values_Stddev_Pop_Fields = {
  __typename?: 'promo_values_stddev_pop_fields';
  active_users?: Maybe<Scalars['Float']>;
  lootbox_opens?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Promo_Values_Stddev_Samp_Fields = {
  __typename?: 'promo_values_stddev_samp_fields';
  active_users?: Maybe<Scalars['Float']>;
  lootbox_opens?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "promo_values" */
export type Promo_Values_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promo_Values_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Promo_Values_Stream_Cursor_Value_Input = {
  active_users?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  lootbox_opens?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Promo_Values_Sum_Fields = {
  __typename?: 'promo_values_sum_fields';
  active_users?: Maybe<Scalars['Int']>;
  lootbox_opens?: Maybe<Scalars['Int']>;
};

/** update columns of table "promo_values" */
export enum Promo_Values_Update_Column {
  /** column name */
  ActiveUsers = 'active_users',
  /** column name */
  Id = 'id',
  /** column name */
  LootboxOpens = 'lootbox_opens'
}

export type Promo_Values_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Promo_Values_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Promo_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Promo_Values_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Promo_Values_Var_Pop_Fields = {
  __typename?: 'promo_values_var_pop_fields';
  active_users?: Maybe<Scalars['Float']>;
  lootbox_opens?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Promo_Values_Var_Samp_Fields = {
  __typename?: 'promo_values_var_samp_fields';
  active_users?: Maybe<Scalars['Float']>;
  lootbox_opens?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Promo_Values_Variance_Fields = {
  __typename?: 'promo_values_variance_fields';
  active_users?: Maybe<Scalars['Float']>;
  lootbox_opens?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "promocode_activations" */
export type Promocode_Activations = {
  __typename?: 'promocode_activations';
  activation_datetime: Scalars['timestamp'];
  activation_id: Scalars['uuid'];
  activation_used: Scalars['Boolean'];
  bonus_money_amount?: Maybe<Scalars['Int']>;
  deposit_amount: Scalars['Float'];
  payment_system: Scalars['Int'];
  /** An object relationship */
  promocode?: Maybe<Promocodes>;
  promocode_id: Scalars['uuid'];
  promocode_slug?: Maybe<Scalars['String']>;
  reward_type?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  user_id: Scalars['uuid'];
  user_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "promocode_activations" */
export type Promocode_Activations_Aggregate = {
  __typename?: 'promocode_activations_aggregate';
  aggregate?: Maybe<Promocode_Activations_Aggregate_Fields>;
  nodes: Array<Promocode_Activations>;
};

export type Promocode_Activations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Promocode_Activations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Promocode_Activations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Promocode_Activations_Aggregate_Bool_Exp_Count>;
};

export type Promocode_Activations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Promocode_Activations_Select_Column_Promocode_Activations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Promocode_Activations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Promocode_Activations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Promocode_Activations_Select_Column_Promocode_Activations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Promocode_Activations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Promocode_Activations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Promocode_Activations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Promocode_Activations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "promocode_activations" */
export type Promocode_Activations_Aggregate_Fields = {
  __typename?: 'promocode_activations_aggregate_fields';
  avg?: Maybe<Promocode_Activations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Promocode_Activations_Max_Fields>;
  min?: Maybe<Promocode_Activations_Min_Fields>;
  stddev?: Maybe<Promocode_Activations_Stddev_Fields>;
  stddev_pop?: Maybe<Promocode_Activations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Promocode_Activations_Stddev_Samp_Fields>;
  sum?: Maybe<Promocode_Activations_Sum_Fields>;
  var_pop?: Maybe<Promocode_Activations_Var_Pop_Fields>;
  var_samp?: Maybe<Promocode_Activations_Var_Samp_Fields>;
  variance?: Maybe<Promocode_Activations_Variance_Fields>;
};


/** aggregate fields of "promocode_activations" */
export type Promocode_Activations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Promocode_Activations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "promocode_activations" */
export type Promocode_Activations_Aggregate_Order_By = {
  avg?: InputMaybe<Promocode_Activations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Promocode_Activations_Max_Order_By>;
  min?: InputMaybe<Promocode_Activations_Min_Order_By>;
  stddev?: InputMaybe<Promocode_Activations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Promocode_Activations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Promocode_Activations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Promocode_Activations_Sum_Order_By>;
  var_pop?: InputMaybe<Promocode_Activations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Promocode_Activations_Var_Samp_Order_By>;
  variance?: InputMaybe<Promocode_Activations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "promocode_activations" */
export type Promocode_Activations_Arr_Rel_Insert_Input = {
  data: Array<Promocode_Activations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Promocode_Activations_On_Conflict>;
};

/** aggregate avg on columns */
export type Promocode_Activations_Avg_Fields = {
  __typename?: 'promocode_activations_avg_fields';
  bonus_money_amount?: Maybe<Scalars['Float']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  payment_system?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "promocode_activations" */
export type Promocode_Activations_Avg_Order_By = {
  bonus_money_amount?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "promocode_activations". All fields are combined with a logical 'AND'. */
export type Promocode_Activations_Bool_Exp = {
  _and?: InputMaybe<Array<Promocode_Activations_Bool_Exp>>;
  _not?: InputMaybe<Promocode_Activations_Bool_Exp>;
  _or?: InputMaybe<Array<Promocode_Activations_Bool_Exp>>;
  activation_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  activation_id?: InputMaybe<Uuid_Comparison_Exp>;
  activation_used?: InputMaybe<Boolean_Comparison_Exp>;
  bonus_money_amount?: InputMaybe<Int_Comparison_Exp>;
  deposit_amount?: InputMaybe<Float_Comparison_Exp>;
  payment_system?: InputMaybe<Int_Comparison_Exp>;
  promocode?: InputMaybe<Promocodes_Bool_Exp>;
  promocode_id?: InputMaybe<Uuid_Comparison_Exp>;
  promocode_slug?: InputMaybe<String_Comparison_Exp>;
  reward_type?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "promocode_activations" */
export enum Promocode_Activations_Constraint {
  /** unique or primary key constraint on columns "activation_id" */
  PromocodeActivationsPkey = 'promocode_activations_pkey'
}

/** input type for incrementing numeric columns in table "promocode_activations" */
export type Promocode_Activations_Inc_Input = {
  bonus_money_amount?: InputMaybe<Scalars['Int']>;
  deposit_amount?: InputMaybe<Scalars['Float']>;
  payment_system?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "promocode_activations" */
export type Promocode_Activations_Insert_Input = {
  activation_datetime?: InputMaybe<Scalars['timestamp']>;
  activation_id?: InputMaybe<Scalars['uuid']>;
  activation_used?: InputMaybe<Scalars['Boolean']>;
  bonus_money_amount?: InputMaybe<Scalars['Int']>;
  deposit_amount?: InputMaybe<Scalars['Float']>;
  payment_system?: InputMaybe<Scalars['Int']>;
  promocode?: InputMaybe<Promocodes_Obj_Rel_Insert_Input>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  reward_type?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Promocode_Activations_Max_Fields = {
  __typename?: 'promocode_activations_max_fields';
  activation_datetime?: Maybe<Scalars['timestamp']>;
  activation_id?: Maybe<Scalars['uuid']>;
  bonus_money_amount?: Maybe<Scalars['Int']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  payment_system?: Maybe<Scalars['Int']>;
  promocode_id?: Maybe<Scalars['uuid']>;
  promocode_slug?: Maybe<Scalars['String']>;
  reward_type?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "promocode_activations" */
export type Promocode_Activations_Max_Order_By = {
  activation_datetime?: InputMaybe<Order_By>;
  activation_id?: InputMaybe<Order_By>;
  bonus_money_amount?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
  promocode_id?: InputMaybe<Order_By>;
  promocode_slug?: InputMaybe<Order_By>;
  reward_type?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Promocode_Activations_Min_Fields = {
  __typename?: 'promocode_activations_min_fields';
  activation_datetime?: Maybe<Scalars['timestamp']>;
  activation_id?: Maybe<Scalars['uuid']>;
  bonus_money_amount?: Maybe<Scalars['Int']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  payment_system?: Maybe<Scalars['Int']>;
  promocode_id?: Maybe<Scalars['uuid']>;
  promocode_slug?: Maybe<Scalars['String']>;
  reward_type?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "promocode_activations" */
export type Promocode_Activations_Min_Order_By = {
  activation_datetime?: InputMaybe<Order_By>;
  activation_id?: InputMaybe<Order_By>;
  bonus_money_amount?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
  promocode_id?: InputMaybe<Order_By>;
  promocode_slug?: InputMaybe<Order_By>;
  reward_type?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "promocode_activations" */
export type Promocode_Activations_Mutation_Response = {
  __typename?: 'promocode_activations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Promocode_Activations>;
};

/** on_conflict condition type for table "promocode_activations" */
export type Promocode_Activations_On_Conflict = {
  constraint: Promocode_Activations_Constraint;
  update_columns?: Array<Promocode_Activations_Update_Column>;
  where?: InputMaybe<Promocode_Activations_Bool_Exp>;
};

/** Ordering options when selecting data from "promocode_activations". */
export type Promocode_Activations_Order_By = {
  activation_datetime?: InputMaybe<Order_By>;
  activation_id?: InputMaybe<Order_By>;
  activation_used?: InputMaybe<Order_By>;
  bonus_money_amount?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
  promocode?: InputMaybe<Promocodes_Order_By>;
  promocode_id?: InputMaybe<Order_By>;
  promocode_slug?: InputMaybe<Order_By>;
  reward_type?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: promocode_activations */
export type Promocode_Activations_Pk_Columns_Input = {
  activation_id: Scalars['uuid'];
};

/** select columns of table "promocode_activations" */
export enum Promocode_Activations_Select_Column {
  /** column name */
  ActivationDatetime = 'activation_datetime',
  /** column name */
  ActivationId = 'activation_id',
  /** column name */
  ActivationUsed = 'activation_used',
  /** column name */
  BonusMoneyAmount = 'bonus_money_amount',
  /** column name */
  DepositAmount = 'deposit_amount',
  /** column name */
  PaymentSystem = 'payment_system',
  /** column name */
  PromocodeId = 'promocode_id',
  /** column name */
  PromocodeSlug = 'promocode_slug',
  /** column name */
  RewardType = 'reward_type',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** select "promocode_activations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "promocode_activations" */
export enum Promocode_Activations_Select_Column_Promocode_Activations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ActivationUsed = 'activation_used'
}

/** select "promocode_activations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "promocode_activations" */
export enum Promocode_Activations_Select_Column_Promocode_Activations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ActivationUsed = 'activation_used'
}

/** input type for updating data in table "promocode_activations" */
export type Promocode_Activations_Set_Input = {
  activation_datetime?: InputMaybe<Scalars['timestamp']>;
  activation_id?: InputMaybe<Scalars['uuid']>;
  activation_used?: InputMaybe<Scalars['Boolean']>;
  bonus_money_amount?: InputMaybe<Scalars['Int']>;
  deposit_amount?: InputMaybe<Scalars['Float']>;
  payment_system?: InputMaybe<Scalars['Int']>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  reward_type?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Promocode_Activations_Stddev_Fields = {
  __typename?: 'promocode_activations_stddev_fields';
  bonus_money_amount?: Maybe<Scalars['Float']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  payment_system?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "promocode_activations" */
export type Promocode_Activations_Stddev_Order_By = {
  bonus_money_amount?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Promocode_Activations_Stddev_Pop_Fields = {
  __typename?: 'promocode_activations_stddev_pop_fields';
  bonus_money_amount?: Maybe<Scalars['Float']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  payment_system?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "promocode_activations" */
export type Promocode_Activations_Stddev_Pop_Order_By = {
  bonus_money_amount?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Promocode_Activations_Stddev_Samp_Fields = {
  __typename?: 'promocode_activations_stddev_samp_fields';
  bonus_money_amount?: Maybe<Scalars['Float']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  payment_system?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "promocode_activations" */
export type Promocode_Activations_Stddev_Samp_Order_By = {
  bonus_money_amount?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "promocode_activations" */
export type Promocode_Activations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promocode_Activations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Promocode_Activations_Stream_Cursor_Value_Input = {
  activation_datetime?: InputMaybe<Scalars['timestamp']>;
  activation_id?: InputMaybe<Scalars['uuid']>;
  activation_used?: InputMaybe<Scalars['Boolean']>;
  bonus_money_amount?: InputMaybe<Scalars['Int']>;
  deposit_amount?: InputMaybe<Scalars['Float']>;
  payment_system?: InputMaybe<Scalars['Int']>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  reward_type?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Promocode_Activations_Sum_Fields = {
  __typename?: 'promocode_activations_sum_fields';
  bonus_money_amount?: Maybe<Scalars['Int']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  payment_system?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "promocode_activations" */
export type Promocode_Activations_Sum_Order_By = {
  bonus_money_amount?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
};

/** update columns of table "promocode_activations" */
export enum Promocode_Activations_Update_Column {
  /** column name */
  ActivationDatetime = 'activation_datetime',
  /** column name */
  ActivationId = 'activation_id',
  /** column name */
  ActivationUsed = 'activation_used',
  /** column name */
  BonusMoneyAmount = 'bonus_money_amount',
  /** column name */
  DepositAmount = 'deposit_amount',
  /** column name */
  PaymentSystem = 'payment_system',
  /** column name */
  PromocodeId = 'promocode_id',
  /** column name */
  PromocodeSlug = 'promocode_slug',
  /** column name */
  RewardType = 'reward_type',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Promocode_Activations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Promocode_Activations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Promocode_Activations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Promocode_Activations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Promocode_Activations_Var_Pop_Fields = {
  __typename?: 'promocode_activations_var_pop_fields';
  bonus_money_amount?: Maybe<Scalars['Float']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  payment_system?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "promocode_activations" */
export type Promocode_Activations_Var_Pop_Order_By = {
  bonus_money_amount?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Promocode_Activations_Var_Samp_Fields = {
  __typename?: 'promocode_activations_var_samp_fields';
  bonus_money_amount?: Maybe<Scalars['Float']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  payment_system?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "promocode_activations" */
export type Promocode_Activations_Var_Samp_Order_By = {
  bonus_money_amount?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Promocode_Activations_Variance_Fields = {
  __typename?: 'promocode_activations_variance_fields';
  bonus_money_amount?: Maybe<Scalars['Float']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  payment_system?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "promocode_activations" */
export type Promocode_Activations_Variance_Order_By = {
  bonus_money_amount?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
};

/** columns and relationships of "promocodes" */
export type Promocodes = {
  __typename?: 'promocodes';
  activations_per_user: Scalars['bigint'];
  active_end_date?: Maybe<Scalars['date']>;
  active_start_date: Scalars['date'];
  created_at: Scalars['timestamp'];
  full_activations_count?: Maybe<Scalars['bigint']>;
  is_active: Scalars['Boolean'];
  minimum_deposit_value?: Maybe<Scalars['Int']>;
  /** Оригинальное написание промокода, которое будет выводится для отображения пользователю */
  original_promocode?: Maybe<Scalars['String']>;
  promo_freespin_case_id?: Maybe<Scalars['uuid']>;
  promo_freespin_case_open_count?: Maybe<Scalars['Int']>;
  promo_is_upgrade?: Maybe<Scalars['Boolean']>;
  promo_money_amount?: Maybe<Scalars['Int']>;
  promo_percent_amount?: Maybe<Scalars['Int']>;
  /** An array relationship */
  promocode_activations: Array<Promocode_Activations>;
  /** An aggregate relationship */
  promocode_activations_aggregate: Promocode_Activations_Aggregate;
  promocode_id: Scalars['uuid'];
  promocode_max_amount?: Maybe<Scalars['Int']>;
  promocode_slug: Scalars['String'];
  promocode_type: Scalars['Int'];
  value_promocode?: Maybe<Scalars['String']>;
  value_type_promocode?: Maybe<Scalars['String']>;
};


/** columns and relationships of "promocodes" */
export type PromocodesPromocode_ActivationsArgs = {
  distinct_on?: InputMaybe<Array<Promocode_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promocode_Activations_Order_By>>;
  where?: InputMaybe<Promocode_Activations_Bool_Exp>;
};


/** columns and relationships of "promocodes" */
export type PromocodesPromocode_Activations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promocode_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promocode_Activations_Order_By>>;
  where?: InputMaybe<Promocode_Activations_Bool_Exp>;
};

/** aggregated selection of "promocodes" */
export type Promocodes_Aggregate = {
  __typename?: 'promocodes_aggregate';
  aggregate?: Maybe<Promocodes_Aggregate_Fields>;
  nodes: Array<Promocodes>;
};

/** aggregate fields of "promocodes" */
export type Promocodes_Aggregate_Fields = {
  __typename?: 'promocodes_aggregate_fields';
  avg?: Maybe<Promocodes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Promocodes_Max_Fields>;
  min?: Maybe<Promocodes_Min_Fields>;
  stddev?: Maybe<Promocodes_Stddev_Fields>;
  stddev_pop?: Maybe<Promocodes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Promocodes_Stddev_Samp_Fields>;
  sum?: Maybe<Promocodes_Sum_Fields>;
  var_pop?: Maybe<Promocodes_Var_Pop_Fields>;
  var_samp?: Maybe<Promocodes_Var_Samp_Fields>;
  variance?: Maybe<Promocodes_Variance_Fields>;
};


/** aggregate fields of "promocodes" */
export type Promocodes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Promocodes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Promocodes_Avg_Fields = {
  __typename?: 'promocodes_avg_fields';
  activations_per_user?: Maybe<Scalars['Float']>;
  full_activations_count?: Maybe<Scalars['Float']>;
  minimum_deposit_value?: Maybe<Scalars['Float']>;
  promo_freespin_case_open_count?: Maybe<Scalars['Float']>;
  promo_money_amount?: Maybe<Scalars['Float']>;
  promo_percent_amount?: Maybe<Scalars['Float']>;
  promocode_max_amount?: Maybe<Scalars['Float']>;
  promocode_type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "promocodes". All fields are combined with a logical 'AND'. */
export type Promocodes_Bool_Exp = {
  _and?: InputMaybe<Array<Promocodes_Bool_Exp>>;
  _not?: InputMaybe<Promocodes_Bool_Exp>;
  _or?: InputMaybe<Array<Promocodes_Bool_Exp>>;
  activations_per_user?: InputMaybe<Bigint_Comparison_Exp>;
  active_end_date?: InputMaybe<Date_Comparison_Exp>;
  active_start_date?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  full_activations_count?: InputMaybe<Bigint_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  minimum_deposit_value?: InputMaybe<Int_Comparison_Exp>;
  original_promocode?: InputMaybe<String_Comparison_Exp>;
  promo_freespin_case_id?: InputMaybe<Uuid_Comparison_Exp>;
  promo_freespin_case_open_count?: InputMaybe<Int_Comparison_Exp>;
  promo_is_upgrade?: InputMaybe<Boolean_Comparison_Exp>;
  promo_money_amount?: InputMaybe<Int_Comparison_Exp>;
  promo_percent_amount?: InputMaybe<Int_Comparison_Exp>;
  promocode_activations?: InputMaybe<Promocode_Activations_Bool_Exp>;
  promocode_activations_aggregate?: InputMaybe<Promocode_Activations_Aggregate_Bool_Exp>;
  promocode_id?: InputMaybe<Uuid_Comparison_Exp>;
  promocode_max_amount?: InputMaybe<Int_Comparison_Exp>;
  promocode_slug?: InputMaybe<String_Comparison_Exp>;
  promocode_type?: InputMaybe<Int_Comparison_Exp>;
  value_promocode?: InputMaybe<String_Comparison_Exp>;
  value_type_promocode?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "promocodes" */
export enum Promocodes_Constraint {
  /** unique or primary key constraint on columns "promocode_id" */
  PromocodesPkey = 'promocodes_pkey',
  /** unique or primary key constraint on columns "promocode_slug" */
  PromocodesPromocodeSlugKey = 'promocodes_promocode_slug_key'
}

/** input type for incrementing numeric columns in table "promocodes" */
export type Promocodes_Inc_Input = {
  activations_per_user?: InputMaybe<Scalars['bigint']>;
  full_activations_count?: InputMaybe<Scalars['bigint']>;
  minimum_deposit_value?: InputMaybe<Scalars['Int']>;
  promo_freespin_case_open_count?: InputMaybe<Scalars['Int']>;
  promo_money_amount?: InputMaybe<Scalars['Int']>;
  promo_percent_amount?: InputMaybe<Scalars['Int']>;
  promocode_max_amount?: InputMaybe<Scalars['Int']>;
  promocode_type?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "promocodes" */
export type Promocodes_Insert_Input = {
  activations_per_user?: InputMaybe<Scalars['bigint']>;
  active_end_date?: InputMaybe<Scalars['date']>;
  active_start_date?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  full_activations_count?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  minimum_deposit_value?: InputMaybe<Scalars['Int']>;
  /** Оригинальное написание промокода, которое будет выводится для отображения пользователю */
  original_promocode?: InputMaybe<Scalars['String']>;
  promo_freespin_case_id?: InputMaybe<Scalars['uuid']>;
  promo_freespin_case_open_count?: InputMaybe<Scalars['Int']>;
  promo_is_upgrade?: InputMaybe<Scalars['Boolean']>;
  promo_money_amount?: InputMaybe<Scalars['Int']>;
  promo_percent_amount?: InputMaybe<Scalars['Int']>;
  promocode_activations?: InputMaybe<Promocode_Activations_Arr_Rel_Insert_Input>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_max_amount?: InputMaybe<Scalars['Int']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  promocode_type?: InputMaybe<Scalars['Int']>;
  value_promocode?: InputMaybe<Scalars['String']>;
  value_type_promocode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Promocodes_Max_Fields = {
  __typename?: 'promocodes_max_fields';
  activations_per_user?: Maybe<Scalars['bigint']>;
  active_end_date?: Maybe<Scalars['date']>;
  active_start_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  full_activations_count?: Maybe<Scalars['bigint']>;
  minimum_deposit_value?: Maybe<Scalars['Int']>;
  /** Оригинальное написание промокода, которое будет выводится для отображения пользователю */
  original_promocode?: Maybe<Scalars['String']>;
  promo_freespin_case_id?: Maybe<Scalars['uuid']>;
  promo_freespin_case_open_count?: Maybe<Scalars['Int']>;
  promo_money_amount?: Maybe<Scalars['Int']>;
  promo_percent_amount?: Maybe<Scalars['Int']>;
  promocode_id?: Maybe<Scalars['uuid']>;
  promocode_max_amount?: Maybe<Scalars['Int']>;
  promocode_slug?: Maybe<Scalars['String']>;
  promocode_type?: Maybe<Scalars['Int']>;
  value_promocode?: Maybe<Scalars['String']>;
  value_type_promocode?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Promocodes_Min_Fields = {
  __typename?: 'promocodes_min_fields';
  activations_per_user?: Maybe<Scalars['bigint']>;
  active_end_date?: Maybe<Scalars['date']>;
  active_start_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamp']>;
  full_activations_count?: Maybe<Scalars['bigint']>;
  minimum_deposit_value?: Maybe<Scalars['Int']>;
  /** Оригинальное написание промокода, которое будет выводится для отображения пользователю */
  original_promocode?: Maybe<Scalars['String']>;
  promo_freespin_case_id?: Maybe<Scalars['uuid']>;
  promo_freespin_case_open_count?: Maybe<Scalars['Int']>;
  promo_money_amount?: Maybe<Scalars['Int']>;
  promo_percent_amount?: Maybe<Scalars['Int']>;
  promocode_id?: Maybe<Scalars['uuid']>;
  promocode_max_amount?: Maybe<Scalars['Int']>;
  promocode_slug?: Maybe<Scalars['String']>;
  promocode_type?: Maybe<Scalars['Int']>;
  value_promocode?: Maybe<Scalars['String']>;
  value_type_promocode?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "promocodes" */
export type Promocodes_Mutation_Response = {
  __typename?: 'promocodes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Promocodes>;
};

/** input type for inserting object relation for remote table "promocodes" */
export type Promocodes_Obj_Rel_Insert_Input = {
  data: Promocodes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Promocodes_On_Conflict>;
};

/** on_conflict condition type for table "promocodes" */
export type Promocodes_On_Conflict = {
  constraint: Promocodes_Constraint;
  update_columns?: Array<Promocodes_Update_Column>;
  where?: InputMaybe<Promocodes_Bool_Exp>;
};

/** Ordering options when selecting data from "promocodes". */
export type Promocodes_Order_By = {
  activations_per_user?: InputMaybe<Order_By>;
  active_end_date?: InputMaybe<Order_By>;
  active_start_date?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  full_activations_count?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  minimum_deposit_value?: InputMaybe<Order_By>;
  original_promocode?: InputMaybe<Order_By>;
  promo_freespin_case_id?: InputMaybe<Order_By>;
  promo_freespin_case_open_count?: InputMaybe<Order_By>;
  promo_is_upgrade?: InputMaybe<Order_By>;
  promo_money_amount?: InputMaybe<Order_By>;
  promo_percent_amount?: InputMaybe<Order_By>;
  promocode_activations_aggregate?: InputMaybe<Promocode_Activations_Aggregate_Order_By>;
  promocode_id?: InputMaybe<Order_By>;
  promocode_max_amount?: InputMaybe<Order_By>;
  promocode_slug?: InputMaybe<Order_By>;
  promocode_type?: InputMaybe<Order_By>;
  value_promocode?: InputMaybe<Order_By>;
  value_type_promocode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: promocodes */
export type Promocodes_Pk_Columns_Input = {
  promocode_id: Scalars['uuid'];
};

/** select columns of table "promocodes" */
export enum Promocodes_Select_Column {
  /** column name */
  ActivationsPerUser = 'activations_per_user',
  /** column name */
  ActiveEndDate = 'active_end_date',
  /** column name */
  ActiveStartDate = 'active_start_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FullActivationsCount = 'full_activations_count',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MinimumDepositValue = 'minimum_deposit_value',
  /** column name */
  OriginalPromocode = 'original_promocode',
  /** column name */
  PromoFreespinCaseId = 'promo_freespin_case_id',
  /** column name */
  PromoFreespinCaseOpenCount = 'promo_freespin_case_open_count',
  /** column name */
  PromoIsUpgrade = 'promo_is_upgrade',
  /** column name */
  PromoMoneyAmount = 'promo_money_amount',
  /** column name */
  PromoPercentAmount = 'promo_percent_amount',
  /** column name */
  PromocodeId = 'promocode_id',
  /** column name */
  PromocodeMaxAmount = 'promocode_max_amount',
  /** column name */
  PromocodeSlug = 'promocode_slug',
  /** column name */
  PromocodeType = 'promocode_type',
  /** column name */
  ValuePromocode = 'value_promocode',
  /** column name */
  ValueTypePromocode = 'value_type_promocode'
}

/** input type for updating data in table "promocodes" */
export type Promocodes_Set_Input = {
  activations_per_user?: InputMaybe<Scalars['bigint']>;
  active_end_date?: InputMaybe<Scalars['date']>;
  active_start_date?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  full_activations_count?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  minimum_deposit_value?: InputMaybe<Scalars['Int']>;
  /** Оригинальное написание промокода, которое будет выводится для отображения пользователю */
  original_promocode?: InputMaybe<Scalars['String']>;
  promo_freespin_case_id?: InputMaybe<Scalars['uuid']>;
  promo_freespin_case_open_count?: InputMaybe<Scalars['Int']>;
  promo_is_upgrade?: InputMaybe<Scalars['Boolean']>;
  promo_money_amount?: InputMaybe<Scalars['Int']>;
  promo_percent_amount?: InputMaybe<Scalars['Int']>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_max_amount?: InputMaybe<Scalars['Int']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  promocode_type?: InputMaybe<Scalars['Int']>;
  value_promocode?: InputMaybe<Scalars['String']>;
  value_type_promocode?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Promocodes_Stddev_Fields = {
  __typename?: 'promocodes_stddev_fields';
  activations_per_user?: Maybe<Scalars['Float']>;
  full_activations_count?: Maybe<Scalars['Float']>;
  minimum_deposit_value?: Maybe<Scalars['Float']>;
  promo_freespin_case_open_count?: Maybe<Scalars['Float']>;
  promo_money_amount?: Maybe<Scalars['Float']>;
  promo_percent_amount?: Maybe<Scalars['Float']>;
  promocode_max_amount?: Maybe<Scalars['Float']>;
  promocode_type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Promocodes_Stddev_Pop_Fields = {
  __typename?: 'promocodes_stddev_pop_fields';
  activations_per_user?: Maybe<Scalars['Float']>;
  full_activations_count?: Maybe<Scalars['Float']>;
  minimum_deposit_value?: Maybe<Scalars['Float']>;
  promo_freespin_case_open_count?: Maybe<Scalars['Float']>;
  promo_money_amount?: Maybe<Scalars['Float']>;
  promo_percent_amount?: Maybe<Scalars['Float']>;
  promocode_max_amount?: Maybe<Scalars['Float']>;
  promocode_type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Promocodes_Stddev_Samp_Fields = {
  __typename?: 'promocodes_stddev_samp_fields';
  activations_per_user?: Maybe<Scalars['Float']>;
  full_activations_count?: Maybe<Scalars['Float']>;
  minimum_deposit_value?: Maybe<Scalars['Float']>;
  promo_freespin_case_open_count?: Maybe<Scalars['Float']>;
  promo_money_amount?: Maybe<Scalars['Float']>;
  promo_percent_amount?: Maybe<Scalars['Float']>;
  promocode_max_amount?: Maybe<Scalars['Float']>;
  promocode_type?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "promocodes" */
export type Promocodes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promocodes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Promocodes_Stream_Cursor_Value_Input = {
  activations_per_user?: InputMaybe<Scalars['bigint']>;
  active_end_date?: InputMaybe<Scalars['date']>;
  active_start_date?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  full_activations_count?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  minimum_deposit_value?: InputMaybe<Scalars['Int']>;
  /** Оригинальное написание промокода, которое будет выводится для отображения пользователю */
  original_promocode?: InputMaybe<Scalars['String']>;
  promo_freespin_case_id?: InputMaybe<Scalars['uuid']>;
  promo_freespin_case_open_count?: InputMaybe<Scalars['Int']>;
  promo_is_upgrade?: InputMaybe<Scalars['Boolean']>;
  promo_money_amount?: InputMaybe<Scalars['Int']>;
  promo_percent_amount?: InputMaybe<Scalars['Int']>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_max_amount?: InputMaybe<Scalars['Int']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  promocode_type?: InputMaybe<Scalars['Int']>;
  value_promocode?: InputMaybe<Scalars['String']>;
  value_type_promocode?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Promocodes_Sum_Fields = {
  __typename?: 'promocodes_sum_fields';
  activations_per_user?: Maybe<Scalars['bigint']>;
  full_activations_count?: Maybe<Scalars['bigint']>;
  minimum_deposit_value?: Maybe<Scalars['Int']>;
  promo_freespin_case_open_count?: Maybe<Scalars['Int']>;
  promo_money_amount?: Maybe<Scalars['Int']>;
  promo_percent_amount?: Maybe<Scalars['Int']>;
  promocode_max_amount?: Maybe<Scalars['Int']>;
  promocode_type?: Maybe<Scalars['Int']>;
};

/** update columns of table "promocodes" */
export enum Promocodes_Update_Column {
  /** column name */
  ActivationsPerUser = 'activations_per_user',
  /** column name */
  ActiveEndDate = 'active_end_date',
  /** column name */
  ActiveStartDate = 'active_start_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FullActivationsCount = 'full_activations_count',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MinimumDepositValue = 'minimum_deposit_value',
  /** column name */
  OriginalPromocode = 'original_promocode',
  /** column name */
  PromoFreespinCaseId = 'promo_freespin_case_id',
  /** column name */
  PromoFreespinCaseOpenCount = 'promo_freespin_case_open_count',
  /** column name */
  PromoIsUpgrade = 'promo_is_upgrade',
  /** column name */
  PromoMoneyAmount = 'promo_money_amount',
  /** column name */
  PromoPercentAmount = 'promo_percent_amount',
  /** column name */
  PromocodeId = 'promocode_id',
  /** column name */
  PromocodeMaxAmount = 'promocode_max_amount',
  /** column name */
  PromocodeSlug = 'promocode_slug',
  /** column name */
  PromocodeType = 'promocode_type',
  /** column name */
  ValuePromocode = 'value_promocode',
  /** column name */
  ValueTypePromocode = 'value_type_promocode'
}

export type Promocodes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Promocodes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Promocodes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Promocodes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Promocodes_Var_Pop_Fields = {
  __typename?: 'promocodes_var_pop_fields';
  activations_per_user?: Maybe<Scalars['Float']>;
  full_activations_count?: Maybe<Scalars['Float']>;
  minimum_deposit_value?: Maybe<Scalars['Float']>;
  promo_freespin_case_open_count?: Maybe<Scalars['Float']>;
  promo_money_amount?: Maybe<Scalars['Float']>;
  promo_percent_amount?: Maybe<Scalars['Float']>;
  promocode_max_amount?: Maybe<Scalars['Float']>;
  promocode_type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Promocodes_Var_Samp_Fields = {
  __typename?: 'promocodes_var_samp_fields';
  activations_per_user?: Maybe<Scalars['Float']>;
  full_activations_count?: Maybe<Scalars['Float']>;
  minimum_deposit_value?: Maybe<Scalars['Float']>;
  promo_freespin_case_open_count?: Maybe<Scalars['Float']>;
  promo_money_amount?: Maybe<Scalars['Float']>;
  promo_percent_amount?: Maybe<Scalars['Float']>;
  promocode_max_amount?: Maybe<Scalars['Float']>;
  promocode_type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Promocodes_Variance_Fields = {
  __typename?: 'promocodes_variance_fields';
  activations_per_user?: Maybe<Scalars['Float']>;
  full_activations_count?: Maybe<Scalars['Float']>;
  minimum_deposit_value?: Maybe<Scalars['Float']>;
  promo_freespin_case_open_count?: Maybe<Scalars['Float']>;
  promo_money_amount?: Maybe<Scalars['Float']>;
  promo_percent_amount?: Maybe<Scalars['Float']>;
  promocode_max_amount?: Maybe<Scalars['Float']>;
  promocode_type?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ps_types" */
export type Ps_Types = {
  __typename?: 'ps_types';
  is_active: Scalars['Boolean'];
  /** An array relationship */
  pm_methods: Array<Payment_Systems_Methods>;
  /** An aggregate relationship */
  pm_methods_aggregate: Payment_Systems_Methods_Aggregate;
  ps_id: Scalars['Int'];
  ps_name: Scalars['String'];
  /** An object relationship */
  ps_provider?: Maybe<Payment_Providers>;
  ps_uuid: Scalars['uuid'];
};


/** columns and relationships of "ps_types" */
export type Ps_TypesPm_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Methods_Order_By>>;
  where?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
};


/** columns and relationships of "ps_types" */
export type Ps_TypesPm_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Methods_Order_By>>;
  where?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
};

/** aggregated selection of "ps_types" */
export type Ps_Types_Aggregate = {
  __typename?: 'ps_types_aggregate';
  aggregate?: Maybe<Ps_Types_Aggregate_Fields>;
  nodes: Array<Ps_Types>;
};

/** aggregate fields of "ps_types" */
export type Ps_Types_Aggregate_Fields = {
  __typename?: 'ps_types_aggregate_fields';
  avg?: Maybe<Ps_Types_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ps_Types_Max_Fields>;
  min?: Maybe<Ps_Types_Min_Fields>;
  stddev?: Maybe<Ps_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Ps_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ps_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Ps_Types_Sum_Fields>;
  var_pop?: Maybe<Ps_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Ps_Types_Var_Samp_Fields>;
  variance?: Maybe<Ps_Types_Variance_Fields>;
};


/** aggregate fields of "ps_types" */
export type Ps_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ps_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ps_Types_Avg_Fields = {
  __typename?: 'ps_types_avg_fields';
  ps_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ps_types". All fields are combined with a logical 'AND'. */
export type Ps_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Ps_Types_Bool_Exp>>;
  _not?: InputMaybe<Ps_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Ps_Types_Bool_Exp>>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  pm_methods?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
  pm_methods_aggregate?: InputMaybe<Payment_Systems_Methods_Aggregate_Bool_Exp>;
  ps_id?: InputMaybe<Int_Comparison_Exp>;
  ps_name?: InputMaybe<String_Comparison_Exp>;
  ps_provider?: InputMaybe<Payment_Providers_Bool_Exp>;
  ps_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ps_types" */
export enum Ps_Types_Constraint {
  /** unique or primary key constraint on columns "ps_id" */
  PsTypesPkey = 'ps_types_pkey'
}

/** input type for incrementing numeric columns in table "ps_types" */
export type Ps_Types_Inc_Input = {
  ps_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ps_types" */
export type Ps_Types_Insert_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  pm_methods?: InputMaybe<Payment_Systems_Methods_Arr_Rel_Insert_Input>;
  ps_id?: InputMaybe<Scalars['Int']>;
  ps_name?: InputMaybe<Scalars['String']>;
  ps_provider?: InputMaybe<Payment_Providers_Obj_Rel_Insert_Input>;
  ps_uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ps_Types_Max_Fields = {
  __typename?: 'ps_types_max_fields';
  ps_id?: Maybe<Scalars['Int']>;
  ps_name?: Maybe<Scalars['String']>;
  ps_uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ps_Types_Min_Fields = {
  __typename?: 'ps_types_min_fields';
  ps_id?: Maybe<Scalars['Int']>;
  ps_name?: Maybe<Scalars['String']>;
  ps_uuid?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "ps_types" */
export type Ps_Types_Mutation_Response = {
  __typename?: 'ps_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ps_Types>;
};

/** on_conflict condition type for table "ps_types" */
export type Ps_Types_On_Conflict = {
  constraint: Ps_Types_Constraint;
  update_columns?: Array<Ps_Types_Update_Column>;
  where?: InputMaybe<Ps_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "ps_types". */
export type Ps_Types_Order_By = {
  is_active?: InputMaybe<Order_By>;
  pm_methods_aggregate?: InputMaybe<Payment_Systems_Methods_Aggregate_Order_By>;
  ps_id?: InputMaybe<Order_By>;
  ps_name?: InputMaybe<Order_By>;
  ps_provider?: InputMaybe<Payment_Providers_Order_By>;
  ps_uuid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ps_types */
export type Ps_Types_Pk_Columns_Input = {
  ps_id: Scalars['Int'];
};

/** select columns of table "ps_types" */
export enum Ps_Types_Select_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PsName = 'ps_name',
  /** column name */
  PsUuid = 'ps_uuid'
}

/** input type for updating data in table "ps_types" */
export type Ps_Types_Set_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  ps_id?: InputMaybe<Scalars['Int']>;
  ps_name?: InputMaybe<Scalars['String']>;
  ps_uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Ps_Types_Stddev_Fields = {
  __typename?: 'ps_types_stddev_fields';
  ps_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ps_Types_Stddev_Pop_Fields = {
  __typename?: 'ps_types_stddev_pop_fields';
  ps_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ps_Types_Stddev_Samp_Fields = {
  __typename?: 'ps_types_stddev_samp_fields';
  ps_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ps_types" */
export type Ps_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ps_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ps_Types_Stream_Cursor_Value_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  ps_id?: InputMaybe<Scalars['Int']>;
  ps_name?: InputMaybe<Scalars['String']>;
  ps_uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Ps_Types_Sum_Fields = {
  __typename?: 'ps_types_sum_fields';
  ps_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "ps_types" */
export enum Ps_Types_Update_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PsId = 'ps_id',
  /** column name */
  PsName = 'ps_name',
  /** column name */
  PsUuid = 'ps_uuid'
}

export type Ps_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ps_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ps_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ps_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ps_Types_Var_Pop_Fields = {
  __typename?: 'ps_types_var_pop_fields';
  ps_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ps_Types_Var_Samp_Fields = {
  __typename?: 'ps_types_var_samp_fields';
  ps_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ps_Types_Variance_Fields = {
  __typename?: 'ps_types_variance_fields';
  ps_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "qservice_data" */
export type Qservice_Data = {
  __typename?: 'qservice_data';
  /** An object relationship */
  csgomarket_tokens?: Maybe<Csgomarket_Tokens>;
  order_custom_id?: Maybe<Scalars['String']>;
  request_date: Scalars['timestamp'];
  request_id: Scalars['uuid'];
  request_type?: Maybe<Scalars['smallint']>;
  status?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "qservice_data" */
export type Qservice_Data_Aggregate = {
  __typename?: 'qservice_data_aggregate';
  aggregate?: Maybe<Qservice_Data_Aggregate_Fields>;
  nodes: Array<Qservice_Data>;
};

export type Qservice_Data_Aggregate_Bool_Exp = {
  count?: InputMaybe<Qservice_Data_Aggregate_Bool_Exp_Count>;
};

export type Qservice_Data_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Qservice_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Qservice_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "qservice_data" */
export type Qservice_Data_Aggregate_Fields = {
  __typename?: 'qservice_data_aggregate_fields';
  avg?: Maybe<Qservice_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Qservice_Data_Max_Fields>;
  min?: Maybe<Qservice_Data_Min_Fields>;
  stddev?: Maybe<Qservice_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Qservice_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Qservice_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Qservice_Data_Sum_Fields>;
  var_pop?: Maybe<Qservice_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Qservice_Data_Var_Samp_Fields>;
  variance?: Maybe<Qservice_Data_Variance_Fields>;
};


/** aggregate fields of "qservice_data" */
export type Qservice_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Qservice_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "qservice_data" */
export type Qservice_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Qservice_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Qservice_Data_Max_Order_By>;
  min?: InputMaybe<Qservice_Data_Min_Order_By>;
  stddev?: InputMaybe<Qservice_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Qservice_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Qservice_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Qservice_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Qservice_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Qservice_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Qservice_Data_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "qservice_data" */
export type Qservice_Data_Arr_Rel_Insert_Input = {
  data: Array<Qservice_Data_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Qservice_Data_On_Conflict>;
};

/** aggregate avg on columns */
export type Qservice_Data_Avg_Fields = {
  __typename?: 'qservice_data_avg_fields';
  request_type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "qservice_data" */
export type Qservice_Data_Avg_Order_By = {
  request_type?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "qservice_data". All fields are combined with a logical 'AND'. */
export type Qservice_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Qservice_Data_Bool_Exp>>;
  _not?: InputMaybe<Qservice_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Qservice_Data_Bool_Exp>>;
  csgomarket_tokens?: InputMaybe<Csgomarket_Tokens_Bool_Exp>;
  order_custom_id?: InputMaybe<String_Comparison_Exp>;
  request_date?: InputMaybe<Timestamp_Comparison_Exp>;
  request_id?: InputMaybe<Uuid_Comparison_Exp>;
  request_type?: InputMaybe<Smallint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "qservice_data" */
export enum Qservice_Data_Constraint {
  /** unique or primary key constraint on columns "request_id" */
  QserviceDataPkey = 'qservice_data_pkey'
}

/** input type for incrementing numeric columns in table "qservice_data" */
export type Qservice_Data_Inc_Input = {
  request_type?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "qservice_data" */
export type Qservice_Data_Insert_Input = {
  csgomarket_tokens?: InputMaybe<Csgomarket_Tokens_Obj_Rel_Insert_Input>;
  order_custom_id?: InputMaybe<Scalars['String']>;
  request_date?: InputMaybe<Scalars['timestamp']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  request_type?: InputMaybe<Scalars['smallint']>;
  status?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Qservice_Data_Max_Fields = {
  __typename?: 'qservice_data_max_fields';
  order_custom_id?: Maybe<Scalars['String']>;
  request_date?: Maybe<Scalars['timestamp']>;
  request_id?: Maybe<Scalars['uuid']>;
  request_type?: Maybe<Scalars['smallint']>;
  status?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "qservice_data" */
export type Qservice_Data_Max_Order_By = {
  order_custom_id?: InputMaybe<Order_By>;
  request_date?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  request_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Qservice_Data_Min_Fields = {
  __typename?: 'qservice_data_min_fields';
  order_custom_id?: Maybe<Scalars['String']>;
  request_date?: Maybe<Scalars['timestamp']>;
  request_id?: Maybe<Scalars['uuid']>;
  request_type?: Maybe<Scalars['smallint']>;
  status?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "qservice_data" */
export type Qservice_Data_Min_Order_By = {
  order_custom_id?: InputMaybe<Order_By>;
  request_date?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  request_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "qservice_data" */
export type Qservice_Data_Mutation_Response = {
  __typename?: 'qservice_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Qservice_Data>;
};

/** on_conflict condition type for table "qservice_data" */
export type Qservice_Data_On_Conflict = {
  constraint: Qservice_Data_Constraint;
  update_columns?: Array<Qservice_Data_Update_Column>;
  where?: InputMaybe<Qservice_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "qservice_data". */
export type Qservice_Data_Order_By = {
  csgomarket_tokens?: InputMaybe<Csgomarket_Tokens_Order_By>;
  order_custom_id?: InputMaybe<Order_By>;
  request_date?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  request_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: qservice_data */
export type Qservice_Data_Pk_Columns_Input = {
  request_id: Scalars['uuid'];
};

/** select columns of table "qservice_data" */
export enum Qservice_Data_Select_Column {
  /** column name */
  OrderCustomId = 'order_custom_id',
  /** column name */
  RequestDate = 'request_date',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  RequestType = 'request_type',
  /** column name */
  Status = 'status',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "qservice_data" */
export type Qservice_Data_Set_Input = {
  order_custom_id?: InputMaybe<Scalars['String']>;
  request_date?: InputMaybe<Scalars['timestamp']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  request_type?: InputMaybe<Scalars['smallint']>;
  status?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Qservice_Data_Stddev_Fields = {
  __typename?: 'qservice_data_stddev_fields';
  request_type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "qservice_data" */
export type Qservice_Data_Stddev_Order_By = {
  request_type?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Qservice_Data_Stddev_Pop_Fields = {
  __typename?: 'qservice_data_stddev_pop_fields';
  request_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "qservice_data" */
export type Qservice_Data_Stddev_Pop_Order_By = {
  request_type?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Qservice_Data_Stddev_Samp_Fields = {
  __typename?: 'qservice_data_stddev_samp_fields';
  request_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "qservice_data" */
export type Qservice_Data_Stddev_Samp_Order_By = {
  request_type?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "qservice_data" */
export type Qservice_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Qservice_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Qservice_Data_Stream_Cursor_Value_Input = {
  order_custom_id?: InputMaybe<Scalars['String']>;
  request_date?: InputMaybe<Scalars['timestamp']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  request_type?: InputMaybe<Scalars['smallint']>;
  status?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Qservice_Data_Sum_Fields = {
  __typename?: 'qservice_data_sum_fields';
  request_type?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "qservice_data" */
export type Qservice_Data_Sum_Order_By = {
  request_type?: InputMaybe<Order_By>;
};

/** update columns of table "qservice_data" */
export enum Qservice_Data_Update_Column {
  /** column name */
  OrderCustomId = 'order_custom_id',
  /** column name */
  RequestDate = 'request_date',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  RequestType = 'request_type',
  /** column name */
  Status = 'status',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UserId = 'user_id'
}

export type Qservice_Data_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Qservice_Data_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Qservice_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Qservice_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Qservice_Data_Var_Pop_Fields = {
  __typename?: 'qservice_data_var_pop_fields';
  request_type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "qservice_data" */
export type Qservice_Data_Var_Pop_Order_By = {
  request_type?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Qservice_Data_Var_Samp_Fields = {
  __typename?: 'qservice_data_var_samp_fields';
  request_type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "qservice_data" */
export type Qservice_Data_Var_Samp_Order_By = {
  request_type?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Qservice_Data_Variance_Fields = {
  __typename?: 'qservice_data_variance_fields';
  request_type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "qservice_data" */
export type Qservice_Data_Variance_Order_By = {
  request_type?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  BoostersShop?: Maybe<Boosters>;
  CalcUpgradeParams: UserUpgradeCalcAnswer;
  CalculateReferralBalance?: Maybe<CalculateReferralBalanceResponse>;
  CheckUserPromocode?: Maybe<CheckPromocodeOutput>;
  ClosestGiveaway?: Maybe<ClosestGiveawayResponse>;
  CompetitionStatusAction?: Maybe<CompetitionStatusModel>;
  GetBWPageData: BonusWheelPageDataAnswerTemp;
  GetBWPageDataByUIDTEMP: BonusWheelPageDataAnswerTemp;
  GetBasicMissionsData?: Maybe<BasicMissionsResponse>;
  GetBonusWheelAvailabilityDataByUserID: BonusWheelPageDataAnswer;
  GetBonusWheelPageData: BonusWheelPageDataAnswer;
  GetCurrentOnline?: Maybe<CurrentOnlineOutput>;
  GetDemoLBData?: Maybe<DemoLootboxDataOutput>;
  GetDemoResultItem?: Maybe<DemoItemOutput>;
  GetFreeLootboxInfoBySlug?: Maybe<FreeLootboxInfo>;
  GetFreeLootboxes: Array<FreeLootbox>;
  GetGiveaway?: Maybe<GivewaysInfo>;
  GetGiveawayMembers?: Maybe<Array<Maybe<GivewayMembers>>>;
  GetGiveawaysRibbon?: Maybe<Array<Maybe<GivewayRibbon>>>;
  GetHistoryUserGiveaway?: Maybe<UserGiveawayHistoryResponse>;
  GetItemsInBonusLootboxBySlug: GetItemsInBonusLootboxOutput;
  GetItemsInFreeLootbox?: Maybe<GetItemsInFreeLootboxOutput>;
  GetItemsInFreeLootboxBySlug: GetItemsInFreeLootboxOutput;
  GetItemsInLootboxBySlug?: Maybe<GetItemsInLootboxOutput>;
  GetItemsListByName?: Maybe<OutputItemsInfoList>;
  GetItemsOutputStatusFromMarketMass?: Maybe<Array<ItemsOutputStatusOutput>>;
  GetLootboxInfoBySlug?: Maybe<LootboxInfoBySlug>;
  GetMainpageLootboxFilterParams: MpLootboxesFilterModel;
  GetMainpageLootboxes: MainPageLootboxesAnswerModel;
  GetMissionsData?: Maybe<MissionsResponse>;
  GetOffers?: Maybe<OfferResponse>;
  GetPageMetas?: Maybe<MetasOutput>;
  GetPossibleItemsForUpgrade: GetItemsForUpgradeAnswer;
  GetReferralInfo?: Maybe<OfferAccountInfo>;
  GetReferralStatistics?: Maybe<ReferralStatisticsResponse>;
  GetTokensQueue?: Maybe<Array<Maybe<GetTokensQueueOutput>>>;
  GetUserHelloBonusData: HelloBonusAnswer;
  GetUserReferalPageData: GetUserReferalPageDataOutput;
  GetUserSettingsPageData: UserSettingsPageDataModel;
  GetWheelRewardType: WheelRewardsType;
  GiveawayGetItem?: Maybe<GiveawayGetItem>;
  GiveawayParticipate?: Maybe<GiveawayParticipate>;
  GiveawaysBots?: Maybe<SampleOutput>;
  GiveawaysWinner: Scalars['String'];
  /** fetch data from the table: "LootboxOpensCounts" */
  LootboxOpensCounts: Array<LootboxOpensCounts>;
  /** fetch aggregated fields from the table: "LootboxOpensCounts" */
  LootboxOpensCounts_aggregate: LootboxOpensCounts_Aggregate;
  MonthlyWinners?: Maybe<MonthlyWinnersResponse>;
  ParticipantRewardsAction?: Maybe<ParticipantRewardsOutput>;
  ParticipantsRatingAction?: Maybe<ParticipantsRatingModel>;
  SendEmailToUser?: Maybe<SendEmailToUserAnswer>;
  /** Метод получения предметов для игры Что круче */
  Store__BetterGameItems: GameItemsResponse;
  /** Метод запроса данных о получении бесплатных монет в магазине */
  Store__DailyCoinsClaimInfo?: Maybe<DailyCoinsClaimAnswer>;
  /** Метод получение предложений магазина */
  Store__OffersData?: Maybe<StoreOffersView>;
  /** Тестовый экшен для главной стсраницы */
  TestGetMainpageLootboxes: TestMainPageLootboxesAnswerModel;
  /** fetch data from the table: "TopDropStripView" */
  TopDropStripView: Array<TopDropStripView>;
  /** fetch aggregated fields from the table: "TopDropStripView" */
  TopDropStripView_aggregate: TopDropStripView_Aggregate;
  UpdatedGiveaways: Scalars['String'];
  UpdatedMailAlertsCustomer?: Maybe<Answer>;
  UserWheelInfo: UserWheelInfo;
  /** Метод получения данных о рейтинге участников розыгрышей гивов. */
  UsersGiveawaysRating?: Maybe<UsersGiveawaysRatingResponse>;
  ValidateItemsOutStatusMass?: Maybe<Array<ItemsOutputStatusOutput>>;
  ValidateUserBonusWheelAvailability?: Maybe<ValidateUserBonusWheelAvailabilityAnswerModel>;
  WheelUserTicketInfo?: Maybe<TicketsInfoResponse>;
  WhichItemBetterGame?: Maybe<Array<RandomItems>>;
  /** fetch data from the table: "account_items_names" */
  account_items_names: Array<Account_Items_Names>;
  /** fetch aggregated fields from the table: "account_items_names" */
  account_items_names_aggregate: Account_Items_Names_Aggregate;
  /** fetch data from the table: "account_items_names" using primary key columns */
  account_items_names_by_pk?: Maybe<Account_Items_Names>;
  /** fetch data from the table: "admin_items_names" */
  admin_items_names: Array<Admin_Items_Names>;
  /** fetch aggregated fields from the table: "admin_items_names" */
  admin_items_names_aggregate: Admin_Items_Names_Aggregate;
  /** fetch data from the table: "admin_items_names" using primary key columns */
  admin_items_names_by_pk?: Maybe<Admin_Items_Names>;
  /** fetch data from the table: "admin_user_params" */
  admin_user_params: Array<Admin_User_Params>;
  /** fetch aggregated fields from the table: "admin_user_params" */
  admin_user_params_aggregate: Admin_User_Params_Aggregate;
  /** fetch data from the table: "admin_user_params" using primary key columns */
  admin_user_params_by_pk?: Maybe<Admin_User_Params>;
  /** fetch data from the table: "amp_sessions_data" */
  amp_sessions_data: Array<Amp_Sessions_Data>;
  /** fetch aggregated fields from the table: "amp_sessions_data" */
  amp_sessions_data_aggregate: Amp_Sessions_Data_Aggregate;
  /** fetch data from the table: "amp_sessions_data" using primary key columns */
  amp_sessions_data_by_pk?: Maybe<Amp_Sessions_Data>;
  /** fetch data from the table: "banned_ips" */
  banned_ips: Array<Banned_Ips>;
  /** fetch aggregated fields from the table: "banned_ips" */
  banned_ips_aggregate: Banned_Ips_Aggregate;
  /** fetch data from the table: "banned_ips" using primary key columns */
  banned_ips_by_pk?: Maybe<Banned_Ips>;
  /** fetch data from the table: "banned_users_for_multiaccount" */
  banned_users_for_multiaccount: Array<Banned_Users_For_Multiaccount>;
  /** fetch aggregated fields from the table: "banned_users_for_multiaccount" */
  banned_users_for_multiaccount_aggregate: Banned_Users_For_Multiaccount_Aggregate;
  /** fetch data from the table: "banned_users_for_multiaccount" using primary key columns */
  banned_users_for_multiaccount_by_pk?: Maybe<Banned_Users_For_Multiaccount>;
  /** fetch data from the table: "bill" */
  bill: Array<Bill>;
  /** fetch aggregated fields from the table: "bill" */
  bill_aggregate: Bill_Aggregate;
  /** fetch data from the table: "bill" using primary key columns */
  bill_by_pk?: Maybe<Bill>;
  /** fetch data from the table: "bonus_params" */
  bonus_params: Array<Bonus_Params>;
  /** fetch aggregated fields from the table: "bonus_params" */
  bonus_params_aggregate: Bonus_Params_Aggregate;
  /** fetch data from the table: "bonus_params" using primary key columns */
  bonus_params_by_pk?: Maybe<Bonus_Params>;
  /** fetch data from the table: "bonus_wheel_rewards" */
  bonus_wheel_rewards: Array<Bonus_Wheel_Rewards>;
  /** fetch aggregated fields from the table: "bonus_wheel_rewards" */
  bonus_wheel_rewards_aggregate: Bonus_Wheel_Rewards_Aggregate;
  /** fetch data from the table: "bonus_wheel_rewards" using primary key columns */
  bonus_wheel_rewards_by_pk?: Maybe<Bonus_Wheel_Rewards>;
  /** fetch data from the table: "bonus_wheel_ticket_type" */
  bonus_wheel_ticket_type: Array<Bonus_Wheel_Ticket_Type>;
  /** fetch aggregated fields from the table: "bonus_wheel_ticket_type" */
  bonus_wheel_ticket_type_aggregate: Bonus_Wheel_Ticket_Type_Aggregate;
  /** fetch data from the table: "bonus_wheel_ticket_type" using primary key columns */
  bonus_wheel_ticket_type_by_pk?: Maybe<Bonus_Wheel_Ticket_Type>;
  /** fetch data from the table: "bonus_wheel_ticket_type_name_localization" */
  bonus_wheel_ticket_type_name_localization: Array<Bonus_Wheel_Ticket_Type_Name_Localization>;
  /** fetch aggregated fields from the table: "bonus_wheel_ticket_type_name_localization" */
  bonus_wheel_ticket_type_name_localization_aggregate: Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate;
  /** fetch data from the table: "bonus_wheel_ticket_type_name_localization" using primary key columns */
  bonus_wheel_ticket_type_name_localization_by_pk?: Maybe<Bonus_Wheel_Ticket_Type_Name_Localization>;
  /** fetch data from the table: "bonus_wheel_user_rewards" */
  bonus_wheel_user_rewards: Array<Bonus_Wheel_User_Rewards>;
  /** fetch aggregated fields from the table: "bonus_wheel_user_rewards" */
  bonus_wheel_user_rewards_aggregate: Bonus_Wheel_User_Rewards_Aggregate;
  /** fetch data from the table: "bonus_wheel_user_rewards" using primary key columns */
  bonus_wheel_user_rewards_by_pk?: Maybe<Bonus_Wheel_User_Rewards>;
  /** fetch data from the table: "bonus_wheel_user_tickets" */
  bonus_wheel_user_tickets: Array<Bonus_Wheel_User_Tickets>;
  /** fetch aggregated fields from the table: "bonus_wheel_user_tickets" */
  bonus_wheel_user_tickets_aggregate: Bonus_Wheel_User_Tickets_Aggregate;
  /** fetch data from the table: "bonus_wheel_user_tickets" using primary key columns */
  bonus_wheel_user_tickets_by_pk?: Maybe<Bonus_Wheel_User_Tickets>;
  /** fetch data from the table: "bonus_wheels" */
  bonus_wheels: Array<Bonus_Wheels>;
  /** fetch aggregated fields from the table: "bonus_wheels" */
  bonus_wheels_aggregate: Bonus_Wheels_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonus_skins" */
  bonus_wheels_bonus_skins: Array<Bonus_Wheels_Bonus_Skins>;
  /** fetch aggregated fields from the table: "bonus_wheels_bonus_skins" */
  bonus_wheels_bonus_skins_aggregate: Bonus_Wheels_Bonus_Skins_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonus_skins" using primary key columns */
  bonus_wheels_bonus_skins_by_pk?: Maybe<Bonus_Wheels_Bonus_Skins>;
  /** fetch data from the table: "bonus_wheels_bonuses" */
  bonus_wheels_bonuses: Array<Bonus_Wheels_Bonuses>;
  /** fetch aggregated fields from the table: "bonus_wheels_bonuses" */
  bonus_wheels_bonuses_aggregate: Bonus_Wheels_Bonuses_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonuses" using primary key columns */
  bonus_wheels_bonuses_by_pk?: Maybe<Bonus_Wheels_Bonuses>;
  /** fetch data from the table: "bonus_wheels_bonuses_localization" */
  bonus_wheels_bonuses_localization: Array<Bonus_Wheels_Bonuses_Localization>;
  /** fetch aggregated fields from the table: "bonus_wheels_bonuses_localization" */
  bonus_wheels_bonuses_localization_aggregate: Bonus_Wheels_Bonuses_Localization_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonuses_localization" using primary key columns */
  bonus_wheels_bonuses_localization_by_pk?: Maybe<Bonus_Wheels_Bonuses_Localization>;
  /** fetch data from the table: "bonus_wheels_bonuses_params" */
  bonus_wheels_bonuses_params: Array<Bonus_Wheels_Bonuses_Params>;
  /** fetch aggregated fields from the table: "bonus_wheels_bonuses_params" */
  bonus_wheels_bonuses_params_aggregate: Bonus_Wheels_Bonuses_Params_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonuses_params" using primary key columns */
  bonus_wheels_bonuses_params_by_pk?: Maybe<Bonus_Wheels_Bonuses_Params>;
  /** fetch data from the table: "bonus_wheels_bonuses_params_localization" */
  bonus_wheels_bonuses_params_localization: Array<Bonus_Wheels_Bonuses_Params_Localization>;
  /** fetch aggregated fields from the table: "bonus_wheels_bonuses_params_localization" */
  bonus_wheels_bonuses_params_localization_aggregate: Bonus_Wheels_Bonuses_Params_Localization_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonuses_params_localization" using primary key columns */
  bonus_wheels_bonuses_params_localization_by_pk?: Maybe<Bonus_Wheels_Bonuses_Params_Localization>;
  /** fetch data from the table: "bonus_wheels" using primary key columns */
  bonus_wheels_by_pk?: Maybe<Bonus_Wheels>;
  /** fetch data from the table: "bonus_wheels_generator" */
  bonus_wheels_generator: Array<Bonus_Wheels_Generator>;
  /** fetch aggregated fields from the table: "bonus_wheels_generator" */
  bonus_wheels_generator_aggregate: Bonus_Wheels_Generator_Aggregate;
  /** fetch data from the table: "bonus_wheels_generator_bonuses" */
  bonus_wheels_generator_bonuses: Array<Bonus_Wheels_Generator_Bonuses>;
  /** fetch aggregated fields from the table: "bonus_wheels_generator_bonuses" */
  bonus_wheels_generator_bonuses_aggregate: Bonus_Wheels_Generator_Bonuses_Aggregate;
  /** fetch data from the table: "bonus_wheels_generator_bonuses" using primary key columns */
  bonus_wheels_generator_bonuses_by_pk?: Maybe<Bonus_Wheels_Generator_Bonuses>;
  /** fetch data from the table: "bonus_wheels_generator" using primary key columns */
  bonus_wheels_generator_by_pk?: Maybe<Bonus_Wheels_Generator>;
  /** fetch data from the table: "bonus_wheels_items_in_wheel" */
  bonus_wheels_items_in_wheel: Array<Bonus_Wheels_Items_In_Wheel>;
  /** fetch aggregated fields from the table: "bonus_wheels_items_in_wheel" */
  bonus_wheels_items_in_wheel_aggregate: Bonus_Wheels_Items_In_Wheel_Aggregate;
  /** fetch data from the table: "bonus_wheels_items_in_wheel" using primary key columns */
  bonus_wheels_items_in_wheel_by_pk?: Maybe<Bonus_Wheels_Items_In_Wheel>;
  /** fetch data from the table: "bonus_wheels_user_check" */
  bonus_wheels_user_check: Array<Bonus_Wheels_User_Check>;
  /** fetch aggregated fields from the table: "bonus_wheels_user_check" */
  bonus_wheels_user_check_aggregate: Bonus_Wheels_User_Check_Aggregate;
  /** fetch data from the table: "bonus_wheels_user_check" using primary key columns */
  bonus_wheels_user_check_by_pk?: Maybe<Bonus_Wheels_User_Check>;
  /** fetch data from the table: "bonus_wheels_user_check_params" */
  bonus_wheels_user_check_params: Array<Bonus_Wheels_User_Check_Params>;
  /** fetch aggregated fields from the table: "bonus_wheels_user_check_params" */
  bonus_wheels_user_check_params_aggregate: Bonus_Wheels_User_Check_Params_Aggregate;
  /** fetch data from the table: "bonus_wheels_user_check_params" using primary key columns */
  bonus_wheels_user_check_params_by_pk?: Maybe<Bonus_Wheels_User_Check_Params>;
  /** fetch data from the table: "bonus_wheels_user_offers" */
  bonus_wheels_user_offers: Array<Bonus_Wheels_User_Offers>;
  /** fetch aggregated fields from the table: "bonus_wheels_user_offers" */
  bonus_wheels_user_offers_aggregate: Bonus_Wheels_User_Offers_Aggregate;
  /** fetch data from the table: "bonus_wheels_user_offers" using primary key columns */
  bonus_wheels_user_offers_by_pk?: Maybe<Bonus_Wheels_User_Offers>;
  /** fetch data from the table: "bonus_wheels_user_offers_params" */
  bonus_wheels_user_offers_params: Array<Bonus_Wheels_User_Offers_Params>;
  /** fetch aggregated fields from the table: "bonus_wheels_user_offers_params" */
  bonus_wheels_user_offers_params_aggregate: Bonus_Wheels_User_Offers_Params_Aggregate;
  /** fetch data from the table: "bonus_wheels_user_offers_params" using primary key columns */
  bonus_wheels_user_offers_params_by_pk?: Maybe<Bonus_Wheels_User_Offers_Params>;
  /** fetch data from the table: "bonus_wheels_user_offers_params_localization" */
  bonus_wheels_user_offers_params_localization: Array<Bonus_Wheels_User_Offers_Params_Localization>;
  /** fetch aggregated fields from the table: "bonus_wheels_user_offers_params_localization" */
  bonus_wheels_user_offers_params_localization_aggregate: Bonus_Wheels_User_Offers_Params_Localization_Aggregate;
  /** fetch data from the table: "bonus_wheels_user_offers_params_localization" using primary key columns */
  bonus_wheels_user_offers_params_localization_by_pk?: Maybe<Bonus_Wheels_User_Offers_Params_Localization>;
  /** fetch data from the table: "bonuses" */
  bonuses: Array<Bonuses>;
  /** fetch aggregated fields from the table: "bonuses" */
  bonuses_aggregate: Bonuses_Aggregate;
  /** fetch data from the table: "bonuses" using primary key columns */
  bonuses_by_pk?: Maybe<Bonuses>;
  /** fetch data from the table: "bonuses_register" */
  bonuses_register: Array<Bonuses_Register>;
  /** fetch aggregated fields from the table: "bonuses_register" */
  bonuses_register_aggregate: Bonuses_Register_Aggregate;
  /** fetch data from the table: "bonuses_register" using primary key columns */
  bonuses_register_by_pk?: Maybe<Bonuses_Register>;
  /** fetch data from the table: "bonuses_register_offers" */
  bonuses_register_offers: Array<Bonuses_Register_Offers>;
  /** fetch aggregated fields from the table: "bonuses_register_offers" */
  bonuses_register_offers_aggregate: Bonuses_Register_Offers_Aggregate;
  /** fetch data from the table: "bonuses_register_offers" using primary key columns */
  bonuses_register_offers_by_pk?: Maybe<Bonuses_Register_Offers>;
  /** fetch data from the table: "boosters_set_content" */
  boosters_set_content: Array<Boosters_Set_Content>;
  /** fetch aggregated fields from the table: "boosters_set_content" */
  boosters_set_content_aggregate: Boosters_Set_Content_Aggregate;
  /** fetch data from the table: "boosters_set_content" using primary key columns */
  boosters_set_content_by_pk?: Maybe<Boosters_Set_Content>;
  /** fetch data from the table: "boosters_sets" */
  boosters_sets: Array<Boosters_Sets>;
  /** fetch aggregated fields from the table: "boosters_sets" */
  boosters_sets_aggregate: Boosters_Sets_Aggregate;
  /** fetch data from the table: "boosters_sets" using primary key columns */
  boosters_sets_by_pk?: Maybe<Boosters_Sets>;
  /** fetch data from the table: "bot_account_settings" */
  bot_account_settings: Array<Bot_Account_Settings>;
  /** fetch aggregated fields from the table: "bot_account_settings" */
  bot_account_settings_aggregate: Bot_Account_Settings_Aggregate;
  /** fetch data from the table: "bot_account_settings" using primary key columns */
  bot_account_settings_by_pk?: Maybe<Bot_Account_Settings>;
  /** fetch data from the table: "bots_lootboxes_opens_count" */
  bots_lootboxes_opens_count: Array<Bots_Lootboxes_Opens_Count>;
  /** fetch aggregated fields from the table: "bots_lootboxes_opens_count" */
  bots_lootboxes_opens_count_aggregate: Bots_Lootboxes_Opens_Count_Aggregate;
  /** fetch data from the table: "bots_lootboxes_opens_count" using primary key columns */
  bots_lootboxes_opens_count_by_pk?: Maybe<Bots_Lootboxes_Opens_Count>;
  /** fetch data from the table: "coins_set_content" */
  coins_set_content: Array<Coins_Set_Content>;
  /** fetch aggregated fields from the table: "coins_set_content" */
  coins_set_content_aggregate: Coins_Set_Content_Aggregate;
  /** fetch data from the table: "coins_set_content" using primary key columns */
  coins_set_content_by_pk?: Maybe<Coins_Set_Content>;
  /** fetch data from the table: "coins_sets" */
  coins_sets: Array<Coins_Sets>;
  /** fetch aggregated fields from the table: "coins_sets" */
  coins_sets_aggregate: Coins_Sets_Aggregate;
  /** fetch data from the table: "coins_sets" using primary key columns */
  coins_sets_by_pk?: Maybe<Coins_Sets>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "coupons" */
  coupons: Array<Coupons>;
  /** fetch aggregated fields from the table: "coupons" */
  coupons_aggregate: Coupons_Aggregate;
  /** fetch data from the table: "coupons" using primary key columns */
  coupons_by_pk?: Maybe<Coupons>;
  /** An array relationship */
  csgomarket_token_balance: Array<Csgomarket_Token_Balance>;
  /** An aggregate relationship */
  csgomarket_token_balance_aggregate: Csgomarket_Token_Balance_Aggregate;
  /** fetch data from the table: "csgomarket_token_balance" using primary key columns */
  csgomarket_token_balance_by_pk?: Maybe<Csgomarket_Token_Balance>;
  /** fetch data from the table: "csgomarket_tokens" */
  csgomarket_tokens: Array<Csgomarket_Tokens>;
  /** fetch aggregated fields from the table: "csgomarket_tokens" */
  csgomarket_tokens_aggregate: Csgomarket_Tokens_Aggregate;
  /** fetch data from the table: "csgomarket_tokens" using primary key columns */
  csgomarket_tokens_by_pk?: Maybe<Csgomarket_Tokens>;
  /** fetch data from the table: "currency_history" */
  currency_history: Array<Currency_History>;
  /** fetch aggregated fields from the table: "currency_history" */
  currency_history_aggregate: Currency_History_Aggregate;
  /** fetch data from the table: "currency_history" using primary key columns */
  currency_history_by_pk?: Maybe<Currency_History>;
  /** fetch data from the table: "currencys" */
  currencys: Array<Currencys>;
  /** fetch aggregated fields from the table: "currencys" */
  currencys_aggregate: Currencys_Aggregate;
  /** fetch data from the table: "currencys" using primary key columns */
  currencys_by_pk?: Maybe<Currencys>;
  /** fetch data from the table: "daily_deposit_sum" */
  daily_deposit_sum: Array<Daily_Deposit_Sum>;
  /** fetch aggregated fields from the table: "daily_deposit_sum" */
  daily_deposit_sum_aggregate: Daily_Deposit_Sum_Aggregate;
  /** fetch data from the table: "daily_deposit_sum" using primary key columns */
  daily_deposit_sum_by_pk?: Maybe<Daily_Deposit_Sum>;
  /** fetch data from the table: "emails_from_upgrade_question" */
  emails_from_upgrade_question: Array<Emails_From_Upgrade_Question>;
  /** fetch aggregated fields from the table: "emails_from_upgrade_question" */
  emails_from_upgrade_question_aggregate: Emails_From_Upgrade_Question_Aggregate;
  /** fetch data from the table: "emails_from_upgrade_question" using primary key columns */
  emails_from_upgrade_question_by_pk?: Maybe<Emails_From_Upgrade_Question>;
  /** fetch data from the table: "execution_methods" */
  execution_methods: Array<Execution_Methods>;
  /** fetch aggregated fields from the table: "execution_methods" */
  execution_methods_aggregate: Execution_Methods_Aggregate;
  /** fetch data from the table: "execution_methods" using primary key columns */
  execution_methods_by_pk?: Maybe<Execution_Methods>;
  /** fetch data from the table: "faq_table" */
  faq_table: Array<Faq_Table>;
  /** fetch aggregated fields from the table: "faq_table" */
  faq_table_aggregate: Faq_Table_Aggregate;
  /** fetch data from the table: "faq_table" using primary key columns */
  faq_table_by_pk?: Maybe<Faq_Table>;
  /** An array relationship */
  fixed_items_generator_boosters: Array<Fixed_Items_Generator_Boosters>;
  /** An aggregate relationship */
  fixed_items_generator_boosters_aggregate: Fixed_Items_Generator_Boosters_Aggregate;
  /** fetch data from the table: "fixed_items_generator_boosters" using primary key columns */
  fixed_items_generator_boosters_by_pk?: Maybe<Fixed_Items_Generator_Boosters>;
  /** An array relationship */
  fixed_items_generator_items: Array<Fixed_Items_Generator_Items>;
  /** An aggregate relationship */
  fixed_items_generator_items_aggregate: Fixed_Items_Generator_Items_Aggregate;
  /** fetch data from the table: "fixed_items_generator_items" using primary key columns */
  fixed_items_generator_items_by_pk?: Maybe<Fixed_Items_Generator_Items>;
  /** fetch data from the table: "fixed_items_generator_lootboxes" */
  fixed_items_generator_lootboxes: Array<Fixed_Items_Generator_Lootboxes>;
  /** fetch aggregated fields from the table: "fixed_items_generator_lootboxes" */
  fixed_items_generator_lootboxes_aggregate: Fixed_Items_Generator_Lootboxes_Aggregate;
  /** fetch data from the table: "fixed_items_generator_lootboxes" using primary key columns */
  fixed_items_generator_lootboxes_by_pk?: Maybe<Fixed_Items_Generator_Lootboxes>;
  get_item_output_status?: Maybe<OutputStatus>;
  /** execute function "get_ticket_counts_by_type" which returns "ticket_counts_result" */
  get_ticket_counts_by_type: Array<Ticket_Counts_Result>;
  /** execute function "get_ticket_counts_by_type" and query aggregates on result of table type "ticket_counts_result" */
  get_ticket_counts_by_type_aggregate: Ticket_Counts_Result_Aggregate;
  get_user_inventory_one?: Maybe<UserInventoryOutput>;
  get_user_profile?: Maybe<UserProfileOutput>;
  /** An array relationship */
  giveaway_members: Array<Giveaway_Members>;
  /** An aggregate relationship */
  giveaway_members_aggregate: Giveaway_Members_Aggregate;
  /** fetch data from the table: "giveaway_members" using primary key columns */
  giveaway_members_by_pk?: Maybe<Giveaway_Members>;
  /** fetch data from the table: "giveaway_members_rating" */
  giveaway_members_rating: Array<Giveaway_Members_Rating>;
  /** fetch aggregated fields from the table: "giveaway_members_rating" */
  giveaway_members_rating_aggregate: Giveaway_Members_Rating_Aggregate;
  /** An array relationship */
  giveaway_params: Array<Giveaway_Params>;
  /** An aggregate relationship */
  giveaway_params_aggregate: Giveaway_Params_Aggregate;
  /** fetch data from the table: "giveaway_params" using primary key columns */
  giveaway_params_by_pk?: Maybe<Giveaway_Params>;
  /** fetch data from the table: "giveaways" */
  giveaways: Array<Giveaways>;
  /** fetch aggregated fields from the table: "giveaways" */
  giveaways_aggregate: Giveaways_Aggregate;
  /** fetch data from the table: "giveaways" using primary key columns */
  giveaways_by_pk?: Maybe<Giveaways>;
  /** fetch data from the table: "group_items_csgomarket" */
  group_items_csgomarket: Array<Group_Items_Csgomarket>;
  /** fetch aggregated fields from the table: "group_items_csgomarket" */
  group_items_csgomarket_aggregate: Group_Items_Csgomarket_Aggregate;
  /** fetch data from the table: "group_items_csgomarket" using primary key columns */
  group_items_csgomarket_by_pk?: Maybe<Group_Items_Csgomarket>;
  /** fetch data from the table: "history_group_items_csgomarket" */
  history_group_items_csgomarket: Array<History_Group_Items_Csgomarket>;
  /** fetch aggregated fields from the table: "history_group_items_csgomarket" */
  history_group_items_csgomarket_aggregate: History_Group_Items_Csgomarket_Aggregate;
  /** fetch data from the table: "history_group_items_csgomarket" using primary key columns */
  history_group_items_csgomarket_by_pk?: Maybe<History_Group_Items_Csgomarket>;
  /** fetch data from the table: "history_items_csgomarket" */
  history_items_csgomarket: Array<History_Items_Csgomarket>;
  /** fetch aggregated fields from the table: "history_items_csgomarket" */
  history_items_csgomarket_aggregate: History_Items_Csgomarket_Aggregate;
  /** fetch data from the table: "history_items_csgomarket" using primary key columns */
  history_items_csgomarket_by_pk?: Maybe<History_Items_Csgomarket>;
  /** fetch data from the table: "item_out_transactions" */
  item_out_transactions: Array<Item_Out_Transactions>;
  /** fetch aggregated fields from the table: "item_out_transactions" */
  item_out_transactions_aggregate: Item_Out_Transactions_Aggregate;
  /** fetch data from the table: "item_out_transactions" using primary key columns */
  item_out_transactions_by_pk?: Maybe<Item_Out_Transactions>;
  /** fetch data from the table: "items" */
  items: Array<Items>;
  /** fetch aggregated fields from the table: "items" */
  items_aggregate: Items_Aggregate;
  /** fetch data from the table: "items" using primary key columns */
  items_by_pk?: Maybe<Items>;
  /** fetch data from the table: "items_csgomarket" */
  items_csgomarket: Array<Items_Csgomarket>;
  /** fetch aggregated fields from the table: "items_csgomarket" */
  items_csgomarket_aggregate: Items_Csgomarket_Aggregate;
  /** fetch data from the table: "items_csgomarket" using primary key columns */
  items_csgomarket_by_pk?: Maybe<Items_Csgomarket>;
  /** fetch data from the table: "items_drops" */
  items_drops: Array<Items_Drops>;
  /** fetch aggregated fields from the table: "items_drops" */
  items_drops_aggregate: Items_Drops_Aggregate;
  /** fetch data from the table: "items_drops" using primary key columns */
  items_drops_by_pk?: Maybe<Items_Drops>;
  /** fetch data from the table: "items_for_upgrade" */
  items_for_upgrade: Array<Items_For_Upgrade>;
  /** fetch aggregated fields from the table: "items_for_upgrade" */
  items_for_upgrade_aggregate: Items_For_Upgrade_Aggregate;
  /** fetch data from the table: "items_for_upgrade" using primary key columns */
  items_for_upgrade_by_pk?: Maybe<Items_For_Upgrade>;
  /** fetch data from the table: "items_history" */
  items_history: Array<Items_History>;
  /** fetch aggregated fields from the table: "items_history" */
  items_history_aggregate: Items_History_Aggregate;
  /** fetch data from the table: "items_history" using primary key columns */
  items_history_by_pk?: Maybe<Items_History>;
  /** fetch data from the table: "items_in_lootbox" */
  items_in_lootbox: Array<Items_In_Lootbox>;
  /** fetch aggregated fields from the table: "items_in_lootbox" */
  items_in_lootbox_aggregate: Items_In_Lootbox_Aggregate;
  /** fetch data from the table: "items_in_lootbox" using primary key columns */
  items_in_lootbox_by_pk?: Maybe<Items_In_Lootbox>;
  /** fetch data from the table: "items_in_lootbox_view" */
  items_in_lootbox_view: Array<Items_In_Lootbox_View>;
  /** fetch aggregated fields from the table: "items_in_lootbox_view" */
  items_in_lootbox_view_aggregate: Items_In_Lootbox_View_Aggregate;
  /** fetch data from the table: "items_in_lootbox_view" using primary key columns */
  items_in_lootbox_view_by_pk?: Maybe<Items_In_Lootbox_View>;
  /** fetch data from the table: "items_items_names" */
  items_items_names: Array<Items_Items_Names>;
  /** fetch aggregated fields from the table: "items_items_names" */
  items_items_names_aggregate: Items_Items_Names_Aggregate;
  /** fetch data from the table: "items_items_names" using primary key columns */
  items_items_names_by_pk?: Maybe<Items_Items_Names>;
  /** fetch data from the table: "items_localization" */
  items_localization: Array<Items_Localization>;
  /** fetch aggregated fields from the table: "items_localization" */
  items_localization_aggregate: Items_Localization_Aggregate;
  /** fetch data from the table: "items_localization" using primary key columns */
  items_localization_by_pk?: Maybe<Items_Localization>;
  /** fetch data from the table: "items_names" */
  items_names: Array<Items_Names>;
  /** fetch aggregated fields from the table: "items_names" */
  items_names_aggregate: Items_Names_Aggregate;
  /** fetch data from the table: "items_names" using primary key columns */
  items_names_by_pk?: Maybe<Items_Names>;
  /** fetch data from the table: "items_quality" */
  items_quality: Array<Items_Quality>;
  /** fetch aggregated fields from the table: "items_quality" */
  items_quality_aggregate: Items_Quality_Aggregate;
  /** fetch data from the table: "items_quality" using primary key columns */
  items_quality_by_pk?: Maybe<Items_Quality>;
  /** fetch data from the table: "items_quality_localization" */
  items_quality_localization: Array<Items_Quality_Localization>;
  /** fetch aggregated fields from the table: "items_quality_localization" */
  items_quality_localization_aggregate: Items_Quality_Localization_Aggregate;
  /** fetch data from the table: "items_quality_localization" using primary key columns */
  items_quality_localization_by_pk?: Maybe<Items_Quality_Localization>;
  /** fetch data from the table: "items_rarity" */
  items_rarity: Array<Items_Rarity>;
  /** fetch aggregated fields from the table: "items_rarity" */
  items_rarity_aggregate: Items_Rarity_Aggregate;
  /** fetch data from the table: "items_rarity" using primary key columns */
  items_rarity_by_pk?: Maybe<Items_Rarity>;
  /** fetch data from the table: "items_rarity_localization" */
  items_rarity_localization: Array<Items_Rarity_Localization>;
  /** fetch aggregated fields from the table: "items_rarity_localization" */
  items_rarity_localization_aggregate: Items_Rarity_Localization_Aggregate;
  /** fetch data from the table: "items_rarity_localization" using primary key columns */
  items_rarity_localization_by_pk?: Maybe<Items_Rarity_Localization>;
  /** fetch data from the table: "items_type" */
  items_type: Array<Items_Type>;
  /** fetch aggregated fields from the table: "items_type" */
  items_type_aggregate: Items_Type_Aggregate;
  /** fetch data from the table: "items_type" using primary key columns */
  items_type_by_pk?: Maybe<Items_Type>;
  /** fetch data from the table: "items_type_localization" */
  items_type_localization: Array<Items_Type_Localization>;
  /** fetch aggregated fields from the table: "items_type_localization" */
  items_type_localization_aggregate: Items_Type_Localization_Aggregate;
  /** fetch data from the table: "items_type_localization" using primary key columns */
  items_type_localization_by_pk?: Maybe<Items_Type_Localization>;
  /** fetch data from the table: "items_weapon_localization" */
  items_weapon_localization: Array<Items_Weapon_Localization>;
  /** fetch aggregated fields from the table: "items_weapon_localization" */
  items_weapon_localization_aggregate: Items_Weapon_Localization_Aggregate;
  /** fetch data from the table: "items_weapon_localization" using primary key columns */
  items_weapon_localization_by_pk?: Maybe<Items_Weapon_Localization>;
  /** fetch data from the table: "items_weapons" */
  items_weapons: Array<Items_Weapons>;
  /** fetch aggregated fields from the table: "items_weapons" */
  items_weapons_aggregate: Items_Weapons_Aggregate;
  /** fetch data from the table: "items_weapons" using primary key columns */
  items_weapons_by_pk?: Maybe<Items_Weapons>;
  /** execute function "list_items_random" which returns "items" */
  list_items_random: Array<Items>;
  /** execute function "list_items_random" and query aggregates on result of table type "items" */
  list_items_random_aggregate: Items_Aggregate;
  /** fetch data from the table: "lootbox_booster_items" */
  lootbox_booster_items: Array<Lootbox_Booster_Items>;
  /** fetch aggregated fields from the table: "lootbox_booster_items" */
  lootbox_booster_items_aggregate: Lootbox_Booster_Items_Aggregate;
  /** fetch data from the table: "lootbox_booster_items" using primary key columns */
  lootbox_booster_items_by_pk?: Maybe<Lootbox_Booster_Items>;
  /** fetch data from the table: "lootbox_booster_localization" */
  lootbox_booster_localization: Array<Lootbox_Booster_Localization>;
  /** fetch aggregated fields from the table: "lootbox_booster_localization" */
  lootbox_booster_localization_aggregate: Lootbox_Booster_Localization_Aggregate;
  /** fetch data from the table: "lootbox_booster_localization" using primary key columns */
  lootbox_booster_localization_by_pk?: Maybe<Lootbox_Booster_Localization>;
  /** fetch data from the table: "lootbox_category" */
  lootbox_category: Array<Lootbox_Category>;
  /** fetch aggregated fields from the table: "lootbox_category" */
  lootbox_category_aggregate: Lootbox_Category_Aggregate;
  /** fetch data from the table: "lootbox_category" using primary key columns */
  lootbox_category_by_pk?: Maybe<Lootbox_Category>;
  /** An array relationship */
  lootbox_items_sets: Array<Lootbox_Items_Sets>;
  /** An aggregate relationship */
  lootbox_items_sets_aggregate: Lootbox_Items_Sets_Aggregate;
  /** fetch data from the table: "lootbox_items_sets" using primary key columns */
  lootbox_items_sets_by_pk?: Maybe<Lootbox_Items_Sets>;
  /** fetch data from the table: "lootbox_service" */
  lootbox_service: Array<Lootbox_Service>;
  /** fetch aggregated fields from the table: "lootbox_service" */
  lootbox_service_aggregate: Lootbox_Service_Aggregate;
  /** fetch data from the table: "lootbox_service" using primary key columns */
  lootbox_service_by_pk?: Maybe<Lootbox_Service>;
  /** An array relationship */
  lootboxes: Array<Lootboxes>;
  /** An aggregate relationship */
  lootboxes_aggregate: Lootboxes_Aggregate;
  /** fetch data from the table: "lootboxes_available_statuses" */
  lootboxes_available_statuses: Array<Lootboxes_Available_Statuses>;
  /** fetch aggregated fields from the table: "lootboxes_available_statuses" */
  lootboxes_available_statuses_aggregate: Lootboxes_Available_Statuses_Aggregate;
  /** fetch data from the table: "lootboxes_available_statuses" using primary key columns */
  lootboxes_available_statuses_by_pk?: Maybe<Lootboxes_Available_Statuses>;
  /** fetch data from the table: "lootboxes_bonus" */
  lootboxes_bonus: Array<Lootboxes_Bonus>;
  /** fetch aggregated fields from the table: "lootboxes_bonus" */
  lootboxes_bonus_aggregate: Lootboxes_Bonus_Aggregate;
  /** fetch data from the table: "lootboxes_bonus" using primary key columns */
  lootboxes_bonus_by_pk?: Maybe<Lootboxes_Bonus>;
  /** fetch data from the table: "lootboxes_bonus_generator_items" */
  lootboxes_bonus_generator_items: Array<Lootboxes_Bonus_Generator_Items>;
  /** fetch aggregated fields from the table: "lootboxes_bonus_generator_items" */
  lootboxes_bonus_generator_items_aggregate: Lootboxes_Bonus_Generator_Items_Aggregate;
  /** fetch data from the table: "lootboxes_bonus_generator_items" using primary key columns */
  lootboxes_bonus_generator_items_by_pk?: Maybe<Lootboxes_Bonus_Generator_Items>;
  /** fetch data from the table: "lootboxes_bonus_generator_params" */
  lootboxes_bonus_generator_params: Array<Lootboxes_Bonus_Generator_Params>;
  /** fetch aggregated fields from the table: "lootboxes_bonus_generator_params" */
  lootboxes_bonus_generator_params_aggregate: Lootboxes_Bonus_Generator_Params_Aggregate;
  /** fetch data from the table: "lootboxes_bonus_generator_params" using primary key columns */
  lootboxes_bonus_generator_params_by_pk?: Maybe<Lootboxes_Bonus_Generator_Params>;
  /** fetch data from the table: "lootboxes_bonus_items" */
  lootboxes_bonus_items: Array<Lootboxes_Bonus_Items>;
  /** fetch aggregated fields from the table: "lootboxes_bonus_items" */
  lootboxes_bonus_items_aggregate: Lootboxes_Bonus_Items_Aggregate;
  /** fetch data from the table: "lootboxes_bonus_items" using primary key columns */
  lootboxes_bonus_items_by_pk?: Maybe<Lootboxes_Bonus_Items>;
  /** fetch data from the table: "lootboxes_bonus_views" */
  lootboxes_bonus_views: Array<Lootboxes_Bonus_Views>;
  /** fetch aggregated fields from the table: "lootboxes_bonus_views" */
  lootboxes_bonus_views_aggregate: Lootboxes_Bonus_Views_Aggregate;
  /** fetch data from the table: "lootboxes_bonus_views" using primary key columns */
  lootboxes_bonus_views_by_pk?: Maybe<Lootboxes_Bonus_Views>;
  /** fetch data from the table: "lootboxes" using primary key columns */
  lootboxes_by_pk?: Maybe<Lootboxes>;
  /** fetch data from the table: "lootboxes_dynamic_category" */
  lootboxes_dynamic_category: Array<Lootboxes_Dynamic_Category>;
  /** fetch aggregated fields from the table: "lootboxes_dynamic_category" */
  lootboxes_dynamic_category_aggregate: Lootboxes_Dynamic_Category_Aggregate;
  /** fetch data from the table: "lootboxes_dynamic_category" using primary key columns */
  lootboxes_dynamic_category_by_pk?: Maybe<Lootboxes_Dynamic_Category>;
  /** An array relationship */
  lootboxes_dynamic_category_lootboxes: Array<Lootboxes_Dynamic_Category_Lootboxes>;
  /** An aggregate relationship */
  lootboxes_dynamic_category_lootboxes_aggregate: Lootboxes_Dynamic_Category_Lootboxes_Aggregate;
  /** fetch data from the table: "lootboxes_dynamic_category_lootboxes" using primary key columns */
  lootboxes_dynamic_category_lootboxes_by_pk?: Maybe<Lootboxes_Dynamic_Category_Lootboxes>;
  /** fetch data from the table: "lootboxes_free" */
  lootboxes_free: Array<Lootboxes_Free>;
  /** fetch aggregated fields from the table: "lootboxes_free" */
  lootboxes_free_aggregate: Lootboxes_Free_Aggregate;
  /** fetch data from the table: "lootboxes_free" using primary key columns */
  lootboxes_free_by_pk?: Maybe<Lootboxes_Free>;
  /** fetch data from the table: "lootboxes_free_category" */
  lootboxes_free_category: Array<Lootboxes_Free_Category>;
  /** fetch aggregated fields from the table: "lootboxes_free_category" */
  lootboxes_free_category_aggregate: Lootboxes_Free_Category_Aggregate;
  /** fetch data from the table: "lootboxes_free_category" using primary key columns */
  lootboxes_free_category_by_pk?: Maybe<Lootboxes_Free_Category>;
  /** fetch data from the table: "lootboxes_free_generator_items" */
  lootboxes_free_generator_items: Array<Lootboxes_Free_Generator_Items>;
  /** fetch aggregated fields from the table: "lootboxes_free_generator_items" */
  lootboxes_free_generator_items_aggregate: Lootboxes_Free_Generator_Items_Aggregate;
  /** fetch data from the table: "lootboxes_free_generator_items" using primary key columns */
  lootboxes_free_generator_items_by_pk?: Maybe<Lootboxes_Free_Generator_Items>;
  /** fetch data from the table: "lootboxes_free_generator_params" */
  lootboxes_free_generator_params: Array<Lootboxes_Free_Generator_Params>;
  /** fetch aggregated fields from the table: "lootboxes_free_generator_params" */
  lootboxes_free_generator_params_aggregate: Lootboxes_Free_Generator_Params_Aggregate;
  /** fetch data from the table: "lootboxes_free_generator_params" using primary key columns */
  lootboxes_free_generator_params_by_pk?: Maybe<Lootboxes_Free_Generator_Params>;
  /** fetch data from the table: "lootboxes_free_items" */
  lootboxes_free_items: Array<Lootboxes_Free_Items>;
  /** fetch aggregated fields from the table: "lootboxes_free_items" */
  lootboxes_free_items_aggregate: Lootboxes_Free_Items_Aggregate;
  /** fetch data from the table: "lootboxes_free_items" using primary key columns */
  lootboxes_free_items_by_pk?: Maybe<Lootboxes_Free_Items>;
  /** fetch data from the table: "lootboxes_free_views" */
  lootboxes_free_views: Array<Lootboxes_Free_Views>;
  /** fetch aggregated fields from the table: "lootboxes_free_views" */
  lootboxes_free_views_aggregate: Lootboxes_Free_Views_Aggregate;
  /** fetch data from the table: "lootboxes_free_views" using primary key columns */
  lootboxes_free_views_by_pk?: Maybe<Lootboxes_Free_Views>;
  /** fetch data from the table: "lootboxes_generator_mechanics" */
  lootboxes_generator_mechanics: Array<Lootboxes_Generator_Mechanics>;
  /** fetch aggregated fields from the table: "lootboxes_generator_mechanics" */
  lootboxes_generator_mechanics_aggregate: Lootboxes_Generator_Mechanics_Aggregate;
  /** fetch data from the table: "lootboxes_generator_mechanics" using primary key columns */
  lootboxes_generator_mechanics_by_pk?: Maybe<Lootboxes_Generator_Mechanics>;
  /** fetch data from the table: "lootboxes_generator_params" */
  lootboxes_generator_params: Array<Lootboxes_Generator_Params>;
  /** fetch aggregated fields from the table: "lootboxes_generator_params" */
  lootboxes_generator_params_aggregate: Lootboxes_Generator_Params_Aggregate;
  /** fetch data from the table: "lootboxes_generator_params" using primary key columns */
  lootboxes_generator_params_by_pk?: Maybe<Lootboxes_Generator_Params>;
  /** fetch data from the table: "lootboxes_history" */
  lootboxes_history: Array<Lootboxes_History>;
  /** fetch aggregated fields from the table: "lootboxes_history" */
  lootboxes_history_aggregate: Lootboxes_History_Aggregate;
  /** fetch data from the table: "lootboxes_history" using primary key columns */
  lootboxes_history_by_pk?: Maybe<Lootboxes_History>;
  /** fetch data from the table: "lootboxes_items_names" */
  lootboxes_items_names: Array<Lootboxes_Items_Names>;
  /** fetch aggregated fields from the table: "lootboxes_items_names" */
  lootboxes_items_names_aggregate: Lootboxes_Items_Names_Aggregate;
  /** fetch data from the table: "lootboxes_items_names" using primary key columns */
  lootboxes_items_names_by_pk?: Maybe<Lootboxes_Items_Names>;
  /** fetch data from the table: "lootboxes_top_drop" */
  lootboxes_top_drop: Array<Lootboxes_Top_Drop>;
  /** fetch aggregated fields from the table: "lootboxes_top_drop" */
  lootboxes_top_drop_aggregate: Lootboxes_Top_Drop_Aggregate;
  /** fetch data from the table: "lootboxes_top_drop" using primary key columns */
  lootboxes_top_drop_by_pk?: Maybe<Lootboxes_Top_Drop>;
  /** fetch data from the table: "mail_autosending_types" */
  mail_autosending_types: Array<Mail_Autosending_Types>;
  /** fetch aggregated fields from the table: "mail_autosending_types" */
  mail_autosending_types_aggregate: Mail_Autosending_Types_Aggregate;
  /** fetch data from the table: "mail_autosending_types" using primary key columns */
  mail_autosending_types_by_pk?: Maybe<Mail_Autosending_Types>;
  /** fetch data from the table: "mail_bonuses" */
  mail_bonuses: Array<Mail_Bonuses>;
  /** fetch aggregated fields from the table: "mail_bonuses" */
  mail_bonuses_aggregate: Mail_Bonuses_Aggregate;
  /** fetch data from the table: "mail_bonuses" using primary key columns */
  mail_bonuses_by_pk?: Maybe<Mail_Bonuses>;
  /** An array relationship */
  mail_bonuses_offers: Array<Mail_Bonuses_Offers>;
  /** An aggregate relationship */
  mail_bonuses_offers_aggregate: Mail_Bonuses_Offers_Aggregate;
  /** fetch data from the table: "mail_bonuses_offers" using primary key columns */
  mail_bonuses_offers_by_pk?: Maybe<Mail_Bonuses_Offers>;
  /** fetch data from the table: "mail_mailing_callbacks" */
  mail_mailing_callbacks: Array<Mail_Mailing_Callbacks>;
  /** fetch aggregated fields from the table: "mail_mailing_callbacks" */
  mail_mailing_callbacks_aggregate: Mail_Mailing_Callbacks_Aggregate;
  /** fetch data from the table: "mail_mailing_callbacks" using primary key columns */
  mail_mailing_callbacks_by_pk?: Maybe<Mail_Mailing_Callbacks>;
  /** fetch data from the table: "mail_mailing_lines" */
  mail_mailing_lines: Array<Mail_Mailing_Lines>;
  /** fetch aggregated fields from the table: "mail_mailing_lines" */
  mail_mailing_lines_aggregate: Mail_Mailing_Lines_Aggregate;
  /** fetch data from the table: "mail_mailing_lines" using primary key columns */
  mail_mailing_lines_by_pk?: Maybe<Mail_Mailing_Lines>;
  /** fetch data from the table: "mail_mailing_lists" */
  mail_mailing_lists: Array<Mail_Mailing_Lists>;
  /** fetch aggregated fields from the table: "mail_mailing_lists" */
  mail_mailing_lists_aggregate: Mail_Mailing_Lists_Aggregate;
  /** fetch data from the table: "mail_mailing_lists" using primary key columns */
  mail_mailing_lists_by_pk?: Maybe<Mail_Mailing_Lists>;
  /** fetch data from the table: "mail_mailing_scheduling" */
  mail_mailing_scheduling: Array<Mail_Mailing_Scheduling>;
  /** fetch aggregated fields from the table: "mail_mailing_scheduling" */
  mail_mailing_scheduling_aggregate: Mail_Mailing_Scheduling_Aggregate;
  /** fetch data from the table: "mail_mailing_scheduling" using primary key columns */
  mail_mailing_scheduling_by_pk?: Maybe<Mail_Mailing_Scheduling>;
  /** fetch data from the table: "mail_mailing_sending_log" */
  mail_mailing_sending_log: Array<Mail_Mailing_Sending_Log>;
  /** fetch aggregated fields from the table: "mail_mailing_sending_log" */
  mail_mailing_sending_log_aggregate: Mail_Mailing_Sending_Log_Aggregate;
  /** fetch data from the table: "mail_mailing_sending_log" using primary key columns */
  mail_mailing_sending_log_by_pk?: Maybe<Mail_Mailing_Sending_Log>;
  /** fetch data from the table: "mail_mailing_triggers" */
  mail_mailing_triggers: Array<Mail_Mailing_Triggers>;
  /** fetch aggregated fields from the table: "mail_mailing_triggers" */
  mail_mailing_triggers_aggregate: Mail_Mailing_Triggers_Aggregate;
  /** fetch data from the table: "mail_mailing_triggers" using primary key columns */
  mail_mailing_triggers_by_pk?: Maybe<Mail_Mailing_Triggers>;
  /** An array relationship */
  mail_mailing_triggers_groups: Array<Mail_Mailing_Triggers_Groups>;
  /** An aggregate relationship */
  mail_mailing_triggers_groups_aggregate: Mail_Mailing_Triggers_Groups_Aggregate;
  /** fetch data from the table: "mail_mailing_triggers_groups" using primary key columns */
  mail_mailing_triggers_groups_by_pk?: Maybe<Mail_Mailing_Triggers_Groups>;
  /** fetch data from the table: "mail_templates" */
  mail_templates: Array<Mail_Templates>;
  /** fetch aggregated fields from the table: "mail_templates" */
  mail_templates_aggregate: Mail_Templates_Aggregate;
  /** fetch data from the table: "mail_templates" using primary key columns */
  mail_templates_by_pk?: Maybe<Mail_Templates>;
  /** fetch data from the table: "mainpage_counters" */
  mainpage_counters: Array<Mainpage_Counters>;
  /** fetch aggregated fields from the table: "mainpage_counters" */
  mainpage_counters_aggregate: Mainpage_Counters_Aggregate;
  /** fetch data from the table: "mainpage_counters" using primary key columns */
  mainpage_counters_by_pk?: Maybe<Mainpage_Counters>;
  /** fetch data from the table: "maintenance_mode" */
  maintenance_mode: Array<Maintenance_Mode>;
  /** fetch aggregated fields from the table: "maintenance_mode" */
  maintenance_mode_aggregate: Maintenance_Mode_Aggregate;
  /** fetch data from the table: "maintenance_mode" using primary key columns */
  maintenance_mode_by_pk?: Maybe<Maintenance_Mode>;
  /** fetch data from the table: "market_groups_conn" */
  market_groups_conn: Array<Market_Groups_Conn>;
  /** fetch aggregated fields from the table: "market_groups_conn" */
  market_groups_conn_aggregate: Market_Groups_Conn_Aggregate;
  /** fetch data from the table: "market_groups_conn" using primary key columns */
  market_groups_conn_by_pk?: Maybe<Market_Groups_Conn>;
  /** fetch data from the table: "metas" */
  metas: Array<Metas>;
  /** fetch aggregated fields from the table: "metas" */
  metas_aggregate: Metas_Aggregate;
  /** fetch data from the table: "metas" using primary key columns */
  metas_by_pk?: Maybe<Metas>;
  /** fetch data from the table: "missions" */
  missions: Array<Missions>;
  /** fetch aggregated fields from the table: "missions" */
  missions_aggregate: Missions_Aggregate;
  /** fetch data from the table: "missions" using primary key columns */
  missions_by_pk?: Maybe<Missions>;
  /** fetch data from the table: "missions_onetime_bonus_case_claims" */
  missions_onetime_bonus_case_claims: Array<Missions_Onetime_Bonus_Case_Claims>;
  /** fetch aggregated fields from the table: "missions_onetime_bonus_case_claims" */
  missions_onetime_bonus_case_claims_aggregate: Missions_Onetime_Bonus_Case_Claims_Aggregate;
  /** fetch data from the table: "missions_onetime_bonus_case_claims" using primary key columns */
  missions_onetime_bonus_case_claims_by_pk?: Maybe<Missions_Onetime_Bonus_Case_Claims>;
  /** fetch data from the table: "missions_onetime_params" */
  missions_onetime_params: Array<Missions_Onetime_Params>;
  /** fetch aggregated fields from the table: "missions_onetime_params" */
  missions_onetime_params_aggregate: Missions_Onetime_Params_Aggregate;
  /** fetch data from the table: "missions_onetime_params" using primary key columns */
  missions_onetime_params_by_pk?: Maybe<Missions_Onetime_Params>;
  /** An array relationship */
  missions_onetime_users_progress: Array<Missions_Onetime_Users_Progress>;
  /** An aggregate relationship */
  missions_onetime_users_progress_aggregate: Missions_Onetime_Users_Progress_Aggregate;
  /** fetch data from the table: "missions_onetime_users_progress" using primary key columns */
  missions_onetime_users_progress_by_pk?: Maybe<Missions_Onetime_Users_Progress>;
  /** fetch data from the table: "missions_permanent_params" */
  missions_permanent_params: Array<Missions_Permanent_Params>;
  /** fetch aggregated fields from the table: "missions_permanent_params" */
  missions_permanent_params_aggregate: Missions_Permanent_Params_Aggregate;
  /** fetch data from the table: "missions_permanent_params" using primary key columns */
  missions_permanent_params_by_pk?: Maybe<Missions_Permanent_Params>;
  /** fetch data from the table: "missions_permanent_user_progress" */
  missions_permanent_user_progress: Array<Missions_Permanent_User_Progress>;
  /** fetch aggregated fields from the table: "missions_permanent_user_progress" */
  missions_permanent_user_progress_aggregate: Missions_Permanent_User_Progress_Aggregate;
  /** fetch data from the table: "missions_permanent_user_progress" using primary key columns */
  missions_permanent_user_progress_by_pk?: Maybe<Missions_Permanent_User_Progress>;
  /** An array relationship */
  missions_tags: Array<Missions_Tags>;
  /** An aggregate relationship */
  missions_tags_aggregate: Missions_Tags_Aggregate;
  /** fetch data from the table: "missions_tags" using primary key columns */
  missions_tags_by_pk?: Maybe<Missions_Tags>;
  /** fetch data from the table: "missions_tags_names" */
  missions_tags_names: Array<Missions_Tags_Names>;
  /** fetch aggregated fields from the table: "missions_tags_names" */
  missions_tags_names_aggregate: Missions_Tags_Names_Aggregate;
  /** fetch data from the table: "missions_tags_names" using primary key columns */
  missions_tags_names_by_pk?: Maybe<Missions_Tags_Names>;
  /** fetch data from the table: "missions_vk_links" */
  missions_vk_links: Array<Missions_Vk_Links>;
  /** fetch aggregated fields from the table: "missions_vk_links" */
  missions_vk_links_aggregate: Missions_Vk_Links_Aggregate;
  /** fetch data from the table: "missions_vk_links" using primary key columns */
  missions_vk_links_by_pk?: Maybe<Missions_Vk_Links>;
  /** fetch data from the table: "new_year_competition_boosters_rewards" */
  new_year_competition_boosters_rewards: Array<New_Year_Competition_Boosters_Rewards>;
  /** fetch aggregated fields from the table: "new_year_competition_boosters_rewards" */
  new_year_competition_boosters_rewards_aggregate: New_Year_Competition_Boosters_Rewards_Aggregate;
  /** fetch data from the table: "new_year_competition_boosters_rewards" using primary key columns */
  new_year_competition_boosters_rewards_by_pk?: Maybe<New_Year_Competition_Boosters_Rewards>;
  /** An array relationship */
  new_year_competition_participants: Array<New_Year_Competition_Participants>;
  /** An aggregate relationship */
  new_year_competition_participants_aggregate: New_Year_Competition_Participants_Aggregate;
  /** fetch data from the table: "new_year_competition_participants" using primary key columns */
  new_year_competition_participants_by_pk?: Maybe<New_Year_Competition_Participants>;
  /** fetch data from the table: "new_year_competition_participants_performance" */
  new_year_competition_participants_performance: Array<New_Year_Competition_Participants_Performance>;
  /** fetch data from the table: "new_year_competition_participants_performance" using primary key columns */
  new_year_competition_participants_performance_by_pk?: Maybe<New_Year_Competition_Participants_Performance>;
  /** fetch data from the table: "new_year_competition_participants_rating" */
  new_year_competition_participants_rating: Array<New_Year_Competition_Participants_Rating>;
  /** fetch aggregated fields from the table: "new_year_competition_participants_rating" */
  new_year_competition_participants_rating_aggregate: New_Year_Competition_Participants_Rating_Aggregate;
  /** fetch data from the table: "new_year_competition_teams" */
  new_year_competition_teams: Array<New_Year_Competition_Teams>;
  /** fetch aggregated fields from the table: "new_year_competition_teams" */
  new_year_competition_teams_aggregate: New_Year_Competition_Teams_Aggregate;
  /** fetch data from the table: "new_year_competition_teams" using primary key columns */
  new_year_competition_teams_by_pk?: Maybe<New_Year_Competition_Teams>;
  /** fetch data from the table: "new_year_competition_winner_team" */
  new_year_competition_winner_team: Array<New_Year_Competition_Winner_Team>;
  /** fetch aggregated fields from the table: "new_year_competition_winner_team" */
  new_year_competition_winner_team_aggregate: New_Year_Competition_Winner_Team_Aggregate;
  /** fetch data from the table: "operation_types" */
  operation_types: Array<Operation_Types>;
  /** fetch aggregated fields from the table: "operation_types" */
  operation_types_aggregate: Operation_Types_Aggregate;
  /** fetch data from the table: "operation_types" using primary key columns */
  operation_types_by_pk?: Maybe<Operation_Types>;
  /** fetch data from the table: "parsers_timestamp_log" */
  parsers_timestamp_log: Array<Parsers_Timestamp_Log>;
  /** fetch aggregated fields from the table: "parsers_timestamp_log" */
  parsers_timestamp_log_aggregate: Parsers_Timestamp_Log_Aggregate;
  /** fetch data from the table: "parsers_timestamp_log" using primary key columns */
  parsers_timestamp_log_by_pk?: Maybe<Parsers_Timestamp_Log>;
  /** fetch aggregated fields from the table: "new_year_competition_participants_performance" */
  participants_performance_aggregate: New_Year_Competition_Participants_Performance_Aggregate;
  /** An array relationship */
  partner_balance_transactions: Array<Partner_Balance_Transactions>;
  /** An aggregate relationship */
  partner_balance_transactions_aggregate: Partner_Balance_Transactions_Aggregate;
  /** fetch data from the table: "partner_balance_transactions" using primary key columns */
  partner_balance_transactions_by_pk?: Maybe<Partner_Balance_Transactions>;
  /** fetch data from the table: "partner_offer_types" */
  partner_offer_types: Array<Partner_Offer_Types>;
  /** fetch aggregated fields from the table: "partner_offer_types" */
  partner_offer_types_aggregate: Partner_Offer_Types_Aggregate;
  /** fetch data from the table: "partner_offer_types" using primary key columns */
  partner_offer_types_by_pk?: Maybe<Partner_Offer_Types>;
  /** fetch data from the table: "partner_params" */
  partner_params: Array<Partner_Params>;
  /** fetch aggregated fields from the table: "partner_params" */
  partner_params_aggregate: Partner_Params_Aggregate;
  /** fetch data from the table: "partner_params_audit" */
  partner_params_audit: Array<Partner_Params_Audit>;
  /** fetch aggregated fields from the table: "partner_params_audit" */
  partner_params_audit_aggregate: Partner_Params_Audit_Aggregate;
  /** fetch data from the table: "partner_params_audit" using primary key columns */
  partner_params_audit_by_pk?: Maybe<Partner_Params_Audit>;
  /** fetch data from the table: "partner_params" using primary key columns */
  partner_params_by_pk?: Maybe<Partner_Params>;
  /** fetch data from the table: "passport_info" */
  passport_info: Array<Passport_Info>;
  /** fetch aggregated fields from the table: "passport_info" */
  passport_info_aggregate: Passport_Info_Aggregate;
  /** fetch data from the table: "passport_info" using primary key columns */
  passport_info_by_pk?: Maybe<Passport_Info>;
  /** fetch data from the table: "payment_methods" */
  payment_methods: Array<Payment_Methods>;
  /** fetch aggregated fields from the table: "payment_methods" */
  payment_methods_aggregate: Payment_Methods_Aggregate;
  /** fetch data from the table: "payment_methods" using primary key columns */
  payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** fetch data from the table: "payment_methods_view" */
  payment_methods_view: Array<Payment_Methods_View>;
  /** fetch aggregated fields from the table: "payment_methods_view" */
  payment_methods_view_aggregate: Payment_Methods_View_Aggregate;
  /** fetch data from the table: "payment_methods_view" using primary key columns */
  payment_methods_view_by_pk?: Maybe<Payment_Methods_View>;
  /** fetch data from the table: "payment_providers" */
  payment_providers: Array<Payment_Providers>;
  /** fetch aggregated fields from the table: "payment_providers" */
  payment_providers_aggregate: Payment_Providers_Aggregate;
  /** fetch data from the table: "payment_providers" using primary key columns */
  payment_providers_by_pk?: Maybe<Payment_Providers>;
  /** fetch data from the table: "payment_recurring_tokens" */
  payment_recurring_tokens: Array<Payment_Recurring_Tokens>;
  /** fetch aggregated fields from the table: "payment_recurring_tokens" */
  payment_recurring_tokens_aggregate: Payment_Recurring_Tokens_Aggregate;
  /** fetch data from the table: "payment_recurring_tokens" using primary key columns */
  payment_recurring_tokens_by_pk?: Maybe<Payment_Recurring_Tokens>;
  /** fetch data from the table: "payment_requisites" */
  payment_requisites: Array<Payment_Requisites>;
  /** fetch aggregated fields from the table: "payment_requisites" */
  payment_requisites_aggregate: Payment_Requisites_Aggregate;
  /** fetch data from the table: "payment_requisites" using primary key columns */
  payment_requisites_by_pk?: Maybe<Payment_Requisites>;
  /** fetch data from the table: "payment_system_1payment_transaction_requests" */
  payment_system_1payment_transaction_requests: Array<Payment_System_1payment_Transaction_Requests>;
  /** fetch aggregated fields from the table: "payment_system_1payment_transaction_requests" */
  payment_system_1payment_transaction_requests_aggregate: Payment_System_1payment_Transaction_Requests_Aggregate;
  /** fetch data from the table: "payment_system_1payment_transaction_requests" using primary key columns */
  payment_system_1payment_transaction_requests_by_pk?: Maybe<Payment_System_1payment_Transaction_Requests>;
  /** fetch data from the table: "payment_system_free_kassa_callbacks" */
  payment_system_free_kassa_callbacks: Array<Payment_System_Free_Kassa_Callbacks>;
  /** fetch aggregated fields from the table: "payment_system_free_kassa_callbacks" */
  payment_system_free_kassa_callbacks_aggregate: Payment_System_Free_Kassa_Callbacks_Aggregate;
  /** fetch data from the table: "payment_system_free_kassa_callbacks" using primary key columns */
  payment_system_free_kassa_callbacks_by_pk?: Maybe<Payment_System_Free_Kassa_Callbacks>;
  /** fetch data from the table: "payment_system_free_kassa_transaction_requests" */
  payment_system_free_kassa_transaction_requests: Array<Payment_System_Free_Kassa_Transaction_Requests>;
  /** fetch aggregated fields from the table: "payment_system_free_kassa_transaction_requests" */
  payment_system_free_kassa_transaction_requests_aggregate: Payment_System_Free_Kassa_Transaction_Requests_Aggregate;
  /** fetch data from the table: "payment_system_free_kassa_transaction_requests" using primary key columns */
  payment_system_free_kassa_transaction_requests_by_pk?: Maybe<Payment_System_Free_Kassa_Transaction_Requests>;
  /** fetch data from the table: "payment_system_main_callbacks" */
  payment_system_main_callbacks: Array<Payment_System_Main_Callbacks>;
  /** fetch aggregated fields from the table: "payment_system_main_callbacks" */
  payment_system_main_callbacks_aggregate: Payment_System_Main_Callbacks_Aggregate;
  /** fetch data from the table: "payment_system_main_callbacks" using primary key columns */
  payment_system_main_callbacks_by_pk?: Maybe<Payment_System_Main_Callbacks>;
  /** fetch data from the table: "payment_system_main_transaction_requests" */
  payment_system_main_transaction_requests: Array<Payment_System_Main_Transaction_Requests>;
  /** fetch aggregated fields from the table: "payment_system_main_transaction_requests" */
  payment_system_main_transaction_requests_aggregate: Payment_System_Main_Transaction_Requests_Aggregate;
  /** fetch data from the table: "payment_system_main_transaction_requests" using primary key columns */
  payment_system_main_transaction_requests_by_pk?: Maybe<Payment_System_Main_Transaction_Requests>;
  /** fetch data from the table: "payment_systems_1payment_callbacks" */
  payment_systems_1payment_callbacks: Array<Payment_Systems_1payment_Callbacks>;
  /** fetch aggregated fields from the table: "payment_systems_1payment_callbacks" */
  payment_systems_1payment_callbacks_aggregate: Payment_Systems_1payment_Callbacks_Aggregate;
  /** fetch data from the table: "payment_systems_1payment_callbacks" using primary key columns */
  payment_systems_1payment_callbacks_by_pk?: Maybe<Payment_Systems_1payment_Callbacks>;
  /** fetch data from the table: "payment_systems_callbacks" */
  payment_systems_callbacks: Array<Payment_Systems_Callbacks>;
  /** fetch aggregated fields from the table: "payment_systems_callbacks" */
  payment_systems_callbacks_aggregate: Payment_Systems_Callbacks_Aggregate;
  /** fetch data from the table: "payment_systems_callbacks" using primary key columns */
  payment_systems_callbacks_by_pk?: Maybe<Payment_Systems_Callbacks>;
  /** fetch data from the table: "payment_systems_data" */
  payment_systems_data: Array<Payment_Systems_Data>;
  /** fetch aggregated fields from the table: "payment_systems_data" */
  payment_systems_data_aggregate: Payment_Systems_Data_Aggregate;
  /** fetch data from the table: "payment_systems_data" using primary key columns */
  payment_systems_data_by_pk?: Maybe<Payment_Systems_Data>;
  /** fetch data from the table: "payment_systems_methods" */
  payment_systems_methods: Array<Payment_Systems_Methods>;
  /** fetch aggregated fields from the table: "payment_systems_methods" */
  payment_systems_methods_aggregate: Payment_Systems_Methods_Aggregate;
  /** fetch data from the table: "payment_systems_methods" using primary key columns */
  payment_systems_methods_by_pk?: Maybe<Payment_Systems_Methods>;
  /** fetch data from the table: "payment_systems_transaction_requests" */
  payment_systems_transaction_requests: Array<Payment_Systems_Transaction_Requests>;
  /** fetch aggregated fields from the table: "payment_systems_transaction_requests" */
  payment_systems_transaction_requests_aggregate: Payment_Systems_Transaction_Requests_Aggregate;
  /** fetch data from the table: "payment_systems_transaction_requests" using primary key columns */
  payment_systems_transaction_requests_by_pk?: Maybe<Payment_Systems_Transaction_Requests>;
  /** fetch data from the table: "payment_transactions" */
  payment_transactions: Array<Payment_Transactions>;
  /** fetch aggregated fields from the table: "payment_transactions" */
  payment_transactions_aggregate: Payment_Transactions_Aggregate;
  /** fetch data from the table: "payment_transactions" using primary key columns */
  payment_transactions_by_pk?: Maybe<Payment_Transactions>;
  /** fetch data from the table: "profile_items_names" */
  profile_items_names: Array<Profile_Items_Names>;
  /** fetch aggregated fields from the table: "profile_items_names" */
  profile_items_names_aggregate: Profile_Items_Names_Aggregate;
  /** fetch data from the table: "profile_items_names" using primary key columns */
  profile_items_names_by_pk?: Maybe<Profile_Items_Names>;
  /** fetch data from the table: "promo_lootboxes" */
  promo_lootboxes: Array<Promo_Lootboxes>;
  /** fetch aggregated fields from the table: "promo_lootboxes" */
  promo_lootboxes_aggregate: Promo_Lootboxes_Aggregate;
  /** fetch data from the table: "promo_lootboxes" using primary key columns */
  promo_lootboxes_by_pk?: Maybe<Promo_Lootboxes>;
  /** fetch data from the table: "promo_users" */
  promo_users: Array<Promo_Users>;
  /** fetch aggregated fields from the table: "promo_users" */
  promo_users_aggregate: Promo_Users_Aggregate;
  /** fetch data from the table: "promo_users" using primary key columns */
  promo_users_by_pk?: Maybe<Promo_Users>;
  /** fetch data from the table: "promo_values" */
  promo_values: Array<Promo_Values>;
  /** fetch aggregated fields from the table: "promo_values" */
  promo_values_aggregate: Promo_Values_Aggregate;
  /** fetch data from the table: "promo_values" using primary key columns */
  promo_values_by_pk?: Maybe<Promo_Values>;
  /** An array relationship */
  promocode_activations: Array<Promocode_Activations>;
  /** An aggregate relationship */
  promocode_activations_aggregate: Promocode_Activations_Aggregate;
  /** fetch data from the table: "promocode_activations" using primary key columns */
  promocode_activations_by_pk?: Maybe<Promocode_Activations>;
  /** fetch data from the table: "promocodes" */
  promocodes: Array<Promocodes>;
  /** fetch aggregated fields from the table: "promocodes" */
  promocodes_aggregate: Promocodes_Aggregate;
  /** fetch data from the table: "promocodes" using primary key columns */
  promocodes_by_pk?: Maybe<Promocodes>;
  /** fetch data from the table: "ps_types" */
  ps_types: Array<Ps_Types>;
  /** fetch aggregated fields from the table: "ps_types" */
  ps_types_aggregate: Ps_Types_Aggregate;
  /** fetch data from the table: "ps_types" using primary key columns */
  ps_types_by_pk?: Maybe<Ps_Types>;
  /** An array relationship */
  qservice_data: Array<Qservice_Data>;
  /** An aggregate relationship */
  qservice_data_aggregate: Qservice_Data_Aggregate;
  /** fetch data from the table: "qservice_data" using primary key columns */
  qservice_data_by_pk?: Maybe<Qservice_Data>;
  /** fetch data from the table: "statistic_bonus_wheels_users_offers" */
  statistic_bonus_wheels_users_offers: Array<Statistic_Bonus_Wheels_Users_Offers>;
  /** fetch aggregated fields from the table: "statistic_bonus_wheels_users_offers" */
  statistic_bonus_wheels_users_offers_aggregate: Statistic_Bonus_Wheels_Users_Offers_Aggregate;
  /** fetch data from the table: "statistic_bonus_wheels_users_offers" using primary key columns */
  statistic_bonus_wheels_users_offers_by_pk?: Maybe<Statistic_Bonus_Wheels_Users_Offers>;
  /** fetch data from the table: "statistic_button_again_press" */
  statistic_button_again_press: Array<Statistic_Button_Again_Press>;
  /** fetch aggregated fields from the table: "statistic_button_again_press" */
  statistic_button_again_press_aggregate: Statistic_Button_Again_Press_Aggregate;
  /** fetch data from the table: "statistic_button_again_press" using primary key columns */
  statistic_button_again_press_by_pk?: Maybe<Statistic_Button_Again_Press>;
  /** fetch data from the table: "statistic_fast_roll_trigger" */
  statistic_fast_roll_trigger: Array<Statistic_Fast_Roll_Trigger>;
  /** fetch aggregated fields from the table: "statistic_fast_roll_trigger" */
  statistic_fast_roll_trigger_aggregate: Statistic_Fast_Roll_Trigger_Aggregate;
  /** fetch data from the table: "statistic_fast_roll_trigger" using primary key columns */
  statistic_fast_roll_trigger_by_pk?: Maybe<Statistic_Fast_Roll_Trigger>;
  /** fetch data from the table: "statistic_inventory_opens" */
  statistic_inventory_opens: Array<Statistic_Inventory_Opens>;
  /** fetch aggregated fields from the table: "statistic_inventory_opens" */
  statistic_inventory_opens_aggregate: Statistic_Inventory_Opens_Aggregate;
  /** fetch data from the table: "statistic_inventory_opens" using primary key columns */
  statistic_inventory_opens_by_pk?: Maybe<Statistic_Inventory_Opens>;
  /** fetch data from the table: "statistic_items_drops" */
  statistic_items_drops: Array<Statistic_Items_Drops>;
  /** fetch aggregated fields from the table: "statistic_items_drops" */
  statistic_items_drops_aggregate: Statistic_Items_Drops_Aggregate;
  /** fetch data from the table: "statistic_items_drops" using primary key columns */
  statistic_items_drops_by_pk?: Maybe<Statistic_Items_Drops>;
  /** fetch data from the table: "statistic_items_names" */
  statistic_items_names: Array<Statistic_Items_Names>;
  /** fetch aggregated fields from the table: "statistic_items_names" */
  statistic_items_names_aggregate: Statistic_Items_Names_Aggregate;
  /** fetch data from the table: "statistic_items_names" using primary key columns */
  statistic_items_names_by_pk?: Maybe<Statistic_Items_Names>;
  /** fetch data from the table: "statistic_items_output_fail" */
  statistic_items_output_fail: Array<Statistic_Items_Output_Fail>;
  /** fetch aggregated fields from the table: "statistic_items_output_fail" */
  statistic_items_output_fail_aggregate: Statistic_Items_Output_Fail_Aggregate;
  /** fetch data from the table: "statistic_items_output_fail" using primary key columns */
  statistic_items_output_fail_by_pk?: Maybe<Statistic_Items_Output_Fail>;
  /** fetch data from the table: "statistic_items_output_success" */
  statistic_items_output_success: Array<Statistic_Items_Output_Success>;
  /** fetch aggregated fields from the table: "statistic_items_output_success" */
  statistic_items_output_success_aggregate: Statistic_Items_Output_Success_Aggregate;
  /** fetch data from the table: "statistic_items_output_success" using primary key columns */
  statistic_items_output_success_by_pk?: Maybe<Statistic_Items_Output_Success>;
  /** fetch data from the table: "statistic_lootbox_opens" */
  statistic_lootbox_opens: Array<Statistic_Lootbox_Opens>;
  /** fetch aggregated fields from the table: "statistic_lootbox_opens" */
  statistic_lootbox_opens_aggregate: Statistic_Lootbox_Opens_Aggregate;
  /** fetch data from the table: "statistic_lootbox_opens" using primary key columns */
  statistic_lootbox_opens_by_pk?: Maybe<Statistic_Lootbox_Opens>;
  /** fetch data from the table: "statistic_lootbox_views" */
  statistic_lootbox_views: Array<Statistic_Lootbox_Views>;
  /** fetch aggregated fields from the table: "statistic_lootbox_views" */
  statistic_lootbox_views_aggregate: Statistic_Lootbox_Views_Aggregate;
  /** fetch data from the table: "statistic_lootbox_views" using primary key columns */
  statistic_lootbox_views_by_pk?: Maybe<Statistic_Lootbox_Views>;
  /** fetch data from the table: "statistic_mainpage_visits" */
  statistic_mainpage_visits: Array<Statistic_Mainpage_Visits>;
  /** fetch aggregated fields from the table: "statistic_mainpage_visits" */
  statistic_mainpage_visits_aggregate: Statistic_Mainpage_Visits_Aggregate;
  /** fetch data from the table: "statistic_mainpage_visits" using primary key columns */
  statistic_mainpage_visits_by_pk?: Maybe<Statistic_Mainpage_Visits>;
  /** fetch data from the table: "statistic_promocode_full_activations" */
  statistic_promocode_full_activations: Array<Statistic_Promocode_Full_Activations>;
  /** fetch aggregated fields from the table: "statistic_promocode_full_activations" */
  statistic_promocode_full_activations_aggregate: Statistic_Promocode_Full_Activations_Aggregate;
  /** fetch data from the table: "statistic_promocode_full_activations" using primary key columns */
  statistic_promocode_full_activations_by_pk?: Maybe<Statistic_Promocode_Full_Activations>;
  /** fetch data from the table: "statistic_promocode_success_activations" */
  statistic_promocode_success_activations: Array<Statistic_Promocode_Success_Activations>;
  /** fetch aggregated fields from the table: "statistic_promocode_success_activations" */
  statistic_promocode_success_activations_aggregate: Statistic_Promocode_Success_Activations_Aggregate;
  /** fetch data from the table: "statistic_promocode_success_activations" using primary key columns */
  statistic_promocode_success_activations_by_pk?: Maybe<Statistic_Promocode_Success_Activations>;
  /** fetch data from the table: "statistic_sell_all_items" */
  statistic_sell_all_items: Array<Statistic_Sell_All_Items>;
  /** fetch aggregated fields from the table: "statistic_sell_all_items" */
  statistic_sell_all_items_aggregate: Statistic_Sell_All_Items_Aggregate;
  /** fetch data from the table: "statistic_sell_all_items" using primary key columns */
  statistic_sell_all_items_by_pk?: Maybe<Statistic_Sell_All_Items>;
  /** fetch data from the table: "statistic_sell_item" */
  statistic_sell_item: Array<Statistic_Sell_Item>;
  /** fetch aggregated fields from the table: "statistic_sell_item" */
  statistic_sell_item_aggregate: Statistic_Sell_Item_Aggregate;
  /** fetch data from the table: "statistic_sell_item" using primary key columns */
  statistic_sell_item_by_pk?: Maybe<Statistic_Sell_Item>;
  /** fetch data from the table: "statistic_user_deposit_success_callbacks" */
  statistic_user_deposit_success_callbacks: Array<Statistic_User_Deposit_Success_Callbacks>;
  /** fetch aggregated fields from the table: "statistic_user_deposit_success_callbacks" */
  statistic_user_deposit_success_callbacks_aggregate: Statistic_User_Deposit_Success_Callbacks_Aggregate;
  /** fetch data from the table: "statistic_user_deposit_success_callbacks" using primary key columns */
  statistic_user_deposit_success_callbacks_by_pk?: Maybe<Statistic_User_Deposit_Success_Callbacks>;
  /** fetch data from the table: "statistic_user_deposits_transaction_requests" */
  statistic_user_deposits_transaction_requests: Array<Statistic_User_Deposits_Transaction_Requests>;
  /** fetch aggregated fields from the table: "statistic_user_deposits_transaction_requests" */
  statistic_user_deposits_transaction_requests_aggregate: Statistic_User_Deposits_Transaction_Requests_Aggregate;
  /** fetch data from the table: "statistic_user_deposits_transaction_requests" using primary key columns */
  statistic_user_deposits_transaction_requests_by_pk?: Maybe<Statistic_User_Deposits_Transaction_Requests>;
  /** fetch data from the table: "statistic_user_free_lootboxes_opens" */
  statistic_user_free_lootboxes_opens: Array<Statistic_User_Free_Lootboxes_Opens>;
  /** fetch aggregated fields from the table: "statistic_user_free_lootboxes_opens" */
  statistic_user_free_lootboxes_opens_aggregate: Statistic_User_Free_Lootboxes_Opens_Aggregate;
  /** fetch data from the table: "statistic_user_free_lootboxes_opens" using primary key columns */
  statistic_user_free_lootboxes_opens_by_pk?: Maybe<Statistic_User_Free_Lootboxes_Opens>;
  /** fetch data from the table: "statistic_user_registration" */
  statistic_user_registration: Array<Statistic_User_Registration>;
  /** fetch aggregated fields from the table: "statistic_user_registration" */
  statistic_user_registration_aggregate: Statistic_User_Registration_Aggregate;
  /** fetch data from the table: "statistic_user_registration" using primary key columns */
  statistic_user_registration_by_pk?: Maybe<Statistic_User_Registration>;
  /** fetch data from the table: "stocks" */
  stocks: Array<Stocks>;
  /** fetch aggregated fields from the table: "stocks" */
  stocks_aggregate: Stocks_Aggregate;
  /** fetch data from the table: "stocks" using primary key columns */
  stocks_by_pk?: Maybe<Stocks>;
  /** fetch data from the table: "store_offer_badges" */
  store_offer_badges: Array<Store_Offer_Badges>;
  /** fetch aggregated fields from the table: "store_offer_badges" */
  store_offer_badges_aggregate: Store_Offer_Badges_Aggregate;
  /** fetch data from the table: "store_offer_badges" using primary key columns */
  store_offer_badges_by_pk?: Maybe<Store_Offer_Badges>;
  /** fetch data from the table: "store_offer_boosters" */
  store_offer_boosters: Array<Store_Offer_Boosters>;
  /** fetch aggregated fields from the table: "store_offer_boosters" */
  store_offer_boosters_aggregate: Store_Offer_Boosters_Aggregate;
  /** fetch data from the table: "store_offer_boosters" using primary key columns */
  store_offer_boosters_by_pk?: Maybe<Store_Offer_Boosters>;
  /** fetch data from the table: "store_offer_coins" */
  store_offer_coins: Array<Store_Offer_Coins>;
  /** fetch aggregated fields from the table: "store_offer_coins" */
  store_offer_coins_aggregate: Store_Offer_Coins_Aggregate;
  /** fetch data from the table: "store_offer_coins" using primary key columns */
  store_offer_coins_by_pk?: Maybe<Store_Offer_Coins>;
  /** fetch data from the table: "store_offer_lootboxes" */
  store_offer_lootboxes: Array<Store_Offer_Lootboxes>;
  /** fetch aggregated fields from the table: "store_offer_lootboxes" */
  store_offer_lootboxes_aggregate: Store_Offer_Lootboxes_Aggregate;
  /** fetch data from the table: "store_offer_lootboxes" using primary key columns */
  store_offer_lootboxes_by_pk?: Maybe<Store_Offer_Lootboxes>;
  /** fetch data from the table: "store_offer_sales" */
  store_offer_sales: Array<Store_Offer_Sales>;
  /** fetch aggregated fields from the table: "store_offer_sales" */
  store_offer_sales_aggregate: Store_Offer_Sales_Aggregate;
  /** fetch data from the table: "store_offer_sales" using primary key columns */
  store_offer_sales_by_pk?: Maybe<Store_Offer_Sales>;
  /** fetch data from the table: "store_offer_skins" */
  store_offer_skins: Array<Store_Offer_Skins>;
  /** fetch aggregated fields from the table: "store_offer_skins" */
  store_offer_skins_aggregate: Store_Offer_Skins_Aggregate;
  /** fetch data from the table: "store_offer_skins" using primary key columns */
  store_offer_skins_by_pk?: Maybe<Store_Offer_Skins>;
  /** fetch data from the table: "store_offer_tickets" */
  store_offer_tickets: Array<Store_Offer_Tickets>;
  /** fetch aggregated fields from the table: "store_offer_tickets" */
  store_offer_tickets_aggregate: Store_Offer_Tickets_Aggregate;
  /** fetch data from the table: "store_offer_tickets" using primary key columns */
  store_offer_tickets_by_pk?: Maybe<Store_Offer_Tickets>;
  /** fetch data from the table: "store_offer_types" */
  store_offer_types: Array<Store_Offer_Types>;
  /** fetch aggregated fields from the table: "store_offer_types" */
  store_offer_types_aggregate: Store_Offer_Types_Aggregate;
  /** fetch data from the table: "store_offer_types" using primary key columns */
  store_offer_types_by_pk?: Maybe<Store_Offer_Types>;
  /** fetch data from the table: "store_offers" */
  store_offers: Array<Store_Offers>;
  /** fetch aggregated fields from the table: "store_offers" */
  store_offers_aggregate: Store_Offers_Aggregate;
  /** fetch data from the table: "store_offers" using primary key columns */
  store_offers_by_pk?: Maybe<Store_Offers>;
  /** fetch data from the table: "survey_data_links" */
  survey_data_links: Array<Survey_Data_Links>;
  /** fetch aggregated fields from the table: "survey_data_links" */
  survey_data_links_aggregate: Survey_Data_Links_Aggregate;
  /** fetch data from the table: "survey_data_links" using primary key columns */
  survey_data_links_by_pk?: Maybe<Survey_Data_Links>;
  /** fetch data from the table: "telegram_admin_params" */
  telegram_admin_params: Array<Telegram_Admin_Params>;
  /** fetch aggregated fields from the table: "telegram_admin_params" */
  telegram_admin_params_aggregate: Telegram_Admin_Params_Aggregate;
  /** fetch data from the table: "telegram_admin_params" using primary key columns */
  telegram_admin_params_by_pk?: Maybe<Telegram_Admin_Params>;
  /** fetch data from the table: "ticket_counts_result" */
  ticket_counts_result: Array<Ticket_Counts_Result>;
  /** fetch aggregated fields from the table: "ticket_counts_result" */
  ticket_counts_result_aggregate: Ticket_Counts_Result_Aggregate;
  /** fetch data from the table: "tickets_set_content" */
  tickets_set_content: Array<Tickets_Set_Content>;
  /** fetch aggregated fields from the table: "tickets_set_content" */
  tickets_set_content_aggregate: Tickets_Set_Content_Aggregate;
  /** fetch data from the table: "tickets_set_content" using primary key columns */
  tickets_set_content_by_pk?: Maybe<Tickets_Set_Content>;
  /** fetch data from the table: "tickets_sets" */
  tickets_sets: Array<Tickets_Sets>;
  /** fetch aggregated fields from the table: "tickets_sets" */
  tickets_sets_aggregate: Tickets_Sets_Aggregate;
  /** fetch data from the table: "tickets_sets" using primary key columns */
  tickets_sets_by_pk?: Maybe<Tickets_Sets>;
  /** fetch data from the table: "top_drop_strip" */
  top_drop_strip: Array<Top_Drop_Strip>;
  /** fetch aggregated fields from the table: "top_drop_strip" */
  top_drop_strip_aggregate: Top_Drop_Strip_Aggregate;
  /** fetch data from the table: "top_drop_strip" using primary key columns */
  top_drop_strip_by_pk?: Maybe<Top_Drop_Strip>;
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>;
  /** fetch aggregated fields from the table: "transaction" */
  transaction_aggregate: Transaction_Aggregate;
  /** fetch data from the table: "transaction" using primary key columns */
  transaction_by_pk?: Maybe<Transaction>;
  /** fetch data from the table: "url_mappings" */
  url_mappings: Array<Url_Mappings>;
  /** fetch aggregated fields from the table: "url_mappings" */
  url_mappings_aggregate: Url_Mappings_Aggregate;
  /** fetch data from the table: "url_mappings" using primary key columns */
  url_mappings_by_pk?: Maybe<Url_Mappings>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user_account" */
  user_account: Array<User_Account>;
  /** fetch aggregated fields from the table: "user_account" */
  user_account_aggregate: User_Account_Aggregate;
  /** fetch data from the table: "user_account" using primary key columns */
  user_account_by_pk?: Maybe<User_Account>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user_auth" */
  user_auth: Array<User_Auth>;
  /** fetch aggregated fields from the table: "user_auth" */
  user_auth_aggregate: User_Auth_Aggregate;
  /** fetch data from the table: "user_auth" using primary key columns */
  user_auth_by_pk?: Maybe<User_Auth>;
  /** fetch data from the table: "user_bonus_wallet" */
  user_bonus_wallet: Array<User_Bonus_Wallet>;
  /** fetch aggregated fields from the table: "user_bonus_wallet" */
  user_bonus_wallet_aggregate: User_Bonus_Wallet_Aggregate;
  /** fetch data from the table: "user_bonus_wallet" using primary key columns */
  user_bonus_wallet_by_pk?: Maybe<User_Bonus_Wallet>;
  /** An array relationship */
  user_bonus_wallet_transactions: Array<User_Bonus_Wallet_Transactions>;
  /** An aggregate relationship */
  user_bonus_wallet_transactions_aggregate: User_Bonus_Wallet_Transactions_Aggregate;
  /** fetch data from the table: "user_bonus_wallet_transactions" using primary key columns */
  user_bonus_wallet_transactions_by_pk?: Maybe<User_Bonus_Wallet_Transactions>;
  /** An array relationship */
  user_boosters_in_inventory: Array<User_Boosters_In_Inventory>;
  /** An aggregate relationship */
  user_boosters_in_inventory_aggregate: User_Boosters_In_Inventory_Aggregate;
  /** fetch data from the table: "user_boosters_in_inventory" using primary key columns */
  user_boosters_in_inventory_by_pk?: Maybe<User_Boosters_In_Inventory>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_contacts" */
  user_contacts: Array<User_Contacts>;
  /** fetch aggregated fields from the table: "user_contacts" */
  user_contacts_aggregate: User_Contacts_Aggregate;
  /** fetch data from the table: "user_contacts" using primary key columns */
  user_contacts_by_pk?: Maybe<User_Contacts>;
  /** fetch data from the table: "user_correct_answers_rewards" */
  user_correct_answers_rewards: Array<User_Correct_Answers_Rewards>;
  /** fetch aggregated fields from the table: "user_correct_answers_rewards" */
  user_correct_answers_rewards_aggregate: User_Correct_Answers_Rewards_Aggregate;
  /** fetch data from the table: "user_correct_answers_rewards" using primary key columns */
  user_correct_answers_rewards_by_pk?: Maybe<User_Correct_Answers_Rewards>;
  /** fetch data from the table: "user_daily_coins_claims" */
  user_daily_coins_claims: Array<User_Daily_Coins_Claims>;
  /** fetch aggregated fields from the table: "user_daily_coins_claims" */
  user_daily_coins_claims_aggregate: User_Daily_Coins_Claims_Aggregate;
  /** fetch data from the table: "user_daily_coins_claims" using primary key columns */
  user_daily_coins_claims_by_pk?: Maybe<User_Daily_Coins_Claims>;
  /** fetch data from the table: "user_daily_reward_counts" */
  user_daily_reward_counts: Array<User_Daily_Reward_Counts>;
  /** fetch aggregated fields from the table: "user_daily_reward_counts" */
  user_daily_reward_counts_aggregate: User_Daily_Reward_Counts_Aggregate;
  /** fetch data from the table: "user_daily_reward_counts" using primary key columns */
  user_daily_reward_counts_by_pk?: Maybe<User_Daily_Reward_Counts>;
  /** fetch data from the table: "user_email_verification_data" */
  user_email_verification_data: Array<User_Email_Verification_Data>;
  /** fetch aggregated fields from the table: "user_email_verification_data" */
  user_email_verification_data_aggregate: User_Email_Verification_Data_Aggregate;
  /** fetch data from the table: "user_email_verification_data" using primary key columns */
  user_email_verification_data_by_pk?: Maybe<User_Email_Verification_Data>;
  /** fetch data from the table: "user_events" */
  user_events: Array<User_Events>;
  /** fetch aggregated fields from the table: "user_events" */
  user_events_aggregate: User_Events_Aggregate;
  /** fetch data from the table: "user_events" using primary key columns */
  user_events_by_pk?: Maybe<User_Events>;
  /** An array relationship */
  user_got_bonus: Array<User_Got_Bonus>;
  /** An aggregate relationship */
  user_got_bonus_aggregate: User_Got_Bonus_Aggregate;
  /** fetch data from the table: "user_got_bonus" using primary key columns */
  user_got_bonus_by_pk?: Maybe<User_Got_Bonus>;
  /** fetch data from the table: "user_inventory" */
  user_inventory: Array<User_Inventory>;
  /** fetch aggregated fields from the table: "user_inventory" */
  user_inventory_aggregate: User_Inventory_Aggregate;
  /** fetch data from the table: "user_inventory" using primary key columns */
  user_inventory_by_pk?: Maybe<User_Inventory>;
  /** fetch data from the table: "user_inventory_transactions" */
  user_inventory_transactions: Array<User_Inventory_Transactions>;
  /** fetch aggregated fields from the table: "user_inventory_transactions" */
  user_inventory_transactions_aggregate: User_Inventory_Transactions_Aggregate;
  /** fetch data from the table: "user_inventory_transactions" using primary key columns */
  user_inventory_transactions_by_pk?: Maybe<User_Inventory_Transactions>;
  /** fetch data from the table: "user_items_in_inventory" */
  user_items_in_inventory: Array<User_Items_In_Inventory>;
  /** fetch aggregated fields from the table: "user_items_in_inventory" */
  user_items_in_inventory_aggregate: User_Items_In_Inventory_Aggregate;
  /** fetch data from the table: "user_items_in_inventory" using primary key columns */
  user_items_in_inventory_by_pk?: Maybe<User_Items_In_Inventory>;
  /** fetch data from the table: "user_lootbox_opens_count" */
  user_lootbox_opens_count: Array<User_Lootbox_Opens_Count>;
  /** fetch aggregated fields from the table: "user_lootbox_opens_count" */
  user_lootbox_opens_count_aggregate: User_Lootbox_Opens_Count_Aggregate;
  /** fetch data from the table: "user_lootbox_opens_count" using primary key columns */
  user_lootbox_opens_count_by_pk?: Maybe<User_Lootbox_Opens_Count>;
  /** fetch data from the table: "user_marking" */
  user_marking: Array<User_Marking>;
  /** fetch aggregated fields from the table: "user_marking" */
  user_marking_aggregate: User_Marking_Aggregate;
  /** fetch data from the table: "user_marking" using primary key columns */
  user_marking_by_pk?: Maybe<User_Marking>;
  /** fetch data from the table: "user_markings" */
  user_markings: Array<User_Markings>;
  /** fetch aggregated fields from the table: "user_markings" */
  user_markings_aggregate: User_Markings_Aggregate;
  /** fetch data from the table: "user_markings" using primary key columns */
  user_markings_by_pk?: Maybe<User_Markings>;
  /** fetch data from the table: "user_output_limits" */
  user_output_limits: Array<User_Output_Limits>;
  /** fetch aggregated fields from the table: "user_output_limits" */
  user_output_limits_aggregate: User_Output_Limits_Aggregate;
  /** fetch data from the table: "user_output_limits" using primary key columns */
  user_output_limits_by_pk?: Maybe<User_Output_Limits>;
  /** fetch data from the table: "user_profile" */
  user_profile: Array<User_Profile>;
  /** fetch aggregated fields from the table: "user_profile" */
  user_profile_aggregate: User_Profile_Aggregate;
  /** fetch data from the table: "user_profile" using primary key columns */
  user_profile_by_pk?: Maybe<User_Profile>;
  /** An array relationship */
  user_push_tokens: Array<User_Push_Tokens>;
  /** An aggregate relationship */
  user_push_tokens_aggregate: User_Push_Tokens_Aggregate;
  /** fetch data from the table: "user_push_tokens" using primary key columns */
  user_push_tokens_by_pk?: Maybe<User_Push_Tokens>;
  /** fetch data from the table: "user_push_topics" */
  user_push_topics: Array<User_Push_Topics>;
  /** fetch aggregated fields from the table: "user_push_topics" */
  user_push_topics_aggregate: User_Push_Topics_Aggregate;
  /** fetch data from the table: "user_push_topics" using primary key columns */
  user_push_topics_by_pk?: Maybe<User_Push_Topics>;
  /** fetch data from the table: "user_referal_levels_params" */
  user_referal_levels_params: Array<User_Referal_Levels_Params>;
  /** fetch aggregated fields from the table: "user_referal_levels_params" */
  user_referal_levels_params_aggregate: User_Referal_Levels_Params_Aggregate;
  /** fetch data from the table: "user_referal_levels_params" using primary key columns */
  user_referal_levels_params_by_pk?: Maybe<User_Referal_Levels_Params>;
  /** fetch data from the table: "user_referal_params" */
  user_referal_params: Array<User_Referal_Params>;
  /** fetch aggregated fields from the table: "user_referal_params" */
  user_referal_params_aggregate: User_Referal_Params_Aggregate;
  /** fetch data from the table: "user_referal_params" using primary key columns */
  user_referal_params_by_pk?: Maybe<User_Referal_Params>;
  /** fetch data from the table: "user_referals" */
  user_referals: Array<User_Referals>;
  /** fetch aggregated fields from the table: "user_referals" */
  user_referals_aggregate: User_Referals_Aggregate;
  /** fetch data from the table: "user_referals_balance_transactions" */
  user_referals_balance_transactions: Array<User_Referals_Balance_Transactions>;
  /** fetch aggregated fields from the table: "user_referals_balance_transactions" */
  user_referals_balance_transactions_aggregate: User_Referals_Balance_Transactions_Aggregate;
  /** fetch data from the table: "user_referals_balance_transactions" using primary key columns */
  user_referals_balance_transactions_by_pk?: Maybe<User_Referals_Balance_Transactions>;
  /** fetch data from the table: "user_referals_bonus_history" */
  user_referals_bonus_history: Array<User_Referals_Bonus_History>;
  /** fetch aggregated fields from the table: "user_referals_bonus_history" */
  user_referals_bonus_history_aggregate: User_Referals_Bonus_History_Aggregate;
  /** fetch data from the table: "user_referals_bonus_history" using primary key columns */
  user_referals_bonus_history_by_pk?: Maybe<User_Referals_Bonus_History>;
  /** fetch data from the table: "user_referals" using primary key columns */
  user_referals_by_pk?: Maybe<User_Referals>;
  /** fetch data from the table: "user_referral" */
  user_referral: Array<User_Referral>;
  /** fetch aggregated fields from the table: "user_referral" */
  user_referral_aggregate: User_Referral_Aggregate;
  /** fetch data from the table: "user_referral_association" */
  user_referral_association: Array<User_Referral_Association>;
  /** fetch aggregated fields from the table: "user_referral_association" */
  user_referral_association_aggregate: User_Referral_Association_Aggregate;
  /** fetch data from the table: "user_referral_association" using primary key columns */
  user_referral_association_by_pk?: Maybe<User_Referral_Association>;
  /** fetch data from the table: "user_referral_bonus_type" */
  user_referral_bonus_type: Array<User_Referral_Bonus_Type>;
  /** fetch aggregated fields from the table: "user_referral_bonus_type" */
  user_referral_bonus_type_aggregate: User_Referral_Bonus_Type_Aggregate;
  /** fetch data from the table: "user_referral_bonus_type" using primary key columns */
  user_referral_bonus_type_by_pk?: Maybe<User_Referral_Bonus_Type>;
  /** fetch data from the table: "user_referral" using primary key columns */
  user_referral_by_pk?: Maybe<User_Referral>;
  /** fetch data from the table: "user_referral_levels_params" */
  user_referral_levels_params: Array<User_Referral_Levels_Params>;
  /** fetch aggregated fields from the table: "user_referral_levels_params" */
  user_referral_levels_params_aggregate: User_Referral_Levels_Params_Aggregate;
  /** fetch data from the table: "user_referral_levels_params" using primary key columns */
  user_referral_levels_params_by_pk?: Maybe<User_Referral_Levels_Params>;
  /** An array relationship */
  user_referral_offer_bonus: Array<User_Referral_Offer_Bonus>;
  /** An aggregate relationship */
  user_referral_offer_bonus_aggregate: User_Referral_Offer_Bonus_Aggregate;
  /** fetch data from the table: "user_referral_offer_bonus" using primary key columns */
  user_referral_offer_bonus_by_pk?: Maybe<User_Referral_Offer_Bonus>;
  /** An array relationship */
  user_referral_offers: Array<User_Referral_Offers>;
  /** An aggregate relationship */
  user_referral_offers_aggregate: User_Referral_Offers_Aggregate;
  /** fetch data from the table: "user_referral_offers" using primary key columns */
  user_referral_offers_by_pk?: Maybe<User_Referral_Offers>;
  /** fetch data from the table: "user_referral_params" */
  user_referral_params: Array<User_Referral_Params>;
  /** fetch aggregated fields from the table: "user_referral_params" */
  user_referral_params_aggregate: User_Referral_Params_Aggregate;
  /** fetch data from the table: "user_referral_params" using primary key columns */
  user_referral_params_by_pk?: Maybe<User_Referral_Params>;
  /** fetch data from the table: "user_referrals_balance_transactions" */
  user_referrals_balance_transactions: Array<User_Referrals_Balance_Transactions>;
  /** fetch aggregated fields from the table: "user_referrals_balance_transactions" */
  user_referrals_balance_transactions_aggregate: User_Referrals_Balance_Transactions_Aggregate;
  /** fetch data from the table: "user_referrals_balance_transactions" using primary key columns */
  user_referrals_balance_transactions_by_pk?: Maybe<User_Referrals_Balance_Transactions>;
  /** fetch data from the table: "user_seq_number" */
  user_seq_number: Array<User_Seq_Number>;
  /** fetch aggregated fields from the table: "user_seq_number" */
  user_seq_number_aggregate: User_Seq_Number_Aggregate;
  /** fetch data from the table: "user_seq_number" using primary key columns */
  user_seq_number_by_pk?: Maybe<User_Seq_Number>;
  /** fetch data from the table: "user_settings" */
  user_settings: Array<User_Settings>;
  /** fetch aggregated fields from the table: "user_settings" */
  user_settings_aggregate: User_Settings_Aggregate;
  /** fetch data from the table: "user_settings" using primary key columns */
  user_settings_by_pk?: Maybe<User_Settings>;
  /** fetch data from the table: "user_statistic" */
  user_statistic: Array<User_Statistic>;
  /** fetch aggregated fields from the table: "user_statistic" */
  user_statistic_aggregate: User_Statistic_Aggregate;
  /** fetch data from the table: "user_statistic" using primary key columns */
  user_statistic_by_pk?: Maybe<User_Statistic>;
  /** fetch data from the table: "user_steam_output_alternatives" */
  user_steam_output_alternatives: Array<User_Steam_Output_Alternatives>;
  /** fetch aggregated fields from the table: "user_steam_output_alternatives" */
  user_steam_output_alternatives_aggregate: User_Steam_Output_Alternatives_Aggregate;
  /** fetch data from the table: "user_steam_output_alternatives" using primary key columns */
  user_steam_output_alternatives_by_pk?: Maybe<User_Steam_Output_Alternatives>;
  /** fetch data from the table: "user_steam_output_watcher" */
  user_steam_output_watcher: Array<User_Steam_Output_Watcher>;
  /** fetch aggregated fields from the table: "user_steam_output_watcher" */
  user_steam_output_watcher_aggregate: User_Steam_Output_Watcher_Aggregate;
  /** fetch data from the table: "user_steam_output_watcher" using primary key columns */
  user_steam_output_watcher_by_pk?: Maybe<User_Steam_Output_Watcher>;
  /** fetch data from the table: "user_takes_free_lootbox" */
  user_takes_free_lootbox: Array<User_Takes_Free_Lootbox>;
  /** fetch aggregated fields from the table: "user_takes_free_lootbox" */
  user_takes_free_lootbox_aggregate: User_Takes_Free_Lootbox_Aggregate;
  /** fetch data from the table: "user_takes_free_lootbox" using primary key columns */
  user_takes_free_lootbox_by_pk?: Maybe<User_Takes_Free_Lootbox>;
  /** fetch data from the table: "user_upgrade_params" */
  user_upgrade_params: Array<User_Upgrade_Params>;
  /** fetch aggregated fields from the table: "user_upgrade_params" */
  user_upgrade_params_aggregate: User_Upgrade_Params_Aggregate;
  /** fetch data from the table: "user_upgrade_params" using primary key columns */
  user_upgrade_params_by_pk?: Maybe<User_Upgrade_Params>;
  /** fetch data from the table: "user_upgrade_results" */
  user_upgrade_results: Array<User_Upgrade_Results>;
  /** fetch aggregated fields from the table: "user_upgrade_results" */
  user_upgrade_results_aggregate: User_Upgrade_Results_Aggregate;
  /** fetch data from the table: "user_upgrade_results" using primary key columns */
  user_upgrade_results_by_pk?: Maybe<User_Upgrade_Results>;
  /** fetch data from the table: "user_vk_data" */
  user_vk_data: Array<User_Vk_Data>;
  /** fetch aggregated fields from the table: "user_vk_data" */
  user_vk_data_aggregate: User_Vk_Data_Aggregate;
  /** fetch data from the table: "user_vk_data" using primary key columns */
  user_vk_data_by_pk?: Maybe<User_Vk_Data>;
  /** fetch data from the table: "user_wallet" */
  user_wallet: Array<User_Wallet>;
  /** fetch aggregated fields from the table: "user_wallet" */
  user_wallet_aggregate: User_Wallet_Aggregate;
  /** fetch data from the table: "user_wallet_audit" */
  user_wallet_audit: Array<User_Wallet_Audit>;
  /** fetch aggregated fields from the table: "user_wallet_audit" */
  user_wallet_audit_aggregate: User_Wallet_Audit_Aggregate;
  /** fetch data from the table: "user_wallet_audit" using primary key columns */
  user_wallet_audit_by_pk?: Maybe<User_Wallet_Audit>;
  /** fetch data from the table: "user_wallet" using primary key columns */
  user_wallet_by_pk?: Maybe<User_Wallet>;
  /** An array relationship */
  user_wallet_transactions: Array<User_Wallet_Transactions>;
  /** An aggregate relationship */
  user_wallet_transactions_aggregate: User_Wallet_Transactions_Aggregate;
  /** fetch data from the table: "user_wallet_transactions" using primary key columns */
  user_wallet_transactions_by_pk?: Maybe<User_Wallet_Transactions>;
  /** fetch data from the table: "user_whitelist" */
  user_whitelist: Array<User_Whitelist>;
  /** fetch aggregated fields from the table: "user_whitelist" */
  user_whitelist_aggregate: User_Whitelist_Aggregate;
  /** fetch data from the table: "user_whitelist" using primary key columns */
  user_whitelist_by_pk?: Maybe<User_Whitelist>;
  /** fetch data from the table: "utm_visits_data" */
  utm_visits_data: Array<Utm_Visits_Data>;
  /** fetch aggregated fields from the table: "utm_visits_data" */
  utm_visits_data_aggregate: Utm_Visits_Data_Aggregate;
  /** fetch data from the table: "utm_visits_data" using primary key columns */
  utm_visits_data_by_pk?: Maybe<Utm_Visits_Data>;
  /** fetch data from the table: "webpushes_autosending_types" */
  webpushes_autosending_types: Array<Webpushes_Autosending_Types>;
  /** fetch aggregated fields from the table: "webpushes_autosending_types" */
  webpushes_autosending_types_aggregate: Webpushes_Autosending_Types_Aggregate;
  /** fetch data from the table: "webpushes_autosending_types" using primary key columns */
  webpushes_autosending_types_by_pk?: Maybe<Webpushes_Autosending_Types>;
  /** fetch data from the table: "webpushes_autosending_types_localization" */
  webpushes_autosending_types_localization: Array<Webpushes_Autosending_Types_Localization>;
  /** fetch aggregated fields from the table: "webpushes_autosending_types_localization" */
  webpushes_autosending_types_localization_aggregate: Webpushes_Autosending_Types_Localization_Aggregate;
  /** fetch data from the table: "webpushes_autosending_types_localization" using primary key columns */
  webpushes_autosending_types_localization_by_pk?: Maybe<Webpushes_Autosending_Types_Localization>;
  /** fetch data from the table: "webpushes_bonuses" */
  webpushes_bonuses: Array<Webpushes_Bonuses>;
  /** fetch aggregated fields from the table: "webpushes_bonuses" */
  webpushes_bonuses_aggregate: Webpushes_Bonuses_Aggregate;
  /** fetch data from the table: "webpushes_bonuses" using primary key columns */
  webpushes_bonuses_by_pk?: Maybe<Webpushes_Bonuses>;
  /** An array relationship */
  webpushes_bonuses_offers: Array<Webpushes_Bonuses_Offers>;
  /** An aggregate relationship */
  webpushes_bonuses_offers_aggregate: Webpushes_Bonuses_Offers_Aggregate;
  /** fetch data from the table: "webpushes_bonuses_offers" using primary key columns */
  webpushes_bonuses_offers_by_pk?: Maybe<Webpushes_Bonuses_Offers>;
  /** fetch data from the table: "webpushes_lines" */
  webpushes_lines: Array<Webpushes_Lines>;
  /** fetch aggregated fields from the table: "webpushes_lines" */
  webpushes_lines_aggregate: Webpushes_Lines_Aggregate;
  /** fetch data from the table: "webpushes_lines" using primary key columns */
  webpushes_lines_by_pk?: Maybe<Webpushes_Lines>;
  /** fetch data from the table: "webpushes_scheduling" */
  webpushes_scheduling: Array<Webpushes_Scheduling>;
  /** fetch aggregated fields from the table: "webpushes_scheduling" */
  webpushes_scheduling_aggregate: Webpushes_Scheduling_Aggregate;
  /** fetch data from the table: "webpushes_scheduling" using primary key columns */
  webpushes_scheduling_by_pk?: Maybe<Webpushes_Scheduling>;
  /** An array relationship */
  webpushes_triggers: Array<Webpushes_Triggers>;
  /** An aggregate relationship */
  webpushes_triggers_aggregate: Webpushes_Triggers_Aggregate;
  /** fetch data from the table: "webpushes_triggers" using primary key columns */
  webpushes_triggers_by_pk?: Maybe<Webpushes_Triggers>;
  /** An array relationship */
  webpushes_triggers_groups: Array<Webpushes_Triggers_Groups>;
  /** An aggregate relationship */
  webpushes_triggers_groups_aggregate: Webpushes_Triggers_Groups_Aggregate;
  /** fetch data from the table: "webpushes_triggers_groups" using primary key columns */
  webpushes_triggers_groups_by_pk?: Maybe<Webpushes_Triggers_Groups>;
};


export type Query_RootCalcUpgradeParamsArgs = {
  upgrade_params_input: UserUpgradeCalcInput;
};


export type Query_RootCheckUserPromocodeArgs = {
  promocode: Scalars['String'];
};


export type Query_RootGetBwPageDataByUidtempArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootGetBonusWheelAvailabilityDataByUserIdArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootGetDemoLbDataArgs = {
  lb_id: Scalars['uuid'];
};


export type Query_RootGetDemoResultItemArgs = {
  counter: Scalars['Int'];
  lb_id: Scalars['uuid'];
};


export type Query_RootGetFreeLootboxInfoBySlugArgs = {
  slug: Scalars['String'];
};


export type Query_RootGetGiveawayArgs = {
  giveaway_id: Scalars['String'];
};


export type Query_RootGetGiveawayMembersArgs = {
  giveaway_id: Scalars['String'];
};


export type Query_RootGetHistoryUserGiveawayArgs = {
  page?: InputMaybe<Scalars['Int']>;
  page_size?: InputMaybe<Scalars['Int']>;
};


export type Query_RootGetItemsInBonusLootboxBySlugArgs = {
  lootbox_slug: Scalars['String'];
};


export type Query_RootGetItemsInFreeLootboxArgs = {
  lootbox_id: Scalars['uuid'];
  rt?: InputMaybe<Scalars['uuid']>;
};


export type Query_RootGetItemsInFreeLootboxBySlugArgs = {
  lootbox_slug: Scalars['String'];
};


export type Query_RootGetItemsInLootboxBySlugArgs = {
  lootbox_slug: Scalars['String'];
};


export type Query_RootGetItemsListByNameArgs = {
  names?: InputMaybe<GetItemsListAllInput>;
};


export type Query_RootGetItemsOutputStatusFromMarketMassArgs = {
  data_input: ItemsOutputStatusInput;
};


export type Query_RootGetLootboxInfoBySlugArgs = {
  slug: Scalars['String'];
};


export type Query_RootGetMainpageLootboxesArgs = {
  filters?: InputMaybe<MainPageLootboxFilterDto>;
};


export type Query_RootGetPageMetasArgs = {
  metas_input?: InputMaybe<MetasInput>;
};


export type Query_RootGetPossibleItemsForUpgradeArgs = {
  filters: ItemsForUpgradeFiltersDto;
};


export type Query_RootGetTokensQueueArgs = {
  pass_to_tg: Scalars['Boolean'];
};


export type Query_RootGetUserSettingsPageDataArgs = {
  push_token?: InputMaybe<Scalars['String']>;
};


export type Query_RootGiveawayGetItemArgs = {
  giveaway_id: Scalars['String'];
};


export type Query_RootGiveawayParticipateArgs = {
  giveaway_id: Scalars['String'];
};


export type Query_RootLootboxOpensCountsArgs = {
  distinct_on?: InputMaybe<Array<LootboxOpensCounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LootboxOpensCounts_Order_By>>;
  where?: InputMaybe<LootboxOpensCounts_Bool_Exp>;
};


export type Query_RootLootboxOpensCounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LootboxOpensCounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LootboxOpensCounts_Order_By>>;
  where?: InputMaybe<LootboxOpensCounts_Bool_Exp>;
};


export type Query_RootSendEmailToUserArgs = {
  dto: SendEmailToUserInput;
};


export type Query_RootTestGetMainpageLootboxesArgs = {
  filters?: InputMaybe<TestMainPageLootboxFilterDto>;
};


export type Query_RootTopDropStripViewArgs = {
  distinct_on?: InputMaybe<Array<TopDropStripView_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TopDropStripView_Order_By>>;
  where?: InputMaybe<TopDropStripView_Bool_Exp>;
};


export type Query_RootTopDropStripView_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TopDropStripView_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TopDropStripView_Order_By>>;
  where?: InputMaybe<TopDropStripView_Bool_Exp>;
};


export type Query_RootUpdatedMailAlertsCustomerArgs = {
  email: Scalars['String'];
  is_active: Scalars['Boolean'];
};


export type Query_RootUsersGiveawaysRatingArgs = {
  page?: InputMaybe<Scalars['Int']>;
  page_size?: InputMaybe<Scalars['Int']>;
  won_count?: InputMaybe<Scalars['Boolean']>;
};


export type Query_RootValidateItemsOutStatusMassArgs = {
  data_input: ValidateItemsOutStatusMassInput;
};


export type Query_RootAccount_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Account_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Items_Names_Order_By>>;
  where?: InputMaybe<Account_Items_Names_Bool_Exp>;
};


export type Query_RootAccount_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Items_Names_Order_By>>;
  where?: InputMaybe<Account_Items_Names_Bool_Exp>;
};


export type Query_RootAccount_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootAdmin_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Admin_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_Items_Names_Order_By>>;
  where?: InputMaybe<Admin_Items_Names_Bool_Exp>;
};


export type Query_RootAdmin_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admin_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_Items_Names_Order_By>>;
  where?: InputMaybe<Admin_Items_Names_Bool_Exp>;
};


export type Query_RootAdmin_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootAdmin_User_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Admin_User_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_User_Params_Order_By>>;
  where?: InputMaybe<Admin_User_Params_Bool_Exp>;
};


export type Query_RootAdmin_User_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admin_User_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_User_Params_Order_By>>;
  where?: InputMaybe<Admin_User_Params_Bool_Exp>;
};


export type Query_RootAdmin_User_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootAmp_Sessions_DataArgs = {
  distinct_on?: InputMaybe<Array<Amp_Sessions_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Amp_Sessions_Data_Order_By>>;
  where?: InputMaybe<Amp_Sessions_Data_Bool_Exp>;
};


export type Query_RootAmp_Sessions_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Amp_Sessions_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Amp_Sessions_Data_Order_By>>;
  where?: InputMaybe<Amp_Sessions_Data_Bool_Exp>;
};


export type Query_RootAmp_Sessions_Data_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootBanned_IpsArgs = {
  distinct_on?: InputMaybe<Array<Banned_Ips_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banned_Ips_Order_By>>;
  where?: InputMaybe<Banned_Ips_Bool_Exp>;
};


export type Query_RootBanned_Ips_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Banned_Ips_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banned_Ips_Order_By>>;
  where?: InputMaybe<Banned_Ips_Bool_Exp>;
};


export type Query_RootBanned_Ips_By_PkArgs = {
  ip: Scalars['String'];
};


export type Query_RootBanned_Users_For_MultiaccountArgs = {
  distinct_on?: InputMaybe<Array<Banned_Users_For_Multiaccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banned_Users_For_Multiaccount_Order_By>>;
  where?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
};


export type Query_RootBanned_Users_For_Multiaccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Banned_Users_For_Multiaccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banned_Users_For_Multiaccount_Order_By>>;
  where?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
};


export type Query_RootBanned_Users_For_Multiaccount_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootBillArgs = {
  distinct_on?: InputMaybe<Array<Bill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bill_Order_By>>;
  where?: InputMaybe<Bill_Bool_Exp>;
};


export type Query_RootBill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bill_Order_By>>;
  where?: InputMaybe<Bill_Bool_Exp>;
};


export type Query_RootBill_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBonus_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Params_Order_By>>;
  where?: InputMaybe<Bonus_Params_Bool_Exp>;
};


export type Query_RootBonus_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Params_Order_By>>;
  where?: InputMaybe<Bonus_Params_Bool_Exp>;
};


export type Query_RootBonus_Params_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  param_name: Scalars['String'];
};


export type Query_RootBonus_Wheel_RewardsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Rewards_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Rewards_Bool_Exp>;
};


export type Query_RootBonus_Wheel_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Rewards_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Rewards_Bool_Exp>;
};


export type Query_RootBonus_Wheel_Rewards_By_PkArgs = {
  reward_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheel_Ticket_TypeArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Bool_Exp>;
};


export type Query_RootBonus_Wheel_Ticket_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Bool_Exp>;
};


export type Query_RootBonus_Wheel_Ticket_Type_By_PkArgs = {
  ticket_type_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheel_Ticket_Type_Name_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>;
};


export type Query_RootBonus_Wheel_Ticket_Type_Name_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>;
};


export type Query_RootBonus_Wheel_Ticket_Type_Name_Localization_By_PkArgs = {
  locale: Scalars['String'];
  ticket_type_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheel_User_RewardsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_User_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_User_Rewards_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_User_Rewards_Bool_Exp>;
};


export type Query_RootBonus_Wheel_User_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_User_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_User_Rewards_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_User_Rewards_Bool_Exp>;
};


export type Query_RootBonus_Wheel_User_Rewards_By_PkArgs = {
  user_reward_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheel_User_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_User_Tickets_Bool_Exp>;
};


export type Query_RootBonus_Wheel_User_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_User_Tickets_Bool_Exp>;
};


export type Query_RootBonus_Wheel_User_Tickets_By_PkArgs = {
  ticket_id: Scalars['uuid'];
};


export type Query_RootBonus_WheelsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bool_Exp>;
};


export type Query_RootBonus_Wheels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Bonus_SkinsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonus_Skins_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Bonus_Skins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonus_Skins_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Bonus_Skins_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  item_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheels_BonusesArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Bonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheels_Bonuses_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Bonuses_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Bonuses_Localization_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
};


export type Query_RootBonus_Wheels_Bonuses_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Bonuses_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Bonuses_Params_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheels_Bonuses_Params_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Bonuses_Params_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Bonuses_Params_Localization_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
};


export type Query_RootBonus_Wheels_By_PkArgs = {
  wheel_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheels_GeneratorArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Generator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Generator_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Generator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Generator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Generator_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Generator_BonusesArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Generator_Bonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Generator_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  wheel_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheels_Generator_By_PkArgs = {
  wheel_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheels_Items_In_WheelArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Items_In_Wheel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Bool_Exp>;
};


export type Query_RootBonus_Wheels_Items_In_Wheel_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  wheel_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheels_User_CheckArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Check_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Bool_Exp>;
};


export type Query_RootBonus_Wheels_User_Check_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Check_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Bool_Exp>;
};


export type Query_RootBonus_Wheels_User_Check_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheels_User_Check_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Check_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Check_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Params_Bool_Exp>;
};


export type Query_RootBonus_Wheels_User_Check_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Check_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Check_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Params_Bool_Exp>;
};


export type Query_RootBonus_Wheels_User_Check_Params_By_PkArgs = {
  param_name: Scalars['String'];
};


export type Query_RootBonus_Wheels_User_OffersArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Bool_Exp>;
};


export type Query_RootBonus_Wheels_User_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Bool_Exp>;
};


export type Query_RootBonus_Wheels_User_Offers_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootBonus_Wheels_User_Offers_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Bool_Exp>;
};


export type Query_RootBonus_Wheels_User_Offers_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Bool_Exp>;
};


export type Query_RootBonus_Wheels_User_Offers_Params_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  stage: Scalars['Int'];
};


export type Query_RootBonus_Wheels_User_Offers_Params_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>;
};


export type Query_RootBonus_Wheels_User_Offers_Params_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>;
};


export type Query_RootBonus_Wheels_User_Offers_Params_Localization_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
  stage: Scalars['Int'];
};


export type Query_RootBonusesArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Order_By>>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};


export type Query_RootBonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Order_By>>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};


export type Query_RootBonuses_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBonuses_RegisterArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Register_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Register_Order_By>>;
  where?: InputMaybe<Bonuses_Register_Bool_Exp>;
};


export type Query_RootBonuses_Register_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Register_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Register_Order_By>>;
  where?: InputMaybe<Bonuses_Register_Bool_Exp>;
};


export type Query_RootBonuses_Register_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


export type Query_RootBonuses_Register_OffersArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Register_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Register_Offers_Order_By>>;
  where?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
};


export type Query_RootBonuses_Register_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Register_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Register_Offers_Order_By>>;
  where?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
};


export type Query_RootBonuses_Register_Offers_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootBoosters_Set_ContentArgs = {
  distinct_on?: InputMaybe<Array<Boosters_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Boosters_Set_Content_Order_By>>;
  where?: InputMaybe<Boosters_Set_Content_Bool_Exp>;
};


export type Query_RootBoosters_Set_Content_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Boosters_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Boosters_Set_Content_Order_By>>;
  where?: InputMaybe<Boosters_Set_Content_Bool_Exp>;
};


export type Query_RootBoosters_Set_Content_By_PkArgs = {
  content_id: Scalars['bigint'];
};


export type Query_RootBoosters_SetsArgs = {
  distinct_on?: InputMaybe<Array<Boosters_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Boosters_Sets_Order_By>>;
  where?: InputMaybe<Boosters_Sets_Bool_Exp>;
};


export type Query_RootBoosters_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Boosters_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Boosters_Sets_Order_By>>;
  where?: InputMaybe<Boosters_Sets_Bool_Exp>;
};


export type Query_RootBoosters_Sets_By_PkArgs = {
  set_id: Scalars['bigint'];
};


export type Query_RootBot_Account_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Bot_Account_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bot_Account_Settings_Order_By>>;
  where?: InputMaybe<Bot_Account_Settings_Bool_Exp>;
};


export type Query_RootBot_Account_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bot_Account_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bot_Account_Settings_Order_By>>;
  where?: InputMaybe<Bot_Account_Settings_Bool_Exp>;
};


export type Query_RootBot_Account_Settings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootBots_Lootboxes_Opens_CountArgs = {
  distinct_on?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Order_By>>;
  where?: InputMaybe<Bots_Lootboxes_Opens_Count_Bool_Exp>;
};


export type Query_RootBots_Lootboxes_Opens_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Order_By>>;
  where?: InputMaybe<Bots_Lootboxes_Opens_Count_Bool_Exp>;
};


export type Query_RootBots_Lootboxes_Opens_Count_By_PkArgs = {
  bot_id: Scalars['uuid'];
  lootbox_slug: Scalars['String'];
};


export type Query_RootCoins_Set_ContentArgs = {
  distinct_on?: InputMaybe<Array<Coins_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coins_Set_Content_Order_By>>;
  where?: InputMaybe<Coins_Set_Content_Bool_Exp>;
};


export type Query_RootCoins_Set_Content_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coins_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coins_Set_Content_Order_By>>;
  where?: InputMaybe<Coins_Set_Content_Bool_Exp>;
};


export type Query_RootCoins_Set_Content_By_PkArgs = {
  content_id: Scalars['bigint'];
};


export type Query_RootCoins_SetsArgs = {
  distinct_on?: InputMaybe<Array<Coins_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coins_Sets_Order_By>>;
  where?: InputMaybe<Coins_Sets_Bool_Exp>;
};


export type Query_RootCoins_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coins_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coins_Sets_Order_By>>;
  where?: InputMaybe<Coins_Sets_Bool_Exp>;
};


export type Query_RootCoins_Sets_By_PkArgs = {
  set_id: Scalars['bigint'];
};


export type Query_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Query_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Query_RootCompany_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCouponsArgs = {
  distinct_on?: InputMaybe<Array<Coupons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupons_Order_By>>;
  where?: InputMaybe<Coupons_Bool_Exp>;
};


export type Query_RootCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupons_Order_By>>;
  where?: InputMaybe<Coupons_Bool_Exp>;
};


export type Query_RootCoupons_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCsgomarket_Token_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Csgomarket_Token_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Csgomarket_Token_Balance_Order_By>>;
  where?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
};


export type Query_RootCsgomarket_Token_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Csgomarket_Token_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Csgomarket_Token_Balance_Order_By>>;
  where?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
};


export type Query_RootCsgomarket_Token_Balance_By_PkArgs = {
  datetime: Scalars['timestamp'];
  token_id: Scalars['uuid'];
};


export type Query_RootCsgomarket_TokensArgs = {
  distinct_on?: InputMaybe<Array<Csgomarket_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Csgomarket_Tokens_Order_By>>;
  where?: InputMaybe<Csgomarket_Tokens_Bool_Exp>;
};


export type Query_RootCsgomarket_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Csgomarket_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Csgomarket_Tokens_Order_By>>;
  where?: InputMaybe<Csgomarket_Tokens_Bool_Exp>;
};


export type Query_RootCsgomarket_Tokens_By_PkArgs = {
  token_id: Scalars['uuid'];
};


export type Query_RootCurrency_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Currency_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_History_Order_By>>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};


export type Query_RootCurrency_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_History_Order_By>>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};


export type Query_RootCurrency_History_By_PkArgs = {
  created_at: Scalars['timestamp'];
  currency_id: Scalars['Int'];
};


export type Query_RootCurrencysArgs = {
  distinct_on?: InputMaybe<Array<Currencys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencys_Order_By>>;
  where?: InputMaybe<Currencys_Bool_Exp>;
};


export type Query_RootCurrencys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currencys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencys_Order_By>>;
  where?: InputMaybe<Currencys_Bool_Exp>;
};


export type Query_RootCurrencys_By_PkArgs = {
  currency_id: Scalars['Int'];
};


export type Query_RootDaily_Deposit_SumArgs = {
  distinct_on?: InputMaybe<Array<Daily_Deposit_Sum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Deposit_Sum_Order_By>>;
  where?: InputMaybe<Daily_Deposit_Sum_Bool_Exp>;
};


export type Query_RootDaily_Deposit_Sum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Deposit_Sum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Deposit_Sum_Order_By>>;
  where?: InputMaybe<Daily_Deposit_Sum_Bool_Exp>;
};


export type Query_RootDaily_Deposit_Sum_By_PkArgs = {
  created_at: Scalars['date'];
};


export type Query_RootEmails_From_Upgrade_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Emails_From_Upgrade_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_From_Upgrade_Question_Order_By>>;
  where?: InputMaybe<Emails_From_Upgrade_Question_Bool_Exp>;
};


export type Query_RootEmails_From_Upgrade_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_From_Upgrade_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_From_Upgrade_Question_Order_By>>;
  where?: InputMaybe<Emails_From_Upgrade_Question_Bool_Exp>;
};


export type Query_RootEmails_From_Upgrade_Question_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootExecution_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Execution_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Execution_Methods_Order_By>>;
  where?: InputMaybe<Execution_Methods_Bool_Exp>;
};


export type Query_RootExecution_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Execution_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Execution_Methods_Order_By>>;
  where?: InputMaybe<Execution_Methods_Bool_Exp>;
};


export type Query_RootExecution_Methods_By_PkArgs = {
  method_id: Scalars['Int'];
};


export type Query_RootFaq_TableArgs = {
  distinct_on?: InputMaybe<Array<Faq_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Table_Order_By>>;
  where?: InputMaybe<Faq_Table_Bool_Exp>;
};


export type Query_RootFaq_Table_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faq_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Table_Order_By>>;
  where?: InputMaybe<Faq_Table_Bool_Exp>;
};


export type Query_RootFaq_Table_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFixed_Items_Generator_BoostersArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Boosters_Bool_Exp>;
};


export type Query_RootFixed_Items_Generator_Boosters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Boosters_Bool_Exp>;
};


export type Query_RootFixed_Items_Generator_Boosters_By_PkArgs = {
  booster_id: Scalars['uuid'];
  generator_lootbox_id: Scalars['uuid'];
};


export type Query_RootFixed_Items_Generator_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Items_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Items_Bool_Exp>;
};


export type Query_RootFixed_Items_Generator_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Items_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Items_Bool_Exp>;
};


export type Query_RootFixed_Items_Generator_Items_By_PkArgs = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};


export type Query_RootFixed_Items_Generator_LootboxesArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Lootboxes_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Lootboxes_Bool_Exp>;
};


export type Query_RootFixed_Items_Generator_Lootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Lootboxes_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Lootboxes_Bool_Exp>;
};


export type Query_RootFixed_Items_Generator_Lootboxes_By_PkArgs = {
  lootbox_generator_id: Scalars['uuid'];
};


export type Query_RootGet_Item_Output_StatusArgs = {
  custom_id: Scalars['uuid'];
};


export type Query_RootGet_Ticket_Counts_By_TypeArgs = {
  args: Get_Ticket_Counts_By_Type_Args;
  distinct_on?: InputMaybe<Array<Ticket_Counts_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ticket_Counts_Result_Order_By>>;
  where?: InputMaybe<Ticket_Counts_Result_Bool_Exp>;
};


export type Query_RootGet_Ticket_Counts_By_Type_AggregateArgs = {
  args: Get_Ticket_Counts_By_Type_Args;
  distinct_on?: InputMaybe<Array<Ticket_Counts_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ticket_Counts_Result_Order_By>>;
  where?: InputMaybe<Ticket_Counts_Result_Bool_Exp>;
};


export type Query_RootGet_User_Inventory_OneArgs = {
  arg1: Scalars['uuid'];
};


export type Query_RootGet_User_ProfileArgs = {
  arg1?: InputMaybe<Scalars['uuid']>;
};


export type Query_RootGiveaway_MembersArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Members_Order_By>>;
  where?: InputMaybe<Giveaway_Members_Bool_Exp>;
};


export type Query_RootGiveaway_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Members_Order_By>>;
  where?: InputMaybe<Giveaway_Members_Bool_Exp>;
};


export type Query_RootGiveaway_Members_By_PkArgs = {
  giveaway_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootGiveaway_Members_RatingArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Members_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Members_Rating_Order_By>>;
  where?: InputMaybe<Giveaway_Members_Rating_Bool_Exp>;
};


export type Query_RootGiveaway_Members_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Members_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Members_Rating_Order_By>>;
  where?: InputMaybe<Giveaway_Members_Rating_Bool_Exp>;
};


export type Query_RootGiveaway_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Params_Order_By>>;
  where?: InputMaybe<Giveaway_Params_Bool_Exp>;
};


export type Query_RootGiveaway_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Params_Order_By>>;
  where?: InputMaybe<Giveaway_Params_Bool_Exp>;
};


export type Query_RootGiveaway_Params_By_PkArgs = {
  giveaway_id: Scalars['uuid'];
};


export type Query_RootGiveawaysArgs = {
  distinct_on?: InputMaybe<Array<Giveaways_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaways_Order_By>>;
  where?: InputMaybe<Giveaways_Bool_Exp>;
};


export type Query_RootGiveaways_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Giveaways_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaways_Order_By>>;
  where?: InputMaybe<Giveaways_Bool_Exp>;
};


export type Query_RootGiveaways_By_PkArgs = {
  giveaway_id: Scalars['uuid'];
};


export type Query_RootGroup_Items_CsgomarketArgs = {
  distinct_on?: InputMaybe<Array<Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
};


export type Query_RootGroup_Items_Csgomarket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
};


export type Query_RootGroup_Items_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootHistory_Group_Items_CsgomarketArgs = {
  distinct_on?: InputMaybe<Array<History_Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Group_Items_Csgomarket_Bool_Exp>;
};


export type Query_RootHistory_Group_Items_Csgomarket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<History_Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Group_Items_Csgomarket_Bool_Exp>;
};


export type Query_RootHistory_Group_Items_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootHistory_Items_CsgomarketArgs = {
  distinct_on?: InputMaybe<Array<History_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Items_Csgomarket_Bool_Exp>;
};


export type Query_RootHistory_Items_Csgomarket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<History_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Items_Csgomarket_Bool_Exp>;
};


export type Query_RootHistory_Items_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootItem_Out_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Item_Out_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Out_Transactions_Order_By>>;
  where?: InputMaybe<Item_Out_Transactions_Bool_Exp>;
};


export type Query_RootItem_Out_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Out_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Out_Transactions_Order_By>>;
  where?: InputMaybe<Item_Out_Transactions_Bool_Exp>;
};


export type Query_RootItem_Out_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Query_RootItemsArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Query_RootItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Query_RootItems_By_PkArgs = {
  item_id: Scalars['uuid'];
};


export type Query_RootItems_CsgomarketArgs = {
  distinct_on?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};


export type Query_RootItems_Csgomarket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};


export type Query_RootItems_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootItems_DropsArgs = {
  distinct_on?: InputMaybe<Array<Items_Drops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Drops_Order_By>>;
  where?: InputMaybe<Items_Drops_Bool_Exp>;
};


export type Query_RootItems_Drops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Drops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Drops_Order_By>>;
  where?: InputMaybe<Items_Drops_Bool_Exp>;
};


export type Query_RootItems_Drops_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootItems_For_UpgradeArgs = {
  distinct_on?: InputMaybe<Array<Items_For_Upgrade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_For_Upgrade_Order_By>>;
  where?: InputMaybe<Items_For_Upgrade_Bool_Exp>;
};


export type Query_RootItems_For_Upgrade_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_For_Upgrade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_For_Upgrade_Order_By>>;
  where?: InputMaybe<Items_For_Upgrade_Bool_Exp>;
};


export type Query_RootItems_For_Upgrade_By_PkArgs = {
  item_id: Scalars['uuid'];
};


export type Query_RootItems_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Items_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_History_Order_By>>;
  where?: InputMaybe<Items_History_Bool_Exp>;
};


export type Query_RootItems_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_History_Order_By>>;
  where?: InputMaybe<Items_History_Bool_Exp>;
};


export type Query_RootItems_History_By_PkArgs = {
  created_at: Scalars['timestamp'];
  item_id: Scalars['uuid'];
};


export type Query_RootItems_In_LootboxArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_Bool_Exp>;
};


export type Query_RootItems_In_Lootbox_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_Bool_Exp>;
};


export type Query_RootItems_In_Lootbox_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Query_RootItems_In_Lootbox_ViewArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_View_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
};


export type Query_RootItems_In_Lootbox_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_View_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
};


export type Query_RootItems_In_Lootbox_View_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Query_RootItems_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Items_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Items_Names_Order_By>>;
  where?: InputMaybe<Items_Items_Names_Bool_Exp>;
};


export type Query_RootItems_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Items_Names_Order_By>>;
  where?: InputMaybe<Items_Items_Names_Bool_Exp>;
};


export type Query_RootItems_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootItems_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Items_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Localization_Order_By>>;
  where?: InputMaybe<Items_Localization_Bool_Exp>;
};


export type Query_RootItems_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Localization_Order_By>>;
  where?: InputMaybe<Items_Localization_Bool_Exp>;
};


export type Query_RootItems_Localization_By_PkArgs = {
  item_name_key: Scalars['String'];
  locale: Scalars['String'];
};


export type Query_RootItems_NamesArgs = {
  distinct_on?: InputMaybe<Array<Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Names_Order_By>>;
  where?: InputMaybe<Items_Names_Bool_Exp>;
};


export type Query_RootItems_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Names_Order_By>>;
  where?: InputMaybe<Items_Names_Bool_Exp>;
};


export type Query_RootItems_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootItems_QualityArgs = {
  distinct_on?: InputMaybe<Array<Items_Quality_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Quality_Order_By>>;
  where?: InputMaybe<Items_Quality_Bool_Exp>;
};


export type Query_RootItems_Quality_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Quality_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Quality_Order_By>>;
  where?: InputMaybe<Items_Quality_Bool_Exp>;
};


export type Query_RootItems_Quality_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootItems_Quality_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Items_Quality_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Quality_Localization_Order_By>>;
  where?: InputMaybe<Items_Quality_Localization_Bool_Exp>;
};


export type Query_RootItems_Quality_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Quality_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Quality_Localization_Order_By>>;
  where?: InputMaybe<Items_Quality_Localization_Bool_Exp>;
};


export type Query_RootItems_Quality_Localization_By_PkArgs = {
  locale: Scalars['String'];
  quality_name_key: Scalars['String'];
};


export type Query_RootItems_RarityArgs = {
  distinct_on?: InputMaybe<Array<Items_Rarity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Rarity_Order_By>>;
  where?: InputMaybe<Items_Rarity_Bool_Exp>;
};


export type Query_RootItems_Rarity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Rarity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Rarity_Order_By>>;
  where?: InputMaybe<Items_Rarity_Bool_Exp>;
};


export type Query_RootItems_Rarity_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootItems_Rarity_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Items_Rarity_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Rarity_Localization_Order_By>>;
  where?: InputMaybe<Items_Rarity_Localization_Bool_Exp>;
};


export type Query_RootItems_Rarity_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Rarity_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Rarity_Localization_Order_By>>;
  where?: InputMaybe<Items_Rarity_Localization_Bool_Exp>;
};


export type Query_RootItems_Rarity_Localization_By_PkArgs = {
  rarity_name_key: Scalars['String'];
};


export type Query_RootItems_TypeArgs = {
  distinct_on?: InputMaybe<Array<Items_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Type_Order_By>>;
  where?: InputMaybe<Items_Type_Bool_Exp>;
};


export type Query_RootItems_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Type_Order_By>>;
  where?: InputMaybe<Items_Type_Bool_Exp>;
};


export type Query_RootItems_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootItems_Type_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Items_Type_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Type_Localization_Order_By>>;
  where?: InputMaybe<Items_Type_Localization_Bool_Exp>;
};


export type Query_RootItems_Type_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Type_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Type_Localization_Order_By>>;
  where?: InputMaybe<Items_Type_Localization_Bool_Exp>;
};


export type Query_RootItems_Type_Localization_By_PkArgs = {
  locale: Scalars['String'];
  type_name_key: Scalars['String'];
};


export type Query_RootItems_Weapon_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Items_Weapon_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Weapon_Localization_Order_By>>;
  where?: InputMaybe<Items_Weapon_Localization_Bool_Exp>;
};


export type Query_RootItems_Weapon_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Weapon_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Weapon_Localization_Order_By>>;
  where?: InputMaybe<Items_Weapon_Localization_Bool_Exp>;
};


export type Query_RootItems_Weapon_Localization_By_PkArgs = {
  locale: Scalars['String'];
  weapon_name_key: Scalars['String'];
};


export type Query_RootItems_WeaponsArgs = {
  distinct_on?: InputMaybe<Array<Items_Weapons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Weapons_Order_By>>;
  where?: InputMaybe<Items_Weapons_Bool_Exp>;
};


export type Query_RootItems_Weapons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Weapons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Weapons_Order_By>>;
  where?: InputMaybe<Items_Weapons_Bool_Exp>;
};


export type Query_RootItems_Weapons_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootList_Items_RandomArgs = {
  args?: InputMaybe<List_Items_Random_Args>;
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Query_RootList_Items_Random_AggregateArgs = {
  args?: InputMaybe<List_Items_Random_Args>;
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Query_RootLootbox_Booster_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Items_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Items_Bool_Exp>;
};


export type Query_RootLootbox_Booster_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Items_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Items_Bool_Exp>;
};


export type Query_RootLootbox_Booster_Items_By_PkArgs = {
  item_id: Scalars['uuid'];
};


export type Query_RootLootbox_Booster_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Localization_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
};


export type Query_RootLootbox_Booster_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Localization_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
};


export type Query_RootLootbox_Booster_Localization_By_PkArgs = {
  booster_name_key: Scalars['String'];
  locale: Scalars['String'];
};


export type Query_RootLootbox_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Category_Order_By>>;
  where?: InputMaybe<Lootbox_Category_Bool_Exp>;
};


export type Query_RootLootbox_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Category_Order_By>>;
  where?: InputMaybe<Lootbox_Category_Bool_Exp>;
};


export type Query_RootLootbox_Category_By_PkArgs = {
  category_id: Scalars['Int'];
};


export type Query_RootLootbox_Items_SetsArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Items_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Items_Sets_Order_By>>;
  where?: InputMaybe<Lootbox_Items_Sets_Bool_Exp>;
};


export type Query_RootLootbox_Items_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Items_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Items_Sets_Order_By>>;
  where?: InputMaybe<Lootbox_Items_Sets_Bool_Exp>;
};


export type Query_RootLootbox_Items_Sets_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
  set_id: Scalars['Int'];
};


export type Query_RootLootbox_ServiceArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Service_Order_By>>;
  where?: InputMaybe<Lootbox_Service_Bool_Exp>;
};


export type Query_RootLootbox_Service_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Service_Order_By>>;
  where?: InputMaybe<Lootbox_Service_Bool_Exp>;
};


export type Query_RootLootbox_Service_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLootboxesArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Bool_Exp>;
};


export type Query_RootLootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Bool_Exp>;
};


export type Query_RootLootboxes_Available_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Available_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Available_Statuses_Order_By>>;
  where?: InputMaybe<Lootboxes_Available_Statuses_Bool_Exp>;
};


export type Query_RootLootboxes_Available_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Available_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Available_Statuses_Order_By>>;
  where?: InputMaybe<Lootboxes_Available_Statuses_Bool_Exp>;
};


export type Query_RootLootboxes_Available_Statuses_By_PkArgs = {
  status_id: Scalars['Int'];
  status_sub_type: Scalars['String'];
};


export type Query_RootLootboxes_BonusArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Bool_Exp>;
};


export type Query_RootLootboxes_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Bool_Exp>;
};


export type Query_RootLootboxes_Bonus_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


export type Query_RootLootboxes_Bonus_Generator_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Items_Bool_Exp>;
};


export type Query_RootLootboxes_Bonus_Generator_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Items_Bool_Exp>;
};


export type Query_RootLootboxes_Bonus_Generator_Items_By_PkArgs = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};


export type Query_RootLootboxes_Bonus_Generator_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Params_Bool_Exp>;
};


export type Query_RootLootboxes_Bonus_Generator_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Params_Bool_Exp>;
};


export type Query_RootLootboxes_Bonus_Generator_Params_By_PkArgs = {
  lootbox_generator_id: Scalars['uuid'];
};


export type Query_RootLootboxes_Bonus_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Items_Bool_Exp>;
};


export type Query_RootLootboxes_Bonus_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Items_Bool_Exp>;
};


export type Query_RootLootboxes_Bonus_Items_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Query_RootLootboxes_Bonus_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
};


export type Query_RootLootboxes_Bonus_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
};


export type Query_RootLootboxes_Bonus_Views_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Query_RootLootboxes_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


export type Query_RootLootboxes_Dynamic_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Dynamic_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Dynamic_Category_Order_By>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Bool_Exp>;
};


export type Query_RootLootboxes_Dynamic_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Dynamic_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Dynamic_Category_Order_By>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Bool_Exp>;
};


export type Query_RootLootboxes_Dynamic_Category_By_PkArgs = {
  category_id: Scalars['Int'];
};


export type Query_RootLootboxes_Dynamic_Category_LootboxesArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>;
};


export type Query_RootLootboxes_Dynamic_Category_Lootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>;
};


export type Query_RootLootboxes_Dynamic_Category_Lootboxes_By_PkArgs = {
  category_id: Scalars['Int'];
  lootbox_slug: Scalars['String'];
};


export type Query_RootLootboxes_FreeArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Bool_Exp>;
};


export type Query_RootLootboxes_Free_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Bool_Exp>;
};


export type Query_RootLootboxes_Free_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


export type Query_RootLootboxes_Free_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Category_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Category_Bool_Exp>;
};


export type Query_RootLootboxes_Free_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Category_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Category_Bool_Exp>;
};


export type Query_RootLootboxes_Free_Category_By_PkArgs = {
  category_id: Scalars['Int'];
};


export type Query_RootLootboxes_Free_Generator_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Items_Bool_Exp>;
};


export type Query_RootLootboxes_Free_Generator_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Items_Bool_Exp>;
};


export type Query_RootLootboxes_Free_Generator_Items_By_PkArgs = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};


export type Query_RootLootboxes_Free_Generator_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Params_Bool_Exp>;
};


export type Query_RootLootboxes_Free_Generator_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Params_Bool_Exp>;
};


export type Query_RootLootboxes_Free_Generator_Params_By_PkArgs = {
  lootbox_generator_id: Scalars['uuid'];
};


export type Query_RootLootboxes_Free_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Items_Bool_Exp>;
};


export type Query_RootLootboxes_Free_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Items_Bool_Exp>;
};


export type Query_RootLootboxes_Free_Items_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Query_RootLootboxes_Free_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
};


export type Query_RootLootboxes_Free_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
};


export type Query_RootLootboxes_Free_Views_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Query_RootLootboxes_Generator_MechanicsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Generator_Mechanics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Generator_Mechanics_Order_By>>;
  where?: InputMaybe<Lootboxes_Generator_Mechanics_Bool_Exp>;
};


export type Query_RootLootboxes_Generator_Mechanics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Generator_Mechanics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Generator_Mechanics_Order_By>>;
  where?: InputMaybe<Lootboxes_Generator_Mechanics_Bool_Exp>;
};


export type Query_RootLootboxes_Generator_Mechanics_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLootboxes_Generator_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Generator_Params_Bool_Exp>;
};


export type Query_RootLootboxes_Generator_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Generator_Params_Bool_Exp>;
};


export type Query_RootLootboxes_Generator_Params_By_PkArgs = {
  parent_lootbox_id: Scalars['uuid'];
};


export type Query_RootLootboxes_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_History_Order_By>>;
  where?: InputMaybe<Lootboxes_History_Bool_Exp>;
};


export type Query_RootLootboxes_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_History_Order_By>>;
  where?: InputMaybe<Lootboxes_History_Bool_Exp>;
};


export type Query_RootLootboxes_History_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
  lootbox_parent_id: Scalars['uuid'];
};


export type Query_RootLootboxes_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Items_Names_Order_By>>;
  where?: InputMaybe<Lootboxes_Items_Names_Bool_Exp>;
};


export type Query_RootLootboxes_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Items_Names_Order_By>>;
  where?: InputMaybe<Lootboxes_Items_Names_Bool_Exp>;
};


export type Query_RootLootboxes_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootLootboxes_Top_DropArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Top_Drop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Top_Drop_Order_By>>;
  where?: InputMaybe<Lootboxes_Top_Drop_Bool_Exp>;
};


export type Query_RootLootboxes_Top_Drop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Top_Drop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Top_Drop_Order_By>>;
  where?: InputMaybe<Lootboxes_Top_Drop_Bool_Exp>;
};


export type Query_RootLootboxes_Top_Drop_By_PkArgs = {
  drop_id: Scalars['uuid'];
};


export type Query_RootMail_Autosending_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mail_Autosending_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Autosending_Types_Order_By>>;
  where?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
};


export type Query_RootMail_Autosending_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Autosending_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Autosending_Types_Order_By>>;
  where?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
};


export type Query_RootMail_Autosending_Types_By_PkArgs = {
  mail_id: Scalars['uuid'];
};


export type Query_RootMail_BonusesArgs = {
  distinct_on?: InputMaybe<Array<Mail_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Bonuses_Order_By>>;
  where?: InputMaybe<Mail_Bonuses_Bool_Exp>;
};


export type Query_RootMail_Bonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Bonuses_Order_By>>;
  where?: InputMaybe<Mail_Bonuses_Bool_Exp>;
};


export type Query_RootMail_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


export type Query_RootMail_Bonuses_OffersArgs = {
  distinct_on?: InputMaybe<Array<Mail_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
};


export type Query_RootMail_Bonuses_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
};


export type Query_RootMail_Bonuses_Offers_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootMail_Mailing_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Callbacks_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Callbacks_Bool_Exp>;
};


export type Query_RootMail_Mailing_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Callbacks_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Callbacks_Bool_Exp>;
};


export type Query_RootMail_Mailing_Callbacks_By_PkArgs = {
  event_id: Scalars['String'];
};


export type Query_RootMail_Mailing_LinesArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Lines_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Lines_Bool_Exp>;
};


export type Query_RootMail_Mailing_Lines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Lines_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Lines_Bool_Exp>;
};


export type Query_RootMail_Mailing_Lines_By_PkArgs = {
  mail_id: Scalars['uuid'];
};


export type Query_RootMail_Mailing_ListsArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Lists_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Lists_Bool_Exp>;
};


export type Query_RootMail_Mailing_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Lists_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Lists_Bool_Exp>;
};


export type Query_RootMail_Mailing_Lists_By_PkArgs = {
  list_name: Scalars['String'];
};


export type Query_RootMail_Mailing_SchedulingArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Scheduling_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
};


export type Query_RootMail_Mailing_Scheduling_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Scheduling_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
};


export type Query_RootMail_Mailing_Scheduling_By_PkArgs = {
  mail_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootMail_Mailing_Sending_LogArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Sending_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Sending_Log_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Sending_Log_Bool_Exp>;
};


export type Query_RootMail_Mailing_Sending_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Sending_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Sending_Log_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Sending_Log_Bool_Exp>;
};


export type Query_RootMail_Mailing_Sending_Log_By_PkArgs = {
  mail_mg_id: Scalars['String'];
};


export type Query_RootMail_Mailing_TriggersArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Bool_Exp>;
};


export type Query_RootMail_Mailing_Triggers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Bool_Exp>;
};


export type Query_RootMail_Mailing_Triggers_By_PkArgs = {
  trigger_name: Scalars['String'];
  triggers_group_id: Scalars['uuid'];
};


export type Query_RootMail_Mailing_Triggers_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
};


export type Query_RootMail_Mailing_Triggers_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
};


export type Query_RootMail_Mailing_Triggers_Groups_By_PkArgs = {
  mail_id: Scalars['uuid'];
  triggers_group_id: Scalars['uuid'];
};


export type Query_RootMail_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Mail_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Templates_Order_By>>;
  where?: InputMaybe<Mail_Templates_Bool_Exp>;
};


export type Query_RootMail_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Templates_Order_By>>;
  where?: InputMaybe<Mail_Templates_Bool_Exp>;
};


export type Query_RootMail_Templates_By_PkArgs = {
  template_name: Scalars['String'];
};


export type Query_RootMainpage_CountersArgs = {
  distinct_on?: InputMaybe<Array<Mainpage_Counters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mainpage_Counters_Order_By>>;
  where?: InputMaybe<Mainpage_Counters_Bool_Exp>;
};


export type Query_RootMainpage_Counters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mainpage_Counters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mainpage_Counters_Order_By>>;
  where?: InputMaybe<Mainpage_Counters_Bool_Exp>;
};


export type Query_RootMainpage_Counters_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMaintenance_ModeArgs = {
  distinct_on?: InputMaybe<Array<Maintenance_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Maintenance_Mode_Order_By>>;
  where?: InputMaybe<Maintenance_Mode_Bool_Exp>;
};


export type Query_RootMaintenance_Mode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Maintenance_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Maintenance_Mode_Order_By>>;
  where?: InputMaybe<Maintenance_Mode_Bool_Exp>;
};


export type Query_RootMaintenance_Mode_By_PkArgs = {
  mode_type: Scalars['String'];
};


export type Query_RootMarket_Groups_ConnArgs = {
  distinct_on?: InputMaybe<Array<Market_Groups_Conn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Market_Groups_Conn_Order_By>>;
  where?: InputMaybe<Market_Groups_Conn_Bool_Exp>;
};


export type Query_RootMarket_Groups_Conn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Market_Groups_Conn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Market_Groups_Conn_Order_By>>;
  where?: InputMaybe<Market_Groups_Conn_Bool_Exp>;
};


export type Query_RootMarket_Groups_Conn_By_PkArgs = {
  item_hash_name: Scalars['String'];
};


export type Query_RootMetasArgs = {
  distinct_on?: InputMaybe<Array<Metas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metas_Order_By>>;
  where?: InputMaybe<Metas_Bool_Exp>;
};


export type Query_RootMetas_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metas_Order_By>>;
  where?: InputMaybe<Metas_Bool_Exp>;
};


export type Query_RootMetas_By_PkArgs = {
  meta_global_main_key: Scalars['String'];
  meta_global_sub_key: Scalars['String'];
  meta_key: Scalars['String'];
};


export type Query_RootMissionsArgs = {
  distinct_on?: InputMaybe<Array<Missions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Order_By>>;
  where?: InputMaybe<Missions_Bool_Exp>;
};


export type Query_RootMissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Order_By>>;
  where?: InputMaybe<Missions_Bool_Exp>;
};


export type Query_RootMissions_By_PkArgs = {
  mission_id: Scalars['uuid'];
};


export type Query_RootMissions_Onetime_Bonus_Case_ClaimsArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Bonus_Case_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Bonus_Case_Claims_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_Bool_Exp>;
};


export type Query_RootMissions_Onetime_Bonus_Case_Claims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Bonus_Case_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Bonus_Case_Claims_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_Bool_Exp>;
};


export type Query_RootMissions_Onetime_Bonus_Case_Claims_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootMissions_Onetime_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Params_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Params_Bool_Exp>;
};


export type Query_RootMissions_Onetime_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Params_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Params_Bool_Exp>;
};


export type Query_RootMissions_Onetime_Params_By_PkArgs = {
  mission_id: Scalars['uuid'];
};


export type Query_RootMissions_Onetime_Users_ProgressArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Users_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Users_Progress_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
};


export type Query_RootMissions_Onetime_Users_Progress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Users_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Users_Progress_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
};


export type Query_RootMissions_Onetime_Users_Progress_By_PkArgs = {
  mission_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootMissions_Permanent_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Missions_Permanent_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Permanent_Params_Order_By>>;
  where?: InputMaybe<Missions_Permanent_Params_Bool_Exp>;
};


export type Query_RootMissions_Permanent_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Permanent_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Permanent_Params_Order_By>>;
  where?: InputMaybe<Missions_Permanent_Params_Bool_Exp>;
};


export type Query_RootMissions_Permanent_Params_By_PkArgs = {
  mission_id: Scalars['uuid'];
};


export type Query_RootMissions_Permanent_User_ProgressArgs = {
  distinct_on?: InputMaybe<Array<Missions_Permanent_User_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Permanent_User_Progress_Order_By>>;
  where?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
};


export type Query_RootMissions_Permanent_User_Progress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Permanent_User_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Permanent_User_Progress_Order_By>>;
  where?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
};


export type Query_RootMissions_Permanent_User_Progress_By_PkArgs = {
  mission_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootMissions_TagsArgs = {
  distinct_on?: InputMaybe<Array<Missions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Tags_Order_By>>;
  where?: InputMaybe<Missions_Tags_Bool_Exp>;
};


export type Query_RootMissions_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Tags_Order_By>>;
  where?: InputMaybe<Missions_Tags_Bool_Exp>;
};


export type Query_RootMissions_Tags_By_PkArgs = {
  mission_id: Scalars['uuid'];
  mission_tag: Scalars['String'];
};


export type Query_RootMissions_Tags_NamesArgs = {
  distinct_on?: InputMaybe<Array<Missions_Tags_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Tags_Names_Order_By>>;
  where?: InputMaybe<Missions_Tags_Names_Bool_Exp>;
};


export type Query_RootMissions_Tags_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Tags_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Tags_Names_Order_By>>;
  where?: InputMaybe<Missions_Tags_Names_Bool_Exp>;
};


export type Query_RootMissions_Tags_Names_By_PkArgs = {
  tag_title: Scalars['String'];
};


export type Query_RootMissions_Vk_LinksArgs = {
  distinct_on?: InputMaybe<Array<Missions_Vk_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Vk_Links_Order_By>>;
  where?: InputMaybe<Missions_Vk_Links_Bool_Exp>;
};


export type Query_RootMissions_Vk_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Vk_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Vk_Links_Order_By>>;
  where?: InputMaybe<Missions_Vk_Links_Bool_Exp>;
};


export type Query_RootMissions_Vk_Links_By_PkArgs = {
  vk_id: Scalars['String'];
};


export type Query_RootNew_Year_Competition_Boosters_RewardsArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Boosters_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Boosters_Rewards_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Boosters_Rewards_Bool_Exp>;
};


export type Query_RootNew_Year_Competition_Boosters_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Boosters_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Boosters_Rewards_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Boosters_Rewards_Bool_Exp>;
};


export type Query_RootNew_Year_Competition_Boosters_Rewards_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNew_Year_Competition_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Bool_Exp>;
};


export type Query_RootNew_Year_Competition_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Bool_Exp>;
};


export type Query_RootNew_Year_Competition_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootNew_Year_Competition_Participants_PerformanceArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Performance_Bool_Exp>;
};


export type Query_RootNew_Year_Competition_Participants_Performance_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootNew_Year_Competition_Participants_RatingArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Rating_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Rating_Bool_Exp>;
};


export type Query_RootNew_Year_Competition_Participants_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Rating_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Rating_Bool_Exp>;
};


export type Query_RootNew_Year_Competition_TeamsArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Teams_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Teams_Bool_Exp>;
};


export type Query_RootNew_Year_Competition_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Teams_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Teams_Bool_Exp>;
};


export type Query_RootNew_Year_Competition_Teams_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNew_Year_Competition_Winner_TeamArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Winner_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Winner_Team_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Winner_Team_Bool_Exp>;
};


export type Query_RootNew_Year_Competition_Winner_Team_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Winner_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Winner_Team_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Winner_Team_Bool_Exp>;
};


export type Query_RootOperation_TypesArgs = {
  distinct_on?: InputMaybe<Array<Operation_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Types_Order_By>>;
  where?: InputMaybe<Operation_Types_Bool_Exp>;
};


export type Query_RootOperation_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Types_Order_By>>;
  where?: InputMaybe<Operation_Types_Bool_Exp>;
};


export type Query_RootOperation_Types_By_PkArgs = {
  operation_id: Scalars['Int'];
};


export type Query_RootParsers_Timestamp_LogArgs = {
  distinct_on?: InputMaybe<Array<Parsers_Timestamp_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parsers_Timestamp_Log_Order_By>>;
  where?: InputMaybe<Parsers_Timestamp_Log_Bool_Exp>;
};


export type Query_RootParsers_Timestamp_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parsers_Timestamp_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parsers_Timestamp_Log_Order_By>>;
  where?: InputMaybe<Parsers_Timestamp_Log_Bool_Exp>;
};


export type Query_RootParsers_Timestamp_Log_By_PkArgs = {
  parser_name: Scalars['String'];
};


export type Query_RootParticipants_Performance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Performance_Bool_Exp>;
};


export type Query_RootPartner_Balance_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balance_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
};


export type Query_RootPartner_Balance_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balance_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
};


export type Query_RootPartner_Balance_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Query_RootPartner_Offer_TypesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Offer_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Offer_Types_Order_By>>;
  where?: InputMaybe<Partner_Offer_Types_Bool_Exp>;
};


export type Query_RootPartner_Offer_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Offer_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Offer_Types_Order_By>>;
  where?: InputMaybe<Partner_Offer_Types_Bool_Exp>;
};


export type Query_RootPartner_Offer_Types_By_PkArgs = {
  type_id: Scalars['Int'];
};


export type Query_RootPartner_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Params_Order_By>>;
  where?: InputMaybe<Partner_Params_Bool_Exp>;
};


export type Query_RootPartner_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Params_Order_By>>;
  where?: InputMaybe<Partner_Params_Bool_Exp>;
};


export type Query_RootPartner_Params_AuditArgs = {
  distinct_on?: InputMaybe<Array<Partner_Params_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Params_Audit_Order_By>>;
  where?: InputMaybe<Partner_Params_Audit_Bool_Exp>;
};


export type Query_RootPartner_Params_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Params_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Params_Audit_Order_By>>;
  where?: InputMaybe<Partner_Params_Audit_Bool_Exp>;
};


export type Query_RootPartner_Params_Audit_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Query_RootPartner_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootPassport_InfoArgs = {
  distinct_on?: InputMaybe<Array<Passport_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Passport_Info_Order_By>>;
  where?: InputMaybe<Passport_Info_Bool_Exp>;
};


export type Query_RootPassport_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Passport_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Passport_Info_Order_By>>;
  where?: InputMaybe<Passport_Info_Bool_Exp>;
};


export type Query_RootPassport_Info_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPayment_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Methods_Order_By>>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};


export type Query_RootPayment_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Methods_Order_By>>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};


export type Query_RootPayment_Methods_By_PkArgs = {
  pm_method_id: Scalars['Int'];
};


export type Query_RootPayment_Methods_ViewArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Methods_View_Order_By>>;
  where?: InputMaybe<Payment_Methods_View_Bool_Exp>;
};


export type Query_RootPayment_Methods_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Methods_View_Order_By>>;
  where?: InputMaybe<Payment_Methods_View_Bool_Exp>;
};


export type Query_RootPayment_Methods_View_By_PkArgs = {
  method_id: Scalars['uuid'];
};


export type Query_RootPayment_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Payment_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Providers_Order_By>>;
  where?: InputMaybe<Payment_Providers_Bool_Exp>;
};


export type Query_RootPayment_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Providers_Order_By>>;
  where?: InputMaybe<Payment_Providers_Bool_Exp>;
};


export type Query_RootPayment_Providers_By_PkArgs = {
  ps_id: Scalars['uuid'];
};


export type Query_RootPayment_Recurring_TokensArgs = {
  distinct_on?: InputMaybe<Array<Payment_Recurring_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Recurring_Tokens_Order_By>>;
  where?: InputMaybe<Payment_Recurring_Tokens_Bool_Exp>;
};


export type Query_RootPayment_Recurring_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Recurring_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Recurring_Tokens_Order_By>>;
  where?: InputMaybe<Payment_Recurring_Tokens_Bool_Exp>;
};


export type Query_RootPayment_Recurring_Tokens_By_PkArgs = {
  pmrecur_id: Scalars['uuid'];
};


export type Query_RootPayment_RequisitesArgs = {
  distinct_on?: InputMaybe<Array<Payment_Requisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Requisites_Order_By>>;
  where?: InputMaybe<Payment_Requisites_Bool_Exp>;
};


export type Query_RootPayment_Requisites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Requisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Requisites_Order_By>>;
  where?: InputMaybe<Payment_Requisites_Bool_Exp>;
};


export type Query_RootPayment_Requisites_By_PkArgs = {
  pmrq_id: Scalars['uuid'];
};


export type Query_RootPayment_System_1payment_Transaction_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_1payment_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_1payment_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_1payment_Transaction_Requests_Bool_Exp>;
};


export type Query_RootPayment_System_1payment_Transaction_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_1payment_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_1payment_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_1payment_Transaction_Requests_Bool_Exp>;
};


export type Query_RootPayment_System_1payment_Transaction_Requests_By_PkArgs = {
  payment_transaction_id: Scalars['uuid'];
};


export type Query_RootPayment_System_Free_Kassa_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Free_Kassa_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Free_Kassa_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Bool_Exp>;
};


export type Query_RootPayment_System_Free_Kassa_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Free_Kassa_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Free_Kassa_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Bool_Exp>;
};


export type Query_RootPayment_System_Free_Kassa_Callbacks_By_PkArgs = {
  MERCHANT_ORDER_ID: Scalars['uuid'];
};


export type Query_RootPayment_System_Free_Kassa_Transaction_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Free_Kassa_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Free_Kassa_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp>;
};


export type Query_RootPayment_System_Free_Kassa_Transaction_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Free_Kassa_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Free_Kassa_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp>;
};


export type Query_RootPayment_System_Free_Kassa_Transaction_Requests_By_PkArgs = {
  o: Scalars['uuid'];
};


export type Query_RootPayment_System_Main_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Main_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Main_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_System_Main_Callbacks_Bool_Exp>;
};


export type Query_RootPayment_System_Main_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Main_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Main_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_System_Main_Callbacks_Bool_Exp>;
};


export type Query_RootPayment_System_Main_Callbacks_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Query_RootPayment_System_Main_Transaction_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Main_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Main_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_Main_Transaction_Requests_Bool_Exp>;
};


export type Query_RootPayment_System_Main_Transaction_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Main_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Main_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_Main_Transaction_Requests_Bool_Exp>;
};


export type Query_RootPayment_System_Main_Transaction_Requests_By_PkArgs = {
  orderId: Scalars['String'];
};


export type Query_RootPayment_Systems_1payment_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_1payment_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_1payment_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_Systems_1payment_Callbacks_Bool_Exp>;
};


export type Query_RootPayment_Systems_1payment_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_1payment_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_1payment_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_Systems_1payment_Callbacks_Bool_Exp>;
};


export type Query_RootPayment_Systems_1payment_Callbacks_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Query_RootPayment_Systems_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_Systems_Callbacks_Bool_Exp>;
};


export type Query_RootPayment_Systems_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_Systems_Callbacks_Bool_Exp>;
};


export type Query_RootPayment_Systems_Callbacks_By_PkArgs = {
  transaction_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootPayment_Systems_DataArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Data_Order_By>>;
  where?: InputMaybe<Payment_Systems_Data_Bool_Exp>;
};


export type Query_RootPayment_Systems_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Data_Order_By>>;
  where?: InputMaybe<Payment_Systems_Data_Bool_Exp>;
};


export type Query_RootPayment_Systems_Data_By_PkArgs = {
  ps_id: Scalars['uuid'];
};


export type Query_RootPayment_Systems_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Methods_Order_By>>;
  where?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
};


export type Query_RootPayment_Systems_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Methods_Order_By>>;
  where?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
};


export type Query_RootPayment_Systems_Methods_By_PkArgs = {
  pm_id: Scalars['Int'];
  pm_ps_type: Scalars['Int'];
};


export type Query_RootPayment_Systems_Transaction_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_Systems_Transaction_Requests_Bool_Exp>;
};


export type Query_RootPayment_Systems_Transaction_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_Systems_Transaction_Requests_Bool_Exp>;
};


export type Query_RootPayment_Systems_Transaction_Requests_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Query_RootPayment_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Transactions_Order_By>>;
  where?: InputMaybe<Payment_Transactions_Bool_Exp>;
};


export type Query_RootPayment_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Transactions_Order_By>>;
  where?: InputMaybe<Payment_Transactions_Bool_Exp>;
};


export type Query_RootPayment_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Query_RootProfile_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Items_Names_Order_By>>;
  where?: InputMaybe<Profile_Items_Names_Bool_Exp>;
};


export type Query_RootProfile_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Items_Names_Order_By>>;
  where?: InputMaybe<Profile_Items_Names_Bool_Exp>;
};


export type Query_RootProfile_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootPromo_LootboxesArgs = {
  distinct_on?: InputMaybe<Array<Promo_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Lootboxes_Order_By>>;
  where?: InputMaybe<Promo_Lootboxes_Bool_Exp>;
};


export type Query_RootPromo_Lootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Lootboxes_Order_By>>;
  where?: InputMaybe<Promo_Lootboxes_Bool_Exp>;
};


export type Query_RootPromo_Lootboxes_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


export type Query_RootPromo_UsersArgs = {
  distinct_on?: InputMaybe<Array<Promo_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Users_Order_By>>;
  where?: InputMaybe<Promo_Users_Bool_Exp>;
};


export type Query_RootPromo_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Users_Order_By>>;
  where?: InputMaybe<Promo_Users_Bool_Exp>;
};


export type Query_RootPromo_Users_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootPromo_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Promo_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Values_Order_By>>;
  where?: InputMaybe<Promo_Values_Bool_Exp>;
};


export type Query_RootPromo_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Values_Order_By>>;
  where?: InputMaybe<Promo_Values_Bool_Exp>;
};


export type Query_RootPromo_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPromocode_ActivationsArgs = {
  distinct_on?: InputMaybe<Array<Promocode_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promocode_Activations_Order_By>>;
  where?: InputMaybe<Promocode_Activations_Bool_Exp>;
};


export type Query_RootPromocode_Activations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promocode_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promocode_Activations_Order_By>>;
  where?: InputMaybe<Promocode_Activations_Bool_Exp>;
};


export type Query_RootPromocode_Activations_By_PkArgs = {
  activation_id: Scalars['uuid'];
};


export type Query_RootPromocodesArgs = {
  distinct_on?: InputMaybe<Array<Promocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promocodes_Order_By>>;
  where?: InputMaybe<Promocodes_Bool_Exp>;
};


export type Query_RootPromocodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promocodes_Order_By>>;
  where?: InputMaybe<Promocodes_Bool_Exp>;
};


export type Query_RootPromocodes_By_PkArgs = {
  promocode_id: Scalars['uuid'];
};


export type Query_RootPs_TypesArgs = {
  distinct_on?: InputMaybe<Array<Ps_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ps_Types_Order_By>>;
  where?: InputMaybe<Ps_Types_Bool_Exp>;
};


export type Query_RootPs_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ps_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ps_Types_Order_By>>;
  where?: InputMaybe<Ps_Types_Bool_Exp>;
};


export type Query_RootPs_Types_By_PkArgs = {
  ps_id: Scalars['Int'];
};


export type Query_RootQservice_DataArgs = {
  distinct_on?: InputMaybe<Array<Qservice_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Qservice_Data_Order_By>>;
  where?: InputMaybe<Qservice_Data_Bool_Exp>;
};


export type Query_RootQservice_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Qservice_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Qservice_Data_Order_By>>;
  where?: InputMaybe<Qservice_Data_Bool_Exp>;
};


export type Query_RootQservice_Data_By_PkArgs = {
  request_id: Scalars['uuid'];
};


export type Query_RootStatistic_Bonus_Wheels_Users_OffersArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Bonus_Wheels_Users_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Bonus_Wheels_Users_Offers_Order_By>>;
  where?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Bool_Exp>;
};


export type Query_RootStatistic_Bonus_Wheels_Users_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Bonus_Wheels_Users_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Bonus_Wheels_Users_Offers_Order_By>>;
  where?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Bool_Exp>;
};


export type Query_RootStatistic_Bonus_Wheels_Users_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
};


export type Query_RootStatistic_Button_Again_PressArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Button_Again_Press_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Button_Again_Press_Order_By>>;
  where?: InputMaybe<Statistic_Button_Again_Press_Bool_Exp>;
};


export type Query_RootStatistic_Button_Again_Press_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Button_Again_Press_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Button_Again_Press_Order_By>>;
  where?: InputMaybe<Statistic_Button_Again_Press_Bool_Exp>;
};


export type Query_RootStatistic_Button_Again_Press_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStatistic_Fast_Roll_TriggerArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Fast_Roll_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Fast_Roll_Trigger_Order_By>>;
  where?: InputMaybe<Statistic_Fast_Roll_Trigger_Bool_Exp>;
};


export type Query_RootStatistic_Fast_Roll_Trigger_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Fast_Roll_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Fast_Roll_Trigger_Order_By>>;
  where?: InputMaybe<Statistic_Fast_Roll_Trigger_Bool_Exp>;
};


export type Query_RootStatistic_Fast_Roll_Trigger_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStatistic_Inventory_OpensArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Inventory_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Inventory_Opens_Order_By>>;
  where?: InputMaybe<Statistic_Inventory_Opens_Bool_Exp>;
};


export type Query_RootStatistic_Inventory_Opens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Inventory_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Inventory_Opens_Order_By>>;
  where?: InputMaybe<Statistic_Inventory_Opens_Bool_Exp>;
};


export type Query_RootStatistic_Inventory_Opens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStatistic_Items_DropsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Drops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Drops_Order_By>>;
  where?: InputMaybe<Statistic_Items_Drops_Bool_Exp>;
};


export type Query_RootStatistic_Items_Drops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Drops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Drops_Order_By>>;
  where?: InputMaybe<Statistic_Items_Drops_Bool_Exp>;
};


export type Query_RootStatistic_Items_Drops_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStatistic_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Names_Order_By>>;
  where?: InputMaybe<Statistic_Items_Names_Bool_Exp>;
};


export type Query_RootStatistic_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Names_Order_By>>;
  where?: InputMaybe<Statistic_Items_Names_Bool_Exp>;
};


export type Query_RootStatistic_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootStatistic_Items_Output_FailArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Output_Fail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Output_Fail_Order_By>>;
  where?: InputMaybe<Statistic_Items_Output_Fail_Bool_Exp>;
};


export type Query_RootStatistic_Items_Output_Fail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Output_Fail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Output_Fail_Order_By>>;
  where?: InputMaybe<Statistic_Items_Output_Fail_Bool_Exp>;
};


export type Query_RootStatistic_Items_Output_Fail_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootStatistic_Items_Output_SuccessArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Output_Success_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Output_Success_Order_By>>;
  where?: InputMaybe<Statistic_Items_Output_Success_Bool_Exp>;
};


export type Query_RootStatistic_Items_Output_Success_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Output_Success_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Output_Success_Order_By>>;
  where?: InputMaybe<Statistic_Items_Output_Success_Bool_Exp>;
};


export type Query_RootStatistic_Items_Output_Success_By_PkArgs = {
  user_item_id: Scalars['uuid'];
};


export type Query_RootStatistic_Lootbox_OpensArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Lootbox_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Lootbox_Opens_Order_By>>;
  where?: InputMaybe<Statistic_Lootbox_Opens_Bool_Exp>;
};


export type Query_RootStatistic_Lootbox_Opens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Lootbox_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Lootbox_Opens_Order_By>>;
  where?: InputMaybe<Statistic_Lootbox_Opens_Bool_Exp>;
};


export type Query_RootStatistic_Lootbox_Opens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStatistic_Lootbox_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Lootbox_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Lootbox_Views_Order_By>>;
  where?: InputMaybe<Statistic_Lootbox_Views_Bool_Exp>;
};


export type Query_RootStatistic_Lootbox_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Lootbox_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Lootbox_Views_Order_By>>;
  where?: InputMaybe<Statistic_Lootbox_Views_Bool_Exp>;
};


export type Query_RootStatistic_Lootbox_Views_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStatistic_Mainpage_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Mainpage_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Mainpage_Visits_Order_By>>;
  where?: InputMaybe<Statistic_Mainpage_Visits_Bool_Exp>;
};


export type Query_RootStatistic_Mainpage_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Mainpage_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Mainpage_Visits_Order_By>>;
  where?: InputMaybe<Statistic_Mainpage_Visits_Bool_Exp>;
};


export type Query_RootStatistic_Mainpage_Visits_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStatistic_Promocode_Full_ActivationsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Promocode_Full_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Promocode_Full_Activations_Order_By>>;
  where?: InputMaybe<Statistic_Promocode_Full_Activations_Bool_Exp>;
};


export type Query_RootStatistic_Promocode_Full_Activations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Promocode_Full_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Promocode_Full_Activations_Order_By>>;
  where?: InputMaybe<Statistic_Promocode_Full_Activations_Bool_Exp>;
};


export type Query_RootStatistic_Promocode_Full_Activations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootStatistic_Promocode_Success_ActivationsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Promocode_Success_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Promocode_Success_Activations_Order_By>>;
  where?: InputMaybe<Statistic_Promocode_Success_Activations_Bool_Exp>;
};


export type Query_RootStatistic_Promocode_Success_Activations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Promocode_Success_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Promocode_Success_Activations_Order_By>>;
  where?: InputMaybe<Statistic_Promocode_Success_Activations_Bool_Exp>;
};


export type Query_RootStatistic_Promocode_Success_Activations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootStatistic_Sell_All_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Sell_All_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Sell_All_Items_Order_By>>;
  where?: InputMaybe<Statistic_Sell_All_Items_Bool_Exp>;
};


export type Query_RootStatistic_Sell_All_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Sell_All_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Sell_All_Items_Order_By>>;
  where?: InputMaybe<Statistic_Sell_All_Items_Bool_Exp>;
};


export type Query_RootStatistic_Sell_All_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStatistic_Sell_ItemArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Sell_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Sell_Item_Order_By>>;
  where?: InputMaybe<Statistic_Sell_Item_Bool_Exp>;
};


export type Query_RootStatistic_Sell_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Sell_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Sell_Item_Order_By>>;
  where?: InputMaybe<Statistic_Sell_Item_Bool_Exp>;
};


export type Query_RootStatistic_Sell_Item_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStatistic_User_Deposit_Success_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Deposit_Success_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Deposit_Success_Callbacks_Order_By>>;
  where?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Bool_Exp>;
};


export type Query_RootStatistic_User_Deposit_Success_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Deposit_Success_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Deposit_Success_Callbacks_Order_By>>;
  where?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Bool_Exp>;
};


export type Query_RootStatistic_User_Deposit_Success_Callbacks_By_PkArgs = {
  transaction_id: Scalars['String'];
};


export type Query_RootStatistic_User_Deposits_Transaction_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Deposits_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Deposits_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Bool_Exp>;
};


export type Query_RootStatistic_User_Deposits_Transaction_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Deposits_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Deposits_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Bool_Exp>;
};


export type Query_RootStatistic_User_Deposits_Transaction_Requests_By_PkArgs = {
  transaction_id: Scalars['String'];
};


export type Query_RootStatistic_User_Free_Lootboxes_OpensArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Free_Lootboxes_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Free_Lootboxes_Opens_Order_By>>;
  where?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Bool_Exp>;
};


export type Query_RootStatistic_User_Free_Lootboxes_Opens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Free_Lootboxes_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Free_Lootboxes_Opens_Order_By>>;
  where?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Bool_Exp>;
};


export type Query_RootStatistic_User_Free_Lootboxes_Opens_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootStatistic_User_RegistrationArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Registration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Registration_Order_By>>;
  where?: InputMaybe<Statistic_User_Registration_Bool_Exp>;
};


export type Query_RootStatistic_User_Registration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Registration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Registration_Order_By>>;
  where?: InputMaybe<Statistic_User_Registration_Bool_Exp>;
};


export type Query_RootStatistic_User_Registration_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootStocksArgs = {
  distinct_on?: InputMaybe<Array<Stocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stocks_Order_By>>;
  where?: InputMaybe<Stocks_Bool_Exp>;
};


export type Query_RootStocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stocks_Order_By>>;
  where?: InputMaybe<Stocks_Bool_Exp>;
};


export type Query_RootStocks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStore_Offer_BadgesArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Badges_Order_By>>;
  where?: InputMaybe<Store_Offer_Badges_Bool_Exp>;
};


export type Query_RootStore_Offer_Badges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Badges_Order_By>>;
  where?: InputMaybe<Store_Offer_Badges_Bool_Exp>;
};


export type Query_RootStore_Offer_Badges_By_PkArgs = {
  badge_value: Scalars['String'];
};


export type Query_RootStore_Offer_BoostersArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Boosters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Boosters_Order_By>>;
  where?: InputMaybe<Store_Offer_Boosters_Bool_Exp>;
};


export type Query_RootStore_Offer_Boosters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Boosters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Boosters_Order_By>>;
  where?: InputMaybe<Store_Offer_Boosters_Bool_Exp>;
};


export type Query_RootStore_Offer_Boosters_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


export type Query_RootStore_Offer_CoinsArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Coins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Coins_Order_By>>;
  where?: InputMaybe<Store_Offer_Coins_Bool_Exp>;
};


export type Query_RootStore_Offer_Coins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Coins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Coins_Order_By>>;
  where?: InputMaybe<Store_Offer_Coins_Bool_Exp>;
};


export type Query_RootStore_Offer_Coins_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


export type Query_RootStore_Offer_LootboxesArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Lootboxes_Order_By>>;
  where?: InputMaybe<Store_Offer_Lootboxes_Bool_Exp>;
};


export type Query_RootStore_Offer_Lootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Lootboxes_Order_By>>;
  where?: InputMaybe<Store_Offer_Lootboxes_Bool_Exp>;
};


export type Query_RootStore_Offer_Lootboxes_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


export type Query_RootStore_Offer_SalesArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Sales_Order_By>>;
  where?: InputMaybe<Store_Offer_Sales_Bool_Exp>;
};


export type Query_RootStore_Offer_Sales_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Sales_Order_By>>;
  where?: InputMaybe<Store_Offer_Sales_Bool_Exp>;
};


export type Query_RootStore_Offer_Sales_By_PkArgs = {
  sale_id: Scalars['uuid'];
};


export type Query_RootStore_Offer_SkinsArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Skins_Order_By>>;
  where?: InputMaybe<Store_Offer_Skins_Bool_Exp>;
};


export type Query_RootStore_Offer_Skins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Skins_Order_By>>;
  where?: InputMaybe<Store_Offer_Skins_Bool_Exp>;
};


export type Query_RootStore_Offer_Skins_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


export type Query_RootStore_Offer_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Tickets_Order_By>>;
  where?: InputMaybe<Store_Offer_Tickets_Bool_Exp>;
};


export type Query_RootStore_Offer_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Tickets_Order_By>>;
  where?: InputMaybe<Store_Offer_Tickets_Bool_Exp>;
};


export type Query_RootStore_Offer_Tickets_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


export type Query_RootStore_Offer_TypesArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Types_Order_By>>;
  where?: InputMaybe<Store_Offer_Types_Bool_Exp>;
};


export type Query_RootStore_Offer_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Types_Order_By>>;
  where?: InputMaybe<Store_Offer_Types_Bool_Exp>;
};


export type Query_RootStore_Offer_Types_By_PkArgs = {
  type_value: Scalars['String'];
};


export type Query_RootStore_OffersArgs = {
  distinct_on?: InputMaybe<Array<Store_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offers_Order_By>>;
  where?: InputMaybe<Store_Offers_Bool_Exp>;
};


export type Query_RootStore_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offers_Order_By>>;
  where?: InputMaybe<Store_Offers_Bool_Exp>;
};


export type Query_RootStore_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
};


export type Query_RootSurvey_Data_LinksArgs = {
  distinct_on?: InputMaybe<Array<Survey_Data_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Data_Links_Order_By>>;
  where?: InputMaybe<Survey_Data_Links_Bool_Exp>;
};


export type Query_RootSurvey_Data_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Data_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Data_Links_Order_By>>;
  where?: InputMaybe<Survey_Data_Links_Bool_Exp>;
};


export type Query_RootSurvey_Data_Links_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTelegram_Admin_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Telegram_Admin_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Telegram_Admin_Params_Order_By>>;
  where?: InputMaybe<Telegram_Admin_Params_Bool_Exp>;
};


export type Query_RootTelegram_Admin_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Telegram_Admin_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Telegram_Admin_Params_Order_By>>;
  where?: InputMaybe<Telegram_Admin_Params_Bool_Exp>;
};


export type Query_RootTelegram_Admin_Params_By_PkArgs = {
  param_id: Scalars['Int'];
};


export type Query_RootTicket_Counts_ResultArgs = {
  distinct_on?: InputMaybe<Array<Ticket_Counts_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ticket_Counts_Result_Order_By>>;
  where?: InputMaybe<Ticket_Counts_Result_Bool_Exp>;
};


export type Query_RootTicket_Counts_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ticket_Counts_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ticket_Counts_Result_Order_By>>;
  where?: InputMaybe<Ticket_Counts_Result_Bool_Exp>;
};


export type Query_RootTickets_Set_ContentArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tickets_Set_Content_Order_By>>;
  where?: InputMaybe<Tickets_Set_Content_Bool_Exp>;
};


export type Query_RootTickets_Set_Content_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tickets_Set_Content_Order_By>>;
  where?: InputMaybe<Tickets_Set_Content_Bool_Exp>;
};


export type Query_RootTickets_Set_Content_By_PkArgs = {
  content_id: Scalars['bigint'];
};


export type Query_RootTickets_SetsArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tickets_Sets_Order_By>>;
  where?: InputMaybe<Tickets_Sets_Bool_Exp>;
};


export type Query_RootTickets_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tickets_Sets_Order_By>>;
  where?: InputMaybe<Tickets_Sets_Bool_Exp>;
};


export type Query_RootTickets_Sets_By_PkArgs = {
  set_id: Scalars['bigint'];
};


export type Query_RootTop_Drop_StripArgs = {
  distinct_on?: InputMaybe<Array<Top_Drop_Strip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Top_Drop_Strip_Order_By>>;
  where?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
};


export type Query_RootTop_Drop_Strip_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Top_Drop_Strip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Top_Drop_Strip_Order_By>>;
  where?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
};


export type Query_RootTop_Drop_Strip_By_PkArgs = {
  record_id: Scalars['uuid'];
};


export type Query_RootTransactionArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


export type Query_RootTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


export type Query_RootTransaction_By_PkArgs = {
  id: Scalars['uuid'];
  order_num: Scalars['bigint'];
};


export type Query_RootUrl_MappingsArgs = {
  distinct_on?: InputMaybe<Array<Url_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Url_Mappings_Order_By>>;
  where?: InputMaybe<Url_Mappings_Bool_Exp>;
};


export type Query_RootUrl_Mappings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Url_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Url_Mappings_Order_By>>;
  where?: InputMaybe<Url_Mappings_Bool_Exp>;
};


export type Query_RootUrl_Mappings_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AccountArgs = {
  distinct_on?: InputMaybe<Array<User_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Account_Order_By>>;
  where?: InputMaybe<User_Account_Bool_Exp>;
};


export type Query_RootUser_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Account_Order_By>>;
  where?: InputMaybe<User_Account_Bool_Exp>;
};


export type Query_RootUser_Account_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AuthArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Order_By>>;
  where?: InputMaybe<User_Auth_Bool_Exp>;
};


export type Query_RootUser_Auth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Order_By>>;
  where?: InputMaybe<User_Auth_Bool_Exp>;
};


export type Query_RootUser_Auth_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


export type Query_RootUser_Bonus_WalletArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
};


export type Query_RootUser_Bonus_Wallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
};


export type Query_RootUser_Bonus_Wallet_By_PkArgs = {
  user_bonus_wallet_id: Scalars['uuid'];
};


export type Query_RootUser_Bonus_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Transactions_Bool_Exp>;
};


export type Query_RootUser_Bonus_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Transactions_Bool_Exp>;
};


export type Query_RootUser_Bonus_Wallet_Transactions_By_PkArgs = {
  operation_id: Scalars['uuid'];
};


export type Query_RootUser_Boosters_In_InventoryArgs = {
  distinct_on?: InputMaybe<Array<User_Boosters_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Boosters_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
};


export type Query_RootUser_Boosters_In_Inventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Boosters_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Boosters_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
};


export type Query_RootUser_Boosters_In_Inventory_By_PkArgs = {
  user_booster_id: Scalars['uuid'];
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_ContactsArgs = {
  distinct_on?: InputMaybe<Array<User_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Contacts_Order_By>>;
  where?: InputMaybe<User_Contacts_Bool_Exp>;
};


export type Query_RootUser_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Contacts_Order_By>>;
  where?: InputMaybe<User_Contacts_Bool_Exp>;
};


export type Query_RootUser_Contacts_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_Correct_Answers_RewardsArgs = {
  distinct_on?: InputMaybe<Array<User_Correct_Answers_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Correct_Answers_Rewards_Order_By>>;
  where?: InputMaybe<User_Correct_Answers_Rewards_Bool_Exp>;
};


export type Query_RootUser_Correct_Answers_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Correct_Answers_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Correct_Answers_Rewards_Order_By>>;
  where?: InputMaybe<User_Correct_Answers_Rewards_Bool_Exp>;
};


export type Query_RootUser_Correct_Answers_Rewards_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_Daily_Coins_ClaimsArgs = {
  distinct_on?: InputMaybe<Array<User_Daily_Coins_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Daily_Coins_Claims_Order_By>>;
  where?: InputMaybe<User_Daily_Coins_Claims_Bool_Exp>;
};


export type Query_RootUser_Daily_Coins_Claims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Daily_Coins_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Daily_Coins_Claims_Order_By>>;
  where?: InputMaybe<User_Daily_Coins_Claims_Bool_Exp>;
};


export type Query_RootUser_Daily_Coins_Claims_By_PkArgs = {
  claim_id: Scalars['uuid'];
};


export type Query_RootUser_Daily_Reward_CountsArgs = {
  distinct_on?: InputMaybe<Array<User_Daily_Reward_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Daily_Reward_Counts_Order_By>>;
  where?: InputMaybe<User_Daily_Reward_Counts_Bool_Exp>;
};


export type Query_RootUser_Daily_Reward_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Daily_Reward_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Daily_Reward_Counts_Order_By>>;
  where?: InputMaybe<User_Daily_Reward_Counts_Bool_Exp>;
};


export type Query_RootUser_Daily_Reward_Counts_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_Email_Verification_DataArgs = {
  distinct_on?: InputMaybe<Array<User_Email_Verification_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Email_Verification_Data_Order_By>>;
  where?: InputMaybe<User_Email_Verification_Data_Bool_Exp>;
};


export type Query_RootUser_Email_Verification_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Email_Verification_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Email_Verification_Data_Order_By>>;
  where?: InputMaybe<User_Email_Verification_Data_Bool_Exp>;
};


export type Query_RootUser_Email_Verification_Data_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_EventsArgs = {
  distinct_on?: InputMaybe<Array<User_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Events_Order_By>>;
  where?: InputMaybe<User_Events_Bool_Exp>;
};


export type Query_RootUser_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Events_Order_By>>;
  where?: InputMaybe<User_Events_Bool_Exp>;
};


export type Query_RootUser_Events_By_PkArgs = {
  event_id: Scalars['uuid'];
};


export type Query_RootUser_Got_BonusArgs = {
  distinct_on?: InputMaybe<Array<User_Got_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Got_Bonus_Order_By>>;
  where?: InputMaybe<User_Got_Bonus_Bool_Exp>;
};


export type Query_RootUser_Got_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Got_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Got_Bonus_Order_By>>;
  where?: InputMaybe<User_Got_Bonus_Bool_Exp>;
};


export type Query_RootUser_Got_Bonus_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  last_datetime: Scalars['timestamp'];
  user_id: Scalars['uuid'];
};


export type Query_RootUser_InventoryArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Order_By>>;
  where?: InputMaybe<User_Inventory_Bool_Exp>;
};


export type Query_RootUser_Inventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Order_By>>;
  where?: InputMaybe<User_Inventory_Bool_Exp>;
};


export type Query_RootUser_Inventory_By_PkArgs = {
  user_inventory_id: Scalars['uuid'];
};


export type Query_RootUser_Inventory_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Transactions_Order_By>>;
  where?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
};


export type Query_RootUser_Inventory_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Transactions_Order_By>>;
  where?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
};


export type Query_RootUser_Inventory_Transactions_By_PkArgs = {
  operation_id: Scalars['uuid'];
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};


export type Query_RootUser_Items_In_InventoryArgs = {
  distinct_on?: InputMaybe<Array<User_Items_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Items_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
};


export type Query_RootUser_Items_In_Inventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Items_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Items_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
};


export type Query_RootUser_Items_In_Inventory_By_PkArgs = {
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};


export type Query_RootUser_Lootbox_Opens_CountArgs = {
  distinct_on?: InputMaybe<Array<User_Lootbox_Opens_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Lootbox_Opens_Count_Order_By>>;
  where?: InputMaybe<User_Lootbox_Opens_Count_Bool_Exp>;
};


export type Query_RootUser_Lootbox_Opens_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Lootbox_Opens_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Lootbox_Opens_Count_Order_By>>;
  where?: InputMaybe<User_Lootbox_Opens_Count_Bool_Exp>;
};


export type Query_RootUser_Lootbox_Opens_Count_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootUser_MarkingArgs = {
  distinct_on?: InputMaybe<Array<User_Marking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Marking_Order_By>>;
  where?: InputMaybe<User_Marking_Bool_Exp>;
};


export type Query_RootUser_Marking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Marking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Marking_Order_By>>;
  where?: InputMaybe<User_Marking_Bool_Exp>;
};


export type Query_RootUser_Marking_By_PkArgs = {
  user_marking_id: Scalars['uuid'];
};


export type Query_RootUser_MarkingsArgs = {
  distinct_on?: InputMaybe<Array<User_Markings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Markings_Order_By>>;
  where?: InputMaybe<User_Markings_Bool_Exp>;
};


export type Query_RootUser_Markings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Markings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Markings_Order_By>>;
  where?: InputMaybe<User_Markings_Bool_Exp>;
};


export type Query_RootUser_Markings_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_Output_LimitsArgs = {
  distinct_on?: InputMaybe<Array<User_Output_Limits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Output_Limits_Order_By>>;
  where?: InputMaybe<User_Output_Limits_Bool_Exp>;
};


export type Query_RootUser_Output_Limits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Output_Limits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Output_Limits_Order_By>>;
  where?: InputMaybe<User_Output_Limits_Bool_Exp>;
};


export type Query_RootUser_Output_Limits_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_ProfileArgs = {
  distinct_on?: InputMaybe<Array<User_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Profile_Order_By>>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};


export type Query_RootUser_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Profile_Order_By>>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};


export type Query_RootUser_Profile_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_Push_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Push_Tokens_Order_By>>;
  where?: InputMaybe<User_Push_Tokens_Bool_Exp>;
};


export type Query_RootUser_Push_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Push_Tokens_Order_By>>;
  where?: InputMaybe<User_Push_Tokens_Bool_Exp>;
};


export type Query_RootUser_Push_Tokens_By_PkArgs = {
  token_id: Scalars['uuid'];
};


export type Query_RootUser_Push_TopicsArgs = {
  distinct_on?: InputMaybe<Array<User_Push_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Push_Topics_Order_By>>;
  where?: InputMaybe<User_Push_Topics_Bool_Exp>;
};


export type Query_RootUser_Push_Topics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Push_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Push_Topics_Order_By>>;
  where?: InputMaybe<User_Push_Topics_Bool_Exp>;
};


export type Query_RootUser_Push_Topics_By_PkArgs = {
  topic_id: Scalars['uuid'];
};


export type Query_RootUser_Referal_Levels_ParamsArgs = {
  distinct_on?: InputMaybe<Array<User_Referal_Levels_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referal_Levels_Params_Order_By>>;
  where?: InputMaybe<User_Referal_Levels_Params_Bool_Exp>;
};


export type Query_RootUser_Referal_Levels_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referal_Levels_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referal_Levels_Params_Order_By>>;
  where?: InputMaybe<User_Referal_Levels_Params_Bool_Exp>;
};


export type Query_RootUser_Referal_Levels_Params_By_PkArgs = {
  referal_level: Scalars['Int'];
};


export type Query_RootUser_Referal_ParamsArgs = {
  distinct_on?: InputMaybe<Array<User_Referal_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referal_Params_Order_By>>;
  where?: InputMaybe<User_Referal_Params_Bool_Exp>;
};


export type Query_RootUser_Referal_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referal_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referal_Params_Order_By>>;
  where?: InputMaybe<User_Referal_Params_Bool_Exp>;
};


export type Query_RootUser_Referal_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_ReferalsArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Order_By>>;
  where?: InputMaybe<User_Referals_Bool_Exp>;
};


export type Query_RootUser_Referals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Order_By>>;
  where?: InputMaybe<User_Referals_Bool_Exp>;
};


export type Query_RootUser_Referals_Balance_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Balance_Transactions_Order_By>>;
  where?: InputMaybe<User_Referals_Balance_Transactions_Bool_Exp>;
};


export type Query_RootUser_Referals_Balance_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Balance_Transactions_Order_By>>;
  where?: InputMaybe<User_Referals_Balance_Transactions_Bool_Exp>;
};


export type Query_RootUser_Referals_Balance_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Query_RootUser_Referals_Bonus_HistoryArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Bonus_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Bonus_History_Order_By>>;
  where?: InputMaybe<User_Referals_Bonus_History_Bool_Exp>;
};


export type Query_RootUser_Referals_Bonus_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Bonus_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Bonus_History_Order_By>>;
  where?: InputMaybe<User_Referals_Bonus_History_Bool_Exp>;
};


export type Query_RootUser_Referals_Bonus_History_By_PkArgs = {
  hisoty_id: Scalars['uuid'];
};


export type Query_RootUser_Referals_By_PkArgs = {
  owner_user_id: Scalars['uuid'];
  referal_user_id: Scalars['uuid'];
};


export type Query_RootUser_ReferralArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Order_By>>;
  where?: InputMaybe<User_Referral_Bool_Exp>;
};


export type Query_RootUser_Referral_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Order_By>>;
  where?: InputMaybe<User_Referral_Bool_Exp>;
};


export type Query_RootUser_Referral_AssociationArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Association_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Association_Order_By>>;
  where?: InputMaybe<User_Referral_Association_Bool_Exp>;
};


export type Query_RootUser_Referral_Association_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Association_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Association_Order_By>>;
  where?: InputMaybe<User_Referral_Association_Bool_Exp>;
};


export type Query_RootUser_Referral_Association_By_PkArgs = {
  offer_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootUser_Referral_Bonus_TypeArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Bonus_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Bonus_Type_Order_By>>;
  where?: InputMaybe<User_Referral_Bonus_Type_Bool_Exp>;
};


export type Query_RootUser_Referral_Bonus_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Bonus_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Bonus_Type_Order_By>>;
  where?: InputMaybe<User_Referral_Bonus_Type_Bool_Exp>;
};


export type Query_RootUser_Referral_Bonus_Type_By_PkArgs = {
  referral_bonus_type_id: Scalars['uuid'];
};


export type Query_RootUser_Referral_By_PkArgs = {
  owner_user_id: Scalars['uuid'];
  referral_user_id: Scalars['uuid'];
};


export type Query_RootUser_Referral_Levels_ParamsArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Levels_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Levels_Params_Order_By>>;
  where?: InputMaybe<User_Referral_Levels_Params_Bool_Exp>;
};


export type Query_RootUser_Referral_Levels_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Levels_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Levels_Params_Order_By>>;
  where?: InputMaybe<User_Referral_Levels_Params_Bool_Exp>;
};


export type Query_RootUser_Referral_Levels_Params_By_PkArgs = {
  referral_level: Scalars['Int'];
};


export type Query_RootUser_Referral_Offer_BonusArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offer_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offer_Bonus_Order_By>>;
  where?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
};


export type Query_RootUser_Referral_Offer_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offer_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offer_Bonus_Order_By>>;
  where?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
};


export type Query_RootUser_Referral_Offer_Bonus_By_PkArgs = {
  offer_bonus_id: Scalars['uuid'];
};


export type Query_RootUser_Referral_OffersArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offers_Order_By>>;
  where?: InputMaybe<User_Referral_Offers_Bool_Exp>;
};


export type Query_RootUser_Referral_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offers_Order_By>>;
  where?: InputMaybe<User_Referral_Offers_Bool_Exp>;
};


export type Query_RootUser_Referral_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
};


export type Query_RootUser_Referral_ParamsArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Params_Order_By>>;
  where?: InputMaybe<User_Referral_Params_Bool_Exp>;
};


export type Query_RootUser_Referral_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Params_Order_By>>;
  where?: InputMaybe<User_Referral_Params_Bool_Exp>;
};


export type Query_RootUser_Referral_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_Referrals_Balance_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Referrals_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referrals_Balance_Transactions_Order_By>>;
  where?: InputMaybe<User_Referrals_Balance_Transactions_Bool_Exp>;
};


export type Query_RootUser_Referrals_Balance_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referrals_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referrals_Balance_Transactions_Order_By>>;
  where?: InputMaybe<User_Referrals_Balance_Transactions_Bool_Exp>;
};


export type Query_RootUser_Referrals_Balance_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Query_RootUser_Seq_NumberArgs = {
  distinct_on?: InputMaybe<Array<User_Seq_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Seq_Number_Order_By>>;
  where?: InputMaybe<User_Seq_Number_Bool_Exp>;
};


export type Query_RootUser_Seq_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Seq_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Seq_Number_Order_By>>;
  where?: InputMaybe<User_Seq_Number_Bool_Exp>;
};


export type Query_RootUser_Seq_Number_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_SettingsArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};


export type Query_RootUser_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};


export type Query_RootUser_Settings_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_StatisticArgs = {
  distinct_on?: InputMaybe<Array<User_Statistic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Statistic_Order_By>>;
  where?: InputMaybe<User_Statistic_Bool_Exp>;
};


export type Query_RootUser_Statistic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Statistic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Statistic_Order_By>>;
  where?: InputMaybe<User_Statistic_Bool_Exp>;
};


export type Query_RootUser_Statistic_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Query_RootUser_Steam_Output_AlternativesArgs = {
  distinct_on?: InputMaybe<Array<User_Steam_Output_Alternatives_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Steam_Output_Alternatives_Order_By>>;
  where?: InputMaybe<User_Steam_Output_Alternatives_Bool_Exp>;
};


export type Query_RootUser_Steam_Output_Alternatives_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Steam_Output_Alternatives_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Steam_Output_Alternatives_Order_By>>;
  where?: InputMaybe<User_Steam_Output_Alternatives_Bool_Exp>;
};


export type Query_RootUser_Steam_Output_Alternatives_By_PkArgs = {
  output_id: Scalars['uuid'];
};


export type Query_RootUser_Steam_Output_WatcherArgs = {
  distinct_on?: InputMaybe<Array<User_Steam_Output_Watcher_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Steam_Output_Watcher_Order_By>>;
  where?: InputMaybe<User_Steam_Output_Watcher_Bool_Exp>;
};


export type Query_RootUser_Steam_Output_Watcher_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Steam_Output_Watcher_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Steam_Output_Watcher_Order_By>>;
  where?: InputMaybe<User_Steam_Output_Watcher_Bool_Exp>;
};


export type Query_RootUser_Steam_Output_Watcher_By_PkArgs = {
  order_custom_id: Scalars['uuid'];
};


export type Query_RootUser_Takes_Free_LootboxArgs = {
  distinct_on?: InputMaybe<Array<User_Takes_Free_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Takes_Free_Lootbox_Order_By>>;
  where?: InputMaybe<User_Takes_Free_Lootbox_Bool_Exp>;
};


export type Query_RootUser_Takes_Free_Lootbox_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Takes_Free_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Takes_Free_Lootbox_Order_By>>;
  where?: InputMaybe<User_Takes_Free_Lootbox_Bool_Exp>;
};


export type Query_RootUser_Takes_Free_Lootbox_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootUser_Upgrade_ParamsArgs = {
  distinct_on?: InputMaybe<Array<User_Upgrade_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Upgrade_Params_Order_By>>;
  where?: InputMaybe<User_Upgrade_Params_Bool_Exp>;
};


export type Query_RootUser_Upgrade_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Upgrade_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Upgrade_Params_Order_By>>;
  where?: InputMaybe<User_Upgrade_Params_Bool_Exp>;
};


export type Query_RootUser_Upgrade_Params_By_PkArgs = {
  param_name: Scalars['String'];
};


export type Query_RootUser_Upgrade_ResultsArgs = {
  distinct_on?: InputMaybe<Array<User_Upgrade_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Upgrade_Results_Order_By>>;
  where?: InputMaybe<User_Upgrade_Results_Bool_Exp>;
};


export type Query_RootUser_Upgrade_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Upgrade_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Upgrade_Results_Order_By>>;
  where?: InputMaybe<User_Upgrade_Results_Bool_Exp>;
};


export type Query_RootUser_Upgrade_Results_By_PkArgs = {
  start_item_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootUser_Vk_DataArgs = {
  distinct_on?: InputMaybe<Array<User_Vk_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Vk_Data_Order_By>>;
  where?: InputMaybe<User_Vk_Data_Bool_Exp>;
};


export type Query_RootUser_Vk_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Vk_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Vk_Data_Order_By>>;
  where?: InputMaybe<User_Vk_Data_Bool_Exp>;
};


export type Query_RootUser_Vk_Data_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootUser_WalletArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


export type Query_RootUser_Wallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


export type Query_RootUser_Wallet_AuditArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Audit_Order_By>>;
  where?: InputMaybe<User_Wallet_Audit_Bool_Exp>;
};


export type Query_RootUser_Wallet_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Audit_Order_By>>;
  where?: InputMaybe<User_Wallet_Audit_Bool_Exp>;
};


export type Query_RootUser_Wallet_Audit_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Query_RootUser_Wallet_By_PkArgs = {
  user_wallet_id: Scalars['uuid'];
};


export type Query_RootUser_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Wallet_Transactions_Bool_Exp>;
};


export type Query_RootUser_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Wallet_Transactions_Bool_Exp>;
};


export type Query_RootUser_Wallet_Transactions_By_PkArgs = {
  operation_id: Scalars['uuid'];
  user_wallet_id: Scalars['uuid'];
};


export type Query_RootUser_WhitelistArgs = {
  distinct_on?: InputMaybe<Array<User_Whitelist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Whitelist_Order_By>>;
  where?: InputMaybe<User_Whitelist_Bool_Exp>;
};


export type Query_RootUser_Whitelist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Whitelist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Whitelist_Order_By>>;
  where?: InputMaybe<User_Whitelist_Bool_Exp>;
};


export type Query_RootUser_Whitelist_By_PkArgs = {
  user_data: Scalars['String'];
};


export type Query_RootUtm_Visits_DataArgs = {
  distinct_on?: InputMaybe<Array<Utm_Visits_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Utm_Visits_Data_Order_By>>;
  where?: InputMaybe<Utm_Visits_Data_Bool_Exp>;
};


export type Query_RootUtm_Visits_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Utm_Visits_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Utm_Visits_Data_Order_By>>;
  where?: InputMaybe<Utm_Visits_Data_Bool_Exp>;
};


export type Query_RootUtm_Visits_Data_By_PkArgs = {
  visit_id: Scalars['uuid'];
};


export type Query_RootWebpushes_Autosending_TypesArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Autosending_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Autosending_Types_Order_By>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
};


export type Query_RootWebpushes_Autosending_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Autosending_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Autosending_Types_Order_By>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
};


export type Query_RootWebpushes_Autosending_Types_By_PkArgs = {
  webpush_id: Scalars['uuid'];
};


export type Query_RootWebpushes_Autosending_Types_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Order_By>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Localization_Bool_Exp>;
};


export type Query_RootWebpushes_Autosending_Types_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Order_By>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Localization_Bool_Exp>;
};


export type Query_RootWebpushes_Autosending_Types_Localization_By_PkArgs = {
  locale: Scalars['String'];
  webpush_id: Scalars['uuid'];
};


export type Query_RootWebpushes_BonusesArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Bonuses_Order_By>>;
  where?: InputMaybe<Webpushes_Bonuses_Bool_Exp>;
};


export type Query_RootWebpushes_Bonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Bonuses_Order_By>>;
  where?: InputMaybe<Webpushes_Bonuses_Bool_Exp>;
};


export type Query_RootWebpushes_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


export type Query_RootWebpushes_Bonuses_OffersArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
};


export type Query_RootWebpushes_Bonuses_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
};


export type Query_RootWebpushes_Bonuses_Offers_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Query_RootWebpushes_LinesArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Lines_Order_By>>;
  where?: InputMaybe<Webpushes_Lines_Bool_Exp>;
};


export type Query_RootWebpushes_Lines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Lines_Order_By>>;
  where?: InputMaybe<Webpushes_Lines_Bool_Exp>;
};


export type Query_RootWebpushes_Lines_By_PkArgs = {
  webpush_id: Scalars['uuid'];
};


export type Query_RootWebpushes_SchedulingArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Scheduling_Order_By>>;
  where?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
};


export type Query_RootWebpushes_Scheduling_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Scheduling_Order_By>>;
  where?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
};


export type Query_RootWebpushes_Scheduling_By_PkArgs = {
  user_id: Scalars['uuid'];
  webpush_id: Scalars['uuid'];
};


export type Query_RootWebpushes_TriggersArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Bool_Exp>;
};


export type Query_RootWebpushes_Triggers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Bool_Exp>;
};


export type Query_RootWebpushes_Triggers_By_PkArgs = {
  trigger_name: Scalars['String'];
  triggers_group_id: Scalars['uuid'];
};


export type Query_RootWebpushes_Triggers_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
};


export type Query_RootWebpushes_Triggers_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
};


export type Query_RootWebpushes_Triggers_Groups_By_PkArgs = {
  triggers_group_id: Scalars['uuid'];
  webpush_id: Scalars['uuid'];
};

export type RefreshTokenOutput = {
  __typename?: 'refreshTokenOutput';
  access_token: Scalars['String'];
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "statistic_bonus_wheels_users_offers" */
export type Statistic_Bonus_Wheels_Users_Offers = {
  __typename?: 'statistic_bonus_wheels_users_offers';
  bonus_id: Scalars['uuid'];
  claimed: Scalars['Boolean'];
  created_at: Scalars['timestamp'];
  offer_id: Scalars['uuid'];
  offer_value: Scalars['Int'];
  updated_at: Scalars['timestamp'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "statistic_bonus_wheels_users_offers" */
export type Statistic_Bonus_Wheels_Users_Offers_Aggregate = {
  __typename?: 'statistic_bonus_wheels_users_offers_aggregate';
  aggregate?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Aggregate_Fields>;
  nodes: Array<Statistic_Bonus_Wheels_Users_Offers>;
};

/** aggregate fields of "statistic_bonus_wheels_users_offers" */
export type Statistic_Bonus_Wheels_Users_Offers_Aggregate_Fields = {
  __typename?: 'statistic_bonus_wheels_users_offers_aggregate_fields';
  avg?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Max_Fields>;
  min?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Min_Fields>;
  stddev?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Sum_Fields>;
  var_pop?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Bonus_Wheels_Users_Offers_Variance_Fields>;
};


/** aggregate fields of "statistic_bonus_wheels_users_offers" */
export type Statistic_Bonus_Wheels_Users_Offers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Bonus_Wheels_Users_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Bonus_Wheels_Users_Offers_Avg_Fields = {
  __typename?: 'statistic_bonus_wheels_users_offers_avg_fields';
  offer_value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_bonus_wheels_users_offers". All fields are combined with a logical 'AND'. */
export type Statistic_Bonus_Wheels_Users_Offers_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Bonus_Wheels_Users_Offers_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Bonus_Wheels_Users_Offers_Bool_Exp>>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  claimed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  offer_value?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_bonus_wheels_users_offers" */
export enum Statistic_Bonus_Wheels_Users_Offers_Constraint {
  /** unique or primary key constraint on columns "offer_id" */
  StatisticBonusWheelsUsersOffersPkey = 'statistic_bonus_wheels_users_offers_pkey'
}

/** input type for incrementing numeric columns in table "statistic_bonus_wheels_users_offers" */
export type Statistic_Bonus_Wheels_Users_Offers_Inc_Input = {
  offer_value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "statistic_bonus_wheels_users_offers" */
export type Statistic_Bonus_Wheels_Users_Offers_Insert_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_value?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Statistic_Bonus_Wheels_Users_Offers_Max_Fields = {
  __typename?: 'statistic_bonus_wheels_users_offers_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  offer_id?: Maybe<Scalars['uuid']>;
  offer_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Statistic_Bonus_Wheels_Users_Offers_Min_Fields = {
  __typename?: 'statistic_bonus_wheels_users_offers_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  offer_id?: Maybe<Scalars['uuid']>;
  offer_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "statistic_bonus_wheels_users_offers" */
export type Statistic_Bonus_Wheels_Users_Offers_Mutation_Response = {
  __typename?: 'statistic_bonus_wheels_users_offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Bonus_Wheels_Users_Offers>;
};

/** on_conflict condition type for table "statistic_bonus_wheels_users_offers" */
export type Statistic_Bonus_Wheels_Users_Offers_On_Conflict = {
  constraint: Statistic_Bonus_Wheels_Users_Offers_Constraint;
  update_columns?: Array<Statistic_Bonus_Wheels_Users_Offers_Update_Column>;
  where?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_bonus_wheels_users_offers". */
export type Statistic_Bonus_Wheels_Users_Offers_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  claimed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  offer_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_bonus_wheels_users_offers */
export type Statistic_Bonus_Wheels_Users_Offers_Pk_Columns_Input = {
  offer_id: Scalars['uuid'];
};

/** select columns of table "statistic_bonus_wheels_users_offers" */
export enum Statistic_Bonus_Wheels_Users_Offers_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferValue = 'offer_value',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "statistic_bonus_wheels_users_offers" */
export type Statistic_Bonus_Wheels_Users_Offers_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_value?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Statistic_Bonus_Wheels_Users_Offers_Stddev_Fields = {
  __typename?: 'statistic_bonus_wheels_users_offers_stddev_fields';
  offer_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Bonus_Wheels_Users_Offers_Stddev_Pop_Fields = {
  __typename?: 'statistic_bonus_wheels_users_offers_stddev_pop_fields';
  offer_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Bonus_Wheels_Users_Offers_Stddev_Samp_Fields = {
  __typename?: 'statistic_bonus_wheels_users_offers_stddev_samp_fields';
  offer_value?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_bonus_wheels_users_offers" */
export type Statistic_Bonus_Wheels_Users_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Bonus_Wheels_Users_Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Bonus_Wheels_Users_Offers_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_value?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Statistic_Bonus_Wheels_Users_Offers_Sum_Fields = {
  __typename?: 'statistic_bonus_wheels_users_offers_sum_fields';
  offer_value?: Maybe<Scalars['Int']>;
};

/** update columns of table "statistic_bonus_wheels_users_offers" */
export enum Statistic_Bonus_Wheels_Users_Offers_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferValue = 'offer_value',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Statistic_Bonus_Wheels_Users_Offers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Bonus_Wheels_Users_Offers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Bonus_Wheels_Users_Offers_Var_Pop_Fields = {
  __typename?: 'statistic_bonus_wheels_users_offers_var_pop_fields';
  offer_value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Bonus_Wheels_Users_Offers_Var_Samp_Fields = {
  __typename?: 'statistic_bonus_wheels_users_offers_var_samp_fields';
  offer_value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Bonus_Wheels_Users_Offers_Variance_Fields = {
  __typename?: 'statistic_bonus_wheels_users_offers_variance_fields';
  offer_value?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_button_again_press" */
export type Statistic_Button_Again_Press = {
  __typename?: 'statistic_button_again_press';
  datetime: Scalars['timestamp'];
  id: Scalars['Int'];
  lootbox_id: Scalars['uuid'];
  lootbox_name: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "statistic_button_again_press" */
export type Statistic_Button_Again_Press_Aggregate = {
  __typename?: 'statistic_button_again_press_aggregate';
  aggregate?: Maybe<Statistic_Button_Again_Press_Aggregate_Fields>;
  nodes: Array<Statistic_Button_Again_Press>;
};

/** aggregate fields of "statistic_button_again_press" */
export type Statistic_Button_Again_Press_Aggregate_Fields = {
  __typename?: 'statistic_button_again_press_aggregate_fields';
  avg?: Maybe<Statistic_Button_Again_Press_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Button_Again_Press_Max_Fields>;
  min?: Maybe<Statistic_Button_Again_Press_Min_Fields>;
  stddev?: Maybe<Statistic_Button_Again_Press_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Button_Again_Press_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Button_Again_Press_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Button_Again_Press_Sum_Fields>;
  var_pop?: Maybe<Statistic_Button_Again_Press_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Button_Again_Press_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Button_Again_Press_Variance_Fields>;
};


/** aggregate fields of "statistic_button_again_press" */
export type Statistic_Button_Again_Press_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Button_Again_Press_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Button_Again_Press_Avg_Fields = {
  __typename?: 'statistic_button_again_press_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_button_again_press". All fields are combined with a logical 'AND'. */
export type Statistic_Button_Again_Press_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Button_Again_Press_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Button_Again_Press_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Button_Again_Press_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_button_again_press" */
export enum Statistic_Button_Again_Press_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticButtonAgainPressPkey = 'statistic_button_again_press_pkey'
}

/** input type for incrementing numeric columns in table "statistic_button_again_press" */
export type Statistic_Button_Again_Press_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "statistic_button_again_press" */
export type Statistic_Button_Again_Press_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Statistic_Button_Again_Press_Max_Fields = {
  __typename?: 'statistic_button_again_press_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Statistic_Button_Again_Press_Min_Fields = {
  __typename?: 'statistic_button_again_press_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "statistic_button_again_press" */
export type Statistic_Button_Again_Press_Mutation_Response = {
  __typename?: 'statistic_button_again_press_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Button_Again_Press>;
};

/** on_conflict condition type for table "statistic_button_again_press" */
export type Statistic_Button_Again_Press_On_Conflict = {
  constraint: Statistic_Button_Again_Press_Constraint;
  update_columns?: Array<Statistic_Button_Again_Press_Update_Column>;
  where?: InputMaybe<Statistic_Button_Again_Press_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_button_again_press". */
export type Statistic_Button_Again_Press_Order_By = {
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_button_again_press */
export type Statistic_Button_Again_Press_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "statistic_button_again_press" */
export enum Statistic_Button_Again_Press_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "statistic_button_again_press" */
export type Statistic_Button_Again_Press_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Statistic_Button_Again_Press_Stddev_Fields = {
  __typename?: 'statistic_button_again_press_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Button_Again_Press_Stddev_Pop_Fields = {
  __typename?: 'statistic_button_again_press_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Button_Again_Press_Stddev_Samp_Fields = {
  __typename?: 'statistic_button_again_press_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_button_again_press" */
export type Statistic_Button_Again_Press_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Button_Again_Press_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Button_Again_Press_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Statistic_Button_Again_Press_Sum_Fields = {
  __typename?: 'statistic_button_again_press_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "statistic_button_again_press" */
export enum Statistic_Button_Again_Press_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  UserId = 'user_id'
}

export type Statistic_Button_Again_Press_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Button_Again_Press_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Button_Again_Press_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Button_Again_Press_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Button_Again_Press_Var_Pop_Fields = {
  __typename?: 'statistic_button_again_press_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Button_Again_Press_Var_Samp_Fields = {
  __typename?: 'statistic_button_again_press_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Button_Again_Press_Variance_Fields = {
  __typename?: 'statistic_button_again_press_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_fast_roll_trigger" */
export type Statistic_Fast_Roll_Trigger = {
  __typename?: 'statistic_fast_roll_trigger';
  datetime: Scalars['timestamp'];
  id: Scalars['Int'];
  trigger_status: Scalars['Boolean'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "statistic_fast_roll_trigger" */
export type Statistic_Fast_Roll_Trigger_Aggregate = {
  __typename?: 'statistic_fast_roll_trigger_aggregate';
  aggregate?: Maybe<Statistic_Fast_Roll_Trigger_Aggregate_Fields>;
  nodes: Array<Statistic_Fast_Roll_Trigger>;
};

/** aggregate fields of "statistic_fast_roll_trigger" */
export type Statistic_Fast_Roll_Trigger_Aggregate_Fields = {
  __typename?: 'statistic_fast_roll_trigger_aggregate_fields';
  avg?: Maybe<Statistic_Fast_Roll_Trigger_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Fast_Roll_Trigger_Max_Fields>;
  min?: Maybe<Statistic_Fast_Roll_Trigger_Min_Fields>;
  stddev?: Maybe<Statistic_Fast_Roll_Trigger_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Fast_Roll_Trigger_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Fast_Roll_Trigger_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Fast_Roll_Trigger_Sum_Fields>;
  var_pop?: Maybe<Statistic_Fast_Roll_Trigger_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Fast_Roll_Trigger_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Fast_Roll_Trigger_Variance_Fields>;
};


/** aggregate fields of "statistic_fast_roll_trigger" */
export type Statistic_Fast_Roll_Trigger_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Fast_Roll_Trigger_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Fast_Roll_Trigger_Avg_Fields = {
  __typename?: 'statistic_fast_roll_trigger_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_fast_roll_trigger". All fields are combined with a logical 'AND'. */
export type Statistic_Fast_Roll_Trigger_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Fast_Roll_Trigger_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Fast_Roll_Trigger_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Fast_Roll_Trigger_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  trigger_status?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_fast_roll_trigger" */
export enum Statistic_Fast_Roll_Trigger_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticFastRollTriggerPkey = 'statistic_fast_roll_trigger_pkey'
}

/** input type for incrementing numeric columns in table "statistic_fast_roll_trigger" */
export type Statistic_Fast_Roll_Trigger_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "statistic_fast_roll_trigger" */
export type Statistic_Fast_Roll_Trigger_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  trigger_status?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Statistic_Fast_Roll_Trigger_Max_Fields = {
  __typename?: 'statistic_fast_roll_trigger_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Statistic_Fast_Roll_Trigger_Min_Fields = {
  __typename?: 'statistic_fast_roll_trigger_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "statistic_fast_roll_trigger" */
export type Statistic_Fast_Roll_Trigger_Mutation_Response = {
  __typename?: 'statistic_fast_roll_trigger_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Fast_Roll_Trigger>;
};

/** on_conflict condition type for table "statistic_fast_roll_trigger" */
export type Statistic_Fast_Roll_Trigger_On_Conflict = {
  constraint: Statistic_Fast_Roll_Trigger_Constraint;
  update_columns?: Array<Statistic_Fast_Roll_Trigger_Update_Column>;
  where?: InputMaybe<Statistic_Fast_Roll_Trigger_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_fast_roll_trigger". */
export type Statistic_Fast_Roll_Trigger_Order_By = {
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trigger_status?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_fast_roll_trigger */
export type Statistic_Fast_Roll_Trigger_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "statistic_fast_roll_trigger" */
export enum Statistic_Fast_Roll_Trigger_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  TriggerStatus = 'trigger_status',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "statistic_fast_roll_trigger" */
export type Statistic_Fast_Roll_Trigger_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  trigger_status?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Statistic_Fast_Roll_Trigger_Stddev_Fields = {
  __typename?: 'statistic_fast_roll_trigger_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Fast_Roll_Trigger_Stddev_Pop_Fields = {
  __typename?: 'statistic_fast_roll_trigger_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Fast_Roll_Trigger_Stddev_Samp_Fields = {
  __typename?: 'statistic_fast_roll_trigger_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_fast_roll_trigger" */
export type Statistic_Fast_Roll_Trigger_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Fast_Roll_Trigger_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Fast_Roll_Trigger_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  trigger_status?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Statistic_Fast_Roll_Trigger_Sum_Fields = {
  __typename?: 'statistic_fast_roll_trigger_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "statistic_fast_roll_trigger" */
export enum Statistic_Fast_Roll_Trigger_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  TriggerStatus = 'trigger_status',
  /** column name */
  UserId = 'user_id'
}

export type Statistic_Fast_Roll_Trigger_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Fast_Roll_Trigger_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Fast_Roll_Trigger_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Fast_Roll_Trigger_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Fast_Roll_Trigger_Var_Pop_Fields = {
  __typename?: 'statistic_fast_roll_trigger_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Fast_Roll_Trigger_Var_Samp_Fields = {
  __typename?: 'statistic_fast_roll_trigger_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Fast_Roll_Trigger_Variance_Fields = {
  __typename?: 'statistic_fast_roll_trigger_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_inventory_opens" */
export type Statistic_Inventory_Opens = {
  __typename?: 'statistic_inventory_opens';
  datetime: Scalars['timestamp'];
  id: Scalars['Int'];
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "statistic_inventory_opens" */
export type Statistic_Inventory_Opens_Aggregate = {
  __typename?: 'statistic_inventory_opens_aggregate';
  aggregate?: Maybe<Statistic_Inventory_Opens_Aggregate_Fields>;
  nodes: Array<Statistic_Inventory_Opens>;
};

/** aggregate fields of "statistic_inventory_opens" */
export type Statistic_Inventory_Opens_Aggregate_Fields = {
  __typename?: 'statistic_inventory_opens_aggregate_fields';
  avg?: Maybe<Statistic_Inventory_Opens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Inventory_Opens_Max_Fields>;
  min?: Maybe<Statistic_Inventory_Opens_Min_Fields>;
  stddev?: Maybe<Statistic_Inventory_Opens_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Inventory_Opens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Inventory_Opens_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Inventory_Opens_Sum_Fields>;
  var_pop?: Maybe<Statistic_Inventory_Opens_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Inventory_Opens_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Inventory_Opens_Variance_Fields>;
};


/** aggregate fields of "statistic_inventory_opens" */
export type Statistic_Inventory_Opens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Inventory_Opens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Inventory_Opens_Avg_Fields = {
  __typename?: 'statistic_inventory_opens_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_inventory_opens". All fields are combined with a logical 'AND'. */
export type Statistic_Inventory_Opens_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Inventory_Opens_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Inventory_Opens_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Inventory_Opens_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_inventory_opens" */
export enum Statistic_Inventory_Opens_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticInventoryOpensPkey = 'statistic_inventory_opens_pkey'
}

/** input type for incrementing numeric columns in table "statistic_inventory_opens" */
export type Statistic_Inventory_Opens_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "statistic_inventory_opens" */
export type Statistic_Inventory_Opens_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Statistic_Inventory_Opens_Max_Fields = {
  __typename?: 'statistic_inventory_opens_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Statistic_Inventory_Opens_Min_Fields = {
  __typename?: 'statistic_inventory_opens_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "statistic_inventory_opens" */
export type Statistic_Inventory_Opens_Mutation_Response = {
  __typename?: 'statistic_inventory_opens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Inventory_Opens>;
};

/** on_conflict condition type for table "statistic_inventory_opens" */
export type Statistic_Inventory_Opens_On_Conflict = {
  constraint: Statistic_Inventory_Opens_Constraint;
  update_columns?: Array<Statistic_Inventory_Opens_Update_Column>;
  where?: InputMaybe<Statistic_Inventory_Opens_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_inventory_opens". */
export type Statistic_Inventory_Opens_Order_By = {
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_inventory_opens */
export type Statistic_Inventory_Opens_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "statistic_inventory_opens" */
export enum Statistic_Inventory_Opens_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "statistic_inventory_opens" */
export type Statistic_Inventory_Opens_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Statistic_Inventory_Opens_Stddev_Fields = {
  __typename?: 'statistic_inventory_opens_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Inventory_Opens_Stddev_Pop_Fields = {
  __typename?: 'statistic_inventory_opens_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Inventory_Opens_Stddev_Samp_Fields = {
  __typename?: 'statistic_inventory_opens_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_inventory_opens" */
export type Statistic_Inventory_Opens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Inventory_Opens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Inventory_Opens_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Statistic_Inventory_Opens_Sum_Fields = {
  __typename?: 'statistic_inventory_opens_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "statistic_inventory_opens" */
export enum Statistic_Inventory_Opens_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Statistic_Inventory_Opens_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Inventory_Opens_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Inventory_Opens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Inventory_Opens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Inventory_Opens_Var_Pop_Fields = {
  __typename?: 'statistic_inventory_opens_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Inventory_Opens_Var_Samp_Fields = {
  __typename?: 'statistic_inventory_opens_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Inventory_Opens_Variance_Fields = {
  __typename?: 'statistic_inventory_opens_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_items_drops" */
export type Statistic_Items_Drops = {
  __typename?: 'statistic_items_drops';
  datetime: Scalars['timestamp'];
  id: Scalars['Int'];
  is_bot: Scalars['Boolean'];
  item_hash_name: Scalars['String'];
  item_price: Scalars['Float'];
  lootbox_id: Scalars['uuid'];
  lootbox_name: Scalars['String'];
  lootbox_price: Scalars['Int'];
  user_id: Scalars['uuid'];
  user_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "statistic_items_drops" */
export type Statistic_Items_Drops_Aggregate = {
  __typename?: 'statistic_items_drops_aggregate';
  aggregate?: Maybe<Statistic_Items_Drops_Aggregate_Fields>;
  nodes: Array<Statistic_Items_Drops>;
};

/** aggregate fields of "statistic_items_drops" */
export type Statistic_Items_Drops_Aggregate_Fields = {
  __typename?: 'statistic_items_drops_aggregate_fields';
  avg?: Maybe<Statistic_Items_Drops_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Items_Drops_Max_Fields>;
  min?: Maybe<Statistic_Items_Drops_Min_Fields>;
  stddev?: Maybe<Statistic_Items_Drops_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Items_Drops_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Items_Drops_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Items_Drops_Sum_Fields>;
  var_pop?: Maybe<Statistic_Items_Drops_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Items_Drops_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Items_Drops_Variance_Fields>;
};


/** aggregate fields of "statistic_items_drops" */
export type Statistic_Items_Drops_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Items_Drops_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Items_Drops_Avg_Fields = {
  __typename?: 'statistic_items_drops_avg_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_items_drops". All fields are combined with a logical 'AND'. */
export type Statistic_Items_Drops_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Items_Drops_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Items_Drops_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Items_Drops_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_bot?: InputMaybe<Boolean_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_price?: InputMaybe<Float_Comparison_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  lootbox_price?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_items_drops" */
export enum Statistic_Items_Drops_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticItemsDropsPkey = 'statistic_items_drops_pkey'
}

/** input type for incrementing numeric columns in table "statistic_items_drops" */
export type Statistic_Items_Drops_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "statistic_items_drops" */
export type Statistic_Items_Drops_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Statistic_Items_Drops_Max_Fields = {
  __typename?: 'statistic_items_drops_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_price?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Statistic_Items_Drops_Min_Fields = {
  __typename?: 'statistic_items_drops_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_price?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "statistic_items_drops" */
export type Statistic_Items_Drops_Mutation_Response = {
  __typename?: 'statistic_items_drops_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Items_Drops>;
};

/** on_conflict condition type for table "statistic_items_drops" */
export type Statistic_Items_Drops_On_Conflict = {
  constraint: Statistic_Items_Drops_Constraint;
  update_columns?: Array<Statistic_Items_Drops_Update_Column>;
  where?: InputMaybe<Statistic_Items_Drops_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_items_drops". */
export type Statistic_Items_Drops_Order_By = {
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_bot?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_items_drops */
export type Statistic_Items_Drops_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "statistic_items_drops" */
export enum Statistic_Items_Drops_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxPrice = 'lootbox_price',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "statistic_items_drops" */
export type Statistic_Items_Drops_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Statistic_Items_Drops_Stddev_Fields = {
  __typename?: 'statistic_items_drops_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Items_Drops_Stddev_Pop_Fields = {
  __typename?: 'statistic_items_drops_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Items_Drops_Stddev_Samp_Fields = {
  __typename?: 'statistic_items_drops_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_items_drops" */
export type Statistic_Items_Drops_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Items_Drops_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Items_Drops_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Statistic_Items_Drops_Sum_Fields = {
  __typename?: 'statistic_items_drops_sum_fields';
  id?: Maybe<Scalars['Int']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "statistic_items_drops" */
export enum Statistic_Items_Drops_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxPrice = 'lootbox_price',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Statistic_Items_Drops_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Items_Drops_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Items_Drops_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Items_Drops_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Items_Drops_Var_Pop_Fields = {
  __typename?: 'statistic_items_drops_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Items_Drops_Var_Samp_Fields = {
  __typename?: 'statistic_items_drops_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Items_Drops_Variance_Fields = {
  __typename?: 'statistic_items_drops_variance_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_items_names" */
export type Statistic_Items_Names = {
  __typename?: 'statistic_items_names';
  name: Scalars['String'];
  ru_name: Scalars['String'];
};

/** aggregated selection of "statistic_items_names" */
export type Statistic_Items_Names_Aggregate = {
  __typename?: 'statistic_items_names_aggregate';
  aggregate?: Maybe<Statistic_Items_Names_Aggregate_Fields>;
  nodes: Array<Statistic_Items_Names>;
};

/** aggregate fields of "statistic_items_names" */
export type Statistic_Items_Names_Aggregate_Fields = {
  __typename?: 'statistic_items_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Statistic_Items_Names_Max_Fields>;
  min?: Maybe<Statistic_Items_Names_Min_Fields>;
};


/** aggregate fields of "statistic_items_names" */
export type Statistic_Items_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Items_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "statistic_items_names". All fields are combined with a logical 'AND'. */
export type Statistic_Items_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Items_Names_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Items_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Items_Names_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_items_names" */
export enum Statistic_Items_Names_Constraint {
  /** unique or primary key constraint on columns "name" */
  ItemsNamesPkey = 'items_names_pkey'
}

/** input type for inserting data into table "statistic_items_names" */
export type Statistic_Items_Names_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Statistic_Items_Names_Max_Fields = {
  __typename?: 'statistic_items_names_max_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Statistic_Items_Names_Min_Fields = {
  __typename?: 'statistic_items_names_min_fields';
  name?: Maybe<Scalars['String']>;
  ru_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "statistic_items_names" */
export type Statistic_Items_Names_Mutation_Response = {
  __typename?: 'statistic_items_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Items_Names>;
};

/** input type for inserting object relation for remote table "statistic_items_names" */
export type Statistic_Items_Names_Obj_Rel_Insert_Input = {
  data: Statistic_Items_Names_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Statistic_Items_Names_On_Conflict>;
};

/** on_conflict condition type for table "statistic_items_names" */
export type Statistic_Items_Names_On_Conflict = {
  constraint: Statistic_Items_Names_Constraint;
  update_columns?: Array<Statistic_Items_Names_Update_Column>;
  where?: InputMaybe<Statistic_Items_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_items_names". */
export type Statistic_Items_Names_Order_By = {
  name?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_items_names */
export type Statistic_Items_Names_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "statistic_items_names" */
export enum Statistic_Items_Names_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

/** input type for updating data in table "statistic_items_names" */
export type Statistic_Items_Names_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "statistic_items_names" */
export type Statistic_Items_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Items_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Items_Names_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  ru_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "statistic_items_names" */
export enum Statistic_Items_Names_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  RuName = 'ru_name'
}

export type Statistic_Items_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Items_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Items_Names_Bool_Exp;
};

/** columns and relationships of "statistic_items_output_fail" */
export type Statistic_Items_Output_Fail = {
  __typename?: 'statistic_items_output_fail';
  datetime: Scalars['timestamp'];
  id: Scalars['bigint'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  price: Scalars['Float'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "statistic_items_output_fail" */
export type Statistic_Items_Output_Fail_Aggregate = {
  __typename?: 'statistic_items_output_fail_aggregate';
  aggregate?: Maybe<Statistic_Items_Output_Fail_Aggregate_Fields>;
  nodes: Array<Statistic_Items_Output_Fail>;
};

/** aggregate fields of "statistic_items_output_fail" */
export type Statistic_Items_Output_Fail_Aggregate_Fields = {
  __typename?: 'statistic_items_output_fail_aggregate_fields';
  avg?: Maybe<Statistic_Items_Output_Fail_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Items_Output_Fail_Max_Fields>;
  min?: Maybe<Statistic_Items_Output_Fail_Min_Fields>;
  stddev?: Maybe<Statistic_Items_Output_Fail_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Items_Output_Fail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Items_Output_Fail_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Items_Output_Fail_Sum_Fields>;
  var_pop?: Maybe<Statistic_Items_Output_Fail_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Items_Output_Fail_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Items_Output_Fail_Variance_Fields>;
};


/** aggregate fields of "statistic_items_output_fail" */
export type Statistic_Items_Output_Fail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Items_Output_Fail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Items_Output_Fail_Avg_Fields = {
  __typename?: 'statistic_items_output_fail_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_items_output_fail". All fields are combined with a logical 'AND'. */
export type Statistic_Items_Output_Fail_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Items_Output_Fail_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Items_Output_Fail_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Items_Output_Fail_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Float_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_items_output_fail" */
export enum Statistic_Items_Output_Fail_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticItemsOutputFailPkey = 'statistic_items_output_fail_pkey'
}

/** input type for incrementing numeric columns in table "statistic_items_output_fail" */
export type Statistic_Items_Output_Fail_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "statistic_items_output_fail" */
export type Statistic_Items_Output_Fail_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Statistic_Items_Output_Fail_Max_Fields = {
  __typename?: 'statistic_items_output_fail_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Statistic_Items_Output_Fail_Min_Fields = {
  __typename?: 'statistic_items_output_fail_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "statistic_items_output_fail" */
export type Statistic_Items_Output_Fail_Mutation_Response = {
  __typename?: 'statistic_items_output_fail_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Items_Output_Fail>;
};

/** on_conflict condition type for table "statistic_items_output_fail" */
export type Statistic_Items_Output_Fail_On_Conflict = {
  constraint: Statistic_Items_Output_Fail_Constraint;
  update_columns?: Array<Statistic_Items_Output_Fail_Update_Column>;
  where?: InputMaybe<Statistic_Items_Output_Fail_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_items_output_fail". */
export type Statistic_Items_Output_Fail_Order_By = {
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_items_output_fail */
export type Statistic_Items_Output_Fail_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "statistic_items_output_fail" */
export enum Statistic_Items_Output_Fail_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Price = 'price',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "statistic_items_output_fail" */
export type Statistic_Items_Output_Fail_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Statistic_Items_Output_Fail_Stddev_Fields = {
  __typename?: 'statistic_items_output_fail_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Items_Output_Fail_Stddev_Pop_Fields = {
  __typename?: 'statistic_items_output_fail_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Items_Output_Fail_Stddev_Samp_Fields = {
  __typename?: 'statistic_items_output_fail_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_items_output_fail" */
export type Statistic_Items_Output_Fail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Items_Output_Fail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Items_Output_Fail_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Statistic_Items_Output_Fail_Sum_Fields = {
  __typename?: 'statistic_items_output_fail_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Float']>;
};

/** update columns of table "statistic_items_output_fail" */
export enum Statistic_Items_Output_Fail_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Price = 'price',
  /** column name */
  UserId = 'user_id'
}

export type Statistic_Items_Output_Fail_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Items_Output_Fail_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Items_Output_Fail_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Items_Output_Fail_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Items_Output_Fail_Var_Pop_Fields = {
  __typename?: 'statistic_items_output_fail_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Items_Output_Fail_Var_Samp_Fields = {
  __typename?: 'statistic_items_output_fail_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Items_Output_Fail_Variance_Fields = {
  __typename?: 'statistic_items_output_fail_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_items_output_success" */
export type Statistic_Items_Output_Success = {
  __typename?: 'statistic_items_output_success';
  datetime: Scalars['timestamp'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_price: Scalars['Float'];
  user_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};

/** aggregated selection of "statistic_items_output_success" */
export type Statistic_Items_Output_Success_Aggregate = {
  __typename?: 'statistic_items_output_success_aggregate';
  aggregate?: Maybe<Statistic_Items_Output_Success_Aggregate_Fields>;
  nodes: Array<Statistic_Items_Output_Success>;
};

/** aggregate fields of "statistic_items_output_success" */
export type Statistic_Items_Output_Success_Aggregate_Fields = {
  __typename?: 'statistic_items_output_success_aggregate_fields';
  avg?: Maybe<Statistic_Items_Output_Success_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Items_Output_Success_Max_Fields>;
  min?: Maybe<Statistic_Items_Output_Success_Min_Fields>;
  stddev?: Maybe<Statistic_Items_Output_Success_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Items_Output_Success_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Items_Output_Success_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Items_Output_Success_Sum_Fields>;
  var_pop?: Maybe<Statistic_Items_Output_Success_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Items_Output_Success_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Items_Output_Success_Variance_Fields>;
};


/** aggregate fields of "statistic_items_output_success" */
export type Statistic_Items_Output_Success_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Items_Output_Success_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Items_Output_Success_Avg_Fields = {
  __typename?: 'statistic_items_output_success_avg_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_items_output_success". All fields are combined with a logical 'AND'. */
export type Statistic_Items_Output_Success_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Items_Output_Success_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Items_Output_Success_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Items_Output_Success_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_price?: InputMaybe<Float_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_item_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_items_output_success" */
export enum Statistic_Items_Output_Success_Constraint {
  /** unique or primary key constraint on columns "user_item_id" */
  StatisticItemsOutputSuccessPkey = 'statistic_items_output_success_pkey'
}

/** input type for incrementing numeric columns in table "statistic_items_output_success" */
export type Statistic_Items_Output_Success_Inc_Input = {
  item_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "statistic_items_output_success" */
export type Statistic_Items_Output_Success_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Statistic_Items_Output_Success_Max_Fields = {
  __typename?: 'statistic_items_output_success_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Statistic_Items_Output_Success_Min_Fields = {
  __typename?: 'statistic_items_output_success_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "statistic_items_output_success" */
export type Statistic_Items_Output_Success_Mutation_Response = {
  __typename?: 'statistic_items_output_success_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Items_Output_Success>;
};

/** on_conflict condition type for table "statistic_items_output_success" */
export type Statistic_Items_Output_Success_On_Conflict = {
  constraint: Statistic_Items_Output_Success_Constraint;
  update_columns?: Array<Statistic_Items_Output_Success_Update_Column>;
  where?: InputMaybe<Statistic_Items_Output_Success_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_items_output_success". */
export type Statistic_Items_Output_Success_Order_By = {
  datetime?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_item_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_items_output_success */
export type Statistic_Items_Output_Success_Pk_Columns_Input = {
  user_item_id: Scalars['uuid'];
};

/** select columns of table "statistic_items_output_success" */
export enum Statistic_Items_Output_Success_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserItemId = 'user_item_id'
}

/** input type for updating data in table "statistic_items_output_success" */
export type Statistic_Items_Output_Success_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Statistic_Items_Output_Success_Stddev_Fields = {
  __typename?: 'statistic_items_output_success_stddev_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Items_Output_Success_Stddev_Pop_Fields = {
  __typename?: 'statistic_items_output_success_stddev_pop_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Items_Output_Success_Stddev_Samp_Fields = {
  __typename?: 'statistic_items_output_success_stddev_samp_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_items_output_success" */
export type Statistic_Items_Output_Success_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Items_Output_Success_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Items_Output_Success_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Statistic_Items_Output_Success_Sum_Fields = {
  __typename?: 'statistic_items_output_success_sum_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** update columns of table "statistic_items_output_success" */
export enum Statistic_Items_Output_Success_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserItemId = 'user_item_id'
}

export type Statistic_Items_Output_Success_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Items_Output_Success_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Items_Output_Success_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Items_Output_Success_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Items_Output_Success_Var_Pop_Fields = {
  __typename?: 'statistic_items_output_success_var_pop_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Items_Output_Success_Var_Samp_Fields = {
  __typename?: 'statistic_items_output_success_var_samp_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Items_Output_Success_Variance_Fields = {
  __typename?: 'statistic_items_output_success_variance_fields';
  item_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_lootbox_opens" */
export type Statistic_Lootbox_Opens = {
  __typename?: 'statistic_lootbox_opens';
  datetime: Scalars['timestamp'];
  id: Scalars['Int'];
  is_bot: Scalars['Boolean'];
  lootbox_id: Scalars['uuid'];
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_price: Scalars['Int'];
  user_id: Scalars['uuid'];
  user_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "statistic_lootbox_opens" */
export type Statistic_Lootbox_Opens_Aggregate = {
  __typename?: 'statistic_lootbox_opens_aggregate';
  aggregate?: Maybe<Statistic_Lootbox_Opens_Aggregate_Fields>;
  nodes: Array<Statistic_Lootbox_Opens>;
};

/** aggregate fields of "statistic_lootbox_opens" */
export type Statistic_Lootbox_Opens_Aggregate_Fields = {
  __typename?: 'statistic_lootbox_opens_aggregate_fields';
  avg?: Maybe<Statistic_Lootbox_Opens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Lootbox_Opens_Max_Fields>;
  min?: Maybe<Statistic_Lootbox_Opens_Min_Fields>;
  stddev?: Maybe<Statistic_Lootbox_Opens_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Lootbox_Opens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Lootbox_Opens_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Lootbox_Opens_Sum_Fields>;
  var_pop?: Maybe<Statistic_Lootbox_Opens_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Lootbox_Opens_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Lootbox_Opens_Variance_Fields>;
};


/** aggregate fields of "statistic_lootbox_opens" */
export type Statistic_Lootbox_Opens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Lootbox_Opens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Lootbox_Opens_Avg_Fields = {
  __typename?: 'statistic_lootbox_opens_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_lootbox_opens". All fields are combined with a logical 'AND'. */
export type Statistic_Lootbox_Opens_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Lootbox_Opens_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Lootbox_Opens_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Lootbox_Opens_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_bot?: InputMaybe<Boolean_Comparison_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  lootbox_price?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_lootbox_opens" */
export enum Statistic_Lootbox_Opens_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticLootboxOpensPkey = 'statistic_lootbox_opens_pkey'
}

/** input type for incrementing numeric columns in table "statistic_lootbox_opens" */
export type Statistic_Lootbox_Opens_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "statistic_lootbox_opens" */
export type Statistic_Lootbox_Opens_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Statistic_Lootbox_Opens_Max_Fields = {
  __typename?: 'statistic_lootbox_opens_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_price?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Statistic_Lootbox_Opens_Min_Fields = {
  __typename?: 'statistic_lootbox_opens_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_price?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "statistic_lootbox_opens" */
export type Statistic_Lootbox_Opens_Mutation_Response = {
  __typename?: 'statistic_lootbox_opens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Lootbox_Opens>;
};

/** on_conflict condition type for table "statistic_lootbox_opens" */
export type Statistic_Lootbox_Opens_On_Conflict = {
  constraint: Statistic_Lootbox_Opens_Constraint;
  update_columns?: Array<Statistic_Lootbox_Opens_Update_Column>;
  where?: InputMaybe<Statistic_Lootbox_Opens_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_lootbox_opens". */
export type Statistic_Lootbox_Opens_Order_By = {
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_bot?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_lootbox_opens */
export type Statistic_Lootbox_Opens_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "statistic_lootbox_opens" */
export enum Statistic_Lootbox_Opens_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxPrice = 'lootbox_price',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "statistic_lootbox_opens" */
export type Statistic_Lootbox_Opens_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Statistic_Lootbox_Opens_Stddev_Fields = {
  __typename?: 'statistic_lootbox_opens_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Lootbox_Opens_Stddev_Pop_Fields = {
  __typename?: 'statistic_lootbox_opens_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Lootbox_Opens_Stddev_Samp_Fields = {
  __typename?: 'statistic_lootbox_opens_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_lootbox_opens" */
export type Statistic_Lootbox_Opens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Lootbox_Opens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Lootbox_Opens_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Statistic_Lootbox_Opens_Sum_Fields = {
  __typename?: 'statistic_lootbox_opens_sum_fields';
  id?: Maybe<Scalars['Int']>;
  lootbox_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "statistic_lootbox_opens" */
export enum Statistic_Lootbox_Opens_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxPrice = 'lootbox_price',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Statistic_Lootbox_Opens_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Lootbox_Opens_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Lootbox_Opens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Lootbox_Opens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Lootbox_Opens_Var_Pop_Fields = {
  __typename?: 'statistic_lootbox_opens_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Lootbox_Opens_Var_Samp_Fields = {
  __typename?: 'statistic_lootbox_opens_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Lootbox_Opens_Variance_Fields = {
  __typename?: 'statistic_lootbox_opens_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_lootbox_views" */
export type Statistic_Lootbox_Views = {
  __typename?: 'statistic_lootbox_views';
  datetime: Scalars['timestamp'];
  id: Scalars['Int'];
  lootbox_id: Scalars['uuid'];
  lootbox_name: Scalars['String'];
  lootbox_price: Scalars['Int'];
};

/** aggregated selection of "statistic_lootbox_views" */
export type Statistic_Lootbox_Views_Aggregate = {
  __typename?: 'statistic_lootbox_views_aggregate';
  aggregate?: Maybe<Statistic_Lootbox_Views_Aggregate_Fields>;
  nodes: Array<Statistic_Lootbox_Views>;
};

/** aggregate fields of "statistic_lootbox_views" */
export type Statistic_Lootbox_Views_Aggregate_Fields = {
  __typename?: 'statistic_lootbox_views_aggregate_fields';
  avg?: Maybe<Statistic_Lootbox_Views_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Lootbox_Views_Max_Fields>;
  min?: Maybe<Statistic_Lootbox_Views_Min_Fields>;
  stddev?: Maybe<Statistic_Lootbox_Views_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Lootbox_Views_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Lootbox_Views_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Lootbox_Views_Sum_Fields>;
  var_pop?: Maybe<Statistic_Lootbox_Views_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Lootbox_Views_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Lootbox_Views_Variance_Fields>;
};


/** aggregate fields of "statistic_lootbox_views" */
export type Statistic_Lootbox_Views_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Lootbox_Views_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Lootbox_Views_Avg_Fields = {
  __typename?: 'statistic_lootbox_views_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_lootbox_views". All fields are combined with a logical 'AND'. */
export type Statistic_Lootbox_Views_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Lootbox_Views_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Lootbox_Views_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Lootbox_Views_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  lootbox_price?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_lootbox_views" */
export enum Statistic_Lootbox_Views_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticLootboxViewsPkey = 'statistic_lootbox_views_pkey'
}

/** input type for incrementing numeric columns in table "statistic_lootbox_views" */
export type Statistic_Lootbox_Views_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "statistic_lootbox_views" */
export type Statistic_Lootbox_Views_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Statistic_Lootbox_Views_Max_Fields = {
  __typename?: 'statistic_lootbox_views_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_price?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Statistic_Lootbox_Views_Min_Fields = {
  __typename?: 'statistic_lootbox_views_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_price?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "statistic_lootbox_views" */
export type Statistic_Lootbox_Views_Mutation_Response = {
  __typename?: 'statistic_lootbox_views_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Lootbox_Views>;
};

/** on_conflict condition type for table "statistic_lootbox_views" */
export type Statistic_Lootbox_Views_On_Conflict = {
  constraint: Statistic_Lootbox_Views_Constraint;
  update_columns?: Array<Statistic_Lootbox_Views_Update_Column>;
  where?: InputMaybe<Statistic_Lootbox_Views_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_lootbox_views". */
export type Statistic_Lootbox_Views_Order_By = {
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_lootbox_views */
export type Statistic_Lootbox_Views_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "statistic_lootbox_views" */
export enum Statistic_Lootbox_Views_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxPrice = 'lootbox_price'
}

/** input type for updating data in table "statistic_lootbox_views" */
export type Statistic_Lootbox_Views_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Statistic_Lootbox_Views_Stddev_Fields = {
  __typename?: 'statistic_lootbox_views_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Lootbox_Views_Stddev_Pop_Fields = {
  __typename?: 'statistic_lootbox_views_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Lootbox_Views_Stddev_Samp_Fields = {
  __typename?: 'statistic_lootbox_views_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_lootbox_views" */
export type Statistic_Lootbox_Views_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Lootbox_Views_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Lootbox_Views_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_price?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Statistic_Lootbox_Views_Sum_Fields = {
  __typename?: 'statistic_lootbox_views_sum_fields';
  id?: Maybe<Scalars['Int']>;
  lootbox_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "statistic_lootbox_views" */
export enum Statistic_Lootbox_Views_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxPrice = 'lootbox_price'
}

export type Statistic_Lootbox_Views_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Lootbox_Views_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Lootbox_Views_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Lootbox_Views_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Lootbox_Views_Var_Pop_Fields = {
  __typename?: 'statistic_lootbox_views_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Lootbox_Views_Var_Samp_Fields = {
  __typename?: 'statistic_lootbox_views_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Lootbox_Views_Variance_Fields = {
  __typename?: 'statistic_lootbox_views_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lootbox_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_mainpage_visits" */
export type Statistic_Mainpage_Visits = {
  __typename?: 'statistic_mainpage_visits';
  datetime: Scalars['timestamp'];
  id: Scalars['Int'];
};

/** aggregated selection of "statistic_mainpage_visits" */
export type Statistic_Mainpage_Visits_Aggregate = {
  __typename?: 'statistic_mainpage_visits_aggregate';
  aggregate?: Maybe<Statistic_Mainpage_Visits_Aggregate_Fields>;
  nodes: Array<Statistic_Mainpage_Visits>;
};

/** aggregate fields of "statistic_mainpage_visits" */
export type Statistic_Mainpage_Visits_Aggregate_Fields = {
  __typename?: 'statistic_mainpage_visits_aggregate_fields';
  avg?: Maybe<Statistic_Mainpage_Visits_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Mainpage_Visits_Max_Fields>;
  min?: Maybe<Statistic_Mainpage_Visits_Min_Fields>;
  stddev?: Maybe<Statistic_Mainpage_Visits_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Mainpage_Visits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Mainpage_Visits_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Mainpage_Visits_Sum_Fields>;
  var_pop?: Maybe<Statistic_Mainpage_Visits_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Mainpage_Visits_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Mainpage_Visits_Variance_Fields>;
};


/** aggregate fields of "statistic_mainpage_visits" */
export type Statistic_Mainpage_Visits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Mainpage_Visits_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Mainpage_Visits_Avg_Fields = {
  __typename?: 'statistic_mainpage_visits_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_mainpage_visits". All fields are combined with a logical 'AND'. */
export type Statistic_Mainpage_Visits_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Mainpage_Visits_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Mainpage_Visits_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Mainpage_Visits_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_mainpage_visits" */
export enum Statistic_Mainpage_Visits_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticMainpageVisitsPkey = 'statistic_mainpage_visits_pkey'
}

/** input type for incrementing numeric columns in table "statistic_mainpage_visits" */
export type Statistic_Mainpage_Visits_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "statistic_mainpage_visits" */
export type Statistic_Mainpage_Visits_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Statistic_Mainpage_Visits_Max_Fields = {
  __typename?: 'statistic_mainpage_visits_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Statistic_Mainpage_Visits_Min_Fields = {
  __typename?: 'statistic_mainpage_visits_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "statistic_mainpage_visits" */
export type Statistic_Mainpage_Visits_Mutation_Response = {
  __typename?: 'statistic_mainpage_visits_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Mainpage_Visits>;
};

/** on_conflict condition type for table "statistic_mainpage_visits" */
export type Statistic_Mainpage_Visits_On_Conflict = {
  constraint: Statistic_Mainpage_Visits_Constraint;
  update_columns?: Array<Statistic_Mainpage_Visits_Update_Column>;
  where?: InputMaybe<Statistic_Mainpage_Visits_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_mainpage_visits". */
export type Statistic_Mainpage_Visits_Order_By = {
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_mainpage_visits */
export type Statistic_Mainpage_Visits_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "statistic_mainpage_visits" */
export enum Statistic_Mainpage_Visits_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "statistic_mainpage_visits" */
export type Statistic_Mainpage_Visits_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Statistic_Mainpage_Visits_Stddev_Fields = {
  __typename?: 'statistic_mainpage_visits_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Mainpage_Visits_Stddev_Pop_Fields = {
  __typename?: 'statistic_mainpage_visits_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Mainpage_Visits_Stddev_Samp_Fields = {
  __typename?: 'statistic_mainpage_visits_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_mainpage_visits" */
export type Statistic_Mainpage_Visits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Mainpage_Visits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Mainpage_Visits_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Statistic_Mainpage_Visits_Sum_Fields = {
  __typename?: 'statistic_mainpage_visits_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "statistic_mainpage_visits" */
export enum Statistic_Mainpage_Visits_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id'
}

export type Statistic_Mainpage_Visits_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Mainpage_Visits_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Mainpage_Visits_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Mainpage_Visits_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Mainpage_Visits_Var_Pop_Fields = {
  __typename?: 'statistic_mainpage_visits_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Mainpage_Visits_Var_Samp_Fields = {
  __typename?: 'statistic_mainpage_visits_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Mainpage_Visits_Variance_Fields = {
  __typename?: 'statistic_mainpage_visits_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_promocode_full_activations" */
export type Statistic_Promocode_Full_Activations = {
  __typename?: 'statistic_promocode_full_activations';
  datetime: Scalars['timestamp'];
  deposit_amount: Scalars['Int'];
  id: Scalars['bigint'];
  payment_system: Scalars['String'];
  promocode_id: Scalars['uuid'];
  promocode_slug: Scalars['String'];
  transaction_id?: Maybe<Scalars['String']>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "statistic_promocode_full_activations" */
export type Statistic_Promocode_Full_Activations_Aggregate = {
  __typename?: 'statistic_promocode_full_activations_aggregate';
  aggregate?: Maybe<Statistic_Promocode_Full_Activations_Aggregate_Fields>;
  nodes: Array<Statistic_Promocode_Full_Activations>;
};

/** aggregate fields of "statistic_promocode_full_activations" */
export type Statistic_Promocode_Full_Activations_Aggregate_Fields = {
  __typename?: 'statistic_promocode_full_activations_aggregate_fields';
  avg?: Maybe<Statistic_Promocode_Full_Activations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Promocode_Full_Activations_Max_Fields>;
  min?: Maybe<Statistic_Promocode_Full_Activations_Min_Fields>;
  stddev?: Maybe<Statistic_Promocode_Full_Activations_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Promocode_Full_Activations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Promocode_Full_Activations_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Promocode_Full_Activations_Sum_Fields>;
  var_pop?: Maybe<Statistic_Promocode_Full_Activations_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Promocode_Full_Activations_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Promocode_Full_Activations_Variance_Fields>;
};


/** aggregate fields of "statistic_promocode_full_activations" */
export type Statistic_Promocode_Full_Activations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Promocode_Full_Activations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Promocode_Full_Activations_Avg_Fields = {
  __typename?: 'statistic_promocode_full_activations_avg_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_promocode_full_activations". All fields are combined with a logical 'AND'. */
export type Statistic_Promocode_Full_Activations_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Promocode_Full_Activations_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Promocode_Full_Activations_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Promocode_Full_Activations_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  deposit_amount?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  payment_system?: InputMaybe<String_Comparison_Exp>;
  promocode_id?: InputMaybe<Uuid_Comparison_Exp>;
  promocode_slug?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_promocode_full_activations" */
export enum Statistic_Promocode_Full_Activations_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticPromocodeFullActivationsPkey = 'statistic_promocode_full_activations_pkey'
}

/** input type for incrementing numeric columns in table "statistic_promocode_full_activations" */
export type Statistic_Promocode_Full_Activations_Inc_Input = {
  deposit_amount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "statistic_promocode_full_activations" */
export type Statistic_Promocode_Full_Activations_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  deposit_amount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  payment_system?: InputMaybe<Scalars['String']>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Statistic_Promocode_Full_Activations_Max_Fields = {
  __typename?: 'statistic_promocode_full_activations_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  deposit_amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  payment_system?: Maybe<Scalars['String']>;
  promocode_id?: Maybe<Scalars['uuid']>;
  promocode_slug?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Statistic_Promocode_Full_Activations_Min_Fields = {
  __typename?: 'statistic_promocode_full_activations_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  deposit_amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  payment_system?: Maybe<Scalars['String']>;
  promocode_id?: Maybe<Scalars['uuid']>;
  promocode_slug?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "statistic_promocode_full_activations" */
export type Statistic_Promocode_Full_Activations_Mutation_Response = {
  __typename?: 'statistic_promocode_full_activations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Promocode_Full_Activations>;
};

/** on_conflict condition type for table "statistic_promocode_full_activations" */
export type Statistic_Promocode_Full_Activations_On_Conflict = {
  constraint: Statistic_Promocode_Full_Activations_Constraint;
  update_columns?: Array<Statistic_Promocode_Full_Activations_Update_Column>;
  where?: InputMaybe<Statistic_Promocode_Full_Activations_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_promocode_full_activations". */
export type Statistic_Promocode_Full_Activations_Order_By = {
  datetime?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
  promocode_id?: InputMaybe<Order_By>;
  promocode_slug?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_promocode_full_activations */
export type Statistic_Promocode_Full_Activations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "statistic_promocode_full_activations" */
export enum Statistic_Promocode_Full_Activations_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DepositAmount = 'deposit_amount',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentSystem = 'payment_system',
  /** column name */
  PromocodeId = 'promocode_id',
  /** column name */
  PromocodeSlug = 'promocode_slug',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "statistic_promocode_full_activations" */
export type Statistic_Promocode_Full_Activations_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  deposit_amount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  payment_system?: InputMaybe<Scalars['String']>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Statistic_Promocode_Full_Activations_Stddev_Fields = {
  __typename?: 'statistic_promocode_full_activations_stddev_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Promocode_Full_Activations_Stddev_Pop_Fields = {
  __typename?: 'statistic_promocode_full_activations_stddev_pop_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Promocode_Full_Activations_Stddev_Samp_Fields = {
  __typename?: 'statistic_promocode_full_activations_stddev_samp_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_promocode_full_activations" */
export type Statistic_Promocode_Full_Activations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Promocode_Full_Activations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Promocode_Full_Activations_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  deposit_amount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  payment_system?: InputMaybe<Scalars['String']>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Statistic_Promocode_Full_Activations_Sum_Fields = {
  __typename?: 'statistic_promocode_full_activations_sum_fields';
  deposit_amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "statistic_promocode_full_activations" */
export enum Statistic_Promocode_Full_Activations_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DepositAmount = 'deposit_amount',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentSystem = 'payment_system',
  /** column name */
  PromocodeId = 'promocode_id',
  /** column name */
  PromocodeSlug = 'promocode_slug',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

export type Statistic_Promocode_Full_Activations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Promocode_Full_Activations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Promocode_Full_Activations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Promocode_Full_Activations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Promocode_Full_Activations_Var_Pop_Fields = {
  __typename?: 'statistic_promocode_full_activations_var_pop_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Promocode_Full_Activations_Var_Samp_Fields = {
  __typename?: 'statistic_promocode_full_activations_var_samp_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Promocode_Full_Activations_Variance_Fields = {
  __typename?: 'statistic_promocode_full_activations_variance_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_promocode_success_activations" */
export type Statistic_Promocode_Success_Activations = {
  __typename?: 'statistic_promocode_success_activations';
  datetime: Scalars['timestamp'];
  deposit_amount: Scalars['Float'];
  id: Scalars['bigint'];
  payment_system: Scalars['String'];
  promocode_id: Scalars['uuid'];
  promocode_slug: Scalars['String'];
  transaction_id: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "statistic_promocode_success_activations" */
export type Statistic_Promocode_Success_Activations_Aggregate = {
  __typename?: 'statistic_promocode_success_activations_aggregate';
  aggregate?: Maybe<Statistic_Promocode_Success_Activations_Aggregate_Fields>;
  nodes: Array<Statistic_Promocode_Success_Activations>;
};

/** aggregate fields of "statistic_promocode_success_activations" */
export type Statistic_Promocode_Success_Activations_Aggregate_Fields = {
  __typename?: 'statistic_promocode_success_activations_aggregate_fields';
  avg?: Maybe<Statistic_Promocode_Success_Activations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Promocode_Success_Activations_Max_Fields>;
  min?: Maybe<Statistic_Promocode_Success_Activations_Min_Fields>;
  stddev?: Maybe<Statistic_Promocode_Success_Activations_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Promocode_Success_Activations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Promocode_Success_Activations_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Promocode_Success_Activations_Sum_Fields>;
  var_pop?: Maybe<Statistic_Promocode_Success_Activations_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Promocode_Success_Activations_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Promocode_Success_Activations_Variance_Fields>;
};


/** aggregate fields of "statistic_promocode_success_activations" */
export type Statistic_Promocode_Success_Activations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Promocode_Success_Activations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Promocode_Success_Activations_Avg_Fields = {
  __typename?: 'statistic_promocode_success_activations_avg_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_promocode_success_activations". All fields are combined with a logical 'AND'. */
export type Statistic_Promocode_Success_Activations_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Promocode_Success_Activations_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Promocode_Success_Activations_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Promocode_Success_Activations_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  deposit_amount?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  payment_system?: InputMaybe<String_Comparison_Exp>;
  promocode_id?: InputMaybe<Uuid_Comparison_Exp>;
  promocode_slug?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_promocode_success_activations" */
export enum Statistic_Promocode_Success_Activations_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticPromocodeSuccessActivationsPkey = 'statistic_promocode_success_activations_pkey'
}

/** input type for incrementing numeric columns in table "statistic_promocode_success_activations" */
export type Statistic_Promocode_Success_Activations_Inc_Input = {
  deposit_amount?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "statistic_promocode_success_activations" */
export type Statistic_Promocode_Success_Activations_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  deposit_amount?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['bigint']>;
  payment_system?: InputMaybe<Scalars['String']>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Statistic_Promocode_Success_Activations_Max_Fields = {
  __typename?: 'statistic_promocode_success_activations_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['bigint']>;
  payment_system?: Maybe<Scalars['String']>;
  promocode_id?: Maybe<Scalars['uuid']>;
  promocode_slug?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Statistic_Promocode_Success_Activations_Min_Fields = {
  __typename?: 'statistic_promocode_success_activations_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['bigint']>;
  payment_system?: Maybe<Scalars['String']>;
  promocode_id?: Maybe<Scalars['uuid']>;
  promocode_slug?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "statistic_promocode_success_activations" */
export type Statistic_Promocode_Success_Activations_Mutation_Response = {
  __typename?: 'statistic_promocode_success_activations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Promocode_Success_Activations>;
};

/** on_conflict condition type for table "statistic_promocode_success_activations" */
export type Statistic_Promocode_Success_Activations_On_Conflict = {
  constraint: Statistic_Promocode_Success_Activations_Constraint;
  update_columns?: Array<Statistic_Promocode_Success_Activations_Update_Column>;
  where?: InputMaybe<Statistic_Promocode_Success_Activations_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_promocode_success_activations". */
export type Statistic_Promocode_Success_Activations_Order_By = {
  datetime?: InputMaybe<Order_By>;
  deposit_amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
  promocode_id?: InputMaybe<Order_By>;
  promocode_slug?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_promocode_success_activations */
export type Statistic_Promocode_Success_Activations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "statistic_promocode_success_activations" */
export enum Statistic_Promocode_Success_Activations_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DepositAmount = 'deposit_amount',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentSystem = 'payment_system',
  /** column name */
  PromocodeId = 'promocode_id',
  /** column name */
  PromocodeSlug = 'promocode_slug',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "statistic_promocode_success_activations" */
export type Statistic_Promocode_Success_Activations_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  deposit_amount?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['bigint']>;
  payment_system?: InputMaybe<Scalars['String']>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Statistic_Promocode_Success_Activations_Stddev_Fields = {
  __typename?: 'statistic_promocode_success_activations_stddev_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Promocode_Success_Activations_Stddev_Pop_Fields = {
  __typename?: 'statistic_promocode_success_activations_stddev_pop_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Promocode_Success_Activations_Stddev_Samp_Fields = {
  __typename?: 'statistic_promocode_success_activations_stddev_samp_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_promocode_success_activations" */
export type Statistic_Promocode_Success_Activations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Promocode_Success_Activations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Promocode_Success_Activations_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  deposit_amount?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['bigint']>;
  payment_system?: InputMaybe<Scalars['String']>;
  promocode_id?: InputMaybe<Scalars['uuid']>;
  promocode_slug?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Statistic_Promocode_Success_Activations_Sum_Fields = {
  __typename?: 'statistic_promocode_success_activations_sum_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "statistic_promocode_success_activations" */
export enum Statistic_Promocode_Success_Activations_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DepositAmount = 'deposit_amount',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentSystem = 'payment_system',
  /** column name */
  PromocodeId = 'promocode_id',
  /** column name */
  PromocodeSlug = 'promocode_slug',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

export type Statistic_Promocode_Success_Activations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Promocode_Success_Activations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Promocode_Success_Activations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Promocode_Success_Activations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Promocode_Success_Activations_Var_Pop_Fields = {
  __typename?: 'statistic_promocode_success_activations_var_pop_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Promocode_Success_Activations_Var_Samp_Fields = {
  __typename?: 'statistic_promocode_success_activations_var_samp_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Promocode_Success_Activations_Variance_Fields = {
  __typename?: 'statistic_promocode_success_activations_variance_fields';
  deposit_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_sell_all_items" */
export type Statistic_Sell_All_Items = {
  __typename?: 'statistic_sell_all_items';
  datetime: Scalars['timestamp'];
  id: Scalars['Int'];
  is_bot: Scalars['Boolean'];
  user_id: Scalars['uuid'];
  user_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "statistic_sell_all_items" */
export type Statistic_Sell_All_Items_Aggregate = {
  __typename?: 'statistic_sell_all_items_aggregate';
  aggregate?: Maybe<Statistic_Sell_All_Items_Aggregate_Fields>;
  nodes: Array<Statistic_Sell_All_Items>;
};

/** aggregate fields of "statistic_sell_all_items" */
export type Statistic_Sell_All_Items_Aggregate_Fields = {
  __typename?: 'statistic_sell_all_items_aggregate_fields';
  avg?: Maybe<Statistic_Sell_All_Items_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Sell_All_Items_Max_Fields>;
  min?: Maybe<Statistic_Sell_All_Items_Min_Fields>;
  stddev?: Maybe<Statistic_Sell_All_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Sell_All_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Sell_All_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Sell_All_Items_Sum_Fields>;
  var_pop?: Maybe<Statistic_Sell_All_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Sell_All_Items_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Sell_All_Items_Variance_Fields>;
};


/** aggregate fields of "statistic_sell_all_items" */
export type Statistic_Sell_All_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Sell_All_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Sell_All_Items_Avg_Fields = {
  __typename?: 'statistic_sell_all_items_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_sell_all_items". All fields are combined with a logical 'AND'. */
export type Statistic_Sell_All_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Sell_All_Items_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Sell_All_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Sell_All_Items_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_bot?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_sell_all_items" */
export enum Statistic_Sell_All_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticSellAllItemsPkey = 'statistic_sell_all_items_pkey'
}

/** input type for incrementing numeric columns in table "statistic_sell_all_items" */
export type Statistic_Sell_All_Items_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "statistic_sell_all_items" */
export type Statistic_Sell_All_Items_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Statistic_Sell_All_Items_Max_Fields = {
  __typename?: 'statistic_sell_all_items_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Statistic_Sell_All_Items_Min_Fields = {
  __typename?: 'statistic_sell_all_items_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "statistic_sell_all_items" */
export type Statistic_Sell_All_Items_Mutation_Response = {
  __typename?: 'statistic_sell_all_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Sell_All_Items>;
};

/** on_conflict condition type for table "statistic_sell_all_items" */
export type Statistic_Sell_All_Items_On_Conflict = {
  constraint: Statistic_Sell_All_Items_Constraint;
  update_columns?: Array<Statistic_Sell_All_Items_Update_Column>;
  where?: InputMaybe<Statistic_Sell_All_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_sell_all_items". */
export type Statistic_Sell_All_Items_Order_By = {
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_bot?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_sell_all_items */
export type Statistic_Sell_All_Items_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "statistic_sell_all_items" */
export enum Statistic_Sell_All_Items_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "statistic_sell_all_items" */
export type Statistic_Sell_All_Items_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Statistic_Sell_All_Items_Stddev_Fields = {
  __typename?: 'statistic_sell_all_items_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Sell_All_Items_Stddev_Pop_Fields = {
  __typename?: 'statistic_sell_all_items_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Sell_All_Items_Stddev_Samp_Fields = {
  __typename?: 'statistic_sell_all_items_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_sell_all_items" */
export type Statistic_Sell_All_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Sell_All_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Sell_All_Items_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Statistic_Sell_All_Items_Sum_Fields = {
  __typename?: 'statistic_sell_all_items_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "statistic_sell_all_items" */
export enum Statistic_Sell_All_Items_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Statistic_Sell_All_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Sell_All_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Sell_All_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Sell_All_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Sell_All_Items_Var_Pop_Fields = {
  __typename?: 'statistic_sell_all_items_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Sell_All_Items_Var_Samp_Fields = {
  __typename?: 'statistic_sell_all_items_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Sell_All_Items_Variance_Fields = {
  __typename?: 'statistic_sell_all_items_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_sell_item" */
export type Statistic_Sell_Item = {
  __typename?: 'statistic_sell_item';
  datetime: Scalars['timestamp'];
  id: Scalars['Int'];
  is_bot: Scalars['Boolean'];
  item_id: Scalars['uuid'];
  item_name?: Maybe<Scalars['String']>;
  item_price: Scalars['Float'];
  user_id: Scalars['uuid'];
  user_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "statistic_sell_item" */
export type Statistic_Sell_Item_Aggregate = {
  __typename?: 'statistic_sell_item_aggregate';
  aggregate?: Maybe<Statistic_Sell_Item_Aggregate_Fields>;
  nodes: Array<Statistic_Sell_Item>;
};

/** aggregate fields of "statistic_sell_item" */
export type Statistic_Sell_Item_Aggregate_Fields = {
  __typename?: 'statistic_sell_item_aggregate_fields';
  avg?: Maybe<Statistic_Sell_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_Sell_Item_Max_Fields>;
  min?: Maybe<Statistic_Sell_Item_Min_Fields>;
  stddev?: Maybe<Statistic_Sell_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_Sell_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_Sell_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_Sell_Item_Sum_Fields>;
  var_pop?: Maybe<Statistic_Sell_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_Sell_Item_Var_Samp_Fields>;
  variance?: Maybe<Statistic_Sell_Item_Variance_Fields>;
};


/** aggregate fields of "statistic_sell_item" */
export type Statistic_Sell_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_Sell_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_Sell_Item_Avg_Fields = {
  __typename?: 'statistic_sell_item_avg_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_sell_item". All fields are combined with a logical 'AND'. */
export type Statistic_Sell_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_Sell_Item_Bool_Exp>>;
  _not?: InputMaybe<Statistic_Sell_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_Sell_Item_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_bot?: InputMaybe<Boolean_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_name?: InputMaybe<String_Comparison_Exp>;
  item_price?: InputMaybe<Float_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_sell_item" */
export enum Statistic_Sell_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  StatisticSellItemPkey = 'statistic_sell_item_pkey'
}

/** input type for incrementing numeric columns in table "statistic_sell_item" */
export type Statistic_Sell_Item_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  item_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "statistic_sell_item" */
export type Statistic_Sell_Item_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_name?: InputMaybe<Scalars['String']>;
  item_price?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Statistic_Sell_Item_Max_Fields = {
  __typename?: 'statistic_sell_item_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_name?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Statistic_Sell_Item_Min_Fields = {
  __typename?: 'statistic_sell_item_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_name?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "statistic_sell_item" */
export type Statistic_Sell_Item_Mutation_Response = {
  __typename?: 'statistic_sell_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_Sell_Item>;
};

/** on_conflict condition type for table "statistic_sell_item" */
export type Statistic_Sell_Item_On_Conflict = {
  constraint: Statistic_Sell_Item_Constraint;
  update_columns?: Array<Statistic_Sell_Item_Update_Column>;
  where?: InputMaybe<Statistic_Sell_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_sell_item". */
export type Statistic_Sell_Item_Order_By = {
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_bot?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_name?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_sell_item */
export type Statistic_Sell_Item_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "statistic_sell_item" */
export enum Statistic_Sell_Item_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemName = 'item_name',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "statistic_sell_item" */
export type Statistic_Sell_Item_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_name?: InputMaybe<Scalars['String']>;
  item_price?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Statistic_Sell_Item_Stddev_Fields = {
  __typename?: 'statistic_sell_item_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_Sell_Item_Stddev_Pop_Fields = {
  __typename?: 'statistic_sell_item_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_Sell_Item_Stddev_Samp_Fields = {
  __typename?: 'statistic_sell_item_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_sell_item" */
export type Statistic_Sell_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_Sell_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_Sell_Item_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  is_bot?: InputMaybe<Scalars['Boolean']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_name?: InputMaybe<Scalars['String']>;
  item_price?: InputMaybe<Scalars['Float']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Statistic_Sell_Item_Sum_Fields = {
  __typename?: 'statistic_sell_item_sum_fields';
  id?: Maybe<Scalars['Int']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** update columns of table "statistic_sell_item" */
export enum Statistic_Sell_Item_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  IsBot = 'is_bot',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemName = 'item_name',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Statistic_Sell_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_Sell_Item_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_Sell_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_Sell_Item_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_Sell_Item_Var_Pop_Fields = {
  __typename?: 'statistic_sell_item_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_Sell_Item_Var_Samp_Fields = {
  __typename?: 'statistic_sell_item_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_Sell_Item_Variance_Fields = {
  __typename?: 'statistic_sell_item_variance_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_user_deposit_success_callbacks" */
export type Statistic_User_Deposit_Success_Callbacks = {
  __typename?: 'statistic_user_deposit_success_callbacks';
  amount: Scalars['Float'];
  datetime: Scalars['timestamp'];
  payment_system: Scalars['String'];
  payment_type?: Maybe<Scalars['String']>;
  transaction_id: Scalars['String'];
  user_id: Scalars['uuid'];
  user_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "statistic_user_deposit_success_callbacks" */
export type Statistic_User_Deposit_Success_Callbacks_Aggregate = {
  __typename?: 'statistic_user_deposit_success_callbacks_aggregate';
  aggregate?: Maybe<Statistic_User_Deposit_Success_Callbacks_Aggregate_Fields>;
  nodes: Array<Statistic_User_Deposit_Success_Callbacks>;
};

/** aggregate fields of "statistic_user_deposit_success_callbacks" */
export type Statistic_User_Deposit_Success_Callbacks_Aggregate_Fields = {
  __typename?: 'statistic_user_deposit_success_callbacks_aggregate_fields';
  avg?: Maybe<Statistic_User_Deposit_Success_Callbacks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_User_Deposit_Success_Callbacks_Max_Fields>;
  min?: Maybe<Statistic_User_Deposit_Success_Callbacks_Min_Fields>;
  stddev?: Maybe<Statistic_User_Deposit_Success_Callbacks_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_User_Deposit_Success_Callbacks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_User_Deposit_Success_Callbacks_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_User_Deposit_Success_Callbacks_Sum_Fields>;
  var_pop?: Maybe<Statistic_User_Deposit_Success_Callbacks_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_User_Deposit_Success_Callbacks_Var_Samp_Fields>;
  variance?: Maybe<Statistic_User_Deposit_Success_Callbacks_Variance_Fields>;
};


/** aggregate fields of "statistic_user_deposit_success_callbacks" */
export type Statistic_User_Deposit_Success_Callbacks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_User_Deposit_Success_Callbacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_User_Deposit_Success_Callbacks_Avg_Fields = {
  __typename?: 'statistic_user_deposit_success_callbacks_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_user_deposit_success_callbacks". All fields are combined with a logical 'AND'. */
export type Statistic_User_Deposit_Success_Callbacks_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_User_Deposit_Success_Callbacks_Bool_Exp>>;
  _not?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_User_Deposit_Success_Callbacks_Bool_Exp>>;
  amount?: InputMaybe<Float_Comparison_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  payment_system?: InputMaybe<String_Comparison_Exp>;
  payment_type?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_user_deposit_success_callbacks" */
export enum Statistic_User_Deposit_Success_Callbacks_Constraint {
  /** unique or primary key constraint on columns "transaction_id" */
  StatisticUserDepositSuccessCallbacksPkey = 'statistic_user_deposit_success_callbacks_pkey'
}

/** input type for incrementing numeric columns in table "statistic_user_deposit_success_callbacks" */
export type Statistic_User_Deposit_Success_Callbacks_Inc_Input = {
  amount?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "statistic_user_deposit_success_callbacks" */
export type Statistic_User_Deposit_Success_Callbacks_Insert_Input = {
  amount?: InputMaybe<Scalars['Float']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  payment_system?: InputMaybe<Scalars['String']>;
  payment_type?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Statistic_User_Deposit_Success_Callbacks_Max_Fields = {
  __typename?: 'statistic_user_deposit_success_callbacks_max_fields';
  amount?: Maybe<Scalars['Float']>;
  datetime?: Maybe<Scalars['timestamp']>;
  payment_system?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Statistic_User_Deposit_Success_Callbacks_Min_Fields = {
  __typename?: 'statistic_user_deposit_success_callbacks_min_fields';
  amount?: Maybe<Scalars['Float']>;
  datetime?: Maybe<Scalars['timestamp']>;
  payment_system?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "statistic_user_deposit_success_callbacks" */
export type Statistic_User_Deposit_Success_Callbacks_Mutation_Response = {
  __typename?: 'statistic_user_deposit_success_callbacks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_User_Deposit_Success_Callbacks>;
};

/** on_conflict condition type for table "statistic_user_deposit_success_callbacks" */
export type Statistic_User_Deposit_Success_Callbacks_On_Conflict = {
  constraint: Statistic_User_Deposit_Success_Callbacks_Constraint;
  update_columns?: Array<Statistic_User_Deposit_Success_Callbacks_Update_Column>;
  where?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_user_deposit_success_callbacks". */
export type Statistic_User_Deposit_Success_Callbacks_Order_By = {
  amount?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
  payment_type?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_user_deposit_success_callbacks */
export type Statistic_User_Deposit_Success_Callbacks_Pk_Columns_Input = {
  transaction_id: Scalars['String'];
};

/** select columns of table "statistic_user_deposit_success_callbacks" */
export enum Statistic_User_Deposit_Success_Callbacks_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  PaymentSystem = 'payment_system',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "statistic_user_deposit_success_callbacks" */
export type Statistic_User_Deposit_Success_Callbacks_Set_Input = {
  amount?: InputMaybe<Scalars['Float']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  payment_system?: InputMaybe<Scalars['String']>;
  payment_type?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Statistic_User_Deposit_Success_Callbacks_Stddev_Fields = {
  __typename?: 'statistic_user_deposit_success_callbacks_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_User_Deposit_Success_Callbacks_Stddev_Pop_Fields = {
  __typename?: 'statistic_user_deposit_success_callbacks_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_User_Deposit_Success_Callbacks_Stddev_Samp_Fields = {
  __typename?: 'statistic_user_deposit_success_callbacks_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_user_deposit_success_callbacks" */
export type Statistic_User_Deposit_Success_Callbacks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_User_Deposit_Success_Callbacks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_User_Deposit_Success_Callbacks_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Float']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  payment_system?: InputMaybe<Scalars['String']>;
  payment_type?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Statistic_User_Deposit_Success_Callbacks_Sum_Fields = {
  __typename?: 'statistic_user_deposit_success_callbacks_sum_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** update columns of table "statistic_user_deposit_success_callbacks" */
export enum Statistic_User_Deposit_Success_Callbacks_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  PaymentSystem = 'payment_system',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Statistic_User_Deposit_Success_Callbacks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_User_Deposit_Success_Callbacks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_User_Deposit_Success_Callbacks_Var_Pop_Fields = {
  __typename?: 'statistic_user_deposit_success_callbacks_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_User_Deposit_Success_Callbacks_Var_Samp_Fields = {
  __typename?: 'statistic_user_deposit_success_callbacks_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_User_Deposit_Success_Callbacks_Variance_Fields = {
  __typename?: 'statistic_user_deposit_success_callbacks_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_user_deposits_transaction_requests" */
export type Statistic_User_Deposits_Transaction_Requests = {
  __typename?: 'statistic_user_deposits_transaction_requests';
  amount: Scalars['Int'];
  datetime: Scalars['timestamp'];
  payment_system: Scalars['String'];
  payment_type: Scalars['String'];
  transaction_id: Scalars['String'];
  user_id: Scalars['uuid'];
  user_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "statistic_user_deposits_transaction_requests" */
export type Statistic_User_Deposits_Transaction_Requests_Aggregate = {
  __typename?: 'statistic_user_deposits_transaction_requests_aggregate';
  aggregate?: Maybe<Statistic_User_Deposits_Transaction_Requests_Aggregate_Fields>;
  nodes: Array<Statistic_User_Deposits_Transaction_Requests>;
};

/** aggregate fields of "statistic_user_deposits_transaction_requests" */
export type Statistic_User_Deposits_Transaction_Requests_Aggregate_Fields = {
  __typename?: 'statistic_user_deposits_transaction_requests_aggregate_fields';
  avg?: Maybe<Statistic_User_Deposits_Transaction_Requests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_User_Deposits_Transaction_Requests_Max_Fields>;
  min?: Maybe<Statistic_User_Deposits_Transaction_Requests_Min_Fields>;
  stddev?: Maybe<Statistic_User_Deposits_Transaction_Requests_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_User_Deposits_Transaction_Requests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_User_Deposits_Transaction_Requests_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_User_Deposits_Transaction_Requests_Sum_Fields>;
  var_pop?: Maybe<Statistic_User_Deposits_Transaction_Requests_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_User_Deposits_Transaction_Requests_Var_Samp_Fields>;
  variance?: Maybe<Statistic_User_Deposits_Transaction_Requests_Variance_Fields>;
};


/** aggregate fields of "statistic_user_deposits_transaction_requests" */
export type Statistic_User_Deposits_Transaction_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_User_Deposits_Transaction_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_User_Deposits_Transaction_Requests_Avg_Fields = {
  __typename?: 'statistic_user_deposits_transaction_requests_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_user_deposits_transaction_requests". All fields are combined with a logical 'AND'. */
export type Statistic_User_Deposits_Transaction_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_User_Deposits_Transaction_Requests_Bool_Exp>>;
  _not?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_User_Deposits_Transaction_Requests_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  payment_system?: InputMaybe<String_Comparison_Exp>;
  payment_type?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_user_deposits_transaction_requests" */
export enum Statistic_User_Deposits_Transaction_Requests_Constraint {
  /** unique or primary key constraint on columns "transaction_id" */
  StatisticUserDepositsTransactionRequestsPkey = 'statistic_user_deposits_transaction_requests_pkey'
}

/** input type for incrementing numeric columns in table "statistic_user_deposits_transaction_requests" */
export type Statistic_User_Deposits_Transaction_Requests_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "statistic_user_deposits_transaction_requests" */
export type Statistic_User_Deposits_Transaction_Requests_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  payment_system?: InputMaybe<Scalars['String']>;
  payment_type?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Statistic_User_Deposits_Transaction_Requests_Max_Fields = {
  __typename?: 'statistic_user_deposits_transaction_requests_max_fields';
  amount?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamp']>;
  payment_system?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Statistic_User_Deposits_Transaction_Requests_Min_Fields = {
  __typename?: 'statistic_user_deposits_transaction_requests_min_fields';
  amount?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['timestamp']>;
  payment_system?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "statistic_user_deposits_transaction_requests" */
export type Statistic_User_Deposits_Transaction_Requests_Mutation_Response = {
  __typename?: 'statistic_user_deposits_transaction_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_User_Deposits_Transaction_Requests>;
};

/** on_conflict condition type for table "statistic_user_deposits_transaction_requests" */
export type Statistic_User_Deposits_Transaction_Requests_On_Conflict = {
  constraint: Statistic_User_Deposits_Transaction_Requests_Constraint;
  update_columns?: Array<Statistic_User_Deposits_Transaction_Requests_Update_Column>;
  where?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_user_deposits_transaction_requests". */
export type Statistic_User_Deposits_Transaction_Requests_Order_By = {
  amount?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  payment_system?: InputMaybe<Order_By>;
  payment_type?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_user_deposits_transaction_requests */
export type Statistic_User_Deposits_Transaction_Requests_Pk_Columns_Input = {
  transaction_id: Scalars['String'];
};

/** select columns of table "statistic_user_deposits_transaction_requests" */
export enum Statistic_User_Deposits_Transaction_Requests_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  PaymentSystem = 'payment_system',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "statistic_user_deposits_transaction_requests" */
export type Statistic_User_Deposits_Transaction_Requests_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  payment_system?: InputMaybe<Scalars['String']>;
  payment_type?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Statistic_User_Deposits_Transaction_Requests_Stddev_Fields = {
  __typename?: 'statistic_user_deposits_transaction_requests_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_User_Deposits_Transaction_Requests_Stddev_Pop_Fields = {
  __typename?: 'statistic_user_deposits_transaction_requests_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_User_Deposits_Transaction_Requests_Stddev_Samp_Fields = {
  __typename?: 'statistic_user_deposits_transaction_requests_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_user_deposits_transaction_requests" */
export type Statistic_User_Deposits_Transaction_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_User_Deposits_Transaction_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_User_Deposits_Transaction_Requests_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  payment_system?: InputMaybe<Scalars['String']>;
  payment_type?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Statistic_User_Deposits_Transaction_Requests_Sum_Fields = {
  __typename?: 'statistic_user_deposits_transaction_requests_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** update columns of table "statistic_user_deposits_transaction_requests" */
export enum Statistic_User_Deposits_Transaction_Requests_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  PaymentSystem = 'payment_system',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Statistic_User_Deposits_Transaction_Requests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_User_Deposits_Transaction_Requests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_User_Deposits_Transaction_Requests_Var_Pop_Fields = {
  __typename?: 'statistic_user_deposits_transaction_requests_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_User_Deposits_Transaction_Requests_Var_Samp_Fields = {
  __typename?: 'statistic_user_deposits_transaction_requests_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_User_Deposits_Transaction_Requests_Variance_Fields = {
  __typename?: 'statistic_user_deposits_transaction_requests_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_user_free_lootboxes_opens" */
export type Statistic_User_Free_Lootboxes_Opens = {
  __typename?: 'statistic_user_free_lootboxes_opens';
  datetime: Scalars['timestamp'];
  id: Scalars['bigint'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_price: Scalars['Float'];
  lootbox_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "statistic_user_free_lootboxes_opens" */
export type Statistic_User_Free_Lootboxes_Opens_Aggregate = {
  __typename?: 'statistic_user_free_lootboxes_opens_aggregate';
  aggregate?: Maybe<Statistic_User_Free_Lootboxes_Opens_Aggregate_Fields>;
  nodes: Array<Statistic_User_Free_Lootboxes_Opens>;
};

/** aggregate fields of "statistic_user_free_lootboxes_opens" */
export type Statistic_User_Free_Lootboxes_Opens_Aggregate_Fields = {
  __typename?: 'statistic_user_free_lootboxes_opens_aggregate_fields';
  avg?: Maybe<Statistic_User_Free_Lootboxes_Opens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Statistic_User_Free_Lootboxes_Opens_Max_Fields>;
  min?: Maybe<Statistic_User_Free_Lootboxes_Opens_Min_Fields>;
  stddev?: Maybe<Statistic_User_Free_Lootboxes_Opens_Stddev_Fields>;
  stddev_pop?: Maybe<Statistic_User_Free_Lootboxes_Opens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Statistic_User_Free_Lootboxes_Opens_Stddev_Samp_Fields>;
  sum?: Maybe<Statistic_User_Free_Lootboxes_Opens_Sum_Fields>;
  var_pop?: Maybe<Statistic_User_Free_Lootboxes_Opens_Var_Pop_Fields>;
  var_samp?: Maybe<Statistic_User_Free_Lootboxes_Opens_Var_Samp_Fields>;
  variance?: Maybe<Statistic_User_Free_Lootboxes_Opens_Variance_Fields>;
};


/** aggregate fields of "statistic_user_free_lootboxes_opens" */
export type Statistic_User_Free_Lootboxes_Opens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_User_Free_Lootboxes_Opens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Statistic_User_Free_Lootboxes_Opens_Avg_Fields = {
  __typename?: 'statistic_user_free_lootboxes_opens_avg_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "statistic_user_free_lootboxes_opens". All fields are combined with a logical 'AND'. */
export type Statistic_User_Free_Lootboxes_Opens_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_User_Free_Lootboxes_Opens_Bool_Exp>>;
  _not?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_User_Free_Lootboxes_Opens_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_price?: InputMaybe<Float_Comparison_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_user_free_lootboxes_opens" */
export enum Statistic_User_Free_Lootboxes_Opens_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserFreeLootboxesOpensPkey = 'user_free_lootboxes_opens_pkey'
}

/** input type for incrementing numeric columns in table "statistic_user_free_lootboxes_opens" */
export type Statistic_User_Free_Lootboxes_Opens_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  item_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "statistic_user_free_lootboxes_opens" */
export type Statistic_User_Free_Lootboxes_Opens_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Statistic_User_Free_Lootboxes_Opens_Max_Fields = {
  __typename?: 'statistic_user_free_lootboxes_opens_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Statistic_User_Free_Lootboxes_Opens_Min_Fields = {
  __typename?: 'statistic_user_free_lootboxes_opens_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['bigint']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "statistic_user_free_lootboxes_opens" */
export type Statistic_User_Free_Lootboxes_Opens_Mutation_Response = {
  __typename?: 'statistic_user_free_lootboxes_opens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_User_Free_Lootboxes_Opens>;
};

/** on_conflict condition type for table "statistic_user_free_lootboxes_opens" */
export type Statistic_User_Free_Lootboxes_Opens_On_Conflict = {
  constraint: Statistic_User_Free_Lootboxes_Opens_Constraint;
  update_columns?: Array<Statistic_User_Free_Lootboxes_Opens_Update_Column>;
  where?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_user_free_lootboxes_opens". */
export type Statistic_User_Free_Lootboxes_Opens_Order_By = {
  datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_user_free_lootboxes_opens */
export type Statistic_User_Free_Lootboxes_Opens_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "statistic_user_free_lootboxes_opens" */
export enum Statistic_User_Free_Lootboxes_Opens_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "statistic_user_free_lootboxes_opens" */
export type Statistic_User_Free_Lootboxes_Opens_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Statistic_User_Free_Lootboxes_Opens_Stddev_Fields = {
  __typename?: 'statistic_user_free_lootboxes_opens_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Statistic_User_Free_Lootboxes_Opens_Stddev_Pop_Fields = {
  __typename?: 'statistic_user_free_lootboxes_opens_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Statistic_User_Free_Lootboxes_Opens_Stddev_Samp_Fields = {
  __typename?: 'statistic_user_free_lootboxes_opens_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "statistic_user_free_lootboxes_opens" */
export type Statistic_User_Free_Lootboxes_Opens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_User_Free_Lootboxes_Opens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_User_Free_Lootboxes_Opens_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['bigint']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Statistic_User_Free_Lootboxes_Opens_Sum_Fields = {
  __typename?: 'statistic_user_free_lootboxes_opens_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** update columns of table "statistic_user_free_lootboxes_opens" */
export enum Statistic_User_Free_Lootboxes_Opens_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Id = 'id',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  UserId = 'user_id'
}

export type Statistic_User_Free_Lootboxes_Opens_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_User_Free_Lootboxes_Opens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Statistic_User_Free_Lootboxes_Opens_Var_Pop_Fields = {
  __typename?: 'statistic_user_free_lootboxes_opens_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Statistic_User_Free_Lootboxes_Opens_Var_Samp_Fields = {
  __typename?: 'statistic_user_free_lootboxes_opens_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Statistic_User_Free_Lootboxes_Opens_Variance_Fields = {
  __typename?: 'statistic_user_free_lootboxes_opens_variance_fields';
  id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "statistic_user_registration" */
export type Statistic_User_Registration = {
  __typename?: 'statistic_user_registration';
  datetime: Scalars['timestamp'];
  user_id: Scalars['uuid'];
  user_name: Scalars['String'];
};

/** aggregated selection of "statistic_user_registration" */
export type Statistic_User_Registration_Aggregate = {
  __typename?: 'statistic_user_registration_aggregate';
  aggregate?: Maybe<Statistic_User_Registration_Aggregate_Fields>;
  nodes: Array<Statistic_User_Registration>;
};

/** aggregate fields of "statistic_user_registration" */
export type Statistic_User_Registration_Aggregate_Fields = {
  __typename?: 'statistic_user_registration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Statistic_User_Registration_Max_Fields>;
  min?: Maybe<Statistic_User_Registration_Min_Fields>;
};


/** aggregate fields of "statistic_user_registration" */
export type Statistic_User_Registration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Statistic_User_Registration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "statistic_user_registration". All fields are combined with a logical 'AND'. */
export type Statistic_User_Registration_Bool_Exp = {
  _and?: InputMaybe<Array<Statistic_User_Registration_Bool_Exp>>;
  _not?: InputMaybe<Statistic_User_Registration_Bool_Exp>;
  _or?: InputMaybe<Array<Statistic_User_Registration_Bool_Exp>>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "statistic_user_registration" */
export enum Statistic_User_Registration_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  StatisticUserRegistrationPkey = 'statistic_user_registration_pkey'
}

/** input type for inserting data into table "statistic_user_registration" */
export type Statistic_User_Registration_Insert_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Statistic_User_Registration_Max_Fields = {
  __typename?: 'statistic_user_registration_max_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Statistic_User_Registration_Min_Fields = {
  __typename?: 'statistic_user_registration_min_fields';
  datetime?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "statistic_user_registration" */
export type Statistic_User_Registration_Mutation_Response = {
  __typename?: 'statistic_user_registration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Statistic_User_Registration>;
};

/** on_conflict condition type for table "statistic_user_registration" */
export type Statistic_User_Registration_On_Conflict = {
  constraint: Statistic_User_Registration_Constraint;
  update_columns?: Array<Statistic_User_Registration_Update_Column>;
  where?: InputMaybe<Statistic_User_Registration_Bool_Exp>;
};

/** Ordering options when selecting data from "statistic_user_registration". */
export type Statistic_User_Registration_Order_By = {
  datetime?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: statistic_user_registration */
export type Statistic_User_Registration_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "statistic_user_registration" */
export enum Statistic_User_Registration_Select_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "statistic_user_registration" */
export type Statistic_User_Registration_Set_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "statistic_user_registration" */
export type Statistic_User_Registration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Statistic_User_Registration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Statistic_User_Registration_Stream_Cursor_Value_Input = {
  datetime?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "statistic_user_registration" */
export enum Statistic_User_Registration_Update_Column {
  /** column name */
  Datetime = 'datetime',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Statistic_User_Registration_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Statistic_User_Registration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Statistic_User_Registration_Bool_Exp;
};

/** columns and relationships of "stocks" */
export type Stocks = {
  __typename?: 'stocks';
  active: Scalars['Boolean'];
  button_active: Scalars['Boolean'];
  button_text?: Maybe<Scalars['String']>;
  date_string?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  header: Scalars['String'];
  id: Scalars['uuid'];
  link?: Maybe<Scalars['String']>;
  sort: Scalars['Int'];
};

/** aggregated selection of "stocks" */
export type Stocks_Aggregate = {
  __typename?: 'stocks_aggregate';
  aggregate?: Maybe<Stocks_Aggregate_Fields>;
  nodes: Array<Stocks>;
};

/** aggregate fields of "stocks" */
export type Stocks_Aggregate_Fields = {
  __typename?: 'stocks_aggregate_fields';
  avg?: Maybe<Stocks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stocks_Max_Fields>;
  min?: Maybe<Stocks_Min_Fields>;
  stddev?: Maybe<Stocks_Stddev_Fields>;
  stddev_pop?: Maybe<Stocks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stocks_Stddev_Samp_Fields>;
  sum?: Maybe<Stocks_Sum_Fields>;
  var_pop?: Maybe<Stocks_Var_Pop_Fields>;
  var_samp?: Maybe<Stocks_Var_Samp_Fields>;
  variance?: Maybe<Stocks_Variance_Fields>;
};


/** aggregate fields of "stocks" */
export type Stocks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stocks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Stocks_Avg_Fields = {
  __typename?: 'stocks_avg_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "stocks". All fields are combined with a logical 'AND'. */
export type Stocks_Bool_Exp = {
  _and?: InputMaybe<Array<Stocks_Bool_Exp>>;
  _not?: InputMaybe<Stocks_Bool_Exp>;
  _or?: InputMaybe<Array<Stocks_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  button_active?: InputMaybe<Boolean_Comparison_Exp>;
  button_text?: InputMaybe<String_Comparison_Exp>;
  date_string?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  header?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  sort?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "stocks" */
export enum Stocks_Constraint {
  /** unique or primary key constraint on columns "id" */
  StocksPkey = 'stocks_pkey'
}

/** input type for incrementing numeric columns in table "stocks" */
export type Stocks_Inc_Input = {
  sort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stocks" */
export type Stocks_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  button_active?: InputMaybe<Scalars['Boolean']>;
  button_text?: InputMaybe<Scalars['String']>;
  date_string?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Stocks_Max_Fields = {
  __typename?: 'stocks_max_fields';
  button_text?: Maybe<Scalars['String']>;
  date_string?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Stocks_Min_Fields = {
  __typename?: 'stocks_min_fields';
  button_text?: Maybe<Scalars['String']>;
  date_string?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "stocks" */
export type Stocks_Mutation_Response = {
  __typename?: 'stocks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stocks>;
};

/** on_conflict condition type for table "stocks" */
export type Stocks_On_Conflict = {
  constraint: Stocks_Constraint;
  update_columns?: Array<Stocks_Update_Column>;
  where?: InputMaybe<Stocks_Bool_Exp>;
};

/** Ordering options when selecting data from "stocks". */
export type Stocks_Order_By = {
  active?: InputMaybe<Order_By>;
  button_active?: InputMaybe<Order_By>;
  button_text?: InputMaybe<Order_By>;
  date_string?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stocks */
export type Stocks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "stocks" */
export enum Stocks_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ButtonActive = 'button_active',
  /** column name */
  ButtonText = 'button_text',
  /** column name */
  DateString = 'date_string',
  /** column name */
  Description = 'description',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Sort = 'sort'
}

/** input type for updating data in table "stocks" */
export type Stocks_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  button_active?: InputMaybe<Scalars['Boolean']>;
  button_text?: InputMaybe<Scalars['String']>;
  date_string?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Stocks_Stddev_Fields = {
  __typename?: 'stocks_stddev_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Stocks_Stddev_Pop_Fields = {
  __typename?: 'stocks_stddev_pop_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Stocks_Stddev_Samp_Fields = {
  __typename?: 'stocks_stddev_samp_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "stocks" */
export type Stocks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stocks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stocks_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  button_active?: InputMaybe<Scalars['Boolean']>;
  button_text?: InputMaybe<Scalars['String']>;
  date_string?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Stocks_Sum_Fields = {
  __typename?: 'stocks_sum_fields';
  sort?: Maybe<Scalars['Int']>;
};

/** update columns of table "stocks" */
export enum Stocks_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ButtonActive = 'button_active',
  /** column name */
  ButtonText = 'button_text',
  /** column name */
  DateString = 'date_string',
  /** column name */
  Description = 'description',
  /** column name */
  Header = 'header',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  Sort = 'sort'
}

export type Stocks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stocks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stocks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stocks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stocks_Var_Pop_Fields = {
  __typename?: 'stocks_var_pop_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Stocks_Var_Samp_Fields = {
  __typename?: 'stocks_var_samp_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Stocks_Variance_Fields = {
  __typename?: 'stocks_variance_fields';
  sort?: Maybe<Scalars['Float']>;
};

/** Справочник. Акционные надписи в предложении магазина */
export type Store_Offer_Badges = {
  __typename?: 'store_offer_badges';
  /** Надпись */
  badge_value: Scalars['String'];
};

/** aggregated selection of "store_offer_badges" */
export type Store_Offer_Badges_Aggregate = {
  __typename?: 'store_offer_badges_aggregate';
  aggregate?: Maybe<Store_Offer_Badges_Aggregate_Fields>;
  nodes: Array<Store_Offer_Badges>;
};

/** aggregate fields of "store_offer_badges" */
export type Store_Offer_Badges_Aggregate_Fields = {
  __typename?: 'store_offer_badges_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_Offer_Badges_Max_Fields>;
  min?: Maybe<Store_Offer_Badges_Min_Fields>;
};


/** aggregate fields of "store_offer_badges" */
export type Store_Offer_Badges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Offer_Badges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "store_offer_badges". All fields are combined with a logical 'AND'. */
export type Store_Offer_Badges_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Offer_Badges_Bool_Exp>>;
  _not?: InputMaybe<Store_Offer_Badges_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Offer_Badges_Bool_Exp>>;
  badge_value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_offer_badges" */
export enum Store_Offer_Badges_Constraint {
  /** unique or primary key constraint on columns "badge_value" */
  StoreOfferBadgesPkey = 'store_offer_badges_pkey'
}

/** input type for inserting data into table "store_offer_badges" */
export type Store_Offer_Badges_Insert_Input = {
  /** Надпись */
  badge_value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Store_Offer_Badges_Max_Fields = {
  __typename?: 'store_offer_badges_max_fields';
  /** Надпись */
  badge_value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Store_Offer_Badges_Min_Fields = {
  __typename?: 'store_offer_badges_min_fields';
  /** Надпись */
  badge_value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "store_offer_badges" */
export type Store_Offer_Badges_Mutation_Response = {
  __typename?: 'store_offer_badges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Offer_Badges>;
};

/** on_conflict condition type for table "store_offer_badges" */
export type Store_Offer_Badges_On_Conflict = {
  constraint: Store_Offer_Badges_Constraint;
  update_columns?: Array<Store_Offer_Badges_Update_Column>;
  where?: InputMaybe<Store_Offer_Badges_Bool_Exp>;
};

/** Ordering options when selecting data from "store_offer_badges". */
export type Store_Offer_Badges_Order_By = {
  badge_value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_offer_badges */
export type Store_Offer_Badges_Pk_Columns_Input = {
  /** Надпись */
  badge_value: Scalars['String'];
};

/** select columns of table "store_offer_badges" */
export enum Store_Offer_Badges_Select_Column {
  /** column name */
  BadgeValue = 'badge_value'
}

/** input type for updating data in table "store_offer_badges" */
export type Store_Offer_Badges_Set_Input = {
  /** Надпись */
  badge_value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "store_offer_badges" */
export type Store_Offer_Badges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Store_Offer_Badges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Offer_Badges_Stream_Cursor_Value_Input = {
  /** Надпись */
  badge_value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "store_offer_badges" */
export enum Store_Offer_Badges_Update_Column {
  /** column name */
  BadgeValue = 'badge_value'
}

export type Store_Offer_Badges_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Store_Offer_Badges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Store_Offer_Badges_Bool_Exp;
};

/** Таблица содержания продуктов в предложении магазина */
export type Store_Offer_Boosters = {
  __typename?: 'store_offer_boosters';
  /** Идентификатор набора бустеров */
  boosters_set_id: Scalars['bigint'];
  /** An array relationship */
  boosters_sets: Array<Boosters_Set_Content>;
  /** An aggregate relationship */
  boosters_sets_aggregate: Boosters_Set_Content_Aggregate;
  /** Идентификатор предложения в таблице store_offers */
  offer_id: Scalars['uuid'];
  offer_item_id: Scalars['uuid'];
  /** Поле для определения позиции предложения в общем списке */
  sort: Scalars['smallint'];
};


/** Таблица содержания продуктов в предложении магазина */
export type Store_Offer_BoostersBoosters_SetsArgs = {
  distinct_on?: InputMaybe<Array<Boosters_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Boosters_Set_Content_Order_By>>;
  where?: InputMaybe<Boosters_Set_Content_Bool_Exp>;
};


/** Таблица содержания продуктов в предложении магазина */
export type Store_Offer_BoostersBoosters_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Boosters_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Boosters_Set_Content_Order_By>>;
  where?: InputMaybe<Boosters_Set_Content_Bool_Exp>;
};

/** aggregated selection of "store_offer_boosters" */
export type Store_Offer_Boosters_Aggregate = {
  __typename?: 'store_offer_boosters_aggregate';
  aggregate?: Maybe<Store_Offer_Boosters_Aggregate_Fields>;
  nodes: Array<Store_Offer_Boosters>;
};

/** aggregate fields of "store_offer_boosters" */
export type Store_Offer_Boosters_Aggregate_Fields = {
  __typename?: 'store_offer_boosters_aggregate_fields';
  avg?: Maybe<Store_Offer_Boosters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_Offer_Boosters_Max_Fields>;
  min?: Maybe<Store_Offer_Boosters_Min_Fields>;
  stddev?: Maybe<Store_Offer_Boosters_Stddev_Fields>;
  stddev_pop?: Maybe<Store_Offer_Boosters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_Offer_Boosters_Stddev_Samp_Fields>;
  sum?: Maybe<Store_Offer_Boosters_Sum_Fields>;
  var_pop?: Maybe<Store_Offer_Boosters_Var_Pop_Fields>;
  var_samp?: Maybe<Store_Offer_Boosters_Var_Samp_Fields>;
  variance?: Maybe<Store_Offer_Boosters_Variance_Fields>;
};


/** aggregate fields of "store_offer_boosters" */
export type Store_Offer_Boosters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Offer_Boosters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Store_Offer_Boosters_Avg_Fields = {
  __typename?: 'store_offer_boosters_avg_fields';
  /** Идентификатор набора бустеров */
  boosters_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "store_offer_boosters". All fields are combined with a logical 'AND'. */
export type Store_Offer_Boosters_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Offer_Boosters_Bool_Exp>>;
  _not?: InputMaybe<Store_Offer_Boosters_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Offer_Boosters_Bool_Exp>>;
  boosters_set_id?: InputMaybe<Bigint_Comparison_Exp>;
  boosters_sets?: InputMaybe<Boosters_Set_Content_Bool_Exp>;
  boosters_sets_aggregate?: InputMaybe<Boosters_Set_Content_Aggregate_Bool_Exp>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  offer_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  sort?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_offer_boosters" */
export enum Store_Offer_Boosters_Constraint {
  /** unique or primary key constraint on columns "offer_id" */
  StoreOfferBoostersOfferIdKey = 'store_offer_boosters_offer_id_key',
  /** unique or primary key constraint on columns "offer_item_id" */
  StoreOfferBoostersPkey = 'store_offer_boosters_pkey'
}

/** input type for incrementing numeric columns in table "store_offer_boosters" */
export type Store_Offer_Boosters_Inc_Input = {
  /** Идентификатор набора бустеров */
  boosters_set_id?: InputMaybe<Scalars['bigint']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "store_offer_boosters" */
export type Store_Offer_Boosters_Insert_Input = {
  /** Идентификатор набора бустеров */
  boosters_set_id?: InputMaybe<Scalars['bigint']>;
  boosters_sets?: InputMaybe<Boosters_Set_Content_Arr_Rel_Insert_Input>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Store_Offer_Boosters_Max_Fields = {
  __typename?: 'store_offer_boosters_max_fields';
  /** Идентификатор набора бустеров */
  boosters_set_id?: Maybe<Scalars['bigint']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  offer_item_id?: Maybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type Store_Offer_Boosters_Min_Fields = {
  __typename?: 'store_offer_boosters_min_fields';
  /** Идентификатор набора бустеров */
  boosters_set_id?: Maybe<Scalars['bigint']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  offer_item_id?: Maybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "store_offer_boosters" */
export type Store_Offer_Boosters_Mutation_Response = {
  __typename?: 'store_offer_boosters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Offer_Boosters>;
};

/** input type for inserting object relation for remote table "store_offer_boosters" */
export type Store_Offer_Boosters_Obj_Rel_Insert_Input = {
  data: Store_Offer_Boosters_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Store_Offer_Boosters_On_Conflict>;
};

/** on_conflict condition type for table "store_offer_boosters" */
export type Store_Offer_Boosters_On_Conflict = {
  constraint: Store_Offer_Boosters_Constraint;
  update_columns?: Array<Store_Offer_Boosters_Update_Column>;
  where?: InputMaybe<Store_Offer_Boosters_Bool_Exp>;
};

/** Ordering options when selecting data from "store_offer_boosters". */
export type Store_Offer_Boosters_Order_By = {
  boosters_set_id?: InputMaybe<Order_By>;
  boosters_sets_aggregate?: InputMaybe<Boosters_Set_Content_Aggregate_Order_By>;
  offer_id?: InputMaybe<Order_By>;
  offer_item_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_offer_boosters */
export type Store_Offer_Boosters_Pk_Columns_Input = {
  offer_item_id: Scalars['uuid'];
};

/** select columns of table "store_offer_boosters" */
export enum Store_Offer_Boosters_Select_Column {
  /** column name */
  BoostersSetId = 'boosters_set_id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferItemId = 'offer_item_id',
  /** column name */
  Sort = 'sort'
}

/** input type for updating data in table "store_offer_boosters" */
export type Store_Offer_Boosters_Set_Input = {
  /** Идентификатор набора бустеров */
  boosters_set_id?: InputMaybe<Scalars['bigint']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Store_Offer_Boosters_Stddev_Fields = {
  __typename?: 'store_offer_boosters_stddev_fields';
  /** Идентификатор набора бустеров */
  boosters_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Store_Offer_Boosters_Stddev_Pop_Fields = {
  __typename?: 'store_offer_boosters_stddev_pop_fields';
  /** Идентификатор набора бустеров */
  boosters_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Store_Offer_Boosters_Stddev_Samp_Fields = {
  __typename?: 'store_offer_boosters_stddev_samp_fields';
  /** Идентификатор набора бустеров */
  boosters_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "store_offer_boosters" */
export type Store_Offer_Boosters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Store_Offer_Boosters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Offer_Boosters_Stream_Cursor_Value_Input = {
  /** Идентификатор набора бустеров */
  boosters_set_id?: InputMaybe<Scalars['bigint']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate sum on columns */
export type Store_Offer_Boosters_Sum_Fields = {
  __typename?: 'store_offer_boosters_sum_fields';
  /** Идентификатор набора бустеров */
  boosters_set_id?: Maybe<Scalars['bigint']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** update columns of table "store_offer_boosters" */
export enum Store_Offer_Boosters_Update_Column {
  /** column name */
  BoostersSetId = 'boosters_set_id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferItemId = 'offer_item_id',
  /** column name */
  Sort = 'sort'
}

export type Store_Offer_Boosters_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Store_Offer_Boosters_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Store_Offer_Boosters_Set_Input>;
  /** filter the rows which have to be updated */
  where: Store_Offer_Boosters_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Store_Offer_Boosters_Var_Pop_Fields = {
  __typename?: 'store_offer_boosters_var_pop_fields';
  /** Идентификатор набора бустеров */
  boosters_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Store_Offer_Boosters_Var_Samp_Fields = {
  __typename?: 'store_offer_boosters_var_samp_fields';
  /** Идентификатор набора бустеров */
  boosters_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Store_Offer_Boosters_Variance_Fields = {
  __typename?: 'store_offer_boosters_variance_fields';
  /** Идентификатор набора бустеров */
  boosters_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Таблица содержания продуктов в предложении магазина */
export type Store_Offer_Coins = {
  __typename?: 'store_offer_coins';
  /** Идентификатор набора монет */
  coins_set_id: Scalars['bigint'];
  /** An array relationship */
  coins_sets: Array<Coins_Set_Content>;
  /** An aggregate relationship */
  coins_sets_aggregate: Coins_Set_Content_Aggregate;
  /** Идентификатор предложения в таблице store_offers */
  offer_id: Scalars['uuid'];
  offer_item_id: Scalars['uuid'];
  /** Поле для определения позиции предложения в общем списке */
  sort: Scalars['smallint'];
};


/** Таблица содержания продуктов в предложении магазина */
export type Store_Offer_CoinsCoins_SetsArgs = {
  distinct_on?: InputMaybe<Array<Coins_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coins_Set_Content_Order_By>>;
  where?: InputMaybe<Coins_Set_Content_Bool_Exp>;
};


/** Таблица содержания продуктов в предложении магазина */
export type Store_Offer_CoinsCoins_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coins_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coins_Set_Content_Order_By>>;
  where?: InputMaybe<Coins_Set_Content_Bool_Exp>;
};

/** aggregated selection of "store_offer_coins" */
export type Store_Offer_Coins_Aggregate = {
  __typename?: 'store_offer_coins_aggregate';
  aggregate?: Maybe<Store_Offer_Coins_Aggregate_Fields>;
  nodes: Array<Store_Offer_Coins>;
};

/** aggregate fields of "store_offer_coins" */
export type Store_Offer_Coins_Aggregate_Fields = {
  __typename?: 'store_offer_coins_aggregate_fields';
  avg?: Maybe<Store_Offer_Coins_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_Offer_Coins_Max_Fields>;
  min?: Maybe<Store_Offer_Coins_Min_Fields>;
  stddev?: Maybe<Store_Offer_Coins_Stddev_Fields>;
  stddev_pop?: Maybe<Store_Offer_Coins_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_Offer_Coins_Stddev_Samp_Fields>;
  sum?: Maybe<Store_Offer_Coins_Sum_Fields>;
  var_pop?: Maybe<Store_Offer_Coins_Var_Pop_Fields>;
  var_samp?: Maybe<Store_Offer_Coins_Var_Samp_Fields>;
  variance?: Maybe<Store_Offer_Coins_Variance_Fields>;
};


/** aggregate fields of "store_offer_coins" */
export type Store_Offer_Coins_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Offer_Coins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Store_Offer_Coins_Avg_Fields = {
  __typename?: 'store_offer_coins_avg_fields';
  /** Идентификатор набора монет */
  coins_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "store_offer_coins". All fields are combined with a logical 'AND'. */
export type Store_Offer_Coins_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Offer_Coins_Bool_Exp>>;
  _not?: InputMaybe<Store_Offer_Coins_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Offer_Coins_Bool_Exp>>;
  coins_set_id?: InputMaybe<Bigint_Comparison_Exp>;
  coins_sets?: InputMaybe<Coins_Set_Content_Bool_Exp>;
  coins_sets_aggregate?: InputMaybe<Coins_Set_Content_Aggregate_Bool_Exp>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  offer_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  sort?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_offer_coins" */
export enum Store_Offer_Coins_Constraint {
  /** unique or primary key constraint on columns "offer_id" */
  StoreOfferCoinsOfferIdKey = 'store_offer_coins_offer_id_key',
  /** unique or primary key constraint on columns "offer_item_id" */
  StoreOfferCoinsPkey = 'store_offer_coins_pkey'
}

/** input type for incrementing numeric columns in table "store_offer_coins" */
export type Store_Offer_Coins_Inc_Input = {
  /** Идентификатор набора монет */
  coins_set_id?: InputMaybe<Scalars['bigint']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "store_offer_coins" */
export type Store_Offer_Coins_Insert_Input = {
  /** Идентификатор набора монет */
  coins_set_id?: InputMaybe<Scalars['bigint']>;
  coins_sets?: InputMaybe<Coins_Set_Content_Arr_Rel_Insert_Input>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Store_Offer_Coins_Max_Fields = {
  __typename?: 'store_offer_coins_max_fields';
  /** Идентификатор набора монет */
  coins_set_id?: Maybe<Scalars['bigint']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  offer_item_id?: Maybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type Store_Offer_Coins_Min_Fields = {
  __typename?: 'store_offer_coins_min_fields';
  /** Идентификатор набора монет */
  coins_set_id?: Maybe<Scalars['bigint']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  offer_item_id?: Maybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "store_offer_coins" */
export type Store_Offer_Coins_Mutation_Response = {
  __typename?: 'store_offer_coins_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Offer_Coins>;
};

/** input type for inserting object relation for remote table "store_offer_coins" */
export type Store_Offer_Coins_Obj_Rel_Insert_Input = {
  data: Store_Offer_Coins_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Store_Offer_Coins_On_Conflict>;
};

/** on_conflict condition type for table "store_offer_coins" */
export type Store_Offer_Coins_On_Conflict = {
  constraint: Store_Offer_Coins_Constraint;
  update_columns?: Array<Store_Offer_Coins_Update_Column>;
  where?: InputMaybe<Store_Offer_Coins_Bool_Exp>;
};

/** Ordering options when selecting data from "store_offer_coins". */
export type Store_Offer_Coins_Order_By = {
  coins_set_id?: InputMaybe<Order_By>;
  coins_sets_aggregate?: InputMaybe<Coins_Set_Content_Aggregate_Order_By>;
  offer_id?: InputMaybe<Order_By>;
  offer_item_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_offer_coins */
export type Store_Offer_Coins_Pk_Columns_Input = {
  offer_item_id: Scalars['uuid'];
};

/** select columns of table "store_offer_coins" */
export enum Store_Offer_Coins_Select_Column {
  /** column name */
  CoinsSetId = 'coins_set_id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferItemId = 'offer_item_id',
  /** column name */
  Sort = 'sort'
}

/** input type for updating data in table "store_offer_coins" */
export type Store_Offer_Coins_Set_Input = {
  /** Идентификатор набора монет */
  coins_set_id?: InputMaybe<Scalars['bigint']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Store_Offer_Coins_Stddev_Fields = {
  __typename?: 'store_offer_coins_stddev_fields';
  /** Идентификатор набора монет */
  coins_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Store_Offer_Coins_Stddev_Pop_Fields = {
  __typename?: 'store_offer_coins_stddev_pop_fields';
  /** Идентификатор набора монет */
  coins_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Store_Offer_Coins_Stddev_Samp_Fields = {
  __typename?: 'store_offer_coins_stddev_samp_fields';
  /** Идентификатор набора монет */
  coins_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "store_offer_coins" */
export type Store_Offer_Coins_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Store_Offer_Coins_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Offer_Coins_Stream_Cursor_Value_Input = {
  /** Идентификатор набора монет */
  coins_set_id?: InputMaybe<Scalars['bigint']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate sum on columns */
export type Store_Offer_Coins_Sum_Fields = {
  __typename?: 'store_offer_coins_sum_fields';
  /** Идентификатор набора монет */
  coins_set_id?: Maybe<Scalars['bigint']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** update columns of table "store_offer_coins" */
export enum Store_Offer_Coins_Update_Column {
  /** column name */
  CoinsSetId = 'coins_set_id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferItemId = 'offer_item_id',
  /** column name */
  Sort = 'sort'
}

export type Store_Offer_Coins_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Store_Offer_Coins_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Store_Offer_Coins_Set_Input>;
  /** filter the rows which have to be updated */
  where: Store_Offer_Coins_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Store_Offer_Coins_Var_Pop_Fields = {
  __typename?: 'store_offer_coins_var_pop_fields';
  /** Идентификатор набора монет */
  coins_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Store_Offer_Coins_Var_Samp_Fields = {
  __typename?: 'store_offer_coins_var_samp_fields';
  /** Идентификатор набора монет */
  coins_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Store_Offer_Coins_Variance_Fields = {
  __typename?: 'store_offer_coins_variance_fields';
  /** Идентификатор набора монет */
  coins_set_id?: Maybe<Scalars['Float']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Таблица содержания продуктов в предложении магазина */
export type Store_Offer_Lootboxes = {
  __typename?: 'store_offer_lootboxes';
  is_active: Scalars['Boolean'];
  /** Флаг, показывающий является ли предмет мистическим */
  is_mistery: Scalars['Boolean'];
  lootbox_detail?: Maybe<Lootboxes_Free>;
  /** Слаг кейса в таблице lootbox_db.lootboxes_free */
  lootbox_slug: Scalars['String'];
  /** Идентификатор предложения в таблице store_offers */
  offer_id: Scalars['uuid'];
  offer_item_id: Scalars['uuid'];
  /** Поле для определения позиции предложения в общем списке */
  sort: Scalars['smallint'];
};

/** aggregated selection of "store_offer_lootboxes" */
export type Store_Offer_Lootboxes_Aggregate = {
  __typename?: 'store_offer_lootboxes_aggregate';
  aggregate?: Maybe<Store_Offer_Lootboxes_Aggregate_Fields>;
  nodes: Array<Store_Offer_Lootboxes>;
};

/** aggregate fields of "store_offer_lootboxes" */
export type Store_Offer_Lootboxes_Aggregate_Fields = {
  __typename?: 'store_offer_lootboxes_aggregate_fields';
  avg?: Maybe<Store_Offer_Lootboxes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_Offer_Lootboxes_Max_Fields>;
  min?: Maybe<Store_Offer_Lootboxes_Min_Fields>;
  stddev?: Maybe<Store_Offer_Lootboxes_Stddev_Fields>;
  stddev_pop?: Maybe<Store_Offer_Lootboxes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_Offer_Lootboxes_Stddev_Samp_Fields>;
  sum?: Maybe<Store_Offer_Lootboxes_Sum_Fields>;
  var_pop?: Maybe<Store_Offer_Lootboxes_Var_Pop_Fields>;
  var_samp?: Maybe<Store_Offer_Lootboxes_Var_Samp_Fields>;
  variance?: Maybe<Store_Offer_Lootboxes_Variance_Fields>;
};


/** aggregate fields of "store_offer_lootboxes" */
export type Store_Offer_Lootboxes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Offer_Lootboxes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Store_Offer_Lootboxes_Avg_Fields = {
  __typename?: 'store_offer_lootboxes_avg_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "store_offer_lootboxes". All fields are combined with a logical 'AND'. */
export type Store_Offer_Lootboxes_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Offer_Lootboxes_Bool_Exp>>;
  _not?: InputMaybe<Store_Offer_Lootboxes_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Offer_Lootboxes_Bool_Exp>>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_mistery?: InputMaybe<Boolean_Comparison_Exp>;
  lootbox_slug?: InputMaybe<String_Comparison_Exp>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  offer_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  sort?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_offer_lootboxes" */
export enum Store_Offer_Lootboxes_Constraint {
  /** unique or primary key constraint on columns "offer_id" */
  StoreOfferLootboxesOfferIdKey = 'store_offer_lootboxes_offer_id_key',
  /** unique or primary key constraint on columns "offer_item_id" */
  StoreOfferLootboxesPkey = 'store_offer_lootboxes_pkey'
}

/** input type for incrementing numeric columns in table "store_offer_lootboxes" */
export type Store_Offer_Lootboxes_Inc_Input = {
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "store_offer_lootboxes" */
export type Store_Offer_Lootboxes_Insert_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Флаг, показывающий является ли предмет мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  /** Слаг кейса в таблице lootbox_db.lootboxes_free */
  lootbox_slug?: InputMaybe<Scalars['String']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Store_Offer_Lootboxes_Max_Fields = {
  __typename?: 'store_offer_lootboxes_max_fields';
  /** Слаг кейса в таблице lootbox_db.lootboxes_free */
  lootbox_slug?: Maybe<Scalars['String']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  offer_item_id?: Maybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type Store_Offer_Lootboxes_Min_Fields = {
  __typename?: 'store_offer_lootboxes_min_fields';
  /** Слаг кейса в таблице lootbox_db.lootboxes_free */
  lootbox_slug?: Maybe<Scalars['String']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  offer_item_id?: Maybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "store_offer_lootboxes" */
export type Store_Offer_Lootboxes_Mutation_Response = {
  __typename?: 'store_offer_lootboxes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Offer_Lootboxes>;
};

/** input type for inserting object relation for remote table "store_offer_lootboxes" */
export type Store_Offer_Lootboxes_Obj_Rel_Insert_Input = {
  data: Store_Offer_Lootboxes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Store_Offer_Lootboxes_On_Conflict>;
};

/** on_conflict condition type for table "store_offer_lootboxes" */
export type Store_Offer_Lootboxes_On_Conflict = {
  constraint: Store_Offer_Lootboxes_Constraint;
  update_columns?: Array<Store_Offer_Lootboxes_Update_Column>;
  where?: InputMaybe<Store_Offer_Lootboxes_Bool_Exp>;
};

/** Ordering options when selecting data from "store_offer_lootboxes". */
export type Store_Offer_Lootboxes_Order_By = {
  is_active?: InputMaybe<Order_By>;
  is_mistery?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  offer_item_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_offer_lootboxes */
export type Store_Offer_Lootboxes_Pk_Columns_Input = {
  offer_item_id: Scalars['uuid'];
};

/** select columns of table "store_offer_lootboxes" */
export enum Store_Offer_Lootboxes_Select_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsMistery = 'is_mistery',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferItemId = 'offer_item_id',
  /** column name */
  Sort = 'sort'
}

/** input type for updating data in table "store_offer_lootboxes" */
export type Store_Offer_Lootboxes_Set_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Флаг, показывающий является ли предмет мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  /** Слаг кейса в таблице lootbox_db.lootboxes_free */
  lootbox_slug?: InputMaybe<Scalars['String']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Store_Offer_Lootboxes_Stddev_Fields = {
  __typename?: 'store_offer_lootboxes_stddev_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Store_Offer_Lootboxes_Stddev_Pop_Fields = {
  __typename?: 'store_offer_lootboxes_stddev_pop_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Store_Offer_Lootboxes_Stddev_Samp_Fields = {
  __typename?: 'store_offer_lootboxes_stddev_samp_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "store_offer_lootboxes" */
export type Store_Offer_Lootboxes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Store_Offer_Lootboxes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Offer_Lootboxes_Stream_Cursor_Value_Input = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Флаг, показывающий является ли предмет мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  /** Слаг кейса в таблице lootbox_db.lootboxes_free */
  lootbox_slug?: InputMaybe<Scalars['String']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate sum on columns */
export type Store_Offer_Lootboxes_Sum_Fields = {
  __typename?: 'store_offer_lootboxes_sum_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** update columns of table "store_offer_lootboxes" */
export enum Store_Offer_Lootboxes_Update_Column {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsMistery = 'is_mistery',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferItemId = 'offer_item_id',
  /** column name */
  Sort = 'sort'
}

export type Store_Offer_Lootboxes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Store_Offer_Lootboxes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Store_Offer_Lootboxes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Store_Offer_Lootboxes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Store_Offer_Lootboxes_Var_Pop_Fields = {
  __typename?: 'store_offer_lootboxes_var_pop_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Store_Offer_Lootboxes_Var_Samp_Fields = {
  __typename?: 'store_offer_lootboxes_var_samp_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Store_Offer_Lootboxes_Variance_Fields = {
  __typename?: 'store_offer_lootboxes_variance_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Таблица покупок пользователей */
export type Store_Offer_Sales = {
  __typename?: 'store_offer_sales';
  /** Идентификатор предложения в таблице store_offers */
  offer_id: Scalars['uuid'];
  /** Дата и время совершения покупки */
  sale_datetime: Scalars['timestamp'];
  sale_id: Scalars['uuid'];
  /** Идентификатор пользователя, который совершил покупку */
  user_id: Scalars['uuid'];
};

/** aggregated selection of "store_offer_sales" */
export type Store_Offer_Sales_Aggregate = {
  __typename?: 'store_offer_sales_aggregate';
  aggregate?: Maybe<Store_Offer_Sales_Aggregate_Fields>;
  nodes: Array<Store_Offer_Sales>;
};

/** aggregate fields of "store_offer_sales" */
export type Store_Offer_Sales_Aggregate_Fields = {
  __typename?: 'store_offer_sales_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_Offer_Sales_Max_Fields>;
  min?: Maybe<Store_Offer_Sales_Min_Fields>;
};


/** aggregate fields of "store_offer_sales" */
export type Store_Offer_Sales_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Offer_Sales_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "store_offer_sales". All fields are combined with a logical 'AND'. */
export type Store_Offer_Sales_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Offer_Sales_Bool_Exp>>;
  _not?: InputMaybe<Store_Offer_Sales_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Offer_Sales_Bool_Exp>>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  sale_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  sale_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_offer_sales" */
export enum Store_Offer_Sales_Constraint {
  /** unique or primary key constraint on columns "sale_id" */
  StoreOfferSalesPkey = 'store_offer_sales_pkey'
}

/** input type for inserting data into table "store_offer_sales" */
export type Store_Offer_Sales_Insert_Input = {
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  /** Дата и время совершения покупки */
  sale_datetime?: InputMaybe<Scalars['timestamp']>;
  sale_id?: InputMaybe<Scalars['uuid']>;
  /** Идентификатор пользователя, который совершил покупку */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Store_Offer_Sales_Max_Fields = {
  __typename?: 'store_offer_sales_max_fields';
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  /** Дата и время совершения покупки */
  sale_datetime?: Maybe<Scalars['timestamp']>;
  sale_id?: Maybe<Scalars['uuid']>;
  /** Идентификатор пользователя, который совершил покупку */
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Store_Offer_Sales_Min_Fields = {
  __typename?: 'store_offer_sales_min_fields';
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  /** Дата и время совершения покупки */
  sale_datetime?: Maybe<Scalars['timestamp']>;
  sale_id?: Maybe<Scalars['uuid']>;
  /** Идентификатор пользователя, который совершил покупку */
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "store_offer_sales" */
export type Store_Offer_Sales_Mutation_Response = {
  __typename?: 'store_offer_sales_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Offer_Sales>;
};

/** on_conflict condition type for table "store_offer_sales" */
export type Store_Offer_Sales_On_Conflict = {
  constraint: Store_Offer_Sales_Constraint;
  update_columns?: Array<Store_Offer_Sales_Update_Column>;
  where?: InputMaybe<Store_Offer_Sales_Bool_Exp>;
};

/** Ordering options when selecting data from "store_offer_sales". */
export type Store_Offer_Sales_Order_By = {
  offer_id?: InputMaybe<Order_By>;
  sale_datetime?: InputMaybe<Order_By>;
  sale_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_offer_sales */
export type Store_Offer_Sales_Pk_Columns_Input = {
  sale_id: Scalars['uuid'];
};

/** select columns of table "store_offer_sales" */
export enum Store_Offer_Sales_Select_Column {
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  SaleDatetime = 'sale_datetime',
  /** column name */
  SaleId = 'sale_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "store_offer_sales" */
export type Store_Offer_Sales_Set_Input = {
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  /** Дата и время совершения покупки */
  sale_datetime?: InputMaybe<Scalars['timestamp']>;
  sale_id?: InputMaybe<Scalars['uuid']>;
  /** Идентификатор пользователя, который совершил покупку */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "store_offer_sales" */
export type Store_Offer_Sales_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Store_Offer_Sales_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Offer_Sales_Stream_Cursor_Value_Input = {
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  /** Дата и время совершения покупки */
  sale_datetime?: InputMaybe<Scalars['timestamp']>;
  sale_id?: InputMaybe<Scalars['uuid']>;
  /** Идентификатор пользователя, который совершил покупку */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "store_offer_sales" */
export enum Store_Offer_Sales_Update_Column {
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  SaleDatetime = 'sale_datetime',
  /** column name */
  SaleId = 'sale_id',
  /** column name */
  UserId = 'user_id'
}

export type Store_Offer_Sales_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Store_Offer_Sales_Set_Input>;
  /** filter the rows which have to be updated */
  where: Store_Offer_Sales_Bool_Exp;
};

/** Таблица содержания продуктов в предложении магазина */
export type Store_Offer_Skins = {
  __typename?: 'store_offer_skins';
  /** Флаг, показывающий является ли предмет мистическим */
  is_mistery: Scalars['Boolean'];
  item_detail?: Maybe<Items>;
  /** Идентификатор предмета в таблице item_db.items */
  item_id: Scalars['uuid'];
  /** Идентификатор предложения в таблице store_offers */
  offer_id: Scalars['uuid'];
  offer_item_id: Scalars['uuid'];
  /** Поле для определения позиции предложения в общем списке */
  sort: Scalars['smallint'];
};

/** aggregated selection of "store_offer_skins" */
export type Store_Offer_Skins_Aggregate = {
  __typename?: 'store_offer_skins_aggregate';
  aggregate?: Maybe<Store_Offer_Skins_Aggregate_Fields>;
  nodes: Array<Store_Offer_Skins>;
};

/** aggregate fields of "store_offer_skins" */
export type Store_Offer_Skins_Aggregate_Fields = {
  __typename?: 'store_offer_skins_aggregate_fields';
  avg?: Maybe<Store_Offer_Skins_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_Offer_Skins_Max_Fields>;
  min?: Maybe<Store_Offer_Skins_Min_Fields>;
  stddev?: Maybe<Store_Offer_Skins_Stddev_Fields>;
  stddev_pop?: Maybe<Store_Offer_Skins_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_Offer_Skins_Stddev_Samp_Fields>;
  sum?: Maybe<Store_Offer_Skins_Sum_Fields>;
  var_pop?: Maybe<Store_Offer_Skins_Var_Pop_Fields>;
  var_samp?: Maybe<Store_Offer_Skins_Var_Samp_Fields>;
  variance?: Maybe<Store_Offer_Skins_Variance_Fields>;
};


/** aggregate fields of "store_offer_skins" */
export type Store_Offer_Skins_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Offer_Skins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Store_Offer_Skins_Avg_Fields = {
  __typename?: 'store_offer_skins_avg_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "store_offer_skins". All fields are combined with a logical 'AND'. */
export type Store_Offer_Skins_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Offer_Skins_Bool_Exp>>;
  _not?: InputMaybe<Store_Offer_Skins_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Offer_Skins_Bool_Exp>>;
  is_mistery?: InputMaybe<Boolean_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  offer_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  sort?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_offer_skins" */
export enum Store_Offer_Skins_Constraint {
  /** unique or primary key constraint on columns "offer_id" */
  StoreOfferSkinsOfferIdKey = 'store_offer_skins_offer_id_key',
  /** unique or primary key constraint on columns "offer_item_id" */
  StoreOfferSkinsPkey = 'store_offer_skins_pkey'
}

/** input type for incrementing numeric columns in table "store_offer_skins" */
export type Store_Offer_Skins_Inc_Input = {
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "store_offer_skins" */
export type Store_Offer_Skins_Insert_Input = {
  /** Флаг, показывающий является ли предмет мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  /** Идентификатор предмета в таблице item_db.items */
  item_id?: InputMaybe<Scalars['uuid']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Store_Offer_Skins_Max_Fields = {
  __typename?: 'store_offer_skins_max_fields';
  /** Идентификатор предмета в таблице item_db.items */
  item_id?: Maybe<Scalars['uuid']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  offer_item_id?: Maybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type Store_Offer_Skins_Min_Fields = {
  __typename?: 'store_offer_skins_min_fields';
  /** Идентификатор предмета в таблице item_db.items */
  item_id?: Maybe<Scalars['uuid']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  offer_item_id?: Maybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "store_offer_skins" */
export type Store_Offer_Skins_Mutation_Response = {
  __typename?: 'store_offer_skins_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Offer_Skins>;
};

/** input type for inserting object relation for remote table "store_offer_skins" */
export type Store_Offer_Skins_Obj_Rel_Insert_Input = {
  data: Store_Offer_Skins_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Store_Offer_Skins_On_Conflict>;
};

/** on_conflict condition type for table "store_offer_skins" */
export type Store_Offer_Skins_On_Conflict = {
  constraint: Store_Offer_Skins_Constraint;
  update_columns?: Array<Store_Offer_Skins_Update_Column>;
  where?: InputMaybe<Store_Offer_Skins_Bool_Exp>;
};

/** Ordering options when selecting data from "store_offer_skins". */
export type Store_Offer_Skins_Order_By = {
  is_mistery?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  offer_item_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_offer_skins */
export type Store_Offer_Skins_Pk_Columns_Input = {
  offer_item_id: Scalars['uuid'];
};

/** select columns of table "store_offer_skins" */
export enum Store_Offer_Skins_Select_Column {
  /** column name */
  IsMistery = 'is_mistery',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferItemId = 'offer_item_id',
  /** column name */
  Sort = 'sort'
}

/** input type for updating data in table "store_offer_skins" */
export type Store_Offer_Skins_Set_Input = {
  /** Флаг, показывающий является ли предмет мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  /** Идентификатор предмета в таблице item_db.items */
  item_id?: InputMaybe<Scalars['uuid']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Store_Offer_Skins_Stddev_Fields = {
  __typename?: 'store_offer_skins_stddev_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Store_Offer_Skins_Stddev_Pop_Fields = {
  __typename?: 'store_offer_skins_stddev_pop_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Store_Offer_Skins_Stddev_Samp_Fields = {
  __typename?: 'store_offer_skins_stddev_samp_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "store_offer_skins" */
export type Store_Offer_Skins_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Store_Offer_Skins_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Offer_Skins_Stream_Cursor_Value_Input = {
  /** Флаг, показывающий является ли предмет мистическим */
  is_mistery?: InputMaybe<Scalars['Boolean']>;
  /** Идентификатор предмета в таблице item_db.items */
  item_id?: InputMaybe<Scalars['uuid']>;
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
};

/** aggregate sum on columns */
export type Store_Offer_Skins_Sum_Fields = {
  __typename?: 'store_offer_skins_sum_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
};

/** update columns of table "store_offer_skins" */
export enum Store_Offer_Skins_Update_Column {
  /** column name */
  IsMistery = 'is_mistery',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferItemId = 'offer_item_id',
  /** column name */
  Sort = 'sort'
}

export type Store_Offer_Skins_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Store_Offer_Skins_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Store_Offer_Skins_Set_Input>;
  /** filter the rows which have to be updated */
  where: Store_Offer_Skins_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Store_Offer_Skins_Var_Pop_Fields = {
  __typename?: 'store_offer_skins_var_pop_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Store_Offer_Skins_Var_Samp_Fields = {
  __typename?: 'store_offer_skins_var_samp_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Store_Offer_Skins_Variance_Fields = {
  __typename?: 'store_offer_skins_variance_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
};

/** Таблица содержания продуктов в предложении магазина */
export type Store_Offer_Tickets = {
  __typename?: 'store_offer_tickets';
  /** Идентификатор предложения в таблице store_offers */
  offer_id: Scalars['uuid'];
  offer_item_id: Scalars['uuid'];
  /** Поле для определения позиции предложения в общем списке */
  sort: Scalars['smallint'];
  /** An array relationship */
  tickets_sets: Array<Tickets_Set_Content>;
  /** An aggregate relationship */
  tickets_sets_aggregate: Tickets_Set_Content_Aggregate;
  /** Идентификатор набора билетов */
  tickets_sets_id: Scalars['bigint'];
};


/** Таблица содержания продуктов в предложении магазина */
export type Store_Offer_TicketsTickets_SetsArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tickets_Set_Content_Order_By>>;
  where?: InputMaybe<Tickets_Set_Content_Bool_Exp>;
};


/** Таблица содержания продуктов в предложении магазина */
export type Store_Offer_TicketsTickets_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tickets_Set_Content_Order_By>>;
  where?: InputMaybe<Tickets_Set_Content_Bool_Exp>;
};

/** aggregated selection of "store_offer_tickets" */
export type Store_Offer_Tickets_Aggregate = {
  __typename?: 'store_offer_tickets_aggregate';
  aggregate?: Maybe<Store_Offer_Tickets_Aggregate_Fields>;
  nodes: Array<Store_Offer_Tickets>;
};

/** aggregate fields of "store_offer_tickets" */
export type Store_Offer_Tickets_Aggregate_Fields = {
  __typename?: 'store_offer_tickets_aggregate_fields';
  avg?: Maybe<Store_Offer_Tickets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_Offer_Tickets_Max_Fields>;
  min?: Maybe<Store_Offer_Tickets_Min_Fields>;
  stddev?: Maybe<Store_Offer_Tickets_Stddev_Fields>;
  stddev_pop?: Maybe<Store_Offer_Tickets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_Offer_Tickets_Stddev_Samp_Fields>;
  sum?: Maybe<Store_Offer_Tickets_Sum_Fields>;
  var_pop?: Maybe<Store_Offer_Tickets_Var_Pop_Fields>;
  var_samp?: Maybe<Store_Offer_Tickets_Var_Samp_Fields>;
  variance?: Maybe<Store_Offer_Tickets_Variance_Fields>;
};


/** aggregate fields of "store_offer_tickets" */
export type Store_Offer_Tickets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Offer_Tickets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Store_Offer_Tickets_Avg_Fields = {
  __typename?: 'store_offer_tickets_avg_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "store_offer_tickets". All fields are combined with a logical 'AND'. */
export type Store_Offer_Tickets_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Offer_Tickets_Bool_Exp>>;
  _not?: InputMaybe<Store_Offer_Tickets_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Offer_Tickets_Bool_Exp>>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  offer_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  sort?: InputMaybe<Smallint_Comparison_Exp>;
  tickets_sets?: InputMaybe<Tickets_Set_Content_Bool_Exp>;
  tickets_sets_aggregate?: InputMaybe<Tickets_Set_Content_Aggregate_Bool_Exp>;
  tickets_sets_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_offer_tickets" */
export enum Store_Offer_Tickets_Constraint {
  /** unique or primary key constraint on columns "offer_id" */
  StoreOfferTicketsOfferIdKey = 'store_offer_tickets_offer_id_key',
  /** unique or primary key constraint on columns "offer_item_id" */
  StoreOfferTicketsPkey = 'store_offer_tickets_pkey'
}

/** input type for incrementing numeric columns in table "store_offer_tickets" */
export type Store_Offer_Tickets_Inc_Input = {
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "store_offer_tickets" */
export type Store_Offer_Tickets_Insert_Input = {
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
  tickets_sets?: InputMaybe<Tickets_Set_Content_Arr_Rel_Insert_Input>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Store_Offer_Tickets_Max_Fields = {
  __typename?: 'store_offer_tickets_max_fields';
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  offer_item_id?: Maybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Store_Offer_Tickets_Min_Fields = {
  __typename?: 'store_offer_tickets_min_fields';
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: Maybe<Scalars['uuid']>;
  offer_item_id?: Maybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "store_offer_tickets" */
export type Store_Offer_Tickets_Mutation_Response = {
  __typename?: 'store_offer_tickets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Offer_Tickets>;
};

/** input type for inserting object relation for remote table "store_offer_tickets" */
export type Store_Offer_Tickets_Obj_Rel_Insert_Input = {
  data: Store_Offer_Tickets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Store_Offer_Tickets_On_Conflict>;
};

/** on_conflict condition type for table "store_offer_tickets" */
export type Store_Offer_Tickets_On_Conflict = {
  constraint: Store_Offer_Tickets_Constraint;
  update_columns?: Array<Store_Offer_Tickets_Update_Column>;
  where?: InputMaybe<Store_Offer_Tickets_Bool_Exp>;
};

/** Ordering options when selecting data from "store_offer_tickets". */
export type Store_Offer_Tickets_Order_By = {
  offer_id?: InputMaybe<Order_By>;
  offer_item_id?: InputMaybe<Order_By>;
  sort?: InputMaybe<Order_By>;
  tickets_sets_aggregate?: InputMaybe<Tickets_Set_Content_Aggregate_Order_By>;
  tickets_sets_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_offer_tickets */
export type Store_Offer_Tickets_Pk_Columns_Input = {
  offer_item_id: Scalars['uuid'];
};

/** select columns of table "store_offer_tickets" */
export enum Store_Offer_Tickets_Select_Column {
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferItemId = 'offer_item_id',
  /** column name */
  Sort = 'sort',
  /** column name */
  TicketsSetsId = 'tickets_sets_id'
}

/** input type for updating data in table "store_offer_tickets" */
export type Store_Offer_Tickets_Set_Input = {
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Store_Offer_Tickets_Stddev_Fields = {
  __typename?: 'store_offer_tickets_stddev_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Store_Offer_Tickets_Stddev_Pop_Fields = {
  __typename?: 'store_offer_tickets_stddev_pop_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Store_Offer_Tickets_Stddev_Samp_Fields = {
  __typename?: 'store_offer_tickets_stddev_samp_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "store_offer_tickets" */
export type Store_Offer_Tickets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Store_Offer_Tickets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Offer_Tickets_Stream_Cursor_Value_Input = {
  /** Идентификатор предложения в таблице store_offers */
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_item_id?: InputMaybe<Scalars['uuid']>;
  /** Поле для определения позиции предложения в общем списке */
  sort?: InputMaybe<Scalars['smallint']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Store_Offer_Tickets_Sum_Fields = {
  __typename?: 'store_offer_tickets_sum_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['smallint']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "store_offer_tickets" */
export enum Store_Offer_Tickets_Update_Column {
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferItemId = 'offer_item_id',
  /** column name */
  Sort = 'sort',
  /** column name */
  TicketsSetsId = 'tickets_sets_id'
}

export type Store_Offer_Tickets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Store_Offer_Tickets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Store_Offer_Tickets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Store_Offer_Tickets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Store_Offer_Tickets_Var_Pop_Fields = {
  __typename?: 'store_offer_tickets_var_pop_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Store_Offer_Tickets_Var_Samp_Fields = {
  __typename?: 'store_offer_tickets_var_samp_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Store_Offer_Tickets_Variance_Fields = {
  __typename?: 'store_offer_tickets_variance_fields';
  /** Поле для определения позиции предложения в общем списке */
  sort?: Maybe<Scalars['Float']>;
  /** Идентификатор набора билетов */
  tickets_sets_id?: Maybe<Scalars['Float']>;
};

/** Справочник. Типы предложений магазина */
export type Store_Offer_Types = {
  __typename?: 'store_offer_types';
  /** Описание типа предложения */
  description: Scalars['String'];
  /** Тип предложения */
  type_value: Scalars['String'];
};

/** aggregated selection of "store_offer_types" */
export type Store_Offer_Types_Aggregate = {
  __typename?: 'store_offer_types_aggregate';
  aggregate?: Maybe<Store_Offer_Types_Aggregate_Fields>;
  nodes: Array<Store_Offer_Types>;
};

/** aggregate fields of "store_offer_types" */
export type Store_Offer_Types_Aggregate_Fields = {
  __typename?: 'store_offer_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_Offer_Types_Max_Fields>;
  min?: Maybe<Store_Offer_Types_Min_Fields>;
};


/** aggregate fields of "store_offer_types" */
export type Store_Offer_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Offer_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "store_offer_types". All fields are combined with a logical 'AND'. */
export type Store_Offer_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Offer_Types_Bool_Exp>>;
  _not?: InputMaybe<Store_Offer_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Offer_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  type_value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_offer_types" */
export enum Store_Offer_Types_Constraint {
  /** unique or primary key constraint on columns "type_value" */
  StoreOfferTypesPkey = 'store_offer_types_pkey'
}

/** input type for inserting data into table "store_offer_types" */
export type Store_Offer_Types_Insert_Input = {
  /** Описание типа предложения */
  description?: InputMaybe<Scalars['String']>;
  /** Тип предложения */
  type_value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Store_Offer_Types_Max_Fields = {
  __typename?: 'store_offer_types_max_fields';
  /** Описание типа предложения */
  description?: Maybe<Scalars['String']>;
  /** Тип предложения */
  type_value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Store_Offer_Types_Min_Fields = {
  __typename?: 'store_offer_types_min_fields';
  /** Описание типа предложения */
  description?: Maybe<Scalars['String']>;
  /** Тип предложения */
  type_value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "store_offer_types" */
export type Store_Offer_Types_Mutation_Response = {
  __typename?: 'store_offer_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Offer_Types>;
};

/** on_conflict condition type for table "store_offer_types" */
export type Store_Offer_Types_On_Conflict = {
  constraint: Store_Offer_Types_Constraint;
  update_columns?: Array<Store_Offer_Types_Update_Column>;
  where?: InputMaybe<Store_Offer_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "store_offer_types". */
export type Store_Offer_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  type_value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_offer_types */
export type Store_Offer_Types_Pk_Columns_Input = {
  /** Тип предложения */
  type_value: Scalars['String'];
};

/** select columns of table "store_offer_types" */
export enum Store_Offer_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  TypeValue = 'type_value'
}

/** input type for updating data in table "store_offer_types" */
export type Store_Offer_Types_Set_Input = {
  /** Описание типа предложения */
  description?: InputMaybe<Scalars['String']>;
  /** Тип предложения */
  type_value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "store_offer_types" */
export type Store_Offer_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Store_Offer_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Offer_Types_Stream_Cursor_Value_Input = {
  /** Описание типа предложения */
  description?: InputMaybe<Scalars['String']>;
  /** Тип предложения */
  type_value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "store_offer_types" */
export enum Store_Offer_Types_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  TypeValue = 'type_value'
}

export type Store_Offer_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Store_Offer_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Store_Offer_Types_Bool_Exp;
};

/** Таблица предложений магазина */
export type Store_Offers = {
  __typename?: 'store_offers';
  /** Акционная надпись */
  badge?: Maybe<Scalars['String']>;
  /** Описание предложения */
  description?: Maybe<Scalars['String']>;
  /** Скидка на предложение. Указывается в процентах */
  discount: Scalars['smallint'];
  /** Дата и время окончания акции. Используется только для акционных предложений */
  end_datetime?: Maybe<Scalars['timestamp']>;
  /** Картинка для предложения */
  image?: Maybe<Scalars['String']>;
  /** Флаг, показывающий активность предоложения в магазине */
  is_active: Scalars['Boolean'];
  /** Флаг, показывающий является ли предложение Комбо */
  is_combo: Scalars['Boolean'];
  offer_id: Scalars['uuid'];
  /** Тип предложения */
  offer_type: Scalars['String'];
  /** Цена предложения */
  price: Scalars['Int'];
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: Maybe<Scalars['Int']>;
  /** Дата и время начала акции. Используется только для акционных предложений */
  start_datetime?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  store_offer_booster?: Maybe<Store_Offer_Boosters>;
  /** An object relationship */
  store_offer_coin?: Maybe<Store_Offer_Coins>;
  /** An object relationship */
  store_offer_lootbox?: Maybe<Store_Offer_Lootboxes>;
  /** An object relationship */
  store_offer_skin?: Maybe<Store_Offer_Skins>;
  /** An object relationship */
  store_offer_ticket?: Maybe<Store_Offer_Tickets>;
  /** Название предложения */
  title?: Maybe<Scalars['String']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "store_offers" */
export type Store_Offers_Aggregate = {
  __typename?: 'store_offers_aggregate';
  aggregate?: Maybe<Store_Offers_Aggregate_Fields>;
  nodes: Array<Store_Offers>;
};

/** aggregate fields of "store_offers" */
export type Store_Offers_Aggregate_Fields = {
  __typename?: 'store_offers_aggregate_fields';
  avg?: Maybe<Store_Offers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_Offers_Max_Fields>;
  min?: Maybe<Store_Offers_Min_Fields>;
  stddev?: Maybe<Store_Offers_Stddev_Fields>;
  stddev_pop?: Maybe<Store_Offers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_Offers_Stddev_Samp_Fields>;
  sum?: Maybe<Store_Offers_Sum_Fields>;
  var_pop?: Maybe<Store_Offers_Var_Pop_Fields>;
  var_samp?: Maybe<Store_Offers_Var_Samp_Fields>;
  variance?: Maybe<Store_Offers_Variance_Fields>;
};


/** aggregate fields of "store_offers" */
export type Store_Offers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Store_Offers_Avg_Fields = {
  __typename?: 'store_offers_avg_fields';
  /** Скидка на предложение. Указывается в процентах */
  discount?: Maybe<Scalars['Float']>;
  /** Цена предложения */
  price?: Maybe<Scalars['Float']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: Maybe<Scalars['Float']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "store_offers". All fields are combined with a logical 'AND'. */
export type Store_Offers_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Offers_Bool_Exp>>;
  _not?: InputMaybe<Store_Offers_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Offers_Bool_Exp>>;
  badge?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  discount?: InputMaybe<Smallint_Comparison_Exp>;
  end_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_combo?: InputMaybe<Boolean_Comparison_Exp>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  offer_type?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  sold_quantity?: InputMaybe<Int_Comparison_Exp>;
  start_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  store_offer_booster?: InputMaybe<Store_Offer_Boosters_Bool_Exp>;
  store_offer_coin?: InputMaybe<Store_Offer_Coins_Bool_Exp>;
  store_offer_lootbox?: InputMaybe<Store_Offer_Lootboxes_Bool_Exp>;
  store_offer_skin?: InputMaybe<Store_Offer_Skins_Bool_Exp>;
  store_offer_ticket?: InputMaybe<Store_Offer_Tickets_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  total_quantity?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_offers" */
export enum Store_Offers_Constraint {
  /** unique or primary key constraint on columns "offer_id" */
  StoreOffersPkey = 'store_offers_pkey'
}

/** input type for incrementing numeric columns in table "store_offers" */
export type Store_Offers_Inc_Input = {
  /** Скидка на предложение. Указывается в процентах */
  discount?: InputMaybe<Scalars['smallint']>;
  /** Цена предложения */
  price?: InputMaybe<Scalars['Int']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: InputMaybe<Scalars['Int']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "store_offers" */
export type Store_Offers_Insert_Input = {
  /** Акционная надпись */
  badge?: InputMaybe<Scalars['String']>;
  /** Описание предложения */
  description?: InputMaybe<Scalars['String']>;
  /** Скидка на предложение. Указывается в процентах */
  discount?: InputMaybe<Scalars['smallint']>;
  /** Дата и время окончания акции. Используется только для акционных предложений */
  end_datetime?: InputMaybe<Scalars['timestamp']>;
  /** Картинка для предложения */
  image?: InputMaybe<Scalars['String']>;
  /** Флаг, показывающий активность предоложения в магазине */
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Флаг, показывающий является ли предложение Комбо */
  is_combo?: InputMaybe<Scalars['Boolean']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  /** Тип предложения */
  offer_type?: InputMaybe<Scalars['String']>;
  /** Цена предложения */
  price?: InputMaybe<Scalars['Int']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: InputMaybe<Scalars['Int']>;
  /** Дата и время начала акции. Используется только для акционных предложений */
  start_datetime?: InputMaybe<Scalars['timestamp']>;
  store_offer_booster?: InputMaybe<Store_Offer_Boosters_Obj_Rel_Insert_Input>;
  store_offer_coin?: InputMaybe<Store_Offer_Coins_Obj_Rel_Insert_Input>;
  store_offer_lootbox?: InputMaybe<Store_Offer_Lootboxes_Obj_Rel_Insert_Input>;
  store_offer_skin?: InputMaybe<Store_Offer_Skins_Obj_Rel_Insert_Input>;
  store_offer_ticket?: InputMaybe<Store_Offer_Tickets_Obj_Rel_Insert_Input>;
  /** Название предложения */
  title?: InputMaybe<Scalars['String']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Store_Offers_Max_Fields = {
  __typename?: 'store_offers_max_fields';
  /** Акционная надпись */
  badge?: Maybe<Scalars['String']>;
  /** Описание предложения */
  description?: Maybe<Scalars['String']>;
  /** Скидка на предложение. Указывается в процентах */
  discount?: Maybe<Scalars['smallint']>;
  /** Дата и время окончания акции. Используется только для акционных предложений */
  end_datetime?: Maybe<Scalars['timestamp']>;
  /** Картинка для предложения */
  image?: Maybe<Scalars['String']>;
  offer_id?: Maybe<Scalars['uuid']>;
  /** Тип предложения */
  offer_type?: Maybe<Scalars['String']>;
  /** Цена предложения */
  price?: Maybe<Scalars['Int']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: Maybe<Scalars['Int']>;
  /** Дата и время начала акции. Используется только для акционных предложений */
  start_datetime?: Maybe<Scalars['timestamp']>;
  /** Название предложения */
  title?: Maybe<Scalars['String']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Store_Offers_Min_Fields = {
  __typename?: 'store_offers_min_fields';
  /** Акционная надпись */
  badge?: Maybe<Scalars['String']>;
  /** Описание предложения */
  description?: Maybe<Scalars['String']>;
  /** Скидка на предложение. Указывается в процентах */
  discount?: Maybe<Scalars['smallint']>;
  /** Дата и время окончания акции. Используется только для акционных предложений */
  end_datetime?: Maybe<Scalars['timestamp']>;
  /** Картинка для предложения */
  image?: Maybe<Scalars['String']>;
  offer_id?: Maybe<Scalars['uuid']>;
  /** Тип предложения */
  offer_type?: Maybe<Scalars['String']>;
  /** Цена предложения */
  price?: Maybe<Scalars['Int']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: Maybe<Scalars['Int']>;
  /** Дата и время начала акции. Используется только для акционных предложений */
  start_datetime?: Maybe<Scalars['timestamp']>;
  /** Название предложения */
  title?: Maybe<Scalars['String']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "store_offers" */
export type Store_Offers_Mutation_Response = {
  __typename?: 'store_offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Offers>;
};

/** on_conflict condition type for table "store_offers" */
export type Store_Offers_On_Conflict = {
  constraint: Store_Offers_Constraint;
  update_columns?: Array<Store_Offers_Update_Column>;
  where?: InputMaybe<Store_Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "store_offers". */
export type Store_Offers_Order_By = {
  badge?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  end_datetime?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_combo?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  offer_type?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sold_quantity?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  store_offer_booster?: InputMaybe<Store_Offer_Boosters_Order_By>;
  store_offer_coin?: InputMaybe<Store_Offer_Coins_Order_By>;
  store_offer_lootbox?: InputMaybe<Store_Offer_Lootboxes_Order_By>;
  store_offer_skin?: InputMaybe<Store_Offer_Skins_Order_By>;
  store_offer_ticket?: InputMaybe<Store_Offer_Tickets_Order_By>;
  title?: InputMaybe<Order_By>;
  total_quantity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_offers */
export type Store_Offers_Pk_Columns_Input = {
  offer_id: Scalars['uuid'];
};

/** select columns of table "store_offers" */
export enum Store_Offers_Select_Column {
  /** column name */
  Badge = 'badge',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  EndDatetime = 'end_datetime',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsCombo = 'is_combo',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferType = 'offer_type',
  /** column name */
  Price = 'price',
  /** column name */
  SoldQuantity = 'sold_quantity',
  /** column name */
  StartDatetime = 'start_datetime',
  /** column name */
  Title = 'title',
  /** column name */
  TotalQuantity = 'total_quantity'
}

/** input type for updating data in table "store_offers" */
export type Store_Offers_Set_Input = {
  /** Акционная надпись */
  badge?: InputMaybe<Scalars['String']>;
  /** Описание предложения */
  description?: InputMaybe<Scalars['String']>;
  /** Скидка на предложение. Указывается в процентах */
  discount?: InputMaybe<Scalars['smallint']>;
  /** Дата и время окончания акции. Используется только для акционных предложений */
  end_datetime?: InputMaybe<Scalars['timestamp']>;
  /** Картинка для предложения */
  image?: InputMaybe<Scalars['String']>;
  /** Флаг, показывающий активность предоложения в магазине */
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Флаг, показывающий является ли предложение Комбо */
  is_combo?: InputMaybe<Scalars['Boolean']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  /** Тип предложения */
  offer_type?: InputMaybe<Scalars['String']>;
  /** Цена предложения */
  price?: InputMaybe<Scalars['Int']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: InputMaybe<Scalars['Int']>;
  /** Дата и время начала акции. Используется только для акционных предложений */
  start_datetime?: InputMaybe<Scalars['timestamp']>;
  /** Название предложения */
  title?: InputMaybe<Scalars['String']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Store_Offers_Stddev_Fields = {
  __typename?: 'store_offers_stddev_fields';
  /** Скидка на предложение. Указывается в процентах */
  discount?: Maybe<Scalars['Float']>;
  /** Цена предложения */
  price?: Maybe<Scalars['Float']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: Maybe<Scalars['Float']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Store_Offers_Stddev_Pop_Fields = {
  __typename?: 'store_offers_stddev_pop_fields';
  /** Скидка на предложение. Указывается в процентах */
  discount?: Maybe<Scalars['Float']>;
  /** Цена предложения */
  price?: Maybe<Scalars['Float']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: Maybe<Scalars['Float']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Store_Offers_Stddev_Samp_Fields = {
  __typename?: 'store_offers_stddev_samp_fields';
  /** Скидка на предложение. Указывается в процентах */
  discount?: Maybe<Scalars['Float']>;
  /** Цена предложения */
  price?: Maybe<Scalars['Float']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: Maybe<Scalars['Float']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "store_offers" */
export type Store_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Store_Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Store_Offers_Stream_Cursor_Value_Input = {
  /** Акционная надпись */
  badge?: InputMaybe<Scalars['String']>;
  /** Описание предложения */
  description?: InputMaybe<Scalars['String']>;
  /** Скидка на предложение. Указывается в процентах */
  discount?: InputMaybe<Scalars['smallint']>;
  /** Дата и время окончания акции. Используется только для акционных предложений */
  end_datetime?: InputMaybe<Scalars['timestamp']>;
  /** Картинка для предложения */
  image?: InputMaybe<Scalars['String']>;
  /** Флаг, показывающий активность предоложения в магазине */
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** Флаг, показывающий является ли предложение Комбо */
  is_combo?: InputMaybe<Scalars['Boolean']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  /** Тип предложения */
  offer_type?: InputMaybe<Scalars['String']>;
  /** Цена предложения */
  price?: InputMaybe<Scalars['Int']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: InputMaybe<Scalars['Int']>;
  /** Дата и время начала акции. Используется только для акционных предложений */
  start_datetime?: InputMaybe<Scalars['timestamp']>;
  /** Название предложения */
  title?: InputMaybe<Scalars['String']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Store_Offers_Sum_Fields = {
  __typename?: 'store_offers_sum_fields';
  /** Скидка на предложение. Указывается в процентах */
  discount?: Maybe<Scalars['smallint']>;
  /** Цена предложения */
  price?: Maybe<Scalars['Int']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: Maybe<Scalars['Int']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: Maybe<Scalars['Int']>;
};

/** update columns of table "store_offers" */
export enum Store_Offers_Update_Column {
  /** column name */
  Badge = 'badge',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  EndDatetime = 'end_datetime',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsCombo = 'is_combo',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferType = 'offer_type',
  /** column name */
  Price = 'price',
  /** column name */
  SoldQuantity = 'sold_quantity',
  /** column name */
  StartDatetime = 'start_datetime',
  /** column name */
  Title = 'title',
  /** column name */
  TotalQuantity = 'total_quantity'
}

export type Store_Offers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Store_Offers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Store_Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Store_Offers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Store_Offers_Var_Pop_Fields = {
  __typename?: 'store_offers_var_pop_fields';
  /** Скидка на предложение. Указывается в процентах */
  discount?: Maybe<Scalars['Float']>;
  /** Цена предложения */
  price?: Maybe<Scalars['Float']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: Maybe<Scalars['Float']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Store_Offers_Var_Samp_Fields = {
  __typename?: 'store_offers_var_samp_fields';
  /** Скидка на предложение. Указывается в процентах */
  discount?: Maybe<Scalars['Float']>;
  /** Цена предложения */
  price?: Maybe<Scalars['Float']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: Maybe<Scalars['Float']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Store_Offers_Variance_Fields = {
  __typename?: 'store_offers_variance_fields';
  /** Скидка на предложение. Указывается в процентах */
  discount?: Maybe<Scalars['Float']>;
  /** Цена предложения */
  price?: Maybe<Scalars['Float']>;
  /** Проданное количество, выставленное для продажи. Используется только для акционных предложений */
  sold_quantity?: Maybe<Scalars['Float']>;
  /** Общее количество, выставленное для продажи. Используется только для акционных предложений */
  total_quantity?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "LootboxOpensCounts" */
  LootboxOpensCounts: Array<LootboxOpensCounts>;
  /** fetch aggregated fields from the table: "LootboxOpensCounts" */
  LootboxOpensCounts_aggregate: LootboxOpensCounts_Aggregate;
  /** fetch data from the table in a streaming manner: "LootboxOpensCounts" */
  LootboxOpensCounts_stream: Array<LootboxOpensCounts>;
  /** fetch data from the table: "TopDropStripView" */
  TopDropStripView: Array<TopDropStripView>;
  /** fetch aggregated fields from the table: "TopDropStripView" */
  TopDropStripView_aggregate: TopDropStripView_Aggregate;
  /** fetch data from the table in a streaming manner: "TopDropStripView" */
  TopDropStripView_stream: Array<TopDropStripView>;
  /** fetch data from the table: "account_items_names" */
  account_items_names: Array<Account_Items_Names>;
  /** fetch aggregated fields from the table: "account_items_names" */
  account_items_names_aggregate: Account_Items_Names_Aggregate;
  /** fetch data from the table: "account_items_names" using primary key columns */
  account_items_names_by_pk?: Maybe<Account_Items_Names>;
  /** fetch data from the table in a streaming manner: "account_items_names" */
  account_items_names_stream: Array<Account_Items_Names>;
  /** fetch data from the table: "admin_items_names" */
  admin_items_names: Array<Admin_Items_Names>;
  /** fetch aggregated fields from the table: "admin_items_names" */
  admin_items_names_aggregate: Admin_Items_Names_Aggregate;
  /** fetch data from the table: "admin_items_names" using primary key columns */
  admin_items_names_by_pk?: Maybe<Admin_Items_Names>;
  /** fetch data from the table in a streaming manner: "admin_items_names" */
  admin_items_names_stream: Array<Admin_Items_Names>;
  /** fetch data from the table: "admin_user_params" */
  admin_user_params: Array<Admin_User_Params>;
  /** fetch aggregated fields from the table: "admin_user_params" */
  admin_user_params_aggregate: Admin_User_Params_Aggregate;
  /** fetch data from the table: "admin_user_params" using primary key columns */
  admin_user_params_by_pk?: Maybe<Admin_User_Params>;
  /** fetch data from the table in a streaming manner: "admin_user_params" */
  admin_user_params_stream: Array<Admin_User_Params>;
  /** fetch data from the table: "amp_sessions_data" */
  amp_sessions_data: Array<Amp_Sessions_Data>;
  /** fetch aggregated fields from the table: "amp_sessions_data" */
  amp_sessions_data_aggregate: Amp_Sessions_Data_Aggregate;
  /** fetch data from the table: "amp_sessions_data" using primary key columns */
  amp_sessions_data_by_pk?: Maybe<Amp_Sessions_Data>;
  /** fetch data from the table in a streaming manner: "amp_sessions_data" */
  amp_sessions_data_stream: Array<Amp_Sessions_Data>;
  /** fetch data from the table: "banned_ips" */
  banned_ips: Array<Banned_Ips>;
  /** fetch aggregated fields from the table: "banned_ips" */
  banned_ips_aggregate: Banned_Ips_Aggregate;
  /** fetch data from the table: "banned_ips" using primary key columns */
  banned_ips_by_pk?: Maybe<Banned_Ips>;
  /** fetch data from the table in a streaming manner: "banned_ips" */
  banned_ips_stream: Array<Banned_Ips>;
  /** fetch data from the table: "banned_users_for_multiaccount" */
  banned_users_for_multiaccount: Array<Banned_Users_For_Multiaccount>;
  /** fetch aggregated fields from the table: "banned_users_for_multiaccount" */
  banned_users_for_multiaccount_aggregate: Banned_Users_For_Multiaccount_Aggregate;
  /** fetch data from the table: "banned_users_for_multiaccount" using primary key columns */
  banned_users_for_multiaccount_by_pk?: Maybe<Banned_Users_For_Multiaccount>;
  /** fetch data from the table in a streaming manner: "banned_users_for_multiaccount" */
  banned_users_for_multiaccount_stream: Array<Banned_Users_For_Multiaccount>;
  /** fetch data from the table: "bill" */
  bill: Array<Bill>;
  /** fetch aggregated fields from the table: "bill" */
  bill_aggregate: Bill_Aggregate;
  /** fetch data from the table: "bill" using primary key columns */
  bill_by_pk?: Maybe<Bill>;
  /** fetch data from the table in a streaming manner: "bill" */
  bill_stream: Array<Bill>;
  /** fetch data from the table: "bonus_params" */
  bonus_params: Array<Bonus_Params>;
  /** fetch aggregated fields from the table: "bonus_params" */
  bonus_params_aggregate: Bonus_Params_Aggregate;
  /** fetch data from the table: "bonus_params" using primary key columns */
  bonus_params_by_pk?: Maybe<Bonus_Params>;
  /** fetch data from the table in a streaming manner: "bonus_params" */
  bonus_params_stream: Array<Bonus_Params>;
  /** fetch data from the table: "bonus_wheel_rewards" */
  bonus_wheel_rewards: Array<Bonus_Wheel_Rewards>;
  /** fetch aggregated fields from the table: "bonus_wheel_rewards" */
  bonus_wheel_rewards_aggregate: Bonus_Wheel_Rewards_Aggregate;
  /** fetch data from the table: "bonus_wheel_rewards" using primary key columns */
  bonus_wheel_rewards_by_pk?: Maybe<Bonus_Wheel_Rewards>;
  /** fetch data from the table in a streaming manner: "bonus_wheel_rewards" */
  bonus_wheel_rewards_stream: Array<Bonus_Wheel_Rewards>;
  /** fetch data from the table: "bonus_wheel_ticket_type" */
  bonus_wheel_ticket_type: Array<Bonus_Wheel_Ticket_Type>;
  /** fetch aggregated fields from the table: "bonus_wheel_ticket_type" */
  bonus_wheel_ticket_type_aggregate: Bonus_Wheel_Ticket_Type_Aggregate;
  /** fetch data from the table: "bonus_wheel_ticket_type" using primary key columns */
  bonus_wheel_ticket_type_by_pk?: Maybe<Bonus_Wheel_Ticket_Type>;
  /** fetch data from the table: "bonus_wheel_ticket_type_name_localization" */
  bonus_wheel_ticket_type_name_localization: Array<Bonus_Wheel_Ticket_Type_Name_Localization>;
  /** fetch aggregated fields from the table: "bonus_wheel_ticket_type_name_localization" */
  bonus_wheel_ticket_type_name_localization_aggregate: Bonus_Wheel_Ticket_Type_Name_Localization_Aggregate;
  /** fetch data from the table: "bonus_wheel_ticket_type_name_localization" using primary key columns */
  bonus_wheel_ticket_type_name_localization_by_pk?: Maybe<Bonus_Wheel_Ticket_Type_Name_Localization>;
  /** fetch data from the table in a streaming manner: "bonus_wheel_ticket_type_name_localization" */
  bonus_wheel_ticket_type_name_localization_stream: Array<Bonus_Wheel_Ticket_Type_Name_Localization>;
  /** fetch data from the table in a streaming manner: "bonus_wheel_ticket_type" */
  bonus_wheel_ticket_type_stream: Array<Bonus_Wheel_Ticket_Type>;
  /** fetch data from the table: "bonus_wheel_user_rewards" */
  bonus_wheel_user_rewards: Array<Bonus_Wheel_User_Rewards>;
  /** fetch aggregated fields from the table: "bonus_wheel_user_rewards" */
  bonus_wheel_user_rewards_aggregate: Bonus_Wheel_User_Rewards_Aggregate;
  /** fetch data from the table: "bonus_wheel_user_rewards" using primary key columns */
  bonus_wheel_user_rewards_by_pk?: Maybe<Bonus_Wheel_User_Rewards>;
  /** fetch data from the table in a streaming manner: "bonus_wheel_user_rewards" */
  bonus_wheel_user_rewards_stream: Array<Bonus_Wheel_User_Rewards>;
  /** fetch data from the table: "bonus_wheel_user_tickets" */
  bonus_wheel_user_tickets: Array<Bonus_Wheel_User_Tickets>;
  /** fetch aggregated fields from the table: "bonus_wheel_user_tickets" */
  bonus_wheel_user_tickets_aggregate: Bonus_Wheel_User_Tickets_Aggregate;
  /** fetch data from the table: "bonus_wheel_user_tickets" using primary key columns */
  bonus_wheel_user_tickets_by_pk?: Maybe<Bonus_Wheel_User_Tickets>;
  /** fetch data from the table in a streaming manner: "bonus_wheel_user_tickets" */
  bonus_wheel_user_tickets_stream: Array<Bonus_Wheel_User_Tickets>;
  /** fetch data from the table: "bonus_wheels" */
  bonus_wheels: Array<Bonus_Wheels>;
  /** fetch aggregated fields from the table: "bonus_wheels" */
  bonus_wheels_aggregate: Bonus_Wheels_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonus_skins" */
  bonus_wheels_bonus_skins: Array<Bonus_Wheels_Bonus_Skins>;
  /** fetch aggregated fields from the table: "bonus_wheels_bonus_skins" */
  bonus_wheels_bonus_skins_aggregate: Bonus_Wheels_Bonus_Skins_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonus_skins" using primary key columns */
  bonus_wheels_bonus_skins_by_pk?: Maybe<Bonus_Wheels_Bonus_Skins>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_bonus_skins" */
  bonus_wheels_bonus_skins_stream: Array<Bonus_Wheels_Bonus_Skins>;
  /** fetch data from the table: "bonus_wheels_bonuses" */
  bonus_wheels_bonuses: Array<Bonus_Wheels_Bonuses>;
  /** fetch aggregated fields from the table: "bonus_wheels_bonuses" */
  bonus_wheels_bonuses_aggregate: Bonus_Wheels_Bonuses_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonuses" using primary key columns */
  bonus_wheels_bonuses_by_pk?: Maybe<Bonus_Wheels_Bonuses>;
  /** fetch data from the table: "bonus_wheels_bonuses_localization" */
  bonus_wheels_bonuses_localization: Array<Bonus_Wheels_Bonuses_Localization>;
  /** fetch aggregated fields from the table: "bonus_wheels_bonuses_localization" */
  bonus_wheels_bonuses_localization_aggregate: Bonus_Wheels_Bonuses_Localization_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonuses_localization" using primary key columns */
  bonus_wheels_bonuses_localization_by_pk?: Maybe<Bonus_Wheels_Bonuses_Localization>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_bonuses_localization" */
  bonus_wheels_bonuses_localization_stream: Array<Bonus_Wheels_Bonuses_Localization>;
  /** fetch data from the table: "bonus_wheels_bonuses_params" */
  bonus_wheels_bonuses_params: Array<Bonus_Wheels_Bonuses_Params>;
  /** fetch aggregated fields from the table: "bonus_wheels_bonuses_params" */
  bonus_wheels_bonuses_params_aggregate: Bonus_Wheels_Bonuses_Params_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonuses_params" using primary key columns */
  bonus_wheels_bonuses_params_by_pk?: Maybe<Bonus_Wheels_Bonuses_Params>;
  /** fetch data from the table: "bonus_wheels_bonuses_params_localization" */
  bonus_wheels_bonuses_params_localization: Array<Bonus_Wheels_Bonuses_Params_Localization>;
  /** fetch aggregated fields from the table: "bonus_wheels_bonuses_params_localization" */
  bonus_wheels_bonuses_params_localization_aggregate: Bonus_Wheels_Bonuses_Params_Localization_Aggregate;
  /** fetch data from the table: "bonus_wheels_bonuses_params_localization" using primary key columns */
  bonus_wheels_bonuses_params_localization_by_pk?: Maybe<Bonus_Wheels_Bonuses_Params_Localization>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_bonuses_params_localization" */
  bonus_wheels_bonuses_params_localization_stream: Array<Bonus_Wheels_Bonuses_Params_Localization>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_bonuses_params" */
  bonus_wheels_bonuses_params_stream: Array<Bonus_Wheels_Bonuses_Params>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_bonuses" */
  bonus_wheels_bonuses_stream: Array<Bonus_Wheels_Bonuses>;
  /** fetch data from the table: "bonus_wheels" using primary key columns */
  bonus_wheels_by_pk?: Maybe<Bonus_Wheels>;
  /** fetch data from the table: "bonus_wheels_generator" */
  bonus_wheels_generator: Array<Bonus_Wheels_Generator>;
  /** fetch aggregated fields from the table: "bonus_wheels_generator" */
  bonus_wheels_generator_aggregate: Bonus_Wheels_Generator_Aggregate;
  /** fetch data from the table: "bonus_wheels_generator_bonuses" */
  bonus_wheels_generator_bonuses: Array<Bonus_Wheels_Generator_Bonuses>;
  /** fetch aggregated fields from the table: "bonus_wheels_generator_bonuses" */
  bonus_wheels_generator_bonuses_aggregate: Bonus_Wheels_Generator_Bonuses_Aggregate;
  /** fetch data from the table: "bonus_wheels_generator_bonuses" using primary key columns */
  bonus_wheels_generator_bonuses_by_pk?: Maybe<Bonus_Wheels_Generator_Bonuses>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_generator_bonuses" */
  bonus_wheels_generator_bonuses_stream: Array<Bonus_Wheels_Generator_Bonuses>;
  /** fetch data from the table: "bonus_wheels_generator" using primary key columns */
  bonus_wheels_generator_by_pk?: Maybe<Bonus_Wheels_Generator>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_generator" */
  bonus_wheels_generator_stream: Array<Bonus_Wheels_Generator>;
  /** fetch data from the table: "bonus_wheels_items_in_wheel" */
  bonus_wheels_items_in_wheel: Array<Bonus_Wheels_Items_In_Wheel>;
  /** fetch aggregated fields from the table: "bonus_wheels_items_in_wheel" */
  bonus_wheels_items_in_wheel_aggregate: Bonus_Wheels_Items_In_Wheel_Aggregate;
  /** fetch data from the table: "bonus_wheels_items_in_wheel" using primary key columns */
  bonus_wheels_items_in_wheel_by_pk?: Maybe<Bonus_Wheels_Items_In_Wheel>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_items_in_wheel" */
  bonus_wheels_items_in_wheel_stream: Array<Bonus_Wheels_Items_In_Wheel>;
  /** fetch data from the table in a streaming manner: "bonus_wheels" */
  bonus_wheels_stream: Array<Bonus_Wheels>;
  /** fetch data from the table: "bonus_wheels_user_check" */
  bonus_wheels_user_check: Array<Bonus_Wheels_User_Check>;
  /** fetch aggregated fields from the table: "bonus_wheels_user_check" */
  bonus_wheels_user_check_aggregate: Bonus_Wheels_User_Check_Aggregate;
  /** fetch data from the table: "bonus_wheels_user_check" using primary key columns */
  bonus_wheels_user_check_by_pk?: Maybe<Bonus_Wheels_User_Check>;
  /** fetch data from the table: "bonus_wheels_user_check_params" */
  bonus_wheels_user_check_params: Array<Bonus_Wheels_User_Check_Params>;
  /** fetch aggregated fields from the table: "bonus_wheels_user_check_params" */
  bonus_wheels_user_check_params_aggregate: Bonus_Wheels_User_Check_Params_Aggregate;
  /** fetch data from the table: "bonus_wheels_user_check_params" using primary key columns */
  bonus_wheels_user_check_params_by_pk?: Maybe<Bonus_Wheels_User_Check_Params>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_user_check_params" */
  bonus_wheels_user_check_params_stream: Array<Bonus_Wheels_User_Check_Params>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_user_check" */
  bonus_wheels_user_check_stream: Array<Bonus_Wheels_User_Check>;
  /** fetch data from the table: "bonus_wheels_user_offers" */
  bonus_wheels_user_offers: Array<Bonus_Wheels_User_Offers>;
  /** fetch aggregated fields from the table: "bonus_wheels_user_offers" */
  bonus_wheels_user_offers_aggregate: Bonus_Wheels_User_Offers_Aggregate;
  /** fetch data from the table: "bonus_wheels_user_offers" using primary key columns */
  bonus_wheels_user_offers_by_pk?: Maybe<Bonus_Wheels_User_Offers>;
  /** fetch data from the table: "bonus_wheels_user_offers_params" */
  bonus_wheels_user_offers_params: Array<Bonus_Wheels_User_Offers_Params>;
  /** fetch aggregated fields from the table: "bonus_wheels_user_offers_params" */
  bonus_wheels_user_offers_params_aggregate: Bonus_Wheels_User_Offers_Params_Aggregate;
  /** fetch data from the table: "bonus_wheels_user_offers_params" using primary key columns */
  bonus_wheels_user_offers_params_by_pk?: Maybe<Bonus_Wheels_User_Offers_Params>;
  /** fetch data from the table: "bonus_wheels_user_offers_params_localization" */
  bonus_wheels_user_offers_params_localization: Array<Bonus_Wheels_User_Offers_Params_Localization>;
  /** fetch aggregated fields from the table: "bonus_wheels_user_offers_params_localization" */
  bonus_wheels_user_offers_params_localization_aggregate: Bonus_Wheels_User_Offers_Params_Localization_Aggregate;
  /** fetch data from the table: "bonus_wheels_user_offers_params_localization" using primary key columns */
  bonus_wheels_user_offers_params_localization_by_pk?: Maybe<Bonus_Wheels_User_Offers_Params_Localization>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_user_offers_params_localization" */
  bonus_wheels_user_offers_params_localization_stream: Array<Bonus_Wheels_User_Offers_Params_Localization>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_user_offers_params" */
  bonus_wheels_user_offers_params_stream: Array<Bonus_Wheels_User_Offers_Params>;
  /** fetch data from the table in a streaming manner: "bonus_wheels_user_offers" */
  bonus_wheels_user_offers_stream: Array<Bonus_Wheels_User_Offers>;
  /** fetch data from the table: "bonuses" */
  bonuses: Array<Bonuses>;
  /** fetch aggregated fields from the table: "bonuses" */
  bonuses_aggregate: Bonuses_Aggregate;
  /** fetch data from the table: "bonuses" using primary key columns */
  bonuses_by_pk?: Maybe<Bonuses>;
  /** fetch data from the table: "bonuses_register" */
  bonuses_register: Array<Bonuses_Register>;
  /** fetch aggregated fields from the table: "bonuses_register" */
  bonuses_register_aggregate: Bonuses_Register_Aggregate;
  /** fetch data from the table: "bonuses_register" using primary key columns */
  bonuses_register_by_pk?: Maybe<Bonuses_Register>;
  /** fetch data from the table: "bonuses_register_offers" */
  bonuses_register_offers: Array<Bonuses_Register_Offers>;
  /** fetch aggregated fields from the table: "bonuses_register_offers" */
  bonuses_register_offers_aggregate: Bonuses_Register_Offers_Aggregate;
  /** fetch data from the table: "bonuses_register_offers" using primary key columns */
  bonuses_register_offers_by_pk?: Maybe<Bonuses_Register_Offers>;
  /** fetch data from the table in a streaming manner: "bonuses_register_offers" */
  bonuses_register_offers_stream: Array<Bonuses_Register_Offers>;
  /** fetch data from the table in a streaming manner: "bonuses_register" */
  bonuses_register_stream: Array<Bonuses_Register>;
  /** fetch data from the table in a streaming manner: "bonuses" */
  bonuses_stream: Array<Bonuses>;
  /** fetch data from the table: "boosters_set_content" */
  boosters_set_content: Array<Boosters_Set_Content>;
  /** fetch aggregated fields from the table: "boosters_set_content" */
  boosters_set_content_aggregate: Boosters_Set_Content_Aggregate;
  /** fetch data from the table: "boosters_set_content" using primary key columns */
  boosters_set_content_by_pk?: Maybe<Boosters_Set_Content>;
  /** fetch data from the table in a streaming manner: "boosters_set_content" */
  boosters_set_content_stream: Array<Boosters_Set_Content>;
  /** fetch data from the table: "boosters_sets" */
  boosters_sets: Array<Boosters_Sets>;
  /** fetch aggregated fields from the table: "boosters_sets" */
  boosters_sets_aggregate: Boosters_Sets_Aggregate;
  /** fetch data from the table: "boosters_sets" using primary key columns */
  boosters_sets_by_pk?: Maybe<Boosters_Sets>;
  /** fetch data from the table in a streaming manner: "boosters_sets" */
  boosters_sets_stream: Array<Boosters_Sets>;
  /** fetch data from the table: "bot_account_settings" */
  bot_account_settings: Array<Bot_Account_Settings>;
  /** fetch aggregated fields from the table: "bot_account_settings" */
  bot_account_settings_aggregate: Bot_Account_Settings_Aggregate;
  /** fetch data from the table: "bot_account_settings" using primary key columns */
  bot_account_settings_by_pk?: Maybe<Bot_Account_Settings>;
  /** fetch data from the table in a streaming manner: "bot_account_settings" */
  bot_account_settings_stream: Array<Bot_Account_Settings>;
  /** fetch data from the table: "bots_lootboxes_opens_count" */
  bots_lootboxes_opens_count: Array<Bots_Lootboxes_Opens_Count>;
  /** fetch aggregated fields from the table: "bots_lootboxes_opens_count" */
  bots_lootboxes_opens_count_aggregate: Bots_Lootboxes_Opens_Count_Aggregate;
  /** fetch data from the table: "bots_lootboxes_opens_count" using primary key columns */
  bots_lootboxes_opens_count_by_pk?: Maybe<Bots_Lootboxes_Opens_Count>;
  /** fetch data from the table in a streaming manner: "bots_lootboxes_opens_count" */
  bots_lootboxes_opens_count_stream: Array<Bots_Lootboxes_Opens_Count>;
  /** fetch data from the table: "coins_set_content" */
  coins_set_content: Array<Coins_Set_Content>;
  /** fetch aggregated fields from the table: "coins_set_content" */
  coins_set_content_aggregate: Coins_Set_Content_Aggregate;
  /** fetch data from the table: "coins_set_content" using primary key columns */
  coins_set_content_by_pk?: Maybe<Coins_Set_Content>;
  /** fetch data from the table in a streaming manner: "coins_set_content" */
  coins_set_content_stream: Array<Coins_Set_Content>;
  /** fetch data from the table: "coins_sets" */
  coins_sets: Array<Coins_Sets>;
  /** fetch aggregated fields from the table: "coins_sets" */
  coins_sets_aggregate: Coins_Sets_Aggregate;
  /** fetch data from the table: "coins_sets" using primary key columns */
  coins_sets_by_pk?: Maybe<Coins_Sets>;
  /** fetch data from the table in a streaming manner: "coins_sets" */
  coins_sets_stream: Array<Coins_Sets>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table in a streaming manner: "company" */
  company_stream: Array<Company>;
  /** fetch data from the table: "coupons" */
  coupons: Array<Coupons>;
  /** fetch aggregated fields from the table: "coupons" */
  coupons_aggregate: Coupons_Aggregate;
  /** fetch data from the table: "coupons" using primary key columns */
  coupons_by_pk?: Maybe<Coupons>;
  /** fetch data from the table in a streaming manner: "coupons" */
  coupons_stream: Array<Coupons>;
  /** An array relationship */
  csgomarket_token_balance: Array<Csgomarket_Token_Balance>;
  /** An aggregate relationship */
  csgomarket_token_balance_aggregate: Csgomarket_Token_Balance_Aggregate;
  /** fetch data from the table: "csgomarket_token_balance" using primary key columns */
  csgomarket_token_balance_by_pk?: Maybe<Csgomarket_Token_Balance>;
  /** fetch data from the table in a streaming manner: "csgomarket_token_balance" */
  csgomarket_token_balance_stream: Array<Csgomarket_Token_Balance>;
  /** fetch data from the table: "csgomarket_tokens" */
  csgomarket_tokens: Array<Csgomarket_Tokens>;
  /** fetch aggregated fields from the table: "csgomarket_tokens" */
  csgomarket_tokens_aggregate: Csgomarket_Tokens_Aggregate;
  /** fetch data from the table: "csgomarket_tokens" using primary key columns */
  csgomarket_tokens_by_pk?: Maybe<Csgomarket_Tokens>;
  /** fetch data from the table in a streaming manner: "csgomarket_tokens" */
  csgomarket_tokens_stream: Array<Csgomarket_Tokens>;
  /** fetch data from the table: "currency_history" */
  currency_history: Array<Currency_History>;
  /** fetch aggregated fields from the table: "currency_history" */
  currency_history_aggregate: Currency_History_Aggregate;
  /** fetch data from the table: "currency_history" using primary key columns */
  currency_history_by_pk?: Maybe<Currency_History>;
  /** fetch data from the table in a streaming manner: "currency_history" */
  currency_history_stream: Array<Currency_History>;
  /** fetch data from the table: "currencys" */
  currencys: Array<Currencys>;
  /** fetch aggregated fields from the table: "currencys" */
  currencys_aggregate: Currencys_Aggregate;
  /** fetch data from the table: "currencys" using primary key columns */
  currencys_by_pk?: Maybe<Currencys>;
  /** fetch data from the table in a streaming manner: "currencys" */
  currencys_stream: Array<Currencys>;
  /** fetch data from the table: "daily_deposit_sum" */
  daily_deposit_sum: Array<Daily_Deposit_Sum>;
  /** fetch aggregated fields from the table: "daily_deposit_sum" */
  daily_deposit_sum_aggregate: Daily_Deposit_Sum_Aggregate;
  /** fetch data from the table: "daily_deposit_sum" using primary key columns */
  daily_deposit_sum_by_pk?: Maybe<Daily_Deposit_Sum>;
  /** fetch data from the table in a streaming manner: "daily_deposit_sum" */
  daily_deposit_sum_stream: Array<Daily_Deposit_Sum>;
  /** fetch data from the table: "emails_from_upgrade_question" */
  emails_from_upgrade_question: Array<Emails_From_Upgrade_Question>;
  /** fetch aggregated fields from the table: "emails_from_upgrade_question" */
  emails_from_upgrade_question_aggregate: Emails_From_Upgrade_Question_Aggregate;
  /** fetch data from the table: "emails_from_upgrade_question" using primary key columns */
  emails_from_upgrade_question_by_pk?: Maybe<Emails_From_Upgrade_Question>;
  /** fetch data from the table in a streaming manner: "emails_from_upgrade_question" */
  emails_from_upgrade_question_stream: Array<Emails_From_Upgrade_Question>;
  /** fetch data from the table: "execution_methods" */
  execution_methods: Array<Execution_Methods>;
  /** fetch aggregated fields from the table: "execution_methods" */
  execution_methods_aggregate: Execution_Methods_Aggregate;
  /** fetch data from the table: "execution_methods" using primary key columns */
  execution_methods_by_pk?: Maybe<Execution_Methods>;
  /** fetch data from the table in a streaming manner: "execution_methods" */
  execution_methods_stream: Array<Execution_Methods>;
  /** fetch data from the table: "faq_table" */
  faq_table: Array<Faq_Table>;
  /** fetch aggregated fields from the table: "faq_table" */
  faq_table_aggregate: Faq_Table_Aggregate;
  /** fetch data from the table: "faq_table" using primary key columns */
  faq_table_by_pk?: Maybe<Faq_Table>;
  /** fetch data from the table in a streaming manner: "faq_table" */
  faq_table_stream: Array<Faq_Table>;
  /** An array relationship */
  fixed_items_generator_boosters: Array<Fixed_Items_Generator_Boosters>;
  /** An aggregate relationship */
  fixed_items_generator_boosters_aggregate: Fixed_Items_Generator_Boosters_Aggregate;
  /** fetch data from the table: "fixed_items_generator_boosters" using primary key columns */
  fixed_items_generator_boosters_by_pk?: Maybe<Fixed_Items_Generator_Boosters>;
  /** fetch data from the table in a streaming manner: "fixed_items_generator_boosters" */
  fixed_items_generator_boosters_stream: Array<Fixed_Items_Generator_Boosters>;
  /** An array relationship */
  fixed_items_generator_items: Array<Fixed_Items_Generator_Items>;
  /** An aggregate relationship */
  fixed_items_generator_items_aggregate: Fixed_Items_Generator_Items_Aggregate;
  /** fetch data from the table: "fixed_items_generator_items" using primary key columns */
  fixed_items_generator_items_by_pk?: Maybe<Fixed_Items_Generator_Items>;
  /** fetch data from the table in a streaming manner: "fixed_items_generator_items" */
  fixed_items_generator_items_stream: Array<Fixed_Items_Generator_Items>;
  /** fetch data from the table: "fixed_items_generator_lootboxes" */
  fixed_items_generator_lootboxes: Array<Fixed_Items_Generator_Lootboxes>;
  /** fetch aggregated fields from the table: "fixed_items_generator_lootboxes" */
  fixed_items_generator_lootboxes_aggregate: Fixed_Items_Generator_Lootboxes_Aggregate;
  /** fetch data from the table: "fixed_items_generator_lootboxes" using primary key columns */
  fixed_items_generator_lootboxes_by_pk?: Maybe<Fixed_Items_Generator_Lootboxes>;
  /** fetch data from the table in a streaming manner: "fixed_items_generator_lootboxes" */
  fixed_items_generator_lootboxes_stream: Array<Fixed_Items_Generator_Lootboxes>;
  /** execute function "get_ticket_counts_by_type" which returns "ticket_counts_result" */
  get_ticket_counts_by_type: Array<Ticket_Counts_Result>;
  /** execute function "get_ticket_counts_by_type" and query aggregates on result of table type "ticket_counts_result" */
  get_ticket_counts_by_type_aggregate: Ticket_Counts_Result_Aggregate;
  /** An array relationship */
  giveaway_members: Array<Giveaway_Members>;
  /** An aggregate relationship */
  giveaway_members_aggregate: Giveaway_Members_Aggregate;
  /** fetch data from the table: "giveaway_members" using primary key columns */
  giveaway_members_by_pk?: Maybe<Giveaway_Members>;
  /** fetch data from the table: "giveaway_members_rating" */
  giveaway_members_rating: Array<Giveaway_Members_Rating>;
  /** fetch aggregated fields from the table: "giveaway_members_rating" */
  giveaway_members_rating_aggregate: Giveaway_Members_Rating_Aggregate;
  /** fetch data from the table in a streaming manner: "giveaway_members_rating" */
  giveaway_members_rating_stream: Array<Giveaway_Members_Rating>;
  /** fetch data from the table in a streaming manner: "giveaway_members" */
  giveaway_members_stream: Array<Giveaway_Members>;
  /** An array relationship */
  giveaway_params: Array<Giveaway_Params>;
  /** An aggregate relationship */
  giveaway_params_aggregate: Giveaway_Params_Aggregate;
  /** fetch data from the table: "giveaway_params" using primary key columns */
  giveaway_params_by_pk?: Maybe<Giveaway_Params>;
  /** fetch data from the table in a streaming manner: "giveaway_params" */
  giveaway_params_stream: Array<Giveaway_Params>;
  /** fetch data from the table: "giveaways" */
  giveaways: Array<Giveaways>;
  /** fetch aggregated fields from the table: "giveaways" */
  giveaways_aggregate: Giveaways_Aggregate;
  /** fetch data from the table: "giveaways" using primary key columns */
  giveaways_by_pk?: Maybe<Giveaways>;
  /** fetch data from the table in a streaming manner: "giveaways" */
  giveaways_stream: Array<Giveaways>;
  /** fetch data from the table: "group_items_csgomarket" */
  group_items_csgomarket: Array<Group_Items_Csgomarket>;
  /** fetch aggregated fields from the table: "group_items_csgomarket" */
  group_items_csgomarket_aggregate: Group_Items_Csgomarket_Aggregate;
  /** fetch data from the table: "group_items_csgomarket" using primary key columns */
  group_items_csgomarket_by_pk?: Maybe<Group_Items_Csgomarket>;
  /** fetch data from the table in a streaming manner: "group_items_csgomarket" */
  group_items_csgomarket_stream: Array<Group_Items_Csgomarket>;
  /** fetch data from the table: "history_group_items_csgomarket" */
  history_group_items_csgomarket: Array<History_Group_Items_Csgomarket>;
  /** fetch aggregated fields from the table: "history_group_items_csgomarket" */
  history_group_items_csgomarket_aggregate: History_Group_Items_Csgomarket_Aggregate;
  /** fetch data from the table: "history_group_items_csgomarket" using primary key columns */
  history_group_items_csgomarket_by_pk?: Maybe<History_Group_Items_Csgomarket>;
  /** fetch data from the table in a streaming manner: "history_group_items_csgomarket" */
  history_group_items_csgomarket_stream: Array<History_Group_Items_Csgomarket>;
  /** fetch data from the table: "history_items_csgomarket" */
  history_items_csgomarket: Array<History_Items_Csgomarket>;
  /** fetch aggregated fields from the table: "history_items_csgomarket" */
  history_items_csgomarket_aggregate: History_Items_Csgomarket_Aggregate;
  /** fetch data from the table: "history_items_csgomarket" using primary key columns */
  history_items_csgomarket_by_pk?: Maybe<History_Items_Csgomarket>;
  /** fetch data from the table in a streaming manner: "history_items_csgomarket" */
  history_items_csgomarket_stream: Array<History_Items_Csgomarket>;
  /** fetch data from the table: "item_out_transactions" */
  item_out_transactions: Array<Item_Out_Transactions>;
  /** fetch aggregated fields from the table: "item_out_transactions" */
  item_out_transactions_aggregate: Item_Out_Transactions_Aggregate;
  /** fetch data from the table: "item_out_transactions" using primary key columns */
  item_out_transactions_by_pk?: Maybe<Item_Out_Transactions>;
  /** fetch data from the table in a streaming manner: "item_out_transactions" */
  item_out_transactions_stream: Array<Item_Out_Transactions>;
  /** fetch data from the table: "items" */
  items: Array<Items>;
  /** fetch aggregated fields from the table: "items" */
  items_aggregate: Items_Aggregate;
  /** fetch data from the table: "items" using primary key columns */
  items_by_pk?: Maybe<Items>;
  /** fetch data from the table: "items_csgomarket" */
  items_csgomarket: Array<Items_Csgomarket>;
  /** fetch aggregated fields from the table: "items_csgomarket" */
  items_csgomarket_aggregate: Items_Csgomarket_Aggregate;
  /** fetch data from the table: "items_csgomarket" using primary key columns */
  items_csgomarket_by_pk?: Maybe<Items_Csgomarket>;
  /** fetch data from the table in a streaming manner: "items_csgomarket" */
  items_csgomarket_stream: Array<Items_Csgomarket>;
  /** fetch data from the table: "items_drops" */
  items_drops: Array<Items_Drops>;
  /** fetch aggregated fields from the table: "items_drops" */
  items_drops_aggregate: Items_Drops_Aggregate;
  /** fetch data from the table: "items_drops" using primary key columns */
  items_drops_by_pk?: Maybe<Items_Drops>;
  /** fetch data from the table in a streaming manner: "items_drops" */
  items_drops_stream: Array<Items_Drops>;
  /** fetch data from the table: "items_for_upgrade" */
  items_for_upgrade: Array<Items_For_Upgrade>;
  /** fetch aggregated fields from the table: "items_for_upgrade" */
  items_for_upgrade_aggregate: Items_For_Upgrade_Aggregate;
  /** fetch data from the table: "items_for_upgrade" using primary key columns */
  items_for_upgrade_by_pk?: Maybe<Items_For_Upgrade>;
  /** fetch data from the table in a streaming manner: "items_for_upgrade" */
  items_for_upgrade_stream: Array<Items_For_Upgrade>;
  /** fetch data from the table: "items_history" */
  items_history: Array<Items_History>;
  /** fetch aggregated fields from the table: "items_history" */
  items_history_aggregate: Items_History_Aggregate;
  /** fetch data from the table: "items_history" using primary key columns */
  items_history_by_pk?: Maybe<Items_History>;
  /** fetch data from the table in a streaming manner: "items_history" */
  items_history_stream: Array<Items_History>;
  /** fetch data from the table: "items_in_lootbox" */
  items_in_lootbox: Array<Items_In_Lootbox>;
  /** fetch aggregated fields from the table: "items_in_lootbox" */
  items_in_lootbox_aggregate: Items_In_Lootbox_Aggregate;
  /** fetch data from the table: "items_in_lootbox" using primary key columns */
  items_in_lootbox_by_pk?: Maybe<Items_In_Lootbox>;
  /** fetch data from the table in a streaming manner: "items_in_lootbox" */
  items_in_lootbox_stream: Array<Items_In_Lootbox>;
  /** fetch data from the table: "items_in_lootbox_view" */
  items_in_lootbox_view: Array<Items_In_Lootbox_View>;
  /** fetch aggregated fields from the table: "items_in_lootbox_view" */
  items_in_lootbox_view_aggregate: Items_In_Lootbox_View_Aggregate;
  /** fetch data from the table: "items_in_lootbox_view" using primary key columns */
  items_in_lootbox_view_by_pk?: Maybe<Items_In_Lootbox_View>;
  /** fetch data from the table in a streaming manner: "items_in_lootbox_view" */
  items_in_lootbox_view_stream: Array<Items_In_Lootbox_View>;
  /** fetch data from the table: "items_items_names" */
  items_items_names: Array<Items_Items_Names>;
  /** fetch aggregated fields from the table: "items_items_names" */
  items_items_names_aggregate: Items_Items_Names_Aggregate;
  /** fetch data from the table: "items_items_names" using primary key columns */
  items_items_names_by_pk?: Maybe<Items_Items_Names>;
  /** fetch data from the table in a streaming manner: "items_items_names" */
  items_items_names_stream: Array<Items_Items_Names>;
  /** fetch data from the table: "items_localization" */
  items_localization: Array<Items_Localization>;
  /** fetch aggregated fields from the table: "items_localization" */
  items_localization_aggregate: Items_Localization_Aggregate;
  /** fetch data from the table: "items_localization" using primary key columns */
  items_localization_by_pk?: Maybe<Items_Localization>;
  /** fetch data from the table in a streaming manner: "items_localization" */
  items_localization_stream: Array<Items_Localization>;
  /** fetch data from the table: "items_names" */
  items_names: Array<Items_Names>;
  /** fetch aggregated fields from the table: "items_names" */
  items_names_aggregate: Items_Names_Aggregate;
  /** fetch data from the table: "items_names" using primary key columns */
  items_names_by_pk?: Maybe<Items_Names>;
  /** fetch data from the table in a streaming manner: "items_names" */
  items_names_stream: Array<Items_Names>;
  /** fetch data from the table: "items_quality" */
  items_quality: Array<Items_Quality>;
  /** fetch aggregated fields from the table: "items_quality" */
  items_quality_aggregate: Items_Quality_Aggregate;
  /** fetch data from the table: "items_quality" using primary key columns */
  items_quality_by_pk?: Maybe<Items_Quality>;
  /** fetch data from the table: "items_quality_localization" */
  items_quality_localization: Array<Items_Quality_Localization>;
  /** fetch aggregated fields from the table: "items_quality_localization" */
  items_quality_localization_aggregate: Items_Quality_Localization_Aggregate;
  /** fetch data from the table: "items_quality_localization" using primary key columns */
  items_quality_localization_by_pk?: Maybe<Items_Quality_Localization>;
  /** fetch data from the table in a streaming manner: "items_quality_localization" */
  items_quality_localization_stream: Array<Items_Quality_Localization>;
  /** fetch data from the table in a streaming manner: "items_quality" */
  items_quality_stream: Array<Items_Quality>;
  /** fetch data from the table: "items_rarity" */
  items_rarity: Array<Items_Rarity>;
  /** fetch aggregated fields from the table: "items_rarity" */
  items_rarity_aggregate: Items_Rarity_Aggregate;
  /** fetch data from the table: "items_rarity" using primary key columns */
  items_rarity_by_pk?: Maybe<Items_Rarity>;
  /** fetch data from the table: "items_rarity_localization" */
  items_rarity_localization: Array<Items_Rarity_Localization>;
  /** fetch aggregated fields from the table: "items_rarity_localization" */
  items_rarity_localization_aggregate: Items_Rarity_Localization_Aggregate;
  /** fetch data from the table: "items_rarity_localization" using primary key columns */
  items_rarity_localization_by_pk?: Maybe<Items_Rarity_Localization>;
  /** fetch data from the table in a streaming manner: "items_rarity_localization" */
  items_rarity_localization_stream: Array<Items_Rarity_Localization>;
  /** fetch data from the table in a streaming manner: "items_rarity" */
  items_rarity_stream: Array<Items_Rarity>;
  /** fetch data from the table in a streaming manner: "items" */
  items_stream: Array<Items>;
  /** fetch data from the table: "items_type" */
  items_type: Array<Items_Type>;
  /** fetch aggregated fields from the table: "items_type" */
  items_type_aggregate: Items_Type_Aggregate;
  /** fetch data from the table: "items_type" using primary key columns */
  items_type_by_pk?: Maybe<Items_Type>;
  /** fetch data from the table: "items_type_localization" */
  items_type_localization: Array<Items_Type_Localization>;
  /** fetch aggregated fields from the table: "items_type_localization" */
  items_type_localization_aggregate: Items_Type_Localization_Aggregate;
  /** fetch data from the table: "items_type_localization" using primary key columns */
  items_type_localization_by_pk?: Maybe<Items_Type_Localization>;
  /** fetch data from the table in a streaming manner: "items_type_localization" */
  items_type_localization_stream: Array<Items_Type_Localization>;
  /** fetch data from the table in a streaming manner: "items_type" */
  items_type_stream: Array<Items_Type>;
  /** fetch data from the table: "items_weapon_localization" */
  items_weapon_localization: Array<Items_Weapon_Localization>;
  /** fetch aggregated fields from the table: "items_weapon_localization" */
  items_weapon_localization_aggregate: Items_Weapon_Localization_Aggregate;
  /** fetch data from the table: "items_weapon_localization" using primary key columns */
  items_weapon_localization_by_pk?: Maybe<Items_Weapon_Localization>;
  /** fetch data from the table in a streaming manner: "items_weapon_localization" */
  items_weapon_localization_stream: Array<Items_Weapon_Localization>;
  /** fetch data from the table: "items_weapons" */
  items_weapons: Array<Items_Weapons>;
  /** fetch aggregated fields from the table: "items_weapons" */
  items_weapons_aggregate: Items_Weapons_Aggregate;
  /** fetch data from the table: "items_weapons" using primary key columns */
  items_weapons_by_pk?: Maybe<Items_Weapons>;
  /** fetch data from the table in a streaming manner: "items_weapons" */
  items_weapons_stream: Array<Items_Weapons>;
  /** execute function "list_items_random" which returns "items" */
  list_items_random: Array<Items>;
  /** execute function "list_items_random" and query aggregates on result of table type "items" */
  list_items_random_aggregate: Items_Aggregate;
  /** fetch data from the table: "lootbox_booster_items" */
  lootbox_booster_items: Array<Lootbox_Booster_Items>;
  /** fetch aggregated fields from the table: "lootbox_booster_items" */
  lootbox_booster_items_aggregate: Lootbox_Booster_Items_Aggregate;
  /** fetch data from the table: "lootbox_booster_items" using primary key columns */
  lootbox_booster_items_by_pk?: Maybe<Lootbox_Booster_Items>;
  /** fetch data from the table in a streaming manner: "lootbox_booster_items" */
  lootbox_booster_items_stream: Array<Lootbox_Booster_Items>;
  /** fetch data from the table: "lootbox_booster_localization" */
  lootbox_booster_localization: Array<Lootbox_Booster_Localization>;
  /** fetch aggregated fields from the table: "lootbox_booster_localization" */
  lootbox_booster_localization_aggregate: Lootbox_Booster_Localization_Aggregate;
  /** fetch data from the table: "lootbox_booster_localization" using primary key columns */
  lootbox_booster_localization_by_pk?: Maybe<Lootbox_Booster_Localization>;
  /** fetch data from the table in a streaming manner: "lootbox_booster_localization" */
  lootbox_booster_localization_stream: Array<Lootbox_Booster_Localization>;
  /** fetch data from the table: "lootbox_category" */
  lootbox_category: Array<Lootbox_Category>;
  /** fetch aggregated fields from the table: "lootbox_category" */
  lootbox_category_aggregate: Lootbox_Category_Aggregate;
  /** fetch data from the table: "lootbox_category" using primary key columns */
  lootbox_category_by_pk?: Maybe<Lootbox_Category>;
  /** fetch data from the table in a streaming manner: "lootbox_category" */
  lootbox_category_stream: Array<Lootbox_Category>;
  /** An array relationship */
  lootbox_items_sets: Array<Lootbox_Items_Sets>;
  /** An aggregate relationship */
  lootbox_items_sets_aggregate: Lootbox_Items_Sets_Aggregate;
  /** fetch data from the table: "lootbox_items_sets" using primary key columns */
  lootbox_items_sets_by_pk?: Maybe<Lootbox_Items_Sets>;
  /** fetch data from the table in a streaming manner: "lootbox_items_sets" */
  lootbox_items_sets_stream: Array<Lootbox_Items_Sets>;
  /** fetch data from the table: "lootbox_service" */
  lootbox_service: Array<Lootbox_Service>;
  /** fetch aggregated fields from the table: "lootbox_service" */
  lootbox_service_aggregate: Lootbox_Service_Aggregate;
  /** fetch data from the table: "lootbox_service" using primary key columns */
  lootbox_service_by_pk?: Maybe<Lootbox_Service>;
  /** fetch data from the table in a streaming manner: "lootbox_service" */
  lootbox_service_stream: Array<Lootbox_Service>;
  /** An array relationship */
  lootboxes: Array<Lootboxes>;
  /** An aggregate relationship */
  lootboxes_aggregate: Lootboxes_Aggregate;
  /** fetch data from the table: "lootboxes_available_statuses" */
  lootboxes_available_statuses: Array<Lootboxes_Available_Statuses>;
  /** fetch aggregated fields from the table: "lootboxes_available_statuses" */
  lootboxes_available_statuses_aggregate: Lootboxes_Available_Statuses_Aggregate;
  /** fetch data from the table: "lootboxes_available_statuses" using primary key columns */
  lootboxes_available_statuses_by_pk?: Maybe<Lootboxes_Available_Statuses>;
  /** fetch data from the table in a streaming manner: "lootboxes_available_statuses" */
  lootboxes_available_statuses_stream: Array<Lootboxes_Available_Statuses>;
  /** fetch data from the table: "lootboxes_bonus" */
  lootboxes_bonus: Array<Lootboxes_Bonus>;
  /** fetch aggregated fields from the table: "lootboxes_bonus" */
  lootboxes_bonus_aggregate: Lootboxes_Bonus_Aggregate;
  /** fetch data from the table: "lootboxes_bonus" using primary key columns */
  lootboxes_bonus_by_pk?: Maybe<Lootboxes_Bonus>;
  /** fetch data from the table: "lootboxes_bonus_generator_items" */
  lootboxes_bonus_generator_items: Array<Lootboxes_Bonus_Generator_Items>;
  /** fetch aggregated fields from the table: "lootboxes_bonus_generator_items" */
  lootboxes_bonus_generator_items_aggregate: Lootboxes_Bonus_Generator_Items_Aggregate;
  /** fetch data from the table: "lootboxes_bonus_generator_items" using primary key columns */
  lootboxes_bonus_generator_items_by_pk?: Maybe<Lootboxes_Bonus_Generator_Items>;
  /** fetch data from the table in a streaming manner: "lootboxes_bonus_generator_items" */
  lootboxes_bonus_generator_items_stream: Array<Lootboxes_Bonus_Generator_Items>;
  /** fetch data from the table: "lootboxes_bonus_generator_params" */
  lootboxes_bonus_generator_params: Array<Lootboxes_Bonus_Generator_Params>;
  /** fetch aggregated fields from the table: "lootboxes_bonus_generator_params" */
  lootboxes_bonus_generator_params_aggregate: Lootboxes_Bonus_Generator_Params_Aggregate;
  /** fetch data from the table: "lootboxes_bonus_generator_params" using primary key columns */
  lootboxes_bonus_generator_params_by_pk?: Maybe<Lootboxes_Bonus_Generator_Params>;
  /** fetch data from the table in a streaming manner: "lootboxes_bonus_generator_params" */
  lootboxes_bonus_generator_params_stream: Array<Lootboxes_Bonus_Generator_Params>;
  /** fetch data from the table: "lootboxes_bonus_items" */
  lootboxes_bonus_items: Array<Lootboxes_Bonus_Items>;
  /** fetch aggregated fields from the table: "lootboxes_bonus_items" */
  lootboxes_bonus_items_aggregate: Lootboxes_Bonus_Items_Aggregate;
  /** fetch data from the table: "lootboxes_bonus_items" using primary key columns */
  lootboxes_bonus_items_by_pk?: Maybe<Lootboxes_Bonus_Items>;
  /** fetch data from the table in a streaming manner: "lootboxes_bonus_items" */
  lootboxes_bonus_items_stream: Array<Lootboxes_Bonus_Items>;
  /** fetch data from the table in a streaming manner: "lootboxes_bonus" */
  lootboxes_bonus_stream: Array<Lootboxes_Bonus>;
  /** fetch data from the table: "lootboxes_bonus_views" */
  lootboxes_bonus_views: Array<Lootboxes_Bonus_Views>;
  /** fetch aggregated fields from the table: "lootboxes_bonus_views" */
  lootboxes_bonus_views_aggregate: Lootboxes_Bonus_Views_Aggregate;
  /** fetch data from the table: "lootboxes_bonus_views" using primary key columns */
  lootboxes_bonus_views_by_pk?: Maybe<Lootboxes_Bonus_Views>;
  /** fetch data from the table in a streaming manner: "lootboxes_bonus_views" */
  lootboxes_bonus_views_stream: Array<Lootboxes_Bonus_Views>;
  /** fetch data from the table: "lootboxes" using primary key columns */
  lootboxes_by_pk?: Maybe<Lootboxes>;
  /** fetch data from the table: "lootboxes_dynamic_category" */
  lootboxes_dynamic_category: Array<Lootboxes_Dynamic_Category>;
  /** fetch aggregated fields from the table: "lootboxes_dynamic_category" */
  lootboxes_dynamic_category_aggregate: Lootboxes_Dynamic_Category_Aggregate;
  /** fetch data from the table: "lootboxes_dynamic_category" using primary key columns */
  lootboxes_dynamic_category_by_pk?: Maybe<Lootboxes_Dynamic_Category>;
  /** An array relationship */
  lootboxes_dynamic_category_lootboxes: Array<Lootboxes_Dynamic_Category_Lootboxes>;
  /** An aggregate relationship */
  lootboxes_dynamic_category_lootboxes_aggregate: Lootboxes_Dynamic_Category_Lootboxes_Aggregate;
  /** fetch data from the table: "lootboxes_dynamic_category_lootboxes" using primary key columns */
  lootboxes_dynamic_category_lootboxes_by_pk?: Maybe<Lootboxes_Dynamic_Category_Lootboxes>;
  /** fetch data from the table in a streaming manner: "lootboxes_dynamic_category_lootboxes" */
  lootboxes_dynamic_category_lootboxes_stream: Array<Lootboxes_Dynamic_Category_Lootboxes>;
  /** fetch data from the table in a streaming manner: "lootboxes_dynamic_category" */
  lootboxes_dynamic_category_stream: Array<Lootboxes_Dynamic_Category>;
  /** fetch data from the table: "lootboxes_free" */
  lootboxes_free: Array<Lootboxes_Free>;
  /** fetch aggregated fields from the table: "lootboxes_free" */
  lootboxes_free_aggregate: Lootboxes_Free_Aggregate;
  /** fetch data from the table: "lootboxes_free" using primary key columns */
  lootboxes_free_by_pk?: Maybe<Lootboxes_Free>;
  /** fetch data from the table: "lootboxes_free_category" */
  lootboxes_free_category: Array<Lootboxes_Free_Category>;
  /** fetch aggregated fields from the table: "lootboxes_free_category" */
  lootboxes_free_category_aggregate: Lootboxes_Free_Category_Aggregate;
  /** fetch data from the table: "lootboxes_free_category" using primary key columns */
  lootboxes_free_category_by_pk?: Maybe<Lootboxes_Free_Category>;
  /** fetch data from the table in a streaming manner: "lootboxes_free_category" */
  lootboxes_free_category_stream: Array<Lootboxes_Free_Category>;
  /** fetch data from the table: "lootboxes_free_generator_items" */
  lootboxes_free_generator_items: Array<Lootboxes_Free_Generator_Items>;
  /** fetch aggregated fields from the table: "lootboxes_free_generator_items" */
  lootboxes_free_generator_items_aggregate: Lootboxes_Free_Generator_Items_Aggregate;
  /** fetch data from the table: "lootboxes_free_generator_items" using primary key columns */
  lootboxes_free_generator_items_by_pk?: Maybe<Lootboxes_Free_Generator_Items>;
  /** fetch data from the table in a streaming manner: "lootboxes_free_generator_items" */
  lootboxes_free_generator_items_stream: Array<Lootboxes_Free_Generator_Items>;
  /** fetch data from the table: "lootboxes_free_generator_params" */
  lootboxes_free_generator_params: Array<Lootboxes_Free_Generator_Params>;
  /** fetch aggregated fields from the table: "lootboxes_free_generator_params" */
  lootboxes_free_generator_params_aggregate: Lootboxes_Free_Generator_Params_Aggregate;
  /** fetch data from the table: "lootboxes_free_generator_params" using primary key columns */
  lootboxes_free_generator_params_by_pk?: Maybe<Lootboxes_Free_Generator_Params>;
  /** fetch data from the table in a streaming manner: "lootboxes_free_generator_params" */
  lootboxes_free_generator_params_stream: Array<Lootboxes_Free_Generator_Params>;
  /** fetch data from the table: "lootboxes_free_items" */
  lootboxes_free_items: Array<Lootboxes_Free_Items>;
  /** fetch aggregated fields from the table: "lootboxes_free_items" */
  lootboxes_free_items_aggregate: Lootboxes_Free_Items_Aggregate;
  /** fetch data from the table: "lootboxes_free_items" using primary key columns */
  lootboxes_free_items_by_pk?: Maybe<Lootboxes_Free_Items>;
  /** fetch data from the table in a streaming manner: "lootboxes_free_items" */
  lootboxes_free_items_stream: Array<Lootboxes_Free_Items>;
  /** fetch data from the table in a streaming manner: "lootboxes_free" */
  lootboxes_free_stream: Array<Lootboxes_Free>;
  /** fetch data from the table: "lootboxes_free_views" */
  lootboxes_free_views: Array<Lootboxes_Free_Views>;
  /** fetch aggregated fields from the table: "lootboxes_free_views" */
  lootboxes_free_views_aggregate: Lootboxes_Free_Views_Aggregate;
  /** fetch data from the table: "lootboxes_free_views" using primary key columns */
  lootboxes_free_views_by_pk?: Maybe<Lootboxes_Free_Views>;
  /** fetch data from the table in a streaming manner: "lootboxes_free_views" */
  lootboxes_free_views_stream: Array<Lootboxes_Free_Views>;
  /** fetch data from the table: "lootboxes_generator_mechanics" */
  lootboxes_generator_mechanics: Array<Lootboxes_Generator_Mechanics>;
  /** fetch aggregated fields from the table: "lootboxes_generator_mechanics" */
  lootboxes_generator_mechanics_aggregate: Lootboxes_Generator_Mechanics_Aggregate;
  /** fetch data from the table: "lootboxes_generator_mechanics" using primary key columns */
  lootboxes_generator_mechanics_by_pk?: Maybe<Lootboxes_Generator_Mechanics>;
  /** fetch data from the table in a streaming manner: "lootboxes_generator_mechanics" */
  lootboxes_generator_mechanics_stream: Array<Lootboxes_Generator_Mechanics>;
  /** fetch data from the table: "lootboxes_generator_params" */
  lootboxes_generator_params: Array<Lootboxes_Generator_Params>;
  /** fetch aggregated fields from the table: "lootboxes_generator_params" */
  lootboxes_generator_params_aggregate: Lootboxes_Generator_Params_Aggregate;
  /** fetch data from the table: "lootboxes_generator_params" using primary key columns */
  lootboxes_generator_params_by_pk?: Maybe<Lootboxes_Generator_Params>;
  /** fetch data from the table in a streaming manner: "lootboxes_generator_params" */
  lootboxes_generator_params_stream: Array<Lootboxes_Generator_Params>;
  /** fetch data from the table: "lootboxes_history" */
  lootboxes_history: Array<Lootboxes_History>;
  /** fetch aggregated fields from the table: "lootboxes_history" */
  lootboxes_history_aggregate: Lootboxes_History_Aggregate;
  /** fetch data from the table: "lootboxes_history" using primary key columns */
  lootboxes_history_by_pk?: Maybe<Lootboxes_History>;
  /** fetch data from the table in a streaming manner: "lootboxes_history" */
  lootboxes_history_stream: Array<Lootboxes_History>;
  /** fetch data from the table: "lootboxes_items_names" */
  lootboxes_items_names: Array<Lootboxes_Items_Names>;
  /** fetch aggregated fields from the table: "lootboxes_items_names" */
  lootboxes_items_names_aggregate: Lootboxes_Items_Names_Aggregate;
  /** fetch data from the table: "lootboxes_items_names" using primary key columns */
  lootboxes_items_names_by_pk?: Maybe<Lootboxes_Items_Names>;
  /** fetch data from the table in a streaming manner: "lootboxes_items_names" */
  lootboxes_items_names_stream: Array<Lootboxes_Items_Names>;
  /** fetch data from the table in a streaming manner: "lootboxes" */
  lootboxes_stream: Array<Lootboxes>;
  /** fetch data from the table: "lootboxes_top_drop" */
  lootboxes_top_drop: Array<Lootboxes_Top_Drop>;
  /** fetch aggregated fields from the table: "lootboxes_top_drop" */
  lootboxes_top_drop_aggregate: Lootboxes_Top_Drop_Aggregate;
  /** fetch data from the table: "lootboxes_top_drop" using primary key columns */
  lootboxes_top_drop_by_pk?: Maybe<Lootboxes_Top_Drop>;
  /** fetch data from the table in a streaming manner: "lootboxes_top_drop" */
  lootboxes_top_drop_stream: Array<Lootboxes_Top_Drop>;
  /** fetch data from the table: "mail_autosending_types" */
  mail_autosending_types: Array<Mail_Autosending_Types>;
  /** fetch aggregated fields from the table: "mail_autosending_types" */
  mail_autosending_types_aggregate: Mail_Autosending_Types_Aggregate;
  /** fetch data from the table: "mail_autosending_types" using primary key columns */
  mail_autosending_types_by_pk?: Maybe<Mail_Autosending_Types>;
  /** fetch data from the table in a streaming manner: "mail_autosending_types" */
  mail_autosending_types_stream: Array<Mail_Autosending_Types>;
  /** fetch data from the table: "mail_bonuses" */
  mail_bonuses: Array<Mail_Bonuses>;
  /** fetch aggregated fields from the table: "mail_bonuses" */
  mail_bonuses_aggregate: Mail_Bonuses_Aggregate;
  /** fetch data from the table: "mail_bonuses" using primary key columns */
  mail_bonuses_by_pk?: Maybe<Mail_Bonuses>;
  /** An array relationship */
  mail_bonuses_offers: Array<Mail_Bonuses_Offers>;
  /** An aggregate relationship */
  mail_bonuses_offers_aggregate: Mail_Bonuses_Offers_Aggregate;
  /** fetch data from the table: "mail_bonuses_offers" using primary key columns */
  mail_bonuses_offers_by_pk?: Maybe<Mail_Bonuses_Offers>;
  /** fetch data from the table in a streaming manner: "mail_bonuses_offers" */
  mail_bonuses_offers_stream: Array<Mail_Bonuses_Offers>;
  /** fetch data from the table in a streaming manner: "mail_bonuses" */
  mail_bonuses_stream: Array<Mail_Bonuses>;
  /** fetch data from the table: "mail_mailing_callbacks" */
  mail_mailing_callbacks: Array<Mail_Mailing_Callbacks>;
  /** fetch aggregated fields from the table: "mail_mailing_callbacks" */
  mail_mailing_callbacks_aggregate: Mail_Mailing_Callbacks_Aggregate;
  /** fetch data from the table: "mail_mailing_callbacks" using primary key columns */
  mail_mailing_callbacks_by_pk?: Maybe<Mail_Mailing_Callbacks>;
  /** fetch data from the table in a streaming manner: "mail_mailing_callbacks" */
  mail_mailing_callbacks_stream: Array<Mail_Mailing_Callbacks>;
  /** fetch data from the table: "mail_mailing_lines" */
  mail_mailing_lines: Array<Mail_Mailing_Lines>;
  /** fetch aggregated fields from the table: "mail_mailing_lines" */
  mail_mailing_lines_aggregate: Mail_Mailing_Lines_Aggregate;
  /** fetch data from the table: "mail_mailing_lines" using primary key columns */
  mail_mailing_lines_by_pk?: Maybe<Mail_Mailing_Lines>;
  /** fetch data from the table in a streaming manner: "mail_mailing_lines" */
  mail_mailing_lines_stream: Array<Mail_Mailing_Lines>;
  /** fetch data from the table: "mail_mailing_lists" */
  mail_mailing_lists: Array<Mail_Mailing_Lists>;
  /** fetch aggregated fields from the table: "mail_mailing_lists" */
  mail_mailing_lists_aggregate: Mail_Mailing_Lists_Aggregate;
  /** fetch data from the table: "mail_mailing_lists" using primary key columns */
  mail_mailing_lists_by_pk?: Maybe<Mail_Mailing_Lists>;
  /** fetch data from the table in a streaming manner: "mail_mailing_lists" */
  mail_mailing_lists_stream: Array<Mail_Mailing_Lists>;
  /** fetch data from the table: "mail_mailing_scheduling" */
  mail_mailing_scheduling: Array<Mail_Mailing_Scheduling>;
  /** fetch aggregated fields from the table: "mail_mailing_scheduling" */
  mail_mailing_scheduling_aggregate: Mail_Mailing_Scheduling_Aggregate;
  /** fetch data from the table: "mail_mailing_scheduling" using primary key columns */
  mail_mailing_scheduling_by_pk?: Maybe<Mail_Mailing_Scheduling>;
  /** fetch data from the table in a streaming manner: "mail_mailing_scheduling" */
  mail_mailing_scheduling_stream: Array<Mail_Mailing_Scheduling>;
  /** fetch data from the table: "mail_mailing_sending_log" */
  mail_mailing_sending_log: Array<Mail_Mailing_Sending_Log>;
  /** fetch aggregated fields from the table: "mail_mailing_sending_log" */
  mail_mailing_sending_log_aggregate: Mail_Mailing_Sending_Log_Aggregate;
  /** fetch data from the table: "mail_mailing_sending_log" using primary key columns */
  mail_mailing_sending_log_by_pk?: Maybe<Mail_Mailing_Sending_Log>;
  /** fetch data from the table in a streaming manner: "mail_mailing_sending_log" */
  mail_mailing_sending_log_stream: Array<Mail_Mailing_Sending_Log>;
  /** fetch data from the table: "mail_mailing_triggers" */
  mail_mailing_triggers: Array<Mail_Mailing_Triggers>;
  /** fetch aggregated fields from the table: "mail_mailing_triggers" */
  mail_mailing_triggers_aggregate: Mail_Mailing_Triggers_Aggregate;
  /** fetch data from the table: "mail_mailing_triggers" using primary key columns */
  mail_mailing_triggers_by_pk?: Maybe<Mail_Mailing_Triggers>;
  /** An array relationship */
  mail_mailing_triggers_groups: Array<Mail_Mailing_Triggers_Groups>;
  /** An aggregate relationship */
  mail_mailing_triggers_groups_aggregate: Mail_Mailing_Triggers_Groups_Aggregate;
  /** fetch data from the table: "mail_mailing_triggers_groups" using primary key columns */
  mail_mailing_triggers_groups_by_pk?: Maybe<Mail_Mailing_Triggers_Groups>;
  /** fetch data from the table in a streaming manner: "mail_mailing_triggers_groups" */
  mail_mailing_triggers_groups_stream: Array<Mail_Mailing_Triggers_Groups>;
  /** fetch data from the table in a streaming manner: "mail_mailing_triggers" */
  mail_mailing_triggers_stream: Array<Mail_Mailing_Triggers>;
  /** fetch data from the table: "mail_templates" */
  mail_templates: Array<Mail_Templates>;
  /** fetch aggregated fields from the table: "mail_templates" */
  mail_templates_aggregate: Mail_Templates_Aggregate;
  /** fetch data from the table: "mail_templates" using primary key columns */
  mail_templates_by_pk?: Maybe<Mail_Templates>;
  /** fetch data from the table in a streaming manner: "mail_templates" */
  mail_templates_stream: Array<Mail_Templates>;
  /** fetch data from the table: "mainpage_counters" */
  mainpage_counters: Array<Mainpage_Counters>;
  /** fetch aggregated fields from the table: "mainpage_counters" */
  mainpage_counters_aggregate: Mainpage_Counters_Aggregate;
  /** fetch data from the table: "mainpage_counters" using primary key columns */
  mainpage_counters_by_pk?: Maybe<Mainpage_Counters>;
  /** fetch data from the table in a streaming manner: "mainpage_counters" */
  mainpage_counters_stream: Array<Mainpage_Counters>;
  /** fetch data from the table: "maintenance_mode" */
  maintenance_mode: Array<Maintenance_Mode>;
  /** fetch aggregated fields from the table: "maintenance_mode" */
  maintenance_mode_aggregate: Maintenance_Mode_Aggregate;
  /** fetch data from the table: "maintenance_mode" using primary key columns */
  maintenance_mode_by_pk?: Maybe<Maintenance_Mode>;
  /** fetch data from the table in a streaming manner: "maintenance_mode" */
  maintenance_mode_stream: Array<Maintenance_Mode>;
  /** fetch data from the table: "market_groups_conn" */
  market_groups_conn: Array<Market_Groups_Conn>;
  /** fetch aggregated fields from the table: "market_groups_conn" */
  market_groups_conn_aggregate: Market_Groups_Conn_Aggregate;
  /** fetch data from the table: "market_groups_conn" using primary key columns */
  market_groups_conn_by_pk?: Maybe<Market_Groups_Conn>;
  /** fetch data from the table in a streaming manner: "market_groups_conn" */
  market_groups_conn_stream: Array<Market_Groups_Conn>;
  /** fetch data from the table: "metas" */
  metas: Array<Metas>;
  /** fetch aggregated fields from the table: "metas" */
  metas_aggregate: Metas_Aggregate;
  /** fetch data from the table: "metas" using primary key columns */
  metas_by_pk?: Maybe<Metas>;
  /** fetch data from the table in a streaming manner: "metas" */
  metas_stream: Array<Metas>;
  /** fetch data from the table: "missions" */
  missions: Array<Missions>;
  /** fetch aggregated fields from the table: "missions" */
  missions_aggregate: Missions_Aggregate;
  /** fetch data from the table: "missions" using primary key columns */
  missions_by_pk?: Maybe<Missions>;
  /** fetch data from the table: "missions_onetime_bonus_case_claims" */
  missions_onetime_bonus_case_claims: Array<Missions_Onetime_Bonus_Case_Claims>;
  /** fetch aggregated fields from the table: "missions_onetime_bonus_case_claims" */
  missions_onetime_bonus_case_claims_aggregate: Missions_Onetime_Bonus_Case_Claims_Aggregate;
  /** fetch data from the table: "missions_onetime_bonus_case_claims" using primary key columns */
  missions_onetime_bonus_case_claims_by_pk?: Maybe<Missions_Onetime_Bonus_Case_Claims>;
  /** fetch data from the table in a streaming manner: "missions_onetime_bonus_case_claims" */
  missions_onetime_bonus_case_claims_stream: Array<Missions_Onetime_Bonus_Case_Claims>;
  /** fetch data from the table: "missions_onetime_params" */
  missions_onetime_params: Array<Missions_Onetime_Params>;
  /** fetch aggregated fields from the table: "missions_onetime_params" */
  missions_onetime_params_aggregate: Missions_Onetime_Params_Aggregate;
  /** fetch data from the table: "missions_onetime_params" using primary key columns */
  missions_onetime_params_by_pk?: Maybe<Missions_Onetime_Params>;
  /** fetch data from the table in a streaming manner: "missions_onetime_params" */
  missions_onetime_params_stream: Array<Missions_Onetime_Params>;
  /** An array relationship */
  missions_onetime_users_progress: Array<Missions_Onetime_Users_Progress>;
  /** An aggregate relationship */
  missions_onetime_users_progress_aggregate: Missions_Onetime_Users_Progress_Aggregate;
  /** fetch data from the table: "missions_onetime_users_progress" using primary key columns */
  missions_onetime_users_progress_by_pk?: Maybe<Missions_Onetime_Users_Progress>;
  /** fetch data from the table in a streaming manner: "missions_onetime_users_progress" */
  missions_onetime_users_progress_stream: Array<Missions_Onetime_Users_Progress>;
  /** fetch data from the table: "missions_permanent_params" */
  missions_permanent_params: Array<Missions_Permanent_Params>;
  /** fetch aggregated fields from the table: "missions_permanent_params" */
  missions_permanent_params_aggregate: Missions_Permanent_Params_Aggregate;
  /** fetch data from the table: "missions_permanent_params" using primary key columns */
  missions_permanent_params_by_pk?: Maybe<Missions_Permanent_Params>;
  /** fetch data from the table in a streaming manner: "missions_permanent_params" */
  missions_permanent_params_stream: Array<Missions_Permanent_Params>;
  /** fetch data from the table: "missions_permanent_user_progress" */
  missions_permanent_user_progress: Array<Missions_Permanent_User_Progress>;
  /** fetch aggregated fields from the table: "missions_permanent_user_progress" */
  missions_permanent_user_progress_aggregate: Missions_Permanent_User_Progress_Aggregate;
  /** fetch data from the table: "missions_permanent_user_progress" using primary key columns */
  missions_permanent_user_progress_by_pk?: Maybe<Missions_Permanent_User_Progress>;
  /** fetch data from the table in a streaming manner: "missions_permanent_user_progress" */
  missions_permanent_user_progress_stream: Array<Missions_Permanent_User_Progress>;
  /** fetch data from the table in a streaming manner: "missions" */
  missions_stream: Array<Missions>;
  /** An array relationship */
  missions_tags: Array<Missions_Tags>;
  /** An aggregate relationship */
  missions_tags_aggregate: Missions_Tags_Aggregate;
  /** fetch data from the table: "missions_tags" using primary key columns */
  missions_tags_by_pk?: Maybe<Missions_Tags>;
  /** fetch data from the table: "missions_tags_names" */
  missions_tags_names: Array<Missions_Tags_Names>;
  /** fetch aggregated fields from the table: "missions_tags_names" */
  missions_tags_names_aggregate: Missions_Tags_Names_Aggregate;
  /** fetch data from the table: "missions_tags_names" using primary key columns */
  missions_tags_names_by_pk?: Maybe<Missions_Tags_Names>;
  /** fetch data from the table in a streaming manner: "missions_tags_names" */
  missions_tags_names_stream: Array<Missions_Tags_Names>;
  /** fetch data from the table in a streaming manner: "missions_tags" */
  missions_tags_stream: Array<Missions_Tags>;
  /** fetch data from the table: "missions_vk_links" */
  missions_vk_links: Array<Missions_Vk_Links>;
  /** fetch aggregated fields from the table: "missions_vk_links" */
  missions_vk_links_aggregate: Missions_Vk_Links_Aggregate;
  /** fetch data from the table: "missions_vk_links" using primary key columns */
  missions_vk_links_by_pk?: Maybe<Missions_Vk_Links>;
  /** fetch data from the table in a streaming manner: "missions_vk_links" */
  missions_vk_links_stream: Array<Missions_Vk_Links>;
  /** fetch data from the table: "new_year_competition_boosters_rewards" */
  new_year_competition_boosters_rewards: Array<New_Year_Competition_Boosters_Rewards>;
  /** fetch aggregated fields from the table: "new_year_competition_boosters_rewards" */
  new_year_competition_boosters_rewards_aggregate: New_Year_Competition_Boosters_Rewards_Aggregate;
  /** fetch data from the table: "new_year_competition_boosters_rewards" using primary key columns */
  new_year_competition_boosters_rewards_by_pk?: Maybe<New_Year_Competition_Boosters_Rewards>;
  /** fetch data from the table in a streaming manner: "new_year_competition_boosters_rewards" */
  new_year_competition_boosters_rewards_stream: Array<New_Year_Competition_Boosters_Rewards>;
  /** An array relationship */
  new_year_competition_participants: Array<New_Year_Competition_Participants>;
  /** An aggregate relationship */
  new_year_competition_participants_aggregate: New_Year_Competition_Participants_Aggregate;
  /** fetch data from the table: "new_year_competition_participants" using primary key columns */
  new_year_competition_participants_by_pk?: Maybe<New_Year_Competition_Participants>;
  /** fetch data from the table: "new_year_competition_participants_performance" */
  new_year_competition_participants_performance: Array<New_Year_Competition_Participants_Performance>;
  /** fetch data from the table: "new_year_competition_participants_performance" using primary key columns */
  new_year_competition_participants_performance_by_pk?: Maybe<New_Year_Competition_Participants_Performance>;
  /** fetch data from the table in a streaming manner: "new_year_competition_participants_performance" */
  new_year_competition_participants_performance_stream: Array<New_Year_Competition_Participants_Performance>;
  /** fetch data from the table: "new_year_competition_participants_rating" */
  new_year_competition_participants_rating: Array<New_Year_Competition_Participants_Rating>;
  /** fetch aggregated fields from the table: "new_year_competition_participants_rating" */
  new_year_competition_participants_rating_aggregate: New_Year_Competition_Participants_Rating_Aggregate;
  /** fetch data from the table in a streaming manner: "new_year_competition_participants_rating" */
  new_year_competition_participants_rating_stream: Array<New_Year_Competition_Participants_Rating>;
  /** fetch data from the table in a streaming manner: "new_year_competition_participants" */
  new_year_competition_participants_stream: Array<New_Year_Competition_Participants>;
  /** fetch data from the table: "new_year_competition_teams" */
  new_year_competition_teams: Array<New_Year_Competition_Teams>;
  /** fetch aggregated fields from the table: "new_year_competition_teams" */
  new_year_competition_teams_aggregate: New_Year_Competition_Teams_Aggregate;
  /** fetch data from the table: "new_year_competition_teams" using primary key columns */
  new_year_competition_teams_by_pk?: Maybe<New_Year_Competition_Teams>;
  /** fetch data from the table in a streaming manner: "new_year_competition_teams" */
  new_year_competition_teams_stream: Array<New_Year_Competition_Teams>;
  /** fetch data from the table: "new_year_competition_winner_team" */
  new_year_competition_winner_team: Array<New_Year_Competition_Winner_Team>;
  /** fetch aggregated fields from the table: "new_year_competition_winner_team" */
  new_year_competition_winner_team_aggregate: New_Year_Competition_Winner_Team_Aggregate;
  /** fetch data from the table in a streaming manner: "new_year_competition_winner_team" */
  new_year_competition_winner_team_stream: Array<New_Year_Competition_Winner_Team>;
  /** fetch data from the table: "operation_types" */
  operation_types: Array<Operation_Types>;
  /** fetch aggregated fields from the table: "operation_types" */
  operation_types_aggregate: Operation_Types_Aggregate;
  /** fetch data from the table: "operation_types" using primary key columns */
  operation_types_by_pk?: Maybe<Operation_Types>;
  /** fetch data from the table in a streaming manner: "operation_types" */
  operation_types_stream: Array<Operation_Types>;
  /** fetch data from the table: "parsers_timestamp_log" */
  parsers_timestamp_log: Array<Parsers_Timestamp_Log>;
  /** fetch aggregated fields from the table: "parsers_timestamp_log" */
  parsers_timestamp_log_aggregate: Parsers_Timestamp_Log_Aggregate;
  /** fetch data from the table: "parsers_timestamp_log" using primary key columns */
  parsers_timestamp_log_by_pk?: Maybe<Parsers_Timestamp_Log>;
  /** fetch data from the table in a streaming manner: "parsers_timestamp_log" */
  parsers_timestamp_log_stream: Array<Parsers_Timestamp_Log>;
  /** fetch aggregated fields from the table: "new_year_competition_participants_performance" */
  participants_performance_aggregate: New_Year_Competition_Participants_Performance_Aggregate;
  /** An array relationship */
  partner_balance_transactions: Array<Partner_Balance_Transactions>;
  /** An aggregate relationship */
  partner_balance_transactions_aggregate: Partner_Balance_Transactions_Aggregate;
  /** fetch data from the table: "partner_balance_transactions" using primary key columns */
  partner_balance_transactions_by_pk?: Maybe<Partner_Balance_Transactions>;
  /** fetch data from the table in a streaming manner: "partner_balance_transactions" */
  partner_balance_transactions_stream: Array<Partner_Balance_Transactions>;
  /** fetch data from the table: "partner_offer_types" */
  partner_offer_types: Array<Partner_Offer_Types>;
  /** fetch aggregated fields from the table: "partner_offer_types" */
  partner_offer_types_aggregate: Partner_Offer_Types_Aggregate;
  /** fetch data from the table: "partner_offer_types" using primary key columns */
  partner_offer_types_by_pk?: Maybe<Partner_Offer_Types>;
  /** fetch data from the table in a streaming manner: "partner_offer_types" */
  partner_offer_types_stream: Array<Partner_Offer_Types>;
  /** fetch data from the table: "partner_params" */
  partner_params: Array<Partner_Params>;
  /** fetch aggregated fields from the table: "partner_params" */
  partner_params_aggregate: Partner_Params_Aggregate;
  /** fetch data from the table: "partner_params_audit" */
  partner_params_audit: Array<Partner_Params_Audit>;
  /** fetch aggregated fields from the table: "partner_params_audit" */
  partner_params_audit_aggregate: Partner_Params_Audit_Aggregate;
  /** fetch data from the table: "partner_params_audit" using primary key columns */
  partner_params_audit_by_pk?: Maybe<Partner_Params_Audit>;
  /** fetch data from the table in a streaming manner: "partner_params_audit" */
  partner_params_audit_stream: Array<Partner_Params_Audit>;
  /** fetch data from the table: "partner_params" using primary key columns */
  partner_params_by_pk?: Maybe<Partner_Params>;
  /** fetch data from the table in a streaming manner: "partner_params" */
  partner_params_stream: Array<Partner_Params>;
  /** fetch data from the table: "passport_info" */
  passport_info: Array<Passport_Info>;
  /** fetch aggregated fields from the table: "passport_info" */
  passport_info_aggregate: Passport_Info_Aggregate;
  /** fetch data from the table: "passport_info" using primary key columns */
  passport_info_by_pk?: Maybe<Passport_Info>;
  /** fetch data from the table in a streaming manner: "passport_info" */
  passport_info_stream: Array<Passport_Info>;
  /** fetch data from the table: "payment_methods" */
  payment_methods: Array<Payment_Methods>;
  /** fetch aggregated fields from the table: "payment_methods" */
  payment_methods_aggregate: Payment_Methods_Aggregate;
  /** fetch data from the table: "payment_methods" using primary key columns */
  payment_methods_by_pk?: Maybe<Payment_Methods>;
  /** fetch data from the table in a streaming manner: "payment_methods" */
  payment_methods_stream: Array<Payment_Methods>;
  /** fetch data from the table: "payment_methods_view" */
  payment_methods_view: Array<Payment_Methods_View>;
  /** fetch aggregated fields from the table: "payment_methods_view" */
  payment_methods_view_aggregate: Payment_Methods_View_Aggregate;
  /** fetch data from the table: "payment_methods_view" using primary key columns */
  payment_methods_view_by_pk?: Maybe<Payment_Methods_View>;
  /** fetch data from the table in a streaming manner: "payment_methods_view" */
  payment_methods_view_stream: Array<Payment_Methods_View>;
  /** fetch data from the table: "payment_providers" */
  payment_providers: Array<Payment_Providers>;
  /** fetch aggregated fields from the table: "payment_providers" */
  payment_providers_aggregate: Payment_Providers_Aggregate;
  /** fetch data from the table: "payment_providers" using primary key columns */
  payment_providers_by_pk?: Maybe<Payment_Providers>;
  /** fetch data from the table in a streaming manner: "payment_providers" */
  payment_providers_stream: Array<Payment_Providers>;
  /** fetch data from the table: "payment_recurring_tokens" */
  payment_recurring_tokens: Array<Payment_Recurring_Tokens>;
  /** fetch aggregated fields from the table: "payment_recurring_tokens" */
  payment_recurring_tokens_aggregate: Payment_Recurring_Tokens_Aggregate;
  /** fetch data from the table: "payment_recurring_tokens" using primary key columns */
  payment_recurring_tokens_by_pk?: Maybe<Payment_Recurring_Tokens>;
  /** fetch data from the table in a streaming manner: "payment_recurring_tokens" */
  payment_recurring_tokens_stream: Array<Payment_Recurring_Tokens>;
  /** fetch data from the table: "payment_requisites" */
  payment_requisites: Array<Payment_Requisites>;
  /** fetch aggregated fields from the table: "payment_requisites" */
  payment_requisites_aggregate: Payment_Requisites_Aggregate;
  /** fetch data from the table: "payment_requisites" using primary key columns */
  payment_requisites_by_pk?: Maybe<Payment_Requisites>;
  /** fetch data from the table in a streaming manner: "payment_requisites" */
  payment_requisites_stream: Array<Payment_Requisites>;
  /** fetch data from the table: "payment_system_1payment_transaction_requests" */
  payment_system_1payment_transaction_requests: Array<Payment_System_1payment_Transaction_Requests>;
  /** fetch aggregated fields from the table: "payment_system_1payment_transaction_requests" */
  payment_system_1payment_transaction_requests_aggregate: Payment_System_1payment_Transaction_Requests_Aggregate;
  /** fetch data from the table: "payment_system_1payment_transaction_requests" using primary key columns */
  payment_system_1payment_transaction_requests_by_pk?: Maybe<Payment_System_1payment_Transaction_Requests>;
  /** fetch data from the table in a streaming manner: "payment_system_1payment_transaction_requests" */
  payment_system_1payment_transaction_requests_stream: Array<Payment_System_1payment_Transaction_Requests>;
  /** fetch data from the table: "payment_system_free_kassa_callbacks" */
  payment_system_free_kassa_callbacks: Array<Payment_System_Free_Kassa_Callbacks>;
  /** fetch aggregated fields from the table: "payment_system_free_kassa_callbacks" */
  payment_system_free_kassa_callbacks_aggregate: Payment_System_Free_Kassa_Callbacks_Aggregate;
  /** fetch data from the table: "payment_system_free_kassa_callbacks" using primary key columns */
  payment_system_free_kassa_callbacks_by_pk?: Maybe<Payment_System_Free_Kassa_Callbacks>;
  /** fetch data from the table in a streaming manner: "payment_system_free_kassa_callbacks" */
  payment_system_free_kassa_callbacks_stream: Array<Payment_System_Free_Kassa_Callbacks>;
  /** fetch data from the table: "payment_system_free_kassa_transaction_requests" */
  payment_system_free_kassa_transaction_requests: Array<Payment_System_Free_Kassa_Transaction_Requests>;
  /** fetch aggregated fields from the table: "payment_system_free_kassa_transaction_requests" */
  payment_system_free_kassa_transaction_requests_aggregate: Payment_System_Free_Kassa_Transaction_Requests_Aggregate;
  /** fetch data from the table: "payment_system_free_kassa_transaction_requests" using primary key columns */
  payment_system_free_kassa_transaction_requests_by_pk?: Maybe<Payment_System_Free_Kassa_Transaction_Requests>;
  /** fetch data from the table in a streaming manner: "payment_system_free_kassa_transaction_requests" */
  payment_system_free_kassa_transaction_requests_stream: Array<Payment_System_Free_Kassa_Transaction_Requests>;
  /** fetch data from the table: "payment_system_main_callbacks" */
  payment_system_main_callbacks: Array<Payment_System_Main_Callbacks>;
  /** fetch aggregated fields from the table: "payment_system_main_callbacks" */
  payment_system_main_callbacks_aggregate: Payment_System_Main_Callbacks_Aggregate;
  /** fetch data from the table: "payment_system_main_callbacks" using primary key columns */
  payment_system_main_callbacks_by_pk?: Maybe<Payment_System_Main_Callbacks>;
  /** fetch data from the table in a streaming manner: "payment_system_main_callbacks" */
  payment_system_main_callbacks_stream: Array<Payment_System_Main_Callbacks>;
  /** fetch data from the table: "payment_system_main_transaction_requests" */
  payment_system_main_transaction_requests: Array<Payment_System_Main_Transaction_Requests>;
  /** fetch aggregated fields from the table: "payment_system_main_transaction_requests" */
  payment_system_main_transaction_requests_aggregate: Payment_System_Main_Transaction_Requests_Aggregate;
  /** fetch data from the table: "payment_system_main_transaction_requests" using primary key columns */
  payment_system_main_transaction_requests_by_pk?: Maybe<Payment_System_Main_Transaction_Requests>;
  /** fetch data from the table in a streaming manner: "payment_system_main_transaction_requests" */
  payment_system_main_transaction_requests_stream: Array<Payment_System_Main_Transaction_Requests>;
  /** fetch data from the table: "payment_systems_1payment_callbacks" */
  payment_systems_1payment_callbacks: Array<Payment_Systems_1payment_Callbacks>;
  /** fetch aggregated fields from the table: "payment_systems_1payment_callbacks" */
  payment_systems_1payment_callbacks_aggregate: Payment_Systems_1payment_Callbacks_Aggregate;
  /** fetch data from the table: "payment_systems_1payment_callbacks" using primary key columns */
  payment_systems_1payment_callbacks_by_pk?: Maybe<Payment_Systems_1payment_Callbacks>;
  /** fetch data from the table in a streaming manner: "payment_systems_1payment_callbacks" */
  payment_systems_1payment_callbacks_stream: Array<Payment_Systems_1payment_Callbacks>;
  /** fetch data from the table: "payment_systems_callbacks" */
  payment_systems_callbacks: Array<Payment_Systems_Callbacks>;
  /** fetch aggregated fields from the table: "payment_systems_callbacks" */
  payment_systems_callbacks_aggregate: Payment_Systems_Callbacks_Aggregate;
  /** fetch data from the table: "payment_systems_callbacks" using primary key columns */
  payment_systems_callbacks_by_pk?: Maybe<Payment_Systems_Callbacks>;
  /** fetch data from the table in a streaming manner: "payment_systems_callbacks" */
  payment_systems_callbacks_stream: Array<Payment_Systems_Callbacks>;
  /** fetch data from the table: "payment_systems_data" */
  payment_systems_data: Array<Payment_Systems_Data>;
  /** fetch aggregated fields from the table: "payment_systems_data" */
  payment_systems_data_aggregate: Payment_Systems_Data_Aggregate;
  /** fetch data from the table: "payment_systems_data" using primary key columns */
  payment_systems_data_by_pk?: Maybe<Payment_Systems_Data>;
  /** fetch data from the table in a streaming manner: "payment_systems_data" */
  payment_systems_data_stream: Array<Payment_Systems_Data>;
  /** fetch data from the table: "payment_systems_methods" */
  payment_systems_methods: Array<Payment_Systems_Methods>;
  /** fetch aggregated fields from the table: "payment_systems_methods" */
  payment_systems_methods_aggregate: Payment_Systems_Methods_Aggregate;
  /** fetch data from the table: "payment_systems_methods" using primary key columns */
  payment_systems_methods_by_pk?: Maybe<Payment_Systems_Methods>;
  /** fetch data from the table in a streaming manner: "payment_systems_methods" */
  payment_systems_methods_stream: Array<Payment_Systems_Methods>;
  /** fetch data from the table: "payment_systems_transaction_requests" */
  payment_systems_transaction_requests: Array<Payment_Systems_Transaction_Requests>;
  /** fetch aggregated fields from the table: "payment_systems_transaction_requests" */
  payment_systems_transaction_requests_aggregate: Payment_Systems_Transaction_Requests_Aggregate;
  /** fetch data from the table: "payment_systems_transaction_requests" using primary key columns */
  payment_systems_transaction_requests_by_pk?: Maybe<Payment_Systems_Transaction_Requests>;
  /** fetch data from the table in a streaming manner: "payment_systems_transaction_requests" */
  payment_systems_transaction_requests_stream: Array<Payment_Systems_Transaction_Requests>;
  /** fetch data from the table: "payment_transactions" */
  payment_transactions: Array<Payment_Transactions>;
  /** fetch aggregated fields from the table: "payment_transactions" */
  payment_transactions_aggregate: Payment_Transactions_Aggregate;
  /** fetch data from the table: "payment_transactions" using primary key columns */
  payment_transactions_by_pk?: Maybe<Payment_Transactions>;
  /** fetch data from the table in a streaming manner: "payment_transactions" */
  payment_transactions_stream: Array<Payment_Transactions>;
  /** fetch data from the table: "profile_items_names" */
  profile_items_names: Array<Profile_Items_Names>;
  /** fetch aggregated fields from the table: "profile_items_names" */
  profile_items_names_aggregate: Profile_Items_Names_Aggregate;
  /** fetch data from the table: "profile_items_names" using primary key columns */
  profile_items_names_by_pk?: Maybe<Profile_Items_Names>;
  /** fetch data from the table in a streaming manner: "profile_items_names" */
  profile_items_names_stream: Array<Profile_Items_Names>;
  /** fetch data from the table: "promo_lootboxes" */
  promo_lootboxes: Array<Promo_Lootboxes>;
  /** fetch aggregated fields from the table: "promo_lootboxes" */
  promo_lootboxes_aggregate: Promo_Lootboxes_Aggregate;
  /** fetch data from the table: "promo_lootboxes" using primary key columns */
  promo_lootboxes_by_pk?: Maybe<Promo_Lootboxes>;
  /** fetch data from the table in a streaming manner: "promo_lootboxes" */
  promo_lootboxes_stream: Array<Promo_Lootboxes>;
  /** fetch data from the table: "promo_users" */
  promo_users: Array<Promo_Users>;
  /** fetch aggregated fields from the table: "promo_users" */
  promo_users_aggregate: Promo_Users_Aggregate;
  /** fetch data from the table: "promo_users" using primary key columns */
  promo_users_by_pk?: Maybe<Promo_Users>;
  /** fetch data from the table in a streaming manner: "promo_users" */
  promo_users_stream: Array<Promo_Users>;
  /** fetch data from the table: "promo_values" */
  promo_values: Array<Promo_Values>;
  /** fetch aggregated fields from the table: "promo_values" */
  promo_values_aggregate: Promo_Values_Aggregate;
  /** fetch data from the table: "promo_values" using primary key columns */
  promo_values_by_pk?: Maybe<Promo_Values>;
  /** fetch data from the table in a streaming manner: "promo_values" */
  promo_values_stream: Array<Promo_Values>;
  /** An array relationship */
  promocode_activations: Array<Promocode_Activations>;
  /** An aggregate relationship */
  promocode_activations_aggregate: Promocode_Activations_Aggregate;
  /** fetch data from the table: "promocode_activations" using primary key columns */
  promocode_activations_by_pk?: Maybe<Promocode_Activations>;
  /** fetch data from the table in a streaming manner: "promocode_activations" */
  promocode_activations_stream: Array<Promocode_Activations>;
  /** fetch data from the table: "promocodes" */
  promocodes: Array<Promocodes>;
  /** fetch aggregated fields from the table: "promocodes" */
  promocodes_aggregate: Promocodes_Aggregate;
  /** fetch data from the table: "promocodes" using primary key columns */
  promocodes_by_pk?: Maybe<Promocodes>;
  /** fetch data from the table in a streaming manner: "promocodes" */
  promocodes_stream: Array<Promocodes>;
  /** fetch data from the table: "ps_types" */
  ps_types: Array<Ps_Types>;
  /** fetch aggregated fields from the table: "ps_types" */
  ps_types_aggregate: Ps_Types_Aggregate;
  /** fetch data from the table: "ps_types" using primary key columns */
  ps_types_by_pk?: Maybe<Ps_Types>;
  /** fetch data from the table in a streaming manner: "ps_types" */
  ps_types_stream: Array<Ps_Types>;
  /** An array relationship */
  qservice_data: Array<Qservice_Data>;
  /** An aggregate relationship */
  qservice_data_aggregate: Qservice_Data_Aggregate;
  /** fetch data from the table: "qservice_data" using primary key columns */
  qservice_data_by_pk?: Maybe<Qservice_Data>;
  /** fetch data from the table in a streaming manner: "qservice_data" */
  qservice_data_stream: Array<Qservice_Data>;
  /** fetch data from the table: "statistic_bonus_wheels_users_offers" */
  statistic_bonus_wheels_users_offers: Array<Statistic_Bonus_Wheels_Users_Offers>;
  /** fetch aggregated fields from the table: "statistic_bonus_wheels_users_offers" */
  statistic_bonus_wheels_users_offers_aggregate: Statistic_Bonus_Wheels_Users_Offers_Aggregate;
  /** fetch data from the table: "statistic_bonus_wheels_users_offers" using primary key columns */
  statistic_bonus_wheels_users_offers_by_pk?: Maybe<Statistic_Bonus_Wheels_Users_Offers>;
  /** fetch data from the table in a streaming manner: "statistic_bonus_wheels_users_offers" */
  statistic_bonus_wheels_users_offers_stream: Array<Statistic_Bonus_Wheels_Users_Offers>;
  /** fetch data from the table: "statistic_button_again_press" */
  statistic_button_again_press: Array<Statistic_Button_Again_Press>;
  /** fetch aggregated fields from the table: "statistic_button_again_press" */
  statistic_button_again_press_aggregate: Statistic_Button_Again_Press_Aggregate;
  /** fetch data from the table: "statistic_button_again_press" using primary key columns */
  statistic_button_again_press_by_pk?: Maybe<Statistic_Button_Again_Press>;
  /** fetch data from the table in a streaming manner: "statistic_button_again_press" */
  statistic_button_again_press_stream: Array<Statistic_Button_Again_Press>;
  /** fetch data from the table: "statistic_fast_roll_trigger" */
  statistic_fast_roll_trigger: Array<Statistic_Fast_Roll_Trigger>;
  /** fetch aggregated fields from the table: "statistic_fast_roll_trigger" */
  statistic_fast_roll_trigger_aggregate: Statistic_Fast_Roll_Trigger_Aggregate;
  /** fetch data from the table: "statistic_fast_roll_trigger" using primary key columns */
  statistic_fast_roll_trigger_by_pk?: Maybe<Statistic_Fast_Roll_Trigger>;
  /** fetch data from the table in a streaming manner: "statistic_fast_roll_trigger" */
  statistic_fast_roll_trigger_stream: Array<Statistic_Fast_Roll_Trigger>;
  /** fetch data from the table: "statistic_inventory_opens" */
  statistic_inventory_opens: Array<Statistic_Inventory_Opens>;
  /** fetch aggregated fields from the table: "statistic_inventory_opens" */
  statistic_inventory_opens_aggregate: Statistic_Inventory_Opens_Aggregate;
  /** fetch data from the table: "statistic_inventory_opens" using primary key columns */
  statistic_inventory_opens_by_pk?: Maybe<Statistic_Inventory_Opens>;
  /** fetch data from the table in a streaming manner: "statistic_inventory_opens" */
  statistic_inventory_opens_stream: Array<Statistic_Inventory_Opens>;
  /** fetch data from the table: "statistic_items_drops" */
  statistic_items_drops: Array<Statistic_Items_Drops>;
  /** fetch aggregated fields from the table: "statistic_items_drops" */
  statistic_items_drops_aggregate: Statistic_Items_Drops_Aggregate;
  /** fetch data from the table: "statistic_items_drops" using primary key columns */
  statistic_items_drops_by_pk?: Maybe<Statistic_Items_Drops>;
  /** fetch data from the table in a streaming manner: "statistic_items_drops" */
  statistic_items_drops_stream: Array<Statistic_Items_Drops>;
  /** fetch data from the table: "statistic_items_names" */
  statistic_items_names: Array<Statistic_Items_Names>;
  /** fetch aggregated fields from the table: "statistic_items_names" */
  statistic_items_names_aggregate: Statistic_Items_Names_Aggregate;
  /** fetch data from the table: "statistic_items_names" using primary key columns */
  statistic_items_names_by_pk?: Maybe<Statistic_Items_Names>;
  /** fetch data from the table in a streaming manner: "statistic_items_names" */
  statistic_items_names_stream: Array<Statistic_Items_Names>;
  /** fetch data from the table: "statistic_items_output_fail" */
  statistic_items_output_fail: Array<Statistic_Items_Output_Fail>;
  /** fetch aggregated fields from the table: "statistic_items_output_fail" */
  statistic_items_output_fail_aggregate: Statistic_Items_Output_Fail_Aggregate;
  /** fetch data from the table: "statistic_items_output_fail" using primary key columns */
  statistic_items_output_fail_by_pk?: Maybe<Statistic_Items_Output_Fail>;
  /** fetch data from the table in a streaming manner: "statistic_items_output_fail" */
  statistic_items_output_fail_stream: Array<Statistic_Items_Output_Fail>;
  /** fetch data from the table: "statistic_items_output_success" */
  statistic_items_output_success: Array<Statistic_Items_Output_Success>;
  /** fetch aggregated fields from the table: "statistic_items_output_success" */
  statistic_items_output_success_aggregate: Statistic_Items_Output_Success_Aggregate;
  /** fetch data from the table: "statistic_items_output_success" using primary key columns */
  statistic_items_output_success_by_pk?: Maybe<Statistic_Items_Output_Success>;
  /** fetch data from the table in a streaming manner: "statistic_items_output_success" */
  statistic_items_output_success_stream: Array<Statistic_Items_Output_Success>;
  /** fetch data from the table: "statistic_lootbox_opens" */
  statistic_lootbox_opens: Array<Statistic_Lootbox_Opens>;
  /** fetch aggregated fields from the table: "statistic_lootbox_opens" */
  statistic_lootbox_opens_aggregate: Statistic_Lootbox_Opens_Aggregate;
  /** fetch data from the table: "statistic_lootbox_opens" using primary key columns */
  statistic_lootbox_opens_by_pk?: Maybe<Statistic_Lootbox_Opens>;
  /** fetch data from the table in a streaming manner: "statistic_lootbox_opens" */
  statistic_lootbox_opens_stream: Array<Statistic_Lootbox_Opens>;
  /** fetch data from the table: "statistic_lootbox_views" */
  statistic_lootbox_views: Array<Statistic_Lootbox_Views>;
  /** fetch aggregated fields from the table: "statistic_lootbox_views" */
  statistic_lootbox_views_aggregate: Statistic_Lootbox_Views_Aggregate;
  /** fetch data from the table: "statistic_lootbox_views" using primary key columns */
  statistic_lootbox_views_by_pk?: Maybe<Statistic_Lootbox_Views>;
  /** fetch data from the table in a streaming manner: "statistic_lootbox_views" */
  statistic_lootbox_views_stream: Array<Statistic_Lootbox_Views>;
  /** fetch data from the table: "statistic_mainpage_visits" */
  statistic_mainpage_visits: Array<Statistic_Mainpage_Visits>;
  /** fetch aggregated fields from the table: "statistic_mainpage_visits" */
  statistic_mainpage_visits_aggregate: Statistic_Mainpage_Visits_Aggregate;
  /** fetch data from the table: "statistic_mainpage_visits" using primary key columns */
  statistic_mainpage_visits_by_pk?: Maybe<Statistic_Mainpage_Visits>;
  /** fetch data from the table in a streaming manner: "statistic_mainpage_visits" */
  statistic_mainpage_visits_stream: Array<Statistic_Mainpage_Visits>;
  /** fetch data from the table: "statistic_promocode_full_activations" */
  statistic_promocode_full_activations: Array<Statistic_Promocode_Full_Activations>;
  /** fetch aggregated fields from the table: "statistic_promocode_full_activations" */
  statistic_promocode_full_activations_aggregate: Statistic_Promocode_Full_Activations_Aggregate;
  /** fetch data from the table: "statistic_promocode_full_activations" using primary key columns */
  statistic_promocode_full_activations_by_pk?: Maybe<Statistic_Promocode_Full_Activations>;
  /** fetch data from the table in a streaming manner: "statistic_promocode_full_activations" */
  statistic_promocode_full_activations_stream: Array<Statistic_Promocode_Full_Activations>;
  /** fetch data from the table: "statistic_promocode_success_activations" */
  statistic_promocode_success_activations: Array<Statistic_Promocode_Success_Activations>;
  /** fetch aggregated fields from the table: "statistic_promocode_success_activations" */
  statistic_promocode_success_activations_aggregate: Statistic_Promocode_Success_Activations_Aggregate;
  /** fetch data from the table: "statistic_promocode_success_activations" using primary key columns */
  statistic_promocode_success_activations_by_pk?: Maybe<Statistic_Promocode_Success_Activations>;
  /** fetch data from the table in a streaming manner: "statistic_promocode_success_activations" */
  statistic_promocode_success_activations_stream: Array<Statistic_Promocode_Success_Activations>;
  /** fetch data from the table: "statistic_sell_all_items" */
  statistic_sell_all_items: Array<Statistic_Sell_All_Items>;
  /** fetch aggregated fields from the table: "statistic_sell_all_items" */
  statistic_sell_all_items_aggregate: Statistic_Sell_All_Items_Aggregate;
  /** fetch data from the table: "statistic_sell_all_items" using primary key columns */
  statistic_sell_all_items_by_pk?: Maybe<Statistic_Sell_All_Items>;
  /** fetch data from the table in a streaming manner: "statistic_sell_all_items" */
  statistic_sell_all_items_stream: Array<Statistic_Sell_All_Items>;
  /** fetch data from the table: "statistic_sell_item" */
  statistic_sell_item: Array<Statistic_Sell_Item>;
  /** fetch aggregated fields from the table: "statistic_sell_item" */
  statistic_sell_item_aggregate: Statistic_Sell_Item_Aggregate;
  /** fetch data from the table: "statistic_sell_item" using primary key columns */
  statistic_sell_item_by_pk?: Maybe<Statistic_Sell_Item>;
  /** fetch data from the table in a streaming manner: "statistic_sell_item" */
  statistic_sell_item_stream: Array<Statistic_Sell_Item>;
  /** fetch data from the table: "statistic_user_deposit_success_callbacks" */
  statistic_user_deposit_success_callbacks: Array<Statistic_User_Deposit_Success_Callbacks>;
  /** fetch aggregated fields from the table: "statistic_user_deposit_success_callbacks" */
  statistic_user_deposit_success_callbacks_aggregate: Statistic_User_Deposit_Success_Callbacks_Aggregate;
  /** fetch data from the table: "statistic_user_deposit_success_callbacks" using primary key columns */
  statistic_user_deposit_success_callbacks_by_pk?: Maybe<Statistic_User_Deposit_Success_Callbacks>;
  /** fetch data from the table in a streaming manner: "statistic_user_deposit_success_callbacks" */
  statistic_user_deposit_success_callbacks_stream: Array<Statistic_User_Deposit_Success_Callbacks>;
  /** fetch data from the table: "statistic_user_deposits_transaction_requests" */
  statistic_user_deposits_transaction_requests: Array<Statistic_User_Deposits_Transaction_Requests>;
  /** fetch aggregated fields from the table: "statistic_user_deposits_transaction_requests" */
  statistic_user_deposits_transaction_requests_aggregate: Statistic_User_Deposits_Transaction_Requests_Aggregate;
  /** fetch data from the table: "statistic_user_deposits_transaction_requests" using primary key columns */
  statistic_user_deposits_transaction_requests_by_pk?: Maybe<Statistic_User_Deposits_Transaction_Requests>;
  /** fetch data from the table in a streaming manner: "statistic_user_deposits_transaction_requests" */
  statistic_user_deposits_transaction_requests_stream: Array<Statistic_User_Deposits_Transaction_Requests>;
  /** fetch data from the table: "statistic_user_free_lootboxes_opens" */
  statistic_user_free_lootboxes_opens: Array<Statistic_User_Free_Lootboxes_Opens>;
  /** fetch aggregated fields from the table: "statistic_user_free_lootboxes_opens" */
  statistic_user_free_lootboxes_opens_aggregate: Statistic_User_Free_Lootboxes_Opens_Aggregate;
  /** fetch data from the table: "statistic_user_free_lootboxes_opens" using primary key columns */
  statistic_user_free_lootboxes_opens_by_pk?: Maybe<Statistic_User_Free_Lootboxes_Opens>;
  /** fetch data from the table in a streaming manner: "statistic_user_free_lootboxes_opens" */
  statistic_user_free_lootboxes_opens_stream: Array<Statistic_User_Free_Lootboxes_Opens>;
  /** fetch data from the table: "statistic_user_registration" */
  statistic_user_registration: Array<Statistic_User_Registration>;
  /** fetch aggregated fields from the table: "statistic_user_registration" */
  statistic_user_registration_aggregate: Statistic_User_Registration_Aggregate;
  /** fetch data from the table: "statistic_user_registration" using primary key columns */
  statistic_user_registration_by_pk?: Maybe<Statistic_User_Registration>;
  /** fetch data from the table in a streaming manner: "statistic_user_registration" */
  statistic_user_registration_stream: Array<Statistic_User_Registration>;
  /** fetch data from the table: "stocks" */
  stocks: Array<Stocks>;
  /** fetch aggregated fields from the table: "stocks" */
  stocks_aggregate: Stocks_Aggregate;
  /** fetch data from the table: "stocks" using primary key columns */
  stocks_by_pk?: Maybe<Stocks>;
  /** fetch data from the table in a streaming manner: "stocks" */
  stocks_stream: Array<Stocks>;
  /** fetch data from the table: "store_offer_badges" */
  store_offer_badges: Array<Store_Offer_Badges>;
  /** fetch aggregated fields from the table: "store_offer_badges" */
  store_offer_badges_aggregate: Store_Offer_Badges_Aggregate;
  /** fetch data from the table: "store_offer_badges" using primary key columns */
  store_offer_badges_by_pk?: Maybe<Store_Offer_Badges>;
  /** fetch data from the table in a streaming manner: "store_offer_badges" */
  store_offer_badges_stream: Array<Store_Offer_Badges>;
  /** fetch data from the table: "store_offer_boosters" */
  store_offer_boosters: Array<Store_Offer_Boosters>;
  /** fetch aggregated fields from the table: "store_offer_boosters" */
  store_offer_boosters_aggregate: Store_Offer_Boosters_Aggregate;
  /** fetch data from the table: "store_offer_boosters" using primary key columns */
  store_offer_boosters_by_pk?: Maybe<Store_Offer_Boosters>;
  /** fetch data from the table in a streaming manner: "store_offer_boosters" */
  store_offer_boosters_stream: Array<Store_Offer_Boosters>;
  /** fetch data from the table: "store_offer_coins" */
  store_offer_coins: Array<Store_Offer_Coins>;
  /** fetch aggregated fields from the table: "store_offer_coins" */
  store_offer_coins_aggregate: Store_Offer_Coins_Aggregate;
  /** fetch data from the table: "store_offer_coins" using primary key columns */
  store_offer_coins_by_pk?: Maybe<Store_Offer_Coins>;
  /** fetch data from the table in a streaming manner: "store_offer_coins" */
  store_offer_coins_stream: Array<Store_Offer_Coins>;
  /** fetch data from the table: "store_offer_lootboxes" */
  store_offer_lootboxes: Array<Store_Offer_Lootboxes>;
  /** fetch aggregated fields from the table: "store_offer_lootboxes" */
  store_offer_lootboxes_aggregate: Store_Offer_Lootboxes_Aggregate;
  /** fetch data from the table: "store_offer_lootboxes" using primary key columns */
  store_offer_lootboxes_by_pk?: Maybe<Store_Offer_Lootboxes>;
  /** fetch data from the table in a streaming manner: "store_offer_lootboxes" */
  store_offer_lootboxes_stream: Array<Store_Offer_Lootboxes>;
  /** fetch data from the table: "store_offer_sales" */
  store_offer_sales: Array<Store_Offer_Sales>;
  /** fetch aggregated fields from the table: "store_offer_sales" */
  store_offer_sales_aggregate: Store_Offer_Sales_Aggregate;
  /** fetch data from the table: "store_offer_sales" using primary key columns */
  store_offer_sales_by_pk?: Maybe<Store_Offer_Sales>;
  /** fetch data from the table in a streaming manner: "store_offer_sales" */
  store_offer_sales_stream: Array<Store_Offer_Sales>;
  /** fetch data from the table: "store_offer_skins" */
  store_offer_skins: Array<Store_Offer_Skins>;
  /** fetch aggregated fields from the table: "store_offer_skins" */
  store_offer_skins_aggregate: Store_Offer_Skins_Aggregate;
  /** fetch data from the table: "store_offer_skins" using primary key columns */
  store_offer_skins_by_pk?: Maybe<Store_Offer_Skins>;
  /** fetch data from the table in a streaming manner: "store_offer_skins" */
  store_offer_skins_stream: Array<Store_Offer_Skins>;
  /** fetch data from the table: "store_offer_tickets" */
  store_offer_tickets: Array<Store_Offer_Tickets>;
  /** fetch aggregated fields from the table: "store_offer_tickets" */
  store_offer_tickets_aggregate: Store_Offer_Tickets_Aggregate;
  /** fetch data from the table: "store_offer_tickets" using primary key columns */
  store_offer_tickets_by_pk?: Maybe<Store_Offer_Tickets>;
  /** fetch data from the table in a streaming manner: "store_offer_tickets" */
  store_offer_tickets_stream: Array<Store_Offer_Tickets>;
  /** fetch data from the table: "store_offer_types" */
  store_offer_types: Array<Store_Offer_Types>;
  /** fetch aggregated fields from the table: "store_offer_types" */
  store_offer_types_aggregate: Store_Offer_Types_Aggregate;
  /** fetch data from the table: "store_offer_types" using primary key columns */
  store_offer_types_by_pk?: Maybe<Store_Offer_Types>;
  /** fetch data from the table in a streaming manner: "store_offer_types" */
  store_offer_types_stream: Array<Store_Offer_Types>;
  /** fetch data from the table: "store_offers" */
  store_offers: Array<Store_Offers>;
  /** fetch aggregated fields from the table: "store_offers" */
  store_offers_aggregate: Store_Offers_Aggregate;
  /** fetch data from the table: "store_offers" using primary key columns */
  store_offers_by_pk?: Maybe<Store_Offers>;
  /** fetch data from the table in a streaming manner: "store_offers" */
  store_offers_stream: Array<Store_Offers>;
  /** fetch data from the table: "survey_data_links" */
  survey_data_links: Array<Survey_Data_Links>;
  /** fetch aggregated fields from the table: "survey_data_links" */
  survey_data_links_aggregate: Survey_Data_Links_Aggregate;
  /** fetch data from the table: "survey_data_links" using primary key columns */
  survey_data_links_by_pk?: Maybe<Survey_Data_Links>;
  /** fetch data from the table in a streaming manner: "survey_data_links" */
  survey_data_links_stream: Array<Survey_Data_Links>;
  /** fetch data from the table: "telegram_admin_params" */
  telegram_admin_params: Array<Telegram_Admin_Params>;
  /** fetch aggregated fields from the table: "telegram_admin_params" */
  telegram_admin_params_aggregate: Telegram_Admin_Params_Aggregate;
  /** fetch data from the table: "telegram_admin_params" using primary key columns */
  telegram_admin_params_by_pk?: Maybe<Telegram_Admin_Params>;
  /** fetch data from the table in a streaming manner: "telegram_admin_params" */
  telegram_admin_params_stream: Array<Telegram_Admin_Params>;
  /** fetch data from the table: "ticket_counts_result" */
  ticket_counts_result: Array<Ticket_Counts_Result>;
  /** fetch aggregated fields from the table: "ticket_counts_result" */
  ticket_counts_result_aggregate: Ticket_Counts_Result_Aggregate;
  /** fetch data from the table in a streaming manner: "ticket_counts_result" */
  ticket_counts_result_stream: Array<Ticket_Counts_Result>;
  /** fetch data from the table: "tickets_set_content" */
  tickets_set_content: Array<Tickets_Set_Content>;
  /** fetch aggregated fields from the table: "tickets_set_content" */
  tickets_set_content_aggregate: Tickets_Set_Content_Aggregate;
  /** fetch data from the table: "tickets_set_content" using primary key columns */
  tickets_set_content_by_pk?: Maybe<Tickets_Set_Content>;
  /** fetch data from the table in a streaming manner: "tickets_set_content" */
  tickets_set_content_stream: Array<Tickets_Set_Content>;
  /** fetch data from the table: "tickets_sets" */
  tickets_sets: Array<Tickets_Sets>;
  /** fetch aggregated fields from the table: "tickets_sets" */
  tickets_sets_aggregate: Tickets_Sets_Aggregate;
  /** fetch data from the table: "tickets_sets" using primary key columns */
  tickets_sets_by_pk?: Maybe<Tickets_Sets>;
  /** fetch data from the table in a streaming manner: "tickets_sets" */
  tickets_sets_stream: Array<Tickets_Sets>;
  /** fetch data from the table: "top_drop_strip" */
  top_drop_strip: Array<Top_Drop_Strip>;
  /** fetch aggregated fields from the table: "top_drop_strip" */
  top_drop_strip_aggregate: Top_Drop_Strip_Aggregate;
  /** fetch data from the table: "top_drop_strip" using primary key columns */
  top_drop_strip_by_pk?: Maybe<Top_Drop_Strip>;
  /** fetch data from the table in a streaming manner: "top_drop_strip" */
  top_drop_strip_stream: Array<Top_Drop_Strip>;
  /** fetch data from the table: "transaction" */
  transaction: Array<Transaction>;
  /** fetch aggregated fields from the table: "transaction" */
  transaction_aggregate: Transaction_Aggregate;
  /** fetch data from the table: "transaction" using primary key columns */
  transaction_by_pk?: Maybe<Transaction>;
  /** fetch data from the table in a streaming manner: "transaction" */
  transaction_stream: Array<Transaction>;
  /** fetch data from the table: "url_mappings" */
  url_mappings: Array<Url_Mappings>;
  /** fetch aggregated fields from the table: "url_mappings" */
  url_mappings_aggregate: Url_Mappings_Aggregate;
  /** fetch data from the table: "url_mappings" using primary key columns */
  url_mappings_by_pk?: Maybe<Url_Mappings>;
  /** fetch data from the table in a streaming manner: "url_mappings" */
  url_mappings_stream: Array<Url_Mappings>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user_account" */
  user_account: Array<User_Account>;
  /** fetch aggregated fields from the table: "user_account" */
  user_account_aggregate: User_Account_Aggregate;
  /** fetch data from the table: "user_account" using primary key columns */
  user_account_by_pk?: Maybe<User_Account>;
  /** fetch data from the table in a streaming manner: "user_account" */
  user_account_stream: Array<User_Account>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user_auth" */
  user_auth: Array<User_Auth>;
  /** fetch aggregated fields from the table: "user_auth" */
  user_auth_aggregate: User_Auth_Aggregate;
  /** fetch data from the table: "user_auth" using primary key columns */
  user_auth_by_pk?: Maybe<User_Auth>;
  /** fetch data from the table in a streaming manner: "user_auth" */
  user_auth_stream: Array<User_Auth>;
  /** fetch data from the table: "user_bonus_wallet" */
  user_bonus_wallet: Array<User_Bonus_Wallet>;
  /** fetch aggregated fields from the table: "user_bonus_wallet" */
  user_bonus_wallet_aggregate: User_Bonus_Wallet_Aggregate;
  /** fetch data from the table: "user_bonus_wallet" using primary key columns */
  user_bonus_wallet_by_pk?: Maybe<User_Bonus_Wallet>;
  /** fetch data from the table in a streaming manner: "user_bonus_wallet" */
  user_bonus_wallet_stream: Array<User_Bonus_Wallet>;
  /** An array relationship */
  user_bonus_wallet_transactions: Array<User_Bonus_Wallet_Transactions>;
  /** An aggregate relationship */
  user_bonus_wallet_transactions_aggregate: User_Bonus_Wallet_Transactions_Aggregate;
  /** fetch data from the table: "user_bonus_wallet_transactions" using primary key columns */
  user_bonus_wallet_transactions_by_pk?: Maybe<User_Bonus_Wallet_Transactions>;
  /** fetch data from the table in a streaming manner: "user_bonus_wallet_transactions" */
  user_bonus_wallet_transactions_stream: Array<User_Bonus_Wallet_Transactions>;
  /** An array relationship */
  user_boosters_in_inventory: Array<User_Boosters_In_Inventory>;
  /** An aggregate relationship */
  user_boosters_in_inventory_aggregate: User_Boosters_In_Inventory_Aggregate;
  /** fetch data from the table: "user_boosters_in_inventory" using primary key columns */
  user_boosters_in_inventory_by_pk?: Maybe<User_Boosters_In_Inventory>;
  /** fetch data from the table in a streaming manner: "user_boosters_in_inventory" */
  user_boosters_in_inventory_stream: Array<User_Boosters_In_Inventory>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_contacts" */
  user_contacts: Array<User_Contacts>;
  /** fetch aggregated fields from the table: "user_contacts" */
  user_contacts_aggregate: User_Contacts_Aggregate;
  /** fetch data from the table: "user_contacts" using primary key columns */
  user_contacts_by_pk?: Maybe<User_Contacts>;
  /** fetch data from the table in a streaming manner: "user_contacts" */
  user_contacts_stream: Array<User_Contacts>;
  /** fetch data from the table: "user_correct_answers_rewards" */
  user_correct_answers_rewards: Array<User_Correct_Answers_Rewards>;
  /** fetch aggregated fields from the table: "user_correct_answers_rewards" */
  user_correct_answers_rewards_aggregate: User_Correct_Answers_Rewards_Aggregate;
  /** fetch data from the table: "user_correct_answers_rewards" using primary key columns */
  user_correct_answers_rewards_by_pk?: Maybe<User_Correct_Answers_Rewards>;
  /** fetch data from the table in a streaming manner: "user_correct_answers_rewards" */
  user_correct_answers_rewards_stream: Array<User_Correct_Answers_Rewards>;
  /** fetch data from the table: "user_daily_coins_claims" */
  user_daily_coins_claims: Array<User_Daily_Coins_Claims>;
  /** fetch aggregated fields from the table: "user_daily_coins_claims" */
  user_daily_coins_claims_aggregate: User_Daily_Coins_Claims_Aggregate;
  /** fetch data from the table: "user_daily_coins_claims" using primary key columns */
  user_daily_coins_claims_by_pk?: Maybe<User_Daily_Coins_Claims>;
  /** fetch data from the table in a streaming manner: "user_daily_coins_claims" */
  user_daily_coins_claims_stream: Array<User_Daily_Coins_Claims>;
  /** fetch data from the table: "user_daily_reward_counts" */
  user_daily_reward_counts: Array<User_Daily_Reward_Counts>;
  /** fetch aggregated fields from the table: "user_daily_reward_counts" */
  user_daily_reward_counts_aggregate: User_Daily_Reward_Counts_Aggregate;
  /** fetch data from the table: "user_daily_reward_counts" using primary key columns */
  user_daily_reward_counts_by_pk?: Maybe<User_Daily_Reward_Counts>;
  /** fetch data from the table in a streaming manner: "user_daily_reward_counts" */
  user_daily_reward_counts_stream: Array<User_Daily_Reward_Counts>;
  /** fetch data from the table: "user_email_verification_data" */
  user_email_verification_data: Array<User_Email_Verification_Data>;
  /** fetch aggregated fields from the table: "user_email_verification_data" */
  user_email_verification_data_aggregate: User_Email_Verification_Data_Aggregate;
  /** fetch data from the table: "user_email_verification_data" using primary key columns */
  user_email_verification_data_by_pk?: Maybe<User_Email_Verification_Data>;
  /** fetch data from the table in a streaming manner: "user_email_verification_data" */
  user_email_verification_data_stream: Array<User_Email_Verification_Data>;
  /** fetch data from the table: "user_events" */
  user_events: Array<User_Events>;
  /** fetch aggregated fields from the table: "user_events" */
  user_events_aggregate: User_Events_Aggregate;
  /** fetch data from the table: "user_events" using primary key columns */
  user_events_by_pk?: Maybe<User_Events>;
  /** fetch data from the table in a streaming manner: "user_events" */
  user_events_stream: Array<User_Events>;
  /** An array relationship */
  user_got_bonus: Array<User_Got_Bonus>;
  /** An aggregate relationship */
  user_got_bonus_aggregate: User_Got_Bonus_Aggregate;
  /** fetch data from the table: "user_got_bonus" using primary key columns */
  user_got_bonus_by_pk?: Maybe<User_Got_Bonus>;
  /** fetch data from the table in a streaming manner: "user_got_bonus" */
  user_got_bonus_stream: Array<User_Got_Bonus>;
  /** fetch data from the table: "user_inventory" */
  user_inventory: Array<User_Inventory>;
  /** fetch aggregated fields from the table: "user_inventory" */
  user_inventory_aggregate: User_Inventory_Aggregate;
  /** fetch data from the table: "user_inventory" using primary key columns */
  user_inventory_by_pk?: Maybe<User_Inventory>;
  /** fetch data from the table in a streaming manner: "user_inventory" */
  user_inventory_stream: Array<User_Inventory>;
  /** fetch data from the table: "user_inventory_transactions" */
  user_inventory_transactions: Array<User_Inventory_Transactions>;
  /** fetch aggregated fields from the table: "user_inventory_transactions" */
  user_inventory_transactions_aggregate: User_Inventory_Transactions_Aggregate;
  /** fetch data from the table: "user_inventory_transactions" using primary key columns */
  user_inventory_transactions_by_pk?: Maybe<User_Inventory_Transactions>;
  /** fetch data from the table in a streaming manner: "user_inventory_transactions" */
  user_inventory_transactions_stream: Array<User_Inventory_Transactions>;
  /** fetch data from the table: "user_items_in_inventory" */
  user_items_in_inventory: Array<User_Items_In_Inventory>;
  /** fetch aggregated fields from the table: "user_items_in_inventory" */
  user_items_in_inventory_aggregate: User_Items_In_Inventory_Aggregate;
  /** fetch data from the table: "user_items_in_inventory" using primary key columns */
  user_items_in_inventory_by_pk?: Maybe<User_Items_In_Inventory>;
  /** fetch data from the table in a streaming manner: "user_items_in_inventory" */
  user_items_in_inventory_stream: Array<User_Items_In_Inventory>;
  /** fetch data from the table: "user_lootbox_opens_count" */
  user_lootbox_opens_count: Array<User_Lootbox_Opens_Count>;
  /** fetch aggregated fields from the table: "user_lootbox_opens_count" */
  user_lootbox_opens_count_aggregate: User_Lootbox_Opens_Count_Aggregate;
  /** fetch data from the table: "user_lootbox_opens_count" using primary key columns */
  user_lootbox_opens_count_by_pk?: Maybe<User_Lootbox_Opens_Count>;
  /** fetch data from the table in a streaming manner: "user_lootbox_opens_count" */
  user_lootbox_opens_count_stream: Array<User_Lootbox_Opens_Count>;
  /** fetch data from the table: "user_marking" */
  user_marking: Array<User_Marking>;
  /** fetch aggregated fields from the table: "user_marking" */
  user_marking_aggregate: User_Marking_Aggregate;
  /** fetch data from the table: "user_marking" using primary key columns */
  user_marking_by_pk?: Maybe<User_Marking>;
  /** fetch data from the table in a streaming manner: "user_marking" */
  user_marking_stream: Array<User_Marking>;
  /** fetch data from the table: "user_markings" */
  user_markings: Array<User_Markings>;
  /** fetch aggregated fields from the table: "user_markings" */
  user_markings_aggregate: User_Markings_Aggregate;
  /** fetch data from the table: "user_markings" using primary key columns */
  user_markings_by_pk?: Maybe<User_Markings>;
  /** fetch data from the table in a streaming manner: "user_markings" */
  user_markings_stream: Array<User_Markings>;
  /** fetch data from the table: "user_output_limits" */
  user_output_limits: Array<User_Output_Limits>;
  /** fetch aggregated fields from the table: "user_output_limits" */
  user_output_limits_aggregate: User_Output_Limits_Aggregate;
  /** fetch data from the table: "user_output_limits" using primary key columns */
  user_output_limits_by_pk?: Maybe<User_Output_Limits>;
  /** fetch data from the table in a streaming manner: "user_output_limits" */
  user_output_limits_stream: Array<User_Output_Limits>;
  /** fetch data from the table: "user_profile" */
  user_profile: Array<User_Profile>;
  /** fetch aggregated fields from the table: "user_profile" */
  user_profile_aggregate: User_Profile_Aggregate;
  /** fetch data from the table: "user_profile" using primary key columns */
  user_profile_by_pk?: Maybe<User_Profile>;
  /** fetch data from the table in a streaming manner: "user_profile" */
  user_profile_stream: Array<User_Profile>;
  /** An array relationship */
  user_push_tokens: Array<User_Push_Tokens>;
  /** An aggregate relationship */
  user_push_tokens_aggregate: User_Push_Tokens_Aggregate;
  /** fetch data from the table: "user_push_tokens" using primary key columns */
  user_push_tokens_by_pk?: Maybe<User_Push_Tokens>;
  /** fetch data from the table in a streaming manner: "user_push_tokens" */
  user_push_tokens_stream: Array<User_Push_Tokens>;
  /** fetch data from the table: "user_push_topics" */
  user_push_topics: Array<User_Push_Topics>;
  /** fetch aggregated fields from the table: "user_push_topics" */
  user_push_topics_aggregate: User_Push_Topics_Aggregate;
  /** fetch data from the table: "user_push_topics" using primary key columns */
  user_push_topics_by_pk?: Maybe<User_Push_Topics>;
  /** fetch data from the table in a streaming manner: "user_push_topics" */
  user_push_topics_stream: Array<User_Push_Topics>;
  /** fetch data from the table: "user_referal_levels_params" */
  user_referal_levels_params: Array<User_Referal_Levels_Params>;
  /** fetch aggregated fields from the table: "user_referal_levels_params" */
  user_referal_levels_params_aggregate: User_Referal_Levels_Params_Aggregate;
  /** fetch data from the table: "user_referal_levels_params" using primary key columns */
  user_referal_levels_params_by_pk?: Maybe<User_Referal_Levels_Params>;
  /** fetch data from the table in a streaming manner: "user_referal_levels_params" */
  user_referal_levels_params_stream: Array<User_Referal_Levels_Params>;
  /** fetch data from the table: "user_referal_params" */
  user_referal_params: Array<User_Referal_Params>;
  /** fetch aggregated fields from the table: "user_referal_params" */
  user_referal_params_aggregate: User_Referal_Params_Aggregate;
  /** fetch data from the table: "user_referal_params" using primary key columns */
  user_referal_params_by_pk?: Maybe<User_Referal_Params>;
  /** fetch data from the table in a streaming manner: "user_referal_params" */
  user_referal_params_stream: Array<User_Referal_Params>;
  /** fetch data from the table: "user_referals" */
  user_referals: Array<User_Referals>;
  /** fetch aggregated fields from the table: "user_referals" */
  user_referals_aggregate: User_Referals_Aggregate;
  /** fetch data from the table: "user_referals_balance_transactions" */
  user_referals_balance_transactions: Array<User_Referals_Balance_Transactions>;
  /** fetch aggregated fields from the table: "user_referals_balance_transactions" */
  user_referals_balance_transactions_aggregate: User_Referals_Balance_Transactions_Aggregate;
  /** fetch data from the table: "user_referals_balance_transactions" using primary key columns */
  user_referals_balance_transactions_by_pk?: Maybe<User_Referals_Balance_Transactions>;
  /** fetch data from the table in a streaming manner: "user_referals_balance_transactions" */
  user_referals_balance_transactions_stream: Array<User_Referals_Balance_Transactions>;
  /** fetch data from the table: "user_referals_bonus_history" */
  user_referals_bonus_history: Array<User_Referals_Bonus_History>;
  /** fetch aggregated fields from the table: "user_referals_bonus_history" */
  user_referals_bonus_history_aggregate: User_Referals_Bonus_History_Aggregate;
  /** fetch data from the table: "user_referals_bonus_history" using primary key columns */
  user_referals_bonus_history_by_pk?: Maybe<User_Referals_Bonus_History>;
  /** fetch data from the table in a streaming manner: "user_referals_bonus_history" */
  user_referals_bonus_history_stream: Array<User_Referals_Bonus_History>;
  /** fetch data from the table: "user_referals" using primary key columns */
  user_referals_by_pk?: Maybe<User_Referals>;
  /** fetch data from the table in a streaming manner: "user_referals" */
  user_referals_stream: Array<User_Referals>;
  /** fetch data from the table: "user_referral" */
  user_referral: Array<User_Referral>;
  /** fetch aggregated fields from the table: "user_referral" */
  user_referral_aggregate: User_Referral_Aggregate;
  /** fetch data from the table: "user_referral_association" */
  user_referral_association: Array<User_Referral_Association>;
  /** fetch aggregated fields from the table: "user_referral_association" */
  user_referral_association_aggregate: User_Referral_Association_Aggregate;
  /** fetch data from the table: "user_referral_association" using primary key columns */
  user_referral_association_by_pk?: Maybe<User_Referral_Association>;
  /** fetch data from the table in a streaming manner: "user_referral_association" */
  user_referral_association_stream: Array<User_Referral_Association>;
  /** fetch data from the table: "user_referral_bonus_type" */
  user_referral_bonus_type: Array<User_Referral_Bonus_Type>;
  /** fetch aggregated fields from the table: "user_referral_bonus_type" */
  user_referral_bonus_type_aggregate: User_Referral_Bonus_Type_Aggregate;
  /** fetch data from the table: "user_referral_bonus_type" using primary key columns */
  user_referral_bonus_type_by_pk?: Maybe<User_Referral_Bonus_Type>;
  /** fetch data from the table in a streaming manner: "user_referral_bonus_type" */
  user_referral_bonus_type_stream: Array<User_Referral_Bonus_Type>;
  /** fetch data from the table: "user_referral" using primary key columns */
  user_referral_by_pk?: Maybe<User_Referral>;
  /** fetch data from the table: "user_referral_levels_params" */
  user_referral_levels_params: Array<User_Referral_Levels_Params>;
  /** fetch aggregated fields from the table: "user_referral_levels_params" */
  user_referral_levels_params_aggregate: User_Referral_Levels_Params_Aggregate;
  /** fetch data from the table: "user_referral_levels_params" using primary key columns */
  user_referral_levels_params_by_pk?: Maybe<User_Referral_Levels_Params>;
  /** fetch data from the table in a streaming manner: "user_referral_levels_params" */
  user_referral_levels_params_stream: Array<User_Referral_Levels_Params>;
  /** An array relationship */
  user_referral_offer_bonus: Array<User_Referral_Offer_Bonus>;
  /** An aggregate relationship */
  user_referral_offer_bonus_aggregate: User_Referral_Offer_Bonus_Aggregate;
  /** fetch data from the table: "user_referral_offer_bonus" using primary key columns */
  user_referral_offer_bonus_by_pk?: Maybe<User_Referral_Offer_Bonus>;
  /** fetch data from the table in a streaming manner: "user_referral_offer_bonus" */
  user_referral_offer_bonus_stream: Array<User_Referral_Offer_Bonus>;
  /** An array relationship */
  user_referral_offers: Array<User_Referral_Offers>;
  /** An aggregate relationship */
  user_referral_offers_aggregate: User_Referral_Offers_Aggregate;
  /** fetch data from the table: "user_referral_offers" using primary key columns */
  user_referral_offers_by_pk?: Maybe<User_Referral_Offers>;
  /** fetch data from the table in a streaming manner: "user_referral_offers" */
  user_referral_offers_stream: Array<User_Referral_Offers>;
  /** fetch data from the table: "user_referral_params" */
  user_referral_params: Array<User_Referral_Params>;
  /** fetch aggregated fields from the table: "user_referral_params" */
  user_referral_params_aggregate: User_Referral_Params_Aggregate;
  /** fetch data from the table: "user_referral_params" using primary key columns */
  user_referral_params_by_pk?: Maybe<User_Referral_Params>;
  /** fetch data from the table in a streaming manner: "user_referral_params" */
  user_referral_params_stream: Array<User_Referral_Params>;
  /** fetch data from the table in a streaming manner: "user_referral" */
  user_referral_stream: Array<User_Referral>;
  /** fetch data from the table: "user_referrals_balance_transactions" */
  user_referrals_balance_transactions: Array<User_Referrals_Balance_Transactions>;
  /** fetch aggregated fields from the table: "user_referrals_balance_transactions" */
  user_referrals_balance_transactions_aggregate: User_Referrals_Balance_Transactions_Aggregate;
  /** fetch data from the table: "user_referrals_balance_transactions" using primary key columns */
  user_referrals_balance_transactions_by_pk?: Maybe<User_Referrals_Balance_Transactions>;
  /** fetch data from the table in a streaming manner: "user_referrals_balance_transactions" */
  user_referrals_balance_transactions_stream: Array<User_Referrals_Balance_Transactions>;
  /** fetch data from the table: "user_seq_number" */
  user_seq_number: Array<User_Seq_Number>;
  /** fetch aggregated fields from the table: "user_seq_number" */
  user_seq_number_aggregate: User_Seq_Number_Aggregate;
  /** fetch data from the table: "user_seq_number" using primary key columns */
  user_seq_number_by_pk?: Maybe<User_Seq_Number>;
  /** fetch data from the table in a streaming manner: "user_seq_number" */
  user_seq_number_stream: Array<User_Seq_Number>;
  /** fetch data from the table: "user_settings" */
  user_settings: Array<User_Settings>;
  /** fetch aggregated fields from the table: "user_settings" */
  user_settings_aggregate: User_Settings_Aggregate;
  /** fetch data from the table: "user_settings" using primary key columns */
  user_settings_by_pk?: Maybe<User_Settings>;
  /** fetch data from the table in a streaming manner: "user_settings" */
  user_settings_stream: Array<User_Settings>;
  /** fetch data from the table: "user_statistic" */
  user_statistic: Array<User_Statistic>;
  /** fetch aggregated fields from the table: "user_statistic" */
  user_statistic_aggregate: User_Statistic_Aggregate;
  /** fetch data from the table: "user_statistic" using primary key columns */
  user_statistic_by_pk?: Maybe<User_Statistic>;
  /** fetch data from the table in a streaming manner: "user_statistic" */
  user_statistic_stream: Array<User_Statistic>;
  /** fetch data from the table: "user_steam_output_alternatives" */
  user_steam_output_alternatives: Array<User_Steam_Output_Alternatives>;
  /** fetch aggregated fields from the table: "user_steam_output_alternatives" */
  user_steam_output_alternatives_aggregate: User_Steam_Output_Alternatives_Aggregate;
  /** fetch data from the table: "user_steam_output_alternatives" using primary key columns */
  user_steam_output_alternatives_by_pk?: Maybe<User_Steam_Output_Alternatives>;
  /** fetch data from the table in a streaming manner: "user_steam_output_alternatives" */
  user_steam_output_alternatives_stream: Array<User_Steam_Output_Alternatives>;
  /** fetch data from the table: "user_steam_output_watcher" */
  user_steam_output_watcher: Array<User_Steam_Output_Watcher>;
  /** fetch aggregated fields from the table: "user_steam_output_watcher" */
  user_steam_output_watcher_aggregate: User_Steam_Output_Watcher_Aggregate;
  /** fetch data from the table: "user_steam_output_watcher" using primary key columns */
  user_steam_output_watcher_by_pk?: Maybe<User_Steam_Output_Watcher>;
  /** fetch data from the table in a streaming manner: "user_steam_output_watcher" */
  user_steam_output_watcher_stream: Array<User_Steam_Output_Watcher>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "user_takes_free_lootbox" */
  user_takes_free_lootbox: Array<User_Takes_Free_Lootbox>;
  /** fetch aggregated fields from the table: "user_takes_free_lootbox" */
  user_takes_free_lootbox_aggregate: User_Takes_Free_Lootbox_Aggregate;
  /** fetch data from the table: "user_takes_free_lootbox" using primary key columns */
  user_takes_free_lootbox_by_pk?: Maybe<User_Takes_Free_Lootbox>;
  /** fetch data from the table in a streaming manner: "user_takes_free_lootbox" */
  user_takes_free_lootbox_stream: Array<User_Takes_Free_Lootbox>;
  /** fetch data from the table: "user_upgrade_params" */
  user_upgrade_params: Array<User_Upgrade_Params>;
  /** fetch aggregated fields from the table: "user_upgrade_params" */
  user_upgrade_params_aggregate: User_Upgrade_Params_Aggregate;
  /** fetch data from the table: "user_upgrade_params" using primary key columns */
  user_upgrade_params_by_pk?: Maybe<User_Upgrade_Params>;
  /** fetch data from the table in a streaming manner: "user_upgrade_params" */
  user_upgrade_params_stream: Array<User_Upgrade_Params>;
  /** fetch data from the table: "user_upgrade_results" */
  user_upgrade_results: Array<User_Upgrade_Results>;
  /** fetch aggregated fields from the table: "user_upgrade_results" */
  user_upgrade_results_aggregate: User_Upgrade_Results_Aggregate;
  /** fetch data from the table: "user_upgrade_results" using primary key columns */
  user_upgrade_results_by_pk?: Maybe<User_Upgrade_Results>;
  /** fetch data from the table in a streaming manner: "user_upgrade_results" */
  user_upgrade_results_stream: Array<User_Upgrade_Results>;
  /** fetch data from the table: "user_vk_data" */
  user_vk_data: Array<User_Vk_Data>;
  /** fetch aggregated fields from the table: "user_vk_data" */
  user_vk_data_aggregate: User_Vk_Data_Aggregate;
  /** fetch data from the table: "user_vk_data" using primary key columns */
  user_vk_data_by_pk?: Maybe<User_Vk_Data>;
  /** fetch data from the table in a streaming manner: "user_vk_data" */
  user_vk_data_stream: Array<User_Vk_Data>;
  /** fetch data from the table: "user_wallet" */
  user_wallet: Array<User_Wallet>;
  /** fetch aggregated fields from the table: "user_wallet" */
  user_wallet_aggregate: User_Wallet_Aggregate;
  /** fetch data from the table: "user_wallet_audit" */
  user_wallet_audit: Array<User_Wallet_Audit>;
  /** fetch aggregated fields from the table: "user_wallet_audit" */
  user_wallet_audit_aggregate: User_Wallet_Audit_Aggregate;
  /** fetch data from the table: "user_wallet_audit" using primary key columns */
  user_wallet_audit_by_pk?: Maybe<User_Wallet_Audit>;
  /** fetch data from the table in a streaming manner: "user_wallet_audit" */
  user_wallet_audit_stream: Array<User_Wallet_Audit>;
  /** fetch data from the table: "user_wallet" using primary key columns */
  user_wallet_by_pk?: Maybe<User_Wallet>;
  /** fetch data from the table in a streaming manner: "user_wallet" */
  user_wallet_stream: Array<User_Wallet>;
  /** An array relationship */
  user_wallet_transactions: Array<User_Wallet_Transactions>;
  /** An aggregate relationship */
  user_wallet_transactions_aggregate: User_Wallet_Transactions_Aggregate;
  /** fetch data from the table: "user_wallet_transactions" using primary key columns */
  user_wallet_transactions_by_pk?: Maybe<User_Wallet_Transactions>;
  /** fetch data from the table in a streaming manner: "user_wallet_transactions" */
  user_wallet_transactions_stream: Array<User_Wallet_Transactions>;
  /** fetch data from the table: "user_whitelist" */
  user_whitelist: Array<User_Whitelist>;
  /** fetch aggregated fields from the table: "user_whitelist" */
  user_whitelist_aggregate: User_Whitelist_Aggregate;
  /** fetch data from the table: "user_whitelist" using primary key columns */
  user_whitelist_by_pk?: Maybe<User_Whitelist>;
  /** fetch data from the table in a streaming manner: "user_whitelist" */
  user_whitelist_stream: Array<User_Whitelist>;
  /** fetch data from the table: "utm_visits_data" */
  utm_visits_data: Array<Utm_Visits_Data>;
  /** fetch aggregated fields from the table: "utm_visits_data" */
  utm_visits_data_aggregate: Utm_Visits_Data_Aggregate;
  /** fetch data from the table: "utm_visits_data" using primary key columns */
  utm_visits_data_by_pk?: Maybe<Utm_Visits_Data>;
  /** fetch data from the table in a streaming manner: "utm_visits_data" */
  utm_visits_data_stream: Array<Utm_Visits_Data>;
  /** fetch data from the table: "webpushes_autosending_types" */
  webpushes_autosending_types: Array<Webpushes_Autosending_Types>;
  /** fetch aggregated fields from the table: "webpushes_autosending_types" */
  webpushes_autosending_types_aggregate: Webpushes_Autosending_Types_Aggregate;
  /** fetch data from the table: "webpushes_autosending_types" using primary key columns */
  webpushes_autosending_types_by_pk?: Maybe<Webpushes_Autosending_Types>;
  /** fetch data from the table: "webpushes_autosending_types_localization" */
  webpushes_autosending_types_localization: Array<Webpushes_Autosending_Types_Localization>;
  /** fetch aggregated fields from the table: "webpushes_autosending_types_localization" */
  webpushes_autosending_types_localization_aggregate: Webpushes_Autosending_Types_Localization_Aggregate;
  /** fetch data from the table: "webpushes_autosending_types_localization" using primary key columns */
  webpushes_autosending_types_localization_by_pk?: Maybe<Webpushes_Autosending_Types_Localization>;
  /** fetch data from the table in a streaming manner: "webpushes_autosending_types_localization" */
  webpushes_autosending_types_localization_stream: Array<Webpushes_Autosending_Types_Localization>;
  /** fetch data from the table in a streaming manner: "webpushes_autosending_types" */
  webpushes_autosending_types_stream: Array<Webpushes_Autosending_Types>;
  /** fetch data from the table: "webpushes_bonuses" */
  webpushes_bonuses: Array<Webpushes_Bonuses>;
  /** fetch aggregated fields from the table: "webpushes_bonuses" */
  webpushes_bonuses_aggregate: Webpushes_Bonuses_Aggregate;
  /** fetch data from the table: "webpushes_bonuses" using primary key columns */
  webpushes_bonuses_by_pk?: Maybe<Webpushes_Bonuses>;
  /** An array relationship */
  webpushes_bonuses_offers: Array<Webpushes_Bonuses_Offers>;
  /** An aggregate relationship */
  webpushes_bonuses_offers_aggregate: Webpushes_Bonuses_Offers_Aggregate;
  /** fetch data from the table: "webpushes_bonuses_offers" using primary key columns */
  webpushes_bonuses_offers_by_pk?: Maybe<Webpushes_Bonuses_Offers>;
  /** fetch data from the table in a streaming manner: "webpushes_bonuses_offers" */
  webpushes_bonuses_offers_stream: Array<Webpushes_Bonuses_Offers>;
  /** fetch data from the table in a streaming manner: "webpushes_bonuses" */
  webpushes_bonuses_stream: Array<Webpushes_Bonuses>;
  /** fetch data from the table: "webpushes_lines" */
  webpushes_lines: Array<Webpushes_Lines>;
  /** fetch aggregated fields from the table: "webpushes_lines" */
  webpushes_lines_aggregate: Webpushes_Lines_Aggregate;
  /** fetch data from the table: "webpushes_lines" using primary key columns */
  webpushes_lines_by_pk?: Maybe<Webpushes_Lines>;
  /** fetch data from the table in a streaming manner: "webpushes_lines" */
  webpushes_lines_stream: Array<Webpushes_Lines>;
  /** fetch data from the table: "webpushes_scheduling" */
  webpushes_scheduling: Array<Webpushes_Scheduling>;
  /** fetch aggregated fields from the table: "webpushes_scheduling" */
  webpushes_scheduling_aggregate: Webpushes_Scheduling_Aggregate;
  /** fetch data from the table: "webpushes_scheduling" using primary key columns */
  webpushes_scheduling_by_pk?: Maybe<Webpushes_Scheduling>;
  /** fetch data from the table in a streaming manner: "webpushes_scheduling" */
  webpushes_scheduling_stream: Array<Webpushes_Scheduling>;
  /** An array relationship */
  webpushes_triggers: Array<Webpushes_Triggers>;
  /** An aggregate relationship */
  webpushes_triggers_aggregate: Webpushes_Triggers_Aggregate;
  /** fetch data from the table: "webpushes_triggers" using primary key columns */
  webpushes_triggers_by_pk?: Maybe<Webpushes_Triggers>;
  /** An array relationship */
  webpushes_triggers_groups: Array<Webpushes_Triggers_Groups>;
  /** An aggregate relationship */
  webpushes_triggers_groups_aggregate: Webpushes_Triggers_Groups_Aggregate;
  /** fetch data from the table: "webpushes_triggers_groups" using primary key columns */
  webpushes_triggers_groups_by_pk?: Maybe<Webpushes_Triggers_Groups>;
  /** fetch data from the table in a streaming manner: "webpushes_triggers_groups" */
  webpushes_triggers_groups_stream: Array<Webpushes_Triggers_Groups>;
  /** fetch data from the table in a streaming manner: "webpushes_triggers" */
  webpushes_triggers_stream: Array<Webpushes_Triggers>;
};


export type Subscription_RootLootboxOpensCountsArgs = {
  distinct_on?: InputMaybe<Array<LootboxOpensCounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LootboxOpensCounts_Order_By>>;
  where?: InputMaybe<LootboxOpensCounts_Bool_Exp>;
};


export type Subscription_RootLootboxOpensCounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LootboxOpensCounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LootboxOpensCounts_Order_By>>;
  where?: InputMaybe<LootboxOpensCounts_Bool_Exp>;
};


export type Subscription_RootLootboxOpensCounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LootboxOpensCounts_Stream_Cursor_Input>>;
  where?: InputMaybe<LootboxOpensCounts_Bool_Exp>;
};


export type Subscription_RootTopDropStripViewArgs = {
  distinct_on?: InputMaybe<Array<TopDropStripView_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TopDropStripView_Order_By>>;
  where?: InputMaybe<TopDropStripView_Bool_Exp>;
};


export type Subscription_RootTopDropStripView_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TopDropStripView_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TopDropStripView_Order_By>>;
  where?: InputMaybe<TopDropStripView_Bool_Exp>;
};


export type Subscription_RootTopDropStripView_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TopDropStripView_Stream_Cursor_Input>>;
  where?: InputMaybe<TopDropStripView_Bool_Exp>;
};


export type Subscription_RootAccount_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Account_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Items_Names_Order_By>>;
  where?: InputMaybe<Account_Items_Names_Bool_Exp>;
};


export type Subscription_RootAccount_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Items_Names_Order_By>>;
  where?: InputMaybe<Account_Items_Names_Bool_Exp>;
};


export type Subscription_RootAccount_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootAccount_Items_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Account_Items_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Items_Names_Bool_Exp>;
};


export type Subscription_RootAdmin_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Admin_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_Items_Names_Order_By>>;
  where?: InputMaybe<Admin_Items_Names_Bool_Exp>;
};


export type Subscription_RootAdmin_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admin_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_Items_Names_Order_By>>;
  where?: InputMaybe<Admin_Items_Names_Bool_Exp>;
};


export type Subscription_RootAdmin_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootAdmin_Items_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Admin_Items_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Admin_Items_Names_Bool_Exp>;
};


export type Subscription_RootAdmin_User_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Admin_User_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_User_Params_Order_By>>;
  where?: InputMaybe<Admin_User_Params_Bool_Exp>;
};


export type Subscription_RootAdmin_User_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Admin_User_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Admin_User_Params_Order_By>>;
  where?: InputMaybe<Admin_User_Params_Bool_Exp>;
};


export type Subscription_RootAdmin_User_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootAdmin_User_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Admin_User_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Admin_User_Params_Bool_Exp>;
};


export type Subscription_RootAmp_Sessions_DataArgs = {
  distinct_on?: InputMaybe<Array<Amp_Sessions_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Amp_Sessions_Data_Order_By>>;
  where?: InputMaybe<Amp_Sessions_Data_Bool_Exp>;
};


export type Subscription_RootAmp_Sessions_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Amp_Sessions_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Amp_Sessions_Data_Order_By>>;
  where?: InputMaybe<Amp_Sessions_Data_Bool_Exp>;
};


export type Subscription_RootAmp_Sessions_Data_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootAmp_Sessions_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Amp_Sessions_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Amp_Sessions_Data_Bool_Exp>;
};


export type Subscription_RootBanned_IpsArgs = {
  distinct_on?: InputMaybe<Array<Banned_Ips_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banned_Ips_Order_By>>;
  where?: InputMaybe<Banned_Ips_Bool_Exp>;
};


export type Subscription_RootBanned_Ips_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Banned_Ips_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banned_Ips_Order_By>>;
  where?: InputMaybe<Banned_Ips_Bool_Exp>;
};


export type Subscription_RootBanned_Ips_By_PkArgs = {
  ip: Scalars['String'];
};


export type Subscription_RootBanned_Ips_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Banned_Ips_Stream_Cursor_Input>>;
  where?: InputMaybe<Banned_Ips_Bool_Exp>;
};


export type Subscription_RootBanned_Users_For_MultiaccountArgs = {
  distinct_on?: InputMaybe<Array<Banned_Users_For_Multiaccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banned_Users_For_Multiaccount_Order_By>>;
  where?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
};


export type Subscription_RootBanned_Users_For_Multiaccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Banned_Users_For_Multiaccount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Banned_Users_For_Multiaccount_Order_By>>;
  where?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
};


export type Subscription_RootBanned_Users_For_Multiaccount_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootBanned_Users_For_Multiaccount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Banned_Users_For_Multiaccount_Stream_Cursor_Input>>;
  where?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
};


export type Subscription_RootBillArgs = {
  distinct_on?: InputMaybe<Array<Bill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bill_Order_By>>;
  where?: InputMaybe<Bill_Bool_Exp>;
};


export type Subscription_RootBill_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bill_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bill_Order_By>>;
  where?: InputMaybe<Bill_Bool_Exp>;
};


export type Subscription_RootBill_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBill_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bill_Stream_Cursor_Input>>;
  where?: InputMaybe<Bill_Bool_Exp>;
};


export type Subscription_RootBonus_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Params_Order_By>>;
  where?: InputMaybe<Bonus_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Params_Order_By>>;
  where?: InputMaybe<Bonus_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Params_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  param_name: Scalars['String'];
};


export type Subscription_RootBonus_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_RewardsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Rewards_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Rewards_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Rewards_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Rewards_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_Rewards_By_PkArgs = {
  reward_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheel_Rewards_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheel_Rewards_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheel_Rewards_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_Ticket_TypeArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_Ticket_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_Ticket_Type_By_PkArgs = {
  ticket_type_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheel_Ticket_Type_Name_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_Ticket_Type_Name_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_Ticket_Type_Name_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_Ticket_Type_Name_Localization_By_PkArgs = {
  locale: Scalars['String'];
  ticket_type_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheel_Ticket_Type_Name_Localization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Name_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_Ticket_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheel_Ticket_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheel_Ticket_Type_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_User_RewardsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_User_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_User_Rewards_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_User_Rewards_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_User_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_User_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_User_Rewards_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_User_Rewards_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_User_Rewards_By_PkArgs = {
  user_reward_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheel_User_Rewards_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheel_User_Rewards_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheel_User_Rewards_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_User_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_User_Tickets_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_User_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_User_Tickets_Bool_Exp>;
};


export type Subscription_RootBonus_Wheel_User_Tickets_By_PkArgs = {
  ticket_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheel_User_Tickets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheel_User_Tickets_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheel_User_Tickets_Bool_Exp>;
};


export type Subscription_RootBonus_WheelsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonus_SkinsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonus_Skins_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonus_Skins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonus_Skins_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonus_Skins_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonus_Skins_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  item_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheels_Bonus_Skins_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_Bonus_Skins_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_Bonus_Skins_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_BonusesArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheels_Bonuses_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonuses_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonuses_Localization_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
};


export type Subscription_RootBonus_Wheels_Bonuses_Localization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_Bonuses_Localization_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonuses_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonuses_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonuses_Params_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheels_Bonuses_Params_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonuses_Params_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Bonuses_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonuses_Params_Localization_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
};


export type Subscription_RootBonus_Wheels_Bonuses_Params_Localization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonuses_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_Bonuses_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Bonuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_Bonuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_Bonuses_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_By_PkArgs = {
  wheel_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheels_GeneratorArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Generator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Generator_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Generator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Generator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Generator_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Generator_BonusesArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Generator_Bonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Generator_Bonuses_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Generator_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  wheel_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheels_Generator_Bonuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_Generator_Bonuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bonuses_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Generator_By_PkArgs = {
  wheel_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheels_Generator_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_Generator_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_Generator_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Items_In_WheelArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Items_In_Wheel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_Items_In_Wheel_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_Items_In_Wheel_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  wheel_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheels_Items_In_Wheel_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_Items_In_Wheel_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_Items_In_Wheel_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_CheckArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Check_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Check_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Check_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Check_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheels_User_Check_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Check_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Check_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Check_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Check_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Check_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Check_Params_By_PkArgs = {
  param_name: Scalars['String'];
};


export type Subscription_RootBonus_Wheels_User_Check_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_User_Check_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Check_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_User_Check_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_User_Check_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_OffersArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Offers_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootBonus_Wheels_User_Offers_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Offers_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Offers_Params_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  stage: Scalars['Int'];
};


export type Subscription_RootBonus_Wheels_User_Offers_Params_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Offers_Params_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheels_User_Offers_Params_Localization_Order_By>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Offers_Params_Localization_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  locale: Scalars['String'];
  stage: Scalars['Int'];
};


export type Subscription_RootBonus_Wheels_User_Offers_Params_Localization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Localization_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Offers_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_User_Offers_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Params_Bool_Exp>;
};


export type Subscription_RootBonus_Wheels_User_Offers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonus_Wheels_User_Offers_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonus_Wheels_User_Offers_Bool_Exp>;
};


export type Subscription_RootBonusesArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Order_By>>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};


export type Subscription_RootBonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Order_By>>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};


export type Subscription_RootBonuses_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBonuses_RegisterArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Register_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Register_Order_By>>;
  where?: InputMaybe<Bonuses_Register_Bool_Exp>;
};


export type Subscription_RootBonuses_Register_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Register_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Register_Order_By>>;
  where?: InputMaybe<Bonuses_Register_Bool_Exp>;
};


export type Subscription_RootBonuses_Register_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


export type Subscription_RootBonuses_Register_OffersArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Register_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Register_Offers_Order_By>>;
  where?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
};


export type Subscription_RootBonuses_Register_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonuses_Register_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonuses_Register_Offers_Order_By>>;
  where?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
};


export type Subscription_RootBonuses_Register_Offers_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootBonuses_Register_Offers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonuses_Register_Offers_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonuses_Register_Offers_Bool_Exp>;
};


export type Subscription_RootBonuses_Register_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonuses_Register_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonuses_Register_Bool_Exp>;
};


export type Subscription_RootBonuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bonuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Bonuses_Bool_Exp>;
};


export type Subscription_RootBoosters_Set_ContentArgs = {
  distinct_on?: InputMaybe<Array<Boosters_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Boosters_Set_Content_Order_By>>;
  where?: InputMaybe<Boosters_Set_Content_Bool_Exp>;
};


export type Subscription_RootBoosters_Set_Content_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Boosters_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Boosters_Set_Content_Order_By>>;
  where?: InputMaybe<Boosters_Set_Content_Bool_Exp>;
};


export type Subscription_RootBoosters_Set_Content_By_PkArgs = {
  content_id: Scalars['bigint'];
};


export type Subscription_RootBoosters_Set_Content_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Boosters_Set_Content_Stream_Cursor_Input>>;
  where?: InputMaybe<Boosters_Set_Content_Bool_Exp>;
};


export type Subscription_RootBoosters_SetsArgs = {
  distinct_on?: InputMaybe<Array<Boosters_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Boosters_Sets_Order_By>>;
  where?: InputMaybe<Boosters_Sets_Bool_Exp>;
};


export type Subscription_RootBoosters_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Boosters_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Boosters_Sets_Order_By>>;
  where?: InputMaybe<Boosters_Sets_Bool_Exp>;
};


export type Subscription_RootBoosters_Sets_By_PkArgs = {
  set_id: Scalars['bigint'];
};


export type Subscription_RootBoosters_Sets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Boosters_Sets_Stream_Cursor_Input>>;
  where?: InputMaybe<Boosters_Sets_Bool_Exp>;
};


export type Subscription_RootBot_Account_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Bot_Account_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bot_Account_Settings_Order_By>>;
  where?: InputMaybe<Bot_Account_Settings_Bool_Exp>;
};


export type Subscription_RootBot_Account_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bot_Account_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bot_Account_Settings_Order_By>>;
  where?: InputMaybe<Bot_Account_Settings_Bool_Exp>;
};


export type Subscription_RootBot_Account_Settings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootBot_Account_Settings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bot_Account_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Bot_Account_Settings_Bool_Exp>;
};


export type Subscription_RootBots_Lootboxes_Opens_CountArgs = {
  distinct_on?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Order_By>>;
  where?: InputMaybe<Bots_Lootboxes_Opens_Count_Bool_Exp>;
};


export type Subscription_RootBots_Lootboxes_Opens_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Order_By>>;
  where?: InputMaybe<Bots_Lootboxes_Opens_Count_Bool_Exp>;
};


export type Subscription_RootBots_Lootboxes_Opens_Count_By_PkArgs = {
  bot_id: Scalars['uuid'];
  lootbox_slug: Scalars['String'];
};


export type Subscription_RootBots_Lootboxes_Opens_Count_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bots_Lootboxes_Opens_Count_Stream_Cursor_Input>>;
  where?: InputMaybe<Bots_Lootboxes_Opens_Count_Bool_Exp>;
};


export type Subscription_RootCoins_Set_ContentArgs = {
  distinct_on?: InputMaybe<Array<Coins_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coins_Set_Content_Order_By>>;
  where?: InputMaybe<Coins_Set_Content_Bool_Exp>;
};


export type Subscription_RootCoins_Set_Content_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coins_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coins_Set_Content_Order_By>>;
  where?: InputMaybe<Coins_Set_Content_Bool_Exp>;
};


export type Subscription_RootCoins_Set_Content_By_PkArgs = {
  content_id: Scalars['bigint'];
};


export type Subscription_RootCoins_Set_Content_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Coins_Set_Content_Stream_Cursor_Input>>;
  where?: InputMaybe<Coins_Set_Content_Bool_Exp>;
};


export type Subscription_RootCoins_SetsArgs = {
  distinct_on?: InputMaybe<Array<Coins_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coins_Sets_Order_By>>;
  where?: InputMaybe<Coins_Sets_Bool_Exp>;
};


export type Subscription_RootCoins_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coins_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coins_Sets_Order_By>>;
  where?: InputMaybe<Coins_Sets_Bool_Exp>;
};


export type Subscription_RootCoins_Sets_By_PkArgs = {
  set_id: Scalars['bigint'];
};


export type Subscription_RootCoins_Sets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Coins_Sets_Stream_Cursor_Input>>;
  where?: InputMaybe<Coins_Sets_Bool_Exp>;
};


export type Subscription_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCouponsArgs = {
  distinct_on?: InputMaybe<Array<Coupons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupons_Order_By>>;
  where?: InputMaybe<Coupons_Bool_Exp>;
};


export type Subscription_RootCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupons_Order_By>>;
  where?: InputMaybe<Coupons_Bool_Exp>;
};


export type Subscription_RootCoupons_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCoupons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Coupons_Stream_Cursor_Input>>;
  where?: InputMaybe<Coupons_Bool_Exp>;
};


export type Subscription_RootCsgomarket_Token_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Csgomarket_Token_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Csgomarket_Token_Balance_Order_By>>;
  where?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
};


export type Subscription_RootCsgomarket_Token_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Csgomarket_Token_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Csgomarket_Token_Balance_Order_By>>;
  where?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
};


export type Subscription_RootCsgomarket_Token_Balance_By_PkArgs = {
  datetime: Scalars['timestamp'];
  token_id: Scalars['uuid'];
};


export type Subscription_RootCsgomarket_Token_Balance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Csgomarket_Token_Balance_Stream_Cursor_Input>>;
  where?: InputMaybe<Csgomarket_Token_Balance_Bool_Exp>;
};


export type Subscription_RootCsgomarket_TokensArgs = {
  distinct_on?: InputMaybe<Array<Csgomarket_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Csgomarket_Tokens_Order_By>>;
  where?: InputMaybe<Csgomarket_Tokens_Bool_Exp>;
};


export type Subscription_RootCsgomarket_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Csgomarket_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Csgomarket_Tokens_Order_By>>;
  where?: InputMaybe<Csgomarket_Tokens_Bool_Exp>;
};


export type Subscription_RootCsgomarket_Tokens_By_PkArgs = {
  token_id: Scalars['uuid'];
};


export type Subscription_RootCsgomarket_Tokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Csgomarket_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Csgomarket_Tokens_Bool_Exp>;
};


export type Subscription_RootCurrency_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Currency_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_History_Order_By>>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};


export type Subscription_RootCurrency_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currency_History_Order_By>>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};


export type Subscription_RootCurrency_History_By_PkArgs = {
  created_at: Scalars['timestamp'];
  currency_id: Scalars['Int'];
};


export type Subscription_RootCurrency_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Currency_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Currency_History_Bool_Exp>;
};


export type Subscription_RootCurrencysArgs = {
  distinct_on?: InputMaybe<Array<Currencys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencys_Order_By>>;
  where?: InputMaybe<Currencys_Bool_Exp>;
};


export type Subscription_RootCurrencys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currencys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Currencys_Order_By>>;
  where?: InputMaybe<Currencys_Bool_Exp>;
};


export type Subscription_RootCurrencys_By_PkArgs = {
  currency_id: Scalars['Int'];
};


export type Subscription_RootCurrencys_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Currencys_Stream_Cursor_Input>>;
  where?: InputMaybe<Currencys_Bool_Exp>;
};


export type Subscription_RootDaily_Deposit_SumArgs = {
  distinct_on?: InputMaybe<Array<Daily_Deposit_Sum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Deposit_Sum_Order_By>>;
  where?: InputMaybe<Daily_Deposit_Sum_Bool_Exp>;
};


export type Subscription_RootDaily_Deposit_Sum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Deposit_Sum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Deposit_Sum_Order_By>>;
  where?: InputMaybe<Daily_Deposit_Sum_Bool_Exp>;
};


export type Subscription_RootDaily_Deposit_Sum_By_PkArgs = {
  created_at: Scalars['date'];
};


export type Subscription_RootDaily_Deposit_Sum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Daily_Deposit_Sum_Stream_Cursor_Input>>;
  where?: InputMaybe<Daily_Deposit_Sum_Bool_Exp>;
};


export type Subscription_RootEmails_From_Upgrade_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Emails_From_Upgrade_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_From_Upgrade_Question_Order_By>>;
  where?: InputMaybe<Emails_From_Upgrade_Question_Bool_Exp>;
};


export type Subscription_RootEmails_From_Upgrade_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Emails_From_Upgrade_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Emails_From_Upgrade_Question_Order_By>>;
  where?: InputMaybe<Emails_From_Upgrade_Question_Bool_Exp>;
};


export type Subscription_RootEmails_From_Upgrade_Question_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEmails_From_Upgrade_Question_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Emails_From_Upgrade_Question_Stream_Cursor_Input>>;
  where?: InputMaybe<Emails_From_Upgrade_Question_Bool_Exp>;
};


export type Subscription_RootExecution_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Execution_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Execution_Methods_Order_By>>;
  where?: InputMaybe<Execution_Methods_Bool_Exp>;
};


export type Subscription_RootExecution_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Execution_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Execution_Methods_Order_By>>;
  where?: InputMaybe<Execution_Methods_Bool_Exp>;
};


export type Subscription_RootExecution_Methods_By_PkArgs = {
  method_id: Scalars['Int'];
};


export type Subscription_RootExecution_Methods_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Execution_Methods_Stream_Cursor_Input>>;
  where?: InputMaybe<Execution_Methods_Bool_Exp>;
};


export type Subscription_RootFaq_TableArgs = {
  distinct_on?: InputMaybe<Array<Faq_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Table_Order_By>>;
  where?: InputMaybe<Faq_Table_Bool_Exp>;
};


export type Subscription_RootFaq_Table_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Faq_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Faq_Table_Order_By>>;
  where?: InputMaybe<Faq_Table_Bool_Exp>;
};


export type Subscription_RootFaq_Table_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFaq_Table_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Faq_Table_Stream_Cursor_Input>>;
  where?: InputMaybe<Faq_Table_Bool_Exp>;
};


export type Subscription_RootFixed_Items_Generator_BoostersArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Boosters_Bool_Exp>;
};


export type Subscription_RootFixed_Items_Generator_Boosters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Boosters_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Boosters_Bool_Exp>;
};


export type Subscription_RootFixed_Items_Generator_Boosters_By_PkArgs = {
  booster_id: Scalars['uuid'];
  generator_lootbox_id: Scalars['uuid'];
};


export type Subscription_RootFixed_Items_Generator_Boosters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fixed_Items_Generator_Boosters_Stream_Cursor_Input>>;
  where?: InputMaybe<Fixed_Items_Generator_Boosters_Bool_Exp>;
};


export type Subscription_RootFixed_Items_Generator_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Items_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Items_Bool_Exp>;
};


export type Subscription_RootFixed_Items_Generator_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Items_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Items_Bool_Exp>;
};


export type Subscription_RootFixed_Items_Generator_Items_By_PkArgs = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};


export type Subscription_RootFixed_Items_Generator_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fixed_Items_Generator_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Fixed_Items_Generator_Items_Bool_Exp>;
};


export type Subscription_RootFixed_Items_Generator_LootboxesArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Lootboxes_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Lootboxes_Bool_Exp>;
};


export type Subscription_RootFixed_Items_Generator_Lootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fixed_Items_Generator_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fixed_Items_Generator_Lootboxes_Order_By>>;
  where?: InputMaybe<Fixed_Items_Generator_Lootboxes_Bool_Exp>;
};


export type Subscription_RootFixed_Items_Generator_Lootboxes_By_PkArgs = {
  lootbox_generator_id: Scalars['uuid'];
};


export type Subscription_RootFixed_Items_Generator_Lootboxes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fixed_Items_Generator_Lootboxes_Stream_Cursor_Input>>;
  where?: InputMaybe<Fixed_Items_Generator_Lootboxes_Bool_Exp>;
};


export type Subscription_RootGet_Ticket_Counts_By_TypeArgs = {
  args: Get_Ticket_Counts_By_Type_Args;
  distinct_on?: InputMaybe<Array<Ticket_Counts_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ticket_Counts_Result_Order_By>>;
  where?: InputMaybe<Ticket_Counts_Result_Bool_Exp>;
};


export type Subscription_RootGet_Ticket_Counts_By_Type_AggregateArgs = {
  args: Get_Ticket_Counts_By_Type_Args;
  distinct_on?: InputMaybe<Array<Ticket_Counts_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ticket_Counts_Result_Order_By>>;
  where?: InputMaybe<Ticket_Counts_Result_Bool_Exp>;
};


export type Subscription_RootGiveaway_MembersArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Members_Order_By>>;
  where?: InputMaybe<Giveaway_Members_Bool_Exp>;
};


export type Subscription_RootGiveaway_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Members_Order_By>>;
  where?: InputMaybe<Giveaway_Members_Bool_Exp>;
};


export type Subscription_RootGiveaway_Members_By_PkArgs = {
  giveaway_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootGiveaway_Members_RatingArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Members_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Members_Rating_Order_By>>;
  where?: InputMaybe<Giveaway_Members_Rating_Bool_Exp>;
};


export type Subscription_RootGiveaway_Members_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Members_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Members_Rating_Order_By>>;
  where?: InputMaybe<Giveaway_Members_Rating_Bool_Exp>;
};


export type Subscription_RootGiveaway_Members_Rating_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Giveaway_Members_Rating_Stream_Cursor_Input>>;
  where?: InputMaybe<Giveaway_Members_Rating_Bool_Exp>;
};


export type Subscription_RootGiveaway_Members_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Giveaway_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Giveaway_Members_Bool_Exp>;
};


export type Subscription_RootGiveaway_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Params_Order_By>>;
  where?: InputMaybe<Giveaway_Params_Bool_Exp>;
};


export type Subscription_RootGiveaway_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Giveaway_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaway_Params_Order_By>>;
  where?: InputMaybe<Giveaway_Params_Bool_Exp>;
};


export type Subscription_RootGiveaway_Params_By_PkArgs = {
  giveaway_id: Scalars['uuid'];
};


export type Subscription_RootGiveaway_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Giveaway_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Giveaway_Params_Bool_Exp>;
};


export type Subscription_RootGiveawaysArgs = {
  distinct_on?: InputMaybe<Array<Giveaways_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaways_Order_By>>;
  where?: InputMaybe<Giveaways_Bool_Exp>;
};


export type Subscription_RootGiveaways_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Giveaways_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Giveaways_Order_By>>;
  where?: InputMaybe<Giveaways_Bool_Exp>;
};


export type Subscription_RootGiveaways_By_PkArgs = {
  giveaway_id: Scalars['uuid'];
};


export type Subscription_RootGiveaways_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Giveaways_Stream_Cursor_Input>>;
  where?: InputMaybe<Giveaways_Bool_Exp>;
};


export type Subscription_RootGroup_Items_CsgomarketArgs = {
  distinct_on?: InputMaybe<Array<Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootGroup_Items_Csgomarket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootGroup_Items_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroup_Items_Csgomarket_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Group_Items_Csgomarket_Stream_Cursor_Input>>;
  where?: InputMaybe<Group_Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootHistory_Group_Items_CsgomarketArgs = {
  distinct_on?: InputMaybe<Array<History_Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Group_Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootHistory_Group_Items_Csgomarket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<History_Group_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Group_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Group_Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootHistory_Group_Items_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootHistory_Group_Items_Csgomarket_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<History_Group_Items_Csgomarket_Stream_Cursor_Input>>;
  where?: InputMaybe<History_Group_Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootHistory_Items_CsgomarketArgs = {
  distinct_on?: InputMaybe<Array<History_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootHistory_Items_Csgomarket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<History_Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<History_Items_Csgomarket_Order_By>>;
  where?: InputMaybe<History_Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootHistory_Items_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootHistory_Items_Csgomarket_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<History_Items_Csgomarket_Stream_Cursor_Input>>;
  where?: InputMaybe<History_Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootItem_Out_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Item_Out_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Out_Transactions_Order_By>>;
  where?: InputMaybe<Item_Out_Transactions_Bool_Exp>;
};


export type Subscription_RootItem_Out_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Item_Out_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Item_Out_Transactions_Order_By>>;
  where?: InputMaybe<Item_Out_Transactions_Bool_Exp>;
};


export type Subscription_RootItem_Out_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Subscription_RootItem_Out_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Item_Out_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Item_Out_Transactions_Bool_Exp>;
};


export type Subscription_RootItemsArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_By_PkArgs = {
  item_id: Scalars['uuid'];
};


export type Subscription_RootItems_CsgomarketArgs = {
  distinct_on?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootItems_Csgomarket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Csgomarket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Csgomarket_Order_By>>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootItems_Csgomarket_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootItems_Csgomarket_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Csgomarket_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Csgomarket_Bool_Exp>;
};


export type Subscription_RootItems_DropsArgs = {
  distinct_on?: InputMaybe<Array<Items_Drops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Drops_Order_By>>;
  where?: InputMaybe<Items_Drops_Bool_Exp>;
};


export type Subscription_RootItems_Drops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Drops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Drops_Order_By>>;
  where?: InputMaybe<Items_Drops_Bool_Exp>;
};


export type Subscription_RootItems_Drops_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootItems_Drops_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Drops_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Drops_Bool_Exp>;
};


export type Subscription_RootItems_For_UpgradeArgs = {
  distinct_on?: InputMaybe<Array<Items_For_Upgrade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_For_Upgrade_Order_By>>;
  where?: InputMaybe<Items_For_Upgrade_Bool_Exp>;
};


export type Subscription_RootItems_For_Upgrade_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_For_Upgrade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_For_Upgrade_Order_By>>;
  where?: InputMaybe<Items_For_Upgrade_Bool_Exp>;
};


export type Subscription_RootItems_For_Upgrade_By_PkArgs = {
  item_id: Scalars['uuid'];
};


export type Subscription_RootItems_For_Upgrade_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_For_Upgrade_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_For_Upgrade_Bool_Exp>;
};


export type Subscription_RootItems_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Items_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_History_Order_By>>;
  where?: InputMaybe<Items_History_Bool_Exp>;
};


export type Subscription_RootItems_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_History_Order_By>>;
  where?: InputMaybe<Items_History_Bool_Exp>;
};


export type Subscription_RootItems_History_By_PkArgs = {
  created_at: Scalars['timestamp'];
  item_id: Scalars['uuid'];
};


export type Subscription_RootItems_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_History_Bool_Exp>;
};


export type Subscription_RootItems_In_LootboxArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_Bool_Exp>;
};


export type Subscription_RootItems_In_Lootbox_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_Bool_Exp>;
};


export type Subscription_RootItems_In_Lootbox_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Subscription_RootItems_In_Lootbox_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_In_Lootbox_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_In_Lootbox_Bool_Exp>;
};


export type Subscription_RootItems_In_Lootbox_ViewArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_View_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
};


export type Subscription_RootItems_In_Lootbox_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_In_Lootbox_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_In_Lootbox_View_Order_By>>;
  where?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
};


export type Subscription_RootItems_In_Lootbox_View_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Subscription_RootItems_In_Lootbox_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_In_Lootbox_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_In_Lootbox_View_Bool_Exp>;
};


export type Subscription_RootItems_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Items_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Items_Names_Order_By>>;
  where?: InputMaybe<Items_Items_Names_Bool_Exp>;
};


export type Subscription_RootItems_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Items_Names_Order_By>>;
  where?: InputMaybe<Items_Items_Names_Bool_Exp>;
};


export type Subscription_RootItems_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootItems_Items_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Items_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Items_Names_Bool_Exp>;
};


export type Subscription_RootItems_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Items_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Localization_Order_By>>;
  where?: InputMaybe<Items_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Localization_Order_By>>;
  where?: InputMaybe<Items_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Localization_By_PkArgs = {
  item_name_key: Scalars['String'];
  locale: Scalars['String'];
};


export type Subscription_RootItems_Localization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Localization_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Localization_Bool_Exp>;
};


export type Subscription_RootItems_NamesArgs = {
  distinct_on?: InputMaybe<Array<Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Names_Order_By>>;
  where?: InputMaybe<Items_Names_Bool_Exp>;
};


export type Subscription_RootItems_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Names_Order_By>>;
  where?: InputMaybe<Items_Names_Bool_Exp>;
};


export type Subscription_RootItems_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootItems_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Names_Bool_Exp>;
};


export type Subscription_RootItems_QualityArgs = {
  distinct_on?: InputMaybe<Array<Items_Quality_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Quality_Order_By>>;
  where?: InputMaybe<Items_Quality_Bool_Exp>;
};


export type Subscription_RootItems_Quality_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Quality_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Quality_Order_By>>;
  where?: InputMaybe<Items_Quality_Bool_Exp>;
};


export type Subscription_RootItems_Quality_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootItems_Quality_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Items_Quality_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Quality_Localization_Order_By>>;
  where?: InputMaybe<Items_Quality_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Quality_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Quality_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Quality_Localization_Order_By>>;
  where?: InputMaybe<Items_Quality_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Quality_Localization_By_PkArgs = {
  locale: Scalars['String'];
  quality_name_key: Scalars['String'];
};


export type Subscription_RootItems_Quality_Localization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Quality_Localization_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Quality_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Quality_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Quality_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Quality_Bool_Exp>;
};


export type Subscription_RootItems_RarityArgs = {
  distinct_on?: InputMaybe<Array<Items_Rarity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Rarity_Order_By>>;
  where?: InputMaybe<Items_Rarity_Bool_Exp>;
};


export type Subscription_RootItems_Rarity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Rarity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Rarity_Order_By>>;
  where?: InputMaybe<Items_Rarity_Bool_Exp>;
};


export type Subscription_RootItems_Rarity_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootItems_Rarity_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Items_Rarity_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Rarity_Localization_Order_By>>;
  where?: InputMaybe<Items_Rarity_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Rarity_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Rarity_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Rarity_Localization_Order_By>>;
  where?: InputMaybe<Items_Rarity_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Rarity_Localization_By_PkArgs = {
  rarity_name_key: Scalars['String'];
};


export type Subscription_RootItems_Rarity_Localization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Rarity_Localization_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Rarity_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Rarity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Rarity_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Rarity_Bool_Exp>;
};


export type Subscription_RootItems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_TypeArgs = {
  distinct_on?: InputMaybe<Array<Items_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Type_Order_By>>;
  where?: InputMaybe<Items_Type_Bool_Exp>;
};


export type Subscription_RootItems_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Type_Order_By>>;
  where?: InputMaybe<Items_Type_Bool_Exp>;
};


export type Subscription_RootItems_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootItems_Type_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Items_Type_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Type_Localization_Order_By>>;
  where?: InputMaybe<Items_Type_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Type_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Type_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Type_Localization_Order_By>>;
  where?: InputMaybe<Items_Type_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Type_Localization_By_PkArgs = {
  locale: Scalars['String'];
  type_name_key: Scalars['String'];
};


export type Subscription_RootItems_Type_Localization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Type_Localization_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Type_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Type_Bool_Exp>;
};


export type Subscription_RootItems_Weapon_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Items_Weapon_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Weapon_Localization_Order_By>>;
  where?: InputMaybe<Items_Weapon_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Weapon_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Weapon_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Weapon_Localization_Order_By>>;
  where?: InputMaybe<Items_Weapon_Localization_Bool_Exp>;
};


export type Subscription_RootItems_Weapon_Localization_By_PkArgs = {
  locale: Scalars['String'];
  weapon_name_key: Scalars['String'];
};


export type Subscription_RootItems_Weapon_Localization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Weapon_Localization_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Weapon_Localization_Bool_Exp>;
};


export type Subscription_RootItems_WeaponsArgs = {
  distinct_on?: InputMaybe<Array<Items_Weapons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Weapons_Order_By>>;
  where?: InputMaybe<Items_Weapons_Bool_Exp>;
};


export type Subscription_RootItems_Weapons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Weapons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Weapons_Order_By>>;
  where?: InputMaybe<Items_Weapons_Bool_Exp>;
};


export type Subscription_RootItems_Weapons_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootItems_Weapons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Weapons_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Weapons_Bool_Exp>;
};


export type Subscription_RootList_Items_RandomArgs = {
  args?: InputMaybe<List_Items_Random_Args>;
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootList_Items_Random_AggregateArgs = {
  args?: InputMaybe<List_Items_Random_Args>;
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootLootbox_Booster_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Items_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Items_Bool_Exp>;
};


export type Subscription_RootLootbox_Booster_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Items_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Items_Bool_Exp>;
};


export type Subscription_RootLootbox_Booster_Items_By_PkArgs = {
  item_id: Scalars['uuid'];
};


export type Subscription_RootLootbox_Booster_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootbox_Booster_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootbox_Booster_Items_Bool_Exp>;
};


export type Subscription_RootLootbox_Booster_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Localization_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
};


export type Subscription_RootLootbox_Booster_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Booster_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Booster_Localization_Order_By>>;
  where?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
};


export type Subscription_RootLootbox_Booster_Localization_By_PkArgs = {
  booster_name_key: Scalars['String'];
  locale: Scalars['String'];
};


export type Subscription_RootLootbox_Booster_Localization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootbox_Booster_Localization_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootbox_Booster_Localization_Bool_Exp>;
};


export type Subscription_RootLootbox_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Category_Order_By>>;
  where?: InputMaybe<Lootbox_Category_Bool_Exp>;
};


export type Subscription_RootLootbox_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Category_Order_By>>;
  where?: InputMaybe<Lootbox_Category_Bool_Exp>;
};


export type Subscription_RootLootbox_Category_By_PkArgs = {
  category_id: Scalars['Int'];
};


export type Subscription_RootLootbox_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootbox_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootbox_Category_Bool_Exp>;
};


export type Subscription_RootLootbox_Items_SetsArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Items_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Items_Sets_Order_By>>;
  where?: InputMaybe<Lootbox_Items_Sets_Bool_Exp>;
};


export type Subscription_RootLootbox_Items_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Items_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Items_Sets_Order_By>>;
  where?: InputMaybe<Lootbox_Items_Sets_Bool_Exp>;
};


export type Subscription_RootLootbox_Items_Sets_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
  set_id: Scalars['Int'];
};


export type Subscription_RootLootbox_Items_Sets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootbox_Items_Sets_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootbox_Items_Sets_Bool_Exp>;
};


export type Subscription_RootLootbox_ServiceArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Service_Order_By>>;
  where?: InputMaybe<Lootbox_Service_Bool_Exp>;
};


export type Subscription_RootLootbox_Service_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootbox_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootbox_Service_Order_By>>;
  where?: InputMaybe<Lootbox_Service_Bool_Exp>;
};


export type Subscription_RootLootbox_Service_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLootbox_Service_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootbox_Service_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootbox_Service_Bool_Exp>;
};


export type Subscription_RootLootboxesArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Bool_Exp>;
};


export type Subscription_RootLootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Bool_Exp>;
};


export type Subscription_RootLootboxes_Available_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Available_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Available_Statuses_Order_By>>;
  where?: InputMaybe<Lootboxes_Available_Statuses_Bool_Exp>;
};


export type Subscription_RootLootboxes_Available_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Available_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Available_Statuses_Order_By>>;
  where?: InputMaybe<Lootboxes_Available_Statuses_Bool_Exp>;
};


export type Subscription_RootLootboxes_Available_Statuses_By_PkArgs = {
  status_id: Scalars['Int'];
  status_sub_type: Scalars['String'];
};


export type Subscription_RootLootboxes_Available_Statuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Available_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Available_Statuses_Bool_Exp>;
};


export type Subscription_RootLootboxes_BonusArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_Bonus_Generator_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_Generator_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_Generator_Items_By_PkArgs = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};


export type Subscription_RootLootboxes_Bonus_Generator_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Bonus_Generator_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_Generator_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Params_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_Generator_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Params_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_Generator_Params_By_PkArgs = {
  lootbox_generator_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_Bonus_Generator_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Bonus_Generator_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Bonus_Generator_Params_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_Items_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_Bonus_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Bonus_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Bonus_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Bonus_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Bonus_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Bonus_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Bonus_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
};


export type Subscription_RootLootboxes_Bonus_Views_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_Bonus_Views_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Bonus_Views_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Bonus_Views_Bool_Exp>;
};


export type Subscription_RootLootboxes_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_Dynamic_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Dynamic_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Dynamic_Category_Order_By>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Bool_Exp>;
};


export type Subscription_RootLootboxes_Dynamic_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Dynamic_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Dynamic_Category_Order_By>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Bool_Exp>;
};


export type Subscription_RootLootboxes_Dynamic_Category_By_PkArgs = {
  category_id: Scalars['Int'];
};


export type Subscription_RootLootboxes_Dynamic_Category_LootboxesArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>;
};


export type Subscription_RootLootboxes_Dynamic_Category_Lootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Dynamic_Category_Lootboxes_Order_By>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>;
};


export type Subscription_RootLootboxes_Dynamic_Category_Lootboxes_By_PkArgs = {
  category_id: Scalars['Int'];
  lootbox_slug: Scalars['String'];
};


export type Subscription_RootLootboxes_Dynamic_Category_Lootboxes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Lootboxes_Bool_Exp>;
};


export type Subscription_RootLootboxes_Dynamic_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Dynamic_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Dynamic_Category_Bool_Exp>;
};


export type Subscription_RootLootboxes_FreeArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_Free_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Category_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Category_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Category_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Category_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Category_By_PkArgs = {
  category_id: Scalars['Int'];
};


export type Subscription_RootLootboxes_Free_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Free_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Free_Category_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Generator_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Generator_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Generator_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Generator_Items_By_PkArgs = {
  generator_lootbox_id: Scalars['uuid'];
  item_hash_name: Scalars['String'];
};


export type Subscription_RootLootboxes_Free_Generator_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Free_Generator_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Generator_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Params_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Generator_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Params_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Generator_Params_By_PkArgs = {
  lootbox_generator_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_Free_Generator_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Free_Generator_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Free_Generator_Params_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Items_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Items_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_Free_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Free_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Free_Items_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Free_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Free_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Free_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Free_Views_Order_By>>;
  where?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
};


export type Subscription_RootLootboxes_Free_Views_By_PkArgs = {
  item_id: Scalars['uuid'];
  lootbox_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_Free_Views_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Free_Views_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Free_Views_Bool_Exp>;
};


export type Subscription_RootLootboxes_Generator_MechanicsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Generator_Mechanics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Generator_Mechanics_Order_By>>;
  where?: InputMaybe<Lootboxes_Generator_Mechanics_Bool_Exp>;
};


export type Subscription_RootLootboxes_Generator_Mechanics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Generator_Mechanics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Generator_Mechanics_Order_By>>;
  where?: InputMaybe<Lootboxes_Generator_Mechanics_Bool_Exp>;
};


export type Subscription_RootLootboxes_Generator_Mechanics_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLootboxes_Generator_Mechanics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Generator_Mechanics_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Generator_Mechanics_Bool_Exp>;
};


export type Subscription_RootLootboxes_Generator_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Generator_Params_Bool_Exp>;
};


export type Subscription_RootLootboxes_Generator_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Generator_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Generator_Params_Order_By>>;
  where?: InputMaybe<Lootboxes_Generator_Params_Bool_Exp>;
};


export type Subscription_RootLootboxes_Generator_Params_By_PkArgs = {
  parent_lootbox_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_Generator_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Generator_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Generator_Params_Bool_Exp>;
};


export type Subscription_RootLootboxes_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_History_Order_By>>;
  where?: InputMaybe<Lootboxes_History_Bool_Exp>;
};


export type Subscription_RootLootboxes_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_History_Order_By>>;
  where?: InputMaybe<Lootboxes_History_Bool_Exp>;
};


export type Subscription_RootLootboxes_History_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
  lootbox_parent_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_History_Bool_Exp>;
};


export type Subscription_RootLootboxes_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Items_Names_Order_By>>;
  where?: InputMaybe<Lootboxes_Items_Names_Bool_Exp>;
};


export type Subscription_RootLootboxes_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Items_Names_Order_By>>;
  where?: InputMaybe<Lootboxes_Items_Names_Bool_Exp>;
};


export type Subscription_RootLootboxes_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootLootboxes_Items_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Items_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Items_Names_Bool_Exp>;
};


export type Subscription_RootLootboxes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Bool_Exp>;
};


export type Subscription_RootLootboxes_Top_DropArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Top_Drop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Top_Drop_Order_By>>;
  where?: InputMaybe<Lootboxes_Top_Drop_Bool_Exp>;
};


export type Subscription_RootLootboxes_Top_Drop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lootboxes_Top_Drop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lootboxes_Top_Drop_Order_By>>;
  where?: InputMaybe<Lootboxes_Top_Drop_Bool_Exp>;
};


export type Subscription_RootLootboxes_Top_Drop_By_PkArgs = {
  drop_id: Scalars['uuid'];
};


export type Subscription_RootLootboxes_Top_Drop_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lootboxes_Top_Drop_Stream_Cursor_Input>>;
  where?: InputMaybe<Lootboxes_Top_Drop_Bool_Exp>;
};


export type Subscription_RootMail_Autosending_TypesArgs = {
  distinct_on?: InputMaybe<Array<Mail_Autosending_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Autosending_Types_Order_By>>;
  where?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
};


export type Subscription_RootMail_Autosending_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Autosending_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Autosending_Types_Order_By>>;
  where?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
};


export type Subscription_RootMail_Autosending_Types_By_PkArgs = {
  mail_id: Scalars['uuid'];
};


export type Subscription_RootMail_Autosending_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mail_Autosending_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Mail_Autosending_Types_Bool_Exp>;
};


export type Subscription_RootMail_BonusesArgs = {
  distinct_on?: InputMaybe<Array<Mail_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Bonuses_Order_By>>;
  where?: InputMaybe<Mail_Bonuses_Bool_Exp>;
};


export type Subscription_RootMail_Bonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Bonuses_Order_By>>;
  where?: InputMaybe<Mail_Bonuses_Bool_Exp>;
};


export type Subscription_RootMail_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


export type Subscription_RootMail_Bonuses_OffersArgs = {
  distinct_on?: InputMaybe<Array<Mail_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
};


export type Subscription_RootMail_Bonuses_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
};


export type Subscription_RootMail_Bonuses_Offers_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootMail_Bonuses_Offers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mail_Bonuses_Offers_Stream_Cursor_Input>>;
  where?: InputMaybe<Mail_Bonuses_Offers_Bool_Exp>;
};


export type Subscription_RootMail_Bonuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mail_Bonuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Mail_Bonuses_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Callbacks_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Callbacks_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Callbacks_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Callbacks_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Callbacks_By_PkArgs = {
  event_id: Scalars['String'];
};


export type Subscription_RootMail_Mailing_Callbacks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mail_Mailing_Callbacks_Stream_Cursor_Input>>;
  where?: InputMaybe<Mail_Mailing_Callbacks_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_LinesArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Lines_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Lines_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Lines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Lines_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Lines_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Lines_By_PkArgs = {
  mail_id: Scalars['uuid'];
};


export type Subscription_RootMail_Mailing_Lines_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mail_Mailing_Lines_Stream_Cursor_Input>>;
  where?: InputMaybe<Mail_Mailing_Lines_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_ListsArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Lists_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Lists_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Lists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Lists_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Lists_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Lists_By_PkArgs = {
  list_name: Scalars['String'];
};


export type Subscription_RootMail_Mailing_Lists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mail_Mailing_Lists_Stream_Cursor_Input>>;
  where?: InputMaybe<Mail_Mailing_Lists_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_SchedulingArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Scheduling_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Scheduling_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Scheduling_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Scheduling_By_PkArgs = {
  mail_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootMail_Mailing_Scheduling_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mail_Mailing_Scheduling_Stream_Cursor_Input>>;
  where?: InputMaybe<Mail_Mailing_Scheduling_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Sending_LogArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Sending_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Sending_Log_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Sending_Log_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Sending_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Sending_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Sending_Log_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Sending_Log_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Sending_Log_By_PkArgs = {
  mail_mg_id: Scalars['String'];
};


export type Subscription_RootMail_Mailing_Sending_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mail_Mailing_Sending_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Mail_Mailing_Sending_Log_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_TriggersArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Triggers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Triggers_By_PkArgs = {
  trigger_name: Scalars['String'];
  triggers_group_id: Scalars['uuid'];
};


export type Subscription_RootMail_Mailing_Triggers_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Triggers_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Mailing_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Triggers_Groups_By_PkArgs = {
  mail_id: Scalars['uuid'];
  triggers_group_id: Scalars['uuid'];
};


export type Subscription_RootMail_Mailing_Triggers_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mail_Mailing_Triggers_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Groups_Bool_Exp>;
};


export type Subscription_RootMail_Mailing_Triggers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mail_Mailing_Triggers_Stream_Cursor_Input>>;
  where?: InputMaybe<Mail_Mailing_Triggers_Bool_Exp>;
};


export type Subscription_RootMail_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Mail_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Templates_Order_By>>;
  where?: InputMaybe<Mail_Templates_Bool_Exp>;
};


export type Subscription_RootMail_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mail_Templates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mail_Templates_Order_By>>;
  where?: InputMaybe<Mail_Templates_Bool_Exp>;
};


export type Subscription_RootMail_Templates_By_PkArgs = {
  template_name: Scalars['String'];
};


export type Subscription_RootMail_Templates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mail_Templates_Stream_Cursor_Input>>;
  where?: InputMaybe<Mail_Templates_Bool_Exp>;
};


export type Subscription_RootMainpage_CountersArgs = {
  distinct_on?: InputMaybe<Array<Mainpage_Counters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mainpage_Counters_Order_By>>;
  where?: InputMaybe<Mainpage_Counters_Bool_Exp>;
};


export type Subscription_RootMainpage_Counters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mainpage_Counters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mainpage_Counters_Order_By>>;
  where?: InputMaybe<Mainpage_Counters_Bool_Exp>;
};


export type Subscription_RootMainpage_Counters_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMainpage_Counters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mainpage_Counters_Stream_Cursor_Input>>;
  where?: InputMaybe<Mainpage_Counters_Bool_Exp>;
};


export type Subscription_RootMaintenance_ModeArgs = {
  distinct_on?: InputMaybe<Array<Maintenance_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Maintenance_Mode_Order_By>>;
  where?: InputMaybe<Maintenance_Mode_Bool_Exp>;
};


export type Subscription_RootMaintenance_Mode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Maintenance_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Maintenance_Mode_Order_By>>;
  where?: InputMaybe<Maintenance_Mode_Bool_Exp>;
};


export type Subscription_RootMaintenance_Mode_By_PkArgs = {
  mode_type: Scalars['String'];
};


export type Subscription_RootMaintenance_Mode_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Maintenance_Mode_Stream_Cursor_Input>>;
  where?: InputMaybe<Maintenance_Mode_Bool_Exp>;
};


export type Subscription_RootMarket_Groups_ConnArgs = {
  distinct_on?: InputMaybe<Array<Market_Groups_Conn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Market_Groups_Conn_Order_By>>;
  where?: InputMaybe<Market_Groups_Conn_Bool_Exp>;
};


export type Subscription_RootMarket_Groups_Conn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Market_Groups_Conn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Market_Groups_Conn_Order_By>>;
  where?: InputMaybe<Market_Groups_Conn_Bool_Exp>;
};


export type Subscription_RootMarket_Groups_Conn_By_PkArgs = {
  item_hash_name: Scalars['String'];
};


export type Subscription_RootMarket_Groups_Conn_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Market_Groups_Conn_Stream_Cursor_Input>>;
  where?: InputMaybe<Market_Groups_Conn_Bool_Exp>;
};


export type Subscription_RootMetasArgs = {
  distinct_on?: InputMaybe<Array<Metas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metas_Order_By>>;
  where?: InputMaybe<Metas_Bool_Exp>;
};


export type Subscription_RootMetas_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metas_Order_By>>;
  where?: InputMaybe<Metas_Bool_Exp>;
};


export type Subscription_RootMetas_By_PkArgs = {
  meta_global_main_key: Scalars['String'];
  meta_global_sub_key: Scalars['String'];
  meta_key: Scalars['String'];
};


export type Subscription_RootMetas_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Metas_Stream_Cursor_Input>>;
  where?: InputMaybe<Metas_Bool_Exp>;
};


export type Subscription_RootMissionsArgs = {
  distinct_on?: InputMaybe<Array<Missions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Order_By>>;
  where?: InputMaybe<Missions_Bool_Exp>;
};


export type Subscription_RootMissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Order_By>>;
  where?: InputMaybe<Missions_Bool_Exp>;
};


export type Subscription_RootMissions_By_PkArgs = {
  mission_id: Scalars['uuid'];
};


export type Subscription_RootMissions_Onetime_Bonus_Case_ClaimsArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Bonus_Case_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Bonus_Case_Claims_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_Bool_Exp>;
};


export type Subscription_RootMissions_Onetime_Bonus_Case_Claims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Bonus_Case_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Bonus_Case_Claims_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_Bool_Exp>;
};


export type Subscription_RootMissions_Onetime_Bonus_Case_Claims_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootMissions_Onetime_Bonus_Case_Claims_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Missions_Onetime_Bonus_Case_Claims_Stream_Cursor_Input>>;
  where?: InputMaybe<Missions_Onetime_Bonus_Case_Claims_Bool_Exp>;
};


export type Subscription_RootMissions_Onetime_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Params_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Params_Bool_Exp>;
};


export type Subscription_RootMissions_Onetime_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Params_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Params_Bool_Exp>;
};


export type Subscription_RootMissions_Onetime_Params_By_PkArgs = {
  mission_id: Scalars['uuid'];
};


export type Subscription_RootMissions_Onetime_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Missions_Onetime_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Missions_Onetime_Params_Bool_Exp>;
};


export type Subscription_RootMissions_Onetime_Users_ProgressArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Users_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Users_Progress_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
};


export type Subscription_RootMissions_Onetime_Users_Progress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Onetime_Users_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Onetime_Users_Progress_Order_By>>;
  where?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
};


export type Subscription_RootMissions_Onetime_Users_Progress_By_PkArgs = {
  mission_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootMissions_Onetime_Users_Progress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Missions_Onetime_Users_Progress_Stream_Cursor_Input>>;
  where?: InputMaybe<Missions_Onetime_Users_Progress_Bool_Exp>;
};


export type Subscription_RootMissions_Permanent_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Missions_Permanent_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Permanent_Params_Order_By>>;
  where?: InputMaybe<Missions_Permanent_Params_Bool_Exp>;
};


export type Subscription_RootMissions_Permanent_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Permanent_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Permanent_Params_Order_By>>;
  where?: InputMaybe<Missions_Permanent_Params_Bool_Exp>;
};


export type Subscription_RootMissions_Permanent_Params_By_PkArgs = {
  mission_id: Scalars['uuid'];
};


export type Subscription_RootMissions_Permanent_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Missions_Permanent_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Missions_Permanent_Params_Bool_Exp>;
};


export type Subscription_RootMissions_Permanent_User_ProgressArgs = {
  distinct_on?: InputMaybe<Array<Missions_Permanent_User_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Permanent_User_Progress_Order_By>>;
  where?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
};


export type Subscription_RootMissions_Permanent_User_Progress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Permanent_User_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Permanent_User_Progress_Order_By>>;
  where?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
};


export type Subscription_RootMissions_Permanent_User_Progress_By_PkArgs = {
  mission_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootMissions_Permanent_User_Progress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Missions_Permanent_User_Progress_Stream_Cursor_Input>>;
  where?: InputMaybe<Missions_Permanent_User_Progress_Bool_Exp>;
};


export type Subscription_RootMissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Missions_Stream_Cursor_Input>>;
  where?: InputMaybe<Missions_Bool_Exp>;
};


export type Subscription_RootMissions_TagsArgs = {
  distinct_on?: InputMaybe<Array<Missions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Tags_Order_By>>;
  where?: InputMaybe<Missions_Tags_Bool_Exp>;
};


export type Subscription_RootMissions_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Tags_Order_By>>;
  where?: InputMaybe<Missions_Tags_Bool_Exp>;
};


export type Subscription_RootMissions_Tags_By_PkArgs = {
  mission_id: Scalars['uuid'];
  mission_tag: Scalars['String'];
};


export type Subscription_RootMissions_Tags_NamesArgs = {
  distinct_on?: InputMaybe<Array<Missions_Tags_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Tags_Names_Order_By>>;
  where?: InputMaybe<Missions_Tags_Names_Bool_Exp>;
};


export type Subscription_RootMissions_Tags_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Tags_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Tags_Names_Order_By>>;
  where?: InputMaybe<Missions_Tags_Names_Bool_Exp>;
};


export type Subscription_RootMissions_Tags_Names_By_PkArgs = {
  tag_title: Scalars['String'];
};


export type Subscription_RootMissions_Tags_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Missions_Tags_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Missions_Tags_Names_Bool_Exp>;
};


export type Subscription_RootMissions_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Missions_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Missions_Tags_Bool_Exp>;
};


export type Subscription_RootMissions_Vk_LinksArgs = {
  distinct_on?: InputMaybe<Array<Missions_Vk_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Vk_Links_Order_By>>;
  where?: InputMaybe<Missions_Vk_Links_Bool_Exp>;
};


export type Subscription_RootMissions_Vk_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Missions_Vk_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Missions_Vk_Links_Order_By>>;
  where?: InputMaybe<Missions_Vk_Links_Bool_Exp>;
};


export type Subscription_RootMissions_Vk_Links_By_PkArgs = {
  vk_id: Scalars['String'];
};


export type Subscription_RootMissions_Vk_Links_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Missions_Vk_Links_Stream_Cursor_Input>>;
  where?: InputMaybe<Missions_Vk_Links_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Boosters_RewardsArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Boosters_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Boosters_Rewards_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Boosters_Rewards_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Boosters_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Boosters_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Boosters_Rewards_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Boosters_Rewards_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Boosters_Rewards_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootNew_Year_Competition_Boosters_Rewards_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<New_Year_Competition_Boosters_Rewards_Stream_Cursor_Input>>;
  where?: InputMaybe<New_Year_Competition_Boosters_Rewards_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootNew_Year_Competition_Participants_PerformanceArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Performance_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Participants_Performance_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootNew_Year_Competition_Participants_Performance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<New_Year_Competition_Participants_Performance_Stream_Cursor_Input>>;
  where?: InputMaybe<New_Year_Competition_Participants_Performance_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Participants_RatingArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Rating_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Rating_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Participants_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Rating_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Rating_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Participants_Rating_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<New_Year_Competition_Participants_Rating_Stream_Cursor_Input>>;
  where?: InputMaybe<New_Year_Competition_Participants_Rating_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Participants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<New_Year_Competition_Participants_Stream_Cursor_Input>>;
  where?: InputMaybe<New_Year_Competition_Participants_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_TeamsArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Teams_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Teams_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Teams_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Teams_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Teams_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootNew_Year_Competition_Teams_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<New_Year_Competition_Teams_Stream_Cursor_Input>>;
  where?: InputMaybe<New_Year_Competition_Teams_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Winner_TeamArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Winner_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Winner_Team_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Winner_Team_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Winner_Team_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Winner_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Winner_Team_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Winner_Team_Bool_Exp>;
};


export type Subscription_RootNew_Year_Competition_Winner_Team_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<New_Year_Competition_Winner_Team_Stream_Cursor_Input>>;
  where?: InputMaybe<New_Year_Competition_Winner_Team_Bool_Exp>;
};


export type Subscription_RootOperation_TypesArgs = {
  distinct_on?: InputMaybe<Array<Operation_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Types_Order_By>>;
  where?: InputMaybe<Operation_Types_Bool_Exp>;
};


export type Subscription_RootOperation_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operation_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Types_Order_By>>;
  where?: InputMaybe<Operation_Types_Bool_Exp>;
};


export type Subscription_RootOperation_Types_By_PkArgs = {
  operation_id: Scalars['Int'];
};


export type Subscription_RootOperation_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Operation_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Operation_Types_Bool_Exp>;
};


export type Subscription_RootParsers_Timestamp_LogArgs = {
  distinct_on?: InputMaybe<Array<Parsers_Timestamp_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parsers_Timestamp_Log_Order_By>>;
  where?: InputMaybe<Parsers_Timestamp_Log_Bool_Exp>;
};


export type Subscription_RootParsers_Timestamp_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parsers_Timestamp_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parsers_Timestamp_Log_Order_By>>;
  where?: InputMaybe<Parsers_Timestamp_Log_Bool_Exp>;
};


export type Subscription_RootParsers_Timestamp_Log_By_PkArgs = {
  parser_name: Scalars['String'];
};


export type Subscription_RootParsers_Timestamp_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Parsers_Timestamp_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Parsers_Timestamp_Log_Bool_Exp>;
};


export type Subscription_RootParticipants_Performance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Performance_Bool_Exp>;
};


export type Subscription_RootPartner_Balance_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balance_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_Balance_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Balance_Transactions_Order_By>>;
  where?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_Balance_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Subscription_RootPartner_Balance_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Balance_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Balance_Transactions_Bool_Exp>;
};


export type Subscription_RootPartner_Offer_TypesArgs = {
  distinct_on?: InputMaybe<Array<Partner_Offer_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Offer_Types_Order_By>>;
  where?: InputMaybe<Partner_Offer_Types_Bool_Exp>;
};


export type Subscription_RootPartner_Offer_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Offer_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Offer_Types_Order_By>>;
  where?: InputMaybe<Partner_Offer_Types_Bool_Exp>;
};


export type Subscription_RootPartner_Offer_Types_By_PkArgs = {
  type_id: Scalars['Int'];
};


export type Subscription_RootPartner_Offer_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Offer_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Offer_Types_Bool_Exp>;
};


export type Subscription_RootPartner_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Partner_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Params_Order_By>>;
  where?: InputMaybe<Partner_Params_Bool_Exp>;
};


export type Subscription_RootPartner_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Params_Order_By>>;
  where?: InputMaybe<Partner_Params_Bool_Exp>;
};


export type Subscription_RootPartner_Params_AuditArgs = {
  distinct_on?: InputMaybe<Array<Partner_Params_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Params_Audit_Order_By>>;
  where?: InputMaybe<Partner_Params_Audit_Bool_Exp>;
};


export type Subscription_RootPartner_Params_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Partner_Params_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Params_Audit_Order_By>>;
  where?: InputMaybe<Partner_Params_Audit_Bool_Exp>;
};


export type Subscription_RootPartner_Params_Audit_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Subscription_RootPartner_Params_Audit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Params_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Params_Audit_Bool_Exp>;
};


export type Subscription_RootPartner_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootPartner_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Params_Bool_Exp>;
};


export type Subscription_RootPassport_InfoArgs = {
  distinct_on?: InputMaybe<Array<Passport_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Passport_Info_Order_By>>;
  where?: InputMaybe<Passport_Info_Bool_Exp>;
};


export type Subscription_RootPassport_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Passport_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Passport_Info_Order_By>>;
  where?: InputMaybe<Passport_Info_Bool_Exp>;
};


export type Subscription_RootPassport_Info_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPassport_Info_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Passport_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<Passport_Info_Bool_Exp>;
};


export type Subscription_RootPayment_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Methods_Order_By>>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};


export type Subscription_RootPayment_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Methods_Order_By>>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};


export type Subscription_RootPayment_Methods_By_PkArgs = {
  pm_method_id: Scalars['Int'];
};


export type Subscription_RootPayment_Methods_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Methods_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Methods_Bool_Exp>;
};


export type Subscription_RootPayment_Methods_ViewArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Methods_View_Order_By>>;
  where?: InputMaybe<Payment_Methods_View_Bool_Exp>;
};


export type Subscription_RootPayment_Methods_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Methods_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Methods_View_Order_By>>;
  where?: InputMaybe<Payment_Methods_View_Bool_Exp>;
};


export type Subscription_RootPayment_Methods_View_By_PkArgs = {
  method_id: Scalars['uuid'];
};


export type Subscription_RootPayment_Methods_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Methods_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Methods_View_Bool_Exp>;
};


export type Subscription_RootPayment_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Payment_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Providers_Order_By>>;
  where?: InputMaybe<Payment_Providers_Bool_Exp>;
};


export type Subscription_RootPayment_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Providers_Order_By>>;
  where?: InputMaybe<Payment_Providers_Bool_Exp>;
};


export type Subscription_RootPayment_Providers_By_PkArgs = {
  ps_id: Scalars['uuid'];
};


export type Subscription_RootPayment_Providers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Providers_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Providers_Bool_Exp>;
};


export type Subscription_RootPayment_Recurring_TokensArgs = {
  distinct_on?: InputMaybe<Array<Payment_Recurring_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Recurring_Tokens_Order_By>>;
  where?: InputMaybe<Payment_Recurring_Tokens_Bool_Exp>;
};


export type Subscription_RootPayment_Recurring_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Recurring_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Recurring_Tokens_Order_By>>;
  where?: InputMaybe<Payment_Recurring_Tokens_Bool_Exp>;
};


export type Subscription_RootPayment_Recurring_Tokens_By_PkArgs = {
  pmrecur_id: Scalars['uuid'];
};


export type Subscription_RootPayment_Recurring_Tokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Recurring_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Recurring_Tokens_Bool_Exp>;
};


export type Subscription_RootPayment_RequisitesArgs = {
  distinct_on?: InputMaybe<Array<Payment_Requisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Requisites_Order_By>>;
  where?: InputMaybe<Payment_Requisites_Bool_Exp>;
};


export type Subscription_RootPayment_Requisites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Requisites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Requisites_Order_By>>;
  where?: InputMaybe<Payment_Requisites_Bool_Exp>;
};


export type Subscription_RootPayment_Requisites_By_PkArgs = {
  pmrq_id: Scalars['uuid'];
};


export type Subscription_RootPayment_Requisites_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Requisites_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Requisites_Bool_Exp>;
};


export type Subscription_RootPayment_System_1payment_Transaction_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_1payment_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_1payment_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_1payment_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_System_1payment_Transaction_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_1payment_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_1payment_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_1payment_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_System_1payment_Transaction_Requests_By_PkArgs = {
  payment_transaction_id: Scalars['uuid'];
};


export type Subscription_RootPayment_System_1payment_Transaction_Requests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_System_1payment_Transaction_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_System_1payment_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_System_Free_Kassa_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Free_Kassa_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Free_Kassa_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_System_Free_Kassa_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Free_Kassa_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Free_Kassa_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_System_Free_Kassa_Callbacks_By_PkArgs = {
  MERCHANT_ORDER_ID: Scalars['uuid'];
};


export type Subscription_RootPayment_System_Free_Kassa_Callbacks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_System_Free_Kassa_Callbacks_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_System_Free_Kassa_Transaction_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Free_Kassa_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Free_Kassa_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_System_Free_Kassa_Transaction_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Free_Kassa_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Free_Kassa_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_System_Free_Kassa_Transaction_Requests_By_PkArgs = {
  o: Scalars['uuid'];
};


export type Subscription_RootPayment_System_Free_Kassa_Transaction_Requests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_System_Free_Kassa_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_System_Main_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Main_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Main_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_System_Main_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_System_Main_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Main_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Main_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_System_Main_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_System_Main_Callbacks_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Subscription_RootPayment_System_Main_Callbacks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_System_Main_Callbacks_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_System_Main_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_System_Main_Transaction_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Main_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Main_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_Main_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_System_Main_Transaction_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_System_Main_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_System_Main_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_System_Main_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_System_Main_Transaction_Requests_By_PkArgs = {
  orderId: Scalars['String'];
};


export type Subscription_RootPayment_System_Main_Transaction_Requests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_System_Main_Transaction_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_System_Main_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_1payment_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_1payment_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_1payment_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_Systems_1payment_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_1payment_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_1payment_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_1payment_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_Systems_1payment_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_1payment_Callbacks_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Subscription_RootPayment_Systems_1payment_Callbacks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Systems_1payment_Callbacks_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Systems_1payment_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_Systems_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Callbacks_Order_By>>;
  where?: InputMaybe<Payment_Systems_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_Callbacks_By_PkArgs = {
  transaction_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootPayment_Systems_Callbacks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Systems_Callbacks_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Systems_Callbacks_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_DataArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Data_Order_By>>;
  where?: InputMaybe<Payment_Systems_Data_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Data_Order_By>>;
  where?: InputMaybe<Payment_Systems_Data_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_Data_By_PkArgs = {
  ps_id: Scalars['uuid'];
};


export type Subscription_RootPayment_Systems_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Systems_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Systems_Data_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Methods_Order_By>>;
  where?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Methods_Order_By>>;
  where?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_Methods_By_PkArgs = {
  pm_id: Scalars['Int'];
  pm_ps_type: Scalars['Int'];
};


export type Subscription_RootPayment_Systems_Methods_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Systems_Methods_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Systems_Methods_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_Transaction_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_Systems_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_Transaction_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Systems_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Systems_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Payment_Systems_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_Systems_Transaction_Requests_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Subscription_RootPayment_Systems_Transaction_Requests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Systems_Transaction_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Systems_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootPayment_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Transactions_Order_By>>;
  where?: InputMaybe<Payment_Transactions_Bool_Exp>;
};


export type Subscription_RootPayment_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Transactions_Order_By>>;
  where?: InputMaybe<Payment_Transactions_Bool_Exp>;
};


export type Subscription_RootPayment_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Subscription_RootPayment_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Transactions_Bool_Exp>;
};


export type Subscription_RootProfile_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Profile_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Items_Names_Order_By>>;
  where?: InputMaybe<Profile_Items_Names_Bool_Exp>;
};


export type Subscription_RootProfile_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Items_Names_Order_By>>;
  where?: InputMaybe<Profile_Items_Names_Bool_Exp>;
};


export type Subscription_RootProfile_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootProfile_Items_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Profile_Items_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Profile_Items_Names_Bool_Exp>;
};


export type Subscription_RootPromo_LootboxesArgs = {
  distinct_on?: InputMaybe<Array<Promo_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Lootboxes_Order_By>>;
  where?: InputMaybe<Promo_Lootboxes_Bool_Exp>;
};


export type Subscription_RootPromo_Lootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Lootboxes_Order_By>>;
  where?: InputMaybe<Promo_Lootboxes_Bool_Exp>;
};


export type Subscription_RootPromo_Lootboxes_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
};


export type Subscription_RootPromo_Lootboxes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Promo_Lootboxes_Stream_Cursor_Input>>;
  where?: InputMaybe<Promo_Lootboxes_Bool_Exp>;
};


export type Subscription_RootPromo_UsersArgs = {
  distinct_on?: InputMaybe<Array<Promo_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Users_Order_By>>;
  where?: InputMaybe<Promo_Users_Bool_Exp>;
};


export type Subscription_RootPromo_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Users_Order_By>>;
  where?: InputMaybe<Promo_Users_Bool_Exp>;
};


export type Subscription_RootPromo_Users_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootPromo_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Promo_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Promo_Users_Bool_Exp>;
};


export type Subscription_RootPromo_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Promo_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Values_Order_By>>;
  where?: InputMaybe<Promo_Values_Bool_Exp>;
};


export type Subscription_RootPromo_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promo_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promo_Values_Order_By>>;
  where?: InputMaybe<Promo_Values_Bool_Exp>;
};


export type Subscription_RootPromo_Values_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPromo_Values_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Promo_Values_Stream_Cursor_Input>>;
  where?: InputMaybe<Promo_Values_Bool_Exp>;
};


export type Subscription_RootPromocode_ActivationsArgs = {
  distinct_on?: InputMaybe<Array<Promocode_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promocode_Activations_Order_By>>;
  where?: InputMaybe<Promocode_Activations_Bool_Exp>;
};


export type Subscription_RootPromocode_Activations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promocode_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promocode_Activations_Order_By>>;
  where?: InputMaybe<Promocode_Activations_Bool_Exp>;
};


export type Subscription_RootPromocode_Activations_By_PkArgs = {
  activation_id: Scalars['uuid'];
};


export type Subscription_RootPromocode_Activations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Promocode_Activations_Stream_Cursor_Input>>;
  where?: InputMaybe<Promocode_Activations_Bool_Exp>;
};


export type Subscription_RootPromocodesArgs = {
  distinct_on?: InputMaybe<Array<Promocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promocodes_Order_By>>;
  where?: InputMaybe<Promocodes_Bool_Exp>;
};


export type Subscription_RootPromocodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Promocodes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Promocodes_Order_By>>;
  where?: InputMaybe<Promocodes_Bool_Exp>;
};


export type Subscription_RootPromocodes_By_PkArgs = {
  promocode_id: Scalars['uuid'];
};


export type Subscription_RootPromocodes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Promocodes_Stream_Cursor_Input>>;
  where?: InputMaybe<Promocodes_Bool_Exp>;
};


export type Subscription_RootPs_TypesArgs = {
  distinct_on?: InputMaybe<Array<Ps_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ps_Types_Order_By>>;
  where?: InputMaybe<Ps_Types_Bool_Exp>;
};


export type Subscription_RootPs_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ps_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ps_Types_Order_By>>;
  where?: InputMaybe<Ps_Types_Bool_Exp>;
};


export type Subscription_RootPs_Types_By_PkArgs = {
  ps_id: Scalars['Int'];
};


export type Subscription_RootPs_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ps_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Ps_Types_Bool_Exp>;
};


export type Subscription_RootQservice_DataArgs = {
  distinct_on?: InputMaybe<Array<Qservice_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Qservice_Data_Order_By>>;
  where?: InputMaybe<Qservice_Data_Bool_Exp>;
};


export type Subscription_RootQservice_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Qservice_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Qservice_Data_Order_By>>;
  where?: InputMaybe<Qservice_Data_Bool_Exp>;
};


export type Subscription_RootQservice_Data_By_PkArgs = {
  request_id: Scalars['uuid'];
};


export type Subscription_RootQservice_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Qservice_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Qservice_Data_Bool_Exp>;
};


export type Subscription_RootStatistic_Bonus_Wheels_Users_OffersArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Bonus_Wheels_Users_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Bonus_Wheels_Users_Offers_Order_By>>;
  where?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Bool_Exp>;
};


export type Subscription_RootStatistic_Bonus_Wheels_Users_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Bonus_Wheels_Users_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Bonus_Wheels_Users_Offers_Order_By>>;
  where?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Bool_Exp>;
};


export type Subscription_RootStatistic_Bonus_Wheels_Users_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
};


export type Subscription_RootStatistic_Bonus_Wheels_Users_Offers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Bonus_Wheels_Users_Offers_Bool_Exp>;
};


export type Subscription_RootStatistic_Button_Again_PressArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Button_Again_Press_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Button_Again_Press_Order_By>>;
  where?: InputMaybe<Statistic_Button_Again_Press_Bool_Exp>;
};


export type Subscription_RootStatistic_Button_Again_Press_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Button_Again_Press_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Button_Again_Press_Order_By>>;
  where?: InputMaybe<Statistic_Button_Again_Press_Bool_Exp>;
};


export type Subscription_RootStatistic_Button_Again_Press_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStatistic_Button_Again_Press_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Button_Again_Press_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Button_Again_Press_Bool_Exp>;
};


export type Subscription_RootStatistic_Fast_Roll_TriggerArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Fast_Roll_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Fast_Roll_Trigger_Order_By>>;
  where?: InputMaybe<Statistic_Fast_Roll_Trigger_Bool_Exp>;
};


export type Subscription_RootStatistic_Fast_Roll_Trigger_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Fast_Roll_Trigger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Fast_Roll_Trigger_Order_By>>;
  where?: InputMaybe<Statistic_Fast_Roll_Trigger_Bool_Exp>;
};


export type Subscription_RootStatistic_Fast_Roll_Trigger_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStatistic_Fast_Roll_Trigger_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Fast_Roll_Trigger_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Fast_Roll_Trigger_Bool_Exp>;
};


export type Subscription_RootStatistic_Inventory_OpensArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Inventory_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Inventory_Opens_Order_By>>;
  where?: InputMaybe<Statistic_Inventory_Opens_Bool_Exp>;
};


export type Subscription_RootStatistic_Inventory_Opens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Inventory_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Inventory_Opens_Order_By>>;
  where?: InputMaybe<Statistic_Inventory_Opens_Bool_Exp>;
};


export type Subscription_RootStatistic_Inventory_Opens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStatistic_Inventory_Opens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Inventory_Opens_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Inventory_Opens_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_DropsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Drops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Drops_Order_By>>;
  where?: InputMaybe<Statistic_Items_Drops_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_Drops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Drops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Drops_Order_By>>;
  where?: InputMaybe<Statistic_Items_Drops_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_Drops_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStatistic_Items_Drops_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Items_Drops_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Items_Drops_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_NamesArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Names_Order_By>>;
  where?: InputMaybe<Statistic_Items_Names_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Names_Order_By>>;
  where?: InputMaybe<Statistic_Items_Names_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_Names_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootStatistic_Items_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Items_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Items_Names_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_Output_FailArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Output_Fail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Output_Fail_Order_By>>;
  where?: InputMaybe<Statistic_Items_Output_Fail_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_Output_Fail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Output_Fail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Output_Fail_Order_By>>;
  where?: InputMaybe<Statistic_Items_Output_Fail_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_Output_Fail_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootStatistic_Items_Output_Fail_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Items_Output_Fail_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Items_Output_Fail_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_Output_SuccessArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Output_Success_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Output_Success_Order_By>>;
  where?: InputMaybe<Statistic_Items_Output_Success_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_Output_Success_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Items_Output_Success_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Items_Output_Success_Order_By>>;
  where?: InputMaybe<Statistic_Items_Output_Success_Bool_Exp>;
};


export type Subscription_RootStatistic_Items_Output_Success_By_PkArgs = {
  user_item_id: Scalars['uuid'];
};


export type Subscription_RootStatistic_Items_Output_Success_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Items_Output_Success_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Items_Output_Success_Bool_Exp>;
};


export type Subscription_RootStatistic_Lootbox_OpensArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Lootbox_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Lootbox_Opens_Order_By>>;
  where?: InputMaybe<Statistic_Lootbox_Opens_Bool_Exp>;
};


export type Subscription_RootStatistic_Lootbox_Opens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Lootbox_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Lootbox_Opens_Order_By>>;
  where?: InputMaybe<Statistic_Lootbox_Opens_Bool_Exp>;
};


export type Subscription_RootStatistic_Lootbox_Opens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStatistic_Lootbox_Opens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Lootbox_Opens_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Lootbox_Opens_Bool_Exp>;
};


export type Subscription_RootStatistic_Lootbox_ViewsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Lootbox_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Lootbox_Views_Order_By>>;
  where?: InputMaybe<Statistic_Lootbox_Views_Bool_Exp>;
};


export type Subscription_RootStatistic_Lootbox_Views_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Lootbox_Views_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Lootbox_Views_Order_By>>;
  where?: InputMaybe<Statistic_Lootbox_Views_Bool_Exp>;
};


export type Subscription_RootStatistic_Lootbox_Views_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStatistic_Lootbox_Views_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Lootbox_Views_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Lootbox_Views_Bool_Exp>;
};


export type Subscription_RootStatistic_Mainpage_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Mainpage_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Mainpage_Visits_Order_By>>;
  where?: InputMaybe<Statistic_Mainpage_Visits_Bool_Exp>;
};


export type Subscription_RootStatistic_Mainpage_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Mainpage_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Mainpage_Visits_Order_By>>;
  where?: InputMaybe<Statistic_Mainpage_Visits_Bool_Exp>;
};


export type Subscription_RootStatistic_Mainpage_Visits_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStatistic_Mainpage_Visits_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Mainpage_Visits_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Mainpage_Visits_Bool_Exp>;
};


export type Subscription_RootStatistic_Promocode_Full_ActivationsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Promocode_Full_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Promocode_Full_Activations_Order_By>>;
  where?: InputMaybe<Statistic_Promocode_Full_Activations_Bool_Exp>;
};


export type Subscription_RootStatistic_Promocode_Full_Activations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Promocode_Full_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Promocode_Full_Activations_Order_By>>;
  where?: InputMaybe<Statistic_Promocode_Full_Activations_Bool_Exp>;
};


export type Subscription_RootStatistic_Promocode_Full_Activations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootStatistic_Promocode_Full_Activations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Promocode_Full_Activations_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Promocode_Full_Activations_Bool_Exp>;
};


export type Subscription_RootStatistic_Promocode_Success_ActivationsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Promocode_Success_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Promocode_Success_Activations_Order_By>>;
  where?: InputMaybe<Statistic_Promocode_Success_Activations_Bool_Exp>;
};


export type Subscription_RootStatistic_Promocode_Success_Activations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Promocode_Success_Activations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Promocode_Success_Activations_Order_By>>;
  where?: InputMaybe<Statistic_Promocode_Success_Activations_Bool_Exp>;
};


export type Subscription_RootStatistic_Promocode_Success_Activations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootStatistic_Promocode_Success_Activations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Promocode_Success_Activations_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Promocode_Success_Activations_Bool_Exp>;
};


export type Subscription_RootStatistic_Sell_All_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Sell_All_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Sell_All_Items_Order_By>>;
  where?: InputMaybe<Statistic_Sell_All_Items_Bool_Exp>;
};


export type Subscription_RootStatistic_Sell_All_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Sell_All_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Sell_All_Items_Order_By>>;
  where?: InputMaybe<Statistic_Sell_All_Items_Bool_Exp>;
};


export type Subscription_RootStatistic_Sell_All_Items_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStatistic_Sell_All_Items_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Sell_All_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Sell_All_Items_Bool_Exp>;
};


export type Subscription_RootStatistic_Sell_ItemArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Sell_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Sell_Item_Order_By>>;
  where?: InputMaybe<Statistic_Sell_Item_Bool_Exp>;
};


export type Subscription_RootStatistic_Sell_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_Sell_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_Sell_Item_Order_By>>;
  where?: InputMaybe<Statistic_Sell_Item_Bool_Exp>;
};


export type Subscription_RootStatistic_Sell_Item_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStatistic_Sell_Item_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_Sell_Item_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_Sell_Item_Bool_Exp>;
};


export type Subscription_RootStatistic_User_Deposit_Success_CallbacksArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Deposit_Success_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Deposit_Success_Callbacks_Order_By>>;
  where?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Bool_Exp>;
};


export type Subscription_RootStatistic_User_Deposit_Success_Callbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Deposit_Success_Callbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Deposit_Success_Callbacks_Order_By>>;
  where?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Bool_Exp>;
};


export type Subscription_RootStatistic_User_Deposit_Success_Callbacks_By_PkArgs = {
  transaction_id: Scalars['String'];
};


export type Subscription_RootStatistic_User_Deposit_Success_Callbacks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_User_Deposit_Success_Callbacks_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_User_Deposit_Success_Callbacks_Bool_Exp>;
};


export type Subscription_RootStatistic_User_Deposits_Transaction_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Deposits_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Deposits_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootStatistic_User_Deposits_Transaction_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Deposits_Transaction_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Deposits_Transaction_Requests_Order_By>>;
  where?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootStatistic_User_Deposits_Transaction_Requests_By_PkArgs = {
  transaction_id: Scalars['String'];
};


export type Subscription_RootStatistic_User_Deposits_Transaction_Requests_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_User_Deposits_Transaction_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_User_Deposits_Transaction_Requests_Bool_Exp>;
};


export type Subscription_RootStatistic_User_Free_Lootboxes_OpensArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Free_Lootboxes_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Free_Lootboxes_Opens_Order_By>>;
  where?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Bool_Exp>;
};


export type Subscription_RootStatistic_User_Free_Lootboxes_Opens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Free_Lootboxes_Opens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Free_Lootboxes_Opens_Order_By>>;
  where?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Bool_Exp>;
};


export type Subscription_RootStatistic_User_Free_Lootboxes_Opens_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootStatistic_User_Free_Lootboxes_Opens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_User_Free_Lootboxes_Opens_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_User_Free_Lootboxes_Opens_Bool_Exp>;
};


export type Subscription_RootStatistic_User_RegistrationArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Registration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Registration_Order_By>>;
  where?: InputMaybe<Statistic_User_Registration_Bool_Exp>;
};


export type Subscription_RootStatistic_User_Registration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Statistic_User_Registration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Statistic_User_Registration_Order_By>>;
  where?: InputMaybe<Statistic_User_Registration_Bool_Exp>;
};


export type Subscription_RootStatistic_User_Registration_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootStatistic_User_Registration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Statistic_User_Registration_Stream_Cursor_Input>>;
  where?: InputMaybe<Statistic_User_Registration_Bool_Exp>;
};


export type Subscription_RootStocksArgs = {
  distinct_on?: InputMaybe<Array<Stocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stocks_Order_By>>;
  where?: InputMaybe<Stocks_Bool_Exp>;
};


export type Subscription_RootStocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stocks_Order_By>>;
  where?: InputMaybe<Stocks_Bool_Exp>;
};


export type Subscription_RootStocks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStocks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stocks_Stream_Cursor_Input>>;
  where?: InputMaybe<Stocks_Bool_Exp>;
};


export type Subscription_RootStore_Offer_BadgesArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Badges_Order_By>>;
  where?: InputMaybe<Store_Offer_Badges_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Badges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Badges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Badges_Order_By>>;
  where?: InputMaybe<Store_Offer_Badges_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Badges_By_PkArgs = {
  badge_value: Scalars['String'];
};


export type Subscription_RootStore_Offer_Badges_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Store_Offer_Badges_Stream_Cursor_Input>>;
  where?: InputMaybe<Store_Offer_Badges_Bool_Exp>;
};


export type Subscription_RootStore_Offer_BoostersArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Boosters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Boosters_Order_By>>;
  where?: InputMaybe<Store_Offer_Boosters_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Boosters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Boosters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Boosters_Order_By>>;
  where?: InputMaybe<Store_Offer_Boosters_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Boosters_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


export type Subscription_RootStore_Offer_Boosters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Store_Offer_Boosters_Stream_Cursor_Input>>;
  where?: InputMaybe<Store_Offer_Boosters_Bool_Exp>;
};


export type Subscription_RootStore_Offer_CoinsArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Coins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Coins_Order_By>>;
  where?: InputMaybe<Store_Offer_Coins_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Coins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Coins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Coins_Order_By>>;
  where?: InputMaybe<Store_Offer_Coins_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Coins_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


export type Subscription_RootStore_Offer_Coins_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Store_Offer_Coins_Stream_Cursor_Input>>;
  where?: InputMaybe<Store_Offer_Coins_Bool_Exp>;
};


export type Subscription_RootStore_Offer_LootboxesArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Lootboxes_Order_By>>;
  where?: InputMaybe<Store_Offer_Lootboxes_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Lootboxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Lootboxes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Lootboxes_Order_By>>;
  where?: InputMaybe<Store_Offer_Lootboxes_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Lootboxes_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


export type Subscription_RootStore_Offer_Lootboxes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Store_Offer_Lootboxes_Stream_Cursor_Input>>;
  where?: InputMaybe<Store_Offer_Lootboxes_Bool_Exp>;
};


export type Subscription_RootStore_Offer_SalesArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Sales_Order_By>>;
  where?: InputMaybe<Store_Offer_Sales_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Sales_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Sales_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Sales_Order_By>>;
  where?: InputMaybe<Store_Offer_Sales_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Sales_By_PkArgs = {
  sale_id: Scalars['uuid'];
};


export type Subscription_RootStore_Offer_Sales_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Store_Offer_Sales_Stream_Cursor_Input>>;
  where?: InputMaybe<Store_Offer_Sales_Bool_Exp>;
};


export type Subscription_RootStore_Offer_SkinsArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Skins_Order_By>>;
  where?: InputMaybe<Store_Offer_Skins_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Skins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Skins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Skins_Order_By>>;
  where?: InputMaybe<Store_Offer_Skins_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Skins_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


export type Subscription_RootStore_Offer_Skins_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Store_Offer_Skins_Stream_Cursor_Input>>;
  where?: InputMaybe<Store_Offer_Skins_Bool_Exp>;
};


export type Subscription_RootStore_Offer_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Tickets_Order_By>>;
  where?: InputMaybe<Store_Offer_Tickets_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Tickets_Order_By>>;
  where?: InputMaybe<Store_Offer_Tickets_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Tickets_By_PkArgs = {
  offer_item_id: Scalars['uuid'];
};


export type Subscription_RootStore_Offer_Tickets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Store_Offer_Tickets_Stream_Cursor_Input>>;
  where?: InputMaybe<Store_Offer_Tickets_Bool_Exp>;
};


export type Subscription_RootStore_Offer_TypesArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Types_Order_By>>;
  where?: InputMaybe<Store_Offer_Types_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offer_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offer_Types_Order_By>>;
  where?: InputMaybe<Store_Offer_Types_Bool_Exp>;
};


export type Subscription_RootStore_Offer_Types_By_PkArgs = {
  type_value: Scalars['String'];
};


export type Subscription_RootStore_Offer_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Store_Offer_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Store_Offer_Types_Bool_Exp>;
};


export type Subscription_RootStore_OffersArgs = {
  distinct_on?: InputMaybe<Array<Store_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offers_Order_By>>;
  where?: InputMaybe<Store_Offers_Bool_Exp>;
};


export type Subscription_RootStore_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Offers_Order_By>>;
  where?: InputMaybe<Store_Offers_Bool_Exp>;
};


export type Subscription_RootStore_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
};


export type Subscription_RootStore_Offers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Store_Offers_Stream_Cursor_Input>>;
  where?: InputMaybe<Store_Offers_Bool_Exp>;
};


export type Subscription_RootSurvey_Data_LinksArgs = {
  distinct_on?: InputMaybe<Array<Survey_Data_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Data_Links_Order_By>>;
  where?: InputMaybe<Survey_Data_Links_Bool_Exp>;
};


export type Subscription_RootSurvey_Data_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Data_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Data_Links_Order_By>>;
  where?: InputMaybe<Survey_Data_Links_Bool_Exp>;
};


export type Subscription_RootSurvey_Data_Links_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSurvey_Data_Links_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_Data_Links_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_Data_Links_Bool_Exp>;
};


export type Subscription_RootTelegram_Admin_ParamsArgs = {
  distinct_on?: InputMaybe<Array<Telegram_Admin_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Telegram_Admin_Params_Order_By>>;
  where?: InputMaybe<Telegram_Admin_Params_Bool_Exp>;
};


export type Subscription_RootTelegram_Admin_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Telegram_Admin_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Telegram_Admin_Params_Order_By>>;
  where?: InputMaybe<Telegram_Admin_Params_Bool_Exp>;
};


export type Subscription_RootTelegram_Admin_Params_By_PkArgs = {
  param_id: Scalars['Int'];
};


export type Subscription_RootTelegram_Admin_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Telegram_Admin_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<Telegram_Admin_Params_Bool_Exp>;
};


export type Subscription_RootTicket_Counts_ResultArgs = {
  distinct_on?: InputMaybe<Array<Ticket_Counts_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ticket_Counts_Result_Order_By>>;
  where?: InputMaybe<Ticket_Counts_Result_Bool_Exp>;
};


export type Subscription_RootTicket_Counts_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ticket_Counts_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ticket_Counts_Result_Order_By>>;
  where?: InputMaybe<Ticket_Counts_Result_Bool_Exp>;
};


export type Subscription_RootTicket_Counts_Result_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ticket_Counts_Result_Stream_Cursor_Input>>;
  where?: InputMaybe<Ticket_Counts_Result_Bool_Exp>;
};


export type Subscription_RootTickets_Set_ContentArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tickets_Set_Content_Order_By>>;
  where?: InputMaybe<Tickets_Set_Content_Bool_Exp>;
};


export type Subscription_RootTickets_Set_Content_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Set_Content_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tickets_Set_Content_Order_By>>;
  where?: InputMaybe<Tickets_Set_Content_Bool_Exp>;
};


export type Subscription_RootTickets_Set_Content_By_PkArgs = {
  content_id: Scalars['bigint'];
};


export type Subscription_RootTickets_Set_Content_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tickets_Set_Content_Stream_Cursor_Input>>;
  where?: InputMaybe<Tickets_Set_Content_Bool_Exp>;
};


export type Subscription_RootTickets_SetsArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tickets_Sets_Order_By>>;
  where?: InputMaybe<Tickets_Sets_Bool_Exp>;
};


export type Subscription_RootTickets_Sets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tickets_Sets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tickets_Sets_Order_By>>;
  where?: InputMaybe<Tickets_Sets_Bool_Exp>;
};


export type Subscription_RootTickets_Sets_By_PkArgs = {
  set_id: Scalars['bigint'];
};


export type Subscription_RootTickets_Sets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tickets_Sets_Stream_Cursor_Input>>;
  where?: InputMaybe<Tickets_Sets_Bool_Exp>;
};


export type Subscription_RootTop_Drop_StripArgs = {
  distinct_on?: InputMaybe<Array<Top_Drop_Strip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Top_Drop_Strip_Order_By>>;
  where?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
};


export type Subscription_RootTop_Drop_Strip_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Top_Drop_Strip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Top_Drop_Strip_Order_By>>;
  where?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
};


export type Subscription_RootTop_Drop_Strip_By_PkArgs = {
  record_id: Scalars['uuid'];
};


export type Subscription_RootTop_Drop_Strip_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Top_Drop_Strip_Stream_Cursor_Input>>;
  where?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
};


export type Subscription_RootTransactionArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


export type Subscription_RootTransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transaction_Order_By>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


export type Subscription_RootTransaction_By_PkArgs = {
  id: Scalars['uuid'];
  order_num: Scalars['bigint'];
};


export type Subscription_RootTransaction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Transaction_Stream_Cursor_Input>>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};


export type Subscription_RootUrl_MappingsArgs = {
  distinct_on?: InputMaybe<Array<Url_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Url_Mappings_Order_By>>;
  where?: InputMaybe<Url_Mappings_Bool_Exp>;
};


export type Subscription_RootUrl_Mappings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Url_Mappings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Url_Mappings_Order_By>>;
  where?: InputMaybe<Url_Mappings_Bool_Exp>;
};


export type Subscription_RootUrl_Mappings_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootUrl_Mappings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Url_Mappings_Stream_Cursor_Input>>;
  where?: InputMaybe<Url_Mappings_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AccountArgs = {
  distinct_on?: InputMaybe<Array<User_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Account_Order_By>>;
  where?: InputMaybe<User_Account_Bool_Exp>;
};


export type Subscription_RootUser_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Account_Order_By>>;
  where?: InputMaybe<User_Account_Bool_Exp>;
};


export type Subscription_RootUser_Account_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Account_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Account_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Account_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AuthArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Order_By>>;
  where?: InputMaybe<User_Auth_Bool_Exp>;
};


export type Subscription_RootUser_Auth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Order_By>>;
  where?: InputMaybe<User_Auth_Bool_Exp>;
};


export type Subscription_RootUser_Auth_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


export type Subscription_RootUser_Auth_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Auth_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Auth_Bool_Exp>;
};


export type Subscription_RootUser_Bonus_WalletArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
};


export type Subscription_RootUser_Bonus_Wallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
};


export type Subscription_RootUser_Bonus_Wallet_By_PkArgs = {
  user_bonus_wallet_id: Scalars['uuid'];
};


export type Subscription_RootUser_Bonus_Wallet_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Bonus_Wallet_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
};


export type Subscription_RootUser_Bonus_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Bonus_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Bonus_Wallet_Transactions_By_PkArgs = {
  operation_id: Scalars['uuid'];
};


export type Subscription_RootUser_Bonus_Wallet_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Bonus_Wallet_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bonus_Wallet_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Boosters_In_InventoryArgs = {
  distinct_on?: InputMaybe<Array<User_Boosters_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Boosters_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
};


export type Subscription_RootUser_Boosters_In_Inventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Boosters_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Boosters_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
};


export type Subscription_RootUser_Boosters_In_Inventory_By_PkArgs = {
  user_booster_id: Scalars['uuid'];
};


export type Subscription_RootUser_Boosters_In_Inventory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Boosters_In_Inventory_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_ContactsArgs = {
  distinct_on?: InputMaybe<Array<User_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Contacts_Order_By>>;
  where?: InputMaybe<User_Contacts_Bool_Exp>;
};


export type Subscription_RootUser_Contacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Contacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Contacts_Order_By>>;
  where?: InputMaybe<User_Contacts_Bool_Exp>;
};


export type Subscription_RootUser_Contacts_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Contacts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Contacts_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Contacts_Bool_Exp>;
};


export type Subscription_RootUser_Correct_Answers_RewardsArgs = {
  distinct_on?: InputMaybe<Array<User_Correct_Answers_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Correct_Answers_Rewards_Order_By>>;
  where?: InputMaybe<User_Correct_Answers_Rewards_Bool_Exp>;
};


export type Subscription_RootUser_Correct_Answers_Rewards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Correct_Answers_Rewards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Correct_Answers_Rewards_Order_By>>;
  where?: InputMaybe<User_Correct_Answers_Rewards_Bool_Exp>;
};


export type Subscription_RootUser_Correct_Answers_Rewards_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Correct_Answers_Rewards_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Correct_Answers_Rewards_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Correct_Answers_Rewards_Bool_Exp>;
};


export type Subscription_RootUser_Daily_Coins_ClaimsArgs = {
  distinct_on?: InputMaybe<Array<User_Daily_Coins_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Daily_Coins_Claims_Order_By>>;
  where?: InputMaybe<User_Daily_Coins_Claims_Bool_Exp>;
};


export type Subscription_RootUser_Daily_Coins_Claims_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Daily_Coins_Claims_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Daily_Coins_Claims_Order_By>>;
  where?: InputMaybe<User_Daily_Coins_Claims_Bool_Exp>;
};


export type Subscription_RootUser_Daily_Coins_Claims_By_PkArgs = {
  claim_id: Scalars['uuid'];
};


export type Subscription_RootUser_Daily_Coins_Claims_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Daily_Coins_Claims_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Daily_Coins_Claims_Bool_Exp>;
};


export type Subscription_RootUser_Daily_Reward_CountsArgs = {
  distinct_on?: InputMaybe<Array<User_Daily_Reward_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Daily_Reward_Counts_Order_By>>;
  where?: InputMaybe<User_Daily_Reward_Counts_Bool_Exp>;
};


export type Subscription_RootUser_Daily_Reward_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Daily_Reward_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Daily_Reward_Counts_Order_By>>;
  where?: InputMaybe<User_Daily_Reward_Counts_Bool_Exp>;
};


export type Subscription_RootUser_Daily_Reward_Counts_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Daily_Reward_Counts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Daily_Reward_Counts_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Daily_Reward_Counts_Bool_Exp>;
};


export type Subscription_RootUser_Email_Verification_DataArgs = {
  distinct_on?: InputMaybe<Array<User_Email_Verification_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Email_Verification_Data_Order_By>>;
  where?: InputMaybe<User_Email_Verification_Data_Bool_Exp>;
};


export type Subscription_RootUser_Email_Verification_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Email_Verification_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Email_Verification_Data_Order_By>>;
  where?: InputMaybe<User_Email_Verification_Data_Bool_Exp>;
};


export type Subscription_RootUser_Email_Verification_Data_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Email_Verification_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Email_Verification_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Email_Verification_Data_Bool_Exp>;
};


export type Subscription_RootUser_EventsArgs = {
  distinct_on?: InputMaybe<Array<User_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Events_Order_By>>;
  where?: InputMaybe<User_Events_Bool_Exp>;
};


export type Subscription_RootUser_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Events_Order_By>>;
  where?: InputMaybe<User_Events_Bool_Exp>;
};


export type Subscription_RootUser_Events_By_PkArgs = {
  event_id: Scalars['uuid'];
};


export type Subscription_RootUser_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Events_Bool_Exp>;
};


export type Subscription_RootUser_Got_BonusArgs = {
  distinct_on?: InputMaybe<Array<User_Got_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Got_Bonus_Order_By>>;
  where?: InputMaybe<User_Got_Bonus_Bool_Exp>;
};


export type Subscription_RootUser_Got_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Got_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Got_Bonus_Order_By>>;
  where?: InputMaybe<User_Got_Bonus_Bool_Exp>;
};


export type Subscription_RootUser_Got_Bonus_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  last_datetime: Scalars['timestamp'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Got_Bonus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Got_Bonus_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Got_Bonus_Bool_Exp>;
};


export type Subscription_RootUser_InventoryArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Order_By>>;
  where?: InputMaybe<User_Inventory_Bool_Exp>;
};


export type Subscription_RootUser_Inventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Order_By>>;
  where?: InputMaybe<User_Inventory_Bool_Exp>;
};


export type Subscription_RootUser_Inventory_By_PkArgs = {
  user_inventory_id: Scalars['uuid'];
};


export type Subscription_RootUser_Inventory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Inventory_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Inventory_Bool_Exp>;
};


export type Subscription_RootUser_Inventory_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Transactions_Order_By>>;
  where?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Inventory_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Transactions_Order_By>>;
  where?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Inventory_Transactions_By_PkArgs = {
  operation_id: Scalars['uuid'];
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};


export type Subscription_RootUser_Inventory_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Inventory_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Items_In_InventoryArgs = {
  distinct_on?: InputMaybe<Array<User_Items_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Items_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
};


export type Subscription_RootUser_Items_In_Inventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Items_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Items_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
};


export type Subscription_RootUser_Items_In_Inventory_By_PkArgs = {
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};


export type Subscription_RootUser_Items_In_Inventory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Items_In_Inventory_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
};


export type Subscription_RootUser_Lootbox_Opens_CountArgs = {
  distinct_on?: InputMaybe<Array<User_Lootbox_Opens_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Lootbox_Opens_Count_Order_By>>;
  where?: InputMaybe<User_Lootbox_Opens_Count_Bool_Exp>;
};


export type Subscription_RootUser_Lootbox_Opens_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Lootbox_Opens_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Lootbox_Opens_Count_Order_By>>;
  where?: InputMaybe<User_Lootbox_Opens_Count_Bool_Exp>;
};


export type Subscription_RootUser_Lootbox_Opens_Count_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Lootbox_Opens_Count_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Lootbox_Opens_Count_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Lootbox_Opens_Count_Bool_Exp>;
};


export type Subscription_RootUser_MarkingArgs = {
  distinct_on?: InputMaybe<Array<User_Marking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Marking_Order_By>>;
  where?: InputMaybe<User_Marking_Bool_Exp>;
};


export type Subscription_RootUser_Marking_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Marking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Marking_Order_By>>;
  where?: InputMaybe<User_Marking_Bool_Exp>;
};


export type Subscription_RootUser_Marking_By_PkArgs = {
  user_marking_id: Scalars['uuid'];
};


export type Subscription_RootUser_Marking_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Marking_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Marking_Bool_Exp>;
};


export type Subscription_RootUser_MarkingsArgs = {
  distinct_on?: InputMaybe<Array<User_Markings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Markings_Order_By>>;
  where?: InputMaybe<User_Markings_Bool_Exp>;
};


export type Subscription_RootUser_Markings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Markings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Markings_Order_By>>;
  where?: InputMaybe<User_Markings_Bool_Exp>;
};


export type Subscription_RootUser_Markings_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Markings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Markings_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Markings_Bool_Exp>;
};


export type Subscription_RootUser_Output_LimitsArgs = {
  distinct_on?: InputMaybe<Array<User_Output_Limits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Output_Limits_Order_By>>;
  where?: InputMaybe<User_Output_Limits_Bool_Exp>;
};


export type Subscription_RootUser_Output_Limits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Output_Limits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Output_Limits_Order_By>>;
  where?: InputMaybe<User_Output_Limits_Bool_Exp>;
};


export type Subscription_RootUser_Output_Limits_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Output_Limits_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Output_Limits_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Output_Limits_Bool_Exp>;
};


export type Subscription_RootUser_ProfileArgs = {
  distinct_on?: InputMaybe<Array<User_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Profile_Order_By>>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};


export type Subscription_RootUser_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Profile_Order_By>>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};


export type Subscription_RootUser_Profile_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Profile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Profile_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};


export type Subscription_RootUser_Push_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Push_Tokens_Order_By>>;
  where?: InputMaybe<User_Push_Tokens_Bool_Exp>;
};


export type Subscription_RootUser_Push_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Push_Tokens_Order_By>>;
  where?: InputMaybe<User_Push_Tokens_Bool_Exp>;
};


export type Subscription_RootUser_Push_Tokens_By_PkArgs = {
  token_id: Scalars['uuid'];
};


export type Subscription_RootUser_Push_Tokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Push_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Push_Tokens_Bool_Exp>;
};


export type Subscription_RootUser_Push_TopicsArgs = {
  distinct_on?: InputMaybe<Array<User_Push_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Push_Topics_Order_By>>;
  where?: InputMaybe<User_Push_Topics_Bool_Exp>;
};


export type Subscription_RootUser_Push_Topics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Push_Topics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Push_Topics_Order_By>>;
  where?: InputMaybe<User_Push_Topics_Bool_Exp>;
};


export type Subscription_RootUser_Push_Topics_By_PkArgs = {
  topic_id: Scalars['uuid'];
};


export type Subscription_RootUser_Push_Topics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Push_Topics_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Push_Topics_Bool_Exp>;
};


export type Subscription_RootUser_Referal_Levels_ParamsArgs = {
  distinct_on?: InputMaybe<Array<User_Referal_Levels_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referal_Levels_Params_Order_By>>;
  where?: InputMaybe<User_Referal_Levels_Params_Bool_Exp>;
};


export type Subscription_RootUser_Referal_Levels_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referal_Levels_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referal_Levels_Params_Order_By>>;
  where?: InputMaybe<User_Referal_Levels_Params_Bool_Exp>;
};


export type Subscription_RootUser_Referal_Levels_Params_By_PkArgs = {
  referal_level: Scalars['Int'];
};


export type Subscription_RootUser_Referal_Levels_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referal_Levels_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referal_Levels_Params_Bool_Exp>;
};


export type Subscription_RootUser_Referal_ParamsArgs = {
  distinct_on?: InputMaybe<Array<User_Referal_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referal_Params_Order_By>>;
  where?: InputMaybe<User_Referal_Params_Bool_Exp>;
};


export type Subscription_RootUser_Referal_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referal_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referal_Params_Order_By>>;
  where?: InputMaybe<User_Referal_Params_Bool_Exp>;
};


export type Subscription_RootUser_Referal_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Referal_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referal_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referal_Params_Bool_Exp>;
};


export type Subscription_RootUser_ReferalsArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Order_By>>;
  where?: InputMaybe<User_Referals_Bool_Exp>;
};


export type Subscription_RootUser_Referals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Order_By>>;
  where?: InputMaybe<User_Referals_Bool_Exp>;
};


export type Subscription_RootUser_Referals_Balance_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Balance_Transactions_Order_By>>;
  where?: InputMaybe<User_Referals_Balance_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Referals_Balance_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Balance_Transactions_Order_By>>;
  where?: InputMaybe<User_Referals_Balance_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Referals_Balance_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Subscription_RootUser_Referals_Balance_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referals_Balance_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referals_Balance_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Referals_Bonus_HistoryArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Bonus_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Bonus_History_Order_By>>;
  where?: InputMaybe<User_Referals_Bonus_History_Bool_Exp>;
};


export type Subscription_RootUser_Referals_Bonus_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Bonus_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Bonus_History_Order_By>>;
  where?: InputMaybe<User_Referals_Bonus_History_Bool_Exp>;
};


export type Subscription_RootUser_Referals_Bonus_History_By_PkArgs = {
  hisoty_id: Scalars['uuid'];
};


export type Subscription_RootUser_Referals_Bonus_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referals_Bonus_History_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referals_Bonus_History_Bool_Exp>;
};


export type Subscription_RootUser_Referals_By_PkArgs = {
  owner_user_id: Scalars['uuid'];
  referal_user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Referals_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referals_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referals_Bool_Exp>;
};


export type Subscription_RootUser_ReferralArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Order_By>>;
  where?: InputMaybe<User_Referral_Bool_Exp>;
};


export type Subscription_RootUser_Referral_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Order_By>>;
  where?: InputMaybe<User_Referral_Bool_Exp>;
};


export type Subscription_RootUser_Referral_AssociationArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Association_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Association_Order_By>>;
  where?: InputMaybe<User_Referral_Association_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Association_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Association_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Association_Order_By>>;
  where?: InputMaybe<User_Referral_Association_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Association_By_PkArgs = {
  offer_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Referral_Association_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referral_Association_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referral_Association_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Bonus_TypeArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Bonus_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Bonus_Type_Order_By>>;
  where?: InputMaybe<User_Referral_Bonus_Type_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Bonus_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Bonus_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Bonus_Type_Order_By>>;
  where?: InputMaybe<User_Referral_Bonus_Type_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Bonus_Type_By_PkArgs = {
  referral_bonus_type_id: Scalars['uuid'];
};


export type Subscription_RootUser_Referral_Bonus_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referral_Bonus_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referral_Bonus_Type_Bool_Exp>;
};


export type Subscription_RootUser_Referral_By_PkArgs = {
  owner_user_id: Scalars['uuid'];
  referral_user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Referral_Levels_ParamsArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Levels_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Levels_Params_Order_By>>;
  where?: InputMaybe<User_Referral_Levels_Params_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Levels_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Levels_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Levels_Params_Order_By>>;
  where?: InputMaybe<User_Referral_Levels_Params_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Levels_Params_By_PkArgs = {
  referral_level: Scalars['Int'];
};


export type Subscription_RootUser_Referral_Levels_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referral_Levels_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referral_Levels_Params_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Offer_BonusArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offer_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offer_Bonus_Order_By>>;
  where?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Offer_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offer_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offer_Bonus_Order_By>>;
  where?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Offer_Bonus_By_PkArgs = {
  offer_bonus_id: Scalars['uuid'];
};


export type Subscription_RootUser_Referral_Offer_Bonus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referral_Offer_Bonus_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
};


export type Subscription_RootUser_Referral_OffersArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offers_Order_By>>;
  where?: InputMaybe<User_Referral_Offers_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offers_Order_By>>;
  where?: InputMaybe<User_Referral_Offers_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Offers_By_PkArgs = {
  offer_id: Scalars['uuid'];
};


export type Subscription_RootUser_Referral_Offers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referral_Offers_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referral_Offers_Bool_Exp>;
};


export type Subscription_RootUser_Referral_ParamsArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Params_Order_By>>;
  where?: InputMaybe<User_Referral_Params_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Params_Order_By>>;
  where?: InputMaybe<User_Referral_Params_Bool_Exp>;
};


export type Subscription_RootUser_Referral_Params_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Referral_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referral_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referral_Params_Bool_Exp>;
};


export type Subscription_RootUser_Referral_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referral_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referral_Bool_Exp>;
};


export type Subscription_RootUser_Referrals_Balance_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Referrals_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referrals_Balance_Transactions_Order_By>>;
  where?: InputMaybe<User_Referrals_Balance_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Referrals_Balance_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referrals_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referrals_Balance_Transactions_Order_By>>;
  where?: InputMaybe<User_Referrals_Balance_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Referrals_Balance_Transactions_By_PkArgs = {
  transaction_id: Scalars['uuid'];
};


export type Subscription_RootUser_Referrals_Balance_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Referrals_Balance_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Referrals_Balance_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Seq_NumberArgs = {
  distinct_on?: InputMaybe<Array<User_Seq_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Seq_Number_Order_By>>;
  where?: InputMaybe<User_Seq_Number_Bool_Exp>;
};


export type Subscription_RootUser_Seq_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Seq_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Seq_Number_Order_By>>;
  where?: InputMaybe<User_Seq_Number_Bool_Exp>;
};


export type Subscription_RootUser_Seq_Number_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Seq_Number_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Seq_Number_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Seq_Number_Bool_Exp>;
};


export type Subscription_RootUser_SettingsArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};


export type Subscription_RootUser_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Settings_Order_By>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};


export type Subscription_RootUser_Settings_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Settings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};


export type Subscription_RootUser_StatisticArgs = {
  distinct_on?: InputMaybe<Array<User_Statistic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Statistic_Order_By>>;
  where?: InputMaybe<User_Statistic_Bool_Exp>;
};


export type Subscription_RootUser_Statistic_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Statistic_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Statistic_Order_By>>;
  where?: InputMaybe<User_Statistic_Bool_Exp>;
};


export type Subscription_RootUser_Statistic_By_PkArgs = {
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Statistic_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Statistic_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Statistic_Bool_Exp>;
};


export type Subscription_RootUser_Steam_Output_AlternativesArgs = {
  distinct_on?: InputMaybe<Array<User_Steam_Output_Alternatives_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Steam_Output_Alternatives_Order_By>>;
  where?: InputMaybe<User_Steam_Output_Alternatives_Bool_Exp>;
};


export type Subscription_RootUser_Steam_Output_Alternatives_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Steam_Output_Alternatives_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Steam_Output_Alternatives_Order_By>>;
  where?: InputMaybe<User_Steam_Output_Alternatives_Bool_Exp>;
};


export type Subscription_RootUser_Steam_Output_Alternatives_By_PkArgs = {
  output_id: Scalars['uuid'];
};


export type Subscription_RootUser_Steam_Output_Alternatives_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Steam_Output_Alternatives_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Steam_Output_Alternatives_Bool_Exp>;
};


export type Subscription_RootUser_Steam_Output_WatcherArgs = {
  distinct_on?: InputMaybe<Array<User_Steam_Output_Watcher_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Steam_Output_Watcher_Order_By>>;
  where?: InputMaybe<User_Steam_Output_Watcher_Bool_Exp>;
};


export type Subscription_RootUser_Steam_Output_Watcher_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Steam_Output_Watcher_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Steam_Output_Watcher_Order_By>>;
  where?: InputMaybe<User_Steam_Output_Watcher_Bool_Exp>;
};


export type Subscription_RootUser_Steam_Output_Watcher_By_PkArgs = {
  order_custom_id: Scalars['uuid'];
};


export type Subscription_RootUser_Steam_Output_Watcher_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Steam_Output_Watcher_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Steam_Output_Watcher_Bool_Exp>;
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_Takes_Free_LootboxArgs = {
  distinct_on?: InputMaybe<Array<User_Takes_Free_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Takes_Free_Lootbox_Order_By>>;
  where?: InputMaybe<User_Takes_Free_Lootbox_Bool_Exp>;
};


export type Subscription_RootUser_Takes_Free_Lootbox_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Takes_Free_Lootbox_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Takes_Free_Lootbox_Order_By>>;
  where?: InputMaybe<User_Takes_Free_Lootbox_Bool_Exp>;
};


export type Subscription_RootUser_Takes_Free_Lootbox_By_PkArgs = {
  lootbox_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Takes_Free_Lootbox_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Takes_Free_Lootbox_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Takes_Free_Lootbox_Bool_Exp>;
};


export type Subscription_RootUser_Upgrade_ParamsArgs = {
  distinct_on?: InputMaybe<Array<User_Upgrade_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Upgrade_Params_Order_By>>;
  where?: InputMaybe<User_Upgrade_Params_Bool_Exp>;
};


export type Subscription_RootUser_Upgrade_Params_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Upgrade_Params_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Upgrade_Params_Order_By>>;
  where?: InputMaybe<User_Upgrade_Params_Bool_Exp>;
};


export type Subscription_RootUser_Upgrade_Params_By_PkArgs = {
  param_name: Scalars['String'];
};


export type Subscription_RootUser_Upgrade_Params_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Upgrade_Params_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Upgrade_Params_Bool_Exp>;
};


export type Subscription_RootUser_Upgrade_ResultsArgs = {
  distinct_on?: InputMaybe<Array<User_Upgrade_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Upgrade_Results_Order_By>>;
  where?: InputMaybe<User_Upgrade_Results_Bool_Exp>;
};


export type Subscription_RootUser_Upgrade_Results_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Upgrade_Results_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Upgrade_Results_Order_By>>;
  where?: InputMaybe<User_Upgrade_Results_Bool_Exp>;
};


export type Subscription_RootUser_Upgrade_Results_By_PkArgs = {
  start_item_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Upgrade_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Upgrade_Results_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Upgrade_Results_Bool_Exp>;
};


export type Subscription_RootUser_Vk_DataArgs = {
  distinct_on?: InputMaybe<Array<User_Vk_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Vk_Data_Order_By>>;
  where?: InputMaybe<User_Vk_Data_Bool_Exp>;
};


export type Subscription_RootUser_Vk_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Vk_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Vk_Data_Order_By>>;
  where?: InputMaybe<User_Vk_Data_Bool_Exp>;
};


export type Subscription_RootUser_Vk_Data_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootUser_Vk_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Vk_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Vk_Data_Bool_Exp>;
};


export type Subscription_RootUser_WalletArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


export type Subscription_RootUser_Wallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


export type Subscription_RootUser_Wallet_AuditArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Audit_Order_By>>;
  where?: InputMaybe<User_Wallet_Audit_Bool_Exp>;
};


export type Subscription_RootUser_Wallet_Audit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Audit_Order_By>>;
  where?: InputMaybe<User_Wallet_Audit_Bool_Exp>;
};


export type Subscription_RootUser_Wallet_Audit_By_PkArgs = {
  uuid: Scalars['uuid'];
};


export type Subscription_RootUser_Wallet_Audit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Wallet_Audit_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Wallet_Audit_Bool_Exp>;
};


export type Subscription_RootUser_Wallet_By_PkArgs = {
  user_wallet_id: Scalars['uuid'];
};


export type Subscription_RootUser_Wallet_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Wallet_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


export type Subscription_RootUser_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Wallet_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Wallet_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_Wallet_Transactions_By_PkArgs = {
  operation_id: Scalars['uuid'];
  user_wallet_id: Scalars['uuid'];
};


export type Subscription_RootUser_Wallet_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Wallet_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Wallet_Transactions_Bool_Exp>;
};


export type Subscription_RootUser_WhitelistArgs = {
  distinct_on?: InputMaybe<Array<User_Whitelist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Whitelist_Order_By>>;
  where?: InputMaybe<User_Whitelist_Bool_Exp>;
};


export type Subscription_RootUser_Whitelist_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Whitelist_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Whitelist_Order_By>>;
  where?: InputMaybe<User_Whitelist_Bool_Exp>;
};


export type Subscription_RootUser_Whitelist_By_PkArgs = {
  user_data: Scalars['String'];
};


export type Subscription_RootUser_Whitelist_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Whitelist_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Whitelist_Bool_Exp>;
};


export type Subscription_RootUtm_Visits_DataArgs = {
  distinct_on?: InputMaybe<Array<Utm_Visits_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Utm_Visits_Data_Order_By>>;
  where?: InputMaybe<Utm_Visits_Data_Bool_Exp>;
};


export type Subscription_RootUtm_Visits_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Utm_Visits_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Utm_Visits_Data_Order_By>>;
  where?: InputMaybe<Utm_Visits_Data_Bool_Exp>;
};


export type Subscription_RootUtm_Visits_Data_By_PkArgs = {
  visit_id: Scalars['uuid'];
};


export type Subscription_RootUtm_Visits_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Utm_Visits_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Utm_Visits_Data_Bool_Exp>;
};


export type Subscription_RootWebpushes_Autosending_TypesArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Autosending_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Autosending_Types_Order_By>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
};


export type Subscription_RootWebpushes_Autosending_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Autosending_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Autosending_Types_Order_By>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
};


export type Subscription_RootWebpushes_Autosending_Types_By_PkArgs = {
  webpush_id: Scalars['uuid'];
};


export type Subscription_RootWebpushes_Autosending_Types_LocalizationArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Order_By>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Localization_Bool_Exp>;
};


export type Subscription_RootWebpushes_Autosending_Types_Localization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Order_By>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Localization_Bool_Exp>;
};


export type Subscription_RootWebpushes_Autosending_Types_Localization_By_PkArgs = {
  locale: Scalars['String'];
  webpush_id: Scalars['uuid'];
};


export type Subscription_RootWebpushes_Autosending_Types_Localization_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Webpushes_Autosending_Types_Localization_Stream_Cursor_Input>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Localization_Bool_Exp>;
};


export type Subscription_RootWebpushes_Autosending_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Webpushes_Autosending_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
};


export type Subscription_RootWebpushes_BonusesArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Bonuses_Order_By>>;
  where?: InputMaybe<Webpushes_Bonuses_Bool_Exp>;
};


export type Subscription_RootWebpushes_Bonuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Bonuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Bonuses_Order_By>>;
  where?: InputMaybe<Webpushes_Bonuses_Bool_Exp>;
};


export type Subscription_RootWebpushes_Bonuses_By_PkArgs = {
  bonus_id: Scalars['uuid'];
};


export type Subscription_RootWebpushes_Bonuses_OffersArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
};


export type Subscription_RootWebpushes_Bonuses_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
};


export type Subscription_RootWebpushes_Bonuses_Offers_By_PkArgs = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootWebpushes_Bonuses_Offers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Webpushes_Bonuses_Offers_Stream_Cursor_Input>>;
  where?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
};


export type Subscription_RootWebpushes_Bonuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Webpushes_Bonuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Webpushes_Bonuses_Bool_Exp>;
};


export type Subscription_RootWebpushes_LinesArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Lines_Order_By>>;
  where?: InputMaybe<Webpushes_Lines_Bool_Exp>;
};


export type Subscription_RootWebpushes_Lines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Lines_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Lines_Order_By>>;
  where?: InputMaybe<Webpushes_Lines_Bool_Exp>;
};


export type Subscription_RootWebpushes_Lines_By_PkArgs = {
  webpush_id: Scalars['uuid'];
};


export type Subscription_RootWebpushes_Lines_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Webpushes_Lines_Stream_Cursor_Input>>;
  where?: InputMaybe<Webpushes_Lines_Bool_Exp>;
};


export type Subscription_RootWebpushes_SchedulingArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Scheduling_Order_By>>;
  where?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
};


export type Subscription_RootWebpushes_Scheduling_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Scheduling_Order_By>>;
  where?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
};


export type Subscription_RootWebpushes_Scheduling_By_PkArgs = {
  user_id: Scalars['uuid'];
  webpush_id: Scalars['uuid'];
};


export type Subscription_RootWebpushes_Scheduling_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Webpushes_Scheduling_Stream_Cursor_Input>>;
  where?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
};


export type Subscription_RootWebpushes_TriggersArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Bool_Exp>;
};


export type Subscription_RootWebpushes_Triggers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Bool_Exp>;
};


export type Subscription_RootWebpushes_Triggers_By_PkArgs = {
  trigger_name: Scalars['String'];
  triggers_group_id: Scalars['uuid'];
};


export type Subscription_RootWebpushes_Triggers_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
};


export type Subscription_RootWebpushes_Triggers_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
};


export type Subscription_RootWebpushes_Triggers_Groups_By_PkArgs = {
  triggers_group_id: Scalars['uuid'];
  webpush_id: Scalars['uuid'];
};


export type Subscription_RootWebpushes_Triggers_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Webpushes_Triggers_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
};


export type Subscription_RootWebpushes_Triggers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Webpushes_Triggers_Stream_Cursor_Input>>;
  where?: InputMaybe<Webpushes_Triggers_Bool_Exp>;
};

/** columns and relationships of "survey_data_links" */
export type Survey_Data_Links = {
  __typename?: 'survey_data_links';
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  link: Scalars['String'];
};

/** aggregated selection of "survey_data_links" */
export type Survey_Data_Links_Aggregate = {
  __typename?: 'survey_data_links_aggregate';
  aggregate?: Maybe<Survey_Data_Links_Aggregate_Fields>;
  nodes: Array<Survey_Data_Links>;
};

/** aggregate fields of "survey_data_links" */
export type Survey_Data_Links_Aggregate_Fields = {
  __typename?: 'survey_data_links_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_Data_Links_Max_Fields>;
  min?: Maybe<Survey_Data_Links_Min_Fields>;
};


/** aggregate fields of "survey_data_links" */
export type Survey_Data_Links_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_Data_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "survey_data_links". All fields are combined with a logical 'AND'. */
export type Survey_Data_Links_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_Data_Links_Bool_Exp>>;
  _not?: InputMaybe<Survey_Data_Links_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_Data_Links_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_data_links" */
export enum Survey_Data_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyDataLinksPkey = 'survey_data_links_pkey'
}

/** input type for inserting data into table "survey_data_links" */
export type Survey_Data_Links_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Survey_Data_Links_Max_Fields = {
  __typename?: 'survey_data_links_max_fields';
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Survey_Data_Links_Min_Fields = {
  __typename?: 'survey_data_links_min_fields';
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "survey_data_links" */
export type Survey_Data_Links_Mutation_Response = {
  __typename?: 'survey_data_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_Data_Links>;
};

/** on_conflict condition type for table "survey_data_links" */
export type Survey_Data_Links_On_Conflict = {
  constraint: Survey_Data_Links_Constraint;
  update_columns?: Array<Survey_Data_Links_Update_Column>;
  where?: InputMaybe<Survey_Data_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_data_links". */
export type Survey_Data_Links_Order_By = {
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_data_links */
export type Survey_Data_Links_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "survey_data_links" */
export enum Survey_Data_Links_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Link = 'link'
}

/** input type for updating data in table "survey_data_links" */
export type Survey_Data_Links_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "survey_data_links" */
export type Survey_Data_Links_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_Data_Links_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_Data_Links_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
};

/** update columns of table "survey_data_links" */
export enum Survey_Data_Links_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Link = 'link'
}

export type Survey_Data_Links_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_Data_Links_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_Data_Links_Bool_Exp;
};

/** columns and relationships of "telegram_admin_params" */
export type Telegram_Admin_Params = {
  __typename?: 'telegram_admin_params';
  param_id: Scalars['Int'];
  param_name: Scalars['String'];
  param_value: Scalars['String'];
};

/** aggregated selection of "telegram_admin_params" */
export type Telegram_Admin_Params_Aggregate = {
  __typename?: 'telegram_admin_params_aggregate';
  aggregate?: Maybe<Telegram_Admin_Params_Aggregate_Fields>;
  nodes: Array<Telegram_Admin_Params>;
};

/** aggregate fields of "telegram_admin_params" */
export type Telegram_Admin_Params_Aggregate_Fields = {
  __typename?: 'telegram_admin_params_aggregate_fields';
  avg?: Maybe<Telegram_Admin_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Telegram_Admin_Params_Max_Fields>;
  min?: Maybe<Telegram_Admin_Params_Min_Fields>;
  stddev?: Maybe<Telegram_Admin_Params_Stddev_Fields>;
  stddev_pop?: Maybe<Telegram_Admin_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Telegram_Admin_Params_Stddev_Samp_Fields>;
  sum?: Maybe<Telegram_Admin_Params_Sum_Fields>;
  var_pop?: Maybe<Telegram_Admin_Params_Var_Pop_Fields>;
  var_samp?: Maybe<Telegram_Admin_Params_Var_Samp_Fields>;
  variance?: Maybe<Telegram_Admin_Params_Variance_Fields>;
};


/** aggregate fields of "telegram_admin_params" */
export type Telegram_Admin_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Telegram_Admin_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Telegram_Admin_Params_Avg_Fields = {
  __typename?: 'telegram_admin_params_avg_fields';
  param_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "telegram_admin_params". All fields are combined with a logical 'AND'. */
export type Telegram_Admin_Params_Bool_Exp = {
  _and?: InputMaybe<Array<Telegram_Admin_Params_Bool_Exp>>;
  _not?: InputMaybe<Telegram_Admin_Params_Bool_Exp>;
  _or?: InputMaybe<Array<Telegram_Admin_Params_Bool_Exp>>;
  param_id?: InputMaybe<Int_Comparison_Exp>;
  param_name?: InputMaybe<String_Comparison_Exp>;
  param_value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "telegram_admin_params" */
export enum Telegram_Admin_Params_Constraint {
  /** unique or primary key constraint on columns "param_name" */
  TelegramAdminParamsParamNameKey = 'telegram_admin_params_param_name_key',
  /** unique or primary key constraint on columns "param_id" */
  TelegramAdminParamsPkey = 'telegram_admin_params_pkey'
}

/** input type for incrementing numeric columns in table "telegram_admin_params" */
export type Telegram_Admin_Params_Inc_Input = {
  param_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "telegram_admin_params" */
export type Telegram_Admin_Params_Insert_Input = {
  param_id?: InputMaybe<Scalars['Int']>;
  param_name?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Telegram_Admin_Params_Max_Fields = {
  __typename?: 'telegram_admin_params_max_fields';
  param_id?: Maybe<Scalars['Int']>;
  param_name?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Telegram_Admin_Params_Min_Fields = {
  __typename?: 'telegram_admin_params_min_fields';
  param_id?: Maybe<Scalars['Int']>;
  param_name?: Maybe<Scalars['String']>;
  param_value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "telegram_admin_params" */
export type Telegram_Admin_Params_Mutation_Response = {
  __typename?: 'telegram_admin_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Telegram_Admin_Params>;
};

/** on_conflict condition type for table "telegram_admin_params" */
export type Telegram_Admin_Params_On_Conflict = {
  constraint: Telegram_Admin_Params_Constraint;
  update_columns?: Array<Telegram_Admin_Params_Update_Column>;
  where?: InputMaybe<Telegram_Admin_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "telegram_admin_params". */
export type Telegram_Admin_Params_Order_By = {
  param_id?: InputMaybe<Order_By>;
  param_name?: InputMaybe<Order_By>;
  param_value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: telegram_admin_params */
export type Telegram_Admin_Params_Pk_Columns_Input = {
  param_id: Scalars['Int'];
};

/** select columns of table "telegram_admin_params" */
export enum Telegram_Admin_Params_Select_Column {
  /** column name */
  ParamId = 'param_id',
  /** column name */
  ParamName = 'param_name',
  /** column name */
  ParamValue = 'param_value'
}

/** input type for updating data in table "telegram_admin_params" */
export type Telegram_Admin_Params_Set_Input = {
  param_id?: InputMaybe<Scalars['Int']>;
  param_name?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Telegram_Admin_Params_Stddev_Fields = {
  __typename?: 'telegram_admin_params_stddev_fields';
  param_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Telegram_Admin_Params_Stddev_Pop_Fields = {
  __typename?: 'telegram_admin_params_stddev_pop_fields';
  param_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Telegram_Admin_Params_Stddev_Samp_Fields = {
  __typename?: 'telegram_admin_params_stddev_samp_fields';
  param_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "telegram_admin_params" */
export type Telegram_Admin_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Telegram_Admin_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Telegram_Admin_Params_Stream_Cursor_Value_Input = {
  param_id?: InputMaybe<Scalars['Int']>;
  param_name?: InputMaybe<Scalars['String']>;
  param_value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Telegram_Admin_Params_Sum_Fields = {
  __typename?: 'telegram_admin_params_sum_fields';
  param_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "telegram_admin_params" */
export enum Telegram_Admin_Params_Update_Column {
  /** column name */
  ParamId = 'param_id',
  /** column name */
  ParamName = 'param_name',
  /** column name */
  ParamValue = 'param_value'
}

export type Telegram_Admin_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Telegram_Admin_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Telegram_Admin_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: Telegram_Admin_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Telegram_Admin_Params_Var_Pop_Fields = {
  __typename?: 'telegram_admin_params_var_pop_fields';
  param_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Telegram_Admin_Params_Var_Samp_Fields = {
  __typename?: 'telegram_admin_params_var_samp_fields';
  param_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Telegram_Admin_Params_Variance_Fields = {
  __typename?: 'telegram_admin_params_variance_fields';
  param_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ticket_counts_result" */
export type Ticket_Counts_Result = {
  __typename?: 'ticket_counts_result';
  ticket_count?: Maybe<Scalars['Int']>;
  ticket_type_name?: Maybe<Scalars['String']>;
};

export type Ticket_Counts_Result_Aggregate = {
  __typename?: 'ticket_counts_result_aggregate';
  aggregate?: Maybe<Ticket_Counts_Result_Aggregate_Fields>;
  nodes: Array<Ticket_Counts_Result>;
};

/** aggregate fields of "ticket_counts_result" */
export type Ticket_Counts_Result_Aggregate_Fields = {
  __typename?: 'ticket_counts_result_aggregate_fields';
  avg?: Maybe<Ticket_Counts_Result_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ticket_Counts_Result_Max_Fields>;
  min?: Maybe<Ticket_Counts_Result_Min_Fields>;
  stddev?: Maybe<Ticket_Counts_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Ticket_Counts_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ticket_Counts_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Ticket_Counts_Result_Sum_Fields>;
  var_pop?: Maybe<Ticket_Counts_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Ticket_Counts_Result_Var_Samp_Fields>;
  variance?: Maybe<Ticket_Counts_Result_Variance_Fields>;
};


/** aggregate fields of "ticket_counts_result" */
export type Ticket_Counts_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ticket_Counts_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ticket_Counts_Result_Avg_Fields = {
  __typename?: 'ticket_counts_result_avg_fields';
  ticket_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ticket_counts_result". All fields are combined with a logical 'AND'. */
export type Ticket_Counts_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Ticket_Counts_Result_Bool_Exp>>;
  _not?: InputMaybe<Ticket_Counts_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Ticket_Counts_Result_Bool_Exp>>;
  ticket_count?: InputMaybe<Int_Comparison_Exp>;
  ticket_type_name?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "ticket_counts_result" */
export type Ticket_Counts_Result_Inc_Input = {
  ticket_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ticket_counts_result" */
export type Ticket_Counts_Result_Insert_Input = {
  ticket_count?: InputMaybe<Scalars['Int']>;
  ticket_type_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ticket_Counts_Result_Max_Fields = {
  __typename?: 'ticket_counts_result_max_fields';
  ticket_count?: Maybe<Scalars['Int']>;
  ticket_type_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ticket_Counts_Result_Min_Fields = {
  __typename?: 'ticket_counts_result_min_fields';
  ticket_count?: Maybe<Scalars['Int']>;
  ticket_type_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ticket_counts_result" */
export type Ticket_Counts_Result_Mutation_Response = {
  __typename?: 'ticket_counts_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ticket_Counts_Result>;
};

/** Ordering options when selecting data from "ticket_counts_result". */
export type Ticket_Counts_Result_Order_By = {
  ticket_count?: InputMaybe<Order_By>;
  ticket_type_name?: InputMaybe<Order_By>;
};

/** select columns of table "ticket_counts_result" */
export enum Ticket_Counts_Result_Select_Column {
  /** column name */
  TicketCount = 'ticket_count',
  /** column name */
  TicketTypeName = 'ticket_type_name'
}

/** input type for updating data in table "ticket_counts_result" */
export type Ticket_Counts_Result_Set_Input = {
  ticket_count?: InputMaybe<Scalars['Int']>;
  ticket_type_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ticket_Counts_Result_Stddev_Fields = {
  __typename?: 'ticket_counts_result_stddev_fields';
  ticket_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ticket_Counts_Result_Stddev_Pop_Fields = {
  __typename?: 'ticket_counts_result_stddev_pop_fields';
  ticket_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ticket_Counts_Result_Stddev_Samp_Fields = {
  __typename?: 'ticket_counts_result_stddev_samp_fields';
  ticket_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ticket_counts_result" */
export type Ticket_Counts_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ticket_Counts_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ticket_Counts_Result_Stream_Cursor_Value_Input = {
  ticket_count?: InputMaybe<Scalars['Int']>;
  ticket_type_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ticket_Counts_Result_Sum_Fields = {
  __typename?: 'ticket_counts_result_sum_fields';
  ticket_count?: Maybe<Scalars['Int']>;
};

export type Ticket_Counts_Result_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ticket_Counts_Result_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ticket_Counts_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ticket_Counts_Result_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ticket_Counts_Result_Var_Pop_Fields = {
  __typename?: 'ticket_counts_result_var_pop_fields';
  ticket_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ticket_Counts_Result_Var_Samp_Fields = {
  __typename?: 'ticket_counts_result_var_samp_fields';
  ticket_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ticket_Counts_Result_Variance_Fields = {
  __typename?: 'ticket_counts_result_variance_fields';
  ticket_count?: Maybe<Scalars['Float']>;
};

/** Содержимое наборов билетов для продажи */
export type Tickets_Set_Content = {
  __typename?: 'tickets_set_content';
  content_id: Scalars['bigint'];
  /** Описание предмета */
  description: Scalars['String'];
  /** Количество билетов в наборе */
  quantity: Scalars['Int'];
  /** Идентификатор набора */
  set_id: Scalars['bigint'];
  ticket_detail?: Maybe<Bonus_Wheel_Ticket_Type>;
  /** Идентификатор билета в таблице admin_db.bonus_wheel_ticket_type */
  ticket_id: Scalars['uuid'];
};

/** aggregated selection of "tickets_set_content" */
export type Tickets_Set_Content_Aggregate = {
  __typename?: 'tickets_set_content_aggregate';
  aggregate?: Maybe<Tickets_Set_Content_Aggregate_Fields>;
  nodes: Array<Tickets_Set_Content>;
};

export type Tickets_Set_Content_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tickets_Set_Content_Aggregate_Bool_Exp_Count>;
};

export type Tickets_Set_Content_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tickets_Set_Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tickets_Set_Content_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tickets_set_content" */
export type Tickets_Set_Content_Aggregate_Fields = {
  __typename?: 'tickets_set_content_aggregate_fields';
  avg?: Maybe<Tickets_Set_Content_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tickets_Set_Content_Max_Fields>;
  min?: Maybe<Tickets_Set_Content_Min_Fields>;
  stddev?: Maybe<Tickets_Set_Content_Stddev_Fields>;
  stddev_pop?: Maybe<Tickets_Set_Content_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tickets_Set_Content_Stddev_Samp_Fields>;
  sum?: Maybe<Tickets_Set_Content_Sum_Fields>;
  var_pop?: Maybe<Tickets_Set_Content_Var_Pop_Fields>;
  var_samp?: Maybe<Tickets_Set_Content_Var_Samp_Fields>;
  variance?: Maybe<Tickets_Set_Content_Variance_Fields>;
};


/** aggregate fields of "tickets_set_content" */
export type Tickets_Set_Content_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tickets_Set_Content_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tickets_set_content" */
export type Tickets_Set_Content_Aggregate_Order_By = {
  avg?: InputMaybe<Tickets_Set_Content_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tickets_Set_Content_Max_Order_By>;
  min?: InputMaybe<Tickets_Set_Content_Min_Order_By>;
  stddev?: InputMaybe<Tickets_Set_Content_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tickets_Set_Content_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tickets_Set_Content_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tickets_Set_Content_Sum_Order_By>;
  var_pop?: InputMaybe<Tickets_Set_Content_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tickets_Set_Content_Var_Samp_Order_By>;
  variance?: InputMaybe<Tickets_Set_Content_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tickets_set_content" */
export type Tickets_Set_Content_Arr_Rel_Insert_Input = {
  data: Array<Tickets_Set_Content_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tickets_Set_Content_On_Conflict>;
};

/** aggregate avg on columns */
export type Tickets_Set_Content_Avg_Fields = {
  __typename?: 'tickets_set_content_avg_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tickets_set_content" */
export type Tickets_Set_Content_Avg_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tickets_set_content". All fields are combined with a logical 'AND'. */
export type Tickets_Set_Content_Bool_Exp = {
  _and?: InputMaybe<Array<Tickets_Set_Content_Bool_Exp>>;
  _not?: InputMaybe<Tickets_Set_Content_Bool_Exp>;
  _or?: InputMaybe<Array<Tickets_Set_Content_Bool_Exp>>;
  content_id?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  set_id?: InputMaybe<Bigint_Comparison_Exp>;
  ticket_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tickets_set_content" */
export enum Tickets_Set_Content_Constraint {
  /** unique or primary key constraint on columns "content_id" */
  TicketsSetContentPkey = 'tickets_set_content_pkey'
}

/** input type for incrementing numeric columns in table "tickets_set_content" */
export type Tickets_Set_Content_Inc_Input = {
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tickets_set_content" */
export type Tickets_Set_Content_Insert_Input = {
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: InputMaybe<Scalars['String']>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
  /** Идентификатор билета в таблице admin_db.bonus_wheel_ticket_type */
  ticket_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tickets_Set_Content_Max_Fields = {
  __typename?: 'tickets_set_content_max_fields';
  content_id?: Maybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: Maybe<Scalars['String']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['bigint']>;
  /** Идентификатор билета в таблице admin_db.bonus_wheel_ticket_type */
  ticket_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tickets_set_content" */
export type Tickets_Set_Content_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Описание предмета */
  description?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
  /** Идентификатор билета в таблице admin_db.bonus_wheel_ticket_type */
  ticket_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tickets_Set_Content_Min_Fields = {
  __typename?: 'tickets_set_content_min_fields';
  content_id?: Maybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: Maybe<Scalars['String']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['bigint']>;
  /** Идентификатор билета в таблице admin_db.bonus_wheel_ticket_type */
  ticket_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tickets_set_content" */
export type Tickets_Set_Content_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Описание предмета */
  description?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
  /** Идентификатор билета в таблице admin_db.bonus_wheel_ticket_type */
  ticket_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tickets_set_content" */
export type Tickets_Set_Content_Mutation_Response = {
  __typename?: 'tickets_set_content_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tickets_Set_Content>;
};

/** on_conflict condition type for table "tickets_set_content" */
export type Tickets_Set_Content_On_Conflict = {
  constraint: Tickets_Set_Content_Constraint;
  update_columns?: Array<Tickets_Set_Content_Update_Column>;
  where?: InputMaybe<Tickets_Set_Content_Bool_Exp>;
};

/** Ordering options when selecting data from "tickets_set_content". */
export type Tickets_Set_Content_Order_By = {
  content_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
  ticket_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tickets_set_content */
export type Tickets_Set_Content_Pk_Columns_Input = {
  content_id: Scalars['bigint'];
};

/** select columns of table "tickets_set_content" */
export enum Tickets_Set_Content_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Description = 'description',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SetId = 'set_id',
  /** column name */
  TicketId = 'ticket_id'
}

/** input type for updating data in table "tickets_set_content" */
export type Tickets_Set_Content_Set_Input = {
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: InputMaybe<Scalars['String']>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
  /** Идентификатор билета в таблице admin_db.bonus_wheel_ticket_type */
  ticket_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Tickets_Set_Content_Stddev_Fields = {
  __typename?: 'tickets_set_content_stddev_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tickets_set_content" */
export type Tickets_Set_Content_Stddev_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tickets_Set_Content_Stddev_Pop_Fields = {
  __typename?: 'tickets_set_content_stddev_pop_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tickets_set_content" */
export type Tickets_Set_Content_Stddev_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tickets_Set_Content_Stddev_Samp_Fields = {
  __typename?: 'tickets_set_content_stddev_samp_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tickets_set_content" */
export type Tickets_Set_Content_Stddev_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tickets_set_content" */
export type Tickets_Set_Content_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tickets_Set_Content_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tickets_Set_Content_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['bigint']>;
  /** Описание предмета */
  description?: InputMaybe<Scalars['String']>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Scalars['bigint']>;
  /** Идентификатор билета в таблице admin_db.bonus_wheel_ticket_type */
  ticket_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Tickets_Set_Content_Sum_Fields = {
  __typename?: 'tickets_set_content_sum_fields';
  content_id?: Maybe<Scalars['bigint']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Int']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tickets_set_content" */
export type Tickets_Set_Content_Sum_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** update columns of table "tickets_set_content" */
export enum Tickets_Set_Content_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Description = 'description',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SetId = 'set_id',
  /** column name */
  TicketId = 'ticket_id'
}

export type Tickets_Set_Content_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tickets_Set_Content_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tickets_Set_Content_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tickets_Set_Content_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tickets_Set_Content_Var_Pop_Fields = {
  __typename?: 'tickets_set_content_var_pop_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tickets_set_content" */
export type Tickets_Set_Content_Var_Pop_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tickets_Set_Content_Var_Samp_Fields = {
  __typename?: 'tickets_set_content_var_samp_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tickets_set_content" */
export type Tickets_Set_Content_Var_Samp_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tickets_Set_Content_Variance_Fields = {
  __typename?: 'tickets_set_content_variance_fields';
  content_id?: Maybe<Scalars['Float']>;
  /** Количество билетов в наборе */
  quantity?: Maybe<Scalars['Float']>;
  /** Идентификатор набора */
  set_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tickets_set_content" */
export type Tickets_Set_Content_Variance_Order_By = {
  content_id?: InputMaybe<Order_By>;
  /** Количество билетов в наборе */
  quantity?: InputMaybe<Order_By>;
  /** Идентификатор набора */
  set_id?: InputMaybe<Order_By>;
};

/** Наборы билетов для продажи */
export type Tickets_Sets = {
  __typename?: 'tickets_sets';
  /** Описание набора */
  description: Scalars['String'];
  set_id: Scalars['bigint'];
};

/** aggregated selection of "tickets_sets" */
export type Tickets_Sets_Aggregate = {
  __typename?: 'tickets_sets_aggregate';
  aggregate?: Maybe<Tickets_Sets_Aggregate_Fields>;
  nodes: Array<Tickets_Sets>;
};

/** aggregate fields of "tickets_sets" */
export type Tickets_Sets_Aggregate_Fields = {
  __typename?: 'tickets_sets_aggregate_fields';
  avg?: Maybe<Tickets_Sets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tickets_Sets_Max_Fields>;
  min?: Maybe<Tickets_Sets_Min_Fields>;
  stddev?: Maybe<Tickets_Sets_Stddev_Fields>;
  stddev_pop?: Maybe<Tickets_Sets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tickets_Sets_Stddev_Samp_Fields>;
  sum?: Maybe<Tickets_Sets_Sum_Fields>;
  var_pop?: Maybe<Tickets_Sets_Var_Pop_Fields>;
  var_samp?: Maybe<Tickets_Sets_Var_Samp_Fields>;
  variance?: Maybe<Tickets_Sets_Variance_Fields>;
};


/** aggregate fields of "tickets_sets" */
export type Tickets_Sets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tickets_Sets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tickets_Sets_Avg_Fields = {
  __typename?: 'tickets_sets_avg_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tickets_sets". All fields are combined with a logical 'AND'. */
export type Tickets_Sets_Bool_Exp = {
  _and?: InputMaybe<Array<Tickets_Sets_Bool_Exp>>;
  _not?: InputMaybe<Tickets_Sets_Bool_Exp>;
  _or?: InputMaybe<Array<Tickets_Sets_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  set_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "tickets_sets" */
export enum Tickets_Sets_Constraint {
  /** unique or primary key constraint on columns "set_id" */
  TicketsSetsPkey = 'tickets_sets_pkey'
}

/** input type for incrementing numeric columns in table "tickets_sets" */
export type Tickets_Sets_Inc_Input = {
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tickets_sets" */
export type Tickets_Sets_Insert_Input = {
  /** Описание набора */
  description?: InputMaybe<Scalars['String']>;
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Tickets_Sets_Max_Fields = {
  __typename?: 'tickets_sets_max_fields';
  /** Описание набора */
  description?: Maybe<Scalars['String']>;
  set_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Tickets_Sets_Min_Fields = {
  __typename?: 'tickets_sets_min_fields';
  /** Описание набора */
  description?: Maybe<Scalars['String']>;
  set_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "tickets_sets" */
export type Tickets_Sets_Mutation_Response = {
  __typename?: 'tickets_sets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tickets_Sets>;
};

/** on_conflict condition type for table "tickets_sets" */
export type Tickets_Sets_On_Conflict = {
  constraint: Tickets_Sets_Constraint;
  update_columns?: Array<Tickets_Sets_Update_Column>;
  where?: InputMaybe<Tickets_Sets_Bool_Exp>;
};

/** Ordering options when selecting data from "tickets_sets". */
export type Tickets_Sets_Order_By = {
  description?: InputMaybe<Order_By>;
  set_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tickets_sets */
export type Tickets_Sets_Pk_Columns_Input = {
  set_id: Scalars['bigint'];
};

/** select columns of table "tickets_sets" */
export enum Tickets_Sets_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  SetId = 'set_id'
}

/** input type for updating data in table "tickets_sets" */
export type Tickets_Sets_Set_Input = {
  /** Описание набора */
  description?: InputMaybe<Scalars['String']>;
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Tickets_Sets_Stddev_Fields = {
  __typename?: 'tickets_sets_stddev_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tickets_Sets_Stddev_Pop_Fields = {
  __typename?: 'tickets_sets_stddev_pop_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tickets_Sets_Stddev_Samp_Fields = {
  __typename?: 'tickets_sets_stddev_samp_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tickets_sets" */
export type Tickets_Sets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tickets_Sets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tickets_Sets_Stream_Cursor_Value_Input = {
  /** Описание набора */
  description?: InputMaybe<Scalars['String']>;
  set_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Tickets_Sets_Sum_Fields = {
  __typename?: 'tickets_sets_sum_fields';
  set_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "tickets_sets" */
export enum Tickets_Sets_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  SetId = 'set_id'
}

export type Tickets_Sets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tickets_Sets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tickets_Sets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tickets_Sets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tickets_Sets_Var_Pop_Fields = {
  __typename?: 'tickets_sets_var_pop_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tickets_Sets_Var_Samp_Fields = {
  __typename?: 'tickets_sets_var_samp_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tickets_Sets_Variance_Fields = {
  __typename?: 'tickets_sets_variance_fields';
  set_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "top_drop_strip" */
export type Top_Drop_Strip = {
  __typename?: 'top_drop_strip';
  avatar?: Maybe<Scalars['String']>;
  drop_datetime: Scalars['timestamp'];
  drop_key: Scalars['uuid'];
  fulll_item_trigger: Scalars['Int'];
  item_class: Scalars['Int'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_formated_item_image_link" */
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  /** An object relationship */
  localized_name?: Maybe<Statistic_Items_Names>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name: Scalars['String'];
  lootbox_slug: Scalars['String'];
  persona_name?: Maybe<Scalars['String']>;
  record_id: Scalars['uuid'];
  ru_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "top_drop_strip" */
export type Top_Drop_Strip_Aggregate = {
  __typename?: 'top_drop_strip_aggregate';
  aggregate?: Maybe<Top_Drop_Strip_Aggregate_Fields>;
  nodes: Array<Top_Drop_Strip>;
};

export type Top_Drop_Strip_Aggregate_Bool_Exp = {
  count?: InputMaybe<Top_Drop_Strip_Aggregate_Bool_Exp_Count>;
};

export type Top_Drop_Strip_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Top_Drop_Strip_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "top_drop_strip" */
export type Top_Drop_Strip_Aggregate_Fields = {
  __typename?: 'top_drop_strip_aggregate_fields';
  avg?: Maybe<Top_Drop_Strip_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Top_Drop_Strip_Max_Fields>;
  min?: Maybe<Top_Drop_Strip_Min_Fields>;
  stddev?: Maybe<Top_Drop_Strip_Stddev_Fields>;
  stddev_pop?: Maybe<Top_Drop_Strip_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Top_Drop_Strip_Stddev_Samp_Fields>;
  sum?: Maybe<Top_Drop_Strip_Sum_Fields>;
  var_pop?: Maybe<Top_Drop_Strip_Var_Pop_Fields>;
  var_samp?: Maybe<Top_Drop_Strip_Var_Samp_Fields>;
  variance?: Maybe<Top_Drop_Strip_Variance_Fields>;
};


/** aggregate fields of "top_drop_strip" */
export type Top_Drop_Strip_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Top_Drop_Strip_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "top_drop_strip" */
export type Top_Drop_Strip_Aggregate_Order_By = {
  avg?: InputMaybe<Top_Drop_Strip_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Top_Drop_Strip_Max_Order_By>;
  min?: InputMaybe<Top_Drop_Strip_Min_Order_By>;
  stddev?: InputMaybe<Top_Drop_Strip_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Top_Drop_Strip_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Top_Drop_Strip_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Top_Drop_Strip_Sum_Order_By>;
  var_pop?: InputMaybe<Top_Drop_Strip_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Top_Drop_Strip_Var_Samp_Order_By>;
  variance?: InputMaybe<Top_Drop_Strip_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Top_Drop_Strip_Avg_Fields = {
  __typename?: 'top_drop_strip_avg_fields';
  fulll_item_trigger?: Maybe<Scalars['Float']>;
  item_class?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "top_drop_strip" */
export type Top_Drop_Strip_Avg_Order_By = {
  fulll_item_trigger?: InputMaybe<Order_By>;
  item_class?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "top_drop_strip". All fields are combined with a logical 'AND'. */
export type Top_Drop_Strip_Bool_Exp = {
  _and?: InputMaybe<Array<Top_Drop_Strip_Bool_Exp>>;
  _not?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
  _or?: InputMaybe<Array<Top_Drop_Strip_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  drop_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  drop_key?: InputMaybe<Uuid_Comparison_Exp>;
  fulll_item_trigger?: InputMaybe<Int_Comparison_Exp>;
  item_class?: InputMaybe<Int_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_image_link?: InputMaybe<String_Comparison_Exp>;
  item_image_link_formated?: InputMaybe<String_Comparison_Exp>;
  item_quality?: InputMaybe<String_Comparison_Exp>;
  item_rarity?: InputMaybe<String_Comparison_Exp>;
  item_start_price?: InputMaybe<Float_Comparison_Exp>;
  localized_name?: InputMaybe<Statistic_Items_Names_Bool_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  lootbox_image_link?: InputMaybe<String_Comparison_Exp>;
  lootbox_name?: InputMaybe<String_Comparison_Exp>;
  lootbox_slug?: InputMaybe<String_Comparison_Exp>;
  persona_name?: InputMaybe<String_Comparison_Exp>;
  record_id?: InputMaybe<Uuid_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "top_drop_strip" */
export enum Top_Drop_Strip_Constraint {
  /** unique or primary key constraint on columns "record_id" */
  TopDropStripPkey = 'top_drop_strip_pkey',
  /** unique or primary key constraint on columns "record_id" */
  TopDropStripRecordIdKey = 'top_drop_strip_record_id_key'
}

/** input type for incrementing numeric columns in table "top_drop_strip" */
export type Top_Drop_Strip_Inc_Input = {
  fulll_item_trigger?: InputMaybe<Scalars['Int']>;
  item_class?: InputMaybe<Scalars['Int']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "top_drop_strip" */
export type Top_Drop_Strip_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  drop_datetime?: InputMaybe<Scalars['timestamp']>;
  drop_key?: InputMaybe<Scalars['uuid']>;
  fulll_item_trigger?: InputMaybe<Scalars['Int']>;
  item_class?: InputMaybe<Scalars['Int']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  localized_name?: InputMaybe<Statistic_Items_Names_Obj_Rel_Insert_Input>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  persona_name?: InputMaybe<Scalars['String']>;
  record_id?: InputMaybe<Scalars['uuid']>;
  ru_name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Top_Drop_Strip_Max_Fields = {
  __typename?: 'top_drop_strip_max_fields';
  avatar?: Maybe<Scalars['String']>;
  drop_datetime?: Maybe<Scalars['timestamp']>;
  drop_key?: Maybe<Scalars['uuid']>;
  fulll_item_trigger?: Maybe<Scalars['Int']>;
  item_class?: Maybe<Scalars['Int']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_formated_item_image_link" */
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  persona_name?: Maybe<Scalars['String']>;
  record_id?: Maybe<Scalars['uuid']>;
  ru_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "top_drop_strip" */
export type Top_Drop_Strip_Max_Order_By = {
  avatar?: InputMaybe<Order_By>;
  drop_datetime?: InputMaybe<Order_By>;
  drop_key?: InputMaybe<Order_By>;
  fulll_item_trigger?: InputMaybe<Order_By>;
  item_class?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  persona_name?: InputMaybe<Order_By>;
  record_id?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Top_Drop_Strip_Min_Fields = {
  __typename?: 'top_drop_strip_min_fields';
  avatar?: Maybe<Scalars['String']>;
  drop_datetime?: Maybe<Scalars['timestamp']>;
  drop_key?: Maybe<Scalars['uuid']>;
  fulll_item_trigger?: Maybe<Scalars['Int']>;
  item_class?: Maybe<Scalars['Int']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_formated_item_image_link" */
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rarity?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  lootbox_id?: Maybe<Scalars['uuid']>;
  lootbox_image_link?: Maybe<Scalars['String']>;
  lootbox_name?: Maybe<Scalars['String']>;
  lootbox_slug?: Maybe<Scalars['String']>;
  persona_name?: Maybe<Scalars['String']>;
  record_id?: Maybe<Scalars['uuid']>;
  ru_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "top_drop_strip" */
export type Top_Drop_Strip_Min_Order_By = {
  avatar?: InputMaybe<Order_By>;
  drop_datetime?: InputMaybe<Order_By>;
  drop_key?: InputMaybe<Order_By>;
  fulll_item_trigger?: InputMaybe<Order_By>;
  item_class?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  persona_name?: InputMaybe<Order_By>;
  record_id?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "top_drop_strip" */
export type Top_Drop_Strip_Mutation_Response = {
  __typename?: 'top_drop_strip_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Top_Drop_Strip>;
};

/** on_conflict condition type for table "top_drop_strip" */
export type Top_Drop_Strip_On_Conflict = {
  constraint: Top_Drop_Strip_Constraint;
  update_columns?: Array<Top_Drop_Strip_Update_Column>;
  where?: InputMaybe<Top_Drop_Strip_Bool_Exp>;
};

/** Ordering options when selecting data from "top_drop_strip". */
export type Top_Drop_Strip_Order_By = {
  avatar?: InputMaybe<Order_By>;
  drop_datetime?: InputMaybe<Order_By>;
  drop_key?: InputMaybe<Order_By>;
  fulll_item_trigger?: InputMaybe<Order_By>;
  item_class?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_image_link_formated?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rarity?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  localized_name?: InputMaybe<Statistic_Items_Names_Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  lootbox_image_link?: InputMaybe<Order_By>;
  lootbox_name?: InputMaybe<Order_By>;
  lootbox_slug?: InputMaybe<Order_By>;
  persona_name?: InputMaybe<Order_By>;
  record_id?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: top_drop_strip */
export type Top_Drop_Strip_Pk_Columns_Input = {
  record_id: Scalars['uuid'];
};

/** select columns of table "top_drop_strip" */
export enum Top_Drop_Strip_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  DropDatetime = 'drop_datetime',
  /** column name */
  DropKey = 'drop_key',
  /** column name */
  FulllItemTrigger = 'fulll_item_trigger',
  /** column name */
  ItemClass = 'item_class',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  PersonaName = 'persona_name',
  /** column name */
  RecordId = 'record_id',
  /** column name */
  RuName = 'ru_name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "top_drop_strip" */
export type Top_Drop_Strip_Set_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  drop_datetime?: InputMaybe<Scalars['timestamp']>;
  drop_key?: InputMaybe<Scalars['uuid']>;
  fulll_item_trigger?: InputMaybe<Scalars['Int']>;
  item_class?: InputMaybe<Scalars['Int']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  persona_name?: InputMaybe<Scalars['String']>;
  record_id?: InputMaybe<Scalars['uuid']>;
  ru_name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Top_Drop_Strip_Stddev_Fields = {
  __typename?: 'top_drop_strip_stddev_fields';
  fulll_item_trigger?: Maybe<Scalars['Float']>;
  item_class?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "top_drop_strip" */
export type Top_Drop_Strip_Stddev_Order_By = {
  fulll_item_trigger?: InputMaybe<Order_By>;
  item_class?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Top_Drop_Strip_Stddev_Pop_Fields = {
  __typename?: 'top_drop_strip_stddev_pop_fields';
  fulll_item_trigger?: Maybe<Scalars['Float']>;
  item_class?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "top_drop_strip" */
export type Top_Drop_Strip_Stddev_Pop_Order_By = {
  fulll_item_trigger?: InputMaybe<Order_By>;
  item_class?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Top_Drop_Strip_Stddev_Samp_Fields = {
  __typename?: 'top_drop_strip_stddev_samp_fields';
  fulll_item_trigger?: Maybe<Scalars['Float']>;
  item_class?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "top_drop_strip" */
export type Top_Drop_Strip_Stddev_Samp_Order_By = {
  fulll_item_trigger?: InputMaybe<Order_By>;
  item_class?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "top_drop_strip" */
export type Top_Drop_Strip_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Top_Drop_Strip_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Top_Drop_Strip_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  drop_datetime?: InputMaybe<Scalars['timestamp']>;
  drop_key?: InputMaybe<Scalars['uuid']>;
  fulll_item_trigger?: InputMaybe<Scalars['Int']>;
  item_class?: InputMaybe<Scalars['Int']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rarity?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  lootbox_image_link?: InputMaybe<Scalars['String']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_slug?: InputMaybe<Scalars['String']>;
  persona_name?: InputMaybe<Scalars['String']>;
  record_id?: InputMaybe<Scalars['uuid']>;
  ru_name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Top_Drop_Strip_Sum_Fields = {
  __typename?: 'top_drop_strip_sum_fields';
  fulll_item_trigger?: Maybe<Scalars['Int']>;
  item_class?: Maybe<Scalars['Int']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "top_drop_strip" */
export type Top_Drop_Strip_Sum_Order_By = {
  fulll_item_trigger?: InputMaybe<Order_By>;
  item_class?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** update columns of table "top_drop_strip" */
export enum Top_Drop_Strip_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  DropDatetime = 'drop_datetime',
  /** column name */
  DropKey = 'drop_key',
  /** column name */
  FulllItemTrigger = 'fulll_item_trigger',
  /** column name */
  ItemClass = 'item_class',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRarity = 'item_rarity',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  LootboxImageLink = 'lootbox_image_link',
  /** column name */
  LootboxName = 'lootbox_name',
  /** column name */
  LootboxSlug = 'lootbox_slug',
  /** column name */
  PersonaName = 'persona_name',
  /** column name */
  RecordId = 'record_id',
  /** column name */
  RuName = 'ru_name',
  /** column name */
  UserId = 'user_id'
}

export type Top_Drop_Strip_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Top_Drop_Strip_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Top_Drop_Strip_Set_Input>;
  /** filter the rows which have to be updated */
  where: Top_Drop_Strip_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Top_Drop_Strip_Var_Pop_Fields = {
  __typename?: 'top_drop_strip_var_pop_fields';
  fulll_item_trigger?: Maybe<Scalars['Float']>;
  item_class?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "top_drop_strip" */
export type Top_Drop_Strip_Var_Pop_Order_By = {
  fulll_item_trigger?: InputMaybe<Order_By>;
  item_class?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Top_Drop_Strip_Var_Samp_Fields = {
  __typename?: 'top_drop_strip_var_samp_fields';
  fulll_item_trigger?: Maybe<Scalars['Float']>;
  item_class?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "top_drop_strip" */
export type Top_Drop_Strip_Var_Samp_Order_By = {
  fulll_item_trigger?: InputMaybe<Order_By>;
  item_class?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Top_Drop_Strip_Variance_Fields = {
  __typename?: 'top_drop_strip_variance_fields';
  fulll_item_trigger?: Maybe<Scalars['Float']>;
  item_class?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "top_drop_strip" */
export type Top_Drop_Strip_Variance_Order_By = {
  fulll_item_trigger?: InputMaybe<Order_By>;
  item_class?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "transaction" */
export type Transaction = {
  __typename?: 'transaction';
  /** An object relationship */
  bill: Bill;
  /** An object relationship */
  billByDebit: Bill;
  credit: Scalars['uuid'];
  credit_value: Scalars['Float'];
  datetime: Scalars['timestamp'];
  debit: Scalars['uuid'];
  debit_value: Scalars['Float'];
  id: Scalars['uuid'];
  order_num: Scalars['bigint'];
  ps_transaction_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  to_beta_callbacks?: Maybe<Payment_System_Main_Callbacks>;
  /** An object relationship */
  to_fk_callbacks?: Maybe<Payment_System_Free_Kassa_Callbacks>;
};

/** aggregated selection of "transaction" */
export type Transaction_Aggregate = {
  __typename?: 'transaction_aggregate';
  aggregate?: Maybe<Transaction_Aggregate_Fields>;
  nodes: Array<Transaction>;
};

export type Transaction_Aggregate_Bool_Exp = {
  count?: InputMaybe<Transaction_Aggregate_Bool_Exp_Count>;
};

export type Transaction_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Transaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transaction_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "transaction" */
export type Transaction_Aggregate_Fields = {
  __typename?: 'transaction_aggregate_fields';
  avg?: Maybe<Transaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transaction_Max_Fields>;
  min?: Maybe<Transaction_Min_Fields>;
  stddev?: Maybe<Transaction_Stddev_Fields>;
  stddev_pop?: Maybe<Transaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transaction_Stddev_Samp_Fields>;
  sum?: Maybe<Transaction_Sum_Fields>;
  var_pop?: Maybe<Transaction_Var_Pop_Fields>;
  var_samp?: Maybe<Transaction_Var_Samp_Fields>;
  variance?: Maybe<Transaction_Variance_Fields>;
};


/** aggregate fields of "transaction" */
export type Transaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transaction" */
export type Transaction_Aggregate_Order_By = {
  avg?: InputMaybe<Transaction_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Transaction_Max_Order_By>;
  min?: InputMaybe<Transaction_Min_Order_By>;
  stddev?: InputMaybe<Transaction_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Transaction_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Transaction_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Transaction_Sum_Order_By>;
  var_pop?: InputMaybe<Transaction_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Transaction_Var_Samp_Order_By>;
  variance?: InputMaybe<Transaction_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "transaction" */
export type Transaction_Arr_Rel_Insert_Input = {
  data: Array<Transaction_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};

/** aggregate avg on columns */
export type Transaction_Avg_Fields = {
  __typename?: 'transaction_avg_fields';
  credit_value?: Maybe<Scalars['Float']>;
  debit_value?: Maybe<Scalars['Float']>;
  order_num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transaction" */
export type Transaction_Avg_Order_By = {
  credit_value?: InputMaybe<Order_By>;
  debit_value?: InputMaybe<Order_By>;
  order_num?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transaction". All fields are combined with a logical 'AND'. */
export type Transaction_Bool_Exp = {
  _and?: InputMaybe<Array<Transaction_Bool_Exp>>;
  _not?: InputMaybe<Transaction_Bool_Exp>;
  _or?: InputMaybe<Array<Transaction_Bool_Exp>>;
  bill?: InputMaybe<Bill_Bool_Exp>;
  billByDebit?: InputMaybe<Bill_Bool_Exp>;
  credit?: InputMaybe<Uuid_Comparison_Exp>;
  credit_value?: InputMaybe<Float_Comparison_Exp>;
  datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  debit?: InputMaybe<Uuid_Comparison_Exp>;
  debit_value?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order_num?: InputMaybe<Bigint_Comparison_Exp>;
  ps_transaction_id?: InputMaybe<String_Comparison_Exp>;
  to_beta_callbacks?: InputMaybe<Payment_System_Main_Callbacks_Bool_Exp>;
  to_fk_callbacks?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Bool_Exp>;
};

/** unique or primary key constraints on table "transaction" */
export enum Transaction_Constraint {
  /** unique or primary key constraint on columns "id", "order_num" */
  TransactionPkey = 'transaction_pkey'
}

/** input type for incrementing numeric columns in table "transaction" */
export type Transaction_Inc_Input = {
  credit_value?: InputMaybe<Scalars['Float']>;
  debit_value?: InputMaybe<Scalars['Float']>;
  order_num?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "transaction" */
export type Transaction_Insert_Input = {
  bill?: InputMaybe<Bill_Obj_Rel_Insert_Input>;
  billByDebit?: InputMaybe<Bill_Obj_Rel_Insert_Input>;
  credit?: InputMaybe<Scalars['uuid']>;
  credit_value?: InputMaybe<Scalars['Float']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  debit?: InputMaybe<Scalars['uuid']>;
  debit_value?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_num?: InputMaybe<Scalars['bigint']>;
  ps_transaction_id?: InputMaybe<Scalars['String']>;
  to_beta_callbacks?: InputMaybe<Payment_System_Main_Callbacks_Obj_Rel_Insert_Input>;
  to_fk_callbacks?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Transaction_Max_Fields = {
  __typename?: 'transaction_max_fields';
  credit?: Maybe<Scalars['uuid']>;
  credit_value?: Maybe<Scalars['Float']>;
  datetime?: Maybe<Scalars['timestamp']>;
  debit?: Maybe<Scalars['uuid']>;
  debit_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  order_num?: Maybe<Scalars['bigint']>;
  ps_transaction_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "transaction" */
export type Transaction_Max_Order_By = {
  credit?: InputMaybe<Order_By>;
  credit_value?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  debit?: InputMaybe<Order_By>;
  debit_value?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_num?: InputMaybe<Order_By>;
  ps_transaction_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Transaction_Min_Fields = {
  __typename?: 'transaction_min_fields';
  credit?: Maybe<Scalars['uuid']>;
  credit_value?: Maybe<Scalars['Float']>;
  datetime?: Maybe<Scalars['timestamp']>;
  debit?: Maybe<Scalars['uuid']>;
  debit_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  order_num?: Maybe<Scalars['bigint']>;
  ps_transaction_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "transaction" */
export type Transaction_Min_Order_By = {
  credit?: InputMaybe<Order_By>;
  credit_value?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  debit?: InputMaybe<Order_By>;
  debit_value?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_num?: InputMaybe<Order_By>;
  ps_transaction_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "transaction" */
export type Transaction_Mutation_Response = {
  __typename?: 'transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transaction>;
};

/** input type for inserting object relation for remote table "transaction" */
export type Transaction_Obj_Rel_Insert_Input = {
  data: Transaction_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};

/** on_conflict condition type for table "transaction" */
export type Transaction_On_Conflict = {
  constraint: Transaction_Constraint;
  update_columns?: Array<Transaction_Update_Column>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "transaction". */
export type Transaction_Order_By = {
  bill?: InputMaybe<Bill_Order_By>;
  billByDebit?: InputMaybe<Bill_Order_By>;
  credit?: InputMaybe<Order_By>;
  credit_value?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  debit?: InputMaybe<Order_By>;
  debit_value?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_num?: InputMaybe<Order_By>;
  ps_transaction_id?: InputMaybe<Order_By>;
  to_beta_callbacks?: InputMaybe<Payment_System_Main_Callbacks_Order_By>;
  to_fk_callbacks?: InputMaybe<Payment_System_Free_Kassa_Callbacks_Order_By>;
};

/** primary key columns input for table: transaction */
export type Transaction_Pk_Columns_Input = {
  id: Scalars['uuid'];
  order_num: Scalars['bigint'];
};

/** select columns of table "transaction" */
export enum Transaction_Select_Column {
  /** column name */
  Credit = 'credit',
  /** column name */
  CreditValue = 'credit_value',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Debit = 'debit',
  /** column name */
  DebitValue = 'debit_value',
  /** column name */
  Id = 'id',
  /** column name */
  OrderNum = 'order_num',
  /** column name */
  PsTransactionId = 'ps_transaction_id'
}

/** input type for updating data in table "transaction" */
export type Transaction_Set_Input = {
  credit?: InputMaybe<Scalars['uuid']>;
  credit_value?: InputMaybe<Scalars['Float']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  debit?: InputMaybe<Scalars['uuid']>;
  debit_value?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_num?: InputMaybe<Scalars['bigint']>;
  ps_transaction_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Transaction_Stddev_Fields = {
  __typename?: 'transaction_stddev_fields';
  credit_value?: Maybe<Scalars['Float']>;
  debit_value?: Maybe<Scalars['Float']>;
  order_num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transaction" */
export type Transaction_Stddev_Order_By = {
  credit_value?: InputMaybe<Order_By>;
  debit_value?: InputMaybe<Order_By>;
  order_num?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transaction_Stddev_Pop_Fields = {
  __typename?: 'transaction_stddev_pop_fields';
  credit_value?: Maybe<Scalars['Float']>;
  debit_value?: Maybe<Scalars['Float']>;
  order_num?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "transaction" */
export type Transaction_Stddev_Pop_Order_By = {
  credit_value?: InputMaybe<Order_By>;
  debit_value?: InputMaybe<Order_By>;
  order_num?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transaction_Stddev_Samp_Fields = {
  __typename?: 'transaction_stddev_samp_fields';
  credit_value?: Maybe<Scalars['Float']>;
  debit_value?: Maybe<Scalars['Float']>;
  order_num?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "transaction" */
export type Transaction_Stddev_Samp_Order_By = {
  credit_value?: InputMaybe<Order_By>;
  debit_value?: InputMaybe<Order_By>;
  order_num?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transaction" */
export type Transaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transaction_Stream_Cursor_Value_Input = {
  credit?: InputMaybe<Scalars['uuid']>;
  credit_value?: InputMaybe<Scalars['Float']>;
  datetime?: InputMaybe<Scalars['timestamp']>;
  debit?: InputMaybe<Scalars['uuid']>;
  debit_value?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_num?: InputMaybe<Scalars['bigint']>;
  ps_transaction_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Transaction_Sum_Fields = {
  __typename?: 'transaction_sum_fields';
  credit_value?: Maybe<Scalars['Float']>;
  debit_value?: Maybe<Scalars['Float']>;
  order_num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "transaction" */
export type Transaction_Sum_Order_By = {
  credit_value?: InputMaybe<Order_By>;
  debit_value?: InputMaybe<Order_By>;
  order_num?: InputMaybe<Order_By>;
};

/** update columns of table "transaction" */
export enum Transaction_Update_Column {
  /** column name */
  Credit = 'credit',
  /** column name */
  CreditValue = 'credit_value',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  Debit = 'debit',
  /** column name */
  DebitValue = 'debit_value',
  /** column name */
  Id = 'id',
  /** column name */
  OrderNum = 'order_num',
  /** column name */
  PsTransactionId = 'ps_transaction_id'
}

export type Transaction_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transaction_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transaction_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transaction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Transaction_Var_Pop_Fields = {
  __typename?: 'transaction_var_pop_fields';
  credit_value?: Maybe<Scalars['Float']>;
  debit_value?: Maybe<Scalars['Float']>;
  order_num?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "transaction" */
export type Transaction_Var_Pop_Order_By = {
  credit_value?: InputMaybe<Order_By>;
  debit_value?: InputMaybe<Order_By>;
  order_num?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transaction_Var_Samp_Fields = {
  __typename?: 'transaction_var_samp_fields';
  credit_value?: Maybe<Scalars['Float']>;
  debit_value?: Maybe<Scalars['Float']>;
  order_num?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "transaction" */
export type Transaction_Var_Samp_Order_By = {
  credit_value?: InputMaybe<Order_By>;
  debit_value?: InputMaybe<Order_By>;
  order_num?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Transaction_Variance_Fields = {
  __typename?: 'transaction_variance_fields';
  credit_value?: Maybe<Scalars['Float']>;
  debit_value?: Maybe<Scalars['Float']>;
  order_num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transaction" */
export type Transaction_Variance_Order_By = {
  credit_value?: InputMaybe<Order_By>;
  debit_value?: InputMaybe<Order_By>;
  order_num?: InputMaybe<Order_By>;
};

/** Данные по соотношению кодов и полных ссылок для функционала укорачивания ссылок */
export type Url_Mappings = {
  __typename?: 'url_mappings';
  /** Код для короткой ссылки */
  code: Scalars['String'];
  /** Дата и время создания записи */
  created_at: Scalars['timestamp'];
  /** Полная оригинальная ссылка */
  original_url: Scalars['String'];
};

/** aggregated selection of "url_mappings" */
export type Url_Mappings_Aggregate = {
  __typename?: 'url_mappings_aggregate';
  aggregate?: Maybe<Url_Mappings_Aggregate_Fields>;
  nodes: Array<Url_Mappings>;
};

/** aggregate fields of "url_mappings" */
export type Url_Mappings_Aggregate_Fields = {
  __typename?: 'url_mappings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Url_Mappings_Max_Fields>;
  min?: Maybe<Url_Mappings_Min_Fields>;
};


/** aggregate fields of "url_mappings" */
export type Url_Mappings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Url_Mappings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "url_mappings". All fields are combined with a logical 'AND'. */
export type Url_Mappings_Bool_Exp = {
  _and?: InputMaybe<Array<Url_Mappings_Bool_Exp>>;
  _not?: InputMaybe<Url_Mappings_Bool_Exp>;
  _or?: InputMaybe<Array<Url_Mappings_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  original_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "url_mappings" */
export enum Url_Mappings_Constraint {
  /** unique or primary key constraint on columns "code" */
  UrlMappingsPkey = 'url_mappings_pkey'
}

/** input type for inserting data into table "url_mappings" */
export type Url_Mappings_Insert_Input = {
  /** Код для короткой ссылки */
  code?: InputMaybe<Scalars['String']>;
  /** Дата и время создания записи */
  created_at?: InputMaybe<Scalars['timestamp']>;
  /** Полная оригинальная ссылка */
  original_url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Url_Mappings_Max_Fields = {
  __typename?: 'url_mappings_max_fields';
  /** Код для короткой ссылки */
  code?: Maybe<Scalars['String']>;
  /** Дата и время создания записи */
  created_at?: Maybe<Scalars['timestamp']>;
  /** Полная оригинальная ссылка */
  original_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Url_Mappings_Min_Fields = {
  __typename?: 'url_mappings_min_fields';
  /** Код для короткой ссылки */
  code?: Maybe<Scalars['String']>;
  /** Дата и время создания записи */
  created_at?: Maybe<Scalars['timestamp']>;
  /** Полная оригинальная ссылка */
  original_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "url_mappings" */
export type Url_Mappings_Mutation_Response = {
  __typename?: 'url_mappings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Url_Mappings>;
};

/** on_conflict condition type for table "url_mappings" */
export type Url_Mappings_On_Conflict = {
  constraint: Url_Mappings_Constraint;
  update_columns?: Array<Url_Mappings_Update_Column>;
  where?: InputMaybe<Url_Mappings_Bool_Exp>;
};

/** Ordering options when selecting data from "url_mappings". */
export type Url_Mappings_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  original_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: url_mappings */
export type Url_Mappings_Pk_Columns_Input = {
  /** Код для короткой ссылки */
  code: Scalars['String'];
};

/** select columns of table "url_mappings" */
export enum Url_Mappings_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OriginalUrl = 'original_url'
}

/** input type for updating data in table "url_mappings" */
export type Url_Mappings_Set_Input = {
  /** Код для короткой ссылки */
  code?: InputMaybe<Scalars['String']>;
  /** Дата и время создания записи */
  created_at?: InputMaybe<Scalars['timestamp']>;
  /** Полная оригинальная ссылка */
  original_url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "url_mappings" */
export type Url_Mappings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Url_Mappings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Url_Mappings_Stream_Cursor_Value_Input = {
  /** Код для короткой ссылки */
  code?: InputMaybe<Scalars['String']>;
  /** Дата и время создания записи */
  created_at?: InputMaybe<Scalars['timestamp']>;
  /** Полная оригинальная ссылка */
  original_url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "url_mappings" */
export enum Url_Mappings_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OriginalUrl = 'original_url'
}

export type Url_Mappings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Url_Mappings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Url_Mappings_Bool_Exp;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  age?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  passport_info?: Maybe<Passport_Info>;
};

/** columns and relationships of "user_account" */
export type User_Account = {
  __typename?: 'user_account';
  admin_output_block: Scalars['Boolean'];
  /** An array relationship */
  bot_insert_user_bonus_wallet: Array<User_Bonus_Wallet>;
  /** An aggregate relationship */
  bot_insert_user_bonus_wallet_aggregate: User_Bonus_Wallet_Aggregate;
  /** An array relationship */
  bot_insert_user_inventory: Array<User_Inventory>;
  /** An aggregate relationship */
  bot_insert_user_inventory_aggregate: User_Inventory_Aggregate;
  /** An array relationship */
  bot_insert_user_wallet: Array<User_Wallet>;
  /** An aggregate relationship */
  bot_insert_user_wallet_aggregate: User_Wallet_Aggregate;
  bot_lootbox_opens: Array<Bots_Lootboxes_Opens_Count>;
  bot_lootbox_opens_aggregate: Bots_Lootboxes_Opens_Count_Aggregate;
  bot_user: Scalars['Boolean'];
  /** An object relationship */
  daily_reward_counts?: Maybe<User_Daily_Reward_Counts>;
  is_banned?: Maybe<Scalars['Boolean']>;
  is_output_blocked: Scalars['Boolean'];
  /** An array relationship */
  new_year_competition_participants_performances: Array<New_Year_Competition_Participants_Performance>;
  /** An aggregate relationship */
  new_year_competition_participants_performances_aggregate: New_Year_Competition_Participants_Performance_Aggregate;
  /** An object relationship */
  user_bonus_wallet?: Maybe<User_Bonus_Wallet>;
  user_bonus_wallet_id: Scalars['uuid'];
  /** An array relationship */
  user_boosters_in_inventories: Array<User_Boosters_In_Inventory>;
  /** An aggregate relationship */
  user_boosters_in_inventories_aggregate: User_Boosters_In_Inventory_Aggregate;
  user_id: Scalars['uuid'];
  /** An object relationship */
  user_inventory?: Maybe<User_Inventory>;
  user_inventory_id: Scalars['uuid'];
  /** An object relationship */
  user_multiacc_ban?: Maybe<Banned_Users_For_Multiaccount>;
  user_profile?: Maybe<User_Profile>;
  user_profile_statistic?: Maybe<User_Statistic>;
  /** An array relationship */
  user_referral_associations: Array<User_Referral_Association>;
  /** An aggregate relationship */
  user_referral_associations_aggregate: User_Referral_Association_Aggregate;
  user_tickets: Array<Bonus_Wheel_User_Tickets>;
  user_tickets_aggregate: Bonus_Wheel_User_Tickets_Aggregate;
  user_trade_partner_id?: Maybe<Scalars['String']>;
  user_trade_str_part?: Maybe<Scalars['String']>;
  /** An object relationship */
  user_wallet?: Maybe<User_Wallet>;
  user_wallet_id: Scalars['uuid'];
};


/** columns and relationships of "user_account" */
export type User_AccountBot_Insert_User_Bonus_WalletArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountBot_Insert_User_Bonus_Wallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountBot_Insert_User_InventoryArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Order_By>>;
  where?: InputMaybe<User_Inventory_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountBot_Insert_User_Inventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Order_By>>;
  where?: InputMaybe<User_Inventory_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountBot_Insert_User_WalletArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountBot_Insert_User_Wallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Order_By>>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountBot_Lootbox_OpensArgs = {
  distinct_on?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Order_By>>;
  where?: InputMaybe<Bots_Lootboxes_Opens_Count_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountBot_Lootbox_Opens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bots_Lootboxes_Opens_Count_Order_By>>;
  where?: InputMaybe<Bots_Lootboxes_Opens_Count_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountNew_Year_Competition_Participants_PerformancesArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Performance_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountNew_Year_Competition_Participants_Performances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<New_Year_Competition_Participants_Performance_Order_By>>;
  where?: InputMaybe<New_Year_Competition_Participants_Performance_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountUser_Boosters_In_InventoriesArgs = {
  distinct_on?: InputMaybe<Array<User_Boosters_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Boosters_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountUser_Boosters_In_Inventories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Boosters_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Boosters_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountUser_Referral_AssociationsArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Association_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Association_Order_By>>;
  where?: InputMaybe<User_Referral_Association_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountUser_Referral_Associations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Association_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Association_Order_By>>;
  where?: InputMaybe<User_Referral_Association_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountUser_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_User_Tickets_Bool_Exp>;
};


/** columns and relationships of "user_account" */
export type User_AccountUser_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bonus_Wheel_User_Tickets_Order_By>>;
  where?: InputMaybe<Bonus_Wheel_User_Tickets_Bool_Exp>;
};

/** aggregated selection of "user_account" */
export type User_Account_Aggregate = {
  __typename?: 'user_account_aggregate';
  aggregate?: Maybe<User_Account_Aggregate_Fields>;
  nodes: Array<User_Account>;
};

/** aggregate fields of "user_account" */
export type User_Account_Aggregate_Fields = {
  __typename?: 'user_account_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Account_Max_Fields>;
  min?: Maybe<User_Account_Min_Fields>;
};


/** aggregate fields of "user_account" */
export type User_Account_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Account_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_account". All fields are combined with a logical 'AND'. */
export type User_Account_Bool_Exp = {
  _and?: InputMaybe<Array<User_Account_Bool_Exp>>;
  _not?: InputMaybe<User_Account_Bool_Exp>;
  _or?: InputMaybe<Array<User_Account_Bool_Exp>>;
  admin_output_block?: InputMaybe<Boolean_Comparison_Exp>;
  bot_insert_user_bonus_wallet?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
  bot_insert_user_bonus_wallet_aggregate?: InputMaybe<User_Bonus_Wallet_Aggregate_Bool_Exp>;
  bot_insert_user_inventory?: InputMaybe<User_Inventory_Bool_Exp>;
  bot_insert_user_inventory_aggregate?: InputMaybe<User_Inventory_Aggregate_Bool_Exp>;
  bot_insert_user_wallet?: InputMaybe<User_Wallet_Bool_Exp>;
  bot_insert_user_wallet_aggregate?: InputMaybe<User_Wallet_Aggregate_Bool_Exp>;
  bot_user?: InputMaybe<Boolean_Comparison_Exp>;
  daily_reward_counts?: InputMaybe<User_Daily_Reward_Counts_Bool_Exp>;
  is_banned?: InputMaybe<Boolean_Comparison_Exp>;
  is_output_blocked?: InputMaybe<Boolean_Comparison_Exp>;
  new_year_competition_participants_performances?: InputMaybe<New_Year_Competition_Participants_Performance_Bool_Exp>;
  new_year_competition_participants_performances_aggregate?: InputMaybe<New_Year_Competition_Participants_Performance_Aggregate_Bool_Exp>;
  user_bonus_wallet?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
  user_bonus_wallet_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_boosters_in_inventories?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
  user_boosters_in_inventories_aggregate?: InputMaybe<User_Boosters_In_Inventory_Aggregate_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_inventory?: InputMaybe<User_Inventory_Bool_Exp>;
  user_inventory_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_multiacc_ban?: InputMaybe<Banned_Users_For_Multiaccount_Bool_Exp>;
  user_referral_associations?: InputMaybe<User_Referral_Association_Bool_Exp>;
  user_referral_associations_aggregate?: InputMaybe<User_Referral_Association_Aggregate_Bool_Exp>;
  user_trade_partner_id?: InputMaybe<String_Comparison_Exp>;
  user_trade_str_part?: InputMaybe<String_Comparison_Exp>;
  user_wallet?: InputMaybe<User_Wallet_Bool_Exp>;
  user_wallet_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_account" */
export enum User_Account_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserAccountPkey = 'user_account_pkey',
  /** unique or primary key constraint on columns "user_bonus_wallet_id" */
  UserAccountUserBonusWalletIdKey = 'user_account_user_bonus_wallet_id_key',
  /** unique or primary key constraint on columns "user_id" */
  UserAccountUserIdKey = 'user_account_user_id_key',
  /** unique or primary key constraint on columns "user_inventory_id" */
  UserAccountUserInventoryIdKey = 'user_account_user_inventory_id_key',
  /** unique or primary key constraint on columns "user_wallet_id" */
  UserAccountUserWalletIdKey = 'user_account_user_wallet_id_key'
}

/** input type for inserting data into table "user_account" */
export type User_Account_Insert_Input = {
  admin_output_block?: InputMaybe<Scalars['Boolean']>;
  bot_insert_user_bonus_wallet?: InputMaybe<User_Bonus_Wallet_Arr_Rel_Insert_Input>;
  bot_insert_user_inventory?: InputMaybe<User_Inventory_Arr_Rel_Insert_Input>;
  bot_insert_user_wallet?: InputMaybe<User_Wallet_Arr_Rel_Insert_Input>;
  bot_user?: InputMaybe<Scalars['Boolean']>;
  daily_reward_counts?: InputMaybe<User_Daily_Reward_Counts_Obj_Rel_Insert_Input>;
  is_banned?: InputMaybe<Scalars['Boolean']>;
  is_output_blocked?: InputMaybe<Scalars['Boolean']>;
  new_year_competition_participants_performances?: InputMaybe<New_Year_Competition_Participants_Performance_Arr_Rel_Insert_Input>;
  user_bonus_wallet?: InputMaybe<User_Bonus_Wallet_Obj_Rel_Insert_Input>;
  user_bonus_wallet_id?: InputMaybe<Scalars['uuid']>;
  user_boosters_in_inventories?: InputMaybe<User_Boosters_In_Inventory_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_inventory?: InputMaybe<User_Inventory_Obj_Rel_Insert_Input>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_multiacc_ban?: InputMaybe<Banned_Users_For_Multiaccount_Obj_Rel_Insert_Input>;
  user_referral_associations?: InputMaybe<User_Referral_Association_Arr_Rel_Insert_Input>;
  user_trade_partner_id?: InputMaybe<Scalars['String']>;
  user_trade_str_part?: InputMaybe<Scalars['String']>;
  user_wallet?: InputMaybe<User_Wallet_Obj_Rel_Insert_Input>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Account_Max_Fields = {
  __typename?: 'user_account_max_fields';
  user_bonus_wallet_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
  user_trade_partner_id?: Maybe<Scalars['String']>;
  user_trade_str_part?: Maybe<Scalars['String']>;
  user_wallet_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Account_Min_Fields = {
  __typename?: 'user_account_min_fields';
  user_bonus_wallet_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
  user_trade_partner_id?: Maybe<Scalars['String']>;
  user_trade_str_part?: Maybe<Scalars['String']>;
  user_wallet_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_account" */
export type User_Account_Mutation_Response = {
  __typename?: 'user_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Account>;
};

/** input type for inserting object relation for remote table "user_account" */
export type User_Account_Obj_Rel_Insert_Input = {
  data: User_Account_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Account_On_Conflict>;
};

/** on_conflict condition type for table "user_account" */
export type User_Account_On_Conflict = {
  constraint: User_Account_Constraint;
  update_columns?: Array<User_Account_Update_Column>;
  where?: InputMaybe<User_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "user_account". */
export type User_Account_Order_By = {
  admin_output_block?: InputMaybe<Order_By>;
  bot_insert_user_bonus_wallet_aggregate?: InputMaybe<User_Bonus_Wallet_Aggregate_Order_By>;
  bot_insert_user_inventory_aggregate?: InputMaybe<User_Inventory_Aggregate_Order_By>;
  bot_insert_user_wallet_aggregate?: InputMaybe<User_Wallet_Aggregate_Order_By>;
  bot_user?: InputMaybe<Order_By>;
  daily_reward_counts?: InputMaybe<User_Daily_Reward_Counts_Order_By>;
  is_banned?: InputMaybe<Order_By>;
  is_output_blocked?: InputMaybe<Order_By>;
  new_year_competition_participants_performances_aggregate?: InputMaybe<New_Year_Competition_Participants_Performance_Aggregate_Order_By>;
  user_bonus_wallet?: InputMaybe<User_Bonus_Wallet_Order_By>;
  user_bonus_wallet_id?: InputMaybe<Order_By>;
  user_boosters_in_inventories_aggregate?: InputMaybe<User_Boosters_In_Inventory_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_inventory?: InputMaybe<User_Inventory_Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
  user_multiacc_ban?: InputMaybe<Banned_Users_For_Multiaccount_Order_By>;
  user_referral_associations_aggregate?: InputMaybe<User_Referral_Association_Aggregate_Order_By>;
  user_trade_partner_id?: InputMaybe<Order_By>;
  user_trade_str_part?: InputMaybe<Order_By>;
  user_wallet?: InputMaybe<User_Wallet_Order_By>;
  user_wallet_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_account */
export type User_Account_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_account" */
export enum User_Account_Select_Column {
  /** column name */
  AdminOutputBlock = 'admin_output_block',
  /** column name */
  BotUser = 'bot_user',
  /** column name */
  IsBanned = 'is_banned',
  /** column name */
  IsOutputBlocked = 'is_output_blocked',
  /** column name */
  UserBonusWalletId = 'user_bonus_wallet_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserTradePartnerId = 'user_trade_partner_id',
  /** column name */
  UserTradeStrPart = 'user_trade_str_part',
  /** column name */
  UserWalletId = 'user_wallet_id'
}

/** input type for updating data in table "user_account" */
export type User_Account_Set_Input = {
  admin_output_block?: InputMaybe<Scalars['Boolean']>;
  bot_user?: InputMaybe<Scalars['Boolean']>;
  is_banned?: InputMaybe<Scalars['Boolean']>;
  is_output_blocked?: InputMaybe<Scalars['Boolean']>;
  user_bonus_wallet_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_trade_partner_id?: InputMaybe<Scalars['String']>;
  user_trade_str_part?: InputMaybe<Scalars['String']>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_account" */
export type User_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Account_Stream_Cursor_Value_Input = {
  admin_output_block?: InputMaybe<Scalars['Boolean']>;
  bot_user?: InputMaybe<Scalars['Boolean']>;
  is_banned?: InputMaybe<Scalars['Boolean']>;
  is_output_blocked?: InputMaybe<Scalars['Boolean']>;
  user_bonus_wallet_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_trade_partner_id?: InputMaybe<Scalars['String']>;
  user_trade_str_part?: InputMaybe<Scalars['String']>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_account" */
export enum User_Account_Update_Column {
  /** column name */
  AdminOutputBlock = 'admin_output_block',
  /** column name */
  BotUser = 'bot_user',
  /** column name */
  IsBanned = 'is_banned',
  /** column name */
  IsOutputBlocked = 'is_output_blocked',
  /** column name */
  UserBonusWalletId = 'user_bonus_wallet_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserTradePartnerId = 'user_trade_partner_id',
  /** column name */
  UserTradeStrPart = 'user_trade_str_part',
  /** column name */
  UserWalletId = 'user_wallet_id'
}

export type User_Account_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Account_Bool_Exp;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "user_auth" */
export type User_Auth = {
  __typename?: 'user_auth';
  banned_multiacc?: Maybe<Banned_Users_For_Multiaccount>;
  created_at?: Maybe<Scalars['timestamp']>;
  expired_token_time: Scalars['timestamp'];
  ip: Scalars['String'];
  ip_forwarded_header: Scalars['String'];
  real_ip_header: Scalars['String'];
  refresh_token: Scalars['uuid'];
  token_is_used: Scalars['Boolean'];
  user_agent: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_auth" */
export type User_Auth_Aggregate = {
  __typename?: 'user_auth_aggregate';
  aggregate?: Maybe<User_Auth_Aggregate_Fields>;
  nodes: Array<User_Auth>;
};

/** aggregate fields of "user_auth" */
export type User_Auth_Aggregate_Fields = {
  __typename?: 'user_auth_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Auth_Max_Fields>;
  min?: Maybe<User_Auth_Min_Fields>;
};


/** aggregate fields of "user_auth" */
export type User_Auth_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Auth_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_auth". All fields are combined with a logical 'AND'. */
export type User_Auth_Bool_Exp = {
  _and?: InputMaybe<Array<User_Auth_Bool_Exp>>;
  _not?: InputMaybe<User_Auth_Bool_Exp>;
  _or?: InputMaybe<Array<User_Auth_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  expired_token_time?: InputMaybe<Timestamp_Comparison_Exp>;
  ip?: InputMaybe<String_Comparison_Exp>;
  ip_forwarded_header?: InputMaybe<String_Comparison_Exp>;
  real_ip_header?: InputMaybe<String_Comparison_Exp>;
  refresh_token?: InputMaybe<Uuid_Comparison_Exp>;
  token_is_used?: InputMaybe<Boolean_Comparison_Exp>;
  user_agent?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_auth" */
export enum User_Auth_Constraint {
  /** unique or primary key constraint on columns "refresh_token" */
  UserAuthPkey = 'user_auth_pkey'
}

/** input type for inserting data into table "user_auth" */
export type User_Auth_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  expired_token_time?: InputMaybe<Scalars['timestamp']>;
  ip?: InputMaybe<Scalars['String']>;
  ip_forwarded_header?: InputMaybe<Scalars['String']>;
  real_ip_header?: InputMaybe<Scalars['String']>;
  refresh_token?: InputMaybe<Scalars['uuid']>;
  token_is_used?: InputMaybe<Scalars['Boolean']>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Auth_Max_Fields = {
  __typename?: 'user_auth_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  expired_token_time?: Maybe<Scalars['timestamp']>;
  ip?: Maybe<Scalars['String']>;
  ip_forwarded_header?: Maybe<Scalars['String']>;
  real_ip_header?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['uuid']>;
  user_agent?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Auth_Min_Fields = {
  __typename?: 'user_auth_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  expired_token_time?: Maybe<Scalars['timestamp']>;
  ip?: Maybe<Scalars['String']>;
  ip_forwarded_header?: Maybe<Scalars['String']>;
  real_ip_header?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['uuid']>;
  user_agent?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_auth" */
export type User_Auth_Mutation_Response = {
  __typename?: 'user_auth_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Auth>;
};

/** on_conflict condition type for table "user_auth" */
export type User_Auth_On_Conflict = {
  constraint: User_Auth_Constraint;
  update_columns?: Array<User_Auth_Update_Column>;
  where?: InputMaybe<User_Auth_Bool_Exp>;
};

/** Ordering options when selecting data from "user_auth". */
export type User_Auth_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expired_token_time?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  ip_forwarded_header?: InputMaybe<Order_By>;
  real_ip_header?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
  token_is_used?: InputMaybe<Order_By>;
  user_agent?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_auth */
export type User_Auth_Pk_Columns_Input = {
  refresh_token: Scalars['uuid'];
};

/** select columns of table "user_auth" */
export enum User_Auth_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiredTokenTime = 'expired_token_time',
  /** column name */
  Ip = 'ip',
  /** column name */
  IpForwardedHeader = 'ip_forwarded_header',
  /** column name */
  RealIpHeader = 'real_ip_header',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  TokenIsUsed = 'token_is_used',
  /** column name */
  UserAgent = 'user_agent',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_auth" */
export type User_Auth_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  expired_token_time?: InputMaybe<Scalars['timestamp']>;
  ip?: InputMaybe<Scalars['String']>;
  ip_forwarded_header?: InputMaybe<Scalars['String']>;
  real_ip_header?: InputMaybe<Scalars['String']>;
  refresh_token?: InputMaybe<Scalars['uuid']>;
  token_is_used?: InputMaybe<Scalars['Boolean']>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_auth" */
export type User_Auth_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Auth_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Auth_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  expired_token_time?: InputMaybe<Scalars['timestamp']>;
  ip?: InputMaybe<Scalars['String']>;
  ip_forwarded_header?: InputMaybe<Scalars['String']>;
  real_ip_header?: InputMaybe<Scalars['String']>;
  refresh_token?: InputMaybe<Scalars['uuid']>;
  token_is_used?: InputMaybe<Scalars['Boolean']>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_auth" */
export enum User_Auth_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiredTokenTime = 'expired_token_time',
  /** column name */
  Ip = 'ip',
  /** column name */
  IpForwardedHeader = 'ip_forwarded_header',
  /** column name */
  RealIpHeader = 'real_ip_header',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  TokenIsUsed = 'token_is_used',
  /** column name */
  UserAgent = 'user_agent',
  /** column name */
  UserId = 'user_id'
}

export type User_Auth_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Auth_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Auth_Bool_Exp;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  age?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_bonus_wallet" */
export type User_Bonus_Wallet = {
  __typename?: 'user_bonus_wallet';
  balance: Scalars['Float'];
  /** An object relationship */
  user_account?: Maybe<User_Account>;
  user_bonus_wallet_id: Scalars['uuid'];
  /** An array relationship */
  user_bonus_wallet_transactions: Array<User_Bonus_Wallet_Transactions>;
  /** An aggregate relationship */
  user_bonus_wallet_transactions_aggregate: User_Bonus_Wallet_Transactions_Aggregate;
  wallet_currency: Scalars['String'];
};


/** columns and relationships of "user_bonus_wallet" */
export type User_Bonus_WalletUser_Bonus_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Transactions_Bool_Exp>;
};


/** columns and relationships of "user_bonus_wallet" */
export type User_Bonus_WalletUser_Bonus_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Bonus_Wallet_Transactions_Bool_Exp>;
};

/** aggregated selection of "user_bonus_wallet" */
export type User_Bonus_Wallet_Aggregate = {
  __typename?: 'user_bonus_wallet_aggregate';
  aggregate?: Maybe<User_Bonus_Wallet_Aggregate_Fields>;
  nodes: Array<User_Bonus_Wallet>;
};

export type User_Bonus_Wallet_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Bonus_Wallet_Aggregate_Bool_Exp_Count>;
};

export type User_Bonus_Wallet_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Bonus_Wallet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_bonus_wallet" */
export type User_Bonus_Wallet_Aggregate_Fields = {
  __typename?: 'user_bonus_wallet_aggregate_fields';
  avg?: Maybe<User_Bonus_Wallet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Bonus_Wallet_Max_Fields>;
  min?: Maybe<User_Bonus_Wallet_Min_Fields>;
  stddev?: Maybe<User_Bonus_Wallet_Stddev_Fields>;
  stddev_pop?: Maybe<User_Bonus_Wallet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Bonus_Wallet_Stddev_Samp_Fields>;
  sum?: Maybe<User_Bonus_Wallet_Sum_Fields>;
  var_pop?: Maybe<User_Bonus_Wallet_Var_Pop_Fields>;
  var_samp?: Maybe<User_Bonus_Wallet_Var_Samp_Fields>;
  variance?: Maybe<User_Bonus_Wallet_Variance_Fields>;
};


/** aggregate fields of "user_bonus_wallet" */
export type User_Bonus_Wallet_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Bonus_Wallet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_bonus_wallet" */
export type User_Bonus_Wallet_Aggregate_Order_By = {
  avg?: InputMaybe<User_Bonus_Wallet_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Bonus_Wallet_Max_Order_By>;
  min?: InputMaybe<User_Bonus_Wallet_Min_Order_By>;
  stddev?: InputMaybe<User_Bonus_Wallet_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Bonus_Wallet_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Bonus_Wallet_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Bonus_Wallet_Sum_Order_By>;
  var_pop?: InputMaybe<User_Bonus_Wallet_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Bonus_Wallet_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Bonus_Wallet_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_bonus_wallet" */
export type User_Bonus_Wallet_Arr_Rel_Insert_Input = {
  data: Array<User_Bonus_Wallet_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Bonus_Wallet_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Bonus_Wallet_Avg_Fields = {
  __typename?: 'user_bonus_wallet_avg_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_bonus_wallet" */
export type User_Bonus_Wallet_Avg_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_bonus_wallet". All fields are combined with a logical 'AND'. */
export type User_Bonus_Wallet_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bonus_Wallet_Bool_Exp>>;
  _not?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bonus_Wallet_Bool_Exp>>;
  balance?: InputMaybe<Float_Comparison_Exp>;
  user_account?: InputMaybe<User_Account_Bool_Exp>;
  user_bonus_wallet_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_bonus_wallet_transactions?: InputMaybe<User_Bonus_Wallet_Transactions_Bool_Exp>;
  user_bonus_wallet_transactions_aggregate?: InputMaybe<User_Bonus_Wallet_Transactions_Aggregate_Bool_Exp>;
  wallet_currency?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_bonus_wallet" */
export enum User_Bonus_Wallet_Constraint {
  /** unique or primary key constraint on columns "user_bonus_wallet_id" */
  UserBonusWalletPkey = 'user_bonus_wallet_pkey'
}

/** input type for incrementing numeric columns in table "user_bonus_wallet" */
export type User_Bonus_Wallet_Inc_Input = {
  balance?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "user_bonus_wallet" */
export type User_Bonus_Wallet_Insert_Input = {
  balance?: InputMaybe<Scalars['Float']>;
  user_account?: InputMaybe<User_Account_Obj_Rel_Insert_Input>;
  user_bonus_wallet_id?: InputMaybe<Scalars['uuid']>;
  user_bonus_wallet_transactions?: InputMaybe<User_Bonus_Wallet_Transactions_Arr_Rel_Insert_Input>;
  wallet_currency?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Bonus_Wallet_Max_Fields = {
  __typename?: 'user_bonus_wallet_max_fields';
  balance?: Maybe<Scalars['Float']>;
  user_bonus_wallet_id?: Maybe<Scalars['uuid']>;
  wallet_currency?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_bonus_wallet" */
export type User_Bonus_Wallet_Max_Order_By = {
  balance?: InputMaybe<Order_By>;
  user_bonus_wallet_id?: InputMaybe<Order_By>;
  wallet_currency?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Bonus_Wallet_Min_Fields = {
  __typename?: 'user_bonus_wallet_min_fields';
  balance?: Maybe<Scalars['Float']>;
  user_bonus_wallet_id?: Maybe<Scalars['uuid']>;
  wallet_currency?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_bonus_wallet" */
export type User_Bonus_Wallet_Min_Order_By = {
  balance?: InputMaybe<Order_By>;
  user_bonus_wallet_id?: InputMaybe<Order_By>;
  wallet_currency?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_bonus_wallet" */
export type User_Bonus_Wallet_Mutation_Response = {
  __typename?: 'user_bonus_wallet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Bonus_Wallet>;
};

/** input type for inserting object relation for remote table "user_bonus_wallet" */
export type User_Bonus_Wallet_Obj_Rel_Insert_Input = {
  data: User_Bonus_Wallet_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Bonus_Wallet_On_Conflict>;
};

/** on_conflict condition type for table "user_bonus_wallet" */
export type User_Bonus_Wallet_On_Conflict = {
  constraint: User_Bonus_Wallet_Constraint;
  update_columns?: Array<User_Bonus_Wallet_Update_Column>;
  where?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
};

/** Ordering options when selecting data from "user_bonus_wallet". */
export type User_Bonus_Wallet_Order_By = {
  balance?: InputMaybe<Order_By>;
  user_account?: InputMaybe<User_Account_Order_By>;
  user_bonus_wallet_id?: InputMaybe<Order_By>;
  user_bonus_wallet_transactions_aggregate?: InputMaybe<User_Bonus_Wallet_Transactions_Aggregate_Order_By>;
  wallet_currency?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_bonus_wallet */
export type User_Bonus_Wallet_Pk_Columns_Input = {
  user_bonus_wallet_id: Scalars['uuid'];
};

/** select columns of table "user_bonus_wallet" */
export enum User_Bonus_Wallet_Select_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  UserBonusWalletId = 'user_bonus_wallet_id',
  /** column name */
  WalletCurrency = 'wallet_currency'
}

/** input type for updating data in table "user_bonus_wallet" */
export type User_Bonus_Wallet_Set_Input = {
  balance?: InputMaybe<Scalars['Float']>;
  user_bonus_wallet_id?: InputMaybe<Scalars['uuid']>;
  wallet_currency?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Bonus_Wallet_Stddev_Fields = {
  __typename?: 'user_bonus_wallet_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_bonus_wallet" */
export type User_Bonus_Wallet_Stddev_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Bonus_Wallet_Stddev_Pop_Fields = {
  __typename?: 'user_bonus_wallet_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_bonus_wallet" */
export type User_Bonus_Wallet_Stddev_Pop_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Bonus_Wallet_Stddev_Samp_Fields = {
  __typename?: 'user_bonus_wallet_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_bonus_wallet" */
export type User_Bonus_Wallet_Stddev_Samp_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_bonus_wallet" */
export type User_Bonus_Wallet_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Bonus_Wallet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Bonus_Wallet_Stream_Cursor_Value_Input = {
  balance?: InputMaybe<Scalars['Float']>;
  user_bonus_wallet_id?: InputMaybe<Scalars['uuid']>;
  wallet_currency?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Bonus_Wallet_Sum_Fields = {
  __typename?: 'user_bonus_wallet_sum_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "user_bonus_wallet" */
export type User_Bonus_Wallet_Sum_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** operation_type: 1 - поступление на бонусный баланс, 2 - списание с баланса */
export type User_Bonus_Wallet_Transactions = {
  __typename?: 'user_bonus_wallet_transactions';
  created_at: Scalars['timestamp'];
  operation_id: Scalars['uuid'];
  operation_status: Scalars['Int'];
  operation_type: Scalars['Int'];
  /** An object relationship */
  user_bonus_wallet: User_Bonus_Wallet;
  user_bonus_wallet_id: Scalars['uuid'];
  value: Scalars['Int'];
};

/** aggregated selection of "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Aggregate = {
  __typename?: 'user_bonus_wallet_transactions_aggregate';
  aggregate?: Maybe<User_Bonus_Wallet_Transactions_Aggregate_Fields>;
  nodes: Array<User_Bonus_Wallet_Transactions>;
};

export type User_Bonus_Wallet_Transactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Bonus_Wallet_Transactions_Aggregate_Bool_Exp_Count>;
};

export type User_Bonus_Wallet_Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bonus_Wallet_Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Aggregate_Fields = {
  __typename?: 'user_bonus_wallet_transactions_aggregate_fields';
  avg?: Maybe<User_Bonus_Wallet_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Bonus_Wallet_Transactions_Max_Fields>;
  min?: Maybe<User_Bonus_Wallet_Transactions_Min_Fields>;
  stddev?: Maybe<User_Bonus_Wallet_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<User_Bonus_Wallet_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Bonus_Wallet_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<User_Bonus_Wallet_Transactions_Sum_Fields>;
  var_pop?: Maybe<User_Bonus_Wallet_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<User_Bonus_Wallet_Transactions_Var_Samp_Fields>;
  variance?: Maybe<User_Bonus_Wallet_Transactions_Variance_Fields>;
};


/** aggregate fields of "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Aggregate_Order_By = {
  avg?: InputMaybe<User_Bonus_Wallet_Transactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Bonus_Wallet_Transactions_Max_Order_By>;
  min?: InputMaybe<User_Bonus_Wallet_Transactions_Min_Order_By>;
  stddev?: InputMaybe<User_Bonus_Wallet_Transactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Bonus_Wallet_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Bonus_Wallet_Transactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Bonus_Wallet_Transactions_Sum_Order_By>;
  var_pop?: InputMaybe<User_Bonus_Wallet_Transactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Bonus_Wallet_Transactions_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Bonus_Wallet_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Arr_Rel_Insert_Input = {
  data: Array<User_Bonus_Wallet_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Bonus_Wallet_Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Bonus_Wallet_Transactions_Avg_Fields = {
  __typename?: 'user_bonus_wallet_transactions_avg_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Avg_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_bonus_wallet_transactions". All fields are combined with a logical 'AND'. */
export type User_Bonus_Wallet_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Bool_Exp>>;
  _not?: InputMaybe<User_Bonus_Wallet_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bonus_Wallet_Transactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  operation_id?: InputMaybe<Uuid_Comparison_Exp>;
  operation_status?: InputMaybe<Int_Comparison_Exp>;
  operation_type?: InputMaybe<Int_Comparison_Exp>;
  user_bonus_wallet?: InputMaybe<User_Bonus_Wallet_Bool_Exp>;
  user_bonus_wallet_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_bonus_wallet_transactions" */
export enum User_Bonus_Wallet_Transactions_Constraint {
  /** unique or primary key constraint on columns "operation_id" */
  UserBonusWalletTransactionsPkey = 'user_bonus_wallet_transactions_pkey'
}

/** input type for incrementing numeric columns in table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Inc_Input = {
  operation_status?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  operation_status?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  user_bonus_wallet?: InputMaybe<User_Bonus_Wallet_Obj_Rel_Insert_Input>;
  user_bonus_wallet_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type User_Bonus_Wallet_Transactions_Max_Fields = {
  __typename?: 'user_bonus_wallet_transactions_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  operation_id?: Maybe<Scalars['uuid']>;
  operation_status?: Maybe<Scalars['Int']>;
  operation_type?: Maybe<Scalars['Int']>;
  user_bonus_wallet_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  user_bonus_wallet_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Bonus_Wallet_Transactions_Min_Fields = {
  __typename?: 'user_bonus_wallet_transactions_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  operation_id?: Maybe<Scalars['uuid']>;
  operation_status?: Maybe<Scalars['Int']>;
  operation_type?: Maybe<Scalars['Int']>;
  user_bonus_wallet_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  user_bonus_wallet_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Mutation_Response = {
  __typename?: 'user_bonus_wallet_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Bonus_Wallet_Transactions>;
};

/** on_conflict condition type for table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_On_Conflict = {
  constraint: User_Bonus_Wallet_Transactions_Constraint;
  update_columns?: Array<User_Bonus_Wallet_Transactions_Update_Column>;
  where?: InputMaybe<User_Bonus_Wallet_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_bonus_wallet_transactions". */
export type User_Bonus_Wallet_Transactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  user_bonus_wallet?: InputMaybe<User_Bonus_Wallet_Order_By>;
  user_bonus_wallet_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_bonus_wallet_transactions */
export type User_Bonus_Wallet_Transactions_Pk_Columns_Input = {
  operation_id: Scalars['uuid'];
};

/** select columns of table "user_bonus_wallet_transactions" */
export enum User_Bonus_Wallet_Transactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OperationStatus = 'operation_status',
  /** column name */
  OperationType = 'operation_type',
  /** column name */
  UserBonusWalletId = 'user_bonus_wallet_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  operation_status?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  user_bonus_wallet_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_Bonus_Wallet_Transactions_Stddev_Fields = {
  __typename?: 'user_bonus_wallet_transactions_stddev_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Stddev_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Bonus_Wallet_Transactions_Stddev_Pop_Fields = {
  __typename?: 'user_bonus_wallet_transactions_stddev_pop_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Stddev_Pop_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Bonus_Wallet_Transactions_Stddev_Samp_Fields = {
  __typename?: 'user_bonus_wallet_transactions_stddev_samp_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Stddev_Samp_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Bonus_Wallet_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Bonus_Wallet_Transactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  operation_status?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  user_bonus_wallet_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type User_Bonus_Wallet_Transactions_Sum_Fields = {
  __typename?: 'user_bonus_wallet_transactions_sum_fields';
  operation_status?: Maybe<Scalars['Int']>;
  operation_type?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Sum_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "user_bonus_wallet_transactions" */
export enum User_Bonus_Wallet_Transactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OperationStatus = 'operation_status',
  /** column name */
  OperationType = 'operation_type',
  /** column name */
  UserBonusWalletId = 'user_bonus_wallet_id',
  /** column name */
  Value = 'value'
}

export type User_Bonus_Wallet_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Bonus_Wallet_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Bonus_Wallet_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bonus_Wallet_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Bonus_Wallet_Transactions_Var_Pop_Fields = {
  __typename?: 'user_bonus_wallet_transactions_var_pop_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Var_Pop_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Bonus_Wallet_Transactions_Var_Samp_Fields = {
  __typename?: 'user_bonus_wallet_transactions_var_samp_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Var_Samp_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Bonus_Wallet_Transactions_Variance_Fields = {
  __typename?: 'user_bonus_wallet_transactions_variance_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_bonus_wallet_transactions" */
export type User_Bonus_Wallet_Transactions_Variance_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "user_bonus_wallet" */
export enum User_Bonus_Wallet_Update_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  UserBonusWalletId = 'user_bonus_wallet_id',
  /** column name */
  WalletCurrency = 'wallet_currency'
}

export type User_Bonus_Wallet_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Bonus_Wallet_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Bonus_Wallet_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bonus_Wallet_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Bonus_Wallet_Var_Pop_Fields = {
  __typename?: 'user_bonus_wallet_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_bonus_wallet" */
export type User_Bonus_Wallet_Var_Pop_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Bonus_Wallet_Var_Samp_Fields = {
  __typename?: 'user_bonus_wallet_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_bonus_wallet" */
export type User_Bonus_Wallet_Var_Samp_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Bonus_Wallet_Variance_Fields = {
  __typename?: 'user_bonus_wallet_variance_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_bonus_wallet" */
export type User_Bonus_Wallet_Variance_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  age?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  passport_info?: InputMaybe<Passport_Info_Bool_Exp>;
};

/** columns and relationships of "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory = {
  __typename?: 'user_boosters_in_inventory';
  available: Scalars['Boolean'];
  booster_id: Scalars['uuid'];
  booster_image_link: Scalars['String'];
  booster_value: Scalars['Int'];
  receiving_datetime: Scalars['timestamp'];
  /** An object relationship */
  user_account: User_Account;
  user_booster_id: Scalars['uuid'];
  user_inventory_id: Scalars['uuid'];
};

/** aggregated selection of "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Aggregate = {
  __typename?: 'user_boosters_in_inventory_aggregate';
  aggregate?: Maybe<User_Boosters_In_Inventory_Aggregate_Fields>;
  nodes: Array<User_Boosters_In_Inventory>;
};

export type User_Boosters_In_Inventory_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Boosters_In_Inventory_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Boosters_In_Inventory_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Boosters_In_Inventory_Aggregate_Bool_Exp_Count>;
};

export type User_Boosters_In_Inventory_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Boosters_In_Inventory_Select_Column_User_Boosters_In_Inventory_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Boosters_In_Inventory_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Boosters_In_Inventory_Select_Column_User_Boosters_In_Inventory_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Boosters_In_Inventory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Boosters_In_Inventory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Aggregate_Fields = {
  __typename?: 'user_boosters_in_inventory_aggregate_fields';
  avg?: Maybe<User_Boosters_In_Inventory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Boosters_In_Inventory_Max_Fields>;
  min?: Maybe<User_Boosters_In_Inventory_Min_Fields>;
  stddev?: Maybe<User_Boosters_In_Inventory_Stddev_Fields>;
  stddev_pop?: Maybe<User_Boosters_In_Inventory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Boosters_In_Inventory_Stddev_Samp_Fields>;
  sum?: Maybe<User_Boosters_In_Inventory_Sum_Fields>;
  var_pop?: Maybe<User_Boosters_In_Inventory_Var_Pop_Fields>;
  var_samp?: Maybe<User_Boosters_In_Inventory_Var_Samp_Fields>;
  variance?: Maybe<User_Boosters_In_Inventory_Variance_Fields>;
};


/** aggregate fields of "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Boosters_In_Inventory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Aggregate_Order_By = {
  avg?: InputMaybe<User_Boosters_In_Inventory_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Boosters_In_Inventory_Max_Order_By>;
  min?: InputMaybe<User_Boosters_In_Inventory_Min_Order_By>;
  stddev?: InputMaybe<User_Boosters_In_Inventory_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Boosters_In_Inventory_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Boosters_In_Inventory_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Boosters_In_Inventory_Sum_Order_By>;
  var_pop?: InputMaybe<User_Boosters_In_Inventory_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Boosters_In_Inventory_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Boosters_In_Inventory_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Arr_Rel_Insert_Input = {
  data: Array<User_Boosters_In_Inventory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Boosters_In_Inventory_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Boosters_In_Inventory_Avg_Fields = {
  __typename?: 'user_boosters_in_inventory_avg_fields';
  booster_value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Avg_Order_By = {
  booster_value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_boosters_in_inventory". All fields are combined with a logical 'AND'. */
export type User_Boosters_In_Inventory_Bool_Exp = {
  _and?: InputMaybe<Array<User_Boosters_In_Inventory_Bool_Exp>>;
  _not?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
  _or?: InputMaybe<Array<User_Boosters_In_Inventory_Bool_Exp>>;
  available?: InputMaybe<Boolean_Comparison_Exp>;
  booster_id?: InputMaybe<Uuid_Comparison_Exp>;
  booster_image_link?: InputMaybe<String_Comparison_Exp>;
  booster_value?: InputMaybe<Int_Comparison_Exp>;
  receiving_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  user_account?: InputMaybe<User_Account_Bool_Exp>;
  user_booster_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_inventory_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_boosters_in_inventory" */
export enum User_Boosters_In_Inventory_Constraint {
  /** unique or primary key constraint on columns "user_booster_id" */
  UserBoostersInInventoryPkey = 'user_boosters_in_inventory_pkey'
}

/** input type for incrementing numeric columns in table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Inc_Input = {
  booster_value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Insert_Input = {
  available?: InputMaybe<Scalars['Boolean']>;
  booster_id?: InputMaybe<Scalars['uuid']>;
  booster_image_link?: InputMaybe<Scalars['String']>;
  booster_value?: InputMaybe<Scalars['Int']>;
  receiving_datetime?: InputMaybe<Scalars['timestamp']>;
  user_account?: InputMaybe<User_Account_Obj_Rel_Insert_Input>;
  user_booster_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Boosters_In_Inventory_Max_Fields = {
  __typename?: 'user_boosters_in_inventory_max_fields';
  booster_id?: Maybe<Scalars['uuid']>;
  booster_image_link?: Maybe<Scalars['String']>;
  booster_value?: Maybe<Scalars['Int']>;
  receiving_datetime?: Maybe<Scalars['timestamp']>;
  user_booster_id?: Maybe<Scalars['uuid']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Max_Order_By = {
  booster_id?: InputMaybe<Order_By>;
  booster_image_link?: InputMaybe<Order_By>;
  booster_value?: InputMaybe<Order_By>;
  receiving_datetime?: InputMaybe<Order_By>;
  user_booster_id?: InputMaybe<Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Boosters_In_Inventory_Min_Fields = {
  __typename?: 'user_boosters_in_inventory_min_fields';
  booster_id?: Maybe<Scalars['uuid']>;
  booster_image_link?: Maybe<Scalars['String']>;
  booster_value?: Maybe<Scalars['Int']>;
  receiving_datetime?: Maybe<Scalars['timestamp']>;
  user_booster_id?: Maybe<Scalars['uuid']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Min_Order_By = {
  booster_id?: InputMaybe<Order_By>;
  booster_image_link?: InputMaybe<Order_By>;
  booster_value?: InputMaybe<Order_By>;
  receiving_datetime?: InputMaybe<Order_By>;
  user_booster_id?: InputMaybe<Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Mutation_Response = {
  __typename?: 'user_boosters_in_inventory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Boosters_In_Inventory>;
};

/** on_conflict condition type for table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_On_Conflict = {
  constraint: User_Boosters_In_Inventory_Constraint;
  update_columns?: Array<User_Boosters_In_Inventory_Update_Column>;
  where?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
};

/** Ordering options when selecting data from "user_boosters_in_inventory". */
export type User_Boosters_In_Inventory_Order_By = {
  available?: InputMaybe<Order_By>;
  booster_id?: InputMaybe<Order_By>;
  booster_image_link?: InputMaybe<Order_By>;
  booster_value?: InputMaybe<Order_By>;
  receiving_datetime?: InputMaybe<Order_By>;
  user_account?: InputMaybe<User_Account_Order_By>;
  user_booster_id?: InputMaybe<Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_boosters_in_inventory */
export type User_Boosters_In_Inventory_Pk_Columns_Input = {
  user_booster_id: Scalars['uuid'];
};

/** select columns of table "user_boosters_in_inventory" */
export enum User_Boosters_In_Inventory_Select_Column {
  /** column name */
  Available = 'available',
  /** column name */
  BoosterId = 'booster_id',
  /** column name */
  BoosterImageLink = 'booster_image_link',
  /** column name */
  BoosterValue = 'booster_value',
  /** column name */
  ReceivingDatetime = 'receiving_datetime',
  /** column name */
  UserBoosterId = 'user_booster_id',
  /** column name */
  UserInventoryId = 'user_inventory_id'
}

/** select "user_boosters_in_inventory_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_boosters_in_inventory" */
export enum User_Boosters_In_Inventory_Select_Column_User_Boosters_In_Inventory_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Available = 'available'
}

/** select "user_boosters_in_inventory_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_boosters_in_inventory" */
export enum User_Boosters_In_Inventory_Select_Column_User_Boosters_In_Inventory_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Available = 'available'
}

/** input type for updating data in table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Set_Input = {
  available?: InputMaybe<Scalars['Boolean']>;
  booster_id?: InputMaybe<Scalars['uuid']>;
  booster_image_link?: InputMaybe<Scalars['String']>;
  booster_value?: InputMaybe<Scalars['Int']>;
  receiving_datetime?: InputMaybe<Scalars['timestamp']>;
  user_booster_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Boosters_In_Inventory_Stddev_Fields = {
  __typename?: 'user_boosters_in_inventory_stddev_fields';
  booster_value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Stddev_Order_By = {
  booster_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Boosters_In_Inventory_Stddev_Pop_Fields = {
  __typename?: 'user_boosters_in_inventory_stddev_pop_fields';
  booster_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Stddev_Pop_Order_By = {
  booster_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Boosters_In_Inventory_Stddev_Samp_Fields = {
  __typename?: 'user_boosters_in_inventory_stddev_samp_fields';
  booster_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Stddev_Samp_Order_By = {
  booster_value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Boosters_In_Inventory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Boosters_In_Inventory_Stream_Cursor_Value_Input = {
  available?: InputMaybe<Scalars['Boolean']>;
  booster_id?: InputMaybe<Scalars['uuid']>;
  booster_image_link?: InputMaybe<Scalars['String']>;
  booster_value?: InputMaybe<Scalars['Int']>;
  receiving_datetime?: InputMaybe<Scalars['timestamp']>;
  user_booster_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Boosters_In_Inventory_Sum_Fields = {
  __typename?: 'user_boosters_in_inventory_sum_fields';
  booster_value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Sum_Order_By = {
  booster_value?: InputMaybe<Order_By>;
};

/** update columns of table "user_boosters_in_inventory" */
export enum User_Boosters_In_Inventory_Update_Column {
  /** column name */
  Available = 'available',
  /** column name */
  BoosterId = 'booster_id',
  /** column name */
  BoosterImageLink = 'booster_image_link',
  /** column name */
  BoosterValue = 'booster_value',
  /** column name */
  ReceivingDatetime = 'receiving_datetime',
  /** column name */
  UserBoosterId = 'user_booster_id',
  /** column name */
  UserInventoryId = 'user_inventory_id'
}

export type User_Boosters_In_Inventory_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Boosters_In_Inventory_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Boosters_In_Inventory_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Boosters_In_Inventory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Boosters_In_Inventory_Var_Pop_Fields = {
  __typename?: 'user_boosters_in_inventory_var_pop_fields';
  booster_value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Var_Pop_Order_By = {
  booster_value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Boosters_In_Inventory_Var_Samp_Fields = {
  __typename?: 'user_boosters_in_inventory_var_samp_fields';
  booster_value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Var_Samp_Order_By = {
  booster_value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Boosters_In_Inventory_Variance_Fields = {
  __typename?: 'user_boosters_in_inventory_variance_fields';
  booster_value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_boosters_in_inventory" */
export type User_Boosters_In_Inventory_Variance_Order_By = {
  booster_value?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** columns and relationships of "user_contacts" */
export type User_Contacts = {
  __typename?: 'user_contacts';
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  telegram_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  user_email_verification_data?: Maybe<User_Email_Verification_Data>;
  user_id: Scalars['uuid'];
  /** An object relationship */
  user_profile: User_Profile;
};

/** aggregated selection of "user_contacts" */
export type User_Contacts_Aggregate = {
  __typename?: 'user_contacts_aggregate';
  aggregate?: Maybe<User_Contacts_Aggregate_Fields>;
  nodes: Array<User_Contacts>;
};

/** aggregate fields of "user_contacts" */
export type User_Contacts_Aggregate_Fields = {
  __typename?: 'user_contacts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Contacts_Max_Fields>;
  min?: Maybe<User_Contacts_Min_Fields>;
};


/** aggregate fields of "user_contacts" */
export type User_Contacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Contacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_contacts". All fields are combined with a logical 'AND'. */
export type User_Contacts_Bool_Exp = {
  _and?: InputMaybe<Array<User_Contacts_Bool_Exp>>;
  _not?: InputMaybe<User_Contacts_Bool_Exp>;
  _or?: InputMaybe<Array<User_Contacts_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_verified?: InputMaybe<Boolean_Comparison_Exp>;
  telegram_id?: InputMaybe<String_Comparison_Exp>;
  user_email_verification_data?: InputMaybe<User_Email_Verification_Data_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_profile?: InputMaybe<User_Profile_Bool_Exp>;
};

/** unique or primary key constraints on table "user_contacts" */
export enum User_Contacts_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserContactsPkey = 'user_contacts_pkey'
}

/** input type for inserting data into table "user_contacts" */
export type User_Contacts_Insert_Input = {
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  telegram_id?: InputMaybe<Scalars['String']>;
  user_email_verification_data?: InputMaybe<User_Email_Verification_Data_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_profile?: InputMaybe<User_Profile_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Contacts_Max_Fields = {
  __typename?: 'user_contacts_max_fields';
  email?: Maybe<Scalars['String']>;
  telegram_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Contacts_Min_Fields = {
  __typename?: 'user_contacts_min_fields';
  email?: Maybe<Scalars['String']>;
  telegram_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_contacts" */
export type User_Contacts_Mutation_Response = {
  __typename?: 'user_contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Contacts>;
};

/** input type for inserting object relation for remote table "user_contacts" */
export type User_Contacts_Obj_Rel_Insert_Input = {
  data: User_Contacts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Contacts_On_Conflict>;
};

/** on_conflict condition type for table "user_contacts" */
export type User_Contacts_On_Conflict = {
  constraint: User_Contacts_Constraint;
  update_columns?: Array<User_Contacts_Update_Column>;
  where?: InputMaybe<User_Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "user_contacts". */
export type User_Contacts_Order_By = {
  email?: InputMaybe<Order_By>;
  email_verified?: InputMaybe<Order_By>;
  telegram_id?: InputMaybe<Order_By>;
  user_email_verification_data?: InputMaybe<User_Email_Verification_Data_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_profile?: InputMaybe<User_Profile_Order_By>;
};

/** primary key columns input for table: user_contacts */
export type User_Contacts_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_contacts" */
export enum User_Contacts_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  TelegramId = 'telegram_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_contacts" */
export type User_Contacts_Set_Input = {
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  telegram_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_contacts" */
export type User_Contacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Contacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Contacts_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  telegram_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_contacts" */
export enum User_Contacts_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  TelegramId = 'telegram_id',
  /** column name */
  UserId = 'user_id'
}

export type User_Contacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Contacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Contacts_Bool_Exp;
};

/** columns and relationships of "user_correct_answers_rewards" */
export type User_Correct_Answers_Rewards = {
  __typename?: 'user_correct_answers_rewards';
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_correct_answers_rewards" */
export type User_Correct_Answers_Rewards_Aggregate = {
  __typename?: 'user_correct_answers_rewards_aggregate';
  aggregate?: Maybe<User_Correct_Answers_Rewards_Aggregate_Fields>;
  nodes: Array<User_Correct_Answers_Rewards>;
};

/** aggregate fields of "user_correct_answers_rewards" */
export type User_Correct_Answers_Rewards_Aggregate_Fields = {
  __typename?: 'user_correct_answers_rewards_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Correct_Answers_Rewards_Max_Fields>;
  min?: Maybe<User_Correct_Answers_Rewards_Min_Fields>;
};


/** aggregate fields of "user_correct_answers_rewards" */
export type User_Correct_Answers_Rewards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Correct_Answers_Rewards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_correct_answers_rewards". All fields are combined with a logical 'AND'. */
export type User_Correct_Answers_Rewards_Bool_Exp = {
  _and?: InputMaybe<Array<User_Correct_Answers_Rewards_Bool_Exp>>;
  _not?: InputMaybe<User_Correct_Answers_Rewards_Bool_Exp>;
  _or?: InputMaybe<Array<User_Correct_Answers_Rewards_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_correct_answers_rewards" */
export enum User_Correct_Answers_Rewards_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCorrectAnswersRewardsPkey = 'user_correct_answers_rewards_pkey'
}

/** input type for inserting data into table "user_correct_answers_rewards" */
export type User_Correct_Answers_Rewards_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Correct_Answers_Rewards_Max_Fields = {
  __typename?: 'user_correct_answers_rewards_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Correct_Answers_Rewards_Min_Fields = {
  __typename?: 'user_correct_answers_rewards_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_correct_answers_rewards" */
export type User_Correct_Answers_Rewards_Mutation_Response = {
  __typename?: 'user_correct_answers_rewards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Correct_Answers_Rewards>;
};

/** on_conflict condition type for table "user_correct_answers_rewards" */
export type User_Correct_Answers_Rewards_On_Conflict = {
  constraint: User_Correct_Answers_Rewards_Constraint;
  update_columns?: Array<User_Correct_Answers_Rewards_Update_Column>;
  where?: InputMaybe<User_Correct_Answers_Rewards_Bool_Exp>;
};

/** Ordering options when selecting data from "user_correct_answers_rewards". */
export type User_Correct_Answers_Rewards_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_correct_answers_rewards */
export type User_Correct_Answers_Rewards_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_correct_answers_rewards" */
export enum User_Correct_Answers_Rewards_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_correct_answers_rewards" */
export type User_Correct_Answers_Rewards_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_correct_answers_rewards" */
export type User_Correct_Answers_Rewards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Correct_Answers_Rewards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Correct_Answers_Rewards_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_correct_answers_rewards" */
export enum User_Correct_Answers_Rewards_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type User_Correct_Answers_Rewards_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Correct_Answers_Rewards_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Correct_Answers_Rewards_Bool_Exp;
};

/** таблица хранит данные о получении монет за правильный выбор в игре */
export type User_Daily_Coins_Claims = {
  __typename?: 'user_daily_coins_claims';
  claim_id: Scalars['uuid'];
  /** Дата и время получения монет */
  claimed_at: Scalars['timestamp'];
  /** Идентификатор пользователя, который получил монеты */
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_daily_coins_claims" */
export type User_Daily_Coins_Claims_Aggregate = {
  __typename?: 'user_daily_coins_claims_aggregate';
  aggregate?: Maybe<User_Daily_Coins_Claims_Aggregate_Fields>;
  nodes: Array<User_Daily_Coins_Claims>;
};

/** aggregate fields of "user_daily_coins_claims" */
export type User_Daily_Coins_Claims_Aggregate_Fields = {
  __typename?: 'user_daily_coins_claims_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Daily_Coins_Claims_Max_Fields>;
  min?: Maybe<User_Daily_Coins_Claims_Min_Fields>;
};


/** aggregate fields of "user_daily_coins_claims" */
export type User_Daily_Coins_Claims_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Daily_Coins_Claims_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_daily_coins_claims". All fields are combined with a logical 'AND'. */
export type User_Daily_Coins_Claims_Bool_Exp = {
  _and?: InputMaybe<Array<User_Daily_Coins_Claims_Bool_Exp>>;
  _not?: InputMaybe<User_Daily_Coins_Claims_Bool_Exp>;
  _or?: InputMaybe<Array<User_Daily_Coins_Claims_Bool_Exp>>;
  claim_id?: InputMaybe<Uuid_Comparison_Exp>;
  claimed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_daily_coins_claims" */
export enum User_Daily_Coins_Claims_Constraint {
  /** unique or primary key constraint on columns "claim_id" */
  UserDailyCoinsClaimsPkey = 'user_daily_coins_claims_pkey'
}

/** input type for inserting data into table "user_daily_coins_claims" */
export type User_Daily_Coins_Claims_Insert_Input = {
  claim_id?: InputMaybe<Scalars['uuid']>;
  /** Дата и время получения монет */
  claimed_at?: InputMaybe<Scalars['timestamp']>;
  /** Идентификатор пользователя, который получил монеты */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Daily_Coins_Claims_Max_Fields = {
  __typename?: 'user_daily_coins_claims_max_fields';
  claim_id?: Maybe<Scalars['uuid']>;
  /** Дата и время получения монет */
  claimed_at?: Maybe<Scalars['timestamp']>;
  /** Идентификатор пользователя, который получил монеты */
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Daily_Coins_Claims_Min_Fields = {
  __typename?: 'user_daily_coins_claims_min_fields';
  claim_id?: Maybe<Scalars['uuid']>;
  /** Дата и время получения монет */
  claimed_at?: Maybe<Scalars['timestamp']>;
  /** Идентификатор пользователя, который получил монеты */
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_daily_coins_claims" */
export type User_Daily_Coins_Claims_Mutation_Response = {
  __typename?: 'user_daily_coins_claims_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Daily_Coins_Claims>;
};

/** on_conflict condition type for table "user_daily_coins_claims" */
export type User_Daily_Coins_Claims_On_Conflict = {
  constraint: User_Daily_Coins_Claims_Constraint;
  update_columns?: Array<User_Daily_Coins_Claims_Update_Column>;
  where?: InputMaybe<User_Daily_Coins_Claims_Bool_Exp>;
};

/** Ordering options when selecting data from "user_daily_coins_claims". */
export type User_Daily_Coins_Claims_Order_By = {
  claim_id?: InputMaybe<Order_By>;
  claimed_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_daily_coins_claims */
export type User_Daily_Coins_Claims_Pk_Columns_Input = {
  claim_id: Scalars['uuid'];
};

/** select columns of table "user_daily_coins_claims" */
export enum User_Daily_Coins_Claims_Select_Column {
  /** column name */
  ClaimId = 'claim_id',
  /** column name */
  ClaimedAt = 'claimed_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_daily_coins_claims" */
export type User_Daily_Coins_Claims_Set_Input = {
  claim_id?: InputMaybe<Scalars['uuid']>;
  /** Дата и время получения монет */
  claimed_at?: InputMaybe<Scalars['timestamp']>;
  /** Идентификатор пользователя, который получил монеты */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_daily_coins_claims" */
export type User_Daily_Coins_Claims_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Daily_Coins_Claims_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Daily_Coins_Claims_Stream_Cursor_Value_Input = {
  claim_id?: InputMaybe<Scalars['uuid']>;
  /** Дата и время получения монет */
  claimed_at?: InputMaybe<Scalars['timestamp']>;
  /** Идентификатор пользователя, который получил монеты */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_daily_coins_claims" */
export enum User_Daily_Coins_Claims_Update_Column {
  /** column name */
  ClaimId = 'claim_id',
  /** column name */
  ClaimedAt = 'claimed_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Daily_Coins_Claims_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Daily_Coins_Claims_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Daily_Coins_Claims_Bool_Exp;
};

/** Таблица хранит данные о количестве получении монет за правильный выбор в игре за день */
export type User_Daily_Reward_Counts = {
  __typename?: 'user_daily_reward_counts';
  /** Дата и время сброса счётчика полученных монет */
  last_reset_date: Scalars['timestamp'];
  /** Количество полученых монет */
  reward_count: Scalars['smallint'];
  /** Идентификатор пользователя, который получил монеты */
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_daily_reward_counts" */
export type User_Daily_Reward_Counts_Aggregate = {
  __typename?: 'user_daily_reward_counts_aggregate';
  aggregate?: Maybe<User_Daily_Reward_Counts_Aggregate_Fields>;
  nodes: Array<User_Daily_Reward_Counts>;
};

/** aggregate fields of "user_daily_reward_counts" */
export type User_Daily_Reward_Counts_Aggregate_Fields = {
  __typename?: 'user_daily_reward_counts_aggregate_fields';
  avg?: Maybe<User_Daily_Reward_Counts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Daily_Reward_Counts_Max_Fields>;
  min?: Maybe<User_Daily_Reward_Counts_Min_Fields>;
  stddev?: Maybe<User_Daily_Reward_Counts_Stddev_Fields>;
  stddev_pop?: Maybe<User_Daily_Reward_Counts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Daily_Reward_Counts_Stddev_Samp_Fields>;
  sum?: Maybe<User_Daily_Reward_Counts_Sum_Fields>;
  var_pop?: Maybe<User_Daily_Reward_Counts_Var_Pop_Fields>;
  var_samp?: Maybe<User_Daily_Reward_Counts_Var_Samp_Fields>;
  variance?: Maybe<User_Daily_Reward_Counts_Variance_Fields>;
};


/** aggregate fields of "user_daily_reward_counts" */
export type User_Daily_Reward_Counts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Daily_Reward_Counts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Daily_Reward_Counts_Avg_Fields = {
  __typename?: 'user_daily_reward_counts_avg_fields';
  /** Количество полученых монет */
  reward_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_daily_reward_counts". All fields are combined with a logical 'AND'. */
export type User_Daily_Reward_Counts_Bool_Exp = {
  _and?: InputMaybe<Array<User_Daily_Reward_Counts_Bool_Exp>>;
  _not?: InputMaybe<User_Daily_Reward_Counts_Bool_Exp>;
  _or?: InputMaybe<Array<User_Daily_Reward_Counts_Bool_Exp>>;
  last_reset_date?: InputMaybe<Timestamp_Comparison_Exp>;
  reward_count?: InputMaybe<Smallint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_daily_reward_counts" */
export enum User_Daily_Reward_Counts_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserDailyRewardCountsPkey = 'user_daily_reward_counts_pkey'
}

/** input type for incrementing numeric columns in table "user_daily_reward_counts" */
export type User_Daily_Reward_Counts_Inc_Input = {
  /** Количество полученых монет */
  reward_count?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "user_daily_reward_counts" */
export type User_Daily_Reward_Counts_Insert_Input = {
  /** Дата и время сброса счётчика полученных монет */
  last_reset_date?: InputMaybe<Scalars['timestamp']>;
  /** Количество полученых монет */
  reward_count?: InputMaybe<Scalars['smallint']>;
  /** Идентификатор пользователя, который получил монеты */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Daily_Reward_Counts_Max_Fields = {
  __typename?: 'user_daily_reward_counts_max_fields';
  /** Дата и время сброса счётчика полученных монет */
  last_reset_date?: Maybe<Scalars['timestamp']>;
  /** Количество полученых монет */
  reward_count?: Maybe<Scalars['smallint']>;
  /** Идентификатор пользователя, который получил монеты */
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Daily_Reward_Counts_Min_Fields = {
  __typename?: 'user_daily_reward_counts_min_fields';
  /** Дата и время сброса счётчика полученных монет */
  last_reset_date?: Maybe<Scalars['timestamp']>;
  /** Количество полученых монет */
  reward_count?: Maybe<Scalars['smallint']>;
  /** Идентификатор пользователя, который получил монеты */
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_daily_reward_counts" */
export type User_Daily_Reward_Counts_Mutation_Response = {
  __typename?: 'user_daily_reward_counts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Daily_Reward_Counts>;
};

/** input type for inserting object relation for remote table "user_daily_reward_counts" */
export type User_Daily_Reward_Counts_Obj_Rel_Insert_Input = {
  data: User_Daily_Reward_Counts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Daily_Reward_Counts_On_Conflict>;
};

/** on_conflict condition type for table "user_daily_reward_counts" */
export type User_Daily_Reward_Counts_On_Conflict = {
  constraint: User_Daily_Reward_Counts_Constraint;
  update_columns?: Array<User_Daily_Reward_Counts_Update_Column>;
  where?: InputMaybe<User_Daily_Reward_Counts_Bool_Exp>;
};

/** Ordering options when selecting data from "user_daily_reward_counts". */
export type User_Daily_Reward_Counts_Order_By = {
  last_reset_date?: InputMaybe<Order_By>;
  reward_count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_daily_reward_counts */
export type User_Daily_Reward_Counts_Pk_Columns_Input = {
  /** Идентификатор пользователя, который получил монеты */
  user_id: Scalars['uuid'];
};

/** select columns of table "user_daily_reward_counts" */
export enum User_Daily_Reward_Counts_Select_Column {
  /** column name */
  LastResetDate = 'last_reset_date',
  /** column name */
  RewardCount = 'reward_count',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_daily_reward_counts" */
export type User_Daily_Reward_Counts_Set_Input = {
  /** Дата и время сброса счётчика полученных монет */
  last_reset_date?: InputMaybe<Scalars['timestamp']>;
  /** Количество полученых монет */
  reward_count?: InputMaybe<Scalars['smallint']>;
  /** Идентификатор пользователя, который получил монеты */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Daily_Reward_Counts_Stddev_Fields = {
  __typename?: 'user_daily_reward_counts_stddev_fields';
  /** Количество полученых монет */
  reward_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Daily_Reward_Counts_Stddev_Pop_Fields = {
  __typename?: 'user_daily_reward_counts_stddev_pop_fields';
  /** Количество полученых монет */
  reward_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Daily_Reward_Counts_Stddev_Samp_Fields = {
  __typename?: 'user_daily_reward_counts_stddev_samp_fields';
  /** Количество полученых монет */
  reward_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_daily_reward_counts" */
export type User_Daily_Reward_Counts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Daily_Reward_Counts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Daily_Reward_Counts_Stream_Cursor_Value_Input = {
  /** Дата и время сброса счётчика полученных монет */
  last_reset_date?: InputMaybe<Scalars['timestamp']>;
  /** Количество полученых монет */
  reward_count?: InputMaybe<Scalars['smallint']>;
  /** Идентификатор пользователя, который получил монеты */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Daily_Reward_Counts_Sum_Fields = {
  __typename?: 'user_daily_reward_counts_sum_fields';
  /** Количество полученых монет */
  reward_count?: Maybe<Scalars['smallint']>;
};

/** update columns of table "user_daily_reward_counts" */
export enum User_Daily_Reward_Counts_Update_Column {
  /** column name */
  LastResetDate = 'last_reset_date',
  /** column name */
  RewardCount = 'reward_count',
  /** column name */
  UserId = 'user_id'
}

export type User_Daily_Reward_Counts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Daily_Reward_Counts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Daily_Reward_Counts_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Daily_Reward_Counts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Daily_Reward_Counts_Var_Pop_Fields = {
  __typename?: 'user_daily_reward_counts_var_pop_fields';
  /** Количество полученых монет */
  reward_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Daily_Reward_Counts_Var_Samp_Fields = {
  __typename?: 'user_daily_reward_counts_var_samp_fields';
  /** Количество полученых монет */
  reward_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Daily_Reward_Counts_Variance_Fields = {
  __typename?: 'user_daily_reward_counts_variance_fields';
  /** Количество полученых монет */
  reward_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_email_verification_data" */
export type User_Email_Verification_Data = {
  __typename?: 'user_email_verification_data';
  created_at: Scalars['timestamp'];
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user_contact: User_Contacts;
  user_id: Scalars['uuid'];
  verification_param: Scalars['String'];
};

/** aggregated selection of "user_email_verification_data" */
export type User_Email_Verification_Data_Aggregate = {
  __typename?: 'user_email_verification_data_aggregate';
  aggregate?: Maybe<User_Email_Verification_Data_Aggregate_Fields>;
  nodes: Array<User_Email_Verification_Data>;
};

/** aggregate fields of "user_email_verification_data" */
export type User_Email_Verification_Data_Aggregate_Fields = {
  __typename?: 'user_email_verification_data_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Email_Verification_Data_Max_Fields>;
  min?: Maybe<User_Email_Verification_Data_Min_Fields>;
};


/** aggregate fields of "user_email_verification_data" */
export type User_Email_Verification_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Email_Verification_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_email_verification_data". All fields are combined with a logical 'AND'. */
export type User_Email_Verification_Data_Bool_Exp = {
  _and?: InputMaybe<Array<User_Email_Verification_Data_Bool_Exp>>;
  _not?: InputMaybe<User_Email_Verification_Data_Bool_Exp>;
  _or?: InputMaybe<Array<User_Email_Verification_Data_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_contact?: InputMaybe<User_Contacts_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  verification_param?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_email_verification_data" */
export enum User_Email_Verification_Data_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserEmailVerificationDataPkey = 'user_email_verification_data_pkey',
  /** unique or primary key constraint on columns "verification_param" */
  UserEmailVerificationDataVerificationParamKey = 'user_email_verification_data_verification_param_key'
}

/** input type for inserting data into table "user_email_verification_data" */
export type User_Email_Verification_Data_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_contact?: InputMaybe<User_Contacts_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  verification_param?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Email_Verification_Data_Max_Fields = {
  __typename?: 'user_email_verification_data_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  verification_param?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Email_Verification_Data_Min_Fields = {
  __typename?: 'user_email_verification_data_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  verification_param?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_email_verification_data" */
export type User_Email_Verification_Data_Mutation_Response = {
  __typename?: 'user_email_verification_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Email_Verification_Data>;
};

/** input type for inserting object relation for remote table "user_email_verification_data" */
export type User_Email_Verification_Data_Obj_Rel_Insert_Input = {
  data: User_Email_Verification_Data_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Email_Verification_Data_On_Conflict>;
};

/** on_conflict condition type for table "user_email_verification_data" */
export type User_Email_Verification_Data_On_Conflict = {
  constraint: User_Email_Verification_Data_Constraint;
  update_columns?: Array<User_Email_Verification_Data_Update_Column>;
  where?: InputMaybe<User_Email_Verification_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "user_email_verification_data". */
export type User_Email_Verification_Data_Order_By = {
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_contact?: InputMaybe<User_Contacts_Order_By>;
  user_id?: InputMaybe<Order_By>;
  verification_param?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_email_verification_data */
export type User_Email_Verification_Data_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_email_verification_data" */
export enum User_Email_Verification_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VerificationParam = 'verification_param'
}

/** input type for updating data in table "user_email_verification_data" */
export type User_Email_Verification_Data_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  verification_param?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_email_verification_data" */
export type User_Email_Verification_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Email_Verification_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Email_Verification_Data_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  verification_param?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_email_verification_data" */
export enum User_Email_Verification_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VerificationParam = 'verification_param'
}

export type User_Email_Verification_Data_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Email_Verification_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Email_Verification_Data_Bool_Exp;
};

/** columns and relationships of "user_events" */
export type User_Events = {
  __typename?: 'user_events';
  created_at: Scalars['timestamp'];
  event_data?: Maybe<Scalars['jsonb']>;
  event_id: Scalars['uuid'];
  event_sub_type: Scalars['String'];
  event_type: Scalars['String'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "user_events" */
export type User_EventsEvent_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_events" */
export type User_Events_Aggregate = {
  __typename?: 'user_events_aggregate';
  aggregate?: Maybe<User_Events_Aggregate_Fields>;
  nodes: Array<User_Events>;
};

/** aggregate fields of "user_events" */
export type User_Events_Aggregate_Fields = {
  __typename?: 'user_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Events_Max_Fields>;
  min?: Maybe<User_Events_Min_Fields>;
};


/** aggregate fields of "user_events" */
export type User_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Events_Append_Input = {
  event_data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "user_events". All fields are combined with a logical 'AND'. */
export type User_Events_Bool_Exp = {
  _and?: InputMaybe<Array<User_Events_Bool_Exp>>;
  _not?: InputMaybe<User_Events_Bool_Exp>;
  _or?: InputMaybe<Array<User_Events_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  event_data?: InputMaybe<Jsonb_Comparison_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  event_sub_type?: InputMaybe<String_Comparison_Exp>;
  event_type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_events" */
export enum User_Events_Constraint {
  /** unique or primary key constraint on columns "event_id" */
  UserEventsPkey = 'user_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Events_Delete_At_Path_Input = {
  event_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Events_Delete_Elem_Input = {
  event_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Events_Delete_Key_Input = {
  event_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_events" */
export type User_Events_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  event_data?: InputMaybe<Scalars['jsonb']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  event_sub_type?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Events_Max_Fields = {
  __typename?: 'user_events_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  event_id?: Maybe<Scalars['uuid']>;
  event_sub_type?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Events_Min_Fields = {
  __typename?: 'user_events_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  event_id?: Maybe<Scalars['uuid']>;
  event_sub_type?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_events" */
export type User_Events_Mutation_Response = {
  __typename?: 'user_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Events>;
};

/** on_conflict condition type for table "user_events" */
export type User_Events_On_Conflict = {
  constraint: User_Events_Constraint;
  update_columns?: Array<User_Events_Update_Column>;
  where?: InputMaybe<User_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "user_events". */
export type User_Events_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_data?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  event_sub_type?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_events */
export type User_Events_Pk_Columns_Input = {
  event_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Events_Prepend_Input = {
  event_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_events" */
export enum User_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  EventId = 'event_id',
  /** column name */
  EventSubType = 'event_sub_type',
  /** column name */
  EventType = 'event_type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_events" */
export type User_Events_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  event_data?: InputMaybe<Scalars['jsonb']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  event_sub_type?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_events" */
export type User_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Events_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  event_data?: InputMaybe<Scalars['jsonb']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  event_sub_type?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_events" */
export enum User_Events_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  EventId = 'event_id',
  /** column name */
  EventSubType = 'event_sub_type',
  /** column name */
  EventType = 'event_type',
  /** column name */
  UserId = 'user_id'
}

export type User_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Events_Bool_Exp;
};

/** columns and relationships of "user_got_bonus" */
export type User_Got_Bonus = {
  __typename?: 'user_got_bonus';
  bonus_amount?: Maybe<Scalars['String']>;
  bonus_id: Scalars['uuid'];
  /** An object relationship */
  bonuse: Bonuses;
  last_datetime: Scalars['timestamp'];
  repeatable: Scalars['Boolean'];
  user_id: Scalars['uuid'];
  user_ip?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user_got_bonus" */
export type User_Got_Bonus_Aggregate = {
  __typename?: 'user_got_bonus_aggregate';
  aggregate?: Maybe<User_Got_Bonus_Aggregate_Fields>;
  nodes: Array<User_Got_Bonus>;
};

export type User_Got_Bonus_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Got_Bonus_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Got_Bonus_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Got_Bonus_Aggregate_Bool_Exp_Count>;
};

export type User_Got_Bonus_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Got_Bonus_Select_Column_User_Got_Bonus_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Got_Bonus_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Got_Bonus_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Got_Bonus_Select_Column_User_Got_Bonus_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Got_Bonus_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Got_Bonus_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Got_Bonus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Got_Bonus_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_got_bonus" */
export type User_Got_Bonus_Aggregate_Fields = {
  __typename?: 'user_got_bonus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Got_Bonus_Max_Fields>;
  min?: Maybe<User_Got_Bonus_Min_Fields>;
};


/** aggregate fields of "user_got_bonus" */
export type User_Got_Bonus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Got_Bonus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_got_bonus" */
export type User_Got_Bonus_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Got_Bonus_Max_Order_By>;
  min?: InputMaybe<User_Got_Bonus_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_got_bonus" */
export type User_Got_Bonus_Arr_Rel_Insert_Input = {
  data: Array<User_Got_Bonus_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Got_Bonus_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_got_bonus". All fields are combined with a logical 'AND'. */
export type User_Got_Bonus_Bool_Exp = {
  _and?: InputMaybe<Array<User_Got_Bonus_Bool_Exp>>;
  _not?: InputMaybe<User_Got_Bonus_Bool_Exp>;
  _or?: InputMaybe<Array<User_Got_Bonus_Bool_Exp>>;
  bonus_amount?: InputMaybe<String_Comparison_Exp>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  bonuse?: InputMaybe<Bonuses_Bool_Exp>;
  last_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  repeatable?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_ip?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_got_bonus" */
export enum User_Got_Bonus_Constraint {
  /** unique or primary key constraint on columns "last_datetime", "user_id", "bonus_id" */
  UserGotBonusPkey = 'user_got_bonus_pkey'
}

/** input type for inserting data into table "user_got_bonus" */
export type User_Got_Bonus_Insert_Input = {
  bonus_amount?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonuse?: InputMaybe<Bonuses_Obj_Rel_Insert_Input>;
  last_datetime?: InputMaybe<Scalars['timestamp']>;
  repeatable?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_ip?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Got_Bonus_Max_Fields = {
  __typename?: 'user_got_bonus_max_fields';
  bonus_amount?: Maybe<Scalars['String']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  last_datetime?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_ip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_got_bonus" */
export type User_Got_Bonus_Max_Order_By = {
  bonus_amount?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  last_datetime?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_ip?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Got_Bonus_Min_Fields = {
  __typename?: 'user_got_bonus_min_fields';
  bonus_amount?: Maybe<Scalars['String']>;
  bonus_id?: Maybe<Scalars['uuid']>;
  last_datetime?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_ip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_got_bonus" */
export type User_Got_Bonus_Min_Order_By = {
  bonus_amount?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  last_datetime?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_ip?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_got_bonus" */
export type User_Got_Bonus_Mutation_Response = {
  __typename?: 'user_got_bonus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Got_Bonus>;
};

/** on_conflict condition type for table "user_got_bonus" */
export type User_Got_Bonus_On_Conflict = {
  constraint: User_Got_Bonus_Constraint;
  update_columns?: Array<User_Got_Bonus_Update_Column>;
  where?: InputMaybe<User_Got_Bonus_Bool_Exp>;
};

/** Ordering options when selecting data from "user_got_bonus". */
export type User_Got_Bonus_Order_By = {
  bonus_amount?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  bonuse?: InputMaybe<Bonuses_Order_By>;
  last_datetime?: InputMaybe<Order_By>;
  repeatable?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_ip?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_got_bonus */
export type User_Got_Bonus_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  last_datetime: Scalars['timestamp'];
  user_id: Scalars['uuid'];
};

/** select columns of table "user_got_bonus" */
export enum User_Got_Bonus_Select_Column {
  /** column name */
  BonusAmount = 'bonus_amount',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  LastDatetime = 'last_datetime',
  /** column name */
  Repeatable = 'repeatable',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIp = 'user_ip'
}

/** select "user_got_bonus_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_got_bonus" */
export enum User_Got_Bonus_Select_Column_User_Got_Bonus_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Repeatable = 'repeatable'
}

/** select "user_got_bonus_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_got_bonus" */
export enum User_Got_Bonus_Select_Column_User_Got_Bonus_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Repeatable = 'repeatable'
}

/** input type for updating data in table "user_got_bonus" */
export type User_Got_Bonus_Set_Input = {
  bonus_amount?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  last_datetime?: InputMaybe<Scalars['timestamp']>;
  repeatable?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_ip?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_got_bonus" */
export type User_Got_Bonus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Got_Bonus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Got_Bonus_Stream_Cursor_Value_Input = {
  bonus_amount?: InputMaybe<Scalars['String']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  last_datetime?: InputMaybe<Scalars['timestamp']>;
  repeatable?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_ip?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_got_bonus" */
export enum User_Got_Bonus_Update_Column {
  /** column name */
  BonusAmount = 'bonus_amount',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  LastDatetime = 'last_datetime',
  /** column name */
  Repeatable = 'repeatable',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIp = 'user_ip'
}

export type User_Got_Bonus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Got_Bonus_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Got_Bonus_Bool_Exp;
};

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  age?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  age?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  passport_info?: InputMaybe<Passport_Info_Obj_Rel_Insert_Input>;
};

/** columns and relationships of "user_inventory" */
export type User_Inventory = {
  __typename?: 'user_inventory';
  /** A computed field, executes function "get_inventory_pages" */
  pages_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_boosters" */
  pages_for_boosters?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_sale" */
  pages_for_sale_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_upgrade" */
  pages_for_upgrade_count?: Maybe<Scalars['Int']>;
  /** An object relationship */
  user_account: User_Account;
  /** An array relationship */
  user_boosters_in_inventory: Array<User_Boosters_In_Inventory>;
  /** An aggregate relationship */
  user_boosters_in_inventory_aggregate: User_Boosters_In_Inventory_Aggregate;
  user_inventory_id: Scalars['uuid'];
  user_inventory_status: Scalars['Boolean'];
  /** An array relationship */
  user_items_in_inventories: Array<User_Items_In_Inventory>;
  /** An aggregate relationship */
  user_items_in_inventories_aggregate: User_Items_In_Inventory_Aggregate;
};


/** columns and relationships of "user_inventory" */
export type User_InventoryPages_CountArgs = {
  args?: InputMaybe<Pages_Count_User_Inventory_Args>;
};


/** columns and relationships of "user_inventory" */
export type User_InventoryPages_For_BoostersArgs = {
  args?: InputMaybe<Pages_For_Boosters_User_Inventory_Args>;
};


/** columns and relationships of "user_inventory" */
export type User_InventoryPages_For_Sale_CountArgs = {
  args?: InputMaybe<Pages_For_Sale_Count_User_Inventory_Args>;
};


/** columns and relationships of "user_inventory" */
export type User_InventoryPages_For_Upgrade_CountArgs = {
  args?: InputMaybe<Pages_For_Upgrade_Count_User_Inventory_Args>;
};


/** columns and relationships of "user_inventory" */
export type User_InventoryUser_Boosters_In_InventoryArgs = {
  distinct_on?: InputMaybe<Array<User_Boosters_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Boosters_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
};


/** columns and relationships of "user_inventory" */
export type User_InventoryUser_Boosters_In_Inventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Boosters_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Boosters_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
};


/** columns and relationships of "user_inventory" */
export type User_InventoryUser_Items_In_InventoriesArgs = {
  distinct_on?: InputMaybe<Array<User_Items_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Items_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
};


/** columns and relationships of "user_inventory" */
export type User_InventoryUser_Items_In_Inventories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Items_In_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Items_In_Inventory_Order_By>>;
  where?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
};

/** aggregated selection of "user_inventory" */
export type User_Inventory_Aggregate = {
  __typename?: 'user_inventory_aggregate';
  aggregate?: Maybe<User_Inventory_Aggregate_Fields>;
  nodes: Array<User_Inventory>;
};

export type User_Inventory_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Inventory_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Inventory_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Inventory_Aggregate_Bool_Exp_Count>;
};

export type User_Inventory_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Inventory_Select_Column_User_Inventory_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Inventory_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Inventory_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Inventory_Select_Column_User_Inventory_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Inventory_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Inventory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Inventory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Inventory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_inventory" */
export type User_Inventory_Aggregate_Fields = {
  __typename?: 'user_inventory_aggregate_fields';
  avg?: Maybe<User_Inventory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Inventory_Max_Fields>;
  min?: Maybe<User_Inventory_Min_Fields>;
  stddev?: Maybe<User_Inventory_Stddev_Fields>;
  stddev_pop?: Maybe<User_Inventory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Inventory_Stddev_Samp_Fields>;
  sum?: Maybe<User_Inventory_Sum_Fields>;
  var_pop?: Maybe<User_Inventory_Var_Pop_Fields>;
  var_samp?: Maybe<User_Inventory_Var_Samp_Fields>;
  variance?: Maybe<User_Inventory_Variance_Fields>;
};


/** aggregate fields of "user_inventory" */
export type User_Inventory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Inventory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_inventory" */
export type User_Inventory_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Inventory_Max_Order_By>;
  min?: InputMaybe<User_Inventory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_inventory" */
export type User_Inventory_Arr_Rel_Insert_Input = {
  data: Array<User_Inventory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Inventory_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Inventory_Avg_Fields = {
  __typename?: 'user_inventory_avg_fields';
  /** A computed field, executes function "get_inventory_pages" */
  pages_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_boosters" */
  pages_for_boosters?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_sale" */
  pages_for_sale_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_upgrade" */
  pages_for_upgrade_count?: Maybe<Scalars['Int']>;
};


/** aggregate avg on columns */
export type User_Inventory_Avg_FieldsPages_CountArgs = {
  args?: InputMaybe<Pages_Count_User_Inventory_Args>;
};


/** aggregate avg on columns */
export type User_Inventory_Avg_FieldsPages_For_BoostersArgs = {
  args?: InputMaybe<Pages_For_Boosters_User_Inventory_Args>;
};


/** aggregate avg on columns */
export type User_Inventory_Avg_FieldsPages_For_Sale_CountArgs = {
  args?: InputMaybe<Pages_For_Sale_Count_User_Inventory_Args>;
};


/** aggregate avg on columns */
export type User_Inventory_Avg_FieldsPages_For_Upgrade_CountArgs = {
  args?: InputMaybe<Pages_For_Upgrade_Count_User_Inventory_Args>;
};

/** Boolean expression to filter rows from the table "user_inventory". All fields are combined with a logical 'AND'. */
export type User_Inventory_Bool_Exp = {
  _and?: InputMaybe<Array<User_Inventory_Bool_Exp>>;
  _not?: InputMaybe<User_Inventory_Bool_Exp>;
  _or?: InputMaybe<Array<User_Inventory_Bool_Exp>>;
  user_account?: InputMaybe<User_Account_Bool_Exp>;
  user_boosters_in_inventory?: InputMaybe<User_Boosters_In_Inventory_Bool_Exp>;
  user_boosters_in_inventory_aggregate?: InputMaybe<User_Boosters_In_Inventory_Aggregate_Bool_Exp>;
  user_inventory_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_inventory_status?: InputMaybe<Boolean_Comparison_Exp>;
  user_items_in_inventories?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
  user_items_in_inventories_aggregate?: InputMaybe<User_Items_In_Inventory_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user_inventory" */
export enum User_Inventory_Constraint {
  /** unique or primary key constraint on columns "user_inventory_id" */
  UserInventoryPkey = 'user_inventory_pkey',
  /** unique or primary key constraint on columns "user_inventory_id" */
  UserInventoryUserInventoryIdKey = 'user_inventory_user_inventory_id_key'
}

/** input type for inserting data into table "user_inventory" */
export type User_Inventory_Insert_Input = {
  user_account?: InputMaybe<User_Account_Obj_Rel_Insert_Input>;
  user_boosters_in_inventory?: InputMaybe<User_Boosters_In_Inventory_Arr_Rel_Insert_Input>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_status?: InputMaybe<Scalars['Boolean']>;
  user_items_in_inventories?: InputMaybe<User_Items_In_Inventory_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Inventory_Max_Fields = {
  __typename?: 'user_inventory_max_fields';
  /** A computed field, executes function "get_inventory_pages" */
  pages_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_boosters" */
  pages_for_boosters?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_sale" */
  pages_for_sale_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_upgrade" */
  pages_for_upgrade_count?: Maybe<Scalars['Int']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
};


/** aggregate max on columns */
export type User_Inventory_Max_FieldsPages_CountArgs = {
  args?: InputMaybe<Pages_Count_User_Inventory_Args>;
};


/** aggregate max on columns */
export type User_Inventory_Max_FieldsPages_For_BoostersArgs = {
  args?: InputMaybe<Pages_For_Boosters_User_Inventory_Args>;
};


/** aggregate max on columns */
export type User_Inventory_Max_FieldsPages_For_Sale_CountArgs = {
  args?: InputMaybe<Pages_For_Sale_Count_User_Inventory_Args>;
};


/** aggregate max on columns */
export type User_Inventory_Max_FieldsPages_For_Upgrade_CountArgs = {
  args?: InputMaybe<Pages_For_Upgrade_Count_User_Inventory_Args>;
};

/** order by max() on columns of table "user_inventory" */
export type User_Inventory_Max_Order_By = {
  user_inventory_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Inventory_Min_Fields = {
  __typename?: 'user_inventory_min_fields';
  /** A computed field, executes function "get_inventory_pages" */
  pages_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_boosters" */
  pages_for_boosters?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_sale" */
  pages_for_sale_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_upgrade" */
  pages_for_upgrade_count?: Maybe<Scalars['Int']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
};


/** aggregate min on columns */
export type User_Inventory_Min_FieldsPages_CountArgs = {
  args?: InputMaybe<Pages_Count_User_Inventory_Args>;
};


/** aggregate min on columns */
export type User_Inventory_Min_FieldsPages_For_BoostersArgs = {
  args?: InputMaybe<Pages_For_Boosters_User_Inventory_Args>;
};


/** aggregate min on columns */
export type User_Inventory_Min_FieldsPages_For_Sale_CountArgs = {
  args?: InputMaybe<Pages_For_Sale_Count_User_Inventory_Args>;
};


/** aggregate min on columns */
export type User_Inventory_Min_FieldsPages_For_Upgrade_CountArgs = {
  args?: InputMaybe<Pages_For_Upgrade_Count_User_Inventory_Args>;
};

/** order by min() on columns of table "user_inventory" */
export type User_Inventory_Min_Order_By = {
  user_inventory_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_inventory" */
export type User_Inventory_Mutation_Response = {
  __typename?: 'user_inventory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Inventory>;
};

/** input type for inserting object relation for remote table "user_inventory" */
export type User_Inventory_Obj_Rel_Insert_Input = {
  data: User_Inventory_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Inventory_On_Conflict>;
};

/** on_conflict condition type for table "user_inventory" */
export type User_Inventory_On_Conflict = {
  constraint: User_Inventory_Constraint;
  update_columns?: Array<User_Inventory_Update_Column>;
  where?: InputMaybe<User_Inventory_Bool_Exp>;
};

/** Ordering options when selecting data from "user_inventory". */
export type User_Inventory_Order_By = {
  user_account?: InputMaybe<User_Account_Order_By>;
  user_boosters_in_inventory_aggregate?: InputMaybe<User_Boosters_In_Inventory_Aggregate_Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
  user_inventory_status?: InputMaybe<Order_By>;
  user_items_in_inventories_aggregate?: InputMaybe<User_Items_In_Inventory_Aggregate_Order_By>;
};

/** primary key columns input for table: user_inventory */
export type User_Inventory_Pk_Columns_Input = {
  user_inventory_id: Scalars['uuid'];
};

/** select columns of table "user_inventory" */
export enum User_Inventory_Select_Column {
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserInventoryStatus = 'user_inventory_status'
}

/** select "user_inventory_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_inventory" */
export enum User_Inventory_Select_Column_User_Inventory_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  UserInventoryStatus = 'user_inventory_status'
}

/** select "user_inventory_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_inventory" */
export enum User_Inventory_Select_Column_User_Inventory_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  UserInventoryStatus = 'user_inventory_status'
}

/** input type for updating data in table "user_inventory" */
export type User_Inventory_Set_Input = {
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_status?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type User_Inventory_Stddev_Fields = {
  __typename?: 'user_inventory_stddev_fields';
  /** A computed field, executes function "get_inventory_pages" */
  pages_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_boosters" */
  pages_for_boosters?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_sale" */
  pages_for_sale_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_upgrade" */
  pages_for_upgrade_count?: Maybe<Scalars['Int']>;
};


/** aggregate stddev on columns */
export type User_Inventory_Stddev_FieldsPages_CountArgs = {
  args?: InputMaybe<Pages_Count_User_Inventory_Args>;
};


/** aggregate stddev on columns */
export type User_Inventory_Stddev_FieldsPages_For_BoostersArgs = {
  args?: InputMaybe<Pages_For_Boosters_User_Inventory_Args>;
};


/** aggregate stddev on columns */
export type User_Inventory_Stddev_FieldsPages_For_Sale_CountArgs = {
  args?: InputMaybe<Pages_For_Sale_Count_User_Inventory_Args>;
};


/** aggregate stddev on columns */
export type User_Inventory_Stddev_FieldsPages_For_Upgrade_CountArgs = {
  args?: InputMaybe<Pages_For_Upgrade_Count_User_Inventory_Args>;
};

/** aggregate stddev_pop on columns */
export type User_Inventory_Stddev_Pop_Fields = {
  __typename?: 'user_inventory_stddev_pop_fields';
  /** A computed field, executes function "get_inventory_pages" */
  pages_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_boosters" */
  pages_for_boosters?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_sale" */
  pages_for_sale_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_upgrade" */
  pages_for_upgrade_count?: Maybe<Scalars['Int']>;
};


/** aggregate stddev_pop on columns */
export type User_Inventory_Stddev_Pop_FieldsPages_CountArgs = {
  args?: InputMaybe<Pages_Count_User_Inventory_Args>;
};


/** aggregate stddev_pop on columns */
export type User_Inventory_Stddev_Pop_FieldsPages_For_BoostersArgs = {
  args?: InputMaybe<Pages_For_Boosters_User_Inventory_Args>;
};


/** aggregate stddev_pop on columns */
export type User_Inventory_Stddev_Pop_FieldsPages_For_Sale_CountArgs = {
  args?: InputMaybe<Pages_For_Sale_Count_User_Inventory_Args>;
};


/** aggregate stddev_pop on columns */
export type User_Inventory_Stddev_Pop_FieldsPages_For_Upgrade_CountArgs = {
  args?: InputMaybe<Pages_For_Upgrade_Count_User_Inventory_Args>;
};

/** aggregate stddev_samp on columns */
export type User_Inventory_Stddev_Samp_Fields = {
  __typename?: 'user_inventory_stddev_samp_fields';
  /** A computed field, executes function "get_inventory_pages" */
  pages_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_boosters" */
  pages_for_boosters?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_sale" */
  pages_for_sale_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_upgrade" */
  pages_for_upgrade_count?: Maybe<Scalars['Int']>;
};


/** aggregate stddev_samp on columns */
export type User_Inventory_Stddev_Samp_FieldsPages_CountArgs = {
  args?: InputMaybe<Pages_Count_User_Inventory_Args>;
};


/** aggregate stddev_samp on columns */
export type User_Inventory_Stddev_Samp_FieldsPages_For_BoostersArgs = {
  args?: InputMaybe<Pages_For_Boosters_User_Inventory_Args>;
};


/** aggregate stddev_samp on columns */
export type User_Inventory_Stddev_Samp_FieldsPages_For_Sale_CountArgs = {
  args?: InputMaybe<Pages_For_Sale_Count_User_Inventory_Args>;
};


/** aggregate stddev_samp on columns */
export type User_Inventory_Stddev_Samp_FieldsPages_For_Upgrade_CountArgs = {
  args?: InputMaybe<Pages_For_Upgrade_Count_User_Inventory_Args>;
};

/** Streaming cursor of the table "user_inventory" */
export type User_Inventory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Inventory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Inventory_Stream_Cursor_Value_Input = {
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_status?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type User_Inventory_Sum_Fields = {
  __typename?: 'user_inventory_sum_fields';
  /** A computed field, executes function "get_inventory_pages" */
  pages_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_boosters" */
  pages_for_boosters?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_sale" */
  pages_for_sale_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_upgrade" */
  pages_for_upgrade_count?: Maybe<Scalars['Int']>;
};


/** aggregate sum on columns */
export type User_Inventory_Sum_FieldsPages_CountArgs = {
  args?: InputMaybe<Pages_Count_User_Inventory_Args>;
};


/** aggregate sum on columns */
export type User_Inventory_Sum_FieldsPages_For_BoostersArgs = {
  args?: InputMaybe<Pages_For_Boosters_User_Inventory_Args>;
};


/** aggregate sum on columns */
export type User_Inventory_Sum_FieldsPages_For_Sale_CountArgs = {
  args?: InputMaybe<Pages_For_Sale_Count_User_Inventory_Args>;
};


/** aggregate sum on columns */
export type User_Inventory_Sum_FieldsPages_For_Upgrade_CountArgs = {
  args?: InputMaybe<Pages_For_Upgrade_Count_User_Inventory_Args>;
};

/** operation_type: 1 - попал в инвентарь, 2 - продан, 3 - выведен */
export type User_Inventory_Transactions = {
  __typename?: 'user_inventory_transactions';
  item_id: Scalars['uuid'];
  operation_date_time: Scalars['timestamp'];
  operation_id: Scalars['uuid'];
  operation_status?: Maybe<Scalars['Boolean']>;
  operation_type: Scalars['Int'];
  output_custom_id?: Maybe<Scalars['uuid']>;
  real_paid?: Maybe<Scalars['Float']>;
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};

/** aggregated selection of "user_inventory_transactions" */
export type User_Inventory_Transactions_Aggregate = {
  __typename?: 'user_inventory_transactions_aggregate';
  aggregate?: Maybe<User_Inventory_Transactions_Aggregate_Fields>;
  nodes: Array<User_Inventory_Transactions>;
};

export type User_Inventory_Transactions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Inventory_Transactions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Inventory_Transactions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Inventory_Transactions_Aggregate_Bool_Exp_Count>;
};

export type User_Inventory_Transactions_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Inventory_Transactions_Select_Column_User_Inventory_Transactions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Inventory_Transactions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Inventory_Transactions_Select_Column_User_Inventory_Transactions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Inventory_Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Inventory_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_inventory_transactions" */
export type User_Inventory_Transactions_Aggregate_Fields = {
  __typename?: 'user_inventory_transactions_aggregate_fields';
  avg?: Maybe<User_Inventory_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Inventory_Transactions_Max_Fields>;
  min?: Maybe<User_Inventory_Transactions_Min_Fields>;
  stddev?: Maybe<User_Inventory_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<User_Inventory_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Inventory_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<User_Inventory_Transactions_Sum_Fields>;
  var_pop?: Maybe<User_Inventory_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<User_Inventory_Transactions_Var_Samp_Fields>;
  variance?: Maybe<User_Inventory_Transactions_Variance_Fields>;
};


/** aggregate fields of "user_inventory_transactions" */
export type User_Inventory_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Inventory_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_inventory_transactions" */
export type User_Inventory_Transactions_Aggregate_Order_By = {
  avg?: InputMaybe<User_Inventory_Transactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Inventory_Transactions_Max_Order_By>;
  min?: InputMaybe<User_Inventory_Transactions_Min_Order_By>;
  stddev?: InputMaybe<User_Inventory_Transactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Inventory_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Inventory_Transactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Inventory_Transactions_Sum_Order_By>;
  var_pop?: InputMaybe<User_Inventory_Transactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Inventory_Transactions_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Inventory_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_inventory_transactions" */
export type User_Inventory_Transactions_Arr_Rel_Insert_Input = {
  data: Array<User_Inventory_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Inventory_Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Inventory_Transactions_Avg_Fields = {
  __typename?: 'user_inventory_transactions_avg_fields';
  operation_type?: Maybe<Scalars['Float']>;
  real_paid?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_inventory_transactions" */
export type User_Inventory_Transactions_Avg_Order_By = {
  operation_type?: InputMaybe<Order_By>;
  real_paid?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_inventory_transactions". All fields are combined with a logical 'AND'. */
export type User_Inventory_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<User_Inventory_Transactions_Bool_Exp>>;
  _not?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<User_Inventory_Transactions_Bool_Exp>>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  operation_date_time?: InputMaybe<Timestamp_Comparison_Exp>;
  operation_id?: InputMaybe<Uuid_Comparison_Exp>;
  operation_status?: InputMaybe<Boolean_Comparison_Exp>;
  operation_type?: InputMaybe<Int_Comparison_Exp>;
  output_custom_id?: InputMaybe<Uuid_Comparison_Exp>;
  real_paid?: InputMaybe<Float_Comparison_Exp>;
  user_inventory_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_item_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_inventory_transactions" */
export enum User_Inventory_Transactions_Constraint {
  /** unique or primary key constraint on columns "operation_id" */
  UserInventoryTransactionsOperationIdKey = 'user_inventory_transactions_operation_id_key',
  /** unique or primary key constraint on columns "user_item_id", "operation_id", "user_inventory_id" */
  UserInventoryTransactionsPkey = 'user_inventory_transactions_pkey'
}

/** input type for incrementing numeric columns in table "user_inventory_transactions" */
export type User_Inventory_Transactions_Inc_Input = {
  operation_type?: InputMaybe<Scalars['Int']>;
  real_paid?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "user_inventory_transactions" */
export type User_Inventory_Transactions_Insert_Input = {
  item_id?: InputMaybe<Scalars['uuid']>;
  operation_date_time?: InputMaybe<Scalars['timestamp']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  operation_status?: InputMaybe<Scalars['Boolean']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  output_custom_id?: InputMaybe<Scalars['uuid']>;
  real_paid?: InputMaybe<Scalars['Float']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Inventory_Transactions_Max_Fields = {
  __typename?: 'user_inventory_transactions_max_fields';
  item_id?: Maybe<Scalars['uuid']>;
  operation_date_time?: Maybe<Scalars['timestamp']>;
  operation_id?: Maybe<Scalars['uuid']>;
  operation_type?: Maybe<Scalars['Int']>;
  output_custom_id?: Maybe<Scalars['uuid']>;
  real_paid?: Maybe<Scalars['Float']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_inventory_transactions" */
export type User_Inventory_Transactions_Max_Order_By = {
  item_id?: InputMaybe<Order_By>;
  operation_date_time?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  output_custom_id?: InputMaybe<Order_By>;
  real_paid?: InputMaybe<Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
  user_item_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Inventory_Transactions_Min_Fields = {
  __typename?: 'user_inventory_transactions_min_fields';
  item_id?: Maybe<Scalars['uuid']>;
  operation_date_time?: Maybe<Scalars['timestamp']>;
  operation_id?: Maybe<Scalars['uuid']>;
  operation_type?: Maybe<Scalars['Int']>;
  output_custom_id?: Maybe<Scalars['uuid']>;
  real_paid?: Maybe<Scalars['Float']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_inventory_transactions" */
export type User_Inventory_Transactions_Min_Order_By = {
  item_id?: InputMaybe<Order_By>;
  operation_date_time?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  output_custom_id?: InputMaybe<Order_By>;
  real_paid?: InputMaybe<Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
  user_item_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_inventory_transactions" */
export type User_Inventory_Transactions_Mutation_Response = {
  __typename?: 'user_inventory_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Inventory_Transactions>;
};

/** on_conflict condition type for table "user_inventory_transactions" */
export type User_Inventory_Transactions_On_Conflict = {
  constraint: User_Inventory_Transactions_Constraint;
  update_columns?: Array<User_Inventory_Transactions_Update_Column>;
  where?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_inventory_transactions". */
export type User_Inventory_Transactions_Order_By = {
  item_id?: InputMaybe<Order_By>;
  operation_date_time?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  output_custom_id?: InputMaybe<Order_By>;
  real_paid?: InputMaybe<Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
  user_item_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_inventory_transactions */
export type User_Inventory_Transactions_Pk_Columns_Input = {
  operation_id: Scalars['uuid'];
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};

/** select columns of table "user_inventory_transactions" */
export enum User_Inventory_Transactions_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  OperationDateTime = 'operation_date_time',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OperationStatus = 'operation_status',
  /** column name */
  OperationType = 'operation_type',
  /** column name */
  OutputCustomId = 'output_custom_id',
  /** column name */
  RealPaid = 'real_paid',
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserItemId = 'user_item_id'
}

/** select "user_inventory_transactions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_inventory_transactions" */
export enum User_Inventory_Transactions_Select_Column_User_Inventory_Transactions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  OperationStatus = 'operation_status'
}

/** select "user_inventory_transactions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_inventory_transactions" */
export enum User_Inventory_Transactions_Select_Column_User_Inventory_Transactions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  OperationStatus = 'operation_status'
}

/** input type for updating data in table "user_inventory_transactions" */
export type User_Inventory_Transactions_Set_Input = {
  item_id?: InputMaybe<Scalars['uuid']>;
  operation_date_time?: InputMaybe<Scalars['timestamp']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  operation_status?: InputMaybe<Scalars['Boolean']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  output_custom_id?: InputMaybe<Scalars['uuid']>;
  real_paid?: InputMaybe<Scalars['Float']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Inventory_Transactions_Stddev_Fields = {
  __typename?: 'user_inventory_transactions_stddev_fields';
  operation_type?: Maybe<Scalars['Float']>;
  real_paid?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_inventory_transactions" */
export type User_Inventory_Transactions_Stddev_Order_By = {
  operation_type?: InputMaybe<Order_By>;
  real_paid?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Inventory_Transactions_Stddev_Pop_Fields = {
  __typename?: 'user_inventory_transactions_stddev_pop_fields';
  operation_type?: Maybe<Scalars['Float']>;
  real_paid?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_inventory_transactions" */
export type User_Inventory_Transactions_Stddev_Pop_Order_By = {
  operation_type?: InputMaybe<Order_By>;
  real_paid?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Inventory_Transactions_Stddev_Samp_Fields = {
  __typename?: 'user_inventory_transactions_stddev_samp_fields';
  operation_type?: Maybe<Scalars['Float']>;
  real_paid?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_inventory_transactions" */
export type User_Inventory_Transactions_Stddev_Samp_Order_By = {
  operation_type?: InputMaybe<Order_By>;
  real_paid?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_inventory_transactions" */
export type User_Inventory_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Inventory_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Inventory_Transactions_Stream_Cursor_Value_Input = {
  item_id?: InputMaybe<Scalars['uuid']>;
  operation_date_time?: InputMaybe<Scalars['timestamp']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  operation_status?: InputMaybe<Scalars['Boolean']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  output_custom_id?: InputMaybe<Scalars['uuid']>;
  real_paid?: InputMaybe<Scalars['Float']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Inventory_Transactions_Sum_Fields = {
  __typename?: 'user_inventory_transactions_sum_fields';
  operation_type?: Maybe<Scalars['Int']>;
  real_paid?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "user_inventory_transactions" */
export type User_Inventory_Transactions_Sum_Order_By = {
  operation_type?: InputMaybe<Order_By>;
  real_paid?: InputMaybe<Order_By>;
};

/** update columns of table "user_inventory_transactions" */
export enum User_Inventory_Transactions_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  OperationDateTime = 'operation_date_time',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OperationStatus = 'operation_status',
  /** column name */
  OperationType = 'operation_type',
  /** column name */
  OutputCustomId = 'output_custom_id',
  /** column name */
  RealPaid = 'real_paid',
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserItemId = 'user_item_id'
}

export type User_Inventory_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Inventory_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Inventory_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Inventory_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Inventory_Transactions_Var_Pop_Fields = {
  __typename?: 'user_inventory_transactions_var_pop_fields';
  operation_type?: Maybe<Scalars['Float']>;
  real_paid?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_inventory_transactions" */
export type User_Inventory_Transactions_Var_Pop_Order_By = {
  operation_type?: InputMaybe<Order_By>;
  real_paid?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Inventory_Transactions_Var_Samp_Fields = {
  __typename?: 'user_inventory_transactions_var_samp_fields';
  operation_type?: Maybe<Scalars['Float']>;
  real_paid?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_inventory_transactions" */
export type User_Inventory_Transactions_Var_Samp_Order_By = {
  operation_type?: InputMaybe<Order_By>;
  real_paid?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Inventory_Transactions_Variance_Fields = {
  __typename?: 'user_inventory_transactions_variance_fields';
  operation_type?: Maybe<Scalars['Float']>;
  real_paid?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_inventory_transactions" */
export type User_Inventory_Transactions_Variance_Order_By = {
  operation_type?: InputMaybe<Order_By>;
  real_paid?: InputMaybe<Order_By>;
};

/** update columns of table "user_inventory" */
export enum User_Inventory_Update_Column {
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserInventoryStatus = 'user_inventory_status'
}

export type User_Inventory_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Inventory_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Inventory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Inventory_Var_Pop_Fields = {
  __typename?: 'user_inventory_var_pop_fields';
  /** A computed field, executes function "get_inventory_pages" */
  pages_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_boosters" */
  pages_for_boosters?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_sale" */
  pages_for_sale_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_upgrade" */
  pages_for_upgrade_count?: Maybe<Scalars['Int']>;
};


/** aggregate var_pop on columns */
export type User_Inventory_Var_Pop_FieldsPages_CountArgs = {
  args?: InputMaybe<Pages_Count_User_Inventory_Args>;
};


/** aggregate var_pop on columns */
export type User_Inventory_Var_Pop_FieldsPages_For_BoostersArgs = {
  args?: InputMaybe<Pages_For_Boosters_User_Inventory_Args>;
};


/** aggregate var_pop on columns */
export type User_Inventory_Var_Pop_FieldsPages_For_Sale_CountArgs = {
  args?: InputMaybe<Pages_For_Sale_Count_User_Inventory_Args>;
};


/** aggregate var_pop on columns */
export type User_Inventory_Var_Pop_FieldsPages_For_Upgrade_CountArgs = {
  args?: InputMaybe<Pages_For_Upgrade_Count_User_Inventory_Args>;
};

/** aggregate var_samp on columns */
export type User_Inventory_Var_Samp_Fields = {
  __typename?: 'user_inventory_var_samp_fields';
  /** A computed field, executes function "get_inventory_pages" */
  pages_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_boosters" */
  pages_for_boosters?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_sale" */
  pages_for_sale_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_upgrade" */
  pages_for_upgrade_count?: Maybe<Scalars['Int']>;
};


/** aggregate var_samp on columns */
export type User_Inventory_Var_Samp_FieldsPages_CountArgs = {
  args?: InputMaybe<Pages_Count_User_Inventory_Args>;
};


/** aggregate var_samp on columns */
export type User_Inventory_Var_Samp_FieldsPages_For_BoostersArgs = {
  args?: InputMaybe<Pages_For_Boosters_User_Inventory_Args>;
};


/** aggregate var_samp on columns */
export type User_Inventory_Var_Samp_FieldsPages_For_Sale_CountArgs = {
  args?: InputMaybe<Pages_For_Sale_Count_User_Inventory_Args>;
};


/** aggregate var_samp on columns */
export type User_Inventory_Var_Samp_FieldsPages_For_Upgrade_CountArgs = {
  args?: InputMaybe<Pages_For_Upgrade_Count_User_Inventory_Args>;
};

/** aggregate variance on columns */
export type User_Inventory_Variance_Fields = {
  __typename?: 'user_inventory_variance_fields';
  /** A computed field, executes function "get_inventory_pages" */
  pages_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_boosters" */
  pages_for_boosters?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_sale" */
  pages_for_sale_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_inventory_pages_for_upgrade" */
  pages_for_upgrade_count?: Maybe<Scalars['Int']>;
};


/** aggregate variance on columns */
export type User_Inventory_Variance_FieldsPages_CountArgs = {
  args?: InputMaybe<Pages_Count_User_Inventory_Args>;
};


/** aggregate variance on columns */
export type User_Inventory_Variance_FieldsPages_For_BoostersArgs = {
  args?: InputMaybe<Pages_For_Boosters_User_Inventory_Args>;
};


/** aggregate variance on columns */
export type User_Inventory_Variance_FieldsPages_For_Sale_CountArgs = {
  args?: InputMaybe<Pages_For_Sale_Count_User_Inventory_Args>;
};


/** aggregate variance on columns */
export type User_Inventory_Variance_FieldsPages_For_Upgrade_CountArgs = {
  args?: InputMaybe<Pages_For_Upgrade_Count_User_Inventory_Args>;
};

/** item_status: 1 - предмет доступен для действий (продажа/вывод/апгрейд), 2 - продан, 3 - выведен, 4 - использован в апгрейде */
export type User_Items_In_Inventory = {
  __typename?: 'user_items_in_inventory';
  from_upgrade: Scalars['Boolean'];
  item_frozen: Scalars['Boolean'];
  item_hash_name: Scalars['String'];
  item_id: Scalars['uuid'];
  item_image_link: Scalars['String'];
  /** A computed field, executes function "get_formated_item_image_link" */
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_lootbox_id?: Maybe<Scalars['uuid']>;
  item_lootbox_price: Scalars['Float'];
  item_quality: Scalars['String'];
  item_rariry: Scalars['String'];
  item_start_price: Scalars['Float'];
  item_status: Scalars['Int'];
  /** An array relationship */
  item_transactions: Array<User_Inventory_Transactions>;
  /** An aggregate relationship */
  item_transactions_aggregate: User_Inventory_Transactions_Aggregate;
  /** An object relationship */
  localized_name?: Maybe<Account_Items_Names>;
  lootbox?: Maybe<Lootboxes>;
  lootboxes_bonus?: Maybe<Lootboxes_Bonus>;
  lootboxes_free?: Maybe<Lootboxes_Free>;
  receiving_datetime?: Maybe<Scalars['timestamp']>;
  ru_name?: Maybe<Scalars['String']>;
  upgrade_block: Scalars['Boolean'];
  upgrade_serial_data?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  user_account?: Maybe<User_Account>;
  user_bonus_item_to_item?: Maybe<Lootboxes_Bonus_Items>;
  user_coin_item_to_item?: Maybe<Lootboxes_Free_Items>;
  /** An object relationship */
  user_inventory: User_Inventory;
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
  user_item_to_item?: Maybe<Items_In_Lootbox>;
  user_item_to_market_item?: Maybe<Items_Csgomarket>;
};


/** item_status: 1 - предмет доступен для действий (продажа/вывод/апгрейд), 2 - продан, 3 - выведен, 4 - использован в апгрейде */
export type User_Items_In_InventoryItem_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Transactions_Order_By>>;
  where?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
};


/** item_status: 1 - предмет доступен для действий (продажа/вывод/апгрейд), 2 - продан, 3 - выведен, 4 - использован в апгрейде */
export type User_Items_In_InventoryItem_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Inventory_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Inventory_Transactions_Order_By>>;
  where?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
};


/** item_status: 1 - предмет доступен для действий (продажа/вывод/апгрейд), 2 - продан, 3 - выведен, 4 - использован в апгрейде */
export type User_Items_In_InventoryUpgrade_Serial_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_items_in_inventory" */
export type User_Items_In_Inventory_Aggregate = {
  __typename?: 'user_items_in_inventory_aggregate';
  aggregate?: Maybe<User_Items_In_Inventory_Aggregate_Fields>;
  nodes: Array<User_Items_In_Inventory>;
};

export type User_Items_In_Inventory_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Items_In_Inventory_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Items_In_Inventory_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Items_In_Inventory_Aggregate_Bool_Exp_Count>;
};

export type User_Items_In_Inventory_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Items_In_Inventory_Select_Column_User_Items_In_Inventory_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Items_In_Inventory_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Items_In_Inventory_Select_Column_User_Items_In_Inventory_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Items_In_Inventory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Items_In_Inventory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_items_in_inventory" */
export type User_Items_In_Inventory_Aggregate_Fields = {
  __typename?: 'user_items_in_inventory_aggregate_fields';
  avg?: Maybe<User_Items_In_Inventory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Items_In_Inventory_Max_Fields>;
  min?: Maybe<User_Items_In_Inventory_Min_Fields>;
  stddev?: Maybe<User_Items_In_Inventory_Stddev_Fields>;
  stddev_pop?: Maybe<User_Items_In_Inventory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Items_In_Inventory_Stddev_Samp_Fields>;
  sum?: Maybe<User_Items_In_Inventory_Sum_Fields>;
  var_pop?: Maybe<User_Items_In_Inventory_Var_Pop_Fields>;
  var_samp?: Maybe<User_Items_In_Inventory_Var_Samp_Fields>;
  variance?: Maybe<User_Items_In_Inventory_Variance_Fields>;
};


/** aggregate fields of "user_items_in_inventory" */
export type User_Items_In_Inventory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Items_In_Inventory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_items_in_inventory" */
export type User_Items_In_Inventory_Aggregate_Order_By = {
  avg?: InputMaybe<User_Items_In_Inventory_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Items_In_Inventory_Max_Order_By>;
  min?: InputMaybe<User_Items_In_Inventory_Min_Order_By>;
  stddev?: InputMaybe<User_Items_In_Inventory_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Items_In_Inventory_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Items_In_Inventory_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Items_In_Inventory_Sum_Order_By>;
  var_pop?: InputMaybe<User_Items_In_Inventory_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Items_In_Inventory_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Items_In_Inventory_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Items_In_Inventory_Append_Input = {
  upgrade_serial_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_items_in_inventory" */
export type User_Items_In_Inventory_Arr_Rel_Insert_Input = {
  data: Array<User_Items_In_Inventory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Items_In_Inventory_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Items_In_Inventory_Avg_Fields = {
  __typename?: 'user_items_in_inventory_avg_fields';
  item_lootbox_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_items_in_inventory" */
export type User_Items_In_Inventory_Avg_Order_By = {
  item_lootbox_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_items_in_inventory". All fields are combined with a logical 'AND'. */
export type User_Items_In_Inventory_Bool_Exp = {
  _and?: InputMaybe<Array<User_Items_In_Inventory_Bool_Exp>>;
  _not?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
  _or?: InputMaybe<Array<User_Items_In_Inventory_Bool_Exp>>;
  from_upgrade?: InputMaybe<Boolean_Comparison_Exp>;
  item_frozen?: InputMaybe<Boolean_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_image_link?: InputMaybe<String_Comparison_Exp>;
  item_image_link_formated?: InputMaybe<String_Comparison_Exp>;
  item_lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_lootbox_price?: InputMaybe<Float_Comparison_Exp>;
  item_quality?: InputMaybe<String_Comparison_Exp>;
  item_rariry?: InputMaybe<String_Comparison_Exp>;
  item_start_price?: InputMaybe<Float_Comparison_Exp>;
  item_status?: InputMaybe<Int_Comparison_Exp>;
  item_transactions?: InputMaybe<User_Inventory_Transactions_Bool_Exp>;
  item_transactions_aggregate?: InputMaybe<User_Inventory_Transactions_Aggregate_Bool_Exp>;
  localized_name?: InputMaybe<Account_Items_Names_Bool_Exp>;
  receiving_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  ru_name?: InputMaybe<String_Comparison_Exp>;
  upgrade_block?: InputMaybe<Boolean_Comparison_Exp>;
  upgrade_serial_data?: InputMaybe<Jsonb_Comparison_Exp>;
  user_account?: InputMaybe<User_Account_Bool_Exp>;
  user_inventory?: InputMaybe<User_Inventory_Bool_Exp>;
  user_inventory_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_item_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_items_in_inventory" */
export enum User_Items_In_Inventory_Constraint {
  /** unique or primary key constraint on columns "user_item_id", "user_inventory_id" */
  UserItemsInInventoryPkey = 'user_items_in_inventory_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Items_In_Inventory_Delete_At_Path_Input = {
  upgrade_serial_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Items_In_Inventory_Delete_Elem_Input = {
  upgrade_serial_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Items_In_Inventory_Delete_Key_Input = {
  upgrade_serial_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user_items_in_inventory" */
export type User_Items_In_Inventory_Inc_Input = {
  item_lootbox_price?: InputMaybe<Scalars['Float']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_status?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_items_in_inventory" */
export type User_Items_In_Inventory_Insert_Input = {
  from_upgrade?: InputMaybe<Scalars['Boolean']>;
  item_frozen?: InputMaybe<Scalars['Boolean']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_lootbox_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rariry?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_status?: InputMaybe<Scalars['Int']>;
  item_transactions?: InputMaybe<User_Inventory_Transactions_Arr_Rel_Insert_Input>;
  localized_name?: InputMaybe<Account_Items_Names_Obj_Rel_Insert_Input>;
  receiving_datetime?: InputMaybe<Scalars['timestamp']>;
  ru_name?: InputMaybe<Scalars['String']>;
  upgrade_block?: InputMaybe<Scalars['Boolean']>;
  upgrade_serial_data?: InputMaybe<Scalars['jsonb']>;
  user_account?: InputMaybe<User_Account_Obj_Rel_Insert_Input>;
  user_inventory?: InputMaybe<User_Inventory_Obj_Rel_Insert_Input>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Items_In_Inventory_Max_Fields = {
  __typename?: 'user_items_in_inventory_max_fields';
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_formated_item_image_link" */
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_lootbox_id?: Maybe<Scalars['uuid']>;
  item_lootbox_price?: Maybe<Scalars['Float']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rariry?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_status?: Maybe<Scalars['Int']>;
  receiving_datetime?: Maybe<Scalars['timestamp']>;
  ru_name?: Maybe<Scalars['String']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_items_in_inventory" */
export type User_Items_In_Inventory_Max_Order_By = {
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_lootbox_id?: InputMaybe<Order_By>;
  item_lootbox_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rariry?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
  receiving_datetime?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
  user_item_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Items_In_Inventory_Min_Fields = {
  __typename?: 'user_items_in_inventory_min_fields';
  item_hash_name?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_image_link?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_formated_item_image_link" */
  item_image_link_formated?: Maybe<Scalars['String']>;
  item_lootbox_id?: Maybe<Scalars['uuid']>;
  item_lootbox_price?: Maybe<Scalars['Float']>;
  item_quality?: Maybe<Scalars['String']>;
  item_rariry?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_status?: Maybe<Scalars['Int']>;
  receiving_datetime?: Maybe<Scalars['timestamp']>;
  ru_name?: Maybe<Scalars['String']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_items_in_inventory" */
export type User_Items_In_Inventory_Min_Order_By = {
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_lootbox_id?: InputMaybe<Order_By>;
  item_lootbox_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rariry?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
  receiving_datetime?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
  user_item_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_items_in_inventory" */
export type User_Items_In_Inventory_Mutation_Response = {
  __typename?: 'user_items_in_inventory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Items_In_Inventory>;
};

/** input type for inserting object relation for remote table "user_items_in_inventory" */
export type User_Items_In_Inventory_Obj_Rel_Insert_Input = {
  data: User_Items_In_Inventory_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Items_In_Inventory_On_Conflict>;
};

/** on_conflict condition type for table "user_items_in_inventory" */
export type User_Items_In_Inventory_On_Conflict = {
  constraint: User_Items_In_Inventory_Constraint;
  update_columns?: Array<User_Items_In_Inventory_Update_Column>;
  where?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
};

/** Ordering options when selecting data from "user_items_in_inventory". */
export type User_Items_In_Inventory_Order_By = {
  from_upgrade?: InputMaybe<Order_By>;
  item_frozen?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_image_link?: InputMaybe<Order_By>;
  item_image_link_formated?: InputMaybe<Order_By>;
  item_lootbox_id?: InputMaybe<Order_By>;
  item_lootbox_price?: InputMaybe<Order_By>;
  item_quality?: InputMaybe<Order_By>;
  item_rariry?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
  item_transactions_aggregate?: InputMaybe<User_Inventory_Transactions_Aggregate_Order_By>;
  localized_name?: InputMaybe<Account_Items_Names_Order_By>;
  receiving_datetime?: InputMaybe<Order_By>;
  ru_name?: InputMaybe<Order_By>;
  upgrade_block?: InputMaybe<Order_By>;
  upgrade_serial_data?: InputMaybe<Order_By>;
  user_account?: InputMaybe<User_Account_Order_By>;
  user_inventory?: InputMaybe<User_Inventory_Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
  user_item_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_items_in_inventory */
export type User_Items_In_Inventory_Pk_Columns_Input = {
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Items_In_Inventory_Prepend_Input = {
  upgrade_serial_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_items_in_inventory" */
export enum User_Items_In_Inventory_Select_Column {
  /** column name */
  FromUpgrade = 'from_upgrade',
  /** column name */
  ItemFrozen = 'item_frozen',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemLootboxId = 'item_lootbox_id',
  /** column name */
  ItemLootboxPrice = 'item_lootbox_price',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRariry = 'item_rariry',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemStatus = 'item_status',
  /** column name */
  ReceivingDatetime = 'receiving_datetime',
  /** column name */
  RuName = 'ru_name',
  /** column name */
  UpgradeBlock = 'upgrade_block',
  /** column name */
  UpgradeSerialData = 'upgrade_serial_data',
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserItemId = 'user_item_id'
}

/** select "user_items_in_inventory_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_items_in_inventory" */
export enum User_Items_In_Inventory_Select_Column_User_Items_In_Inventory_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FromUpgrade = 'from_upgrade',
  /** column name */
  ItemFrozen = 'item_frozen',
  /** column name */
  UpgradeBlock = 'upgrade_block'
}

/** select "user_items_in_inventory_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_items_in_inventory" */
export enum User_Items_In_Inventory_Select_Column_User_Items_In_Inventory_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FromUpgrade = 'from_upgrade',
  /** column name */
  ItemFrozen = 'item_frozen',
  /** column name */
  UpgradeBlock = 'upgrade_block'
}

/** input type for updating data in table "user_items_in_inventory" */
export type User_Items_In_Inventory_Set_Input = {
  from_upgrade?: InputMaybe<Scalars['Boolean']>;
  item_frozen?: InputMaybe<Scalars['Boolean']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_lootbox_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rariry?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_status?: InputMaybe<Scalars['Int']>;
  receiving_datetime?: InputMaybe<Scalars['timestamp']>;
  ru_name?: InputMaybe<Scalars['String']>;
  upgrade_block?: InputMaybe<Scalars['Boolean']>;
  upgrade_serial_data?: InputMaybe<Scalars['jsonb']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Items_In_Inventory_Stddev_Fields = {
  __typename?: 'user_items_in_inventory_stddev_fields';
  item_lootbox_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_items_in_inventory" */
export type User_Items_In_Inventory_Stddev_Order_By = {
  item_lootbox_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Items_In_Inventory_Stddev_Pop_Fields = {
  __typename?: 'user_items_in_inventory_stddev_pop_fields';
  item_lootbox_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_items_in_inventory" */
export type User_Items_In_Inventory_Stddev_Pop_Order_By = {
  item_lootbox_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Items_In_Inventory_Stddev_Samp_Fields = {
  __typename?: 'user_items_in_inventory_stddev_samp_fields';
  item_lootbox_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_items_in_inventory" */
export type User_Items_In_Inventory_Stddev_Samp_Order_By = {
  item_lootbox_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_items_in_inventory" */
export type User_Items_In_Inventory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Items_In_Inventory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Items_In_Inventory_Stream_Cursor_Value_Input = {
  from_upgrade?: InputMaybe<Scalars['Boolean']>;
  item_frozen?: InputMaybe<Scalars['Boolean']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_image_link?: InputMaybe<Scalars['String']>;
  item_lootbox_id?: InputMaybe<Scalars['uuid']>;
  item_lootbox_price?: InputMaybe<Scalars['Float']>;
  item_quality?: InputMaybe<Scalars['String']>;
  item_rariry?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Float']>;
  item_status?: InputMaybe<Scalars['Int']>;
  receiving_datetime?: InputMaybe<Scalars['timestamp']>;
  ru_name?: InputMaybe<Scalars['String']>;
  upgrade_block?: InputMaybe<Scalars['Boolean']>;
  upgrade_serial_data?: InputMaybe<Scalars['jsonb']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Items_In_Inventory_Sum_Fields = {
  __typename?: 'user_items_in_inventory_sum_fields';
  item_lootbox_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_status?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_items_in_inventory" */
export type User_Items_In_Inventory_Sum_Order_By = {
  item_lootbox_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
};

/** update columns of table "user_items_in_inventory" */
export enum User_Items_In_Inventory_Update_Column {
  /** column name */
  FromUpgrade = 'from_upgrade',
  /** column name */
  ItemFrozen = 'item_frozen',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageLink = 'item_image_link',
  /** column name */
  ItemLootboxId = 'item_lootbox_id',
  /** column name */
  ItemLootboxPrice = 'item_lootbox_price',
  /** column name */
  ItemQuality = 'item_quality',
  /** column name */
  ItemRariry = 'item_rariry',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  ItemStatus = 'item_status',
  /** column name */
  ReceivingDatetime = 'receiving_datetime',
  /** column name */
  RuName = 'ru_name',
  /** column name */
  UpgradeBlock = 'upgrade_block',
  /** column name */
  UpgradeSerialData = 'upgrade_serial_data',
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserItemId = 'user_item_id'
}

export type User_Items_In_Inventory_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Items_In_Inventory_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Items_In_Inventory_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Items_In_Inventory_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Items_In_Inventory_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Items_In_Inventory_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Items_In_Inventory_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Items_In_Inventory_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Items_In_Inventory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Items_In_Inventory_Var_Pop_Fields = {
  __typename?: 'user_items_in_inventory_var_pop_fields';
  item_lootbox_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_items_in_inventory" */
export type User_Items_In_Inventory_Var_Pop_Order_By = {
  item_lootbox_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Items_In_Inventory_Var_Samp_Fields = {
  __typename?: 'user_items_in_inventory_var_samp_fields';
  item_lootbox_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_items_in_inventory" */
export type User_Items_In_Inventory_Var_Samp_Order_By = {
  item_lootbox_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Items_In_Inventory_Variance_Fields = {
  __typename?: 'user_items_in_inventory_variance_fields';
  item_lootbox_price?: Maybe<Scalars['Float']>;
  item_start_price?: Maybe<Scalars['Float']>;
  item_status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_items_in_inventory" */
export type User_Items_In_Inventory_Variance_Order_By = {
  item_lootbox_price?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_lootbox_opens_count" */
export type User_Lootbox_Opens_Count = {
  __typename?: 'user_lootbox_opens_count';
  active?: Maybe<Scalars['Boolean']>;
  lootbox_id: Scalars['uuid'];
  opens_count?: Maybe<Scalars['Int']>;
  update_datetime?: Maybe<Scalars['timestamp']>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_lootbox_opens_count" */
export type User_Lootbox_Opens_Count_Aggregate = {
  __typename?: 'user_lootbox_opens_count_aggregate';
  aggregate?: Maybe<User_Lootbox_Opens_Count_Aggregate_Fields>;
  nodes: Array<User_Lootbox_Opens_Count>;
};

/** aggregate fields of "user_lootbox_opens_count" */
export type User_Lootbox_Opens_Count_Aggregate_Fields = {
  __typename?: 'user_lootbox_opens_count_aggregate_fields';
  avg?: Maybe<User_Lootbox_Opens_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Lootbox_Opens_Count_Max_Fields>;
  min?: Maybe<User_Lootbox_Opens_Count_Min_Fields>;
  stddev?: Maybe<User_Lootbox_Opens_Count_Stddev_Fields>;
  stddev_pop?: Maybe<User_Lootbox_Opens_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Lootbox_Opens_Count_Stddev_Samp_Fields>;
  sum?: Maybe<User_Lootbox_Opens_Count_Sum_Fields>;
  var_pop?: Maybe<User_Lootbox_Opens_Count_Var_Pop_Fields>;
  var_samp?: Maybe<User_Lootbox_Opens_Count_Var_Samp_Fields>;
  variance?: Maybe<User_Lootbox_Opens_Count_Variance_Fields>;
};


/** aggregate fields of "user_lootbox_opens_count" */
export type User_Lootbox_Opens_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Lootbox_Opens_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Lootbox_Opens_Count_Avg_Fields = {
  __typename?: 'user_lootbox_opens_count_avg_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_lootbox_opens_count". All fields are combined with a logical 'AND'. */
export type User_Lootbox_Opens_Count_Bool_Exp = {
  _and?: InputMaybe<Array<User_Lootbox_Opens_Count_Bool_Exp>>;
  _not?: InputMaybe<User_Lootbox_Opens_Count_Bool_Exp>;
  _or?: InputMaybe<Array<User_Lootbox_Opens_Count_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  opens_count?: InputMaybe<Int_Comparison_Exp>;
  update_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_lootbox_opens_count" */
export enum User_Lootbox_Opens_Count_Constraint {
  /** unique or primary key constraint on columns "lootbox_id", "user_id" */
  UserLootboxOpensCountPkey = 'user_lootbox_opens_count_pkey'
}

/** input type for incrementing numeric columns in table "user_lootbox_opens_count" */
export type User_Lootbox_Opens_Count_Inc_Input = {
  opens_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_lootbox_opens_count" */
export type User_Lootbox_Opens_Count_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  opens_count?: InputMaybe<Scalars['Int']>;
  update_datetime?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Lootbox_Opens_Count_Max_Fields = {
  __typename?: 'user_lootbox_opens_count_max_fields';
  lootbox_id?: Maybe<Scalars['uuid']>;
  opens_count?: Maybe<Scalars['Int']>;
  update_datetime?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Lootbox_Opens_Count_Min_Fields = {
  __typename?: 'user_lootbox_opens_count_min_fields';
  lootbox_id?: Maybe<Scalars['uuid']>;
  opens_count?: Maybe<Scalars['Int']>;
  update_datetime?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_lootbox_opens_count" */
export type User_Lootbox_Opens_Count_Mutation_Response = {
  __typename?: 'user_lootbox_opens_count_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Lootbox_Opens_Count>;
};

/** on_conflict condition type for table "user_lootbox_opens_count" */
export type User_Lootbox_Opens_Count_On_Conflict = {
  constraint: User_Lootbox_Opens_Count_Constraint;
  update_columns?: Array<User_Lootbox_Opens_Count_Update_Column>;
  where?: InputMaybe<User_Lootbox_Opens_Count_Bool_Exp>;
};

/** Ordering options when selecting data from "user_lootbox_opens_count". */
export type User_Lootbox_Opens_Count_Order_By = {
  active?: InputMaybe<Order_By>;
  lootbox_id?: InputMaybe<Order_By>;
  opens_count?: InputMaybe<Order_By>;
  update_datetime?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_lootbox_opens_count */
export type User_Lootbox_Opens_Count_Pk_Columns_Input = {
  lootbox_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "user_lootbox_opens_count" */
export enum User_Lootbox_Opens_Count_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  OpensCount = 'opens_count',
  /** column name */
  UpdateDatetime = 'update_datetime',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_lootbox_opens_count" */
export type User_Lootbox_Opens_Count_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  opens_count?: InputMaybe<Scalars['Int']>;
  update_datetime?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Lootbox_Opens_Count_Stddev_Fields = {
  __typename?: 'user_lootbox_opens_count_stddev_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Lootbox_Opens_Count_Stddev_Pop_Fields = {
  __typename?: 'user_lootbox_opens_count_stddev_pop_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Lootbox_Opens_Count_Stddev_Samp_Fields = {
  __typename?: 'user_lootbox_opens_count_stddev_samp_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_lootbox_opens_count" */
export type User_Lootbox_Opens_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Lootbox_Opens_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Lootbox_Opens_Count_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  opens_count?: InputMaybe<Scalars['Int']>;
  update_datetime?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Lootbox_Opens_Count_Sum_Fields = {
  __typename?: 'user_lootbox_opens_count_sum_fields';
  opens_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_lootbox_opens_count" */
export enum User_Lootbox_Opens_Count_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  OpensCount = 'opens_count',
  /** column name */
  UpdateDatetime = 'update_datetime',
  /** column name */
  UserId = 'user_id'
}

export type User_Lootbox_Opens_Count_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Lootbox_Opens_Count_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Lootbox_Opens_Count_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Lootbox_Opens_Count_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Lootbox_Opens_Count_Var_Pop_Fields = {
  __typename?: 'user_lootbox_opens_count_var_pop_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Lootbox_Opens_Count_Var_Samp_Fields = {
  __typename?: 'user_lootbox_opens_count_var_samp_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Lootbox_Opens_Count_Variance_Fields = {
  __typename?: 'user_lootbox_opens_count_variance_fields';
  opens_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_marking" */
export type User_Marking = {
  __typename?: 'user_marking';
  created_at: Scalars['timestamptz'];
  identification?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  /** An object relationship */
  transaction?: Maybe<Transaction>;
  transaction_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  user_marking_id: Scalars['uuid'];
  user_profile?: Maybe<User_Profile>;
};

/** aggregated selection of "user_marking" */
export type User_Marking_Aggregate = {
  __typename?: 'user_marking_aggregate';
  aggregate?: Maybe<User_Marking_Aggregate_Fields>;
  nodes: Array<User_Marking>;
};

/** aggregate fields of "user_marking" */
export type User_Marking_Aggregate_Fields = {
  __typename?: 'user_marking_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Marking_Max_Fields>;
  min?: Maybe<User_Marking_Min_Fields>;
};


/** aggregate fields of "user_marking" */
export type User_Marking_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Marking_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_marking". All fields are combined with a logical 'AND'. */
export type User_Marking_Bool_Exp = {
  _and?: InputMaybe<Array<User_Marking_Bool_Exp>>;
  _not?: InputMaybe<User_Marking_Bool_Exp>;
  _or?: InputMaybe<Array<User_Marking_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  identification?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  transaction?: InputMaybe<Transaction_Bool_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_marking_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_marking" */
export enum User_Marking_Constraint {
  /** unique or primary key constraint on columns "user_marking_id" */
  UserMarkingPkey = 'user_marking_pkey'
}

/** input type for inserting data into table "user_marking" */
export type User_Marking_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identification?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  transaction?: InputMaybe<Transaction_Obj_Rel_Insert_Input>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_marking_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Marking_Max_Fields = {
  __typename?: 'user_marking_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  identification?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_marking_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Marking_Min_Fields = {
  __typename?: 'user_marking_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  identification?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_marking_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_marking" */
export type User_Marking_Mutation_Response = {
  __typename?: 'user_marking_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Marking>;
};

/** on_conflict condition type for table "user_marking" */
export type User_Marking_On_Conflict = {
  constraint: User_Marking_Constraint;
  update_columns?: Array<User_Marking_Update_Column>;
  where?: InputMaybe<User_Marking_Bool_Exp>;
};

/** Ordering options when selecting data from "user_marking". */
export type User_Marking_Order_By = {
  created_at?: InputMaybe<Order_By>;
  identification?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  transaction?: InputMaybe<Transaction_Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_marking_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_marking */
export type User_Marking_Pk_Columns_Input = {
  user_marking_id: Scalars['uuid'];
};

/** select columns of table "user_marking" */
export enum User_Marking_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Identification = 'identification',
  /** column name */
  Source = 'source',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserMarkingId = 'user_marking_id'
}

/** input type for updating data in table "user_marking" */
export type User_Marking_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identification?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_marking_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_marking" */
export type User_Marking_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Marking_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Marking_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  identification?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_marking_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_marking" */
export enum User_Marking_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Identification = 'identification',
  /** column name */
  Source = 'source',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserMarkingId = 'user_marking_id'
}

export type User_Marking_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Marking_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Marking_Bool_Exp;
};

/** columns and relationships of "user_markings" */
export type User_Markings = {
  __typename?: 'user_markings';
  first_deposit_date: Scalars['timestamptz'];
  identification?: Maybe<Scalars['String']>;
  registration_date: Scalars['timestamptz'];
  source?: Maybe<Scalars['String']>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_markings" */
export type User_Markings_Aggregate = {
  __typename?: 'user_markings_aggregate';
  aggregate?: Maybe<User_Markings_Aggregate_Fields>;
  nodes: Array<User_Markings>;
};

/** aggregate fields of "user_markings" */
export type User_Markings_Aggregate_Fields = {
  __typename?: 'user_markings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Markings_Max_Fields>;
  min?: Maybe<User_Markings_Min_Fields>;
};


/** aggregate fields of "user_markings" */
export type User_Markings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Markings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_markings". All fields are combined with a logical 'AND'. */
export type User_Markings_Bool_Exp = {
  _and?: InputMaybe<Array<User_Markings_Bool_Exp>>;
  _not?: InputMaybe<User_Markings_Bool_Exp>;
  _or?: InputMaybe<Array<User_Markings_Bool_Exp>>;
  first_deposit_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  identification?: InputMaybe<String_Comparison_Exp>;
  registration_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_markings" */
export enum User_Markings_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserMarkingsPkey = 'user_markings_pkey'
}

/** input type for inserting data into table "user_markings" */
export type User_Markings_Insert_Input = {
  first_deposit_date?: InputMaybe<Scalars['timestamptz']>;
  identification?: InputMaybe<Scalars['String']>;
  registration_date?: InputMaybe<Scalars['timestamptz']>;
  source?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Markings_Max_Fields = {
  __typename?: 'user_markings_max_fields';
  first_deposit_date?: Maybe<Scalars['timestamptz']>;
  identification?: Maybe<Scalars['String']>;
  registration_date?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Markings_Min_Fields = {
  __typename?: 'user_markings_min_fields';
  first_deposit_date?: Maybe<Scalars['timestamptz']>;
  identification?: Maybe<Scalars['String']>;
  registration_date?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_markings" */
export type User_Markings_Mutation_Response = {
  __typename?: 'user_markings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Markings>;
};

/** on_conflict condition type for table "user_markings" */
export type User_Markings_On_Conflict = {
  constraint: User_Markings_Constraint;
  update_columns?: Array<User_Markings_Update_Column>;
  where?: InputMaybe<User_Markings_Bool_Exp>;
};

/** Ordering options when selecting data from "user_markings". */
export type User_Markings_Order_By = {
  first_deposit_date?: InputMaybe<Order_By>;
  identification?: InputMaybe<Order_By>;
  registration_date?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_markings */
export type User_Markings_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_markings" */
export enum User_Markings_Select_Column {
  /** column name */
  FirstDepositDate = 'first_deposit_date',
  /** column name */
  Identification = 'identification',
  /** column name */
  RegistrationDate = 'registration_date',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_markings" */
export type User_Markings_Set_Input = {
  first_deposit_date?: InputMaybe<Scalars['timestamptz']>;
  identification?: InputMaybe<Scalars['String']>;
  registration_date?: InputMaybe<Scalars['timestamptz']>;
  source?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_markings" */
export type User_Markings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Markings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Markings_Stream_Cursor_Value_Input = {
  first_deposit_date?: InputMaybe<Scalars['timestamptz']>;
  identification?: InputMaybe<Scalars['String']>;
  registration_date?: InputMaybe<Scalars['timestamptz']>;
  source?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_markings" */
export enum User_Markings_Update_Column {
  /** column name */
  FirstDepositDate = 'first_deposit_date',
  /** column name */
  Identification = 'identification',
  /** column name */
  RegistrationDate = 'registration_date',
  /** column name */
  Source = 'source',
  /** column name */
  UserId = 'user_id'
}

export type User_Markings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Markings_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Markings_Bool_Exp;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  age?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  age?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  age?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  passport_info?: InputMaybe<Passport_Info_Order_By>;
};

/** columns and relationships of "user_output_limits" */
export type User_Output_Limits = {
  __typename?: 'user_output_limits';
  created_at: Scalars['timestamp'];
  limit?: Maybe<Scalars['Int']>;
  limit_currency: Scalars['String'];
  limit_start_date: Scalars['date'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_output_limits" */
export type User_Output_Limits_Aggregate = {
  __typename?: 'user_output_limits_aggregate';
  aggregate?: Maybe<User_Output_Limits_Aggregate_Fields>;
  nodes: Array<User_Output_Limits>;
};

/** aggregate fields of "user_output_limits" */
export type User_Output_Limits_Aggregate_Fields = {
  __typename?: 'user_output_limits_aggregate_fields';
  avg?: Maybe<User_Output_Limits_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Output_Limits_Max_Fields>;
  min?: Maybe<User_Output_Limits_Min_Fields>;
  stddev?: Maybe<User_Output_Limits_Stddev_Fields>;
  stddev_pop?: Maybe<User_Output_Limits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Output_Limits_Stddev_Samp_Fields>;
  sum?: Maybe<User_Output_Limits_Sum_Fields>;
  var_pop?: Maybe<User_Output_Limits_Var_Pop_Fields>;
  var_samp?: Maybe<User_Output_Limits_Var_Samp_Fields>;
  variance?: Maybe<User_Output_Limits_Variance_Fields>;
};


/** aggregate fields of "user_output_limits" */
export type User_Output_Limits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Output_Limits_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Output_Limits_Avg_Fields = {
  __typename?: 'user_output_limits_avg_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_output_limits". All fields are combined with a logical 'AND'. */
export type User_Output_Limits_Bool_Exp = {
  _and?: InputMaybe<Array<User_Output_Limits_Bool_Exp>>;
  _not?: InputMaybe<User_Output_Limits_Bool_Exp>;
  _or?: InputMaybe<Array<User_Output_Limits_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  limit?: InputMaybe<Int_Comparison_Exp>;
  limit_currency?: InputMaybe<String_Comparison_Exp>;
  limit_start_date?: InputMaybe<Date_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_output_limits" */
export enum User_Output_Limits_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserOutputLimitsPkey = 'user_output_limits_pkey'
}

/** input type for incrementing numeric columns in table "user_output_limits" */
export type User_Output_Limits_Inc_Input = {
  limit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_output_limits" */
export type User_Output_Limits_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  limit?: InputMaybe<Scalars['Int']>;
  limit_currency?: InputMaybe<Scalars['String']>;
  limit_start_date?: InputMaybe<Scalars['date']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Output_Limits_Max_Fields = {
  __typename?: 'user_output_limits_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  limit?: Maybe<Scalars['Int']>;
  limit_currency?: Maybe<Scalars['String']>;
  limit_start_date?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Output_Limits_Min_Fields = {
  __typename?: 'user_output_limits_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  limit?: Maybe<Scalars['Int']>;
  limit_currency?: Maybe<Scalars['String']>;
  limit_start_date?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_output_limits" */
export type User_Output_Limits_Mutation_Response = {
  __typename?: 'user_output_limits_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Output_Limits>;
};

/** on_conflict condition type for table "user_output_limits" */
export type User_Output_Limits_On_Conflict = {
  constraint: User_Output_Limits_Constraint;
  update_columns?: Array<User_Output_Limits_Update_Column>;
  where?: InputMaybe<User_Output_Limits_Bool_Exp>;
};

/** Ordering options when selecting data from "user_output_limits". */
export type User_Output_Limits_Order_By = {
  created_at?: InputMaybe<Order_By>;
  limit?: InputMaybe<Order_By>;
  limit_currency?: InputMaybe<Order_By>;
  limit_start_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_output_limits */
export type User_Output_Limits_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_output_limits" */
export enum User_Output_Limits_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Limit = 'limit',
  /** column name */
  LimitCurrency = 'limit_currency',
  /** column name */
  LimitStartDate = 'limit_start_date',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_output_limits" */
export type User_Output_Limits_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  limit?: InputMaybe<Scalars['Int']>;
  limit_currency?: InputMaybe<Scalars['String']>;
  limit_start_date?: InputMaybe<Scalars['date']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Output_Limits_Stddev_Fields = {
  __typename?: 'user_output_limits_stddev_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Output_Limits_Stddev_Pop_Fields = {
  __typename?: 'user_output_limits_stddev_pop_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Output_Limits_Stddev_Samp_Fields = {
  __typename?: 'user_output_limits_stddev_samp_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_output_limits" */
export type User_Output_Limits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Output_Limits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Output_Limits_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  limit?: InputMaybe<Scalars['Int']>;
  limit_currency?: InputMaybe<Scalars['String']>;
  limit_start_date?: InputMaybe<Scalars['date']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Output_Limits_Sum_Fields = {
  __typename?: 'user_output_limits_sum_fields';
  limit?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_output_limits" */
export enum User_Output_Limits_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Limit = 'limit',
  /** column name */
  LimitCurrency = 'limit_currency',
  /** column name */
  LimitStartDate = 'limit_start_date',
  /** column name */
  UserId = 'user_id'
}

export type User_Output_Limits_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Output_Limits_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Output_Limits_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Output_Limits_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Output_Limits_Var_Pop_Fields = {
  __typename?: 'user_output_limits_var_pop_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Output_Limits_Var_Samp_Fields = {
  __typename?: 'user_output_limits_var_samp_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Output_Limits_Variance_Fields = {
  __typename?: 'user_output_limits_variance_fields';
  limit?: Maybe<Scalars['Float']>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Профиль конкретного юзера */
export type User_Profile = {
  __typename?: 'user_profile';
  avatar?: Maybe<Scalars['String']>;
  avatar_full?: Maybe<Scalars['String']>;
  avatar_medium?: Maybe<Scalars['String']>;
  bot_user: Scalars['Boolean'];
  created_at: Scalars['timestamp'];
  email?: Maybe<Scalars['String']>;
  google_full_name?: Maybe<Scalars['String']>;
  google_id?: Maybe<Scalars['String']>;
  is_deleted: Scalars['Boolean'];
  persona_name: Scalars['String'];
  profile_url?: Maybe<Scalars['String']>;
  steam_id?: Maybe<Scalars['bigint']>;
  steam_id_str?: Maybe<Scalars['String']>;
  terms_accepted: Scalars['Boolean'];
  trade_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  user_contact?: Maybe<User_Contacts>;
  user_id: Scalars['uuid'];
  /** An array relationship */
  user_push_tokens: Array<User_Push_Tokens>;
  /** An aggregate relationship */
  user_push_tokens_aggregate: User_Push_Tokens_Aggregate;
  user_referal?: Maybe<User_Referals>;
  /** An object relationship */
  user_setting?: Maybe<User_Settings>;
  /** An object relationship */
  user_statistic?: Maybe<User_Statistic>;
  user_visits: Array<Utm_Visits_Data>;
  user_visits_aggregate: Utm_Visits_Data_Aggregate;
  vk_full_name?: Maybe<Scalars['String']>;
  vk_id?: Maybe<Scalars['String']>;
  vk_nickname?: Maybe<Scalars['String']>;
  vk_profile_url?: Maybe<Scalars['String']>;
};


/** Профиль конкретного юзера */
export type User_ProfileUser_Push_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Push_Tokens_Order_By>>;
  where?: InputMaybe<User_Push_Tokens_Bool_Exp>;
};


/** Профиль конкретного юзера */
export type User_ProfileUser_Push_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Push_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Push_Tokens_Order_By>>;
  where?: InputMaybe<User_Push_Tokens_Bool_Exp>;
};


/** Профиль конкретного юзера */
export type User_ProfileUser_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Utm_Visits_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Utm_Visits_Data_Order_By>>;
  where?: InputMaybe<Utm_Visits_Data_Bool_Exp>;
};


/** Профиль конкретного юзера */
export type User_ProfileUser_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Utm_Visits_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Utm_Visits_Data_Order_By>>;
  where?: InputMaybe<Utm_Visits_Data_Bool_Exp>;
};

/** aggregated selection of "user_profile" */
export type User_Profile_Aggregate = {
  __typename?: 'user_profile_aggregate';
  aggregate?: Maybe<User_Profile_Aggregate_Fields>;
  nodes: Array<User_Profile>;
};

/** aggregate fields of "user_profile" */
export type User_Profile_Aggregate_Fields = {
  __typename?: 'user_profile_aggregate_fields';
  avg?: Maybe<User_Profile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Profile_Max_Fields>;
  min?: Maybe<User_Profile_Min_Fields>;
  stddev?: Maybe<User_Profile_Stddev_Fields>;
  stddev_pop?: Maybe<User_Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Profile_Stddev_Samp_Fields>;
  sum?: Maybe<User_Profile_Sum_Fields>;
  var_pop?: Maybe<User_Profile_Var_Pop_Fields>;
  var_samp?: Maybe<User_Profile_Var_Samp_Fields>;
  variance?: Maybe<User_Profile_Variance_Fields>;
};


/** aggregate fields of "user_profile" */
export type User_Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Profile_Avg_Fields = {
  __typename?: 'user_profile_avg_fields';
  steam_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_profile". All fields are combined with a logical 'AND'. */
export type User_Profile_Bool_Exp = {
  _and?: InputMaybe<Array<User_Profile_Bool_Exp>>;
  _not?: InputMaybe<User_Profile_Bool_Exp>;
  _or?: InputMaybe<Array<User_Profile_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  avatar_full?: InputMaybe<String_Comparison_Exp>;
  avatar_medium?: InputMaybe<String_Comparison_Exp>;
  bot_user?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  google_full_name?: InputMaybe<String_Comparison_Exp>;
  google_id?: InputMaybe<String_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  persona_name?: InputMaybe<String_Comparison_Exp>;
  profile_url?: InputMaybe<String_Comparison_Exp>;
  steam_id?: InputMaybe<Bigint_Comparison_Exp>;
  steam_id_str?: InputMaybe<String_Comparison_Exp>;
  terms_accepted?: InputMaybe<Boolean_Comparison_Exp>;
  trade_url?: InputMaybe<String_Comparison_Exp>;
  user_contact?: InputMaybe<User_Contacts_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_push_tokens?: InputMaybe<User_Push_Tokens_Bool_Exp>;
  user_push_tokens_aggregate?: InputMaybe<User_Push_Tokens_Aggregate_Bool_Exp>;
  user_setting?: InputMaybe<User_Settings_Bool_Exp>;
  user_statistic?: InputMaybe<User_Statistic_Bool_Exp>;
  vk_full_name?: InputMaybe<String_Comparison_Exp>;
  vk_id?: InputMaybe<String_Comparison_Exp>;
  vk_nickname?: InputMaybe<String_Comparison_Exp>;
  vk_profile_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_profile" */
export enum User_Profile_Constraint {
  /** unique or primary key constraint on columns "google_id" */
  UserProfileGoogleIdKey = 'user_profile_google_id_key',
  /** unique or primary key constraint on columns "user_id" */
  UserProfilePkey = 'user_profile_pkey',
  /** unique or primary key constraint on columns "steam_id", "vk_id", "google_id" */
  UserProfileSteamIdGoogleIdVkIdKey = 'user_profile_steam_id_google_id_vk_id_key',
  /** unique or primary key constraint on columns "steam_id" */
  UserProfileSteamIdKey = 'user_profile_steam_id_key',
  /** unique or primary key constraint on columns "vk_id" */
  UserProfileVkIdKey = 'user_profile_vk_id_key'
}

/** input type for incrementing numeric columns in table "user_profile" */
export type User_Profile_Inc_Input = {
  steam_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_profile" */
export type User_Profile_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  avatar_full?: InputMaybe<Scalars['String']>;
  avatar_medium?: InputMaybe<Scalars['String']>;
  bot_user?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  google_full_name?: InputMaybe<Scalars['String']>;
  google_id?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  persona_name?: InputMaybe<Scalars['String']>;
  profile_url?: InputMaybe<Scalars['String']>;
  steam_id?: InputMaybe<Scalars['bigint']>;
  steam_id_str?: InputMaybe<Scalars['String']>;
  terms_accepted?: InputMaybe<Scalars['Boolean']>;
  trade_url?: InputMaybe<Scalars['String']>;
  user_contact?: InputMaybe<User_Contacts_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_push_tokens?: InputMaybe<User_Push_Tokens_Arr_Rel_Insert_Input>;
  user_setting?: InputMaybe<User_Settings_Obj_Rel_Insert_Input>;
  user_statistic?: InputMaybe<User_Statistic_Obj_Rel_Insert_Input>;
  vk_full_name?: InputMaybe<Scalars['String']>;
  vk_id?: InputMaybe<Scalars['String']>;
  vk_nickname?: InputMaybe<Scalars['String']>;
  vk_profile_url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Profile_Max_Fields = {
  __typename?: 'user_profile_max_fields';
  avatar?: Maybe<Scalars['String']>;
  avatar_full?: Maybe<Scalars['String']>;
  avatar_medium?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  google_full_name?: Maybe<Scalars['String']>;
  google_id?: Maybe<Scalars['String']>;
  persona_name?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
  steam_id?: Maybe<Scalars['bigint']>;
  steam_id_str?: Maybe<Scalars['String']>;
  trade_url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vk_full_name?: Maybe<Scalars['String']>;
  vk_id?: Maybe<Scalars['String']>;
  vk_nickname?: Maybe<Scalars['String']>;
  vk_profile_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Profile_Min_Fields = {
  __typename?: 'user_profile_min_fields';
  avatar?: Maybe<Scalars['String']>;
  avatar_full?: Maybe<Scalars['String']>;
  avatar_medium?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  google_full_name?: Maybe<Scalars['String']>;
  google_id?: Maybe<Scalars['String']>;
  persona_name?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
  steam_id?: Maybe<Scalars['bigint']>;
  steam_id_str?: Maybe<Scalars['String']>;
  trade_url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  vk_full_name?: Maybe<Scalars['String']>;
  vk_id?: Maybe<Scalars['String']>;
  vk_nickname?: Maybe<Scalars['String']>;
  vk_profile_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_profile" */
export type User_Profile_Mutation_Response = {
  __typename?: 'user_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Profile>;
};

/** input type for inserting object relation for remote table "user_profile" */
export type User_Profile_Obj_Rel_Insert_Input = {
  data: User_Profile_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Profile_On_Conflict>;
};

/** on_conflict condition type for table "user_profile" */
export type User_Profile_On_Conflict = {
  constraint: User_Profile_Constraint;
  update_columns?: Array<User_Profile_Update_Column>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "user_profile". */
export type User_Profile_Order_By = {
  avatar?: InputMaybe<Order_By>;
  avatar_full?: InputMaybe<Order_By>;
  avatar_medium?: InputMaybe<Order_By>;
  bot_user?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  google_full_name?: InputMaybe<Order_By>;
  google_id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  persona_name?: InputMaybe<Order_By>;
  profile_url?: InputMaybe<Order_By>;
  steam_id?: InputMaybe<Order_By>;
  steam_id_str?: InputMaybe<Order_By>;
  terms_accepted?: InputMaybe<Order_By>;
  trade_url?: InputMaybe<Order_By>;
  user_contact?: InputMaybe<User_Contacts_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_push_tokens_aggregate?: InputMaybe<User_Push_Tokens_Aggregate_Order_By>;
  user_setting?: InputMaybe<User_Settings_Order_By>;
  user_statistic?: InputMaybe<User_Statistic_Order_By>;
  vk_full_name?: InputMaybe<Order_By>;
  vk_id?: InputMaybe<Order_By>;
  vk_nickname?: InputMaybe<Order_By>;
  vk_profile_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_profile */
export type User_Profile_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_profile" */
export enum User_Profile_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  AvatarFull = 'avatar_full',
  /** column name */
  AvatarMedium = 'avatar_medium',
  /** column name */
  BotUser = 'bot_user',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  GoogleFullName = 'google_full_name',
  /** column name */
  GoogleId = 'google_id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  PersonaName = 'persona_name',
  /** column name */
  ProfileUrl = 'profile_url',
  /** column name */
  SteamId = 'steam_id',
  /** column name */
  SteamIdStr = 'steam_id_str',
  /** column name */
  TermsAccepted = 'terms_accepted',
  /** column name */
  TradeUrl = 'trade_url',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VkFullName = 'vk_full_name',
  /** column name */
  VkId = 'vk_id',
  /** column name */
  VkNickname = 'vk_nickname',
  /** column name */
  VkProfileUrl = 'vk_profile_url'
}

/** input type for updating data in table "user_profile" */
export type User_Profile_Set_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  avatar_full?: InputMaybe<Scalars['String']>;
  avatar_medium?: InputMaybe<Scalars['String']>;
  bot_user?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  google_full_name?: InputMaybe<Scalars['String']>;
  google_id?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  persona_name?: InputMaybe<Scalars['String']>;
  profile_url?: InputMaybe<Scalars['String']>;
  steam_id?: InputMaybe<Scalars['bigint']>;
  steam_id_str?: InputMaybe<Scalars['String']>;
  terms_accepted?: InputMaybe<Scalars['Boolean']>;
  trade_url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vk_full_name?: InputMaybe<Scalars['String']>;
  vk_id?: InputMaybe<Scalars['String']>;
  vk_nickname?: InputMaybe<Scalars['String']>;
  vk_profile_url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Profile_Stddev_Fields = {
  __typename?: 'user_profile_stddev_fields';
  steam_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Profile_Stddev_Pop_Fields = {
  __typename?: 'user_profile_stddev_pop_fields';
  steam_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Profile_Stddev_Samp_Fields = {
  __typename?: 'user_profile_stddev_samp_fields';
  steam_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_profile" */
export type User_Profile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Profile_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  avatar_full?: InputMaybe<Scalars['String']>;
  avatar_medium?: InputMaybe<Scalars['String']>;
  bot_user?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  google_full_name?: InputMaybe<Scalars['String']>;
  google_id?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  persona_name?: InputMaybe<Scalars['String']>;
  profile_url?: InputMaybe<Scalars['String']>;
  steam_id?: InputMaybe<Scalars['bigint']>;
  steam_id_str?: InputMaybe<Scalars['String']>;
  terms_accepted?: InputMaybe<Scalars['Boolean']>;
  trade_url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vk_full_name?: InputMaybe<Scalars['String']>;
  vk_id?: InputMaybe<Scalars['String']>;
  vk_nickname?: InputMaybe<Scalars['String']>;
  vk_profile_url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Profile_Sum_Fields = {
  __typename?: 'user_profile_sum_fields';
  steam_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user_profile" */
export enum User_Profile_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  AvatarFull = 'avatar_full',
  /** column name */
  AvatarMedium = 'avatar_medium',
  /** column name */
  BotUser = 'bot_user',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  GoogleFullName = 'google_full_name',
  /** column name */
  GoogleId = 'google_id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  PersonaName = 'persona_name',
  /** column name */
  ProfileUrl = 'profile_url',
  /** column name */
  SteamId = 'steam_id',
  /** column name */
  SteamIdStr = 'steam_id_str',
  /** column name */
  TermsAccepted = 'terms_accepted',
  /** column name */
  TradeUrl = 'trade_url',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VkFullName = 'vk_full_name',
  /** column name */
  VkId = 'vk_id',
  /** column name */
  VkNickname = 'vk_nickname',
  /** column name */
  VkProfileUrl = 'vk_profile_url'
}

export type User_Profile_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Profile_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Profile_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Profile_Var_Pop_Fields = {
  __typename?: 'user_profile_var_pop_fields';
  steam_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Profile_Var_Samp_Fields = {
  __typename?: 'user_profile_var_samp_fields';
  steam_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Profile_Variance_Fields = {
  __typename?: 'user_profile_variance_fields';
  steam_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_push_tokens" */
export type User_Push_Tokens = {
  __typename?: 'user_push_tokens';
  created_at: Scalars['timestamp'];
  is_active: Scalars['Boolean'];
  push_token: Scalars['String'];
  token_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
  user_agent?: Maybe<Scalars['String']>;
  user_id: Scalars['uuid'];
  /** An object relationship */
  user_profile: User_Profile;
};

/** aggregated selection of "user_push_tokens" */
export type User_Push_Tokens_Aggregate = {
  __typename?: 'user_push_tokens_aggregate';
  aggregate?: Maybe<User_Push_Tokens_Aggregate_Fields>;
  nodes: Array<User_Push_Tokens>;
};

export type User_Push_Tokens_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Push_Tokens_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Push_Tokens_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Push_Tokens_Aggregate_Bool_Exp_Count>;
};

export type User_Push_Tokens_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Push_Tokens_Select_Column_User_Push_Tokens_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Push_Tokens_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Push_Tokens_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Push_Tokens_Select_Column_User_Push_Tokens_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Push_Tokens_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Push_Tokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Push_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Push_Tokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_push_tokens" */
export type User_Push_Tokens_Aggregate_Fields = {
  __typename?: 'user_push_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Push_Tokens_Max_Fields>;
  min?: Maybe<User_Push_Tokens_Min_Fields>;
};


/** aggregate fields of "user_push_tokens" */
export type User_Push_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Push_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_push_tokens" */
export type User_Push_Tokens_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Push_Tokens_Max_Order_By>;
  min?: InputMaybe<User_Push_Tokens_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_push_tokens" */
export type User_Push_Tokens_Arr_Rel_Insert_Input = {
  data: Array<User_Push_Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Push_Tokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_push_tokens". All fields are combined with a logical 'AND'. */
export type User_Push_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<User_Push_Tokens_Bool_Exp>>;
  _not?: InputMaybe<User_Push_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<User_Push_Tokens_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  push_token?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_agent?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_profile?: InputMaybe<User_Profile_Bool_Exp>;
};

/** unique or primary key constraints on table "user_push_tokens" */
export enum User_Push_Tokens_Constraint {
  /** unique or primary key constraint on columns "token_id" */
  UserPushTokensPkey = 'user_push_tokens_pkey',
  /** unique or primary key constraint on columns "push_token" */
  UserPushTokensPushTokenKey = 'user_push_tokens_push_token_key'
}

/** input type for inserting data into table "user_push_tokens" */
export type User_Push_Tokens_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  push_token?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_profile?: InputMaybe<User_Profile_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Push_Tokens_Max_Fields = {
  __typename?: 'user_push_tokens_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  push_token?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_agent?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_push_tokens" */
export type User_Push_Tokens_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  push_token?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_agent?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Push_Tokens_Min_Fields = {
  __typename?: 'user_push_tokens_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  push_token?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_agent?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_push_tokens" */
export type User_Push_Tokens_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  push_token?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_agent?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_push_tokens" */
export type User_Push_Tokens_Mutation_Response = {
  __typename?: 'user_push_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Push_Tokens>;
};

/** on_conflict condition type for table "user_push_tokens" */
export type User_Push_Tokens_On_Conflict = {
  constraint: User_Push_Tokens_Constraint;
  update_columns?: Array<User_Push_Tokens_Update_Column>;
  where?: InputMaybe<User_Push_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "user_push_tokens". */
export type User_Push_Tokens_Order_By = {
  created_at?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  push_token?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_agent?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_profile?: InputMaybe<User_Profile_Order_By>;
};

/** primary key columns input for table: user_push_tokens */
export type User_Push_Tokens_Pk_Columns_Input = {
  token_id: Scalars['uuid'];
};

/** select columns of table "user_push_tokens" */
export enum User_Push_Tokens_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PushToken = 'push_token',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserAgent = 'user_agent',
  /** column name */
  UserId = 'user_id'
}

/** select "user_push_tokens_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_push_tokens" */
export enum User_Push_Tokens_Select_Column_User_Push_Tokens_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active'
}

/** select "user_push_tokens_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_push_tokens" */
export enum User_Push_Tokens_Select_Column_User_Push_Tokens_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active'
}

/** input type for updating data in table "user_push_tokens" */
export type User_Push_Tokens_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  push_token?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_push_tokens" */
export type User_Push_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Push_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Push_Tokens_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  push_token?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_agent?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_push_tokens" */
export enum User_Push_Tokens_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  PushToken = 'push_token',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserAgent = 'user_agent',
  /** column name */
  UserId = 'user_id'
}

export type User_Push_Tokens_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Push_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Push_Tokens_Bool_Exp;
};

/** columns and relationships of "user_push_topics" */
export type User_Push_Topics = {
  __typename?: 'user_push_topics';
  public: Scalars['Boolean'];
  topic_description?: Maybe<Scalars['String']>;
  topic_id: Scalars['uuid'];
  topic_label: Scalars['String'];
  topic_name: Scalars['String'];
};

/** aggregated selection of "user_push_topics" */
export type User_Push_Topics_Aggregate = {
  __typename?: 'user_push_topics_aggregate';
  aggregate?: Maybe<User_Push_Topics_Aggregate_Fields>;
  nodes: Array<User_Push_Topics>;
};

/** aggregate fields of "user_push_topics" */
export type User_Push_Topics_Aggregate_Fields = {
  __typename?: 'user_push_topics_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Push_Topics_Max_Fields>;
  min?: Maybe<User_Push_Topics_Min_Fields>;
};


/** aggregate fields of "user_push_topics" */
export type User_Push_Topics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Push_Topics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_push_topics". All fields are combined with a logical 'AND'. */
export type User_Push_Topics_Bool_Exp = {
  _and?: InputMaybe<Array<User_Push_Topics_Bool_Exp>>;
  _not?: InputMaybe<User_Push_Topics_Bool_Exp>;
  _or?: InputMaybe<Array<User_Push_Topics_Bool_Exp>>;
  public?: InputMaybe<Boolean_Comparison_Exp>;
  topic_description?: InputMaybe<String_Comparison_Exp>;
  topic_id?: InputMaybe<Uuid_Comparison_Exp>;
  topic_label?: InputMaybe<String_Comparison_Exp>;
  topic_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_push_topics" */
export enum User_Push_Topics_Constraint {
  /** unique or primary key constraint on columns "topic_id" */
  UserPushTopicsPkey = 'user_push_topics_pkey',
  /** unique or primary key constraint on columns "topic_label" */
  UserPushTopicsTopicLabelKey = 'user_push_topics_topic_label_key'
}

/** input type for inserting data into table "user_push_topics" */
export type User_Push_Topics_Insert_Input = {
  public?: InputMaybe<Scalars['Boolean']>;
  topic_description?: InputMaybe<Scalars['String']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
  topic_label?: InputMaybe<Scalars['String']>;
  topic_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Push_Topics_Max_Fields = {
  __typename?: 'user_push_topics_max_fields';
  topic_description?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['uuid']>;
  topic_label?: Maybe<Scalars['String']>;
  topic_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Push_Topics_Min_Fields = {
  __typename?: 'user_push_topics_min_fields';
  topic_description?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['uuid']>;
  topic_label?: Maybe<Scalars['String']>;
  topic_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_push_topics" */
export type User_Push_Topics_Mutation_Response = {
  __typename?: 'user_push_topics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Push_Topics>;
};

/** on_conflict condition type for table "user_push_topics" */
export type User_Push_Topics_On_Conflict = {
  constraint: User_Push_Topics_Constraint;
  update_columns?: Array<User_Push_Topics_Update_Column>;
  where?: InputMaybe<User_Push_Topics_Bool_Exp>;
};

/** Ordering options when selecting data from "user_push_topics". */
export type User_Push_Topics_Order_By = {
  public?: InputMaybe<Order_By>;
  topic_description?: InputMaybe<Order_By>;
  topic_id?: InputMaybe<Order_By>;
  topic_label?: InputMaybe<Order_By>;
  topic_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_push_topics */
export type User_Push_Topics_Pk_Columns_Input = {
  topic_id: Scalars['uuid'];
};

/** select columns of table "user_push_topics" */
export enum User_Push_Topics_Select_Column {
  /** column name */
  Public = 'public',
  /** column name */
  TopicDescription = 'topic_description',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  TopicLabel = 'topic_label',
  /** column name */
  TopicName = 'topic_name'
}

/** input type for updating data in table "user_push_topics" */
export type User_Push_Topics_Set_Input = {
  public?: InputMaybe<Scalars['Boolean']>;
  topic_description?: InputMaybe<Scalars['String']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
  topic_label?: InputMaybe<Scalars['String']>;
  topic_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_push_topics" */
export type User_Push_Topics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Push_Topics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Push_Topics_Stream_Cursor_Value_Input = {
  public?: InputMaybe<Scalars['Boolean']>;
  topic_description?: InputMaybe<Scalars['String']>;
  topic_id?: InputMaybe<Scalars['uuid']>;
  topic_label?: InputMaybe<Scalars['String']>;
  topic_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_push_topics" */
export enum User_Push_Topics_Update_Column {
  /** column name */
  Public = 'public',
  /** column name */
  TopicDescription = 'topic_description',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  TopicLabel = 'topic_label',
  /** column name */
  TopicName = 'topic_name'
}

export type User_Push_Topics_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Push_Topics_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Push_Topics_Bool_Exp;
};

/** columns and relationships of "user_referal_levels_params" */
export type User_Referal_Levels_Params = {
  __typename?: 'user_referal_levels_params';
  deposits_amount: Scalars['Int'];
  deposits_amount_currency: Scalars['String'];
  min_output_amount: Scalars['Int'];
  min_output_amount_currency: Scalars['String'];
  owner_bonus_percent: Scalars['Int'];
  referal_bonus_amount: Scalars['Int'];
  referal_bonus_min_deposit: Scalars['Int'];
  referal_bonus_min_deposit_currency: Scalars['String'];
  referal_bonus_percent: Scalars['Int'];
  referal_bonus_type: Scalars['String'];
  referal_level: Scalars['Int'];
};

/** aggregated selection of "user_referal_levels_params" */
export type User_Referal_Levels_Params_Aggregate = {
  __typename?: 'user_referal_levels_params_aggregate';
  aggregate?: Maybe<User_Referal_Levels_Params_Aggregate_Fields>;
  nodes: Array<User_Referal_Levels_Params>;
};

/** aggregate fields of "user_referal_levels_params" */
export type User_Referal_Levels_Params_Aggregate_Fields = {
  __typename?: 'user_referal_levels_params_aggregate_fields';
  avg?: Maybe<User_Referal_Levels_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Referal_Levels_Params_Max_Fields>;
  min?: Maybe<User_Referal_Levels_Params_Min_Fields>;
  stddev?: Maybe<User_Referal_Levels_Params_Stddev_Fields>;
  stddev_pop?: Maybe<User_Referal_Levels_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Referal_Levels_Params_Stddev_Samp_Fields>;
  sum?: Maybe<User_Referal_Levels_Params_Sum_Fields>;
  var_pop?: Maybe<User_Referal_Levels_Params_Var_Pop_Fields>;
  var_samp?: Maybe<User_Referal_Levels_Params_Var_Samp_Fields>;
  variance?: Maybe<User_Referal_Levels_Params_Variance_Fields>;
};


/** aggregate fields of "user_referal_levels_params" */
export type User_Referal_Levels_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referal_Levels_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Referal_Levels_Params_Avg_Fields = {
  __typename?: 'user_referal_levels_params_avg_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  min_output_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referal_bonus_amount?: Maybe<Scalars['Float']>;
  referal_bonus_min_deposit?: Maybe<Scalars['Float']>;
  referal_bonus_percent?: Maybe<Scalars['Float']>;
  referal_level?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_referal_levels_params". All fields are combined with a logical 'AND'. */
export type User_Referal_Levels_Params_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referal_Levels_Params_Bool_Exp>>;
  _not?: InputMaybe<User_Referal_Levels_Params_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referal_Levels_Params_Bool_Exp>>;
  deposits_amount?: InputMaybe<Int_Comparison_Exp>;
  deposits_amount_currency?: InputMaybe<String_Comparison_Exp>;
  min_output_amount?: InputMaybe<Int_Comparison_Exp>;
  min_output_amount_currency?: InputMaybe<String_Comparison_Exp>;
  owner_bonus_percent?: InputMaybe<Int_Comparison_Exp>;
  referal_bonus_amount?: InputMaybe<Int_Comparison_Exp>;
  referal_bonus_min_deposit?: InputMaybe<Int_Comparison_Exp>;
  referal_bonus_min_deposit_currency?: InputMaybe<String_Comparison_Exp>;
  referal_bonus_percent?: InputMaybe<Int_Comparison_Exp>;
  referal_bonus_type?: InputMaybe<String_Comparison_Exp>;
  referal_level?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_referal_levels_params" */
export enum User_Referal_Levels_Params_Constraint {
  /** unique or primary key constraint on columns "referal_level" */
  UserReferalLevelsParamsPkey = 'user_referal_levels_params_pkey'
}

/** input type for incrementing numeric columns in table "user_referal_levels_params" */
export type User_Referal_Levels_Params_Inc_Input = {
  deposits_amount?: InputMaybe<Scalars['Int']>;
  min_output_amount?: InputMaybe<Scalars['Int']>;
  owner_bonus_percent?: InputMaybe<Scalars['Int']>;
  referal_bonus_amount?: InputMaybe<Scalars['Int']>;
  referal_bonus_min_deposit?: InputMaybe<Scalars['Int']>;
  referal_bonus_percent?: InputMaybe<Scalars['Int']>;
  referal_level?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_referal_levels_params" */
export type User_Referal_Levels_Params_Insert_Input = {
  deposits_amount?: InputMaybe<Scalars['Int']>;
  deposits_amount_currency?: InputMaybe<Scalars['String']>;
  min_output_amount?: InputMaybe<Scalars['Int']>;
  min_output_amount_currency?: InputMaybe<Scalars['String']>;
  owner_bonus_percent?: InputMaybe<Scalars['Int']>;
  referal_bonus_amount?: InputMaybe<Scalars['Int']>;
  referal_bonus_min_deposit?: InputMaybe<Scalars['Int']>;
  referal_bonus_min_deposit_currency?: InputMaybe<Scalars['String']>;
  referal_bonus_percent?: InputMaybe<Scalars['Int']>;
  referal_bonus_type?: InputMaybe<Scalars['String']>;
  referal_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type User_Referal_Levels_Params_Max_Fields = {
  __typename?: 'user_referal_levels_params_max_fields';
  deposits_amount?: Maybe<Scalars['Int']>;
  deposits_amount_currency?: Maybe<Scalars['String']>;
  min_output_amount?: Maybe<Scalars['Int']>;
  min_output_amount_currency?: Maybe<Scalars['String']>;
  owner_bonus_percent?: Maybe<Scalars['Int']>;
  referal_bonus_amount?: Maybe<Scalars['Int']>;
  referal_bonus_min_deposit?: Maybe<Scalars['Int']>;
  referal_bonus_min_deposit_currency?: Maybe<Scalars['String']>;
  referal_bonus_percent?: Maybe<Scalars['Int']>;
  referal_bonus_type?: Maybe<Scalars['String']>;
  referal_level?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type User_Referal_Levels_Params_Min_Fields = {
  __typename?: 'user_referal_levels_params_min_fields';
  deposits_amount?: Maybe<Scalars['Int']>;
  deposits_amount_currency?: Maybe<Scalars['String']>;
  min_output_amount?: Maybe<Scalars['Int']>;
  min_output_amount_currency?: Maybe<Scalars['String']>;
  owner_bonus_percent?: Maybe<Scalars['Int']>;
  referal_bonus_amount?: Maybe<Scalars['Int']>;
  referal_bonus_min_deposit?: Maybe<Scalars['Int']>;
  referal_bonus_min_deposit_currency?: Maybe<Scalars['String']>;
  referal_bonus_percent?: Maybe<Scalars['Int']>;
  referal_bonus_type?: Maybe<Scalars['String']>;
  referal_level?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "user_referal_levels_params" */
export type User_Referal_Levels_Params_Mutation_Response = {
  __typename?: 'user_referal_levels_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referal_Levels_Params>;
};

/** on_conflict condition type for table "user_referal_levels_params" */
export type User_Referal_Levels_Params_On_Conflict = {
  constraint: User_Referal_Levels_Params_Constraint;
  update_columns?: Array<User_Referal_Levels_Params_Update_Column>;
  where?: InputMaybe<User_Referal_Levels_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referal_levels_params". */
export type User_Referal_Levels_Params_Order_By = {
  deposits_amount?: InputMaybe<Order_By>;
  deposits_amount_currency?: InputMaybe<Order_By>;
  min_output_amount?: InputMaybe<Order_By>;
  min_output_amount_currency?: InputMaybe<Order_By>;
  owner_bonus_percent?: InputMaybe<Order_By>;
  referal_bonus_amount?: InputMaybe<Order_By>;
  referal_bonus_min_deposit?: InputMaybe<Order_By>;
  referal_bonus_min_deposit_currency?: InputMaybe<Order_By>;
  referal_bonus_percent?: InputMaybe<Order_By>;
  referal_bonus_type?: InputMaybe<Order_By>;
  referal_level?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_referal_levels_params */
export type User_Referal_Levels_Params_Pk_Columns_Input = {
  referal_level: Scalars['Int'];
};

/** select columns of table "user_referal_levels_params" */
export enum User_Referal_Levels_Params_Select_Column {
  /** column name */
  DepositsAmount = 'deposits_amount',
  /** column name */
  DepositsAmountCurrency = 'deposits_amount_currency',
  /** column name */
  MinOutputAmount = 'min_output_amount',
  /** column name */
  MinOutputAmountCurrency = 'min_output_amount_currency',
  /** column name */
  OwnerBonusPercent = 'owner_bonus_percent',
  /** column name */
  ReferalBonusAmount = 'referal_bonus_amount',
  /** column name */
  ReferalBonusMinDeposit = 'referal_bonus_min_deposit',
  /** column name */
  ReferalBonusMinDepositCurrency = 'referal_bonus_min_deposit_currency',
  /** column name */
  ReferalBonusPercent = 'referal_bonus_percent',
  /** column name */
  ReferalBonusType = 'referal_bonus_type',
  /** column name */
  ReferalLevel = 'referal_level'
}

/** input type for updating data in table "user_referal_levels_params" */
export type User_Referal_Levels_Params_Set_Input = {
  deposits_amount?: InputMaybe<Scalars['Int']>;
  deposits_amount_currency?: InputMaybe<Scalars['String']>;
  min_output_amount?: InputMaybe<Scalars['Int']>;
  min_output_amount_currency?: InputMaybe<Scalars['String']>;
  owner_bonus_percent?: InputMaybe<Scalars['Int']>;
  referal_bonus_amount?: InputMaybe<Scalars['Int']>;
  referal_bonus_min_deposit?: InputMaybe<Scalars['Int']>;
  referal_bonus_min_deposit_currency?: InputMaybe<Scalars['String']>;
  referal_bonus_percent?: InputMaybe<Scalars['Int']>;
  referal_bonus_type?: InputMaybe<Scalars['String']>;
  referal_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_Referal_Levels_Params_Stddev_Fields = {
  __typename?: 'user_referal_levels_params_stddev_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  min_output_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referal_bonus_amount?: Maybe<Scalars['Float']>;
  referal_bonus_min_deposit?: Maybe<Scalars['Float']>;
  referal_bonus_percent?: Maybe<Scalars['Float']>;
  referal_level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Referal_Levels_Params_Stddev_Pop_Fields = {
  __typename?: 'user_referal_levels_params_stddev_pop_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  min_output_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referal_bonus_amount?: Maybe<Scalars['Float']>;
  referal_bonus_min_deposit?: Maybe<Scalars['Float']>;
  referal_bonus_percent?: Maybe<Scalars['Float']>;
  referal_level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Referal_Levels_Params_Stddev_Samp_Fields = {
  __typename?: 'user_referal_levels_params_stddev_samp_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  min_output_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referal_bonus_amount?: Maybe<Scalars['Float']>;
  referal_bonus_min_deposit?: Maybe<Scalars['Float']>;
  referal_bonus_percent?: Maybe<Scalars['Float']>;
  referal_level?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_referal_levels_params" */
export type User_Referal_Levels_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referal_Levels_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referal_Levels_Params_Stream_Cursor_Value_Input = {
  deposits_amount?: InputMaybe<Scalars['Int']>;
  deposits_amount_currency?: InputMaybe<Scalars['String']>;
  min_output_amount?: InputMaybe<Scalars['Int']>;
  min_output_amount_currency?: InputMaybe<Scalars['String']>;
  owner_bonus_percent?: InputMaybe<Scalars['Int']>;
  referal_bonus_amount?: InputMaybe<Scalars['Int']>;
  referal_bonus_min_deposit?: InputMaybe<Scalars['Int']>;
  referal_bonus_min_deposit_currency?: InputMaybe<Scalars['String']>;
  referal_bonus_percent?: InputMaybe<Scalars['Int']>;
  referal_bonus_type?: InputMaybe<Scalars['String']>;
  referal_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type User_Referal_Levels_Params_Sum_Fields = {
  __typename?: 'user_referal_levels_params_sum_fields';
  deposits_amount?: Maybe<Scalars['Int']>;
  min_output_amount?: Maybe<Scalars['Int']>;
  owner_bonus_percent?: Maybe<Scalars['Int']>;
  referal_bonus_amount?: Maybe<Scalars['Int']>;
  referal_bonus_min_deposit?: Maybe<Scalars['Int']>;
  referal_bonus_percent?: Maybe<Scalars['Int']>;
  referal_level?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_referal_levels_params" */
export enum User_Referal_Levels_Params_Update_Column {
  /** column name */
  DepositsAmount = 'deposits_amount',
  /** column name */
  DepositsAmountCurrency = 'deposits_amount_currency',
  /** column name */
  MinOutputAmount = 'min_output_amount',
  /** column name */
  MinOutputAmountCurrency = 'min_output_amount_currency',
  /** column name */
  OwnerBonusPercent = 'owner_bonus_percent',
  /** column name */
  ReferalBonusAmount = 'referal_bonus_amount',
  /** column name */
  ReferalBonusMinDeposit = 'referal_bonus_min_deposit',
  /** column name */
  ReferalBonusMinDepositCurrency = 'referal_bonus_min_deposit_currency',
  /** column name */
  ReferalBonusPercent = 'referal_bonus_percent',
  /** column name */
  ReferalBonusType = 'referal_bonus_type',
  /** column name */
  ReferalLevel = 'referal_level'
}

export type User_Referal_Levels_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Referal_Levels_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referal_Levels_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referal_Levels_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Referal_Levels_Params_Var_Pop_Fields = {
  __typename?: 'user_referal_levels_params_var_pop_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  min_output_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referal_bonus_amount?: Maybe<Scalars['Float']>;
  referal_bonus_min_deposit?: Maybe<Scalars['Float']>;
  referal_bonus_percent?: Maybe<Scalars['Float']>;
  referal_level?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Referal_Levels_Params_Var_Samp_Fields = {
  __typename?: 'user_referal_levels_params_var_samp_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  min_output_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referal_bonus_amount?: Maybe<Scalars['Float']>;
  referal_bonus_min_deposit?: Maybe<Scalars['Float']>;
  referal_bonus_percent?: Maybe<Scalars['Float']>;
  referal_level?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Referal_Levels_Params_Variance_Fields = {
  __typename?: 'user_referal_levels_params_variance_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  min_output_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referal_bonus_amount?: Maybe<Scalars['Float']>;
  referal_bonus_min_deposit?: Maybe<Scalars['Float']>;
  referal_bonus_percent?: Maybe<Scalars['Float']>;
  referal_level?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_referal_params" */
export type User_Referal_Params = {
  __typename?: 'user_referal_params';
  referal_balance: Scalars['Int'];
  referal_balance_currency: Scalars['String'];
  referal_link_param: Scalars['String'];
  referals_count: Scalars['Int'];
  referals_deposits_currency: Scalars['String'];
  referals_deposits_sum: Scalars['bigint'];
  user_id: Scalars['uuid'];
  user_profile_data?: Maybe<User_Profile>;
  /** An array relationship */
  user_referal_transactions: Array<User_Referals_Balance_Transactions>;
  /** An aggregate relationship */
  user_referal_transactions_aggregate: User_Referals_Balance_Transactions_Aggregate;
};


/** columns and relationships of "user_referal_params" */
export type User_Referal_ParamsUser_Referal_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Balance_Transactions_Order_By>>;
  where?: InputMaybe<User_Referals_Balance_Transactions_Bool_Exp>;
};


/** columns and relationships of "user_referal_params" */
export type User_Referal_ParamsUser_Referal_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referals_Balance_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referals_Balance_Transactions_Order_By>>;
  where?: InputMaybe<User_Referals_Balance_Transactions_Bool_Exp>;
};

/** aggregated selection of "user_referal_params" */
export type User_Referal_Params_Aggregate = {
  __typename?: 'user_referal_params_aggregate';
  aggregate?: Maybe<User_Referal_Params_Aggregate_Fields>;
  nodes: Array<User_Referal_Params>;
};

/** aggregate fields of "user_referal_params" */
export type User_Referal_Params_Aggregate_Fields = {
  __typename?: 'user_referal_params_aggregate_fields';
  avg?: Maybe<User_Referal_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Referal_Params_Max_Fields>;
  min?: Maybe<User_Referal_Params_Min_Fields>;
  stddev?: Maybe<User_Referal_Params_Stddev_Fields>;
  stddev_pop?: Maybe<User_Referal_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Referal_Params_Stddev_Samp_Fields>;
  sum?: Maybe<User_Referal_Params_Sum_Fields>;
  var_pop?: Maybe<User_Referal_Params_Var_Pop_Fields>;
  var_samp?: Maybe<User_Referal_Params_Var_Samp_Fields>;
  variance?: Maybe<User_Referal_Params_Variance_Fields>;
};


/** aggregate fields of "user_referal_params" */
export type User_Referal_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referal_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Referal_Params_Avg_Fields = {
  __typename?: 'user_referal_params_avg_fields';
  referal_balance?: Maybe<Scalars['Float']>;
  referals_count?: Maybe<Scalars['Float']>;
  referals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_referal_params". All fields are combined with a logical 'AND'. */
export type User_Referal_Params_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referal_Params_Bool_Exp>>;
  _not?: InputMaybe<User_Referal_Params_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referal_Params_Bool_Exp>>;
  referal_balance?: InputMaybe<Int_Comparison_Exp>;
  referal_balance_currency?: InputMaybe<String_Comparison_Exp>;
  referal_link_param?: InputMaybe<String_Comparison_Exp>;
  referals_count?: InputMaybe<Int_Comparison_Exp>;
  referals_deposits_currency?: InputMaybe<String_Comparison_Exp>;
  referals_deposits_sum?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_referal_transactions?: InputMaybe<User_Referals_Balance_Transactions_Bool_Exp>;
  user_referal_transactions_aggregate?: InputMaybe<User_Referals_Balance_Transactions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user_referal_params" */
export enum User_Referal_Params_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserReferalParamsPkey = 'user_referal_params_pkey',
  /** unique or primary key constraint on columns "referal_link_param" */
  UserReferalParamsReferalLinkParamKey = 'user_referal_params_referal_link_param_key'
}

/** input type for incrementing numeric columns in table "user_referal_params" */
export type User_Referal_Params_Inc_Input = {
  referal_balance?: InputMaybe<Scalars['Int']>;
  referals_count?: InputMaybe<Scalars['Int']>;
  referals_deposits_sum?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_referal_params" */
export type User_Referal_Params_Insert_Input = {
  referal_balance?: InputMaybe<Scalars['Int']>;
  referal_balance_currency?: InputMaybe<Scalars['String']>;
  referal_link_param?: InputMaybe<Scalars['String']>;
  referals_count?: InputMaybe<Scalars['Int']>;
  referals_deposits_currency?: InputMaybe<Scalars['String']>;
  referals_deposits_sum?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_referal_transactions?: InputMaybe<User_Referals_Balance_Transactions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Referal_Params_Max_Fields = {
  __typename?: 'user_referal_params_max_fields';
  referal_balance?: Maybe<Scalars['Int']>;
  referal_balance_currency?: Maybe<Scalars['String']>;
  referal_link_param?: Maybe<Scalars['String']>;
  referals_count?: Maybe<Scalars['Int']>;
  referals_deposits_currency?: Maybe<Scalars['String']>;
  referals_deposits_sum?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Referal_Params_Min_Fields = {
  __typename?: 'user_referal_params_min_fields';
  referal_balance?: Maybe<Scalars['Int']>;
  referal_balance_currency?: Maybe<Scalars['String']>;
  referal_link_param?: Maybe<Scalars['String']>;
  referals_count?: Maybe<Scalars['Int']>;
  referals_deposits_currency?: Maybe<Scalars['String']>;
  referals_deposits_sum?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_referal_params" */
export type User_Referal_Params_Mutation_Response = {
  __typename?: 'user_referal_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referal_Params>;
};

/** input type for inserting object relation for remote table "user_referal_params" */
export type User_Referal_Params_Obj_Rel_Insert_Input = {
  data: User_Referal_Params_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Referal_Params_On_Conflict>;
};

/** on_conflict condition type for table "user_referal_params" */
export type User_Referal_Params_On_Conflict = {
  constraint: User_Referal_Params_Constraint;
  update_columns?: Array<User_Referal_Params_Update_Column>;
  where?: InputMaybe<User_Referal_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referal_params". */
export type User_Referal_Params_Order_By = {
  referal_balance?: InputMaybe<Order_By>;
  referal_balance_currency?: InputMaybe<Order_By>;
  referal_link_param?: InputMaybe<Order_By>;
  referals_count?: InputMaybe<Order_By>;
  referals_deposits_currency?: InputMaybe<Order_By>;
  referals_deposits_sum?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_referal_transactions_aggregate?: InputMaybe<User_Referals_Balance_Transactions_Aggregate_Order_By>;
};

/** primary key columns input for table: user_referal_params */
export type User_Referal_Params_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_referal_params" */
export enum User_Referal_Params_Select_Column {
  /** column name */
  ReferalBalance = 'referal_balance',
  /** column name */
  ReferalBalanceCurrency = 'referal_balance_currency',
  /** column name */
  ReferalLinkParam = 'referal_link_param',
  /** column name */
  ReferalsCount = 'referals_count',
  /** column name */
  ReferalsDepositsCurrency = 'referals_deposits_currency',
  /** column name */
  ReferalsDepositsSum = 'referals_deposits_sum',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_referal_params" */
export type User_Referal_Params_Set_Input = {
  referal_balance?: InputMaybe<Scalars['Int']>;
  referal_balance_currency?: InputMaybe<Scalars['String']>;
  referal_link_param?: InputMaybe<Scalars['String']>;
  referals_count?: InputMaybe<Scalars['Int']>;
  referals_deposits_currency?: InputMaybe<Scalars['String']>;
  referals_deposits_sum?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Referal_Params_Stddev_Fields = {
  __typename?: 'user_referal_params_stddev_fields';
  referal_balance?: Maybe<Scalars['Float']>;
  referals_count?: Maybe<Scalars['Float']>;
  referals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Referal_Params_Stddev_Pop_Fields = {
  __typename?: 'user_referal_params_stddev_pop_fields';
  referal_balance?: Maybe<Scalars['Float']>;
  referals_count?: Maybe<Scalars['Float']>;
  referals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Referal_Params_Stddev_Samp_Fields = {
  __typename?: 'user_referal_params_stddev_samp_fields';
  referal_balance?: Maybe<Scalars['Float']>;
  referals_count?: Maybe<Scalars['Float']>;
  referals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_referal_params" */
export type User_Referal_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referal_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referal_Params_Stream_Cursor_Value_Input = {
  referal_balance?: InputMaybe<Scalars['Int']>;
  referal_balance_currency?: InputMaybe<Scalars['String']>;
  referal_link_param?: InputMaybe<Scalars['String']>;
  referals_count?: InputMaybe<Scalars['Int']>;
  referals_deposits_currency?: InputMaybe<Scalars['String']>;
  referals_deposits_sum?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Referal_Params_Sum_Fields = {
  __typename?: 'user_referal_params_sum_fields';
  referal_balance?: Maybe<Scalars['Int']>;
  referals_count?: Maybe<Scalars['Int']>;
  referals_deposits_sum?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user_referal_params" */
export enum User_Referal_Params_Update_Column {
  /** column name */
  ReferalBalance = 'referal_balance',
  /** column name */
  ReferalBalanceCurrency = 'referal_balance_currency',
  /** column name */
  ReferalLinkParam = 'referal_link_param',
  /** column name */
  ReferalsCount = 'referals_count',
  /** column name */
  ReferalsDepositsCurrency = 'referals_deposits_currency',
  /** column name */
  ReferalsDepositsSum = 'referals_deposits_sum',
  /** column name */
  UserId = 'user_id'
}

export type User_Referal_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Referal_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referal_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referal_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Referal_Params_Var_Pop_Fields = {
  __typename?: 'user_referal_params_var_pop_fields';
  referal_balance?: Maybe<Scalars['Float']>;
  referals_count?: Maybe<Scalars['Float']>;
  referals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Referal_Params_Var_Samp_Fields = {
  __typename?: 'user_referal_params_var_samp_fields';
  referal_balance?: Maybe<Scalars['Float']>;
  referals_count?: Maybe<Scalars['Float']>;
  referals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Referal_Params_Variance_Fields = {
  __typename?: 'user_referal_params_variance_fields';
  referal_balance?: Maybe<Scalars['Float']>;
  referals_count?: Maybe<Scalars['Float']>;
  referals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_referals" */
export type User_Referals = {
  __typename?: 'user_referals';
  bonus_amount?: Maybe<Scalars['Int']>;
  bonus_amount_currency?: Maybe<Scalars['String']>;
  bonus_claimed: Scalars['Boolean'];
  created_at: Scalars['timestamp'];
  owner_user_id: Scalars['uuid'];
  referal_user_id: Scalars['uuid'];
  source: Scalars['String'];
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user_referal_params?: Maybe<User_Referal_Params>;
};

/** aggregated selection of "user_referals" */
export type User_Referals_Aggregate = {
  __typename?: 'user_referals_aggregate';
  aggregate?: Maybe<User_Referals_Aggregate_Fields>;
  nodes: Array<User_Referals>;
};

/** aggregate fields of "user_referals" */
export type User_Referals_Aggregate_Fields = {
  __typename?: 'user_referals_aggregate_fields';
  avg?: Maybe<User_Referals_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Referals_Max_Fields>;
  min?: Maybe<User_Referals_Min_Fields>;
  stddev?: Maybe<User_Referals_Stddev_Fields>;
  stddev_pop?: Maybe<User_Referals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Referals_Stddev_Samp_Fields>;
  sum?: Maybe<User_Referals_Sum_Fields>;
  var_pop?: Maybe<User_Referals_Var_Pop_Fields>;
  var_samp?: Maybe<User_Referals_Var_Samp_Fields>;
  variance?: Maybe<User_Referals_Variance_Fields>;
};


/** aggregate fields of "user_referals" */
export type User_Referals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Referals_Avg_Fields = {
  __typename?: 'user_referals_avg_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions = {
  __typename?: 'user_referals_balance_transactions';
  additional_data?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamp'];
  target_type: Scalars['String'];
  transaction_amount: Scalars['Int'];
  transaction_amount_currency: Scalars['String'];
  transaction_id: Scalars['uuid'];
  transaction_type: Scalars['Int'];
  updated_at: Scalars['timestamp'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "user_referals_balance_transactions" */
export type User_Referals_Balance_TransactionsAdditional_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Aggregate = {
  __typename?: 'user_referals_balance_transactions_aggregate';
  aggregate?: Maybe<User_Referals_Balance_Transactions_Aggregate_Fields>;
  nodes: Array<User_Referals_Balance_Transactions>;
};

export type User_Referals_Balance_Transactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Referals_Balance_Transactions_Aggregate_Bool_Exp_Count>;
};

export type User_Referals_Balance_Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Referals_Balance_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Referals_Balance_Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Aggregate_Fields = {
  __typename?: 'user_referals_balance_transactions_aggregate_fields';
  avg?: Maybe<User_Referals_Balance_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Referals_Balance_Transactions_Max_Fields>;
  min?: Maybe<User_Referals_Balance_Transactions_Min_Fields>;
  stddev?: Maybe<User_Referals_Balance_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<User_Referals_Balance_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Referals_Balance_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<User_Referals_Balance_Transactions_Sum_Fields>;
  var_pop?: Maybe<User_Referals_Balance_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<User_Referals_Balance_Transactions_Var_Samp_Fields>;
  variance?: Maybe<User_Referals_Balance_Transactions_Variance_Fields>;
};


/** aggregate fields of "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referals_Balance_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Aggregate_Order_By = {
  avg?: InputMaybe<User_Referals_Balance_Transactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Referals_Balance_Transactions_Max_Order_By>;
  min?: InputMaybe<User_Referals_Balance_Transactions_Min_Order_By>;
  stddev?: InputMaybe<User_Referals_Balance_Transactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Referals_Balance_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Referals_Balance_Transactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Referals_Balance_Transactions_Sum_Order_By>;
  var_pop?: InputMaybe<User_Referals_Balance_Transactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Referals_Balance_Transactions_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Referals_Balance_Transactions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Referals_Balance_Transactions_Append_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Arr_Rel_Insert_Input = {
  data: Array<User_Referals_Balance_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Referals_Balance_Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Referals_Balance_Transactions_Avg_Fields = {
  __typename?: 'user_referals_balance_transactions_avg_fields';
  transaction_amount?: Maybe<Scalars['Float']>;
  transaction_type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Avg_Order_By = {
  transaction_amount?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_referals_balance_transactions". All fields are combined with a logical 'AND'. */
export type User_Referals_Balance_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referals_Balance_Transactions_Bool_Exp>>;
  _not?: InputMaybe<User_Referals_Balance_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referals_Balance_Transactions_Bool_Exp>>;
  additional_data?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  target_type?: InputMaybe<String_Comparison_Exp>;
  transaction_amount?: InputMaybe<Int_Comparison_Exp>;
  transaction_amount_currency?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  transaction_type?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_referals_balance_transactions" */
export enum User_Referals_Balance_Transactions_Constraint {
  /** unique or primary key constraint on columns "transaction_id" */
  UserReferalsBalanceTransactionsPkey = 'user_referals_balance_transactions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Referals_Balance_Transactions_Delete_At_Path_Input = {
  additional_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Referals_Balance_Transactions_Delete_Elem_Input = {
  additional_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Referals_Balance_Transactions_Delete_Key_Input = {
  additional_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Inc_Input = {
  transaction_amount?: InputMaybe<Scalars['Int']>;
  transaction_type?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Insert_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  target_type?: InputMaybe<Scalars['String']>;
  transaction_amount?: InputMaybe<Scalars['Int']>;
  transaction_amount_currency?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  transaction_type?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Referals_Balance_Transactions_Max_Fields = {
  __typename?: 'user_referals_balance_transactions_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  target_type?: Maybe<Scalars['String']>;
  transaction_amount?: Maybe<Scalars['Int']>;
  transaction_amount_currency?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  transaction_type?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
  transaction_amount_currency?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Referals_Balance_Transactions_Min_Fields = {
  __typename?: 'user_referals_balance_transactions_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  target_type?: Maybe<Scalars['String']>;
  transaction_amount?: Maybe<Scalars['Int']>;
  transaction_amount_currency?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  transaction_type?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
  transaction_amount_currency?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Mutation_Response = {
  __typename?: 'user_referals_balance_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referals_Balance_Transactions>;
};

/** on_conflict condition type for table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_On_Conflict = {
  constraint: User_Referals_Balance_Transactions_Constraint;
  update_columns?: Array<User_Referals_Balance_Transactions_Update_Column>;
  where?: InputMaybe<User_Referals_Balance_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referals_balance_transactions". */
export type User_Referals_Balance_Transactions_Order_By = {
  additional_data?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
  transaction_amount_currency?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_referals_balance_transactions */
export type User_Referals_Balance_Transactions_Pk_Columns_Input = {
  transaction_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Referals_Balance_Transactions_Prepend_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_referals_balance_transactions" */
export enum User_Referals_Balance_Transactions_Select_Column {
  /** column name */
  AdditionalData = 'additional_data',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  TransactionAmount = 'transaction_amount',
  /** column name */
  TransactionAmountCurrency = 'transaction_amount_currency',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Set_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  target_type?: InputMaybe<Scalars['String']>;
  transaction_amount?: InputMaybe<Scalars['Int']>;
  transaction_amount_currency?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  transaction_type?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Referals_Balance_Transactions_Stddev_Fields = {
  __typename?: 'user_referals_balance_transactions_stddev_fields';
  transaction_amount?: Maybe<Scalars['Float']>;
  transaction_type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Stddev_Order_By = {
  transaction_amount?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Referals_Balance_Transactions_Stddev_Pop_Fields = {
  __typename?: 'user_referals_balance_transactions_stddev_pop_fields';
  transaction_amount?: Maybe<Scalars['Float']>;
  transaction_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Stddev_Pop_Order_By = {
  transaction_amount?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Referals_Balance_Transactions_Stddev_Samp_Fields = {
  __typename?: 'user_referals_balance_transactions_stddev_samp_fields';
  transaction_amount?: Maybe<Scalars['Float']>;
  transaction_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Stddev_Samp_Order_By = {
  transaction_amount?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referals_Balance_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referals_Balance_Transactions_Stream_Cursor_Value_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  target_type?: InputMaybe<Scalars['String']>;
  transaction_amount?: InputMaybe<Scalars['Int']>;
  transaction_amount_currency?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  transaction_type?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Referals_Balance_Transactions_Sum_Fields = {
  __typename?: 'user_referals_balance_transactions_sum_fields';
  transaction_amount?: Maybe<Scalars['Int']>;
  transaction_type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Sum_Order_By = {
  transaction_amount?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
};

/** update columns of table "user_referals_balance_transactions" */
export enum User_Referals_Balance_Transactions_Update_Column {
  /** column name */
  AdditionalData = 'additional_data',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  TransactionAmount = 'transaction_amount',
  /** column name */
  TransactionAmountCurrency = 'transaction_amount_currency',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Referals_Balance_Transactions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Referals_Balance_Transactions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Referals_Balance_Transactions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Referals_Balance_Transactions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Referals_Balance_Transactions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Referals_Balance_Transactions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Referals_Balance_Transactions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referals_Balance_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referals_Balance_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Referals_Balance_Transactions_Var_Pop_Fields = {
  __typename?: 'user_referals_balance_transactions_var_pop_fields';
  transaction_amount?: Maybe<Scalars['Float']>;
  transaction_type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Var_Pop_Order_By = {
  transaction_amount?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Referals_Balance_Transactions_Var_Samp_Fields = {
  __typename?: 'user_referals_balance_transactions_var_samp_fields';
  transaction_amount?: Maybe<Scalars['Float']>;
  transaction_type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Var_Samp_Order_By = {
  transaction_amount?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Referals_Balance_Transactions_Variance_Fields = {
  __typename?: 'user_referals_balance_transactions_variance_fields';
  transaction_amount?: Maybe<Scalars['Float']>;
  transaction_type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_referals_balance_transactions" */
export type User_Referals_Balance_Transactions_Variance_Order_By = {
  transaction_amount?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_referals_bonus_history" */
export type User_Referals_Bonus_History = {
  __typename?: 'user_referals_bonus_history';
  bonus_amount: Scalars['Int'];
  bonus_amount_currency: Scalars['String'];
  created_at: Scalars['timestamp'];
  depost_amount: Scalars['Int'];
  depost_amount_currency: Scalars['String'];
  hisoty_id: Scalars['uuid'];
  owner_user_id: Scalars['uuid'];
  referal_user_id: Scalars['uuid'];
};

/** aggregated selection of "user_referals_bonus_history" */
export type User_Referals_Bonus_History_Aggregate = {
  __typename?: 'user_referals_bonus_history_aggregate';
  aggregate?: Maybe<User_Referals_Bonus_History_Aggregate_Fields>;
  nodes: Array<User_Referals_Bonus_History>;
};

/** aggregate fields of "user_referals_bonus_history" */
export type User_Referals_Bonus_History_Aggregate_Fields = {
  __typename?: 'user_referals_bonus_history_aggregate_fields';
  avg?: Maybe<User_Referals_Bonus_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Referals_Bonus_History_Max_Fields>;
  min?: Maybe<User_Referals_Bonus_History_Min_Fields>;
  stddev?: Maybe<User_Referals_Bonus_History_Stddev_Fields>;
  stddev_pop?: Maybe<User_Referals_Bonus_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Referals_Bonus_History_Stddev_Samp_Fields>;
  sum?: Maybe<User_Referals_Bonus_History_Sum_Fields>;
  var_pop?: Maybe<User_Referals_Bonus_History_Var_Pop_Fields>;
  var_samp?: Maybe<User_Referals_Bonus_History_Var_Samp_Fields>;
  variance?: Maybe<User_Referals_Bonus_History_Variance_Fields>;
};


/** aggregate fields of "user_referals_bonus_history" */
export type User_Referals_Bonus_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referals_Bonus_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Referals_Bonus_History_Avg_Fields = {
  __typename?: 'user_referals_bonus_history_avg_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
  depost_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_referals_bonus_history". All fields are combined with a logical 'AND'. */
export type User_Referals_Bonus_History_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referals_Bonus_History_Bool_Exp>>;
  _not?: InputMaybe<User_Referals_Bonus_History_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referals_Bonus_History_Bool_Exp>>;
  bonus_amount?: InputMaybe<Int_Comparison_Exp>;
  bonus_amount_currency?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  depost_amount?: InputMaybe<Int_Comparison_Exp>;
  depost_amount_currency?: InputMaybe<String_Comparison_Exp>;
  hisoty_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  referal_user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_referals_bonus_history" */
export enum User_Referals_Bonus_History_Constraint {
  /** unique or primary key constraint on columns "hisoty_id" */
  UserReferalsBonusHistoryPkey = 'user_referals_bonus_history_pkey'
}

/** input type for incrementing numeric columns in table "user_referals_bonus_history" */
export type User_Referals_Bonus_History_Inc_Input = {
  bonus_amount?: InputMaybe<Scalars['Int']>;
  depost_amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_referals_bonus_history" */
export type User_Referals_Bonus_History_Insert_Input = {
  bonus_amount?: InputMaybe<Scalars['Int']>;
  bonus_amount_currency?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  depost_amount?: InputMaybe<Scalars['Int']>;
  depost_amount_currency?: InputMaybe<Scalars['String']>;
  hisoty_id?: InputMaybe<Scalars['uuid']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referal_user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Referals_Bonus_History_Max_Fields = {
  __typename?: 'user_referals_bonus_history_max_fields';
  bonus_amount?: Maybe<Scalars['Int']>;
  bonus_amount_currency?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  depost_amount?: Maybe<Scalars['Int']>;
  depost_amount_currency?: Maybe<Scalars['String']>;
  hisoty_id?: Maybe<Scalars['uuid']>;
  owner_user_id?: Maybe<Scalars['uuid']>;
  referal_user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Referals_Bonus_History_Min_Fields = {
  __typename?: 'user_referals_bonus_history_min_fields';
  bonus_amount?: Maybe<Scalars['Int']>;
  bonus_amount_currency?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  depost_amount?: Maybe<Scalars['Int']>;
  depost_amount_currency?: Maybe<Scalars['String']>;
  hisoty_id?: Maybe<Scalars['uuid']>;
  owner_user_id?: Maybe<Scalars['uuid']>;
  referal_user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_referals_bonus_history" */
export type User_Referals_Bonus_History_Mutation_Response = {
  __typename?: 'user_referals_bonus_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referals_Bonus_History>;
};

/** on_conflict condition type for table "user_referals_bonus_history" */
export type User_Referals_Bonus_History_On_Conflict = {
  constraint: User_Referals_Bonus_History_Constraint;
  update_columns?: Array<User_Referals_Bonus_History_Update_Column>;
  where?: InputMaybe<User_Referals_Bonus_History_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referals_bonus_history". */
export type User_Referals_Bonus_History_Order_By = {
  bonus_amount?: InputMaybe<Order_By>;
  bonus_amount_currency?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  depost_amount?: InputMaybe<Order_By>;
  depost_amount_currency?: InputMaybe<Order_By>;
  hisoty_id?: InputMaybe<Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  referal_user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_referals_bonus_history */
export type User_Referals_Bonus_History_Pk_Columns_Input = {
  hisoty_id: Scalars['uuid'];
};

/** select columns of table "user_referals_bonus_history" */
export enum User_Referals_Bonus_History_Select_Column {
  /** column name */
  BonusAmount = 'bonus_amount',
  /** column name */
  BonusAmountCurrency = 'bonus_amount_currency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepostAmount = 'depost_amount',
  /** column name */
  DepostAmountCurrency = 'depost_amount_currency',
  /** column name */
  HisotyId = 'hisoty_id',
  /** column name */
  OwnerUserId = 'owner_user_id',
  /** column name */
  ReferalUserId = 'referal_user_id'
}

/** input type for updating data in table "user_referals_bonus_history" */
export type User_Referals_Bonus_History_Set_Input = {
  bonus_amount?: InputMaybe<Scalars['Int']>;
  bonus_amount_currency?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  depost_amount?: InputMaybe<Scalars['Int']>;
  depost_amount_currency?: InputMaybe<Scalars['String']>;
  hisoty_id?: InputMaybe<Scalars['uuid']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referal_user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Referals_Bonus_History_Stddev_Fields = {
  __typename?: 'user_referals_bonus_history_stddev_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
  depost_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Referals_Bonus_History_Stddev_Pop_Fields = {
  __typename?: 'user_referals_bonus_history_stddev_pop_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
  depost_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Referals_Bonus_History_Stddev_Samp_Fields = {
  __typename?: 'user_referals_bonus_history_stddev_samp_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
  depost_amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_referals_bonus_history" */
export type User_Referals_Bonus_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referals_Bonus_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referals_Bonus_History_Stream_Cursor_Value_Input = {
  bonus_amount?: InputMaybe<Scalars['Int']>;
  bonus_amount_currency?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  depost_amount?: InputMaybe<Scalars['Int']>;
  depost_amount_currency?: InputMaybe<Scalars['String']>;
  hisoty_id?: InputMaybe<Scalars['uuid']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referal_user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Referals_Bonus_History_Sum_Fields = {
  __typename?: 'user_referals_bonus_history_sum_fields';
  bonus_amount?: Maybe<Scalars['Int']>;
  depost_amount?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_referals_bonus_history" */
export enum User_Referals_Bonus_History_Update_Column {
  /** column name */
  BonusAmount = 'bonus_amount',
  /** column name */
  BonusAmountCurrency = 'bonus_amount_currency',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DepostAmount = 'depost_amount',
  /** column name */
  DepostAmountCurrency = 'depost_amount_currency',
  /** column name */
  HisotyId = 'hisoty_id',
  /** column name */
  OwnerUserId = 'owner_user_id',
  /** column name */
  ReferalUserId = 'referal_user_id'
}

export type User_Referals_Bonus_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Referals_Bonus_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referals_Bonus_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referals_Bonus_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Referals_Bonus_History_Var_Pop_Fields = {
  __typename?: 'user_referals_bonus_history_var_pop_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
  depost_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Referals_Bonus_History_Var_Samp_Fields = {
  __typename?: 'user_referals_bonus_history_var_samp_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
  depost_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Referals_Bonus_History_Variance_Fields = {
  __typename?: 'user_referals_bonus_history_variance_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
  depost_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_referals". All fields are combined with a logical 'AND'. */
export type User_Referals_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referals_Bool_Exp>>;
  _not?: InputMaybe<User_Referals_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referals_Bool_Exp>>;
  bonus_amount?: InputMaybe<Int_Comparison_Exp>;
  bonus_amount_currency?: InputMaybe<String_Comparison_Exp>;
  bonus_claimed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  owner_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  referal_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_referal_params?: InputMaybe<User_Referal_Params_Bool_Exp>;
};

/** unique or primary key constraints on table "user_referals" */
export enum User_Referals_Constraint {
  /** unique or primary key constraint on columns "owner_user_id", "referal_user_id" */
  UserReferalsPkey = 'user_referals_pkey'
}

/** input type for incrementing numeric columns in table "user_referals" */
export type User_Referals_Inc_Input = {
  bonus_amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_referals" */
export type User_Referals_Insert_Input = {
  bonus_amount?: InputMaybe<Scalars['Int']>;
  bonus_amount_currency?: InputMaybe<Scalars['String']>;
  bonus_claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referal_user_id?: InputMaybe<Scalars['uuid']>;
  source?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_referal_params?: InputMaybe<User_Referal_Params_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Referals_Max_Fields = {
  __typename?: 'user_referals_max_fields';
  bonus_amount?: Maybe<Scalars['Int']>;
  bonus_amount_currency?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  owner_user_id?: Maybe<Scalars['uuid']>;
  referal_user_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type User_Referals_Min_Fields = {
  __typename?: 'user_referals_min_fields';
  bonus_amount?: Maybe<Scalars['Int']>;
  bonus_amount_currency?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  owner_user_id?: Maybe<Scalars['uuid']>;
  referal_user_id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "user_referals" */
export type User_Referals_Mutation_Response = {
  __typename?: 'user_referals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referals>;
};

/** on_conflict condition type for table "user_referals" */
export type User_Referals_On_Conflict = {
  constraint: User_Referals_Constraint;
  update_columns?: Array<User_Referals_Update_Column>;
  where?: InputMaybe<User_Referals_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referals". */
export type User_Referals_Order_By = {
  bonus_amount?: InputMaybe<Order_By>;
  bonus_amount_currency?: InputMaybe<Order_By>;
  bonus_claimed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  referal_user_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_referal_params?: InputMaybe<User_Referal_Params_Order_By>;
};

/** primary key columns input for table: user_referals */
export type User_Referals_Pk_Columns_Input = {
  owner_user_id: Scalars['uuid'];
  referal_user_id: Scalars['uuid'];
};

/** select columns of table "user_referals" */
export enum User_Referals_Select_Column {
  /** column name */
  BonusAmount = 'bonus_amount',
  /** column name */
  BonusAmountCurrency = 'bonus_amount_currency',
  /** column name */
  BonusClaimed = 'bonus_claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OwnerUserId = 'owner_user_id',
  /** column name */
  ReferalUserId = 'referal_user_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_referals" */
export type User_Referals_Set_Input = {
  bonus_amount?: InputMaybe<Scalars['Int']>;
  bonus_amount_currency?: InputMaybe<Scalars['String']>;
  bonus_claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referal_user_id?: InputMaybe<Scalars['uuid']>;
  source?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type User_Referals_Stddev_Fields = {
  __typename?: 'user_referals_stddev_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Referals_Stddev_Pop_Fields = {
  __typename?: 'user_referals_stddev_pop_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Referals_Stddev_Samp_Fields = {
  __typename?: 'user_referals_stddev_samp_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_referals" */
export type User_Referals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referals_Stream_Cursor_Value_Input = {
  bonus_amount?: InputMaybe<Scalars['Int']>;
  bonus_amount_currency?: InputMaybe<Scalars['String']>;
  bonus_claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referal_user_id?: InputMaybe<Scalars['uuid']>;
  source?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type User_Referals_Sum_Fields = {
  __typename?: 'user_referals_sum_fields';
  bonus_amount?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_referals" */
export enum User_Referals_Update_Column {
  /** column name */
  BonusAmount = 'bonus_amount',
  /** column name */
  BonusAmountCurrency = 'bonus_amount_currency',
  /** column name */
  BonusClaimed = 'bonus_claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OwnerUserId = 'owner_user_id',
  /** column name */
  ReferalUserId = 'referal_user_id',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Referals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Referals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referals_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Referals_Var_Pop_Fields = {
  __typename?: 'user_referals_var_pop_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Referals_Var_Samp_Fields = {
  __typename?: 'user_referals_var_samp_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Referals_Variance_Fields = {
  __typename?: 'user_referals_variance_fields';
  bonus_amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_referral" */
export type User_Referral = {
  __typename?: 'user_referral';
  bonus_claimed: Scalars['Boolean'];
  created_at: Scalars['timestamp'];
  owner_user_id: Scalars['uuid'];
  referral_user_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  user_account?: Maybe<User_Account>;
  /** An object relationship */
  user_referral_params?: Maybe<User_Referral_Params>;
};

/** aggregated selection of "user_referral" */
export type User_Referral_Aggregate = {
  __typename?: 'user_referral_aggregate';
  aggregate?: Maybe<User_Referral_Aggregate_Fields>;
  nodes: Array<User_Referral>;
};

/** aggregate fields of "user_referral" */
export type User_Referral_Aggregate_Fields = {
  __typename?: 'user_referral_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Referral_Max_Fields>;
  min?: Maybe<User_Referral_Min_Fields>;
};


/** aggregate fields of "user_referral" */
export type User_Referral_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referral_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "user_referral_association" */
export type User_Referral_Association = {
  __typename?: 'user_referral_association';
  created_at?: Maybe<Scalars['timestamptz']>;
  offer_id: Scalars['uuid'];
  transaction?: Maybe<Payment_Transactions>;
  transaction_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  used: Scalars['Boolean'];
  /** An object relationship */
  user_account: User_Account;
  user_id: Scalars['uuid'];
  /** An object relationship */
  user_referral_offer: User_Referral_Offers;
};

/** aggregated selection of "user_referral_association" */
export type User_Referral_Association_Aggregate = {
  __typename?: 'user_referral_association_aggregate';
  aggregate?: Maybe<User_Referral_Association_Aggregate_Fields>;
  nodes: Array<User_Referral_Association>;
};

export type User_Referral_Association_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Referral_Association_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Referral_Association_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Referral_Association_Aggregate_Bool_Exp_Count>;
};

export type User_Referral_Association_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Referral_Association_Select_Column_User_Referral_Association_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Referral_Association_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Referral_Association_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Referral_Association_Select_Column_User_Referral_Association_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Referral_Association_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Referral_Association_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Referral_Association_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Referral_Association_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_referral_association" */
export type User_Referral_Association_Aggregate_Fields = {
  __typename?: 'user_referral_association_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Referral_Association_Max_Fields>;
  min?: Maybe<User_Referral_Association_Min_Fields>;
};


/** aggregate fields of "user_referral_association" */
export type User_Referral_Association_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referral_Association_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_referral_association" */
export type User_Referral_Association_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Referral_Association_Max_Order_By>;
  min?: InputMaybe<User_Referral_Association_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_referral_association" */
export type User_Referral_Association_Arr_Rel_Insert_Input = {
  data: Array<User_Referral_Association_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Referral_Association_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_referral_association". All fields are combined with a logical 'AND'. */
export type User_Referral_Association_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referral_Association_Bool_Exp>>;
  _not?: InputMaybe<User_Referral_Association_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referral_Association_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  used?: InputMaybe<Boolean_Comparison_Exp>;
  user_account?: InputMaybe<User_Account_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_referral_offer?: InputMaybe<User_Referral_Offers_Bool_Exp>;
};

/** unique or primary key constraints on table "user_referral_association" */
export enum User_Referral_Association_Constraint {
  /** unique or primary key constraint on columns "offer_id", "user_id" */
  UserReferralAssociationPkey = 'user_referral_association_pkey',
  /** unique or primary key constraint on columns "transaction_id" */
  UserReferralAssociationTransactionIdKey = 'user_referral_association_transaction_id_key'
}

/** input type for inserting data into table "user_referral_association" */
export type User_Referral_Association_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
  user_account?: InputMaybe<User_Account_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_referral_offer?: InputMaybe<User_Referral_Offers_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Referral_Association_Max_Fields = {
  __typename?: 'user_referral_association_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  offer_id?: Maybe<Scalars['uuid']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_referral_association" */
export type User_Referral_Association_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Referral_Association_Min_Fields = {
  __typename?: 'user_referral_association_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  offer_id?: Maybe<Scalars['uuid']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_referral_association" */
export type User_Referral_Association_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_referral_association" */
export type User_Referral_Association_Mutation_Response = {
  __typename?: 'user_referral_association_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referral_Association>;
};

/** on_conflict condition type for table "user_referral_association" */
export type User_Referral_Association_On_Conflict = {
  constraint: User_Referral_Association_Constraint;
  update_columns?: Array<User_Referral_Association_Update_Column>;
  where?: InputMaybe<User_Referral_Association_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referral_association". */
export type User_Referral_Association_Order_By = {
  created_at?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  used?: InputMaybe<Order_By>;
  user_account?: InputMaybe<User_Account_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_referral_offer?: InputMaybe<User_Referral_Offers_Order_By>;
};

/** primary key columns input for table: user_referral_association */
export type User_Referral_Association_Pk_Columns_Input = {
  offer_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "user_referral_association" */
export enum User_Referral_Association_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used',
  /** column name */
  UserId = 'user_id'
}

/** select "user_referral_association_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_referral_association" */
export enum User_Referral_Association_Select_Column_User_Referral_Association_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Used = 'used'
}

/** select "user_referral_association_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_referral_association" */
export enum User_Referral_Association_Select_Column_User_Referral_Association_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Used = 'used'
}

/** input type for updating data in table "user_referral_association" */
export type User_Referral_Association_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_referral_association" */
export type User_Referral_Association_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referral_Association_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referral_Association_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_referral_association" */
export enum User_Referral_Association_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used',
  /** column name */
  UserId = 'user_id'
}

export type User_Referral_Association_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referral_Association_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referral_Association_Bool_Exp;
};

/** columns and relationships of "user_referral_bonus_type" */
export type User_Referral_Bonus_Type = {
  __typename?: 'user_referral_bonus_type';
  bonus_type: Scalars['String'];
  count: Scalars['Int'];
  referral_bonus_min_deposit?: Maybe<Scalars['Int']>;
  referral_bonus_type_id: Scalars['uuid'];
  /** An array relationship */
  user_referral_offer_bonus: Array<User_Referral_Offer_Bonus>;
  /** An aggregate relationship */
  user_referral_offer_bonus_aggregate: User_Referral_Offer_Bonus_Aggregate;
};


/** columns and relationships of "user_referral_bonus_type" */
export type User_Referral_Bonus_TypeUser_Referral_Offer_BonusArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offer_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offer_Bonus_Order_By>>;
  where?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
};


/** columns and relationships of "user_referral_bonus_type" */
export type User_Referral_Bonus_TypeUser_Referral_Offer_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offer_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offer_Bonus_Order_By>>;
  where?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
};

/** aggregated selection of "user_referral_bonus_type" */
export type User_Referral_Bonus_Type_Aggregate = {
  __typename?: 'user_referral_bonus_type_aggregate';
  aggregate?: Maybe<User_Referral_Bonus_Type_Aggregate_Fields>;
  nodes: Array<User_Referral_Bonus_Type>;
};

/** aggregate fields of "user_referral_bonus_type" */
export type User_Referral_Bonus_Type_Aggregate_Fields = {
  __typename?: 'user_referral_bonus_type_aggregate_fields';
  avg?: Maybe<User_Referral_Bonus_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Referral_Bonus_Type_Max_Fields>;
  min?: Maybe<User_Referral_Bonus_Type_Min_Fields>;
  stddev?: Maybe<User_Referral_Bonus_Type_Stddev_Fields>;
  stddev_pop?: Maybe<User_Referral_Bonus_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Referral_Bonus_Type_Stddev_Samp_Fields>;
  sum?: Maybe<User_Referral_Bonus_Type_Sum_Fields>;
  var_pop?: Maybe<User_Referral_Bonus_Type_Var_Pop_Fields>;
  var_samp?: Maybe<User_Referral_Bonus_Type_Var_Samp_Fields>;
  variance?: Maybe<User_Referral_Bonus_Type_Variance_Fields>;
};


/** aggregate fields of "user_referral_bonus_type" */
export type User_Referral_Bonus_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referral_Bonus_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Referral_Bonus_Type_Avg_Fields = {
  __typename?: 'user_referral_bonus_type_avg_fields';
  count?: Maybe<Scalars['Float']>;
  referral_bonus_min_deposit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_referral_bonus_type". All fields are combined with a logical 'AND'. */
export type User_Referral_Bonus_Type_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referral_Bonus_Type_Bool_Exp>>;
  _not?: InputMaybe<User_Referral_Bonus_Type_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referral_Bonus_Type_Bool_Exp>>;
  bonus_type?: InputMaybe<String_Comparison_Exp>;
  count?: InputMaybe<Int_Comparison_Exp>;
  referral_bonus_min_deposit?: InputMaybe<Int_Comparison_Exp>;
  referral_bonus_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_referral_offer_bonus?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
  user_referral_offer_bonus_aggregate?: InputMaybe<User_Referral_Offer_Bonus_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user_referral_bonus_type" */
export enum User_Referral_Bonus_Type_Constraint {
  /** unique or primary key constraint on columns "referral_bonus_type_id" */
  UserReferralBonusTypePkey = 'user_referral_bonus_type_pkey'
}

/** input type for incrementing numeric columns in table "user_referral_bonus_type" */
export type User_Referral_Bonus_Type_Inc_Input = {
  count?: InputMaybe<Scalars['Int']>;
  referral_bonus_min_deposit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_referral_bonus_type" */
export type User_Referral_Bonus_Type_Insert_Input = {
  bonus_type?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  referral_bonus_min_deposit?: InputMaybe<Scalars['Int']>;
  referral_bonus_type_id?: InputMaybe<Scalars['uuid']>;
  user_referral_offer_bonus?: InputMaybe<User_Referral_Offer_Bonus_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Referral_Bonus_Type_Max_Fields = {
  __typename?: 'user_referral_bonus_type_max_fields';
  bonus_type?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  referral_bonus_min_deposit?: Maybe<Scalars['Int']>;
  referral_bonus_type_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Referral_Bonus_Type_Min_Fields = {
  __typename?: 'user_referral_bonus_type_min_fields';
  bonus_type?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  referral_bonus_min_deposit?: Maybe<Scalars['Int']>;
  referral_bonus_type_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_referral_bonus_type" */
export type User_Referral_Bonus_Type_Mutation_Response = {
  __typename?: 'user_referral_bonus_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referral_Bonus_Type>;
};

/** input type for inserting object relation for remote table "user_referral_bonus_type" */
export type User_Referral_Bonus_Type_Obj_Rel_Insert_Input = {
  data: User_Referral_Bonus_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Referral_Bonus_Type_On_Conflict>;
};

/** on_conflict condition type for table "user_referral_bonus_type" */
export type User_Referral_Bonus_Type_On_Conflict = {
  constraint: User_Referral_Bonus_Type_Constraint;
  update_columns?: Array<User_Referral_Bonus_Type_Update_Column>;
  where?: InputMaybe<User_Referral_Bonus_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referral_bonus_type". */
export type User_Referral_Bonus_Type_Order_By = {
  bonus_type?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  referral_bonus_min_deposit?: InputMaybe<Order_By>;
  referral_bonus_type_id?: InputMaybe<Order_By>;
  user_referral_offer_bonus_aggregate?: InputMaybe<User_Referral_Offer_Bonus_Aggregate_Order_By>;
};

/** primary key columns input for table: user_referral_bonus_type */
export type User_Referral_Bonus_Type_Pk_Columns_Input = {
  referral_bonus_type_id: Scalars['uuid'];
};

/** select columns of table "user_referral_bonus_type" */
export enum User_Referral_Bonus_Type_Select_Column {
  /** column name */
  BonusType = 'bonus_type',
  /** column name */
  Count = 'count',
  /** column name */
  ReferralBonusMinDeposit = 'referral_bonus_min_deposit',
  /** column name */
  ReferralBonusTypeId = 'referral_bonus_type_id'
}

/** input type for updating data in table "user_referral_bonus_type" */
export type User_Referral_Bonus_Type_Set_Input = {
  bonus_type?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  referral_bonus_min_deposit?: InputMaybe<Scalars['Int']>;
  referral_bonus_type_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Referral_Bonus_Type_Stddev_Fields = {
  __typename?: 'user_referral_bonus_type_stddev_fields';
  count?: Maybe<Scalars['Float']>;
  referral_bonus_min_deposit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Referral_Bonus_Type_Stddev_Pop_Fields = {
  __typename?: 'user_referral_bonus_type_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
  referral_bonus_min_deposit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Referral_Bonus_Type_Stddev_Samp_Fields = {
  __typename?: 'user_referral_bonus_type_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
  referral_bonus_min_deposit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_referral_bonus_type" */
export type User_Referral_Bonus_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referral_Bonus_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referral_Bonus_Type_Stream_Cursor_Value_Input = {
  bonus_type?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['Int']>;
  referral_bonus_min_deposit?: InputMaybe<Scalars['Int']>;
  referral_bonus_type_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Referral_Bonus_Type_Sum_Fields = {
  __typename?: 'user_referral_bonus_type_sum_fields';
  count?: Maybe<Scalars['Int']>;
  referral_bonus_min_deposit?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_referral_bonus_type" */
export enum User_Referral_Bonus_Type_Update_Column {
  /** column name */
  BonusType = 'bonus_type',
  /** column name */
  Count = 'count',
  /** column name */
  ReferralBonusMinDeposit = 'referral_bonus_min_deposit',
  /** column name */
  ReferralBonusTypeId = 'referral_bonus_type_id'
}

export type User_Referral_Bonus_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Referral_Bonus_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referral_Bonus_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referral_Bonus_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Referral_Bonus_Type_Var_Pop_Fields = {
  __typename?: 'user_referral_bonus_type_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
  referral_bonus_min_deposit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Referral_Bonus_Type_Var_Samp_Fields = {
  __typename?: 'user_referral_bonus_type_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
  referral_bonus_min_deposit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Referral_Bonus_Type_Variance_Fields = {
  __typename?: 'user_referral_bonus_type_variance_fields';
  count?: Maybe<Scalars['Float']>;
  referral_bonus_min_deposit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_referral". All fields are combined with a logical 'AND'. */
export type User_Referral_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referral_Bool_Exp>>;
  _not?: InputMaybe<User_Referral_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referral_Bool_Exp>>;
  bonus_claimed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  owner_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  referral_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_account?: InputMaybe<User_Account_Bool_Exp>;
  user_referral_params?: InputMaybe<User_Referral_Params_Bool_Exp>;
};

/** unique or primary key constraints on table "user_referral" */
export enum User_Referral_Constraint {
  /** unique or primary key constraint on columns "owner_user_id", "referral_user_id" */
  UserReferralPkey = 'user_referral_pkey'
}

/** input type for inserting data into table "user_referral" */
export type User_Referral_Insert_Input = {
  bonus_claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referral_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_account?: InputMaybe<User_Account_Obj_Rel_Insert_Input>;
  user_referral_params?: InputMaybe<User_Referral_Params_Obj_Rel_Insert_Input>;
};

/** columns and relationships of "user_referral_levels_params" */
export type User_Referral_Levels_Params = {
  __typename?: 'user_referral_levels_params';
  deposits_amount: Scalars['Int'];
  owner_bonus_percent: Scalars['Int'];
  referral_level: Scalars['Int'];
};

/** aggregated selection of "user_referral_levels_params" */
export type User_Referral_Levels_Params_Aggregate = {
  __typename?: 'user_referral_levels_params_aggregate';
  aggregate?: Maybe<User_Referral_Levels_Params_Aggregate_Fields>;
  nodes: Array<User_Referral_Levels_Params>;
};

/** aggregate fields of "user_referral_levels_params" */
export type User_Referral_Levels_Params_Aggregate_Fields = {
  __typename?: 'user_referral_levels_params_aggregate_fields';
  avg?: Maybe<User_Referral_Levels_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Referral_Levels_Params_Max_Fields>;
  min?: Maybe<User_Referral_Levels_Params_Min_Fields>;
  stddev?: Maybe<User_Referral_Levels_Params_Stddev_Fields>;
  stddev_pop?: Maybe<User_Referral_Levels_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Referral_Levels_Params_Stddev_Samp_Fields>;
  sum?: Maybe<User_Referral_Levels_Params_Sum_Fields>;
  var_pop?: Maybe<User_Referral_Levels_Params_Var_Pop_Fields>;
  var_samp?: Maybe<User_Referral_Levels_Params_Var_Samp_Fields>;
  variance?: Maybe<User_Referral_Levels_Params_Variance_Fields>;
};


/** aggregate fields of "user_referral_levels_params" */
export type User_Referral_Levels_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referral_Levels_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Referral_Levels_Params_Avg_Fields = {
  __typename?: 'user_referral_levels_params_avg_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referral_level?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_referral_levels_params". All fields are combined with a logical 'AND'. */
export type User_Referral_Levels_Params_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referral_Levels_Params_Bool_Exp>>;
  _not?: InputMaybe<User_Referral_Levels_Params_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referral_Levels_Params_Bool_Exp>>;
  deposits_amount?: InputMaybe<Int_Comparison_Exp>;
  owner_bonus_percent?: InputMaybe<Int_Comparison_Exp>;
  referral_level?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_referral_levels_params" */
export enum User_Referral_Levels_Params_Constraint {
  /** unique or primary key constraint on columns "referral_level" */
  UserReferralLevelsParamsPkey = 'user_referral_levels_params_pkey'
}

/** input type for incrementing numeric columns in table "user_referral_levels_params" */
export type User_Referral_Levels_Params_Inc_Input = {
  deposits_amount?: InputMaybe<Scalars['Int']>;
  owner_bonus_percent?: InputMaybe<Scalars['Int']>;
  referral_level?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_referral_levels_params" */
export type User_Referral_Levels_Params_Insert_Input = {
  deposits_amount?: InputMaybe<Scalars['Int']>;
  owner_bonus_percent?: InputMaybe<Scalars['Int']>;
  referral_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type User_Referral_Levels_Params_Max_Fields = {
  __typename?: 'user_referral_levels_params_max_fields';
  deposits_amount?: Maybe<Scalars['Int']>;
  owner_bonus_percent?: Maybe<Scalars['Int']>;
  referral_level?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type User_Referral_Levels_Params_Min_Fields = {
  __typename?: 'user_referral_levels_params_min_fields';
  deposits_amount?: Maybe<Scalars['Int']>;
  owner_bonus_percent?: Maybe<Scalars['Int']>;
  referral_level?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "user_referral_levels_params" */
export type User_Referral_Levels_Params_Mutation_Response = {
  __typename?: 'user_referral_levels_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referral_Levels_Params>;
};

/** on_conflict condition type for table "user_referral_levels_params" */
export type User_Referral_Levels_Params_On_Conflict = {
  constraint: User_Referral_Levels_Params_Constraint;
  update_columns?: Array<User_Referral_Levels_Params_Update_Column>;
  where?: InputMaybe<User_Referral_Levels_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referral_levels_params". */
export type User_Referral_Levels_Params_Order_By = {
  deposits_amount?: InputMaybe<Order_By>;
  owner_bonus_percent?: InputMaybe<Order_By>;
  referral_level?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_referral_levels_params */
export type User_Referral_Levels_Params_Pk_Columns_Input = {
  referral_level: Scalars['Int'];
};

/** select columns of table "user_referral_levels_params" */
export enum User_Referral_Levels_Params_Select_Column {
  /** column name */
  DepositsAmount = 'deposits_amount',
  /** column name */
  OwnerBonusPercent = 'owner_bonus_percent',
  /** column name */
  ReferralLevel = 'referral_level'
}

/** input type for updating data in table "user_referral_levels_params" */
export type User_Referral_Levels_Params_Set_Input = {
  deposits_amount?: InputMaybe<Scalars['Int']>;
  owner_bonus_percent?: InputMaybe<Scalars['Int']>;
  referral_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_Referral_Levels_Params_Stddev_Fields = {
  __typename?: 'user_referral_levels_params_stddev_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referral_level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Referral_Levels_Params_Stddev_Pop_Fields = {
  __typename?: 'user_referral_levels_params_stddev_pop_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referral_level?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Referral_Levels_Params_Stddev_Samp_Fields = {
  __typename?: 'user_referral_levels_params_stddev_samp_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referral_level?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_referral_levels_params" */
export type User_Referral_Levels_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referral_Levels_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referral_Levels_Params_Stream_Cursor_Value_Input = {
  deposits_amount?: InputMaybe<Scalars['Int']>;
  owner_bonus_percent?: InputMaybe<Scalars['Int']>;
  referral_level?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type User_Referral_Levels_Params_Sum_Fields = {
  __typename?: 'user_referral_levels_params_sum_fields';
  deposits_amount?: Maybe<Scalars['Int']>;
  owner_bonus_percent?: Maybe<Scalars['Int']>;
  referral_level?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_referral_levels_params" */
export enum User_Referral_Levels_Params_Update_Column {
  /** column name */
  DepositsAmount = 'deposits_amount',
  /** column name */
  OwnerBonusPercent = 'owner_bonus_percent',
  /** column name */
  ReferralLevel = 'referral_level'
}

export type User_Referral_Levels_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Referral_Levels_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referral_Levels_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referral_Levels_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Referral_Levels_Params_Var_Pop_Fields = {
  __typename?: 'user_referral_levels_params_var_pop_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referral_level?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Referral_Levels_Params_Var_Samp_Fields = {
  __typename?: 'user_referral_levels_params_var_samp_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referral_level?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Referral_Levels_Params_Variance_Fields = {
  __typename?: 'user_referral_levels_params_variance_fields';
  deposits_amount?: Maybe<Scalars['Float']>;
  owner_bonus_percent?: Maybe<Scalars['Float']>;
  referral_level?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type User_Referral_Max_Fields = {
  __typename?: 'user_referral_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  owner_user_id?: Maybe<Scalars['uuid']>;
  referral_user_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type User_Referral_Min_Fields = {
  __typename?: 'user_referral_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  owner_user_id?: Maybe<Scalars['uuid']>;
  referral_user_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "user_referral" */
export type User_Referral_Mutation_Response = {
  __typename?: 'user_referral_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referral>;
};

/** columns and relationships of "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus = {
  __typename?: 'user_referral_offer_bonus';
  offer_bonus_id: Scalars['uuid'];
  offer_bonus_type: Scalars['uuid'];
  offer_id: Scalars['uuid'];
  /** An object relationship */
  user_referral_bonus_type: User_Referral_Bonus_Type;
  /** An object relationship */
  user_referral_offer: User_Referral_Offers;
};

/** aggregated selection of "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_Aggregate = {
  __typename?: 'user_referral_offer_bonus_aggregate';
  aggregate?: Maybe<User_Referral_Offer_Bonus_Aggregate_Fields>;
  nodes: Array<User_Referral_Offer_Bonus>;
};

export type User_Referral_Offer_Bonus_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Referral_Offer_Bonus_Aggregate_Bool_Exp_Count>;
};

export type User_Referral_Offer_Bonus_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Referral_Offer_Bonus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_Aggregate_Fields = {
  __typename?: 'user_referral_offer_bonus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Referral_Offer_Bonus_Max_Fields>;
  min?: Maybe<User_Referral_Offer_Bonus_Min_Fields>;
};


/** aggregate fields of "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referral_Offer_Bonus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Referral_Offer_Bonus_Max_Order_By>;
  min?: InputMaybe<User_Referral_Offer_Bonus_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_Arr_Rel_Insert_Input = {
  data: Array<User_Referral_Offer_Bonus_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Referral_Offer_Bonus_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_referral_offer_bonus". All fields are combined with a logical 'AND'. */
export type User_Referral_Offer_Bonus_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referral_Offer_Bonus_Bool_Exp>>;
  _not?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referral_Offer_Bonus_Bool_Exp>>;
  offer_bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  offer_bonus_type?: InputMaybe<Uuid_Comparison_Exp>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_referral_bonus_type?: InputMaybe<User_Referral_Bonus_Type_Bool_Exp>;
  user_referral_offer?: InputMaybe<User_Referral_Offers_Bool_Exp>;
};

/** unique or primary key constraints on table "user_referral_offer_bonus" */
export enum User_Referral_Offer_Bonus_Constraint {
  /** unique or primary key constraint on columns "offer_bonus_id" */
  UserReferralOfferBonusPkey = 'user_referral_offer_bonus_pkey'
}

/** input type for inserting data into table "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_Insert_Input = {
  offer_bonus_id?: InputMaybe<Scalars['uuid']>;
  offer_bonus_type?: InputMaybe<Scalars['uuid']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  user_referral_bonus_type?: InputMaybe<User_Referral_Bonus_Type_Obj_Rel_Insert_Input>;
  user_referral_offer?: InputMaybe<User_Referral_Offers_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Referral_Offer_Bonus_Max_Fields = {
  __typename?: 'user_referral_offer_bonus_max_fields';
  offer_bonus_id?: Maybe<Scalars['uuid']>;
  offer_bonus_type?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_Max_Order_By = {
  offer_bonus_id?: InputMaybe<Order_By>;
  offer_bonus_type?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Referral_Offer_Bonus_Min_Fields = {
  __typename?: 'user_referral_offer_bonus_min_fields';
  offer_bonus_id?: Maybe<Scalars['uuid']>;
  offer_bonus_type?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_Min_Order_By = {
  offer_bonus_id?: InputMaybe<Order_By>;
  offer_bonus_type?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_Mutation_Response = {
  __typename?: 'user_referral_offer_bonus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referral_Offer_Bonus>;
};

/** on_conflict condition type for table "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_On_Conflict = {
  constraint: User_Referral_Offer_Bonus_Constraint;
  update_columns?: Array<User_Referral_Offer_Bonus_Update_Column>;
  where?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referral_offer_bonus". */
export type User_Referral_Offer_Bonus_Order_By = {
  offer_bonus_id?: InputMaybe<Order_By>;
  offer_bonus_type?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  user_referral_bonus_type?: InputMaybe<User_Referral_Bonus_Type_Order_By>;
  user_referral_offer?: InputMaybe<User_Referral_Offers_Order_By>;
};

/** primary key columns input for table: user_referral_offer_bonus */
export type User_Referral_Offer_Bonus_Pk_Columns_Input = {
  offer_bonus_id: Scalars['uuid'];
};

/** select columns of table "user_referral_offer_bonus" */
export enum User_Referral_Offer_Bonus_Select_Column {
  /** column name */
  OfferBonusId = 'offer_bonus_id',
  /** column name */
  OfferBonusType = 'offer_bonus_type',
  /** column name */
  OfferId = 'offer_id'
}

/** input type for updating data in table "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_Set_Input = {
  offer_bonus_id?: InputMaybe<Scalars['uuid']>;
  offer_bonus_type?: InputMaybe<Scalars['uuid']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_referral_offer_bonus" */
export type User_Referral_Offer_Bonus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referral_Offer_Bonus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referral_Offer_Bonus_Stream_Cursor_Value_Input = {
  offer_bonus_id?: InputMaybe<Scalars['uuid']>;
  offer_bonus_type?: InputMaybe<Scalars['uuid']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_referral_offer_bonus" */
export enum User_Referral_Offer_Bonus_Update_Column {
  /** column name */
  OfferBonusId = 'offer_bonus_id',
  /** column name */
  OfferBonusType = 'offer_bonus_type',
  /** column name */
  OfferId = 'offer_id'
}

export type User_Referral_Offer_Bonus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referral_Offer_Bonus_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referral_Offer_Bonus_Bool_Exp;
};

/** columns and relationships of "user_referral_offers" */
export type User_Referral_Offers = {
  __typename?: 'user_referral_offers';
  active: Scalars['Boolean'];
  created_at?: Maybe<Scalars['timestamp']>;
  offer_id: Scalars['uuid'];
  offer_name?: Maybe<Scalars['String']>;
  offer_type: Scalars['String'];
  /** An object relationship */
  partner_params?: Maybe<Partner_Params>;
  promo_code: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['uuid'];
  /** An array relationship */
  user_referral_associations: Array<User_Referral_Association>;
  /** An aggregate relationship */
  user_referral_associations_aggregate: User_Referral_Association_Aggregate;
  /** An array relationship */
  user_referral_offer_bonus: Array<User_Referral_Offer_Bonus>;
  /** An aggregate relationship */
  user_referral_offer_bonus_aggregate: User_Referral_Offer_Bonus_Aggregate;
};


/** columns and relationships of "user_referral_offers" */
export type User_Referral_OffersUser_Referral_AssociationsArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Association_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Association_Order_By>>;
  where?: InputMaybe<User_Referral_Association_Bool_Exp>;
};


/** columns and relationships of "user_referral_offers" */
export type User_Referral_OffersUser_Referral_Associations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Association_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Association_Order_By>>;
  where?: InputMaybe<User_Referral_Association_Bool_Exp>;
};


/** columns and relationships of "user_referral_offers" */
export type User_Referral_OffersUser_Referral_Offer_BonusArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offer_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offer_Bonus_Order_By>>;
  where?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
};


/** columns and relationships of "user_referral_offers" */
export type User_Referral_OffersUser_Referral_Offer_Bonus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Referral_Offer_Bonus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Referral_Offer_Bonus_Order_By>>;
  where?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
};

/** aggregated selection of "user_referral_offers" */
export type User_Referral_Offers_Aggregate = {
  __typename?: 'user_referral_offers_aggregate';
  aggregate?: Maybe<User_Referral_Offers_Aggregate_Fields>;
  nodes: Array<User_Referral_Offers>;
};

export type User_Referral_Offers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Referral_Offers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Referral_Offers_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Referral_Offers_Aggregate_Bool_Exp_Count>;
};

export type User_Referral_Offers_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Referral_Offers_Select_Column_User_Referral_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Referral_Offers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Referral_Offers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Referral_Offers_Select_Column_User_Referral_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Referral_Offers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Referral_Offers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Referral_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Referral_Offers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_referral_offers" */
export type User_Referral_Offers_Aggregate_Fields = {
  __typename?: 'user_referral_offers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Referral_Offers_Max_Fields>;
  min?: Maybe<User_Referral_Offers_Min_Fields>;
};


/** aggregate fields of "user_referral_offers" */
export type User_Referral_Offers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referral_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_referral_offers" */
export type User_Referral_Offers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Referral_Offers_Max_Order_By>;
  min?: InputMaybe<User_Referral_Offers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_referral_offers" */
export type User_Referral_Offers_Arr_Rel_Insert_Input = {
  data: Array<User_Referral_Offers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Referral_Offers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_referral_offers". All fields are combined with a logical 'AND'. */
export type User_Referral_Offers_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referral_Offers_Bool_Exp>>;
  _not?: InputMaybe<User_Referral_Offers_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referral_Offers_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  offer_id?: InputMaybe<Uuid_Comparison_Exp>;
  offer_name?: InputMaybe<String_Comparison_Exp>;
  offer_type?: InputMaybe<String_Comparison_Exp>;
  partner_params?: InputMaybe<Partner_Params_Bool_Exp>;
  promo_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_referral_associations?: InputMaybe<User_Referral_Association_Bool_Exp>;
  user_referral_associations_aggregate?: InputMaybe<User_Referral_Association_Aggregate_Bool_Exp>;
  user_referral_offer_bonus?: InputMaybe<User_Referral_Offer_Bonus_Bool_Exp>;
  user_referral_offer_bonus_aggregate?: InputMaybe<User_Referral_Offer_Bonus_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user_referral_offers" */
export enum User_Referral_Offers_Constraint {
  /** unique or primary key constraint on columns "promo_code", "offer_type" */
  UniquePromoCodeOfferType = 'unique_promo_code_offer_type',
  /** unique or primary key constraint on columns "offer_id" */
  UserReferralPromoOffersPkey = 'user_referral_promo_offers_pkey'
}

/** input type for inserting data into table "user_referral_offers" */
export type User_Referral_Offers_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_name?: InputMaybe<Scalars['String']>;
  offer_type?: InputMaybe<Scalars['String']>;
  partner_params?: InputMaybe<Partner_Params_Obj_Rel_Insert_Input>;
  promo_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_referral_associations?: InputMaybe<User_Referral_Association_Arr_Rel_Insert_Input>;
  user_referral_offer_bonus?: InputMaybe<User_Referral_Offer_Bonus_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Referral_Offers_Max_Fields = {
  __typename?: 'user_referral_offers_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  offer_id?: Maybe<Scalars['uuid']>;
  offer_name?: Maybe<Scalars['String']>;
  offer_type?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_referral_offers" */
export type User_Referral_Offers_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  offer_name?: InputMaybe<Order_By>;
  offer_type?: InputMaybe<Order_By>;
  promo_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Referral_Offers_Min_Fields = {
  __typename?: 'user_referral_offers_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  offer_id?: Maybe<Scalars['uuid']>;
  offer_name?: Maybe<Scalars['String']>;
  offer_type?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_referral_offers" */
export type User_Referral_Offers_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  offer_name?: InputMaybe<Order_By>;
  offer_type?: InputMaybe<Order_By>;
  promo_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_referral_offers" */
export type User_Referral_Offers_Mutation_Response = {
  __typename?: 'user_referral_offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referral_Offers>;
};

/** input type for inserting object relation for remote table "user_referral_offers" */
export type User_Referral_Offers_Obj_Rel_Insert_Input = {
  data: User_Referral_Offers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Referral_Offers_On_Conflict>;
};

/** on_conflict condition type for table "user_referral_offers" */
export type User_Referral_Offers_On_Conflict = {
  constraint: User_Referral_Offers_Constraint;
  update_columns?: Array<User_Referral_Offers_Update_Column>;
  where?: InputMaybe<User_Referral_Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referral_offers". */
export type User_Referral_Offers_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  offer_id?: InputMaybe<Order_By>;
  offer_name?: InputMaybe<Order_By>;
  offer_type?: InputMaybe<Order_By>;
  partner_params?: InputMaybe<Partner_Params_Order_By>;
  promo_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_referral_associations_aggregate?: InputMaybe<User_Referral_Association_Aggregate_Order_By>;
  user_referral_offer_bonus_aggregate?: InputMaybe<User_Referral_Offer_Bonus_Aggregate_Order_By>;
};

/** primary key columns input for table: user_referral_offers */
export type User_Referral_Offers_Pk_Columns_Input = {
  offer_id: Scalars['uuid'];
};

/** select columns of table "user_referral_offers" */
export enum User_Referral_Offers_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferName = 'offer_name',
  /** column name */
  OfferType = 'offer_type',
  /** column name */
  PromoCode = 'promo_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "user_referral_offers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_referral_offers" */
export enum User_Referral_Offers_Select_Column_User_Referral_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "user_referral_offers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_referral_offers" */
export enum User_Referral_Offers_Select_Column_User_Referral_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "user_referral_offers" */
export type User_Referral_Offers_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_name?: InputMaybe<Scalars['String']>;
  offer_type?: InputMaybe<Scalars['String']>;
  promo_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_referral_offers" */
export type User_Referral_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referral_Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referral_Offers_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  offer_id?: InputMaybe<Scalars['uuid']>;
  offer_name?: InputMaybe<Scalars['String']>;
  offer_type?: InputMaybe<Scalars['String']>;
  promo_code?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_referral_offers" */
export enum User_Referral_Offers_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferName = 'offer_name',
  /** column name */
  OfferType = 'offer_type',
  /** column name */
  PromoCode = 'promo_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Referral_Offers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referral_Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referral_Offers_Bool_Exp;
};

/** on_conflict condition type for table "user_referral" */
export type User_Referral_On_Conflict = {
  constraint: User_Referral_Constraint;
  update_columns?: Array<User_Referral_Update_Column>;
  where?: InputMaybe<User_Referral_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referral". */
export type User_Referral_Order_By = {
  bonus_claimed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  referral_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_account?: InputMaybe<User_Account_Order_By>;
  user_referral_params?: InputMaybe<User_Referral_Params_Order_By>;
};

/** columns and relationships of "user_referral_params" */
export type User_Referral_Params = {
  __typename?: 'user_referral_params';
  referral_balance: Scalars['Int'];
  referrals_count: Scalars['Int'];
  referrals_deposits_sum: Scalars['bigint'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_referral_params" */
export type User_Referral_Params_Aggregate = {
  __typename?: 'user_referral_params_aggregate';
  aggregate?: Maybe<User_Referral_Params_Aggregate_Fields>;
  nodes: Array<User_Referral_Params>;
};

/** aggregate fields of "user_referral_params" */
export type User_Referral_Params_Aggregate_Fields = {
  __typename?: 'user_referral_params_aggregate_fields';
  avg?: Maybe<User_Referral_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Referral_Params_Max_Fields>;
  min?: Maybe<User_Referral_Params_Min_Fields>;
  stddev?: Maybe<User_Referral_Params_Stddev_Fields>;
  stddev_pop?: Maybe<User_Referral_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Referral_Params_Stddev_Samp_Fields>;
  sum?: Maybe<User_Referral_Params_Sum_Fields>;
  var_pop?: Maybe<User_Referral_Params_Var_Pop_Fields>;
  var_samp?: Maybe<User_Referral_Params_Var_Samp_Fields>;
  variance?: Maybe<User_Referral_Params_Variance_Fields>;
};


/** aggregate fields of "user_referral_params" */
export type User_Referral_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referral_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Referral_Params_Avg_Fields = {
  __typename?: 'user_referral_params_avg_fields';
  referral_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  referrals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_referral_params". All fields are combined with a logical 'AND'. */
export type User_Referral_Params_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referral_Params_Bool_Exp>>;
  _not?: InputMaybe<User_Referral_Params_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referral_Params_Bool_Exp>>;
  referral_balance?: InputMaybe<Int_Comparison_Exp>;
  referrals_count?: InputMaybe<Int_Comparison_Exp>;
  referrals_deposits_sum?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_referral_params" */
export enum User_Referral_Params_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserReferralParamsPkey = 'user_referral_params_pkey'
}

/** input type for incrementing numeric columns in table "user_referral_params" */
export type User_Referral_Params_Inc_Input = {
  referral_balance?: InputMaybe<Scalars['Int']>;
  referrals_count?: InputMaybe<Scalars['Int']>;
  referrals_deposits_sum?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_referral_params" */
export type User_Referral_Params_Insert_Input = {
  referral_balance?: InputMaybe<Scalars['Int']>;
  referrals_count?: InputMaybe<Scalars['Int']>;
  referrals_deposits_sum?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Referral_Params_Max_Fields = {
  __typename?: 'user_referral_params_max_fields';
  referral_balance?: Maybe<Scalars['Int']>;
  referrals_count?: Maybe<Scalars['Int']>;
  referrals_deposits_sum?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Referral_Params_Min_Fields = {
  __typename?: 'user_referral_params_min_fields';
  referral_balance?: Maybe<Scalars['Int']>;
  referrals_count?: Maybe<Scalars['Int']>;
  referrals_deposits_sum?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_referral_params" */
export type User_Referral_Params_Mutation_Response = {
  __typename?: 'user_referral_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referral_Params>;
};

/** input type for inserting object relation for remote table "user_referral_params" */
export type User_Referral_Params_Obj_Rel_Insert_Input = {
  data: User_Referral_Params_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Referral_Params_On_Conflict>;
};

/** on_conflict condition type for table "user_referral_params" */
export type User_Referral_Params_On_Conflict = {
  constraint: User_Referral_Params_Constraint;
  update_columns?: Array<User_Referral_Params_Update_Column>;
  where?: InputMaybe<User_Referral_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referral_params". */
export type User_Referral_Params_Order_By = {
  referral_balance?: InputMaybe<Order_By>;
  referrals_count?: InputMaybe<Order_By>;
  referrals_deposits_sum?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_referral_params */
export type User_Referral_Params_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_referral_params" */
export enum User_Referral_Params_Select_Column {
  /** column name */
  ReferralBalance = 'referral_balance',
  /** column name */
  ReferralsCount = 'referrals_count',
  /** column name */
  ReferralsDepositsSum = 'referrals_deposits_sum',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_referral_params" */
export type User_Referral_Params_Set_Input = {
  referral_balance?: InputMaybe<Scalars['Int']>;
  referrals_count?: InputMaybe<Scalars['Int']>;
  referrals_deposits_sum?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Referral_Params_Stddev_Fields = {
  __typename?: 'user_referral_params_stddev_fields';
  referral_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  referrals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Referral_Params_Stddev_Pop_Fields = {
  __typename?: 'user_referral_params_stddev_pop_fields';
  referral_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  referrals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Referral_Params_Stddev_Samp_Fields = {
  __typename?: 'user_referral_params_stddev_samp_fields';
  referral_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  referrals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_referral_params" */
export type User_Referral_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referral_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referral_Params_Stream_Cursor_Value_Input = {
  referral_balance?: InputMaybe<Scalars['Int']>;
  referrals_count?: InputMaybe<Scalars['Int']>;
  referrals_deposits_sum?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Referral_Params_Sum_Fields = {
  __typename?: 'user_referral_params_sum_fields';
  referral_balance?: Maybe<Scalars['Int']>;
  referrals_count?: Maybe<Scalars['Int']>;
  referrals_deposits_sum?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user_referral_params" */
export enum User_Referral_Params_Update_Column {
  /** column name */
  ReferralBalance = 'referral_balance',
  /** column name */
  ReferralsCount = 'referrals_count',
  /** column name */
  ReferralsDepositsSum = 'referrals_deposits_sum',
  /** column name */
  UserId = 'user_id'
}

export type User_Referral_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Referral_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referral_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referral_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Referral_Params_Var_Pop_Fields = {
  __typename?: 'user_referral_params_var_pop_fields';
  referral_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  referrals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Referral_Params_Var_Samp_Fields = {
  __typename?: 'user_referral_params_var_samp_fields';
  referral_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  referrals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Referral_Params_Variance_Fields = {
  __typename?: 'user_referral_params_variance_fields';
  referral_balance?: Maybe<Scalars['Float']>;
  referrals_count?: Maybe<Scalars['Float']>;
  referrals_deposits_sum?: Maybe<Scalars['Float']>;
};

/** primary key columns input for table: user_referral */
export type User_Referral_Pk_Columns_Input = {
  owner_user_id: Scalars['uuid'];
  referral_user_id: Scalars['uuid'];
};

/** select columns of table "user_referral" */
export enum User_Referral_Select_Column {
  /** column name */
  BonusClaimed = 'bonus_claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OwnerUserId = 'owner_user_id',
  /** column name */
  ReferralUserId = 'referral_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_referral" */
export type User_Referral_Set_Input = {
  bonus_claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referral_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "user_referral" */
export type User_Referral_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referral_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referral_Stream_Cursor_Value_Input = {
  bonus_claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referral_user_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "user_referral" */
export enum User_Referral_Update_Column {
  /** column name */
  BonusClaimed = 'bonus_claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OwnerUserId = 'owner_user_id',
  /** column name */
  ReferralUserId = 'referral_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Referral_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referral_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referral_Bool_Exp;
};

/** columns and relationships of "user_referrals_balance_transactions" */
export type User_Referrals_Balance_Transactions = {
  __typename?: 'user_referrals_balance_transactions';
  created_at: Scalars['timestamp'];
  ggr_cases: Scalars['bigint'];
  ggr_upgrades: Scalars['bigint'];
  owner_user_id: Scalars['uuid'];
  referral_id?: Maybe<Scalars['uuid']>;
  transaction_amount?: Maybe<Scalars['Int']>;
  transaction_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "user_referrals_balance_transactions" */
export type User_Referrals_Balance_Transactions_Aggregate = {
  __typename?: 'user_referrals_balance_transactions_aggregate';
  aggregate?: Maybe<User_Referrals_Balance_Transactions_Aggregate_Fields>;
  nodes: Array<User_Referrals_Balance_Transactions>;
};

/** aggregate fields of "user_referrals_balance_transactions" */
export type User_Referrals_Balance_Transactions_Aggregate_Fields = {
  __typename?: 'user_referrals_balance_transactions_aggregate_fields';
  avg?: Maybe<User_Referrals_Balance_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Referrals_Balance_Transactions_Max_Fields>;
  min?: Maybe<User_Referrals_Balance_Transactions_Min_Fields>;
  stddev?: Maybe<User_Referrals_Balance_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<User_Referrals_Balance_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Referrals_Balance_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<User_Referrals_Balance_Transactions_Sum_Fields>;
  var_pop?: Maybe<User_Referrals_Balance_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<User_Referrals_Balance_Transactions_Var_Samp_Fields>;
  variance?: Maybe<User_Referrals_Balance_Transactions_Variance_Fields>;
};


/** aggregate fields of "user_referrals_balance_transactions" */
export type User_Referrals_Balance_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Referrals_Balance_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Referrals_Balance_Transactions_Avg_Fields = {
  __typename?: 'user_referrals_balance_transactions_avg_fields';
  ggr_cases?: Maybe<Scalars['Float']>;
  ggr_upgrades?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_referrals_balance_transactions". All fields are combined with a logical 'AND'. */
export type User_Referrals_Balance_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<User_Referrals_Balance_Transactions_Bool_Exp>>;
  _not?: InputMaybe<User_Referrals_Balance_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<User_Referrals_Balance_Transactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  ggr_cases?: InputMaybe<Bigint_Comparison_Exp>;
  ggr_upgrades?: InputMaybe<Bigint_Comparison_Exp>;
  owner_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  referral_id?: InputMaybe<Uuid_Comparison_Exp>;
  transaction_amount?: InputMaybe<Int_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_referrals_balance_transactions" */
export enum User_Referrals_Balance_Transactions_Constraint {
  /** unique or primary key constraint on columns "transaction_id" */
  UserReferralsBalanceTransactionsPkey = 'user_referrals_balance_transactions_pkey'
}

/** input type for incrementing numeric columns in table "user_referrals_balance_transactions" */
export type User_Referrals_Balance_Transactions_Inc_Input = {
  ggr_cases?: InputMaybe<Scalars['bigint']>;
  ggr_upgrades?: InputMaybe<Scalars['bigint']>;
  transaction_amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_referrals_balance_transactions" */
export type User_Referrals_Balance_Transactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  ggr_cases?: InputMaybe<Scalars['bigint']>;
  ggr_upgrades?: InputMaybe<Scalars['bigint']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referral_id?: InputMaybe<Scalars['uuid']>;
  transaction_amount?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type User_Referrals_Balance_Transactions_Max_Fields = {
  __typename?: 'user_referrals_balance_transactions_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  ggr_cases?: Maybe<Scalars['bigint']>;
  ggr_upgrades?: Maybe<Scalars['bigint']>;
  owner_user_id?: Maybe<Scalars['uuid']>;
  referral_id?: Maybe<Scalars['uuid']>;
  transaction_amount?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type User_Referrals_Balance_Transactions_Min_Fields = {
  __typename?: 'user_referrals_balance_transactions_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  ggr_cases?: Maybe<Scalars['bigint']>;
  ggr_upgrades?: Maybe<Scalars['bigint']>;
  owner_user_id?: Maybe<Scalars['uuid']>;
  referral_id?: Maybe<Scalars['uuid']>;
  transaction_amount?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "user_referrals_balance_transactions" */
export type User_Referrals_Balance_Transactions_Mutation_Response = {
  __typename?: 'user_referrals_balance_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Referrals_Balance_Transactions>;
};

/** on_conflict condition type for table "user_referrals_balance_transactions" */
export type User_Referrals_Balance_Transactions_On_Conflict = {
  constraint: User_Referrals_Balance_Transactions_Constraint;
  update_columns?: Array<User_Referrals_Balance_Transactions_Update_Column>;
  where?: InputMaybe<User_Referrals_Balance_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_referrals_balance_transactions". */
export type User_Referrals_Balance_Transactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ggr_cases?: InputMaybe<Order_By>;
  ggr_upgrades?: InputMaybe<Order_By>;
  owner_user_id?: InputMaybe<Order_By>;
  referral_id?: InputMaybe<Order_By>;
  transaction_amount?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_referrals_balance_transactions */
export type User_Referrals_Balance_Transactions_Pk_Columns_Input = {
  transaction_id: Scalars['uuid'];
};

/** select columns of table "user_referrals_balance_transactions" */
export enum User_Referrals_Balance_Transactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GgrCases = 'ggr_cases',
  /** column name */
  GgrUpgrades = 'ggr_upgrades',
  /** column name */
  OwnerUserId = 'owner_user_id',
  /** column name */
  ReferralId = 'referral_id',
  /** column name */
  TransactionAmount = 'transaction_amount',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_referrals_balance_transactions" */
export type User_Referrals_Balance_Transactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  ggr_cases?: InputMaybe<Scalars['bigint']>;
  ggr_upgrades?: InputMaybe<Scalars['bigint']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referral_id?: InputMaybe<Scalars['uuid']>;
  transaction_amount?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type User_Referrals_Balance_Transactions_Stddev_Fields = {
  __typename?: 'user_referrals_balance_transactions_stddev_fields';
  ggr_cases?: Maybe<Scalars['Float']>;
  ggr_upgrades?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Referrals_Balance_Transactions_Stddev_Pop_Fields = {
  __typename?: 'user_referrals_balance_transactions_stddev_pop_fields';
  ggr_cases?: Maybe<Scalars['Float']>;
  ggr_upgrades?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Referrals_Balance_Transactions_Stddev_Samp_Fields = {
  __typename?: 'user_referrals_balance_transactions_stddev_samp_fields';
  ggr_cases?: Maybe<Scalars['Float']>;
  ggr_upgrades?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_referrals_balance_transactions" */
export type User_Referrals_Balance_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referrals_Balance_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Referrals_Balance_Transactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  ggr_cases?: InputMaybe<Scalars['bigint']>;
  ggr_upgrades?: InputMaybe<Scalars['bigint']>;
  owner_user_id?: InputMaybe<Scalars['uuid']>;
  referral_id?: InputMaybe<Scalars['uuid']>;
  transaction_amount?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type User_Referrals_Balance_Transactions_Sum_Fields = {
  __typename?: 'user_referrals_balance_transactions_sum_fields';
  ggr_cases?: Maybe<Scalars['bigint']>;
  ggr_upgrades?: Maybe<Scalars['bigint']>;
  transaction_amount?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_referrals_balance_transactions" */
export enum User_Referrals_Balance_Transactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GgrCases = 'ggr_cases',
  /** column name */
  GgrUpgrades = 'ggr_upgrades',
  /** column name */
  OwnerUserId = 'owner_user_id',
  /** column name */
  ReferralId = 'referral_id',
  /** column name */
  TransactionAmount = 'transaction_amount',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Referrals_Balance_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Referrals_Balance_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Referrals_Balance_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Referrals_Balance_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Referrals_Balance_Transactions_Var_Pop_Fields = {
  __typename?: 'user_referrals_balance_transactions_var_pop_fields';
  ggr_cases?: Maybe<Scalars['Float']>;
  ggr_upgrades?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Referrals_Balance_Transactions_Var_Samp_Fields = {
  __typename?: 'user_referrals_balance_transactions_var_samp_fields';
  ggr_cases?: Maybe<Scalars['Float']>;
  ggr_upgrades?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Referrals_Balance_Transactions_Variance_Fields = {
  __typename?: 'user_referrals_balance_transactions_variance_fields';
  ggr_cases?: Maybe<Scalars['Float']>;
  ggr_upgrades?: Maybe<Scalars['Float']>;
  transaction_amount?: Maybe<Scalars['Float']>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Таблица хранящая номер авторизации пользователя */
export type User_Seq_Number = {
  __typename?: 'user_seq_number';
  authorization_number_user: Scalars['Int'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_seq_number" */
export type User_Seq_Number_Aggregate = {
  __typename?: 'user_seq_number_aggregate';
  aggregate?: Maybe<User_Seq_Number_Aggregate_Fields>;
  nodes: Array<User_Seq_Number>;
};

/** aggregate fields of "user_seq_number" */
export type User_Seq_Number_Aggregate_Fields = {
  __typename?: 'user_seq_number_aggregate_fields';
  avg?: Maybe<User_Seq_Number_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Seq_Number_Max_Fields>;
  min?: Maybe<User_Seq_Number_Min_Fields>;
  stddev?: Maybe<User_Seq_Number_Stddev_Fields>;
  stddev_pop?: Maybe<User_Seq_Number_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Seq_Number_Stddev_Samp_Fields>;
  sum?: Maybe<User_Seq_Number_Sum_Fields>;
  var_pop?: Maybe<User_Seq_Number_Var_Pop_Fields>;
  var_samp?: Maybe<User_Seq_Number_Var_Samp_Fields>;
  variance?: Maybe<User_Seq_Number_Variance_Fields>;
};


/** aggregate fields of "user_seq_number" */
export type User_Seq_Number_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Seq_Number_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Seq_Number_Avg_Fields = {
  __typename?: 'user_seq_number_avg_fields';
  authorization_number_user?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_seq_number". All fields are combined with a logical 'AND'. */
export type User_Seq_Number_Bool_Exp = {
  _and?: InputMaybe<Array<User_Seq_Number_Bool_Exp>>;
  _not?: InputMaybe<User_Seq_Number_Bool_Exp>;
  _or?: InputMaybe<Array<User_Seq_Number_Bool_Exp>>;
  authorization_number_user?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_seq_number" */
export enum User_Seq_Number_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserSeqNumberPkey = 'user_seq_number_pkey'
}

/** input type for incrementing numeric columns in table "user_seq_number" */
export type User_Seq_Number_Inc_Input = {
  authorization_number_user?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_seq_number" */
export type User_Seq_Number_Insert_Input = {
  authorization_number_user?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Seq_Number_Max_Fields = {
  __typename?: 'user_seq_number_max_fields';
  authorization_number_user?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Seq_Number_Min_Fields = {
  __typename?: 'user_seq_number_min_fields';
  authorization_number_user?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_seq_number" */
export type User_Seq_Number_Mutation_Response = {
  __typename?: 'user_seq_number_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Seq_Number>;
};

/** on_conflict condition type for table "user_seq_number" */
export type User_Seq_Number_On_Conflict = {
  constraint: User_Seq_Number_Constraint;
  update_columns?: Array<User_Seq_Number_Update_Column>;
  where?: InputMaybe<User_Seq_Number_Bool_Exp>;
};

/** Ordering options when selecting data from "user_seq_number". */
export type User_Seq_Number_Order_By = {
  authorization_number_user?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_seq_number */
export type User_Seq_Number_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_seq_number" */
export enum User_Seq_Number_Select_Column {
  /** column name */
  AuthorizationNumberUser = 'authorization_number_user',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_seq_number" */
export type User_Seq_Number_Set_Input = {
  authorization_number_user?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Seq_Number_Stddev_Fields = {
  __typename?: 'user_seq_number_stddev_fields';
  authorization_number_user?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Seq_Number_Stddev_Pop_Fields = {
  __typename?: 'user_seq_number_stddev_pop_fields';
  authorization_number_user?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Seq_Number_Stddev_Samp_Fields = {
  __typename?: 'user_seq_number_stddev_samp_fields';
  authorization_number_user?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_seq_number" */
export type User_Seq_Number_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Seq_Number_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Seq_Number_Stream_Cursor_Value_Input = {
  authorization_number_user?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Seq_Number_Sum_Fields = {
  __typename?: 'user_seq_number_sum_fields';
  authorization_number_user?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_seq_number" */
export enum User_Seq_Number_Update_Column {
  /** column name */
  AuthorizationNumberUser = 'authorization_number_user',
  /** column name */
  UserId = 'user_id'
}

export type User_Seq_Number_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Seq_Number_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Seq_Number_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Seq_Number_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Seq_Number_Var_Pop_Fields = {
  __typename?: 'user_seq_number_var_pop_fields';
  authorization_number_user?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Seq_Number_Var_Samp_Fields = {
  __typename?: 'user_seq_number_var_samp_fields';
  authorization_number_user?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Seq_Number_Variance_Fields = {
  __typename?: 'user_seq_number_variance_fields';
  authorization_number_user?: Maybe<Scalars['Float']>;
};

/** input type for updating data in table "user" */
export type User_Set_Input = {
  age?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Настройки конкретного пользователя */
export type User_Settings = {
  __typename?: 'user_settings';
  email_general_alerts: Scalars['Boolean'];
  fast_roll: Scalars['Boolean'];
  user_id: Scalars['uuid'];
  user_locale: Scalars['String'];
  /** An object relationship */
  user_profile: User_Profile;
};

/** aggregated selection of "user_settings" */
export type User_Settings_Aggregate = {
  __typename?: 'user_settings_aggregate';
  aggregate?: Maybe<User_Settings_Aggregate_Fields>;
  nodes: Array<User_Settings>;
};

/** aggregate fields of "user_settings" */
export type User_Settings_Aggregate_Fields = {
  __typename?: 'user_settings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Settings_Max_Fields>;
  min?: Maybe<User_Settings_Min_Fields>;
};


/** aggregate fields of "user_settings" */
export type User_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_settings". All fields are combined with a logical 'AND'. */
export type User_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<User_Settings_Bool_Exp>>;
  _not?: InputMaybe<User_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<User_Settings_Bool_Exp>>;
  email_general_alerts?: InputMaybe<Boolean_Comparison_Exp>;
  fast_roll?: InputMaybe<Boolean_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_locale?: InputMaybe<String_Comparison_Exp>;
  user_profile?: InputMaybe<User_Profile_Bool_Exp>;
};

/** unique or primary key constraints on table "user_settings" */
export enum User_Settings_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserSettingsPkey = 'user_settings_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UserSettingsUserIdKey = 'user_settings_user_id_key'
}

/** input type for inserting data into table "user_settings" */
export type User_Settings_Insert_Input = {
  email_general_alerts?: InputMaybe<Scalars['Boolean']>;
  fast_roll?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_locale?: InputMaybe<Scalars['String']>;
  user_profile?: InputMaybe<User_Profile_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Settings_Max_Fields = {
  __typename?: 'user_settings_max_fields';
  user_id?: Maybe<Scalars['uuid']>;
  user_locale?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Settings_Min_Fields = {
  __typename?: 'user_settings_min_fields';
  user_id?: Maybe<Scalars['uuid']>;
  user_locale?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_settings" */
export type User_Settings_Mutation_Response = {
  __typename?: 'user_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Settings>;
};

/** input type for inserting object relation for remote table "user_settings" */
export type User_Settings_Obj_Rel_Insert_Input = {
  data: User_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Settings_On_Conflict>;
};

/** on_conflict condition type for table "user_settings" */
export type User_Settings_On_Conflict = {
  constraint: User_Settings_Constraint;
  update_columns?: Array<User_Settings_Update_Column>;
  where?: InputMaybe<User_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "user_settings". */
export type User_Settings_Order_By = {
  email_general_alerts?: InputMaybe<Order_By>;
  fast_roll?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_locale?: InputMaybe<Order_By>;
  user_profile?: InputMaybe<User_Profile_Order_By>;
};

/** primary key columns input for table: user_settings */
export type User_Settings_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "user_settings" */
export enum User_Settings_Select_Column {
  /** column name */
  EmailGeneralAlerts = 'email_general_alerts',
  /** column name */
  FastRoll = 'fast_roll',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserLocale = 'user_locale'
}

/** input type for updating data in table "user_settings" */
export type User_Settings_Set_Input = {
  email_general_alerts?: InputMaybe<Scalars['Boolean']>;
  fast_roll?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_locale?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_settings" */
export type User_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Settings_Stream_Cursor_Value_Input = {
  email_general_alerts?: InputMaybe<Scalars['Boolean']>;
  fast_roll?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_locale?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_settings" */
export enum User_Settings_Update_Column {
  /** column name */
  EmailGeneralAlerts = 'email_general_alerts',
  /** column name */
  FastRoll = 'fast_roll',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserLocale = 'user_locale'
}

export type User_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Settings_Bool_Exp;
};

/** Статистика конкретного пользователя */
export type User_Statistic = {
  __typename?: 'user_statistic';
  /** A computed field, executes function "get_localized_best_item_name" */
  best_item_localized_name?: Maybe<Array<Profile_Items_Names>>;
  user_best_item?: Maybe<Scalars['jsonb']>;
  user_daily_deposits_sum: Scalars['Int'];
  user_favorite_lootbox?: Maybe<Scalars['jsonb']>;
  user_id: Scalars['uuid'];
  user_items_count?: Maybe<Scalars['Int']>;
  user_items_sold_count?: Maybe<Scalars['Int']>;
  user_items_to_steam_count?: Maybe<Scalars['Int']>;
  /** An object relationship */
  user_profile: User_Profile;
};


/** Статистика конкретного пользователя */
export type User_StatisticBest_Item_Localized_NameArgs = {
  distinct_on?: InputMaybe<Array<Profile_Items_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Items_Names_Order_By>>;
  where?: InputMaybe<Profile_Items_Names_Bool_Exp>;
};


/** Статистика конкретного пользователя */
export type User_StatisticUser_Best_ItemArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Статистика конкретного пользователя */
export type User_StatisticUser_Favorite_LootboxArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_statistic" */
export type User_Statistic_Aggregate = {
  __typename?: 'user_statistic_aggregate';
  aggregate?: Maybe<User_Statistic_Aggregate_Fields>;
  nodes: Array<User_Statistic>;
};

/** aggregate fields of "user_statistic" */
export type User_Statistic_Aggregate_Fields = {
  __typename?: 'user_statistic_aggregate_fields';
  avg?: Maybe<User_Statistic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Statistic_Max_Fields>;
  min?: Maybe<User_Statistic_Min_Fields>;
  stddev?: Maybe<User_Statistic_Stddev_Fields>;
  stddev_pop?: Maybe<User_Statistic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Statistic_Stddev_Samp_Fields>;
  sum?: Maybe<User_Statistic_Sum_Fields>;
  var_pop?: Maybe<User_Statistic_Var_Pop_Fields>;
  var_samp?: Maybe<User_Statistic_Var_Samp_Fields>;
  variance?: Maybe<User_Statistic_Variance_Fields>;
};


/** aggregate fields of "user_statistic" */
export type User_Statistic_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Statistic_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Statistic_Append_Input = {
  user_best_item?: InputMaybe<Scalars['jsonb']>;
  user_favorite_lootbox?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type User_Statistic_Avg_Fields = {
  __typename?: 'user_statistic_avg_fields';
  user_daily_deposits_sum?: Maybe<Scalars['Float']>;
  user_items_count?: Maybe<Scalars['Float']>;
  user_items_sold_count?: Maybe<Scalars['Float']>;
  user_items_to_steam_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_statistic". All fields are combined with a logical 'AND'. */
export type User_Statistic_Bool_Exp = {
  _and?: InputMaybe<Array<User_Statistic_Bool_Exp>>;
  _not?: InputMaybe<User_Statistic_Bool_Exp>;
  _or?: InputMaybe<Array<User_Statistic_Bool_Exp>>;
  best_item_localized_name?: InputMaybe<Profile_Items_Names_Bool_Exp>;
  user_best_item?: InputMaybe<Jsonb_Comparison_Exp>;
  user_daily_deposits_sum?: InputMaybe<Int_Comparison_Exp>;
  user_favorite_lootbox?: InputMaybe<Jsonb_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_items_count?: InputMaybe<Int_Comparison_Exp>;
  user_items_sold_count?: InputMaybe<Int_Comparison_Exp>;
  user_items_to_steam_count?: InputMaybe<Int_Comparison_Exp>;
  user_profile?: InputMaybe<User_Profile_Bool_Exp>;
};

/** unique or primary key constraints on table "user_statistic" */
export enum User_Statistic_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserStatisticPkey = 'user_statistic_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UserStatisticUserIdKey = 'user_statistic_user_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Statistic_Delete_At_Path_Input = {
  user_best_item?: InputMaybe<Array<Scalars['String']>>;
  user_favorite_lootbox?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Statistic_Delete_Elem_Input = {
  user_best_item?: InputMaybe<Scalars['Int']>;
  user_favorite_lootbox?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Statistic_Delete_Key_Input = {
  user_best_item?: InputMaybe<Scalars['String']>;
  user_favorite_lootbox?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user_statistic" */
export type User_Statistic_Inc_Input = {
  user_daily_deposits_sum?: InputMaybe<Scalars['Int']>;
  user_items_count?: InputMaybe<Scalars['Int']>;
  user_items_sold_count?: InputMaybe<Scalars['Int']>;
  user_items_to_steam_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_statistic" */
export type User_Statistic_Insert_Input = {
  user_best_item?: InputMaybe<Scalars['jsonb']>;
  user_daily_deposits_sum?: InputMaybe<Scalars['Int']>;
  user_favorite_lootbox?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_items_count?: InputMaybe<Scalars['Int']>;
  user_items_sold_count?: InputMaybe<Scalars['Int']>;
  user_items_to_steam_count?: InputMaybe<Scalars['Int']>;
  user_profile?: InputMaybe<User_Profile_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Statistic_Max_Fields = {
  __typename?: 'user_statistic_max_fields';
  user_daily_deposits_sum?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_items_count?: Maybe<Scalars['Int']>;
  user_items_sold_count?: Maybe<Scalars['Int']>;
  user_items_to_steam_count?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type User_Statistic_Min_Fields = {
  __typename?: 'user_statistic_min_fields';
  user_daily_deposits_sum?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_items_count?: Maybe<Scalars['Int']>;
  user_items_sold_count?: Maybe<Scalars['Int']>;
  user_items_to_steam_count?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "user_statistic" */
export type User_Statistic_Mutation_Response = {
  __typename?: 'user_statistic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Statistic>;
};

/** input type for inserting object relation for remote table "user_statistic" */
export type User_Statistic_Obj_Rel_Insert_Input = {
  data: User_Statistic_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Statistic_On_Conflict>;
};

/** on_conflict condition type for table "user_statistic" */
export type User_Statistic_On_Conflict = {
  constraint: User_Statistic_Constraint;
  update_columns?: Array<User_Statistic_Update_Column>;
  where?: InputMaybe<User_Statistic_Bool_Exp>;
};

/** Ordering options when selecting data from "user_statistic". */
export type User_Statistic_Order_By = {
  best_item_localized_name_aggregate?: InputMaybe<Profile_Items_Names_Aggregate_Order_By>;
  user_best_item?: InputMaybe<Order_By>;
  user_daily_deposits_sum?: InputMaybe<Order_By>;
  user_favorite_lootbox?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_items_count?: InputMaybe<Order_By>;
  user_items_sold_count?: InputMaybe<Order_By>;
  user_items_to_steam_count?: InputMaybe<Order_By>;
  user_profile?: InputMaybe<User_Profile_Order_By>;
};

/** primary key columns input for table: user_statistic */
export type User_Statistic_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Statistic_Prepend_Input = {
  user_best_item?: InputMaybe<Scalars['jsonb']>;
  user_favorite_lootbox?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_statistic" */
export enum User_Statistic_Select_Column {
  /** column name */
  UserBestItem = 'user_best_item',
  /** column name */
  UserDailyDepositsSum = 'user_daily_deposits_sum',
  /** column name */
  UserFavoriteLootbox = 'user_favorite_lootbox',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserItemsCount = 'user_items_count',
  /** column name */
  UserItemsSoldCount = 'user_items_sold_count',
  /** column name */
  UserItemsToSteamCount = 'user_items_to_steam_count'
}

/** input type for updating data in table "user_statistic" */
export type User_Statistic_Set_Input = {
  user_best_item?: InputMaybe<Scalars['jsonb']>;
  user_daily_deposits_sum?: InputMaybe<Scalars['Int']>;
  user_favorite_lootbox?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_items_count?: InputMaybe<Scalars['Int']>;
  user_items_sold_count?: InputMaybe<Scalars['Int']>;
  user_items_to_steam_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_Statistic_Stddev_Fields = {
  __typename?: 'user_statistic_stddev_fields';
  user_daily_deposits_sum?: Maybe<Scalars['Float']>;
  user_items_count?: Maybe<Scalars['Float']>;
  user_items_sold_count?: Maybe<Scalars['Float']>;
  user_items_to_steam_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Statistic_Stddev_Pop_Fields = {
  __typename?: 'user_statistic_stddev_pop_fields';
  user_daily_deposits_sum?: Maybe<Scalars['Float']>;
  user_items_count?: Maybe<Scalars['Float']>;
  user_items_sold_count?: Maybe<Scalars['Float']>;
  user_items_to_steam_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Statistic_Stddev_Samp_Fields = {
  __typename?: 'user_statistic_stddev_samp_fields';
  user_daily_deposits_sum?: Maybe<Scalars['Float']>;
  user_items_count?: Maybe<Scalars['Float']>;
  user_items_sold_count?: Maybe<Scalars['Float']>;
  user_items_to_steam_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_statistic" */
export type User_Statistic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Statistic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Statistic_Stream_Cursor_Value_Input = {
  user_best_item?: InputMaybe<Scalars['jsonb']>;
  user_daily_deposits_sum?: InputMaybe<Scalars['Int']>;
  user_favorite_lootbox?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_items_count?: InputMaybe<Scalars['Int']>;
  user_items_sold_count?: InputMaybe<Scalars['Int']>;
  user_items_to_steam_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type User_Statistic_Sum_Fields = {
  __typename?: 'user_statistic_sum_fields';
  user_daily_deposits_sum?: Maybe<Scalars['Int']>;
  user_items_count?: Maybe<Scalars['Int']>;
  user_items_sold_count?: Maybe<Scalars['Int']>;
  user_items_to_steam_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_statistic" */
export enum User_Statistic_Update_Column {
  /** column name */
  UserBestItem = 'user_best_item',
  /** column name */
  UserDailyDepositsSum = 'user_daily_deposits_sum',
  /** column name */
  UserFavoriteLootbox = 'user_favorite_lootbox',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserItemsCount = 'user_items_count',
  /** column name */
  UserItemsSoldCount = 'user_items_sold_count',
  /** column name */
  UserItemsToSteamCount = 'user_items_to_steam_count'
}

export type User_Statistic_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Statistic_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Statistic_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Statistic_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Statistic_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Statistic_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Statistic_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Statistic_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Statistic_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Statistic_Var_Pop_Fields = {
  __typename?: 'user_statistic_var_pop_fields';
  user_daily_deposits_sum?: Maybe<Scalars['Float']>;
  user_items_count?: Maybe<Scalars['Float']>;
  user_items_sold_count?: Maybe<Scalars['Float']>;
  user_items_to_steam_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Statistic_Var_Samp_Fields = {
  __typename?: 'user_statistic_var_samp_fields';
  user_daily_deposits_sum?: Maybe<Scalars['Float']>;
  user_items_count?: Maybe<Scalars['Float']>;
  user_items_sold_count?: Maybe<Scalars['Float']>;
  user_items_to_steam_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Statistic_Variance_Fields = {
  __typename?: 'user_statistic_variance_fields';
  user_daily_deposits_sum?: Maybe<Scalars['Float']>;
  user_items_count?: Maybe<Scalars['Float']>;
  user_items_sold_count?: Maybe<Scalars['Float']>;
  user_items_to_steam_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  age?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  age?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  age?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_steam_output_alternatives" */
export type User_Steam_Output_Alternatives = {
  __typename?: 'user_steam_output_alternatives';
  alt_used: Scalars['Boolean'];
  alternatives: Scalars['jsonb'];
  created_at: Scalars['timestamp'];
  item_hash_name: Scalars['String'];
  item_start_price: Scalars['Int'];
  output_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
};


/** columns and relationships of "user_steam_output_alternatives" */
export type User_Steam_Output_AlternativesAlternativesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_steam_output_alternatives" */
export type User_Steam_Output_Alternatives_Aggregate = {
  __typename?: 'user_steam_output_alternatives_aggregate';
  aggregate?: Maybe<User_Steam_Output_Alternatives_Aggregate_Fields>;
  nodes: Array<User_Steam_Output_Alternatives>;
};

/** aggregate fields of "user_steam_output_alternatives" */
export type User_Steam_Output_Alternatives_Aggregate_Fields = {
  __typename?: 'user_steam_output_alternatives_aggregate_fields';
  avg?: Maybe<User_Steam_Output_Alternatives_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Steam_Output_Alternatives_Max_Fields>;
  min?: Maybe<User_Steam_Output_Alternatives_Min_Fields>;
  stddev?: Maybe<User_Steam_Output_Alternatives_Stddev_Fields>;
  stddev_pop?: Maybe<User_Steam_Output_Alternatives_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Steam_Output_Alternatives_Stddev_Samp_Fields>;
  sum?: Maybe<User_Steam_Output_Alternatives_Sum_Fields>;
  var_pop?: Maybe<User_Steam_Output_Alternatives_Var_Pop_Fields>;
  var_samp?: Maybe<User_Steam_Output_Alternatives_Var_Samp_Fields>;
  variance?: Maybe<User_Steam_Output_Alternatives_Variance_Fields>;
};


/** aggregate fields of "user_steam_output_alternatives" */
export type User_Steam_Output_Alternatives_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Steam_Output_Alternatives_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Steam_Output_Alternatives_Append_Input = {
  alternatives?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type User_Steam_Output_Alternatives_Avg_Fields = {
  __typename?: 'user_steam_output_alternatives_avg_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_steam_output_alternatives". All fields are combined with a logical 'AND'. */
export type User_Steam_Output_Alternatives_Bool_Exp = {
  _and?: InputMaybe<Array<User_Steam_Output_Alternatives_Bool_Exp>>;
  _not?: InputMaybe<User_Steam_Output_Alternatives_Bool_Exp>;
  _or?: InputMaybe<Array<User_Steam_Output_Alternatives_Bool_Exp>>;
  alt_used?: InputMaybe<Boolean_Comparison_Exp>;
  alternatives?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  item_hash_name?: InputMaybe<String_Comparison_Exp>;
  item_start_price?: InputMaybe<Int_Comparison_Exp>;
  output_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_item_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_steam_output_alternatives" */
export enum User_Steam_Output_Alternatives_Constraint {
  /** unique or primary key constraint on columns "output_id" */
  UserSteamOutputAlternativesPkey = 'user_steam_output_alternatives_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Steam_Output_Alternatives_Delete_At_Path_Input = {
  alternatives?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Steam_Output_Alternatives_Delete_Elem_Input = {
  alternatives?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Steam_Output_Alternatives_Delete_Key_Input = {
  alternatives?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user_steam_output_alternatives" */
export type User_Steam_Output_Alternatives_Inc_Input = {
  item_start_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_steam_output_alternatives" */
export type User_Steam_Output_Alternatives_Insert_Input = {
  alt_used?: InputMaybe<Scalars['Boolean']>;
  alternatives?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Int']>;
  output_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Steam_Output_Alternatives_Max_Fields = {
  __typename?: 'user_steam_output_alternatives_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Int']>;
  output_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Steam_Output_Alternatives_Min_Fields = {
  __typename?: 'user_steam_output_alternatives_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  item_hash_name?: Maybe<Scalars['String']>;
  item_start_price?: Maybe<Scalars['Int']>;
  output_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_steam_output_alternatives" */
export type User_Steam_Output_Alternatives_Mutation_Response = {
  __typename?: 'user_steam_output_alternatives_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Steam_Output_Alternatives>;
};

/** on_conflict condition type for table "user_steam_output_alternatives" */
export type User_Steam_Output_Alternatives_On_Conflict = {
  constraint: User_Steam_Output_Alternatives_Constraint;
  update_columns?: Array<User_Steam_Output_Alternatives_Update_Column>;
  where?: InputMaybe<User_Steam_Output_Alternatives_Bool_Exp>;
};

/** Ordering options when selecting data from "user_steam_output_alternatives". */
export type User_Steam_Output_Alternatives_Order_By = {
  alt_used?: InputMaybe<Order_By>;
  alternatives?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  item_hash_name?: InputMaybe<Order_By>;
  item_start_price?: InputMaybe<Order_By>;
  output_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_item_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_steam_output_alternatives */
export type User_Steam_Output_Alternatives_Pk_Columns_Input = {
  output_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Steam_Output_Alternatives_Prepend_Input = {
  alternatives?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_steam_output_alternatives" */
export enum User_Steam_Output_Alternatives_Select_Column {
  /** column name */
  AltUsed = 'alt_used',
  /** column name */
  Alternatives = 'alternatives',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  OutputId = 'output_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserItemId = 'user_item_id'
}

/** input type for updating data in table "user_steam_output_alternatives" */
export type User_Steam_Output_Alternatives_Set_Input = {
  alt_used?: InputMaybe<Scalars['Boolean']>;
  alternatives?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Int']>;
  output_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Steam_Output_Alternatives_Stddev_Fields = {
  __typename?: 'user_steam_output_alternatives_stddev_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Steam_Output_Alternatives_Stddev_Pop_Fields = {
  __typename?: 'user_steam_output_alternatives_stddev_pop_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Steam_Output_Alternatives_Stddev_Samp_Fields = {
  __typename?: 'user_steam_output_alternatives_stddev_samp_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_steam_output_alternatives" */
export type User_Steam_Output_Alternatives_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Steam_Output_Alternatives_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Steam_Output_Alternatives_Stream_Cursor_Value_Input = {
  alt_used?: InputMaybe<Scalars['Boolean']>;
  alternatives?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_hash_name?: InputMaybe<Scalars['String']>;
  item_start_price?: InputMaybe<Scalars['Int']>;
  output_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Steam_Output_Alternatives_Sum_Fields = {
  __typename?: 'user_steam_output_alternatives_sum_fields';
  item_start_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_steam_output_alternatives" */
export enum User_Steam_Output_Alternatives_Update_Column {
  /** column name */
  AltUsed = 'alt_used',
  /** column name */
  Alternatives = 'alternatives',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ItemHashName = 'item_hash_name',
  /** column name */
  ItemStartPrice = 'item_start_price',
  /** column name */
  OutputId = 'output_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserItemId = 'user_item_id'
}

export type User_Steam_Output_Alternatives_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Steam_Output_Alternatives_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Steam_Output_Alternatives_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Steam_Output_Alternatives_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Steam_Output_Alternatives_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Steam_Output_Alternatives_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Steam_Output_Alternatives_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Steam_Output_Alternatives_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Steam_Output_Alternatives_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Steam_Output_Alternatives_Var_Pop_Fields = {
  __typename?: 'user_steam_output_alternatives_var_pop_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Steam_Output_Alternatives_Var_Samp_Fields = {
  __typename?: 'user_steam_output_alternatives_var_samp_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Steam_Output_Alternatives_Variance_Fields = {
  __typename?: 'user_steam_output_alternatives_variance_fields';
  item_start_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_steam_output_watcher" */
export type User_Steam_Output_Watcher = {
  __typename?: 'user_steam_output_watcher';
  active: Scalars['Boolean'];
  alternative_item_data?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamp'];
  item_id: Scalars['uuid'];
  market_data?: Maybe<Scalars['jsonb']>;
  operation_id?: Maybe<Scalars['uuid']>;
  order_custom_id: Scalars['uuid'];
  status_datetime?: Maybe<Scalars['timestamp']>;
  time?: Maybe<Scalars['Float']>;
  transaction_last_status: Scalars['Int'];
  /** An object relationship */
  user_account?: Maybe<User_Account>;
  user_id?: Maybe<Scalars['uuid']>;
  user_inventory_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
  /** An object relationship */
  user_items_in_inventory?: Maybe<User_Items_In_Inventory>;
};


/** columns and relationships of "user_steam_output_watcher" */
export type User_Steam_Output_WatcherAlternative_Item_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "user_steam_output_watcher" */
export type User_Steam_Output_WatcherMarket_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_steam_output_watcher" */
export type User_Steam_Output_Watcher_Aggregate = {
  __typename?: 'user_steam_output_watcher_aggregate';
  aggregate?: Maybe<User_Steam_Output_Watcher_Aggregate_Fields>;
  nodes: Array<User_Steam_Output_Watcher>;
};

/** aggregate fields of "user_steam_output_watcher" */
export type User_Steam_Output_Watcher_Aggregate_Fields = {
  __typename?: 'user_steam_output_watcher_aggregate_fields';
  avg?: Maybe<User_Steam_Output_Watcher_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Steam_Output_Watcher_Max_Fields>;
  min?: Maybe<User_Steam_Output_Watcher_Min_Fields>;
  stddev?: Maybe<User_Steam_Output_Watcher_Stddev_Fields>;
  stddev_pop?: Maybe<User_Steam_Output_Watcher_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Steam_Output_Watcher_Stddev_Samp_Fields>;
  sum?: Maybe<User_Steam_Output_Watcher_Sum_Fields>;
  var_pop?: Maybe<User_Steam_Output_Watcher_Var_Pop_Fields>;
  var_samp?: Maybe<User_Steam_Output_Watcher_Var_Samp_Fields>;
  variance?: Maybe<User_Steam_Output_Watcher_Variance_Fields>;
};


/** aggregate fields of "user_steam_output_watcher" */
export type User_Steam_Output_Watcher_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Steam_Output_Watcher_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Steam_Output_Watcher_Append_Input = {
  alternative_item_data?: InputMaybe<Scalars['jsonb']>;
  market_data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type User_Steam_Output_Watcher_Avg_Fields = {
  __typename?: 'user_steam_output_watcher_avg_fields';
  time?: Maybe<Scalars['Float']>;
  transaction_last_status?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_steam_output_watcher". All fields are combined with a logical 'AND'. */
export type User_Steam_Output_Watcher_Bool_Exp = {
  _and?: InputMaybe<Array<User_Steam_Output_Watcher_Bool_Exp>>;
  _not?: InputMaybe<User_Steam_Output_Watcher_Bool_Exp>;
  _or?: InputMaybe<Array<User_Steam_Output_Watcher_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  alternative_item_data?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  market_data?: InputMaybe<Jsonb_Comparison_Exp>;
  operation_id?: InputMaybe<Uuid_Comparison_Exp>;
  order_custom_id?: InputMaybe<Uuid_Comparison_Exp>;
  status_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  time?: InputMaybe<Float_Comparison_Exp>;
  transaction_last_status?: InputMaybe<Int_Comparison_Exp>;
  user_account?: InputMaybe<User_Account_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_inventory_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_items_in_inventory?: InputMaybe<User_Items_In_Inventory_Bool_Exp>;
};

/** unique or primary key constraints on table "user_steam_output_watcher" */
export enum User_Steam_Output_Watcher_Constraint {
  /** unique or primary key constraint on columns "order_custom_id" */
  UserSteamOutputWatcherPkey = 'user_steam_output_watcher_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Steam_Output_Watcher_Delete_At_Path_Input = {
  alternative_item_data?: InputMaybe<Array<Scalars['String']>>;
  market_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Steam_Output_Watcher_Delete_Elem_Input = {
  alternative_item_data?: InputMaybe<Scalars['Int']>;
  market_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Steam_Output_Watcher_Delete_Key_Input = {
  alternative_item_data?: InputMaybe<Scalars['String']>;
  market_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user_steam_output_watcher" */
export type User_Steam_Output_Watcher_Inc_Input = {
  time?: InputMaybe<Scalars['Float']>;
  transaction_last_status?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_steam_output_watcher" */
export type User_Steam_Output_Watcher_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  alternative_item_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  market_data?: InputMaybe<Scalars['jsonb']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  order_custom_id?: InputMaybe<Scalars['uuid']>;
  status_datetime?: InputMaybe<Scalars['timestamp']>;
  time?: InputMaybe<Scalars['Float']>;
  transaction_last_status?: InputMaybe<Scalars['Int']>;
  user_account?: InputMaybe<User_Account_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
  user_items_in_inventory?: InputMaybe<User_Items_In_Inventory_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Steam_Output_Watcher_Max_Fields = {
  __typename?: 'user_steam_output_watcher_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  item_id?: Maybe<Scalars['uuid']>;
  operation_id?: Maybe<Scalars['uuid']>;
  order_custom_id?: Maybe<Scalars['uuid']>;
  status_datetime?: Maybe<Scalars['timestamp']>;
  time?: Maybe<Scalars['Float']>;
  transaction_last_status?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Steam_Output_Watcher_Min_Fields = {
  __typename?: 'user_steam_output_watcher_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  item_id?: Maybe<Scalars['uuid']>;
  operation_id?: Maybe<Scalars['uuid']>;
  order_custom_id?: Maybe<Scalars['uuid']>;
  status_datetime?: Maybe<Scalars['timestamp']>;
  time?: Maybe<Scalars['Float']>;
  transaction_last_status?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_inventory_id?: Maybe<Scalars['uuid']>;
  user_item_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_steam_output_watcher" */
export type User_Steam_Output_Watcher_Mutation_Response = {
  __typename?: 'user_steam_output_watcher_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Steam_Output_Watcher>;
};

/** on_conflict condition type for table "user_steam_output_watcher" */
export type User_Steam_Output_Watcher_On_Conflict = {
  constraint: User_Steam_Output_Watcher_Constraint;
  update_columns?: Array<User_Steam_Output_Watcher_Update_Column>;
  where?: InputMaybe<User_Steam_Output_Watcher_Bool_Exp>;
};

/** Ordering options when selecting data from "user_steam_output_watcher". */
export type User_Steam_Output_Watcher_Order_By = {
  active?: InputMaybe<Order_By>;
  alternative_item_data?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  market_data?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_custom_id?: InputMaybe<Order_By>;
  status_datetime?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  transaction_last_status?: InputMaybe<Order_By>;
  user_account?: InputMaybe<User_Account_Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_inventory_id?: InputMaybe<Order_By>;
  user_item_id?: InputMaybe<Order_By>;
  user_items_in_inventory?: InputMaybe<User_Items_In_Inventory_Order_By>;
};

/** primary key columns input for table: user_steam_output_watcher */
export type User_Steam_Output_Watcher_Pk_Columns_Input = {
  order_custom_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Steam_Output_Watcher_Prepend_Input = {
  alternative_item_data?: InputMaybe<Scalars['jsonb']>;
  market_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_steam_output_watcher" */
export enum User_Steam_Output_Watcher_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AlternativeItemData = 'alternative_item_data',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  MarketData = 'market_data',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OrderCustomId = 'order_custom_id',
  /** column name */
  StatusDatetime = 'status_datetime',
  /** column name */
  Time = 'time',
  /** column name */
  TransactionLastStatus = 'transaction_last_status',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserItemId = 'user_item_id'
}

/** input type for updating data in table "user_steam_output_watcher" */
export type User_Steam_Output_Watcher_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  alternative_item_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  market_data?: InputMaybe<Scalars['jsonb']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  order_custom_id?: InputMaybe<Scalars['uuid']>;
  status_datetime?: InputMaybe<Scalars['timestamp']>;
  time?: InputMaybe<Scalars['Float']>;
  transaction_last_status?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Steam_Output_Watcher_Stddev_Fields = {
  __typename?: 'user_steam_output_watcher_stddev_fields';
  time?: Maybe<Scalars['Float']>;
  transaction_last_status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Steam_Output_Watcher_Stddev_Pop_Fields = {
  __typename?: 'user_steam_output_watcher_stddev_pop_fields';
  time?: Maybe<Scalars['Float']>;
  transaction_last_status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Steam_Output_Watcher_Stddev_Samp_Fields = {
  __typename?: 'user_steam_output_watcher_stddev_samp_fields';
  time?: Maybe<Scalars['Float']>;
  transaction_last_status?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_steam_output_watcher" */
export type User_Steam_Output_Watcher_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Steam_Output_Watcher_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Steam_Output_Watcher_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  alternative_item_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  market_data?: InputMaybe<Scalars['jsonb']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  order_custom_id?: InputMaybe<Scalars['uuid']>;
  status_datetime?: InputMaybe<Scalars['timestamp']>;
  time?: InputMaybe<Scalars['Float']>;
  transaction_last_status?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_inventory_id?: InputMaybe<Scalars['uuid']>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Steam_Output_Watcher_Sum_Fields = {
  __typename?: 'user_steam_output_watcher_sum_fields';
  time?: Maybe<Scalars['Float']>;
  transaction_last_status?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_steam_output_watcher" */
export enum User_Steam_Output_Watcher_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AlternativeItemData = 'alternative_item_data',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  MarketData = 'market_data',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OrderCustomId = 'order_custom_id',
  /** column name */
  StatusDatetime = 'status_datetime',
  /** column name */
  Time = 'time',
  /** column name */
  TransactionLastStatus = 'transaction_last_status',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserInventoryId = 'user_inventory_id',
  /** column name */
  UserItemId = 'user_item_id'
}

export type User_Steam_Output_Watcher_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Steam_Output_Watcher_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Steam_Output_Watcher_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Steam_Output_Watcher_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Steam_Output_Watcher_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Steam_Output_Watcher_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Steam_Output_Watcher_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Steam_Output_Watcher_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Steam_Output_Watcher_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Steam_Output_Watcher_Var_Pop_Fields = {
  __typename?: 'user_steam_output_watcher_var_pop_fields';
  time?: Maybe<Scalars['Float']>;
  transaction_last_status?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Steam_Output_Watcher_Var_Samp_Fields = {
  __typename?: 'user_steam_output_watcher_var_samp_fields';
  time?: Maybe<Scalars['Float']>;
  transaction_last_status?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Steam_Output_Watcher_Variance_Fields = {
  __typename?: 'user_steam_output_watcher_variance_fields';
  time?: Maybe<Scalars['Float']>;
  transaction_last_status?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  age?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  age?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "user_takes_free_lootbox" */
export type User_Takes_Free_Lootbox = {
  __typename?: 'user_takes_free_lootbox';
  free_lootbox?: Maybe<Lootboxes_Free>;
  lootbox_id: Scalars['uuid'];
  time: Scalars['timestamp'];
  user_account?: Maybe<User_Account>;
  user_id: Scalars['uuid'];
  user_profile_statistic?: Maybe<User_Statistic>;
  user_statistic?: Maybe<User_Statistic>;
};

/** aggregated selection of "user_takes_free_lootbox" */
export type User_Takes_Free_Lootbox_Aggregate = {
  __typename?: 'user_takes_free_lootbox_aggregate';
  aggregate?: Maybe<User_Takes_Free_Lootbox_Aggregate_Fields>;
  nodes: Array<User_Takes_Free_Lootbox>;
};

/** aggregate fields of "user_takes_free_lootbox" */
export type User_Takes_Free_Lootbox_Aggregate_Fields = {
  __typename?: 'user_takes_free_lootbox_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Takes_Free_Lootbox_Max_Fields>;
  min?: Maybe<User_Takes_Free_Lootbox_Min_Fields>;
};


/** aggregate fields of "user_takes_free_lootbox" */
export type User_Takes_Free_Lootbox_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Takes_Free_Lootbox_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_takes_free_lootbox". All fields are combined with a logical 'AND'. */
export type User_Takes_Free_Lootbox_Bool_Exp = {
  _and?: InputMaybe<Array<User_Takes_Free_Lootbox_Bool_Exp>>;
  _not?: InputMaybe<User_Takes_Free_Lootbox_Bool_Exp>;
  _or?: InputMaybe<Array<User_Takes_Free_Lootbox_Bool_Exp>>;
  lootbox_id?: InputMaybe<Uuid_Comparison_Exp>;
  time?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_takes_free_lootbox" */
export enum User_Takes_Free_Lootbox_Constraint {
  /** unique or primary key constraint on columns "lootbox_id", "user_id" */
  UserTakesFreeLootboxPkey = 'user_takes_free_lootbox_pkey'
}

/** input type for inserting data into table "user_takes_free_lootbox" */
export type User_Takes_Free_Lootbox_Insert_Input = {
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  time?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Takes_Free_Lootbox_Max_Fields = {
  __typename?: 'user_takes_free_lootbox_max_fields';
  lootbox_id?: Maybe<Scalars['uuid']>;
  time?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Takes_Free_Lootbox_Min_Fields = {
  __typename?: 'user_takes_free_lootbox_min_fields';
  lootbox_id?: Maybe<Scalars['uuid']>;
  time?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_takes_free_lootbox" */
export type User_Takes_Free_Lootbox_Mutation_Response = {
  __typename?: 'user_takes_free_lootbox_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Takes_Free_Lootbox>;
};

/** on_conflict condition type for table "user_takes_free_lootbox" */
export type User_Takes_Free_Lootbox_On_Conflict = {
  constraint: User_Takes_Free_Lootbox_Constraint;
  update_columns?: Array<User_Takes_Free_Lootbox_Update_Column>;
  where?: InputMaybe<User_Takes_Free_Lootbox_Bool_Exp>;
};

/** Ordering options when selecting data from "user_takes_free_lootbox". */
export type User_Takes_Free_Lootbox_Order_By = {
  lootbox_id?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_takes_free_lootbox */
export type User_Takes_Free_Lootbox_Pk_Columns_Input = {
  lootbox_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "user_takes_free_lootbox" */
export enum User_Takes_Free_Lootbox_Select_Column {
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_takes_free_lootbox" */
export type User_Takes_Free_Lootbox_Set_Input = {
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  time?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_takes_free_lootbox" */
export type User_Takes_Free_Lootbox_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Takes_Free_Lootbox_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Takes_Free_Lootbox_Stream_Cursor_Value_Input = {
  lootbox_id?: InputMaybe<Scalars['uuid']>;
  time?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_takes_free_lootbox" */
export enum User_Takes_Free_Lootbox_Update_Column {
  /** column name */
  LootboxId = 'lootbox_id',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id'
}

export type User_Takes_Free_Lootbox_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Takes_Free_Lootbox_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Takes_Free_Lootbox_Bool_Exp;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** columns and relationships of "user_upgrade_params" */
export type User_Upgrade_Params = {
  __typename?: 'user_upgrade_params';
  param_name: Scalars['String'];
  param_value_dig?: Maybe<Scalars['Float']>;
  param_value_txt?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user_upgrade_params" */
export type User_Upgrade_Params_Aggregate = {
  __typename?: 'user_upgrade_params_aggregate';
  aggregate?: Maybe<User_Upgrade_Params_Aggregate_Fields>;
  nodes: Array<User_Upgrade_Params>;
};

/** aggregate fields of "user_upgrade_params" */
export type User_Upgrade_Params_Aggregate_Fields = {
  __typename?: 'user_upgrade_params_aggregate_fields';
  avg?: Maybe<User_Upgrade_Params_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Upgrade_Params_Max_Fields>;
  min?: Maybe<User_Upgrade_Params_Min_Fields>;
  stddev?: Maybe<User_Upgrade_Params_Stddev_Fields>;
  stddev_pop?: Maybe<User_Upgrade_Params_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Upgrade_Params_Stddev_Samp_Fields>;
  sum?: Maybe<User_Upgrade_Params_Sum_Fields>;
  var_pop?: Maybe<User_Upgrade_Params_Var_Pop_Fields>;
  var_samp?: Maybe<User_Upgrade_Params_Var_Samp_Fields>;
  variance?: Maybe<User_Upgrade_Params_Variance_Fields>;
};


/** aggregate fields of "user_upgrade_params" */
export type User_Upgrade_Params_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Upgrade_Params_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Upgrade_Params_Avg_Fields = {
  __typename?: 'user_upgrade_params_avg_fields';
  param_value_dig?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_upgrade_params". All fields are combined with a logical 'AND'. */
export type User_Upgrade_Params_Bool_Exp = {
  _and?: InputMaybe<Array<User_Upgrade_Params_Bool_Exp>>;
  _not?: InputMaybe<User_Upgrade_Params_Bool_Exp>;
  _or?: InputMaybe<Array<User_Upgrade_Params_Bool_Exp>>;
  param_name?: InputMaybe<String_Comparison_Exp>;
  param_value_dig?: InputMaybe<Float_Comparison_Exp>;
  param_value_txt?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_upgrade_params" */
export enum User_Upgrade_Params_Constraint {
  /** unique or primary key constraint on columns "param_name" */
  UserUpgradeParamsPkey = 'user_upgrade_params_pkey'
}

/** input type for incrementing numeric columns in table "user_upgrade_params" */
export type User_Upgrade_Params_Inc_Input = {
  param_value_dig?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "user_upgrade_params" */
export type User_Upgrade_Params_Insert_Input = {
  param_name?: InputMaybe<Scalars['String']>;
  param_value_dig?: InputMaybe<Scalars['Float']>;
  param_value_txt?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Upgrade_Params_Max_Fields = {
  __typename?: 'user_upgrade_params_max_fields';
  param_name?: Maybe<Scalars['String']>;
  param_value_dig?: Maybe<Scalars['Float']>;
  param_value_txt?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Upgrade_Params_Min_Fields = {
  __typename?: 'user_upgrade_params_min_fields';
  param_name?: Maybe<Scalars['String']>;
  param_value_dig?: Maybe<Scalars['Float']>;
  param_value_txt?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_upgrade_params" */
export type User_Upgrade_Params_Mutation_Response = {
  __typename?: 'user_upgrade_params_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Upgrade_Params>;
};

/** on_conflict condition type for table "user_upgrade_params" */
export type User_Upgrade_Params_On_Conflict = {
  constraint: User_Upgrade_Params_Constraint;
  update_columns?: Array<User_Upgrade_Params_Update_Column>;
  where?: InputMaybe<User_Upgrade_Params_Bool_Exp>;
};

/** Ordering options when selecting data from "user_upgrade_params". */
export type User_Upgrade_Params_Order_By = {
  param_name?: InputMaybe<Order_By>;
  param_value_dig?: InputMaybe<Order_By>;
  param_value_txt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_upgrade_params */
export type User_Upgrade_Params_Pk_Columns_Input = {
  param_name: Scalars['String'];
};

/** select columns of table "user_upgrade_params" */
export enum User_Upgrade_Params_Select_Column {
  /** column name */
  ParamName = 'param_name',
  /** column name */
  ParamValueDig = 'param_value_dig',
  /** column name */
  ParamValueTxt = 'param_value_txt'
}

/** input type for updating data in table "user_upgrade_params" */
export type User_Upgrade_Params_Set_Input = {
  param_name?: InputMaybe<Scalars['String']>;
  param_value_dig?: InputMaybe<Scalars['Float']>;
  param_value_txt?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Upgrade_Params_Stddev_Fields = {
  __typename?: 'user_upgrade_params_stddev_fields';
  param_value_dig?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Upgrade_Params_Stddev_Pop_Fields = {
  __typename?: 'user_upgrade_params_stddev_pop_fields';
  param_value_dig?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Upgrade_Params_Stddev_Samp_Fields = {
  __typename?: 'user_upgrade_params_stddev_samp_fields';
  param_value_dig?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_upgrade_params" */
export type User_Upgrade_Params_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Upgrade_Params_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Upgrade_Params_Stream_Cursor_Value_Input = {
  param_name?: InputMaybe<Scalars['String']>;
  param_value_dig?: InputMaybe<Scalars['Float']>;
  param_value_txt?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Upgrade_Params_Sum_Fields = {
  __typename?: 'user_upgrade_params_sum_fields';
  param_value_dig?: Maybe<Scalars['Float']>;
};

/** update columns of table "user_upgrade_params" */
export enum User_Upgrade_Params_Update_Column {
  /** column name */
  ParamName = 'param_name',
  /** column name */
  ParamValueDig = 'param_value_dig',
  /** column name */
  ParamValueTxt = 'param_value_txt'
}

export type User_Upgrade_Params_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Upgrade_Params_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Upgrade_Params_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Upgrade_Params_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Upgrade_Params_Var_Pop_Fields = {
  __typename?: 'user_upgrade_params_var_pop_fields';
  param_value_dig?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Upgrade_Params_Var_Samp_Fields = {
  __typename?: 'user_upgrade_params_var_samp_fields';
  param_value_dig?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Upgrade_Params_Variance_Fields = {
  __typename?: 'user_upgrade_params_variance_fields';
  param_value_dig?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_upgrade_results" */
export type User_Upgrade_Results = {
  __typename?: 'user_upgrade_results';
  boost_boosters?: Maybe<Scalars['jsonb']>;
  boost_coins?: Maybe<Scalars['Int']>;
  boost_items?: Maybe<Scalars['jsonb']>;
  chance: Scalars['Float'];
  created_at?: Maybe<Scalars['timestamp']>;
  koef: Scalars['Float'];
  result: Scalars['Boolean'];
  start_item_data: Scalars['jsonb'];
  start_item_id: Scalars['uuid'];
  target_item_data: Scalars['jsonb'];
  target_item_hash_name: Scalars['String'];
  target_item_id: Scalars['uuid'];
  target_item_price: Scalars['Int'];
  user_account?: Maybe<User_Account>;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "user_upgrade_results" */
export type User_Upgrade_ResultsBoost_BoostersArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "user_upgrade_results" */
export type User_Upgrade_ResultsBoost_ItemsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "user_upgrade_results" */
export type User_Upgrade_ResultsStart_Item_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "user_upgrade_results" */
export type User_Upgrade_ResultsTarget_Item_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_upgrade_results" */
export type User_Upgrade_Results_Aggregate = {
  __typename?: 'user_upgrade_results_aggregate';
  aggregate?: Maybe<User_Upgrade_Results_Aggregate_Fields>;
  nodes: Array<User_Upgrade_Results>;
};

/** aggregate fields of "user_upgrade_results" */
export type User_Upgrade_Results_Aggregate_Fields = {
  __typename?: 'user_upgrade_results_aggregate_fields';
  avg?: Maybe<User_Upgrade_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Upgrade_Results_Max_Fields>;
  min?: Maybe<User_Upgrade_Results_Min_Fields>;
  stddev?: Maybe<User_Upgrade_Results_Stddev_Fields>;
  stddev_pop?: Maybe<User_Upgrade_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Upgrade_Results_Stddev_Samp_Fields>;
  sum?: Maybe<User_Upgrade_Results_Sum_Fields>;
  var_pop?: Maybe<User_Upgrade_Results_Var_Pop_Fields>;
  var_samp?: Maybe<User_Upgrade_Results_Var_Samp_Fields>;
  variance?: Maybe<User_Upgrade_Results_Variance_Fields>;
};


/** aggregate fields of "user_upgrade_results" */
export type User_Upgrade_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Upgrade_Results_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Upgrade_Results_Append_Input = {
  boost_boosters?: InputMaybe<Scalars['jsonb']>;
  boost_items?: InputMaybe<Scalars['jsonb']>;
  start_item_data?: InputMaybe<Scalars['jsonb']>;
  target_item_data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type User_Upgrade_Results_Avg_Fields = {
  __typename?: 'user_upgrade_results_avg_fields';
  boost_coins?: Maybe<Scalars['Float']>;
  chance?: Maybe<Scalars['Float']>;
  koef?: Maybe<Scalars['Float']>;
  target_item_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_upgrade_results". All fields are combined with a logical 'AND'. */
export type User_Upgrade_Results_Bool_Exp = {
  _and?: InputMaybe<Array<User_Upgrade_Results_Bool_Exp>>;
  _not?: InputMaybe<User_Upgrade_Results_Bool_Exp>;
  _or?: InputMaybe<Array<User_Upgrade_Results_Bool_Exp>>;
  boost_boosters?: InputMaybe<Jsonb_Comparison_Exp>;
  boost_coins?: InputMaybe<Int_Comparison_Exp>;
  boost_items?: InputMaybe<Jsonb_Comparison_Exp>;
  chance?: InputMaybe<Float_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  koef?: InputMaybe<Float_Comparison_Exp>;
  result?: InputMaybe<Boolean_Comparison_Exp>;
  start_item_data?: InputMaybe<Jsonb_Comparison_Exp>;
  start_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  target_item_data?: InputMaybe<Jsonb_Comparison_Exp>;
  target_item_hash_name?: InputMaybe<String_Comparison_Exp>;
  target_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  target_item_price?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_upgrade_results" */
export enum User_Upgrade_Results_Constraint {
  /** unique or primary key constraint on columns "start_item_id", "user_id" */
  UserUpgradeResultsPkey = 'user_upgrade_results_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Upgrade_Results_Delete_At_Path_Input = {
  boost_boosters?: InputMaybe<Array<Scalars['String']>>;
  boost_items?: InputMaybe<Array<Scalars['String']>>;
  start_item_data?: InputMaybe<Array<Scalars['String']>>;
  target_item_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Upgrade_Results_Delete_Elem_Input = {
  boost_boosters?: InputMaybe<Scalars['Int']>;
  boost_items?: InputMaybe<Scalars['Int']>;
  start_item_data?: InputMaybe<Scalars['Int']>;
  target_item_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Upgrade_Results_Delete_Key_Input = {
  boost_boosters?: InputMaybe<Scalars['String']>;
  boost_items?: InputMaybe<Scalars['String']>;
  start_item_data?: InputMaybe<Scalars['String']>;
  target_item_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "user_upgrade_results" */
export type User_Upgrade_Results_Inc_Input = {
  boost_coins?: InputMaybe<Scalars['Int']>;
  chance?: InputMaybe<Scalars['Float']>;
  koef?: InputMaybe<Scalars['Float']>;
  target_item_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_upgrade_results" */
export type User_Upgrade_Results_Insert_Input = {
  boost_boosters?: InputMaybe<Scalars['jsonb']>;
  boost_coins?: InputMaybe<Scalars['Int']>;
  boost_items?: InputMaybe<Scalars['jsonb']>;
  chance?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  koef?: InputMaybe<Scalars['Float']>;
  result?: InputMaybe<Scalars['Boolean']>;
  start_item_data?: InputMaybe<Scalars['jsonb']>;
  start_item_id?: InputMaybe<Scalars['uuid']>;
  target_item_data?: InputMaybe<Scalars['jsonb']>;
  target_item_hash_name?: InputMaybe<Scalars['String']>;
  target_item_id?: InputMaybe<Scalars['uuid']>;
  target_item_price?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Upgrade_Results_Max_Fields = {
  __typename?: 'user_upgrade_results_max_fields';
  boost_coins?: Maybe<Scalars['Int']>;
  chance?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['timestamp']>;
  koef?: Maybe<Scalars['Float']>;
  start_item_id?: Maybe<Scalars['uuid']>;
  target_item_hash_name?: Maybe<Scalars['String']>;
  target_item_id?: Maybe<Scalars['uuid']>;
  target_item_price?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Upgrade_Results_Min_Fields = {
  __typename?: 'user_upgrade_results_min_fields';
  boost_coins?: Maybe<Scalars['Int']>;
  chance?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['timestamp']>;
  koef?: Maybe<Scalars['Float']>;
  start_item_id?: Maybe<Scalars['uuid']>;
  target_item_hash_name?: Maybe<Scalars['String']>;
  target_item_id?: Maybe<Scalars['uuid']>;
  target_item_price?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_upgrade_results" */
export type User_Upgrade_Results_Mutation_Response = {
  __typename?: 'user_upgrade_results_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Upgrade_Results>;
};

/** on_conflict condition type for table "user_upgrade_results" */
export type User_Upgrade_Results_On_Conflict = {
  constraint: User_Upgrade_Results_Constraint;
  update_columns?: Array<User_Upgrade_Results_Update_Column>;
  where?: InputMaybe<User_Upgrade_Results_Bool_Exp>;
};

/** Ordering options when selecting data from "user_upgrade_results". */
export type User_Upgrade_Results_Order_By = {
  boost_boosters?: InputMaybe<Order_By>;
  boost_coins?: InputMaybe<Order_By>;
  boost_items?: InputMaybe<Order_By>;
  chance?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  koef?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  start_item_data?: InputMaybe<Order_By>;
  start_item_id?: InputMaybe<Order_By>;
  target_item_data?: InputMaybe<Order_By>;
  target_item_hash_name?: InputMaybe<Order_By>;
  target_item_id?: InputMaybe<Order_By>;
  target_item_price?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_upgrade_results */
export type User_Upgrade_Results_Pk_Columns_Input = {
  start_item_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Upgrade_Results_Prepend_Input = {
  boost_boosters?: InputMaybe<Scalars['jsonb']>;
  boost_items?: InputMaybe<Scalars['jsonb']>;
  start_item_data?: InputMaybe<Scalars['jsonb']>;
  target_item_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_upgrade_results" */
export enum User_Upgrade_Results_Select_Column {
  /** column name */
  BoostBoosters = 'boost_boosters',
  /** column name */
  BoostCoins = 'boost_coins',
  /** column name */
  BoostItems = 'boost_items',
  /** column name */
  Chance = 'chance',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Koef = 'koef',
  /** column name */
  Result = 'result',
  /** column name */
  StartItemData = 'start_item_data',
  /** column name */
  StartItemId = 'start_item_id',
  /** column name */
  TargetItemData = 'target_item_data',
  /** column name */
  TargetItemHashName = 'target_item_hash_name',
  /** column name */
  TargetItemId = 'target_item_id',
  /** column name */
  TargetItemPrice = 'target_item_price',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_upgrade_results" */
export type User_Upgrade_Results_Set_Input = {
  boost_boosters?: InputMaybe<Scalars['jsonb']>;
  boost_coins?: InputMaybe<Scalars['Int']>;
  boost_items?: InputMaybe<Scalars['jsonb']>;
  chance?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  koef?: InputMaybe<Scalars['Float']>;
  result?: InputMaybe<Scalars['Boolean']>;
  start_item_data?: InputMaybe<Scalars['jsonb']>;
  start_item_id?: InputMaybe<Scalars['uuid']>;
  target_item_data?: InputMaybe<Scalars['jsonb']>;
  target_item_hash_name?: InputMaybe<Scalars['String']>;
  target_item_id?: InputMaybe<Scalars['uuid']>;
  target_item_price?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type User_Upgrade_Results_Stddev_Fields = {
  __typename?: 'user_upgrade_results_stddev_fields';
  boost_coins?: Maybe<Scalars['Float']>;
  chance?: Maybe<Scalars['Float']>;
  koef?: Maybe<Scalars['Float']>;
  target_item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Upgrade_Results_Stddev_Pop_Fields = {
  __typename?: 'user_upgrade_results_stddev_pop_fields';
  boost_coins?: Maybe<Scalars['Float']>;
  chance?: Maybe<Scalars['Float']>;
  koef?: Maybe<Scalars['Float']>;
  target_item_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Upgrade_Results_Stddev_Samp_Fields = {
  __typename?: 'user_upgrade_results_stddev_samp_fields';
  boost_coins?: Maybe<Scalars['Float']>;
  chance?: Maybe<Scalars['Float']>;
  koef?: Maybe<Scalars['Float']>;
  target_item_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_upgrade_results" */
export type User_Upgrade_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Upgrade_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Upgrade_Results_Stream_Cursor_Value_Input = {
  boost_boosters?: InputMaybe<Scalars['jsonb']>;
  boost_coins?: InputMaybe<Scalars['Int']>;
  boost_items?: InputMaybe<Scalars['jsonb']>;
  chance?: InputMaybe<Scalars['Float']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  koef?: InputMaybe<Scalars['Float']>;
  result?: InputMaybe<Scalars['Boolean']>;
  start_item_data?: InputMaybe<Scalars['jsonb']>;
  start_item_id?: InputMaybe<Scalars['uuid']>;
  target_item_data?: InputMaybe<Scalars['jsonb']>;
  target_item_hash_name?: InputMaybe<Scalars['String']>;
  target_item_id?: InputMaybe<Scalars['uuid']>;
  target_item_price?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type User_Upgrade_Results_Sum_Fields = {
  __typename?: 'user_upgrade_results_sum_fields';
  boost_coins?: Maybe<Scalars['Int']>;
  chance?: Maybe<Scalars['Float']>;
  koef?: Maybe<Scalars['Float']>;
  target_item_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_upgrade_results" */
export enum User_Upgrade_Results_Update_Column {
  /** column name */
  BoostBoosters = 'boost_boosters',
  /** column name */
  BoostCoins = 'boost_coins',
  /** column name */
  BoostItems = 'boost_items',
  /** column name */
  Chance = 'chance',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Koef = 'koef',
  /** column name */
  Result = 'result',
  /** column name */
  StartItemData = 'start_item_data',
  /** column name */
  StartItemId = 'start_item_id',
  /** column name */
  TargetItemData = 'target_item_data',
  /** column name */
  TargetItemHashName = 'target_item_hash_name',
  /** column name */
  TargetItemId = 'target_item_id',
  /** column name */
  TargetItemPrice = 'target_item_price',
  /** column name */
  UserId = 'user_id'
}

export type User_Upgrade_Results_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Upgrade_Results_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Upgrade_Results_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Upgrade_Results_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Upgrade_Results_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Upgrade_Results_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Upgrade_Results_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Upgrade_Results_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Upgrade_Results_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Upgrade_Results_Var_Pop_Fields = {
  __typename?: 'user_upgrade_results_var_pop_fields';
  boost_coins?: Maybe<Scalars['Float']>;
  chance?: Maybe<Scalars['Float']>;
  koef?: Maybe<Scalars['Float']>;
  target_item_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Upgrade_Results_Var_Samp_Fields = {
  __typename?: 'user_upgrade_results_var_samp_fields';
  boost_coins?: Maybe<Scalars['Float']>;
  chance?: Maybe<Scalars['Float']>;
  koef?: Maybe<Scalars['Float']>;
  target_item_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Upgrade_Results_Variance_Fields = {
  __typename?: 'user_upgrade_results_variance_fields';
  boost_coins?: Maybe<Scalars['Float']>;
  chance?: Maybe<Scalars['Float']>;
  koef?: Maybe<Scalars['Float']>;
  target_item_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  age?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  age?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  age?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "user_vk_data" */
export type User_Vk_Data = {
  __typename?: 'user_vk_data';
  bdate?: Maybe<Scalars['String']>;
  can_access_closed: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deactivated?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  has_mobile?: Maybe<Scalars['Int']>;
  has_photo?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  is_closed: Scalars['Boolean'];
  last_name: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile_picture?: Maybe<Scalars['String']>;
  screen_name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['Int']>;
  /** An object relationship */
  user_profile?: Maybe<User_Profile>;
  user_slug?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "user_vk_data" */
export type User_Vk_Data_Aggregate = {
  __typename?: 'user_vk_data_aggregate';
  aggregate?: Maybe<User_Vk_Data_Aggregate_Fields>;
  nodes: Array<User_Vk_Data>;
};

/** aggregate fields of "user_vk_data" */
export type User_Vk_Data_Aggregate_Fields = {
  __typename?: 'user_vk_data_aggregate_fields';
  avg?: Maybe<User_Vk_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Vk_Data_Max_Fields>;
  min?: Maybe<User_Vk_Data_Min_Fields>;
  stddev?: Maybe<User_Vk_Data_Stddev_Fields>;
  stddev_pop?: Maybe<User_Vk_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Vk_Data_Stddev_Samp_Fields>;
  sum?: Maybe<User_Vk_Data_Sum_Fields>;
  var_pop?: Maybe<User_Vk_Data_Var_Pop_Fields>;
  var_samp?: Maybe<User_Vk_Data_Var_Samp_Fields>;
  variance?: Maybe<User_Vk_Data_Variance_Fields>;
};


/** aggregate fields of "user_vk_data" */
export type User_Vk_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Vk_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Vk_Data_Avg_Fields = {
  __typename?: 'user_vk_data_avg_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_vk_data". All fields are combined with a logical 'AND'. */
export type User_Vk_Data_Bool_Exp = {
  _and?: InputMaybe<Array<User_Vk_Data_Bool_Exp>>;
  _not?: InputMaybe<User_Vk_Data_Bool_Exp>;
  _or?: InputMaybe<Array<User_Vk_Data_Bool_Exp>>;
  bdate?: InputMaybe<String_Comparison_Exp>;
  can_access_closed?: InputMaybe<Boolean_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  deactivated?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  has_mobile?: InputMaybe<Int_Comparison_Exp>;
  has_photo?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  is_closed?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  nickname?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  profile_picture?: InputMaybe<String_Comparison_Exp>;
  screen_name?: InputMaybe<String_Comparison_Exp>;
  sex?: InputMaybe<Int_Comparison_Exp>;
  timezone?: InputMaybe<Int_Comparison_Exp>;
  user_profile?: InputMaybe<User_Profile_Bool_Exp>;
  user_slug?: InputMaybe<String_Comparison_Exp>;
  verified?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_vk_data" */
export enum User_Vk_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserVkDataPkey = 'user_vk_data_pkey'
}

/** input type for incrementing numeric columns in table "user_vk_data" */
export type User_Vk_Data_Inc_Input = {
  has_mobile?: InputMaybe<Scalars['Int']>;
  has_photo?: InputMaybe<Scalars['Int']>;
  sex?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['Int']>;
  verified?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_vk_data" */
export type User_Vk_Data_Insert_Input = {
  bdate?: InputMaybe<Scalars['String']>;
  can_access_closed?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  deactivated?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_mobile?: InputMaybe<Scalars['Int']>;
  has_photo?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  is_closed?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  screen_name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['Int']>;
  user_profile?: InputMaybe<User_Profile_Obj_Rel_Insert_Input>;
  user_slug?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type User_Vk_Data_Max_Fields = {
  __typename?: 'user_vk_data_max_fields';
  bdate?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deactivated?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  has_mobile?: Maybe<Scalars['Int']>;
  has_photo?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile_picture?: Maybe<Scalars['String']>;
  screen_name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['Int']>;
  user_slug?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type User_Vk_Data_Min_Fields = {
  __typename?: 'user_vk_data_min_fields';
  bdate?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deactivated?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  has_mobile?: Maybe<Scalars['Int']>;
  has_photo?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile_picture?: Maybe<Scalars['String']>;
  screen_name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['Int']>;
  user_slug?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "user_vk_data" */
export type User_Vk_Data_Mutation_Response = {
  __typename?: 'user_vk_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Vk_Data>;
};

/** on_conflict condition type for table "user_vk_data" */
export type User_Vk_Data_On_Conflict = {
  constraint: User_Vk_Data_Constraint;
  update_columns?: Array<User_Vk_Data_Update_Column>;
  where?: InputMaybe<User_Vk_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "user_vk_data". */
export type User_Vk_Data_Order_By = {
  bdate?: InputMaybe<Order_By>;
  can_access_closed?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  deactivated?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  has_mobile?: InputMaybe<Order_By>;
  has_photo?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_closed?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  profile_picture?: InputMaybe<Order_By>;
  screen_name?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  user_profile?: InputMaybe<User_Profile_Order_By>;
  user_slug?: InputMaybe<Order_By>;
  verified?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_vk_data */
export type User_Vk_Data_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "user_vk_data" */
export enum User_Vk_Data_Select_Column {
  /** column name */
  Bdate = 'bdate',
  /** column name */
  CanAccessClosed = 'can_access_closed',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Deactivated = 'deactivated',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasMobile = 'has_mobile',
  /** column name */
  HasPhoto = 'has_photo',
  /** column name */
  Id = 'id',
  /** column name */
  IsClosed = 'is_closed',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfilePicture = 'profile_picture',
  /** column name */
  ScreenName = 'screen_name',
  /** column name */
  Sex = 'sex',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UserSlug = 'user_slug',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "user_vk_data" */
export type User_Vk_Data_Set_Input = {
  bdate?: InputMaybe<Scalars['String']>;
  can_access_closed?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  deactivated?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_mobile?: InputMaybe<Scalars['Int']>;
  has_photo?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  is_closed?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  screen_name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['Int']>;
  user_slug?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_Vk_Data_Stddev_Fields = {
  __typename?: 'user_vk_data_stddev_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Vk_Data_Stddev_Pop_Fields = {
  __typename?: 'user_vk_data_stddev_pop_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Vk_Data_Stddev_Samp_Fields = {
  __typename?: 'user_vk_data_stddev_samp_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_vk_data" */
export type User_Vk_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Vk_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Vk_Data_Stream_Cursor_Value_Input = {
  bdate?: InputMaybe<Scalars['String']>;
  can_access_closed?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  deactivated?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_mobile?: InputMaybe<Scalars['Int']>;
  has_photo?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  is_closed?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile_picture?: InputMaybe<Scalars['String']>;
  screen_name?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['Int']>;
  user_slug?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type User_Vk_Data_Sum_Fields = {
  __typename?: 'user_vk_data_sum_fields';
  has_mobile?: Maybe<Scalars['Int']>;
  has_photo?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['Int']>;
  verified?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_vk_data" */
export enum User_Vk_Data_Update_Column {
  /** column name */
  Bdate = 'bdate',
  /** column name */
  CanAccessClosed = 'can_access_closed',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Deactivated = 'deactivated',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasMobile = 'has_mobile',
  /** column name */
  HasPhoto = 'has_photo',
  /** column name */
  Id = 'id',
  /** column name */
  IsClosed = 'is_closed',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfilePicture = 'profile_picture',
  /** column name */
  ScreenName = 'screen_name',
  /** column name */
  Sex = 'sex',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UserSlug = 'user_slug',
  /** column name */
  Verified = 'verified'
}

export type User_Vk_Data_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Vk_Data_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Vk_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Vk_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Vk_Data_Var_Pop_Fields = {
  __typename?: 'user_vk_data_var_pop_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Vk_Data_Var_Samp_Fields = {
  __typename?: 'user_vk_data_var_samp_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Vk_Data_Variance_Fields = {
  __typename?: 'user_vk_data_variance_fields';
  has_mobile?: Maybe<Scalars['Float']>;
  has_photo?: Maybe<Scalars['Float']>;
  sex?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  verified?: Maybe<Scalars['Float']>;
};

/** Кошелек пользователя */
export type User_Wallet = {
  __typename?: 'user_wallet';
  balance: Scalars['Float'];
  /** An object relationship */
  user_account?: Maybe<User_Account>;
  user_wallet_id: Scalars['uuid'];
  /** An array relationship */
  user_wallet_transactions: Array<User_Wallet_Transactions>;
  /** An aggregate relationship */
  user_wallet_transactions_aggregate: User_Wallet_Transactions_Aggregate;
  wallet_currency: Scalars['String'];
};


/** Кошелек пользователя */
export type User_WalletUser_Wallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Wallet_Transactions_Bool_Exp>;
};


/** Кошелек пользователя */
export type User_WalletUser_Wallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Wallet_Transactions_Order_By>>;
  where?: InputMaybe<User_Wallet_Transactions_Bool_Exp>;
};

/** aggregated selection of "user_wallet" */
export type User_Wallet_Aggregate = {
  __typename?: 'user_wallet_aggregate';
  aggregate?: Maybe<User_Wallet_Aggregate_Fields>;
  nodes: Array<User_Wallet>;
};

export type User_Wallet_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Wallet_Aggregate_Bool_Exp_Count>;
};

export type User_Wallet_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Wallet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Wallet_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_wallet" */
export type User_Wallet_Aggregate_Fields = {
  __typename?: 'user_wallet_aggregate_fields';
  avg?: Maybe<User_Wallet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Wallet_Max_Fields>;
  min?: Maybe<User_Wallet_Min_Fields>;
  stddev?: Maybe<User_Wallet_Stddev_Fields>;
  stddev_pop?: Maybe<User_Wallet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Wallet_Stddev_Samp_Fields>;
  sum?: Maybe<User_Wallet_Sum_Fields>;
  var_pop?: Maybe<User_Wallet_Var_Pop_Fields>;
  var_samp?: Maybe<User_Wallet_Var_Samp_Fields>;
  variance?: Maybe<User_Wallet_Variance_Fields>;
};


/** aggregate fields of "user_wallet" */
export type User_Wallet_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Wallet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_wallet" */
export type User_Wallet_Aggregate_Order_By = {
  avg?: InputMaybe<User_Wallet_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Wallet_Max_Order_By>;
  min?: InputMaybe<User_Wallet_Min_Order_By>;
  stddev?: InputMaybe<User_Wallet_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Wallet_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Wallet_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Wallet_Sum_Order_By>;
  var_pop?: InputMaybe<User_Wallet_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Wallet_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Wallet_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_wallet" */
export type User_Wallet_Arr_Rel_Insert_Input = {
  data: Array<User_Wallet_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Wallet_On_Conflict>;
};

/** Таблица для сохранения всех действий с таблицей user_wallet */
export type User_Wallet_Audit = {
  __typename?: 'user_wallet_audit';
  balance: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  operation: Scalars['bpchar'];
  user_id: Scalars['String'];
  user_wallet_id: Scalars['uuid'];
  uuid: Scalars['uuid'];
  wallet_currency: Scalars['String'];
};

/** aggregated selection of "user_wallet_audit" */
export type User_Wallet_Audit_Aggregate = {
  __typename?: 'user_wallet_audit_aggregate';
  aggregate?: Maybe<User_Wallet_Audit_Aggregate_Fields>;
  nodes: Array<User_Wallet_Audit>;
};

/** aggregate fields of "user_wallet_audit" */
export type User_Wallet_Audit_Aggregate_Fields = {
  __typename?: 'user_wallet_audit_aggregate_fields';
  avg?: Maybe<User_Wallet_Audit_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Wallet_Audit_Max_Fields>;
  min?: Maybe<User_Wallet_Audit_Min_Fields>;
  stddev?: Maybe<User_Wallet_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<User_Wallet_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Wallet_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<User_Wallet_Audit_Sum_Fields>;
  var_pop?: Maybe<User_Wallet_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<User_Wallet_Audit_Var_Samp_Fields>;
  variance?: Maybe<User_Wallet_Audit_Variance_Fields>;
};


/** aggregate fields of "user_wallet_audit" */
export type User_Wallet_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Wallet_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Wallet_Audit_Avg_Fields = {
  __typename?: 'user_wallet_audit_avg_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_wallet_audit". All fields are combined with a logical 'AND'. */
export type User_Wallet_Audit_Bool_Exp = {
  _and?: InputMaybe<Array<User_Wallet_Audit_Bool_Exp>>;
  _not?: InputMaybe<User_Wallet_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<User_Wallet_Audit_Bool_Exp>>;
  balance?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  operation?: InputMaybe<Bpchar_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_wallet_id?: InputMaybe<Uuid_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
  wallet_currency?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_wallet_audit" */
export enum User_Wallet_Audit_Constraint {
  /** unique or primary key constraint on columns "uuid" */
  UserWalletAuditPkey = 'user_wallet_audit_pkey'
}

/** input type for incrementing numeric columns in table "user_wallet_audit" */
export type User_Wallet_Audit_Inc_Input = {
  balance?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_wallet_audit" */
export type User_Wallet_Audit_Insert_Input = {
  balance?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  operation?: InputMaybe<Scalars['bpchar']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
  uuid?: InputMaybe<Scalars['uuid']>;
  wallet_currency?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Wallet_Audit_Max_Fields = {
  __typename?: 'user_wallet_audit_max_fields';
  balance?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  operation?: Maybe<Scalars['bpchar']>;
  user_id?: Maybe<Scalars['String']>;
  user_wallet_id?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
  wallet_currency?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Wallet_Audit_Min_Fields = {
  __typename?: 'user_wallet_audit_min_fields';
  balance?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  operation?: Maybe<Scalars['bpchar']>;
  user_id?: Maybe<Scalars['String']>;
  user_wallet_id?: Maybe<Scalars['uuid']>;
  uuid?: Maybe<Scalars['uuid']>;
  wallet_currency?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_wallet_audit" */
export type User_Wallet_Audit_Mutation_Response = {
  __typename?: 'user_wallet_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Wallet_Audit>;
};

/** on_conflict condition type for table "user_wallet_audit" */
export type User_Wallet_Audit_On_Conflict = {
  constraint: User_Wallet_Audit_Constraint;
  update_columns?: Array<User_Wallet_Audit_Update_Column>;
  where?: InputMaybe<User_Wallet_Audit_Bool_Exp>;
};

/** Ordering options when selecting data from "user_wallet_audit". */
export type User_Wallet_Audit_Order_By = {
  balance?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  operation?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_wallet_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
  wallet_currency?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_wallet_audit */
export type User_Wallet_Audit_Pk_Columns_Input = {
  uuid: Scalars['uuid'];
};

/** select columns of table "user_wallet_audit" */
export enum User_Wallet_Audit_Select_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Operation = 'operation',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserWalletId = 'user_wallet_id',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  WalletCurrency = 'wallet_currency'
}

/** input type for updating data in table "user_wallet_audit" */
export type User_Wallet_Audit_Set_Input = {
  balance?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  operation?: InputMaybe<Scalars['bpchar']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
  uuid?: InputMaybe<Scalars['uuid']>;
  wallet_currency?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Wallet_Audit_Stddev_Fields = {
  __typename?: 'user_wallet_audit_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Wallet_Audit_Stddev_Pop_Fields = {
  __typename?: 'user_wallet_audit_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Wallet_Audit_Stddev_Samp_Fields = {
  __typename?: 'user_wallet_audit_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_wallet_audit" */
export type User_Wallet_Audit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Wallet_Audit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Wallet_Audit_Stream_Cursor_Value_Input = {
  balance?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  operation?: InputMaybe<Scalars['bpchar']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
  uuid?: InputMaybe<Scalars['uuid']>;
  wallet_currency?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Wallet_Audit_Sum_Fields = {
  __typename?: 'user_wallet_audit_sum_fields';
  balance?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user_wallet_audit" */
export enum User_Wallet_Audit_Update_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Operation = 'operation',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserWalletId = 'user_wallet_id',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  WalletCurrency = 'wallet_currency'
}

export type User_Wallet_Audit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Wallet_Audit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Wallet_Audit_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Wallet_Audit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Wallet_Audit_Var_Pop_Fields = {
  __typename?: 'user_wallet_audit_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Wallet_Audit_Var_Samp_Fields = {
  __typename?: 'user_wallet_audit_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Wallet_Audit_Variance_Fields = {
  __typename?: 'user_wallet_audit_variance_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** aggregate avg on columns */
export type User_Wallet_Avg_Fields = {
  __typename?: 'user_wallet_avg_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_wallet" */
export type User_Wallet_Avg_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_wallet". All fields are combined with a logical 'AND'. */
export type User_Wallet_Bool_Exp = {
  _and?: InputMaybe<Array<User_Wallet_Bool_Exp>>;
  _not?: InputMaybe<User_Wallet_Bool_Exp>;
  _or?: InputMaybe<Array<User_Wallet_Bool_Exp>>;
  balance?: InputMaybe<Float_Comparison_Exp>;
  user_account?: InputMaybe<User_Account_Bool_Exp>;
  user_wallet_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_wallet_transactions?: InputMaybe<User_Wallet_Transactions_Bool_Exp>;
  user_wallet_transactions_aggregate?: InputMaybe<User_Wallet_Transactions_Aggregate_Bool_Exp>;
  wallet_currency?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_wallet" */
export enum User_Wallet_Constraint {
  /** unique or primary key constraint on columns "user_wallet_id" */
  UserWalletPkey = 'user_wallet_pkey',
  /** unique or primary key constraint on columns "user_wallet_id" */
  UserWalletUserWalletIdKey = 'user_wallet_user_wallet_id_key'
}

/** input type for incrementing numeric columns in table "user_wallet" */
export type User_Wallet_Inc_Input = {
  balance?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "user_wallet" */
export type User_Wallet_Insert_Input = {
  balance?: InputMaybe<Scalars['Float']>;
  user_account?: InputMaybe<User_Account_Obj_Rel_Insert_Input>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
  user_wallet_transactions?: InputMaybe<User_Wallet_Transactions_Arr_Rel_Insert_Input>;
  wallet_currency?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Wallet_Max_Fields = {
  __typename?: 'user_wallet_max_fields';
  balance?: Maybe<Scalars['Float']>;
  user_wallet_id?: Maybe<Scalars['uuid']>;
  wallet_currency?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_wallet" */
export type User_Wallet_Max_Order_By = {
  balance?: InputMaybe<Order_By>;
  user_wallet_id?: InputMaybe<Order_By>;
  wallet_currency?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Wallet_Min_Fields = {
  __typename?: 'user_wallet_min_fields';
  balance?: Maybe<Scalars['Float']>;
  user_wallet_id?: Maybe<Scalars['uuid']>;
  wallet_currency?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_wallet" */
export type User_Wallet_Min_Order_By = {
  balance?: InputMaybe<Order_By>;
  user_wallet_id?: InputMaybe<Order_By>;
  wallet_currency?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_wallet" */
export type User_Wallet_Mutation_Response = {
  __typename?: 'user_wallet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Wallet>;
};

/** input type for inserting object relation for remote table "user_wallet" */
export type User_Wallet_Obj_Rel_Insert_Input = {
  data: User_Wallet_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Wallet_On_Conflict>;
};

/** on_conflict condition type for table "user_wallet" */
export type User_Wallet_On_Conflict = {
  constraint: User_Wallet_Constraint;
  update_columns?: Array<User_Wallet_Update_Column>;
  where?: InputMaybe<User_Wallet_Bool_Exp>;
};

/** Ordering options when selecting data from "user_wallet". */
export type User_Wallet_Order_By = {
  balance?: InputMaybe<Order_By>;
  user_account?: InputMaybe<User_Account_Order_By>;
  user_wallet_id?: InputMaybe<Order_By>;
  user_wallet_transactions_aggregate?: InputMaybe<User_Wallet_Transactions_Aggregate_Order_By>;
  wallet_currency?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_wallet */
export type User_Wallet_Pk_Columns_Input = {
  user_wallet_id: Scalars['uuid'];
};

/** select columns of table "user_wallet" */
export enum User_Wallet_Select_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  UserWalletId = 'user_wallet_id',
  /** column name */
  WalletCurrency = 'wallet_currency'
}

/** input type for updating data in table "user_wallet" */
export type User_Wallet_Set_Input = {
  balance?: InputMaybe<Scalars['Float']>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
  wallet_currency?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Wallet_Stddev_Fields = {
  __typename?: 'user_wallet_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_wallet" */
export type User_Wallet_Stddev_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Wallet_Stddev_Pop_Fields = {
  __typename?: 'user_wallet_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_wallet" */
export type User_Wallet_Stddev_Pop_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Wallet_Stddev_Samp_Fields = {
  __typename?: 'user_wallet_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_wallet" */
export type User_Wallet_Stddev_Samp_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_wallet" */
export type User_Wallet_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Wallet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Wallet_Stream_Cursor_Value_Input = {
  balance?: InputMaybe<Scalars['Float']>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
  wallet_currency?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Wallet_Sum_Fields = {
  __typename?: 'user_wallet_sum_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by sum() on columns of table "user_wallet" */
export type User_Wallet_Sum_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** operation_type: 1 - пополнение, 2 - покупка кейса, 3 - продажа предмета, 4 - приход с рефералки */
export type User_Wallet_Transactions = {
  __typename?: 'user_wallet_transactions';
  operation_date_time: Scalars['timestamp'];
  operation_id: Scalars['uuid'];
  operation_status: Scalars['Int'];
  operation_type: Scalars['Int'];
  /** An object relationship */
  user_wallet: User_Wallet;
  user_wallet_id: Scalars['uuid'];
  value: Scalars['Int'];
};

/** aggregated selection of "user_wallet_transactions" */
export type User_Wallet_Transactions_Aggregate = {
  __typename?: 'user_wallet_transactions_aggregate';
  aggregate?: Maybe<User_Wallet_Transactions_Aggregate_Fields>;
  nodes: Array<User_Wallet_Transactions>;
};

export type User_Wallet_Transactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Wallet_Transactions_Aggregate_Bool_Exp_Count>;
};

export type User_Wallet_Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Wallet_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Wallet_Transactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_wallet_transactions" */
export type User_Wallet_Transactions_Aggregate_Fields = {
  __typename?: 'user_wallet_transactions_aggregate_fields';
  avg?: Maybe<User_Wallet_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Wallet_Transactions_Max_Fields>;
  min?: Maybe<User_Wallet_Transactions_Min_Fields>;
  stddev?: Maybe<User_Wallet_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<User_Wallet_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Wallet_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<User_Wallet_Transactions_Sum_Fields>;
  var_pop?: Maybe<User_Wallet_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<User_Wallet_Transactions_Var_Samp_Fields>;
  variance?: Maybe<User_Wallet_Transactions_Variance_Fields>;
};


/** aggregate fields of "user_wallet_transactions" */
export type User_Wallet_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Wallet_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_wallet_transactions" */
export type User_Wallet_Transactions_Aggregate_Order_By = {
  avg?: InputMaybe<User_Wallet_Transactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Wallet_Transactions_Max_Order_By>;
  min?: InputMaybe<User_Wallet_Transactions_Min_Order_By>;
  stddev?: InputMaybe<User_Wallet_Transactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Wallet_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Wallet_Transactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Wallet_Transactions_Sum_Order_By>;
  var_pop?: InputMaybe<User_Wallet_Transactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Wallet_Transactions_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Wallet_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_wallet_transactions" */
export type User_Wallet_Transactions_Arr_Rel_Insert_Input = {
  data: Array<User_Wallet_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Wallet_Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Wallet_Transactions_Avg_Fields = {
  __typename?: 'user_wallet_transactions_avg_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_wallet_transactions" */
export type User_Wallet_Transactions_Avg_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_wallet_transactions". All fields are combined with a logical 'AND'. */
export type User_Wallet_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<User_Wallet_Transactions_Bool_Exp>>;
  _not?: InputMaybe<User_Wallet_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<User_Wallet_Transactions_Bool_Exp>>;
  operation_date_time?: InputMaybe<Timestamp_Comparison_Exp>;
  operation_id?: InputMaybe<Uuid_Comparison_Exp>;
  operation_status?: InputMaybe<Int_Comparison_Exp>;
  operation_type?: InputMaybe<Int_Comparison_Exp>;
  user_wallet?: InputMaybe<User_Wallet_Bool_Exp>;
  user_wallet_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_wallet_transactions" */
export enum User_Wallet_Transactions_Constraint {
  /** unique or primary key constraint on columns "operation_id" */
  UserWalletTransactionsOperationIdKey = 'user_wallet_transactions_operation_id_key',
  /** unique or primary key constraint on columns "operation_id", "user_wallet_id" */
  UserWalletTransactionsPkey = 'user_wallet_transactions_pkey'
}

/** input type for incrementing numeric columns in table "user_wallet_transactions" */
export type User_Wallet_Transactions_Inc_Input = {
  operation_status?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_wallet_transactions" */
export type User_Wallet_Transactions_Insert_Input = {
  operation_date_time?: InputMaybe<Scalars['timestamp']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  operation_status?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  user_wallet?: InputMaybe<User_Wallet_Obj_Rel_Insert_Input>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type User_Wallet_Transactions_Max_Fields = {
  __typename?: 'user_wallet_transactions_max_fields';
  operation_date_time?: Maybe<Scalars['timestamp']>;
  operation_id?: Maybe<Scalars['uuid']>;
  operation_status?: Maybe<Scalars['Int']>;
  operation_type?: Maybe<Scalars['Int']>;
  user_wallet_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "user_wallet_transactions" */
export type User_Wallet_Transactions_Max_Order_By = {
  operation_date_time?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  user_wallet_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Wallet_Transactions_Min_Fields = {
  __typename?: 'user_wallet_transactions_min_fields';
  operation_date_time?: Maybe<Scalars['timestamp']>;
  operation_id?: Maybe<Scalars['uuid']>;
  operation_status?: Maybe<Scalars['Int']>;
  operation_type?: Maybe<Scalars['Int']>;
  user_wallet_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "user_wallet_transactions" */
export type User_Wallet_Transactions_Min_Order_By = {
  operation_date_time?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  user_wallet_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_wallet_transactions" */
export type User_Wallet_Transactions_Mutation_Response = {
  __typename?: 'user_wallet_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Wallet_Transactions>;
};

/** on_conflict condition type for table "user_wallet_transactions" */
export type User_Wallet_Transactions_On_Conflict = {
  constraint: User_Wallet_Transactions_Constraint;
  update_columns?: Array<User_Wallet_Transactions_Update_Column>;
  where?: InputMaybe<User_Wallet_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_wallet_transactions". */
export type User_Wallet_Transactions_Order_By = {
  operation_date_time?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  user_wallet?: InputMaybe<User_Wallet_Order_By>;
  user_wallet_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_wallet_transactions */
export type User_Wallet_Transactions_Pk_Columns_Input = {
  operation_id: Scalars['uuid'];
  user_wallet_id: Scalars['uuid'];
};

/** select columns of table "user_wallet_transactions" */
export enum User_Wallet_Transactions_Select_Column {
  /** column name */
  OperationDateTime = 'operation_date_time',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OperationStatus = 'operation_status',
  /** column name */
  OperationType = 'operation_type',
  /** column name */
  UserWalletId = 'user_wallet_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_wallet_transactions" */
export type User_Wallet_Transactions_Set_Input = {
  operation_date_time?: InputMaybe<Scalars['timestamp']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  operation_status?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_Wallet_Transactions_Stddev_Fields = {
  __typename?: 'user_wallet_transactions_stddev_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_wallet_transactions" */
export type User_Wallet_Transactions_Stddev_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Wallet_Transactions_Stddev_Pop_Fields = {
  __typename?: 'user_wallet_transactions_stddev_pop_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_wallet_transactions" */
export type User_Wallet_Transactions_Stddev_Pop_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Wallet_Transactions_Stddev_Samp_Fields = {
  __typename?: 'user_wallet_transactions_stddev_samp_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_wallet_transactions" */
export type User_Wallet_Transactions_Stddev_Samp_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_wallet_transactions" */
export type User_Wallet_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Wallet_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Wallet_Transactions_Stream_Cursor_Value_Input = {
  operation_date_time?: InputMaybe<Scalars['timestamp']>;
  operation_id?: InputMaybe<Scalars['uuid']>;
  operation_status?: InputMaybe<Scalars['Int']>;
  operation_type?: InputMaybe<Scalars['Int']>;
  user_wallet_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type User_Wallet_Transactions_Sum_Fields = {
  __typename?: 'user_wallet_transactions_sum_fields';
  operation_status?: Maybe<Scalars['Int']>;
  operation_type?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_wallet_transactions" */
export type User_Wallet_Transactions_Sum_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "user_wallet_transactions" */
export enum User_Wallet_Transactions_Update_Column {
  /** column name */
  OperationDateTime = 'operation_date_time',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OperationStatus = 'operation_status',
  /** column name */
  OperationType = 'operation_type',
  /** column name */
  UserWalletId = 'user_wallet_id',
  /** column name */
  Value = 'value'
}

export type User_Wallet_Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Wallet_Transactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Wallet_Transactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Wallet_Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Wallet_Transactions_Var_Pop_Fields = {
  __typename?: 'user_wallet_transactions_var_pop_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_wallet_transactions" */
export type User_Wallet_Transactions_Var_Pop_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Wallet_Transactions_Var_Samp_Fields = {
  __typename?: 'user_wallet_transactions_var_samp_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_wallet_transactions" */
export type User_Wallet_Transactions_Var_Samp_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Wallet_Transactions_Variance_Fields = {
  __typename?: 'user_wallet_transactions_variance_fields';
  operation_status?: Maybe<Scalars['Float']>;
  operation_type?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_wallet_transactions" */
export type User_Wallet_Transactions_Variance_Order_By = {
  operation_status?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "user_wallet" */
export enum User_Wallet_Update_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  UserWalletId = 'user_wallet_id',
  /** column name */
  WalletCurrency = 'wallet_currency'
}

export type User_Wallet_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Wallet_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Wallet_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Wallet_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Wallet_Var_Pop_Fields = {
  __typename?: 'user_wallet_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_wallet" */
export type User_Wallet_Var_Pop_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Wallet_Var_Samp_Fields = {
  __typename?: 'user_wallet_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_wallet" */
export type User_Wallet_Var_Samp_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Wallet_Variance_Fields = {
  __typename?: 'user_wallet_variance_fields';
  balance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_wallet" */
export type User_Wallet_Variance_Order_By = {
  balance?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_whitelist" */
export type User_Whitelist = {
  __typename?: 'user_whitelist';
  causer?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  user_data: Scalars['String'];
};

/** aggregated selection of "user_whitelist" */
export type User_Whitelist_Aggregate = {
  __typename?: 'user_whitelist_aggregate';
  aggregate?: Maybe<User_Whitelist_Aggregate_Fields>;
  nodes: Array<User_Whitelist>;
};

/** aggregate fields of "user_whitelist" */
export type User_Whitelist_Aggregate_Fields = {
  __typename?: 'user_whitelist_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Whitelist_Max_Fields>;
  min?: Maybe<User_Whitelist_Min_Fields>;
};


/** aggregate fields of "user_whitelist" */
export type User_Whitelist_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Whitelist_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_whitelist". All fields are combined with a logical 'AND'. */
export type User_Whitelist_Bool_Exp = {
  _and?: InputMaybe<Array<User_Whitelist_Bool_Exp>>;
  _not?: InputMaybe<User_Whitelist_Bool_Exp>;
  _or?: InputMaybe<Array<User_Whitelist_Bool_Exp>>;
  causer?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_data?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_whitelist" */
export enum User_Whitelist_Constraint {
  /** unique or primary key constraint on columns "user_data" */
  UserWhitelistPkey = 'user_whitelist_pkey'
}

/** input type for inserting data into table "user_whitelist" */
export type User_Whitelist_Insert_Input = {
  causer?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  user_data?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Whitelist_Max_Fields = {
  __typename?: 'user_whitelist_max_fields';
  causer?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  user_data?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Whitelist_Min_Fields = {
  __typename?: 'user_whitelist_min_fields';
  causer?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  user_data?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_whitelist" */
export type User_Whitelist_Mutation_Response = {
  __typename?: 'user_whitelist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Whitelist>;
};

/** on_conflict condition type for table "user_whitelist" */
export type User_Whitelist_On_Conflict = {
  constraint: User_Whitelist_Constraint;
  update_columns?: Array<User_Whitelist_Update_Column>;
  where?: InputMaybe<User_Whitelist_Bool_Exp>;
};

/** Ordering options when selecting data from "user_whitelist". */
export type User_Whitelist_Order_By = {
  causer?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  user_data?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_whitelist */
export type User_Whitelist_Pk_Columns_Input = {
  user_data: Scalars['String'];
};

/** select columns of table "user_whitelist" */
export enum User_Whitelist_Select_Column {
  /** column name */
  Causer = 'causer',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UserData = 'user_data'
}

/** input type for updating data in table "user_whitelist" */
export type User_Whitelist_Set_Input = {
  causer?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  user_data?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_whitelist" */
export type User_Whitelist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Whitelist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Whitelist_Stream_Cursor_Value_Input = {
  causer?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  user_data?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_whitelist" */
export enum User_Whitelist_Update_Column {
  /** column name */
  Causer = 'causer',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UserData = 'user_data'
}

export type User_Whitelist_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Whitelist_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Whitelist_Bool_Exp;
};

/** columns and relationships of "utm_visits_data" */
export type Utm_Visits_Data = {
  __typename?: 'utm_visits_data';
  balance?: Maybe<Scalars['float8']>;
  created_at: Scalars['timestamp'];
  tds_source?: Maybe<Scalars['jsonb']>;
  user_account?: Maybe<User_Account>;
  user_id: Scalars['uuid'];
  user_ip?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
  utm_source?: Maybe<Scalars['jsonb']>;
  visit_id: Scalars['uuid'];
  visit_seq: Scalars['Int'];
};


/** columns and relationships of "utm_visits_data" */
export type Utm_Visits_DataTds_SourceArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "utm_visits_data" */
export type Utm_Visits_DataUtm_SourceArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "utm_visits_data" */
export type Utm_Visits_Data_Aggregate = {
  __typename?: 'utm_visits_data_aggregate';
  aggregate?: Maybe<Utm_Visits_Data_Aggregate_Fields>;
  nodes: Array<Utm_Visits_Data>;
};

/** aggregate fields of "utm_visits_data" */
export type Utm_Visits_Data_Aggregate_Fields = {
  __typename?: 'utm_visits_data_aggregate_fields';
  avg?: Maybe<Utm_Visits_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Utm_Visits_Data_Max_Fields>;
  min?: Maybe<Utm_Visits_Data_Min_Fields>;
  stddev?: Maybe<Utm_Visits_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Utm_Visits_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Utm_Visits_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Utm_Visits_Data_Sum_Fields>;
  var_pop?: Maybe<Utm_Visits_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Utm_Visits_Data_Var_Samp_Fields>;
  variance?: Maybe<Utm_Visits_Data_Variance_Fields>;
};


/** aggregate fields of "utm_visits_data" */
export type Utm_Visits_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Utm_Visits_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Utm_Visits_Data_Append_Input = {
  tds_source?: InputMaybe<Scalars['jsonb']>;
  utm_source?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Utm_Visits_Data_Avg_Fields = {
  __typename?: 'utm_visits_data_avg_fields';
  balance?: Maybe<Scalars['Float']>;
  visit_seq?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "utm_visits_data". All fields are combined with a logical 'AND'. */
export type Utm_Visits_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Utm_Visits_Data_Bool_Exp>>;
  _not?: InputMaybe<Utm_Visits_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Utm_Visits_Data_Bool_Exp>>;
  balance?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  tds_source?: InputMaybe<Jsonb_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_ip?: InputMaybe<String_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
  utm_source?: InputMaybe<Jsonb_Comparison_Exp>;
  visit_id?: InputMaybe<Uuid_Comparison_Exp>;
  visit_seq?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "utm_visits_data" */
export enum Utm_Visits_Data_Constraint {
  /** unique or primary key constraint on columns "visit_id" */
  UtmVisitsDataPkey = 'utm_visits_data_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Utm_Visits_Data_Delete_At_Path_Input = {
  tds_source?: InputMaybe<Array<Scalars['String']>>;
  utm_source?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Utm_Visits_Data_Delete_Elem_Input = {
  tds_source?: InputMaybe<Scalars['Int']>;
  utm_source?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Utm_Visits_Data_Delete_Key_Input = {
  tds_source?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "utm_visits_data" */
export type Utm_Visits_Data_Inc_Input = {
  balance?: InputMaybe<Scalars['float8']>;
  visit_seq?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "utm_visits_data" */
export type Utm_Visits_Data_Insert_Input = {
  balance?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  tds_source?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_ip?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['jsonb']>;
  visit_id?: InputMaybe<Scalars['uuid']>;
  visit_seq?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Utm_Visits_Data_Max_Fields = {
  __typename?: 'utm_visits_data_max_fields';
  balance?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_ip?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
  visit_id?: Maybe<Scalars['uuid']>;
  visit_seq?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Utm_Visits_Data_Min_Fields = {
  __typename?: 'utm_visits_data_min_fields';
  balance?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_ip?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
  visit_id?: Maybe<Scalars['uuid']>;
  visit_seq?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "utm_visits_data" */
export type Utm_Visits_Data_Mutation_Response = {
  __typename?: 'utm_visits_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Utm_Visits_Data>;
};

/** on_conflict condition type for table "utm_visits_data" */
export type Utm_Visits_Data_On_Conflict = {
  constraint: Utm_Visits_Data_Constraint;
  update_columns?: Array<Utm_Visits_Data_Update_Column>;
  where?: InputMaybe<Utm_Visits_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "utm_visits_data". */
export type Utm_Visits_Data_Order_By = {
  balance?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  tds_source?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_ip?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
  utm_source?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
  visit_seq?: InputMaybe<Order_By>;
};

/** primary key columns input for table: utm_visits_data */
export type Utm_Visits_Data_Pk_Columns_Input = {
  visit_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Utm_Visits_Data_Prepend_Input = {
  tds_source?: InputMaybe<Scalars['jsonb']>;
  utm_source?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "utm_visits_data" */
export enum Utm_Visits_Data_Select_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TdsSource = 'tds_source',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIp = 'user_ip',
  /** column name */
  UserName = 'user_name',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  VisitId = 'visit_id',
  /** column name */
  VisitSeq = 'visit_seq'
}

/** input type for updating data in table "utm_visits_data" */
export type Utm_Visits_Data_Set_Input = {
  balance?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  tds_source?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_ip?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['jsonb']>;
  visit_id?: InputMaybe<Scalars['uuid']>;
  visit_seq?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Utm_Visits_Data_Stddev_Fields = {
  __typename?: 'utm_visits_data_stddev_fields';
  balance?: Maybe<Scalars['Float']>;
  visit_seq?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Utm_Visits_Data_Stddev_Pop_Fields = {
  __typename?: 'utm_visits_data_stddev_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  visit_seq?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Utm_Visits_Data_Stddev_Samp_Fields = {
  __typename?: 'utm_visits_data_stddev_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  visit_seq?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "utm_visits_data" */
export type Utm_Visits_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Utm_Visits_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Utm_Visits_Data_Stream_Cursor_Value_Input = {
  balance?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  tds_source?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  user_ip?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['jsonb']>;
  visit_id?: InputMaybe<Scalars['uuid']>;
  visit_seq?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Utm_Visits_Data_Sum_Fields = {
  __typename?: 'utm_visits_data_sum_fields';
  balance?: Maybe<Scalars['float8']>;
  visit_seq?: Maybe<Scalars['Int']>;
};

/** update columns of table "utm_visits_data" */
export enum Utm_Visits_Data_Update_Column {
  /** column name */
  Balance = 'balance',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TdsSource = 'tds_source',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserIp = 'user_ip',
  /** column name */
  UserName = 'user_name',
  /** column name */
  UtmSource = 'utm_source',
  /** column name */
  VisitId = 'visit_id',
  /** column name */
  VisitSeq = 'visit_seq'
}

export type Utm_Visits_Data_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Utm_Visits_Data_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Utm_Visits_Data_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Utm_Visits_Data_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Utm_Visits_Data_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Utm_Visits_Data_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Utm_Visits_Data_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Utm_Visits_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Utm_Visits_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Utm_Visits_Data_Var_Pop_Fields = {
  __typename?: 'utm_visits_data_var_pop_fields';
  balance?: Maybe<Scalars['Float']>;
  visit_seq?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Utm_Visits_Data_Var_Samp_Fields = {
  __typename?: 'utm_visits_data_var_samp_fields';
  balance?: Maybe<Scalars['Float']>;
  visit_seq?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Utm_Visits_Data_Variance_Fields = {
  __typename?: 'utm_visits_data_variance_fields';
  balance?: Maybe<Scalars['Float']>;
  visit_seq?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "webpushes_autosending_types" */
export type Webpushes_Autosending_Types = {
  __typename?: 'webpushes_autosending_types';
  body: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  is_active: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  /** An array relationship */
  localization: Array<Webpushes_Autosending_Types_Localization>;
  /** An aggregate relationship */
  localization_aggregate: Webpushes_Autosending_Types_Localization_Aggregate;
  onetime_trigger: Scalars['Boolean'];
  title: Scalars['String'];
  /** An object relationship */
  webpush_bonus?: Maybe<Webpushes_Bonuses>;
  webpush_bonus_id?: Maybe<Scalars['uuid']>;
  webpush_id: Scalars['uuid'];
  webpush_unique_trigger?: Maybe<Scalars['String']>;
  /** An array relationship */
  webpushes_schedulings: Array<Webpushes_Scheduling>;
  /** An aggregate relationship */
  webpushes_schedulings_aggregate: Webpushes_Scheduling_Aggregate;
  /** An array relationship */
  webpushes_triggers_groups: Array<Webpushes_Triggers_Groups>;
  /** An aggregate relationship */
  webpushes_triggers_groups_aggregate: Webpushes_Triggers_Groups_Aggregate;
};


/** columns and relationships of "webpushes_autosending_types" */
export type Webpushes_Autosending_TypesLocalizationArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Order_By>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Localization_Bool_Exp>;
};


/** columns and relationships of "webpushes_autosending_types" */
export type Webpushes_Autosending_TypesLocalization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Order_By>>;
  where?: InputMaybe<Webpushes_Autosending_Types_Localization_Bool_Exp>;
};


/** columns and relationships of "webpushes_autosending_types" */
export type Webpushes_Autosending_TypesWebpushes_SchedulingsArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Scheduling_Order_By>>;
  where?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
};


/** columns and relationships of "webpushes_autosending_types" */
export type Webpushes_Autosending_TypesWebpushes_Schedulings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Scheduling_Order_By>>;
  where?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
};


/** columns and relationships of "webpushes_autosending_types" */
export type Webpushes_Autosending_TypesWebpushes_Triggers_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
};


/** columns and relationships of "webpushes_autosending_types" */
export type Webpushes_Autosending_TypesWebpushes_Triggers_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Groups_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
};

/** aggregated selection of "webpushes_autosending_types" */
export type Webpushes_Autosending_Types_Aggregate = {
  __typename?: 'webpushes_autosending_types_aggregate';
  aggregate?: Maybe<Webpushes_Autosending_Types_Aggregate_Fields>;
  nodes: Array<Webpushes_Autosending_Types>;
};

/** aggregate fields of "webpushes_autosending_types" */
export type Webpushes_Autosending_Types_Aggregate_Fields = {
  __typename?: 'webpushes_autosending_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Webpushes_Autosending_Types_Max_Fields>;
  min?: Maybe<Webpushes_Autosending_Types_Min_Fields>;
};


/** aggregate fields of "webpushes_autosending_types" */
export type Webpushes_Autosending_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webpushes_Autosending_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "webpushes_autosending_types". All fields are combined with a logical 'AND'. */
export type Webpushes_Autosending_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Webpushes_Autosending_Types_Bool_Exp>>;
  _not?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Webpushes_Autosending_Types_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  localization?: InputMaybe<Webpushes_Autosending_Types_Localization_Bool_Exp>;
  localization_aggregate?: InputMaybe<Webpushes_Autosending_Types_Localization_Aggregate_Bool_Exp>;
  onetime_trigger?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  webpush_bonus?: InputMaybe<Webpushes_Bonuses_Bool_Exp>;
  webpush_bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  webpush_id?: InputMaybe<Uuid_Comparison_Exp>;
  webpush_unique_trigger?: InputMaybe<String_Comparison_Exp>;
  webpushes_schedulings?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
  webpushes_schedulings_aggregate?: InputMaybe<Webpushes_Scheduling_Aggregate_Bool_Exp>;
  webpushes_triggers_groups?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
  webpushes_triggers_groups_aggregate?: InputMaybe<Webpushes_Triggers_Groups_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "webpushes_autosending_types" */
export enum Webpushes_Autosending_Types_Constraint {
  /** unique or primary key constraint on columns "webpush_id" */
  WebpushesAutosendingTypesPkey = 'webpushes_autosending_types_pkey',
  /** unique or primary key constraint on columns "webpush_unique_trigger" */
  WebpushesAutosendingTypesWebpushTypeKey = 'webpushes_autosending_types_webpush_type_key'
}

/** input type for inserting data into table "webpushes_autosending_types" */
export type Webpushes_Autosending_Types_Insert_Input = {
  body?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  localization?: InputMaybe<Webpushes_Autosending_Types_Localization_Arr_Rel_Insert_Input>;
  onetime_trigger?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  webpush_bonus?: InputMaybe<Webpushes_Bonuses_Obj_Rel_Insert_Input>;
  webpush_bonus_id?: InputMaybe<Scalars['uuid']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
  webpush_unique_trigger?: InputMaybe<Scalars['String']>;
  webpushes_schedulings?: InputMaybe<Webpushes_Scheduling_Arr_Rel_Insert_Input>;
  webpushes_triggers_groups?: InputMaybe<Webpushes_Triggers_Groups_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization = {
  __typename?: 'webpushes_autosending_types_localization';
  body_localized: Scalars['String'];
  locale: Scalars['String'];
  title_localized: Scalars['String'];
  webpush_id: Scalars['uuid'];
};

/** aggregated selection of "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_Aggregate = {
  __typename?: 'webpushes_autosending_types_localization_aggregate';
  aggregate?: Maybe<Webpushes_Autosending_Types_Localization_Aggregate_Fields>;
  nodes: Array<Webpushes_Autosending_Types_Localization>;
};

export type Webpushes_Autosending_Types_Localization_Aggregate_Bool_Exp = {
  count?: InputMaybe<Webpushes_Autosending_Types_Localization_Aggregate_Bool_Exp_Count>;
};

export type Webpushes_Autosending_Types_Localization_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Webpushes_Autosending_Types_Localization_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_Aggregate_Fields = {
  __typename?: 'webpushes_autosending_types_localization_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Webpushes_Autosending_Types_Localization_Max_Fields>;
  min?: Maybe<Webpushes_Autosending_Types_Localization_Min_Fields>;
};


/** aggregate fields of "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Webpushes_Autosending_Types_Localization_Max_Order_By>;
  min?: InputMaybe<Webpushes_Autosending_Types_Localization_Min_Order_By>;
};

/** input type for inserting array relation for remote table "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_Arr_Rel_Insert_Input = {
  data: Array<Webpushes_Autosending_Types_Localization_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Webpushes_Autosending_Types_Localization_On_Conflict>;
};

/** Boolean expression to filter rows from the table "webpushes_autosending_types_localization". All fields are combined with a logical 'AND'. */
export type Webpushes_Autosending_Types_Localization_Bool_Exp = {
  _and?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Bool_Exp>>;
  _not?: InputMaybe<Webpushes_Autosending_Types_Localization_Bool_Exp>;
  _or?: InputMaybe<Array<Webpushes_Autosending_Types_Localization_Bool_Exp>>;
  body_localized?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  title_localized?: InputMaybe<String_Comparison_Exp>;
  webpush_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "webpushes_autosending_types_localization" */
export enum Webpushes_Autosending_Types_Localization_Constraint {
  /** unique or primary key constraint on columns "webpush_id", "locale" */
  WebpushesAutosendingTypesLocalizationPkey = 'webpushes_autosending_types_localization_pkey'
}

/** input type for inserting data into table "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_Insert_Input = {
  body_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  title_localized?: InputMaybe<Scalars['String']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Webpushes_Autosending_Types_Localization_Max_Fields = {
  __typename?: 'webpushes_autosending_types_localization_max_fields';
  body_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  title_localized?: Maybe<Scalars['String']>;
  webpush_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_Max_Order_By = {
  body_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  title_localized?: InputMaybe<Order_By>;
  webpush_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Webpushes_Autosending_Types_Localization_Min_Fields = {
  __typename?: 'webpushes_autosending_types_localization_min_fields';
  body_localized?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  title_localized?: Maybe<Scalars['String']>;
  webpush_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_Min_Order_By = {
  body_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  title_localized?: InputMaybe<Order_By>;
  webpush_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_Mutation_Response = {
  __typename?: 'webpushes_autosending_types_localization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webpushes_Autosending_Types_Localization>;
};

/** on_conflict condition type for table "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_On_Conflict = {
  constraint: Webpushes_Autosending_Types_Localization_Constraint;
  update_columns?: Array<Webpushes_Autosending_Types_Localization_Update_Column>;
  where?: InputMaybe<Webpushes_Autosending_Types_Localization_Bool_Exp>;
};

/** Ordering options when selecting data from "webpushes_autosending_types_localization". */
export type Webpushes_Autosending_Types_Localization_Order_By = {
  body_localized?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  title_localized?: InputMaybe<Order_By>;
  webpush_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: webpushes_autosending_types_localization */
export type Webpushes_Autosending_Types_Localization_Pk_Columns_Input = {
  locale: Scalars['String'];
  webpush_id: Scalars['uuid'];
};

/** select columns of table "webpushes_autosending_types_localization" */
export enum Webpushes_Autosending_Types_Localization_Select_Column {
  /** column name */
  BodyLocalized = 'body_localized',
  /** column name */
  Locale = 'locale',
  /** column name */
  TitleLocalized = 'title_localized',
  /** column name */
  WebpushId = 'webpush_id'
}

/** input type for updating data in table "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_Set_Input = {
  body_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  title_localized?: InputMaybe<Scalars['String']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "webpushes_autosending_types_localization" */
export type Webpushes_Autosending_Types_Localization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Webpushes_Autosending_Types_Localization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Webpushes_Autosending_Types_Localization_Stream_Cursor_Value_Input = {
  body_localized?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  title_localized?: InputMaybe<Scalars['String']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "webpushes_autosending_types_localization" */
export enum Webpushes_Autosending_Types_Localization_Update_Column {
  /** column name */
  BodyLocalized = 'body_localized',
  /** column name */
  Locale = 'locale',
  /** column name */
  TitleLocalized = 'title_localized',
  /** column name */
  WebpushId = 'webpush_id'
}

export type Webpushes_Autosending_Types_Localization_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Webpushes_Autosending_Types_Localization_Set_Input>;
  /** filter the rows which have to be updated */
  where: Webpushes_Autosending_Types_Localization_Bool_Exp;
};

/** aggregate max on columns */
export type Webpushes_Autosending_Types_Max_Fields = {
  __typename?: 'webpushes_autosending_types_max_fields';
  body?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  webpush_bonus_id?: Maybe<Scalars['uuid']>;
  webpush_id?: Maybe<Scalars['uuid']>;
  webpush_unique_trigger?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Webpushes_Autosending_Types_Min_Fields = {
  __typename?: 'webpushes_autosending_types_min_fields';
  body?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  webpush_bonus_id?: Maybe<Scalars['uuid']>;
  webpush_id?: Maybe<Scalars['uuid']>;
  webpush_unique_trigger?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "webpushes_autosending_types" */
export type Webpushes_Autosending_Types_Mutation_Response = {
  __typename?: 'webpushes_autosending_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webpushes_Autosending_Types>;
};

/** input type for inserting object relation for remote table "webpushes_autosending_types" */
export type Webpushes_Autosending_Types_Obj_Rel_Insert_Input = {
  data: Webpushes_Autosending_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Webpushes_Autosending_Types_On_Conflict>;
};

/** on_conflict condition type for table "webpushes_autosending_types" */
export type Webpushes_Autosending_Types_On_Conflict = {
  constraint: Webpushes_Autosending_Types_Constraint;
  update_columns?: Array<Webpushes_Autosending_Types_Update_Column>;
  where?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "webpushes_autosending_types". */
export type Webpushes_Autosending_Types_Order_By = {
  body?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  localization_aggregate?: InputMaybe<Webpushes_Autosending_Types_Localization_Aggregate_Order_By>;
  onetime_trigger?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  webpush_bonus?: InputMaybe<Webpushes_Bonuses_Order_By>;
  webpush_bonus_id?: InputMaybe<Order_By>;
  webpush_id?: InputMaybe<Order_By>;
  webpush_unique_trigger?: InputMaybe<Order_By>;
  webpushes_schedulings_aggregate?: InputMaybe<Webpushes_Scheduling_Aggregate_Order_By>;
  webpushes_triggers_groups_aggregate?: InputMaybe<Webpushes_Triggers_Groups_Aggregate_Order_By>;
};

/** primary key columns input for table: webpushes_autosending_types */
export type Webpushes_Autosending_Types_Pk_Columns_Input = {
  webpush_id: Scalars['uuid'];
};

/** select columns of table "webpushes_autosending_types" */
export enum Webpushes_Autosending_Types_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Link = 'link',
  /** column name */
  OnetimeTrigger = 'onetime_trigger',
  /** column name */
  Title = 'title',
  /** column name */
  WebpushBonusId = 'webpush_bonus_id',
  /** column name */
  WebpushId = 'webpush_id',
  /** column name */
  WebpushUniqueTrigger = 'webpush_unique_trigger'
}

/** input type for updating data in table "webpushes_autosending_types" */
export type Webpushes_Autosending_Types_Set_Input = {
  body?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  onetime_trigger?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  webpush_bonus_id?: InputMaybe<Scalars['uuid']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
  webpush_unique_trigger?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "webpushes_autosending_types" */
export type Webpushes_Autosending_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Webpushes_Autosending_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Webpushes_Autosending_Types_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  onetime_trigger?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  webpush_bonus_id?: InputMaybe<Scalars['uuid']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
  webpush_unique_trigger?: InputMaybe<Scalars['String']>;
};

/** update columns of table "webpushes_autosending_types" */
export enum Webpushes_Autosending_Types_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Link = 'link',
  /** column name */
  OnetimeTrigger = 'onetime_trigger',
  /** column name */
  Title = 'title',
  /** column name */
  WebpushBonusId = 'webpush_bonus_id',
  /** column name */
  WebpushId = 'webpush_id',
  /** column name */
  WebpushUniqueTrigger = 'webpush_unique_trigger'
}

export type Webpushes_Autosending_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Webpushes_Autosending_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Webpushes_Autosending_Types_Bool_Exp;
};

/** columns and relationships of "webpushes_bonuses" */
export type Webpushes_Bonuses = {
  __typename?: 'webpushes_bonuses';
  bonus_additional_value?: Maybe<Scalars['jsonb']>;
  bonus_id: Scalars['uuid'];
  bonus_type: Scalars['String'];
  bonus_value: Scalars['String'];
  /** An array relationship */
  webpushes_bonuses_offers: Array<Webpushes_Bonuses_Offers>;
  /** An aggregate relationship */
  webpushes_bonuses_offers_aggregate: Webpushes_Bonuses_Offers_Aggregate;
};


/** columns and relationships of "webpushes_bonuses" */
export type Webpushes_BonusesBonus_Additional_ValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "webpushes_bonuses" */
export type Webpushes_BonusesWebpushes_Bonuses_OffersArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
};


/** columns and relationships of "webpushes_bonuses" */
export type Webpushes_BonusesWebpushes_Bonuses_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Bonuses_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Bonuses_Offers_Order_By>>;
  where?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
};

/** aggregated selection of "webpushes_bonuses" */
export type Webpushes_Bonuses_Aggregate = {
  __typename?: 'webpushes_bonuses_aggregate';
  aggregate?: Maybe<Webpushes_Bonuses_Aggregate_Fields>;
  nodes: Array<Webpushes_Bonuses>;
};

/** aggregate fields of "webpushes_bonuses" */
export type Webpushes_Bonuses_Aggregate_Fields = {
  __typename?: 'webpushes_bonuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Webpushes_Bonuses_Max_Fields>;
  min?: Maybe<Webpushes_Bonuses_Min_Fields>;
};


/** aggregate fields of "webpushes_bonuses" */
export type Webpushes_Bonuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webpushes_Bonuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Webpushes_Bonuses_Append_Input = {
  bonus_additional_value?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "webpushes_bonuses". All fields are combined with a logical 'AND'. */
export type Webpushes_Bonuses_Bool_Exp = {
  _and?: InputMaybe<Array<Webpushes_Bonuses_Bool_Exp>>;
  _not?: InputMaybe<Webpushes_Bonuses_Bool_Exp>;
  _or?: InputMaybe<Array<Webpushes_Bonuses_Bool_Exp>>;
  bonus_additional_value?: InputMaybe<Jsonb_Comparison_Exp>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  bonus_type?: InputMaybe<String_Comparison_Exp>;
  bonus_value?: InputMaybe<String_Comparison_Exp>;
  webpushes_bonuses_offers?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
  webpushes_bonuses_offers_aggregate?: InputMaybe<Webpushes_Bonuses_Offers_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "webpushes_bonuses" */
export enum Webpushes_Bonuses_Constraint {
  /** unique or primary key constraint on columns "bonus_id" */
  WebpushesBonusesPkey = 'webpushes_bonuses_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Webpushes_Bonuses_Delete_At_Path_Input = {
  bonus_additional_value?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Webpushes_Bonuses_Delete_Elem_Input = {
  bonus_additional_value?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Webpushes_Bonuses_Delete_Key_Input = {
  bonus_additional_value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "webpushes_bonuses" */
export type Webpushes_Bonuses_Insert_Input = {
  bonus_additional_value?: InputMaybe<Scalars['jsonb']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_type?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['String']>;
  webpushes_bonuses_offers?: InputMaybe<Webpushes_Bonuses_Offers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Webpushes_Bonuses_Max_Fields = {
  __typename?: 'webpushes_bonuses_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_type?: Maybe<Scalars['String']>;
  bonus_value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Webpushes_Bonuses_Min_Fields = {
  __typename?: 'webpushes_bonuses_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  bonus_type?: Maybe<Scalars['String']>;
  bonus_value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "webpushes_bonuses" */
export type Webpushes_Bonuses_Mutation_Response = {
  __typename?: 'webpushes_bonuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webpushes_Bonuses>;
};

/** input type for inserting object relation for remote table "webpushes_bonuses" */
export type Webpushes_Bonuses_Obj_Rel_Insert_Input = {
  data: Webpushes_Bonuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Webpushes_Bonuses_On_Conflict>;
};

/** columns and relationships of "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers = {
  __typename?: 'webpushes_bonuses_offers';
  bonus_id: Scalars['uuid'];
  claim_datetime?: Maybe<Scalars['timestamp']>;
  claimed: Scalars['Boolean'];
  created_at: Scalars['timestamp'];
  current_active: Scalars['Boolean'];
  end_timer?: Maybe<Scalars['Int']>;
  offer_value: Scalars['String'];
  updated_at: Scalars['timestamp'];
  user_id: Scalars['uuid'];
  /** An object relationship */
  webpushes_bonuse: Webpushes_Bonuses;
};

/** aggregated selection of "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Aggregate = {
  __typename?: 'webpushes_bonuses_offers_aggregate';
  aggregate?: Maybe<Webpushes_Bonuses_Offers_Aggregate_Fields>;
  nodes: Array<Webpushes_Bonuses_Offers>;
};

export type Webpushes_Bonuses_Offers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Webpushes_Bonuses_Offers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Webpushes_Bonuses_Offers_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Webpushes_Bonuses_Offers_Aggregate_Bool_Exp_Count>;
};

export type Webpushes_Bonuses_Offers_Aggregate_Bool_Exp_Bool_And = {
  arguments: Webpushes_Bonuses_Offers_Select_Column_Webpushes_Bonuses_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Webpushes_Bonuses_Offers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Webpushes_Bonuses_Offers_Select_Column_Webpushes_Bonuses_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Webpushes_Bonuses_Offers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Webpushes_Bonuses_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Aggregate_Fields = {
  __typename?: 'webpushes_bonuses_offers_aggregate_fields';
  avg?: Maybe<Webpushes_Bonuses_Offers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Webpushes_Bonuses_Offers_Max_Fields>;
  min?: Maybe<Webpushes_Bonuses_Offers_Min_Fields>;
  stddev?: Maybe<Webpushes_Bonuses_Offers_Stddev_Fields>;
  stddev_pop?: Maybe<Webpushes_Bonuses_Offers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Webpushes_Bonuses_Offers_Stddev_Samp_Fields>;
  sum?: Maybe<Webpushes_Bonuses_Offers_Sum_Fields>;
  var_pop?: Maybe<Webpushes_Bonuses_Offers_Var_Pop_Fields>;
  var_samp?: Maybe<Webpushes_Bonuses_Offers_Var_Samp_Fields>;
  variance?: Maybe<Webpushes_Bonuses_Offers_Variance_Fields>;
};


/** aggregate fields of "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webpushes_Bonuses_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Aggregate_Order_By = {
  avg?: InputMaybe<Webpushes_Bonuses_Offers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Webpushes_Bonuses_Offers_Max_Order_By>;
  min?: InputMaybe<Webpushes_Bonuses_Offers_Min_Order_By>;
  stddev?: InputMaybe<Webpushes_Bonuses_Offers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Webpushes_Bonuses_Offers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Webpushes_Bonuses_Offers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Webpushes_Bonuses_Offers_Sum_Order_By>;
  var_pop?: InputMaybe<Webpushes_Bonuses_Offers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Webpushes_Bonuses_Offers_Var_Samp_Order_By>;
  variance?: InputMaybe<Webpushes_Bonuses_Offers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Arr_Rel_Insert_Input = {
  data: Array<Webpushes_Bonuses_Offers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Webpushes_Bonuses_Offers_On_Conflict>;
};

/** aggregate avg on columns */
export type Webpushes_Bonuses_Offers_Avg_Fields = {
  __typename?: 'webpushes_bonuses_offers_avg_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Avg_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "webpushes_bonuses_offers". All fields are combined with a logical 'AND'. */
export type Webpushes_Bonuses_Offers_Bool_Exp = {
  _and?: InputMaybe<Array<Webpushes_Bonuses_Offers_Bool_Exp>>;
  _not?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
  _or?: InputMaybe<Array<Webpushes_Bonuses_Offers_Bool_Exp>>;
  bonus_id?: InputMaybe<Uuid_Comparison_Exp>;
  claim_datetime?: InputMaybe<Timestamp_Comparison_Exp>;
  claimed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  current_active?: InputMaybe<Boolean_Comparison_Exp>;
  end_timer?: InputMaybe<Int_Comparison_Exp>;
  offer_value?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  webpushes_bonuse?: InputMaybe<Webpushes_Bonuses_Bool_Exp>;
};

/** unique or primary key constraints on table "webpushes_bonuses_offers" */
export enum Webpushes_Bonuses_Offers_Constraint {
  /** unique or primary key constraint on columns "user_id", "bonus_id" */
  WebpushesBonusesOffersPkey = 'webpushes_bonuses_offers_pkey'
}

/** input type for incrementing numeric columns in table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Inc_Input = {
  end_timer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Insert_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  claim_datetime?: InputMaybe<Scalars['timestamp']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  current_active?: InputMaybe<Scalars['Boolean']>;
  end_timer?: InputMaybe<Scalars['Int']>;
  offer_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  webpushes_bonuse?: InputMaybe<Webpushes_Bonuses_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Webpushes_Bonuses_Offers_Max_Fields = {
  __typename?: 'webpushes_bonuses_offers_max_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  claim_datetime?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamp']>;
  end_timer?: Maybe<Scalars['Int']>;
  offer_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Max_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  claim_datetime?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_timer?: InputMaybe<Order_By>;
  offer_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Webpushes_Bonuses_Offers_Min_Fields = {
  __typename?: 'webpushes_bonuses_offers_min_fields';
  bonus_id?: Maybe<Scalars['uuid']>;
  claim_datetime?: Maybe<Scalars['timestamp']>;
  created_at?: Maybe<Scalars['timestamp']>;
  end_timer?: Maybe<Scalars['Int']>;
  offer_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Min_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  claim_datetime?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_timer?: InputMaybe<Order_By>;
  offer_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Mutation_Response = {
  __typename?: 'webpushes_bonuses_offers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webpushes_Bonuses_Offers>;
};

/** on_conflict condition type for table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_On_Conflict = {
  constraint: Webpushes_Bonuses_Offers_Constraint;
  update_columns?: Array<Webpushes_Bonuses_Offers_Update_Column>;
  where?: InputMaybe<Webpushes_Bonuses_Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "webpushes_bonuses_offers". */
export type Webpushes_Bonuses_Offers_Order_By = {
  bonus_id?: InputMaybe<Order_By>;
  claim_datetime?: InputMaybe<Order_By>;
  claimed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_active?: InputMaybe<Order_By>;
  end_timer?: InputMaybe<Order_By>;
  offer_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  webpushes_bonuse?: InputMaybe<Webpushes_Bonuses_Order_By>;
};

/** primary key columns input for table: webpushes_bonuses_offers */
export type Webpushes_Bonuses_Offers_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "webpushes_bonuses_offers" */
export enum Webpushes_Bonuses_Offers_Select_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ClaimDatetime = 'claim_datetime',
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentActive = 'current_active',
  /** column name */
  EndTimer = 'end_timer',
  /** column name */
  OfferValue = 'offer_value',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "webpushes_bonuses_offers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "webpushes_bonuses_offers" */
export enum Webpushes_Bonuses_Offers_Select_Column_Webpushes_Bonuses_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CurrentActive = 'current_active'
}

/** select "webpushes_bonuses_offers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "webpushes_bonuses_offers" */
export enum Webpushes_Bonuses_Offers_Select_Column_Webpushes_Bonuses_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CurrentActive = 'current_active'
}

/** input type for updating data in table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Set_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  claim_datetime?: InputMaybe<Scalars['timestamp']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  current_active?: InputMaybe<Scalars['Boolean']>;
  end_timer?: InputMaybe<Scalars['Int']>;
  offer_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Webpushes_Bonuses_Offers_Stddev_Fields = {
  __typename?: 'webpushes_bonuses_offers_stddev_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Stddev_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Webpushes_Bonuses_Offers_Stddev_Pop_Fields = {
  __typename?: 'webpushes_bonuses_offers_stddev_pop_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Stddev_Pop_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Webpushes_Bonuses_Offers_Stddev_Samp_Fields = {
  __typename?: 'webpushes_bonuses_offers_stddev_samp_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Stddev_Samp_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Webpushes_Bonuses_Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Webpushes_Bonuses_Offers_Stream_Cursor_Value_Input = {
  bonus_id?: InputMaybe<Scalars['uuid']>;
  claim_datetime?: InputMaybe<Scalars['timestamp']>;
  claimed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  current_active?: InputMaybe<Scalars['Boolean']>;
  end_timer?: InputMaybe<Scalars['Int']>;
  offer_value?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Webpushes_Bonuses_Offers_Sum_Fields = {
  __typename?: 'webpushes_bonuses_offers_sum_fields';
  end_timer?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Sum_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** update columns of table "webpushes_bonuses_offers" */
export enum Webpushes_Bonuses_Offers_Update_Column {
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  ClaimDatetime = 'claim_datetime',
  /** column name */
  Claimed = 'claimed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentActive = 'current_active',
  /** column name */
  EndTimer = 'end_timer',
  /** column name */
  OfferValue = 'offer_value',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Webpushes_Bonuses_Offers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Webpushes_Bonuses_Offers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Webpushes_Bonuses_Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Webpushes_Bonuses_Offers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Webpushes_Bonuses_Offers_Var_Pop_Fields = {
  __typename?: 'webpushes_bonuses_offers_var_pop_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Var_Pop_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Webpushes_Bonuses_Offers_Var_Samp_Fields = {
  __typename?: 'webpushes_bonuses_offers_var_samp_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Var_Samp_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Webpushes_Bonuses_Offers_Variance_Fields = {
  __typename?: 'webpushes_bonuses_offers_variance_fields';
  end_timer?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "webpushes_bonuses_offers" */
export type Webpushes_Bonuses_Offers_Variance_Order_By = {
  end_timer?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "webpushes_bonuses" */
export type Webpushes_Bonuses_On_Conflict = {
  constraint: Webpushes_Bonuses_Constraint;
  update_columns?: Array<Webpushes_Bonuses_Update_Column>;
  where?: InputMaybe<Webpushes_Bonuses_Bool_Exp>;
};

/** Ordering options when selecting data from "webpushes_bonuses". */
export type Webpushes_Bonuses_Order_By = {
  bonus_additional_value?: InputMaybe<Order_By>;
  bonus_id?: InputMaybe<Order_By>;
  bonus_type?: InputMaybe<Order_By>;
  bonus_value?: InputMaybe<Order_By>;
  webpushes_bonuses_offers_aggregate?: InputMaybe<Webpushes_Bonuses_Offers_Aggregate_Order_By>;
};

/** primary key columns input for table: webpushes_bonuses */
export type Webpushes_Bonuses_Pk_Columns_Input = {
  bonus_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Webpushes_Bonuses_Prepend_Input = {
  bonus_additional_value?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "webpushes_bonuses" */
export enum Webpushes_Bonuses_Select_Column {
  /** column name */
  BonusAdditionalValue = 'bonus_additional_value',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusType = 'bonus_type',
  /** column name */
  BonusValue = 'bonus_value'
}

/** input type for updating data in table "webpushes_bonuses" */
export type Webpushes_Bonuses_Set_Input = {
  bonus_additional_value?: InputMaybe<Scalars['jsonb']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_type?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "webpushes_bonuses" */
export type Webpushes_Bonuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Webpushes_Bonuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Webpushes_Bonuses_Stream_Cursor_Value_Input = {
  bonus_additional_value?: InputMaybe<Scalars['jsonb']>;
  bonus_id?: InputMaybe<Scalars['uuid']>;
  bonus_type?: InputMaybe<Scalars['String']>;
  bonus_value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "webpushes_bonuses" */
export enum Webpushes_Bonuses_Update_Column {
  /** column name */
  BonusAdditionalValue = 'bonus_additional_value',
  /** column name */
  BonusId = 'bonus_id',
  /** column name */
  BonusType = 'bonus_type',
  /** column name */
  BonusValue = 'bonus_value'
}

export type Webpushes_Bonuses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Webpushes_Bonuses_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Webpushes_Bonuses_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Webpushes_Bonuses_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Webpushes_Bonuses_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Webpushes_Bonuses_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Webpushes_Bonuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Webpushes_Bonuses_Bool_Exp;
};

/** columns and relationships of "webpushes_lines" */
export type Webpushes_Lines = {
  __typename?: 'webpushes_lines';
  break_condition?: Maybe<Scalars['String']>;
  next_push_hours_skip?: Maybe<Scalars['Int']>;
  /** An object relationship */
  next_webpush?: Maybe<Webpushes_Autosending_Types>;
  next_webpush_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  previous_webpush?: Maybe<Webpushes_Autosending_Types>;
  previous_webpush_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  webpush?: Maybe<Webpushes_Autosending_Types>;
  webpush_id: Scalars['uuid'];
};

/** aggregated selection of "webpushes_lines" */
export type Webpushes_Lines_Aggregate = {
  __typename?: 'webpushes_lines_aggregate';
  aggregate?: Maybe<Webpushes_Lines_Aggregate_Fields>;
  nodes: Array<Webpushes_Lines>;
};

/** aggregate fields of "webpushes_lines" */
export type Webpushes_Lines_Aggregate_Fields = {
  __typename?: 'webpushes_lines_aggregate_fields';
  avg?: Maybe<Webpushes_Lines_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Webpushes_Lines_Max_Fields>;
  min?: Maybe<Webpushes_Lines_Min_Fields>;
  stddev?: Maybe<Webpushes_Lines_Stddev_Fields>;
  stddev_pop?: Maybe<Webpushes_Lines_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Webpushes_Lines_Stddev_Samp_Fields>;
  sum?: Maybe<Webpushes_Lines_Sum_Fields>;
  var_pop?: Maybe<Webpushes_Lines_Var_Pop_Fields>;
  var_samp?: Maybe<Webpushes_Lines_Var_Samp_Fields>;
  variance?: Maybe<Webpushes_Lines_Variance_Fields>;
};


/** aggregate fields of "webpushes_lines" */
export type Webpushes_Lines_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webpushes_Lines_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Webpushes_Lines_Avg_Fields = {
  __typename?: 'webpushes_lines_avg_fields';
  next_push_hours_skip?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "webpushes_lines". All fields are combined with a logical 'AND'. */
export type Webpushes_Lines_Bool_Exp = {
  _and?: InputMaybe<Array<Webpushes_Lines_Bool_Exp>>;
  _not?: InputMaybe<Webpushes_Lines_Bool_Exp>;
  _or?: InputMaybe<Array<Webpushes_Lines_Bool_Exp>>;
  break_condition?: InputMaybe<String_Comparison_Exp>;
  next_push_hours_skip?: InputMaybe<Int_Comparison_Exp>;
  next_webpush?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
  next_webpush_id?: InputMaybe<Uuid_Comparison_Exp>;
  previous_webpush?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
  previous_webpush_id?: InputMaybe<Uuid_Comparison_Exp>;
  webpush?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
  webpush_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "webpushes_lines" */
export enum Webpushes_Lines_Constraint {
  /** unique or primary key constraint on columns "webpush_id" */
  WebpushesLinesPkey = 'webpushes_lines_pkey'
}

/** input type for incrementing numeric columns in table "webpushes_lines" */
export type Webpushes_Lines_Inc_Input = {
  next_push_hours_skip?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "webpushes_lines" */
export type Webpushes_Lines_Insert_Input = {
  break_condition?: InputMaybe<Scalars['String']>;
  next_push_hours_skip?: InputMaybe<Scalars['Int']>;
  next_webpush?: InputMaybe<Webpushes_Autosending_Types_Obj_Rel_Insert_Input>;
  next_webpush_id?: InputMaybe<Scalars['uuid']>;
  previous_webpush?: InputMaybe<Webpushes_Autosending_Types_Obj_Rel_Insert_Input>;
  previous_webpush_id?: InputMaybe<Scalars['uuid']>;
  webpush?: InputMaybe<Webpushes_Autosending_Types_Obj_Rel_Insert_Input>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Webpushes_Lines_Max_Fields = {
  __typename?: 'webpushes_lines_max_fields';
  break_condition?: Maybe<Scalars['String']>;
  next_push_hours_skip?: Maybe<Scalars['Int']>;
  next_webpush_id?: Maybe<Scalars['uuid']>;
  previous_webpush_id?: Maybe<Scalars['uuid']>;
  webpush_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Webpushes_Lines_Min_Fields = {
  __typename?: 'webpushes_lines_min_fields';
  break_condition?: Maybe<Scalars['String']>;
  next_push_hours_skip?: Maybe<Scalars['Int']>;
  next_webpush_id?: Maybe<Scalars['uuid']>;
  previous_webpush_id?: Maybe<Scalars['uuid']>;
  webpush_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "webpushes_lines" */
export type Webpushes_Lines_Mutation_Response = {
  __typename?: 'webpushes_lines_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webpushes_Lines>;
};

/** input type for inserting object relation for remote table "webpushes_lines" */
export type Webpushes_Lines_Obj_Rel_Insert_Input = {
  data: Webpushes_Lines_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Webpushes_Lines_On_Conflict>;
};

/** on_conflict condition type for table "webpushes_lines" */
export type Webpushes_Lines_On_Conflict = {
  constraint: Webpushes_Lines_Constraint;
  update_columns?: Array<Webpushes_Lines_Update_Column>;
  where?: InputMaybe<Webpushes_Lines_Bool_Exp>;
};

/** Ordering options when selecting data from "webpushes_lines". */
export type Webpushes_Lines_Order_By = {
  break_condition?: InputMaybe<Order_By>;
  next_push_hours_skip?: InputMaybe<Order_By>;
  next_webpush?: InputMaybe<Webpushes_Autosending_Types_Order_By>;
  next_webpush_id?: InputMaybe<Order_By>;
  previous_webpush?: InputMaybe<Webpushes_Autosending_Types_Order_By>;
  previous_webpush_id?: InputMaybe<Order_By>;
  webpush?: InputMaybe<Webpushes_Autosending_Types_Order_By>;
  webpush_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: webpushes_lines */
export type Webpushes_Lines_Pk_Columns_Input = {
  webpush_id: Scalars['uuid'];
};

/** select columns of table "webpushes_lines" */
export enum Webpushes_Lines_Select_Column {
  /** column name */
  BreakCondition = 'break_condition',
  /** column name */
  NextPushHoursSkip = 'next_push_hours_skip',
  /** column name */
  NextWebpushId = 'next_webpush_id',
  /** column name */
  PreviousWebpushId = 'previous_webpush_id',
  /** column name */
  WebpushId = 'webpush_id'
}

/** input type for updating data in table "webpushes_lines" */
export type Webpushes_Lines_Set_Input = {
  break_condition?: InputMaybe<Scalars['String']>;
  next_push_hours_skip?: InputMaybe<Scalars['Int']>;
  next_webpush_id?: InputMaybe<Scalars['uuid']>;
  previous_webpush_id?: InputMaybe<Scalars['uuid']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Webpushes_Lines_Stddev_Fields = {
  __typename?: 'webpushes_lines_stddev_fields';
  next_push_hours_skip?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Webpushes_Lines_Stddev_Pop_Fields = {
  __typename?: 'webpushes_lines_stddev_pop_fields';
  next_push_hours_skip?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Webpushes_Lines_Stddev_Samp_Fields = {
  __typename?: 'webpushes_lines_stddev_samp_fields';
  next_push_hours_skip?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "webpushes_lines" */
export type Webpushes_Lines_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Webpushes_Lines_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Webpushes_Lines_Stream_Cursor_Value_Input = {
  break_condition?: InputMaybe<Scalars['String']>;
  next_push_hours_skip?: InputMaybe<Scalars['Int']>;
  next_webpush_id?: InputMaybe<Scalars['uuid']>;
  previous_webpush_id?: InputMaybe<Scalars['uuid']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Webpushes_Lines_Sum_Fields = {
  __typename?: 'webpushes_lines_sum_fields';
  next_push_hours_skip?: Maybe<Scalars['Int']>;
};

/** update columns of table "webpushes_lines" */
export enum Webpushes_Lines_Update_Column {
  /** column name */
  BreakCondition = 'break_condition',
  /** column name */
  NextPushHoursSkip = 'next_push_hours_skip',
  /** column name */
  NextWebpushId = 'next_webpush_id',
  /** column name */
  PreviousWebpushId = 'previous_webpush_id',
  /** column name */
  WebpushId = 'webpush_id'
}

export type Webpushes_Lines_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Webpushes_Lines_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Webpushes_Lines_Set_Input>;
  /** filter the rows which have to be updated */
  where: Webpushes_Lines_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Webpushes_Lines_Var_Pop_Fields = {
  __typename?: 'webpushes_lines_var_pop_fields';
  next_push_hours_skip?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Webpushes_Lines_Var_Samp_Fields = {
  __typename?: 'webpushes_lines_var_samp_fields';
  next_push_hours_skip?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Webpushes_Lines_Variance_Fields = {
  __typename?: 'webpushes_lines_variance_fields';
  next_push_hours_skip?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "webpushes_scheduling" */
export type Webpushes_Scheduling = {
  __typename?: 'webpushes_scheduling';
  cancelled_by_trigger: Scalars['Boolean'];
  created_at: Scalars['timestamp'];
  dispatch_time: Scalars['timestamp'];
  dispatched: Scalars['Boolean'];
  should_validate_trigger: Scalars['Boolean'];
  updated_at: Scalars['timestamp'];
  user_id: Scalars['uuid'];
  user_profile?: Maybe<User_Profile>;
  webpush_id: Scalars['uuid'];
  /** An object relationship */
  webpushes_autosending_type: Webpushes_Autosending_Types;
  /** An object relationship */
  webpushes_line?: Maybe<Webpushes_Lines>;
};

/** aggregated selection of "webpushes_scheduling" */
export type Webpushes_Scheduling_Aggregate = {
  __typename?: 'webpushes_scheduling_aggregate';
  aggregate?: Maybe<Webpushes_Scheduling_Aggregate_Fields>;
  nodes: Array<Webpushes_Scheduling>;
};

export type Webpushes_Scheduling_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Webpushes_Scheduling_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Webpushes_Scheduling_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Webpushes_Scheduling_Aggregate_Bool_Exp_Count>;
};

export type Webpushes_Scheduling_Aggregate_Bool_Exp_Bool_And = {
  arguments: Webpushes_Scheduling_Select_Column_Webpushes_Scheduling_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Webpushes_Scheduling_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Webpushes_Scheduling_Select_Column_Webpushes_Scheduling_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Webpushes_Scheduling_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Webpushes_Scheduling_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "webpushes_scheduling" */
export type Webpushes_Scheduling_Aggregate_Fields = {
  __typename?: 'webpushes_scheduling_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Webpushes_Scheduling_Max_Fields>;
  min?: Maybe<Webpushes_Scheduling_Min_Fields>;
};


/** aggregate fields of "webpushes_scheduling" */
export type Webpushes_Scheduling_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webpushes_Scheduling_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "webpushes_scheduling" */
export type Webpushes_Scheduling_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Webpushes_Scheduling_Max_Order_By>;
  min?: InputMaybe<Webpushes_Scheduling_Min_Order_By>;
};

/** input type for inserting array relation for remote table "webpushes_scheduling" */
export type Webpushes_Scheduling_Arr_Rel_Insert_Input = {
  data: Array<Webpushes_Scheduling_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Webpushes_Scheduling_On_Conflict>;
};

/** Boolean expression to filter rows from the table "webpushes_scheduling". All fields are combined with a logical 'AND'. */
export type Webpushes_Scheduling_Bool_Exp = {
  _and?: InputMaybe<Array<Webpushes_Scheduling_Bool_Exp>>;
  _not?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
  _or?: InputMaybe<Array<Webpushes_Scheduling_Bool_Exp>>;
  cancelled_by_trigger?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  dispatch_time?: InputMaybe<Timestamp_Comparison_Exp>;
  dispatched?: InputMaybe<Boolean_Comparison_Exp>;
  should_validate_trigger?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  webpush_id?: InputMaybe<Uuid_Comparison_Exp>;
  webpushes_autosending_type?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
  webpushes_line?: InputMaybe<Webpushes_Lines_Bool_Exp>;
};

/** unique or primary key constraints on table "webpushes_scheduling" */
export enum Webpushes_Scheduling_Constraint {
  /** unique or primary key constraint on columns "webpush_id", "user_id" */
  WebpushesSchedulingPkey = 'webpushes_scheduling_pkey'
}

/** input type for inserting data into table "webpushes_scheduling" */
export type Webpushes_Scheduling_Insert_Input = {
  cancelled_by_trigger?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dispatch_time?: InputMaybe<Scalars['timestamp']>;
  dispatched?: InputMaybe<Scalars['Boolean']>;
  should_validate_trigger?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
  webpushes_autosending_type?: InputMaybe<Webpushes_Autosending_Types_Obj_Rel_Insert_Input>;
  webpushes_line?: InputMaybe<Webpushes_Lines_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Webpushes_Scheduling_Max_Fields = {
  __typename?: 'webpushes_scheduling_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  dispatch_time?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  webpush_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "webpushes_scheduling" */
export type Webpushes_Scheduling_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dispatch_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  webpush_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Webpushes_Scheduling_Min_Fields = {
  __typename?: 'webpushes_scheduling_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  dispatch_time?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
  webpush_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "webpushes_scheduling" */
export type Webpushes_Scheduling_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dispatch_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  webpush_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "webpushes_scheduling" */
export type Webpushes_Scheduling_Mutation_Response = {
  __typename?: 'webpushes_scheduling_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webpushes_Scheduling>;
};

/** on_conflict condition type for table "webpushes_scheduling" */
export type Webpushes_Scheduling_On_Conflict = {
  constraint: Webpushes_Scheduling_Constraint;
  update_columns?: Array<Webpushes_Scheduling_Update_Column>;
  where?: InputMaybe<Webpushes_Scheduling_Bool_Exp>;
};

/** Ordering options when selecting data from "webpushes_scheduling". */
export type Webpushes_Scheduling_Order_By = {
  cancelled_by_trigger?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dispatch_time?: InputMaybe<Order_By>;
  dispatched?: InputMaybe<Order_By>;
  should_validate_trigger?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  webpush_id?: InputMaybe<Order_By>;
  webpushes_autosending_type?: InputMaybe<Webpushes_Autosending_Types_Order_By>;
  webpushes_line?: InputMaybe<Webpushes_Lines_Order_By>;
};

/** primary key columns input for table: webpushes_scheduling */
export type Webpushes_Scheduling_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
  webpush_id: Scalars['uuid'];
};

/** select columns of table "webpushes_scheduling" */
export enum Webpushes_Scheduling_Select_Column {
  /** column name */
  CancelledByTrigger = 'cancelled_by_trigger',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DispatchTime = 'dispatch_time',
  /** column name */
  Dispatched = 'dispatched',
  /** column name */
  ShouldValidateTrigger = 'should_validate_trigger',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WebpushId = 'webpush_id'
}

/** select "webpushes_scheduling_aggregate_bool_exp_bool_and_arguments_columns" columns of table "webpushes_scheduling" */
export enum Webpushes_Scheduling_Select_Column_Webpushes_Scheduling_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CancelledByTrigger = 'cancelled_by_trigger',
  /** column name */
  Dispatched = 'dispatched',
  /** column name */
  ShouldValidateTrigger = 'should_validate_trigger'
}

/** select "webpushes_scheduling_aggregate_bool_exp_bool_or_arguments_columns" columns of table "webpushes_scheduling" */
export enum Webpushes_Scheduling_Select_Column_Webpushes_Scheduling_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CancelledByTrigger = 'cancelled_by_trigger',
  /** column name */
  Dispatched = 'dispatched',
  /** column name */
  ShouldValidateTrigger = 'should_validate_trigger'
}

/** input type for updating data in table "webpushes_scheduling" */
export type Webpushes_Scheduling_Set_Input = {
  cancelled_by_trigger?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dispatch_time?: InputMaybe<Scalars['timestamp']>;
  dispatched?: InputMaybe<Scalars['Boolean']>;
  should_validate_trigger?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "webpushes_scheduling" */
export type Webpushes_Scheduling_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Webpushes_Scheduling_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Webpushes_Scheduling_Stream_Cursor_Value_Input = {
  cancelled_by_trigger?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dispatch_time?: InputMaybe<Scalars['timestamp']>;
  dispatched?: InputMaybe<Scalars['Boolean']>;
  should_validate_trigger?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "webpushes_scheduling" */
export enum Webpushes_Scheduling_Update_Column {
  /** column name */
  CancelledByTrigger = 'cancelled_by_trigger',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DispatchTime = 'dispatch_time',
  /** column name */
  Dispatched = 'dispatched',
  /** column name */
  ShouldValidateTrigger = 'should_validate_trigger',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WebpushId = 'webpush_id'
}

export type Webpushes_Scheduling_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Webpushes_Scheduling_Set_Input>;
  /** filter the rows which have to be updated */
  where: Webpushes_Scheduling_Bool_Exp;
};

/** columns and relationships of "webpushes_triggers" */
export type Webpushes_Triggers = {
  __typename?: 'webpushes_triggers';
  operator: Scalars['String'];
  trigger_name: Scalars['String'];
  trigger_param?: Maybe<Scalars['String']>;
  trigger_type: Scalars['String'];
  trigger_value?: Maybe<Scalars['String']>;
  triggers_group_id: Scalars['uuid'];
  /** An object relationship */
  webpush_group?: Maybe<Webpushes_Triggers_Groups>;
};

/** aggregated selection of "webpushes_triggers" */
export type Webpushes_Triggers_Aggregate = {
  __typename?: 'webpushes_triggers_aggregate';
  aggregate?: Maybe<Webpushes_Triggers_Aggregate_Fields>;
  nodes: Array<Webpushes_Triggers>;
};

export type Webpushes_Triggers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Webpushes_Triggers_Aggregate_Bool_Exp_Count>;
};

export type Webpushes_Triggers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Webpushes_Triggers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Webpushes_Triggers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "webpushes_triggers" */
export type Webpushes_Triggers_Aggregate_Fields = {
  __typename?: 'webpushes_triggers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Webpushes_Triggers_Max_Fields>;
  min?: Maybe<Webpushes_Triggers_Min_Fields>;
};


/** aggregate fields of "webpushes_triggers" */
export type Webpushes_Triggers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webpushes_Triggers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "webpushes_triggers" */
export type Webpushes_Triggers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Webpushes_Triggers_Max_Order_By>;
  min?: InputMaybe<Webpushes_Triggers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "webpushes_triggers" */
export type Webpushes_Triggers_Arr_Rel_Insert_Input = {
  data: Array<Webpushes_Triggers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Webpushes_Triggers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "webpushes_triggers". All fields are combined with a logical 'AND'. */
export type Webpushes_Triggers_Bool_Exp = {
  _and?: InputMaybe<Array<Webpushes_Triggers_Bool_Exp>>;
  _not?: InputMaybe<Webpushes_Triggers_Bool_Exp>;
  _or?: InputMaybe<Array<Webpushes_Triggers_Bool_Exp>>;
  operator?: InputMaybe<String_Comparison_Exp>;
  trigger_name?: InputMaybe<String_Comparison_Exp>;
  trigger_param?: InputMaybe<String_Comparison_Exp>;
  trigger_type?: InputMaybe<String_Comparison_Exp>;
  trigger_value?: InputMaybe<String_Comparison_Exp>;
  triggers_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  webpush_group?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
};

/** unique or primary key constraints on table "webpushes_triggers" */
export enum Webpushes_Triggers_Constraint {
  /** unique or primary key constraint on columns "trigger_name", "triggers_group_id" */
  WebpushesTriggersPkey = 'webpushes_triggers_pkey'
}

/** columns and relationships of "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups = {
  __typename?: 'webpushes_triggers_groups';
  group_and_clause: Scalars['Boolean'];
  triggers_group_id: Scalars['uuid'];
  webpush_id: Scalars['uuid'];
  /** An object relationship */
  webpushes_autosending_type: Webpushes_Autosending_Types;
  /** An array relationship */
  webpushes_triggers: Array<Webpushes_Triggers>;
  /** An aggregate relationship */
  webpushes_triggers_aggregate: Webpushes_Triggers_Aggregate;
};


/** columns and relationships of "webpushes_triggers_groups" */
export type Webpushes_Triggers_GroupsWebpushes_TriggersArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Bool_Exp>;
};


/** columns and relationships of "webpushes_triggers_groups" */
export type Webpushes_Triggers_GroupsWebpushes_Triggers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webpushes_Triggers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webpushes_Triggers_Order_By>>;
  where?: InputMaybe<Webpushes_Triggers_Bool_Exp>;
};

/** aggregated selection of "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Aggregate = {
  __typename?: 'webpushes_triggers_groups_aggregate';
  aggregate?: Maybe<Webpushes_Triggers_Groups_Aggregate_Fields>;
  nodes: Array<Webpushes_Triggers_Groups>;
};

export type Webpushes_Triggers_Groups_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Webpushes_Triggers_Groups_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Webpushes_Triggers_Groups_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Webpushes_Triggers_Groups_Aggregate_Bool_Exp_Count>;
};

export type Webpushes_Triggers_Groups_Aggregate_Bool_Exp_Bool_And = {
  arguments: Webpushes_Triggers_Groups_Select_Column_Webpushes_Triggers_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Webpushes_Triggers_Groups_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Webpushes_Triggers_Groups_Select_Column_Webpushes_Triggers_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Webpushes_Triggers_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Webpushes_Triggers_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Aggregate_Fields = {
  __typename?: 'webpushes_triggers_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Webpushes_Triggers_Groups_Max_Fields>;
  min?: Maybe<Webpushes_Triggers_Groups_Min_Fields>;
};


/** aggregate fields of "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webpushes_Triggers_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Webpushes_Triggers_Groups_Max_Order_By>;
  min?: InputMaybe<Webpushes_Triggers_Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Arr_Rel_Insert_Input = {
  data: Array<Webpushes_Triggers_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Webpushes_Triggers_Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "webpushes_triggers_groups". All fields are combined with a logical 'AND'. */
export type Webpushes_Triggers_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Webpushes_Triggers_Groups_Bool_Exp>>;
  _not?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Webpushes_Triggers_Groups_Bool_Exp>>;
  group_and_clause?: InputMaybe<Boolean_Comparison_Exp>;
  triggers_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  webpush_id?: InputMaybe<Uuid_Comparison_Exp>;
  webpushes_autosending_type?: InputMaybe<Webpushes_Autosending_Types_Bool_Exp>;
  webpushes_triggers?: InputMaybe<Webpushes_Triggers_Bool_Exp>;
  webpushes_triggers_aggregate?: InputMaybe<Webpushes_Triggers_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "webpushes_triggers_groups" */
export enum Webpushes_Triggers_Groups_Constraint {
  /** unique or primary key constraint on columns "webpush_id", "triggers_group_id" */
  WebpushesTriggersGroupsPkey = 'webpushes_triggers_groups_pkey'
}

/** input type for inserting data into table "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Insert_Input = {
  group_and_clause?: InputMaybe<Scalars['Boolean']>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
  webpushes_autosending_type?: InputMaybe<Webpushes_Autosending_Types_Obj_Rel_Insert_Input>;
  webpushes_triggers?: InputMaybe<Webpushes_Triggers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Webpushes_Triggers_Groups_Max_Fields = {
  __typename?: 'webpushes_triggers_groups_max_fields';
  triggers_group_id?: Maybe<Scalars['uuid']>;
  webpush_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Max_Order_By = {
  triggers_group_id?: InputMaybe<Order_By>;
  webpush_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Webpushes_Triggers_Groups_Min_Fields = {
  __typename?: 'webpushes_triggers_groups_min_fields';
  triggers_group_id?: Maybe<Scalars['uuid']>;
  webpush_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Min_Order_By = {
  triggers_group_id?: InputMaybe<Order_By>;
  webpush_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Mutation_Response = {
  __typename?: 'webpushes_triggers_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webpushes_Triggers_Groups>;
};

/** input type for inserting object relation for remote table "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Obj_Rel_Insert_Input = {
  data: Webpushes_Triggers_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Webpushes_Triggers_Groups_On_Conflict>;
};

/** on_conflict condition type for table "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_On_Conflict = {
  constraint: Webpushes_Triggers_Groups_Constraint;
  update_columns?: Array<Webpushes_Triggers_Groups_Update_Column>;
  where?: InputMaybe<Webpushes_Triggers_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "webpushes_triggers_groups". */
export type Webpushes_Triggers_Groups_Order_By = {
  group_and_clause?: InputMaybe<Order_By>;
  triggers_group_id?: InputMaybe<Order_By>;
  webpush_id?: InputMaybe<Order_By>;
  webpushes_autosending_type?: InputMaybe<Webpushes_Autosending_Types_Order_By>;
  webpushes_triggers_aggregate?: InputMaybe<Webpushes_Triggers_Aggregate_Order_By>;
};

/** primary key columns input for table: webpushes_triggers_groups */
export type Webpushes_Triggers_Groups_Pk_Columns_Input = {
  triggers_group_id: Scalars['uuid'];
  webpush_id: Scalars['uuid'];
};

/** select columns of table "webpushes_triggers_groups" */
export enum Webpushes_Triggers_Groups_Select_Column {
  /** column name */
  GroupAndClause = 'group_and_clause',
  /** column name */
  TriggersGroupId = 'triggers_group_id',
  /** column name */
  WebpushId = 'webpush_id'
}

/** select "webpushes_triggers_groups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "webpushes_triggers_groups" */
export enum Webpushes_Triggers_Groups_Select_Column_Webpushes_Triggers_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  GroupAndClause = 'group_and_clause'
}

/** select "webpushes_triggers_groups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "webpushes_triggers_groups" */
export enum Webpushes_Triggers_Groups_Select_Column_Webpushes_Triggers_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  GroupAndClause = 'group_and_clause'
}

/** input type for updating data in table "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Set_Input = {
  group_and_clause?: InputMaybe<Scalars['Boolean']>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "webpushes_triggers_groups" */
export type Webpushes_Triggers_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Webpushes_Triggers_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Webpushes_Triggers_Groups_Stream_Cursor_Value_Input = {
  group_and_clause?: InputMaybe<Scalars['Boolean']>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
  webpush_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "webpushes_triggers_groups" */
export enum Webpushes_Triggers_Groups_Update_Column {
  /** column name */
  GroupAndClause = 'group_and_clause',
  /** column name */
  TriggersGroupId = 'triggers_group_id',
  /** column name */
  WebpushId = 'webpush_id'
}

export type Webpushes_Triggers_Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Webpushes_Triggers_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Webpushes_Triggers_Groups_Bool_Exp;
};

/** input type for inserting data into table "webpushes_triggers" */
export type Webpushes_Triggers_Insert_Input = {
  operator?: InputMaybe<Scalars['String']>;
  trigger_name?: InputMaybe<Scalars['String']>;
  trigger_param?: InputMaybe<Scalars['String']>;
  trigger_type?: InputMaybe<Scalars['String']>;
  trigger_value?: InputMaybe<Scalars['String']>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
  webpush_group?: InputMaybe<Webpushes_Triggers_Groups_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Webpushes_Triggers_Max_Fields = {
  __typename?: 'webpushes_triggers_max_fields';
  operator?: Maybe<Scalars['String']>;
  trigger_name?: Maybe<Scalars['String']>;
  trigger_param?: Maybe<Scalars['String']>;
  trigger_type?: Maybe<Scalars['String']>;
  trigger_value?: Maybe<Scalars['String']>;
  triggers_group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "webpushes_triggers" */
export type Webpushes_Triggers_Max_Order_By = {
  operator?: InputMaybe<Order_By>;
  trigger_name?: InputMaybe<Order_By>;
  trigger_param?: InputMaybe<Order_By>;
  trigger_type?: InputMaybe<Order_By>;
  trigger_value?: InputMaybe<Order_By>;
  triggers_group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Webpushes_Triggers_Min_Fields = {
  __typename?: 'webpushes_triggers_min_fields';
  operator?: Maybe<Scalars['String']>;
  trigger_name?: Maybe<Scalars['String']>;
  trigger_param?: Maybe<Scalars['String']>;
  trigger_type?: Maybe<Scalars['String']>;
  trigger_value?: Maybe<Scalars['String']>;
  triggers_group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "webpushes_triggers" */
export type Webpushes_Triggers_Min_Order_By = {
  operator?: InputMaybe<Order_By>;
  trigger_name?: InputMaybe<Order_By>;
  trigger_param?: InputMaybe<Order_By>;
  trigger_type?: InputMaybe<Order_By>;
  trigger_value?: InputMaybe<Order_By>;
  triggers_group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "webpushes_triggers" */
export type Webpushes_Triggers_Mutation_Response = {
  __typename?: 'webpushes_triggers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webpushes_Triggers>;
};

/** on_conflict condition type for table "webpushes_triggers" */
export type Webpushes_Triggers_On_Conflict = {
  constraint: Webpushes_Triggers_Constraint;
  update_columns?: Array<Webpushes_Triggers_Update_Column>;
  where?: InputMaybe<Webpushes_Triggers_Bool_Exp>;
};

/** Ordering options when selecting data from "webpushes_triggers". */
export type Webpushes_Triggers_Order_By = {
  operator?: InputMaybe<Order_By>;
  trigger_name?: InputMaybe<Order_By>;
  trigger_param?: InputMaybe<Order_By>;
  trigger_type?: InputMaybe<Order_By>;
  trigger_value?: InputMaybe<Order_By>;
  triggers_group_id?: InputMaybe<Order_By>;
  webpush_group?: InputMaybe<Webpushes_Triggers_Groups_Order_By>;
};

/** primary key columns input for table: webpushes_triggers */
export type Webpushes_Triggers_Pk_Columns_Input = {
  trigger_name: Scalars['String'];
  triggers_group_id: Scalars['uuid'];
};

/** select columns of table "webpushes_triggers" */
export enum Webpushes_Triggers_Select_Column {
  /** column name */
  Operator = 'operator',
  /** column name */
  TriggerName = 'trigger_name',
  /** column name */
  TriggerParam = 'trigger_param',
  /** column name */
  TriggerType = 'trigger_type',
  /** column name */
  TriggerValue = 'trigger_value',
  /** column name */
  TriggersGroupId = 'triggers_group_id'
}

/** input type for updating data in table "webpushes_triggers" */
export type Webpushes_Triggers_Set_Input = {
  operator?: InputMaybe<Scalars['String']>;
  trigger_name?: InputMaybe<Scalars['String']>;
  trigger_param?: InputMaybe<Scalars['String']>;
  trigger_type?: InputMaybe<Scalars['String']>;
  trigger_value?: InputMaybe<Scalars['String']>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "webpushes_triggers" */
export type Webpushes_Triggers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Webpushes_Triggers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Webpushes_Triggers_Stream_Cursor_Value_Input = {
  operator?: InputMaybe<Scalars['String']>;
  trigger_name?: InputMaybe<Scalars['String']>;
  trigger_param?: InputMaybe<Scalars['String']>;
  trigger_type?: InputMaybe<Scalars['String']>;
  trigger_value?: InputMaybe<Scalars['String']>;
  triggers_group_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "webpushes_triggers" */
export enum Webpushes_Triggers_Update_Column {
  /** column name */
  Operator = 'operator',
  /** column name */
  TriggerName = 'trigger_name',
  /** column name */
  TriggerParam = 'trigger_param',
  /** column name */
  TriggerType = 'trigger_type',
  /** column name */
  TriggerValue = 'trigger_value',
  /** column name */
  TriggersGroupId = 'triggers_group_id'
}

export type Webpushes_Triggers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Webpushes_Triggers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Webpushes_Triggers_Bool_Exp;
};

export type GetUserProfileByIdQueryVariables = Exact<{
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}>;


export type GetUserProfileByIdQuery = { __typename?: 'query_root', user_profile: Array<{ __typename?: 'user_profile', avatar?: string | null, avatar_medium?: string | null, avatar_full?: string | null, persona_name: string, profile_url?: string | null, user_id: string }>, user_got_bonus: Array<{ __typename?: 'user_got_bonus', bonus_id: string }> };

export type RefreshTokenMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutationMutation = { __typename?: 'mutation_root', refreshToken?: { __typename?: 'refreshTokenOutput', access_token: string } | null };

export type SellItemMutationVariables = Exact<{
  user_item_ids?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  sell_all?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
}>;


export type SellItemMutation = { __typename?: 'mutation_root', SellItem?: { __typename?: 'SellOutput', error?: string | null, success: boolean, count: number, full_price: number } | null };

export type SendItemToUserMutationVariables = Exact<{
  alternative_item?: InputMaybe<AlternativeItemInput>;
  user_item_id?: InputMaybe<Scalars['uuid']>;
}>;


export type SendItemToUserMutation = { __typename?: 'mutation_root', SendItemToUser?: { __typename?: 'BuyOutput', cust_id?: string | null, error?: string | null, id?: any | null, order_id?: string | null, output_id?: string | null, price?: number | null, success: boolean, base_item?: { __typename?: 'AlternativeBaseItem', item_hash_name: string, user_item_id: string, item_image_link: string, item_quality: string, item_rarity: string, item_start_price: number, localized_name?: string | null, output_id?: string | null } | null, alternative_items: Array<{ __typename?: 'AlternativeItem', item_hash_name: string, item_id: string, item_image_link: string, item_quality: string, item_rarity: string, item_start_price: number, localized_name?: string | null, output_id?: string | null }> } | null };

export type GetFullUserProfileQueryVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type GetFullUserProfileQuery = { __typename?: 'query_root', user_profile_by_pk?: { __typename?: 'user_profile', user_id: string, avatar?: string | null, avatar_medium?: string | null, avatar_full?: string | null, persona_name: string, profile_url?: string | null } | null, user_account_by_pk?: { __typename?: 'user_account', user_id: string, user_inventory_id: string } | null, user_statistic_by_pk?: { __typename?: 'user_statistic', user_best_item?: any | null, user_favorite_lootbox?: any | null, best_item_localized_name?: Array<{ __typename?: 'profile_items_names', name: string, ru_name: string }> | null } | null };

export type UserBalanceQueryVariables = Exact<{
  user_id?: InputMaybe<Scalars['uuid']>;
}>;


export type UserBalanceQuery = { __typename?: 'query_root', user_account_by_pk?: { __typename?: 'user_account', user_id: string, is_output_blocked: boolean, user_wallet?: { __typename?: 'user_wallet', balance: number } | null, user_bonus_wallet?: { __typename?: 'user_bonus_wallet', balance: number } | null } | null };

export type InventoryFieldsFragment = { __typename?: 'user_items_in_inventory', item_id: string, user_item_id: string, item_image_link: string, item_hash_name: string, item_frozen: boolean, item_image_link_formated?: string | null, item_lootbox_id?: string | null, item_quality: string, item_rariry: string, item_start_price: number, item_status: number, receiving_datetime?: string | null, localized_name?: { __typename?: 'account_items_names', ru_name: string } | null };

export type UserInventoryQueryVariables = Exact<{
  user_inventory_id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  for_sale: Scalars['Boolean'];
}>;


export type UserInventoryQuery = { __typename?: 'query_root', main?: { __typename?: 'user_inventory', user_inventory_id: string, pages_count?: number | null, user_items_in_inventories: Array<{ __typename?: 'user_items_in_inventory', item_id: string, user_item_id: string, item_image_link: string, item_hash_name: string, item_frozen: boolean, item_image_link_formated?: string | null, item_lootbox_id?: string | null, item_quality: string, item_rariry: string, item_start_price: number, item_status: number, receiving_datetime?: string | null, localized_name?: { __typename?: 'account_items_names', ru_name: string } | null }>, count_all: { __typename?: 'user_items_in_inventory_aggregate', aggregate?: { __typename?: 'user_items_in_inventory_aggregate_fields', count: number } | null } } | null, for_sale?: { __typename?: 'user_inventory', user_inventory_id: string, pages_count?: number | null, user_items_in_inventories: Array<{ __typename?: 'user_items_in_inventory', item_id: string, user_item_id: string, item_image_link: string, item_hash_name: string, item_frozen: boolean, item_image_link_formated?: string | null, item_lootbox_id?: string | null, item_quality: string, item_rariry: string, item_start_price: number, item_status: number, receiving_datetime?: string | null, localized_name?: { __typename?: 'account_items_names', ru_name: string } | null }>, count_all: { __typename?: 'user_items_in_inventory_aggregate', aggregate?: { __typename?: 'user_items_in_inventory_aggregate_fields', count: number } | null } } | null };

export type GetInventoryPagesAndCountQueryVariables = Exact<{
  user_inventory_id: Scalars['uuid'];
  limit: Scalars['Int'];
  for_sale: Scalars['Boolean'];
}>;


export type GetInventoryPagesAndCountQuery = { __typename?: 'query_root', main?: { __typename?: 'user_inventory', user_inventory_id: string, pages_count?: number | null, count_all: { __typename?: 'user_items_in_inventory_aggregate', aggregate?: { __typename?: 'user_items_in_inventory_aggregate_fields', count: number } | null } } | null, for_sale?: { __typename?: 'user_inventory', user_inventory_id: string, pages_count?: number | null, count_all: { __typename?: 'user_items_in_inventory_aggregate', aggregate?: { __typename?: 'user_items_in_inventory_aggregate_fields', count: number } | null } } | null };

export type InventoryEventsSubscriptionVariables = Exact<{
  datetime_start: Scalars['timestamp'];
  user_id: Scalars['uuid'];
  event_types: Array<Scalars['String']> | Scalars['String'];
  event_sub_types: Array<Scalars['String']> | Scalars['String'];
}>;


export type InventoryEventsSubscription = { __typename?: 'subscription_root', user_events: Array<{ __typename?: 'user_events', event_id: string, user_id: string, event_type: string, event_sub_type: string, event_data?: any | null, created_at: string }> };

export type UpdateUserTradeUrlMutationVariables = Exact<{
  trade_url: Scalars['String'];
  user_trade_partner_id: Scalars['String'];
  user_trade_str_part: Scalars['String'];
}>;


export type UpdateUserTradeUrlMutation = { __typename?: 'mutation_root', update_user_profile?: { __typename?: 'user_profile_mutation_response', returning: Array<{ __typename?: 'user_profile', user_id: string, trade_url?: string | null }> } | null, update_user_account?: { __typename?: 'user_account_mutation_response', returning: Array<{ __typename?: 'user_account', user_trade_partner_id?: string | null, user_trade_str_part?: string | null, user_id: string }> } | null };

export type GetTradeUrlQueryVariables = Exact<{
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
}>;


export type GetTradeUrlQuery = { __typename?: 'query_root', user_profile: Array<{ __typename?: 'user_profile', trade_url?: string | null }> };

export type GetActiveBonusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveBonusesQuery = { __typename?: 'query_root', bonuses: Array<{ __typename?: 'bonuses', active: boolean, header: string, description?: string | null, button_active: boolean, button_text?: string | null, id: string }> };

export type TryAgainMetricMutationVariables = Exact<{
  user_id?: InputMaybe<Scalars['uuid']>;
  lootbox_name?: InputMaybe<Scalars['String']>;
  lootbox_id?: InputMaybe<Scalars['uuid']>;
}>;


export type TryAgainMetricMutation = { __typename?: 'mutation_root', insert_statistic_button_again_press?: { __typename?: 'statistic_button_again_press_mutation_response', affected_rows: number } | null };

export type CheckUserPromocodeQueryVariables = Exact<{
  promocode: Scalars['String'];
}>;


export type CheckUserPromocodeQuery = { __typename?: 'query_root', CheckUserPromocode?: { __typename?: 'CheckPromocodeOutput', error?: string | null, max_amount?: number | null, min_deposit?: number | null, success: boolean, type?: number | null, value?: number | null, reward_type?: string | null } | null };

export type GetPageMetasQueryVariables = Exact<{
  metas_input?: InputMaybe<MetasInput>;
}>;


export type GetPageMetasQuery = { __typename?: 'query_root', GetPageMetas?: { __typename?: 'MetasOutput', metas?: any | null } | null };

export type GetUserUpgradeInitialInventoryQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  inventory_limit: Scalars['Int'];
  booster_limit: Scalars['Int'];
}>;


export type GetUserUpgradeInitialInventoryQuery = { __typename?: 'query_root', user_account_by_pk?: { __typename?: 'user_account', user_id: string, user_inventory_id: string, user_inventory?: { __typename?: 'user_inventory', pages_for_upgrade_count?: number | null, pages_for_boosters?: number | null, user_inventory_id: string, items: Array<{ __typename?: 'user_items_in_inventory', item_id: string, user_item_id: string, item_image_link: string, item_hash_name: string, item_frozen: boolean, item_image_link_formated?: string | null, item_quality: string, item_rariry: string, item_start_price: number, item_status: number, localized_name?: { __typename?: 'account_items_names', ru_name: string } | null }>, boosters: Array<{ __typename?: 'user_boosters_in_inventory', booster_id: string, user_booster_id: string, booster_image_link: string, booster_value: number, user_inventory_id: string }> } | null } | null };

export type GetUpgradeInventoryPaginationQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetUpgradeInventoryPaginationQuery = { __typename?: 'query_root', user_account_by_pk?: { __typename?: 'user_account', user_id: string, user_inventory?: { __typename?: 'user_inventory', pages_for_upgrade_count?: number | null, user_inventory_id: string, items: Array<{ __typename?: 'user_items_in_inventory', item_id: string, user_item_id: string, item_image_link: string, item_hash_name: string, item_frozen: boolean, item_image_link_formated?: string | null, item_quality: string, item_rariry: string, item_start_price: number, item_status: number, localized_name?: { __typename?: 'account_items_names', ru_name: string } | null }> } | null } | null };

export type GetBoostersPaginationQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetBoostersPaginationQuery = { __typename?: 'query_root', user_account_by_pk?: { __typename?: 'user_account', user_id: string, user_inventory?: { __typename?: 'user_inventory', pages_for_boosters?: number | null, user_inventory_id: string, boosters: Array<{ __typename?: 'user_boosters_in_inventory', booster_id: string, user_booster_id: string, booster_image_link: string, booster_value: number, user_inventory_id: string, available: boolean, receiving_datetime: string }> } | null } | null };

export type GetPossibleItemsForUpgradeQueryVariables = Exact<{
  filters?: InputMaybe<ItemsForUpgradeFiltersDto>;
}>;


export type GetPossibleItemsForUpgradeQuery = { __typename?: 'query_root', GetPossibleItemsForUpgrade: { __typename?: 'GetItemsForUpgradeAnswer', current_page: number, pages_count: number, per_page: number, items: Array<{ __typename?: 'ItemForUpgrade', item_id: string, item_hash_name: string, item_image_link: string, item_image_link_formated: string, item_price: number, item_quality: string, item_rarity: string, ru_name: string }> } };

export type GetUpgradeInfoQueryVariables = Exact<{
  boosters?: InputMaybe<Array<UpgradeBoosters> | UpgradeBoosters>;
  input_item_price: Scalars['Int'];
  target_item_price: Scalars['Int'];
}>;


export type GetUpgradeInfoQuery = { __typename?: 'query_root', CalcUpgradeParams: { __typename?: 'UserUpgradeCalcAnswer', chance: number, koef: number } };

export type UpgradeItemMutationVariables = Exact<{
  item_id: Scalars['uuid'];
  user_item_id: Scalars['uuid'];
  boosters?: InputMaybe<Array<UserUpgradeInputBooster> | UserUpgradeInputBooster>;
}>;


export type UpgradeItemMutation = { __typename?: 'mutation_root', UpgradeUserItem?: { __typename?: 'UserUpgradeAnswer', error?: string | null, result: boolean, success: boolean } | null };

export type InformationNoticeQueryVariables = Exact<{ [key: string]: never; }>;


export type InformationNoticeQuery = { __typename?: 'query_root', survey_data_links: Array<{ __typename?: 'survey_data_links', id: string, link: string }>, maintenance_mode: Array<{ __typename?: 'maintenance_mode', background_color?: string | null, main_text_desktop: string, main_text_mobile: string, mode_type: string, show_type: number }> };

export type GetMainpageFilterParamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMainpageFilterParamsQuery = { __typename?: 'query_root', GetMainpageLootboxFilterParams: { __typename?: 'MpLootboxesFilterModel', filter_skin_name: string, max_price: number, min_price: number, filter_weapon_types: Array<{ __typename?: 'MpLootboxesFilterWeaponTypeModel', name: string, param: string }> } };

export type GetGiveawayFeedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGiveawayFeedQuery = { __typename?: 'query_root', GetGiveawaysRibbon?: Array<{ __typename?: 'GivewayRibbon', item_rarity: string, item_quality: string, item_price: number, item_name: string, item_image_link: string, giveaway_id: string, total_seconds: number, start_date?: string | null, end_date?: string | null, item_claimed?: boolean | null, current_deposit?: number | null, minimal_deposit?: number | null, user_participates?: boolean | null, winner_name?: string | null, winner_id?: string | null, winner_avatar?: string | null, user_count?: number | null } | null> | null };

export type GetGiveawayQueryVariables = Exact<{
  giveaway_id?: InputMaybe<Scalars['String']>;
}>;


export type GetGiveawayQuery = { __typename?: 'query_root', GetGiveaway?: { __typename?: 'GivewaysInfo', current_deposit?: number | null, giveaway_id?: string | null, total_seconds?: number | null, item_id?: string | null, item_image_link?: string | null, item_name?: string | null, item_price?: number | null, item_claimed?: boolean | null, current_users?: number | null, limit_users?: number | null, item_rarity?: string | null, item_quality?: string | null, minimal_deposit?: number | null, winner_avatar?: string | null, winner_id?: string | null, winner_name?: string | null, start_date?: string | null, success: boolean, minimal_users?: number | null, end_date?: string | null, status?: string | null, user_participates?: boolean | null } | null };

export type GetGiveawayWinnerQueryVariables = Exact<{
  giveaway_id?: InputMaybe<Scalars['String']>;
}>;


export type GetGiveawayWinnerQuery = { __typename?: 'query_root', GetGiveaway?: { __typename?: 'GivewaysInfo', winner_avatar?: string | null, winner_id?: string | null, winner_name?: string | null } | null };

export type GetGiveawayMembersQueryVariables = Exact<{
  giveaway_id?: InputMaybe<Scalars['String']>;
}>;


export type GetGiveawayMembersQuery = { __typename?: 'query_root', GetGiveawayMembers?: Array<{ __typename?: 'GivewayMembers', user_id: string, avatar?: string | null, persona_name: string } | null> | null };

export type GetGiveawayItemQueryVariables = Exact<{
  giveaway_id?: InputMaybe<Scalars['String']>;
}>;


export type GetGiveawayItemQuery = { __typename?: 'query_root', GiveawayGetItem?: { __typename?: 'GiveawayGetItem', success: boolean, error?: string | null } | null };

export type GetUserGiveawayHistoryQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetUserGiveawayHistoryQuery = { __typename?: 'query_root', GetHistoryUserGiveaway?: { __typename?: 'UserGiveawayHistoryResponse', history: Array<{ __typename?: 'GiveawayItem', active: boolean, end_date: string, fixed_price?: number | null, item_claimed?: boolean | null, item_hash_name?: string | null, item_id: string, item_image_link?: string | null, item_quality?: string | null, item_rarity?: string | null, start_date: string, status: GiveawayStatus, type?: string | null, winner_id?: string | null, giveaway_id: string }>, pagination: { __typename?: 'PaginationInfo', current_page: number, page_size: number, total_items: number, total_pages: number } } | null };

export type GiveawayMembersRatingQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  wonCount: Scalars['Boolean'];
}>;


export type GiveawayMembersRatingQuery = { __typename?: 'query_root', UsersGiveawaysRating?: { __typename?: 'UsersGiveawaysRatingResponse', pagination: { __typename?: 'PaginationInfo', current_page: number, total_pages: number, total_items: number, page_size: number }, rating: Array<{ __typename?: 'UsersGiveawaysRating', avatar?: string | null, persona_name: string, user_id: string, won_count: number, participation_count: number, last_prize?: Array<{ __typename?: 'GiveawayPrize', item_id: string, item_image_link?: string | null, item_name?: string | null, item_price?: number | null, item_quality?: string | null, item_rarity?: string | null } | null> | null }> } | null };

export type ClosestGiveawayQueryVariables = Exact<{ [key: string]: never; }>;


export type ClosestGiveawayQuery = { __typename?: 'query_root', ClosestGiveaway?: { __typename?: 'ClosestGiveawayResponse', giveaway?: { __typename?: 'ClosestGiveaway', giveaway_id: string, start_date?: string | null, end_date?: string | null, total_seconds?: number | null, item?: { __typename?: 'Item', item_id?: string | null, item_image_link?: string | null, item_name?: string | null, item_price?: number | null, item_quality?: string | null, item_rarity?: string | null } | null } | null } | null };

export type MonthlyWinnersQueryVariables = Exact<{ [key: string]: never; }>;


export type MonthlyWinnersQuery = { __typename?: 'query_root', MonthlyWinners?: { __typename?: 'MonthlyWinnersResponse', winners?: Array<{ __typename?: 'MonthlyWinners', user_id?: string | null, persona_name?: string | null, avatar?: string | null, giveaway_id?: string | null, start_date?: string | null, end_date?: string | null, participation_count?: number | null, won_count?: number | null, item?: { __typename?: 'Item', item_id?: string | null, item_name?: string | null, item_image_link?: string | null, item_price?: number | null, item_quality?: string | null, item_rarity?: string | null } | null } | null> | null } | null };

export type AcceptTermsMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptTermsMutation = { __typename?: 'mutation_root', AcceptTerms: string };

export const InventoryFieldsFragmentDoc = gql`
    fragment inventoryFields on user_items_in_inventory {
  item_id
  user_item_id
  item_image_link
  item_hash_name
  item_frozen
  item_image_link_formated
  item_lootbox_id
  item_quality
  item_rariry
  item_start_price
  item_status
  receiving_datetime
  localized_name {
    ru_name
  }
}
    `;
export const GetUserProfileByIdDocument = gql`
    query GetUserProfileById($user_id: uuid_comparison_exp = {_eq: ""}) {
  user_profile(where: {user_id: $user_id}) {
    avatar
    avatar_medium
    avatar_full
    persona_name
    profile_url
    user_id
  }
  user_got_bonus(
    where: {bonus_id: {_eq: "55533435-6d95-4395-adb9-329b069bca3e"}, user_id: $user_id}
  ) {
    bonus_id
  }
}
    `;

/**
 * __useGetUserProfileByIdQuery__
 *
 * To run a query within a React component, call `useGetUserProfileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileByIdQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserProfileByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileByIdQuery, GetUserProfileByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileByIdQuery, GetUserProfileByIdQueryVariables>(GetUserProfileByIdDocument, options);
      }
export function useGetUserProfileByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileByIdQuery, GetUserProfileByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileByIdQuery, GetUserProfileByIdQueryVariables>(GetUserProfileByIdDocument, options);
        }
export type GetUserProfileByIdQueryHookResult = ReturnType<typeof useGetUserProfileByIdQuery>;
export type GetUserProfileByIdLazyQueryHookResult = ReturnType<typeof useGetUserProfileByIdLazyQuery>;
export type GetUserProfileByIdQueryResult = Apollo.QueryResult<GetUserProfileByIdQuery, GetUserProfileByIdQueryVariables>;
export const RefreshTokenMutationDocument = gql`
    mutation RefreshTokenMutation {
  refreshToken {
    access_token
  }
}
    `;
export type RefreshTokenMutationMutationFn = Apollo.MutationFunction<RefreshTokenMutationMutation, RefreshTokenMutationMutationVariables>;

/**
 * __useRefreshTokenMutationMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutationMutation, { data, loading, error }] = useRefreshTokenMutationMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutationMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutationMutation, RefreshTokenMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutationMutation, RefreshTokenMutationMutationVariables>(RefreshTokenMutationDocument, options);
      }
export type RefreshTokenMutationMutationHookResult = ReturnType<typeof useRefreshTokenMutationMutation>;
export type RefreshTokenMutationMutationResult = Apollo.MutationResult<RefreshTokenMutationMutation>;
export type RefreshTokenMutationMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutationMutation, RefreshTokenMutationMutationVariables>;
export const SellItemDocument = gql`
    mutation SellItem($user_item_ids: [uuid!], $sell_all: Boolean = false, $type: String) {
  SellItem(
    item_data: {user_item_ids: $user_item_ids, sell_all: $sell_all, type: $type}
  ) {
    error
    success
    count
    full_price
  }
}
    `;
export type SellItemMutationFn = Apollo.MutationFunction<SellItemMutation, SellItemMutationVariables>;

/**
 * __useSellItemMutation__
 *
 * To run a mutation, you first call `useSellItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSellItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sellItemMutation, { data, loading, error }] = useSellItemMutation({
 *   variables: {
 *      user_item_ids: // value for 'user_item_ids'
 *      sell_all: // value for 'sell_all'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSellItemMutation(baseOptions?: Apollo.MutationHookOptions<SellItemMutation, SellItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SellItemMutation, SellItemMutationVariables>(SellItemDocument, options);
      }
export type SellItemMutationHookResult = ReturnType<typeof useSellItemMutation>;
export type SellItemMutationResult = Apollo.MutationResult<SellItemMutation>;
export type SellItemMutationOptions = Apollo.BaseMutationOptions<SellItemMutation, SellItemMutationVariables>;
export const SendItemToUserDocument = gql`
    mutation SendItemToUser($alternative_item: AlternativeItemInput, $user_item_id: uuid = "") {
  SendItemToUser(
    item_data: {user_item_id: $user_item_id, alternative_item: $alternative_item}
  ) {
    cust_id
    error
    id
    order_id
    output_id
    price
    success
    base_item {
      item_hash_name
      user_item_id
      item_image_link
      item_quality
      item_rarity
      item_start_price
      localized_name
      output_id
    }
    alternative_items {
      item_hash_name
      item_id
      item_image_link
      item_quality
      item_rarity
      item_start_price
      localized_name
      output_id
    }
  }
}
    `;
export type SendItemToUserMutationFn = Apollo.MutationFunction<SendItemToUserMutation, SendItemToUserMutationVariables>;

/**
 * __useSendItemToUserMutation__
 *
 * To run a mutation, you first call `useSendItemToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendItemToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendItemToUserMutation, { data, loading, error }] = useSendItemToUserMutation({
 *   variables: {
 *      alternative_item: // value for 'alternative_item'
 *      user_item_id: // value for 'user_item_id'
 *   },
 * });
 */
export function useSendItemToUserMutation(baseOptions?: Apollo.MutationHookOptions<SendItemToUserMutation, SendItemToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendItemToUserMutation, SendItemToUserMutationVariables>(SendItemToUserDocument, options);
      }
export type SendItemToUserMutationHookResult = ReturnType<typeof useSendItemToUserMutation>;
export type SendItemToUserMutationResult = Apollo.MutationResult<SendItemToUserMutation>;
export type SendItemToUserMutationOptions = Apollo.BaseMutationOptions<SendItemToUserMutation, SendItemToUserMutationVariables>;
export const GetFullUserProfileDocument = gql`
    query GetFullUserProfile($user_id: uuid!) {
  user_profile_by_pk(user_id: $user_id) {
    user_id
    avatar
    avatar_medium
    avatar_full
    persona_name
    profile_url
    user_id
  }
  user_account_by_pk(user_id: $user_id) {
    user_id
    user_inventory_id
  }
  user_statistic_by_pk(user_id: $user_id) {
    user_best_item
    user_favorite_lootbox
    best_item_localized_name {
      name
      ru_name
    }
  }
}
    `;

/**
 * __useGetFullUserProfileQuery__
 *
 * To run a query within a React component, call `useGetFullUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullUserProfileQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetFullUserProfileQuery(baseOptions: Apollo.QueryHookOptions<GetFullUserProfileQuery, GetFullUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFullUserProfileQuery, GetFullUserProfileQueryVariables>(GetFullUserProfileDocument, options);
      }
export function useGetFullUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFullUserProfileQuery, GetFullUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFullUserProfileQuery, GetFullUserProfileQueryVariables>(GetFullUserProfileDocument, options);
        }
export type GetFullUserProfileQueryHookResult = ReturnType<typeof useGetFullUserProfileQuery>;
export type GetFullUserProfileLazyQueryHookResult = ReturnType<typeof useGetFullUserProfileLazyQuery>;
export type GetFullUserProfileQueryResult = Apollo.QueryResult<GetFullUserProfileQuery, GetFullUserProfileQueryVariables>;
export const UserBalanceDocument = gql`
    query UserBalance($user_id: uuid = "") {
  user_account_by_pk(user_id: $user_id) {
    user_id
    is_output_blocked
    user_wallet {
      balance
    }
    user_bonus_wallet {
      balance
    }
  }
}
    `;

/**
 * __useUserBalanceQuery__
 *
 * To run a query within a React component, call `useUserBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBalanceQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUserBalanceQuery(baseOptions?: Apollo.QueryHookOptions<UserBalanceQuery, UserBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserBalanceQuery, UserBalanceQueryVariables>(UserBalanceDocument, options);
      }
export function useUserBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserBalanceQuery, UserBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserBalanceQuery, UserBalanceQueryVariables>(UserBalanceDocument, options);
        }
export type UserBalanceQueryHookResult = ReturnType<typeof useUserBalanceQuery>;
export type UserBalanceLazyQueryHookResult = ReturnType<typeof useUserBalanceLazyQuery>;
export type UserBalanceQueryResult = Apollo.QueryResult<UserBalanceQuery, UserBalanceQueryVariables>;
export const UserInventoryDocument = gql`
    query UserInventory($user_inventory_id: uuid!, $limit: Int!, $offset: Int!, $for_sale: Boolean!) {
  main: user_inventory_by_pk(user_inventory_id: $user_inventory_id) @skip(if: $for_sale) {
    user_inventory_id
    user_items_in_inventories(
      where: {item_status: {_in: [1, 2, 3]}}
      limit: $limit
      offset: $offset
      order_by: [{receiving_datetime: desc}, {user_item_id: desc}]
    ) {
      ...inventoryFields
    }
    count_all: user_items_in_inventories_aggregate {
      aggregate {
        count
      }
    }
    pages_count: pages_count(args: {per_page: $limit})
  }
  for_sale: user_inventory_by_pk(user_inventory_id: $user_inventory_id) @include(if: $for_sale) {
    user_inventory_id
    user_items_in_inventories(
      where: {item_status: {_eq: 1}, item_frozen: {_eq: false}}
      limit: $limit
      offset: $offset
      order_by: [{receiving_datetime: desc}, {user_item_id: desc}]
    ) {
      ...inventoryFields
    }
    count_all: user_items_in_inventories_aggregate(
      where: {item_status: {_eq: 1}, item_frozen: {_eq: false}}
    ) {
      aggregate {
        count
      }
    }
    pages_count: pages_for_sale_count(args: {per_page: $limit})
  }
}
    ${InventoryFieldsFragmentDoc}`;

/**
 * __useUserInventoryQuery__
 *
 * To run a query within a React component, call `useUserInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInventoryQuery({
 *   variables: {
 *      user_inventory_id: // value for 'user_inventory_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      for_sale: // value for 'for_sale'
 *   },
 * });
 */
export function useUserInventoryQuery(baseOptions: Apollo.QueryHookOptions<UserInventoryQuery, UserInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInventoryQuery, UserInventoryQueryVariables>(UserInventoryDocument, options);
      }
export function useUserInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInventoryQuery, UserInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInventoryQuery, UserInventoryQueryVariables>(UserInventoryDocument, options);
        }
export type UserInventoryQueryHookResult = ReturnType<typeof useUserInventoryQuery>;
export type UserInventoryLazyQueryHookResult = ReturnType<typeof useUserInventoryLazyQuery>;
export type UserInventoryQueryResult = Apollo.QueryResult<UserInventoryQuery, UserInventoryQueryVariables>;
export const GetInventoryPagesAndCountDocument = gql`
    query GetInventoryPagesAndCount($user_inventory_id: uuid!, $limit: Int!, $for_sale: Boolean!) {
  main: user_inventory_by_pk(user_inventory_id: $user_inventory_id) @skip(if: $for_sale) {
    user_inventory_id
    count_all: user_items_in_inventories_aggregate {
      aggregate {
        count
      }
    }
    pages_count: pages_count(args: {per_page: $limit})
  }
  for_sale: user_inventory_by_pk(user_inventory_id: $user_inventory_id) @include(if: $for_sale) {
    user_inventory_id
    count_all: user_items_in_inventories_aggregate(
      where: {item_status: {_eq: 1}, item_frozen: {_eq: false}}
    ) {
      aggregate {
        count
      }
    }
    pages_count: pages_for_sale_count(args: {per_page: $limit})
  }
}
    `;

/**
 * __useGetInventoryPagesAndCountQuery__
 *
 * To run a query within a React component, call `useGetInventoryPagesAndCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryPagesAndCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryPagesAndCountQuery({
 *   variables: {
 *      user_inventory_id: // value for 'user_inventory_id'
 *      limit: // value for 'limit'
 *      for_sale: // value for 'for_sale'
 *   },
 * });
 */
export function useGetInventoryPagesAndCountQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryPagesAndCountQuery, GetInventoryPagesAndCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryPagesAndCountQuery, GetInventoryPagesAndCountQueryVariables>(GetInventoryPagesAndCountDocument, options);
      }
export function useGetInventoryPagesAndCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryPagesAndCountQuery, GetInventoryPagesAndCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryPagesAndCountQuery, GetInventoryPagesAndCountQueryVariables>(GetInventoryPagesAndCountDocument, options);
        }
export type GetInventoryPagesAndCountQueryHookResult = ReturnType<typeof useGetInventoryPagesAndCountQuery>;
export type GetInventoryPagesAndCountLazyQueryHookResult = ReturnType<typeof useGetInventoryPagesAndCountLazyQuery>;
export type GetInventoryPagesAndCountQueryResult = Apollo.QueryResult<GetInventoryPagesAndCountQuery, GetInventoryPagesAndCountQueryVariables>;
export const InventoryEventsDocument = gql`
    subscription InventoryEvents($datetime_start: timestamp!, $user_id: uuid!, $event_types: [String!]!, $event_sub_types: [String!]!) {
  user_events(
    where: {user_id: {_eq: $user_id}, created_at: {_gt: $datetime_start}, event_type: {_in: $event_types}, event_sub_type: {_in: $event_sub_types}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    event_id
    user_id
    event_type
    event_sub_type
    event_data
    created_at
  }
}
    `;

/**
 * __useInventoryEventsSubscription__
 *
 * To run a query within a React component, call `useInventoryEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInventoryEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryEventsSubscription({
 *   variables: {
 *      datetime_start: // value for 'datetime_start'
 *      user_id: // value for 'user_id'
 *      event_types: // value for 'event_types'
 *      event_sub_types: // value for 'event_sub_types'
 *   },
 * });
 */
export function useInventoryEventsSubscription(baseOptions: Apollo.SubscriptionHookOptions<InventoryEventsSubscription, InventoryEventsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<InventoryEventsSubscription, InventoryEventsSubscriptionVariables>(InventoryEventsDocument, options);
      }
export type InventoryEventsSubscriptionHookResult = ReturnType<typeof useInventoryEventsSubscription>;
export type InventoryEventsSubscriptionResult = Apollo.SubscriptionResult<InventoryEventsSubscription>;
export const UpdateUserTradeUrlDocument = gql`
    mutation UpdateUserTradeUrl($trade_url: String!, $user_trade_partner_id: String!, $user_trade_str_part: String!) {
  update_user_profile(_set: {trade_url: $trade_url}, where: {}) {
    returning {
      user_id
      trade_url
    }
  }
  update_user_account(
    where: {}
    _set: {user_trade_partner_id: $user_trade_partner_id, user_trade_str_part: $user_trade_str_part}
  ) {
    returning {
      user_trade_partner_id
      user_trade_str_part
      user_id
    }
  }
}
    `;
export type UpdateUserTradeUrlMutationFn = Apollo.MutationFunction<UpdateUserTradeUrlMutation, UpdateUserTradeUrlMutationVariables>;

/**
 * __useUpdateUserTradeUrlMutation__
 *
 * To run a mutation, you first call `useUpdateUserTradeUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTradeUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTradeUrlMutation, { data, loading, error }] = useUpdateUserTradeUrlMutation({
 *   variables: {
 *      trade_url: // value for 'trade_url'
 *      user_trade_partner_id: // value for 'user_trade_partner_id'
 *      user_trade_str_part: // value for 'user_trade_str_part'
 *   },
 * });
 */
export function useUpdateUserTradeUrlMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserTradeUrlMutation, UpdateUserTradeUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserTradeUrlMutation, UpdateUserTradeUrlMutationVariables>(UpdateUserTradeUrlDocument, options);
      }
export type UpdateUserTradeUrlMutationHookResult = ReturnType<typeof useUpdateUserTradeUrlMutation>;
export type UpdateUserTradeUrlMutationResult = Apollo.MutationResult<UpdateUserTradeUrlMutation>;
export type UpdateUserTradeUrlMutationOptions = Apollo.BaseMutationOptions<UpdateUserTradeUrlMutation, UpdateUserTradeUrlMutationVariables>;
export const GetTradeUrlDocument = gql`
    query GetTradeUrl($user_id: uuid_comparison_exp = {_eq: ""}) {
  user_profile(where: {user_id: $user_id}) {
    trade_url
  }
}
    `;

/**
 * __useGetTradeUrlQuery__
 *
 * To run a query within a React component, call `useGetTradeUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTradeUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTradeUrlQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetTradeUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetTradeUrlQuery, GetTradeUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTradeUrlQuery, GetTradeUrlQueryVariables>(GetTradeUrlDocument, options);
      }
export function useGetTradeUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTradeUrlQuery, GetTradeUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTradeUrlQuery, GetTradeUrlQueryVariables>(GetTradeUrlDocument, options);
        }
export type GetTradeUrlQueryHookResult = ReturnType<typeof useGetTradeUrlQuery>;
export type GetTradeUrlLazyQueryHookResult = ReturnType<typeof useGetTradeUrlLazyQuery>;
export type GetTradeUrlQueryResult = Apollo.QueryResult<GetTradeUrlQuery, GetTradeUrlQueryVariables>;
export const GetActiveBonusesDocument = gql`
    query GetActiveBonuses {
  bonuses(where: {active: {_eq: true}}) {
    active
    header
    description
    button_active
    button_text
    id
  }
}
    `;

/**
 * __useGetActiveBonusesQuery__
 *
 * To run a query within a React component, call `useGetActiveBonusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveBonusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveBonusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveBonusesQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveBonusesQuery, GetActiveBonusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveBonusesQuery, GetActiveBonusesQueryVariables>(GetActiveBonusesDocument, options);
      }
export function useGetActiveBonusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveBonusesQuery, GetActiveBonusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveBonusesQuery, GetActiveBonusesQueryVariables>(GetActiveBonusesDocument, options);
        }
export type GetActiveBonusesQueryHookResult = ReturnType<typeof useGetActiveBonusesQuery>;
export type GetActiveBonusesLazyQueryHookResult = ReturnType<typeof useGetActiveBonusesLazyQuery>;
export type GetActiveBonusesQueryResult = Apollo.QueryResult<GetActiveBonusesQuery, GetActiveBonusesQueryVariables>;
export const TryAgainMetricDocument = gql`
    mutation TryAgainMetric($user_id: uuid = "", $lootbox_name: String = "", $lootbox_id: uuid = "") {
  insert_statistic_button_again_press(
    objects: {lootbox_id: $lootbox_id, lootbox_name: $lootbox_name, user_id: $user_id}
  ) {
    affected_rows
  }
}
    `;
export type TryAgainMetricMutationFn = Apollo.MutationFunction<TryAgainMetricMutation, TryAgainMetricMutationVariables>;

/**
 * __useTryAgainMetricMutation__
 *
 * To run a mutation, you first call `useTryAgainMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTryAgainMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tryAgainMetricMutation, { data, loading, error }] = useTryAgainMetricMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      lootbox_name: // value for 'lootbox_name'
 *      lootbox_id: // value for 'lootbox_id'
 *   },
 * });
 */
export function useTryAgainMetricMutation(baseOptions?: Apollo.MutationHookOptions<TryAgainMetricMutation, TryAgainMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TryAgainMetricMutation, TryAgainMetricMutationVariables>(TryAgainMetricDocument, options);
      }
export type TryAgainMetricMutationHookResult = ReturnType<typeof useTryAgainMetricMutation>;
export type TryAgainMetricMutationResult = Apollo.MutationResult<TryAgainMetricMutation>;
export type TryAgainMetricMutationOptions = Apollo.BaseMutationOptions<TryAgainMetricMutation, TryAgainMetricMutationVariables>;
export const CheckUserPromocodeDocument = gql`
    query CheckUserPromocode($promocode: String!) {
  CheckUserPromocode(promocode: $promocode) {
    error
    max_amount
    min_deposit
    success
    type
    value
    reward_type
  }
}
    `;

/**
 * __useCheckUserPromocodeQuery__
 *
 * To run a query within a React component, call `useCheckUserPromocodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserPromocodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserPromocodeQuery({
 *   variables: {
 *      promocode: // value for 'promocode'
 *   },
 * });
 */
export function useCheckUserPromocodeQuery(baseOptions: Apollo.QueryHookOptions<CheckUserPromocodeQuery, CheckUserPromocodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckUserPromocodeQuery, CheckUserPromocodeQueryVariables>(CheckUserPromocodeDocument, options);
      }
export function useCheckUserPromocodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckUserPromocodeQuery, CheckUserPromocodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckUserPromocodeQuery, CheckUserPromocodeQueryVariables>(CheckUserPromocodeDocument, options);
        }
export type CheckUserPromocodeQueryHookResult = ReturnType<typeof useCheckUserPromocodeQuery>;
export type CheckUserPromocodeLazyQueryHookResult = ReturnType<typeof useCheckUserPromocodeLazyQuery>;
export type CheckUserPromocodeQueryResult = Apollo.QueryResult<CheckUserPromocodeQuery, CheckUserPromocodeQueryVariables>;
export const GetPageMetasDocument = gql`
    query GetPageMetas($metas_input: MetasInput = {}) {
  GetPageMetas(metas_input: $metas_input) {
    metas
  }
}
    `;

/**
 * __useGetPageMetasQuery__
 *
 * To run a query within a React component, call `useGetPageMetasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageMetasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageMetasQuery({
 *   variables: {
 *      metas_input: // value for 'metas_input'
 *   },
 * });
 */
export function useGetPageMetasQuery(baseOptions?: Apollo.QueryHookOptions<GetPageMetasQuery, GetPageMetasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageMetasQuery, GetPageMetasQueryVariables>(GetPageMetasDocument, options);
      }
export function useGetPageMetasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageMetasQuery, GetPageMetasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageMetasQuery, GetPageMetasQueryVariables>(GetPageMetasDocument, options);
        }
export type GetPageMetasQueryHookResult = ReturnType<typeof useGetPageMetasQuery>;
export type GetPageMetasLazyQueryHookResult = ReturnType<typeof useGetPageMetasLazyQuery>;
export type GetPageMetasQueryResult = Apollo.QueryResult<GetPageMetasQuery, GetPageMetasQueryVariables>;
export const GetUserUpgradeInitialInventoryDocument = gql`
    query GetUserUpgradeInitialInventory($user_id: uuid!, $inventory_limit: Int!, $booster_limit: Int!) {
  user_account_by_pk(user_id: $user_id) {
    user_id
    user_inventory_id
    user_inventory {
      items: user_items_in_inventories(
        where: {item_status: {_eq: 1}, item_frozen: {_eq: false}}
        limit: $inventory_limit
        offset: 0
        order_by: [{receiving_datetime: desc}, {user_item_id: desc}]
      ) {
        item_id
        user_item_id
        item_image_link
        item_hash_name
        localized_name {
          ru_name
        }
        item_frozen
        item_image_link_formated
        item_quality
        item_rariry
        item_start_price
        item_status
      }
      boosters: user_boosters_in_inventory(
        where: {available: {_eq: true}}
        limit: $booster_limit
        offset: 0
        order_by: {booster_value: desc}
      ) {
        booster_id
        user_booster_id
        booster_image_link
        booster_value
        user_inventory_id
      }
      pages_for_upgrade_count(args: {per_page: $inventory_limit})
      pages_for_boosters(args: {per_page: $booster_limit})
      user_inventory_id
    }
  }
}
    `;

/**
 * __useGetUserUpgradeInitialInventoryQuery__
 *
 * To run a query within a React component, call `useGetUserUpgradeInitialInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserUpgradeInitialInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserUpgradeInitialInventoryQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      inventory_limit: // value for 'inventory_limit'
 *      booster_limit: // value for 'booster_limit'
 *   },
 * });
 */
export function useGetUserUpgradeInitialInventoryQuery(baseOptions: Apollo.QueryHookOptions<GetUserUpgradeInitialInventoryQuery, GetUserUpgradeInitialInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserUpgradeInitialInventoryQuery, GetUserUpgradeInitialInventoryQueryVariables>(GetUserUpgradeInitialInventoryDocument, options);
      }
export function useGetUserUpgradeInitialInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserUpgradeInitialInventoryQuery, GetUserUpgradeInitialInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserUpgradeInitialInventoryQuery, GetUserUpgradeInitialInventoryQueryVariables>(GetUserUpgradeInitialInventoryDocument, options);
        }
export type GetUserUpgradeInitialInventoryQueryHookResult = ReturnType<typeof useGetUserUpgradeInitialInventoryQuery>;
export type GetUserUpgradeInitialInventoryLazyQueryHookResult = ReturnType<typeof useGetUserUpgradeInitialInventoryLazyQuery>;
export type GetUserUpgradeInitialInventoryQueryResult = Apollo.QueryResult<GetUserUpgradeInitialInventoryQuery, GetUserUpgradeInitialInventoryQueryVariables>;
export const GetUpgradeInventoryPaginationDocument = gql`
    query GetUpgradeInventoryPagination($user_id: uuid!, $limit: Int!, $offset: Int!) {
  user_account_by_pk(user_id: $user_id) {
    user_id
    user_inventory {
      items: user_items_in_inventories(
        where: {item_status: {_eq: 1}, item_frozen: {_eq: false}}
        limit: $limit
        offset: $offset
        order_by: [{receiving_datetime: desc}, {user_item_id: desc}]
      ) {
        item_id
        user_item_id
        item_image_link
        item_hash_name
        localized_name {
          ru_name
        }
        item_frozen
        item_image_link_formated
        item_quality
        item_rariry
        item_start_price
        item_status
      }
      pages_for_upgrade_count(args: {per_page: $limit})
      user_inventory_id
    }
  }
}
    `;

/**
 * __useGetUpgradeInventoryPaginationQuery__
 *
 * To run a query within a React component, call `useGetUpgradeInventoryPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpgradeInventoryPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpgradeInventoryPaginationQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetUpgradeInventoryPaginationQuery(baseOptions: Apollo.QueryHookOptions<GetUpgradeInventoryPaginationQuery, GetUpgradeInventoryPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpgradeInventoryPaginationQuery, GetUpgradeInventoryPaginationQueryVariables>(GetUpgradeInventoryPaginationDocument, options);
      }
export function useGetUpgradeInventoryPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpgradeInventoryPaginationQuery, GetUpgradeInventoryPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpgradeInventoryPaginationQuery, GetUpgradeInventoryPaginationQueryVariables>(GetUpgradeInventoryPaginationDocument, options);
        }
export type GetUpgradeInventoryPaginationQueryHookResult = ReturnType<typeof useGetUpgradeInventoryPaginationQuery>;
export type GetUpgradeInventoryPaginationLazyQueryHookResult = ReturnType<typeof useGetUpgradeInventoryPaginationLazyQuery>;
export type GetUpgradeInventoryPaginationQueryResult = Apollo.QueryResult<GetUpgradeInventoryPaginationQuery, GetUpgradeInventoryPaginationQueryVariables>;
export const GetBoostersPaginationDocument = gql`
    query GetBoostersPagination($user_id: uuid!, $limit: Int!, $offset: Int!) {
  user_account_by_pk(user_id: $user_id) {
    user_id
    user_inventory {
      boosters: user_boosters_in_inventory(
        where: {available: {_eq: true}}
        limit: $limit
        offset: $offset
        order_by: {booster_value: desc}
      ) {
        booster_id
        user_booster_id
        booster_image_link
        booster_value
        user_inventory_id
        available
        receiving_datetime
      }
      pages_for_boosters(args: {per_page: $limit})
      user_inventory_id
    }
  }
}
    `;

/**
 * __useGetBoostersPaginationQuery__
 *
 * To run a query within a React component, call `useGetBoostersPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoostersPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoostersPaginationQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetBoostersPaginationQuery(baseOptions: Apollo.QueryHookOptions<GetBoostersPaginationQuery, GetBoostersPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBoostersPaginationQuery, GetBoostersPaginationQueryVariables>(GetBoostersPaginationDocument, options);
      }
export function useGetBoostersPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBoostersPaginationQuery, GetBoostersPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBoostersPaginationQuery, GetBoostersPaginationQueryVariables>(GetBoostersPaginationDocument, options);
        }
export type GetBoostersPaginationQueryHookResult = ReturnType<typeof useGetBoostersPaginationQuery>;
export type GetBoostersPaginationLazyQueryHookResult = ReturnType<typeof useGetBoostersPaginationLazyQuery>;
export type GetBoostersPaginationQueryResult = Apollo.QueryResult<GetBoostersPaginationQuery, GetBoostersPaginationQueryVariables>;
export const GetPossibleItemsForUpgradeDocument = gql`
    query GetPossibleItemsForUpgrade($filters: ItemsForUpgradeFiltersDto = {item_price: 3000}) {
  GetPossibleItemsForUpgrade(filters: $filters) {
    current_page
    pages_count
    per_page
    items {
      item_id
      item_hash_name
      item_image_link
      item_image_link_formated
      item_price
      item_quality
      item_rarity
      ru_name
    }
  }
}
    `;

/**
 * __useGetPossibleItemsForUpgradeQuery__
 *
 * To run a query within a React component, call `useGetPossibleItemsForUpgradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPossibleItemsForUpgradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPossibleItemsForUpgradeQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPossibleItemsForUpgradeQuery(baseOptions?: Apollo.QueryHookOptions<GetPossibleItemsForUpgradeQuery, GetPossibleItemsForUpgradeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPossibleItemsForUpgradeQuery, GetPossibleItemsForUpgradeQueryVariables>(GetPossibleItemsForUpgradeDocument, options);
      }
export function useGetPossibleItemsForUpgradeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPossibleItemsForUpgradeQuery, GetPossibleItemsForUpgradeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPossibleItemsForUpgradeQuery, GetPossibleItemsForUpgradeQueryVariables>(GetPossibleItemsForUpgradeDocument, options);
        }
export type GetPossibleItemsForUpgradeQueryHookResult = ReturnType<typeof useGetPossibleItemsForUpgradeQuery>;
export type GetPossibleItemsForUpgradeLazyQueryHookResult = ReturnType<typeof useGetPossibleItemsForUpgradeLazyQuery>;
export type GetPossibleItemsForUpgradeQueryResult = Apollo.QueryResult<GetPossibleItemsForUpgradeQuery, GetPossibleItemsForUpgradeQueryVariables>;
export const GetUpgradeInfoDocument = gql`
    query GetUpgradeInfo($boosters: [UpgradeBoosters!], $input_item_price: Int!, $target_item_price: Int!) {
  CalcUpgradeParams(
    upgrade_params_input: {input_item_price: $input_item_price, target_item_price: $target_item_price, boosters: $boosters}
  ) {
    chance
    koef
  }
}
    `;

/**
 * __useGetUpgradeInfoQuery__
 *
 * To run a query within a React component, call `useGetUpgradeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpgradeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpgradeInfoQuery({
 *   variables: {
 *      boosters: // value for 'boosters'
 *      input_item_price: // value for 'input_item_price'
 *      target_item_price: // value for 'target_item_price'
 *   },
 * });
 */
export function useGetUpgradeInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUpgradeInfoQuery, GetUpgradeInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpgradeInfoQuery, GetUpgradeInfoQueryVariables>(GetUpgradeInfoDocument, options);
      }
export function useGetUpgradeInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpgradeInfoQuery, GetUpgradeInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpgradeInfoQuery, GetUpgradeInfoQueryVariables>(GetUpgradeInfoDocument, options);
        }
export type GetUpgradeInfoQueryHookResult = ReturnType<typeof useGetUpgradeInfoQuery>;
export type GetUpgradeInfoLazyQueryHookResult = ReturnType<typeof useGetUpgradeInfoLazyQuery>;
export type GetUpgradeInfoQueryResult = Apollo.QueryResult<GetUpgradeInfoQuery, GetUpgradeInfoQueryVariables>;
export const UpgradeItemDocument = gql`
    mutation UpgradeItem($item_id: uuid!, $user_item_id: uuid!, $boosters: [UserUpgradeInputBooster!]) {
  UpgradeUserItem(
    upgrade_input: {item_id: $item_id, user_item_id: $user_item_id, boosters: $boosters}
  ) {
    error
    result
    success
  }
}
    `;
export type UpgradeItemMutationFn = Apollo.MutationFunction<UpgradeItemMutation, UpgradeItemMutationVariables>;

/**
 * __useUpgradeItemMutation__
 *
 * To run a mutation, you first call `useUpgradeItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeItemMutation, { data, loading, error }] = useUpgradeItemMutation({
 *   variables: {
 *      item_id: // value for 'item_id'
 *      user_item_id: // value for 'user_item_id'
 *      boosters: // value for 'boosters'
 *   },
 * });
 */
export function useUpgradeItemMutation(baseOptions?: Apollo.MutationHookOptions<UpgradeItemMutation, UpgradeItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpgradeItemMutation, UpgradeItemMutationVariables>(UpgradeItemDocument, options);
      }
export type UpgradeItemMutationHookResult = ReturnType<typeof useUpgradeItemMutation>;
export type UpgradeItemMutationResult = Apollo.MutationResult<UpgradeItemMutation>;
export type UpgradeItemMutationOptions = Apollo.BaseMutationOptions<UpgradeItemMutation, UpgradeItemMutationVariables>;
export const InformationNoticeDocument = gql`
    query InformationNotice {
  survey_data_links(where: {is_active: {_eq: true}}) {
    id
    link
  }
  maintenance_mode(where: {is_active: {_eq: true}}) {
    background_color
    main_text_desktop
    main_text_mobile
    mode_type
    show_type
  }
}
    `;

/**
 * __useInformationNoticeQuery__
 *
 * To run a query within a React component, call `useInformationNoticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInformationNoticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInformationNoticeQuery({
 *   variables: {
 *   },
 * });
 */
export function useInformationNoticeQuery(baseOptions?: Apollo.QueryHookOptions<InformationNoticeQuery, InformationNoticeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InformationNoticeQuery, InformationNoticeQueryVariables>(InformationNoticeDocument, options);
      }
export function useInformationNoticeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InformationNoticeQuery, InformationNoticeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InformationNoticeQuery, InformationNoticeQueryVariables>(InformationNoticeDocument, options);
        }
export type InformationNoticeQueryHookResult = ReturnType<typeof useInformationNoticeQuery>;
export type InformationNoticeLazyQueryHookResult = ReturnType<typeof useInformationNoticeLazyQuery>;
export type InformationNoticeQueryResult = Apollo.QueryResult<InformationNoticeQuery, InformationNoticeQueryVariables>;
export const GetMainpageFilterParamsDocument = gql`
    query GetMainpageFilterParams {
  GetMainpageLootboxFilterParams {
    filter_skin_name
    max_price
    min_price
    filter_weapon_types {
      name
      param
    }
  }
}
    `;

/**
 * __useGetMainpageFilterParamsQuery__
 *
 * To run a query within a React component, call `useGetMainpageFilterParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMainpageFilterParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMainpageFilterParamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMainpageFilterParamsQuery(baseOptions?: Apollo.QueryHookOptions<GetMainpageFilterParamsQuery, GetMainpageFilterParamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMainpageFilterParamsQuery, GetMainpageFilterParamsQueryVariables>(GetMainpageFilterParamsDocument, options);
      }
export function useGetMainpageFilterParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMainpageFilterParamsQuery, GetMainpageFilterParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMainpageFilterParamsQuery, GetMainpageFilterParamsQueryVariables>(GetMainpageFilterParamsDocument, options);
        }
export type GetMainpageFilterParamsQueryHookResult = ReturnType<typeof useGetMainpageFilterParamsQuery>;
export type GetMainpageFilterParamsLazyQueryHookResult = ReturnType<typeof useGetMainpageFilterParamsLazyQuery>;
export type GetMainpageFilterParamsQueryResult = Apollo.QueryResult<GetMainpageFilterParamsQuery, GetMainpageFilterParamsQueryVariables>;
export const GetGiveawayFeedDocument = gql`
    query GetGiveawayFeed {
  GetGiveawaysRibbon {
    item_rarity
    item_quality
    item_price
    item_name
    item_image_link
    giveaway_id
    total_seconds
    start_date
    end_date
    item_claimed
    end_date
    current_deposit
    minimal_deposit
    user_participates
    winner_name
    winner_id
    winner_avatar
    user_count
  }
}
    `;

/**
 * __useGetGiveawayFeedQuery__
 *
 * To run a query within a React component, call `useGetGiveawayFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiveawayFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiveawayFeedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGiveawayFeedQuery(baseOptions?: Apollo.QueryHookOptions<GetGiveawayFeedQuery, GetGiveawayFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiveawayFeedQuery, GetGiveawayFeedQueryVariables>(GetGiveawayFeedDocument, options);
      }
export function useGetGiveawayFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiveawayFeedQuery, GetGiveawayFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiveawayFeedQuery, GetGiveawayFeedQueryVariables>(GetGiveawayFeedDocument, options);
        }
export type GetGiveawayFeedQueryHookResult = ReturnType<typeof useGetGiveawayFeedQuery>;
export type GetGiveawayFeedLazyQueryHookResult = ReturnType<typeof useGetGiveawayFeedLazyQuery>;
export type GetGiveawayFeedQueryResult = Apollo.QueryResult<GetGiveawayFeedQuery, GetGiveawayFeedQueryVariables>;
export const GetGiveawayDocument = gql`
    query GetGiveaway($giveaway_id: String = "") {
  GetGiveaway(giveaway_id: $giveaway_id) {
    current_deposit
    giveaway_id
    total_seconds
    item_id
    item_image_link
    item_name
    item_price
    item_claimed
    current_users
    limit_users
    item_rarity
    item_quality
    minimal_deposit
    winner_avatar
    winner_id
    winner_name
    start_date
    success
    minimal_users
    end_date
    status
    user_participates
  }
}
    `;

/**
 * __useGetGiveawayQuery__
 *
 * To run a query within a React component, call `useGetGiveawayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiveawayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiveawayQuery({
 *   variables: {
 *      giveaway_id: // value for 'giveaway_id'
 *   },
 * });
 */
export function useGetGiveawayQuery(baseOptions?: Apollo.QueryHookOptions<GetGiveawayQuery, GetGiveawayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiveawayQuery, GetGiveawayQueryVariables>(GetGiveawayDocument, options);
      }
export function useGetGiveawayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiveawayQuery, GetGiveawayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiveawayQuery, GetGiveawayQueryVariables>(GetGiveawayDocument, options);
        }
export type GetGiveawayQueryHookResult = ReturnType<typeof useGetGiveawayQuery>;
export type GetGiveawayLazyQueryHookResult = ReturnType<typeof useGetGiveawayLazyQuery>;
export type GetGiveawayQueryResult = Apollo.QueryResult<GetGiveawayQuery, GetGiveawayQueryVariables>;
export const GetGiveawayWinnerDocument = gql`
    query GetGiveawayWinner($giveaway_id: String = "") {
  GetGiveaway(giveaway_id: $giveaway_id) {
    winner_avatar
    winner_id
    winner_name
  }
}
    `;

/**
 * __useGetGiveawayWinnerQuery__
 *
 * To run a query within a React component, call `useGetGiveawayWinnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiveawayWinnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiveawayWinnerQuery({
 *   variables: {
 *      giveaway_id: // value for 'giveaway_id'
 *   },
 * });
 */
export function useGetGiveawayWinnerQuery(baseOptions?: Apollo.QueryHookOptions<GetGiveawayWinnerQuery, GetGiveawayWinnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiveawayWinnerQuery, GetGiveawayWinnerQueryVariables>(GetGiveawayWinnerDocument, options);
      }
export function useGetGiveawayWinnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiveawayWinnerQuery, GetGiveawayWinnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiveawayWinnerQuery, GetGiveawayWinnerQueryVariables>(GetGiveawayWinnerDocument, options);
        }
export type GetGiveawayWinnerQueryHookResult = ReturnType<typeof useGetGiveawayWinnerQuery>;
export type GetGiveawayWinnerLazyQueryHookResult = ReturnType<typeof useGetGiveawayWinnerLazyQuery>;
export type GetGiveawayWinnerQueryResult = Apollo.QueryResult<GetGiveawayWinnerQuery, GetGiveawayWinnerQueryVariables>;
export const GetGiveawayMembersDocument = gql`
    query GetGiveawayMembers($giveaway_id: String = "") {
  GetGiveawayMembers(giveaway_id: $giveaway_id) {
    user_id
    avatar
    persona_name
  }
}
    `;

/**
 * __useGetGiveawayMembersQuery__
 *
 * To run a query within a React component, call `useGetGiveawayMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiveawayMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiveawayMembersQuery({
 *   variables: {
 *      giveaway_id: // value for 'giveaway_id'
 *   },
 * });
 */
export function useGetGiveawayMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetGiveawayMembersQuery, GetGiveawayMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiveawayMembersQuery, GetGiveawayMembersQueryVariables>(GetGiveawayMembersDocument, options);
      }
export function useGetGiveawayMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiveawayMembersQuery, GetGiveawayMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiveawayMembersQuery, GetGiveawayMembersQueryVariables>(GetGiveawayMembersDocument, options);
        }
export type GetGiveawayMembersQueryHookResult = ReturnType<typeof useGetGiveawayMembersQuery>;
export type GetGiveawayMembersLazyQueryHookResult = ReturnType<typeof useGetGiveawayMembersLazyQuery>;
export type GetGiveawayMembersQueryResult = Apollo.QueryResult<GetGiveawayMembersQuery, GetGiveawayMembersQueryVariables>;
export const GetGiveawayItemDocument = gql`
    query GetGiveawayItem($giveaway_id: String = "") {
  GiveawayGetItem(giveaway_id: $giveaway_id) {
    success
    error
  }
}
    `;

/**
 * __useGetGiveawayItemQuery__
 *
 * To run a query within a React component, call `useGetGiveawayItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiveawayItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiveawayItemQuery({
 *   variables: {
 *      giveaway_id: // value for 'giveaway_id'
 *   },
 * });
 */
export function useGetGiveawayItemQuery(baseOptions?: Apollo.QueryHookOptions<GetGiveawayItemQuery, GetGiveawayItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiveawayItemQuery, GetGiveawayItemQueryVariables>(GetGiveawayItemDocument, options);
      }
export function useGetGiveawayItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiveawayItemQuery, GetGiveawayItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiveawayItemQuery, GetGiveawayItemQueryVariables>(GetGiveawayItemDocument, options);
        }
export type GetGiveawayItemQueryHookResult = ReturnType<typeof useGetGiveawayItemQuery>;
export type GetGiveawayItemLazyQueryHookResult = ReturnType<typeof useGetGiveawayItemLazyQuery>;
export type GetGiveawayItemQueryResult = Apollo.QueryResult<GetGiveawayItemQuery, GetGiveawayItemQueryVariables>;
export const GetUserGiveawayHistoryDocument = gql`
    query GetUserGiveawayHistory($page: Int) {
  GetHistoryUserGiveaway(page: $page, page_size: 4) {
    history {
      active
      end_date
      fixed_price
      item_claimed
      item_hash_name
      item_id
      item_image_link
      item_quality
      item_rarity
      start_date
      status
      type
      winner_id
      giveaway_id
    }
    pagination {
      current_page
      page_size
      total_items
      total_pages
    }
  }
}
    `;

/**
 * __useGetUserGiveawayHistoryQuery__
 *
 * To run a query within a React component, call `useGetUserGiveawayHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGiveawayHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGiveawayHistoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetUserGiveawayHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetUserGiveawayHistoryQuery, GetUserGiveawayHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserGiveawayHistoryQuery, GetUserGiveawayHistoryQueryVariables>(GetUserGiveawayHistoryDocument, options);
      }
export function useGetUserGiveawayHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserGiveawayHistoryQuery, GetUserGiveawayHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserGiveawayHistoryQuery, GetUserGiveawayHistoryQueryVariables>(GetUserGiveawayHistoryDocument, options);
        }
export type GetUserGiveawayHistoryQueryHookResult = ReturnType<typeof useGetUserGiveawayHistoryQuery>;
export type GetUserGiveawayHistoryLazyQueryHookResult = ReturnType<typeof useGetUserGiveawayHistoryLazyQuery>;
export type GetUserGiveawayHistoryQueryResult = Apollo.QueryResult<GetUserGiveawayHistoryQuery, GetUserGiveawayHistoryQueryVariables>;
export const GiveawayMembersRatingDocument = gql`
    query GiveawayMembersRating($page: Int, $pageSize: Int, $wonCount: Boolean!) {
  UsersGiveawaysRating(page: $page, page_size: $pageSize, won_count: $wonCount) {
    pagination {
      current_page
      total_pages
      total_items
      page_size
    }
    rating {
      avatar
      persona_name
      user_id
      won_count
      participation_count
      last_prize {
        item_id
        item_image_link
        item_name
        item_price
        item_quality
        item_rarity
      }
    }
  }
}
    `;

/**
 * __useGiveawayMembersRatingQuery__
 *
 * To run a query within a React component, call `useGiveawayMembersRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiveawayMembersRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiveawayMembersRatingQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      wonCount: // value for 'wonCount'
 *   },
 * });
 */
export function useGiveawayMembersRatingQuery(baseOptions: Apollo.QueryHookOptions<GiveawayMembersRatingQuery, GiveawayMembersRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GiveawayMembersRatingQuery, GiveawayMembersRatingQueryVariables>(GiveawayMembersRatingDocument, options);
      }
export function useGiveawayMembersRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiveawayMembersRatingQuery, GiveawayMembersRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GiveawayMembersRatingQuery, GiveawayMembersRatingQueryVariables>(GiveawayMembersRatingDocument, options);
        }
export type GiveawayMembersRatingQueryHookResult = ReturnType<typeof useGiveawayMembersRatingQuery>;
export type GiveawayMembersRatingLazyQueryHookResult = ReturnType<typeof useGiveawayMembersRatingLazyQuery>;
export type GiveawayMembersRatingQueryResult = Apollo.QueryResult<GiveawayMembersRatingQuery, GiveawayMembersRatingQueryVariables>;
export const ClosestGiveawayDocument = gql`
    query ClosestGiveaway {
  ClosestGiveaway {
    giveaway {
      giveaway_id
      start_date
      end_date
      total_seconds
      item {
        item_id
        item_image_link
        item_name
        item_price
        item_quality
        item_rarity
      }
    }
  }
}
    `;

/**
 * __useClosestGiveawayQuery__
 *
 * To run a query within a React component, call `useClosestGiveawayQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosestGiveawayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosestGiveawayQuery({
 *   variables: {
 *   },
 * });
 */
export function useClosestGiveawayQuery(baseOptions?: Apollo.QueryHookOptions<ClosestGiveawayQuery, ClosestGiveawayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClosestGiveawayQuery, ClosestGiveawayQueryVariables>(ClosestGiveawayDocument, options);
      }
export function useClosestGiveawayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClosestGiveawayQuery, ClosestGiveawayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClosestGiveawayQuery, ClosestGiveawayQueryVariables>(ClosestGiveawayDocument, options);
        }
export type ClosestGiveawayQueryHookResult = ReturnType<typeof useClosestGiveawayQuery>;
export type ClosestGiveawayLazyQueryHookResult = ReturnType<typeof useClosestGiveawayLazyQuery>;
export type ClosestGiveawayQueryResult = Apollo.QueryResult<ClosestGiveawayQuery, ClosestGiveawayQueryVariables>;
export const MonthlyWinnersDocument = gql`
    query MonthlyWinners {
  MonthlyWinners {
    winners {
      user_id
      persona_name
      avatar
      giveaway_id
      start_date
      end_date
      participation_count
      won_count
      item {
        item_id
        item_name
        item_image_link
        item_price
        item_quality
        item_rarity
      }
    }
  }
}
    `;

/**
 * __useMonthlyWinnersQuery__
 *
 * To run a query within a React component, call `useMonthlyWinnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyWinnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyWinnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useMonthlyWinnersQuery(baseOptions?: Apollo.QueryHookOptions<MonthlyWinnersQuery, MonthlyWinnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonthlyWinnersQuery, MonthlyWinnersQueryVariables>(MonthlyWinnersDocument, options);
      }
export function useMonthlyWinnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyWinnersQuery, MonthlyWinnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonthlyWinnersQuery, MonthlyWinnersQueryVariables>(MonthlyWinnersDocument, options);
        }
export type MonthlyWinnersQueryHookResult = ReturnType<typeof useMonthlyWinnersQuery>;
export type MonthlyWinnersLazyQueryHookResult = ReturnType<typeof useMonthlyWinnersLazyQuery>;
export type MonthlyWinnersQueryResult = Apollo.QueryResult<MonthlyWinnersQuery, MonthlyWinnersQueryVariables>;
export const AcceptTermsDocument = gql`
    mutation AcceptTerms {
  AcceptTerms
}
    `;
export type AcceptTermsMutationFn = Apollo.MutationFunction<AcceptTermsMutation, AcceptTermsMutationVariables>;

/**
 * __useAcceptTermsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsMutation, { data, loading, error }] = useAcceptTermsMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptTermsMutation(baseOptions?: Apollo.MutationHookOptions<AcceptTermsMutation, AcceptTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptTermsMutation, AcceptTermsMutationVariables>(AcceptTermsDocument, options);
      }
export type AcceptTermsMutationHookResult = ReturnType<typeof useAcceptTermsMutation>;
export type AcceptTermsMutationResult = Apollo.MutationResult<AcceptTermsMutation>;
export type AcceptTermsMutationOptions = Apollo.BaseMutationOptions<AcceptTermsMutation, AcceptTermsMutationVariables>;