interface ICrossIcon {
  color?: string;
}

const CrossIcon = (props: ICrossIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        d="M9 1L1 9M1 1L9 9"
        stroke={props.color || '#F44141'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossIcon;
