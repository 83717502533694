import { getSessionId } from '@amplitude/analytics-browser';

export const passAmpliSessionIdToCookie = () => {
  const sessionId = getSessionId();
  if (sessionId) {
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const expires = `expires=${date.toUTCString()}`;

    if (typeof window !== 'undefined') {
      document.cookie = `amp_session=${sessionId}; ${expires}; path=/; SameSite=None; Secure`;
    }
  }
};
