const TopIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.3333 14.6667H4.66666C4.39333 14.6667 4.16666 14.44 4.16666 14.1667C4.16666 13.8934 4.39333 13.6667 4.66666 13.6667H11.3333C11.6067 13.6667 11.8333 13.8934 11.8333 14.1667C11.8333 14.44 11.6067 14.6667 11.3333 14.6667Z"
        fill="#9297B2"
      />
      <path
        d="M13.5667 3.67999L10.9 5.58665C10.5467 5.83999 10.04 5.68665 9.88667 5.27999L8.62667 1.91999C8.41334 1.33999 7.59334 1.33999 7.38 1.91999L6.11334 5.27332C5.96 5.68665 5.46 5.83999 5.10667 5.57999L2.44 3.67332C1.90667 3.29999 1.2 3.82665 1.42 4.44665L4.19333 12.2133C4.28667 12.48 4.54 12.6533 4.82 12.6533H11.1733C11.4533 12.6533 11.7067 12.4733 11.8 12.2133L14.5733 4.44665C14.8 3.82665 14.0933 3.29999 13.5667 3.67999ZM9.66667 9.83332H6.33334C6.06 9.83332 5.83333 9.60665 5.83333 9.33332C5.83333 9.05999 6.06 8.83332 6.33334 8.83332H9.66667C9.94 8.83332 10.1667 9.05999 10.1667 9.33332C10.1667 9.60665 9.94 9.83332 9.66667 9.83332Z"
        fill="#9297B2"
      />
    </svg>
  );
};

export default TopIcon;
