import { Box } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './ItemLiveDropComponent.module.scss';
import { itemRarities } from '@/configs/itemRarities';
import OptimizedImage from '@/components/Image';

export interface IItemLifeDropComponent {
  img: string;
  rarity: string;
  weaponName: string | null | undefined;
  skinName: string | null | undefined;
  userLink: string;
  userAvatar: string | null | undefined;
  userName: string;
  caseImg: string | null | undefined;
}

/**
 * Карточка айтема
 * @param text название айтема
 * @param icon иконка айтема
 * @param rarity цве т градиента в зависимости от редкости
 */
const ItemLiveDropComponent = (props: IItemLifeDropComponent) => {
  const itemColor = itemRarities[props.rarity];
  // const borderColor = itemBorders[props.rarity];
  return (
    <Link href={props.userLink} passHref>
      <Box
        className={styles.container}
        sx={{
          background: `radial-gradient(64.94% 102.4% at 50% 29.81%, ${itemColor} 0%, rgba(93, 3, 101, 0) 100%), linear-gradient(180deg, #141830 0%, rgba(25, 31, 64, 0) 100%)`,
          border: `1px solid ${itemColor}`,
        }}
      >
        <div className={styles.container_line1}></div>
        <div className={styles.container_line2}>
          <div className={styles.main_live_item_front}>
            <div className={styles.main_live_item_front_img}>
              <Image
                src={props.img}
                alt="weapon skin"
                layout="fill"
                loader={({ src }) => src}
                unoptimized
              />
            </div>
            <div className={styles.main_live_item_front_text}>
              <p className={styles.main_live_item_front_text_weaponName}>
                {props.weaponName}
              </p>
              <p className={styles.main_live_item_front_text_skinsName}>
                {props.skinName}
              </p>
            </div>
          </div>
          <div className={styles.main_live_item_back}>
            <div className={styles.main_live_item_back_case}>
              <OptimizedImage
                src={props.caseImg ?? 'TODO: добавить картинку кейса'}
                alt="case image"
                objectFit="contain"
                layout="fill"
              />
            </div>
            <div className={styles.main_live_item_back_userProfile}>
              <p className={styles.main_live_item_back_userProfile_text}>
                {props.userName}
              </p>
              <OptimizedImage
                className={styles.main_live_item_back_userProfile_avatar}
                src={props.userAvatar ?? 'TODO: добавить картинку аватара'}
                width="32px"
                height="32px"
                alt="Картинка профиля"
              />
            </div>
          </div>
        </div>
      </Box>
    </Link>
  );
};

export default ItemLiveDropComponent;
