import imgLogo from '@/assets/icons/Logo.svg';
import mobileLogo from '@/assets/icons/mobileLogo.svg';
import Image from 'next/image';
import Link from 'next/link';
import styles from './Logo.module.scss';
import * as process from 'process';

/**
 * Зеленый логотип
 */
export const Logo = ({
  isAdaptive,
  isMobile,
}: {
  isAdaptive?: boolean;
  isMobile?: boolean;
}) => {
  const className = isAdaptive
    ? isMobile
      ? styles.mobileLogo
      : styles.adaptiveLogo
    : styles.logo;

  const isDev = process.env.NEXT_PUBLIC_APP_ENV == 'development';

  return (
    <Link href={'/'} passHref>
      <a className={className}>
        {isDev ? (
          <h1 style={{ color: 'red' }}>TEST BUILD</h1>
        ) : (
          <Image
            src={isMobile ? mobileLogo : imgLogo}
            style={{ cursor: 'pointer' }}
            alt="Hyper Drop Logo"
            layout="responsive"
          />
        )}
      </a>
    </Link>
  );
};
