import noAvatar from '@/assets/images/profile.svg';
import { APP_URLS } from '@/configs/urls';
import { useAuthContext } from '@/hooks/use-auth-context';
import { useUserData } from '@/hooks/use-user-data';
import { theme } from '@/styles/theme';
import { useMediaQuery } from '@mui/material';
import Link from 'next/link';
import { UserMoneyBlock } from '../UserMoneyBlock/UserMoneyBlock';
import styles from './AccountBlock.module.scss';
import { useSoundContext } from 'sounds';
import OptimizedImage from '@/components/Image';

/**
 * Блок авторизованного пользователя (desktop)
 */
export const AccountBlock = () => {
  const { userId } = useAuthContext();
  const { avatar } = useUserData(userId);
  const showMobileMenuSmall = useMediaQuery(theme.breakpoints.down(700));

  const { playSound } = useSoundContext();

  return (
    <div className={styles.avatar_container}>
      <UserMoneyBlock />
      {!showMobileMenuSmall && (
        <div className={styles.buttons}>
          <Link href={APP_URLS.getUserPage(userId!)} passHref>
            <a
              className={styles.avatarBack_container}
              onClick={() => playSound('click_general')}
            >
              <OptimizedImage
                src={avatar ? avatar : noAvatar}
                alt="Аватарка"
                unoptimized
                layout={avatar ? 'fill' : undefined}
                loader={({ src }) => src}
              />
            </a>
          </Link>
        </div>
      )}
    </div>
  );
};
