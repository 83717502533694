export const isEmpty = (data: any) => {
  return (
    data === null ||
    data === 'null' ||
    data === undefined ||
    data === 'undefined' ||
    ((typeof data === 'string' || data instanceof String) && !data.length) ||
    (Array.isArray(data) && !data.length) ||
    (Object.keys(data).length === 0 && data.constructor === Object)
  );
};
