export function getBrowserName() {
  const userAgent = window.navigator.userAgent;
  if (userAgent.includes('YaBrowser')) {
    return 'Yandex Browser';
  }
  if (userAgent.includes('Firefox')) {
    return 'Mozilla Firefox';
  }
  if (userAgent.includes('SamsungBrowser')) {
    return 'Samsung Internet';
  }
  if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    return 'Opera';
  }
  if (userAgent.includes('Edge')) {
    return 'Microsoft Edge (Legacy)';
  }
  if (userAgent.includes('Edg')) {
    return 'Microsoft Edge (Chromium)';
  }
  if (userAgent.includes('Chrome')) {
    return 'Google Chrome or Chromium';
  }
  if (userAgent.includes('Safari')) {
    return 'Apple Safari';
  }
  return 'Unknown browser';
}

export function detectMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Opera Mini/i,
    /IEMobile/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export function getUserOS() {
  const userAgent = window.navigator.userAgent;
  if (
    userAgent.includes('Windows NT 10.0') ||
    userAgent.includes('Windows 10.0')
  )
    return 'Windows 10';
  if (userAgent.includes('Windows NT 6.3') || userAgent.includes('Windows 8.1'))
    return 'Windows 8.1';
  if (userAgent.includes('Windows NT 6.2') || userAgent.includes('Windows 8'))
    return 'Windows 8';
  if (userAgent.includes('Windows NT 6.1') || userAgent.includes('Windows 7'))
    return 'Windows 7';
  if (
    userAgent.includes('Windows NT 6.0') ||
    userAgent.includes('Windows Vista')
  )
    return 'Windows Vista';
  if (userAgent.includes('Windows NT 5.1') || userAgent.includes('Windows XP'))
    return 'Windows XP';
  if (
    userAgent.includes('Windows NT 5.0') ||
    userAgent.includes('Windows 2000')
  )
    return 'Windows 2000';
  if (userAgent.includes('Open BSD')) return 'Open BSD';
  if (userAgent.includes('Chrome OS')) return 'Chrome OS';
  if (userAgent.includes('Android')) return 'Android';
  if (
    userAgent.includes('iPhone') ||
    userAgent.includes('iPad') ||
    userAgent.includes('iPod')
  )
    return 'iOS';
  if (userAgent.includes('Mac')) return 'Mac';
  if (userAgent.includes('X11') || userAgent.includes('UNIX')) return 'UNIX';
  if (userAgent.includes('Linux')) return 'Linux';
  return 'Unknown OS';
}

export function getUserTimeZoneOffset() {
  const offset = new Date().getTimezoneOffset();
  const hours = Math.abs(Math.floor(offset / 60));
  const minutes = Math.abs(offset % 60);
  const sign = offset > 0 ? '-' : '+';

  return `GMT${sign}${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}
