import sbp from '@/assets/images/pay/sbp.svg';
import sbpMobile from '@/assets/images/pay/sbpMobile.svg';
import visaMir from '@/assets/images/pay/visaMir.svg';
import visaMirMobile from '@/assets/images/pay/visaMirMobile.svg';
import { StaticImageData } from 'next/image';
import tether from '@/assets/images/pay/tether.svg';
import tetherMobile from '@/assets/images/pay/tetherMobile.svg';
import skinsback from '@/assets/images/pay/skinsback.svg';

export type TPmMethod = {
  pm_name: string;
  pm_ps_type: number;
  pm_method: string;
  pm_currency: string;
  pm_min_value: number;
  pm_max_value: number;
  pm_pic: string | StaticImageData;
  pm_pic_mobile?: string | StaticImageData;
  commission_percentage?: number;
  pm_tab: number;
  pm_sub?: string;
  new_req?: boolean;
  sort: number;
  is_popular: boolean;
  is_p2p: boolean;
};

export const payment_systems_methods: TPmMethod[] = [
  {
    pm_tab: 1,
    pm_name: 'Все карты',
    pm_ps_type: 7,
    pm_method: 'card',
    pm_sub: 'card',
    pm_currency: 'RUB',
    pm_min_value: 100,
    pm_max_value: 10000,
    pm_pic: visaMir,
    pm_pic_mobile: visaMirMobile,
    commission_percentage: 10,
    new_req: true,
    sort: 0,
    is_popular: false,
    is_p2p: false,
  },
  {
    pm_tab: 0,
    pm_name: 'СБП',
    pm_ps_type: 3,
    pm_method: 'online',
    pm_sub: 'sbp',
    pm_currency: 'RUB',
    pm_min_value: 500,
    pm_max_value: 10000,
    pm_pic: sbp,
    pm_pic_mobile: sbpMobile,
    new_req: true,
    sort: 1,
    is_popular: false,
    is_p2p: false,
  },

  // {
  // freekassa cards
  //   pm_tab: 1,
  //   pm_name: 'Карты',
  //   pm_ps_type: 1,
  //   pm_method: 'card',
  //   pm_sub: 'card',
  //   pm_currency: 'RUB',
  //   pm_min_value: 300,
  //   pm_max_value: 10000,
  //   pm_pic: visaMir,
  //   pm_pic_mobile: visaMirMobile,
  //   commission_percentage: 10,
  //   new_req: true,
  // },
  {
    pm_tab: 2,
    pm_name: 'Crypto (USDT, Bitcoin)',
    pm_ps_type: 6,
    pm_method: 'crypto',
    pm_sub: 'crypto',
    pm_currency: 'RUB',
    pm_min_value: 200,
    pm_max_value: 10000,
    pm_pic: tether,
    pm_pic_mobile: tetherMobile,
    new_req: true,
    commission_percentage: 10,
    sort: 2,
    is_popular: false,
    is_p2p: false,
  },
  {
    pm_tab: 3,
    pm_name: 'Оплата скинами',
    pm_ps_type: 4,
    pm_method: 'skins',
    pm_currency: 'RUB',
    pm_min_value: 200,
    pm_max_value: 10000,
    pm_pic: skinsback,
    pm_pic_mobile: skinsback,
    pm_sub: 'skins',
    new_req: true,
    sort: 3,
    is_popular: false,
    is_p2p: false,
  },
  // {
  //   pm_tab: 1,
  //   pm_name: 'Карты (доп)',
  //   pm_ps_type: 3,
  //   pm_method: 'card',
  //   pm_sub: 'card',
  //   pm_currency: 'RUB',
  //   pm_min_value: 100,
  //   pm_max_value: 10000,
  //   pm_pic: visaMir,
  //   pm_pic_mobile: visaMirMobile,
  //   new_req: true,
  // },
  // {
  //   pm_tab: 1,
  //   pm_name: 'Карты (доп)',
  //   pm_ps_type: 3,
  //   pm_method: 'card',
  //   pm_currency: 'RUB',
  //   pm_min_value: 100,
  //   pm_max_value: 10000,
  //   pm_pic: visaMir,
  //   pm_pic_mobile: visaMirMobile,
  // },
  // {
  //   pm_tab: 1,
  //   pm_name: 'Qiwi',
  //   pm_ps_type: 1,
  //   pm_method: 'qiwi',
  //   pm_sub: 'qiwi',
  //   pm_currency: 'RUB',
  //   pm_min_value: 300,
  //   pm_max_value: 10000,
  //   pm_pic: qiwi,
  //   pm_pic_mobile: qiwiMobile,
  //   commission_percentage: 10,
  //   new_req: true,
  // },
  // {
  //   pm_tab: 1,
  //   pm_name: 'SberPay',
  //   pm_ps_type: 1,
  //   pm_method: 'online',
  //   pm_sub: 'sber_pay',
  //   pm_currency: 'RUB',
  //   pm_min_value: 100,
  //   pm_max_value: 10000,
  //   pm_pic: sberpay,
  //   pm_pic_mobile: sberpay,
  //   commission_percentage: 10,
  //   new_req: true
  // },
  // {
  //   pm_tab: 1,
  //   pm_name: 'Yoomoney',
  //   pm_ps_type: 1,
  //   pm_method: 'online',
  //   pm_sub: 'yoomoney',
  //   pm_currency: 'RUB',
  //   pm_min_value: 300,
  //   pm_max_value: 10000,
  //   pm_pic: yoomoney,
  //   pm_pic_mobile: yoomoneyMobile,
  //   commission_percentage: 10,
  //   new_req: true
  // },
  // {
  //   pm_tab: 0,
  //   pm_name: 'СБП',
  //   pm_ps_type: 1,
  //   pm_method: '42',
  //   pm_currency: 'RUB',
  //   pm_min_value: 300,
  //   pm_max_value: 10000,
  //   pm_pic: sbp,
  //   pm_pic_mobile: sbpMobile,
  //   commission_percentage: 10,
  // },

  // {
  //   pm_tab: 0,
  //   pm_name: 'Онлайн-Банк',
  //   pm_ps_type: 1,
  //   pm_method: 'online',
  //   pm_sub: 'online_bank',
  //   pm_currency: 'RUB',
  //   pm_min_value: 300,
  //   pm_max_value: 10000,
  //   pm_pic: onlineBank,
  //   pm_pic_mobile: onlineBank,
  //   new_req: true,
  //   commission_percentage: 10
  // },
  // {
  //   pm_tab: 0,
  //   pm_name: 'Онлайн-Банк',
  //   pm_ps_type: 1,
  //   pm_method: '13',
  //   pm_currency: 'RUB',
  //   pm_min_value: 300,
  //   pm_max_value: 10000,
  //   pm_pic: onlineBank,
  //   commission_percentage: 10,
  // },

  // {
  //   pm_tab: 1,
  //   pm_name: 'Карты (доп)',
  //   pm_ps_type: 1,
  //   pm_method: '8',
  //   pm_currency: 'RUB',
  //   pm_min_value: 100,
  //   pm_max_value: 10000,
  //   pm_pic: visaMir,
  //   commission_percentage: 10,
  // },
  // {
  //   pm_tab: 1,
  //   pm_name: 'Qiwi',
  //   pm_ps_type: 3,
  //   pm_method: 'qiwi',
  //   pm_currency: 'RUB',
  //   pm_min_value: 100,
  //   pm_max_value: 10000,
  //   pm_pic: qiwi,
  // },
];
