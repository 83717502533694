import NextImage, {
  ImageProps as NextImageProps,
  StaticImageData,
} from 'next/image';
import { FC } from 'react';

declare type StaticImport = StaticRequire | StaticImageData;

interface StaticRequire {
  default: StaticImageData;
}

interface ImageProps extends NextImageProps {
  alt: string;
}

function isStaticImageData(src: string | StaticImport): src is StaticImageData {
  return (
    typeof src === 'object' && 'src' in src && 'height' in src && 'width' in src
  );
}

function isUrl(str: string): boolean {
  try {
    new URL(str);
    return true;
  } catch (error) {
    return false;
  }
}

function getImageSrc(src: string | StaticImport) {
  if (isStaticImageData(src)) {
    return src;
  } else if (isUrl(src as string)) {
    return src;
  } else {
    const cdnPath =
      process.env.NEXT_PUBLIC_APP_ENV === 'production'
        ? 'https://cdn.hyper-drop.com/'
        : 'https://cdn-stage.hyper-drop.com/';
    return cdnPath + src;
  }
}

const OptimizedImage: FC<ImageProps> = ({ src, ...props }) => {
  //TODO: Добавить оптимизацию изображений с помощью библиотеки sharp
  const imageSrc = getImageSrc(src);

  return <NextImage {...props} src={imageSrc} />;
};

export default OptimizedImage;
