import React from 'react';
import styles from './UnauthPromoCheck.module.scss';
import Image from 'next/image';
import give from '@/assets/images/promoMain/give.webp';
import LoginButton from '@/components/LoginButton/LoginButton';

const UnauthPromoCheck = ({ promocode }: { promocode: string | undefined }) => {
  return (
    <div className={styles.containerPromo}>
      <div className={styles.containerPromoImage}>
        <Image src={give} alt="Party popper" />
      </div>
      <p className={styles.containerPromoText}>
        Скорее авторизуйся и забери награду по промокоду
        <span> {promocode}</span>
      </p>
      <LoginButton from="i_have_promocode" openPayModal />
    </div>
  );
};

export default UnauthPromoCheck;
