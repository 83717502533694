import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LiveDropQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LiveDropQuery = {
  __typename?: 'query_root';
  TopDropStripView: Array<{
    __typename?: 'TopDropStripView';
    full_items: Array<{
      __typename?: 'top_drop_strip';
      avatar?: string | null;
      item_image_link?: string | null;
      item_image_link_formated?: string | null;
      item_rarity?: string | null;
      lootbox_image_link?: string | null;
      persona_name?: string | null;
      user_id?: string | null;
      item_hash_name: string;
      drop_key: string;
      localized_name?: {
        __typename?: 'statistic_items_names';
        name: string;
        ru_name: string;
      } | null;
    }>;
    top_items: Array<{
      __typename?: 'top_drop_strip';
      avatar?: string | null;
      item_image_link?: string | null;
      item_image_link_formated?: string | null;
      item_rarity?: string | null;
      lootbox_image_link?: string | null;
      persona_name?: string | null;
      user_id?: string | null;
      item_hash_name: string;
      drop_key: string;
      localized_name?: {
        __typename?: 'statistic_items_names';
        name: string;
        ru_name: string;
      } | null;
    }>;
  }>;
};

export const LiveDropDocument = gql`
  query LiveDrop {
    TopDropStripView {
      full_items(
        limit: 11
        order_by: [{ drop_datetime: desc }, { record_id: asc }]
      ) {
        avatar
        item_image_link
        item_image_link_formated
        item_rarity
        lootbox_image_link
        persona_name
        user_id
        item_hash_name
        drop_key
        localized_name {
          name
          ru_name
        }
      }
      top_items(
        limit: 11
        order_by: [{ drop_datetime: desc }, { record_id: asc }]
      ) {
        avatar
        item_image_link
        item_image_link_formated
        item_rarity
        lootbox_image_link
        persona_name
        user_id
        item_hash_name
        drop_key
        localized_name {
          name
          ru_name
        }
      }
    }
  }
`;

/**
 * __useLiveDropQuery__
 *
 * To run a query within a React component, call `useLiveDropQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveDropQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveDropQuery({
 *   variables: {
 *   },
 * });
 */
export function useLiveDropQuery(
  baseOptions?: Apollo.QueryHookOptions<LiveDropQuery, LiveDropQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LiveDropQuery, LiveDropQueryVariables>(
    LiveDropDocument,
    options
  );
}
export function useLiveDropLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LiveDropQuery,
    LiveDropQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LiveDropQuery, LiveDropQueryVariables>(
    LiveDropDocument,
    options
  );
}
export type LiveDropQueryHookResult = ReturnType<typeof useLiveDropQuery>;
export type LiveDropLazyQueryHookResult = ReturnType<
  typeof useLiveDropLazyQuery
>;
export type LiveDropQueryResult = Apollo.QueryResult<
  LiveDropQuery,
  LiveDropQueryVariables
>;
