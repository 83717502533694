import errorImg from '@/assets/images/pay/status/error.svg';
import successImg from '@/assets/images/pay/status/success.svg';
import cross from '@/assets/images/pay/status/cross.svg';
import { Button, Modal, Typography } from '@mui/material';
import Image, { StaticImageData } from 'next/image';
import { useState } from 'react';
import styles from './PayStatus.module.scss';

interface IPayStatus {
  img: StaticImageData;
  text: string;
  subText?: string;
  btnText: string;
}

const paymentStatus: { [statusName: string]: IPayStatus } = {
  success: {
    img: successImg,
    text: 'Платеж успешно зачислен',
    btnText: 'Отлично',
  },
  error: {
    img: errorImg,
    text: 'Что-то пошло не так',
    subText: 'На карте недостаточно средств',
    btnText: 'Попробовать еще раз',
  },
  // pending: {
  //   img: pendingImg,
  //   text: 'Платеж обрабатывается',
  //   btnText: 'Отлично',
  // },
};

/** Модальное окно со статусом пополнения счета */
const PayStatus = ({ status }: { status: string }) => {
  const [open, setOpen] = useState(true);

  const handleClickClose = () => {
    setOpen(false);
    const url = new URL(window.location.href);
    url.searchParams.delete('payment');
    window.history.pushState({}, '', url.toString());
  };

  return (
    <>
      <Modal
        aria-labelledby="пополнение счета"
        aria-describedby="модальное окно с пополнением счета"
        open={open}
        onClose={handleClickClose}
        className={styles.modal}
      >
        <div className={styles.card_container}>
          <div className={styles.cross} onClick={handleClickClose}>
            <Image src={cross} alt="" />
          </div>

          <div className={styles.container}>
            <Typography className={styles.title}>Пополнение баланса</Typography>
            <div className={styles.imageText}>
              <Image src={paymentStatus[status].img} alt="" />
              <div className={styles.errorText}>
                <Typography className={styles.text}>
                  {paymentStatus[status].text}
                </Typography>
                <Typography className={styles.subtext}>
                  {paymentStatus[status].subText}
                </Typography>
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            className={styles.btn}
            color="mainGreen"
            onClick={handleClickClose}
          >
            <Typography className={styles.btnText}>
              {paymentStatus[status].btnText}
            </Typography>
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default PayStatus;
