export const formatNumber = (
  num: number | null | undefined,
  cashier?: boolean
) => {
  if (num === null || num === undefined) {
    return 0;
  }
  if (!cashier && num < 10000) {
    return num.toLocaleString('ru-RU');
  } else if (cashier && num < 1000) {
    return num.toLocaleString('ru-RU');
  } else if (num < 1000000) {
    return Math.floor((num / 1000) * 10) / 10 + 'k';
  } else {
    return Math.floor((num / 1000000) * 10) / 10 + 'M';
  }
};
