type gettingJWT = {
  issuer: string;
  audience: string;
  exp: number;
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': string;
    'x-hasura-default-role': string;
    'x-hasura-user-id': string;
    'x-hasura-profile-steam': string;
    'x-hasura-steam-id': string;
  };
};

type newJWT = {
  userID: string;
  userSteamID: string;
  steamProfileURL: string;
  userRole: string;
};

export const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  const JWTpayload: gettingJWT = JSON.parse(jsonPayload);

  const resultJWT: newJWT = {
    userID: JWTpayload['https://hasura.io/jwt/claims']['x-hasura-user-id'],
    steamProfileURL:
      JWTpayload['https://hasura.io/jwt/claims']['x-hasura-profile-steam'],
    userRole:
      JWTpayload['https://hasura.io/jwt/claims']['x-hasura-default-role'],
    userSteamID:
      JWTpayload['https://hasura.io/jwt/claims']['x-hasura-steam-id'],
  };

  return resultJWT;
};
