import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import styles from './CentreButton.module.scss';
import HyperRub from '@/assets/icons/hyperRub';

type ICentreButton = ButtonProps & {
  loading?: boolean;
  className?: string;
  icon?: JSX.Element;
  text: string;
  wallet?: boolean;
};

export default function CentreButton({
  loading,
  className,
  icon,
  text,
  wallet,
  ...props
}: ICentreButton) {
  return (
    // <div
    //   className={
    //     props.disabled || loading == true
    //       ? styles.disabled_container
    //       : styles.container
    //   }
    // >
    <Button
      // TouchRippleProps={{ style: { color: '#C0E252', opacity: 1 } }}
      className={`${styles.default} ${className}`}
      disabled={props.disabled || loading == true}
      {...props}
    >
      {loading ? (
        <CircularProgress
          style={{
            color: '#0C1027',
          }}
        />
      ) : (
        <>
          {icon}
          {text} {wallet ? <HyperRub color="#0C1027" /> : ''}
        </>
      )}
    </Button>
    // </div>
  );
}
