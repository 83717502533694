type THashName = string | null | undefined;

// Получает название скина из строки хэш-нейма
export const getSkinName = (hashName: THashName) => {
  if (!hashName?.includes('|')) {
    return '';
  }

  const afterPipe = hashName.substring(hashName.indexOf('|') + 1).trim();
  const withoutBrackets = afterPipe.replace(/\([^)]*\)/g, '').trim();
  return withoutBrackets;
};

// Получает название оружия из строки хэш-нейма
export const getWeaponName = (hashName: THashName) => {
  return hashName?.includes('|')
    ? hashName?.substring(0, hashName?.indexOf('|') - 1)
    : hashName;
};
