import { useEffect, useState } from 'react';

/** Хук для отслеживания состояния загрузки страницы */
export const useLoadingPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return { loading };
};
