import { useEffect, useState, useRef } from 'react';
import { getSessionId } from '@amplitude/analytics-browser';
import { userOnlineAtom } from 'atoms/user-online-atom';
import { useSetAtom } from 'jotai';

interface ICurrentOnline {
  current_online: number;
}

export const useOnlineUserCount = () => {
  const setOnlineUserCount = useSetAtom(userOnlineAtom);

  const reconnectDelayRef = useRef(5000);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    let socket: WebSocket;
    let reconnectTimeout: NodeJS.Timeout;

    const ampliSessionId = getSessionId();
    const wsUrl = `${process.env.NEXT_PUBLIC_WS_URL}online_connection?identifier=${ampliSessionId}`;

    const connectWebSocket = () => {
      socket = new WebSocket(wsUrl);

      socket.onmessage = (event) => {
        const message: ICurrentOnline = JSON.parse(event.data);

        message?.current_online && setOnlineUserCount(message.current_online);
      };

      socket.onclose = () => {
        reconnectTimeoutRef.current = setTimeout(
          connectWebSocket,
          reconnectDelayRef.current
        );
        reconnectDelayRef.current *= 2;
      };

      socket.onerror = (error) => {
        console.error(error);
        socket.close();
      };
    };

    connectWebSocket();

    return () => {
      socket.close();
      clearTimeout(reconnectTimeout);
    };
  }, []);
};
