import { createTheme } from '@mui/material';

declare module '@mui/material' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    web: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    mainGreen: Palette['primary'];
    mainBlue: Palette['primary'];
    darkBlue: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    mainGreen?: PaletteOptions['primary'];
    mainBlue?: PaletteOptions['primary'];
    darkBlue?: Palette['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    mainGreen: true;
    mainBlue: true;
    darkBlue: true;
  }
}

export const theme = createTheme({
  palette: {
    mainGreen: {
      main: '#D3F85A',
      contrastText: '#0C1027',
    },

    mainBlue: {
      main: '#797FA4',
      contrastText: '#0C1027',
    },
    darkBlue: {
      main: '#191f40',
      contrastText: '#0C1027',
      light: '#191f40',
      dark: '#191f40',
    },
  },
  breakpoints: {
    values: {
      mobile: 376,
      tablet: 1025,
      web: 2540,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        style: {
          fontFamily: `'Inter', system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols'`,
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            '& .MuiInputBase-input': {
              fontFamily: `'Play',system-ui, sans-serif, 'Segoe UI Emoji', 'Noto Color Emoji', 'Apple Color Emoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiOne', 'NotoEmoji', 'EmojiSymbols'`,
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '1.71',
              letterSpacing: '0.28px',
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            // '&:hover': {
            //   transform: 'scale(1.02) skew(-16deg)',
            // },
            // transition: 'all .2s ease-in-out',
            // transform: 'skew(-16deg)',
            borderRadius: '12px',
            '& p': {
              // transform: 'skew(16deg)',
              fontWeight: '700',
              textTransform: 'none',
              fontSize: '18px',
              color: ' #0C1027',
            },
            // '& img': {
            //   transform: 'skew(16deg)',
            // },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            // transform: 'skew(-16deg)',
            borderRadius: '10px',
            border: 'solid 2px transparent',
            backgroundImage:
              'linear-gradient(#0c1027, #0c1027), linear-gradient(165deg, rgba(211, 248, 90, 1) 0% , rgba(211, 248, 90, 0.14) 60%)',
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box',
            '& p': {
              // transform: 'skew(16deg)',
              fontWeight: '500',
              textTransform: 'none',
            },
            '&:hover': {
              border: 'solid 2px',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            borderRadius: '10px',
            border: 'solid 2px rgba(211, 248, 90, 1)',
            '& p': {
              // transform: 'skew(16deg)',
              fontWeight: '500',
              textTransform: 'none',
            },
            '&:hover': {
              border: 'solid 2px',
            },
          },
        },
        {
          props: { disabled: true },
          style: {
            // transform: 'skew(-16deg)',
            borderRadius: '10px',
            border: 'solid 2px rgba(255, 255, 255, 0.3)',
            // '& img': {
            //   transform: 'skew(16deg)',
            // },
          },
        },
        {
          props: { disabled: true, variant: 'contained' },
          style: {
            backgroundColor: 'rgba(211, 248, 90, 0.6) !important',
            color: 'rgba(0, 0, 0, 0.7) !important',
            // transform: 'skew(-16deg)',
            borderRadius: '12px',
            border: 'solid 2px rgba(255, 255, 255, 0.3)',
            // '& img': {
            //   transform: 'skew(16deg)',
            // },
          },
        },
      ],
    },
  },
});
