import { jsonToCookie } from '@/utils/json-to-cookie';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

const HASURA_WS_LINK = process.env.NEXT_PUBLIC_HASURA_WS_ENDPOINT;
const HASURA_LINK = process.env.NEXT_PUBLIC_HASURA_ENDPOINT;

// Мидлварь для добавления хедеров в gql
const middlewareLink = new ApolloLink((operation, forward) => {
  const oldContext = operation.getContext();
  const headers = {};

  if (oldContext.token) {
    headers.authorization = `Bearer ${oldContext.token}`;
  }

  if (oldContext.cookies) {
    headers.cookie = jsonToCookie(oldContext.cookies);
  }

  operation.setContext({ headers: headers });
  return forward(operation);
});

// Линк для вебсокетов
const wsLink =
  typeof window !== 'undefined'
    ? new GraphQLWsLink(
        createClient({
          url: HASURA_WS_LINK,
          shouldRetry: () => true,
        })
      )
    : null;

// Дефолтный линк
const httpLink = new HttpLink({
  uri: HASURA_LINK,
});

// Разделение линков
const splitLink =
  typeof window !== 'undefined'
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink
      )
    : httpLink;

// Создание клиента
const client = new ApolloClient({
  link: ApolloLink.from([middlewareLink, splitLink]),

  ssrMode: typeof window === 'undefined',
  cache: new InMemoryCache({
    typePolicies: {
      user_account: {
        keyFields: ['user_id'],
      },
      user_inventory: {
        keyFields: ['user_inventory_id'],
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: typeof window === 'undefined' ? 'no-cache' : 'cache-first',
    },
  },
});

export default client;
